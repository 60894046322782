import { useTranslation } from 'react-i18next';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import { Paper } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { ContentContainer, PageContent, PageHeader, PageTitle } from 'layout';
import FlagsSection from './FlagsSection';
import FxFeeSection from './FxFeeSection';
import PremiumCardFeeSection from './PremiumCardFeeSection';
import SubscriptionPlanParamsSection from './SubscriptionPlanParamsSection';
import SubscriptionPlanTypeSection from './SubscriptionPlanTypeSection';

const SubscriptionSettingsPage = () => {
  const { t } = useTranslation();
  const {
    state: { subscriptionPlan },
  } = useGlobalState();

  return (
    <>
      <PageHeader>
        <PageTitle
          title={t('int.subscriptionSettingsPage.title')}
          suptitle={t('int.sidebar.terms.title')}
        />
      </PageHeader>
      <PageContent>
        {subscriptionPlan ? (
          <ContentContainer>
            <Paper variant="tinted" sx={{ mb: 3 }}>
              <SubscriptionPlanTypeSection
                subscriptionPlan={subscriptionPlan}
              />
              <SubscriptionPlanParamsSection
                subscriptionPlan={subscriptionPlan}
              />
              <PremiumCardFeeSection />
            </Paper>
            <FlagsSection subscriptionPlan={subscriptionPlan} />
            <FxFeeSection subscriptionPlan={subscriptionPlan} />
          </ContentContainer>
        ) : (
          <WidgetError />
        )}
      </PageContent>
    </>
  );
};

export default withPageConfig(SubscriptionSettingsPage, {
  permission: 'subscription-settings-page:visit',
});
