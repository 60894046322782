import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  MoneyField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Money } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getMoneyObject } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
  expectedMonthlySpend: Money | null;
  onSuccess: (expectedMonthlySpend: Money) => void;
}

const ChangeExpectedMonthlySpendDialog = ({
  onSuccess,
  expectedMonthlySpend,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const hasInitialValue = useMemo(() => !!expectedMonthlySpend, []);
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      expectedMonthlySpend: expectedMonthlySpend
        ? expectedMonthlySpend.value / 100
        : undefined,
    },
    onSubmit: async ({ expectedMonthlySpend }) => {
      try {
        const data = await api.updateExpectedMonthlySpend(
          organization!.id,
          getMoneyObject(expectedMonthlySpend! * 100)
        );
        if (!mounted.current) return;
        onSuccess(data.expectedMonthlySpend);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const isSubmitDisabled =
    formik.isSubmitting || formik.values.expectedMonthlySpend === undefined;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {hasInitialValue
          ? t('changeExpectedMonthlySpendDialog.updateTitle')
          : t('changeExpectedMonthlySpendDialog.assignTitle')}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="change-expected-monthly-spend-form"
          noValidate
        >
          <Typography variant="body2" mb={4}>
            {hasInitialValue
              ? t('changeExpectedMonthlySpendDialog.updateDescription')
              : t('changeExpectedMonthlySpendDialog.assignDescription')}
          </Typography>
          <MoneyField
            label={t('changeExpectedMonthlySpendDialog.label')}
            {...omit(formik.getFieldProps('expectedMonthlySpend'), 'onChange')}
            onValueChange={({ floatValue }) =>
              formik.setFieldValue('expectedMonthlySpend', floatValue)
            }
            isAllowed={({ floatValue }) => floatValue !== 0}
            allowNegative={false}
            disabled={formik.isSubmitting}
            autoFocus
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="change-expected-monthly-spend-form"
          type="submit"
        >
          {hasInitialValue
            ? t('common.button.update')
            : t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(ChangeExpectedMonthlySpendDialog);
