import React, { MouseEvent, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { OrganizationRepresentativeExternalStatusBadge } from 'components/Badge';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Box, Button, IconButton, Tooltip, XCircleIcon } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  LegalRepresentativeType,
  OnboardingDocumentName,
  OnboardingDocumentStatus,
  OrganizationRepresentativeExternalStatus,
  OrganizationStatus,
  RelatedPerson,
  UboType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import RequestAdditionalInfoReasonDialog from './RequestAdditionalInfoReasonDialog';

interface Props {
  setIsLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  representative: RelatedPerson;
}

interface State {
  isCancelAdditionalInfoRequestDialogOpen: boolean;
  isRequestAdditionalInfoReasonDialog: boolean;
}

const RequestAdditionalInfoButton = ({
  isLoading,
  representative,
  setIsLoading,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();

  const {
    state: { organization },
  } = useGlobalState();

  const {
    actions: { fetchRepresentatives, fetchOnboardingDocuments },
    state: { onboardingDocuments },
  } = useCreditAndComplianceContext();

  const [state, setState] = useState<State>({
    isCancelAdditionalInfoRequestDialogOpen: false,
    isRequestAdditionalInfoReasonDialog: false,
  });

  const representativeDocuments = useMemo(
    () =>
      onboardingDocuments.filter(
        (doc) => doc.relatedEntityId === representative.id.value
      ),
    [onboardingDocuments]
  );

  const onRequestAdditionalInfo = async (
    reasonForAdditionalInfoRequest: string = ''
  ) => {
    try {
      setIsLoading(true);

      const shouldCreateAndRequestUboDocuments =
        representative!.legalRepType.value === LegalRepresentativeType.none &&
        [UboType.fictive, UboType.other, UboType.shareholding].includes(
          representative!.uboType.value
        ) &&
        representativeDocuments!.length === 0;

      const onboardingDocumentIdsToRequest = representativeDocuments!
        .filter((doc) =>
          [
            OnboardingDocumentStatus.DRAFT,
            OnboardingDocumentStatus.SUBMITTED,
          ].includes(doc.status)
        )
        .map((doc) => doc.id);

      const shouldRequestUboDocuments =
        representative!.legalRepType.value === LegalRepresentativeType.none &&
        [UboType.fictive, UboType.other, UboType.shareholding].includes(
          representative!.uboType.value
        ) &&
        onboardingDocumentIdsToRequest.length > 0;

      await Promise.all([
        api.requestOrganizationRepresentativeAdditionalInfo(
          organization!.id,
          representative!.id.value,
          reasonForAdditionalInfoRequest
        ),
        shouldCreateAndRequestUboDocuments
          ? api.createOnboardingDocuments(
              organization!.id,
              [
                OnboardingDocumentName.ID_COPY,
                OnboardingDocumentName.PROOF_OF_ADDRESS,
              ].map((type) => ({
                type: type,
                attributes: null,
                relatedEntityId: representative!.id.value,
                shouldAlsoRequest: true,
              }))
            )
          : undefined,
        shouldRequestUboDocuments
          ? api.requestOnboardingDocuments(
              organization!.id,
              onboardingDocumentIdsToRequest.map((docId) => ({
                onboardingDocumentId: docId,
              }))
            )
          : undefined,
      ]);

      if (shouldCreateAndRequestUboDocuments) {
        await fetchOnboardingDocuments();
      }
      await fetchRepresentatives();
      if (!mounted) return;
      setIsLoading(false);
      return true;
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const cancelAdditionalInfoRequest = async () => {
    try {
      setIsLoading(true);
      await api.cancelOrganizationRepresentativesAdditionalInfo(
        organization!.id,
        representative!.id.value
      );
      await fetchRepresentatives();
      if (!mounted) return;

      setState((prevState) => ({
        ...prevState,
        isCancelAdditionalInfoRequestDialogOpen: false,
      }));
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      {![OrganizationStatus.active, OrganizationStatus.onboardingWait].includes(
        organization!.status
      ) && (
        <Box display="flex" alignItems="center">
          {[
            OrganizationRepresentativeExternalStatus.infoSubmitted,
            OrganizationRepresentativeExternalStatus.internalOnly,
          ].includes(representative.externalStatus.value) ? (
            <Button
              disabled={isLoading}
              onClick={(e) => {
                e.stopPropagation();
                if (
                  representative.externalStatus.value ===
                  OrganizationRepresentativeExternalStatus.internalOnly
                ) {
                  onRequestAdditionalInfo();
                  return;
                }

                setIsLoading(true);
                setState((prevState) => ({
                  ...prevState,
                  isRequestAdditionalInfoReasonDialog: true,
                }));
              }}
              variant="outlined"
            >
              {t(
                'int.organizationRepresentativeSection.additionalInfoRequestButton.requestAdditionalInfoButton'
              )}
            </Button>
          ) : (
            <>
              <Tooltip
                disableHoverListener={
                  !representative?.reasonForAdditionalInfoRequest
                }
                title={representative!.reasonForAdditionalInfoRequest.value}
              >
                <span>
                  <OrganizationRepresentativeExternalStatusBadge
                    status={representative.externalStatus.value}
                  />
                </span>
              </Tooltip>

              {[
                OrganizationRepresentativeExternalStatus.infoRequested,
                OrganizationRepresentativeExternalStatus.infoFilled,
              ].includes(representative.externalStatus.value) && (
                <IconButton
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setState((prevState) => ({
                      ...prevState,
                      isCancelAdditionalInfoRequestDialogOpen: true,
                    }));
                  }}
                >
                  <XCircleIcon fontSize="small" />
                </IconButton>
              )}
            </>
          )}
        </Box>
      )}

      <RequestAdditionalInfoReasonDialog
        open={state.isRequestAdditionalInfoReasonDialog}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            isRequestAdditionalInfoReasonDialog: false,
          }));
          setIsLoading(false);
        }}
        onSuccess={onRequestAdditionalInfo}
      />

      <ConfirmDialog
        open={state.isCancelAdditionalInfoRequestDialogOpen}
        loading={isLoading}
        title={t(
          'int.organizationRepresentativeSection.additionalInfoRequestButton.cancelOpenRequestConfirmDialogTitle'
        )}
        description={
          <Trans
            i18nKey="int.organizationRepresentativeSection.additionalInfoRequestButton.cancelOpenRequestConfirmDialogDescription"
            values={{
              name: `${representative.givenName.value} ${representative.surName.value}`,
            }}
            components={{ b: <b /> }}
          />
        }
        onSuccess={(e) => {
          e.stopPropagation();
          cancelAdditionalInfoRequest();
        }}
        onClose={(e) => {
          e.stopPropagation();
          setState((prevState) => ({
            ...prevState,
            isCancelAdditionalInfoRequestDialogOpen: false,
          }));
        }}
      />
    </>
  );
};

export default RequestAdditionalInfoButton;
