import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { TopUpAccountDialog } from 'domains/billing/dialogs';
import { useCardAccountCurrency } from 'domains/card/hooks';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import {
  BankIcon,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InfoIcon,
  Link,
  Paper,
  PencilSimpleIcon,
  Tooltip,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import {
  CardAccountStatus,
  Money,
  OrganizationAccountType,
  OrganizationProductType,
  OrganizationStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { formatMoney, getPath } from 'services/utils';
import { didOrgOnboardingStepPassed } from 'services/utils/onboarding';
import CashbackSummary from './CashbackSummary';
import ChangeExpectedMonthlySpendDialog from './ChangeExpectedMonthlySpendDialog';
import CustomBar from './CustomBar';
import EmissionSummary from './EmissionSummary';
import MultiCurrenciesOverview from './MultiCurrenciesOverview';

const isCreditOrgAvailableLimitLow = (
  availableLimit: Money | null,
  limit: Money | null
) => {
  if (!availableLimit || !limit || !limit.value) return false;
  return (availableLimit.value / limit.value) * 100 <= 20;
};

const isPrefundOrgBalanceLow = (
  balance: Money | null,
  expectedMonthlySpend: Money | null
) => {
  if (!balance || !expectedMonthlySpend) return false;
  return (balance.value / expectedMonthlySpend.value) * 100 <= 20;
};

const useGetNextPaymentDate = (orgId: string | null): string | null => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const [nextPaymentDate, setNextPaymentDate] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (!canUser('billing-payments-page:visit')) return;
      setNextPaymentDate(null);
      if (!orgId) return;
      try {
        const data = await api.getOrganizationBillPayments(orgId, 0, 1);
        if (!mounted.current) return;
        const nextPaymentDate = data.pendingPayments
          .concat(data.upcomingPayments)
          .map((payment) => payment.collectionDate)
          .filter((s) => !!s)
          .sort((a, b) => moment(a).diff(moment(b)))[0];
        if (nextPaymentDate) {
          setNextPaymentDate(moment(nextPaymentDate).format('D MMM YYYY'));
        }
      } catch (error) {
        logError(error);
      }
    })();
  }, [orgId]);

  return nextPaymentDate;
};

const useGetExpectedMonthlySpend = () => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const {
    state: { organization, defaultCardAccount },
  } = useGlobalState();
  const didOrgPassOnboardingRisk = didOrgOnboardingStepPassed(
    organization!.status,
    OrganizationStatus.onboardingRiskReview
  );
  const isVisible =
    defaultCardAccount!.accountType.value ===
      OrganizationAccountType.prefunded &&
    didOrgPassOnboardingRisk &&
    canUser('org-expected-monthly-spend:view');
  const [
    expectedMonthlySpend,
    setExpectedMonthlySpend,
  ] = useState<Money | null>(null);
  const [isLoading, setIsLoading] = useState(isVisible);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!isVisible) return;
    (async () => {
      setIsLoading(true);
      try {
        const { expectedMonthlySpend } = await api.getExpectedMonthlySpend(
          organization!.id
        );
        if (!mounted.current) return;
        setExpectedMonthlySpend(expectedMonthlySpend);
        setIsLoading(false);
        setIsLoaded(true);
      } catch (error) {
        if (!mounted.current) return;
        setIsLoading(false);
        setError(true);
        logError(error);
      }
    })();
  }, []);

  return {
    isLoading,
    isLoaded,
    error,
    value: expectedMonthlySpend,
    onUpdate: setExpectedMonthlySpend,
  };
};

const FinancialOverview = () => {
  const { t, i18n } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { cardAccounts, defaultCardAccount, featureModules, organization },
  } = useGlobalState();
  const currency = useCardAccountCurrency();
  const currencyDisplay = useCurrencyDisplayMode();
  const balance = cardAccounts[0].balance.value;
  const availableLimit = cardAccounts[0].availableLimit.value;
  const limit = cardAccounts[0].creditLimit.value;
  const didOrgPassOnboardingRisk = didOrgOnboardingStepPassed(
    organization!.status,
    OrganizationStatus.onboardingRiskReview
  );
  const activeCardAccounts = cardAccounts.filter(
    (item) => item.status.value === CardAccountStatus.active
  );
  const [isTopUpDialogOpen, setIsTopUpDialogOpen] = useState(false);
  const [
    isChangeExpectedMonthlySpendDialogOpen,
    setIsChangeExpectedMonthlySpendDialogOpen,
  ] = useState(false);
  const nextPaymentDate = useGetNextPaymentDate(
    defaultCardAccount!.accountType.value === OrganizationAccountType.credit &&
      organization!.productType === OrganizationProductType.MDESD &&
      didOrgPassOnboardingRisk
      ? organization!.id
      : null
  );
  const expectedMonthlySpend = useGetExpectedMonthlySpend();

  const isCashbackVisible = featureModules.CASHBACK;
  const isEmissionVisible = featureModules.PLIANT_EARTH;

  const getCoverage = () => {
    if (
      defaultCardAccount!.accountType.value === OrganizationAccountType.credit
    )
      return Math.round((availableLimit.value / limit.value) * 100) || 0;
    return Math.round(
      (balance.value / expectedMonthlySpend.value!.value) * 100
    );
  };

  const getRemainingFundsInDays = () => {
    return Math.round((balance.value * 30) / expectedMonthlySpend.value!.value);
  };

  const renderBalance = () => {
    const value = didOrgPassOnboardingRisk
      ? formatMoney(
          { value: Math.abs(balance.value), currency: limit.currency },
          i18n.language,
          { currencyDisplay }
        )
      : '-';
    return balance.value > 0
      ? t('dashboardPage.prefundedBalance', { value })
      : t('dashboardPage.outstandingBalance', { value });
  };

  const renderFooter = () => {
    if (isCashbackVisible && isEmissionVisible) {
      return (
        <Box display="flex" mx={-2} mb={-2}>
          <CashbackSummary size="sm" />
          <EmissionSummary />
        </Box>
      );
    }

    if (isCashbackVisible) {
      return (
        <Box mx={-2} mb={-2}>
          <CashbackSummary size="lg" />
        </Box>
      );
    }

    return null;
  };

  const renderMultiAccountsOverview = () => {
    const totalAvailable = {
      value: activeCardAccounts.reduce(
        (accumulator, item) =>
          accumulator +
          (item.accountType.value === OrganizationAccountType.credit
            ? item.availableLimit.value.value
            : item.balance.value.value),
        0
      ),
      currency: currency.code,
    };
    const totalCreditLimit = {
      value: activeCardAccounts.reduce(
        (accumulator, item) => accumulator + item.creditLimit.value.value,
        0
      ),
      currency: currency.code,
    };
    const shouldShowCreditLimit = activeCardAccounts.some(
      (item) => item.accountType.value === OrganizationAccountType.credit
    );

    return (
      <Box display="flex" flexWrap="wrap" mb={2}>
        <Box>
          <Typography
            variant="caption2"
            mb={0.5}
            component="div"
            color="text.secondary"
          >
            {t('dashboardPage.available')}
            {shouldShowCreditLimit && (
              <> / {t('dashboardPage.creditLimitTotal')}</>
            )}
          </Typography>
          <Typography
            variant="h5"
            color={
              shouldShowCreditLimit &&
              isCreditOrgAvailableLimitLow(totalAvailable, totalCreditLimit)
                ? 'warning.main'
                : 'text.primary'
            }
          >
            <FormatMoney value={totalAvailable} />
            {shouldShowCreditLimit && (
              <Typography
                variant="subtitle1"
                component="span"
                color="text.secondary"
              >
                {' '}
                / <FormatMoney value={totalCreditLimit} />
              </Typography>
            )}
          </Typography>
        </Box>
        <Divider orientation="vertical" sx={{ height: 'auto', mx: 3 }} />
        <Box>
          <Typography
            variant="caption2"
            mb={0.5}
            component="div"
            color="text.secondary"
          >
            {t('dashboardPage.accounts')}
          </Typography>
          <Box display="flex" alignItems="center">
            <BankIcon sx={{ mr: 1 }} />
            <Typography variant="h6">
              {t('dashboardPage.accountsCount', {
                count: activeCardAccounts.length,
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  if (activeCardAccounts.length > 1) {
    return (
      <Paper
        data-intercom-target="financial-overview-widget"
        variant="outlined"
        sx={{ height: '100%', p: 2 }}
      >
        <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}>
          <Typography variant="subtitle1" flexGrow={1}>
            {t('dashboardPage.availableFundsAndLimit')}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            component={RouterLink}
            to={generatePath(getPath('cardAccounts'), {
              orgId: organization!.id,
            })}
          >
            {t('dashboardPage.viewAllAccounts')}
          </Button>
        </Box>
        {featureModules.MULTI_CURRENCY_BILLING ? (
          <MultiCurrenciesOverview />
        ) : (
          renderMultiAccountsOverview()
        )}
        {renderFooter()}
      </Paper>
    );
  }

  if (
    defaultCardAccount!.accountType.value === OrganizationAccountType.prefunded
  ) {
    return (
      <>
        <Paper
          data-intercom-target="financial-overview-widget"
          variant="outlined"
          sx={{ height: '100%', p: 2 }}
        >
          <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}>
            <Typography variant="subtitle1" flexGrow={1}>
              {t('dashboardPage.availableFundsAndLimit')}
            </Typography>
            {didOrgPassOnboardingRisk && (
              <>
                {!!expectedMonthlySpend.value && (
                  <Typography variant="caption2" color="text.secondary" mx={1}>
                    {t('dashboardPage.expectedRemainingFundsInDays', {
                      count: getRemainingFundsInDays(),
                    })}
                  </Typography>
                )}
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setIsTopUpDialogOpen(true)}
                >
                  {t('dashboardPage.topUpAccount')}
                </Button>
              </>
            )}
          </Box>
          <Box mb={2}>
            <Typography
              variant="caption2"
              component="div"
              color="text.secondary"
              mb={0.5}
            >
              {t('dashboardPage.available')}
            </Typography>
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Typography
                variant="h5"
                color={
                  isPrefundOrgBalanceLow(balance, expectedMonthlySpend.value)
                    ? 'warning.main'
                    : 'text.primary'
                }
              >
                {didOrgPassOnboardingRisk ? (
                  <FormatMoney value={balance} />
                ) : (
                  '-'
                )}
              </Typography>
              {didOrgPassOnboardingRisk && expectedMonthlySpend.value && (
                <>
                  <Typography
                    variant="caption"
                    mr={0.5}
                    mb="2px"
                    ml={1}
                    color="text.secondary"
                  >
                    <Trans
                      i18nKey="dashboardPage.coverage"
                      values={{ value: getCoverage() }}
                    />
                  </Typography>
                  <Tooltip title={t('dashboardPage.coveragePrefundTooltip')}>
                    <InfoIcon
                      fontSize="small"
                      sx={{ mb: '2px', color: 'text.secondary' }}
                    />
                  </Tooltip>
                </>
              )}
            </Box>
            {expectedMonthlySpend.isLoading && !expectedMonthlySpend.value && (
              <Box height="40px" textAlign="center">
                <CircularProgress size="small" />
              </Box>
            )}
            {expectedMonthlySpend.error && (
              <Box height="40px" textAlign="center">
                <Typography variant="caption" color="error.main">
                  {t('errors.loadData')}
                </Typography>
              </Box>
            )}
            {expectedMonthlySpend.isLoaded && !!expectedMonthlySpend.value && (
              <>
                <CustomBar
                  balance={balance}
                  availableLimit={availableLimit}
                  limit={limit}
                  expectedMonthlySpend={expectedMonthlySpend.value}
                />
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  mt={0.5}
                >
                  <IconButton
                    size="small"
                    disabled={!canUser('org-expected-monthly-spend:change')}
                    onClick={() =>
                      setIsChangeExpectedMonthlySpendDialogOpen(true)
                    }
                  >
                    <PencilSimpleIcon sx={{ width: 16, height: 16 }} />
                  </IconButton>
                  <Typography
                    variant="caption2"
                    color="text.secondary"
                    ml={0.5}
                  >
                    <Trans
                      i18nKey="dashboardPage.expectedMonthlySpending"
                      components={{
                        value: (
                          <FormatMoney value={expectedMonthlySpend.value} />
                        ),
                      }}
                    />
                  </Typography>
                </Box>
              </>
            )}
            {expectedMonthlySpend.isLoaded && !expectedMonthlySpend.value && (
              <>
                <Link
                  variant="caption"
                  component="button"
                  sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
                  disabled={!canUser('org-expected-monthly-spend:change')}
                  onClick={() =>
                    setIsChangeExpectedMonthlySpendDialogOpen(true)
                  }
                >
                  {t('dashboardPage.addYourExpMonthlySpendingBtn')}
                  <PencilSimpleIcon sx={{ width: 16, height: 16, ml: 1 }} />
                </Link>
                <Typography
                  variant="caption2"
                  component="div"
                  color="text.secondary"
                >
                  {t('dashboardPage.addYourExpMonthlySpendingDescription')}
                </Typography>
              </>
            )}
          </Box>
          {renderFooter()}
        </Paper>
        <TopUpAccountDialog
          open={isTopUpDialogOpen}
          onClose={() => setIsTopUpDialogOpen(false)}
        />
        <ChangeExpectedMonthlySpendDialog
          expectedMonthlySpend={expectedMonthlySpend.value}
          onSuccess={(value) => {
            expectedMonthlySpend.onUpdate(value);
            setIsChangeExpectedMonthlySpendDialogOpen(false);
          }}
          open={isChangeExpectedMonthlySpendDialogOpen}
          onClose={() => setIsChangeExpectedMonthlySpendDialogOpen(false)}
        />
      </>
    );
  }

  return (
    <>
      <Paper
        data-intercom-target="financial-overview-widget"
        variant="outlined"
        sx={{ height: '100%', p: 2 }}
      >
        <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}>
          <Typography variant="subtitle1" flexGrow={1}>
            {t('dashboardPage.availableFundsAndLimit')}
          </Typography>
          {[
            OrganizationProductType.MDESD,
            OrganizationProductType.CCROY,
          ].includes(organization!.productType) &&
            didOrgPassOnboardingRisk && (
              <>
                {nextPaymentDate && (
                  <Typography variant="caption2" color="text.secondary" mx={1}>
                    <Trans
                      i18nKey="dashboardPage.nextPaymentDate"
                      values={{ date: nextPaymentDate }}
                    />
                  </Typography>
                )}
                <Tooltip title={t('dashboardPage.topUpAccountTooltip')}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setIsTopUpDialogOpen(true)}
                    data-intercom-target="top-up-account-button"
                  >
                    {t('dashboardPage.topUpAccount')}
                  </Button>
                </Tooltip>
              </>
            )}
        </Box>
        <Box mb={2}>
          <Typography
            variant="caption2"
            component="div"
            color="text.secondary"
            mb={0.5}
          >
            {t('dashboardPage.available')}
          </Typography>
          <Box display="flex" alignItems="flex-end" mb={1}>
            <Typography
              variant="h5"
              color={
                isCreditOrgAvailableLimitLow(availableLimit, limit)
                  ? 'warning.main'
                  : 'text.primary'
              }
              data-intercom-target="financial-overview-widget-available-limit"
            >
              {didOrgPassOnboardingRisk ? (
                <FormatMoney value={availableLimit} />
              ) : (
                '-'
              )}
            </Typography>
            {didOrgPassOnboardingRisk && !!limit.value && (
              <>
                <Typography
                  variant="caption"
                  mr={0.5}
                  mb="2px"
                  ml={1}
                  color="text.secondary"
                >
                  <Trans
                    i18nKey="dashboardPage.coverage"
                    values={{ value: getCoverage() }}
                  />
                </Typography>
                <Tooltip title={t('dashboardPage.coverageCreditTooltip')}>
                  <InfoIcon
                    fontSize="small"
                    sx={{ mb: '2px', color: 'text.secondary' }}
                  />
                </Tooltip>
              </>
            )}
          </Box>
          <CustomBar
            balance={didOrgPassOnboardingRisk ? balance : null}
            availableLimit={didOrgPassOnboardingRisk ? availableLimit : null}
            limit={didOrgPassOnboardingRisk ? limit : null}
          />
          <Box display="flex" justifyContent="space-between" mt={1}>
            <Box display="flex" alignItems="center">
              <Typography variant="caption2" color="text.secondary">
                {t('dashboardPage.creditLimit', {
                  value: didOrgPassOnboardingRisk
                    ? formatMoney(limit, i18n.language, { currencyDisplay })
                    : '-',
                })}
              </Typography>
              <Tooltip title={t('dashboardPage.creditLimitHint')}>
                <InfoIcon
                  fontSize="small"
                  sx={{
                    width: 16,
                    height: 16,
                    ml: 0.5,
                    color: 'text.secondary',
                  }}
                />
              </Tooltip>
            </Box>
            <Typography
              variant="caption2"
              color="text.secondary"
              data-intercom-target="financial-overview-widget-outstanding-balance"
            >
              {renderBalance()}
            </Typography>
          </Box>
        </Box>
        {renderFooter()}
      </Paper>
      <TopUpAccountDialog
        open={isTopUpDialogOpen}
        onClose={() => setIsTopUpDialogOpen(false)}
      />
    </>
  );
};

export default FinancialOverview;
