import React, { useEffect, useState } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { UpdatedTermsAndConditionsDialog } from 'domains/document/dialogs';
import { OpenActiveOptIn } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';

interface State {
  isLoading: boolean;
  openActiveOptIns: OpenActiveOptIn[];
  legalReps: string[];
  isDialogOpen: boolean;
}

const isExpired = (optIn: OpenActiveOptIn) => {
  const startDate = new Date(optIn.documentVersion);
  startDate.setUTCHours(0, 0, 0, 0);
  return startDate.getTime() < new Date().getTime();
};

const withTermsAndConditions = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => (props: P): JSX.Element | null => {
  const { dispatch, state: globalState } = useGlobalState();
  const canUser = useCanUser();
  const [state, setState] = useState<State>({
    isLoading: true,
    openActiveOptIns: [],
    legalReps: [],
    isDialogOpen: false,
  });
  const api = useImperativeApi();

  const getOpenActiveOptIn = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        isDialogOpen: false,
      }));
      const data = await api.getOpenActiveOptIn();
      const hasExpiredActiveOptIns = data.openActiveOptIns.some(isExpired);
      setState((prevState) => ({
        ...prevState,
        ...data,
        isLoading: false,
        isDialogOpen: canUser(
          'terms-and-conditions-updated-dialog:view',
          data.openActiveOptIns[0],
          hasExpiredActiveOptIns
        ),
      }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      dispatch({ type: 'SET_ERROR', payload: error });
      logError(error);
    }
  };

  useEffect(() => {
    if (globalState.member.id) {
      getOpenActiveOptIn();
    }
  }, [globalState.member]);

  const expiredActiveOptIns = state.openActiveOptIns.filter(isExpired);

  const shouldShowUpdatedTermsAndConditionsDialog = state.isDialogOpen;

  const shouldPreventClose = !!expiredActiveOptIns.length;

  return (
    <>
      <UpdatedTermsAndConditionsDialog
        open={shouldShowUpdatedTermsAndConditionsDialog}
        isLoading={state.isLoading}
        openActiveOptIn={state.openActiveOptIns[0]}
        legalReps={state.legalReps}
        onSuccess={getOpenActiveOptIn}
        onClose={
          shouldPreventClose
            ? undefined
            : () => {
                setState((prevState) => ({
                  ...prevState,
                  isDialogOpen: false,
                }));
              }
        }
      />
      <Component {...props} />
    </>
  );
};

export default withTermsAndConditions;
