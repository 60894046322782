import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney/index';
import FormatPercent from 'components/FormatPercent';
import { Box, LinearProgress, Typography } from 'elements';
import { Money } from 'services/constants';
import {
  getBudgetSpentPercentage,
  getLimitPercentage,
  getMoneyWithOppositeSign,
} from 'services/utils';

interface Props {
  balance: Money;
  budget: Money;
  availableBudget: Money;
}

const TeamBudget = ({ balance, budget, availableBudget }: Props) => {
  const { t } = useTranslation();

  const amountSpent = getMoneyWithOppositeSign(balance);

  const { limitPercentage, isPercentageLow } = getLimitPercentage({
    availableLimit: amountSpent,
    limit: budget,
    basePercentage: 80,
  });

  const budgetSpentPercentage = getBudgetSpentPercentage(
    budget.value,
    amountSpent.value
  );

  return (
    <Box py={3}>
      <Typography variant="subtitle1" mb={1}>
        {t('managerTeamsPage.monthlySpend')}{' '}
        <Typography
          variant="inherit"
          component="span"
          color={isPercentageLow ? 'inherit' : 'warning.main'}
        >
          <Trans
            i18nKey="managerTeamsPage.percentageSpent"
            components={{
              percent: (
                <FormatPercent
                  fractionalPart={false}
                  value={budgetSpentPercentage}
                />
              ),
            }}
          />
        </Typography>
      </Typography>

      <Box display="flex" justifyContent="flex-start">
        <Box width={400}>
          <Typography variant="caption" component="div" color="text.secondary">
            {t('managerTeamsPage.budgetBalance')}
          </Typography>
          <Typography
            variant="h5"
            mb={1}
            color={isPercentageLow ? 'inherit' : 'warning.main'}
          >
            <FormatMoney value={amountSpent} />
          </Typography>
          <LinearProgress
            value={limitPercentage}
            color={isPercentageLow ? 'primary' : 'warning'}
          />
        </Box>

        <Box ml={4} textAlign="right">
          <Typography variant="caption" component="div" color="text.secondary">
            {t('managerTeamsPage.availableBudget')}
          </Typography>
          <Typography variant="h6">
            <FormatMoney value={availableBudget} />
          </Typography>
        </Box>

        <Box ml={4} textAlign="right">
          <Typography variant="caption" component="div" color="text.secondary">
            {t('managerTeamsPage.budget')}
          </Typography>
          <Typography variant="h6">
            <FormatMoney value={budget!} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TeamBudget;
