import { ReactNode } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography } from 'elements';
import { TransactionMerchantInfo } from 'services/constants';
import { getPath } from 'services/utils';

interface Props {
  label: ReactNode;
  orgId: string;
  merchant: TransactionMerchantInfo;
}

const Merchant = ({ label, orgId, merchant }: Props) => {
  return (
    <Paper
      sx={{
        px: 1.5,
        py: 2,
        minHeight: 56,
      }}
      variant="outlined"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">{label}</Typography>

        <Typography
          variant="body2"
          noWrap
          sx={{
            pl: 2,
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          component={Link}
          to={generatePath(getPath('merchantDetails'), {
            orgId,
            merchantId: merchant.id,
          })}
        >
          {merchant.displayName}
        </Typography>
      </Box>
    </Paper>
  );
};

export default Merchant;
