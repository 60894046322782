import { Components, Theme } from '@mui/material';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    'primary-contained': true;
  }
}

const MuiButtonOverrides: Components<Theme> = {
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: 'contained',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.variables.button.shape.borderRadius,
        textTransform: 'none',
      }),
      sizeSmall: ({ theme }) => ({
        ...theme.variables.components['button-small'],
      }),
      sizeMedium: ({ theme }) => ({
        ...theme.variables.components['button-medium'],
      }),
      sizeLarge: ({ theme }) => ({
        ...theme.variables.components['button-large'],
      }),
      containedPrimary: ({ theme }) => ({
        background: theme.variables.button.filled.default,
        color: theme.variables.button.filled.text,
        '&:hover': {
          background: theme.variables.button.filled.hover,
        },
      }),
      outlinedPrimary: ({ theme }) => ({
        borderColor: theme.variables.button.textOutlined.border,
        color: theme.variables.button.textOutlined.text,
        '&:hover': {
          background: theme.variables.button.textOutlined.hover,
          borderColor: theme.variables.button.textOutlined.border,
        },
      }),
      textPrimary: ({ theme }) => ({
        color: theme.variables.button.textOutlined.text,
        '&:hover': {
          background: theme.variables.button.textOutlined.hover,
        },
      }),
    },
  },
  MuiLoadingButton: {
    defaultProps: {
      variant: 'contained',
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        '&.selected': { background: theme.palette.action.selected },

        // MUI icon buttons don't support variants (see https://github.com/mui/material-ui/issues/35456)
        ...(ownerState.color === 'primary-contained' && {
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            background: theme.palette.primary.main,
          },
        }),
      }),
    },
  },
};

export default MuiButtonOverrides;
