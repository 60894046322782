import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { BlackCardsListDialog } from 'domains/card/dialogs';
import { Box, Button, CircularProgress, Grid, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import { ContentContainer, SettingsGroupBox } from 'layout';
import {
  CardCount,
  CardSimpleStatus,
  CardType,
  PremiumCardFeeTier,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getCardCount } from 'services/utils';

interface Props {
  cardCount: CardCount;
}

const AddonsSection = ({ cardCount }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const [isBlackCardsListDialogOpen, setIsBlackCardsListDialogOpen] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(true);
  const [
    premiumCardFeeTier,
    setPremiumCardFeeTier,
  ] = useState<PremiumCardFeeTier | null>(null);

  const getData = async () => {
    try {
      const data = await api.getCurrentPremiumCardFeeTier(organization!.id);
      if (!mounted.current) return;
      setPremiumCardFeeTier(data);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const activeBlackCardCount = getCardCount(cardCount, CardType.black, [
    CardSimpleStatus.active,
  ]);
  const pendingOrRequestedBlackCardCount = getCardCount(
    cardCount,
    CardType.black,
    [CardSimpleStatus.pending, CardSimpleStatus.requested]
  );

  return (
    <>
      <SettingsGroupBox>
        <ContentContainer>
          <Typography variant="h6">
            {t('subscriptionPlanSettingsSubPage.addonsSection.title')}
          </Typography>

          <Grid container mt={3}>
            <Grid item xs={4} pr={2}>
              <Typography variant="overline">
                {t(
                  'subscriptionPlanSettingsSubPage.addonsSection.blackCardsKeyPlan',
                  { cardName: t('cardNames.PLIANT_BLACK') }
                )}
              </Typography>
              <Box display="flex" alignItems="flex-end">
                <Typography variant="h5">{activeBlackCardCount}</Typography>
                <Typography>
                  {pendingOrRequestedBlackCardCount > 0 &&
                    ` (+${pendingOrRequestedBlackCardCount})`}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4} pr={2}>
              <Typography variant="overline">
                {t(
                  'subscriptionPlanSettingsSubPage.addonsSection.totalAnnualBlackCardsFeeKeyPlan'
                )}
              </Typography>
              <Box display="flex" alignItems="flex-end">
                {!isLoading && premiumCardFeeTier && (
                  <Typography variant="h5">
                    <FormatMoney value={premiumCardFeeTier.fee} />
                  </Typography>
                )}

                {!isLoading && !premiumCardFeeTier && (
                  <Typography variant="body2" color="error.main">
                    {t('errors.loadData')}
                  </Typography>
                )}
                {isLoading && (
                  <Box mt={1}>
                    <CircularProgress size="small" />
                  </Box>
                )}
              </Box>
            </Grid>

            {canUser('black-cards-list:view') && (
              <Grid item xs={4} pr={2} pt={1.5}>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => setIsBlackCardsListDialogOpen(true)}
                >
                  {t(
                    'subscriptionPlanSettingsSubPage.addonsSection.viewDetailsBtn'
                  )}
                </Button>
              </Grid>
            )}
          </Grid>
        </ContentContainer>
      </SettingsGroupBox>

      <BlackCardsListDialog
        open={isBlackCardsListDialogOpen}
        onClose={() => setIsBlackCardsListDialogOpen(false)}
      />
    </>
  );
};

export default AddonsSection;
