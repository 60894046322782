import InputBase from '@material-ui/core/InputBase';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import styled from 'styled-components';

export const StyledInputBase = styled(InputBase)`
  & .MuiInputBase-input {
    height: ${({ multiline }) => !multiline && '46px'};
    min-height: ${({ multiline }) => multiline && '46px'};
    padding: 13px 14px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 20px;
    border-radius: 4px;
    border: 1px solid transparent;
    color: ${({ theme }) => theme.colors.grey10};
    background: ${({ theme }) => theme.colors.neutralVariant100};
    transition: border-color 0.2s;

    &.Mui-disabled {
      color: ${({ theme }) => alpha(theme.colors.grey10, 0.5)};
    }

    &.MuiSelect-select {
      padding-right: 40px;

      &[aria-expanded='true'] {
        border-color: ${({ theme }) => theme.colors.grey10};
      }
    }

    &.MuiInputBase-inputAdornedStart {
      padding-left: 48px;
    }

    &.MuiInputBase-inputAdornedEnd {
      padding-right: 40px;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.grey100};
      opacity: 1;
    }
  }

  &.Mui-focused .MuiInputBase-input:not(.MuiSelect-select) {
    border-color: ${({ theme }) => theme.colors.grey10};
  }

  &.Mui-error .MuiInputBase-input {
    background: ${({ theme }) => theme.colors.red100};
  }

  &.MuiInputBase-multiline {
    padding: 0;
  }
`;
