import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, SuitcaseSimpleIcon } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DEFAULT_AUTOCOMPLETE_DELAY,
  PartialOrganizationGroup,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const MIN_ALLOWED_CHARS = 3;

interface Props {
  label: string;
  onChange: (org: PartialOrganizationGroup | null) => void;
  organizationGroupType: string;
  value: PartialOrganizationGroup | null;
}

interface State {
  inputValueOrg: string;
  isLoading: boolean;
  isOpen: boolean;
  searchResults: PartialOrganizationGroup[];
}

const OrganizationGroupAutocompleteSearch = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState<State>({
    inputValueOrg: '',
    isLoading: false,
    isOpen: false,
    searchResults: [],
  });

  const searchOrganizationGroup = async (name: string) => {
    try {
      const {
        organizationGroups: searchResults,
      } = await api.getOrganizationGroups({
        q: name,
        type: props.organizationGroupType,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        searchResults,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isOpen: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    let active = true;

    if (state.inputValueOrg.length < MIN_ALLOWED_CHARS) return;

    setState((prevState) => ({ ...prevState, isLoading: true }));

    setTimeout(() => {
      if (active) searchOrganizationGroup(state.inputValueOrg);
    }, DEFAULT_AUTOCOMPLETE_DELAY);

    return () => {
      active = false;
    };
  }, [state.inputValueOrg]);

  return (
    <Autocomplete<PartialOrganizationGroup, false, false, false>
      inputValue={state.inputValueOrg}
      onInputChange={(e, value) =>
        setState((prevState) => ({
          ...prevState,
          inputValueOrg: value,
        }))
      }
      value={props.value}
      onChange={(e, selectedOrg) => props.onChange(selectedOrg)}
      options={state.searchResults}
      open={state.isOpen}
      onOpen={() =>
        setState((prevState) => ({
          ...prevState,
          isOpen: prevState.inputValueOrg.length >= MIN_ALLOWED_CHARS,
        }))
      }
      onClose={() =>
        setState((prevState) => ({
          ...prevState,
          searchResults: props.value ? prevState.searchResults : [],
          isOpen: false,
        }))
      }
      loading={state.isLoading}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <SuitcaseSimpleIcon />
            <Box ml={2}>{option.name}</Box>
          </li>
        );
      }}
      forcePopupIcon={false}
      label={props.label}
      placeholder={props.label}
      noOptionsText={t('common.nothingFound')}
    />
  );
};

export default OrganizationGroupAutocompleteSearch;
