import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { sortBy } from 'lodash';
import { useGlobalState } from 'context/GlobalState';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Address,
  LegalRepresentativeType,
  Onboarding,
  OnboardingDocument,
  OnboardingDocumentStatus,
  OrganizationRepresentativeAdditionalInfo,
  OrganizationRepresentativeExternalStatus,
  OrganizationStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const sortOrgRepsAdditionalInfo = (
  items: OrganizationRepresentativeAdditionalInfo[]
) =>
  sortBy(items, [
    (item) =>
      ({
        [LegalRepresentativeType.sole]: 1,
        [LegalRepresentativeType.joint]: 2,
        [LegalRepresentativeType.none]: 3,
      }[item.legalRepType]),
    'surName',
    'givenName',
  ]);

type UpdateOnboardingData =
  | Onboarding
  | { vatId: string }
  | { deliveryAddress: Address }
  | { hubspotId: string };

interface OnboardingContextType {
  state: {
    onboarding: Onboarding | null;
    isLoading: boolean;
    isConfirmOrgDetailsLoading: boolean;
    isConfirmTermsAndConditionsLoading: boolean;
    onboardingDocuments: OnboardingDocument[];
    orgRepsAdditionalInfo: OrganizationRepresentativeAdditionalInfo[];
    hasOpenQaRequests: boolean;
    vatIdRequired: boolean;
  };
  actions: {
    updateOnboarding: (
      data: UpdateOnboardingData,
      vatIdRequired?: boolean
    ) => void;
    confirmOrgDetails: () => void;
    confirmTermsAndConditions: () => void;
    fetchOnboardingDocuments: () => Promise<void>;
    fetchOrganizationAddtionalInfo: () => Promise<void>;
    setOnboardingDocuments: (
      updatedOnboardingDocuments: OnboardingDocument[]
    ) => void;
    updateOrgRepAdditionalInfo: (
      updatedOrgRepAdditionalInfo: OrganizationRepresentativeAdditionalInfo
    ) => void;
    setOrgRepsAdditionalInfo: (
      updatedOrgRepsAdditionalInfos: OrganizationRepresentativeAdditionalInfo[]
    ) => void;
  };
}

const OnboardingContext = createContext<OnboardingContextType>({
  actions: {} as OnboardingContextType['actions'],
  state: {
    onboarding: null,
    isLoading: true,
    isConfirmOrgDetailsLoading: false,
    isConfirmTermsAndConditionsLoading: false,
    onboardingDocuments: [],
    orgRepsAdditionalInfo: [],
    hasOpenQaRequests: false,
    vatIdRequired: false,
  },
});
OnboardingContext.displayName = 'OnboardingContext';

interface OnboardingProviderProps {
  children: React.ReactNode;
}

interface State {
  onboarding: Onboarding | null;
  isLoading: boolean;
  isConfirmOrgDetailsLoading: boolean;
  isConfirmTermsAndConditionsLoading: boolean;
  onboardingDocuments: OnboardingDocument[];
  orgRepsAdditionalInfo: OrganizationRepresentativeAdditionalInfo[];
  vatIdRequired: boolean;
}

const OnboardingContextProvider = ({ children }: OnboardingProviderProps) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
    dispatch,
  } = useGlobalState();
  const organizationId = organization!.id;

  const [onboardingState, setOnboardingState] = useState<State>({
    onboarding: null,
    isLoading: true,
    isConfirmOrgDetailsLoading: false,
    isConfirmTermsAndConditionsLoading: false,
    onboardingDocuments: [],
    orgRepsAdditionalInfo: [],
    vatIdRequired: false,
  });

  const getData = useCallback(async () => {
    try {
      setOnboardingState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const [
        onboardingResponse,
        { onboardingDocuments },
        orgRepsAdditionalInfo,
        { vatIdRequired },
      ] = await Promise.all([
        api.getOrganizationOnboardingDetails(organizationId),
        api.getOnboardingDocuments(organizationId),
        api.getOrganizationRepresentativesAdditionalInfo(organizationId),
        api.getAdditionalInformations(organizationId),
      ]);
      if (!mounted.current) return;
      setOnboardingState((prevState) => ({
        ...prevState,
        onboarding: onboardingResponse,
        onboardingDocuments,
        orgRepsAdditionalInfo: sortOrgRepsAdditionalInfo(orgRepsAdditionalInfo),
        vatIdRequired,
        isLoading: false,
      }));
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error });
      logError(error);
    }
  }, [organizationId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const confirmOrgDetails = useCallback(async () => {
    setOnboardingState((prevState) => ({
      ...prevState,
      isConfirmOrgDetailsLoading: true,
    }));
    try {
      const confirmOrgDetailsResponse = await api.confirmOrganizationDetails(
        organizationId
      );
      if (!mounted.current) return;
      setOnboardingState((prevState) => ({
        ...prevState,
        onboarding: confirmOrgDetailsResponse,
        isConfirmOrgDetailsLoading: false,
      }));
      updateOrgStatus(confirmOrgDetailsResponse.status);
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error });
      logError(error);
    }
  }, [organizationId]);

  const confirmTermsAndConditions = useCallback(async () => {
    setOnboardingState((prevState) => ({
      ...prevState,
      isConfirmTermsAndConditionsLoading: true,
    }));
    try {
      const confirmTermsAndConditionsResponse = await api.confirmTermsAndConditions(
        organizationId
      );
      if (!mounted.current) return;
      setOnboardingState((prevState) => ({
        ...prevState,
        onboarding: confirmTermsAndConditionsResponse,
        isConfirmTermsAndConditionsLoading: false,
      }));
      updateOrgStatus(confirmTermsAndConditionsResponse.status);
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error });
      logError(error);
    }
  }, [organizationId]);

  const updateOrgStatus = useCallback(
    (status: OrganizationStatus) =>
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { organization: { ...organization!, status } },
      }),
    [organization]
  );

  const updateOnboarding = useCallback(
    (data: UpdateOnboardingData, vatIdRequired?: boolean) => {
      setOnboardingState((prevState) => ({
        ...prevState,
        onboarding: { ...prevState.onboarding, ...data } as Onboarding,
        ...(typeof vatIdRequired === 'boolean' && { vatIdRequired }),
      }));
      if ('status' in data) updateOrgStatus(data.status);
    },
    []
  );

  const updateOrgRepAdditionalInfo = useCallback(
    (updatedOrgRepAdditionalInfo: OrganizationRepresentativeAdditionalInfo) =>
      setOnboardingState((prevState) => ({
        ...prevState,
        orgRepsAdditionalInfo: prevState.orgRepsAdditionalInfo.map((item) =>
          item.representativeId !== updatedOrgRepAdditionalInfo.representativeId
            ? item
            : updatedOrgRepAdditionalInfo
        ),
      })),
    []
  );

  const setOrgRepsAdditionalInfo = useCallback(
    (
      updatedOrgRepsAdditionalInfos: OrganizationRepresentativeAdditionalInfo[]
    ) =>
      setOnboardingState((prevState) => ({
        ...prevState,
        orgRepsAdditionalInfo: sortOrgRepsAdditionalInfo(
          updatedOrgRepsAdditionalInfos
        ),
      })),
    []
  );

  const setOnboardingDocuments = useCallback(
    (updatedOnboardingDocuments: OnboardingDocument[]) => {
      setOnboardingState((prevState) => ({
        ...prevState,
        onboardingDocuments: updatedOnboardingDocuments,
      }));
    },
    []
  );

  const fetchOrganizationAddtionalInfo = useCallback(async () => {
    try {
      const { vatIdRequired } = await api.getAdditionalInformations(
        organizationId
      );
      if (!mounted.current) return;
      setOnboardingState((prevState) => ({
        ...prevState,
        vatIdRequired,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  }, [organizationId]);

  const fetchOnboardingDocuments = useCallback(async () => {
    try {
      const { onboardingDocuments } = await api.getOnboardingDocuments(
        organizationId
      );
      if (!mounted.current) return;
      setOnboardingState((prevState) => ({
        ...prevState,
        onboardingDocuments: onboardingDocuments,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  }, [organizationId]);

  const value = useMemo(
    () => ({
      actions: {
        confirmOrgDetails,
        confirmTermsAndConditions,
        fetchOnboardingDocuments,
        fetchOrganizationAddtionalInfo,
        updateOnboarding,
        updateOrgRepAdditionalInfo,
        setOnboardingDocuments,
        setOrgRepsAdditionalInfo,
      },
      state: {
        ...onboardingState,
        hasOpenQaRequests: !!(
          onboardingState.onboardingDocuments.find(
            (doc) => doc.status === OnboardingDocumentStatus.REQUESTED
          ) ||
          onboardingState.orgRepsAdditionalInfo.find((rep) =>
            [
              OrganizationRepresentativeExternalStatus.infoRequested,
              OrganizationRepresentativeExternalStatus.infoFilled,
            ].includes(rep.externalStatus)
          )
        ),
      },
    }),
    [onboardingState]
  );

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};

const useOnboardingContext = () => {
  const context = useContext(OnboardingContext);
  if (context === null) {
    throw new Error(
      `useOnboardingContext must be used within a OnboardingContextProvider`
    );
  }
  return context;
};

const withOnboardingContext = (Component: React.ComponentType) => () => {
  return (
    <OnboardingContextProvider>
      <Component />
    </OnboardingContextProvider>
  );
};

export { useOnboardingContext, withOnboardingContext };
