import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  LoaderWithOverlay,
  Typography,
  VerificationCodeField,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card, NetworkErrorCode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import ChangePinSuccessStep from './ChangePinSuccessStep';

interface State {
  step: 'changePin' | 'changePinSuccess';
  isAtmExplanationOpen: boolean;
  newPin: string;
  isLoading: boolean;
  errorMessage: string;
}

interface Props {
  onClose: () => void;
  card: Card;
}

export const ChangePinStep = ({ onClose, card }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    step: 'changePin',
    isAtmExplanationOpen: false,
    newPin: '',
    isLoading: false,
    errorMessage: '',
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      await api.updateCardPin(card.cardId, state.newPin);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        step: 'changePinSuccess',
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      const errorCode = getNetworkErrorCode(error);
      if (errorCode === NetworkErrorCode.hasSequentialNumbers) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          errorMessage: t('pinDialog.sequentialNumbersError'),
        }));
        return;
      }
      if (errorCode === NetworkErrorCode.hasRepeatingNumbers) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          errorMessage: t('pinDialog.repeatingNumbersError'),
        }));
        return;
      }
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  return state.step === 'changePin' ? (
    <>
      <DialogTitle>{t('pinDialog.changePin')}</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit} id="change-pin-form" noValidate>
          <Typography variant="body2">
            {t('pinDialog.chooseSecurePinHint')}
          </Typography>
          <Box mt={4} mb={4}>
            <Alert severity="warning">
              {t('pinDialog.confirmChangeAtAtmWarning')}
              <br />
              <Link
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isAtmExplanationOpen: !prevState.isAtmExplanationOpen,
                  }))
                }
                color="inherit"
                component="button"
                type="button"
              >
                {state.isAtmExplanationOpen
                  ? t('common.button.close')
                  : t('pinDialog.learnMore')}
              </Link>
              {state.isAtmExplanationOpen && (
                <>
                  <br />
                  <br />
                  {t('pinDialog.confirmChangeAtAtmExplanation')}
                </>
              )}
            </Alert>
          </Box>
          <VerificationCodeField
            onChange={(val) =>
              setState((prevState) => ({ ...prevState, newPin: val }))
            }
            disabled={state.isLoading}
            error={!!state.errorMessage}
            helperText={state.errorMessage}
            fields={4}
            autoFocus
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={state.newPin.length < 4 || state.isLoading}
          form="change-pin-form"
          type="submit"
        >
          {t('pinDialog.changePin')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </>
  ) : (
    <ChangePinSuccessStep onClose={onClose} />
  );
};
