import React from 'react';
import MuiFormControl from '@material-ui/core/FormControl';
import { InputLabelProps } from '@material-ui/core/InputLabel/InputLabel';
import MuiSelect, {
  SelectProps as MuiSelectProps,
} from '@material-ui/core/Select';
import styled from 'styled-components';
import { ReactComponent as ArrowsButtonDownIcon } from 'assets/icons/interface/arrowsButtonDown.svg';
import { StyledInputBase } from './StyledInputBase';
import { StyledInputLabel } from './StyledInputLabel';

const StyledArrowsButtonDownIcon = styled(ArrowsButtonDownIcon)`
  top: calc(50% - 8px);
  right: 16px;
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.colors.grey10};
`;

const StyledSelect = styled(
  ({ className, MenuProps, ...props }: MuiSelectProps) => (
    <MuiSelect
      input={<StyledInputBase />}
      labelId={`${props.id ?? props.name}-label`}
      inputProps={{
        id: props.id ?? props.name,
      }}
      IconComponent={props.disabled ? () => null : StyledArrowsButtonDownIcon}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        classes: { paper: className },
        ...MenuProps,
      }}
      {...props}
    />
  )
)`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);

  & .MuiMenu-list.MuiList-padding {
    padding: 5px 0;
  }

  & .MuiListItem-root {
    padding: 3px 13px;
    margin: 6px 11px;
    border-radius: 6px;

    &.Mui-selected {
      background: ${({ theme }) => theme.colors.neutralVariant80};
    }

    &:hover,
    &.Mui-selected:hover {
      background: ${({ theme }) => theme.colors.neutralVariant80};
    }
  }

  .MuiTypography-root {
    font-size: 14px;
    line-height: 24px;
  }
`;

export interface SelectProps extends MuiSelectProps {
  label?: string;
  error?: boolean;
  fullWidth?: boolean;
  inputLabelProps?: InputLabelProps;
}

const FormControl = styled(MuiFormControl)`
  &.readonly .MuiSelect-select {
    cursor: default;
  }
`;

export const Select = ({
  label,
  error,
  className,
  children,
  fullWidth = true,
  inputLabelProps,
  ...props
}: SelectProps) => {
  return (
    <FormControl fullWidth={fullWidth} error={error} className={className}>
      {label && (
        <StyledInputLabel
          id={`${props.id ?? props.name}-label`}
          shrink={false}
          {...inputLabelProps}
        >
          {label}
        </StyledInputLabel>
      )}
      <StyledSelect {...props}>{children}</StyledSelect>
    </FormControl>
  );
};

Select.defaultProps = {
  renderValue: (selected: unknown) => selected as string,
};
