import { styled } from '@mui/material';
import { Box } from 'elements';

export const WeekButton = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacing(3)};
  height: ${({ theme }) => theme.spacing(3)};
  margin-right: ${({ theme }) => theme.spacing(1)};
  border-radius: 100%;
  cursor: pointer;

  &:hover,
  &.active {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.common.white};
  }
`;
