import React from 'react';
import MuiPopover, {
  PopoverProps as MuiPopoverProps,
} from '@material-ui/core/Popover/Popover';
import styled from 'styled-components';

interface PopoverExtraProps {
  $borderRadius?: number;
}

const Popover = styled((props: MuiPopoverProps) => (
  <MuiPopover
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))<PopoverExtraProps>`
  & .MuiPopover-paper {
    border-radius: ${({ $borderRadius }) => $borderRadius || 6}px;
    border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
  }
`;

export default Popover;
