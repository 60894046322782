import React, { useEffect, useMemo } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import flowRight from 'lodash/flowRight';
import { generatePath } from 'react-router';
import { Container, ContainerInner, MainContent } from 'components/App/style';
import withLoader from 'components/App/withLoader';
import { InternalAdminMainHeader } from 'components/MainHeader';
import Sidebar from 'components/Sidebar';
import { useGlobalState } from 'context/GlobalState';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { OrganizationStatus } from 'services/constants';
import { withFeatureFlagsProvider } from 'services/featureflags';
import { loadInternalTranslations } from 'services/i18n';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { withJwtPayload } from 'services/rbac';
import { internalOrgPaths, InternalOrgPathsKeys } from './paths';
import useToggleSidebar from './useToggleSidebar';
import withInitialLogic from './withInitialLogic';

const SIDEBAR_TABS: InternalOrgPathsKeys[] = [
  'dashboard',
  'cardAccounts',
  'cards',
  'transactions',
  'transactionsReview',
  'transactionsFlagged',
  'accounting',
  'billingPayments',
  'billingStatements',
  'creditAndCompliance',
  'members',
  'teams',
  'rewards',
  'merchants',
  'pliantEarth',
  'export',
  'exportQueue',
  'exported',
  'settlements',
  'termsGeneral',
  'termsSubscriptionSettings',
  'termsCashbackSettings',
  'processingAccount',
  'settings',
  'settingsIntegrations',
  'settingsFeatureModules',
  'settingsOrganization',
  'settingsAccounting',
  'partner',
];
const NCO_SIDEBAR_TABS: InternalOrgPathsKeys[] = [
  'settings',
  'settingsOrganization',
];

const UserDataAndLayout = ({ children }: { children: React.ReactNode }) => {
  const {
    dispatch,
    state: { isLoading, organization },
  } = useGlobalState();
  const api = useImperativeApi();
  const isOrgInOnboarding = useIsOrgInOnboarding();

  useToggleSidebar();

  const getData = async () => {
    try {
      await loadInternalTranslations();
      const [
        { cardDesigns },
        { partners },
        { legalForms: platformLegalForms },
        organizationsStaticData,
        { currencies: cardAccountCurrencies },
      ] = await Promise.all([
        api.getCardDesigns().catch((error) => {
          logError(error);
          return { cardDesigns: [] };
        }),
        api.getPartners(),
        api.getPlatformLegalForms(),
        api.getOrganizationsStaticData(),
        api.getCardAccountCurrencies(),
      ]);
      dispatch({
        type: 'SET_CARD_DESIGNS',
        payload: { cardDesigns },
      });
      dispatch({
        type: 'SET_PARTNER_DATA',
        payload: { partners },
      });
      dispatch({
        type: 'SET_USER_DATA',
        payload: { platformLegalForms },
      });
      dispatch({
        type: 'SET_ORGANIZATIONS_STATIC_DATA',
        payload: { organizationsStaticData },
      });
      dispatch({
        type: 'SET_CARD_ACCOUNT_CURRENCIES',
        payload: { cardAccountCurrencies },
      });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error });
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const sidebarPaths = useMemo(() => {
    if (organization) {
      const sidebarPaths: { [pathId: string]: string } = {};
      if (organization.status === OrganizationStatus.nonCustomer) {
        NCO_SIDEBAR_TABS.forEach((key) => {
          sidebarPaths[key] = generatePath(internalOrgPaths[key], {
            orgId: organization.id,
          });
        });
        return sidebarPaths;
      }

      if (
        isOrgInOnboarding ||
        organization!.status === OrganizationStatus.cancelled
      ) {
        sidebarPaths.onboarding = generatePath(internalOrgPaths.onboarding, {
          orgId: organization.id,
        });
      }
      SIDEBAR_TABS.forEach((key) => {
        sidebarPaths[key] = generatePath(internalOrgPaths[key], {
          orgId: organization.id,
        });
      });
      return sidebarPaths;
    } else {
      return {};
    }
  }, [organization]);

  if (isLoading) return null;

  return (
    <Container>
      <Sidebar paths={sidebarPaths} showOnboarding />
      <ContainerInner>
        <InternalAdminMainHeader />
        <MainContent>{children}</MainContent>
      </ContainerInner>
    </Container>
  );
};

export default flowRight([
  withLoader,
  withAuthenticationRequired,
  withInitialLogic,
  withFeatureFlagsProvider,
  withJwtPayload,
])(UserDataAndLayout);
