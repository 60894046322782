import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { generatePath, Link, useLocation } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  ActiveFilterValue,
  StatusFilterSelect,
} from 'domains/settings/components';
import {
  CodatSyncSeparatedDialog,
  SyncNameEnum,
} from 'domains/settings/dialogs';
import {
  FiltersContainer,
  HeaderContainer,
  HeaderTitle,
} from 'domains/settings/layout';
import CostCenterUnitsSelect from 'domains/settings/pages/AccountingPage/CostCenterUnitsSelect';
import withTabPermission from 'domains/settings/pages/AccountingPage/withTabPermission';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import {
  Badge,
  Box,
  Button,
  DataGrid,
  GridSortModel,
  gridUtils,
  LoaderWithOverlay,
  Typography,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, PageSearchInput } from 'layout';
import {
  CodatMappingOptionsSummary,
  DEFAULT_PAGE_LIMIT,
  FeatureModuleKey,
  Team,
  TeamStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import useCanUser from 'services/rbac/useCanUser';
import { getGenericErrorMsg, getPath } from 'services/utils';
import AssignCostCenterDialog from './AssignCostCenterDialog';
import useColumns from './useColumns';

const INITIAL_SORT = '+name';

interface State {
  teams: Team[];
  hasNextPage: boolean;
  isLoading: boolean;
  activeFilter: ActiveFilterValue | '';
  isAssignCostCenterDialogOpen: boolean;
  isEditTeamDialogOpen: boolean;
  editableTeam: Team | null;
  codatMappingOptionsSummary: CodatMappingOptionsSummary | null;
  codatSyncDialogOpen: boolean;
  search: string;
  sort: string;
}

const TeamsSubPage = () => {
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const history = useHistory();
  const location = useLocation<{ isCodatFlow: boolean }>();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    teams: [],
    hasNextPage: false,
    isLoading: true,
    activeFilter: TeamStatus.active,
    isAssignCostCenterDialogOpen: false,
    isEditTeamDialogOpen: false,
    editableTeam: null,
    codatMappingOptionsSummary: null,
    codatSyncDialogOpen: false,
    search: '',
    sort: INITIAL_SORT,
  });
  const isCodatConnected = isCodatAccSystemConnected(accountingSettings);
  const areItemsReadOnly = isCodatConnected;
  const isAssignButtonVisible =
    canUser('team-cost-center:assign') &&
    !!state.teams.length &&
    !areItemsReadOnly;

  const columns = useColumns((item) =>
    setState((prevState) => ({
      ...prevState,
      isEditTeamDialogOpen: true,
      editableTeam: item,
    }))
  );

  useEffect(() => {
    if (location.state?.isCodatFlow && canUser('codat-sync:change')) {
      setState((prevState) => ({
        ...prevState,
        codatSyncDialogOpen: true,
      }));
      history.replace({ state: null });
    }
  }, [location.state]);

  const getData = async (isLoadMore = false, fetchCodatSummary = false) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [
        { teams, hasNextPage },
        codatMappingOptionsSummary,
      ] = await Promise.all([
        api.getTeams({
          organizationId: organization!.id,
          page: pageRef.current,
          limit: DEFAULT_PAGE_LIMIT,
          q: state.search || undefined,
          sortBy: state.sort,
          status: (state.activeFilter as TeamStatus) || undefined,
        }),
        fetchCodatSummary && isCodatConnected && canUser('codat-sync:view')
          ? api.getCodatMappingOptionsSummary(organization!.id)
          : state.codatMappingOptionsSummary,
      ]);

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        teams: isLoadMore ? [...prevState.teams, ...teams] : teams,
        hasNextPage,
        codatMappingOptionsSummary,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    pageRef.current = 0;
    getData(false, !state.codatMappingOptionsSummary);
  }, [state.activeFilter, state.search, state.sort]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(true);
  };

  const handleSortModelChange = (sort: GridSortModel) => {
    if (state.isLoading || !state.teams.length) return;
    setState((prevState) => ({
      ...prevState,
      sort: gridUtils.getNewSortParam(sort) || INITIAL_SORT,
    }));
  };

  const renderTitle = () => {
    if (isCodatConnected)
      return (
        <Trans
          i18nKey="constCenterSubPage.codatTitle"
          values={{ name: accountingSettings!.accountingSystemName }}
          components={{
            a: (
              <Link
                to={generatePath(getPath('settingsAccountingSystem'), {
                  orgId: organization!.id,
                })}
              />
            ),
          }}
        />
      );
    return (
      <Trans
        i18nKey="constCenterSubPage.title"
        components={{
          div: <div />,
          linkTo: (
            <Link
              to={generatePath(getPath('teams'), {
                orgId: organization!.id,
              })}
            />
          ),
        }}
      />
    );
  };

  const renderCustomButton = () => {
    if (isCodatConnected && state.codatMappingOptionsSummary) {
      if (!state.codatMappingOptionsSummary.lastSynced) {
        return (
          <Button
            sx={{ ml: 1 }}
            variant={isAssignButtonVisible ? 'outlined' : 'contained'}
            onClick={() =>
              history.push(
                generatePath(getPath('settingsAccountingSystem'), {
                  orgId: organization!.id,
                })
              )
            }
          >
            {t('constCenterSubPage.setupSyncBtn')}
          </Button>
        );
      }
      return (
        <Badge
          color="secondary"
          badgeContent={
            state.codatMappingOptionsSummary?.trackingCategories.newItems
          }
        >
          <Button
            sx={{ ml: 1 }}
            variant={isAssignButtonVisible ? 'outlined' : 'contained'}
            disabled={!canUser('codat-sync:change')}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                codatSyncDialogOpen: true,
              }))
            }
          >
            {t('constCenterSubPage.editAccSyncBtn')}
          </Button>
        </Badge>
      );
    }
    return null;
  };

  return (
    <>
      <HeaderContainer>
        <ContentContainer>
          <HeaderTitle>
            {t('constCenterSubPage.mainTitle')}

            {state.codatMappingOptionsSummary?.lastSynced && (
              <Typography
                color="textSecondary"
                variant="body2"
                component="span"
                ml={1}
              >
                (
                {t('constCenterSubPage.lastSync', {
                  date: moment(
                    state.codatMappingOptionsSummary.lastSynced
                  ).format('DD MMM YYYY, HH:mm'),
                })}
                )
              </Typography>
            )}
          </HeaderTitle>

          <Typography>{renderTitle()}</Typography>
        </ContentContainer>
      </HeaderContainer>

      <FiltersContainer>
        <CostCenterUnitsSelect />

        <PageSearchInput
          sx={{ mx: 2 }}
          initialValue={state.search}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, search: value }))
          }
          disabled={!state.search && !state.teams.length}
        />

        <StatusFilterSelect
          teamsPage
          filterValue={state.activeFilter}
          disabled={state.isLoading}
          onFilterChange={(activeFilter) =>
            setState((prevState) => ({
              ...prevState,
              activeFilter,
            }))
          }
        />

        <Box ml="auto">
          {isAssignButtonVisible && (
            <Button
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  isAssignCostCenterDialogOpen: true,
                }))
              }
            >
              {t('constCenterSubPage.addButtonTitle')}
            </Button>
          )}

          {renderCustomButton()}
        </Box>
      </FiltersContainer>

      <Box
        position="relative"
        flex={1}
        maxHeight={(theme) => `calc(100% + ${theme.spacing(5)})`}
      >
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid
          apiRef={dataGridRef}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          loading={state.isLoading}
          rows={state.teams}
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(INITIAL_SORT),
            },
          }}
          onSortModelChange={handleSortModelChange}
          columns={columns}
          columnVisibilityModel={{
            actions: !areItemsReadOnly,
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          slots={{
            noRowsOverlay: () => <NothingFound $top={0} $bottom={0} />,
            loadingOverlay: () => null,
          }}
        />
      </Box>

      <AssignCostCenterDialog
        isEditMode={state.isEditTeamDialogOpen}
        open={state.isAssignCostCenterDialogOpen || state.isEditTeamDialogOpen}
        team={state.editableTeam}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isAssignCostCenterDialogOpen: false,
            isEditTeamDialogOpen: false,
            editableTeam: null,
          }))
        }
        onSuccess={() => {
          setState((prevState) => ({
            ...prevState,
            isAssignCostCenterDialogOpen: false,
            isEditTeamDialogOpen: false,
            editableTeam: null,
          }));
          pageRef.current = 0;
          getData();
        }}
      />

      <CodatSyncSeparatedDialog
        name={SyncNameEnum.trackingCategories}
        open={state.codatSyncDialogOpen}
        onSuccess={() => {
          pageRef.current = 0;
          getData(false, true);
        }}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            codatSyncDialogOpen: false,
          }));
        }}
      />
    </>
  );
};

export default withPageConfig(
  withTabPermission(TeamsSubPage, 'costCenterEnabled'),
  {
    permission: 'teams-page:visit',
    featureModule: FeatureModuleKey.teams,
  }
);
