import moment from 'moment';

/* external kyb data sources provide different formats, BE stores those dates untouched as string */
export const parseAndReformatDateStr = (
  dateStr: string,
  outputFormat: string = 'YYYY-MM-DD'
) => {
  let parsingDateFormat = 'YYYY-MM-DD';
  if (/^\d\d\.\d\d\.\d\d\d\d$/.test(dateStr)) {
    parsingDateFormat = 'DD.MM.YYYY';
  }
  if (/^\d\d\.\d\d\.\d\d$/.test(dateStr)) {
    parsingDateFormat = 'DD.MM.YY';
  }

  const parsedDate = moment(dateStr, parsingDateFormat, true).format(
    outputFormat
  );

  return parsedDate === 'Invalid date' ? '' : parsedDate;
};
