import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from 'elements';
import { Member, MemberDetails } from 'services/constants';

interface Props extends Omit<SelectProps, 'renderValue' | 'labelId'> {
  selectedMember: Member | MemberDetails;
  label: string;
}

const TeamSelect = ({ selectedMember, label, disabled, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id="card-team-select-label">{label}</InputLabel>
      <Select
        {...props}
        renderValue={(selected) => {
          const team = selectedMember.teams.find(
            (item) => item.teamId === (selected as string)
          );
          return team?.name;
        }}
        labelId="card-team-select-label"
      >
        <MenuItem value="">
          {t('issueCardDialog_v2.attributesStep.noneTeam')}
        </MenuItem>
        {selectedMember.teams
          ? selectedMember.teams.map((item) => (
              <MenuItem key={item.teamId} value={item.teamId}>
                {item.name}
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  );
};

export default TeamSelect;
