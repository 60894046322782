import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import {
  ActionBox,
  ActionBoxActions,
  ActionBoxTitle,
  ArrowSquareOutIcon,
  Button,
} from 'elements';
import { TransactionExportStatus } from 'services/constants';
import { getPath } from 'services/utils';

interface Props {
  transactionId: string;
  onChangeExportStatus: (
    transactionId: string,
    newExportStatus: TransactionExportStatus
  ) => void;
}

const ExportTransaction = ({ transactionId, onChangeExportStatus }: Props) => {
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const exportStatus = useMemo(() => {
    switch (routeMatch?.path) {
      case getPath('exportTransactionDetails'):
        return TransactionExportStatus.notExported;
      case getPath('exportQueueTransactionDetails'):
        return TransactionExportStatus.inExportQueue;
      case getPath('exportedTransactionDetails'):
        return TransactionExportStatus.exported;
      default:
        return null;
    }
  }, [routeMatch?.path]);

  if (!exportStatus) return null;

  return (
    <ActionBox
      icon={<ArrowSquareOutIcon sx={{ width: 22, height: 22 }} />}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <ActionBoxTitle>
        {t(`transactionDetails.exportTransactions.${exportStatus}.title`)}
      </ActionBoxTitle>
      {t(`transactionDetails.exportTransactions.${exportStatus}.description`)}
      <ActionBoxActions>
        {exportStatus === TransactionExportStatus.notExported && (
          <Button
            onClick={() =>
              onChangeExportStatus(
                transactionId,
                TransactionExportStatus.inExportQueue
              )
            }
          >
            {t(
              `transactionDetails.exportTransactions.${exportStatus}.addToExportBtn`
            )}
          </Button>
        )}
        {exportStatus === TransactionExportStatus.inExportQueue && (
          <>
            <Button
              variant="outlined"
              onClick={() =>
                onChangeExportStatus(
                  transactionId,
                  TransactionExportStatus.notExported
                )
              }
            >
              {t(
                `transactionDetails.exportTransactions.${exportStatus}.rmFromExportBtn`
              )}
            </Button>
            <Button
              startIcon={<ArrowSquareOutIcon />}
              onClick={() =>
                onChangeExportStatus(
                  transactionId,
                  TransactionExportStatus.exported
                )
              }
            >
              {t(
                `transactionDetails.exportTransactions.${exportStatus}.exportBtn`
              )}
            </Button>
          </>
        )}
        {exportStatus === TransactionExportStatus.exported && (
          <Button
            onClick={() =>
              onChangeExportStatus(
                transactionId,
                TransactionExportStatus.exported
              )
            }
          >
            {t(
              `transactionDetails.exportTransactions.${exportStatus}.exportBtn`
            )}
          </Button>
        )}
      </ActionBoxActions>
    </ActionBox>
  );
};

export default ExportTransaction;
