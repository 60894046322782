import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Divider, Grid, Typography } from 'elements';
import { ContentContainer } from 'layout';
import { OrganizationAccountType } from 'services/constants';
import { useCanUser } from 'services/rbac';
import CanSetBillingAccountAsPrefundGroup from './CanSetBillingAccountAsPrefundGroup';
import MainCardAccountAccountGroupGroup from './MainCardAccountAccountGroupGroup';
import OrganizationToHubspotLinkFormGroup from './OrganizationToHubspotLinkFormGroup';

const OnboardingSettingsSection = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { defaultCardAccount },
  } = useGlobalState();

  if (
    !canUser('card-account-account-group:change') &&
    !canUser('org-hubspot-id:view') &&
    !canUser('org-additional-info:view')
  )
    return null;

  return (
    <>
      <ContentContainer py={5}>
        <Typography variant="h6" mb={1}>
          {t('int.generalTermsPage.onboardingSettingsSection.title')}
        </Typography>

        <Grid container columnSpacing={4} rowSpacing={3}>
          {canUser('card-account-account-group:change') && (
            <MainCardAccountAccountGroupGroup />
          )}

          {canUser('org-hubspot-id:view') && (
            <OrganizationToHubspotLinkFormGroup />
          )}

          {canUser('org-additional-info:view') &&
            defaultCardAccount!.accountType.value ===
              OrganizationAccountType.prefunded && (
              <CanSetBillingAccountAsPrefundGroup />
            )}
        </Grid>
      </ContentContainer>

      <Divider />
    </>
  );
};

export default OnboardingSettingsSection;
