import { MerchantCategoryIcon } from 'domains/merchant/components';
import { Avatar, AvatarProps } from 'elements';
import { MerchantCategory } from 'services/constants';

interface Props extends AvatarProps {
  category: MerchantCategory;
  size?: 'large' | 'medium';
}

export const MerchantAvatar = ({
  category,
  size = 'medium',
  sx = [],
  ...props
}: Props) => {
  return (
    <Avatar
      variant="square"
      sx={[
        {
          background: 'none',
          ...(size === 'large'
            ? {
                height: 80,
                width: 80,
                borderRadius: 2,
                color: 'text.primary',
                border: (theme) =>
                  `1px solid ${theme.variables.input.outlined.enabledBorder}`,
              }
            : {
                height: 24,
                width: 24,
                color: 'action.active',
              }),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <MerchantCategoryIcon
        category={category}
        sx={{ fontSize: size === 'large' ? 48 : 24 }}
      />
    </Avatar>
  );
};
