import { Components, Theme } from '@mui/material';

const MuiTooltipOverrides: Components<Theme> = {
  MuiTooltip: {
    defaultProps: { arrow: true },
    styleOverrides: {
      tooltip: ({ theme }) => ({
        padding: theme.spacing(1, 1.5),
        backgroundColor: theme.variables.tooltip.fill,
        ...theme.variables.components['tooltip-label'],
      }),
      arrow: ({ theme }) => ({
        color: theme.variables.tooltip.fill,
      }),
    },
  },
};

export default MuiTooltipOverrides;
