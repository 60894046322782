import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { getDocumentUrl } from 'domains/document/utils';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  GlobeHemisphereWestIcon,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { FeatureModuleKey } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, mapFeatureModules } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

const PliantEarthActivationConfirmationDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [tacChecked, setTacChecked] = useState(false);
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();

  const activate = async () => {
    try {
      setIsLoading(true);
      const data = await api.toggleFeatureModule(organization!.id, {
        feature: FeatureModuleKey.pliantEarth,
        enabled: true,
      });
      dispatch({
        type: 'SET_FEATURE_MODULES',
        payload: mapFeatureModules(data),
      });
      if (!mounted.current) return;
      setIsLoading(false);
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        <Trans
          i18nKey="manageFeatureModuleDialog.activateConfirmationTitle"
          values={{
            featureModuleName: t('featureModuleNames.PLIANT_EARTH'),
          }}
        />
      </DialogTitle>

      <DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          mt={3}
          mb={2}
          sx={(theme) => ({
            fontSize: theme.typography.fontSize * 4,
          })}
        >
          <GlobeHemisphereWestIcon fontSize="inherit" color="info" />
        </Box>

        <Typography align="center" mb={6}>
          {t(
            'manageFeatureModuleDialog.pliantEarthActivationConfirmationDescription'
          )}
        </Typography>

        <FormControlLabel
          checked={tacChecked}
          disabled={isLoading}
          onChange={() => setTacChecked((prevState) => !prevState)}
          control={<Checkbox />}
          label={
            <Trans
              i18nKey={
                'manageFeatureModuleDialog.pliantEarthActivationConfirmationCheckboxLabel'
              }
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={getDocumentUrl('PLIANT_EARTH_TC.pdf')}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          }
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose}>{t('common.button.close')}</Button>
        <Button disabled={!tacChecked} onClick={activate}>
          {t('manageFeatureModuleDialog.activate')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={isLoading} />
    </Dialog>
  );
};

export default PliantEarthActivationConfirmationDialog;
