import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { Button } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { NetworkErrorCode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';

interface Props {
  fileName: string;
  onSuccess: () => void;
}

const ResubmitPaymentFileButton = ({ fileName, onSuccess }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const resubmitPaymentFile = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await api.resubmitPaymentFile(fileName);
      if (!mounted.current) return;
      onSuccess();
      setIsLoading(false);
      setIsDialogOpen(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      setIsDialogOpen(false);
      if (getNetworkErrorCode(error) === NetworkErrorCode.submissionCooldown) {
        enqueueSnackbar(
          t('resubmitPaymentFileButton.submissionCooldownError'),
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    }
  };

  return (
    <>
      <Button size="small" onClick={() => setIsDialogOpen(true)}>
        {t('resubmitPaymentFileButton.text')}
      </Button>
      <ConfirmDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSuccess={resubmitPaymentFile}
        loading={isLoading}
        title={t('resubmitPaymentFileButton.title')}
        description={t('resubmitPaymentFileButton.confirmLabel')}
      />
    </>
  );
};

export default ResubmitPaymentFileButton;
