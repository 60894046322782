import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { cardholderPaths } from 'components/App';
import { LastTransactionsList } from 'domains/transaction/components';
import { Alert, Box, Button, Link, Typography } from 'elements';
import { MemberDetails, MemberStatus, Transaction } from 'services/constants';
import { getPath } from 'services/utils';

interface Props {
  member: MemberDetails;
  transactions: Transaction[] | null;
  teamId?: string;
  onRetry: () => Promise<void>;
}

const MemberDetailsTransactionsBlock = ({
  member,
  transactions,
  teamId,
  onRetry,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box p={3} borderTop={(theme) => `1px solid ${theme.palette.divider}`}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="overline" component="div" py={0.5}>
          {t('memberDetailsPage.lastTransactions')}
        </Typography>
        {!!transactions?.length && (
          <Link
            variant="body2"
            component={RouterLink}
            to={
              teamId
                ? cardholderPaths.teamTransactions
                : generatePath(getPath('transactions'), {
                    orgId: member.organizationId,
                  }) + `?memberId=${member.id}`
            }
          >
            {t('memberDetailsPage.seeAll')}
          </Link>
        )}
      </Box>

      {!!transactions?.length && (
        <LastTransactionsList transactions={transactions} teamId={teamId} />
      )}

      {transactions?.length === 0 && (
        <Typography my={1} color="textSecondary">
          {member.status === MemberStatus.deactivated
            ? t('memberDetailsPage.noTransactions')
            : t('memberDetailsPage.noTransactionsYet')}
        </Typography>
      )}

      {!transactions && (
        <Alert
          sx={{ my: (theme) => theme.spacing(1) }}
          severity="error"
          action={
            <Button variant="text" color="error" size="small" onClick={onRetry}>
              {t('common.retry')}
            </Button>
          }
        >
          {t('errors.loadData')}
        </Alert>
      )}
    </Box>
  );
};

export default MemberDetailsTransactionsBlock;
