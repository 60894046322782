import React, { ComponentType, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import useIdTokenCustomData from 'hooks/useIdTokenCustomData';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { mapFeatureModules } from 'services/utils';

const withFeatureModules = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element | null => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useGlobalState();
  const idTokenCustomData = useIdTokenCustomData();
  const params = useParams<{ orgId: string }>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        // Get an org id from params in internal app or from the id token token in external app
        const organizationId = idTokenCustomData.organizationId || params.orgId;
        const featureModuleByKeyMap = await api.getFeatureModules(
          organizationId
        );
        if (!mounted.current) return;
        dispatch({
          type: 'SET_FEATURE_MODULES',
          payload: mapFeatureModules(featureModuleByKeyMap),
        });
        setIsLoading(false);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(t('errors.featureModulesLoadingFailed'), {
          variant: 'error',
        });
        setIsLoading(false);
        logError(error);
      }
    })();

    return () => {
      dispatch({ type: 'RESET_FEATURE_MODULES' });
    };
  }, []);

  return isLoading ? null : <Component {...props} />;
};

export default withFeatureModules;
