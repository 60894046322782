import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { useTranslation } from 'react-i18next';
import { MerchantStatusBadge } from 'domains/merchant/components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { FilterDrawer, FilterDrawerProps } from 'layout';
import { countries, merchantStatuses } from 'services/constants';
import { QueryParams } from '.././index';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  status: string[];
  country: string[];
}

const initialState: State = {
  status: [],
  country: [],
};

const FiltersDrawer = ({ params, setParam, ...props }: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>(initialState);

  const areFiltersSelected = !!(state.status.length || state.country.length);

  useEffect(() => {
    if (props.open)
      setState({
        status: params.status,
        country: params.country,
      });
  }, [props.open]);

  const onFiltersApply = () => {
    setParam(Object.entries(state));
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.entries(initialState));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-country"
          id="panel-country-header"
        >
          <Typography>{t('filters.country')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth>
            <Select<string[]>
              size="medium"
              multiple
              displayEmpty
              value={state.country}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  country: e.target.value as string[],
                }))
              }
              renderValue={(selected) => {
                if (!selected?.length)
                  return (
                    <Typography color="text.disabled">
                      {t('filters.country')}
                    </Typography>
                  );

                return selected
                  .map((cc) => {
                    return countries.find((item) => item.countryCode === cc)
                      ?.countryName;
                  })
                  .join(', ');
              }}
            >
              {countries.map(({ countryCode, countryName }) => (
                <MenuItem key={countryCode} value={countryCode}>
                  {countryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-status"
          id="panel-status-header"
        >
          <Typography>{t('filters.status')} </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <FormGroup>
              {merchantStatuses.map((status) => (
                <FormControlLabel
                  key={status}
                  checked={state.status.includes(status)}
                  control={<Checkbox />}
                  onChange={() =>
                    setState((prevState) => ({
                      ...prevState,
                      status: xor(prevState.status, [status]),
                    }))
                  }
                  label={<MerchantStatusBadge status={status} />}
                  disableTypography
                />
              ))}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </FilterDrawer>
  );
};

export default FiltersDrawer;
