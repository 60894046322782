import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  CurrencyEurIcon,
  DataGrid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  GlobeHemisphereWestIcon,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CurrencyCashback,
  DEFAULT_PAGE_LIMIT,
  HistoryCashbackRedemption,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import useColumns from './useColumns';

interface State {
  results: HistoryCashbackRedemption[];
  hasNextPage: boolean;
  isLoading: boolean;
}

interface Props extends DialogProps {
  currencyCashback: CurrencyCashback;
  onClose: () => void;
}

const HistoryCashbackRedemptionsDialog = ({
  currencyCashback,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { columns } = useColumns();
  const {
    state: { featureModules, organization },
  } = useGlobalState();
  const pageRef = useRef(0);
  const [state, setState] = useState<State>({
    results: [],
    hasNextPage: false,
    isLoading: true,
  });
  const isPliantEarthActivated = featureModules.PLIANT_EARTH;

  const getData = async (page: number, isLoadMore = false) => {
    try {
      if (!isLoadMore) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
      }
      const { results, hasNextPage } = await api.getHistoryCashbackRedemptions(
        organization!.id,
        {
          page,
          limit: DEFAULT_PAGE_LIMIT,
          currency: currencyCashback.totalPayout.currency,
        }
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        results: isLoadMore ? [...prevState.results, ...results] : results,
        hasNextPage,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData(pageRef.current);
  }, []);

  const loadMoreItems = () => {
    if (state.isLoading || !state.hasNextPage) return;
    pageRef.current++;
    getData(pageRef.current, true);
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{t('historyCashbackRedemptionsDialog.title')}</DialogTitle>
      <DialogContent>
        <Box display="flex" mb={4}>
          <Box mr={4}>
            <Typography variant="caption" component="div" mb={1}>
              {t('historyCashbackRedemptionsDialog.totalPayout')}
            </Typography>
            <Box display="flex" alignItems="center">
              <CurrencyEurIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="h6">
                <FormatMoney
                  value={currencyCashback.totalPayout}
                  fractionalPart
                />
              </Typography>
            </Box>
          </Box>
          {isPliantEarthActivated && (
            <Box>
              <Typography variant="caption" component="div" mb={1}>
                {t('historyCashbackRedemptionsDialog.totalOffset')}
              </Typography>
              <Box display="flex" alignItems="center">
                <GlobeHemisphereWestIcon fontSize="small" sx={{ mr: 1 }} />
                <Typography variant="h6">
                  <FormatMoney
                    value={currencyCashback.totalOffset}
                    fractionalPart
                  />
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box height={264}>
          <DataGrid
            rows={state.results}
            columns={columns}
            onRowsScrollEnd={loadMoreItems}
            loading={state.isLoading}
            slots={{ noRowsOverlay: () => <NothingFound /> }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t('common.button.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default withDialogWrapper<Props>(HistoryCashbackRedemptionsDialog);
