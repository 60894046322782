import { useTranslation } from 'react-i18next';
import { StatusBadge, StatusBadgeProps } from 'elements';
import { CardSimpleStatus, cardSimpleStatuses } from 'services/constants';

const getBadgeType = (status: CardSimpleStatus) => {
  switch (status) {
    case CardSimpleStatus.requested:
      return 'warning';

    case CardSimpleStatus.pending:
      return 'info';

    case CardSimpleStatus.active:
      return 'success';

    case CardSimpleStatus.expired:
    case CardSimpleStatus.terminated:
      return 'error';

    default:
      return 'default';
  }
};

interface Props extends Omit<StatusBadgeProps, 'label' | 'color'> {
  status: CardSimpleStatus;
}

export const CardStatusBadge = ({ status, ...props }: Props) => {
  const { t } = useTranslation();
  const label = t(`cardStatuses.${status}`);

  if (!status || !cardSimpleStatuses.includes(status)) return null;

  return <StatusBadge color={getBadgeType(status)} label={label} {...props} />;
};
