import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { Box, CheckCircleIcon, CircularProgress, WarningIcon } from 'elements';
import { CodatMappingOptionsSummary, SUPPORT_EMAIL } from 'services/constants';
import { getPath, PathsKeys } from 'services/utils';
import { checkMapped, OptionKey } from './SyncOptionsGroup';

const getLinkData = (key: OptionKey) => {
  let link: PathsKeys = 'settingsCategories';
  let messageKey = 'newAccountsAvailable';

  if (key === 'taxRates') {
    link = 'settingsVatRates';
    messageKey = 'newVatRatesAvailable';
  }
  if (key === 'suppliers') {
    link = 'settingsSuppliers';
    messageKey = 'newSuppliersAvailable';
  }
  if (key === 'trackingCategories') {
    link = 'settingsTeams';
    messageKey = 'newTrackingCategoriesAvailable';
  }

  return { link, messageKey };
};

interface Props {
  isLoading: boolean;
  isError: boolean;
  name: OptionKey;
  summary: CodatMappingOptionsSummary | null;
  isPristineNonsyncedCase: boolean;
}

const SyncOptionStatus = ({
  isLoading,
  isError,
  name,
  summary,
  isPristineNonsyncedCase,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();

  if (isLoading) return <CircularProgress size="small" />;

  if (isError)
    return name === 'accounts' ? (
      <Box display="flex" alignItems="center">
        <Trans
          i18nKey="accountingSystemSubPage.syncOptionsGroup.syncErrorMsg"
          components={{
            sup: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={'mailto:' + SUPPORT_EMAIL}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
        <WarningIcon sx={{ ml: 1 }} color="error" />
      </Box>
    ) : null;

  if (!summary?.[name]) return null;

  // initial case - no mapping done before
  if (isPristineNonsyncedCase)
    return <>{t('accountingSystemSubPage.syncOptionsGroup.notSynced')}</>;

  const isSynced =
    summary[name].newItems === 0 &&
    (checkMapped(summary[name]) ||
      (summary[name].selectedItems === 0 &&
        summary[name].unselectedItems === 0));

  // everything is synced and there are no new items
  if (isSynced)
    return (
      <Box display="flex" alignItems="center">
        {t('accountingSystemSubPage.syncOptionsGroup.upToDate')}
        <CheckCircleIcon sx={{ ml: 1 }} />
      </Box>
    );

  const { link, messageKey } = getLinkData(name);

  // case when we have sync, but there are also new items
  return (
    <Link
      to={{
        pathname: generatePath(getPath(link), {
          orgId: organization!.id,
        }),
        state: { isCodatFlow: true },
      }}
    >
      {t(`accountingSystemSubPage.syncOptionsGroup.${messageKey}`)}
    </Link>
  );
};

export default SyncOptionStatus;
