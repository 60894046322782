import React, { useState } from 'react';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  PercentField,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  AccountingSystem,
  NetworkErrorCode,
  VAT_RATE,
  VatRate,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import useCanUser from 'services/rbac/useCanUser';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  trimObjValues,
} from 'services/utils';

interface State {
  confirmation: 'deactivate' | 'delete' | null;
  isUpdating: boolean;
}

interface Props extends DialogProps {
  onClose: () => void;
  onUpdate: (vatRate: VatRate) => void;
  onDelete: (id: string) => void;
  vatRate: VatRate | null;
}

const EditVatRateDialog = ({
  vatRate,
  onUpdate,
  onDelete,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    confirmation: null,
    isUpdating: false,
  });
  const codeIsNonNumeric =
    accountingSettings!.accountingSystem !== AccountingSystem.datev;

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      ...vatRate!,
      // this field is used to store the value entered by the user in "rate" text field (eg "", ".24", etc)
      rateStr: vatRate!.rate.toString(),
    },
    validate(values) {
      const errors: { [key in keyof typeof values]?: string } = {};
      const rate = +values.rate;
      if (rate < VAT_RATE.rateMinVal) {
        errors.rate = t('editVatRateDialog.vatRateTooLowError', {
          min: VAT_RATE.rateMinVal,
        });
      }
      if (rate > VAT_RATE.rateMaxVal) {
        errors.rate = t('editVatRateDialog.vatRateTooBigError', {
          max: VAT_RATE.rateMaxVal,
        });
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        const vatRateData = trimObjValues(omit(values, 'rateStr')) as VatRate;
        const data = await api.updateVatRate({
          ...vatRateData,
          code: vatRateData.code || null,
        });
        if (!mounted.current) return;
        onUpdate(data);
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (getNetworkErrorCode(error) === NetworkErrorCode.invalidVatName) {
          setFieldError('name', t('editVatRateDialog.invalidVatNameError'));
          return;
        }
        if (getNetworkErrorCode(error) === NetworkErrorCode.invalidVatCode) {
          setFieldError('code', t('editVatRateDialog.invalidVatCodeError'));
          return;
        }
        if (getNetworkErrorCode(error) === NetworkErrorCode.vatRateIsInUse) {
          if (values.rate !== vatRate?.rate) {
            setFieldError(
              'rate',
              t('editVatRateDialog.vatRateIsInUseRateError')
            );
          }
          if (values.code !== (vatRate?.code || '')) {
            setFieldError(
              'code',
              t('editVatRateDialog.vatRateIsInUseCodeError')
            );
          }
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const changeStatus = async (status: AccountingItemStatus) => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      const data = await api.updateVatRate({ ...vatRate!, status });
      if (!mounted.current) return;
      onUpdate(data);
      enqueueSnackbar(
        status === AccountingItemStatus.inactive
          ? t('editVatRateDialog.deactivatedSuccessMessage')
          : t('editVatRateDialog.activatedSuccessMessage'),
        { autoHideDuration: 1500 }
      );
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        confirmation: null,
        isUpdating: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const handleDelete = async () => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      await api.deleteVatRate(vatRate!.id);
      if (!mounted.current) return;
      onDelete(vatRate!.id);
      enqueueSnackbar(t('editVatRateDialog.deletedSuccessMessage'), {
        autoHideDuration: 1500,
      });
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        confirmation: null,
        isUpdating: false,
      }));
      if (getNetworkErrorCode(error) === NetworkErrorCode.vatRateIsInUse) {
        props.onClose();
        enqueueSnackbar(t('editVatRateDialog.deleteVatRateIsInUse'), {
          variant: 'error',
        });
        return;
      }
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  if (state.confirmation === 'deactivate') {
    return (
      <ConfirmDialog
        {...props}
        title={t('editVatRateDialog.deactivateStep.title')}
        description={
          <Trans
            i18nKey="editVatRateDialog.deactivateStep.description"
            components={{ br: <br /> }}
          />
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          color: 'error',
          children: t('editVatRateDialog.deactivateStep.deactivate'),
        }}
        onSuccess={() => changeStatus(AccountingItemStatus.inactive)}
        onClose={() =>
          setState((prevState) => ({ ...prevState, confirmation: null }))
        }
      />
    );
  }

  if (state.confirmation === 'delete') {
    return (
      <ConfirmDialog
        {...props}
        title={t('editVatRateDialog.deleteStep.title')}
        description={
          <Trans
            i18nKey="editVatRateDialog.deleteStep.description"
            components={{ br: <br /> }}
          />
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          color: 'error',
          children: t('editVatRateDialog.deleteStep.delete'),
        }}
        onSuccess={handleDelete}
        onClose={() =>
          setState((prevState) => ({ ...prevState, confirmation: null }))
        }
      />
    );
  }

  const hasEmptyField = !formik.values.name.trim() || !formik.values.rateStr;
  const hasChangedValues =
    formik.values.name !== vatRate?.name ||
    formik.values.rate !== vatRate?.rate ||
    (formik.values.code || '') !== (vatRate?.code || '');

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle display="flex" alignItems="center">
        {t('editVatRateDialog.title')}
        {vatRate?.status === AccountingItemStatus.inactive && (
          <Box ml={1} fontSize={0}>
            <Chip
              size="small"
              label={t('editVatRateDialog.inactiveBadge')}
              variant="outlined"
            />
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="edit-vat-rate-form" noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t('editVatRateDialog.nameTitle')}
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting || state.isUpdating}
                onChange={(e) => {
                  formik.setFieldValue(e.target.name, e.target.value, false);
                  formik.setFieldError(e.target.name, undefined);
                }}
                inputProps={{
                  maxLength: VAT_RATE.nameMaxLength,
                }}
                error={!!formik.errors.name}
                helperText={formik.errors.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <PercentField
                isNumericString
                label={t('editVatRateDialog.rateTitle')}
                decimalScale={2}
                name="rate"
                value={formik.values.rateStr}
                disabled={formik.isSubmitting || state.isUpdating}
                onBlur={formik.handleBlur}
                onValueChange={(values) => {
                  formik.setFieldValue('rate', values.floatValue, false);
                  formik.setFieldValue('rateStr', values.value, false);
                  formik.setFieldError('rate', undefined);
                }}
                error={!!formik.errors.rate}
                helperText={formik.errors.rate}
              />
            </Grid>
            <Grid item xs={12}>
              {codeIsNonNumeric ? (
                <TextField
                  {...formik.getFieldProps('code')}
                  label={t('addVatRateDialog.codeTitle')}
                  inputProps={{
                    maxLength: VAT_RATE.codeMaxLength,
                  }}
                  onChange={(e) => {
                    formik.setFieldValue('code', e.target.value, false);
                    formik.setFieldError('code', undefined);
                  }}
                  disabled={formik.isSubmitting || state.isUpdating}
                  error={!!formik.errors.code}
                  helperText={formik.errors.code}
                />
              ) : (
                <NumberFormat
                  customInput={TextField}
                  allowNegative={false}
                  label={t('editVatRateDialog.codeTitle')}
                  decimalScale={0}
                  name="code"
                  value={formik.values.code}
                  onBlur={formik.handleBlur}
                  onValueChange={(values) => {
                    formik.setFieldValue('code', values.value, false);
                    formik.setFieldError('code', undefined);
                  }}
                  inputProps={{
                    maxLength: VAT_RATE.codeMaxLength,
                  }}
                  disabled={formik.isSubmitting || state.isUpdating}
                  error={!!formik.errors.code}
                  helperText={formik.errors.code}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        {vatRate?.status === AccountingItemStatus.active ? (
          <>
            <Box mr="auto">
              <Button
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    confirmation: 'deactivate',
                  }));
                }}
                disabled={formik.isSubmitting || state.isUpdating}
                variant="text"
                color="error"
              >
                {t('editVatRateDialog.deactivate')}
              </Button>
            </Box>
            <Button onClick={props.onClose} variant="text">
              {t('common.button.close')}
            </Button>
            <Button
              disabled={
                hasEmptyField || formik.isSubmitting || state.isUpdating
              }
              form="edit-vat-rate-form"
              type="submit"
            >
              {t('editVatRateDialog.save')}
            </Button>
          </>
        ) : (
          <>
            {canUser('vat-rate:delete') && (
              <Box mr="auto">
                <Button
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      confirmation: 'delete',
                    }))
                  }
                  disabled={formik.isSubmitting || state.isUpdating}
                  variant="text"
                  color="error"
                >
                  {t('editVatRateDialog.delete')}
                </Button>
              </Box>
            )}
            <Button onClick={props.onClose} variant="text">
              {t('common.button.close')}
            </Button>
            {hasChangedValues ? (
              <Button
                disabled={
                  hasEmptyField || formik.isSubmitting || state.isUpdating
                }
                form="edit-vat-rate-form"
                type="submit"
              >
                {t('editVatRateDialog.save')}
              </Button>
            ) : (
              <Button onClick={() => changeStatus(AccountingItemStatus.active)}>
                {t('editVatRateDialog.activate')}
              </Button>
            )}
          </>
        )}
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting || state.isUpdating} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditVatRateDialog);
