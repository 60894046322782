import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { BalanceCalculationDialog } from 'domains/transaction/dialogs';
import { Box, Link, Typography } from 'elements';
import { CardAccountBalance } from 'services/constants';

interface Props {
  cardAccountBalance: CardAccountBalance | null;
}

const AccountBalanceBox = ({ cardAccountBalance }: Props) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (!cardAccountBalance) return null;

  return (
    <>
      <Box textAlign="right" ml="auto" position="relative">
        <Typography
          variant="h4"
          data-intercom-target="acc-entries-page-account-balance-value"
        >
          <FormatMoney value={cardAccountBalance.cardAccountBalance} />
        </Typography>
        <Typography
          component="div"
          variant="caption2"
          noWrap
          sx={{ position: 'absolute', right: 0 }}
          data-intercom-target="acc-entries-page-account-balance-label"
        >
          <Trans
            i18nKey="accountingPage.accountBalance"
            components={{
              btn: (
                <Link component="button" onClick={() => setIsDialogOpen(true)}>
                  {t('accountingPage.titleAccounts')}
                </Link>
              ),
            }}
          />
        </Typography>
      </Box>
      <BalanceCalculationDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        cardAccountBalance={cardAccountBalance}
      />
    </>
  );
};

export default AccountBalanceBox;
