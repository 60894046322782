import { useTranslation } from 'react-i18next';
import { StatusBadge } from 'elements';
import { MemberStatus } from 'services/constants';

interface Props {
  status: MemberStatus;
}

const MemberStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();
  const label = t(`memberStatuses.${status}`);

  if (status === MemberStatus.invited)
    return <StatusBadge label={label} color="info" />;

  if (status === MemberStatus.active)
    return <StatusBadge label={label} color="success" />;

  if (status === MemberStatus.deactivated) return <StatusBadge label={label} />;

  return null;
};

export default MemberStatusBadge;
