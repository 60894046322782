import { RumEvent } from '@datadog/browser-rum';
import { actionOwnerTeamTags } from './teamOwners';

interface PliantDataContextTag {
  [key: string]: string;
}

const normalisedPathTeams = normaliseOwnerTeamTags(actionOwnerTeamTags);

export function getDatadogContextTags(event: RumEvent): PliantDataContextTag {
  const urlPath = new URL(event?.view?.url).pathname;
  const normalisedUrlPath = urlPath.replace(
    /[0-9A-Fa-f]{8}-([0-9A-Fa-f]{4}-){3}[0-9A-Fa-f]{12}/g,
    '?'
  );
  const ownerTeams = retrieveOwnerTeam(normalisedPathTeams, normalisedUrlPath);
  return { pliant_owner_teams: ownerTeams.join(', ').toLowerCase() };
}

function retrieveOwnerTeam(
  teams: any,
  path: string,
  callCount: number = 0
): string[] {
  const maxCallCount = 20;
  if (callCount >= maxCallCount) return teams['/'];
  if (!path) return teams['/'];
  if (teams[path]) return teams[path];
  return retrieveOwnerTeam(teams, path.replace(/\/(\w|\?)*$/, ''), ++callCount);
}

function normaliseOwnerTeamTags(ownerTeamTags: { [k: string]: string[] }) {
  return Object.keys(ownerTeamTags).reduce((acc, key) => {
    return { ...acc, [key.replace(/:\w+/g, '?')]: ownerTeamTags[key] };
  }, {});
}
