import { Theme } from '@mui/material';
import type { DataGridProComponents } from '@mui/x-data-grid-pro/themeAugmentation';

const MuiDataGridProOverrides: DataGridProComponents<Theme> = {
  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: 'none',
      },
      cell: ({ theme }) => ({
        ...theme.variables.typography.body2,
        '&:focus-within': { outline: 'none' },
        // Add 20px padding to the last cell to prevent scrollbar overlapping content
        '&:first-of-type:not(&.MuiDataGrid-cellCheckbox)': { paddingLeft: 16 },
        // Remove extra padding and align with design
        '&.MuiDataGrid-cellCheckbox + .MuiDataGrid-cell': {
          paddingLeft: 0,
        },
        '&:last-of-type': {
          paddingRight: 20,
          '&.MuiDataGrid-cell--textCenter': {
            paddingLeft: 20,
          },
        },
      }),
      columnSeparator: { display: 'none' },
      columnHeader: ({ theme }) => ({
        ...theme.variables.components['table-header'],
        textTransform: 'uppercase',
        '&:focus-within': { outline: 'none' },
        // Make header columns consistent with the body columns (see the scrollbar comment)
        '&:first-of-type:not(&.MuiDataGrid-columnHeaderCheckbox)': {
          paddingLeft: 16,
        },
        // Remove extra padding and align with design
        '&.MuiDataGrid-columnHeaderCheckbox + .MuiDataGrid-columnHeader': {
          paddingLeft: 0,
        },
        '&:last-of-type': {
          paddingRight: 20,
          '&.MuiDataGrid-columnHeader--alignCenter': { paddingLeft: 20 },
        },
      }),
      withBorderColor: ({ theme }) => ({
        borderColor: theme.palette.divider,
      }),
      row: ({ theme, ownerState }) => {
        return ownerState?.onRowClick
          ? {
              cursor: 'pointer',
              // For export and need review tx pages there should be
              // a slightly different row selection implementation.
              // Use this class, when you need row selections + row details
              // functionalities together
              '&.row-details-visible': {
                background: theme.variables.states.primary.selected,
              },
            }
          : { '&:hover': { backgroundColor: 'inherit' } };
      },

      // Styles for a small grid
      cellCheckbox: ({ ownerState }) => {
        if (ownerState?.className === 'small-data-grid')
          return {
            '& .MuiCheckbox-root': {
              padding: '2px',
              '& .MuiSvgIcon-root': { width: '20px', height: '20px' },
            },
          };
      },
      columnHeaderCheckbox: ({ ownerState }) => {
        if (ownerState?.className === 'small-data-grid')
          return {
            '& .MuiCheckbox-root': {
              padding: '2px',
              '& .MuiSvgIcon-root': { width: '20px', height: '20px' },
            },
          };
      },
      overlayWrapperInner: {
        position: 'relative',
      },
    },
  },
};

export default MuiDataGridProOverrides;
