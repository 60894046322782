import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  IconButton,
  Paper,
  PencilSimpleIcon,
  TextField,
  Typography,
} from 'elements';
import { useCanUser } from 'services/rbac';

interface Props {
  id: string;
  note: string | null;
  onUpdate: (note: string | null) => void;
  isEditing: boolean;
  onEditChange: (isEditing: boolean) => void;
}

const MerchantNote = ({
  id,
  note,
  onUpdate,
  isEditing,
  onEditChange,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [state, setState] = useState({ value: note || '', id });

  useEffect(() => {
    if (state.id !== id) {
      setState({ value: note || '', id });
    } else if (!isEditing) {
      setState((prevState) => ({ ...prevState, value: note || '' }));
    }
  }, [id, note, state.id, isEditing]);

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.focus();
      // Moving cursor to the end
      textareaRef.current.selectionStart = textareaRef.current.value.length;
      textareaRef.current.selectionEnd = textareaRef.current.value.length;
    }
  }, [isEditing]);

  if (isEditing) {
    return (
      <Box mt={1}>
        <TextField
          size="medium"
          multiline
          label={t('int.merchantDetailsPage.note')}
          inputRef={textareaRef}
          inputProps={{
            maxLength: 200,
          }}
          rows={4}
          value={state.value}
          placeholder={t('int.merchantDetailsPage.addNote')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setState((prevState) => ({ ...prevState, value }));
          }}
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mt={1}
        >
          <Button onClick={() => onEditChange(false)} variant="text">
            {t('common.button.cancel')}
          </Button>
          <Button
            sx={{ ml: 1 }}
            onClick={() => {
              if ((note || '') === state.value.trim()) {
                onEditChange(false);
                return;
              }
              onUpdate(state.value.trim());
            }}
          >
            {t('common.button.save')}
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Paper
      sx={{
        px: 1.5,
        py: 2,
        mt: 1,
      }}
      variant="outlined"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="body2">
            {t('int.merchantDetailsPage.note')}
          </Typography>
          {!canUser('merchant:change') && !state.value ? (
            <Typography variant="caption">—</Typography>
          ) : (
            <Typography
              variant="caption"
              sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
              color={!state.value ? 'textSecondary' : undefined}
            >
              {state.value || t('int.merchantDetailsPage.addNote')}
            </Typography>
          )}
        </Box>
        {!isEditing && canUser('merchant:change') && (
          <IconButton
            sx={{ ml: 1 }}
            size="small"
            onClick={() => onEditChange(true)}
          >
            <PencilSimpleIcon />
          </IconButton>
        )}
      </Box>
    </Paper>
  );
};

export default MerchantNote;
