import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MAX_RECEIPT_FILE_SIZE_MB } from 'domains/transaction/constants';
import { useUnmatchedReceiptsCount } from 'domains/transaction/hooks';
import { Box, CopyIcon, IconButton, Link, Tooltip, Typography } from 'elements';
import useSnackbar from 'hooks/useSnackbar';
import { Tab, Tabs } from 'layout';
import { ReceiptsAutoMatchingTagGroup } from 'services/constants';
import { TabGroup } from './index';

interface Props {
  tabGroup: TabGroup;
  onTabGroupChange: (tabGroup: TabGroup) => void;
}

const ReceiptInboxHeader = ({ tabGroup, onTabGroupChange }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const unmatchedReceiptsCountAppBased = useUnmatchedReceiptsCount();
  const {
    state: { organization },
  } = useGlobalState();

  return (
    <Box
      sx={{ borderBottom: 1, borderColor: 'divider' }}
      display="flex"
      alignItems="flex-end"
    >
      <Tabs
        value={tabGroup}
        onChange={(_, value) => onTabGroupChange(value)}
        sx={{ flexShrink: 0, mr: 2 }}
      >
        <Tab
          label={`${t('receiptInbox.tab.unmatched')}${
            unmatchedReceiptsCountAppBased
              ? ` (${unmatchedReceiptsCountAppBased})`
              : ''
          }`}
          value={ReceiptsAutoMatchingTagGroup.unmatched}
        />
        <Tab
          label={t('receiptInbox.tab.matched')}
          value={ReceiptsAutoMatchingTagGroup.matched}
        />
        <Tab
          label={t('receiptInbox.tab.trash')}
          value={ReceiptsAutoMatchingTagGroup.trash}
        />
      </Tabs>

      <Box
        display="flex"
        alignItems="center"
        ml="auto"
        py={0.5}
        overflow="hidden"
      >
        <Typography color="textSecondary" variant="body2" noWrap>
          {t('receiptInbox.inboxAddress')}&nbsp;
        </Typography>
        <Typography noWrap variant="body2">
          <Tooltip
            title={t('receiptInbox.emailAddressTooltip', {
              size: MAX_RECEIPT_FILE_SIZE_MB,
            })}
          >
            <Link
              href={`mailto:${organization!.receiptInboxEmail}`}
              rel="noreferrer"
              target="_blank"
              sx={{ textDecoration: 'none' }}
            >
              {organization!.receiptInboxEmail}
            </Link>
          </Tooltip>
        </Typography>
        <CopyToClipboard
          text={organization!.receiptInboxEmail}
          onCopy={() =>
            enqueueSnackbar(t('receiptInbox.copiedToClipboard'), {
              autoHideDuration: 1500,
            })
          }
        >
          <Tooltip title={t('receiptInbox.copyTooltip')} placement="top">
            <IconButton size="small" sx={{ ml: 1 }}>
              <CopyIcon />
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
      </Box>
    </Box>
  );
};

export default ReceiptInboxHeader;
