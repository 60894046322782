import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { HeaderContainer, HeaderTitle } from 'domains/settings/layout';
import { Typography } from 'elements';
import { ContentContainer } from 'layout';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';
import CodatConnection from './CodatConnection';

const CodatSubPage = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();

  const canViewCodatConnection = canUser(
    'accounting-system-subpage:visit',
    accountingSettings
  );

  if (!canViewCodatConnection)
    return (
      <Redirect
        to={generatePath(getPath('settingsAccounting'), {
          orgId: organization!.id,
        })}
      />
    );

  return (
    <ContentContainer>
      <HeaderContainer>
        <HeaderTitle>{accountingSettings?.accountingSystemName}</HeaderTitle>
        <Typography>
          {t('accountingSystemSubPage.title', {
            name: accountingSettings!.accountingSystemName,
          })}
        </Typography>
      </HeaderContainer>

      <CodatConnection />
    </ContentContainer>
  );
};

export default CodatSubPage;
