import { useTranslation } from 'react-i18next';
import { TransactionStatusLabel } from 'domains/transaction/components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from 'elements';
import { TransactionStatus } from 'services/constants';

interface Props {
  value: TransactionStatus[];
  onChange: (status: TransactionStatus, checked: boolean) => void;
  options: TransactionStatus[];
}

const StatusFilter = ({ value, onChange, options }: Props) => {
  const { t } = useTranslation();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-status"
        id="panel-status-header"
      >
        <Typography>{t('filters.status')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl>
          <FormGroup>
            {options.map((status) => (
              <FormControlLabel
                key={status}
                checked={value.includes(status)}
                control={<Checkbox />}
                onChange={(_, checked) => onChange(status, checked)}
                label={<TransactionStatusLabel status={status} />}
              />
            ))}
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default StatusFilter;
