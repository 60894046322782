import i18next from 'i18next';
import { AccountGroup } from 'services/constants';

const getIssuerStatement = (accountGroup: AccountGroup) => {
  if (accountGroup === AccountGroup.bcTpl)
    return i18next.t('common.issuerStatement_v2.BC_TPL');

  return i18next.t('common.issuerStatement_v2.PLIANT_OY');
};

export default getIssuerStatement;
