import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  FormControl,
  ListItemIcon,
  MenuItem,
  ProjectorScreenIcon,
  Select,
  UserPlusIcon,
  UsersIcon,
} from 'elements';
import {
  AccountingItemStatus,
  Project,
  Team,
  TeamStatus,
} from 'services/constants';

type SelectedType = 'new' | Omit<string, 'new'>;

interface Props {
  isProjectStep?: boolean;
  selected: SelectedType;
  disabledIds: string[];
  onChange: (selectedId: SelectedType) => void;
}

const getSelectedInstance = (
  isProjectStep: boolean,
  items: Project[] | Team[],
  selected: SelectedType
) => {
  if (isProjectStep)
    return (items as Project[]).find((item) => item.id === selected);
  return (items as Team[]).find((item) => item.id === selected);
};

// Select used for similar functionality - teams and projects dropdowns
const MappingInstanceSelect = ({
  isProjectStep = false,
  selected,
  disabledIds,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { teams, projects },
  } = useGlobalState();

  const instance = isProjectStep
    ? projects?.filter(
        (item) =>
          item.status === AccountingItemStatus.active || item.id === selected
      ) || []
    : teams.filter(
        (item) => item.teamStatus === TeamStatus.active || item.id === selected
      );

  const addNewLabel = isProjectStep
    ? t('codatSyncSetupDialog.costUnitsStep.createAsNewProject')
    : t('codatSyncSetupDialog.costCentersStep.createAsNewTeam');

  return (
    <FormControl sx={{ width: 200 }}>
      <Select
        value={selected}
        renderValue={(selectedValue) => {
          if (!selectedValue) return '';
          if (selectedValue === 'new') return addNewLabel;

          return (
            getSelectedInstance(
              isProjectStep,
              instance,
              selectedValue as SelectedType
            )?.name || ''
          );
        }}
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem value={'new'}>
          <ListItemIcon>
            {isProjectStep ? <ProjectorScreenIcon /> : <UserPlusIcon />}
          </ListItemIcon>
          {addNewLabel}
        </MenuItem>

        {instance.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            disabled={selected !== item.id && disabledIds.includes(item.id)}
          >
            <ListItemIcon>
              {isProjectStep ? <ProjectorScreenIcon /> : <UsersIcon />}
            </ListItemIcon>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MappingInstanceSelect;
