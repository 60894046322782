import React, { ReactNode, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import { SetCustomCashbackRateDialog } from 'domains/terms/dialogs';
import {
  Box,
  FormControlLabel,
  IconButton,
  InfoIcon,
  Paper,
  PencilSimpleIcon,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Cashback,
  CashbackRateAudit,
  CashbackType,
  cashbackTypes,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface Rate {
  rate: number;
  isCustom: boolean;
  audit: CashbackRateAudit | null;
  expirationDate: string | null;
  tooltip?: ReactNode;
}

interface RateRowProps extends Rate {
  type: CashbackType;
  disabled: boolean;
  onChange: (isCustom: boolean) => void;
}

const RateRow = ({
  type,
  disabled,
  onChange,
  rate,
  isCustom,
  expirationDate,
  audit,
  tooltip,
}: RateRowProps) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>
        <Box display="inline-flex" alignItems="center">
          <Typography variant="inherit">
            {t(
              `int.cashbackSettingsPage.cashbackRatesSection.rateTypes.${type}`
            )}
          </Typography>
          {tooltip && (
            <Tooltip title={tooltip}>
              <InfoIcon
                fontSize="small"
                sx={{ ml: 0.5, color: 'text.secondary' }}
              />
            </Tooltip>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <FormControlLabel
          label={t(
            type === CashbackType.general
              ? 'int.cashbackSettingsPage.cashbackRatesSection.frequencyBased'
              : 'int.cashbackSettingsPage.cashbackRatesSection.default'
          )}
          checked={!isCustom}
          onChange={() => onChange(false)}
          name={type}
          value="DEFAULT"
          disabled={disabled}
          control={<Radio />}
        />
      </TableCell>
      <TableCell>
        <FormControlLabel
          label={t('int.cashbackSettingsPage.cashbackRatesSection.custom')}
          checked={isCustom}
          onChange={() => onChange(true)}
          name={type}
          value="CUSTOM"
          disabled={disabled}
          control={<Radio />}
        />
      </TableCell>
      <TableCell align="right" sx={{ width: '42%' }}>
        <Box display="inline-flex" alignItems="center" height={30}>
          <Typography variant="subtitle1">
            <FormatPercent value={rate * 100} />
          </Typography>
          {expirationDate && (
            <Typography variant="caption" ml={1} color="text.secondary">
              {t(
                'int.cashbackSettingsPage.cashbackRatesSection.expirationDate',
                { date: moment(expirationDate).format('DD.MM.YYYY') }
              )}
            </Typography>
          )}
          {isCustom && !disabled && (
            <IconButton
              onClick={() => onChange(true)}
              sx={{ ml: 0.5 }}
              size="small"
            >
              <PencilSimpleIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
        {audit && (
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            mt={0.5}
          >
            {t('int.common.lastEditedBy', {
              name: audit.email,
              time: moment(audit.updatedAt).format('DD MMM YYYY HH:mm'),
            })}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

const TooltipList = (props: { items: string[] }) => (
  <ul style={{ paddingLeft: '16px' }}>
    {props.items.map((item) => (
      <li key={item}>{item}</li>
    ))}
  </ul>
);

interface Props {
  cashback: Cashback;
  onUpdate: (cashback: Cashback) => void;
}

const CashbackRatesSection = ({ cashback, onUpdate }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const [
    cashbackTypeToUpdate,
    setCashbackTypeToUpdate,
  ] = useState<CashbackType | null>(null);

  const rateByType = useMemo<Record<CashbackType, Rate>>(() => {
    return {
      [CashbackType.general]: {
        rate: cashback.generalRate,
        isCustom: cashback.customGeneralRateEnabled,
        audit: cashback.generalRateAudit,
        expirationDate: cashback.customGeneralRateExpirationDate,
      },
      [CashbackType.fx]: {
        rate: cashback.fxRate,
        isCustom: cashback.fxRateEnabled,
        audit: cashback.fxRateAudit,
        expirationDate: cashback.customFxRateExpirationDate,
      },
      [CashbackType.mccLow]: {
        rate: cashback.mccLowRate,
        isCustom: cashback.mccLowRateEnabled,
        audit: cashback.mccLowRateAudit,
        expirationDate: cashback.customMccLowRateExpirationDate,
        tooltip: (
          <TooltipList
            items={t(
              'int.setCustomCashbackRateDialog.cashbackRateTooltips.mccLow',
              { returnObjects: true }
            )}
          />
        ),
      },
      [CashbackType.mccLowTwo]: {
        rate: cashback.mccLowTwoRate,
        isCustom: cashback.mccLowTwoRateEnabled,
        audit: cashback.mccLowTwoRateAudit,
        expirationDate: cashback.customMccLowTwoRateExpirationDate,
        tooltip: (
          <TooltipList
            items={t(
              'int.setCustomCashbackRateDialog.cashbackRateTooltips.mccLowTwo',
              { returnObjects: true }
            )}
          />
        ),
      },
      [CashbackType.mccZero]: {
        rate: cashback.mccZeroRate,
        isCustom: cashback.mccZeroRateEnabled,
        audit: cashback.mccZeroRateAudit,
        expirationDate: cashback.customMccZeroRateExpirationDate,
        tooltip: (
          <TooltipList
            items={t(
              'int.setCustomCashbackRateDialog.cashbackRateTooltips.mccZero',
              { returnObjects: true }
            )}
          />
        ),
      },
    };
  }, [cashback]);

  const deleteCustomCashbackRate = async (type: CashbackType) => {
    try {
      setIsLoading(true);
      const data = await api.deleteCustomCashbackRate(organization!.id, type);
      if (!mounted.current) return;
      setIsLoading(false);
      onUpdate(data);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <Box
        py={3}
        my={3}
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.divider}`,
          borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        <Typography variant="h6" mb={3}>
          {t('int.cashbackSettingsPage.cashbackRatesSection.title')}
        </Typography>
        <Paper variant="outlined" sx={{ p: 3, mb: 2 }}>
          <Table>
            <TableBody>
              {cashbackTypes.map((type) => (
                <RateRow
                  key={type}
                  type={type}
                  onChange={(isCustom) => {
                    if (isLoading) return;
                    if (isCustom) setCashbackTypeToUpdate(type);
                    else deleteCustomCashbackRate(type);
                  }}
                  disabled={!canUser('cashback-settings:update')}
                  {...rateByType[type]}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>

      <SetCustomCashbackRateDialog
        open={!!cashbackTypeToUpdate}
        cashbackType={cashbackTypeToUpdate}
        currentRate={
          cashbackTypeToUpdate ? rateByType[cashbackTypeToUpdate].rate : 0
        }
        expirationDate={
          cashbackTypeToUpdate
            ? rateByType[cashbackTypeToUpdate].expirationDate
            : null
        }
        onSuccess={(cashback) => {
          setCashbackTypeToUpdate(null);
          onUpdate(cashback);
        }}
        onClose={() => setCashbackTypeToUpdate(null)}
      />
    </>
  );
};

export default CashbackRatesSection;
