import { useTranslation } from 'react-i18next';
import { StatusBadge } from 'elements';
import { ApiIntegrationStatus } from 'services/constants';

interface Props {
  status: ApiIntegrationStatus;
}

export const ApiIntegrationStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();
  const label = t(`datevStatuses.${status}`);

  return (
    <StatusBadge
      color={status === ApiIntegrationStatus.connected ? 'success' : 'primary'}
      variant={status === ApiIntegrationStatus.connected ? 'filled' : 'tinted'}
      label={label}
    />
  );
};
