import { WarningContainer } from 'domains/transaction/components/TransactionDetails/style';
import { Alert, AlertTitle } from 'elements';
import { Transaction } from 'services/constants';

interface Props {
  transaction: Transaction;
}

const DeclinedTransactionInfoSection = ({ transaction }: Props) => {
  if (!transaction.declineReason || !transaction.declineReasonDescription) {
    return null;
  }

  return (
    <WarningContainer>
      <Alert severity="error">
        <AlertTitle>{transaction.declineReason}</AlertTitle>
        {transaction.declineReasonDescription}
      </Alert>
    </WarningContainer>
  );
};

export default DeclinedTransactionInfoSection;
