const overflowEllipsis = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const mixins = {
  overflowEllipsis,
};

export default mixins;
