import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { FormControl, FormControlLabel, Switch } from 'elements';

interface Props {
  isLoading: boolean;
  isSelected: boolean;
  onChange: (checked: boolean) => void;
}

const IncludeReceiptsToggle = ({ isLoading, isSelected, onChange }: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();

  if (!featureModules.RECEIPT_MANAGEMENT) return null;

  return (
    <FormControl sx={{ marginTop: 3 }}>
      <FormControlLabel
        disabled={isLoading}
        checked={isSelected}
        onChange={(_, checked: boolean) => onChange(checked)}
        control={<Switch />}
        label={t('exportTransactionsDialog.includeReceipts')}
      />
    </FormControl>
  );
};

export default IncludeReceiptsToggle;
