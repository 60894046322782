import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowsInLineHorizontalIcon,
  ArrowsMergeIcon,
  ArrowsSplitIcon,
  FadersHorizontalIcon,
  IconButton,
  ListItemIcon,
  MenuContainer,
  MenuItem,
  PencilSimpleIcon,
  SplitHorizontalIcon,
} from 'elements';
import { MerchantDetails, MerchantSplitType } from 'services/constants';
import EditMerchantDialog from './EditMerchantDialog';
import MergeMerchantsDialog from './MergeMerchantsDialog';

interface Props {
  merchant: MerchantDetails;
  hasMultipleDe42Ids: boolean;
  onMerge: (merchant: MerchantDetails) => void;
  onUnmerge: () => void;
  onSplit: () => void;
  onUnsplit: () => void;
  onUpdate: (merchant: MerchantDetails) => void;
}

const MerchantDetailsMenu = ({
  merchant,
  hasMultipleDe42Ids,
  onMerge,
  onUnmerge,
  onSplit,
  onUnsplit,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const [isEditMerchantDialogOpen, setIsEditMerchantDialogOpen] = useState(
    false
  );
  const [isMergeDialogOpen, setIsMergeDialogOpen] = useState(false);

  return (
    <>
      <MenuContainer
        button={
          <IconButton>
            <FadersHorizontalIcon />
          </IconButton>
        }
      >
        <MenuItem onClick={() => setIsEditMerchantDialogOpen(true)}>
          <ListItemIcon>
            <PencilSimpleIcon />
          </ListItemIcon>
          {t('int.merchantDetailsPage.editMerchantDetails')}
        </MenuItem>
        {merchant.splitType !== MerchantSplitType.splitMerchant && (
          <MenuItem onClick={() => setIsMergeDialogOpen(true)}>
            <ListItemIcon>
              <ArrowsInLineHorizontalIcon />
            </ListItemIcon>
            {t('int.merchantDetailsPage.mergeExisting')}
          </MenuItem>
        )}
        {hasMultipleDe42Ids && (
          <MenuItem onClick={onUnmerge}>
            <ListItemIcon>
              <SplitHorizontalIcon />
            </ListItemIcon>
            {t('int.merchantDetailsPage.unmergeMerchant')}
          </MenuItem>
        )}
        {merchant.splitType !== MerchantSplitType.splitMerchant && (
          <MenuItem onClick={onSplit}>
            <ListItemIcon>
              <ArrowsSplitIcon />
            </ListItemIcon>
            {t('int.merchantDetailsPage.splitMerchant')}
          </MenuItem>
        )}
        {merchant.splitType === MerchantSplitType.splitMerchant && (
          <MenuItem onClick={onUnsplit}>
            <ListItemIcon>
              <ArrowsMergeIcon />
            </ListItemIcon>
            {t('int.merchantDetailsPage.unsplitMerchant')}
          </MenuItem>
        )}
      </MenuContainer>
      <EditMerchantDialog
        open={isEditMerchantDialogOpen}
        onClose={() => setIsEditMerchantDialogOpen(false)}
        merchant={merchant}
        onUpdate={onUpdate}
      />
      <MergeMerchantsDialog
        open={isMergeDialogOpen}
        onClose={() => setIsMergeDialogOpen(false)}
        onSuccess={(merchant) => {
          onMerge(merchant);
          setIsMergeDialogOpen(false);
        }}
        srcMerchant={merchant}
      />
    </>
  );
};

export default MerchantDetailsMenu;
