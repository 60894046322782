import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { adminPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import AccountingPage from 'domains/settings/pages/AccountingPage';
import FeatureModulesPage from 'domains/settings/pages/FeatureModulesPage';
import IntegrationsPage from 'domains/settings/pages/IntegrationsPage';
import OrganizationPage from 'domains/settings/pages/OrganizationPage';
import withPageConfig from 'hoc/withPageConfig';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { useCanUser } from 'services/rbac';

const SettingsRoutes = () => {
  const { location } = useHistory();
  const { path } = useRouteMatch();
  const canUser = useCanUser();
  const isOrgInOnboarding = useIsOrgInOnboarding();
  const {
    state: { isComplianceRiskWhiteLabelApp },
  } = useGlobalState();

  if (isComplianceRiskWhiteLabelApp)
    return (
      <Switch>
        <Route path={`${path}/organization`} component={OrganizationPage} />
        <Redirect to={`${path}/organization`} />
      </Switch>
    );

  // we need to maintain old datev redirection link
  // as it takes a lifelong to change it on their side
  if (location.pathname.includes(adminPaths.settingsDatevOld))
    return (
      <Redirect to={{ ...location, pathname: adminPaths.settingsDatev }} />
    );

  return (
    <Switch>
      {canUser('integrations-settings-page:visit') && (
        <Route path={`${path}/integrations`} component={IntegrationsPage} />
      )}
      <Route path={`${path}/organization`} component={OrganizationPage} />
      <Route path={`${path}/accounting`} component={AccountingPage} />
      <Route path={`${path}/feature-modules`} component={FeatureModulesPage} />
      <Redirect
        to={
          isOrgInOnboarding && canUser('integrations-settings-page:visit')
            ? `${path}/integrations`
            : `${path}/organization`
        }
      />
    </Switch>
  );
};

export default withPageConfig(SettingsRoutes, {
  permission: 'settings-page:visit',
});
