import { ChangeEvent, useEffect, useState } from 'react';
import { validateIBAN } from 'ibantools';
import { Trans, useTranslation } from 'react-i18next';
import { TextField } from 'elements';
import useInputSelection from 'hooks/useInputSelection';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Bank, NetworkErrorCode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';

interface State {
  isLoading: boolean;
  bank: Bank | null;
  errorCode: NetworkErrorCode | null;
}

interface Props {
  value: string;
  onChange: (value: string, isValid: boolean) => void;
  disabled: boolean;
}

const IbanInput = ({ value, onChange, disabled }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const inputSelection = useInputSelection();
  const [state, setState] = useState<State>({
    isLoading: false,
    bank: null,
    errorCode: null,
  });

  useEffect(() => {
    let active = true;

    (async () => {
      try {
        if (!validateIBAN(value).valid) return;

        setState((prevState) => ({ ...prevState, isLoading: true }));
        const bank = await api.getBank(value);
        if (!mounted.current || !active) return;
        setState((prevState) => ({ ...prevState, bank, isLoading: false }));
        onChange(value, true);
      } catch (error) {
        if (!mounted.current || !active) return;
        const errorCode = getNetworkErrorCode(error);
        if (
          errorCode === NetworkErrorCode.notFound ||
          errorCode === NetworkErrorCode.invalidIban
        ) {
          setState((prevState) => ({
            ...prevState,
            errorCode,
            isLoading: false,
          }));
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [value]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const iban = e.target.value.replace(/[^A-Za-z\d]/g, '').toUpperCase();
    onChange(iban, false);
    setState((prevState) => ({
      ...prevState,
      bank: null,
      errorCode: null,
    }));
    inputSelection.onChange(e);
  };

  const onInputBlur = () => {
    if (!value || state.isLoading || state.errorCode || state.bank) return;
    setState((prevState) => ({
      ...prevState,
      errorCode: NetworkErrorCode.invalidIban,
    }));
  };

  return (
    <>
      <TextField
        inputRef={inputSelection.ref}
        label={t('ibanInput.iban')}
        name="iban"
        value={value}
        onChange={onInputChange}
        onBlur={onInputBlur}
        disabled={disabled}
        inputProps={{ maxLength: 50 }}
        error={
          state.errorCode === NetworkErrorCode.invalidIban ||
          state.errorCode === NetworkErrorCode.notFound
        }
        helperText={(() => {
          if (state.errorCode === NetworkErrorCode.invalidIban)
            return t('ibanInput.ibanInvalidError');
          if (state.errorCode === NetworkErrorCode.notFound)
            return t('ibanInput.ibanNotFoundError');
          if (state.bank)
            return (
              <Trans
                i18nKey="ibanInput.bankDetails"
                defaults="{{name}} (BIC: {{bic}})"
                values={state.bank}
              />
            );
        })()}
      />
    </>
  );
};

export default IbanInput;
