import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  CircularProgress,
  Grid,
  InfoIcon,
  Link,
  Tooltip,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useUrls from 'hooks/useUrls';
import { ContentContainer, SettingsGroupBox } from 'layout';
import { FxFeeAndVolume, SubscriptionPlan } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getMoneyWithOppositeSign } from 'services/utils';

interface Props {
  subscriptionPlan: SubscriptionPlan;
}

const FxFeeAndVolumeSection = ({ subscriptionPlan }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { HELP_CENTER_FEES_ON_FX_TRANSACTIONS_URL } = useUrls();
  const {
    state: { organization },
  } = useGlobalState();
  const [fxFeeAndVolume, setFxFeeAndVolume] = useState<FxFeeAndVolume | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const period = moment().format('MMM YYYY');

  const getData = async () => {
    try {
      const data = await api.getFxFeeAndVolume({
        organizationId: organization!.id,
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      });
      if (!mounted.current) return;
      setFxFeeAndVolume(data);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <SettingsGroupBox>
      <ContentContainer>
        <Box display="flex" alignItems="center" mb={3}>
          <Typography variant="h6">
            {t('subscriptionPlanSettingsSubPage.fxFeeAndVolumeSection.title')}
          </Typography>
          <Tooltip
            title={
              <Trans
                i18nKey="subscriptionPlanSettingsSubPage.fxFeeAndVolumeSection.tooltip"
                components={{
                  a: (
                    <Link
                      href={HELP_CENTER_FEES_ON_FX_TRANSACTIONS_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="inherit"
                    />
                  ),
                }}
              />
            }
          >
            <InfoIcon
              fontSize="small"
              sx={{ ml: 0.5, color: 'text.secondary' }}
            />
          </Tooltip>
        </Box>

        {isLoading && <CircularProgress size="small" />}

        {!isLoading && !fxFeeAndVolume && (
          <Typography variant="body2" color="error.main">
            {t('errors.loadData')}
          </Typography>
        )}

        {!isLoading && fxFeeAndVolume && (
          <Grid container>
            <Grid item xs={4} pr={2}>
              <Typography variant="overline">
                {t(
                  'subscriptionPlanSettingsSubPage.fxFeeAndVolumeSection.fxTransactionVolume',
                  { period }
                )}
              </Typography>
              <Typography variant="h5">
                <FormatMoney
                  value={getMoneyWithOppositeSign(fxFeeAndVolume.txVolume)}
                  fractionalPart
                />
              </Typography>
              {fxFeeAndVolume.txVolume.value > 0 && (
                <Typography variant="body2" color="text.secondary">
                  {t(
                    'subscriptionPlanSettingsSubPage.fxFeeAndVolumeSection.refund'
                  )}
                </Typography>
              )}
            </Grid>

            <Grid item xs={4} pr={2}>
              <Typography variant="overline">
                {t(
                  'subscriptionPlanSettingsSubPage.fxFeeAndVolumeSection.fxFeeRate'
                )}
              </Typography>
              <Typography variant="h5">
                <FormatPercent value={subscriptionPlan.fxFeePercentage} />
              </Typography>
            </Grid>

            <Grid item xs={4} pr={2}>
              <Typography variant="overline">
                {t(
                  'subscriptionPlanSettingsSubPage.fxFeeAndVolumeSection.fxFeeAmount',
                  { period }
                )}
              </Typography>
              <Typography variant="h5">
                <FormatMoney
                  value={getMoneyWithOppositeSign(fxFeeAndVolume.fxFee)}
                  fractionalPart
                />
              </Typography>
              {fxFeeAndVolume.fxFee.value > 0 && (
                <Typography variant="body2" color="text.secondary">
                  {t(
                    'subscriptionPlanSettingsSubPage.fxFeeAndVolumeSection.refund'
                  )}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </ContentContainer>
    </SettingsGroupBox>
  );
};

export default FxFeeAndVolumeSection;
