import { useTranslation } from 'react-i18next';
import { IntegrationTypeIcon } from 'domains/partner/components';
import { Box, CheckCircleIcon, CircleDashedIcon, Tooltip } from 'elements';
import useIsAdminApp from 'hooks/useIsAdminApp';
import usePartnerName from 'hooks/usePartnerName';
import {
  IntegrationType,
  TransactionExportStatus,
  TransactionStatus,
} from 'services/constants';

interface Props {
  status: TransactionStatus;
  exportStatus: TransactionExportStatus;
  integrationType: IntegrationType | null;
}

const CirculaIconComponent = ({ exportStatus }: Partial<Props>) => {
  const { t } = useTranslation();

  if (exportStatus === TransactionExportStatus.exported) {
    return (
      <Tooltip title={t('tooltips.transactionCirculaSubmitted')}>
        <Box lineHeight={0}>
          <IntegrationTypeIcon integrationType={IntegrationType.circula} />
        </Box>
      </Tooltip>
    );
  }
  if (exportStatus === TransactionExportStatus.notExported) {
    return (
      <Tooltip title={t('tooltips.transactionCirculaSync')}>
        <Box lineHeight={0}>
          <IntegrationTypeIcon
            integrationType={IntegrationType.circula}
            sx={{ opacity: 0.5 }}
          />
        </Box>
      </Tooltip>
    );
  }
  return null;
};

const BezalaIconComponent = ({ status }: Partial<Props>) => {
  const { t } = useTranslation();

  if (status === TransactionStatus.pending) {
    return (
      <Tooltip title={t('tooltips.transactionBezalaSynced')}>
        <Box lineHeight={0}>
          <IntegrationTypeIcon
            integrationType={IntegrationType.bezala}
            sx={{ color: 'textSecondary', opacity: 0.5 }}
          />
        </Box>
      </Tooltip>
    );
  }
  if (
    [TransactionStatus.confirmed, TransactionStatus.declined].includes(status!)
  ) {
    return (
      <Tooltip title={t('tooltips.transactionBezalaBookedAndSynced')}>
        <Box lineHeight={0}>
          <IntegrationTypeIcon integrationType={IntegrationType.bezala} />
        </Box>
      </Tooltip>
    );
  }
  return null;
};

const KlippaIconComponent = ({ exportStatus }: Partial<Props>) => {
  const { t } = useTranslation();

  if (exportStatus === TransactionExportStatus.exported) {
    return (
      <Tooltip title={t('tooltips.transactionKlippaExported')}>
        <Box lineHeight={0}>
          <IntegrationTypeIcon integrationType={IntegrationType.klippa} />
        </Box>
      </Tooltip>
    );
  }
  if (exportStatus === TransactionExportStatus.notExported) {
    return (
      <Tooltip title={t('tooltips.transactionKlippaSynced')}>
        <Box lineHeight={0}>
          <IntegrationTypeIcon
            integrationType={IntegrationType.klippa}
            sx={{ color: 'textSecondary', opacity: 0.5 }}
          />
        </Box>
      </Tooltip>
    );
  }
  return null;
};

const NoneIntegrationIconComponent = ({ exportStatus }: Partial<Props>) => {
  const { t } = useTranslation();
  const isAdminApp = useIsAdminApp();
  const partnerName = usePartnerName();

  if (exportStatus === TransactionExportStatus.exported) {
    return (
      <Tooltip
        title={
          isAdminApp
            ? (t('tooltips.transactionExported') as string)
            : (t('tooltips.cardholderTransactionExported') as string)
        }
      >
        <Box lineHeight={0}>
          <CheckCircleIcon color="action" />
        </Box>
      </Tooltip>
    );
  }
  if (exportStatus === TransactionExportStatus.inExportQueue) {
    return (
      <Tooltip
        title={
          isAdminApp
            ? t('tooltips.transactionInExportQueue')
            : t('tooltips.cardholderTransactionInExportQueue', {
                partnerName,
              })
        }
      >
        <Box lineHeight={0}>
          <CircleDashedIcon color="action" />
        </Box>
      </Tooltip>
    );
  }
  return null;
};

export const IntegrationCell = ({
  status,
  exportStatus,
  integrationType,
}: Props) => {
  if (integrationType === IntegrationType.circula)
    return <CirculaIconComponent exportStatus={exportStatus} />;

  if (integrationType === IntegrationType.bezala)
    return <BezalaIconComponent status={status} />;

  if (integrationType === IntegrationType.klippa)
    return <KlippaIconComponent exportStatus={exportStatus} />;

  if (integrationType === IntegrationType.none)
    return <NoneIntegrationIconComponent exportStatus={exportStatus} />;

  return null;
};
