import { useTranslation } from 'react-i18next';
import { FileXIcon, Link, Paper, Typography } from 'elements';

// use this component to replace receipt error in Tx details (for later)
const ReceiptPreviewError = ({ onRetry }: { onRetry: () => void }) => {
  const { t } = useTranslation();

  return (
    <Paper
      variant="tinted"
      sx={{
        maxWidth: 300,
        width: '100%',
        maxHeight: 280,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <FileXIcon fontSize="large" />
      <Typography mt={2}>{t('previewDialog.previewErrorMessage')}</Typography>
      <Link component="button" variant="body1" onClick={onRetry}>
        {t('common.retry')}
      </Link>
    </Paper>
  );
};

export default ReceiptPreviewError;
