import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  DateRangePicker,
  Typography,
} from 'elements';

interface Props {
  label?: string;
  placeholder?: string;
  fromDate: Moment | null;
  toDate: Moment | null;
  onChange: (fromDate: Moment | null, toDate: Moment | null) => void;
}

export const DateFilter = ({
  label,
  placeholder,
  fromDate,
  toDate,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<CaretDownIcon />}>
        <Typography>{label || t('filters.dates')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <DateRangePicker
          disableFuture
          // don't set values from state, because
          // when user attempts to set date manually we get validation errors
          // that reset the state in onChange handler
          value={[fromDate, toDate]}
          onChange={(value, { validationError }) =>
            onChange(
              validationError[0] ? null : value[0],
              validationError[1] ? null : value[1]
            )
          }
          slotProps={{
            textField: {
              size: 'medium',
              placeholder: placeholder || label || t('filters.dates'),
            },
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};
