import { useMemo } from 'react';
import { PartnerScope } from 'services/constants';
import { useGlobalState } from './index';

const useSourcePartnerScopeCheck = (partnerScope: PartnerScope) => {
  const {
    state: { organization, partners },
  } = useGlobalState();

  return useMemo(
    () =>
      Boolean(
        partners?.find(
          (item) =>
            item.partnerId === organization!.partnerId &&
            item.partnerScope === partnerScope
        )
      ),
    [organization!.partnerId, partners]
  );
};

export default useSourcePartnerScopeCheck;
