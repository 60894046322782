import React from 'react';
import { useTranslation } from 'react-i18next';
import { BillStatus } from 'services/constants';
import { StatusBadge } from './StatusBadge';

export const StatementStatusBadge = ({ status }: { status: BillStatus }) => {
  const { t } = useTranslation();
  const label = t(`statementStatuses.${status}`);

  switch (status) {
    case BillStatus.ongoing:
      return <StatusBadge type="primary">{label}</StatusBadge>;
    case BillStatus.open:
      return <StatusBadge type="info">{label}</StatusBadge>;
    case BillStatus.overdue:
      return <StatusBadge type="secondary">{label}</StatusBadge>;
    case BillStatus.closed:
      return <StatusBadge type="success">{label}</StatusBadge>;
    default:
      return null;
  }
};
