import { FormikErrors, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingSystem,
  AddSupplierPayload,
  NetworkErrorCode,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  trimObjValues,
} from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
}

const AddSupplierDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: '',
      accountNumber: '',
      organizationId: organization!.id,
    },
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        const trimmedValues: AddSupplierPayload = { ...trimObjValues(values) };
        await api.addSupplier(trimmedValues);
        if (!mounted.current) return;
        onSuccess();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (
          getNetworkErrorCode(error) === NetworkErrorCode.supplierNameIsTaken
        ) {
          setFieldError(
            'name',
            t('addSupplierDialog.supplierNameIsTakenError')
          );
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};

      if (
        accountingSettings!.accountingSystem === AccountingSystem.datev &&
        values.name.length > 50
      ) {
        errors.name = t('addSupplierDialog.supplierNameLengthError', {
          limit: 50,
        });
      }

      return errors;
    },
  });

  const hasEmptyField = !formik.values.name.trim();

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('addSupplierDialog.title')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} noValidate id="add-supplier-form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t('addSupplierDialog.nameTitle')}
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue(e.target.name, e.target.value, false);
                  formik.setFieldError(e.target.name, undefined);
                }}
                disabled={formik.isSubmitting}
                error={!!formik.errors.name}
                helperText={formik.errors.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('addSupplierDialog.accountNumberTitle')}
                name="accountNumber"
                value={formik.values.accountNumber}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue(e.target.name, e.target.value, false);
                  formik.setFieldError(e.target.name, undefined);
                }}
                disabled={formik.isSubmitting}
                error={!!formik.errors.accountNumber}
                helperText={
                  formik.errors.accountNumber && formik.errors.accountNumber
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={hasEmptyField || formik.isSubmitting}
          type="submit"
          form="add-supplier-form"
        >
          {t('addSupplierDialog.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(AddSupplierDialog);
