import {
  DateRangePicker as DateRangePickerMui,
  DateRangePickerProps,
} from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

export const DateRangePicker = ({
  slotProps = {},
  ...props
}: DateRangePickerProps<Moment>) => {
  const { t } = useTranslation();

  return (
    <DateRangePickerMui
      slots={{ field: SingleInputDateRangeField }}
      slotProps={{
        field: {
          format: 'D MMM YY',
        },
        textField: {
          size: 'medium',
          placeholder: t('filters.dates', { defaultValue: 'Dates' }),
        },
        ...slotProps,
      }}
      {...props}
    />
  );
};

export type { DateRangePickerProps };
