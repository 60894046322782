import { GridColDef, GridSortModel } from './index';

export const getAllowedSortKeysDataGrid = (columns: GridColDef[]) =>
  columns
    .filter((column) => !('sortable' in column) || column.sortable)
    .map((column) => column.field) as string[];

export const getSortModel = (sort: string): GridSortModel => [
  {
    field: sort.slice(1),
    sort: sort.slice(0, 1) === '+' ? 'asc' : 'desc',
  },
];

export const getNewSortParam = (sort: GridSortModel) => {
  const s = sort[0];
  if (!s) return;
  return `${s.sort === 'asc' ? '+' : '-'}${s.field}`;
};
