import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { internalRootPathsArray } from './paths';

const useToggleSidebar = () => {
  const { path } = useRouteMatch();
  const { dispatch } = useGlobalState();
  const isInternalRootPath = useMemo(
    () => internalRootPathsArray.some((value) => value === path),
    [path]
  );

  useEffect(() => {
    dispatch({ type: 'TOGGLE_SIDEBAR', payload: !isInternalRootPath });
  }, [isInternalRootPath]);
};

export default useToggleSidebar;
