import { generatePath } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { getPath } from 'services/utils';

const AccountEntriesRouteRedirect = () => {
  const accountingTransactionPath = getPath('accountingTransaction');
  const accountingEntryPath = getPath('accountingEntry');
  const accountingPath = getPath('accounting');

  return (
    <>
      <Route
        exact
        path={accountingTransactionPath}
        render={({ match, location }) => (
          <Redirect
            to={`${generatePath(accountingPath, {
              orgId: match.params.orgId,
            })}${location.search}`}
          />
        )}
      />
      <Route
        exact
        path={accountingEntryPath}
        render={({ match, location }) => (
          <Redirect
            to={`${generatePath(accountingPath, {
              orgId: match.params.orgId,
            })}${location.search}`}
          />
        )}
      />
    </>
  );
};

export default AccountEntriesRouteRedirect;
