import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBadge } from 'elements';
import { BankSubmissionStatus } from 'services/constants';

interface Props {
  status: BankSubmissionStatus;
}

const BankSubmissionStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();
  const label = t(`int.bankSubmissionStatuses.${status}`);

  switch (status) {
    case BankSubmissionStatus.draft:
      return <StatusBadge color="primary" label={label} />;
    case BankSubmissionStatus.pendingApproval:
      return <StatusBadge color="secondary" label={label} />;
    case BankSubmissionStatus.validated:
      return <StatusBadge color="success" label={label} />;
    case BankSubmissionStatus.submitted:
      return <StatusBadge color="info" label={label} />;
    default:
      return null;
  }
};

export default BankSubmissionStatusBadge;
