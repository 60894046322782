import React from 'react';
import CompleteComplianceCheckSection from './CompleteComplianceCheckSection';
import ComplianceRatingSection from './ComplianceRatingSection';

const ComplianceAssessmentSubPage = () => {
  return (
    <>
      <ComplianceRatingSection />
      <CompleteComplianceCheckSection />
    </>
  );
};

export default ComplianceAssessmentSubPage;
