import React, { ComponentType, MutableRefObject, useCallback } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

interface Props<T> {
  data: T[];
  hasNextPage: boolean;
  loadNextPage: (startIndex: number, endIndex: number) => any;
  height: number;
  itemHeight: number;
  children: ComponentType<ListChildComponentProps<T[]>>;
  scrollableListRef?: MutableRefObject<FixedSizeList | null>;
}

const InfiniteScroll = <T extends unknown>({
  data,
  hasNextPage,
  loadNextPage,
  height,
  itemHeight,
  children,
  scrollableListRef,
}: Props<T>) => {
  const itemCount = hasNextPage ? data.length + 1 : data.length;

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || !!data[index],
    [hasNextPage, data]
  );

  return (
    <InfiniteLoader
      itemCount={itemCount}
      isItemLoaded={isItemLoaded}
      loadMoreItems={loadNextPage}
    >
      {({ onItemsRendered, ref: setRef }) => (
        <FixedSizeList
          width="100%"
          height={height}
          itemSize={itemHeight}
          itemData={data}
          itemCount={itemCount}
          onItemsRendered={onItemsRendered}
          ref={(ref) => {
            // @ts-ignore
            setRef(ref);
            if (scrollableListRef) scrollableListRef.current = ref;
          }}
        >
          {children}
        </FixedSizeList>
      )}
    </InfiniteLoader>
  );
};

export default InfiniteScroll;
