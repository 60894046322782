import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddressDetails from 'components/AddressDetails';
import { Button, EditButton } from 'components/Button';
import { useOnboardingContext } from 'components/OnboardingPage/useOnboardingContext';
import { DeliveryAddressChangeDialog } from 'domains/organization/dialogs';
import { Box, StatusBadge } from 'elements';
import { Onboarding, Organization } from 'services/constants';
import { Group, GroupDescription, GroupLabel } from './style';

interface DeliveryAddressFormGroupProps {
  onboarding: Onboarding;
  label: ReactNode;
}

const DeliveryAddressFormGroup = ({
  onboarding,
  label,
}: DeliveryAddressFormGroupProps) => {
  const { t } = useTranslation();
  const {
    actions: { updateOnboarding },
  } = useOnboardingContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onUpdated = (organization: Organization) => {
    updateOnboarding({
      deliveryAddress: organization.deliveryAddress!,
    });
    setIsDialogOpen(false);
  };

  const onCancelEdit = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Group $alignItems="flex-start">
        <GroupLabel $lineHeight={32}>{label}</GroupLabel>

        <Box display="flex" alignItems="center">
          {onboarding.deliveryAddress ? (
            <>
              <GroupDescription $pt={7}>
                <AddressDetails address={onboarding.deliveryAddress} />
              </GroupDescription>
              <EditButton
                $m="0 0 0 10px"
                onClick={() => setIsDialogOpen(true)}
              />
            </>
          ) : (
            <>
              <Button variant="outlined" onClick={() => setIsDialogOpen(true)}>
                {t('onboardingPage.addInfoBtn')}
              </Button>
              <StatusBadge
                sx={{ ml: 2 }}
                label={t('common.badge.informationRequired')}
                color="warning"
              />
            </>
          )}
        </Box>
      </Group>

      <DeliveryAddressChangeDialog
        label={label}
        open={isDialogOpen}
        organizationId={onboarding.organizationId}
        deliveryAddress={onboarding.deliveryAddress}
        billingAddress={onboarding.billingAddress}
        onSuccess={onUpdated}
        onClose={onCancelEdit}
      />
    </>
  );
};

export default DeliveryAddressFormGroup;
