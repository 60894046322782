import { Box, Typography } from '@mui/material';
import { DropzoneState } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { UploadSimpleIcon } from 'elements/TemporaryIcon';
import { Variant } from './index';

export interface DropzoneIdleProps extends Pick<DropzoneState, 'isDragReject'> {
  variant?: Variant;
  description?: React.ReactNode;
  secondaryDescription?: React.ReactNode;
  dragRejectDescription?: React.ReactNode;
}

const DropzoneIdleComponent = ({
  variant,
  description,
  secondaryDescription,
  dragRejectDescription,
  isDragReject,
}: DropzoneIdleProps) => {
  const { t } = useTranslation();

  return (
    <Box textAlign="center" lineHeight={0}>
      <Box
        sx={{
          width: '40px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto',
        }}
      >
        <UploadSimpleIcon
          color={
            variant === 'error' ? 'inherit' : isDragReject ? 'error' : 'primary'
          }
        />
      </Box>
      <Typography variant="body1" mt={1}>
        {description || t('dropzone.description')}
      </Typography>
      {!isDragReject && secondaryDescription && (
        <Typography
          variant="body2"
          mt={1}
          color={variant === 'error' ? 'inherit' : 'textSecondary'}
        >
          {secondaryDescription}
        </Typography>
      )}
      {isDragReject && (
        <Typography
          variant="body2"
          mt={1}
          color={variant === 'error' ? 'inherit' : 'error.main'}
        >
          {dragRejectDescription || t('dropzone.dragRejectDescription')}
        </Typography>
      )}
    </Box>
  );
};

export default DropzoneIdleComponent;
