import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { OrganizationToHubspotLinkDialog } from 'domains/organization/dialogs';
import { Grid, LabeledValue } from 'elements';
import { useCanUser } from 'services/rbac';

const OrganizationToHubspotLinkFormGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onUpdated = (hubspotId: string) => {
    dispatch({
      type: 'SET_ORGANIZATION_DATA',
      payload: {
        organization: {
          ...organization!,
          hubspotId,
        },
      },
    });
    setIsDialogOpen(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <LabeledValue
          label={t('int.generalTermsPage.onboardingSettingsSection.hubspotId')}
          value={organization!.hubspotId || '-'}
          onEdit={
            canUser('org-hubspot-id:update')
              ? () => setIsDialogOpen(true)
              : undefined
          }
        />
      </Grid>

      <OrganizationToHubspotLinkDialog
        open={isDialogOpen}
        label={t('int.generalTermsPage.onboardingSettingsSection.hubspotId')}
        existingHubspotId={organization!.hubspotId || ''}
        onSuccess={onUpdated}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default OrganizationToHubspotLinkFormGroup;
