import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Chip,
  DataGridCell,
  GridColDef,
  IconButton,
  PencilSimpleIcon,
  StatusBadge,
} from 'elements';
import { AccountingItemStatus, Project } from 'services/constants';

const useColumns = (onEdit: (item: Project) => void) => {
  const { t, i18n } = useTranslation();
  const {
    state: { teams },
  } = useGlobalState();

  return useMemo(() => {
    const columns: GridColDef<Project>[] = [
      {
        field: 'name',
        headerName: t('constUnitsSubPage.nameLabel'),
        flex: 1,
        renderCell: ({ row }) => (
          <Box display="flex" alignItems="center" overflow="hidden">
            <DataGridCell>{row.name}</DataGridCell>
            {row.status === AccountingItemStatus.inactive && (
              <StatusBadge
                color="error"
                sx={{ ml: 1, flexShrink: 0 }}
                label={t('constUnitsSubPage.inactiveBadgeLabel')}
              />
            )}
          </Box>
        ),
      },
      {
        field: 'costUnit',
        headerName: t('constUnitsSubPage.costUnitLabel'),
        flex: 1,
      },
      {
        field: 'teams',
        headerName: t('constUnitsSubPage.teamsLabel'),
        sortable: false,
        flex: 1,
        renderCell: ({ row }) =>
          row.teams.length ? (
            <>
              {row.teams
                .slice(0, 2)
                .map((team, index) =>
                  team ? (
                    <Chip
                      sx={{ ml: index > 0 ? 1 : 0 }}
                      variant="tinted"
                      key={team.id}
                      label={team.name}
                      size="small"
                    />
                  ) : null
                )}
              {row.teams.length > 2 && (
                <Chip
                  sx={{ ml: 1 }}
                  color="primary"
                  size="small"
                  label={`+${row.teams.length - 2}`}
                />
              )}
            </>
          ) : (
            t('constUnitsSubPage.allTeamsOption')
          ),
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 72,
        getActions: ({ row }) => [
          <IconButton onClick={() => onEdit(row)}>
            <PencilSimpleIcon />
          </IconButton>,
        ],
      },
    ];

    return columns;
  }, [i18n.language, teams]);
};

export default useColumns;
