import { useMemo } from 'react';
import styled from 'styled-components';
import { Box, Typography, TypographyProps } from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import { Role } from 'services/rbac';

const Wrapper = styled(Box)`
  display: inline-block;
  font-size: 8px;
  line-height: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey10};
  vertical-align: top;
  text-transform: uppercase;
`;

interface Props extends TypographyProps {
  roles?: string[];
  // todo: temporary prop, should be removed once we redesign
  isNewDesign?: boolean;
}

// Pliant Pro API type overline
// also used/known as embedded partner membership
const MemberTypeOverline = ({
  roles,
  isNewDesign = false,
  ...props
}: Props) => {
  const { isExternalApp } = useCurrentApp();

  const hasSeparator = useMemo(
    () =>
      !!roles?.some((role) =>
        [Role.accountant, Role.orgAdmin, Role.accountOwner].includes(
          role as Role
        )
      ),
    [roles]
  );

  if (isExternalApp) return null;

  // will be removed later
  if (!isNewDesign)
    return (
      <Wrapper ml={hasSeparator ? '-7px' : 1}>
        {hasSeparator && ', '} API
      </Wrapper>
    );

  return (
    <Typography variant="overline2" ml={hasSeparator ? 0 : 1} {...props}>
      {hasSeparator && ', '}
      API
    </Typography>
  );
};

export default MemberTypeOverline;
