import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { cardholderPaths } from 'components/App';
import { CardsCell } from 'domains/card/components';
import {
  MemberRoleOverline,
  MemberStatusBadge,
} from 'domains/member/components';
import {
  Badge,
  DataGridCell,
  drawerPlaceholderColumn,
  GridColDef,
  gridUtils,
  Tooltip,
} from 'elements';
import { Member } from 'services/constants';
import { getFullName } from 'services/utils';

const useColumns = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { teamId } = useParams<{ teamId: string }>();

  return useMemo(() => {
    const columns: GridColDef<Member>[] = [
      {
        headerName: t('managerTeamsPage.name'),
        field: 'firstName',
        flex: 180,
        renderCell: ({ row }) => (
          <DataGridCell>
            {getFullName(row.firstName, row.lastName)}
            <MemberRoleOverline
              roles={row.roles}
              ml={1}
              color="textSecondary"
              sx={{
                verticalAlign: 'top',
              }}
            />
            <Tooltip
              title={`${row.activeRequestsCount} ${t(
                'managerTeamsPage.requests',
                {
                  count: row.activeRequestsCount,
                }
              )}`}
            >
              <Badge
                badgeContent={row.activeRequestsCount}
                color="warning"
                sx={{
                  ml: 1,
                  '.MuiBadge-badge': { position: 'static', transform: 'none' },
                }}
              />
            </Tooltip>
          </DataGridCell>
        ),
      },
      {
        headerName: t('managerTeamsPage.status'),
        field: 'status',
        flex: 100,
        renderCell: ({ row }) => <MemberStatusBadge status={row.status} />,
      },
      {
        headerName: t('managerTeamsPage.cards'),
        field: 'cards',
        flex: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <CardsCell
            cards={row.cards}
            onCardClick={(e, cardId) => {
              e.stopPropagation();
              history.push(
                generatePath(cardholderPaths.teamCardDetails, {
                  teamId,
                  cardId,
                })
              );
            }}
          />
        ),
      },
      drawerPlaceholderColumn,
    ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language, teamId]);
};

export default useColumns;
