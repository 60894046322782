import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from 'elements';
import useMounted from 'hooks/useMounted';
import {
  AccountGroup,
  CardAccountCurrency,
  PartnerIdType,
  SupportedCountry,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

interface Props {
  country: SupportedCountry;
  currency: CardAccountCurrency;
  partnerId: PartnerIdType;
  value: AccountGroup | null;
  onChange: (value: AccountGroup | null) => void;
  mode?: 'create' | 'edit';
}

const AccountGroupSelect = ({
  country,
  currency,
  partnerId,
  value,
  onChange,
  mode = 'create',
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [accountGroups, setAccountGroups] = useState<AccountGroup[]>(
    mode === 'create' ? [] : [value!]
  );

  const getData = async () => {
    try {
      if (mode === 'create') {
        onChange(null);
      }
      setIsLoading(true);
      setError('');
      const data = await api.getAccountGroups({ country, currency, partnerId });
      if (!mounted.current) return;
      if (data.accountGroups.length > 0) {
        setAccountGroups(data.accountGroups);
        if (mode === 'create') {
          onChange(data.accountGroups[0]);
        } else {
          if (!data.accountGroups.includes(value!)) {
            onChange(data.accountGroups[0]);
          }
        }
      } else {
        onChange(null);
        setError(t('int.accountGroupSelect.noAccountGroupsError'));
      }
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setAccountGroups([]);
      setError(t('errors.loadData'));
      setIsLoading(false);
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, [country, currency, partnerId]);

  return (
    <FormControl fullWidth disabled={isLoading || !!error} error={!!error}>
      <InputLabel id="account-group-select-label">
        {t('int.accountGroupSelect.label')}
      </InputLabel>
      <Select
        value={value || ''}
        onChange={(event) => onChange(event.target.value as AccountGroup)}
        renderValue={(selected) =>
          t(`int.accountGroupSelect.items.${selected}`)
        }
        startAdornment={
          isLoading ? (
            <InputAdornment position="start">
              <CircularProgress size="small" />
            </InputAdornment>
          ) : null
        }
        labelId="account-group-select-label"
      >
        {accountGroups.map((item) => (
          <MenuItem key={item} value={item}>
            {t(`int.accountGroupSelect.items.${item}`)}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default AccountGroupSelect;
