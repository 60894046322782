import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IbanInputV2 } from 'domains/billing/components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { NetworkErrorCode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';

interface State {
  iban: string;
  isIbanValid: boolean;
  isLoading: boolean;
}

interface Props extends DialogProps {
  onClose: () => void;
}

const AddBankAccountDialog = (props: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    iban: '',
    isIbanValid: false,
    isLoading: false,
  });

  const addBankAccount = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (!state.isIbanValid) {
        // Trigger the blur event on IbanInput to show the validation error
        // when users submit a form by pressing the enter key
        if (document.activeElement instanceof HTMLInputElement)
          document.activeElement.blur();
        return;
      }

      setState((prevState) => ({ ...prevState, isLoading: true }));
      await api.addBankAccount(state.iban!);
      if (!mounted.current) return;
      window.location.reload();
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      if (getNetworkErrorCode(error) === NetworkErrorCode.ibanIsAlreadyInUse) {
        enqueueSnackbar(t('addBankAccountDialog.ibanInUseError'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('addBankAccountDialog.title')}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={2}>
          {t('addBankAccountDialog.description')}
        </Typography>
        <form onSubmit={addBankAccount} id="add-bank-account-form" noValidate>
          <IbanInputV2
            value={state.iban}
            onChange={(iban, isIbanValid) =>
              setState((prevState) => ({ ...prevState, iban, isIbanValid }))
            }
            disabled={state.isLoading}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          form="add-bank-account-form"
          type="submit"
          disabled={!state.iban || state.isLoading}
        >
          {t('common.button.submit')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(AddBankAccountDialog);
