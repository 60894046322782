import { capitalize } from 'lodash';
import { SubscriptionPlanType } from 'services/constants';

const getSubscriptionPlanName = (planType: SubscriptionPlanType) => {
  if (planType.includes('PARTNER_')) {
    const partnerName = planType
      .replace('PARTNER_', '')
      .split('_')
      .map((item) => capitalize(item.toLowerCase()))
      .join(' ');

    return `Partner (${partnerName})`;
  }

  return capitalize(planType.toLowerCase());
};

export default getSubscriptionPlanName;
