import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  StatusBadge,
  Typography,
} from 'elements';
import { PartnerAuthStatus } from 'services/constants';
import { IntegrationItem } from './index';

interface Props {
  integrations: IntegrationItem[];
  onIntegrationClick: (integration: IntegrationItem) => void;
}

const IntegrationsSection = ({ integrations, onIntegrationClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      {integrations.map((item) => (
        <Grid item key={item.title}>
          <Card
            sx={{
              width: 348,
              cursor: 'pointer',
              position: 'relative',
              height: '100%',
              minHeight: 310,
            }}
            onClick={() => onIntegrationClick(item)}
            variant="outlined"
          >
            {item.requiresUpgrade && (
              <StatusBadge
                sx={(theme) => ({
                  position: 'absolute',
                  top: theme.spacing(3),
                  right: theme.spacing(3),
                })}
                label={t('common.badge.upgrade')}
                variant="filled"
                color="secondary"
              />
            )}

            {item.status === PartnerAuthStatus.active && (
              <StatusBadge
                sx={(theme) => ({
                  position: 'absolute',
                  top: theme.spacing(3),
                  right: theme.spacing(3),
                })}
                label={t('integrationsPage.active')}
                variant="filled"
                color="success"
              />
            )}

            {item.status === PartnerAuthStatus.pending && (
              <StatusBadge
                sx={(theme) => ({
                  position: 'absolute',
                  top: theme.spacing(3),
                  right: theme.spacing(3),
                })}
                label={t('integrationsPage.pending')}
                color="primary"
              />
            )}

            <CardContent
              sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                height: 'inherit',
              }}
            >
              <Box mt={1} mb={3.5}>
                <Avatar
                  alt={item.name}
                  src={item.avatar}
                  sx={{
                    width: 56,
                    height: 56,
                    '.MuiAvatar-img': { objectFit: 'contain' },
                  }}
                  variant="rounded"
                />
              </Box>

              <Typography variant="h6">{item.title}</Typography>
              <Typography mt={2} mb={1} color="textSecondary" component="div">
                {item.subtitle}
              </Typography>

              <Grid container spacing={1} mt="auto">
                {item.type.map((typeItem, index) => (
                  <Grid item key={`${item.name}-type-${index}`}>
                    <Chip label={typeItem} />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default IntegrationsSection;
