import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Box, EditButton, QuestionIcon, Tooltip, Typography } from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { ContentContainer } from 'layout';
import { useCanUser } from 'services/rbac';
import GeneralAccountsChangeDialog from './GeneralAccountsChangeDialog';

const GeneralAccountsSettingsGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const partnerName = usePartnerName();
  const {
    state: { accountingSettings, cardAccounts },
  } = useGlobalState();
  const [
    isGeneralAccountsDialogOpen,
    setIsGeneralAccountsDialogOpen,
  ] = useState(false);

  return (
    <>
      <ContentContainer mb={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6">
            {t('datevExportSettingsGroup.generalAccounts')}
          </Typography>
          {canUser('accounting-settings:update') && (
            <EditButton
              sx={{ ml: 1 }}
              onClick={() => setIsGeneralAccountsDialogOpen(true)}
            />
          )}
        </Box>

        <Box mt={2}>
          {cardAccounts.length < 2 && (
            <Box display="flex" alignItems="center">
              <Typography variant="body2" mr={1}>
                {t('datevExportSettingsGroup.pliantCreditCardAccount', {
                  partnerName,
                })}
              </Typography>
              <Tooltip
                title={t(
                  'datevExportSettingsGroup.pliantCreditCardAccountTitle',
                  {
                    partnerName,
                  }
                )}
              >
                <QuestionIcon fontSize="small" />
              </Tooltip>

              <Typography ml="auto" pl={1}>
                {accountingSettings!.pliantCreditCardAccount || '-'}
              </Typography>
            </Box>
          )}

          {accountingSettings!.useSupplierAccount && (
            <Box mt={1.5} display="flex" alignItems="center">
              <Typography variant="body2" mr={1}>
                {t('datevExportSettingsGroup.defaultSupplierAccount')}
              </Typography>
              <Tooltip
                title={t(
                  'datevExportSettingsGroup.defaultSupplierAccountTitle',
                  {
                    partnerName,
                  }
                )}
              >
                <QuestionIcon fontSize="small" />
              </Tooltip>

              <Typography ml="auto" pl={1}>
                {accountingSettings!.defaultSupplierAccount || '-'}
              </Typography>
            </Box>
          )}

          <Box mt={1.5} display="flex" alignItems="center">
            <Typography variant="body2" mr={1}>
              {t('datevExportSettingsGroup.cashInTransitAccount')}
            </Typography>
            <Tooltip
              title={t('datevExportSettingsGroup.cashInTransitAccountTitle', {
                partnerName,
              })}
            >
              <QuestionIcon fontSize="small" />
            </Tooltip>

            <Typography ml="auto" pl={1}>
              {accountingSettings!.cashInTransitAccount || '-'}
            </Typography>
          </Box>
        </Box>
      </ContentContainer>

      <GeneralAccountsChangeDialog
        open={isGeneralAccountsDialogOpen}
        onClose={() => setIsGeneralAccountsDialogOpen(false)}
      />
    </>
  );
};

export default GeneralAccountsSettingsGroup;
