import { useEffect, useRef, useState } from 'react';
import { sortBy } from 'lodash';
import { useParams } from 'react-router-dom';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import { ReceiptPreviewDialog } from 'domains/transaction/components';
import TransactionDetailsMenu from 'domains/transaction/components/TransactionDetails/TransactionDetailsMenu';
import { LoaderWithOverlay } from 'elements';
import useMounted from 'hooks/useMounted';
import {
  DetailsDrawer,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  AccountingTransaction,
  MinimalTeam,
  Receipt,
  ReceiptAutomatchStatus,
  ReceiptList,
  ReceiptStatus,
  Transaction,
  TransactionEmissionList,
  TransactionExportStatus,
  TransactionReviewStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { filterMinimalTeams, useCanUser } from 'services/rbac';
import TransactionDetailsContent from './TransactionDetailsContent';

interface State {
  isLoading: boolean;
  transaction: Transaction | null;
  error: unknown;
  receipts: Receipt[];
  receiptNumberStatus: ReceiptAutomatchStatus;
  receiptDateStatus: ReceiptAutomatchStatus;
  minimalTeams: MinimalTeam[];
  accountingTransactions: AccountingTransaction[] | null;
  isReceiptPreviewDialogOpen: boolean;
  selectedReceiptId: string | null;
}

interface Props extends DetailsDrawerProps {
  isAdminApp: boolean;
  onUpdate: (
    transaction: Transaction,
    accountingTransactions: AccountingTransaction[]
  ) => void;
  refetchMissingReceiptCount?: () => void;
  onReviewUpdated?: (reviewStatus: TransactionReviewStatus) => void;
  isExportPage?: boolean;
  onChangeExportStatus?: (
    id: string,
    newExportStatus: TransactionExportStatus
  ) => void;
}

export const TransactionDetailsPage = ({
  onUpdate,
  refetchMissingReceiptCount,
  onReviewUpdated,
  isAdminApp,
  isExportPage = false,
  onChangeExportStatus,
  ...props
}: Props) => {
  const api = useImperativeApi();
  const { transactionId } = useParams<{ transactionId: string }>();
  const {
    state: { featureModules, member, organization },
  } = useGlobalState();
  const canUser = useCanUser();
  const mounted = useMounted();
  const idRef = useRef(transactionId);

  const [state, setState] = useState<State>({
    isLoading: false,
    transaction: null,
    error: null,
    receipts: [],
    receiptNumberStatus: ReceiptAutomatchStatus.NONE,
    receiptDateStatus: ReceiptAutomatchStatus.NONE,
    minimalTeams: [],
    accountingTransactions: null,
    isReceiptPreviewDialogOpen: false,
    selectedReceiptId: null,
  });

  useEffect(() => {
    if (state.transaction && state.accountingTransactions) {
      onUpdate(state.transaction, state.accountingTransactions);
    }
  }, [state.transaction, state.accountingTransactions]);

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [
        transaction,
        { receipts, receiptDateStatus, receiptNumberStatus },
        minimalTeams,
        { transactions: emissions },
        accountingTransactions,
      ] = await Promise.all([
        api.getTransaction(transactionId),
        api.getReceipts(transactionId),
        canUser('transaction-team:change')
          ? api.getMyTeams(organization!.id)
          : Promise.resolve<MinimalTeam[]>([]),
        featureModules.PLIANT_EARTH
          ? api.getTransactionEmissions({
              organizationId: organization!.id,
              transactionIds: [transactionId],
            })
          : Promise.resolve<TransactionEmissionList>({ transactions: [] }),
        api
          .getAccountingTransactions(transactionId)
          .then((data) => sortBy(data.accountingTransactions, ['index'])),
      ]);

      if (!mounted.current || transactionId !== idRef.current) return;

      setState((prevState) => ({
        ...prevState,
        transaction: {
          ...transaction,
          emission:
            emissions[0]?.emissionAmount > 0
              ? emissions[0].emissionAmount
              : null,
        },
        accountingTransactions,
        receipts,
        receiptDateStatus,
        receiptNumberStatus,
        minimalTeams: filterMinimalTeams(minimalTeams, transaction, member),
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || transactionId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        transaction: null,
        error,
        isLoading: false,
      }));
      logError(error);
    }
  };

  const refetchAccountingTransactions = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const accountingTransactions = await api
        .getAccountingTransactions(transactionId)
        .then((data) => sortBy(data.accountingTransactions, ['index']));

      if (!mounted.current || transactionId !== idRef.current) return;

      setState((prevState) => ({
        ...prevState,
        accountingTransactions,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || transactionId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        error: null,
        isLoading: false,
      }));
      logError(error);
    }
  };

  const onReceiptUpdate = async (receiptsListFromPolling?: ReceiptList) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const [
        transaction,
        { receipts, receiptDateStatus, receiptNumberStatus },
        accountingTransactions,
      ] = await Promise.all([
        api.getTransaction(transactionId),
        receiptsListFromPolling || api.getReceipts(transactionId),
        api
          .getAccountingTransactions(transactionId)
          .then((data) => sortBy(data.accountingTransactions, ['index'])),
      ]);

      if (!mounted.current || transactionId !== idRef.current) return;

      setState((prevState) => ({
        ...prevState,
        transaction: {
          // make sure we don't override emission
          ...prevState.transaction,
          ...transaction,
        },
        accountingTransactions,
        receipts,
        receiptDateStatus,
        receiptNumberStatus,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || transactionId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        error,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!transactionId) return;
    idRef.current = transactionId;
    getData();
  }, [transactionId]);

  const refetchReceipts = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const {
        receipts,
        receiptDateStatus,
        receiptNumberStatus,
      } = await api.getReceipts(transactionId);
      if (!mounted.current || transactionId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        receipts,
        receiptDateStatus,
        receiptNumberStatus,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || transactionId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        error,
        isLoading: false,
      }));
      logError(error);
    }
  };

  const onReceiptReplaced = (
    transaction: Transaction,
    oldReceipt: Receipt,
    replacedReceipt: Receipt
  ) => {
    const updatedReceipts = state.receipts.map((r) => {
      if (r.receiptId === oldReceipt.receiptId) {
        return { ...replacedReceipt };
      }
      return { ...r, autoMatched: false };
    });
    setState({
      ...state,
      transaction: {
        ...transaction,
        hasRejectedReceipts: updatedReceipts.some(
          (r) => r.status === ReceiptStatus.rejected
        ),
      },
      receipts: updatedReceipts,
    });
    refetchReceipts();
  };

  const onReceiptRejected = (transaction: Transaction, receipt: Receipt) => {
    const updatedReceipts = state.receipts.map((r) => {
      if (r.receiptId === receipt.receiptId) {
        return { ...receipt };
      }
      return { ...r, autoMatched: false };
    });
    setState({
      ...state,
      transaction: {
        ...transaction,
        hasRejectedReceipts: true,
      },
      receipts: updatedReceipts,
    });
  };

  const onAccountingSystemReceiptUpdated = async (
    receipts: Receipt[],
    shouldTriggerUpdate: boolean
  ) => {
    if (shouldTriggerUpdate) {
      setState((prevState) => ({
        ...prevState,
        receipts,
        isLoading: true,
        isReceiptPreviewDialogOpen: false,
        selectedReceiptId: null,
      }));
      try {
        await api.updateCodatReceipts(transactionId);
        if (!mounted.current || transactionId !== idRef.current) return;
        const [
          transaction,
          { receipts, receiptDateStatus, receiptNumberStatus },
        ] = await Promise.all([
          api.getTransaction(transactionId),
          api.getReceipts(transactionId),
        ]);
        if (!mounted.current || transactionId !== idRef.current) return;
        setState((prevState) => ({
          ...prevState,
          transaction: { ...prevState.transaction, ...transaction },
          receipts,
          receiptDateStatus,
          receiptNumberStatus,
          isLoading: false,
        }));
      } catch (error) {
        if (!mounted.current || transactionId !== idRef.current) return;
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          transaction: null,
          error,
        }));
        logError(error);
      }
    } else {
      setState((prevState) => ({ ...prevState, receipts }));
    }
  };

  const renderTransactionDetailsContent = (
    isReceiptPreviewDialogOpen: boolean
  ) => {
    return (
      <TransactionDetailsContent
        transaction={state.transaction!}
        accountingTransactions={state.accountingTransactions!}
        receipts={state.receipts!}
        receiptDateStatus={state.receiptDateStatus!}
        receiptNumberStatus={state.receiptNumberStatus!}
        teams={state.minimalTeams}
        isAdminApp={isAdminApp}
        isExportPage={isExportPage}
        onUpdate={(transaction, accountingTransactions) =>
          setState((prevState) => ({
            ...prevState,
            transaction,
            accountingTransactions,
          }))
        }
        onReceiptUpdate={(receiptsListFromPolling?: ReceiptList) => {
          refetchMissingReceiptCount && refetchMissingReceiptCount();
          onReceiptUpdate(receiptsListFromPolling);
        }}
        onRefresh={getData}
        refetchAccountingTransactions={refetchAccountingTransactions}
        onReviewUpdated={onReviewUpdated}
        refetchReceipts={refetchReceipts}
        isReceiptPreviewDialogOpen={isReceiptPreviewDialogOpen}
        onReceiptPreviewDialogOpen={(receiptId) =>
          setState((prevState) => ({
            ...prevState,
            isReceiptPreviewDialogOpen: true,
            selectedReceiptId: receiptId || null,
          }))
        }
        onChangeExportStatus={onChangeExportStatus}
      />
    );
  };
  const renderContent = () => {
    return (
      <>
        {renderTransactionDetailsContent(state.isReceiptPreviewDialogOpen)}
        <ReceiptPreviewDialog
          open={state.isReceiptPreviewDialogOpen}
          transaction={state.transaction!}
          receipts={state.receipts}
          selectedReceiptId={state.selectedReceiptId}
          isExportPage={isExportPage}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              isReceiptPreviewDialogOpen: false,
              selectedReceiptId: null,
            }))
          }
          onRemove={(id) => {
            refetchMissingReceiptCount && refetchMissingReceiptCount();
            // need to update receipt number automatch correctly
            if (state.receipts.length > 1) refetchReceipts();

            setState((prevState) => {
              const existingReceipts = prevState.receipts.filter(
                (item) => item.receiptId !== id
              );
              return {
                ...prevState,
                transaction: {
                  ...prevState.transaction!,
                  hasRejectedReceipts: existingReceipts.some(
                    (r) => r.status === ReceiptStatus.rejected
                  ),
                  receiptsCount: existingReceipts.length,
                },
                receiptsCount: existingReceipts.length,
                receipts: existingReceipts,
              };
            });
          }}
          onReceiptReplaced={onReceiptReplaced}
          onReceiptRejected={onReceiptRejected}
          onAccountingSystemReceiptUpdated={onAccountingSystemReceiptUpdated}
        >
          {renderTransactionDetailsContent(true)}
        </ReceiptPreviewDialog>
      </>
    );
  };

  return (
    <DetailsDrawer
      {...props}
      actionsComponent={
        state.transaction &&
        !state.isReceiptPreviewDialogOpen &&
        !isExportPage && (
          <TransactionDetailsMenu
            transaction={state.transaction}
            onUpdate={getData}
          />
        )
      }
    >
      {state.error && <WidgetError onReload={getData} />}
      {state.transaction && renderContent()}
      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(TransactionDetailsPage);
