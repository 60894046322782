import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import {
  DotsThreeOutlineVerticalIcon,
  IconButton,
  ListItemText,
  MenuContainer,
  MenuItem,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  BillPaymentType,
  OrganizationAccountStatementPayment,
  PartialPaymentStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isFailLoading: boolean;
  isFailConfirmOpen: boolean;
  isDiscardLoading: boolean;
  isDiscardConfirmOpen: boolean;
  isConvertToWtLoading: boolean;
  isConvertToWtConfirmOpen: boolean;
}

interface Props {
  payment: OrganizationAccountStatementPayment;
  onUpdate: () => void;
}

const BillPaymentItemMenu = ({ payment, onUpdate }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    isFailLoading: false,
    isFailConfirmOpen: false,
    isDiscardLoading: false,
    isDiscardConfirmOpen: false,
    isConvertToWtLoading: false,
    isConvertToWtConfirmOpen: false,
  });
  const canFailBillPayment =
    canUser('delayed-payment:retry') &&
    payment.status === PartialPaymentStatus.delayed &&
    payment.transferType === BillPaymentType.directDebit;
  const canDiscardBillPayment =
    canUser('delayed-payment:discard') &&
    (([
      PartialPaymentStatus.readyForPmt,
      PartialPaymentStatus.discarded,
    ].includes(payment.status) &&
      payment.transferType === BillPaymentType.directDebit) ||
      ([
        PartialPaymentStatus.pending,
        PartialPaymentStatus.readyForPmt,
        PartialPaymentStatus.delayed,
      ].includes(payment.status) &&
        payment.transferType === BillPaymentType.wireTransfer)) &&
    !payment.paymentBatchId;
  const canConvertToWireTransfer =
    canUser('delayed-payment:convert-to-wt') &&
    [
      PartialPaymentStatus.upcoming,
      PartialPaymentStatus.readyForPmt,
      PartialPaymentStatus.deferred,
    ].includes(payment.status) &&
    payment.transferType === BillPaymentType.directDebit &&
    !payment.paymentBatchId;

  const failBillPayment = async () => {
    try {
      setState((prevState) => ({ ...prevState, isFailLoading: true }));
      await api.failDelayedBillPayment(payment.id);
      if (!mounted.current) return;
      onUpdate();
      setState((prevState) => ({
        ...prevState,
        isFailLoading: false,
        isFailConfirmOpen: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isFailLoading: false,
        isFailConfirmOpen: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const discardBillPayment = async () => {
    try {
      setState((prevState) => ({ ...prevState, isDiscardLoading: true }));
      await api.discardDelayedBillPayment(payment.id);
      if (!mounted.current) return;
      onUpdate();
      setState((prevState) => ({
        ...prevState,
        isDiscardLoading: false,
        isDiscardConfirmOpen: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isDiscardLoading: false,
        isDiscardConfirmOpen: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const convertToWireTransfer = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isConvertToWtLoading: true,
      }));
      await api.convertBillPaymentToWireTransfer(payment.id);
      if (!mounted.current) return;
      onUpdate();
      setState((prevState) => ({
        ...prevState,
        isConvertToWtLoading: false,
        isConvertToWtConfirmOpen: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isConvertToWtLoading: false,
        isConvertToWtConfirmOpen: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  if (
    !canFailBillPayment &&
    !canDiscardBillPayment &&
    !canConvertToWireTransfer
  )
    return null;

  return (
    <>
      <MenuContainer
        button={
          <IconButton>
            <DotsThreeOutlineVerticalIcon />
          </IconButton>
        }
      >
        {canFailBillPayment && (
          <MenuItem
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                isFailConfirmOpen: true,
              }))
            }
            disabled={state.isFailLoading}
          >
            <ListItemText primary={t('int.billPaymentItemMenu.failBtn')} />
          </MenuItem>
        )}
        {canDiscardBillPayment && (
          <MenuItem
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                isDiscardConfirmOpen: true,
              }))
            }
            disabled={state.isDiscardLoading}
          >
            <ListItemText primary={t('int.billPaymentItemMenu.discardBtn')} />
          </MenuItem>
        )}
        {canConvertToWireTransfer && (
          <MenuItem
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                isConvertToWtConfirmOpen: true,
              }))
            }
            disabled={state.isConvertToWtLoading}
          >
            <ListItemText
              primary={t('int.billPaymentItemMenu.convertToWtBtn')}
            />
          </MenuItem>
        )}
      </MenuContainer>
      <ConfirmDialog
        onClose={() =>
          setState((prevState) => ({ ...prevState, isFailConfirmOpen: false }))
        }
        onSuccess={failBillPayment}
        open={state.isFailConfirmOpen}
        title={t('int.billPaymentItemMenu.failTitle')}
        loading={state.isFailLoading}
        description={t('int.billPaymentItemMenu.failDescription')}
      />
      <ConfirmDialog
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isDiscardConfirmOpen: false,
          }))
        }
        onSuccess={discardBillPayment}
        open={state.isDiscardConfirmOpen}
        title={t('int.billPaymentItemMenu.discardTitle')}
        description={t('int.billPaymentItemMenu.discardDescription')}
        loading={state.isDiscardLoading}
      />
      <ConfirmDialog
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isConvertToWtConfirmOpen: false,
          }))
        }
        onSuccess={convertToWireTransfer}
        open={state.isConvertToWtConfirmOpen}
        title={t('int.billPaymentItemMenu.convertToWtTitle')}
        description={t('int.billPaymentItemMenu.convertToWtDescription')}
        loading={state.isConvertToWtLoading}
      />
    </>
  );
};

export default BillPaymentItemMenu;
