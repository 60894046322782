import React, { FormEvent } from 'react';
import { greaterThan } from 'dinero.js';
import { useFormikContext } from 'formik';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { CardAccountSelect, DialogStepper } from 'domains/card/components';
import { FormValues, Step } from 'domains/card/dialogs/CardRequestDialog';
import useStepManager from 'domains/card/dialogs/CardRequestDialog/useStepManager';
import { useCardAccountCurrency } from 'domains/card/hooks';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  InputLabelTooltipIcon,
  MenuItem,
  MoneyField,
  Select,
  TextField,
} from 'elements';
import {
  CardConfigGroup,
  cardLimitRenewFrequencies,
  CardLimitRenewFrequency,
  CardNewType,
  EXPIRY_PERIODS,
  SINGLE_USE_EXPIRY_PERIODS,
} from 'services/constants';
import { dineroFromFloat } from 'services/utils';

export interface Props {
  onClose: () => void;
}

const LimitsAndValidityStep = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const stepManager = useStepManager();
  const formik = useFormikContext<FormValues>();
  const currency = useCardAccountCurrency(formik.values.cardAccountId);
  const cardConfigSetting = formik.values.cardConfigSetting!;
  const expiryPeriods =
    cardConfigSetting.maxUsage === 1
      ? SINGLE_USE_EXPIRY_PERIODS
      : EXPIRY_PERIODS;

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (cardConfigSetting.validityPeriodEnabled) {
      const minDate = moment();
      const maxDate = moment().add(
        expiryPeriods[expiryPeriods.length - 1],
        'months'
      );
      const validFrom = moment(formik.values.validFrom, 'YYYY-MM-DD', true);
      const validTo = moment(formik.values.validTo, 'YYYY-MM-DD', true);

      if (
        !validFrom.isValid() ||
        !validTo.isValid() ||
        !validFrom.isBetween(minDate, maxDate, 'day', '[]') ||
        !validTo.isBetween(minDate, maxDate, 'day', '[]') ||
        validFrom.isAfter(validTo)
      ) {
        return formik.setErrors({
          validFrom: t('errors.cardValidityPeriod', {
            minDate: minDate.format('D MMM YYYY'),
            maxDate: maxDate.format('D MMM YYYY'),
          }),
        });
      }
    }

    const limit = dineroFromFloat(formik.values.limit, currency);
    const transactionLimit = dineroFromFloat(
      formik.values.transactionLimit,
      currency
    );

    if (greaterThan(transactionLimit, limit))
      return formik.setErrors({
        transactionLimit: t('errors.cardTransactionLimitHigherThanLimit'),
      });

    formik.setErrors({});
    stepManager.goNext();
  };

  const renderValidityPeriodControls = () => {
    if (cardConfigSetting.validityPeriodEnabled)
      return (
        <>
          <Grid item xs={6}>
            <TextField
              type="date"
              label={t('cardRequestDialog_v2.limitsAndValidityStep.validFrom')}
              error={!!formik.errors.validFrom}
              {...formik.getFieldProps('validFrom')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="date"
              label={t('cardRequestDialog_v2.limitsAndValidityStep.validTo')}
              error={!!formik.errors.validFrom}
              {...formik.getFieldProps('validTo')}
            />
          </Grid>
          {formik.errors.validFrom && (
            <Grid item xs={12}>
              <FormHelperText error sx={{ mt: -1.5 }}>
                {formik.errors.validFrom}
              </FormHelperText>
            </Grid>
          )}
        </>
      );

    if (cardConfigSetting.cardType === CardNewType.virtual)
      return (
        <Grid item xs={12}>
          <FormControl fullWidth disabled={expiryPeriods.length === 1}>
            <InputLabel id="expiry-period-months-select-label">
              {t('cardRequestDialog_v2.limitsAndValidityStep.validityPeriod')}
              {cardConfigSetting.maxUsage === 1 && (
                <InputLabelTooltipIcon
                  title={t(
                    'cardRequestDialog_v2.limitsAndValidityStep.validityPeriodSingleUseCardTooltip',
                    { count: expiryPeriods[0] }
                  )}
                />
              )}
            </InputLabel>
            <Select<number>
              {...formik.getFieldProps('expiryPeriodMonths')}
              renderValue={(selected) =>
                `${selected} ${t(
                  'cardRequestDialog_v2.limitsAndValidityStep.months'
                )}`
              }
              labelId="expiry-period-months-select-label"
            >
              {expiryPeriods.map((period) => (
                <MenuItem key={period} value={period}>
                  {`${period} ${t(
                    'cardRequestDialog_v2.limitsAndValidityStep.months'
                  )}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );

    return null;
  };

  const isSubmitDisabled =
    !formik.values.limit || !formik.values.transactionLimit;

  return (
    <>
      <DialogStepper<Step>
        currentStep={stepManager.currentStep}
        steps={stepManager.steps}
        goTo={stepManager.goTo}
        getStepLabel={stepManager.getStepLabel}
      />
      <DialogTitle>
        {t('cardRequestDialog_v2.limitsAndValidityStep.title')}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={onSubmit}
          noValidate
          id="request-card-limits-and-validity-form"
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            {renderValidityPeriodControls()}

            {cardConfigSetting.cardAccounts.length > 1 && (
              <Grid item xs={12}>
                <CardAccountSelect
                  label={t(
                    'cardRequestDialog_v2.limitsAndValidityStep.cardAccount'
                  )}
                  cardAccountIds={cardConfigSetting.cardAccounts}
                  {...formik.getFieldProps('cardAccountId')}
                />
              </Grid>
            )}

            {cardConfigSetting.maxUsage !== 1 && (
              <>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="limit-frequency-select-label">
                      {t(
                        'cardRequestDialog_v2.limitsAndValidityStep.limitFrequency'
                      )}
                      <InputLabelTooltipIcon
                        title={t(
                          `cardLimitFrequency.hints.${formik.values.limitRenewFrequency}`
                        )}
                      />
                    </InputLabel>
                    <Select<CardLimitRenewFrequency>
                      {...formik.getFieldProps('limitRenewFrequency')}
                      renderValue={(selected) =>
                        t(`cardLimitFrequency.frequencies.${selected}`)
                      }
                      labelId="limit-frequency-select-label"
                    >
                      {cardLimitRenewFrequencies.map((frequency) => (
                        <MenuItem key={frequency} value={frequency}>
                          {t(`cardLimitFrequency.frequencies.${frequency}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <MoneyField
                    name="limit"
                    value={formik.values.limit}
                    onValueChange={({ value }) => {
                      formik.setFieldValue('limit', value);
                      if (
                        cardConfigSetting?.cardConfigGroup ===
                        CardConfigGroup.pliantVirtualTravel
                      ) {
                        formik.setFieldValue('transactionLimit', value);
                      }
                    }}
                    isNumericString
                    currency={currency.code}
                    decimalScale={currency.exponent}
                    label={t(
                      'cardRequestDialog_v2.limitsAndValidityStep.limit'
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <MoneyField
                name="transactionLimit"
                value={formik.values.transactionLimit}
                onValueChange={({ value }) => {
                  formik.setFieldValue('transactionLimit', value);
                  if (cardConfigSetting.maxUsage === 1)
                    formik.setFieldValue('limit', value);
                }}
                isNumericString
                currency={currency.code}
                decimalScale={currency.exponent}
                label={
                  <>
                    {t(
                      'cardRequestDialog_v2.limitsAndValidityStep.transactionLimit'
                    )}
                    <InputLabelTooltipIcon
                      title={t('tooltips.transactionLimit')}
                    />
                  </>
                }
                error={!!formik.errors.transactionLimit}
                helperText={formik.errors.transactionLimit}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Box flexGrow="1">
          <Button variant="text" onClick={stepManager.goPrev}>
            {t('common.button.back')}
          </Button>
        </Box>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="request-card-limits-and-validity-form"
          type="submit"
        >
          {t('common.button.continue')}
        </Button>
      </DialogActions>
    </>
  );
};

export default LimitsAndValidityStep;
