import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Typography } from 'elements';
import { XlsxValidationErrors } from 'services/constants';

interface Props {
  isValidating: boolean;
  validationErrors: XlsxValidationErrors | null;
}

const ValidatingStatus = ({ isValidating, validationErrors }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {isValidating && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginTop={4}
          marginBottom={2}
        >
          <CircularProgress />
          <Typography variant="body2" paddingTop={2} textAlign="center">
            {isValidating &&
              t('int.bulkUploadItemsDialog.validationInProgress')}
          </Typography>
        </Box>
      )}

      {!isEmpty(validationErrors) && (
        <Box marginBottom={4}>
          <Typography
            variant="body2"
            color="error"
            fontWeight="bold"
            marginBottom={1}
          >
            {t('int.bulkUploadItemsDialog.validationErrors')}
          </Typography>

          <Box maxHeight={100} overflow="auto">
            {Object.keys(validationErrors).map((key) => (
              <Box
                display="flex"
                alignItems="center"
                key={key}
                marginBottom={1}
              >
                <Typography variant="body2" fontWeight="bold" marginRight={0.5}>
                  {key}
                </Typography>
                <Typography variant="body2">
                  {validationErrors![key]}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ValidatingStatus;
