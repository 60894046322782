import { ButtonBase } from '@mui/material';
import { MerchantStatusBadge } from 'domains/merchant/components';
import { MenuContainer, MenuItem } from 'elements';
import { MerchantStatus, merchantStatuses } from 'services/constants';

// if status is new - show only draft
// if status is draft - only published
// if status is published - only draft
const isOptionHidden = (rendered: MerchantStatus, selected: MerchantStatus) =>
  (selected === MerchantStatus.new && rendered !== MerchantStatus.draft) ||
  (selected === MerchantStatus.draft &&
    rendered !== MerchantStatus.published) ||
  (selected === MerchantStatus.published && rendered !== MerchantStatus.draft);

interface Props {
  value: MerchantStatus;
  onChange: (value: MerchantStatus) => void;
  disabled?: boolean;
}

const MerchantStatusSelect = ({ value, disabled, onChange }: Props) => (
  <MenuContainer
    button={
      <ButtonBase disabled={disabled}>
        <MerchantStatusBadge status={value} />
      </ButtonBase>
    }
  >
    {merchantStatuses
      .filter((status) => !isOptionHidden(status, value))
      .map((status) => (
        <MenuItem key={status} onClick={() => onChange(status)}>
          <MerchantStatusBadge status={status} />
        </MenuItem>
      ))}
  </MenuContainer>
);

export default MerchantStatusSelect;
