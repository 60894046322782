import { Backdrop, BackdropProps } from '@mui/material';
import {
  CircularProgress,
  CircularProgressProps,
} from 'elements/CircularProgress';

interface Props extends CircularProgressProps {
  loading: boolean;
  BackdropProps?: Omit<BackdropProps, 'open'>;
}

export const LoaderWithOverlay = ({
  loading,
  BackdropProps,
  ...props
}: Props) => {
  const backdropSx = BackdropProps?.sx || [];
  return (
    <Backdrop
      {...BackdropProps}
      open={loading}
      sx={[
        {
          position: 'absolute',
          zIndex: 50, // use value from the old Overlay component just not to break anything
          background: `rgba(255, 255, 255, 0.5)`,
        },
        ...(Array.isArray(backdropSx) ? backdropSx : [backdropSx]),
      ]}
    >
      <CircularProgress {...props} />
    </Backdrop>
  );
};
