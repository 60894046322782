import React from 'react';
import { Drawer, DrawerProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Link,
  Typography,
  XIcon,
} from 'elements';

export interface FilterDrawerProps extends DrawerProps {
  children?: React.ReactNode;
  areFiltersSelected?: boolean;
  onSuccess: () => void;
  onReset: () => void;
  onClose: () => void;
}

const FilterDrawer = ({
  children,
  areFiltersSelected = false,
  onSuccess,
  onReset,
  onClose,
  ...props
}: FilterDrawerProps) => {
  const { t } = useTranslation();

  return (
    <Drawer {...props} sx={{ zIndex: 1300 }} anchor="right">
      <Box width={320} display="flex" flexDirection="column" height="100%">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={4}
          py={3}
        >
          <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
            {t('filterDrawer.title', { defaultValue: 'Filter' })}
          </Typography>
          <IconButton
            aria-label="filter-close"
            onClick={onClose}
            sx={{ flexShrink: 0 }}
          >
            <XIcon />
          </IconButton>
        </Box>

        <Box flex={1} px={2} sx={{ overflowY: 'auto' }}>
          {children}
        </Box>

        <Box
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            button: {
              flex: 1,
              '& + button': { ml: 1 },
            },
            display: 'flex',
            mt: 'auto',
            py: 2,
            px: 4,
          }}
        >
          <Button
            variant="text"
            onClick={onReset}
            disabled={!areFiltersSelected}
          >
            {t('filterDrawer.resetAllBtn', { defaultValue: 'Reset All' })}
          </Button>

          <Button onClick={onSuccess}>
            {t('filterDrawer.applyBtn', { defaultValue: 'Apply' })}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

// Clear filter element used in filter drawer.
// Better to use a chared component, as it's design might be changed.
export const FilterDrawerClearButton = ({
  onChange,
}: {
  onChange: (value: []) => void;
}) => {
  const { t } = useTranslation();

  return (
    <FormHelperText sx={{ textAlign: 'right' }}>
      <Link component="button" onClick={() => onChange([])}>
        {t('filters.clearFilter')}
      </Link>
    </FormHelperText>
  );
};

export default FilterDrawer;
