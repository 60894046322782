import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import NoData from 'components/NoData';
import { useGlobalState } from 'context/GlobalState';
import { AccountStatementItem } from 'domains/billing/components';
import { useDownloadBillingFileFromQueryParams } from 'domains/billing/hooks';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  Box,
  FileTextIcon,
  Link,
  LoaderWithOverlay,
  MenuItem,
  Select,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageContent, PageHeader, PageTitle } from 'layout';
import {
  CardAccountStatus,
  OrganizationAccountStatement,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';

const useGetSelectedCardAccountId = () => {
  const location = useLocation();
  const {
    state: { cardAccounts, defaultCardAccount },
  } = useGlobalState();

  return useMemo(() => {
    const { cardAccountId } = Object.fromEntries(
      new URLSearchParams(location.search).entries()
    );
    const validCardAccountIds = cardAccounts
      .filter((item) => item.status.value === CardAccountStatus.active)
      .map((account) => account.id);

    if (validCardAccountIds.includes(cardAccountId)) return cardAccountId;

    return defaultCardAccount!.id;
  }, [cardAccounts, defaultCardAccount, location.search]);
};

interface State {
  isLoading: boolean;
  statements: OrganizationAccountStatement[];
  oldBillsCount: number;
}

const BillingStatementsPage = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const showPageError = useShowPageError();
  const mounted = useMounted();
  const canUser = useCanUser();
  const setQueryParam = useSetQueryParam();
  const {
    state: { organization, cardAccounts },
  } = useGlobalState();
  const getCardAccountName = useCardAccountNameGetter();
  const cardAccountId = useGetSelectedCardAccountId();
  const [state, setState] = useState<State>({
    isLoading: true,
    statements: [],
    oldBillsCount: 0,
  });
  useDownloadBillingFileFromQueryParams();

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const { statements } = await api.getOrganizationAccountStatements(
        organization!.id,
        cardAccountId
      );
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false, statements }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const getBillsCount = async () => {
    if (!canUser('statements-page:visit')) return;
    try {
      const oldBillsCount = await api.getBillsCount(organization!.id);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, oldBillsCount }));
    } catch (error) {
      logError(error);
    }
  };

  useEffect(() => {
    getBillsCount();
  }, []);

  useEffect(() => {
    getData();
  }, [cardAccountId]);

  return (
    <>
      <PageHeader>
        <PageTitle title={t('billingPage.statements')} />
      </PageHeader>
      <PageContent>
        {cardAccounts.length > 1 && (
          <Box width={250} mb={3}>
            <Select<string>
              value={cardAccountId}
              onChange={(e) => setQueryParam('cardAccountId', e.target.value)}
            >
              {cardAccounts
                .filter(
                  (item) => item.status.value === CardAccountStatus.active
                )
                .map((account) => (
                  <MenuItem value={account.id} key={account.id}>
                    {getCardAccountName(account)}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        )}
        {!!state.statements.length &&
          state.statements.map((item) => (
            <AccountStatementItem statement={item} key={item.startDate} />
          ))}
        {!state.isLoading && !state.statements.length && (
          <Box
            sx={{
              height:
                state.oldBillsCount || cardAccounts.length > 1
                  ? `calc(100% - ${
                      (state.oldBillsCount ? 32 : 0) +
                      (cardAccounts.length > 1 ? 88 : 0)
                    }px)`
                  : 1,
              minHeight: '200px',
              position: 'relative',
            }}
          >
            <NoData icon={<FileTextIcon />} label={t('billingPage.noData')} />
          </Box>
        )}
        {!state.isLoading && !!state.oldBillsCount && (
          <Box textAlign="center" pt={1}>
            <Link
              variant="body2"
              component={RouterLink}
              to={generatePath(getPath('statements'), {
                orgId: organization!.id,
              })}
            >
              {t('billingPage.linkToStatementsPage')}
            </Link>
          </Box>
        )}
        <LoaderWithOverlay loading={state.isLoading} />
      </PageContent>
    </>
  );
};

export default withPageConfig(BillingStatementsPage, {
  permission: 'billing-statements-page:visit',
});
