import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  InputAdornment,
  MagnifyingGlassIcon,
  TextField,
  TextFieldProps,
  XCircleIcon,
} from 'elements';

export interface PageSearchInputProps extends Omit<TextFieldProps, 'onChange'> {
  initialValue: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export const PageSearchInput = ({
  initialValue = '',
  onChange,
  disabled = false,
  sx = [],
  ...props
}: PageSearchInputProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (initialValue !== value.trim()) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const onValueChange = (value: string) => {
    setValue(value);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => onChange(value.trim()), 500);
  };

  return (
    <TextField
      sx={[{ width: 220 }, ...(Array.isArray(sx) ? sx : [sx])]}
      autoFocus
      name="search"
      placeholder={t('filters.search', { defaultValue: 'Search...' })}
      disabled={disabled}
      value={value}
      onChange={(e) => onValueChange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MagnifyingGlassIcon />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              onClick={() => onValueChange('')}
              edge="end"
            >
              <XCircleIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
