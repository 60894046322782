import React, { useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Box, Button, CheckCircleIcon, Grid, Typography } from 'elements';
import { OrganizationRepresentativeExternalStatus } from 'services/constants';

interface State {
  isCompleteComplianceDialogOpen: boolean;
  isCompleteComplianceLoading: boolean;
}

const CompleteComplianceCheckSection = () => {
  const { t } = useTranslation();

  const [state, setState] = useState<State>({
    isCompleteComplianceDialogOpen: false,
    isCompleteComplianceLoading: false,
  });

  const {
    actions: { completeCompliance },
    state: { riskAndCompliance, representatives },
  } = useCreditAndComplianceContext();

  const {
    state: { organization },
  } = useGlobalState();

  const canBeCompleted =
    !!riskAndCompliance!.riskAssessment &&
    representatives.every((rep) =>
      [
        OrganizationRepresentativeExternalStatus.infoSubmitted,
        OrganizationRepresentativeExternalStatus.internalOnly,
      ].includes(rep.externalStatus.value)
    );

  return (
    <>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        justifyContent="space-between"
        marginBottom={3}
        py={1}
      >
        <Typography variant="h6">
          {t('int.completeComplianceCheckSection.title')}
        </Typography>
      </Box>

      {riskAndCompliance!.complianceCheck ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          paddingY={3}
          spacing={1}
          sx={{
            backgroundColor: '#e5fff5', // Todo color palette
            borderRadius: 1,
          }}
        >
          <Grid
            item
            display="flex"
            alignItems="center"
            sx={{ color: 'success.main' }}
          >
            <CheckCircleIcon color="inherit" />
            <Typography variant="body2" color="inherit" marginLeft={1}>
              {t('int.completeComplianceCheckSection.complianceCompleted')}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {t('common.lastChangedBy', {
                dateTime: moment(
                  riskAndCompliance!.complianceCheck.completedAt
                ).format('DD.MM.YYYY HH:mm'),
                email: riskAndCompliance!.complianceCheck.completedBy,
              })}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          paddingY={3}
          spacing={1}
          sx={{
            backgroundColor: 'grey.100',
            borderRadius: 1,
          }}
        >
          <Grid item>
            <Button
              disabled={!canBeCompleted}
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  isCompleteComplianceDialogOpen: true,
                }))
              }
              variant="outlined"
            >
              {t('int.completeComplianceCheckSection.completeComplianceLabel')}
            </Button>
          </Grid>
        </Grid>
      )}

      <ConfirmDialog
        open={state.isCompleteComplianceDialogOpen}
        loading={state.isCompleteComplianceLoading}
        title={t(
          'int.completeComplianceCheckSection.completeComplianceDialogTitle'
        )}
        description={
          <Trans
            i18nKey="int.completeComplianceCheckSection.completeComplianceDialogDescription"
            components={{
              b: <Typography component="span" sx={{ fontWeight: 'bold' }} />,
            }}
            values={{
              name: organization!.name,
            }}
          />
        }
        onSuccess={async () => {
          setState((prevState) => ({
            ...prevState,
            isCompleteComplianceLoading: true,
          }));
          await completeCompliance(() => {
            setState((prevState) => ({
              ...prevState,
              isCompleteComplianceDialogOpen: false,
              isCompleteComplianceLoading: false,
            }));
          });
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isCompleteComplianceDialogOpen: false,
          }))
        }
      />
    </>
  );
};

export default CompleteComplianceCheckSection;
