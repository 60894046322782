import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCardConfigSetting } from 'context/GlobalState';
import { CardDesignPicker } from 'domains/card/components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card, CARD_NAME_MAX_LENGTH } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  cardName: string;
  cardDesignId: string;
}

interface Props extends DialogProps {
  card: Card;
  onSuccess: (card: Card) => void;
  onClose: () => void;
}

const ChangeCardNameDialog = ({ card, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const cardConfigSetting = useCardConfigSetting(card.cardConfig);
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      cardName: card.cardName || '',
      cardDesignId: card.cardDesignId,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const data = await api.updateCard(card.cardId, {
          cardName: values.cardName.trim() || null,
          cardDesignId: values.cardDesignId,
        });
        if (!mounted.current) return;
        onSuccess(data);
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {!!cardConfigSetting && cardConfigSetting.cardDesignIds.length > 1
          ? t('changeCardNameDialog.title')
          : t('changeCardNameDialog.changeCardNameHeading')}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="change-card-label-and-color-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('cardName')}
                label={t('changeCardNameDialog.cardName')}
                inputProps={{ maxLength: CARD_NAME_MAX_LENGTH }}
                disabled={formik.isSubmitting}
                autoFocus
              />
            </Grid>
            {!!cardConfigSetting && cardConfigSetting.cardDesignIds.length > 1 && (
              <Grid item xs={12}>
                <CardDesignPicker
                  cardConfigSetting={cardConfigSetting}
                  label={t('changeCardPurposeDialog.cardColor')}
                  value={formik.values.cardDesignId}
                  onChange={(value) =>
                    formik.setFieldValue('cardDesignId', value)
                  }
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={formik.isSubmitting}
          form="change-card-label-and-color-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(ChangeCardNameDialog);
