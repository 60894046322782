import {
  Component,
  ComponentType,
  createContext,
  FC,
  ReactNode,
  useContext,
} from 'react';
import { AxiosError } from 'axios';
import WidgetError from 'components/WidgetError';
import useSnackbar, { EnqueueSnackbar } from 'hooks/useSnackbar';
import { getGenericErrorMsg } from 'services/utils';

const ErrorBoundaryContext = createContext<(error?: unknown) => void>(() => {});

export const useShowPageError = () => {
  return useContext(ErrorBoundaryContext);
};

interface Props {
  children: ReactNode;
  enqueueSnackbar: EnqueueSnackbar;
}

interface State {
  hasError: boolean;
}

class PageErrorBoundary extends Component<Props, State> {
  _isMounted = false;

  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showPageError = (error?: unknown) => {
    if (this._isMounted) {
      // ignore in case when request timeouts
      if (error instanceof AxiosError && error.response?.status === 408) {
        this.props.enqueueSnackbar(getGenericErrorMsg(error), {
          key: 'REQUEST_TIMEOUT_ERROR',
          variant: 'error',
        });

        return;
      }
      this.setState({ hasError: true });
    }
  };

  renderContent() {
    if (this.state.hasError) {
      return (
        <WidgetError onReload={() => this.setState({ hasError: false })} />
      );
    }
    return this.props.children;
  }

  render() {
    return (
      <ErrorBoundaryContext.Provider value={this.showPageError}>
        {this.renderContent()}
      </ErrorBoundaryContext.Provider>
    );
  }
}

const withPageErrorWrapper = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <PageErrorBoundary
      enqueueSnackbar={enqueueSnackbar}
      children={<Component {...props} />}
    />
  );
};

export default withPageErrorWrapper;
