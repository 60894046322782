import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SettlementStatusBadge,
  VssTypeBadge,
} from 'domains/billing/components';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import { Chip, drawerPlaceholderColumn, GridColDef, gridUtils } from 'elements';
import { Settlement } from 'services/constants';
import { formatMoney } from 'services/utils';

const useColumns = () => {
  const { t, i18n } = useTranslation();
  const currencyDisplay = useCurrencyDisplayMode();
  const getCardAccountName = useCardAccountNameGetter();

  return useMemo(() => {
    const columns: GridColDef<Settlement>[] = [
      {
        headerName: t('orgSettlementsPage.reportDate'),
        field: 'createdAt',
        flex: 110,
        renderCell: ({ row }) => row.reportDate,
      },
      {
        headerName: t('orgSettlementsPage.accountGroup'),
        field: 'accountGroup',
        flex: 105,
        sortable: false,
        renderCell: ({ row }) => (
          <Chip
            label={t(`int.accountGroupSelect.items.${row.accountGroup}`)}
            size="small"
          />
        ),
      },
      {
        headerName: t('orgSettlementsPage.service'),
        field: 'vssType',
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        flex: 70,
        renderCell: ({ row }) => <VssTypeBadge value={row.vssType} />,
      },
      {
        headerName: t('orgSettlementsPage.cardAccount'),
        field: 'cardAccountName',
        sortable: false,
        flex: 100,
        valueGetter: ({ row }) => getCardAccountName(row.cardAccountId),
      },
      {
        headerName: t('orgSettlementsPage.count'),
        field: 'transactionsCount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 70,
      },
      {
        headerName: t('orgSettlementsPage.amount'),
        field: 'settlementAmount',
        flex: 120,
        renderCell: ({ row }) =>
          formatMoney(row.settlementAmount, i18n.language, {
            currencyDisplay,
            fractionalPart: true,
          }),
        align: 'right',
        headerAlign: 'right',
        sortable: false,
      },
      {
        headerName: t('orgSettlementsPage.fees'),
        field: 'feesAmount',
        flex: 100,
        renderCell: ({ row }) =>
          formatMoney(row.feesAmount, i18n.language, {
            currencyDisplay,
            fractionalPart: true,
          }),
        align: 'right',
        headerAlign: 'right',
        sortable: false,
      },
      {
        headerName: t('orgSettlementsPage.state'),
        field: 'status',
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        flex: 100,
        renderCell: ({ row }) => <SettlementStatusBadge status={row.status} />,
      },
      drawerPlaceholderColumn,
    ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language, currencyDisplay, getCardAccountName]);
};

export default useColumns;
