import { ReactNode } from 'react';
import { styled } from '@mui/material';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import CustomSnackbar from './CustomSnackbar';

const SnackbarProvider = styled(
  ({ children, className }: { children: ReactNode; className?: string }) => (
    <NotistackSnackbarProvider
      maxSnack={1}
      preventDuplicate={true}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      Components={{
        default: CustomSnackbar,
        error: CustomSnackbar,
        success: CustomSnackbar,
        warning: CustomSnackbar,
        info: CustomSnackbar,
      }}
      classes={{
        containerRoot: className,
      }}
    >
      {children}
    </NotistackSnackbarProvider>
  )
)`
  .notistack-CollapseWrapper {
    max-width: 600px;
    margin: 0;
  }
`;

export default SnackbarProvider;
