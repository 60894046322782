import React, { useState } from 'react';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import BulkImportInformationRequestsDialog from 'domains/creditAndCompliance/components/BulkImportInformationRequestsDialog';
import { InformationRequestDraftCard } from 'domains/creditAndCompliance/components/InformationRequestDraftCard';
import OnboardingDocumentRow from 'domains/creditAndCompliance/components/OnboardingDocumentRow';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  ArrowRightIcon,
  Button,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  PlusIcon,
  Switch,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import {
  OnboardingDocumentCategory,
  OnboardingDocumentStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useCanUser } from 'services/rbac';

interface InformationRequestsToCreate {
  category: string;
  id: string;
  status: string;
  subcategory: string;
}
interface Props {
  category:
    | OnboardingDocumentCategory.COMPLIANCE
    | OnboardingDocumentCategory.RISK;
}

interface State {
  isBulkImportInformationRequestsDialogOpen: boolean;
  isConfirmRequestDocumentsDialogOpen: boolean;
  menuAnchorEl: HTMLElement | null;
  showArchviedOnboardingDocuments: boolean;
}

const DocumentAndQuestionRequestsModule = ({ category }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const mounted = useMounted();

  const [state, setState] = useState<State>({
    isBulkImportInformationRequestsDialogOpen: false,
    isConfirmRequestDocumentsDialogOpen: false,
    menuAnchorEl: null,
    showArchviedOnboardingDocuments: false,
  });
  const [
    informationRequestsToCreate,
    setInformationRequestsToCreate,
  ] = useState<InformationRequestsToCreate[]>([]);

  const {
    actions: {
      archiveDocument,
      fetchOnboardingDocuments,
      requestAlltDocumentsInDraft,
    },
    state: {
      canDocumentsSectionBeEdited,
      canDocumentsBeRequestedByCategory,
      isRequesting,
      onboardingDocumentTypeGroups,
      onboardingDocuments,
    },
  } = useCreditAndComplianceContext();

  const categoryOnboardingDocumentTypeSubcategories = onboardingDocumentTypeGroups![
    category === OnboardingDocumentCategory.COMPLIANCE
      ? 'complianceOnboardingDocumentTypeSubcategories'
      : 'riskOnboardingDocumentTypeSubcategories'
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body1">
            {t('int.informationRequestSection.documentsAndOtherInquiries')}
          </Typography>

          <FormControlLabel
            checked={state.showArchviedOnboardingDocuments}
            control={<Switch size="medium" />}
            label={t('int.informationRequestSection.showArchived')}
            labelPlacement="start"
            onChange={() =>
              setState((prevState) => ({
                ...prevState,
                showArchviedOnboardingDocuments: !prevState.showArchviedOnboardingDocuments,
              }))
            }
          />
        </Grid>

        <Grid item container xs={12} spacing={3}>
          {onboardingDocuments
            .filter(
              (doc) =>
                doc.type.category === category &&
                (state.showArchviedOnboardingDocuments ||
                  doc.status !== OnboardingDocumentStatus.ARCHIVED)
            )
            .map((doc) =>
              doc.status === OnboardingDocumentStatus.DRAFT ? (
                <Grid item key={doc.id} xs={12}>
                  <InformationRequestDraftCard mode="edit" doc={doc} />
                </Grid>
              ) : (
                <Grid item key={doc.id} xs={12}>
                  <OnboardingDocumentRow
                    document={doc}
                    {...(canUser('org-onboarding-document-files:manage') && {
                      internalOnly: {
                        archiveDocument,
                      },
                    })}
                    onUpdate={fetchOnboardingDocuments}
                  />
                </Grid>
              )
            )}

          {informationRequestsToCreate.map((item, index) => (
            <Grid item key={item.id} xs={12}>
              <InformationRequestDraftCard
                category={item.category}
                onClear={() =>
                  setInformationRequestsToCreate((prevState) => [
                    ...prevState.slice(0, index),
                    ...prevState.slice(index + 1),
                  ])
                }
                subcategory={item.subcategory}
                mode="create"
              />
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="space-between">
          <Button
            disabled={!canDocumentsSectionBeEdited}
            onClick={(event) =>
              setState((prevState) => ({
                ...prevState,
                menuAnchorEl: event.currentTarget,
              }))
            }
            variant="outlined"
            startIcon={<PlusIcon />}
          >
            {t('int.informationRequestSection.addRequest')}
          </Button>

          <Menu
            anchorEl={state.menuAnchorEl}
            open={!!state.menuAnchorEl}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                menuAnchorEl: null,
              }))
            }
          >
            {[
              {
                key: 'upload',
                children: t(
                  'int.informationRequestSection.uploadQaRequirementsButton'
                ),
                onClick: () => {
                  setState((prevState) => ({
                    ...prevState,
                    isBulkImportInformationRequestsDialogOpen: true,
                    menuAnchorEl: null,
                  }));
                },
              },
              ...categoryOnboardingDocumentTypeSubcategories.map((item) => ({
                key: item,
                children: t(`int.onboardingDocumentSubcategory.${item}`),
                onClick: () => {
                  setInformationRequestsToCreate((prevState) => [
                    ...prevState,
                    {
                      category: category,
                      id: uniqueId(),
                      status: 'FE_DRAFT',
                      subcategory: item,
                    },
                  ]);
                  setState((prevState) => ({
                    ...prevState,
                    menuAnchorEl: null,
                  }));
                },
              })),
            ].map((menuItemProps) => (
              <MenuItem {...menuItemProps} />
            ))}
          </Menu>

          <Button
            disabled={!canDocumentsBeRequestedByCategory[category]}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                isConfirmRequestDocumentsDialogOpen: true,
              }))
            }
            endIcon={<ArrowRightIcon />}
          >
            {t('int.informationRequestSection.requestInfo')}
          </Button>
        </Grid>
      </Grid>

      <ConfirmDialog
        loading={isRequesting}
        onSuccess={async () => {
          try {
            await requestAlltDocumentsInDraft(category);
          } catch (error) {
            logError(error);
          }
          if (!mounted) return;
          setState((prevState) => ({
            ...prevState,
            isConfirmRequestDocumentsDialogOpen: false,
          }));
        }}
        open={state.isConfirmRequestDocumentsDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isConfirmRequestDocumentsDialogOpen: false,
          }))
        }
        description={t(
          'int.informationRequestSection.reqeuestDocumentsConfirmDialogDescription'
        )}
        title={t(
          'int.informationRequestSection.reqeuestDocumentsConfirmDialogTitle'
        )}
      />

      <BulkImportInformationRequestsDialog
        open={state.isBulkImportInformationRequestsDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isBulkImportInformationRequestsDialogOpen: false,
          }))
        }
      />
    </>
  );
};

export default DocumentAndQuestionRequestsModule;
