import React from 'react';
import { Redirect } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { env } from 'env';
import { CirculaConnectionType } from 'services/constants';

const withCirculaSyncCheck = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => (props: P): JSX.Element => {
  const {
    state: { organizationIntegrations },
  } = useGlobalState();

  const isCirculaFullSyncEnabled =
    env.REACT_APP_BUILD_TARGET === 'external' &&
    organizationIntegrations?.syncSettings?.orgConnectionType ===
      CirculaConnectionType.full;

  return isCirculaFullSyncEnabled ? (
    <Redirect to="/" />
  ) : (
    <Component {...props} />
  );
};

export default withCirculaSyncCheck;
