import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useFormik } from 'formik';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  MoneyField,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PremiumCardFeeTier } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getMoneyObject,
  getNetworkErrorCode,
} from 'services/utils';

interface FormValues {
  fee: number | undefined;
  totalCardsCount: number | undefined;
}

interface Props extends DialogProps {
  tier: PremiumCardFeeTier | null;
  onClose: () => void;
  onSuccess: () => void;
}

const UpdatePremiumCardFeeTierDialog = ({
  tier: tierNullable,
  onSuccess,
  ...props
}: Props) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const tier = useMemo(() => tierNullable!, []);
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      fee: tier.fee.value / 100,
      totalCardsCount: tier.totalCardsCount ?? undefined,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await api.updatePremiumCardFeeTier(tier.id, {
          fee: getMoneyObject(values.fee! * 100),
          totalCardsCount: values.totalCardsCount ?? null,
        });
        if (!mounted.current) return;
        onSuccess();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        const errorMessageKey = `int.createUpdatePremiumCardFeeTierDialog.errors.${getNetworkErrorCode(
          error
        )}`;
        if (i18n.exists(errorMessageKey)) {
          enqueueSnackbar(t(errorMessageKey), { variant: 'error' });
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const deleteTier = async () => {
    try {
      formik.setSubmitting(true);
      await api.deletePremiumCardFeeTier(tier.id);
      if (!mounted.current) return;
      onSuccess();
    } catch (error) {
      if (!mounted.current) return;
      formik.setSubmitting(false);
      const errorMessageKey = `int.createUpdatePremiumCardFeeTierDialog.errors.${getNetworkErrorCode(
        error
      )}`;
      if (i18n.exists(errorMessageKey)) {
        enqueueSnackbar(t(errorMessageKey), { variant: 'error' });
        return;
      }
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const isSubmitDisabled =
    formik.values.fee === undefined ||
    (!tier.defaultTier && formik.values.totalCardsCount === undefined) ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('int.createUpdatePremiumCardFeeTierDialog.titleUpdate', {
          cardName: t('cardNames.PLIANT_BLACK'),
        })}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="update-premium-card-fee-tier-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12}>
              <MoneyField
                label={t('int.createUpdatePremiumCardFeeTierDialog.annualFee')}
                placeholder={t(
                  'int.createUpdatePremiumCardFeeTierDialog.annualFee'
                )}
                disabled={formik.isSubmitting}
                isAllowed={({ floatValue }) =>
                  floatValue === undefined || floatValue <= 1000
                }
                {...omit(formik.getFieldProps('fee'), 'onChange')}
                onValueChange={({ floatValue }) =>
                  formik.setFieldValue('fee', floatValue)
                }
              />
            </Grid>
            <Grid item xs={12}>
              {/* For a default tier the total cards count is infinite and can't be changed */}
              {tier.defaultTier ? (
                <TextField
                  value="∞"
                  disabled
                  label={t('int.createUpdatePremiumCardFeeTierDialog.count')}
                />
              ) : (
                <NumberFormat
                  customInput={TextField}
                  label={t('int.createUpdatePremiumCardFeeTierDialog.count')}
                  placeholder={t(
                    'int.createUpdatePremiumCardFeeTierDialog.count'
                  )}
                  disabled={formik.isSubmitting}
                  decimalScale={0}
                  allowNegative={false}
                  isAllowed={({ floatValue }) =>
                    floatValue === undefined ||
                    (floatValue >= 1 && floatValue <= 1000)
                  }
                  {...omit(formik.getFieldProps('totalCardsCount'), 'onChange')}
                  onValueChange={({ floatValue }) => {
                    formik.setFieldValue('totalCardsCount', floatValue);
                  }}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {!tier.defaultTier && (
          <Box flexGrow="1">
            <Button onClick={deleteTier} variant="outlined" color="error">
              {t('common.button.delete')}
            </Button>
          </Box>
        )}
        <Button onClick={props.onClose} variant="text">
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="update-premium-card-fee-tier-form"
          type="submit"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(UpdatePremiumCardFeeTierDialog);
