import { useMemo } from 'react';
import { Currency } from '@dinero.js/currencies';
import { useGlobalState } from 'context/GlobalState';
import { DEFAULT_CURRENCY } from 'services/constants';
import { getCurrencyByCode } from 'services/utils';

const useCardAccountCurrency = (cardAccountId?: string) => {
  const {
    state: { cardAccounts, defaultCardAccount },
  } = useGlobalState();

  return useMemo<Currency<number>>(() => {
    if (cardAccountId) {
      const account = cardAccounts.find((item) => item.id === cardAccountId);
      if (account) return getCurrencyByCode(account.currency.value);
    }

    if (defaultCardAccount) {
      return getCurrencyByCode(defaultCardAccount.currency.value);
    }

    return DEFAULT_CURRENCY;
  }, [cardAccounts, defaultCardAccount, cardAccountId]);
};

export default useCardAccountCurrency;
