// Importing Typography and TypographyProps from 'elements'
// creates a weird circular dependency error in TransactionBadge.test.tsx
// https://github.com/mui/material-ui/issues/35922
import { Typography, TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TransactionSimpleType, TransactionStatus } from 'services/constants';

const getVisibleTransactionStatus = (
  status: TransactionStatus,
  simpleType: TransactionSimpleType,
  bookingDate: string | null
) => {
  if (simpleType === TransactionSimpleType.statusInquiry) return status;
  if (status === TransactionStatus.confirmed && !bookingDate)
    return TransactionStatus.pending;
  return status;
};

const useGetTransactionBadgeColor = (
  status: TransactionStatus,
  simpleType: TransactionSimpleType
) => {
  if (status === TransactionStatus.confirmed) {
    if (
      simpleType === TransactionSimpleType.refund ||
      simpleType === TransactionSimpleType.chargeback
    )
      return 'success.main';

    if (simpleType === TransactionSimpleType.recharge) return 'error.main';

    if (simpleType === TransactionSimpleType.statusInquiry)
      return 'textSecondary';
  }

  if (status === TransactionStatus.pending) return 'info.main';
  if (status === TransactionStatus.declined) return 'error.main';

  return 'action.active';
};

const useGetTransactionBadgeLabel = (
  status: TransactionStatus,
  simpleType: TransactionSimpleType
) => {
  const { t } = useTranslation();

  if (simpleType === TransactionSimpleType.statusInquiry) {
    if (
      status === TransactionStatus.declined ||
      status === TransactionStatus.pending
    ) {
      return (
        t(`transactionStatuses.${status}`) +
        ' ' +
        t(`transactionSimpleTypes.${simpleType}`)
      );
    }
    return t(`transactionSimpleTypes.${simpleType}`);
  }

  if (
    status === TransactionStatus.declined ||
    status === TransactionStatus.pending ||
    status === TransactionStatus.reversed
  ) {
    if (
      simpleType === TransactionSimpleType.refund ||
      simpleType === TransactionSimpleType.recharge ||
      simpleType === TransactionSimpleType.chargeback
    ) {
      return (
        t(`transactionStatuses.${status}`) +
        ' ' +
        t(`transactionSimpleTypes.${simpleType}`)
      );
    }

    return t(`transactionStatuses.${status}`);
  }

  if (
    simpleType === TransactionSimpleType.refund ||
    simpleType === TransactionSimpleType.recharge ||
    simpleType === TransactionSimpleType.chargeback
  ) {
    return t(`transactionSimpleTypes.${simpleType}`);
  }

  return null;
};

interface TransactionBadgeProps extends TypographyProps {
  status: TransactionStatus;
  simpleType: TransactionSimpleType;
  bookingDate: string | null;
}

export const TransactionBadge = ({
  status,
  simpleType,
  bookingDate,
  sx = [],
  ...rest
}: TransactionBadgeProps) => {
  const visibleStatus = getVisibleTransactionStatus(
    status,
    simpleType,
    bookingDate
  );
  const color = useGetTransactionBadgeColor(visibleStatus, simpleType);
  const label = useGetTransactionBadgeLabel(visibleStatus, simpleType);

  return (
    <Typography
      variant="caption2"
      color={color}
      noWrap
      sx={[
        {
          textTransform: 'uppercase',
          display: 'block',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {label}
    </Typography>
  );
};
