import { SxProps, Theme } from '@mui/material';
import clsx from 'clsx';
import { Box } from 'elements';

interface Props {
  currencyCode: string;
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>;
}

const CurrencyFlag = ({ currencyCode, size = 'medium', sx = [] }: Props) => (
  <Box
    sx={[
      {
        width: size === 'medium' ? 24 : 16,
        height: size === 'medium' ? 24 : 16,
        borderRadius: '50%',
        backgroundSize: 'contain',
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    className={clsx(
      'flag-icon-background',
      'flag-icon-squared',
      `flag-icon-${currencyCode.substring(0, 2).toLowerCase()}`
    )}
  />
);

export default CurrencyFlag;
