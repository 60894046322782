import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  MAX_RECEIPT_FILE_SIZE,
  MAX_RECEIPT_FILE_SIZE_MB,
} from 'domains/transaction/constants';
import { Box, Dropzone, DropzoneErrorCode, FileRejection } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Receipt } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';

interface Props {
  receipt: Receipt;
  onSuccess: (receipt: Receipt) => void;
  onUploadStart: () => void;
  onUploadError: () => void;
}

interface State {
  file: File | null;
  isLoading: boolean;
  progress: number;
  hasError: boolean;
}

const ReuploadReceiptDropzone = ({
  receipt,
  onSuccess,
  onUploadStart,
  onUploadError,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const canUser = useCanUser();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    file: null,
    isLoading: false,
    progress: 0,
    hasError: false,
  });
  const disabled = !canUser('receipt-rejected-by-acc-system:update');

  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      const [file] = acceptedFiles;
      try {
        onUploadStart();
        setState({ file, isLoading: true, progress: 0, hasError: false });
        const response = await api.replaceReceipt(
          receipt.receiptId,
          file,
          (progress) => {
            if (!mounted.current) return;
            setState((prevState) => ({ ...prevState, progress }));
          }
        );
        if (!mounted.current) return;
        setState({
          file: null,
          isLoading: false,
          progress: 0,
          hasError: false,
        });
        onSuccess(response);
      } catch (error) {
        if (!mounted.current) return;
        onUploadError();
        setState((prevState) => ({
          ...prevState,
          hasError: true,
          isLoading: false,
        }));
        logError(error);
      }
    },
    [receipt]
  );

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      if (
        fileRejections.length === 1 &&
        fileRejections[0].errors.length === 1 &&
        fileRejections[0].errors[0].code === DropzoneErrorCode.FileTooLarge
      ) {
        enqueueSnackbar(
          t('receiptDropzone.fileIsTooBigErrorMessage', {
            size: MAX_RECEIPT_FILE_SIZE_MB,
          }),
          {
            variant: 'error',
          }
        );
      }
    },
    [enqueueSnackbar]
  );

  const onDragEnter = useCallback(() => {
    setState((prevState) =>
      prevState.hasError
        ? { file: null, isLoading: false, progress: 0, hasError: false }
        : prevState
    );
  }, []);

  const onFileDialogCancel = useCallback(() => {
    setState((prevState) =>
      prevState.hasError
        ? { file: null, isLoading: false, progress: 0, hasError: false }
        : prevState
    );
  }, []);

  return (
    <Box width="100%" p={2} bgcolor={(theme) => theme.palette.warning.main}>
      <Dropzone
        variant="error"
        progress={state.progress}
        file={state.file}
        isLoading={state.isLoading}
        disabled={!!state.file || state.isLoading || disabled}
        error={
          state.hasError ? t('receiptDropzone.uploadErrorMessage') : undefined
        }
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
        onFileDialogCancel={onFileDialogCancel}
        onDragEnter={onDragEnter}
        dropzoneIdleProps={{
          description: t('reuploadReceiptDropzone.title'),
          secondaryDescription: t('reuploadReceiptDropzone.description', {
            name: accountingSettings!.accountingSystemName,
          }),
          dragRejectDescription: t('receiptDropzone.dropRejectLabel'),
        }}
        multiple={false}
        accept={'image/jpeg, image/png, application/pdf'}
        maxSize={MAX_RECEIPT_FILE_SIZE}
      />
    </Box>
  );
};

export default ReuploadReceiptDropzone;
