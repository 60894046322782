import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  EditButton,
  Link,
  QuestionIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from 'elements';
import useUrls from 'hooks/useUrls';
import { DatevCostMode } from 'services/constants';
import { useCanUser } from 'services/rbac';
import DatevExportSettingsDialog from './DatevExportSettingsDialog';

const DatevExportSettings = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const {
    HELP_CENTER_RECEIPT_NUMBER_IN_DATEV_EXPORT_URL,
    HELP_CENTER_RECEIPT_DATE_IN_DATEV_EXPORT_URL,
  } = useUrls();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Table sx={{ mt: (theme) => theme.spacing(4) }}>
        <TableHead>
          <TableRow>
            <TableCell width="60%">
              {t('datevSubPage.datevExportSettings.title')}
            </TableCell>
            <TableCell width="40%" align="right">
              {canUser('accounting-settings:update') && (
                <EditButton onClick={() => setIsDialogOpen(true)} />
              )}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell width="60%">
              {t('datevSubPage.datevExportSettings.kost1')}
            </TableCell>
            <TableCell width="40%">
              {t(`datevCostModes.${accountingSettings!.datevCostMode}`)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="60%">
              {t('datevSubPage.datevExportSettings.kost2')}
            </TableCell>
            <TableCell width="40%">
              {accountingSettings!.datevCostMode === DatevCostMode.standard
                ? t('datevCostModes.SWAP')
                : t('datevCostModes.STANDARD')}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="60%">
              <Box display="flex" alignItems="center">
                {t(
                  'datevSubPage.datevExportSettings.autoGenerateReceiptNumber'
                )}
                <Tooltip
                  title={
                    <Trans
                      i18nKey="datevSubPage.datevExportSettings.autoGenerateReceiptNumberTooltip"
                      components={{
                        linkTo: (
                          <Link
                            href={
                              HELP_CENTER_RECEIPT_NUMBER_IN_DATEV_EXPORT_URL
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                            color="inherit"
                          />
                        ),
                      }}
                    />
                  }
                >
                  <QuestionIcon fontSize="small" sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell width="40%">
              {t(
                `datevSubPage.datevExportSettings.autoGenerateReceiptNumberModes.${
                  accountingSettings!.receiptNumberExportMode
                }`
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="60%">
              <Box display="flex" alignItems="center">
                {t('datevSubPage.datevExportSettings.receiptDateExport')}
                <Tooltip
                  title={
                    <Trans
                      i18nKey="datevSubPage.datevExportSettings.receiptDateExportTooltip"
                      components={{
                        linkTo: (
                          <Link
                            href={HELP_CENTER_RECEIPT_DATE_IN_DATEV_EXPORT_URL}
                            rel="noopener noreferrer"
                            target="_blank"
                            color="inherit"
                          />
                        ),
                      }}
                    />
                  }
                >
                  <QuestionIcon fontSize="small" sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell width="40%">
              {t(
                `datevSubPage.datevExportSettings.receiptDateExportMode.${
                  accountingSettings!.receiptDateExportMode
                }`
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="60%">
              {t('datevSubPage.datevExportSettings.bookingText')}
            </TableCell>
            <TableCell width="40%">
              {t(
                `datevSubPage.datevExportSettings.bookingTextMode.${
                  accountingSettings!.datevBookingTextMode
                }`
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width="60%">
              {t('datevSubPage.datevExportSettings.paymentBookingTextPrefix')}
            </TableCell>
            <TableCell width="40%">
              {t(
                `datevSubPage.datevExportSettings.mode.${
                  accountingSettings!.datevUsePaymentBookingTextPrefix
                    ? 'enabled'
                    : 'disabled'
                }`
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width="60%">
              {t('datevSubPage.datevExportSettings.useInformationField')}
            </TableCell>
            <TableCell width="40%">
              {t(
                `datevSubPage.datevExportSettings.mode.${
                  accountingSettings!.datevUseInformationField
                    ? 'enabled'
                    : 'disabled'
                }`
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <DatevExportSettingsDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default DatevExportSettings;
