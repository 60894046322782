import { FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 9px 0 9px -9px;

  .MuiTypography-root {
    margin: 0 0 0 1px;
    font-size: 16px;
    line-height: 20px;
  }
`;
