import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { MenuButtonCircularProgress, MenuItem } from 'components/Menu';

export const OrgAvailableLimit = styled.div`
  font-size: 34px;
  line-height: 40px;
`;

export const OrgAvailableLimitLabel = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.grey100};
`;

export const Header = styled.div`
  display: flex;
  height: ${({ theme }) => theme.size.dataTableHeaderHeight}px;
  margin: 0 0 12px;
  padding: 0 290px 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  line-height: ${({ theme }) => theme.size.dataTableHeaderHeight - 1}px;
  color: ${({ theme }) => theme.colors.grey100};

  span:first-child {
    width: 322px;
  }

  span:nth-child(2) {
    margin: 0 auto;
  }

  span:last-child {
    width: 150px;
    text-align: right;
  }
`;

export const PaymentDefermentInDays = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.grey100};
`;

export const PaymentItemWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grey10};

  & + & {
    padding-top: 18px;
  }
`;

export const DirectDebitReversalBadge = styled.div`
  display: inline-block;
  padding: 0 4px;
  font-size: 10px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.red10};
  border-radius: 3px;
`;

export const CutOffDate = styled.div`
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.theme.colors.grey100};
`;

export const AdditionalInfoPrimaryText = styled.div`
  font-size: 14px;
  line-height: 16px;
`;

export const AdditionalInfoSecondaryText = styled.div`
  margin-top: 2px;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.grey100};
`;

export const StyledMenuButtonCircularProgress = styled(
  MenuButtonCircularProgress
)`
  top: 11px;
  left: 17px;

  svg {
    color: ${({ theme }) => theme.colors.grey40};
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  right: 7px;
`;

export const StyledMenuItem = styled(MenuItem)`
  min-width: 134px;
  padding-right: 40px;
`;
