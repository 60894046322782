import React, { useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StatementStatusBadge } from 'components/Badge';
import { ButtonLink, IconButton } from 'components/Button';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import PaymentItem from 'domains/billing/pages/StatementsPage/PaymentItem';
import StatementItemMenu from 'domains/billing/pages/StatementsPage/StatementItemMenu';
import useMounted from 'hooks/useMounted';
import {
  Bill,
  BillStatus,
  OrganizationAccountType,
  Payment,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { getMoneyWithOppositeSign } from 'services/utils';
import {
  AmountBlock,
  AmountLabel,
  DatesLabel,
  Header,
  PaidLabel,
  StatementItemMenuWrapper,
  StatementItemWrapper,
  StyledArrowsButtonDownIcon,
  StyledErrorMessage,
  StyledFileTextIcon,
  SummaryHeader,
  SummaryHeaderLine,
  SummarySubHeader,
  TransactionsMainValue,
  TransactionsWrapper,
} from './style';

interface State {
  open: boolean;
  isLoading: boolean;
  error: unknown;
}

interface Props {
  bill: Bill;
  payments: Payment[] | null;
  fetchPayments: () => Promise<any>;
  defaultOpen?: boolean;
}

const StatementItem = ({
  bill,
  payments,
  defaultOpen = false,
  fetchPayments,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const {
    state: { defaultCardAccount },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    open: defaultOpen,
    isLoading: false,
    error: null,
  });

  const showPaymentsBlock = !payments || payments.length > 0;
  const canOpenPaymentsBlock =
    showPaymentsBlock &&
    !!(
      bill.pendingAmount.value ||
      bill.paidAmount.value ||
      bill.unpaidAmount.value ||
      bill.deferredAmount.value
    );

  const getPayments = async () => {
    try {
      setState((state) => ({ ...state, isLoading: true }));
      await fetchPayments();
      if (!mounted.current) return;
      setState((state) => ({ ...state, isLoading: false, error: null }));
    } catch (error) {
      if (!mounted.current) return;
      setState((state) => ({ ...state, isLoading: false, error }));
      logError(error);
    }
  };

  return (
    <StatementItemWrapper $isActive={state.open} data-test-id="statement-item">
      <Header>
        <StyledFileTextIcon />
        <Box width="250px">
          <DatesLabel>
            {moment(bill.startAt).format('D MMM YYYY')}
            {' - '}
            {moment(bill.closeAt).format('D MMM YYYY')}
          </DatesLabel>
          <StatementStatusBadge status={bill.status} />
        </Box>
        <TransactionsWrapper>
          <TransactionsMainValue>
            {bill.numberOfTransactionsIncluded}
          </TransactionsMainValue>
        </TransactionsWrapper>
        <AmountBlock>
          <AmountLabel>
            <FormatMoney
              value={getMoneyWithOppositeSign(bill.amount)}
              fractionalPart
            />
          </AmountLabel>
          {bill.status !== BillStatus.closed && (
            <PaidLabel>
              {defaultCardAccount!.accountType.value ===
              OrganizationAccountType.prefunded
                ? t('statementItem.totalTopUp')
                : t('statementItem.thereofPaid')}{' '}
              <FormatMoney value={bill.paidAmount} fractionalPart />
            </PaidLabel>
          )}
        </AmountBlock>
        <StatementItemMenuWrapper>
          <StatementItemMenu bill={bill} />
        </StatementItemMenuWrapper>
        {canOpenPaymentsBlock ? (
          <IconButton
            onClick={() => {
              if (
                !state.open &&
                !state.isLoading &&
                !state.error &&
                !payments
              ) {
                getPayments();
              }
              setState((state) => ({ ...state, open: !state.open }));
            }}
            $p="16px"
          >
            <StyledArrowsButtonDownIcon $rotated={state.open} />
          </IconButton>
        ) : (
          <Box width="48px" />
        )}
      </Header>
      {showPaymentsBlock && state.open && (
        <>
          <SummaryHeader>
            <div>{t('statementItem.paymentDate')}</div>
            <SummaryHeaderLine />
          </SummaryHeader>
          <SummarySubHeader>{t('statementItem.cutOffDate')}</SummarySubHeader>
          <Box padding="17px 98px 33px 75px">
            {state.isLoading && !payments && (
              <Box
                height="42px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress size={32} thickness={3} />
              </Box>
            )}
            {!state.isLoading && !!state.error && (
              <StyledErrorMessage>
                {t('errors.loadData')}{' '}
                <ButtonLink $fontSize={12} onClick={getPayments}>
                  {t('common.retry')}
                </ButtonLink>
              </StyledErrorMessage>
            )}
            {payments?.map((payment) => (
              <PaymentItem key={payment.id} payment={payment} />
            ))}
          </Box>
        </>
      )}
    </StatementItemWrapper>
  );
};

export default StatementItem;
