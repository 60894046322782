import React from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Box, Typography } from 'elements';

const SddMandateSection = () => {
  const { t } = useTranslation();
  const {
    state: { directDebitInfo },
  } = useCreditAndComplianceContext();

  return (
    <>
      <Typography variant="h6" mb={1}>
        {t('int.customerDeclarations.sddSection')}
      </Typography>

      {directDebitInfo ? (
        <Box component="ul" sx={{ marginTop: 1 }}>
          <li>
            <Typography>{t('int.customerDeclarations.sddMandate')}</Typography>
            {directDebitInfo.activationConfirmations.map((confirmation) => (
              <Typography
                key={confirmation.confirmedAt}
                variant="body2"
                color="textSecondary"
              >
                <Trans
                  i18nKey="int.customerDeclarations.confirmedByAndAt"
                  components={{ b: <b /> }}
                  values={{
                    confirmedBy: confirmation.email,
                    confirmedAt: moment(confirmation.confirmedAt).format(
                      'DD MMM YYYY HH:mm'
                    ),
                  }}
                />
              </Typography>
            ))}
          </li>
        </Box>
      ) : (
        <Typography color="textSecondary">-</Typography>
      )}
    </>
  );
};

export default SddMandateSection;
