import { styled } from '@mui/material';
import { IconButton } from 'elements';

export const Wrapper = styled('div')`
  position: relative;
  width: 640px;
  padding: 0 48px;
  margin: 0 auto;
`;

export const Slide = styled('div')`
  padding-bottom: 22px;
  text-align: center;
  outline: none;
`;

export const PrevButton = styled(IconButton)`
  position: absolute;
  top: 127px;
  left: 0;
`;

export const NextButton = styled(PrevButton)`
  left: auto;
  right: 0;
`;

export const Dot = styled('div')`
  width: 8px;
  height: 8px;
  margin: 6px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.text.disabled};
  cursor: pointer;

  .slick-active & {
    background: ${({ theme }) => theme.palette.text.primary};
  }
`;
