import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoFinApi from 'assets/images/logo-finapi.png';
import { LoaderWithOverlay } from 'components/Loader';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  SuitcaseSimpleIcon,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  BankAccount,
  BankConnectionUrl,
  BankForConnection,
  NetworkErrorCode,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useRequest from 'services/network/useRequest';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import BankSearchSelect from './BankSearchSelect';
import ConnectionSteps from './ConnectionSteps';

const getRedirectUrl = () => window.location.href + '?from=finapi';

interface State {
  bank: BankForConnection | null;
  isLoading: boolean;
}

interface Props extends DialogProps {
  onClose: () => void;
  bankAccountToReconnect?: BankAccount | null;
  bankToConnect?: BankForConnection | null;
}

const ConnectBankAccountsThroughFinApiDialog = ({
  bankAccountToReconnect = null,
  bankToConnect = null,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const request = useRequest();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    bank: null,
    isLoading: false,
  });

  const connectBank = async () => {
    try {
      setState((state) => ({ ...state, isLoading: true }));
      const data = await request<BankConnectionUrl>('/bank-connections', {
        method: bankAccountToReconnect ? 'put' : 'post',
        data: {
          organizationId: organization!.id,
          redirectUri: getRedirectUrl(),
          ...(bankAccountToReconnect
            ? { bankAccountId: bankAccountToReconnect.id }
            : { bank: state.bank || bankToConnect }),
        },
      });
      if (!mounted.current) return;
      if (data.webFormUri) {
        window.location.href = data.webFormUri;
      } else {
        window.location.href = getRedirectUrl();
      }
    } catch (error) {
      if (!mounted.current) return;
      setState((state) => ({ ...state, isLoading: false }));
      if (
        getNetworkErrorCode(error) ===
        NetworkErrorCode.bankConnectionImportIncomplete
      ) {
        enqueueSnackbar(t('connectBankAccountsDialog.pendingConnectionError'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{t('connectBankAccountsDialog.header')}</DialogTitle>
      <DialogContent>
        <ConnectionSteps
          isReconnectFlow={!!bankAccountToReconnect || !!bankToConnect}
        />

        <Box mb={2}>
          {bankAccountToReconnect || bankToConnect ? (
            <Typography
              variant="h6"
              component="div"
              display="flex"
              alignItems="center"
            >
              <SuitcaseSimpleIcon />
              <Box ml={1}>
                {bankAccountToReconnect?.bankName || bankToConnect?.name}
              </Box>
            </Typography>
          ) : (
            <BankSearchSelect
              value={state.bank}
              onChange={(bank) =>
                setState((state) => ({
                  ...state,
                  bank,
                }))
              }
            />
          )}
        </Box>

        <Box display="flex" alignItems="flex-start" justifyContent="flex-end">
          <Typography variant="caption" mr={2}>
            {t('connectBankAccountsDialog.connectExplanation')}
          </Typography>
          <Box
            sx={{
              height: 24,
              width: 73,
              background: `url('${logoFinApi}') center no-repeat`,
              backgroundSize: 'contain',
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={!state.bank && !bankToConnect && !bankAccountToReconnect}
          onClick={connectBank}
        >
          {t('connectBankAccountsDialog.connect')}
        </Button>
      </DialogActions>
      {state.isLoading && <LoaderWithOverlay size={32} thickness={3} />}
    </Dialog>
  );
};

export default withDialogWrapper(ConnectBankAccountsThroughFinApiDialog);
