import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { PaymentStatusBadge } from 'domains/billing/components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import { PaymentStatus, paymentStatuses } from 'services/constants';
import { QueryParams } from '.././index';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  fromDate: Moment | null;
  toDate: Moment | null;
  status: PaymentStatus[];
}

const initialState: State = {
  fromDate: null,
  toDate: null,
  status: [],
};

const FiltersDrawer = ({ params, setParam, ...props }: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>(initialState);

  const areFiltersSelected = !!(
    state.fromDate ||
    state.toDate ||
    state.status.length
  );

  useEffect(() => {
    if (props.open)
      setState({
        fromDate: params.fromDate,
        toDate: params.toDate,
        status: params.status,
      });
  }, [props.open]);

  const onFiltersApply = () => {
    setParam(
      Object.entries({
        ...state,
        fromDate: state.fromDate?.format('YYYY-MM-DD'),
        toDate: state.toDate?.format('YYYY-MM-DD'),
      })
    );
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.entries(initialState));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <DateFilter
        label={t('filters.period')}
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate,
            toDate,
          }))
        }
      />

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-status"
          id="panel-status-header"
        >
          <Typography>{t('filters.status')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {paymentStatuses.map((status) => (
              <FormControlLabel
                key={status}
                checked={state.status.includes(status)}
                control={<Checkbox />}
                onChange={() =>
                  setState((prevState) => ({
                    ...prevState,
                    status: xor(prevState.status, [status]),
                  }))
                }
                label={<PaymentStatusBadge status={status} />}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </FilterDrawer>
  );
};

export default FiltersDrawer;
