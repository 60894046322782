import { useFormik } from 'formik';
import { omit } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  PercentField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { SubscriptionPlan } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const MAX_FX_FEE_PERCENTAGE = 20;

interface FormValues {
  fxFeePercentage: number | undefined;
}

interface Props extends DialogProps {
  subscriptionPlan: SubscriptionPlan;
  onClose: () => void;
}

const UpdateFxFeePercentageDialog = ({ subscriptionPlan, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
    dispatch,
  } = useGlobalState();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      fxFeePercentage:
        subscriptionPlan.upcomingFxFeePercentage ??
        subscriptionPlan.fxFeePercentage,
    },
    onSubmit: async ({ fxFeePercentage }, { setSubmitting }) => {
      try {
        const data = await api.updateFxFeePercentage(organization!.id, {
          fxFeePercentage: fxFeePercentage!,
        });
        dispatch({
          type: 'SET_SUBSCRIPTION_PLAN_DATA',
          payload: { subscriptionPlan: data },
        });
        if (!mounted.current) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const isMaxPercentageExceededError =
    formik.values.fxFeePercentage !== undefined &&
    formik.values.fxFeePercentage > MAX_FX_FEE_PERCENTAGE;
  const isSubmitDisabled =
    formik.values.fxFeePercentage === undefined ||
    isMaxPercentageExceededError ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('int.updateFxFeePercentageDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="update-fx-fee-percentage-form"
          noValidate
        >
          <PercentField
            label={t('int.updateFxFeePercentageDialog.label')}
            autoFocus
            disabled={formik.isSubmitting}
            error={isMaxPercentageExceededError}
            helperText={
              isMaxPercentageExceededError && (
                <Trans
                  i18nKey="int.updateFxFeePercentageDialog.maxPercentageExceededError"
                  components={{
                    percentage: <FormatPercent value={MAX_FX_FEE_PERCENTAGE} />,
                  }}
                />
              )
            }
            {...omit(formik.getFieldProps('fxFeePercentage'), 'onChange')}
            onValueChange={({ floatValue }) =>
              formik.setFieldValue('fxFeePercentage', floatValue)
            }
            decimalScale={1}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="update-fx-fee-percentage-form"
          type="submit"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(UpdateFxFeePercentageDialog);
