import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderWithOverlay } from 'components/Loader';
import { FormControl, InputLabel, MenuItem, Select } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Timezone } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

interface Props {
  shouldFetchData: boolean;
  timezone: string;
  onChange: (timezone: string, offset: string) => void;
}

interface State {
  isLoading: boolean;
  timezones: Timezone[];
}

const TimezoneSelect = ({ shouldFetchData, timezone, onChange }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    isLoading: false,
    timezones: [],
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const timezones = await api.getTimezones();
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false, timezones }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(t('errors.featureModulesLoadingFailed'), {
        variant: 'error',
      });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    if (shouldFetchData) {
      getData();
    }
  }, [shouldFetchData]);

  if (state.isLoading) return <LoaderWithOverlay size={32} thickness={3} />;

  return (
    <>
      {!!state.timezones.length && (
        <FormControl fullWidth>
          <InputLabel>{t('cardControlFormElement.time.timezone')}</InputLabel>
          <Select
            value={timezone}
            onChange={(e) => {
              const item = state.timezones.find(
                (item) => item.timezone === (e.target.value as string)
              )!;
              onChange(item.timezone, item.offset);
            }}
            renderValue={(value) => {
              const item = state.timezones.find(
                (item) => item.timezone === value
              )!;
              return item ? `(${item.offset}) ${item.cities}` : '';
            }}
          >
            {state.timezones.map((item) => (
              <MenuItem
                key={item.timezone}
                value={item.timezone}
                title={item.cities}
                sx={{ maxWidth: '500px' }}
              >
                ({item.offset}) {item.cities}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default TimezoneSelect;
