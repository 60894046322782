import {
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
} from '@mui/material';

type Size = 'small' | 'medium' | 'large';

const CSS_PROPS_BY_SIZE: Record<
  Size,
  { height: number; borderRadius: string }
> = {
  small: { height: 4, borderRadius: '2px' },
  medium: { height: 8, borderRadius: '4px' },
  large: { height: 12, borderRadius: '6px' },
};

export interface LinearProgressProps extends MuiLinearProgressProps {
  /**
   * The size of the component.
   * @default 'medium'
   */
  size?: Size;
  /**
   * The variant to use.
   * Use indeterminate or query when there is no progress value.
   * @default 'determinate'
   */
  variant?: 'determinate' | 'indeterminate' | 'buffer' | 'query';
}

const LinearProgress = ({
  size = 'medium',
  variant = 'determinate',
  sx = [],
  ...props
}: LinearProgressProps) => {
  const { height, borderRadius } = CSS_PROPS_BY_SIZE[size];

  return (
    <MuiLinearProgress
      sx={[
        (theme) => ({
          height,
          borderRadius,
          background: theme.palette.grey[300],
          '.MuiLinearProgress-bar': { borderRadius },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      variant={variant}
      {...props}
    />
  );
};

export default LinearProgress;
