import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import WidgetError from 'components/WidgetError';
import { ReceiptPreviewDialog } from 'domains/transaction/components';
import { LoaderWithOverlay } from 'elements';
import useMounted from 'hooks/useMounted';
import {
  DetailsDrawer,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  AccountingTransaction,
  Receipt,
  Transaction,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import GlobalTransactionDetailsContent from './GlobalTransactionDetailsContent';

interface State {
  isLoading: boolean;
  transaction: Transaction | null;
  error: unknown;
  receipts: Receipt[];
  accountingTransactions: AccountingTransaction[] | null;
  isReceiptPreviewDialogOpen: boolean;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (transaction: Transaction) => void;
}

const GlobalTransactionDetailsPage = ({ onUpdate, ...props }: Props) => {
  const api = useImperativeApi();
  const { transactionId } = useParams<{ transactionId: string }>();
  const mounted = useMounted();
  const idRef = useRef(transactionId);
  const [state, setState] = useState<State>({
    isLoading: false,
    transaction: null,
    error: null,
    receipts: [],
    accountingTransactions: null,
    isReceiptPreviewDialogOpen: false,
  });

  useEffect(() => {
    if (state.transaction) onUpdate(state.transaction);
  }, [state.transaction]);

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [transaction, { receipts }] = await Promise.all([
        api.getGlobalTransaction(transactionId),
        api.getReceipts(transactionId),
      ]);

      if (!mounted.current || transactionId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        transaction,
        receipts,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || transactionId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        transaction: null,
        error,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!transactionId) return;
    idRef.current = transactionId;
    getData();
  }, [transactionId]);

  const renderContent = () => {
    const content = (
      <GlobalTransactionDetailsContent
        transaction={state.transaction!}
        receipts={state.receipts!}
        onUpdate={getData}
        onRefresh={getData}
        isReceiptPreviewDialogOpen={state.isReceiptPreviewDialogOpen}
        onReceiptPreviewDialogOpen={() =>
          setState((prevState) => ({
            ...prevState,
            isReceiptPreviewDialogOpen: true,
          }))
        }
      />
    );

    return (
      <>
        {content}
        <ReceiptPreviewDialog
          open={state.isReceiptPreviewDialogOpen}
          transaction={state.transaction!}
          receipts={state.receipts}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              isReceiptPreviewDialogOpen: false,
            }))
          }
          onRemove={(id) => {
            setState((prevState) => {
              const existingReceipts = prevState.receipts.filter(
                (item) => item.receiptId !== id
              );
              return {
                ...prevState,
                transaction: {
                  ...prevState.transaction!,
                  receiptsCount: existingReceipts.length,
                },
                receiptsCount: existingReceipts.length,
                receipts: existingReceipts,
              };
            });
          }}
        >
          {content}
        </ReceiptPreviewDialog>
      </>
    );
  };

  return (
    <DetailsDrawer {...props}>
      {state.error && <WidgetError onReload={getData} />}
      {state.transaction && renderContent()}
      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(GlobalTransactionDetailsPage);
