import FormatMoney from 'components/FormatMoney';
import { Box, Typography } from 'elements';
import { Money, ProgramSettlementStatus } from 'services/constants';

interface Props {
  status: ProgramSettlementStatus;
  primary: Money;
  secondary: Money | null;
}

const CustomMoneyCell = ({ status, primary, secondary }: Props) => {
  if (status === ProgramSettlementStatus.reconcile && secondary) {
    return (
      <Box>
        <Typography component="div" variant="inherit" color="error.main" noWrap>
          <FormatMoney value={secondary} fractionalPart />
        </Typography>
        <FormatMoney value={primary} fractionalPart />
      </Box>
    );
  }
  return <FormatMoney value={primary} fractionalPart />;
};

export default CustomMoneyCell;
