import { Suspense, useEffect, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';
import PdfViewer from 'components/PdfViewer';
import { ReceiptPreviewError } from 'domains/transaction/components';
import ImagePreview from 'domains/transaction/components/TransactionDetails/ReceiptPreviewDialog/ImagePreview';
import { DETAILS_WIDTH } from 'domains/transaction/dialogs/ReceiptInboxDialog/ReceiptInboxDetails';
import { Box, LoaderWithOverlay, Paper } from 'elements';
import useMounted from 'hooks/useMounted';
import { MimeType, ReceiptsAutoMatchingAttachment } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { downloadFileUsingAnchorTag } from 'services/utils';
import PreviewDialogHeader from './ReceiptPreviewHeader';

export const DEFAULT_SCALE = 1;
const DEFAULT_PAGE = 1;
const DEFAULT_NUM_PAGES = 1;

interface Props {
  id: string;
  onClose: () => void;
}

interface State {
  isLoading: boolean;
  receiptPageNumber: number;
  receiptNumPages: number;
  receiptScale: number;
  data: ReceiptsAutoMatchingAttachment | null;
  hasError: boolean;
}

const ReceiptPreview = ({ id, onClose }: Props) => {
  const listRef = useRef<FixedSizeList>(null);
  const mounted = useMounted();
  const api = useImperativeApi();
  const [state, setState] = useState<State>({
    isLoading: true,
    receiptPageNumber: DEFAULT_PAGE,
    receiptNumPages: DEFAULT_NUM_PAGES,
    receiptScale: DEFAULT_SCALE,
    data: null,
    hasError: false,
  });

  const getData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const data = await api.getReceiptsAutoMatchingAttachment(id);
      if (!mounted.current) return;

      setState((prevState) => ({
        ...prevState,
        data: {
          ...data,
          attachment: `data:${data.mimeType};base64,${data.attachment}`,
        },
        isLoading: false,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        hasError: true,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  const onChange = (newPage?: number, newScale?: number) => {
    if (listRef?.current?.scrollToItem && newPage) {
      listRef.current.scrollToItem(newPage - 1, 'start');
    }

    setState((prevState) => ({
      ...prevState,
      receiptPageNumber: newPage || prevState.receiptPageNumber,
      receiptScale: newScale || prevState.receiptScale,
    }));
  };

  return (
    <Paper
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: `calc(100% - ${DETAILS_WIDTH}px)`,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PreviewDialogHeader
        isLoading={state.isLoading}
        isAttachmentEmpty={!state.data?.attachment}
        fileName={state.data?.fileName || ''}
        page={state.receiptPageNumber}
        numPages={state.receiptNumPages}
        scale={state.receiptScale}
        onChange={onChange}
        onDownload={() =>
          downloadFileUsingAnchorTag(
            state.data!.fileName,
            state.data!.attachment
          )
        }
        onClose={onClose}
      />

      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        minHeight={0}
      >
        <LoaderWithOverlay loading={state.isLoading} />
        {/* todo: move PdfViewer and ImagePreview to shared components folder,
        when https://github.com/infinnity-card/infinnity-frontend/pull/3825 is merged */}
        {state.data?.attachment &&
          (state.data.mimeType === MimeType.pdf ? (
            <Suspense fallback={<LoaderWithOverlay loading />}>
              <PdfViewer
                ref={listRef}
                selectedPage={state.receiptPageNumber}
                numPages={state.receiptNumPages}
                scale={state.receiptScale}
                onChange={(receiptPageNumber, receiptNumPages) =>
                  setState((prevState) => ({
                    ...prevState,
                    receiptPageNumber,
                    receiptNumPages,
                  }))
                }
                data={state.data.attachment}
                // no need to handle error
                onError={() => {}}
              />
            </Suspense>
          ) : (
            <ImagePreview
              data={state.data.attachment}
              scale={state.receiptScale}
            />
          ))}

        {state.hasError && !state.isLoading && (
          <ReceiptPreviewError onRetry={() => getData()} />
        )}
      </Box>
    </Paper>
  );
};

export default ReceiptPreview;
