import { useEffect, useMemo, useRef, useState } from 'react';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import WidgetError from 'components/WidgetError';
import {
  DownloadVssFileButton,
  SettlementStatusBadge,
  VssTypeBadge,
} from 'domains/billing/components';
import {
  ActionBox,
  ActionBoxActions,
  ActionBoxTitle,
  Box,
  Button,
  CalendarCheckIcon,
  CheckCircleIcon,
  Chip,
  Divider,
  LoaderWithOverlay,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  VssMissingIcon,
  WarningCircleIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  DEFAULT_PAGE_LIMIT,
  PaymentStatus,
  PaymentType,
  ProgramSettlement,
  ProgramSettlementPaymentsFile,
  Settlement,
  SettlementPaymentType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import OrgSettlementsList from './OrgSettlementsList';
import ProgramSettlementPaymentsFileDetails from './ProgramSettlementPaymentsFileDetails';
import TriggerPaymentManuallyDialog from './TriggerPaymentManuallyDialog';

const isPaymentFileInReadyForPaymentType = (
  paymentFiles: ProgramSettlementPaymentsFile[],
  paymentType: ProgramSettlementPaymentsFile['paymentType']
) => {
  const count =
    paymentFiles.find((item) => item.paymentType === paymentType)
      ?.paymentsStatuses?.[PaymentStatus.readyForPmt] || 0;

  return count > 0;
};

interface StepDetails {
  header: string;
  label: string;
  button: string;
  completed: boolean;
  paymentFileIds: string[];
  custom: boolean;
}

const useGetSteps = (
  paymentFiles: ProgramSettlementPaymentsFile[],
  settlement: ProgramSettlement | null
) => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    if (!settlement) return [];

    const steps: StepDetails[] = [];

    if (!settlement) return steps;

    const receivableTransfer = paymentFiles.find(
      (item) => item.paymentType === PaymentType.receivablePurchasePmt
    );
    const receivableDiscount = paymentFiles.find(
      (item) => item.paymentType === PaymentType.receivableDiscountPmt
    );
    if (receivableTransfer && receivableDiscount) {
      steps.push({
        header: `${t(
          `paymentTypes.${PaymentType.receivablePurchasePmt}`
        )} & ${t(`paymentTypes.${PaymentType.receivableDiscountPmt}`)}`,
        label: t('int.programSettlementDetailsPage.steps.rt'),
        button: t('int.programSettlementDetailsPage.steps.triggerRtAndDp'),
        completed:
          !receivableTransfer.paymentsStatuses?.[PaymentStatus.readyForPmt] &&
          !receivableDiscount.paymentsStatuses?.[PaymentStatus.readyForPmt],
        paymentFileIds: [
          receivableTransfer.paymentFileId,
          receivableDiscount.paymentFileId,
        ],
        custom: false,
      });
    }

    const creditFunding = paymentFiles.find(
      (item) => item.paymentType === PaymentType.creditFundingPmt
    );
    if (creditFunding) {
      steps.push({
        header: t(`paymentTypes.${PaymentType.creditFundingPmt}`),
        label: t('int.programSettlementDetailsPage.steps.cf'),
        button: t('int.programSettlementDetailsPage.steps.triggerCf'),
        completed: !creditFunding.paymentsStatuses?.[PaymentStatus.readyForPmt],
        paymentFileIds: [creditFunding.paymentFileId],
        custom: false,
      });
    }

    const os = paymentFiles.find(
      (item) => item.paymentType === PaymentType.orgSetlPmt
    );
    if (os) {
      steps.push({
        header: t(`paymentTypes.${PaymentType.orgSetlPmt}`),
        label: t('int.programSettlementDetailsPage.steps.os'),
        button: t('int.programSettlementDetailsPage.steps.triggerOs'),
        completed: !os.paymentsStatuses?.[PaymentStatus.readyForPmt],
        paymentFileIds: [os.paymentFileId],
        custom: false,
      });
    }

    const programSettlement = paymentFiles.find(
      (item) => item.paymentType === PaymentType.programSetlPmt
    );
    const netFee = paymentFiles.find(
      (item) => item.paymentType === PaymentType.programSetlFeePmt
    );
    if (
      settlement.manualProgramSettlementPayment &&
      settlement.settlementPaymentType === SettlementPaymentType.gross
    ) {
      if (programSettlement) {
        steps.push({
          header: `${capitalize(settlement.settlementPaymentType)} ${t(
            `paymentTypes.${PaymentType.programSetlPmt}`
          )}`,
          label: t('int.programSettlementDetailsPage.steps.psp'),
          // only [Submit PSP Manually] btn visible
          button: '',
          completed: !programSettlement.paymentsStatuses?.[
            PaymentStatus.readyForPmt
          ],
          paymentFileIds: [],
          custom: true,
        });
      }
    } else if (settlement.manualProgramSettlementPayment) {
      if (programSettlement && netFee) {
        steps.push({
          header: `${capitalize(settlement.settlementPaymentType)} ${t(
            `paymentTypes.${PaymentType.programSetlPmt}`
          )} & ${capitalize(settlement.settlementPaymentType)} ${t(
            `paymentTypes.${PaymentType.programSetlFeePmt}`
          )}`,
          label: t('int.programSettlementDetailsPage.steps.psAndFee'),
          button: t('int.programSettlementDetailsPage.steps.triggerFee'),
          completed:
            !programSettlement.paymentsStatuses?.[PaymentStatus.readyForPmt] &&
            !netFee.paymentsStatuses?.[PaymentStatus.readyForPmt],
          paymentFileIds: [netFee.paymentFileId],
          custom: true,
        });
      }
    } else if (settlement.settlementPaymentType === SettlementPaymentType.net) {
      if (programSettlement && netFee) {
        steps.push({
          header: `${capitalize(settlement.settlementPaymentType)} ${t(
            `paymentTypes.${PaymentType.programSetlPmt}`
          )} & ${capitalize(settlement.settlementPaymentType)} ${t(
            `paymentTypes.${PaymentType.programSetlFeePmt}`
          )}`,
          label: t('int.programSettlementDetailsPage.steps.psAndFee'),
          button: t('int.programSettlementDetailsPage.steps.triggerPsAndFee'),
          completed:
            !programSettlement.paymentsStatuses?.[PaymentStatus.readyForPmt] &&
            !netFee.paymentsStatuses?.[PaymentStatus.readyForPmt],
          paymentFileIds: [
            programSettlement.paymentFileId,
            netFee.paymentFileId,
          ],
          custom: false,
        });
      } else if (programSettlement) {
        steps.push({
          header: `${capitalize(settlement.settlementPaymentType)} ${t(
            `paymentTypes.${PaymentType.programSetlPmt}`
          )}`,
          label: t('int.programSettlementDetailsPage.steps.ps'),
          button: t('int.programSettlementDetailsPage.steps.triggerPs'),
          completed: !programSettlement.paymentsStatuses?.[
            PaymentStatus.readyForPmt
          ],
          paymentFileIds: [programSettlement.paymentFileId],
          custom: false,
        });
      }
    } else {
      if (programSettlement) {
        steps.push({
          header: `${capitalize(settlement.settlementPaymentType)} ${t(
            `paymentTypes.${PaymentType.programSetlPmt}`
          )}`,
          label: t('int.programSettlementDetailsPage.steps.ps'),
          button: t('int.programSettlementDetailsPage.steps.triggerPs'),
          completed: !programSettlement.paymentsStatuses?.[
            PaymentStatus.readyForPmt
          ],
          paymentFileIds: [programSettlement.paymentFileId],
          custom: false,
        });
      }
    }

    return steps;
  }, [paymentFiles, settlement, i18n.language]);
};

interface State {
  isLoading: boolean;
  settlement: ProgramSettlement | null;
  error: unknown;
  settlementsLoadedPage: number;
  settlements: Settlement[];
  hasMoreSettlements: boolean;
  areSettlementsLoading: boolean;
  settlementsError: unknown;
  paymentFiles: ProgramSettlementPaymentsFile[];
  isTriggeringSettlementPayment: boolean;
  isTriggerManuallyDialogOpen: boolean;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (settlement: ProgramSettlement) => void;
}

const ProgramSettlementDetailsPage = ({ onUpdate, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const { settlementId } = useParams<{ settlementId: string }>();
  const mounted = useMounted();
  const idRef = useRef(settlementId);
  const contentRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<State>({
    isLoading: true,
    settlement: null,
    error: null,
    settlementsLoadedPage: 0,
    settlements: [],
    hasMoreSettlements: false,
    areSettlementsLoading: false,
    settlementsError: null,
    paymentFiles: [],
    isTriggeringSettlementPayment: false,
    isTriggerManuallyDialogOpen: false,
  });
  const { settlement } = state;

  const steps = useGetSteps(state.paymentFiles, state.settlement);
  const activeStep = steps.find((step) => !step.completed);
  const activeStepIndex =
    (!activeStep && steps.length) || steps.indexOf(activeStep!);

  const getData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        settlementsLoadedPage: 0,
        isLoading: true,
      }));
      const [
        settlement,
        paymentFiles,
        { settlements, hasNextPage: hasMoreSettlements },
      ] = await Promise.all([
        api.getProgramSettlement(settlementId),
        api.getProgramSettlementPaymentsFilesDetails(settlementId),
        api.getOrgSettlements({
          page: state.settlementsLoadedPage,
          limit: DEFAULT_PAGE_LIMIT,
          programSettlementId: settlementId,
        }),
      ]);
      if (!mounted.current || settlementId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        settlement,
        settlements,
        hasMoreSettlements,
        paymentFiles,
        error: null,
        settlementsError: null,
        isLoading: false,
      }));
      contentRef.current?.scrollTo({ top: 0 });
    } catch (error) {
      if (!mounted.current || settlementId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        error,
        settlement: null,
        isLoading: false,
      }));
      logError(error);
    }
  };

  const getNextSettlementsPage = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        areSettlementsLoading: true,
        settlementsError: null,
      }));
      const { settlements, hasNextPage } = await api.getOrgSettlements({
        page: state.settlementsLoadedPage + 1,
        limit: DEFAULT_PAGE_LIMIT,
        programSettlementId: settlementId,
      });
      if (!mounted.current || settlementId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        settlements: [...prevState.settlements, ...settlements],
        hasMoreSettlements: hasNextPage,
        areSettlementsLoading: false,
        settlementsLoadedPage: prevState.settlementsLoadedPage + 1,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        areSettlementsLoading: false,
        settlementsError: error,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!settlementId) return;
    idRef.current = settlementId;
    getData();
  }, [settlementId]);

  const triggerSettlementPayments = async (paymentFileIds: string[]) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isTriggeringSettlementPayment: true,
      }));
      await api.submitProgramSettlementPaymentsFiles(
        paymentFileIds,
        state.settlement!.settlementId
      );
      if (!mounted.current) return;
      getData();
      setState((prevState) => ({
        ...prevState,
        isTriggeringSettlementPayment: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isTriggeringSettlementPayment: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (state.settlement) {
      onUpdate(state.settlement);
    }
  }, [state.settlement]);

  return (
    <>
      <DetailsDrawer {...props}>
        {settlement && (
          <>
            <DetailsDrawerHeader pb={2}>
              <Box display="flex" alignItems="center">
                <Typography variant="h5" mr="auto">
                  {settlement.reportDate}
                </Typography>
                {settlement.vssFileName ? (
                  <DownloadVssFileButton
                    programSettlementId={settlement.settlementId}
                  />
                ) : (
                  <VssMissingIcon color="error" />
                )}
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                flexWrap="wrap"
                spacing={1}
                my={2}
                useFlexGap
              >
                <Chip
                  label={t(
                    `int.accountGroupSelect.items.${settlement.accountGroup}`
                  )}
                  size="small"
                />
                <VssTypeBadge value={settlement.vssType} />
                <Chip label={settlement.sreId} size="small" />
                <Chip label={settlement.currency} size="small" />
              </Stack>
              <Box display="flex" alignItems="center">
                <CalendarCheckIcon sx={{ color: 'text.secondary' }} />
                <Typography
                  variant="caption"
                  color="text.secondary"
                  ml={1}
                  mr={2}
                >
                  {t('int.programSettlementDetailsPage.createdAt')}{' '}
                  <Tooltip
                    title={t('int.programSettlementDetailsPage.berlinTime')}
                  >
                    <span>
                      {moment(settlement.createdAt).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )}
                    </span>
                  </Tooltip>
                </Typography>
                <SettlementStatusBadge status={settlement.status} />
              </Box>
            </DetailsDrawerHeader>
            <DetailsDrawerContent
              onScroll={(e) => {
                const target = e.currentTarget;
                if (
                  !state.settlementsError &&
                  state.hasMoreSettlements &&
                  !state.areSettlementsLoading &&
                  target.scrollTop + target.clientHeight >=
                    target.scrollHeight - 60
                ) {
                  getNextSettlementsPage();
                }
              }}
            >
              {!!steps.length && (
                <ActionBox
                  icon={
                    activeStep ? (
                      <WarningCircleIcon
                        sx={{
                          width: 22,
                          height: 22,
                        }}
                      />
                    ) : (
                      <CheckCircleIcon
                        sx={{
                          width: 22,
                          height: 22,
                        }}
                      />
                    )
                  }
                  sx={{
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <ActionBoxTitle>
                    {activeStep?.header ||
                      t(
                        'int.programSettlementDetailsPage.stepperHeaderCompleted'
                      )}
                  </ActionBoxTitle>

                  <Typography variant="body2" mb={2}>
                    {!!activeStep &&
                      t('int.programSettlementDetailsPage.stepperSubHeader')}
                  </Typography>

                  <Stepper activeStep={activeStepIndex} alternativeLabel>
                    {steps.map((step) => (
                      <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  {!!activeStep && canUser('program-settlements:update') && (
                    <ActionBoxActions>
                      {activeStep.custom ? (
                        <>
                          {isPaymentFileInReadyForPaymentType(
                            state.paymentFiles,
                            PaymentType.programSetlPmt
                          ) && (
                            <Button
                              variant="outlined"
                              onClick={() =>
                                setState((prevState) => ({
                                  ...prevState,
                                  isTriggerManuallyDialogOpen: true,
                                }))
                              }
                            >
                              {t(
                                'int.programSettlementDetailsPage.submitManually'
                              )}
                            </Button>
                          )}
                          {isPaymentFileInReadyForPaymentType(
                            state.paymentFiles,
                            PaymentType.programSetlFeePmt
                          ) && (
                            <Button
                              onClick={() =>
                                triggerSettlementPayments(
                                  activeStep.paymentFileIds
                                )
                              }
                            >
                              {activeStep.button}
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button
                          onClick={() =>
                            triggerSettlementPayments(activeStep.paymentFileIds)
                          }
                        >
                          {activeStep.button}
                        </Button>
                      )}
                    </ActionBoxActions>
                  )}
                </ActionBox>
              )}

              <Paper
                variant="outlined"
                sx={{ mt: 4, mx: 3, mb: 3, px: 2.5, py: 1.5 }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1.5}
                >
                  <Typography variant="body1">
                    {t('int.programSettlementDetailsPage.transactionsCount')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {settlement.transactionsCount}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1.5}
                >
                  <Typography variant="body1">
                    {t('int.programSettlementDetailsPage.cardAccountCount')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {settlement.cardAccountCount || '-'}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1">
                    {t('int.programSettlementDetailsPage.organizationCount')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {settlement.organizationCount || '-'}
                  </Typography>
                </Box>
                {!!settlement.totalDrawdownAmount && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1.5}
                  >
                    <Typography variant="body1">
                      {t(
                        'int.programSettlementDetailsPage.totalDrawdownAmount'
                      )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <FormatMoney value={settlement.totalDrawdownAmount} />
                    </Typography>
                  </Box>
                )}
              </Paper>
              <Divider />

              <Paper
                variant="outlined"
                sx={{ mt: 4, mx: 3, mb: 3, px: 2.5, py: 1.5 }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1.5}
                >
                  <Typography variant="body1">
                    {t(
                      'int.programSettlementDetailsPage.grossSettlementAmount'
                    )}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <FormatMoney
                      value={settlement.settlementAmount}
                      fractionalPart
                    />
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1.5}
                >
                  <Typography variant="body1">
                    {t('int.programSettlementDetailsPage.fees')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <FormatMoney value={settlement.feesAmount} fractionalPart />
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1">
                    {t('int.programSettlementDetailsPage.charges')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <FormatMoney
                      value={settlement.chargesAmount}
                      fractionalPart
                    />
                  </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1">
                    {t('int.programSettlementDetailsPage.net')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <FormatMoney
                      value={settlement.netSettlementAmount}
                      fractionalPart
                    />
                  </Typography>
                </Box>
              </Paper>
              <Divider />

              <Box
                p={3}
                borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
              >
                {state.paymentFiles.map((item) => (
                  <ProgramSettlementPaymentsFileDetails
                    key={item.paymentFileId}
                    data={item}
                    settlement={settlement}
                    dateTooltip={t(
                      'int.programSettlementDetailsPage.berlinTime'
                    )}
                  />
                ))}
              </Box>

              <OrgSettlementsList
                settlements={state.settlements}
                hasNextPage={state.hasMoreSettlements}
                onLoadMore={getNextSettlementsPage}
                error={state.settlementsError}
                isDrawdownAmountVisible={
                  state.settlement?.syntheticCreditEnabled
                }
              />
            </DetailsDrawerContent>
          </>
        )}
        {state.error && <WidgetError onReload={getData} />}
        <LoaderWithOverlay
          loading={state.isLoading || state.isTriggeringSettlementPayment}
        />
      </DetailsDrawer>
      <TriggerPaymentManuallyDialog
        open={state.isTriggerManuallyDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isTriggerManuallyDialogOpen: false,
          }))
        }
        onSuccess={() => {
          getData();
          setState((prevState) => ({
            ...prevState,
            settlement,
            isTriggerManuallyDialogOpen: false,
          }));
        }}
        settlement={settlement!}
      />
    </>
  );
};

export default withDetailsDrawerWrapper(ProgramSettlementDetailsPage);
