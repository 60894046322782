import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { AccountGroupSelect } from 'domains/organization/components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { AccountGroup, CardAccountCurrency } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  accountGroup: AccountGroup;
  currency: CardAccountCurrency;
}

interface Props extends DialogProps {
  onClose: () => void;
}

const MainCardAccountAccountGroupGroupChangeDialog = (props: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, cardAccountCurrencies, defaultCardAccount },
  } = useGlobalState();
  const mounted = useMounted();
  const api = useImperativeApi();

  const formik = useFormik<FormValues>({
    validateOnMount: false,
    validateOnBlur: false,
    initialValues: {
      accountGroup: defaultCardAccount!.accountGroup!.value,
      currency: defaultCardAccount!.currency.value,
    },
    onSubmit: async (values) => {
      try {
        const updatedOrg = await api.updateMainCardAccountAccountGroup(
          organization!.id,
          values.accountGroup,
          values.currency
        );
        const { cardAccounts } = await api.getCardAccounts(organization!.id);

        if (!mounted.current) return;
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: {
            organization: updatedOrg,
            defaultCardAccount: cardAccounts.find(
              (item) => item.defaultAccount.value
            )!,
          },
        });
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        props.onClose();
        logError(error);
      }
    },
  });

  const isSubmitDisabled =
    formik.isSubmitting ||
    !formik.values.accountGroup ||
    (defaultCardAccount!.accountGroup!.value === formik.values.accountGroup &&
      defaultCardAccount!.currency.value === formik.values.currency);

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t(
          'int.generalTermsPage.onboardingSettingsSection.mainCardAccountAccountGroup'
        )}
      </DialogTitle>

      <DialogContent>
        <form
          id="account-group-change-form"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="main-card-account-currency-select-label">
                  {t(
                    'int.generalTermsPage.onboardingSettingsSection.mainCurrency'
                  )}
                </InputLabel>
                <Select
                  {...formik.getFieldProps('currency')}
                  labelId="main-card-account-currency-select-label"
                >
                  {cardAccountCurrencies.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <AccountGroupSelect
                country={organization!.country}
                currency={formik.values.currency}
                mode="edit"
                onChange={(value) =>
                  formik.setFieldValue('accountGroup', value)
                }
                partnerId={organization!.partnerId}
                value={formik.values.accountGroup}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          disabled={formik.isSubmitting}
          onClick={props.onClose}
        >
          {t('common.button.cancel')}
        </Button>

        <Button
          disabled={isSubmitDisabled}
          form="account-group-change-form"
          type="submit"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(MainCardAccountAccountGroupGroupChangeDialog);
