import { TFunction } from 'i18next';
import { SupportedCountry } from 'services/constants';

function getValidationRegexByCountry(country: SupportedCountry) {
  switch (country) {
    case SupportedCountry.at:
      return /^ATU[\d]{8}$/;
    case SupportedCountry.be:
      return /^BE[\d]{10}$/;
    case SupportedCountry.bg:
      return /^BG[\d]{9,10}$/;
    case SupportedCountry.ch:
      return /^CHE[\d]{9}(MWST|TVA|IVA)$/;
    case SupportedCountry.cy:
      return /^CY[\d]{8}[A-Z]{1}$/;
    case SupportedCountry.cz:
      return /^CZ[\d]{8,10}$/;
    case SupportedCountry.de:
      return /^DE[\d]{9}$/;
    case SupportedCountry.dk:
      return /^DK[\d]{8}$/;
    case SupportedCountry.ee:
      return /^EE[\d]{9}$/;
    case SupportedCountry.gr:
      return /^EL[\d]{9}$/;
    case SupportedCountry.es:
      return /^ES[A-Z\d]{1}\d{7}[A-Z\d]{1}$/;
    case SupportedCountry.fi:
      return /^FI[\d]{8}$/;
    case SupportedCountry.fr:
      return /^FR((?![OI])[A-Z\d]){2}\d{9}$/;
    case SupportedCountry.hr:
      return /^HR[\d]{11}$/;
    case SupportedCountry.hu:
      return /^HU[\d]{8}$/;
    case SupportedCountry.ie:
      return /^IE[0-9A-Z]{8,9}$/;
    case SupportedCountry.is:
      return /^IS[\d]{6}$/;
    case SupportedCountry.it:
      return /^IT[\d]{11}$/;
    case SupportedCountry.li:
      return /^LI[\d]{5}$/;
    case SupportedCountry.lt:
      return /^LT[\d]{9,12}$/;
    case SupportedCountry.lu:
      return /^LU[\d]{8}$/;
    case SupportedCountry.lv:
      return /^LV[\d]{11}$/;
    case SupportedCountry.mt:
      return /^MT[\d]{8}$/;
    case SupportedCountry.nl:
      return /^NL[\d]{9}B[\d]{2}$/;
    case SupportedCountry.no:
      return /^[\d]{9}MVA$/;
    case SupportedCountry.pl:
      return /^PL[\d]{10}$/;
    case SupportedCountry.pt:
      return /^PT[\d]{9}$/;
    case SupportedCountry.ro:
      return /^RO[\d]{2,10}$/;
    case SupportedCountry.se:
      return /^SE[\d]{12}$/;
    case SupportedCountry.si:
      return /^SI[\d]{8}$/;
    case SupportedCountry.sk:
      return /^SK[\d]{10}$/;
    case SupportedCountry.uk:
      return /^GB[\d]{9}$/;
    default:
      return null;
  }
}

export function validateVatIdByCountry(
  country: SupportedCountry,
  vatId: string,
  t: TFunction
): string | null {
  const regex = getValidationRegexByCountry(country);

  if (regex && !regex.test(vatId)) {
    return t(`errors.fields.vatId${country}Format`);
  }

  return null;
}
