import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoaderWithOverlay,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OnboardingDocument } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  reasonForRequest: string;
}

interface Props {
  document: OnboardingDocument;
  onClose: () => void;
  onUpdate: () => Promise<void>;
  open: boolean;
}

const RejectDocumentDialog = ({ document, onUpdate, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      reasonForRequest: document.reasonForRequest ?? '',
    },
    onSubmit: async ({ reasonForRequest }) => {
      try {
        await api.requestOnboardingDocuments(organization!.id, [
          {
            onboardingDocumentId: document.id,
            reasonForRequest,
          },
        ]);

        await onUpdate();
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const isCommentTooLong = formik.values.reasonForRequest.length > 255;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('int.rejectOnboardingDocumentDialog.title')}</DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="reject-document-form"
          noValidate
        >
          <Typography variant="body2" mb={4}>
            {t('int.rejectOnboardingDocumentDialog.description')}
          </Typography>
          <TextField
            {...formik.getFieldProps('reasonForRequest')}
            label={t('int.rejectOnboardingDocumentDialog.commentLabel')}
            multiline
            minRows={3}
            maxRows={5}
            error={isCommentTooLong}
            helperText={
              isCommentTooLong &&
              t('int.rejectOnboardingDocumentDialog.commentTooLongError')
            }
            autoFocus
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.close')}
        </Button>
        <Button
          disabled={
            formik.isSubmitting ||
            !formik.values.reasonForRequest ||
            isCommentTooLong
          }
          form="reject-document-form"
          type="submit"
        >
          {t('common.button.reject')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(RejectDocumentDialog);
