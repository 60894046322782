import { FormControlLabel, styled } from '@mui/material';
import { Autocomplete, Box, Paper, RadioGroup, TableContainer } from 'elements';

export const StyledAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-input {
    background: transparent;
    padding: ${({ theme }) => theme.spacing(0, 1)};
  }
` as typeof Autocomplete;

export const StyledBox = styled(Box)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const SummaryFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0;
`;

export const Wrapper = styled((props) => <Paper {...props} variant="tinted" />)`
  padding: ${({ theme }) => theme.spacing(2.5)};
  margin: ${({ theme }) => theme.spacing(0, 0, 2)};
`;

export const StyledRadioGroup = styled(RadioGroup)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const StyledTableContainer = styled(TableContainer)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
