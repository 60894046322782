import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import FormatDecimal from 'components/FormatDecimal';
import FormatMoney from 'components/FormatMoney';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import { Box, Paper, Typography } from 'elements';
import { Transaction } from 'services/constants';

const ValueWrapper = ({ children }: { children: ReactNode }) => (
  <Paper
    variant="outlined"
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: 56,
      px: 1.5,
      py: 2,
      mb: 1,
    }}
  >
    {children}
  </Paper>
);

interface Props {
  transaction: Transaction;
}

const AdditionalInfoSection = ({ transaction }: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();
  const isFxFeeVisible =
    !!transaction.fxFee.fxFeePercentage && !!transaction.fxFee.fxFee;
  const isCo2Visible = featureModules.PLIANT_EARTH && !!transaction.emission;

  if (!isFxFeeVisible && !isCo2Visible) return null;

  return (
    <Box
      p={3}
      sx={(theme) => ({
        borderTop: `1px solid ${theme.palette.divider}`,
        '.MuiPaper-root:last-of-type': { mb: 0 },
      })}
    >
      <Typography variant="overline" component="div" mb={1.5}>
        {t('transactionDetails.additionalInfoSection.title')}
      </Typography>

      {!!transaction.fxFee.fxFeePercentage && !!transaction.fxFee.fxFee && (
        <ValueWrapper>
          <Typography variant="body2">
            {t('transactionDetails.additionalInfoSection.fxFee')}
          </Typography>
          <Typography variant="body2">
            (<FormatPercent value={transaction.fxFee.fxFeePercentage} />){' '}
            <FormatMoney value={transaction.fxFee.fxFee} fractionalPart />
          </Typography>
        </ValueWrapper>
      )}

      {featureModules.PLIANT_EARTH && !!transaction.emission && (
        <ValueWrapper>
          <Typography variant="body2">
            {t('transactionDetails.additionalInfoSection.co2')}
          </Typography>
          <Typography variant="body2">
            <FormatDecimal
              value={Math.round(transaction.emission / 1000)}
              unit="kg"
            />
          </Typography>
        </ValueWrapper>
      )}
    </Box>
  );
};

export default AdditionalInfoSection;
