import { useTranslation } from 'react-i18next';
import { MISSING_SUPPLIER_OPTION } from 'domains/transaction/pages/ExportPage/utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { Supplier } from 'services/constants';

const getSupplierName = (supplier: Supplier) =>
  supplier.name +
  (supplier.accountNumber ? ` (${supplier.accountNumber})` : '');

type Value =
  | typeof MISSING_SUPPLIER_OPTION
  | Omit<string, typeof MISSING_SUPPLIER_OPTION>;

interface Props {
  value: Value;
  onChange: (value: Value) => void;
  suppliers: Supplier[];
}

const SupplierFilter = ({ value, onChange, suppliers }: Props) => {
  const { t } = useTranslation();

  if (!suppliers.length) return null;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-supplier"
        id="panel-supplier-header"
      >
        <Typography>{t('filters.supplier')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<Value>
            size="medium"
            displayEmpty
            value={value}
            onChange={(e) => onChange(e.target.value as Value)}
            renderValue={(selected) => {
              if (!selected)
                return (
                  <Typography color="text.disabled">
                    {t('filters.supplier')}
                  </Typography>
                );

              if (selected === MISSING_SUPPLIER_OPTION)
                return <Typography>—</Typography>;

              const supplier = suppliers.find((item) => item.id === selected);
              return supplier ? getSupplierName(supplier) : '';
            }}
          >
            <MenuItem value="">
              <Typography color="text.disabled">
                {t('filters.supplier')}
              </Typography>
            </MenuItem>

            <MenuItem value={MISSING_SUPPLIER_OPTION}>—</MenuItem>

            {suppliers.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {getSupplierName(item)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default SupplierFilter;
