import { matchPath, useHistory } from 'react-router-dom';
// imported directly from the paths.ts file because of a circular dependency issue
import { cardholderPaths } from 'components/App/ExternalApp/paths';
import { env } from 'env';

const cardholderPathsArray = Object.values(cardholderPaths);

const useCurrentApp = () => {
  const history = useHistory();

  const isCardholderAppPath = cardholderPathsArray.some((path) =>
    matchPath(history.location.pathname, {
      path,
    })
  );

  const isInternalApp = env.REACT_APP_BUILD_TARGET === 'internal';

  return {
    isInternalApp,
    isExternalApp: env.REACT_APP_BUILD_TARGET === 'external',
    isAdminApp: !isCardholderAppPath,
    isCardholderApp: isCardholderAppPath && !isInternalApp, // some paths are the same for internal and cardholder apps
  };
};

export default useCurrentApp;
