import { CashbackRedemptionStatusBadge } from 'domains/billing/components';
import { Stack } from 'elements';
import {
  CashbackRedemptionBatch,
  cashbackRedemptionStatuses,
} from 'services/constants';

interface Props {
  totalCount: number;
  states: CashbackRedemptionBatch['states'];
}

const BatchStateCell = ({ totalCount, states }: Props) => {
  const sameStatus = cashbackRedemptionStatuses.find(
    (status) => states[status] === totalCount
  );

  if (sameStatus) return <CashbackRedemptionStatusBadge status={sameStatus} />;

  return (
    <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
      {cashbackRedemptionStatuses
        .filter((status) => !!states[status])
        .map((status) => (
          <CashbackRedemptionStatusBadge
            key={status}
            status={status}
            count={states[status]}
          />
        ))}
    </Stack>
  );
};

export default BatchStateCell;
