import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { AdminPathsKeys, InternalOrgPathsKeys } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import {
  BankIcon,
  ChartPieIcon,
  ChatTeardropTextIcon,
  CubeIcon,
  PercentIcon,
  ProjectorScreenChartIcon,
  UsersIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingSettings,
  AccountingSystem,
  ApiIntegrationStatus,
  AutomationType,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getPath } from 'services/utils';

export enum UpdateKey {
  subcategoryEnabled = 'subcategoryEnabled',
  vatRateEnabled = 'vatRateEnabled',
  projectEnabled = 'projectEnabled',
  supplierEnabled = 'supplierEnabled',
  costCenterEnabled = 'costCenterEnabled',
  commentEnabled = 'commentEnabled',
  cardAccountsEnabled = 'cardAccountsEnabled',
}

// 24.05.22 - at this moment we don't update for Suppliers, Cost Centers and Cost Units
export enum MandatoryExportUpdateKey {
  costUnitExportMandatory = 'costUnitExportMandatory',
  supplierExportMandatory = 'supplierExportMandatory',
  vatRateExportMandatory = 'vatRateExportMandatory',
  subcategoryExportMandatory = 'subcategoryExportMandatory',
  costCenterExportMandatory = 'costCenterExportMandatory',
  commentExportMandatory = 'commentExportMandatory',
}

const isCodatConnected = (accountingSettings: AccountingSettings) =>
  (accountingSettings!.accountingSystem ===
    AccountingSystem.microsoftDynamics365BusinessCentral ||
    accountingSettings!.accountingSystem === AccountingSystem.quickbooks ||
    accountingSettings!.accountingSystem === AccountingSystem.xero) &&
  accountingSettings!.useAccountingExport &&
  accountingSettings!.apiIntegrationStatus === ApiIntegrationStatus.connected;

export type ChangeableUpdateKey = Exclude<
  UpdateKey,
  UpdateKey.cardAccountsEnabled
>;

export interface CardItem {
  disabled: boolean;
  readonly?: boolean;
  checked: boolean;
  fieldName: UpdateKey;
  mandatoryFieldName?: MandatoryExportUpdateKey;
  icon: JSX.Element;
  visible: boolean;
  intercomTarget: string;
  disabledInfoText?: string;
  redirectionLink?: string;
  canVisitPage?: boolean;
  showSetupButton?: boolean;
  mandatoryForExport?: boolean;
  mandatoryForExportTooltip?: string;
  automationType?: AutomationType;
}

interface State {
  isLoading: boolean;
  isMandatorySettingUpdating: boolean;
  subcategoryEnabled: boolean;
  vatRateEnabled: boolean;
  projectEnabled: boolean;
  supplierEnabled: boolean;
  costCenterEnabled: boolean;
  commentEnabled: boolean;
  isDetailsDialogOpen: boolean;
  selectedSetting: CardItem | null;
}

export const useBasicAccountingSettingsTiles = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const canUser = useCanUser();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { suppliersEnabled: suppliersEnabledFeatureFlag } = useFlags();

  const {
    dispatch,
    state: { organization, accountingSettings, featureModules },
  } = useGlobalState();

  const [state, setState] = useState<State>({
    isLoading: false,
    isMandatorySettingUpdating: false,
    subcategoryEnabled: accountingSettings!.subcategoryEnabled,
    vatRateEnabled: accountingSettings!.vatRateEnabled,
    projectEnabled: accountingSettings!.projectEnabled,
    supplierEnabled: accountingSettings!.supplierEnabled,
    costCenterEnabled: accountingSettings!.costCenterEnabled,
    commentEnabled: accountingSettings!.commentEnabled,
    isDetailsDialogOpen: false,
    selectedSetting: null,
  });

  // new requirement for accountants
  const isTileReadOnly = !canUser('accounting-settings-tiles:update');
  const disabledInfoText = useMemo(
    () =>
      t(
        `accountingGeneralSubpage.basicAccountingSettings.${
          isTileReadOnly ? 'notPermitted' : 'accountExportInUseInfo'
        }`
      ),
    [isTileReadOnly]
  );

  const isDatevAccountingExportInUse =
    accountingSettings!.accountingSystem === AccountingSystem.datev &&
    accountingSettings!.useAccountingExport;
  const isBMDAccountingExportInUse =
    accountingSettings!.accountingSystem === AccountingSystem.bmd &&
    accountingSettings!.useAccountingExport;
  const isLexofficeAccountingExportInUse =
    accountingSettings!.accountingSystem === AccountingSystem.lexOffice &&
    accountingSettings!.useAccountingExport;

  useEffect(() => {
    const {
      subcategoryEnabled,
      vatRateEnabled,
      projectEnabled,
      supplierEnabled,
      costCenterEnabled,
      commentEnabled,
    } = accountingSettings!;
    setState((prevState) => ({
      ...prevState,
      subcategoryEnabled,
      vatRateEnabled,
      projectEnabled,
      supplierEnabled,
      costCenterEnabled,
      commentEnabled,
    }));
  }, [accountingSettings]);

  const getRedirectionLink = (
    pathName: InternalOrgPathsKeys & AdminPathsKeys
  ) => {
    return generatePath(getPath(pathName), {
      orgId: organization!.id,
    });
  };

  const updateSetting = async (key: ChangeableUpdateKey, value: boolean) => {
    if (state.isLoading || state.isMandatorySettingUpdating) return;
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        [key]: value,
      }));
      const data = await api.updateAccountingSettings({
        organizationId: organization!.id,
        vatRateEnabled: accountingSettings!.vatRateEnabled,
        subcategoryEnabled: accountingSettings!.subcategoryEnabled,
        projectEnabled: accountingSettings!.projectEnabled,
        supplierEnabled: accountingSettings!.supplierEnabled,
        costCenterEnabled: accountingSettings!.costCenterEnabled,
        commentEnabled: accountingSettings!.commentEnabled,
        useAccountingExport: accountingSettings!.useAccountingExport,
        [key]: value,
      });
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { accountingSettings: data },
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isDetailsDialogOpen: false,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        [key]: accountingSettings![key],
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const updateMandatoryExportSetting = async (
    key: MandatoryExportUpdateKey,
    value: boolean
  ) => {
    if (state.isLoading || state.isMandatorySettingUpdating) return;
    try {
      const shouldCheckTile = !state.selectedSetting?.checked && value;

      setState((prevState) => ({
        ...prevState,
        isMandatorySettingUpdating: true,
        selectedSetting: {
          ...prevState.selectedSetting!,
          mandatoryForExport: value,
          checked: shouldCheckTile || prevState.selectedSetting!.checked,
        },
      }));
      const data = await api.updateMandatoryExportSettings({
        organizationId: organization!.id,
        costUnitExportMandatory: accountingSettings!.costUnitExportMandatory,
        supplierExportMandatory: accountingSettings!.supplierExportMandatory,
        vatRateExportMandatory: accountingSettings!.vatRateExportMandatory,
        subcategoryExportMandatory: accountingSettings!
          .subcategoryExportMandatory,
        costCenterExportMandatory: accountingSettings!
          .costCenterExportMandatory,
        commentExportMandatory: accountingSettings!.commentExportMandatory,
        [key]: value,
      });
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { accountingSettings: data },
      });
      if (!mounted.current) return;

      if (shouldCheckTile) {
        enqueueSnackbar(
          <Trans
            i18nKey="accountingGeneralSubpage.basicAccountingSettings.tileIsActivatedMsg"
            values={{
              name: t(
                `accountingGeneralSubpage.basicAccountingSettings.${
                  state.selectedSetting!.fieldName
                }Item.title`
              ),
            }}
          />
        );
      }
      setState((prevState) => ({
        ...prevState,
        isMandatorySettingUpdating: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        selectedSetting: {
          ...prevState.selectedSetting!,
          mandatoryForExport: accountingSettings![key],
          checked: accountingSettings![
            prevState.selectedSetting!.fieldName as ChangeableUpdateKey
          ],
        },
        isMandatorySettingUpdating: false,
      }));
      logError(error);
    }
  };

  const cards: CardItem[] = [
    {
      disabled:
        (state.subcategoryEnabled &&
          (isDatevAccountingExportInUse ||
            isBMDAccountingExportInUse ||
            isLexofficeAccountingExportInUse ||
            isCodatConnected(accountingSettings!))) ||
        isTileReadOnly,
      disabledInfoText,
      checked: state.subcategoryEnabled,
      fieldName: UpdateKey.subcategoryEnabled,
      mandatoryFieldName: MandatoryExportUpdateKey.subcategoryExportMandatory,
      icon: <ChartPieIcon fontSize="large" />,
      visible: true,
      redirectionLink: getRedirectionLink('settingsCategories'),
      canVisitPage: canUser('categories-sub-page:visit'),
      ...(isTileReadOnly
        ? {}
        : {
            mandatoryForExport: accountingSettings!.subcategoryExportMandatory,
          }),
      showSetupButton: !accountingSettings!.accountingItems.subCategoryCount,
      intercomTarget: 'settings-gl-accounts',
      automationType: AutomationType.merchant,
    },
    {
      disabled:
        (state.vatRateEnabled &&
          (isDatevAccountingExportInUse ||
            isBMDAccountingExportInUse ||
            isCodatConnected(accountingSettings!))) ||
        isTileReadOnly,
      disabledInfoText,
      checked: state.vatRateEnabled,
      fieldName: UpdateKey.vatRateEnabled,
      mandatoryFieldName: MandatoryExportUpdateKey.vatRateExportMandatory,
      icon: <PercentIcon fontSize="large" />,
      visible: true,
      redirectionLink: getRedirectionLink('settingsVatRates'),
      canVisitPage: canUser('vat-rates-sub-page:visit'),
      ...(isTileReadOnly
        ? {}
        : {
            mandatoryForExport: accountingSettings!.vatRateExportMandatory,
          }),
      showSetupButton: !accountingSettings!.accountingItems.vatRateCount,
      intercomTarget: 'settings-vat-rates',
      automationType: AutomationType.merchant,
    },
    {
      disabled:
        (state.supplierEnabled && isCodatConnected(accountingSettings!)) ||
        isTileReadOnly,
      disabledInfoText,
      checked: state.supplierEnabled,
      fieldName: UpdateKey.supplierEnabled,
      mandatoryFieldName: MandatoryExportUpdateKey.supplierExportMandatory,
      icon: <CubeIcon fontSize="large" />,
      visible: suppliersEnabledFeatureFlag,
      redirectionLink: getRedirectionLink('settingsSuppliers'),
      canVisitPage: canUser('suppliers-sub-page:visit'),
      showSetupButton: !accountingSettings!.accountingItems.supplierCount,
      intercomTarget: 'settings-suppliers',
      automationType: AutomationType.merchant,
    },
    {
      disabled:
        (state.costCenterEnabled && isCodatConnected(accountingSettings!)) ||
        isTileReadOnly,
      disabledInfoText,
      checked: state.costCenterEnabled,
      fieldName: UpdateKey.costCenterEnabled,
      mandatoryFieldName: MandatoryExportUpdateKey.costCenterExportMandatory,
      icon: <UsersIcon />,
      visible: featureModules.TEAMS,
      redirectionLink: getRedirectionLink('settingsTeams'),
      canVisitPage: canUser('teams-page:visit'),
      showSetupButton: !accountingSettings!.accountingItems.costCenterCount,
      intercomTarget: 'settings-cost-centers',
      automationType: AutomationType.card,
    },
    {
      disabled:
        (state.projectEnabled && isCodatConnected(accountingSettings!)) ||
        isTileReadOnly,
      disabledInfoText,
      checked: state.projectEnabled,
      fieldName: UpdateKey.projectEnabled,
      mandatoryFieldName: MandatoryExportUpdateKey.costUnitExportMandatory,
      icon: <ProjectorScreenChartIcon fontSize="large" />,
      visible: true,
      redirectionLink: getRedirectionLink('settingsProjects'),
      canVisitPage: canUser('projects-sub-page:visit'),
      showSetupButton: !accountingSettings!.accountingItems.costUnitCount,
      intercomTarget: 'settings-cost-units',
      automationType: AutomationType.card,
    },
    {
      disabled: !canUser('accounting-settings:update') || isTileReadOnly,
      disabledInfoText,
      checked: state.commentEnabled,
      fieldName: UpdateKey.commentEnabled,
      mandatoryFieldName: MandatoryExportUpdateKey.commentExportMandatory,
      icon: <ChatTeardropTextIcon fontSize="large" />,
      visible: true,
      ...(isTileReadOnly
        ? {}
        : {
            mandatoryForExport: accountingSettings!.commentExportMandatory,
            mandatoryForExportTooltip: t(
              'accountingGeneralSubpage.basicAccountingSettings.commentExportMandatoryTooltip',
              { count: 3 }
            ),
          }),
      intercomTarget: 'settings-notes',
    },
    {
      disabled: featureModules.MULTI_CARD_ACCOUNT,
      readonly: true,
      checked: true,
      fieldName: UpdateKey.cardAccountsEnabled,
      icon: <BankIcon fontSize="large" />,
      visible: featureModules.MULTI_CARD_ACCOUNT,
      intercomTarget: 'card-accounts-tile',
      redirectionLink: getRedirectionLink('settingsAccounts'),
      canVisitPage: canUser('card-accounts-sub-page:visit'),
    },
  ];

  return {
    cards,
    isLoading: state.isLoading,
    isMandatorySettingUpdating: state.isMandatorySettingUpdating,
    isDetailsDialogOpen: state.isDetailsDialogOpen,
    selectedSetting: state.selectedSetting,
    updateSetting,
    updateMandatoryExportSetting,
    onDetailsOpen: (key: UpdateKey) =>
      setState((prevState) => ({
        ...prevState,
        isDetailsDialogOpen: true,
        selectedSetting: cards.find((item) => item.fieldName === key) || null,
      })),
    onDetailsClose: () =>
      setState((prevState) => ({
        ...prevState,
        isDetailsDialogOpen: false,
      })),
  };
};
