import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { AccountingAutocomplete } from 'domains/transaction/components';
import { Box, LightningIcon, Tooltip, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
  Supplier,
  SupplierTransactionInfo,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

const mapSupplierToValue = (
  txSupplier: SupplierTransactionInfo | null,
  organizationId: string
) => {
  return txSupplier
    ? {
        id: txSupplier.id,
        name: txSupplier.name,
        accountNumber: txSupplier.accountNumber,
        // We don't care about suppliers status, even if it's inactive.
        // The idea is to put it to show it in Autocomplete even if there is no such option
        status: AccountingItemStatus.active,
        organizationId,
      }
    : null;
};

interface Props {
  supplier: SupplierTransactionInfo | null;
  isTxFrozen: boolean;
  onChange: (supplier?: Supplier) => void;
}

interface State {
  isLoading: boolean;
  suppliers: Supplier[];
  value: Supplier | null;
}

const SupplierSelect = ({ supplier, isTxFrozen, onChange }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    suppliers: [],
    isLoading: true,
    value: mapSupplierToValue(supplier, organization!.id),
  });
  const [inputValue, setInputValue] = useState('');

  const getData = async () => {
    try {
      const { suppliers } = await api.getSuppliers({
        organizationId: organization!.id,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
        q: inputValue.trim(),
        sortBy: '+name',
        status: AccountingItemStatus.active,
      });

      if (!mounted.current) return;

      setState((prevState) => ({
        ...prevState,
        suppliers,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    let active = true;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    setTimeout(() => {
      if (active) getData();
    }, DEFAULT_AUTOCOMPLETE_DELAY);
    return () => {
      active = false;
    };
  }, [inputValue.trim()]);

  useEffect(() => {
    if (supplier?.name !== inputValue) setInputValue(supplier?.name || '');
    setState((prevState) => ({
      ...prevState,
      value: mapSupplierToValue(supplier, organization!.id),
    }));
  }, [supplier]);

  const isEditDisabled = !canUser('supplier:assign') || isTxFrozen;

  return (
    <Box mt={1}>
      <AccountingAutocomplete<Supplier, true>
        // remove MUI warning about missing value in options, as it's expected
        freeSolo
        label={
          <Box display="flex" alignItems="center">
            <Box overflow="hidden">
              <Typography variant="body2" noWrap>
                {t('transactionDetails.supplierSelectLabel')}
              </Typography>
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
                noWrap
              >
                {t('transactionDetails.supplierSelectSubLabel')}
              </Typography>
            </Box>

            {supplier?.autoMatched && (
              <Box
                position="relative"
                zIndex="1"
                lineHeight={0}
                sx={{ pointerEvents: 'initial' }}
                ml={1.5}
              >
                <Tooltip
                  title={t(
                    'transactionDetails.supplierSelectAutoMatchedTooltip'
                  )}
                >
                  <LightningIcon
                    fontSize="small"
                    color="info"
                    data-test-id="supplier-select-autoMatched-tooltip"
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
        }
        selectedValueComponent={
          state.value ? (
            <Box overflow="hidden" pl={2}>
              <Typography variant="body2" component="div" noWrap>
                {state.value.name || ''}
              </Typography>
              <Typography
                variant="caption"
                noWrap
                component="div"
                color="textSecondary"
              >
                {state.value.accountNumber || '—'}
              </Typography>
            </Box>
          ) : (
            '—'
          )
        }
        disabled={isEditDisabled}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        value={state.value}
        options={state.suppliers}
        onChange={(_, option) => {
          if (!option) setInputValue('');
          if (typeof option === 'string') return;
          onChange(option as Supplier);
        }}
        onClear={() => {
          setInputValue('');
          if (state.value) onChange();
          setState((prevState) => ({ ...prevState, value: null }));
        }}
        loading={state.isLoading}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (option as Supplier).name || ''}
        filterOptions={(x) => x}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <Box overflow="hidden">
              <Typography noWrap>{option.name}</Typography>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
              >
                {option.accountNumber || '—'}
              </Typography>
            </Box>
          </li>
        )}
      />
    </Box>
  );
};

export default SupplierSelect;
