import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OnboardingDocumentName } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import {
  parseInformationRequestString,
  ValidationErrorCode,
} from './parseInformationRequestString';

interface Props extends DialogProps {
  onClose: () => void;
}

interface State {
  errorCodes: ValidationErrorCode[];
  isLoading: boolean;
  rawString: string;
}

const BulkImportInformationRequestsDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    actions: { setOnboardingDocuments },
    state: { onboardingDocumentTypes },
  } = useCreditAndComplianceContext();

  const [state, setState] = useState<State>({
    errorCodes: [],
    isLoading: false,
    rawString: '',
  });

  const onSubmit = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const parsedInformationRequest = parseInformationRequestString(
      state.rawString,
      onboardingDocumentTypes
    );
    if (!parsedInformationRequest.isValid) {
      setState((prevState) => ({
        ...prevState,
        errorCodes: parsedInformationRequest.validationErrorCodes,
        isLoading: false,
      }));
      return;
    }

    const payload = parsedInformationRequest.result.map(
      ({ name, ...attributes }) => ({
        type: name as OnboardingDocumentName,
        attributes,
        shouldAlsoRequest: false,
      })
    );
    try {
      const { onboardingDocuments } = await api.createOnboardingDocuments(
        organization!.id,
        payload
      );
      setOnboardingDocuments(onboardingDocuments);

      if (!mounted) return;
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>
        {t('int.bulkImportInformationRequestsDialog.title')}
      </DialogTitle>

      <DialogContent>
        <Typography variant="body2" mb={2}>
          {t('int.bulkImportInformationRequestsDialog.title')}
        </Typography>
        <TextField
          error={state.errorCodes.length > 0}
          helperText={
            state.errorCodes.length > 0 && state.errorCodes.join(', ')
          }
          inputProps={{
            sx: { fontSize: '0.75rem', wordBreak: 'break-all' },
          }}
          minRows={5}
          multiline
          onChange={(event) =>
            setState((prevState) => ({
              ...prevState,
              errorCodes: [],
              rawString: event.target.value,
            }))
          }
        />
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={
            state.isLoading || !state.rawString || state.errorCodes.length > 0
          }
          onClick={onSubmit}
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(BulkImportInformationRequestsDialog);
