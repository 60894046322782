import { styled } from '@mui/material';
import { Trans } from 'react-i18next';
import {
  Box,
  CaretDownIcon,
  CaretUpIcon,
  Divider,
  DownloadSimpleIcon,
  IconButton,
  IconButtonProps,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  Typography,
  XIcon,
} from 'elements';
import { DEFAULT_SCALE } from './index';

export const HeaderIconButton = styled((props: IconButtonProps) => (
  <IconButton {...props} size="small" />
))`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const SCALE_STEP = 0.15;

interface Props {
  isLoading: boolean;
  isAttachmentEmpty: boolean;
  fileName: string;
  page: number;
  numPages: number;
  scale: number;
  onChange: (newPage?: number, newScale?: number) => void;
  onDownload: () => void;
  onClose: () => void;
}

const PreviewDialogHeader = ({
  isLoading,
  isAttachmentEmpty,
  fileName,
  page,
  numPages,
  scale,
  onChange,
  onDownload,
  onClose,
}: Props) => {
  const scalePercent = Math.round((scale - DEFAULT_SCALE + 1) * 100);

  return (
    <>
      <Box display="flex" alignItems="center" height="60px" flexShrink={0}>
        <Box width="40%" pr={1} pl={2} display="flex" alignItems="center">
          <IconButton onClick={onClose}>
            <XIcon />
          </IconButton>

          <Typography noWrap ml={2}>
            {fileName}
          </Typography>
        </Box>

        <Box width="20%" whiteSpace="nowrap" textAlign="center">
          <Box
            position="relative"
            display="inline-flex"
            justifyContent="center"
          >
            {numPages > 1 && (
              <HeaderIconButton
                sx={{ left: '-45px' }}
                disabled={page <= 1 || isLoading}
                onClick={() => onChange(page - 1)}
              >
                <CaretUpIcon fontSize="small" />
              </HeaderIconButton>
            )}
            <Typography variant="body2" noWrap>
              <Trans
                i18nKey="previewDialog.page"
                values={{ pagination: `${page} / ${numPages}` }}
              />
            </Typography>
            {numPages > 1 && (
              <HeaderIconButton
                sx={{ right: '-45px' }}
                disabled={page >= numPages || isLoading}
                onClick={() => onChange(page + 1)}
              >
                <CaretDownIcon fontSize="small" />
              </HeaderIconButton>
            )}
          </Box>
        </Box>

        <Box width="20%" textAlign="center">
          <Box
            position="relative"
            display="inline-flex"
            justifyContent="center"
            width="48px"
          >
            <HeaderIconButton
              sx={{ position: 'absolute', left: '-40px' }}
              disabled={scalePercent <= 40 || isLoading}
              onClick={() =>
                onChange(undefined, parseFloat((scale - SCALE_STEP).toFixed(2)))
              }
            >
              <MagnifyingGlassMinusIcon fontSize="small" />
            </HeaderIconButton>

            <Typography noWrap>{scalePercent}%</Typography>

            <HeaderIconButton
              sx={{ position: 'absolute', right: '-40px' }}
              disabled={scalePercent >= 190 || isLoading}
              onClick={() =>
                onChange(undefined, parseFloat((scale + SCALE_STEP).toFixed(2)))
              }
            >
              <MagnifyingGlassPlusIcon fontSize="small" />
            </HeaderIconButton>
          </Box>
        </Box>

        <Box width="20%" textAlign="right" pr="16px">
          <IconButton
            onClick={onDownload}
            size="small"
            disabled={isAttachmentEmpty}
          >
            <DownloadSimpleIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default PreviewDialogHeader;
