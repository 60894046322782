import i18next from 'i18next';
import { CARDHOLDER_FULL_NAME_MAX_LENGTH } from 'services/constants';
import { filterNonPrintableChars } from 'services/utils/printableString';

export const validateCardholderFullName = (
  firstName: string,
  lastName: string
) => {
  const firstNameTrimmed = firstName.trim();
  const lastNameTrimmed = lastName.trim();

  const nonPrintableCharsInFirstName = filterNonPrintableChars(
    firstNameTrimmed
  );
  const firstNameNotPrintableError =
    nonPrintableCharsInFirstName.length > 0
      ? i18next.t('errors.charsNotPrintable', {
          nonPrintableChars: nonPrintableCharsInFirstName.join(', '),
        })
      : null;

  const nonPrintableCharsInLastName = filterNonPrintableChars(lastNameTrimmed);
  const lastNameNotPrintableError =
    nonPrintableCharsInLastName.length > 0
      ? i18next.t('errors.charsNotPrintable', {
          nonPrintableChars: nonPrintableCharsInLastName.join(', '),
        })
      : null;

  const fullNameTooLongError =
    `${firstNameTrimmed} ${lastNameTrimmed}`.length >
    CARDHOLDER_FULL_NAME_MAX_LENGTH
      ? i18next.t('errors.fullNameTooLong', {
          limit: CARDHOLDER_FULL_NAME_MAX_LENGTH,
        })
      : null;

  return {
    firstNameNotPrintableError,
    lastNameNotPrintableError,
    fullNameTooLongError,
  };
};
