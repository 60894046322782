import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { Box, Paper, Typography } from 'elements';
import {
  ReceiptAutoMatchingDetails,
  ReceiptsAutoMatchingStatus,
} from 'services/constants';

interface Props {
  receipt: ReceiptAutoMatchingDetails;
}

const ReceiptInformationBlock = ({ receipt }: Props) => {
  const { t } = useTranslation();
  const { ocr, status, createdAt } = receipt;

  if (
    status !== ReceiptsAutoMatchingStatus.processing &&
    Object.values(ocr).every((x) => !x) &&
    !createdAt
  )
    return null;

  const emptyValue =
    status === ReceiptsAutoMatchingStatus.processing ? '...' : '-';

  return (
    <>
      <Paper
        sx={{
          px: 1.5,
          py: 2,
          mt: 1,
        }}
        variant="outlined"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" noWrap>
            {t('receiptInboxDetails.totalAmount')}
          </Typography>

          <Typography variant="body2" noWrap ml={2}>
            {ocr.amount ? (
              <FormatMoney value={ocr.amount} fractionalPart />
            ) : (
              emptyValue
            )}
          </Typography>
        </Box>
      </Paper>

      <Paper
        sx={{
          px: 1.5,
          py: 2,
          mt: 1,
        }}
        variant="outlined"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" noWrap>
            {t('receiptInboxDetails.merchant')}
          </Typography>

          <Typography variant="body2" noWrap ml={2}>
            {ocr.merchantName || emptyValue}
          </Typography>
        </Box>
      </Paper>

      <Paper
        sx={{
          px: 1.5,
          py: 2,
          mt: 1,
        }}
        variant="outlined"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" noWrap>
            {t('receiptInboxDetails.receiptDate')}
          </Typography>

          <Typography variant="body2" noWrap ml={2}>
            {ocr.receiptDate
              ? moment(ocr.receiptDate).format('D MMM YYYY, HH:mm')
              : emptyValue}
          </Typography>
        </Box>
      </Paper>

      <Paper
        sx={{
          px: 1.5,
          py: 2,
          mt: 1,
        }}
        variant="outlined"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" noWrap>
            {t('receiptInboxDetails.receiptNumber')}
          </Typography>

          <Typography variant="body2" noWrap ml={2}>
            {ocr.receiptNumber || emptyValue}
          </Typography>
        </Box>
      </Paper>

      <Paper
        sx={{
          px: 1.5,
          py: 2,
          mt: 1,
        }}
        variant="outlined"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" noWrap>
            {t('receiptInboxDetails.receiptReceivedDate')}
          </Typography>

          <Typography variant="body2" noWrap ml={2}>
            {createdAt
              ? moment(createdAt).format('D MMM YYYY, HH:mm')
              : emptyValue}
          </Typography>
        </Box>
      </Paper>
    </>
  );
};

export default ReceiptInformationBlock;
