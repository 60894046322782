import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { AccountingAutocomplete } from 'domains/transaction/components';
import { Box, Typography } from 'elements';
import useIsAdminApp from 'hooks/useIsAdminApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  AccountingTransaction,
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
  Project,
  Transaction,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const mapProjectToValue = (
  id: string | null,
  name: string,
  costUnit: string,
  organizationId: string
) => {
  return id
    ? {
        id,
        name,
        costUnit,
        // fill the object to Project type
        status: AccountingItemStatus.active,
        organizationId,
        isPrivate: false,
        teams: [],
        externalReference: '',
      }
    : null;
};

interface Props {
  isExportPage: boolean;
  onChange: (project: Project | null) => void;
  transaction: Transaction;
  accountingTransaction: AccountingTransaction;
  disabled: boolean;
}

interface State {
  projects: Project[];
  isLoading: boolean;
  value: Project | null;
}

const ProjectSelect = ({
  isExportPage,
  transaction,
  accountingTransaction,
  onChange,
  disabled,
}: Props) => {
  const isAdminApp = useIsAdminApp();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { accountingSettings, organization },
  } = useGlobalState();
  const [inputValue, setInputValue] = useState('');
  const [state, setState] = useState<State>({
    projects: [],
    isLoading: true,
    value: mapProjectToValue(
      accountingTransaction?.projectId,
      accountingTransaction?.projectName || '',
      accountingTransaction?.costUnit || '',
      organization!.id
    ),
  });

  const getData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const { projects } = isAdminApp
        ? await api.getProjects({
            organizationId: organization!.id,
            page: 0,
            limit: DEFAULT_PAGE_LIMIT,
            q: inputValue.trim(),
            sortBy: '+name',
            status: AccountingItemStatus.active,
          })
        : await api.getCardholdersProjects({
            organizationId: organization!.id,
            transactionId: transaction.transactionId,
            page: 0,
            limit: DEFAULT_PAGE_LIMIT,
            q: inputValue.trim(),
            sortBy: '+name',
          });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        projects,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!accountingSettings?.projectEnabled || disabled) return;
    let active = true;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    setTimeout(() => {
      if (active) getData();
    }, DEFAULT_AUTOCOMPLETE_DELAY);
    return () => {
      active = false;
    };
  }, [inputValue.trim()]);

  useEffect(() => {
    if (accountingTransaction?.projectName !== inputValue)
      setInputValue(accountingTransaction?.projectName || '');

    setState((prevState) => ({
      ...prevState,
      value: mapProjectToValue(
        accountingTransaction?.projectId,
        accountingTransaction?.projectName || '',
        accountingTransaction?.costUnit || '',
        organization!.id
      ),
    }));
  }, [accountingTransaction?.projectId]);

  const renderSelectedValue = () => {
    if (!!state.value) {
      return (
        <Box overflow="hidden" pl={2}>
          <Typography variant="body2" component="div" noWrap>
            {state.value.name || ''}
          </Typography>
          {isExportPage && state.value.costUnit && (
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              noWrap
            >
              {state.value.costUnit}
            </Typography>
          )}
        </Box>
      );
    }
    return '—';
  };

  if (!accountingSettings?.projectEnabled) return null;

  return (
    <Box mb={1}>
      <AccountingAutocomplete<Project, true>
        freeSolo
        label={
          <>
            <Typography variant="body2" noWrap>
              {t('transactionDetails.projectSelectLabel')}
            </Typography>
            {isExportPage && (
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
              >
                {t('transactionDetails.costUnit')}
              </Typography>
            )}
          </>
        }
        loading={state.isLoading}
        data-test-id="projects-autocomplete"
        selectedValueComponent={renderSelectedValue()}
        disabled={disabled}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        value={state.value}
        options={state.projects}
        onChange={(_, option) => {
          if (!option) setInputValue('');
          if (typeof option === 'string') return;
          onChange(option as Project);
        }}
        onClear={() => {
          setInputValue('');
          if (state.value) onChange(null);
          setState((prevState) => ({ ...prevState, value: null }));
        }}
        getOptionLabel={(option) => (option as Project).name || ''}
        filterOptions={(x) => x}
        getOptionDisabled={(option: Project) =>
          option.status === AccountingItemStatus.inactive
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <Box overflow="hidden">
              <Typography noWrap>{option.name}</Typography>
              {isExportPage && option.costUnit && (
                <Typography
                  component="div"
                  variant="caption"
                  color="textSecondary"
                  noWrap
                >
                  {option.costUnit}
                </Typography>
              )}
            </Box>
          </li>
        )}
      />
    </Box>
  );
};

export default ProjectSelect;
