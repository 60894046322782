import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoxProps, Button, FunnelSimpleIcon } from 'elements';
import { PageFilters, PageSearchInput } from 'layout';

export interface PageFiltersComponentType extends BoxProps {
  count: number;
  q: string;
  setQParam: (q: string) => void;
  onOpen: () => void;
  disabled?: boolean;
}

// In many cases filter input/button part is the same for pages.
// Use this component to reduce code duplication.
export const PageFiltersComponent = ({
  count,
  q,
  setQParam,
  onOpen,
  children,
  disabled = false,
  ...props
}: PageFiltersComponentType) => {
  const { t } = useTranslation();

  return (
    <PageFilters {...props}>
      <PageSearchInput
        initialValue={q}
        onChange={setQParam}
        disabled={disabled}
      />
      <Button
        data-intercom-target="filter-button"
        sx={{ ml: 2 }}
        variant={count ? 'contained' : 'text'}
        startIcon={<FunnelSimpleIcon />}
        onClick={onOpen}
        disabled={disabled}
      >
        {t('filterDrawer.title', { defaultValue: 'Filter' })}
        {!!count && ` (${count})`}
      </Button>

      {children}
    </PageFilters>
  );
};
