import { useMemo } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { SupportedCountry } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

export interface RegistrationChangeDialogProps extends DialogProps {
  onClose: () => void;
}

const RegistrationChangeDialog = (props: RegistrationChangeDialogProps) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const org = useMemo(() => organization!, []);

  const formik = useFormik({
    validateOnMount: true,
    validateOnBlur: false,
    initialValues: {
      nationalRegisterCourt: org!.nationalRegisterCourt || '',
      tradeRegisterNumber: org!.tradeRegisterNumber || '',
    },
    onSubmit: async (
      { nationalRegisterCourt, tradeRegisterNumber },
      { setSubmitting }
    ) => {
      try {
        const data = await api.updateRegistration(org!.id, {
          tradeRegisterNumber,
          nationalRegisterCourt,
        });

        if (!mounted.current) return;
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { organization: data },
        });
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const hasEmptyField =
    ([SupportedCountry.at, SupportedCountry.de].includes(
      org!.billingAddress.country
    ) &&
      !formik.values.nationalRegisterCourt.trim()) ||
    !formik.values.tradeRegisterNumber.trim();

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('settingsOrganizationPage.registration')}</DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="registration-change-form"
          noValidate
        >
          <Grid container rowSpacing={2} direction="column">
            <Grid item>
              <TextField
                label={t('registrationForm.registerCourt')}
                {...formik.getFieldProps('nationalRegisterCourt')}
                disabled={formik.isSubmitting}
              />
            </Grid>
            <Grid item>
              <TextField
                label={t('registrationForm.tradeRegisterNumber')}
                {...formik.getFieldProps('tradeRegisterNumber')}
                disabled={formik.isSubmitting}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          disabled={formik.isSubmitting}
          onClick={props.onClose}
        >
          {t('common.button.cancel')}
        </Button>
        <Button
          form="registration-change-form"
          type="submit"
          disabled={hasEmptyField || formik.isSubmitting}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(RegistrationChangeDialog);
