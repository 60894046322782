import { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CountryFlag from 'components/CountryFlag';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Switch,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  countries,
  MerchantDescriptionSource,
  MerchantDetails,
  UpdateMerchantPayload,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, trimObjValues } from 'services/utils';
import EditMerchantLogo from './EditMerchantLogo';

interface Props extends DialogProps {
  merchant: MerchantDetails;
  onClose: () => void;
  onUpdate: (merchant: MerchantDetails) => void;
}

interface State {
  descriptionSource: MerchantDescriptionSource;
  isDescriptionSourceUpdating: boolean;
  isLogoDeleting: boolean;
}

const EditMerchantDialog = ({ merchant, onUpdate, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    descriptionSource: merchant.descriptionSource,
    isDescriptionSourceUpdating: false,
    isLogoDeleting: false,
  });
  const formik = useFormik<UpdateMerchantPayload>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      displayName: merchant.displayName,
      legalName: merchant.legalName,
      url: merchant.url || '',
      street: merchant.street,
      postalCode: merchant.postalCode,
      city: merchant.city,
      state: merchant.state,
      vatNumber: merchant.vatNumber,
      country: merchant.country,
      companyRegistry: merchant.companyRegistry || '',
      note: merchant.note || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const data = await api.updateMerchant(
          merchant.id,
          trimObjValues(values)
        );
        if (!mounted.current) return;
        onUpdate(data);
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const handleLogoDelete = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLogoDeleting: true }));
      await api.deleteMerchantLogo(merchant.id);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLogoDeleting: false }));
      onUpdate({ ...merchant, logoPath: null });
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLogoDeleting: false }));
      logError(error);
    }
  };

  const handleDescriptionSourceUpdate = async (
    descriptionSource: MerchantDescriptionSource
  ) => {
    if (state.isDescriptionSourceUpdating) return;
    try {
      setState((prevState) => ({
        ...prevState,
        isDescriptionSourceUpdating: true,
        descriptionSource,
      }));
      const merchantDetails = await api.updateMerchantDescriptionSource(
        merchant.id,
        descriptionSource
      );
      if (!mounted.current) return;
      onUpdate(merchantDetails);
      setState((prevState) => ({
        ...prevState,
        isDescriptionSourceUpdating: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        descriptionSource: merchant.descriptionSource,
        isDescriptionSourceUpdating: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        {t('int.editMerchantDialog.editMerchantDetails')}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} noValidate id="edit-merchant-form">
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <EditMerchantLogo
                  merchantId={merchant.id}
                  url={merchant.logoPath}
                  name={merchant.displayName}
                  onUpdate={onUpdate}
                  onDelete={handleLogoDelete}
                />
                <Box ml={3} flex={1}>
                  <TextField
                    label={t('int.editMerchantDialog.merchantDisplayName')}
                    {...formik.getFieldProps('displayName')}
                    disabled={formik.isSubmitting}
                    inputProps={{ maxLength: 40 }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch />}
                name="descriptionSource"
                checked={
                  state.descriptionSource ===
                  MerchantDescriptionSource.confirmation
                }
                onChange={(e, value) =>
                  handleDescriptionSourceUpdate(
                    value
                      ? MerchantDescriptionSource.confirmation
                      : MerchantDescriptionSource.authorization
                  )
                }
                label={t('int.editMerchantDialog.descriptionSourceLabel')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('int.editMerchantDialog.legalName')}
                {...formik.getFieldProps('legalName')}
                disabled={formik.isSubmitting}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('int.editMerchantDialog.url')}
                {...formik.getFieldProps('url')}
                disabled={formik.isSubmitting}
                inputProps={{ maxLength: 256 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('int.editMerchantDialog.street')}
                {...formik.getFieldProps('street')}
                disabled={formik.isSubmitting}
                inputProps={{ maxLength: 80 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('int.editMerchantDialog.postalCode')}
                {...formik.getFieldProps('postalCode')}
                disabled={formik.isSubmitting}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('int.editMerchantDialog.city')}
                {...formik.getFieldProps('city')}
                disabled={formik.isSubmitting}
                inputProps={{ maxLength: 40 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('int.editMerchantDialog.state')}
                {...formik.getFieldProps('state')}
                disabled={formik.isSubmitting}
                inputProps={{ maxLength: 150 }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>{t('int.editMerchantDialog.country')}</InputLabel>
                <Select
                  renderValue={(selected) => {
                    const selectedCountry = countries.find(
                      (item) => item.countryCode === selected
                    );
                    return selectedCountry ? (
                      <Box display="flex" alignItems="center">
                        <CountryFlag code={selectedCountry.countryCode} />
                        <Box component="span" ml={1}>
                          {selectedCountry.countryName}
                        </Box>
                      </Box>
                    ) : (
                      ''
                    );
                  }}
                  {...formik.getFieldProps('country')}
                >
                  {countries.map((country) => (
                    <MenuItem
                      key={country.countryCode}
                      value={country.countryCode}
                    >
                      <ListItemIcon>
                        <CountryFlag code={country.countryCode} />
                      </ListItemIcon>
                      <ListItemText primary={country.countryName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('int.editMerchantDialog.vatNumber')}
                {...formik.getFieldProps('vatNumber')}
                disabled={formik.isSubmitting}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('int.editMerchantDialog.companyRegistry')}
                {...formik.getFieldProps('companyRegistry')}
                disabled={formik.isSubmitting}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {formik.dirty && (
          <Box flexGrow="1">
            <Button
              disabled={formik.isSubmitting}
              onClick={() => formik.resetForm()}
              variant="outlined"
            >
              {t('int.editMerchantDialog.reset')}
            </Button>
          </Box>
        )}
        <Button onClick={props.onClose} variant="text">
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={!formik.dirty || formik.isSubmitting}
          form="edit-merchant-form"
          type="submit"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay
        loading={formik.isSubmitting || state.isLogoDeleting}
      />
    </Dialog>
  );
};

export default withDialogWrapper(EditMerchantDialog);
