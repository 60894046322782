import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { DirectDebitInfo } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { downloadPdfFile, getGenericErrorMsg } from 'services/utils';

const useDirectDebitDocument = (directDebitInfo: DirectDebitInfo | null) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState('');

  const getFileContents = async () => {
    if (!directDebitInfo?.documentId) return;
    try {
      const data = await api.getPublicDocumentContentOld(
        directDebitInfo.documentId
      );
      setData(data);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (data) downloadPdfFile(t('documents.directDebit') + '.pdf', data);
  }, [data]);

  return () => {
    if (data) downloadPdfFile(t('documents.directDebit') + '.pdf', data);
    else getFileContents();
  };
};

export default useDirectDebitDocument;
