import React from 'react';
import { Container, ContainerInner, MainContent } from 'components/App/style';
import AppLogo from 'components/AppLogo';
import UserMenu from 'components/MainHeader/UserMenu';
import { Box } from 'elements';

const PartnerLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container>
      <ContainerInner>
        <Box
          px={5}
          py={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: (theme) => theme.variables.background.contrast,
          }}
        >
          <AppLogo />
          <UserMenu />
        </Box>

        <MainContent>
          <Box pt={8} pb={2} height="100%" sx={{ overflowY: 'auto' }}>
            {children}
          </Box>
        </MainContent>
      </ContainerInner>
    </Container>
  );
};

export default PartnerLayout;
