import { useTranslation } from 'react-i18next';
import { BillPaymentTypeBadge } from 'domains/billing/components';
import { Box, StatusBadge } from 'elements';
import {
  AccountEntryBillPaymentType,
  BillPaymentType,
} from 'services/constants';

interface Props {
  billPaymentType: AccountEntryBillPaymentType;
  paymentType: BillPaymentType;
}

const AccountEntryPaymentTypeBadge = ({
  billPaymentType,
  paymentType,
}: Props) => {
  const { t } = useTranslation();

  if (
    [
      AccountEntryBillPaymentType.bill,
      AccountEntryBillPaymentType.prefunding,
      AccountEntryBillPaymentType.payout,
    ].includes(billPaymentType)
  )
    return (
      <Box
        mt={1}
        lineHeight={0}
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <BillPaymentTypeBadge type={paymentType} />
      </Box>
    );

  if (
    [
      AccountEntryBillPaymentType.directDebitReversalFee,
      AccountEntryBillPaymentType.billPaymentReversal,
    ].includes(billPaymentType)
  )
    return (
      <Box
        mt={1}
        lineHeight={0}
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <StatusBadge
          label={t('accountingEntryDetailsPage.ddReturn')}
          variant="filled"
          color="error"
        />
      </Box>
    );

  return null;
};

export default AccountEntryPaymentTypeBadge;
