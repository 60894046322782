import { ChangeEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LightningIcon,
  Paper,
  PencilSimpleIcon,
  TextField,
  Tooltip,
  Typography,
} from 'elements';
import { ReceiptAutomatchStatus } from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props {
  isLoading: boolean;
  disableEdit: boolean;
  isReceiptNumberEditing: boolean;
  isReceiptNumberError: boolean;
  receiptNumber: string;
  receiptNumberStatus: ReceiptAutomatchStatus;
  onChange: (receiptNumber: string) => void;
  onSave: () => void;
  onEdit: () => void;
  onCancel: () => void;
}

const ReceiptNumber = ({
  isLoading,
  disableEdit,
  isReceiptNumberError,
  isReceiptNumberEditing,
  receiptNumber,
  receiptNumberStatus,
  onChange,
  onSave,
  onEdit,
  onCancel,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const canUser = useCanUser();

  useEffect(() => {
    if (isReceiptNumberEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
    }
  }, [isReceiptNumberEditing]);

  if (isReceiptNumberEditing)
    return (
      <Box mt={2}>
        <TextField
          size="medium"
          label={t('transactionReceipts.receiptNumberLabel')}
          inputRef={inputRef}
          inputProps={{
            maxLength: 100,
          }}
          value={receiptNumber}
          error={isReceiptNumberError}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          helperText={
            isReceiptNumberError &&
            t('transactionDetails.receiptNumberValidationError')
          }
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mt={1}
        >
          <Button onClick={onCancel} variant="text">
            {t('common.button.cancel')}
          </Button>
          <Button onClick={onSave} sx={{ ml: 1 }}>
            {t('common.button.save')}
          </Button>
        </Box>
      </Box>
    );

  return (
    <Paper
      variant="outlined"
      sx={{
        mt: 1,
        px: 1.5,
        py: 1.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box display="flex" alignItems="center" minWidth={0}>
        <Typography variant="body2" noWrap>
          {t('transactionReceipts.receiptNumberLabel')}
        </Typography>

        {receiptNumberStatus === ReceiptAutomatchStatus.AUTOMATCHED_SUCCESS && (
          <Tooltip
            title={t('transactionDetails.receiptNumberExtractedTooltip')}
          >
            <LightningIcon fontSize="small" color="info" sx={{ ml: 1.5 }} />
          </Tooltip>
        )}
      </Box>

      <Box pl={2} display="flex" alignItems="center" minWidth={0}>
        <Box
          lineHeight={0}
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {isLoading ? (
            <CircularProgress size="small" />
          ) : (
            <Typography variant="body2" display="inline">
              {receiptNumber || '—'}
            </Typography>
          )}
        </Box>

        {!disableEdit && canUser('receipt:upload') && (
          <Tooltip title={t('transactionDetails.receiptNumberInfo')!}>
            <IconButton
              onClick={onEdit}
              disabled={isLoading}
              sx={{ ml: 1 }}
              size="small"
            >
              <PencilSimpleIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Paper>
  );
};

export default ReceiptNumber;
