import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Grid, LabeledValue } from 'elements';
import FoundationDateChangeDialog from './FoundationDateChangeDialog';

const FoundationDateGroup = () => {
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <LabeledValue
          label={t('settingsOrganizationPage.foundationDate')}
          value={organization!.foundationDate}
          onEdit={() => setIsDialogOpen(true)}
        />
      </Grid>

      <FoundationDateChangeDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default FoundationDateGroup;
