import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as BenefitIcon } from 'assets/icons/appColored/benefit.svg';
import { ReactComponent as CertificateIcon } from 'assets/icons/appColored/certificate.svg';
import { ReactComponent as CompensateIcon } from 'assets/icons/appColored/compensate.svg';
import { ReactComponent as TrackIcon } from 'assets/icons/appColored/track.svg';
import { getDocumentUrl } from 'domains/document/utils';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const Header = styled.h3`
  font-size: 34px;
  line-height: 34px;
  margin: 0 0 40px;
`;

const ItemsContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 20px;
  }
`;

const Item = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutralVariant100};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-height: 216px;
  padding: 30px 20px 20px;
  width: 246px;

  svg {
    margin-bottom: 32px;
  }
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  min-height: 90px;
  text-align: center;
`;

const KeyFeatures = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>{t('pliantEarthPage.keyFeatures.title')}</Header>

      <ItemsContainer>
        <Item>
          <TrackIcon />
          <Text>{t('pliantEarthPage.keyFeatures.kf1')}</Text>
        </Item>

        <Item>
          <CompensateIcon />
          <Text>
            <Trans
              i18nKey="pliantEarthPage.keyFeatures.kf2"
              components={{
                a: (
                  // eslint-disable-next-line
                  <a
                    href={getDocumentUrl('ESG_EMISSION_FACTORS.pdf')}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          </Text>
        </Item>

        <Item>
          <CertificateIcon />
          <Text>{t('pliantEarthPage.keyFeatures.kf3')}</Text>
        </Item>

        <Item>
          <BenefitIcon />
          <Text>{t('pliantEarthPage.keyFeatures.kf4')}</Text>
        </Item>
      </ItemsContainer>
    </Wrapper>
  );
};

export default KeyFeatures;
