import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { Tab, Tabs } from 'layout';
import { BulkUploadError } from 'services/constants';

interface Props {
  dialogKey:
    | 'uploadGlAccountsDialog'
    | 'uploadSuppliersDialog'
    | 'uploadProjectsDialog';
  errors: BulkUploadError[];
  isLoading: boolean;
  onSuccess: () => void;
  onClose: () => void;
}

export const BulkUploadErrors = ({
  isLoading,
  dialogKey,
  errors,
  onSuccess,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const partnerName = usePartnerName();

  const shownErrors = useMemo(() => errors.filter((item) => item.error), [
    errors,
  ]);
  const shownWarnings = useMemo(() => errors.filter((item) => item.warning), [
    errors,
  ]);

  const fileHasErrors = !!shownErrors.length;
  const [activeTab, setActiveTab] = useState(fileHasErrors ? 0 : 1);

  const arrayToIterate = activeTab === 0 ? shownErrors : shownWarnings;

  return (
    <>
      <DialogTitle>
        {fileHasErrors
          ? t(`${dialogKey}.titleErrors`)
          : t(`${dialogKey}.titleWarnings`)}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={2}>
          {fileHasErrors
            ? t(`${dialogKey}.descriptionErrors`)
            : t(`${dialogKey}.descriptionWarnings`)}
        </Typography>

        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
        >
          <Tab
            label={t('bulkUploadErrorsDialog.errorTab', {
              number: shownErrors.length,
            })}
            data-test-id="error-tab"
            disabled={!shownErrors.length}
          />
          <Tab
            data-test-id="warning-tab"
            label={t('bulkUploadErrorsDialog.warningTab', {
              number: shownWarnings.length,
            })}
            disabled={!shownWarnings.length}
          />
        </Tabs>

        <Box maxHeight="250px" overflow="auto" mt={1}>
          <Table>
            <TableBody>
              {arrayToIterate.map((item) => (
                <TableRow key={`error-${item.index}-${item.errorCode}`}>
                  <TableCell>
                    <Typography
                      variant="body2"
                      noWrap
                      color={activeTab === 0 ? 'error.main' : 'warning.main'}
                    >
                      {t('bulkUploadErrorsDialog.errorLine', {
                        number: item.index + 1,
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      color={activeTab === 0 ? 'error.main' : 'warning.main'}
                    >
                      {t(`bulkUploadErrorsDialog.errors.${item.errorCode}`, {
                        partnerName,
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>

      <DialogActions>
        {fileHasErrors ? (
          <Button variant="text" onClick={onClose}>
            {t('common.button.close')}
          </Button>
        ) : (
          <>
            <Button variant="text" onClick={onClose}>
              {t('common.button.cancel')}
            </Button>
            <LoadingButton loading={isLoading} onClick={onSuccess}>
              {t('bulkUploadErrorsDialog.continueUploadButton')}
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </>
  );
};
