import { ReactComponent as BezalaIcon } from 'assets/icons/partnerLogos/bezala.svg';
import { ReactComponent as CirculaIcon } from 'assets/icons/partnerLogos/circula.svg';
import { ReactComponent as KlippaIcon } from 'assets/icons/partnerLogos/klippa.svg';
import { createSvgIcon, SvgIconProps } from 'elements';
import { IntegrationColor, IntegrationType } from 'services/constants';

const icon = {
  [IntegrationType.bezala]: BezalaIcon,
  [IntegrationType.circula]: CirculaIcon,
  [IntegrationType.klippa]: KlippaIcon,
};

interface Props extends SvgIconProps {
  integrationType:
    | typeof IntegrationType.bezala
    | typeof IntegrationType.circula
    | typeof IntegrationType.klippa;
}

const IntegrationTypeIcon = ({ integrationType, sx = [], ...props }: Props) => {
  const Icon = icon[integrationType];
  const IntegrationTypeMuiIcon = createSvgIcon(<Icon />);

  return (
    <IntegrationTypeMuiIcon
      sx={[
        { color: IntegrationColor[integrationType] },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  );
};

export default IntegrationTypeIcon;
