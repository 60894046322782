import { Components, Theme } from '@mui/material';

const MuiFormControlLabelOverrides: Components<Theme> = {
  MuiFormControlLabel: {
    styleOverrides: {
      labelPlacementStart: {
        justifyContent: 'space-between',
        marginLeft: 0,
      },
    },
  },
};

export default MuiFormControlLabelOverrides;
