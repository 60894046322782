import React from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { getDocumentUrl } from 'domains/document/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withDialogWrapper,
} from 'elements';
import { DocumentGenericType } from 'services/constants';
import { getMoneyObject } from 'services/utils';

const MEDICAL_CLAIMS_PHONE_NUMBER = '+44 207 183 8910';
const MEDICAL_CLAIMS_EMAIL = 'ops@northcottglobalsolutions.com';
const OTHER_CLAIMS_EMAIL = 'pliantclaims@crawco.de';

const useRows = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('insuranceDetailsDialog_v2.table.medicalAssistanceTitle'),
      description: <FormatMoney value={getMoneyObject(500000000)} />,
      excessDescription: <FormatMoney value={getMoneyObject(10000)} />,
    },
    {
      title: t('insuranceDetailsDialog_v2.table.hospitalBenefitTitle'),
      description: (
        <Trans
          i18nKey="insuranceDetailsDialog_v2.table.hospitalBenefitDescription"
          components={{
            perDayAmount: <FormatMoney value={getMoneyObject(15000)} />,
            maxAmount: <FormatMoney value={getMoneyObject(450000)} />,
          }}
        />
      ),
      excessDescription: t('insuranceDetailsDialog_v2.table.nil'),
    },
    {
      title: t('insuranceDetailsDialog_v2.table.baggageTitle'),
      description: <FormatMoney value={getMoneyObject(300000)} />,
      excessDescription: <FormatMoney value={getMoneyObject(7500)} />,
    },
    {
      title: t('insuranceDetailsDialog_v2.table.thirdPartyLiabilityTitle'),
      description: <FormatMoney value={getMoneyObject(50000000)} />,
      excessDescription: <FormatMoney value={getMoneyObject(15000)} />,
    },
    {
      title: t('insuranceDetailsDialog_v2.table.cancellationTitle'),
      description: <FormatMoney value={getMoneyObject(600000)} />,
      excessDescription: <FormatMoney value={getMoneyObject(7500)} />,
    },
    {
      title: t('insuranceDetailsDialog_v2.table.travelDelayedFlightTitle'),
      description: <FormatMoney value={getMoneyObject(75000)} />,
      excessDescription: t('insuranceDetailsDialog_v2.table.nil'),
    },
    {
      title: t('insuranceDetailsDialog_v2.table.travelDelayedBaggageTitle'),
      description: (
        <Trans
          i18nKey="insuranceDetailsDialog_v2.table.travelDelayedBaggageDescription"
          components={{
            firstAmount: <FormatMoney value={getMoneyObject(75000)} />,
            secondAmount: <FormatMoney value={getMoneyObject(120000)} />,
          }}
        />
      ),
      excessDescription: t('insuranceDetailsDialog_v2.table.nil'),
    },
    {
      title: t('insuranceDetailsDialog_v2.table.missedDepartureTitle'),
      description: <FormatMoney value={getMoneyObject(120000)} />,
      excessDescription: t('insuranceDetailsDialog_v2.table.nil'),
    },
    {
      title: t('insuranceDetailsDialog_v2.table.travelAccidentTitle'),
      description: (
        <Trans
          i18nKey="insuranceDetailsDialog_v2.table.travelAccidentDescription"
          components={{
            amount: <FormatMoney value={getMoneyObject(10000000)} />,
            limitedAmount: <FormatMoney value={getMoneyObject(4000000)} />,
            b: <b />,
          }}
        />
      ),
      excessDescription: t('insuranceDetailsDialog_v2.table.nil'),
    },
    {
      title: t('insuranceDetailsDialog_v2.table.collisionDamageTitle'),
      description: (
        <Trans
          i18nKey="insuranceDetailsDialog_v2.table.collisionDamageDescription"
          components={{
            amount: <FormatMoney value={getMoneyObject(2500000)} />,
          }}
        />
      ),
      excessDescription: t('insuranceDetailsDialog_v2.table.nil'),
    },
    {
      title: t('insuranceDetailsDialog_v2.table.sportsTitle'),
      description: <FormatMoney value={getMoneyObject(500000000)} />,
      excessDescription: <FormatMoney value={getMoneyObject(10000)} />,
    },
  ];
};

interface Props extends DialogProps {
  onClose: () => void;
}

const InsuranceDetailsDialog = (props: Props) => {
  const { t } = useTranslation();
  const rows = useRows();
  const {
    state: { documents },
  } = useGlobalState();

  return (
    <Dialog {...props} maxWidth="md">
      <DialogTitle>{t('insuranceDetailsDialog_v2.title')}</DialogTitle>

      <DialogContent>
        <TableContainer sx={{ height: 296 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('insuranceDetailsDialog_v2.table.headers.section')}
                </TableCell>
                <TableCell>
                  {t('insuranceDetailsDialog_v2.table.headers.visaCards')}
                </TableCell>
                <TableCell>
                  {t('insuranceDetailsDialog_v2.table.headers.excess')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.excessDescription}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" mt={4} mb={2}>
          <Box width="40%">
            <Typography variant="subtitle1">
              {t('insuranceDetailsDialog_v2.medicalAssistanceClaims')}
            </Typography>
            <Typography color="textSecondary">24/7</Typography>
          </Box>
          <div>
            <Link
              href={`mailto:${MEDICAL_CLAIMS_EMAIL}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="body1"
            >
              {MEDICAL_CLAIMS_EMAIL}
            </Link>
            <Typography>{MEDICAL_CLAIMS_PHONE_NUMBER}</Typography>
          </div>
        </Box>

        <Box display="flex">
          <Box width="40%">
            <Typography variant="subtitle1">
              {t('insuranceDetailsDialog_v2.otherClaims')}
            </Typography>
            <Typography color="textSecondary">
              8:30-17:00 {moment.weekdaysShort(1)}-{moment.weekdaysShort(5)}{' '}
              (CEST)
            </Typography>
          </Box>
          <div>
            <Link
              href={`mailto:${OTHER_CLAIMS_EMAIL}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="body1"
            >
              {OTHER_CLAIMS_EMAIL}
            </Link>
          </div>
        </Box>
      </DialogContent>

      <DialogActions>
        {documents && documents[DocumentGenericType.insuranceTerms] && (
          <Button
            variant="text"
            component="a"
            sx={{ mr: 1 }}
            href={getDocumentUrl(
              documents[DocumentGenericType.insuranceTerms][0].documentName
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('insuranceDetailsDialog_v2.termsAndConditions')}
          </Button>
        )}
        <Button onClick={props.onClose}>{t('common.button.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default withDialogWrapper(InsuranceDetailsDialog);
