import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { Chip, drawerPlaceholderColumn, GridColDef, gridUtils } from 'elements';
import { CashbackRedemptionBatch } from 'services/constants';
import BatchStateCell from './BatchStateCell';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<CashbackRedemptionBatch>[] = [
      {
        headerName: t('int.cashbackPaymentBatchesPage.createdAt'),
        field: 'createdAt',
        valueGetter: ({ row }) => moment(row.createdAt).format('YYYY-MM-DD'),
        flex: 76,
        minWidth: 187,
      },
      {
        headerName: t('int.cashbackPaymentBatchesPage.paymentDate'),
        field: 'paymentDate',
        valueGetter: ({ row }) => moment(row.paymentDate).format('YYYY-MM-DD'),
        flex: 76,
        minWidth: 187,
      },
      {
        headerName: t('int.cashbackPaymentBatchesPage.accountGroup'),
        field: 'accountGroup',
        renderCell: ({ row }) => (
          <Chip
            label={t(`int.accountGroupSelect.items.${row.accountGroup}`)}
            size="small"
          />
        ),
        flex: 76,
        minWidth: 187,
        sortable: false,
      },
      {
        headerName: t('int.cashbackPaymentBatchesPage.currency'),
        field: 'currency',
        flex: 50,
        minWidth: 123,
        sortable: false,
      },
      {
        headerName: t('int.cashbackPaymentBatchesPage.amount'),
        field: 'totalAmount',
        renderCell: ({ row }) => (
          <FormatMoney value={row.amount} fractionalPart />
        ),
        flex: 50,
        minWidth: 123,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.cashbackPaymentBatchesPage.count'),
        field: 'totalCount',
        valueGetter: ({ row }) => row.count,
        flex: 60,
        minWidth: 148,
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.cashbackPaymentBatchesPage.state'),
        field: 'batchState',
        renderCell: ({ row }) => (
          <BatchStateCell states={row.states} totalCount={row.count} />
        ),
        flex: 90,
        minWidth: 221,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },
      drawerPlaceholderColumn,
    ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language]);
};

export default useColumns;
