import i18next from 'i18next';
import { env } from 'env';
import {
  Document,
  DocumentGenericType,
  PartnerIds,
  PartnerIdType,
} from 'services/constants';

export const getDocumentUrl = (name: string) =>
  `${env.REACT_APP_DOCUMENTS_URL}/${name}`;

export const getDocumentName = (
  document: Document | null,
  genericType: DocumentGenericType,
  partnerId: PartnerIdType
) => {
  let documentName = '';
  if (
    partnerId === PartnerIds.commerzbank &&
    [
      DocumentGenericType.issuerTermsAndConditions,
      DocumentGenericType.platformTermsAndConditions,
    ].includes(genericType)
  ) {
    documentName = `${i18next.t(
      `documentNamesByGenericType.${genericType}_COMMERZBANK`
    )}`;
  } else {
    documentName = `${i18next.t(`documentNamesByGenericType.${genericType}`)}`;
  }

  if (document) {
    documentName += ` (${document.counterparty})`;
  }

  return documentName;
};
