import React from 'react';
import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@mui/material';

export interface SwitchProps extends MuiSwitchProps {
  /**
   * The color of the component.
   * @default 'primary'
   */
  color?: 'primary' | 'default';
}

const Switch = (props: SwitchProps) => <MuiSwitch {...props} />;

export default Switch;
