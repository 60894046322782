import React, { useEffect, useMemo, useState } from 'react';
import { differenceWith, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { TeamMemberAutocomplete } from 'domains/member/components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Link,
  LoaderWithOverlay,
  ManagerRoundFillIcon,
  QuestionIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withDialogWrapper,
  XIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DEFAULT_PAGE_LIMIT,
  Member,
  MemberStatus,
  PartialMember,
  Team,
  TeamMember,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface ExtendedTeamMember extends TeamMember {
  teamManager: boolean;
}

const getDiffBetweenMembers = (
  currentTeamMembers: ExtendedTeamMember[],
  newTeamMembers: ExtendedTeamMember[]
): {
  membersToAdd: ExtendedTeamMember[];
  membersToDelete: ExtendedTeamMember[];
  membersToUpdate: ExtendedTeamMember[];
} => {
  const membersToAdd = differenceWith<ExtendedTeamMember, ExtendedTeamMember>(
    newTeamMembers,
    currentTeamMembers,
    (first, second) => first.memberId === second.memberId
  );
  const membersToDelete = differenceWith<
    ExtendedTeamMember,
    ExtendedTeamMember
  >(
    currentTeamMembers,
    newTeamMembers,
    (first, second) => first.memberId === second.memberId
  );
  const membersToUpdate = newTeamMembers.filter((newMember) => {
    const currentMember = currentTeamMembers.find(
      (item) => item.memberId === newMember.memberId
    );
    return (
      !!currentMember && currentMember.teamManager !== newMember.teamManager
    );
  });

  return { membersToAdd, membersToDelete, membersToUpdate };
};

interface State {
  autocompleteMembers: PartialMember[];
  members: Member[];
  newTeamMembers: ExtendedTeamMember[];
  isLoading: boolean;
}

interface Props extends DialogProps {
  team: Team | null;
  isEditing?: boolean;
  onSuccess?: (team: Team) => void;
  onClose: () => void;
}

const UpdateTeamMembersDialog = ({
  team: teamNullable,
  isEditing,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const team = useMemo(() => teamNullable!, []);
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const currentTeamMembers = useMemo<ExtendedTeamMember[]>(
    () => [
      ...team.managers.map((item) => ({ ...item, teamManager: true })),
      ...team.members.map((item) => ({ ...item, teamManager: false })),
    ],
    [team.managers, team.members]
  );
  const [state, setState] = useState<State>({
    autocompleteMembers: [],
    members: [],
    newTeamMembers: [...currentTeamMembers],
    isLoading: false,
  });
  const { membersToAdd, membersToDelete, membersToUpdate } = useMemo(
    () => getDiffBetweenMembers(currentTeamMembers, state.newTeamMembers),
    [currentTeamMembers, state.newTeamMembers]
  );

  useEffect(() => {
    // Load all team members to get info about their team cards.
    // A member cannot be removed from a team if he has pending / active cards assigned to that team.
    (async () => {
      try {
        if (!team.members.length) return;
        setState((prevState) => ({ ...prevState, isLoading: true }));
        let members: Member[] = [];
        let hasNextPage = true;
        let page = 0;
        while (hasNextPage && mounted.current) {
          const data = await api.getMembers({
            page,
            limit: DEFAULT_PAGE_LIMIT,
            status: [MemberStatus.invited, MemberStatus.active].join(),
            organizationId: organization!.id,
            teamIds: team.id,
          });
          members = [...members, ...data.members];
          hasNextPage = data.hasNextPage;
          page++;
        }
        setState((prevState) => ({ ...prevState, members, isLoading: false }));
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        setState((prevState) => ({ ...prevState, isLoading: false }));
        logError(error);
      }
    })();
  }, []);

  const updateTeamMembers = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const data = await api.updateTeamMembers(team.id, {
        membersToAdd: membersToAdd.map(({ memberId, teamManager }) => ({
          memberId,
          teamManager,
        })),
        membersToDelete: membersToDelete.map(({ memberId }) => ({ memberId })),
        membersToUpdate: membersToUpdate.map(({ memberId, teamManager }) => ({
          memberId,
          teamManager,
        })),
      });
      dispatch({ type: 'UPDATE_TEAM', payload: data });
      if (!mounted.current) return;
      if (onSuccess) onSuccess(data);
      else props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const addNewMembers = () => {
    setState((prevState) => {
      const addedMembers = prevState.autocompleteMembers.map(
        ({ id, firstName, lastName }) => {
          const currentMember = currentTeamMembers.find(
            (item) => item.memberId === id
          );
          return {
            memberId: id,
            firstName,
            lastName,
            teamManager: currentMember ? currentMember.teamManager : false,
          };
        }
      );
      return {
        ...prevState,
        autocompleteMembers: [],
        newTeamMembers: sortBy<ExtendedTeamMember>(
          [...prevState.newTeamMembers, ...addedMembers],
          [
            (member) => (member.teamManager ? -1 : 1),
            (member) => `${member.firstName} ${member.lastName}`.toLowerCase(),
          ]
        ),
      };
    });
  };

  const toggleManagerRole = (member: ExtendedTeamMember) => {
    setState((prevState) => ({
      ...prevState,
      newTeamMembers: prevState.newTeamMembers.map((item) =>
        item.memberId === member.memberId
          ? { ...item, teamManager: !item.teamManager }
          : item
      ),
    }));
  };

  const isMemberRemovalDisabled = (teamMember: ExtendedTeamMember) => {
    const member = state.members.find(
      (item) => item.id === teamMember.memberId
    );
    if (!member) return false;
    const memberTeam = member.teams.find((item) => item.teamId === team.id);
    return !!memberTeam && memberTeam.hasActiveCards;
  };

  const removeMember = (member: ExtendedTeamMember) => {
    setState((prevState) => ({
      ...prevState,
      newTeamMembers: prevState.newTeamMembers.filter(
        (item) => item.memberId !== member.memberId
      ),
    }));
  };

  const canChangeTeamManagers = canUser('team-managers:change', team);
  const isSubmitDisabled =
    (!membersToAdd.length &&
      !membersToDelete.length &&
      !membersToUpdate.length) ||
    state.isLoading;

  return (
    <Dialog {...props}>
      <DialogTitle>
        {isEditing
          ? t('updateTeamMembersDialog.editTitle')
          : t('updateTeamMembersDialog.addTitle')}
      </DialogTitle>
      <DialogContent>
        <TeamMemberAutocomplete
          disabled={state.isLoading}
          label={t('updateTeamMembersDialog.teamMembers')}
          teamId={team.id}
          filteredOutMembers={membersToAdd}
          value={state.autocompleteMembers}
          onChange={(autocompleteMembers) =>
            setState((prevState) => ({ ...prevState, autocompleteMembers }))
          }
          onSave={addNewMembers}
        />
        <TableContainer sx={{ mt: 3 }}>
          {!!state.newTeamMembers.length && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('updateTeamMembersDialog.name')}</TableCell>
                  <TableCell colSpan={2} padding="none">
                    {canChangeTeamManagers &&
                      t('updateTeamMembersDialog.managerHoverAndClick')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.newTeamMembers.map((member) => (
                  <TableRow
                    key={member.memberId}
                    sx={{
                      '&:hover .MuiLink-root': {
                        visibility: 'visible',
                      },
                      '.MuiTableCell-root': { border: 0 },
                    }}
                  >
                    <TableCell>
                      {member.firstName} {member.lastName}
                      {member.teamManager && (
                        <Tooltip title={t('tooltips.managerForTeam')}>
                          <ManagerRoundFillIcon
                            sx={{
                              width: 16,
                              height: 16,
                              color: 'text.secondary',
                              ml: 1,
                            }}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell padding="none">
                      {canChangeTeamManagers && (
                        <Link
                          onClick={() => toggleManagerRole(member)}
                          disabled={state.isLoading}
                          component="button"
                          sx={{ visibility: 'hidden' }}
                        >
                          {member.teamManager
                            ? t('updateTeamMembersDialog.unassignManagerRole')
                            : t('updateTeamMembersDialog.assignManagerRole')}
                        </Link>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {isMemberRemovalDisabled(member) ? (
                        <Tooltip title={t('tooltips.removalFromTeamDisabled')!}>
                          <QuestionIcon
                            sx={{
                              margin: '5px',
                              verticalAlign: 'middle',
                              color: 'text.secondary',
                            }}
                            fontSize="small"
                          />
                        </Tooltip>
                      ) : (
                        <IconButton
                          onClick={() => removeMember(member)}
                          disabled={state.isLoading}
                          size="small"
                        >
                          <XIcon fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button onClick={updateTeamMembers} disabled={isSubmitDisabled}>
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(UpdateTeamMembersDialog);
