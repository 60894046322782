import { useEffect, useState } from 'react';
import { FieldInputProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Autocomplete, FormControl } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { DEFAULT_PAGE_LIMIT, Team, TeamStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends Omit<FieldInputProps<Team[]>, 'onChange'> {
  label: string;
  disabled: boolean;
  onChange: (teams: Team[]) => void;
}

interface State {
  teams: Team[];
  isLoading: boolean;
}

const TeamsSelect = ({ label, disabled, onChange, ...fieldProps }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    teams: [],
    isLoading: true,
  });
  const [inputValue, setInputValue] = useState('');

  const getData = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const { teams } = await api.getTeams({
        organizationId: organization!.id,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
        q: inputValue.trim(),
        sortBy: '+name',
        status: TeamStatus.active,
      });

      if (!mounted.current) return;

      setState((prevState) => ({
        ...prevState,
        teams,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    let active = true;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    setTimeout(() => {
      if (active) getData();
    }, 250);
    return () => {
      active = false;
    };
  }, [inputValue.trim()]);

  return (
    <FormControl fullWidth disabled={disabled}>
      <Autocomplete<Team, true, false, true>
        {...fieldProps}
        freeSolo
        multiple
        label={label}
        inputValue={inputValue}
        onInputChange={(e, inputValue) => setInputValue(inputValue)}
        onChange={(_, option) => {
          const teamOptions = option.filter(
            (item) => item && typeof item !== 'string'
          );
          onChange(teamOptions as Team[]);
        }}
        options={state.teams}
        loading={state.isLoading}
        filterOptions={(x) => x}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => {
          if (!option || typeof option === 'string') return '';
          return option.name;
        }}
        placeholder={t('filters.team')}
        loadingText={t('common.loading')}
        noOptionsText={t('common.nothingFound')}
      />
    </FormControl>
  );
};

export default TeamsSelect;
