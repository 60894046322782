import { Theme } from '@mui/material';
import { PickersProComponents } from '@mui/x-date-pickers-pro/themeAugmentation';

const MuiDateRangePickerOverrides: PickersProComponents<Theme> = {
  MuiDateRangeCalendar: {
    styleOverrides: {
      root: {
        '.MuiPickersDay-root': {
          lineHeight: 1,
        },
      },
    },
  },
};

export default MuiDateRangePickerOverrides;
