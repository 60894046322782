import { Components, Theme } from '@mui/material';

const MuiAlertOverrides: Components<Theme> = {
  MuiAlert: {
    styleOverrides: {
      root: {
        '.MuiAlert-action': {
          flexShrink: 0,
        },
      },
      standardError: ({ theme }) => ({
        background: theme.variables.alert.errorFill,
        color: theme.variables.alert.errorContent,
      }),
      standardWarning: ({ theme }) => ({
        background: theme.variables.alert.warningFill,
        color: theme.variables.alert.warningContent,
      }),
      standardInfo: ({ theme }) => ({
        background: theme.variables.alert.infoFill,
        color: theme.variables.alert.infoContent,
      }),
      standardSuccess: ({ theme }) => ({
        background: theme.variables.alert.successFill,
        color: theme.variables.alert.successContent,
      }),
      outlinedError: ({ theme }) => ({
        color: theme.variables.alert.errorContent,
      }),
      outlinedWarning: ({ theme }) => ({
        color: theme.variables.alert.warningContent,
      }),
      outlinedInfo: ({ theme }) => ({
        color: theme.variables.alert.infoContent,
      }),
      outlinedSuccess: ({ theme }) => ({
        color: theme.variables.alert.successContent,
      }),
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.variables.components['alert-title'],
      }),
    },
  },
};

export default MuiAlertOverrides;
