import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Box, Typography } from 'elements';
import { OrganizationMerchant } from 'services/constants';
import { useFlags } from 'services/featureflags';
import SubcategorySelect from './SubcategorySelect';
import SupplierSelect from './SupplierSelect';
import VatRatesSelect from './VatRatesSelect';

interface Props {
  merchant: OrganizationMerchant;
  onChange: (updatedResponse: Partial<OrganizationMerchant>) => void;
}

const AccountingRules = ({ merchant, onChange }: Props) => {
  const { t } = useTranslation();
  const { suppliersEnabled } = useFlags();
  const {
    state: { accountingSettings },
  } = useGlobalState();

  const isSupplierSelectShown =
    suppliersEnabled && accountingSettings?.supplierEnabled;
  const isVatRateSelectShown = accountingSettings?.vatRateEnabled;
  const isSubcategorySelectShown = accountingSettings?.subcategoryEnabled;

  if (
    !isSupplierSelectShown &&
    !isSubcategorySelectShown &&
    !isVatRateSelectShown
  ) {
    return null;
  }

  return (
    <Box p={3} borderBottom={(theme) => `1px solid ${theme.palette.divider}`}>
      <Typography variant="overline" component="div" mb={1} py={0.5}>
        {t('organizationMerchantDetailsPage.accountingRules')}
      </Typography>
      {isSupplierSelectShown && (
        <SupplierSelect
          value={merchant.supplierResponse}
          onChange={(supplierResponse) => onChange({ supplierResponse })}
          merchantId={merchant.id}
        />
      )}
      {isSubcategorySelectShown && (
        <SubcategorySelect
          value={merchant.subcategoryResponse}
          onChange={(subcategoryResponse) => onChange({ subcategoryResponse })}
          merchantId={merchant.id}
        />
      )}
      {isVatRateSelectShown && (
        <VatRatesSelect
          value={merchant.vatRateResponse}
          onChange={(vatRateResponse) => onChange({ vatRateResponse })}
          merchantId={merchant.id}
        />
      )}
    </Box>
  );
};

export default AccountingRules;
