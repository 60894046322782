import { ListItemText, styled } from '@mui/material';

/**
 * Use in place of the ListItemText component if you need
 * to display the secondary text before the primary one.
 */
export const ListItemTextReversed = styled(ListItemText)`
  display: flex;
  flex-direction: column-reverse;
` as typeof ListItemText;
