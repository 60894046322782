import { styled } from '@mui/material';
import { Paper } from 'elements';

export const LogoWrapper = styled(Paper)`
  height: 80px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 60%;
    max-height: 70%;
  }
`;
