import { Components, Theme } from '@mui/material';

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    tinted: true;
  }
}

const MuiPaperOverrides: Components<Theme> = {
  MuiPaper: {
    variants: [
      {
        props: { variant: 'tinted' },
        style: ({ theme }) => ({
          backgroundColor: theme.variables.background.contrast,
        }),
      },
    ],
  },
};

export default MuiPaperOverrides;
