import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { Moment } from 'moment';
import { useGlobalState } from 'context/GlobalState';
import { CardAccountFilter } from 'domains/card/components';
import {
  FlagReasonFilter,
  ReceiptFilter,
  ReviewFilter,
  TransactionSimpleTypeFilter,
} from 'domains/transaction/components';
import {
  MISSING_SUPPLIER_OPTION,
  MISSING_TEAM_OPTION,
  QueryParams,
  visibleTransactionTypes,
} from 'domains/transaction/pages/ExportPage/utils';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import {
  NONE_VALUE,
  ProjectStatus,
  ReviewFlagReason,
  SubcategoryStatus,
  Supplier,
  TransactionReceiptStatus,
  TransactionReviewStatus,
  TransactionSimpleType,
  VatRateStatus,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import ProjectFilter from './ProjectFilter';
import SubcategoryFilter from './SubcategoryFilter';
import SupplierFilter from './SupplierFilter';
import TeamsFilter from './TeamsFilter';
import VatRateFilter from './VatRateFilter';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  suppliers: Supplier[];
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  type: TransactionSimpleType[];
  receipt: TransactionReceiptStatus | '';
  supplier:
    | typeof MISSING_SUPPLIER_OPTION
    | Omit<string, typeof MISSING_SUPPLIER_OPTION>;
  subcategory:
    | SubcategoryStatus.missing
    | Omit<string, SubcategoryStatus.missing>;
  vatRate: VatRateStatus.missing | Omit<VatRateStatus.missing, string>;
  project: ProjectStatus.missing | Omit<ProjectStatus.missing, string>;
  team: typeof MISSING_TEAM_OPTION | Omit<typeof MISSING_TEAM_OPTION, string>;
  fromDate: Moment | null;
  toDate: Moment | null;
  reviewStatus: TransactionReviewStatus | typeof NONE_VALUE | '';
  flagReason: ReviewFlagReason | '';
  cardAccountId: string;
}

const initialState: State = {
  type: [],
  receipt: '',
  supplier: '',
  subcategory: '',
  vatRate: '',
  project: '',
  team: '',
  fromDate: null,
  toDate: null,
  reviewStatus: '',
  flagReason: '',
  cardAccountId: '',
};

const FiltersDrawer = ({ suppliers, params, setParam, ...props }: Props) => {
  const { suppliersEnabled } = useFlags();
  const {
    state: { accountingSettings, cardAccounts, featureModules },
  } = useGlobalState();
  const [state, setState] = useState<State>(initialState);
  const accountingFeaturesEnabled = featureModules.ACCOUNTING_FEATURES;

  const areFiltersSelected = !!(
    state.type.length ||
    state.receipt ||
    state.supplier ||
    state.subcategory ||
    state.vatRate ||
    state.project ||
    state.team ||
    state.fromDate ||
    state.toDate ||
    state.reviewStatus ||
    state.flagReason ||
    state.cardAccountId
  );

  useEffect(() => {
    if (props.open)
      setState({
        type: params.type,
        receipt: params.receipt,
        supplier: params.supplier,
        subcategory: params.subcategory,
        vatRate: params.vatRate,
        project: params.project,
        team: params.team,
        fromDate: params.fromDate,
        toDate: params.toDate,
        reviewStatus: params.reviewStatus,
        flagReason: params.flagReason,
        cardAccountId: params.cardAccountId,
      });
  }, [props.open]);

  const onFiltersApply = () => {
    const filtersToApply = {
      ...state,
      fromDate: state.fromDate?.startOf('day')?.format(),
      toDate: state.toDate?.endOf('day')?.format(),
    };

    setParam(
      Object.keys(filtersToApply).map((key) => [
        key,
        filtersToApply[key as keyof State],
      ])
    );
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.keys(state).map((key) => [key, '']));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <TransactionSimpleTypeFilter
        value={state.type}
        onChange={(value) =>
          setState((prevState) => ({
            ...prevState,
            type: xor(prevState.type, [value]),
          }))
        }
        options={visibleTransactionTypes}
      />

      <DateFilter
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate: fromDate?.startOf('day') || null,
            toDate: toDate?.endOf('day') || null,
          }))
        }
      />

      {cardAccounts.length > 1 && (
        <CardAccountFilter
          value={state.cardAccountId}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, cardAccountId: value }))
          }
        />
      )}

      {accountingFeaturesEnabled &&
        suppliersEnabled &&
        accountingSettings?.supplierEnabled && (
          <SupplierFilter
            value={state.supplier}
            onChange={(value) =>
              setState((prevState) => ({ ...prevState, supplier: value }))
            }
            suppliers={suppliers}
          />
        )}

      {featureModules.RECEIPT_MANAGEMENT && (
        <ReceiptFilter
          value={state.receipt}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, receipt: value }))
          }
        />
      )}

      {accountingSettings?.subcategoryEnabled && (
        <SubcategoryFilter
          value={state.subcategory}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, subcategory: value }))
          }
        />
      )}

      {accountingSettings?.vatRateEnabled && (
        <VatRateFilter
          value={state.vatRate}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, vatRate: value }))
          }
        />
      )}

      {featureModules.TEAMS && (
        <TeamsFilter
          value={state.team}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, team: value }))
          }
        />
      )}

      {accountingFeaturesEnabled && accountingSettings?.projectEnabled && (
        <ProjectFilter
          value={state.project}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, project: value }))
          }
        />
      )}

      <ReviewFilter
        value={state.reviewStatus}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, reviewStatus: value }))
        }
      />

      <FlagReasonFilter
        value={state.flagReason}
        onChange={(flagReason) =>
          setState((prevState) => ({
            ...prevState,
            flagReason,
            reviewStatus:
              prevState.reviewStatus || TransactionReviewStatus.flagged,
          }))
        }
      />
    </FilterDrawer>
  );
};

export default FiltersDrawer;
