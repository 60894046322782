import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { PageFiltersComponent } from 'layout';
import FiltersDrawer from './FiltersDrawer';
import { QueryParams } from '.././index';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  disabled: boolean;
  totalCount: number;
}

const Filters = ({
  params,
  selectedFiltersCount,
  setParam,
  disabled,
  totalCount,
}: Props) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <>
      <PageFiltersComponent
        count={selectedFiltersCount}
        q={params.q}
        setQParam={(value) => setParam('q', value)}
        onOpen={() => setIsFilterOpen(true)}
        disabled={disabled}
      >
        <Typography variant="body2" color="textSecondary" ml={2}>
          {t('common.resultsTable', {
            count: totalCount,
          })}
        </Typography>
      </PageFiltersComponent>

      <FiltersDrawer
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        params={params}
        setParam={setParam}
      />
    </>
  );
};

export default Filters;
