import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import {
  Alert,
  Box,
  Button,
  LoaderWithOverlay,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from 'elements';
import { ReceivableRepaymentAmount } from 'services/constants';

interface Props {
  repayments: ReceivableRepaymentAmount[];
  hasNextPage: boolean;
  onLoadMore: () => void;
  error: unknown;
}

const RepaymentsList = ({
  repayments,
  hasNextPage,
  onLoadMore,
  error,
}: Props) => {
  const { t } = useTranslation();

  if (!repayments.length) {
    return null;
  }

  return (
    <Box m={3}>
      <TableContainer>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 2 / 3 }}>
                {t('int.receivableRepaymentDetailsPage.orgAndAcc')}
              </TableCell>
              <TableCell sx={{ width: 1 / 3 }} align="right">
                {t('int.receivableRepaymentDetailsPage.repaymentAmount')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {repayments.map((item) => (
              <TableRow key={item.cardAccountNumber}>
                <TableCell>
                  <Tooltip title={item.organizationName}>
                    <Typography variant="inherit" noWrap>
                      {item.organizationName}
                    </Typography>
                  </Tooltip>
                  <Typography variant="inherit" color="text.secondary" noWrap>
                    {item.cardAccountNumber}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <FormatMoney value={item.amount} fractionalPart />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {hasNextPage && !error && (
        <Box position="relative" height={52}>
          <LoaderWithOverlay loading />
        </Box>
      )}
      {hasNextPage && !!error && (
        <Alert
          sx={{ mt: 2 }}
          severity="error"
          action={
            <Button
              variant="text"
              color="error"
              size="small"
              onClick={onLoadMore}
            >
              {t('common.retry')}
            </Button>
          }
        >
          {t('errors.loadData')}
        </Alert>
      )}
    </Box>
  );
};

export default RepaymentsList;
