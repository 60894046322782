import i18next from 'i18next';
import { Card, CardConfig } from 'services/constants';

export const getCardName = (
  cardConfig: CardConfig,
  shouldInterpolateCardWord: boolean = false
) => {
  const cardName = i18next.t(`cardNames.${cardConfig}`);

  return shouldInterpolateCardWord
    ? i18next.t('card.nameWithCardWord', { name: cardName })
    : cardName;
};

export const getCardNameWithRefNum = (card: Card) => {
  const cardName = getCardName(card.cardConfig, true);
  return card.cardRefNum ? `${cardName} (${card.cardRefNum})` : cardName;
};
