import { TextareaAutosize as MuiTextareaAutosize } from '@material-ui/core';
import styled from 'styled-components';

export const Textarea = styled.textarea`
  height: 84px;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.grey10};
  background: ${({ theme }) => theme.colors.neutralVariant100};
  transition: border-color 0.2s;
  resize: none;
  outline: none;

  &:focus:not([readonly]) {
    border-color: ${({ theme }) => theme.colors.grey10};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey100};
  }
`;

export const TextareaAutosize = styled(MuiTextareaAutosize)`
  min-height: 92px;
  width: 100%;
  padding: 52px 16px 18px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.grey10};
  background: ${({ theme }) => theme.colors.neutralVariant100};
  transition: border-color 0.2s;
  resize: none;
  outline: none;

  &:focus:not([readonly]) {
    border-color: ${({ theme }) => theme.colors.grey10};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey100};
  }
`;
