import React from 'react';
import { Trans } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { adminPaths, cardholderPaths } from 'components/App';
import useSnackbar from 'hooks/useSnackbar';
import {
  Card,
  CardType,
  Member,
  MemberDetails,
  MemberStatus,
} from 'services/constants';
import { useCanUser } from 'services/rbac';

const getCardIssuedMessage = (
  { type }: Card,
  cardDetailsPath: string,
  member: Member | MemberDetails
) => {
  let i18nKey: string;

  if (member.status !== MemberStatus.active) {
    i18nKey = 'issueCardDialog_v2.cardIssuedForInvitedMember';
  } else if (type === CardType.virtual || type === CardType.singleUse) {
    i18nKey = 'issueCardDialog_v2.virtualCardIssued';
  } else {
    i18nKey = 'issueCardDialog_v2.physicalCardIssued';
  }

  return (
    <Trans
      i18nKey={i18nKey}
      components={{
        'card-link': (
          <Link data-test-id="issued-card-link" to={cardDetailsPath} />
        ),
      }}
    />
  );
};

const useCardIssuedMessage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();

  return (card: Card, member: Member | MemberDetails, teamId?: string) => {
    const cardDetailsPath = canUser('cards-page:visit')
      ? generatePath(adminPaths.cardDetails, {
          cardId: card.cardId,
        })
      : generatePath(cardholderPaths.teamCardDetails, {
          teamId,
          cardId: card.cardId,
        });

    enqueueSnackbar(getCardIssuedMessage(card, cardDetailsPath, member));
  };
};

export default useCardIssuedMessage;
