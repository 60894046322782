import React, { useCallback, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  LoaderWithOverlay,
  TextField,
  Typography,
  withDialogWrapper,
  XIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  isValidMandateReferenceId,
  sleep,
} from 'services/utils';

enum SWITCH_TO_B2B_MANDATE_STEP {
  SET_MANDATE_REFERENCE = 'SET_MANDATE_REFERENCE',
  UPLOAD_PDF = 'UPLOAD_PDF',
}

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: () => Promise<void>;
}

interface State {
  isLoading: boolean;
  mandateReference: string;
  mandateReferenceError: string;
  selectedFile: FileWithPath | null;
  step: SWITCH_TO_B2B_MANDATE_STEP;
}

const SwitchToB2bMandateDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
    mandateReference: '',
    mandateReferenceError: '',
    selectedFile: null,
    step: SWITCH_TO_B2B_MANDATE_STEP.SET_MANDATE_REFERENCE,
  });

  const onFileSelect = useCallback(
    (acceptedFiles: FileWithPath[]) =>
      setState((prevState) => ({
        ...prevState,
        selectedFile: acceptedFiles[0],
      })),
    []
  );
  const { getInputProps, open } = useDropzone({
    accept: 'application/pdf',
    onDrop: onFileSelect,
  });

  const saveB2bMandateReference = async () => {
    try {
      if (!isValidMandateReferenceId(state.mandateReference)) {
        return setState((prevState) => ({
          ...prevState,
          mandateReferenceError: t(
            'int.switchToB2bMandateDialog.mandateReferenceFormatError'
          ),
        }));
      }

      setState({ ...state, isLoading: true });
      await api.changeMandateTypeToB2b(
        organization!.id,
        state.mandateReference
      );
      // required to wait for the backend to create empty doc for mandate
      await sleep(1000);

      if (!mounted.current) return;
      setState({
        ...state,
        isLoading: false,
        step: SWITCH_TO_B2B_MANDATE_STEP.UPLOAD_PDF,
      });
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState({ ...state, isLoading: false });
      logError(error);
    }
  };

  const uploadPdf = async () => {
    try {
      setState({ ...state, isLoading: true });
      await api.uploadMandateFile(
        state.mandateReference,
        state.selectedFile as File
      );
      await onSuccess();

      if (!mounted.current) return;
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState({ ...state, isLoading: false });
      logError(error);
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{t('int.switchToB2bMandateDialog.title')}</DialogTitle>
      <DialogContent>
        <Trans
          i18nKey="int.switchToB2bMandateDialog.description"
          components={{ b: <b /> }}
          values={{
            orgName: organization!.name,
          }}
        />
        <Box my={2}>
          <Typography
            variant="body2"
            mb={0.5}
            color={
              state.step !== SWITCH_TO_B2B_MANDATE_STEP.SET_MANDATE_REFERENCE
                ? 'text.disabled'
                : undefined
            }
          >
            {t('int.switchToB2bMandateDialog.uniqueMandateReferenceStep')}
          </Typography>
          <TextField
            disabled={
              state.isLoading ||
              state.step !== SWITCH_TO_B2B_MANDATE_STEP.SET_MANDATE_REFERENCE
            }
            error={!!state.mandateReferenceError}
            helperText={state.mandateReferenceError}
            onChange={(e) =>
              setState({
                ...state,
                mandateReference: e.target.value,
                mandateReferenceError: '',
              })
            }
            value={state.mandateReference}
          />
          <Box mt={1}>
            <Button
              disabled={
                state.isLoading ||
                !state.mandateReference ||
                !!state.mandateReferenceError ||
                state.step !== SWITCH_TO_B2B_MANDATE_STEP.SET_MANDATE_REFERENCE
              }
              onClick={saveB2bMandateReference}
              size="small"
            >
              {t('common.button.save')}
            </Button>
          </Box>
        </Box>

        <Box mb={2}>
          <Typography
            variant="body2"
            mb={1}
            color={
              state.step !== SWITCH_TO_B2B_MANDATE_STEP.UPLOAD_PDF
                ? 'text.disabled'
                : undefined
            }
          >
            {t('int.switchToB2bMandateDialog.uploadPdfStep')}
          </Typography>
          <input {...getInputProps()} />
          {state.selectedFile ? (
            <Box display="flex" alignItems="center">
              <Typography variant="body2" color="text.secondary" mx={2}>
                {state.selectedFile?.name}
              </Typography>
              <IconButton
                onClick={() => setState({ ...state, selectedFile: null })}
                size="small"
              >
                <XIcon fontSize="small" />
              </IconButton>
            </Box>
          ) : (
            <Button
              size="small"
              variant="outlined"
              onClick={open}
              disabled={
                state.isLoading ||
                state.step !== SWITCH_TO_B2B_MANDATE_STEP.UPLOAD_PDF
              }
            >
              {t('int.switchToB2bMandateDialog.selectFile')}
            </Button>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.close')}
        </Button>
        <Button
          disabled={
            state.isLoading ||
            !state.selectedFile ||
            state.step !== SWITCH_TO_B2B_MANDATE_STEP.UPLOAD_PDF
          }
          onClick={uploadPdf}
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(SwitchToB2bMandateDialog);
