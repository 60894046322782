import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { Box, Chip, LoaderWithOverlay, Paper, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MemberCount } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getPath } from 'services/utils';

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

interface State {
  isLoading: boolean;
  memberCount: MemberCount | null;
}

interface Props {
  refreshIndex: number;
}

const ActiveMembers = ({ refreshIndex }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    memberCount: null,
  });
  const membersPath = generatePath(getPath('members'), {
    orgId: organization!.id,
  });

  const getData = async () => {
    try {
      const memberCount = await api.getMemberCount(organization!.id);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        memberCount,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, [refreshIndex]);

  const renderMemberCount = () => {
    const { memberCount } = state;
    if (!memberCount) return null;

    const totalMemberCount =
      memberCount.activeMembersCount + memberCount.invitedMembersCount;
    const adminAppMemberCount =
      memberCount.activeAccountOwnersCount +
      memberCount.invitedAccountOwnersCount +
      memberCount.activeOrgAdminsCount +
      memberCount.invitedOrgAdminsCount +
      memberCount.activeAccountantsCount +
      memberCount.invitedAccountantsCount;

    return (
      <>
        <Typography variant="h4" mb={2}>
          {totalMemberCount}
        </Typography>
        <Chip
          color="primary"
          label={`${t('dashboardPage.adminApp')} ${adminAppMemberCount}`}
          size="small"
        />
      </>
    );
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 180,
        p: 2,
      }}
    >
      <Typography variant="subtitle1">
        {t('dashboardPage.activeMembers')}
      </Typography>
      <Box
        flexGrow={1}
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {renderMemberCount()}
        <LoaderWithOverlay loading={state.isLoading} size="small" />
      </Box>
      <StyledLink to={membersPath} />
    </Paper>
  );
};

export default ActiveMembers;
