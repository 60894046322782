import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MerchantPreview } from 'domains/merchant/components';
import {
  ArrowRightIcon,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  LoaderWithOverlay,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Merchant,
  MerchantDetails,
  MerchantStatus,
  SplitMerchantDetails,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const hasValidMatchingValue = (str: string) =>
  !!str.replace(/[\n\s,]+/g, '').length;

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (merchant: MerchantDetails) => void;
  merchant: Merchant | null;
}

interface State {
  isLoading: boolean;
  step: 1 | 2;
  matchingStrings: string;
  splitMerchantDetails: SplitMerchantDetails | null;
}

const MerchantSplitDialog = ({ onSuccess, merchant, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    isLoading: false,
    step: 1,
    matchingStrings: '',
    splitMerchantDetails: null,
  });

  const getSplitMerchantDetails = async () => {
    if (state.isLoading) return;
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const splitMerchantDetails = await api.getSplitMerchantDetails(
        merchant!.id,
        state.matchingStrings
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        step: 2,
        splitMerchantDetails,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const onSplitMerchant = async () => {
    if (state.isLoading) return;
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const merchantDetails = await api.splitMerchant(
        merchant!.id,
        state.matchingStrings
      );
      if (!mounted.current) return;
      onSuccess(merchantDetails);
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(t('int.merchantSplitDialog.splitError'), {
        variant: 'error',
      });
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth={state.step === 2 ? 'md' : 'xs'}>
      <DialogTitle>
        {state.step === 1
          ? t('int.merchantSplitDialog.step1.title')
          : t('int.merchantSplitDialog.step2.title')}
      </DialogTitle>
      {state.step === 1 && (
        <>
          <DialogContent>
            <Box mb={4}>
              <Typography variant="body2">
                {t('int.merchantSplitDialog.step1.description')}
              </Typography>
            </Box>
            <TextField
              label={t('int.merchantSplitDialog.step1.strings')}
              placeholder={t('int.merchantSplitDialog.step1.stringsEg')}
              value={state.matchingStrings}
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  matchingStrings: e.target.value,
                }));
              }}
              multiline
              rows={3}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={props.onClose}>
              {t('common.button.cancel')}
            </Button>
            <Button
              onClick={getSplitMerchantDetails}
              disabled={!hasValidMatchingValue(state.matchingStrings)}
            >
              {t('int.merchantSplitDialog.step1.next')}
            </Button>
          </DialogActions>
        </>
      )}
      {state.step === 2 && (
        <>
          <DialogContent>
            <Box mb={2}>
              <Typography variant="body2">
                <Trans
                  i18nKey="int.merchantSplitDialog.step2.description"
                  components={{ br: <br /> }}
                  values={{
                    count: state.splitMerchantDetails!.splitMerchant
                      .transactionsCount,
                  }}
                />
              </Typography>
            </Box>
            <Box display="flex" flexDirection={['column', 'column', 'row']}>
              <Box flex={1} overflow="hidden">
                <Box my={2}>
                  <Typography variant="subtitle2">
                    {t('int.merchantSplitDialog.step2.now')}
                  </Typography>
                </Box>
                <MerchantPreview
                  logoPath={merchant!.logoPath}
                  displayName={merchant!.displayName}
                  status={merchant!.status}
                  transactionsCount={merchant!.transactionsCount}
                  transactionsVolume={merchant!.transactionsVolume}
                  merchant={merchant!}
                />
              </Box>
              <Box
                px={2}
                display={['none', 'none', 'flex']}
                alignItems="center"
                justifyContent="center"
              >
                <ArrowRightIcon />
              </Box>
              <Box flex={1} overflow="hidden">
                <Box my={2}>
                  <Typography variant="subtitle2">
                    {t('int.merchantSplitDialog.step2.after')}
                  </Typography>
                </Box>
                <MerchantPreview
                  logoPath={merchant!.logoPath}
                  displayName={merchant!.displayName}
                  status={merchant!.status}
                  transactionsCount={
                    state.splitMerchantDetails!.mainMerchant.transactionsCount
                  }
                  transactionsVolume={
                    state.splitMerchantDetails!.mainMerchant.transactionsVolume
                  }
                />
                <Divider sx={{ my: 4 }} />
                <MerchantPreview
                  logoPath={null}
                  displayName={merchant!.displayName}
                  status={MerchantStatus.draft}
                  transactionsCount={
                    state.splitMerchantDetails!.splitMerchant.transactionsCount
                  }
                  transactionsVolume={
                    state.splitMerchantDetails!.splitMerchant.transactionsVolume
                  }
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box flexGrow="1">
              <Button
                variant="text"
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    step: 1,
                    splitMerchantDetails: null,
                  }))
                }
              >
                {t('int.merchantSplitDialog.step2.back')}
              </Button>
            </Box>
            <Button variant="text" onClick={props.onClose}>
              {t('common.button.cancel')}
            </Button>
            <Button disabled={state.isLoading} onClick={onSplitMerchant}>
              {t('int.merchantSplitDialog.step2.submit')}
            </Button>
          </DialogActions>
        </>
      )}
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(MerchantSplitDialog);
