import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import {
  NONE_VALUE,
  TransactionReviewStatus,
  transactionReviewStatuses,
} from 'services/constants';

type Value = TransactionReviewStatus | typeof NONE_VALUE | '';

interface Props {
  value: Value;
  onChange: (value: Value) => void;
}

const ReviewFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();
  const visibleStatuses = featureModules.MANAGER_TX_REVIEWS
    ? transactionReviewStatuses
    : [TransactionReviewStatus.flagged, TransactionReviewStatus.resolved];

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-review"
        id="panel-review-header"
      >
        <Typography>{t('filters.review')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<Value>
            size="medium"
            displayEmpty
            value={value}
            onChange={(e) => onChange(e.target.value as Value)}
            renderValue={(selected) => {
              if (!selected)
                return (
                  <Typography color="text.disabled">
                    {t('filters.review')}
                  </Typography>
                );
              return selected === NONE_VALUE
                ? t(`common.none`)
                : t(`transactionReviewStatus.${selected}`);
            }}
          >
            <MenuItem value="">
              <Typography color="text.disabled">
                {t('filters.review')}
              </Typography>
            </MenuItem>
            {visibleStatuses.map((status) => (
              <MenuItem key={status} value={status}>
                {t(`transactionReviewStatus.${status}`)}
              </MenuItem>
            ))}
            <MenuItem value={NONE_VALUE}>{t(`common.none`)}</MenuItem>
          </Select>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default ReviewFilter;
