import { FormControl, FormHelperText, MenuItem, Select } from 'elements';
import { SettlementGroup } from 'services/constants';

interface Props {
  value: string;
  onChange: (value: string) => void;
  options: SettlementGroup[];
}

const AccountIbanFilterSelect = ({ value, onChange, options }: Props) => {
  const selectedOption = options.find(({ id }) => id === value);

  return (
    <FormControl sx={{ position: 'relative', width: 250, mt: 2, ml: 'auto' }}>
      <Select<string> value={value} onChange={(e) => onChange(e.target.value)}>
        {options.map(({ id, accountGroup, currency }) => (
          <MenuItem value={id} key={id}>
            {`${accountGroup} (${currency})`}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText sx={{ position: 'absolute', top: '100%' }}>
        {selectedOption?.settlementBankAccounts.programSettlementAccount.iban}
      </FormHelperText>
    </FormControl>
  );
};

export default AccountIbanFilterSelect;
