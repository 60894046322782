import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FadersHorizontalIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuContainer,
  MenuItem,
  ShieldCheckIcon,
  ShieldWarningIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Transaction,
  TransactionSimpleType,
  TransactionStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  transaction: Transaction;
  onUpdate: () => void;
}

const TransactionDetailsMenu = ({ transaction, onUpdate }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const [isLoading, setIsLoading] = useState(false);

  if (
    !canUser('transaction-dispute:change') ||
    [TransactionStatus.declined, TransactionStatus.reversed].includes(
      transaction.status
    ) ||
    transaction.type === TransactionSimpleType.statusInquiry
  )
    return null;

  const toggleDispute = async (disputed: boolean) => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await api.updateTransactionDispute(transaction.transactionId, disputed);
      if (!mounted.current) return;
      onUpdate();
      setIsLoading(false);
      enqueueSnackbar(
        disputed
          ? t('transactionDetailsMenu.disputeSuccessfullyAdded')
          : t('transactionDetailsMenu.disputeSuccessfullyRemoved'),
        { variant: 'success' }
      );
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <MenuContainer
      button={
        <IconButton>
          <FadersHorizontalIcon />
        </IconButton>
      }
    >
      <MenuItem
        onClick={() => toggleDispute(!transaction.disputed)}
        disabled={isLoading}
      >
        <ListItemIcon>
          {transaction.disputed ? <ShieldCheckIcon /> : <ShieldWarningIcon />}
        </ListItemIcon>
        <ListItemText
          primary={
            transaction.disputed
              ? t('transactionDetailsMenu.disputeRemove')
              : t('transactionDetailsMenu.disputeAdd')
          }
        />
      </MenuItem>
    </MenuContainer>
  );
};

export default TransactionDetailsMenu;
