import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { CardIcon } from 'domains/card/components';
import {
  Box,
  Button,
  EditButton,
  LoaderWithOverlay,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import {
  CardPremiumProductType,
  PremiumCardFeeTier,
  PremiumCardFeeTierList,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  CreatePremiumCardFeeTierDialog,
  UpdatePremiumCardFeeTierDialog,
} from './CreateUpdatePremiumCardFeeTierDialog';

interface State {
  data: PremiumCardFeeTierList | null;
  isLoading: boolean;
  isCreateDialogOpen: boolean;
  tierToUpdate: PremiumCardFeeTier | null;
}

const PremiumCardFeeSection = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const {
    state: { organization, cardConfigSettings, subscriptionPlan },
  } = useGlobalState();
  const premiumCardConfigSetting = cardConfigSettings.find(
    (item) => item.premiumProductType === CardPremiumProductType.visaInfinite
  );
  const [state, setState] = useState<State>({
    data: null,
    isLoading: true,
    isCreateDialogOpen: false,
    tierToUpdate: null,
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const data = await api.getPremiumCardFeeTiers(organization!.id);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, data, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    if (premiumCardConfigSetting) {
      getData();
    }
  }, [premiumCardConfigSetting?.cardConfig, subscriptionPlan?.type]);

  if (!premiumCardConfigSetting) return null;

  return (
    <>
      <Box
        p={3}
        sx={(theme) => ({
          position: 'relative',
          minHeight: 160,
          borderTop: `1px solid ${theme.palette.divider}`,
        })}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={3}
        >
          <Typography variant="h6">
            {t('int.subscriptionSettingsPage.premiumCardFeeSection.title', {
              cardName: t('cardNames.PLIANT_BLACK'),
            })}
          </Typography>

          {state.data && canUser('subscription-plan:update') && (
            <Button
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  isCreateDialogOpen: true,
                }))
              }
              variant="outlined"
            >
              {t('int.subscriptionSettingsPage.premiumCardFeeSection.addTier')}
            </Button>
          )}
        </Box>

        {state.data && (
          <>
            <Paper variant="outlined">
              <Table>
                <TableBody>
                  {state.data.tiers.map((tier, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '.MuiTableCell-root': { width: '25%' },
                        '&:last-of-type .MuiTableCell-root': { border: 'none' },
                      }}
                    >
                      <TableCell sx={{ pl: 4 }}>
                        <Box display="flex" alignItems="center">
                          <CardIcon
                            cardDesignId={
                              premiumCardConfigSetting.cardDesignIds[0]
                            }
                            sx={{ mr: 2 }}
                          />
                          <span>
                            {t(
                              'int.subscriptionSettingsPage.premiumCardFeeSection.tier',
                              {
                                number: index + 1,
                              }
                            )}
                          </span>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <FormatMoney value={tier.fee} />
                      </TableCell>
                      <TableCell>
                        <span>{tier.takenCardsCount}</span>/
                        <span>{tier.totalCardsCount || '∞'}</span>
                      </TableCell>
                      {canUser('subscription-plan:update') && (
                        <TableCell align="right" sx={{ pr: 4 }}>
                          <EditButton
                            onClick={() =>
                              setState((prevState) => ({
                                ...prevState,
                                tierToUpdate: tier,
                              }))
                            }
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            {state.data.lastEditAt && state.data.lastEditUserName && (
              <Typography
                variant="caption"
                component="div"
                mt={1}
                color="text.secondary"
              >
                {t('int.common.lastEditedBy', {
                  name: state.data.lastEditUserName,
                  time: moment(state.data.lastEditAt).format(
                    'DD MMM YYYY HH:mm'
                  ),
                })}
              </Typography>
            )}
          </>
        )}

        {!state.isLoading && !state.data && (
          <Typography variant="caption" color="error.main">
            {t('errors.loadData')}
          </Typography>
        )}

        <LoaderWithOverlay loading={state.isLoading} size="small" />
      </Box>

      <CreatePremiumCardFeeTierDialog
        open={state.isCreateDialogOpen}
        onClose={() =>
          setState((prevState) => ({ ...prevState, isCreateDialogOpen: false }))
        }
        onSuccess={() => {
          getData();
          setState((prevState) => ({
            ...prevState,
            isCreateDialogOpen: false,
          }));
        }}
      />

      <UpdatePremiumCardFeeTierDialog
        open={!!state.tierToUpdate}
        tier={state.tierToUpdate}
        onClose={() =>
          setState((prevState) => ({ ...prevState, tierToUpdate: null }))
        }
        onSuccess={() => {
          getData();
          setState((prevState) => ({ ...prevState, tierToUpdate: null }));
        }}
      />
    </>
  );
};

export default PremiumCardFeeSection;
