import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { UpdateFxFeePercentageDialog } from 'domains/terms/dialogs';
import { Box, EditButton, Paper, Typography } from 'elements';
import { SubscriptionPlan } from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props {
  subscriptionPlan: SubscriptionPlan;
}

const FxFeeSection = ({ subscriptionPlan }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Paper
        variant="outlined"
        sx={{ display: 'flex', alignItems: 'center', p: 2 }}
      >
        <Typography sx={{ width: '33%', py: '10px' }}>
          {t('int.subscriptionSettingsPage.fxFeeSection.title')}
        </Typography>

        {subscriptionPlan.upcomingFxFeePercentage !== null ? (
          <>
            <Box width="20%">
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {t('int.subscriptionSettingsPage.fxFeeSection.current')}
              </Typography>
              <Typography>
                <FormatPercent value={subscriptionPlan.fxFeePercentage} />
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {t('int.subscriptionSettingsPage.fxFeeSection.upcoming')}
              </Typography>
              <Typography>
                <FormatPercent
                  value={subscriptionPlan.upcomingFxFeePercentage}
                />
              </Typography>
            </Box>
          </>
        ) : (
          <Typography sx={{ flexGrow: 1 }}>
            <FormatPercent value={subscriptionPlan.fxFeePercentage} />
          </Typography>
        )}

        {canUser('subscription-plan:update') && (
          <EditButton onClick={() => setIsDialogOpen(true)} />
        )}
      </Paper>

      <UpdateFxFeePercentageDialog
        open={isDialogOpen}
        subscriptionPlan={subscriptionPlan}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default FxFeeSection;
