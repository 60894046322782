import React, { useState } from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { ReceiptDropzone } from 'domains/transaction/components';
import {
  Box,
  CaretDownIcon,
  CaretUpIcon,
  Divider,
  DownloadSimpleIcon,
  IconButton,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  ReceiptAttentionIcon,
  TrashIcon,
  Typography,
} from 'elements';
import useSnackbar from 'hooks/useSnackbar';
import {
  NetworkErrorCode,
  Receipt,
  ReceiptStatus,
  Transaction,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import { DEFAULT_SCALE } from './index';
import { HeaderIconButton } from './style';

export const SCALE_STEP = 0.15;

interface State {
  isUploadInProgress: boolean;
  isReceiptNotNeededChanging: boolean;
  receiptNeeded: boolean;
  receiptNumber: string;
}

interface Props {
  isLoading: boolean;
  transaction: Transaction;
  selectedReceipt: Receipt;
  page: number;
  numPages: number;
  scale: number;
  onChange: (newPage?: number, newScale?: number) => void;
  downloadOriginal: () => Promise<void>;
  onDelete: () => void;
  onReject: () => void;
  canEditTransaction: boolean;
  onReceiptReplaced?: (
    transaction: Transaction,
    oldReceipt: Receipt,
    replacedReceipt: Receipt
  ) => void;
}

const PreviewDialogHeader = ({
  isLoading,
  transaction,
  selectedReceipt,
  page,
  numPages,
  scale,
  onChange,
  downloadOriginal,
  onDelete,
  onReject,
  canEditTransaction,
  onReceiptReplaced,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const [state, setState] = useState<State>({
    isUploadInProgress: false,
    isReceiptNotNeededChanging: false,
    receiptNeeded: transaction.receiptNeeded,
    receiptNumber: transaction.receiptNumber,
  });

  const scalePercent = Math.round((scale - DEFAULT_SCALE + 1) * 100);

  return (
    <>
      <Box display="flex" alignItems="center" height="60px">
        <Box width="40%" pr={1} pl={8}>
          <Typography noWrap>{selectedReceipt?.fileName}</Typography>
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            noWrap
          >
            <Trans
              i18nKey="previewDialog.uploadedBy"
              values={{
                memberFullName: selectedReceipt?.memberFullName,
                date: selectedReceipt?.createdAt
                  ? moment(selectedReceipt.createdAt).format(
                      'D MMM YYYY, HH:mm'
                    )
                  : '',
              }}
            />
          </Typography>
        </Box>
        <Box width="20%" whiteSpace="nowrap" textAlign="center">
          <Box
            position="relative"
            display="inline-flex"
            justifyContent="center"
          >
            {numPages > 1 && (
              <HeaderIconButton
                sx={{ left: '-45px' }}
                disabled={page <= 1 || isLoading}
                onClick={() => onChange(page - 1)}
                data-test-id="preview-prev-page"
              >
                <CaretUpIcon fontSize="small" />
              </HeaderIconButton>
            )}
            <Typography variant="body2" noWrap>
              <Trans
                i18nKey="previewDialog.page"
                values={{ pagination: `${page} / ${numPages}` }}
              />
            </Typography>
            {numPages > 1 && (
              <HeaderIconButton
                sx={{ right: '-45px' }}
                disabled={page >= numPages || isLoading}
                onClick={() => onChange(page + 1)}
                data-test-id="preview-next-page"
              >
                <CaretDownIcon fontSize="small" />
              </HeaderIconButton>
            )}
          </Box>
        </Box>
        <Box width="20%" textAlign="center">
          <Box
            position="relative"
            display="inline-flex"
            justifyContent="center"
            width="48px"
          >
            <HeaderIconButton
              sx={{ left: '-40px' }}
              disabled={scalePercent <= 40 || isLoading}
              onClick={() =>
                onChange(undefined, parseFloat((scale - SCALE_STEP).toFixed(2)))
              }
              data-test-id="preview-zoom-out"
            >
              <MagnifyingGlassMinusIcon fontSize="small" />
            </HeaderIconButton>
            <Typography data-test-id="preview-zoom-percent" noWrap>
              {scalePercent}%
            </Typography>
            <HeaderIconButton
              sx={{ right: '-40px' }}
              disabled={scalePercent >= 190 || isLoading}
              onClick={() =>
                onChange(undefined, parseFloat((scale + SCALE_STEP).toFixed(2)))
              }
              data-test-id="preview-zoom-in"
            >
              <MagnifyingGlassPlusIcon fontSize="small" />
            </HeaderIconButton>
          </Box>
        </Box>
        <Box width="20%" textAlign="right" pr="16px">
          <IconButton
            onClick={downloadOriginal}
            size="small"
            data-test-id="preview-download"
          >
            <DownloadSimpleIcon fontSize="small" />
          </IconButton>
          {canEditTransaction && canUser('receipt:delete') && (
            <IconButton
              onClick={onDelete}
              size="small"
              sx={{ ml: 1.5 }}
              data-test-id="preview-delete"
            >
              <TrashIcon fontSize="small" />
            </IconButton>
          )}
          {canEditTransaction &&
            selectedReceipt &&
            canUser('receipt:reject', selectedReceipt) && (
              <IconButton
                onClick={onReject}
                size="small"
                sx={{ ml: 1.5 }}
                data-test-id="reject-receipt"
              >
                <ReceiptAttentionIcon fontSize="small" color="error" />
              </IconButton>
            )}
        </Box>
      </Box>
      <Divider />
      {canEditTransaction &&
        selectedReceipt?.status === ReceiptStatus.rejected && (
          <Box width="100%" p={2} bgcolor={(theme) => theme.palette.error.main}>
            <ReceiptDropzone
              isInPreviewDialog
              transaction={transaction}
              showMissingReceiptMessage
              receiptId={selectedReceipt.receiptId}
              onSuccess={(receipt: Receipt) => {
                setState((prevState) => ({
                  ...prevState,
                  isUploadInProgress: false,
                }));
                onReceiptReplaced &&
                  onReceiptReplaced(transaction, selectedReceipt, receipt);
              }}
              onUploadStart={() => {
                setState((prevState) => ({
                  ...prevState,
                  isUploadInProgress: true,
                }));
              }}
              onFail={(error) => {
                const errorCode = getNetworkErrorCode(error);
                if (
                  errorCode === NetworkErrorCode.receiptsNotNeeded ||
                  errorCode === NetworkErrorCode.receiptUploadNotEnabled
                ) {
                  enqueueSnackbar(getGenericErrorMsg(error), {
                    variant: 'error',
                  });
                  setState((prevState) => ({
                    ...prevState,
                    isUploadInProgress: false,
                    receiptNeeded:
                      errorCode === NetworkErrorCode.receiptsNotNeeded
                        ? false
                        : prevState.receiptNeeded,
                  }));
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    isUploadInProgress: false,
                  }));
                }
              }}
              disabled={
                !canUser('receipt:upload') || state.isReceiptNotNeededChanging
              }
            />
          </Box>
        )}
    </>
  );
};

export default PreviewDialogHeader;
