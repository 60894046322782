import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'elements';

interface Props {
  updatedAt?: string | null;
  updatedBy?: string | null;
  value: React.ReactNode;
  variant?: 'body1' | 'body2';
}

const DataItemCell = ({
  updatedAt,
  updatedBy,
  value,
  variant = 'body2',
}: Props) => {
  const { t } = useTranslation();
  const [showMetaInfo, setShowMetaInfo] = useState(false);

  return (
    <Grid item minWidth={128} marginRight={4}>
      <Typography
        component="p"
        variant={variant}
        onMouseEnter={() => setShowMetaInfo(true)}
        onMouseLeave={() => setShowMetaInfo(false)}
      >
        {value}
      </Typography>

      {showMetaInfo && (
        <Typography
          position="absolute"
          right="32px"
          bottom="0px"
          color="textSecondary"
          lineHeight="1.2"
          marginLeft="auto"
          variant="caption"
          whiteSpace="pre"
        >
          {updatedAt && updatedBy
            ? t('int.creditAndCompliancePage.dataItemRow.lastChanged', {
                updatedAt: moment(updatedAt).format('DD.MM.YYYY HH:mm'),
                updatedBy,
              })
            : '-'}
        </Typography>
      )}
    </Grid>
  );
};

export default DataItemCell;
