import { useTranslation } from 'react-i18next';
import {
  CheckCircleIcon,
  FlagPennantIcon,
  ThumbsUpIcon,
  Tooltip,
  WarningDiamondIcon,
} from 'elements';
import { TransactionReviewStatus } from 'services/constants';

interface Props {
  status: TransactionReviewStatus | null;
}

const TransactionReviewStatusIcon = ({ status }: Props) => {
  const { t } = useTranslation();

  if (!status) return null;

  const renderIcon = () => {
    if (status === TransactionReviewStatus.approved)
      return <ThumbsUpIcon color="action" />;
    if (status === TransactionReviewStatus.flagged)
      return <FlagPennantIcon color="error" />;
    if (status === TransactionReviewStatus.resolved)
      return <CheckCircleIcon color="action" />;
    return <WarningDiamondIcon color="error" />;
  };

  return (
    <Tooltip title={t(`transactionReviewStatus.${status}`)}>
      {renderIcon()}
    </Tooltip>
  );
};

export default TransactionReviewStatusIcon;
