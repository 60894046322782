import React, { ChangeEvent, useRef, useState } from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import logoIdNow from 'assets/images/logo-idnow.png';
import { useGlobalState } from 'context/GlobalState';
import { Button } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OrganizationRepresentative } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

const readFileAsText = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === 'string') resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsText(file);
  });

const IdNowLogo = styled('div')`
  height: 20px;
  width: 80px;
  background: url('${logoIdNow}') center no-repeat;
  background-size: contain;
`;

interface Props {
  onError: () => void;
  onFileSelectOpen: () => void;
  onSuccess: (updatedRepresentative: OrganizationRepresentative) => void;
  representativeId: string;
}

const IdNowXmlUploadButton = ({
  onError,
  onFileSelectOpen,
  onSuccess,
  representativeId,
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    state: { organization },
  } = useGlobalState();

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    event.currentTarget.value = '';
    if (!file) return;

    onFileSelectOpen();
    setIsLoading(true);

    try {
      const fileContent = await readFileAsText(file);
      const response = await api.uploadOrganizationRepresentativeIdNowXml(
        organization!.id,
        representativeId,
        fileContent
      );
      onSuccess(response);
      enqueueSnackbar(
        t('int.organizationRepresentativeSection.xmlImportedSuccess'),
        {
          variant: 'success',
        }
      );
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(
        t('int.organizationRepresentativeSection.xmlImportedError'),
        {
          variant: 'error',
        }
      );
      logError(error);
      onError();
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Button
        onClick={() => inputRef.current!.click()}
        disabled={isLoading}
        variant="outlined"
        size="small"
      >
        <IdNowLogo />
      </Button>
      <input
        accept=".xml"
        onChange={onChange}
        ref={inputRef}
        style={{ display: 'none', visibility: 'hidden' }}
        type="file"
      />
    </div>
  );
};

export default IdNowXmlUploadButton;
