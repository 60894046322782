import styled from 'styled-components';

export const PartnerCardName = styled.div`
  position: absolute;
  top: 106px;
  right: 24px;
  left: 24px;
  font-family: 'Pangea', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  ${({ theme }) => theme.mixins.overflowEllipsis}
`;

export const CardName = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  display: -webkit-box;
  max-width: 104px;
  max-height: 60px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: 'Pangea', sans-serif;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  word-break: break-word;
`;

export const CardholderName = styled.div`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

export const CardNumber = styled.div`
  margin: 0 0 12px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const PropName = styled.div`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  font-weight: 500;
`;

export const PropValue = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;
