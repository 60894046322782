import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import { CardHistoryCommonEntry } from 'services/constants';
import { formatMoney } from 'services/utils';
import { Nowrap } from './style';

interface Props {
  entry: CardHistoryCommonEntry;
}

const CardLimitChangeRequestEventTitle = ({ entry }: Props) => {
  const { t, i18n } = useTranslation();
  const currencyDisplay = useCurrencyDisplayMode();

  const requestedLimit = entry.requestedLimit || entry.limit;
  const requestedLimitFrequency =
    entry.requestedLimitRenewFrequency || entry.limitRenewFrequency;

  return (
    <Trans
      i18nKey={`cardHistory.titleByType.${entry.type}`}
      values={{
        limitWithFrequency: t(
          `cardLimitFrequency.limitWithFrequencies.${entry.limitRenewFrequency}`,
          {
            limit: formatMoney(entry.limit, i18n.language, { currencyDisplay }),
          }
        ),
        requestedLimitWithFrequency: t(
          `cardLimitFrequency.limitWithFrequencies.${requestedLimitFrequency}`,
          {
            limit: formatMoney(requestedLimit, i18n.language, {
              currencyDisplay,
            }),
          }
        ),
      }}
      components={{ nowrap: <Nowrap /> }}
    />
  );
};

export default CardLimitChangeRequestEventTitle;
