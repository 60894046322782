import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddressDetails from 'components/AddressDetails';
import { useGlobalState } from 'context/GlobalState';
import { DeliveryAddressChangeDialog } from 'domains/organization/dialogs';
import { Box, Grid, LabeledValue, QuestionIcon, Tooltip } from 'elements';
import { Organization } from 'services/constants';
import { useCanUser } from 'services/rbac';

const DeliveryAddressFormGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onUpdated = (organization: Organization) => {
    dispatch({
      type: 'SET_ORGANIZATION_DATA',
      payload: { organization },
    });
    setIsDialogOpen(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <LabeledValue
          label={
            <Box display="flex" alignItems="center">
              {t('settingsOrganizationPage.deliveryAddress')}
              <Tooltip title={t('tooltips.deliveryAddress')}>
                <QuestionIcon fontSize="small" sx={{ ml: 1 }} />
              </Tooltip>
            </Box>
          }
          value={
            organization!.deliveryAddress ? (
              <AddressDetails address={organization!.deliveryAddress} />
            ) : (
              '-'
            )
          }
          onEdit={
            canUser('org-delivery-address:change')
              ? () => setIsDialogOpen(true)
              : undefined
          }
        />
      </Grid>

      <DeliveryAddressChangeDialog
        open={isDialogOpen}
        label={t('settingsOrganizationPage.deliveryAddress')}
        organizationId={organization!.id}
        deliveryAddress={organization!.deliveryAddress}
        billingAddress={organization!.billingAddress}
        onSuccess={onUpdated}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default DeliveryAddressFormGroup;
