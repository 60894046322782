import moment from 'moment';

const isValidMandateReferenceId = (mandateReferenceID: string): boolean => {
  // Valid format: “PLI” + Debtor billing account number + “B” + “YYYYMMDDTTTTT”
  const match = /^PLI\d+B(?<date>\d\d\d\d\d\d\d\d)\d+$/.exec(
    mandateReferenceID
  );

  if (match) {
    const { date } = match.groups as {
      date: string;
    };

    if (moment(date, 'YYYYMMDD', true).isValid()) {
      return true;
    }
  }

  return false;
};

export { isValidMandateReferenceId };
