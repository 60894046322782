import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { CardBenefitsContent } from 'domains/reward/components';
import { InsuranceDetailsDialog } from 'domains/reward/dialogs';
import { Typography } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { ContentContainer } from 'layout';
import { CardNewType, CardPremiumProductType } from 'services/constants';
import useCardBenefits from './useCardBenefits';

const CardBenefitsSubpage = () => {
  const { t } = useTranslation();
  const [
    isInsuranceDetailsDialogOpen,
    setIsInsuranceDetailsDialogOpen,
  ] = useState(false);
  const { commonBenefits, premiumBenefits } = useCardBenefits({
    openInsuranceDetailsDialog: () => setIsInsuranceDetailsDialogOpen(true),
  });
  const {
    state: { cardConfigSettings },
  } = useGlobalState();
  const {
    virtualCardConfigSetting,
    physicalCardConfigSetting,
    physicalPremiumCardConfigSetting,
    hasVirtual,
    hasPhysical,
    hasPhysicalPremium,
  } = useMemo(() => {
    const issuable = cardConfigSettings.filter((item) => item.canBeIssued);
    const virtualCardConfigSetting = issuable.find(
      (item) => item.cardType === CardNewType.virtual && item.maxUsage === null
    );
    const physicalCardConfigSetting = issuable.find(
      (item) =>
        item.cardType === CardNewType.physical &&
        item.premiumProductType === null
    );
    const physicalPremiumCardConfigSetting = issuable.find(
      (item) =>
        item.cardType === CardNewType.physical &&
        item.premiumProductType === CardPremiumProductType.visaInfinite
    );
    const hasVirtual = !!virtualCardConfigSetting;
    const hasPhysical = !!physicalCardConfigSetting;
    const hasPhysicalPremium = !!physicalPremiumCardConfigSetting;

    return {
      virtualCardConfigSetting,
      physicalCardConfigSetting,
      physicalPremiumCardConfigSetting,
      hasVirtual,
      hasPhysical,
      hasPhysicalPremium,
    };
  }, [cardConfigSettings]);

  return (
    <>
      <ContentContainer>
        <Typography variant="h5" mb={1}>
          {t('cardBenefitsSubpage.title')}
        </Typography>
        <Typography variant="body2" mb={5}>
          {t('cardBenefitsSubpage.subtitle')}
        </Typography>
      </ContentContainer>
      <CardBenefitsContent
        commonBenefits={commonBenefits}
        premiumBenefits={premiumBenefits}
        virtualCardConfigSetting={virtualCardConfigSetting}
        physicalCardConfigSetting={physicalCardConfigSetting}
        physicalPremiumCardConfigSetting={physicalPremiumCardConfigSetting}
        hasVirtual={hasVirtual}
        hasPhysical={hasPhysical}
        hasPhysicalPremium={hasPhysicalPremium}
      />
      <InsuranceDetailsDialog
        open={isInsuranceDetailsDialogOpen}
        onClose={() => setIsInsuranceDetailsDialogOpen(false)}
      />
    </>
  );
};

export default withPageConfig(CardBenefitsSubpage, {
  permission: 'card-benefits-subpage:visit',
});
