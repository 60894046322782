import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import CodatSyncSetupDialog from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog';
import {
  ArrowsClockwiseIcon,
  Box,
  Button,
  IconButton,
  QuestionIcon,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from 'elements';
import useMounted from 'hooks/useMounted';
import {
  AccountingSystem,
  CodatMappingOptionsSummary,
  MappingOptionsSummary,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import SyncOptionStatus from './SyncOptionStatus';

export type OptionKey =
  | 'accounts'
  | 'taxRates'
  | 'suppliers'
  | 'trackingCategories';

export const checkMapped = (item: MappingOptionsSummary | undefined) =>
  Number(item?.selectedItems) > 0 || Number(item?.unselectedItems) > 0;

interface State {
  isLoading: boolean;
  isUpdatingSettings: boolean;
  isError: boolean;
  codatMappingOptionsSummary: CodatMappingOptionsSummary | null;
  isSetupDialogOpen: boolean;
  fxConversion: boolean;
  draftState: boolean;
}

const SyncOptionsGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    isUpdatingSettings: false,
    isError: false,
    codatMappingOptionsSummary: null,
    isSetupDialogOpen: false,
    fxConversion: false,
    draftState: false,
  });

  const getData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isError: false,
        isLoading: true,
      }));
      const [
        codatMappingOptionsSummary,
        { fxConversion, draftState },
      ] = await Promise.all([
        api.getCodatMappingOptionsSummary(organization!.id),
        api.getCodatExpenseSettings(organization!.id),
      ]);

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        codatMappingOptionsSummary,
        fxConversion,
        draftState,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isError: true,
        isLoading: false,
      }));
      logError(error);
    }
  };

  const updateSyncSummary = async () => {
    if (!canUser('codat-sync:change')) return;
    try {
      setState((prevState) => ({
        ...prevState,
        isError: false,
        isLoading: true,
      }));
      const codatMappingOptionsSummary = await api.getSyncedCodatMappingOptionsSummary(
        organization!.id
      );

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        codatMappingOptionsSummary,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isError: true,
        isLoading: false,
      }));
      logError(error);
    }
  };

  const updateExpenseSettings = async (
    key: 'fxConversion' | 'draftState',
    value: boolean
  ) => {
    if (!canUser('codat-sync:change')) return;
    try {
      setState((prevState) => ({
        ...prevState,
        isError: false,
        fxConversion: prevState.fxConversion,
        draftState: prevState.draftState,
        [key]: value,
        isUpdatingSettings: true,
      }));

      const { fxConversion, draftState } = await api.updateCodatExpenseSettings(
        organization!.id,
        {
          id: organization!.id,
          accountingSystem: accountingSettings!.accountingSystem!,
          fxConversion: state.fxConversion,
          draftState: state.draftState,
          [key]: value,
        }
      );

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        fxConversion,
        draftState,
        isUpdatingSettings: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        [key]: !value,
        isUpdatingSettings: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const isPristineNonsyncedCase =
    !!state.codatMappingOptionsSummary &&
    !checkMapped(state.codatMappingOptionsSummary?.accounts) &&
    !checkMapped(state.codatMappingOptionsSummary?.taxRates) &&
    !checkMapped(state.codatMappingOptionsSummary?.suppliers) &&
    !checkMapped(state.codatMappingOptionsSummary?.trackingCategories);

  return (
    <>
      <Table sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              {t('accountingSystemSubPage.syncOptionsGroup.title', {
                name: accountingSettings!.accountingSystemName,
              })}
            </TableCell>
            <TableCell>
              {!state.isLoading && !isPristineNonsyncedCase && (
                <>
                  {state.codatMappingOptionsSummary?.lastSynced &&
                    t('accountingSystemSubPage.syncOptionsGroup.lastSync', {
                      date: moment(
                        state.codatMappingOptionsSummary.lastSynced
                      ).format('DD MMM YYYY, HH:mm'),
                    })}
                  <IconButton
                    disabled={!canUser('codat-sync:change')}
                    onClick={updateSyncSummary}
                    size="small"
                  >
                    <ArrowsClockwiseIcon fontSize="small" />
                  </IconButton>
                </>
              )}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              {t('accountingSystemSubPage.syncOptionsGroup.glAccountsLabel')}
            </TableCell>
            <TableCell>
              <SyncOptionStatus
                isLoading={state.isLoading}
                isError={state.isError}
                name="accounts"
                summary={state.codatMappingOptionsSummary}
                isPristineNonsyncedCase={isPristineNonsyncedCase}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              {t('accountingSystemSubPage.syncOptionsGroup.vatRatesLabel')}
            </TableCell>
            <TableCell>
              <SyncOptionStatus
                isLoading={state.isLoading}
                isError={state.isError}
                name="taxRates"
                summary={state.codatMappingOptionsSummary}
                isPristineNonsyncedCase={isPristineNonsyncedCase}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              {t('accountingSystemSubPage.syncOptionsGroup.suppliersLabel')}
            </TableCell>
            <TableCell>
              <SyncOptionStatus
                isLoading={state.isLoading}
                isError={state.isError}
                name="suppliers"
                summary={state.codatMappingOptionsSummary}
                isPristineNonsyncedCase={isPristineNonsyncedCase}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              {t(
                'accountingSystemSubPage.syncOptionsGroup.trackingCategoriesLabel'
              )}
            </TableCell>
            <TableCell>
              <SyncOptionStatus
                isLoading={state.isLoading}
                isError={state.isError}
                name="trackingCategories"
                summary={state.codatMappingOptionsSummary}
                isPristineNonsyncedCase={isPristineNonsyncedCase}
              />
            </TableCell>
          </TableRow>

          {accountingSettings!.accountingSystem ===
            AccountingSystem.microsoftDynamics365BusinessCentral && (
            <TableRow>
              <TableCell>
                <Box display="flex" alignItems="center">
                  {t('accountingSystemSubPage.syncOptionsGroup.draftState')}
                  <Tooltip
                    sx={{ ml: 1 }}
                    title={t(
                      'accountingSystemSubPage.syncOptionsGroup.draftStateTooltip'
                    )}
                  >
                    <QuestionIcon fontSize="small" />
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell>
                <Switch
                  sx={{ ml: -1.5 }}
                  checked={state.draftState}
                  disabled={
                    !canUser('codat-sync:change') ||
                    state.isLoading ||
                    state.isUpdatingSettings
                  }
                  onChange={(e, checked: boolean) =>
                    updateExpenseSettings('draftState', checked)
                  }
                />
              </TableCell>
            </TableRow>
          )}

          <TableRow>
            <TableCell>
              <Box display="flex" alignItems="center">
                {t('accountingSystemSubPage.syncOptionsGroup.expenseCurrency')}
                <Tooltip
                  sx={{ ml: 1 }}
                  title={t(
                    'accountingSystemSubPage.syncOptionsGroup.expenseCurrencyTooltip'
                  )}
                >
                  <QuestionIcon fontSize="small" />
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell>
              <Switch
                sx={{ ml: -1.5 }}
                checked={state.fxConversion}
                disabled={
                  !canUser('codat-sync:change') ||
                  state.isLoading ||
                  state.isUpdatingSettings
                }
                onChange={(e, checked: boolean) =>
                  updateExpenseSettings('fxConversion', checked)
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {!state.isLoading && isPristineNonsyncedCase && (
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            disabled={!canUser('codat-sync:change')}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                isSetupDialogOpen: true,
              }))
            }
          >
            {t('accountingSystemSubPage.syncOptionsGroup.setupSyncButton')}
          </Button>
        </Box>
      )}

      <CodatSyncSetupDialog
        open={state.isSetupDialogOpen}
        onClose={() => {
          setState((prevState) => ({ ...prevState, isSetupDialogOpen: false }));
          getData();
        }}
      />
    </>
  );
};

export default SyncOptionsGroup;
