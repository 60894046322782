import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { isPartnerBasedSource } from 'domains/partner/utils';
import { withPermission } from 'services/rbac';
import { partnerPaths } from './paths';

interface Props {
  children: React.ReactNode;
}

const PartnerRedirect = ({ children }: Props) => {
  const { partnerId } = useParams<{ partnerId: string }>();
  const {
    state: { organization },
  } = useGlobalState();

  if (!partnerId) {
    if (isPartnerBasedSource(organization!.partnerId)) {
      return (
        <Redirect to={`${partnerPaths.partners}/${organization!.partnerId}`} />
      );
    }

    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

export default withPermission(PartnerRedirect, 'partners:change');
