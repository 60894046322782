import React, { Component, ReactNode } from 'react';
import AppErrorOverlay from 'components/AppErrorOverlay';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class AppErrorBoundary extends Component<Props, State> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) return <AppErrorOverlay />;
    return this.props.children;
  }
}

export default AppErrorBoundary;
