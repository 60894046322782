import { forwardRef } from 'react';
import { styled } from '@mui/material';
import { Chip, ChipProps, Paper } from 'elements';

export const Wrapper = styled((props) => <Paper {...props} variant="tinted" />)`
  padding: ${({ theme }) => theme.spacing(2)};
  margin: 0 0 ${({ theme }) => theme.spacing(1)};
`;

export const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 ${({ theme }) => theme.spacing(2)};
`;

export const StyledChip = styled(
  forwardRef<HTMLDivElement, Omit<ChipProps, 'variant' | 'size'>>(
    (props, ref) => (
      <Chip ref={ref} {...props} variant="outlined" size="small" />
    )
  )
)`
  margin: ${({ theme }) => theme.spacing(0, 1, 1, 0)};
  max-width: 200px;
`;
