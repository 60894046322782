import { useTranslation } from 'react-i18next';
import { IntegrationTypeIcon } from 'domains/partner/components';
import { Typography } from 'elements';
import {
  IntegrationType,
  Transaction,
  TransactionExportStatus,
  TransactionStatus,
} from 'services/constants';
import CirculaStatus from './CirculaStatus';
import NoneStatus from './NoneStatus';

interface Props {
  transaction: Transaction;
  isReceiptPreviewDialogOpen?: boolean;
}

const BezalaStatus = ({ status }: Partial<Transaction>) => {
  const { t } = useTranslation();

  if (status === TransactionStatus.pending) {
    return (
      <>
        <IntegrationTypeIcon
          integrationType={IntegrationType.bezala}
          sx={{ color: 'textSecondary', opacity: 0.5, mr: 1 }}
        />
        {t('transactionIntegrationStatus.syncedWithBezala')}
      </>
    );
  }
  if (
    [TransactionStatus.confirmed, TransactionStatus.declined].includes(status!)
  ) {
    return (
      <>
        <IntegrationTypeIcon
          integrationType={IntegrationType.bezala}
          sx={{ mr: 1 }}
        />
        {t('transactionIntegrationStatus.bookedAndSyncedWithBezala')}
      </>
    );
  }
  return null;
};

const KlippaStatus = ({ exportStatus }: Partial<Transaction>) => {
  const { t } = useTranslation();

  if (exportStatus === TransactionExportStatus.exported) {
    return (
      <>
        <IntegrationTypeIcon
          integrationType={IntegrationType.klippa}
          sx={{ mr: 1 }}
        />
        {t('transactionIntegrationStatus.exportedViaKlippa')}
      </>
    );
  }
  if (exportStatus === TransactionExportStatus.notExported) {
    return (
      <>
        <IntegrationTypeIcon
          integrationType={IntegrationType.klippa}
          sx={{ color: 'textSecondary', opacity: 0.5, mr: 1 }}
        />
        {t('transactionIntegrationStatus.syncedWithKlippa')}
      </>
    );
  }
  return null;
};

const TransactionIntegrationStatus = ({
  transaction,
  isReceiptPreviewDialogOpen,
}: Props) => {
  const getStatus = () => {
    switch (transaction.integrationType) {
      case IntegrationType.circula:
        return (
          <CirculaStatus
            transactionId={transaction.transactionId}
            exportStatus={transaction.exportStatus}
            exportDate={transaction.exportDate}
          />
        );
      case IntegrationType.bezala:
        return <BezalaStatus status={transaction.status} />;
      case IntegrationType.klippa:
        return <KlippaStatus exportStatus={transaction.exportStatus} />;
      case IntegrationType.none:
        return (
          <NoneStatus
            exportStatus={transaction.exportStatus}
            exportDate={transaction.exportDate}
            exportFormat={transaction.exportFormat}
            exportExternalReference={transaction.exportExternalReference}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Typography
      variant="caption"
      display="flex"
      alignItems="center"
      component="div"
      sx={
        isReceiptPreviewDialogOpen
          ? [{ marginBottom: (theme) => theme.spacing(3) }]
          : [
              (theme) => ({
                position: 'absolute',
                lineHeight: 1.2,
                top: theme.spacing(4),
                left: theme.spacing(3),
                right: theme.spacing(15),
              }),
            ]
      }
    >
      {getStatus()}
    </Typography>
  );
};

export default TransactionIntegrationStatus;
