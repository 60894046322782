import {
  CertificateIcon,
  ComputingIcon,
  ForkKnifeIcon,
  GiftIcon,
  HandshakeIcon,
  LaptopIcon,
  MegaphoneSimpleIcon,
  MusicNotesIcon,
  PackageIcon,
  PaperclipIcon,
  ShoppingCartIcon,
  SvgIconProps,
  TravelIcon,
} from 'elements';
import { MerchantCategory } from 'services/constants';

const iconByCategoryMap = {
  [MerchantCategory.advertisingAndMarketing]: MegaphoneSimpleIcon,
  [MerchantCategory.computingAndSoftware]: ComputingIcon,
  [MerchantCategory.educationAndTraining]: CertificateIcon,
  [MerchantCategory.electronicsAndItEquipment]: LaptopIcon,
  [MerchantCategory.entertainmentAndWellness]: MusicNotesIcon,
  [MerchantCategory.foodAndDrinks]: ForkKnifeIcon,
  [MerchantCategory.giftsAndVouchers]: GiftIcon,
  [MerchantCategory.materialsAndPackaging]: PackageIcon,
  [MerchantCategory.officeSuppliesAndEquipment]: PaperclipIcon,
  [MerchantCategory.services]: HandshakeIcon,
  [MerchantCategory.travelAndAccommodation]: TravelIcon,
  [MerchantCategory.other]: ShoppingCartIcon,
};

interface Props extends SvgIconProps {
  category: MerchantCategory;
}

const MerchantCategoryIcon = ({ category, ...props }: Props) => {
  const MerchantIcon = iconByCategoryMap[category];

  return <MerchantIcon {...props} />;
};

export default MerchantCategoryIcon;
