import React, { useState } from 'react';
import { Collapse, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TimezoneSelect from 'domains/card/components/CardControlFormElement/TimezoneSelect';
import {
  StyledBox,
  StyledRadioGroup,
  SummaryFormControlLabel,
  Wrapper,
} from 'domains/card/components/CardControlFormElement/style';
import useCardControlsDisplaySettings from 'domains/card/components/CardControlFormElement/useCardControlsDisplaySettings';
import { Radio, Switch } from 'elements';
import {
  CardConfigSetting,
  CardControlRestriction,
  cardControlRestrictions,
  CardTimeControl,
  CardTimeControlType,
  DEFAULT_TIMEZONE,
  DEFAULT_TIMEZONE_OFFSET,
} from 'services/constants';
import { TimeRangePicker } from './TimeRangePicker';

interface Props {
  cardConfigSetting: CardConfigSetting;
  control: CardTimeControl | null;
  onChange: (control: CardTimeControl | null) => void;
  disabled?: boolean;
}

const CardTimeControlFormElement = ({
  cardConfigSetting,
  control,
  onChange,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const {
    times: { isVisible },
  } = useCardControlsDisplaySettings(cardConfigSetting);
  const [isExpanded, setIsExpanded] = useState<boolean>(
    !!control?.values.length
  );
  const defaultControl = {
    restriction: CardControlRestriction.allowed,
    type: CardTimeControlType.timerange,
    values: [],
    timezone: DEFAULT_TIMEZONE,
    offset: DEFAULT_TIMEZONE_OFFSET, // TODO: this doesn't consider daylight saving time
  };

  const { restriction, type, values, timezone, offset } =
    control || defaultControl;
  const formattedTimes = values.length ? values[0].split('-') : [];

  if (!isVisible) return null;

  return (
    <Wrapper>
      <SummaryFormControlLabel
        checked={isExpanded}
        onChange={(e, checked) => {
          onChange(checked ? defaultControl : null);
          setIsExpanded(checked);
        }}
        disabled={disabled}
        control={<Switch size="medium" />}
        label={
          <Typography variant="subtitle1">
            {t('cardControlFormElement.time.title')}
          </Typography>
        }
        labelPlacement="start"
      />
      <Typography variant="body2" color="textSecondary">
        {t('cardControlFormElement.time.description')}
      </Typography>
      <Collapse in={isExpanded}>
        <StyledRadioGroup
          row
          value={restriction}
          onChange={(e) =>
            onChange({
              restriction: e.target.value as CardControlRestriction,
              type,
              values,
              timezone,
              offset,
            })
          }
        >
          {cardControlRestrictions.map((item) => (
            <FormControlLabel
              key={item}
              value={item}
              disabled={disabled}
              control={<Radio />}
              label={
                <Typography variant="body1">
                  {t(`cardControlFormElement.restrictions.${item}`)}
                </Typography>
              }
            />
          ))}
        </StyledRadioGroup>
        <StyledBox>
          <TimeRangePicker
            startTime={formattedTimes[0]}
            endTime={formattedTimes[1]}
            onTimesChange={(from, to) =>
              onChange({
                restriction,
                type,
                values: [`${from}-${to}`],
                timezone,
                offset,
              })
            }
          />
        </StyledBox>
        <StyledBox>
          <TimezoneSelect
            shouldFetchData={isExpanded}
            timezone={timezone}
            onChange={(timezone, offset) =>
              onChange({
                restriction,
                type,
                values,
                timezone,
                offset,
              })
            }
          />
        </StyledBox>
      </Collapse>
    </Wrapper>
  );
};

export default CardTimeControlFormElement;
