import { useMemo } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { CardIconWithDetails } from 'domains/card/components';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  IntegrationCell,
  MerchantCell,
  TransactionAmountCell,
  TransactionMissingReceiptCell,
  TransactionReviewStatusIcon,
} from 'domains/transaction/components';
import { drawerPlaceholderColumn, GridColDef } from 'elements';
import { Transaction } from 'services/constants';
import { getFullName } from 'services/utils';

const useColumns = () => {
  const { t, i18n } = useTranslation();
  const getCardAccountName = useCardAccountNameGetter();

  return useMemo(() => {
    const columns: GridColDef<Transaction>[] = [
      {
        headerName: t('transactionsFlaggedPage.merchant'),
        field: 'merchantName',
        sortable: false,
        flex: 1.5,
        minWidth: 60,
        renderCell: ({ row }) => (
          <MerchantCell transaction={row} showReceiptIcon showCommentIcon />
        ),
      },
      {
        headerName: '',
        field: 'receiptNeeded',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        flex: 0.2,
        renderCell: ({ row }) => (
          <TransactionMissingReceiptCell transaction={row} />
        ),
      },
      {
        headerName: t('transactionsFlaggedPage.date'),
        field: 'createdAt',
        sortable: false,
        flex: 1,
        valueGetter: ({ row }) => moment(row.createdAt).format('D MMM YYYY'),
      },
      {
        headerName: t('transactionsFlaggedPage.card'),
        field: 'cardType',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <CardIconWithDetails
            cardConfig={row.cardConfig}
            cardDesignId={row.cardDesignId}
            cardRefNum={row.cardRefNum}
            cardName={row.cardName}
            showCardNameTooltip
          />
        ),
      },
      {
        headerName: t('transactionsFlaggedPage.member'),
        field: 'memberFirstName',
        sortable: false,
        flex: 0.8,
        valueGetter: ({ row }) =>
          getFullName(row.memberFirstName, row.memberLastName) || '-',
      },

      {
        headerName: t('transactionsFlaggedPage.reviewFlagReason'),
        field: 'reviewFlagReason',
        sortable: false,
        flex: 0.8,
        renderCell: ({ row }) =>
          row.reviewFlagReason &&
          t(`reviewFlagReasons.${row.reviewFlagReason}`),
      },

      {
        headerName: t('transactionsFlaggedPage.account'),
        field: 'cardAccountName',
        sortable: false,
        flex: 0.8,
        valueGetter: ({ row }) => getCardAccountName(row.cardAccountId),
      },
      {
        headerName: t('transactionsFlaggedPage.amount'),
        field: 'transactionAmount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 0.8,
        renderCell: ({ row }) => (
          <TransactionAmountCell
            transaction={row}
            bookingDate={row.bookingDate}
          />
        ),
      },
      {
        headerName: t('transactionsFlaggedPage.review'),
        field: 'review',
        sortable: false,
        flex: 0.4,
        minWidth: 80,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <TransactionReviewStatusIcon status={row.reviewStatus} />
        ),
      },
      {
        headerName: t('transactionsFlaggedPage.export'),
        field: '_integration_',
        sortable: false,
        flex: 0.4,
        minWidth: 80,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ row }) => (
          <IntegrationCell
            status={row.status}
            exportStatus={row.exportStatus}
            integrationType={row.integrationType}
          />
        ),
      },
      drawerPlaceholderColumn,
    ];
    return columns;
  }, [i18n.language, getCardAccountName]);
};

export default useColumns;
