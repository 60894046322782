import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportIcon } from 'elements';
import { SelectionPageToast } from 'layout';
import { TransactionExportStatus } from 'services/constants';

interface Props {
  exportStatus: TransactionExportStatus;
  isDetailsPageOpen: boolean;
  count: number;
  onSuccess: () => void;
  onCancel: () => void;
}

const TransactionExportToast = ({
  exportStatus,
  isDetailsPageOpen,
  count,
  onSuccess,
  onCancel,
}: Props) => {
  const { t } = useTranslation();

  const ConfirmButtonProps = useMemo(() => {
    if (exportStatus === TransactionExportStatus.inExportQueue) {
      return {
        children: t('exportPage.exportSelected'),
        startIcon: <ExportIcon />,
        dataIntercomTarget: 'add-to-export-queue',
      };
    }

    if (exportStatus === TransactionExportStatus.exported) {
      return {
        children: t('exportPage.exportAgain'),
        startIcon: <ExportIcon />,
      };
    }

    return {
      children: t('exportPage.addToExportQueue'),
    };
  }, [exportStatus]);

  if (!count) return null;

  return (
    <SelectionPageToast
      width={isDetailsPageOpen ? 'sm' : 'lg'}
      isDetailsPageOpen={isDetailsPageOpen}
      onSuccess={onSuccess}
      onCancel={onCancel}
      confirmButtonProps={{
        ...ConfirmButtonProps,
      }}
      cancelButtonProps={
        exportStatus === TransactionExportStatus.inExportQueue
          ? {
              variant: 'outlined',
              children: t('exportPage.rmFromQueue'),
            }
          : undefined
      }
    >
      {t('exportPage.selectedCount', {
        count,
      })}
    </SelectionPageToast>
  );
};

export default TransactionExportToast;
