import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReceiptInboxStatusBadge } from 'domains/organization/components';
import { TabGroup } from 'domains/transaction/dialogs/ReceiptInboxDialog';
import { TabGroups } from 'domains/transaction/dialogs/ReceiptInboxDialog/utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from 'elements';
import { ReceiptsAutoMatchingStatus } from 'services/constants';

interface Props {
  tabGroup: TabGroup;
  value: ReceiptsAutoMatchingStatus[];
  onChange: (value: ReceiptsAutoMatchingStatus, checked: boolean) => void;
}

const StatusFilter = ({ tabGroup, value, onChange }: Props) => {
  const { t } = useTranslation();

  const options = useMemo(() => TabGroups[tabGroup as keyof typeof TabGroups], [
    tabGroup,
  ]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-status"
        id="panel-status-header"
      >
        <Typography>{t('filters.status')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl>
          <FormGroup>
            {options.map((type) => (
              <FormControlLabel
                key={type}
                checked={value.includes(type)}
                control={<Checkbox />}
                onChange={(_, checked) => onChange(type, checked)}
                label={<ReceiptInboxStatusBadge status={type} />}
              />
            ))}
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default StatusFilter;
