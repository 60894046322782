import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import { Button, TrashIcon } from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  disabled: boolean;
  onSuccess: () => void;
}

const EmptyTrashButton = ({ disabled, onSuccess }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { isCardholderApp } = useCurrentApp();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, member },
  } = useGlobalState();
  const [state, setState] = useState({
    isLoading: false,
    isConfirmDialogOpen: false,
  });

  const onAllReceiptsDelete = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      await api.deleteReceiptsAutoMatchingTrash(
        { all: true },
        isCardholderApp ? member.id : undefined,
        organization!.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isConfirmDialogOpen: false,
      }));
      onSuccess();
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isConfirmDialogOpen: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <Button
        sx={{ ml: 1 }}
        variant="outlined"
        color="error"
        size="large"
        startIcon={<TrashIcon />}
        disabled={disabled || state.isLoading}
        onClick={() =>
          setState((prevState) => ({ ...prevState, isConfirmDialogOpen: true }))
        }
      >
        {t('receiptInbox.emptryTrashBtn')}
      </Button>
      <ConfirmDialog
        title={t('receiptInbox.confirmEmptyTrashDialog.title')}
        description={t('receiptInbox.confirmEmptyTrashDialog.description')}
        confirmButtonProps={{
          children: t('receiptInbox.confirmEmptyTrashDialog.deleteAllBtn'),
          color: 'error',
        }}
        open={state.isConfirmDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isConfirmDialogOpen: false,
          }))
        }
        onSuccess={onAllReceiptsDelete}
        loading={state.isLoading}
      />
    </>
  );
};

export default EmptyTrashButton;
