import { alpha, Components, ComponentsPropsList, Theme } from '@mui/material';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    tinted: boolean;
  }
}

// ownerState is returned from MUI, but doesn't exist in a type definition
type StyleOverrides = {
  theme: Theme;
} & {
  ownerState: ComponentsPropsList['MuiChip'];
};

const MuiChipOverrides: Components<Theme> = {
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: 'none !important',
        ...theme.variables.components['chip-label'],
      }),
      labelSmall: { padding: '3px 10px' },
      labelMedium: { padding: '7px 10px' },
      deleteIcon: { margin: '0 4px 0 -4px;' },
      outlined: ({ theme }) => ({
        '&.MuiChip-outlinedDefault': {
          borderColor: theme.variables.chip.defaultEnabledBorder,
        },
      }),
      iconSmall: {
        fontSize: '16px',
      },
    },
    variants: [
      {
        props: { variant: 'tinted' },
        style: (props) => {
          const { theme, ownerState } = (props as unknown) as StyleOverrides;

          if (ownerState.color === 'default') {
            return {
              color: theme.palette.text.primary,
              background: alpha(theme.palette.primary.main, 0.08),

              '&.MuiChip-clickable:hover, &.MuiChip-clickable.Mui-focusVisible': {
                backgroundColor: theme.variables.chip.defaultHoverFill,
              },
              '.MuiChip-deleteIcon, .MuiChip-icon': {
                color: theme.palette.action.active,
                '&:hover': {
                  color: theme.palette.action.active,
                },
              },
            };
          }

          const colorName = (ownerState.color || 'primary') as
            | 'primary'
            | 'secondary'
            | 'error'
            | 'info'
            | 'success'
            | 'warning';

          return {
            color: theme.palette[colorName].main,
            background: alpha(theme.palette[colorName].main, 0.12),

            '&.MuiChip-clickable:hover, &.MuiChip-clickable.Mui-focusVisible': {
              backgroundColor: alpha(theme.palette[colorName].dark, 0.16),
            },
            '.MuiChip-deleteIcon, .MuiChip-icon': {
              color: theme.palette[colorName].dark,
              opacity: 0.7,
              '&:hover': {
                color: theme.palette[colorName].dark,
              },
            },
          };
        },
      },
      {
        props: { variant: 'tinted', color: 'primary' },
        style: ({ theme }) => ({
          color: theme.palette.text.primary,
          background: alpha(theme.palette.primary.main, 0.08),

          '&.MuiChip-clickable:hover, &.MuiChip-clickable.Mui-focusVisible': {
            backgroundColor: theme.variables.chip.defaultHoverFill,
          },
          '.MuiChip-deleteIcon, .MuiChip-icon': {
            color: theme.palette.action.active,
            '&:hover': {
              color: theme.palette.action.active,
            },
          },
        }),
      },
      {
        props: { variant: 'tinted', color: 'secondary' },
        style: ({ theme }) => ({
          color: theme.palette.secondary.contrastText,
          background: alpha(theme.palette.secondary.main, 0.16),

          '&.MuiChip-clickable:hover, &.MuiChip-clickable.Mui-focusVisible': {
            backgroundColor: alpha(theme.palette.secondary.dark, 0.18),
          },
        }),
      },
    ],
  },
};

export default MuiChipOverrides;
