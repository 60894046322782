import styled from 'styled-components';

export const RoundedBadge = styled.div`
  display: inline-block;
  height: 22px;
  padding: 2px 5px 0;
  margin: 0 4px;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid ${({ theme }) => theme.colors.grey10};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.grey10};
`;
