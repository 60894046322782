import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import TableControls from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/GLAccountsStep/TableControls';
import {
  GlAccountsStepsEnum,
  useGLAccountsSyncContext,
} from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/GLAccountsStep/useGLAccountsSyncContext';
import {
  Box,
  Button,
  CaretLeftIcon,
  DataGrid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { CodatAccountItem } from 'services/constants';
import useColumns from './useColumns';

const MapAccountsStep = () => {
  const { t } = useTranslation();
  const partnerName = usePartnerName();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const {
    isPartialFlow,
    state: {
      accounts,
      accountCategoryMap,
      selectedAccountIds,
      selectedSyncedAccountIds,
    },
    actions: {
      onInnerStepChange,
      onSelectedAccountsChange,
      onItemCategoryChange,
      onFinalAccountsSave,
      onPartialFlowCancel,
      onClose,
    },
  } = useGLAccountsSyncContext();

  const gridColumns = useColumns(
    accountCategoryMap,
    onItemCategoryChange,
    isPartialFlow
  );

  const visibleAccounts = useMemo(() => {
    return (
      accounts!.filter((account) => selectedAccountIds.includes(account.id)) ||
      []
    );
  }, [accounts, selectedAccountIds]);

  return (
    <>
      <DialogTitle>
        {t('codatSyncSetupDialog.glAccountsStep.title', { step: 2 })}
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography color="text.secondary">
          {t('codatSyncSetupDialog.glAccountsStep.assignAccountsDescription', {
            name: accountingSettings!.accountingSystemName,
            partnerName,
          })}
        </Typography>

        <TableControls
          selectedCount={selectedSyncedAccountIds.length}
          visibleAccountsCount={selectedAccountIds.length}
        />

        <Box flex="1" overflow="hidden">
          <DataGrid<CodatAccountItem>
            checkboxSelection
            rowHeight={72}
            disableRowSelectionOnClick={false}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              onSelectedAccountsChange(
                'selectedSyncedAccountIds',
                newRowSelectionModel as string[]
              );
            }}
            rowSelectionModel={selectedSyncedAccountIds}
            columns={gridColumns}
            rows={visibleAccounts}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Box mr="auto">
          <Button
            variant="text"
            startIcon={<CaretLeftIcon />}
            onClick={() =>
              onInnerStepChange(GlAccountsStepsEnum.selectAccountsStep)
            }
          >
            {t('common.button.back')}
          </Button>
        </Box>

        <Button
          variant="text"
          onClick={onPartialFlowCancel ? onPartialFlowCancel : onClose}
        >
          {t('common.button.close')}
        </Button>

        <Button
          disabled={!selectedSyncedAccountIds.length}
          onClick={onFinalAccountsSave}
        >
          {t('codatSyncSetupDialog.saveAndContinueButton')}
        </Button>
      </DialogActions>
    </>
  );
};

export default MapAccountsStep;
