import React from 'react';
import { equal, greaterThan } from 'dinero.js';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useCardAccountCurrency } from 'domains/card/hooks';
import { getCardNameWithRefNum } from 'domains/card/utils';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  InputLabelTooltipIcon,
  LoaderWithOverlay,
  MenuItem,
  MoneyField,
  Select,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Card,
  CardLimitChangeRequestStatus,
  cardLimitRenewFrequencies,
  CardLimitRenewFrequency,
  CardType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  formatMoney,
  getGenericErrorMsg,
} from 'services/utils';

export interface Props extends DialogProps {
  card: Card;
  onClose: () => void;
  onSuccess: (card: Card) => void;
}

interface FormValues {
  limitRenewFrequency: CardLimitRenewFrequency;
  limit: string;
  transactionLimit: string;
  comment: string;
}

const LimitChangeRequestDialog = ({ card, onSuccess, ...props }: Props) => {
  const { t, i18n } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const currency = useCardAccountCurrency(card.cardAccountId);
  const currencyDisplay = useCurrencyDisplayMode();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      limitRenewFrequency: card.limitRenewFrequency,
      limit: '',
      transactionLimit: '',
      comment: '',
    },
    validate: (values) => {
      let error: { transactionLimit?: string; comment?: string } = {};
      const limit = !!values.limit
        ? dineroFromFloat(values.limit, currency)
        : dineroFromMoney(card.limit);
      const transactionLimit = !!values.transactionLimit
        ? dineroFromFloat(values.transactionLimit, currency)
        : dineroFromMoney(card.transactionLimit);

      if (
        card.type !== CardType.singleUse &&
        greaterThan(transactionLimit, limit)
      ) {
        error.transactionLimit = t(
          'errors.cardTransactionLimitHigherThanLimit'
        );
      }

      if (values.comment.length > 200) {
        error.comment = t('limitChangeRequestDialog.commentLabel');
      }

      return error;
    },
    onSubmit: async (
      { limitRenewFrequency, limit, transactionLimit, comment },
      { setSubmitting }
    ) => {
      try {
        const requestedLimitRenewFrequency =
          limitRenewFrequency === card.limitRenewFrequency
            ? null
            : limitRenewFrequency;
        const requestedLimit = !!limit
          ? convertDineroToMoney(dineroFromFloat(limit, currency))
          : null;
        const requestedTransactionLimit = !!transactionLimit
          ? convertDineroToMoney(dineroFromFloat(transactionLimit, currency))
          : null;

        const limitChangeRequest = await api.createLimitChangeRequest({
          cardId: card.cardId,
          comment: comment.trim(),
          limitRenewFrequency: requestedLimitRenewFrequency,
          limit:
            card.type === CardType.singleUse
              ? requestedTransactionLimit
              : requestedLimit,
          transactionLimit: requestedTransactionLimit,
        });
        if (
          limitChangeRequest.status === CardLimitChangeRequestStatus.approved
        ) {
          const updatedCard = await api.getCard(card.cardId);
          onSuccess(updatedCard);
          if (!mounted.current) return;
        } else {
          onSuccess({
            ...card,
            limitChangeRequestId: limitChangeRequest.id,
            requestedLimitRenewFrequency,
            requestedLimit,
            requestedTransactionLimit,
          });
        }
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        setSubmitting(false);
        logError(error);
      }
    },
  });

  const isSubmitDisabled =
    (formik.values.limitRenewFrequency === card.limitRenewFrequency &&
      (!formik.values.limit ||
        equal(
          dineroFromFloat(formik.values.limit, currency),
          dineroFromMoney(card.limit)
        )) &&
      (!formik.values.transactionLimit ||
        equal(
          dineroFromFloat(formik.values.transactionLimit, currency),
          dineroFromMoney(card.transactionLimit)
        ))) ||
    !formik.values.comment.trim() ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('limitChangeRequestDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="limit-change-request-form"
          noValidate
        >
          <Box mb={4}>
            <Typography variant="body2">
              <Trans
                i18nKey={
                  card.type === CardType.singleUse
                    ? 'limitChangeRequestDialog.selectedCard_one'
                    : 'limitChangeRequestDialog.selectedCard_plural'
                }
                components={{ b: <b /> }}
                values={{ name: getCardNameWithRefNum(card) }}
              />
            </Typography>
          </Box>
          <Grid container columnSpacing={3} rowSpacing={2}>
            {card.type !== CardType.singleUse && (
              <>
                <Grid item xs={12}>
                  <FormControl fullWidth disabled={formik.isSubmitting}>
                    <InputLabel id="limit-frequency-select-label">
                      {t('limitChangeRequestDialog.limitFrequency')}
                      <InputLabelTooltipIcon
                        title={t(
                          `cardLimitFrequency.hints.${formik.values.limitRenewFrequency}`
                        )}
                      />
                    </InputLabel>
                    <Select<CardLimitRenewFrequency>
                      {...formik.getFieldProps('limitRenewFrequency')}
                      renderValue={(selected) =>
                        t(`cardLimitFrequency.frequencies.${selected}`)
                      }
                      labelId="limit-frequency-select-label"
                    >
                      {cardLimitRenewFrequencies.map((frequency) => (
                        <MenuItem key={frequency} value={frequency}>
                          {t(`cardLimitFrequency.frequencies.${frequency}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <MoneyField
                    label={t('limitChangeRequestDialog.limit')}
                    placeholder={formatMoney(card.limit, i18n.language, {
                      currencyDisplay,
                      fractionalPart: true,
                    })}
                    isNumericString
                    currency={currency.code}
                    decimalScale={currency.exponent}
                    {...omit(formik.getFieldProps('limit'), 'onChange')}
                    onValueChange={({ value }) =>
                      formik.setFieldValue('limit', value)
                    }
                    disabled={formik.isSubmitting}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <MoneyField
                label={t('limitChangeRequestDialog.transactionLimit')}
                placeholder={formatMoney(card.transactionLimit, i18n.language, {
                  currencyDisplay,
                  fractionalPart: true,
                })}
                isNumericString
                currency={currency.code}
                decimalScale={currency.exponent}
                {...omit(formik.getFieldProps('transactionLimit'), 'onChange')}
                onValueChange={({ value }) =>
                  formik.setFieldValue('transactionLimit', value)
                }
                error={!!formik.errors.transactionLimit}
                helperText={formik.errors.transactionLimit}
                disabled={formik.isSubmitting}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={t('limitChangeRequestDialog.commentLabel')}
                placeholder={t('limitChangeRequestDialog.commentPlaceholder')}
                {...formik.getFieldProps('comment')}
                multiline
                maxRows={5}
                error={!!formik.errors.comment}
                helperText={formik.errors.comment}
                disabled={formik.isSubmitting}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="limit-change-request-form"
          type="submit"
        >
          {t('limitChangeRequestDialog.submit')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(LimitChangeRequestDialog);
