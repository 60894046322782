import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Box, IconButton, TextField, TrashIcon } from 'elements';
import { Transaction } from 'services/constants';
import { getCurrencyFractionalDigits } from 'services/utils';

const StyledTextField = (props: any) => {
  return (
    <TextField
      size="medium"
      sx={{ '.MuiInputBase-input': { pr: (theme) => theme.spacing(6.5) } }}
      {...props}
    />
  );
};

const allowedDecimalSeparators = ['.', ','];

const useNumberFormatProps = (transaction: Transaction) => {
  const { i18n } = useTranslation();
  const decimalSeparator = useMemo(() => (i18n.language === 'de' ? ',' : '.'), [
    i18n.language,
  ]);
  const currencyProps = useMemo(() => {
    return transaction.fxTransactionAmount.currency ===
      transaction.transactionAmount.currency
      ? { prefix: '€' }
      : { suffix: ` ${transaction.fxTransactionAmount.currency}` };
  }, []);

  return useMemo(
    () => ({
      decimalSeparator,
      ...currencyProps,
    }),
    [decimalSeparator, currencyProps]
  );
};

interface Props {
  transaction: Transaction;
  value: string | null;
  onChange: (stringValue: string, floatValue: number | null) => void;
  readOnly: boolean;
  onRemove: () => void;
}

const SplitAccountingTransactionsItem = ({
  transaction,
  value,
  onChange,
  readOnly,
  onRemove,
}: Props) => {
  const numberFormatRef = useRef<any>(null);
  const props = useNumberFormatProps(transaction);
  const decimalScale = getCurrencyFractionalDigits(
    transaction.fxTransactionAmount.currency
  );
  const [touched, setTouched] = useState(!!value);

  return (
    <Box
      sx={{ '&+&': { mt: (theme) => theme.spacing(1) } }}
      position="relative"
    >
      <NumberFormat
        ref={numberFormatRef}
        isNumericString
        fixedDecimalScale
        customInput={StyledTextField}
        allowedDecimalSeparators={allowedDecimalSeparators}
        decimalScale={decimalScale}
        value={value}
        disabled={readOnly}
        onValueChange={(values) => {
          if (readOnly) return;
          if (!touched && transaction.fxTransactionAmount.value < 0) {
            setTouched(true);
            if (values.value.startsWith('-')) {
              onChange(values.value, values.floatValue ?? null);
              return;
            }
            const stringValue = `-${values.value}`;
            let floatValue = values.floatValue ?? null;
            if (floatValue !== null && floatValue >= 0) floatValue *= -1;
            onChange(stringValue, floatValue);
            numberFormatRef.current?.setState({
              numAsString: stringValue,
              value: `-${floatValue}`,
            });
            return;
          }
          onChange(values.value, values.floatValue ?? null);
        }}
        {...props}
      />

      {/* the button should be outside of the TextInput as customInput
          allows only reference to the input element
      */}
      {!readOnly && (
        <IconButton
          data-test-id="rm-acc-transaction-btn"
          onClick={onRemove}
          sx={{
            position: 'absolute',
            top: '50%',
            right: (theme) => `calc(${theme.spacing(1)} + 1px)`,
            transform: 'translateY(-50%)',
          }}
        >
          <TrashIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default SplitAccountingTransactionsItem;
