import { uniq } from 'lodash';

// prettier-ignore
const SUPPORTED_EXTENDED_ASCII = [
  'À', 'Á', 'Â', 'Ã', 'Ä', 'Å', 'Æ', 'Ç', 'È', 'É', 'Ê', 'Ë', 'Ì', 'Í', 'Î',
  'Ï', 'Ð', 'Ñ', 'Ò', 'Ó', 'Ô', 'Õ', 'Ö', 'Ø', 'Ù', 'Ú', 'Û', 'Ü', 'Ý', 'Þ',
  'ß', 'à', 'á', 'â', 'ã', 'ä', 'å', 'æ', 'ç', 'è', 'é', 'ê', 'ë', 'ì', 'í',
  'î', 'ï', 'ð', 'ñ', 'ò', 'ó', 'ô', 'õ', 'ö', 'ø', 'ù', 'ú', 'û', 'ü', 'ý',
  'þ', 'ÿ', 'ı', 'Œ', 'œ', 'Š', 'š', 'Ÿ', 'Ž', 'ž',
];

const SPECIAL_CHAR_REPLACEMENTS = {
  Æ: 'AE',
  Þ: 'th',
  ß: 'ss',
  æ: 'ae',
  þ: 'th',
  Œ: 'OE',
  œ: 'oe',
};

export function filterNonPrintableChars(str: string): string[] {
  return uniq(
    str.split('').filter(
      (c) =>
        // range of ASCII printable chars https://theasciicode.com.ar/
        (c.charCodeAt(0) < 32 || c.charCodeAt(0) > 126) &&
        // replacement char exists in BE
        !SUPPORTED_EXTENDED_ASCII.includes(c)
    )
  );
}

export function getPrintableLength(str: string): number {
  let len = 0;

  str
    .trim()
    .split('')
    .forEach((c) => {
      if (Object.keys(SPECIAL_CHAR_REPLACEMENTS).includes(c)) {
        len += 2;
      } else {
        len += 1;
      }
    });

  return len;
}
