import moment from 'moment';
import {
  DownloadMt940Button,
  DownloadPainFileButton,
  PaymentStatusBadge,
  ResubmitPaymentFileButton,
} from 'domains/billing/components';
import { TableCell, TableRow, Tooltip } from 'elements';
import { SettlementPayment } from 'services/constants';

interface Props {
  payment: SettlementPayment;
  onResubmitSuccess: () => void;
  isShowResubmitPainFileButton: boolean;
  dateTooltip?: string;
}

const SettlementPaymentItem = ({
  payment,
  onResubmitSuccess,
  isShowResubmitPainFileButton,
  dateTooltip = '',
}: Props) => {
  const date = moment(payment.createdAt);

  return (
    <TableRow>
      <TableCell>
        <Tooltip title={dateTooltip}>
          <span>{date.format('YYYY-MM-DD HH:mm')}</span>
        </Tooltip>
      </TableCell>

      <TableCell>
        <PaymentStatusBadge status={payment.status} />
      </TableCell>

      <TableCell align="right">
        {payment.paymentFileName &&
          isShowResubmitPainFileButton &&
          moment().diff(date, 'days') <= 5 && (
            <ResubmitPaymentFileButton
              fileName={payment.paymentFileName}
              onSuccess={onResubmitSuccess}
            />
          )}
        {payment.accountTransactionId && (
          <DownloadMt940Button
            accountTransactionId={payment.accountTransactionId}
          />
        )}
        {payment.paymentFileName && (
          <DownloadPainFileButton painFileName={payment.paymentFileName} />
        )}
      </TableCell>
    </TableRow>
  );
};

export default SettlementPaymentItem;
