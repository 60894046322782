import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { generateLabelFromSlug } from 'domains/creditAndCompliance/utils';
import { FormControl, Grid, InputLabel, MenuItem, Select } from 'elements';
import { InternalIndustryType } from 'services/constants';

interface Props {
  onChange: (
    name: 'internalIndustryType' | 'internalIndustrySubtype',
    value: string | null
  ) => void;
  internalIndustryType: string | null;
  internalIndustrySubtype: string | null;
}

const InternalIndustryTypeSelectGroup = ({
  internalIndustryType = '',
  internalIndustrySubtype = '',
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { internalIndustryTypes },
  } = useCreditAndComplianceContext();

  const [
    internalIndustrySubtypeOptions,
    setInternalIndustrySubtypeOptions,
  ] = useState<InternalIndustryType['internalIndustrySubtypes']>(() =>
    internalIndustryType
      ? internalIndustryTypes.find(
          (item) => item.name === internalIndustryType
        )!.internalIndustrySubtypes
      : []
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth error={false}>
          <InputLabel>
            {t('int.internalIndustryTypeSelectGroup.internalIndustryType')}
          </InputLabel>
          <Select
            value={internalIndustryType}
            onChange={({ target: { value } }) => {
              onChange('internalIndustryType', value);
              if (internalIndustrySubtype) {
                onChange('internalIndustrySubtype', null);
              }

              setInternalIndustrySubtypeOptions(
                value
                  ? internalIndustryTypes.find((item) => item.name === value)!
                      .internalIndustrySubtypes
                  : []
              );
            }}
          >
            <MenuItem value="">-</MenuItem>
            {internalIndustryTypes.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {generateLabelFromSlug(item.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth disabled={!internalIndustryType}>
          <InputLabel>
            {t('int.internalIndustryTypeSelectGroup.internalIndustrySubtype')}
          </InputLabel>
          <Select
            value={internalIndustrySubtype}
            onChange={({ target: { value } }) =>
              onChange('internalIndustrySubtype', value)
            }
          >
            <MenuItem value="">-</MenuItem>
            {internalIndustrySubtypeOptions.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {generateLabelFromSlug(item.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default InternalIndustryTypeSelectGroup;
