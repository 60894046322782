import React, { useMemo } from 'react';
import { toDecimal } from 'dinero.js';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountCurrency } from 'domains/card/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabelTooltipIcon,
  LoaderWithOverlay,
  MoneyField,
  Radio,
  RadioGroup,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Team } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  getGenericErrorMsg,
} from 'services/utils';

interface FormValues {
  maxLimitPerCard: string;
  teamManagerCanIssueCardForSelf: boolean;
}

interface Props extends DialogProps {
  team: Team | null;
  isEditing?: boolean;
  onClose: () => void;
  onSuccess?: (team: Team) => void;
}

export const EditTeamPermissionsDialog = ({
  team: teamNullable,
  isEditing = false,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { dispatch } = useGlobalState();
  const currency = useCardAccountCurrency();
  const team = useMemo(() => teamNullable!, []);
  const formik = useFormik<FormValues>({
    initialValues: {
      maxLimitPerCard: toDecimal(dineroFromMoney(team.maxLimitPerCard)),
      teamManagerCanIssueCardForSelf: team.teamManagerCanIssueCardForSelf,
    },
    onSubmit: async (
      { teamManagerCanIssueCardForSelf, ...values },
      { setSubmitting }
    ) => {
      try {
        const maxLimitPerCard = convertDineroToMoney(
          dineroFromFloat(values.maxLimitPerCard, currency)
        );
        await api.updateTeamPermissions(team.id, {
          maxLimitPerCard,
          teamManagerCanIssueCardForSelf,
        });

        const data = {
          ...team,
          maxLimitPerCard,
          teamManagerCanIssueCardForSelf,
        };

        dispatch({
          type: 'UPDATE_TEAM',
          payload: data,
        });

        if (!mounted.current) return;

        if (onSuccess) {
          onSuccess(data);
        } else {
          props.onClose();
        }
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const isSubmitDisabled =
    !formik.values.maxLimitPerCard || formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {isEditing
          ? t('editTeamPermissionsDialog.editTitle')
          : t('editTeamPermissionsDialog.setTitle')}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="edit-team-permissions-form"
          noValidate
        >
          <MoneyField
            {...omit(formik.getFieldProps('maxLimitPerCard'), 'onChange')}
            onValueChange={({ value }) =>
              formik.setFieldValue('maxLimitPerCard', value)
            }
            isNumericString
            currency={currency.code}
            disabled={formik.isSubmitting}
            autoFocus
            onFocus={(e) => e.target.select()}
            label={
              <>
                {t('editTeamPermissionsDialog.maxLimitPerCard')}
                <InputLabelTooltipIcon
                  title={t('editTeamPermissionsDialog.maxLimitPerCardTooltip')!}
                />
              </>
            }
          />
          <Box mt={4}>
            <FormControl disabled={formik.isSubmitting} variant="standard">
              <FormLabel>
                {t('editTeamPermissionsDialog.allowCardIssuance')}
              </FormLabel>
              <RadioGroup
                value={
                  formik.values.teamManagerCanIssueCardForSelf ? 'yes' : 'no'
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    'teamManagerCanIssueCardForSelf',
                    e.target.value === 'yes'
                  );
                }}
                row
              >
                {['yes', 'no'].map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio />}
                    label={t(`common.${option}`)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="edit-team-permissions-form"
          type="submit"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditTeamPermissionsDialog);
