import React from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { getDocumentName } from 'domains/document/utils';
import { Box, Typography } from 'elements';
import { DocumentGenericType } from 'services/constants';

const typeToDocumentGenericTypeMap = {
  ISSUER: DocumentGenericType.issuerTermsAndConditions,
  PARTNER_BANK: DocumentGenericType.bankTermsAndConditions,
  PLATFORM: DocumentGenericType.platformTermsAndConditions,
  INSURANCE: DocumentGenericType.insuranceTerms,
};

const TermsAndConditionsSection = () => {
  const { t } = useTranslation();
  const {
    state: { termsAndConditions },
  } = useCreditAndComplianceContext();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    state: { documents },
  } = useGlobalState();

  return (
    <>
      <Typography variant="h6" mb={1}>
        {t('int.customerDeclarations.termsAndConditionsSection')}
      </Typography>

      <Box component="ul">
        {termsAndConditions!.map((tac) => {
          const documentGenericType =
            typeToDocumentGenericTypeMap[
              tac.type as keyof typeof typeToDocumentGenericTypeMap
            ];

          if (!documentGenericType) return null;
          if (!documents) return null;

          const doc = documents[documentGenericType]?.[0];
          if (!doc) return null;

          return (
            <li key={tac.documentId}>
              <Typography>
                {getDocumentName(
                  doc,
                  documentGenericType,
                  organization!.partnerId
                )}
              </Typography>
              {tac.confirmations.map((confirmation) => (
                <Typography
                  key={confirmation.memberId}
                  variant="body2"
                  color="textSecondary"
                >
                  <Trans
                    i18nKey="int.customerDeclarations.confirmedByAndAt"
                    components={{ b: <b /> }}
                    values={{
                      confirmedBy: confirmation.email,
                      confirmedAt: moment(confirmation.confirmedAt).format(
                        'DD MMM YYYY HH:mm'
                      ),
                    }}
                  />
                </Typography>
              ))}
            </li>
          );
        })}
      </Box>
    </>
  );
};

export default TermsAndConditionsSection;
