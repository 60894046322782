import React, { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Divider, LoaderWithOverlay } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import { PageContent, PageHeader, PageTitle } from 'layout';
import {
  OrganizationPartner,
  PartnerAudit,
  PaymentDeferment,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import InternalModulesSection from './InternalModulesSection';
import OnboardingSettingsSection from './OnboardingSettingsSection';
import PartnerAccessSection from './PartnerAccessSection';
import PartnerSettingsSection from './PartnerSettingsSection';
import PaymentTermsSection from './PaymentTermsSection';

interface State {
  paymentDeferment: PaymentDeferment | null;
  partnerAudit: PartnerAudit | null;
  organizationPartners: OrganizationPartner[] | null;
  isLoading: boolean;
}

const GeneralTermsPage = () => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const showPageError = useShowPageError();
  const api = useImperativeApi();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    paymentDeferment: null,
    partnerAudit: null,
    organizationPartners: null,
    isLoading: true,
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [
        paymentDeferment,
        partnerAudit,
        { data: organizationPartners },
      ] = await Promise.all([
        api.getPaymentDeferment(organization!.id),
        await api.getPartnerAudit(organization!.id),
        canUser('partners:view')
          ? await api.getOrganizationPartners(organization!.id)
          : { data: null },
      ]);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        paymentDeferment,
        partnerAudit,
        organizationPartners: sortBy(organizationPartners, (p) =>
          p.partnerName.toLowerCase()
        ),
        isLoading: false,
      }));
    } catch (error) {
      showPageError();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <PageHeader>
        <PageTitle
          title={t('int.generalTermsPage.title')}
          suptitle={t('int.sidebar.terms.title')}
        />
      </PageHeader>

      <Divider sx={{ mx: 5 }} />

      <PageContent>
        {state.isLoading ? (
          <LoaderWithOverlay loading />
        ) : (
          <>
            <InternalModulesSection />

            <OnboardingSettingsSection />

            {!!state.paymentDeferment && (
              <PaymentTermsSection
                paymentDeferment={state.paymentDeferment}
                onUpdate={(paymentDeferment) =>
                  setState((prevState) => ({ ...prevState, paymentDeferment }))
                }
              />
            )}

            {state.partnerAudit && (
              <PartnerSettingsSection
                partnerAudit={state.partnerAudit}
                onSuccess={(partnerAudit) => {
                  if (!mounted.current) return;
                  setState((prevState) => ({ ...prevState, partnerAudit }));
                }}
              />
            )}

            <PartnerAccessSection
              organizationPartners={state.organizationPartners}
              onOrgPartnerUpdate={(partners) => {
                setState((prevState) => ({
                  ...prevState,
                  organizationPartners: sortBy(partners, (p) =>
                    p.partnerName.toLowerCase()
                  ),
                }));
              }}
            />
          </>
        )}
      </PageContent>
    </>
  );
};

export default withPageConfig(GeneralTermsPage, {
  permission: 'general-terms-page:visit',
});
