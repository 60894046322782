import { useTranslation } from 'react-i18next';
import { StatusBadge, StatusBadgeProps } from 'elements';
import { BillPaymentType } from 'services/constants';

interface Props extends Omit<StatusBadgeProps, 'label'> {
  type: BillPaymentType;
}

const BillPaymentTypeBadge = ({ type, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <StatusBadge
      label={t(`billPaymentTypes.${type}`)}
      variant="filled"
      color="primary"
      {...props}
    />
  );
};

export default BillPaymentTypeBadge;
