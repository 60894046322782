import { useCallback, useEffect, useRef, useState } from 'react';

const DELAY = 60 * 1000;

const getSavedTime = (key: string): number | null => {
  const str = sessionStorage.getItem(key);
  if (!str) return null;
  const time = new Date(str as string);
  return isNaN(time as any) ? null : time.getTime();
};

const getDelayBySavedTime = (key: string): number | null => {
  const savedTime = getSavedTime(key);
  if (!savedTime) return null;
  const diff = savedTime - Date.now();
  if (diff <= 0) {
    sessionStorage.removeItem(key);
    return null;
  }
  if (diff > DELAY) {
    sessionStorage.removeItem(key);
    return null;
  }
  return diff;
};

const useDelayedDisabled = (key: string): [boolean, () => void] => {
  const keyRef = useRef(key);
  const [delay, setDelay] = useState<number | null>(null);
  const [disabled, setDisabled] = useState(!!delay);

  useEffect(() => {
    keyRef.current = key;
    const savedDelay = getDelayBySavedTime(key);
    setDelay(savedDelay);
    setDisabled(!!savedDelay);
  }, [key]);

  useEffect(() => {
    let timeoutId: number;
    if (delay) {
      timeoutId = window.setTimeout(() => {
        setDisabled(false);
        setDelay(null);
        sessionStorage.removeItem(key);
      }, delay);
    }
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [delay]);

  const onDisable = useCallback(() => {
    if (key === keyRef.current) {
      setDisabled(true);
      setDelay(DELAY);
    }
    const delayedTime = new Date(Date.now() + DELAY);
    sessionStorage.setItem(key, delayedTime.toISOString());
  }, [key]);

  return [disabled, onDisable];
};

export default useDelayedDisabled;
