import { useTranslation } from 'react-i18next';
import CountryFlag from 'components/CountryFlag';
import { Grid, Paper, Typography } from 'elements';
import { Merchant, OrganizationMerchant } from 'services/constants';

interface Props {
  merchant: Merchant | OrganizationMerchant;
}

const MerchantData = ({ merchant }: Props) => {
  const { t } = useTranslation();
  const {
    street,
    postalCode,
    city,
    state: countryState,
    country,
    companyRegistry,
    vatNumber,
    url,
  } = merchant;

  return (
    <Paper variant="outlined">
      <Grid container>
        {(street || postalCode || city || countryState) && (
          <Grid item xs={6} px={2.5} py={2}>
            <Typography variant="body1">{t('merchantData.address')}</Typography>
            {street && (
              <Typography variant="body2" color="text.secondary">
                {street}
              </Typography>
            )}
            {(postalCode || city || countryState) && (
              <Typography variant="body2" color="text.secondary">
                {[postalCode, city, countryState]
                  .filter((item) => !!item)
                  .join(', ')}
              </Typography>
            )}
          </Grid>
        )}
        {companyRegistry && (
          <Grid item xs={6} px={2.5} py={2}>
            <Typography variant="body1">
              {t('merchantData.registry')}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {companyRegistry}
            </Typography>
          </Grid>
        )}
        {vatNumber && (
          <Grid item xs={6} px={2.5} py={2}>
            <Typography variant="body1">{t('merchantData.vat')}</Typography>
            <Typography variant="body2" color="text.secondary">
              {vatNumber}
            </Typography>
          </Grid>
        )}
        {country && (
          <Grid item xs={6} px={2.5} py={2} display="flex" alignItems="center">
            <CountryFlag code={country} mr={1} />
            <Typography variant="body1" component="span">
              {country}
            </Typography>
          </Grid>
        )}
        {url && (
          <Grid item xs={6} px={2.5} py={2}>
            <Typography variant="body1">{t('merchantData.url')}</Typography>
            <Typography variant="body2" color="text.secondary">
              {url}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default MerchantData;
