import styled from 'styled-components';
import { Button } from 'components/Button';
import { MenuContainer } from 'components/Menu';

export const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
  margin-bottom: 24px;
  padding: 16px;
  position: relative;
`;

export const DocumentTitleRow = styled.div`
  align-items: flex-start;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  line-height: 24px;
`;

export const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  margin-left: 18px;
  padding: 10px 24px;

  & > .MuiButton-label svg {
    margin-right: 8px;
  }
`;

export const IconAndTitleWrapper = styled.div<{
  $isRed: boolean;
  $hasMt: boolean;
}>`
  color: ${({ $isRed, theme }) => ($isRed ? theme.colors.red10 : 'inherit')};
  display: flex;
  margin-top: ${({ $hasMt }) => ($hasMt ? '8px' : '')};

  svg {
    height: 24px;
    margin-right: 8px;
    min-width: 24px;
    width: 24px;
  }
`;

export const FileListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const FileContainerTitle = styled.span`
  color: ${({ theme }) => theme.colors.grey100};
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  margin-bottom: 16px;
`;

export const StyledIconButton = styled(Button)`
  height: 40px;
  margin-left: 18px;
  padding: 8px;
  width: 40px;
`;

export const StyledMenuContainer = styled(MenuContainer)`
  & .MuiMenu-list {
    min-width: 180px;
    padding: 6px 0;
  }
`;

export const Comment = styled.div`
  background-color: ${({ theme }) => theme.colors.yellow90};
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  max-width: 392px;
  padding: 20px 12px;
`;

export const Bold = styled.b`
  font-weight: 700;
`;
