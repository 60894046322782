import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DataItemRow } from 'domains/creditAndCompliance/components';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { parseAndReformatDateStr } from 'domains/creditAndCompliance/utils';
import { Box, EditButton, Grid, Typography } from 'elements';
import { AmlRiskScoring, ComplianceRatingValue } from 'services/constants';
import { getYesNoFromBoolean } from 'services/utils';
import EditComplianceRatingDialog from './EditComplianceRatingDialog';

const ComplianceRatingSection = () => {
  const { t } = useTranslation();
  const {
    state: { complianceCompany },
  } = useCreditAndComplianceContext();
  const complianceRating = complianceCompany!.complianceRating;

  const [
    isEditComplianceRatingDialog,
    setIsEditComplianceRatingDialog,
  ] = useState(false);

  return (
    <>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        py={1}
      >
        <Typography mr={1} variant="h6">
          {t('int.complianceRatingSection.title')}
        </Typography>

        <EditButton onClick={() => setIsEditComplianceRatingDialog(true)} />
      </Box>

      <Grid container direction="column" spacing={2} py={3}>
        <DataItemRow
          label={t('int.complianceRatingSection.sanctionScreening')}
          updatedAt={complianceRating.sanctionScreening.updatedAt}
          updatedBy={complianceRating.sanctionScreening.updatedBy}
          value={
            complianceRating.sanctionScreening.value && (
              <>
                <Typography
                  variant="inherit"
                  fontWeight="bold"
                  color="success.main"
                >
                  {complianceRating.sanctionScreening.value ? 'OK' : ''}
                </Typography>
                {complianceRating.sanctionScreeningDate.value && (
                  <span>
                    {moment(
                      complianceRating.sanctionScreeningDate.value
                    ).format('DD.MM.YYYY')}
                  </span>
                )}
              </>
            )
          }
        />
        <DataItemRow
          label={t('int.complianceRatingSection.amlScreening')}
          updatedAt={complianceRating.amlScreening.updatedAt}
          updatedBy={complianceRating.amlScreening.updatedBy}
          value={
            complianceRating.amlScreening.value && (
              <>
                <Typography
                  variant="inherit"
                  fontWeight="bold"
                  color={
                    complianceRating.amlScreening.value === AmlRiskScoring.low
                      ? 'success.main'
                      : 'error.main'
                  }
                >
                  {complianceRating.amlScreening.value}
                </Typography>
                {complianceRating.amlScreeningDate.value && (
                  <span>
                    {moment(complianceRating.amlScreeningDate.value).format(
                      'DD.MM.YYYY'
                    )}
                  </span>
                )}
              </>
            )
          }
        />
        <DataItemRow
          label={t('int.complianceRatingSection.sanctioned')}
          updatedAt={complianceRating.sanctioned.updatedAt}
          updatedBy={complianceRating.sanctioned.updatedBy}
          value={getYesNoFromBoolean(complianceRating.sanctioned.value, t)}
        />
        <DataItemRow
          label={t('int.complianceRatingSection.pep')}
          updatedAt={complianceRating.pep.updatedAt}
          updatedBy={complianceRating.pep.updatedBy}
          value={getYesNoFromBoolean(complianceRating.pep.value, t)}
        />
        <DataItemRow
          label={t('int.complianceRatingSection.adverseMedia')}
          updatedAt={complianceRating.adverseMedia.updatedAt}
          updatedBy={complianceRating.adverseMedia.updatedBy}
          value={getYesNoFromBoolean(complianceRating.adverseMedia.value, t)}
        />
        <DataItemRow
          label={t('int.complianceRatingSection.highRiskIndustry')}
          updatedAt={complianceRating.highRiskIndustry.updatedAt}
          updatedBy={complianceRating.highRiskIndustry.updatedBy}
          value={getYesNoFromBoolean(
            complianceRating.highRiskIndustry.value,
            t
          )}
        />
        <DataItemRow
          label={t('int.complianceRatingSection.highRiskCountry')}
          updatedAt={complianceRating.highRiskCountry.updatedAt}
          updatedBy={complianceRating.highRiskCountry.updatedBy}
          value={getYesNoFromBoolean(complianceRating.highRiskCountry.value, t)}
        />
        <DataItemRow
          label={t('int.complianceRatingSection.unusuallyComplexStructure')}
          updatedAt={complianceRating.unusuallyComplexStructure.updatedAt}
          updatedBy={complianceRating.unusuallyComplexStructure.updatedBy}
          value={getYesNoFromBoolean(
            complianceRating.unusuallyComplexStructure.value,
            t
          )}
        />
        <DataItemRow
          label={t('int.complianceRatingSection.other')}
          updatedAt={complianceRating.other.updatedAt}
          updatedBy={complianceRating.other.updatedBy}
          value={complianceRating.other.value}
        />
        <DataItemRow
          label={t('int.complianceRatingSection.complianceRatingValue')}
          updatedAt={complianceRating.complianceRatingValue.updatedAt}
          updatedBy={complianceRating.complianceRatingValue.updatedBy}
          value={
            complianceRating.complianceRatingValue.value && (
              <Typography
                variant="inherit"
                fontWeight="bold"
                color={
                  complianceRating.complianceRatingValue.value ===
                  ComplianceRatingValue.low
                    ? 'success.main'
                    : 'error.main'
                }
              >
                {complianceRating.complianceRatingValue.value}
              </Typography>
            )
          }
        />
        <DataItemRow
          label={t('int.complianceRatingSection.tpmlRating')}
          updatedAt={complianceRating.tpmlRating.updatedAt}
          updatedBy={complianceRating.tpmlRating.updatedBy}
          value={complianceRating.tpmlRating.value}
        />
        <DataItemRow
          label={t('int.complianceRatingSection.vgRating')}
          updatedAt={complianceRating.vgRating.updatedAt}
          updatedBy={complianceRating.vgRating.updatedBy}
          value={complianceRating.vgRating.value}
        />
        <DataItemRow
          label={t('int.complianceRatingSection.onboardingType')}
          updatedAt={complianceRating.onboardingType.updatedAt}
          updatedBy={complianceRating.onboardingType.updatedBy}
          value={complianceRating.onboardingType.value}
        />
        <DataItemRow
          label={t('int.complianceRatingSection.nextMonitoringDate')}
          updatedAt={complianceRating.nextMonitoringDate.updatedAt}
          updatedBy={complianceRating.nextMonitoringDate.updatedBy}
          value={
            complianceRating.nextMonitoringDate.value &&
            parseAndReformatDateStr(
              complianceRating.nextMonitoringDate.value,
              'DD.MM.YYYY'
            )
          }
        />
      </Grid>

      <EditComplianceRatingDialog
        open={isEditComplianceRatingDialog}
        onClose={() => setIsEditComplianceRatingDialog(false)}
      />
    </>
  );
};

export default ComplianceRatingSection;
