import React from 'react';
import { useTranslation } from 'react-i18next';
import CompletedMessageBlock from 'components/CompletedMessageBlock';
import OnboardingBlockWrapper from 'components/OnboardingPage/OnboardingBlockWrapper';
import { MailDescription } from 'components/OnboardingPage/style';
import { useOnboardingContext } from 'components/OnboardingPage/useOnboardingContext';

const ComplianceCheckBlock = () => {
  const { t } = useTranslation();
  const {
    state: { onboarding },
  } = useOnboardingContext();

  const getComplianceBlockState = () => {
    if (!onboarding) return 'disabled';

    if (onboarding.complianceCheckCompleted) return 'completed';

    if (onboarding.riskAssessmentCompleted) return 'active';

    return 'disabled';
  };

  return (
    <OnboardingBlockWrapper
      title={t('complianceCheckBlock.title')}
      state={getComplianceBlockState()}
      testId="onboarding-compliance-block"
    >
      {onboarding?.complianceCheckCompleted ? (
        <CompletedMessageBlock $margin="12px 0 8px">
          {t('complianceCheckBlock.completedMessage')}
        </CompletedMessageBlock>
      ) : (
        <MailDescription $mb={3}>
          {t('complianceCheckBlock.description')}
        </MailDescription>
      )}
    </OnboardingBlockWrapper>
  );
};

export default ComplianceCheckBlock;
