import React, { useMemo } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { CardIconWithDetails } from 'domains/card/components';
import { GridColDef } from 'elements';
import { Card } from 'services/constants';
import { getPath } from 'services/utils';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<Card>[] = [
      {
        headerName: t(
          'subscriptionPlanSettingsSubPage.blackCardsListDialog.table.headers.card'
        ),
        field: 'cardConfig',
        flex: 200,
        sortable: false,
        renderCell: ({ row }) => (
          <CardIconWithDetails
            cardConfig={row.cardConfig}
            cardDesignId={row.cardDesignId}
            cardName={row.cardName}
            cardRefNum={row.cardRefNum}
          />
        ),
      },
      {
        headerName: t(
          'subscriptionPlanSettingsSubPage.blackCardsListDialog.table.headers.member'
        ),
        field: 'chFirstName',
        flex: 200,
        sortable: false,
        valueGetter: ({ row }) => `${row.chFirstName} ${row.chLastName}`,
      },
      {
        headerName: t(
          'subscriptionPlanSettingsSubPage.blackCardsListDialog.table.headers.nextChargingDate'
        ),
        field: 'chargingDate',
        flex: 150,
        sortable: false,
        valueGetter: ({ row }) => {
          if (!row.issuingDate) return '-';

          const date = moment(row.issuingDate).set('year', moment().year());
          if (moment().isAfter(date)) {
            date.set('year', moment().year() + 1);
          }

          return date.format('D MMM YYYY');
        },
      },
      {
        headerName: t(
          'subscriptionPlanSettingsSubPage.blackCardsListDialog.table.headers.expiresOn'
        ),
        field: 'expiryDate',
        flex: 120,
        sortable: false,
        valueGetter: ({ row }) =>
          row.expiryDate ? moment(row.expiryDate).format('D MMM YYYY') : '-',
      },
      {
        headerName: '',
        field: 'cardId',
        flex: 80,
        sortable: false,
        align: 'right',
        renderCell: ({ row }) => (
          <Link
            to={generatePath(getPath('cardDetails'), {
              cardId: row.cardId,
              orgId: row.organizationId,
            })}
          >
            {t(
              'subscriptionPlanSettingsSubPage.blackCardsListDialog.table.viewCardDetails'
            )}
          </Link>
        ),
      },
    ];

    return columns;
  }, [i18n.language]);
};

export default useColumns;
