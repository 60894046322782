import { useCallback } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { PermissionKey } from './permissions';

const useCanUser = () => {
  const {
    state: { permissions },
  } = useGlobalState();

  return useCallback(
    (permissionKey: PermissionKey, ...args: any[]) => {
      const value = permissions[permissionKey];

      if (typeof value === 'boolean') return value;

      if (typeof value === 'function') {
        // @ts-ignore
        return value(...args);
      }

      return false;
    },
    [permissions]
  );
};

export default useCanUser;
