import { Backdrop, CircularProgress } from 'elements';

interface Props {
  loading: boolean;
}

const InitialLoader = ({ loading }: Props) => {
  return (
    <Backdrop
      open={loading}
      sx={(theme) => ({
        zIndex: 5000,
        background: theme.variables.navigation.light.background,
      })}
    >
      <CircularProgress size="large" />
    </Backdrop>
  );
};

export default InitialLoader;
