import { memo } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

type Variant = 'filled' | 'tinted';

type Color =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

const getTintedCssColorAndBackground = (color: Color, theme: Theme) => {
  switch (color) {
    case 'default':
      return {
        color: theme.variables.alert.defaultContent,
        background: theme.variables.alert.defaultFill,
      };
    case 'primary':
      return {
        color: theme.variables.primary.main,
        background: theme.variables.states.primary.transparentBackground,
      };
    case 'secondary':
      return {
        color: theme.variables.alert.secondaryContent,
        background: theme.variables.alert.secondaryFill,
      };
    case 'success':
      return {
        color: theme.variables.alert.successContent,
        background: theme.variables.alert.successFill,
      };
    case 'error':
      return {
        color: theme.variables.alert.errorContent,
        background: theme.variables.alert.errorFill,
      };
    case 'info':
      return {
        color: theme.variables.alert.infoContent,
        background: theme.variables.alert.infoFill,
      };
    case 'warning':
      return {
        color: theme.variables.alert.warningContent,
        background: theme.variables.alert.warningFill,
      };
  }
};

const getCssColorAndBackground = (
  variant: Variant,
  color: Color,
  theme: Theme
) => {
  if (variant === 'tinted') {
    return getTintedCssColorAndBackground(color, theme);
  } else {
    if (color === 'default')
      return {
        color: theme.palette.primary.contrastText,
        background: theme.variables.alert.defaultContent,
      };
    return {
      color: theme.palette[color].contrastText,
      background: theme.palette[color].main,
    };
  }
};

export interface StatusBadgeProps {
  label: string;
  variant?: Variant;
  color?: Color;
  sx?: SxProps<Theme>;
}

const StatusBadge = ({
  label,
  variant = 'tinted',
  color = 'default',
  sx = [],
}: StatusBadgeProps) => {
  return (
    <Box
      sx={[
        (theme) => ({
          display: 'inline-block',
          verticalAlign: 'middle',
          px: 1,
          py: 0.5,
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          ...theme.typography.overline,
          lineHeight: '12px',
          borderRadius: '8px',
          ...getCssColorAndBackground(variant, color, theme),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {label}
    </Box>
  );
};

export default memo(StatusBadge);
