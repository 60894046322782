import React from 'react';
import MuiRadio, { RadioProps as MuiRadioProps } from '@material-ui/core/Radio';
import styled, { useTheme } from 'styled-components';

interface RadioIconProps {
  checked?: Boolean;
}

const RadioIcon = ({ checked = false }: RadioIconProps) => {
  const { colors } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={colors[checked ? 'neutralVariant80' : 'neutralVariant100']} // outer circle background
        stroke={colors.neutralVariant50} // outer circle border
        d="M12,0.5 C15.1756373,0.5 18.0506373,1.78718134 20.131728,3.86827202 C22.2128187,5.94936269 23.5,8.82436269 23.5,12 C23.5,15.1756373 22.2128187,18.0506373 20.131728,20.131728 C18.0506373,22.2128187 15.1756373,23.5 12,23.5 C8.82436269,23.5 5.94936269,22.2128187 3.86827202,20.131728 C1.78718134,18.0506373 0.5,15.1756373 0.5,12 C0.5,8.82436269 1.78718134,5.94936269 3.86827202,3.86827202 C5.94936269,1.78718134 8.82436269,0.5 12,0.5 Z"
      />
      {checked && (
        <path
          fill={colors.grey10} // inner circle background
          d="M12,4 C16.418278,4 20,7.581722 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z"
        />
      )}
    </svg>
  );
};

export const Radio = styled((props: MuiRadioProps) => (
  <MuiRadio
    disableRipple
    icon={<RadioIcon />}
    checkedIcon={<RadioIcon checked />}
    {...props}
  />
))`
  &:hover {
    background: transparent !important;

    &:not(.Mui-checked) path {
      stroke: ${({ theme }) => theme.colors.grey10};
    }
  }

  &.Mui-disabled {
    opacity: 0.3;
  }
`;
