import { useTranslation } from 'react-i18next';
import {
  BookIcon,
  FolderNotchIcon,
  GavelIcon,
  SuitcaseSimpleIcon,
} from 'elements';
import { useCanUser } from 'services/rbac';
import { Tab } from './useTabs';

const useInternalTabs = (): Tab[] => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const tabs: Tab[] = [];

  if (canUser('credit-and-compliance-page:visit')) {
    tabs.push({
      label: t('int.sidebar.creditAndCompliance'),
      pathId: 'creditAndCompliance',
      testId: 'credit-and-compliance-tab',
      Icon: GavelIcon,
    });
  }

  if (canUser('settlements-page:visit')) {
    tabs.push({
      label: t('int.sidebar.settlements'),
      pathId: 'settlements',
      testId: 'settlements-tab',
      Icon: BookIcon,
    });
  }

  if (canUser('processing-account-page:visit')) {
    tabs.push({
      label: t('int.sidebar.processingAccount'),
      pathId: 'processingAccount',
      testId: 'processing-account-tab',
      Icon: SuitcaseSimpleIcon,
    });
  }

  if (
    canUser('general-terms-page:visit') ||
    canUser('subscription-settings-page:visit') ||
    canUser('cashback-settings-page:visit')
  ) {
    const subTabs = [];

    if (canUser('general-terms-page:visit')) {
      subTabs.push({
        label: t('int.sidebar.terms.general'),
        pathId: 'termsGeneral',
        testId: 'terms-general-sub-tab',
      });
    }

    if (canUser('subscription-settings-page:visit')) {
      subTabs.push({
        label: t('int.sidebar.terms.subscriptionSettings'),
        pathId: 'termsSubscriptionSettings',
        testId: 'terms-subscription-settings-sub-tab',
      });
    }

    if (canUser('cashback-settings-page:visit')) {
      subTabs.push({
        label: t('int.sidebar.terms.cashbackSettings'),
        pathId: 'termsCashbackSettings',
        testId: 'terms-cashback-settings-sub-tab',
      });
    }

    tabs.push({
      label: t('int.sidebar.terms.title'),
      pathId: subTabs[0].pathId,
      testId: 'terms-tab',
      Icon: FolderNotchIcon,
      items: subTabs,
    });
  }

  return tabs;
};

export default useInternalTabs;
