import { useTranslation } from 'react-i18next';
import {
  Box,
  CloudXIcon,
  DeviceMobileCameraIcon,
  EnvelopeSimpleOpenIcon,
  Paper,
  PasswordIcon,
  Typography,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import { Content, Overlay } from './style';

const BOXES = [
  {
    icon: EnvelopeSimpleOpenIcon,
    key: 'registrationBox',
  },
  {
    icon: PasswordIcon,
    key: 'passwordBox',
  },
  {
    icon: DeviceMobileCameraIcon,
    key: 'phoneNumberBox',
  },
];

const UnauthorizedError = () => {
  const { t } = useTranslation();
  const { isInternalApp } = useCurrentApp();

  return (
    <Overlay>
      {isInternalApp ? (
        <Content>
          <CloudXIcon
            fontSize="large"
            sx={{ verticalAlign: 'top', mb: 1, color: 'text.secondary' }}
          />
          <Typography variant="h6" mb={1}>
            {t('errorOverlay.internalUnauthorizedError.heading')}
          </Typography>
          <Typography color="text.secondary" mb={2}>
            {t('errorOverlay.internalUnauthorizedError.subheading')}
          </Typography>
        </Content>
      ) : (
        <Box my={3} textAlign="center">
          <Typography variant="h6" mb={2}>
            {t('errorOverlay.unauthorizedError.heading')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t('errorOverlay.unauthorizedError.subheading')}
          </Typography>

          <Box display="flex" justifyContent="center" my={10}>
            {BOXES.map(({ key, icon: Icon }, i) => (
              <Paper
                key={key}
                variant="tinted"
                sx={{
                  flexShrink: 0,
                  width: '280px',
                  p: 2,
                  mx: 2,
                }}
              >
                <Icon fontSize="large" sx={{ mt: 2 }} />
                <Typography variant="subtitle1" mb={1}>
                  {t(`errorOverlay.unauthorizedError.${key}.title`)}
                </Typography>
                <Typography variant="body2" color="text.secondary" mb={2}>
                  {t(`errorOverlay.unauthorizedError.${key}.subtitle`)}
                </Typography>
              </Paper>
            ))}
          </Box>

          <Typography variant="body2" color="text.secondary">
            {t('errorOverlay.unauthorizedError.footer')}
          </Typography>
        </Box>
      )}
    </Overlay>
  );
};

export default UnauthorizedError;
