import { AxiosResponse } from 'axios';
import { downloadFileUsingAnchorTag } from 'services/utils/file';
import { getFileNameFromHeader } from 'services/utils/network';

export const downloadResponseAsFile = (
  response: AxiosResponse<string>,
  customFileName?: string
) => {
  const fileName = customFileName || getFileNameFromHeader(response.headers);
  const contentType = response.headers['content-type'];

  const objectURL = URL.createObjectURL(
    new Blob([response.data], { type: contentType })
  );

  return downloadFileUsingAnchorTag(fileName, objectURL);
};
