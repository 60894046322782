import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'elements';

const DataItemRow = ({
  label,
  value,
  updatedAt,
  updatedBy,
  readOnlyValue = false,
  alwaysShowMetaInfo = false,
}: {
  label: string;
  value: React.ReactNode;
  updatedAt: string | null;
  updatedBy: string | null;
  readOnlyValue?: boolean;
  alwaysShowMetaInfo?: boolean;
}) => {
  const { t } = useTranslation();
  const [showMetaInfo, setShowMetaInfo] = useState(false);

  return (
    <Grid item display="flex" position="relative">
      <Typography variant="body2" maxWidth="200px" minWidth="200px">
        {label}
      </Typography>
      <Typography
        color={readOnlyValue ? 'textSecondary' : 'textPrimary'}
        variant="body2"
        whiteSpace="pre"
        component="div"
        onMouseEnter={() => setShowMetaInfo(true)}
        onMouseLeave={() => setShowMetaInfo(false)}
      >
        {value || typeof value === 'number' ? value : '-'}
      </Typography>

      {(alwaysShowMetaInfo || showMetaInfo) && (
        <Typography
          position="absolute"
          right="40px"
          top="0"
          color="textSecondary"
          lineHeight="1.2"
          marginLeft="auto"
          variant="caption"
          whiteSpace="pre"
        >
          {updatedAt && updatedBy
            ? t('int.creditAndCompliancePage.dataItemRow.lastChanged', {
                updatedAt: moment(updatedAt).format('DD.MM.YYYY HH:mm'),
                updatedBy,
              })
            : '-'}
        </Typography>
      )}
    </Grid>
  );
};

export default DataItemRow;
