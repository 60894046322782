import { QuestionIcon } from 'elements/TemporaryIcon';
import Tooltip, { TooltipProps } from 'elements/Tooltip';

export interface InputLabelTooltipIconProps
  extends Omit<TooltipProps, 'children'> {}

export const InputLabelTooltipIcon = (props: InputLabelTooltipIconProps) => (
  <Tooltip {...props}>
    <QuestionIcon
      sx={{
        width: 16,
        height: 16,
        position: 'absolute',
        top: '-2px',
        left: 'calc(100% + 2px)',
      }}
    />
  </Tooltip>
);

export interface FormControlLabelTooltipIconProps
  extends Omit<TooltipProps, 'children'> {}

export const FormControlLabelTooltipIcon = (
  props: FormControlLabelTooltipIconProps
) => (
  <Tooltip {...props}>
    <QuestionIcon
      sx={(theme) => ({
        width: 16,
        height: 16,
        ml: 0.5,
        color: theme.palette.text.secondary,
      })}
    />
  </Tooltip>
);
