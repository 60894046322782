import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { Box, Typography } from 'elements';
import { AccountingTransaction } from 'services/constants';

interface Props {
  accountingTransaction: AccountingTransaction;
  children: ReactNode;
}

const AccountingTransactionItem = ({
  accountingTransaction,
  children,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={(theme) => ({
        '&+&': {
          mt: theme.spacing(5),
          pt: theme.spacing(4),
          borderTop: `1px solid ${theme.palette.divider}`,
        },
      })}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="overline" color="textSecondary" noWrap>
          {t('transactionDetails.splitTransactions')}{' '}
          {accountingTransaction.index + 1}
        </Typography>

        <Box textAlign="right" overflow="hidden">
          <Typography component="div" variant="subtitle1" noWrap>
            <FormatMoney
              value={accountingTransaction.amount}
              fractionalPart
              positiveSign
            />
          </Typography>
          {accountingTransaction.amount.currency !==
            accountingTransaction.fxAmount.currency && (
            <Typography
              component="div"
              color="textSecondary"
              variant="caption"
              noWrap
            >
              <FormatMoney
                value={accountingTransaction.fxAmount}
                fractionalPart
                positiveSign
              />
            </Typography>
          )}
        </Box>
      </Box>

      {children}
    </Box>
  );
};

export default AccountingTransactionItem;
