import React from 'react';
import { useGlobalState } from 'context/GlobalState';
import CreditAssessmentSummarySection from 'domains/creditAndCompliance/pages/CreditPage/AssessmentSubPage/CreditAssessmentSummarySection';
import { OrganizationStatus } from 'services/constants';
import { useCanUser } from 'services/rbac';
import AgencyProfileSection from './AgencyProfileSection';
import CustomerSummarySection from './CustomerSummarySection';
import DeactivateOrganizationSection from './DeactivateOrganizationSection';
import FirmographicsSection from './FirmographicsSection';

const ProfileSubPage = () => {
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();

  return (
    <>
      <CreditAssessmentSummarySection />
      <CustomerSummarySection />
      <AgencyProfileSection />
      <FirmographicsSection />

      {canUser('org:deactivate') &&
        organization!.status === OrganizationStatus.active && (
          <DeactivateOrganizationSection />
        )}
    </>
  );
};

export default ProfileSubPage;
