import { chain, sortBy } from 'lodash';
import {
  OnboardingDocumentCategory,
  OnboardingDocumentType,
} from 'services/constants';

const customSort = (items: string[]) =>
  sortBy(items, [
    (item) => item.endsWith('_CUSTOM'),
    (item) => item.endsWith('_OTHER'),
    (item) => item,
  ]);

export const generatateOnboardingDocumentTypeGroups = (
  onboardingDocumentTypes: OnboardingDocumentType[]
) => {
  const onboardingDocumentTypesByCategory = chain(onboardingDocumentTypes)
    .groupBy('category')
    .value();

  const onboardingDocumentTypeNamesByCategoryAndSubcategory = {
    [OnboardingDocumentCategory.COMPLIANCE]: chain(
      onboardingDocumentTypesByCategory[OnboardingDocumentCategory.COMPLIANCE]
    )
      .groupBy('subcategory')
      .reduce(
        (acc, curr) => ({
          ...acc,
          [curr[0].subcategory ?? 'COMPLIANCE_OTHER']: customSort(
            curr.map((item) => item.name)
          ),
        }),
        {}
      )
      .value(),
    [OnboardingDocumentCategory.RISK]: chain(
      onboardingDocumentTypesByCategory[OnboardingDocumentCategory.RISK]
    )
      .groupBy('subcategory')
      .reduce(
        (acc, curr) => ({
          ...acc,
          [curr[0].subcategory ?? 'RISK_OTHER']: customSort(
            curr.map((item) => item.name)
          ),
        }),
        {}
      )
      .value(),
  };

  const onboardingDocumentTypeByName = onboardingDocumentTypes.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.name]: curr,
    }),
    {}
  );

  const complianceOnboardingDocumentTypeSubcategories = customSort(
    Object.keys(
      onboardingDocumentTypeNamesByCategoryAndSubcategory[
        OnboardingDocumentCategory.COMPLIANCE
      ]
    )
  );

  const riskOnboardingDocumentTypeSubcategories = customSort(
    Object.keys(
      onboardingDocumentTypeNamesByCategoryAndSubcategory[
        OnboardingDocumentCategory.RISK
      ]
    )
  );

  const onboardingDocumentTypeNamesBySubcategory = {
    ...onboardingDocumentTypeNamesByCategoryAndSubcategory[
      OnboardingDocumentCategory.COMPLIANCE
    ],
    ...onboardingDocumentTypeNamesByCategoryAndSubcategory[
      OnboardingDocumentCategory.RISK
    ],
  };

  return {
    complianceOnboardingDocumentTypeSubcategories,
    onboardingDocumentTypeByName,
    onboardingDocumentTypeNamesBySubcategory,
    riskOnboardingDocumentTypeSubcategories,
  };
};
