import styled from 'styled-components';
import { Colors } from 'components/App';

export const Avatar = styled.div<{
  $size: number;
  $backgroundColor?: keyof Colors;
}>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 6px;
  background: ${({ theme, $backgroundColor }) =>
    theme.colors[$backgroundColor || 'white']};
  color: ${({ theme }) => theme.colors.grey10};
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant80};

  & > svg,
  & > span {
    margin: auto;
  }
`;
