import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Box, LoadingButton, Typography } from 'elements';
import useDelayedDisabled from 'hooks/useDelayedDisabled';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const PasswordSection = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [delayedDisabled, onDelayedDisable] = useDelayedDisabled(
    'changePassword'
  );
  const {
    state: { member },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = async () => {
    setIsLoading(true);
    try {
      const data = await api.resetMemberPassword(member.id);
      if (!mounted.current) return;
      setIsLoading(false);
      if (typeof data === 'string') {
        enqueueSnackbar(t('profilePage.changePasswordSuccessMessage'));
        onDelayedDisable();
      }
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Box mb={8}>
      <Typography variant="h6" mb={1}>
        {t('profilePage.passwordSection.title')}
      </Typography>
      <Typography mb={3}>
        {t('profilePage.passwordSection.description')}
      </Typography>
      <LoadingButton
        onClick={resetPassword}
        loading={isLoading}
        disabled={delayedDisabled}
        size="large"
        variant="outlined"
      >
        {t('profilePage.passwordSection.changePassword')}
      </LoadingButton>
    </Box>
  );
};

export default PasswordSection;
