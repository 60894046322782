import React, { ComponentType, FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LoaderWithOverlay } from 'elements';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { SettlementGroup } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

interface State {
  isLoading: boolean;
  settlementGroups: SettlementGroup[];
}

const withInitialData = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const location = useLocation();
  const showPageError = useShowPageError();
  const setQueryParam = useSetQueryParam();
  const [state, setState] = useState<State>({
    isLoading: true,
    settlementGroups: [],
  });
  const { settlementGroupId } = Object.fromEntries(
    new URLSearchParams(location.search).entries()
  );

  const getData = async () => {
    try {
      const { settlementGroups } = await api.getSettlementGroups();
      if (!mounted.current) return;
      const settlementGroupIdList = settlementGroups.map((group) => group.id);
      if (
        !settlementGroupId ||
        !settlementGroupIdList.includes(settlementGroupId)
      ) {
        setQueryParam('settlementGroupId', settlementGroupIdList[0]);
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        settlementGroups,
      }));
    } catch (error) {
      if (!mounted.current) return;
      showPageError();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (state.isLoading) return <LoaderWithOverlay loading />;

  return <Component {...props} settlementGroups={state.settlementGroups} />;
};

export default withInitialData;
