import {
  AccountingSettings,
  AccountingSystem,
  DatevExportFormatOption,
  ExportFormat,
} from 'services/constants';

export const isAccSystemDownloadVisible = (
  accountingSettings: AccountingSettings | null,
  accountingSystem: AccountingSystem
) =>
  accountingSettings?.accountingSystem === accountingSystem &&
  accountingSettings?.useAccountingExport;

export const datevExportFormatsToOption = (
  exportFormats: ExportFormat[]
): DatevExportFormatOption => {
  if (exportFormats.includes(ExportFormat.api)) {
    return 'API';
  } else if (
    exportFormats.includes(ExportFormat.csv) &&
    exportFormats.includes(ExportFormat.xml)
  ) {
    return 'CSV&XML';
  } else if (exportFormats.includes(ExportFormat.csv)) {
    return 'CSV';
  } else {
    return 'XML';
  }
};

export const optionToDatevExportFormats = (value: DatevExportFormatOption) => {
  if (value === 'CSV&XML') return [ExportFormat.csv, ExportFormat.xml];
  if (value === 'API') return [ExportFormat.api];
  if (value === 'CSV') return [ExportFormat.csv];
  return [ExportFormat.xml];
};
