import { Link } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { downloadResponseAsFile, getGenericErrorMsg } from 'services/utils';

interface Props {
  transactionId: string;
  children: string;
}

const DownloadPaymentFileCell = ({ transactionId, children }: Props) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();

  const handleDownloadFile = async () => {
    try {
      const response = await api.downloadFile(
        `/account-statements/transactions/${transactionId}/file`,
        {},
        { responseType: 'arraybuffer' }
      );
      if (!mounted.current) return;
      downloadResponseAsFile(response);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Link variant="body2" component="button" onClick={handleDownloadFile}>
      {children}
    </Link>
  );
};

export default DownloadPaymentFileCell;
