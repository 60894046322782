import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  FiltersContainer,
  HeaderContainer,
  HeaderTitle,
} from 'domains/settings/layout';
import {
  Box,
  Button,
  DataGrid,
  GridSortModel,
  gridUtils,
  Link,
  LoaderWithOverlay,
  Typography,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, PageSearchInput } from 'layout';
import {
  CardAccountSettings,
  CardAccountStatus,
  DEFAULT_PAGE_LIMIT,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getPath } from 'services/utils';
import AssignEditAccountNumberDialog from './AssignEditAccountNumberDialog';
import { StatusFilterSelect } from './StatusFilterSelect';
import useColumns from './useColumns';

const INITIAL_SORT = '+cardAccountName';

interface State {
  isLoading: boolean;
  cardAccountSettings: CardAccountSettings[];
  hasNextPage: boolean;
  totalCount: number;
  isAssignEditDialogOpen: boolean;
  accountToEdit: CardAccountSettings | null;
  activeFilter: CardAccountStatus | '';
  search: string;
  sort: string;
}

const CardAccountsSubPage = () => {
  const { t } = useTranslation();
  const pageRef = useRef(0);
  const canUser = useCanUser();
  const dataGridRef = useGridApiRef();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();

  const [state, setState] = useState<State>({
    isLoading: true,
    cardAccountSettings: [],
    hasNextPage: false,
    totalCount: 0,
    isAssignEditDialogOpen: false,
    accountToEdit: null,
    activeFilter: CardAccountStatus.active,
    search: '',
    sort: INITIAL_SORT,
  });
  const { columns } = useColumns((item) =>
    setState((prevState) => ({
      ...prevState,
      isAssignEditDialogOpen: true,
      accountToEdit: item,
    }))
  );

  const getData = async (isLoadMore = false) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const {
        cardAccountSettings,
        hasNextPage,
        totalCount,
      } = await api.getCardAccountsSettings({
        organizationId: organization!.id,
        page: pageRef.current!,
        limit: DEFAULT_PAGE_LIMIT,
        q: state.search || undefined,
        sort: state.sort,
        status: state.activeFilter || undefined,
      });

      if (!mounted.current) return;

      setState((prevState) => ({
        ...prevState,
        cardAccountSettings: isLoadMore
          ? [...state.cardAccountSettings, ...cardAccountSettings]
          : cardAccountSettings,
        hasNextPage,
        totalCount,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    pageRef.current = 0;
    getData();
  }, [state.activeFilter, state.search, state.sort]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(true);
  };

  const handleSortModelChange = (sort: GridSortModel) => {
    if (state.isLoading || !state.cardAccountSettings.length) return;
    setState((prevState) => ({
      ...prevState,
      sort: gridUtils.getNewSortParam(sort) || INITIAL_SORT,
    }));
  };

  return (
    <>
      <HeaderContainer>
        <ContentContainer>
          <HeaderTitle>{t('cardAccountsSubPage.mainTitle')}</HeaderTitle>
          <Typography component="div">
            <Trans
              i18nKey="cardAccountsSubPage.title"
              components={{
                div: <div />,
                linkTo: (
                  <Link
                    component={RouterLink}
                    to={generatePath(getPath('cardAccounts'), {
                      orgId: organization!.id,
                    })}
                    color="inherit"
                  />
                ),
              }}
            />
          </Typography>
        </ContentContainer>
      </HeaderContainer>

      <FiltersContainer>
        <PageSearchInput
          sx={{ mr: 2 }}
          initialValue={state.search}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, search: value }))
          }
          disabled={!state.search && !state.cardAccountSettings.length}
        />

        <StatusFilterSelect
          filterValue={state.activeFilter}
          onFilterChange={(activeFilter) =>
            setState((prevState) => ({ ...prevState, activeFilter }))
          }
        />

        {canUser('card-account-settings:update') && (
          <Box ml="auto">
            <Button
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  isAssignEditDialogOpen: true,
                  accountToEdit: null,
                }))
              }
            >
              {t('cardAccountsSubPage.assignAccountNumberBtn')}
            </Button>
          </Box>
        )}
      </FiltersContainer>

      <Box
        position="relative"
        flex={1}
        maxHeight={(theme) => `calc(100% + ${theme.spacing(5)})`}
      >
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid
          apiRef={dataGridRef}
          getRowId={(row) => row.cardAccountId}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          rowCount={state.totalCount}
          loading={state.isLoading}
          rows={state.cardAccountSettings}
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(INITIAL_SORT),
            },
          }}
          onSortModelChange={handleSortModelChange}
          columns={columns}
          columnVisibilityModel={{
            actions: canUser('card-account-settings:update'),
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          slots={{
            noRowsOverlay: () => <NothingFound $top={0} $bottom={0} />,
            loadingOverlay: () => null,
          }}
        />
      </Box>

      <AssignEditAccountNumberDialog
        accountToEdit={state.accountToEdit}
        open={state.isAssignEditDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isAssignEditDialogOpen: false,
          }))
        }
        onSuccess={() => {
          if (dataGridRef.current && !state.isLoading)
            dataGridRef.current.scroll({ left: 0, top: 0 });
          pageRef.current = 0;
          getData();
        }}
      />
    </>
  );
};

export default withPageConfig(CardAccountsSubPage, {
  permission: 'card-accounts-sub-page:visit',
  featureModule: (featureModules) =>
    featureModules.MULTI_CARD_ACCOUNT || featureModules.MULTI_CURRENCY_BILLING,
});
