import React, { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

const StyledStatusBadge = styled.div<{ $color: string; $ml?: number }>`
  position: relative;
  padding: 0 0 0 13px;
  margin: 0 6px 0 ${({ $ml }) => (typeof $ml === 'number' ? $ml : 6)}px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey10};

  &:before {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${({ $color }) => $color};
  }
`;

export type StatusBadgeType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'warn'
  | 'dark'
  | 'default';

const useIndicatorColor = (type: StatusBadgeType) => {
  const { colors } = useTheme();

  if (type === 'primary') return colors.financeBlue10;
  if (type === 'secondary') return colors.red10;
  if (type === 'success') return colors.financeGreen10;
  if (type === 'info') return colors.yellow50;
  if (type === 'warn') return colors.orange10;
  if (type === 'dark') return colors.grey50;
  return colors.grey100;
};

export interface StatusBadgeProps {
  type: StatusBadgeType;
  children?: ReactNode;
  className?: string;
  $ml?: number;
}

/** @deprecated */
export const StatusBadge = ({ type, ...rest }: StatusBadgeProps) => {
  const color = useIndicatorColor(type);

  return <StyledStatusBadge $color={color} {...rest} />;
};
