import { EnhancedColumn } from 'components/DataTable';

export const getAllowedSortKeys = (columns: EnhancedColumn[]) =>
  columns
    .filter((column) => !column.disableSortBy)
    .map((column) => column.accessor) as string[];

const allowedSortPrefixes = ['+', '-'];

export const isSortValid = (
  sort: string | undefined,
  allowedSortKeys: string[]
) => {
  if (!sort) return false;

  const prefix = sort.substr(0, 1);
  const key = sort.substr(1);

  return allowedSortPrefixes.includes(prefix) && allowedSortKeys.includes(key);
};

export const getFilterNullableEntries = <
  P extends { [key: string]: unknown },
  K extends keyof P
>(
  params: P,
  ...ignoredKeys: K[] | []
): [string, null][] => {
  const allIgnoredKeys = ['q', 'sort', ...ignoredKeys];
  return Object.keys(params)
    .filter((key) => !allIgnoredKeys.includes(key))
    .map((key) => [key, null]);
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getNestedObjectValues = (
  obj: any
): (string | number | boolean)[] =>
  obj && typeof obj === 'object'
    ? Object.values(obj).map(getNestedObjectValues).flat()
    : [obj];
