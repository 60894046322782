import { styled } from '@mui/material';
import { Box } from 'elements';

export const WarningContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(0, 3),

  '&+&': {
    marginTop: theme.spacing(1),
  },
}));
