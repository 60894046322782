import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { CashbackPaymentBatchDetailsPage } from 'domains/billing/pages';
import {
  DataGrid,
  FileTextIcon,
  GridSortModel,
  gridUtils,
  LoaderWithOverlay,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  accountGroups,
  CashbackRedemptionBatch,
  cashbackRedemptionStatuses,
  DEFAULT_PAGE_LIMIT,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getValidQueryParamValue,
  getValidQueryParamValues,
  isSortValid,
} from 'services/utils';
import Filters from './Filters';
import useColumns from './useColumns';

const getQueryParams = (qs: string, allowedSortKeys: string[]) => {
  const {
    sort,
    createdDateFrom,
    createdDateTo,
    paymentDateFrom,
    paymentDateTo,
    accountGroup,
    states,
  } = Object.fromEntries(new URLSearchParams(qs).entries());

  const fromCreatedDateMoment = moment(createdDateFrom, moment.ISO_8601);
  const toCreatedDateMoment = moment(createdDateTo, moment.ISO_8601);

  const fromPaymentDateMoment = moment(paymentDateFrom, moment.ISO_8601);
  const toPaymentDateMoment = moment(paymentDateTo, moment.ISO_8601);

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '-createdAt',
    createdDateFrom: fromCreatedDateMoment.isValid()
      ? fromCreatedDateMoment
      : null,
    createdDateTo: toCreatedDateMoment.isValid() ? toCreatedDateMoment : null,
    paymentDateFrom: fromPaymentDateMoment.isValid()
      ? fromPaymentDateMoment
      : null,
    paymentDateTo: toPaymentDateMoment.isValid() ? toPaymentDateMoment : null,
    accountGroup: getValidQueryParamValue(accountGroup, accountGroups),
    states: getValidQueryParamValues(states, cashbackRedemptionStatuses),
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({
  createdDateFrom,
  paymentDateFrom,
  accountGroup,
  states,
}: QueryParams) =>
  0 +
  (createdDateFrom ? 1 : 0) +
  (paymentDateFrom ? 1 : 0) +
  (accountGroup ? 1 : 0) +
  (states.length ? 1 : 0);

interface State {
  isLoading: boolean;
  batches: CashbackRedemptionBatch[];
  hasNextPage: boolean;
}

const CashbackPaymentBatchesPage = () => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:batchId',
    true
  );
  const { allowedSortKeys, columns } = useColumns();
  const api = useImperativeApi();
  const mounted = useMounted();
  const showPageError = useShowPageError();
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(getQueryParams(location.search, allowedSortKeys));
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const [state, setState] = useState<State>({
    isLoading: true,
    batches: [],
    hasNextPage: false,
  });
  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied = !!selectedFiltersCount;
  const isEmptyState =
    !state.isLoading && !state.batches.length && !areFiltersApplied;

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      if (!isLoadMore) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
      }
      const {
        sort,
        createdDateFrom,
        createdDateTo,
        paymentDateFrom,
        paymentDateTo,
        accountGroup,
        states,
      } = paramsRef.current;
      const { batches, hasNextPage } = await api.getCashbackRedemptionBatches({
        page,
        limit,
        sort: sort.includes('createdAt') ? sort : `${sort},-createdAt`,
        createdDateFrom: createdDateFrom?.format('YYYY-MM-DD'),
        createdDateTo: createdDateTo?.format('YYYY-MM-DD'),
        paymentDateFrom: paymentDateFrom?.format('YYYY-MM-DD'),
        paymentDateTo: paymentDateTo?.format('YYYY-MM-DD'),
        accountGroup: accountGroup || undefined,
        states: states.length ? states.join() : undefined,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        batches: isLoadMore ? [...prevState.batches, ...batches] : batches,
        hasNextPage,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });
    paramsRef.current = getQueryParams(location.search, allowedSortKeys);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const handleSortModelChange = (sort: GridSortModel) => {
    if (state.isLoading || !state.batches.length) return;
    setQueryParam('sort', gridUtils.getNewSortParam(sort));
  };

  const onBatchUpdated = (batch: CashbackRedemptionBatch) =>
    setState((prevState) => ({
      ...prevState,
      batches: prevState.batches.map((item) =>
        item.id === batch.id ? { ...item, ...batch } : item
      ),
    }));

  return (
    <>
      <PageHeader>
        <PageTitle title={t('int.cashbackPaymentBatchesPage.title')} />
        <Filters
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          disabled={isEmptyState}
        />
      </PageHeader>
      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />
        <DataGrid<CashbackRedemptionBatch>
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          apiRef={dataGridRef}
          rowSelectionModel={
            detailsParams?.batchId ? [detailsParams.batchId] : []
          }
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(paramsRef.current.sort),
            },
          }}
          loading={state.isLoading}
          rows={state.batches}
          columns={columns}
          columnVisibilityModel={{ drawerPlaceholder: isDetailsPageOpen }}
          onSortModelChange={handleSortModelChange}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ id }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${id}${location.search}`);
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.batches.length && areFiltersApplied) {
                return <NothingFound />;
              }
              return (
                <NoData
                  isNewDesign
                  Icon={FileTextIcon}
                  label={t('int.cashbackPaymentBatchesPage.noData')}
                />
              );
            },
            loadingOverlay: () => null,
          }}
          rowHeight={72}
        />
        <Route
          path={`${path}/:batchId`}
          children={({ match }) => (
            <CashbackPaymentBatchDetailsPage
              open={!!match}
              onUpdate={onBatchUpdated}
            />
          )}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(CashbackPaymentBatchesPage, {
  permission: 'cashback-payment-batches-page:visit',
});
