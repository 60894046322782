import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddressDetails from 'components/AddressDetails';
import { useGlobalState } from 'context/GlobalState';
import { Grid, LabeledValue } from 'elements';
import { useCanUser } from 'services/rbac';
import BillingAddressChangeDialog from './BillingAddressChangeDialog';

const BillingAddressGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <LabeledValue
          label={t('settingsOrganizationPage.billingAddress')}
          value={<AddressDetails address={organization!.billingAddress} />}
          onEdit={
            canUser('org-billing-address:change')
              ? () => setIsDialogOpen(true)
              : undefined
          }
        />
      </Grid>

      <BillingAddressChangeDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default BillingAddressGroup;
