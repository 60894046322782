import styled from 'styled-components';
import { Button } from 'components/Button';

export const DescriptionBlock = styled.div`
  font-size: 14px;
  line-height: 26px;
`;

export const ConnectButton = styled(Button)`
  flex-shrink: 0;
  margin-left: 30px;
  margin-bottom: 10px;
`;
