import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilePdfIcon, IconButton, QuestionIcon, Tooltip } from 'elements';
import useDownloadFile from 'hooks/useDownloadFile';
import {
  HistoryCashbackRedemption,
  HistoryCashbackRedemptionStatus,
  HistoryCashbackRedemptionType,
} from 'services/constants';

interface Props {
  redemption: HistoryCashbackRedemption;
}

const ReceiptCell = ({ redemption }: Props) => {
  const { t } = useTranslation();
  const downloadReceipt = useDownloadFile(
    `/documents/${redemption.receiptId}/content`,
    {},
    { responseType: 'arraybuffer' }
  );

  if (!redemption.receiptId) {
    return redemption.type === HistoryCashbackRedemptionType.carbonOffset &&
      redemption.state === HistoryCashbackRedemptionStatus.paid ? (
      <Tooltip
        title={t('historyCashbackRedemptionsDialog.offsetReceiptTooltip')}
      >
        <QuestionIcon fontSize="small" sx={{ color: 'text.secondary' }} />
      </Tooltip>
    ) : null;
  }

  return (
    <IconButton onClick={downloadReceipt}>
      <FilePdfIcon fontSize="small" />
    </IconButton>
  );
};

export default ReceiptCell;
