import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, MenuItem, Select, Typography } from 'elements';
import CategorySelect from './CategorySelect';
import {
  GlAccountsStepsEnum,
  useGLAccountsSyncContext,
} from './useGLAccountsSyncContext';

interface Props {
  selectedCount: number;
  visibleAccountsCount: number;
}

const TableControls = ({ selectedCount, visibleAccountsCount }: Props) => {
  const { t } = useTranslation();
  const {
    state: {
      accounts,
      accountsTypeFilterValue,
      selectedSyncedAccountIds,
      innerStep,
      defaultCategory,
    },
    actions: { setFilterValue, onDefaultCategoryChange },
  } = useGLAccountsSyncContext();

  const accountTypes = useMemo(() => {
    if (!accounts?.length) return [];
    const types = accounts.map((account) => account.accountType);
    return Array.from(new Set(types)).sort();
  }, [accounts]);

  return (
    <Box display="flex" alignItems="center" my={3}>
      <Typography color="text.secondary">
        {t('codatSyncSetupDialog.controls.selectedLabel', {
          value: `${selectedCount} / ${visibleAccountsCount}`,
        })}
      </Typography>

      {innerStep === GlAccountsStepsEnum.selectAccountsStep ? (
        <FormControl sx={{ width: 296, marginLeft: 'auto' }}>
          <Select
            value={accountsTypeFilterValue}
            renderValue={(selected) => {
              if (selected === 'all')
                return t('codatSyncSetupDialog.controls.showAllOption');
              return selected;
            }}
            onChange={(e) => setFilterValue(e.target.value)}
          >
            <MenuItem value="all">
              {t('codatSyncSetupDialog.controls.showAllOption')}
            </MenuItem>
            {accountTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Box sx={{ width: 320, marginLeft: 'auto' }}>
          <CategorySelect
            noneValue="NONE"
            category={defaultCategory}
            disabled={!selectedSyncedAccountIds.length}
            onChange={(category) => onDefaultCategoryChange(category)}
          />
        </Box>
      )}
    </Box>
  );
};

export default TableControls;
