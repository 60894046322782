import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { FilterDrawerClearButton } from 'layout';
import { AccountingItemStatus, Project } from 'services/constants';

interface Props {
  projects: Project[];
  value: string[];
  onChange: (value: string[]) => void;
}

const ProjectFilter = ({ projects, value, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-project"
        id="panel-project-header"
      >
        <Typography>{t('filters.project')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<string[]>
            size="medium"
            multiple
            displayEmpty
            value={value}
            onChange={(e) => onChange(e.target.value as string[])}
            renderValue={(selected) => {
              if (!selected?.length)
                return (
                  <Typography color="text.disabled">
                    {t('filters.project')}
                  </Typography>
                );

              return selected
                .map((projectId) => {
                  const project = projects.find(
                    (item) => item.id === projectId
                  );
                  return project ? project.name : null;
                })
                .filter((item) => !!item)
                .join(', ');
            }}
          >
            {projects.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                <ListItemText
                  primaryTypographyProps={{
                    color:
                      project.status === AccountingItemStatus.inactive
                        ? 'text.disabled'
                        : 'textPrimary',
                  }}
                >
                  {project.name}
                </ListItemText>
              </MenuItem>
            ))}
          </Select>

          {!!value.length && <FilterDrawerClearButton onChange={onChange} />}
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProjectFilter;
