import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import { DatevClient } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { DATEV_CONNECTION_STEPS, SESSION_STORAGE_KEY } from './index';

interface Props extends DialogProps {
  clients: DatevClient[];
  onClose: () => void;
  onError: () => void;
}

interface State {
  datevClientId: string;
  isLoading: boolean;
}

const DatevChooseClientDialog = ({ clients, onError, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const partnerName = usePartnerName();
  const {
    state: { organization },
  } = useGlobalState();

  const [state, setState] = useState<State>({
    datevClientId: '',
    isLoading: false,
  });

  const getDatevAuthRequestOffline = async () => {
    if (state.isLoading) return;
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const { url } = await api.getDatevAuthRequestOffline(
        organization!.id,
        state.datevClientId
      );

      if (!mounted.current) return;
      sessionStorage.setItem(
        SESSION_STORAGE_KEY,
        DATEV_CONNECTION_STEPS.GET_DATEV_AUTH_REQUEST_OFFLINE
      );
      window.location.href = url;
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isConnectionError: true,
      }));
      onError();
      sessionStorage.removeItem(SESSION_STORAGE_KEY);
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('datevSubPage.selectClientDialog.title', { partnerName })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={3}>
          {t('datevSubPage.selectClientDialog.description')}
        </Typography>
        <FormControl fullWidth disabled={state.isLoading}>
          <Select
            name="datevClientId"
            data-test-id="datev-client-select"
            value={state.datevClientId}
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                datevClientId: e.target.value as string,
              }));
            }}
            renderValue={(value) => {
              const selectedClient = clients.find((item) => item.id === value);
              return selectedClient?.name || (value as string);
            }}
            disabled={state.isLoading}
          >
            {clients.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {`${item.name} (${item.consultant_number}/${item.client_number}
                  )`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          onClick={getDatevAuthRequestOffline}
          disabled={!state.datevClientId || state.isLoading}
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(DatevChooseClientDialog);
