import { CustomField, CustomFieldVisibility } from 'services/constants';

export const isTxCustomFieldVisible = (
  txCustomField: CustomField,
  isAdminApp: boolean
) => {
  return isAdminApp
    ? txCustomField.visibility !== CustomFieldVisibility.nowhere
    : txCustomField.visibility === CustomFieldVisibility.adminAndCardholder;
};
