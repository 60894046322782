import { styled } from '@mui/material';
import { Box, BoxProps, Paper, Typography } from 'elements';

interface HeaderContainerProps extends BoxProps {
  children?: React.ReactNode;
}

export const HeaderContainer = ({
  children,
  ...props
}: HeaderContainerProps) => (
  <Box mb={6} {...props}>
    {children}
  </Box>
);

export const HeaderTitle = ({ children }: { children?: React.ReactNode }) => (
  <Typography variant="h6" mb={2} display="flex" alignItems="center">
    {children}
  </Typography>
);

export const FiltersContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  marginBottom: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
}));

export const IntegrationConnectionBlock = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <Paper
    sx={{
      background: (theme) => theme.variables.background.contrast,
      br: 1,
      px: 3,
      py: 4,
      mb: 4,
      textAlign: 'center',
    }}
  >
    {children}
  </Paper>
);

export const IntegrationConnectionLogoWrapper = ({
  children,
  opacity = 1,
}: {
  children?: React.ReactNode;
  opacity?: number | undefined;
}) => {
  return (
    <Box
      mb={3}
      width={160}
      height={35}
      mx="auto"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        opacity,
        svg: {
          maxWidth: '100%',
          maxHeight: '100%',
        },
      }}
    >
      {children}
    </Box>
  );
};
