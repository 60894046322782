import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Box, Grid, LabeledValue, QuestionIcon, Tooltip } from 'elements';
import { useCanUser } from 'services/rbac';
import BillingEmailAddressChangeDialog from './BillingEmailAddressChangeDialog';

const BillingEmailAddressGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <LabeledValue
          label={
            <Box display="flex" alignItems="center">
              {t('settingsOrganizationPage.billingEmailAddress')}
              <Tooltip
                title={t('settingsOrganizationPage.billingEmailAddressTooltip')}
              >
                <QuestionIcon fontSize="small" sx={{ ml: 1 }} />
              </Tooltip>
            </Box>
          }
          value={organization!.billingEmail || '-'}
          onEdit={
            canUser('org-billing-email-address:change')
              ? () => setIsDialogOpen(true)
              : undefined
          }
        />
      </Grid>

      <BillingEmailAddressChangeDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default BillingEmailAddressGroup;
