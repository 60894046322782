import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  ListItemText,
  LoaderWithOverlay,
  MenuItem,
  QuestionIcon,
  Select,
  Switch,
  Tooltip,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import useUrls from 'hooks/useUrls';
import { BookingTextMode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface FormValues {
  bookingTextMode: BookingTextMode;
  amountLogicEnabled: boolean;
  bmdForeignCurrencyHandling: boolean;
}

const ExportSettingsDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, accountingSettings },
  } = useGlobalState();
  const { HELP_CENTER_BMD_EXPORT_URL } = useUrls();

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      bookingTextMode: accountingSettings!.datevBookingTextMode,
      amountLogicEnabled: accountingSettings!.amountLogicEnabled,
      bmdForeignCurrencyHandling: accountingSettings!
        .bmdForeignCurrencyHandling,
    },
    onSubmit: async ({
      bookingTextMode,
      amountLogicEnabled,
      bmdForeignCurrencyHandling,
    }) => {
      try {
        const data = await api.updateAccountingSettings({
          organizationId: organization!.id,
          useAccountingExport: accountingSettings!.useAccountingExport,
          vatRateEnabled: accountingSettings!.vatRateEnabled,
          subcategoryEnabled: accountingSettings!.subcategoryEnabled,
          bookingTextMode,
          amountLogicEnabled,
          bmdForeignCurrencyHandling,
        });

        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { accountingSettings: data },
        });
        if (!mounted.current) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        props.onClose();
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('bmdSubPage.exportSettings.title', {
          name: accountingSettings!.accountingSystemName,
        })}
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="export-settings-change"
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth disabled={formik.isSubmitting}>
                <InputLabel id="booking-text-label">
                  {t('bmdSubPage.exportSettings.bookingText')}
                </InputLabel>
                <Select
                  labelId="booking-text-label"
                  renderValue={(v) => t(`bookingTextMode.${v}`)}
                  {...formik.getFieldProps('bookingTextMode')}
                >
                  {Object.values(BookingTextMode).map((v) => (
                    <MenuItem key={v} value={v}>
                      <ListItemText primary={t(`bookingTextMode.${v}`)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                disabled={formik.isSubmitting}
                checked={formik.values.amountLogicEnabled}
                {...formik.getFieldProps('amountLogicEnabled')}
                control={<Switch />}
                label={
                  <Box display="flex" alignItems="center">
                    {t('bmdSubPage.exportSettings.amountLogic')}
                    <Tooltip
                      title={
                        <Trans
                          i18nKey="bmdSubPage.exportSettings.amountLogicTooltip"
                          components={{
                            linkTo: (
                              <Link
                                href={HELP_CENTER_BMD_EXPORT_URL}
                                rel="noopener noreferrer"
                                target="_blank"
                                color="inherit"
                              />
                            ),
                          }}
                        />
                      }
                    >
                      <QuestionIcon fontSize="small" sx={{ ml: 1 }} />
                    </Tooltip>
                  </Box>
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                disabled={formik.isSubmitting}
                checked={formik.values.bmdForeignCurrencyHandling}
                {...formik.getFieldProps('bmdForeignCurrencyHandling')}
                control={<Switch />}
                label={
                  <Box display="flex" alignItems="center">
                    {t('bmdSubPage.exportSettings.foreignCurrency')}
                    <Tooltip
                      title={t(
                        'bmdSubPage.exportSettings.foreignCurrencyTooltip'
                      )}
                    >
                      <QuestionIcon fontSize="small" sx={{ ml: 1 }} />
                    </Tooltip>
                  </Box>
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          form="export-settings-change"
          type="submit"
          disabled={!formik.dirty || formik.isSubmitting}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(ExportSettingsDialog);
