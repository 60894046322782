import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { TransactionReviewStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const MAX_COMMENT_LENGTH = 200;

interface Props extends DialogProps {
  transactionIds: string[];
  onClose: () => void;
  onSuccess: () => void;
}

const MarkAsResolvedTransactionDialog = ({
  transactionIds,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [comment, setComment] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsUpdating(true);
      await api.updateTransactionsReview({
        transactionIds,
        reviewStatus: TransactionReviewStatus.resolved,
        reviewComment: comment,
      });
      if (!mounted.current) return;
      onSuccess();
      if (transactionIds.length > 1) {
        enqueueSnackbar(
          t('markAsResolvedTransactionDialog.successMessageMultiple')
        );
      } else {
        enqueueSnackbar(t('markAsResolvedTransactionDialog.successMessage'));
      }
    } catch (error) {
      if (!mounted.current) return;
      setIsUpdating(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('markAsResolvedTransactionDialog.title')}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={2}>
          {t('markAsResolvedTransactionDialog.description')}
        </Typography>

        <TextField
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          label={t('markAsResolvedTransactionDialog.commentLabel')}
          helperText={t('errors.maxChars', { count: MAX_COMMENT_LENGTH })}
          inputProps={{ maxLength: MAX_COMMENT_LENGTH }}
          multiline
          minRows={1}
          maxRows={5}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={isUpdating || !comment.trim()} onClick={handleSubmit}>
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={isUpdating} />
    </Dialog>
  );
};

export default withDialogWrapper(MarkAsResolvedTransactionDialog);
