import { forwardRef, ReactNode } from 'react';
import {
  SvgIcon as MuiSvgIcon,
  SvgIconProps as MuiSvgIconProps,
  styled,
} from '@mui/material';

// Temporary SvgIcon component that will be replaced (or deleted) when we align on the final icon set.
// The SvgIcon reusable component with the custom styling is used instead of the global theme overrides
// because the latter approach also changes icons for MUI components (Select, Radio, etc.).

const StyledMuiSvgIcon = styled(MuiSvgIcon)`
  box-sizing: content-box;

  &.MuiSvgIcon-fontSizeSmall {
    font-size: 0.875rem;
    padding: 3px;
  }

  &.MuiSvgIcon-fontSizeMedium {
    font-size: 1.125rem;
    padding: 3px;
  }

  &.MuiSvgIcon-fontSizeLarge {
    font-size: 1.6875rem;
    padding: 4px;
  }
`;

export type SvgIconProps = Omit<MuiSvgIconProps, 'viewBox'>;

const SvgIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
  <StyledMuiSvgIcon viewBox="0 0 48 48" ref={ref} {...props} />
));

// TODO: update IntegrationTypeIcon component and delete this file (or leave it but only for exports)
/**@deprecated Use phosphor icons (phosphor.tsx) or
 * custom phosphor-alike icons with createSvgIcon imported from MUI (custom.tsx) */
export const createSvgIcon = (path: ReactNode) =>
  forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon ref={ref} {...props}>
      {path}
    </SvgIcon>
  ));

export * from './phosphor';
export * from './custom';
