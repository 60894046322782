import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { tacMetadataTypeByDocumentGenericType } from 'domains/creditAndCompliance/pages/CompliancePage/CompanySubPage/LegalSection';
import {
  FormControlLabelTooltipIcon,
  List,
  ListItem,
  Typography,
} from 'elements';
import {
  BillPaymentType,
  DocumentGenericType,
  MandateStatus,
  OrganizationAccountType,
  OrganizationRepresentativeInternalStatus,
  UboType,
} from 'services/constants';

interface Item {
  slug: string;
  provided: boolean;
}

interface Groups {
  [group: string]: Item[];
}

const filterGroups = (groups: Groups) => {
  const filteredGroups: Groups = {};

  for (const groupKey in groups) {
    if (groups.hasOwnProperty(groupKey)) {
      const filteredItems = groups[groupKey].filter((item) => !item.provided);
      if (filteredItems.length) {
        filteredGroups[groupKey] = filteredItems;
      }
    }
  }

  return filteredGroups;
};

const SubmitChecklistTooltip = ({
  isReadyForReview,
}: {
  isReadyForReview: boolean;
}) => {
  const { t } = useTranslation();
  const {
    state: { defaultCardAccount, documents },
  } = useGlobalState();
  const {
    state: {
      complianceCompany,
      creditAssessment,
      creditProfile,
      directDebitInfo,
      representatives,
      riskAndCompliance,
      termsAndConditions,
    },
  } = useCreditAndComplianceContext();
  const customerSummary = creditProfile!.customerSummary!;
  const firmographics = creditProfile!.firmographics!;
  const complianceRating = complianceCompany!.complianceRating;
  const masterData = complianceCompany!.masterData;

  const tacDocumentsWithConfirmations = useMemo(
    () =>
      [
        DocumentGenericType.platformTermsAndConditions,
        DocumentGenericType.bankTermsAndConditions,
        DocumentGenericType.issuerTermsAndConditions,
      ]
        .map((genericType) => {
          const doc = documents?.[genericType]?.[0];
          if (!doc) return null;

          return {
            id: doc.documentId,
            metadata:
              termsAndConditions?.find(
                (metadata) =>
                  metadata.type ===
                  tacMetadataTypeByDocumentGenericType[genericType]
              ) ?? null,
          };
        })
        .filter(Boolean),
    [documents, termsAndConditions]
  );

  const groups = {
    creditCustomerSummary: [
      {
        slug: 'industryCodeType',
        provided: customerSummary.industryCodeType.value === 'NACE',
      },
      {
        slug: 'industryCodeSource',
        provided: !!customerSummary.industryCodeSource.value,
      },
      {
        slug: 'industryCode',
        provided: !!customerSummary.industryCode.value,
      },
      {
        slug: 'internalIndustryType',
        provided: !!customerSummary.internalIndustryType.value,
      },
      {
        slug: 'internalIndustrySubtype',
        provided: !!customerSummary.internalIndustrySubtype.value,
      },
      {
        slug: 'foundationDate',
        provided: !!customerSummary.foundationDate.value,
      },
    ],
    creditAssessment: [
      {
        slug: 'probabilityOfDefault',
        provided: !!creditAssessment!.summary.probabilityOfDefault.value,
      },
      {
        slug: 'selectedUnderwritingCase',
        provided: !!creditAssessment!.underwritingCase.selectedUnderwritingCase
          .value,
      },
    ],
    creditFirmographics: [
      {
        slug: 'annualRevenue',
        provided: !!firmographics.annualRevenue.value,
      },
      {
        slug: 'enterpriseSize',
        provided: !!firmographics.enterpriseSize.value,
      },
      {
        slug: 'numberOfEmployees',
        provided: !!firmographics.numberOfEmployees.value,
      },
      {
        slug: 'balanceSheet',
        provided: !!firmographics.balanceSheet.value,
      },
      {
        slug: 'firmographicsDate',
        provided: !!firmographics.firmographicsDate.value,
      },
    ],
    riskAssessment: [
      {
        slug: 'riskAssessmentCompleted',
        provided: !!riskAndCompliance!.riskAssessment,
      },
    ],
    complianceMasterData: [
      {
        slug: 'registerExcerptDate',
        provided: !!masterData.registerExcerptDate.value,
      },
    ],
    complianceComplianceRating: [
      {
        slug: 'sanctioned',
        provided: typeof complianceRating.sanctioned.value === 'boolean',
      },
      {
        slug: 'pep',
        provided: typeof complianceRating.pep.value === 'boolean',
      },
      {
        slug: 'adverseMedia',
        provided: typeof complianceRating.adverseMedia.value === 'boolean',
      },
      {
        slug: 'highRiskIndustry',
        provided: typeof complianceRating.highRiskIndustry.value === 'boolean',
      },
      {
        slug: 'highRiskCountry',
        provided: typeof complianceRating.highRiskCountry.value === 'boolean',
      },
      {
        slug: 'unusuallyComplexStructure',
        provided:
          typeof complianceRating.unusuallyComplexStructure.value === 'boolean',
      },
      {
        slug: 'complianceRatingValue',
        provided: !!complianceRating.complianceRatingValue.value,
      },
      {
        slug: 'onboardingType',
        provided: !!complianceRating.onboardingType.value,
      },
      {
        slug: 'vgRating',
        provided: !!complianceRating.vgRating.value,
      },
      {
        slug: 'amlScreening',
        provided: !!complianceRating.amlScreening.value,
      },
      {
        slug: 'amlScreeningDate',
        provided: !!complianceRating.amlScreeningDate.value,
      },
      {
        slug: 'sanctionScreening',
        provided: !!complianceRating.sanctionScreening.value,
      },
      {
        slug: 'sanctionScreeningDate',
        provided: !!complianceRating.sanctionScreeningDate.value,
      },
    ],
    complianceIndividuals: [
      {
        slug: 'no_individuals_in_draft',
        provided: representatives.every(
          (rep) =>
            rep.internalStatus.value !==
            OrganizationRepresentativeInternalStatus.draft
        ),
      },
      {
        slug: 'at_least_one_ready_ubo',
        provided: representatives.some(
          (rep) =>
            rep.internalStatus.value ===
              OrganizationRepresentativeInternalStatus.ready &&
            rep.uboType.value !== UboType.notUbo
        ),
      },
    ],
    sddMandate: [
      {
        slug: 'sdd_mandate_signed',
        provided:
          defaultCardAccount!.accountType.value ===
            OrganizationAccountType.prefunded ||
          defaultCardAccount!.billPaymentTransferType.value ===
            BillPaymentType.wireTransfer ||
          directDebitInfo?.mandateStatus === MandateStatus.confirmed,
      },
    ],
    termsAndConditions: [
      {
        slug: 'terms_and_conditions_signed',
        provided: tacDocumentsWithConfirmations.every(
          (doc) => !!doc?.metadata?.acceptedAt
        ),
      },
    ],
  };

  if (isReadyForReview) return null;

  return (
    <FormControlLabelTooltipIcon
      title={
        <List dense>
          <Typography variant="body1">
            {t('int.submitChecklistTooltip.title')}
          </Typography>
          {Object.entries(filterGroups(groups)).map(([slug, group]) => (
            <React.Fragment key={slug}>
              <ListItem>
                {t(`int.submitChecklistTooltip.groups.${slug}`)}
              </ListItem>
              <List
                dense
                sx={{
                  listStylePosition: 'inside',
                  listStyleType: 'disc',
                  paddingTop: 0,
                }}
              >
                {group.map((item) => (
                  <ListItem
                    key={item.slug}
                    sx={{
                      display: 'list-item',
                    }}
                  >
                    {t(`int.submitChecklistTooltip.${item.slug}`)}
                  </ListItem>
                ))}
              </List>
            </React.Fragment>
          ))}
        </List>
      }
    />
  );
};

export default SubmitChecklistTooltip;
