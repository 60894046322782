import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowRightIcon,
  Box,
  Checkbox,
  StatusBadge,
  TableCell,
  TableRow,
} from 'elements';
import { CodatDataItemStatus } from 'services/constants';
import MappingInstanceSelect from './MappingInstanceSelect';
import { CodatTrackingCategoryNestedItem } from './utils';

interface Props {
  isProjectStep?: boolean;
  isPartialFlow?: boolean;
  trackingCategories: CodatTrackingCategoryNestedItem[];
  categoryHashMap: { [id: string]: 'new' | Omit<string, 'new'> };
  selectedIds: string[];
  onChange: (
    categoryId: string,
    selectedId: 'new' | Omit<string, 'new'>
  ) => void;
  onSelect: (categoryId: string, checked: boolean) => void;
}

const NestedTrackingCategoryItems = ({
  isProjectStep = false,
  isPartialFlow,
  trackingCategories,
  categoryHashMap,
  selectedIds,
  onChange,
  onSelect,
}: Props) => {
  const { t } = useTranslation();

  const renderNestedItems = (
    categories: CodatTrackingCategoryNestedItem[],
    indentLevel: number
  ) => {
    // indent size is set per level
    const indentSize = indentLevel * 2;

    const disabledDropdownIds = Object.values(categoryHashMap).filter(
      (item) => item !== 'new'
    ) as string[];

    return categories.map((category) => {
      const isSelected = selectedIds.includes(category.id);

      return (
        <Fragment key={category.id}>
          <TableRow
            data-test-id="list-item"
            role="checkbox"
            aria-checked={isSelected}
            selected={isSelected}
          >
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={isSelected}
                onChange={(e, checked) => onSelect(category.id, checked)}
                inputProps={{
                  'aria-labelledby': `enhanced-table-checkbox-${category.id}`,
                }}
              />
            </TableCell>
            <TableCell
              id={`enhanced-table-checkbox-${category.id}`}
              sx={{ pl: 0 }}
            >
              <Box
                sx={{
                  pl: (theme) => theme.spacing(indentSize),
                  display: 'flex',
                  alignItems: 'center',
                  width: 200,
                }}
              >
                <Box
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {category.name}
                </Box>

                {isPartialFlow &&
                  category.status === CodatDataItemStatus.new && (
                    <StatusBadge
                      sx={{ ml: 1 }}
                      color="secondary"
                      label={t('common.badge.new')}
                    />
                  )}
              </Box>
            </TableCell>
            <TableCell>
              <ArrowRightIcon />
            </TableCell>
            <TableCell>
              <MappingInstanceSelect
                isProjectStep={isProjectStep}
                disabledIds={disabledDropdownIds}
                selected={categoryHashMap[category.id]}
                onChange={(selectedId) => onChange(category.id, selectedId)}
              />
            </TableCell>
          </TableRow>

          {category.children &&
            category.children.length > 0 &&
            renderNestedItems(category.children, indentLevel + 1)}
        </Fragment>
      );
    });
  };

  return <>{renderNestedItems(trackingCategories, 0)}</>;
};

export default NestedTrackingCategoryItems;
