import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneralTileFooter } from 'domains/settings/components';
import {
  StyledAvatar,
  Tile,
} from 'domains/settings/pages/AccountingPage/GeneralSubPage/style';
import {
  Box,
  FormControlLabel,
  Grid,
  StatusBadge,
  Switch,
  Typography,
} from 'elements';
import ConfirmationDialog from './ConfirmationDialog';
import SettingsInformationDialog from './SettingsInformationDialog';
import {
  ChangeableUpdateKey,
  useBasicAccountingSettingsTiles,
} from './useBasicAccountingSettingsTiles';

const BasicAccountingSettingsGroup = () => {
  const { t } = useTranslation();
  const [selectedCard, setSelectedCard] = useState<ChangeableUpdateKey | ''>(
    ''
  );
  const [showInactive, setShowInactive] = useState(false);

  const {
    cards,
    isLoading,
    isMandatorySettingUpdating,
    isDetailsDialogOpen,
    selectedSetting,
    updateSetting,
    updateMandatoryExportSetting,
    onDetailsOpen,
    onDetailsClose,
  } = useBasicAccountingSettingsTiles();

  const activeCards = useMemo(() => cards.filter((item) => item.checked), [
    cards,
  ]);
  const shownCards = showInactive ? cards : activeCards;

  return (
    <Box>
      <Box
        py={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">
          {t('accountingGeneralSubpage.basicAccountingSettings.title')}
        </Typography>

        {activeCards.length < cards.length && (
          <FormControlLabel
            sx={{ mr: 0 }}
            checked={showInactive}
            control={<Switch />}
            label={t('accountingGeneralSubpage.viewInactiveLabel')}
            onChange={(e, checked: boolean) => setShowInactive(checked)}
          />
        )}
      </Box>

      <Grid container spacing={3}>
        {shownCards.map((card) => {
          if (!card.visible) return null;

          return (
            <Grid item key={card.fieldName}>
              <Tile
                sx={{
                  cursor: 'pointer',
                }}
                variant="outlined"
                data-intercom-target={`${card.intercomTarget}-tile`}
                onClick={() => {
                  if (isLoading) return;
                  onDetailsOpen(card.fieldName);
                }}
              >
                <Box p={3} pb={2}>
                  <StatusBadge
                    sx={(theme) => ({
                      position: 'absolute',
                      top: theme.spacing(3),
                      right: theme.spacing(3),
                    })}
                    label={t(
                      `accountingGeneralSubpage.basicAccountingSettings.${
                        card.checked ? 'activeStatus' : 'inactiveStatus'
                      }`
                    )}
                    variant="filled"
                    color={card.checked ? 'success' : 'primary'}
                  />

                  <StyledAvatar>{card.icon}</StyledAvatar>

                  <Box mt={3}>
                    <Typography variant="h6" mb={2}>
                      {t(
                        `accountingGeneralSubpage.basicAccountingSettings.${card.fieldName}Item.title`
                      )}
                    </Typography>

                    <Typography color="textSecondary">
                      {t(
                        `accountingGeneralSubpage.basicAccountingSettings.${card.fieldName}Item.description`
                      )}
                    </Typography>
                  </Box>
                </Box>

                <GeneralTileFooter
                  mandatoryForExport={card.mandatoryForExport}
                  automationType={card.automationType}
                />
              </Tile>
            </Grid>
          );
        })}
      </Grid>

      <ConfirmationDialog
        open={!!selectedCard}
        onClose={() => setSelectedCard('')}
        onSuccess={() => {
          if (!selectedCard) return;
          updateSetting(selectedCard, false);
          setSelectedCard('');
        }}
      />

      <SettingsInformationDialog
        open={isDetailsDialogOpen}
        isLoading={isLoading}
        isMandatorySettingUpdating={isMandatorySettingUpdating}
        selectedSetting={selectedSetting}
        updateSetting={updateSetting}
        updateMandatoryExportSetting={updateMandatoryExportSetting}
        onClose={onDetailsClose}
      />
    </Box>
  );
};

export default BasicAccountingSettingsGroup;
