import React from 'react';
import { styled } from '@mui/material';
import { CardIcon } from 'domains/card/components';
import { Box, FormControl, InputLabel } from 'elements';
import { CardConfigSetting } from 'services/constants';

const ItemWrapper = styled('div')<{ $isActive: boolean }>`
  padding: 5px;
  margin: 1px 3px 0 0;
  border: 1px solid
    ${({ theme, $isActive }) =>
      $isActive ? theme.palette.primary.main : 'transparent'};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  cursor: ${({ $isActive }) => ($isActive ? 'default' : 'pointer')};
`;

interface Props {
  cardConfigSetting: CardConfigSetting;
  label?: string;
  value: string;
  onChange: (value: string) => void;
}

const CardDesignPicker = ({
  cardConfigSetting,
  label,
  value,
  onChange,
}: Props) => {
  return (
    <FormControl fullWidth>
      {label && <InputLabel component="div">{label}</InputLabel>}
      <Box display="flex" mt={3}>
        {cardConfigSetting.cardDesignIds.map((id) => (
          <ItemWrapper
            onClick={() => onChange(id)}
            $isActive={value === id}
            key={id}
            data-test-id={id}
          >
            <CardIcon cardDesignId={id} />
          </ItemWrapper>
        ))}
      </Box>
    </FormControl>
  );
};

export default CardDesignPicker;
