import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { SepaDirectDebit } from 'domains/billing/components';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { BankAccount, DirectDebitInfo, Onboarding } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  isSubmitting: boolean;
  directDebitInfo: DirectDebitInfo | null;
}

interface Props extends DialogProps {
  isInOnboarding: boolean;
  bankAccount: BankAccount;
  onClose: () => void;
  onSuccess: (onboarding?: Onboarding) => void;
}

const ConfirmSepaDirectDebitDialog = ({
  isInOnboarding,
  bankAccount,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    isSubmitting: false,
    directDebitInfo: null,
  });

  const prepareSddMandate = async () => {
    try {
      const directDebitInfo = await api.prepareSddMandate(bankAccount.id);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        directDebitInfo,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    prepareSddMandate();
  }, []);

  const confirmSddMandate = async () => {
    try {
      setState((prevState) => ({ ...prevState, isSubmitting: true }));
      let onboarding;
      if (isInOnboarding) {
        onboarding = await api.confirmSddMandateInOnboarding(
          organization!.id,
          bankAccount.id,
          state.directDebitInfo!.id
        );
      } else {
        await api.confirmSddMandate(state.directDebitInfo!.id);
      }
      if (!mounted.current) return;
      onSuccess(onboarding);
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{t('confirmSepaDirectDebitDialog.title')}</DialogTitle>
      <DialogContent>
        {state.isLoading ? (
          <Box height="130px" />
        ) : (
          <SepaDirectDebit directDebitInfo={state.directDebitInfo!} />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.close')}
        </Button>
        <Button
          onClick={confirmSddMandate}
          disabled={state.isLoading || state.isSubmitting}
          data-test-id="confirm-btn"
        >
          {t('confirmSepaDirectDebitDialog.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading || state.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(ConfirmSepaDirectDebitDialog);
