import React, { ReactNode, useEffect, useState } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import kebabCase from 'lodash/kebabCase';
import { useGlobalState } from 'context/GlobalState';
import { env } from 'env';
import useCurrentApp from 'hooks/useCurrentApp';
import { defaultFlags } from './flags';

type LDProviderType = ({ children }: { children: ReactNode }) => JSX.Element;

const withFeatureFlagsProvider = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => (props: P): JSX.Element | null => {
  const { isInternalApp } = useCurrentApp();
  const [LDProvider, setLDProvider] = useState<LDProviderType | null>(null);
  const {
    state: { organization },
  } = useGlobalState();

  useEffect(() => {
    (async () => {
      const provider = await asyncWithLDProvider({
        clientSideID: env.REACT_APP_DARKLY_CLIENT_SIDE_ID!,
        context: isInternalApp
          ? {
              key: 'internal',
              kind: 'app',
            }
          : {
              app: { key: 'external' },
              kind: 'multi',
              user: {
                activatedAt: organization!.activatedAt,
                country: organization!.billingAddress.country,
                key: organization!.id,
                name: organization!.name,
                partner: organization!.partnerName,
              },
            },

        // default values, keys with original casing!
        flags: Object.entries(defaultFlags).reduce(
          (acc, [k, v]) => ({ ...acc, [kebabCase(k)]: v }),
          {}
        ),

        reactOptions: {
          useCamelCaseFlagKeys: true,
        },
      });

      setLDProvider(() => provider);
    })();
  }, []);

  return LDProvider ? (
    <LDProvider>
      <Component {...props} />
    </LDProvider>
  ) : null;
};

export default withFeatureFlagsProvider;
