import { css, styled } from '@mui/material';

export const Label = styled('label')<{ $disabled: boolean }>`
  max-width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  border: 1px solid
    ${({ theme }) => theme.variables.input.outlined.enabledBorder};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  ${({ $disabled, theme }) =>
    $disabled
      ? css`
          border-color: ${theme.variables.input.outlined.disabledBorder};
          > *:first-of-type {
            // card icon
            > div:first-of-type {
              opacity: 0.5;
            }
            .MuiTypography-root {
              color: ${theme.palette.text.disabled};
            }
          }
        `
      : css`
          cursor: pointer;
          &:hover {
            border-color: ${theme.palette.primary.main};
          }
        `}
`;

export const SubtypeBox = styled('div')`
  padding: ${({ theme }) => theme.spacing(2)};
  border-top: 1px solid
    ${({ theme }) => theme.variables.input.outlined.enabledBorder};
`;

export const CardNameWrapper = styled('div')`
  flex-grow: 1;
  margin: ${({ theme }) => theme.spacing(0, 1, 0, 3)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
