import React, { useEffect, useMemo, useState } from 'react';
import { uniq } from 'lodash';
import { useTranslation } from 'react-i18next';
import CurrencyFlag from 'components/CurrencyFlag';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountCurrency } from 'domains/card/hooks';
import { Box, Divider, LoaderWithOverlay, Tooltip, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import {
  CardAccountCurrency,
  CardAccountList,
  Money,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

const MAX_ITEMS = 5;

type CurrencyWithDataEntry = [
  CardAccountCurrency,
  {
    availableLimit: Money;
    availableLimitInMainCurrency: Money;
    cardAccountCount: number;
  }
];

const useCurrencyWithDataEntries = (data: CardAccountList | null) => {
  return useMemo<CurrencyWithDataEntry[]>(() => {
    const uniqOrderedCurrencies = data
      ? uniq(data.cardAccounts.map((item) => item.currency.value))
      : [];

    return uniqOrderedCurrencies
      .map((currency) =>
        data?.availableLimitPerCurrency[currency] &&
        data?.availableLimitInMainCurrency[currency] &&
        typeof data?.cardAccountsPerCurrency[currency] === 'number'
          ? [
              currency,
              {
                availableLimit: data.availableLimitPerCurrency[currency]!,
                availableLimitInMainCurrency: data.availableLimitInMainCurrency[
                  currency
                ]!,
                cardAccountCount: data.cardAccountsPerCurrency[currency]!,
              },
            ]
          : undefined
      )
      .filter((item): item is CurrencyWithDataEntry => !!item);
  }, [data]);
};

interface State {
  data: CardAccountList | null;
  isLoading: boolean;
  error: unknown;
}

const MultiCurrenciesOverview = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization, featureModules },
  } = useGlobalState();
  const mainCurrency = useCardAccountCurrency();
  const [state, setState] = useState<State>({
    data: null,
    isLoading: true,
    error: null,
  });
  const currencyWithDataEntries = useCurrencyWithDataEntries(state.data);

  const getData = async () => {
    try {
      const data = await api.getCardAccounts(organization!.id);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, data, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, error, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const renderRemainingCurrencies = () => {
    if (currencyWithDataEntries.length <= MAX_ITEMS) return null;

    const remainingEntries = currencyWithDataEntries.slice(MAX_ITEMS);
    const totalCardAccountCount = remainingEntries.reduce(
      (acc, curr) => acc + curr[1].cardAccountCount,
      0
    );
    const totalAvailableLimitInMainCurrency = remainingEntries.reduce(
      (acc, [, { availableLimitInMainCurrency }], index) =>
        index === 0
          ? availableLimitInMainCurrency
          : {
              value: acc!.value + availableLimitInMainCurrency.value,
              currency: acc!.currency,
            },
      null as Money | null
    );

    return (
      <Box mr={2} mt={1}>
        <Typography variant="subtitle2">
          {t('dashboardPage.remainingCurrencyAccounts', {
            count: totalCardAccountCount,
          })}
        </Typography>
        <Typography variant="caption" component="div">
          {totalAvailableLimitInMainCurrency && (
            <FormatMoney value={totalAvailableLimitInMainCurrency} />
          )}
        </Typography>
      </Box>
    );
  };

  return (
    <Box position="relative" display="flex" minHeight={66} mb={2}>
      {state.data && (
        <>
          <Box>
            <Typography
              variant="caption2"
              mb={0.5}
              component="div"
              color="text.secondary"
            >
              {t('dashboardPage.available')}
            </Typography>
            <Tooltip
              title={currencyWithDataEntries.map(
                (
                  [currency, { availableLimit, availableLimitInMainCurrency }],
                  index
                ) => (
                  <Box
                    key={currency}
                    display="flex"
                    justifyContent="space-between"
                    minWidth={200}
                    mt={index === 0 ? 0 : 1}
                  >
                    <Box mr={2}>{currency}</Box>
                    <Box>
                      <FormatMoney value={availableLimit} />
                      {currency !== mainCurrency.code && (
                        <>
                          {' '}
                          (<FormatMoney value={availableLimitInMainCurrency} />)
                        </>
                      )}
                    </Box>
                  </Box>
                )
              )}
            >
              <Typography variant="h6">
                <FormatMoney value={state.data.totalAvailableLimit} />
              </Typography>
            </Tooltip>
          </Box>
          <Divider orientation="vertical" sx={{ height: 'auto', mx: 3 }} />
          <Box>
            <Typography
              variant="caption2"
              component="div"
              color="text.secondary"
            >
              {t('dashboardPage.accounts')}
            </Typography>
            <Box display="flex" flexWrap="wrap">
              {currencyWithDataEntries.map(
                ([currency, { availableLimit, cardAccountCount }], index) =>
                  index < MAX_ITEMS ? (
                    <Box key={currency} mr={2} mt={1}>
                      <Box display="flex" alignItems="center">
                        <CurrencyFlag
                          currencyCode={currency}
                          size="small"
                          sx={{ mr: 0.5 }}
                        />
                        <Typography variant="subtitle2">
                          {featureModules.MULTI_CARD_ACCOUNT
                            ? `${currency} (${cardAccountCount})`
                            : currency}
                        </Typography>
                      </Box>
                      <Typography variant="caption" component="div">
                        <FormatMoney value={availableLimit} />
                      </Typography>
                    </Box>
                  ) : null
              )}

              {renderRemainingCurrencies()}
            </Box>
          </Box>
        </>
      )}

      {state.error && (
        <Typography variant="caption" color="error.main">
          {t('errors.loadData')}
        </Typography>
      )}

      <LoaderWithOverlay loading={state.isLoading} size="small" />
    </Box>
  );
};

export default MultiCurrenciesOverview;
