import { ComponentType, FC, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import useIdTokenCustomData from 'hooks/useIdTokenCustomData';

const withInitialLogic = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element | null => {
  const { user } = useAuth0();
  const { roles } = useIdTokenCustomData();

  useEffect(() => {
    datadogRum.setUser({ iamId: user!.sub, roles });
  }, [user]);

  return <Component {...props} />;
};

export default withInitialLogic;
