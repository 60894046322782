import React, { useState } from 'react';
import { Collapse, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MerchantCategoryIcon } from 'domains/merchant/components';
import {
  Box,
  IconButton,
  MenuItem,
  Radio,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  XIcon,
} from 'elements';
import {
  CardCategoryControl,
  CardCategoryControlType,
  CardConfigSetting,
  CardControlRestriction,
  cardControlRestrictions,
  MerchantCategory,
} from 'services/constants';
import MccCategories from './MccCategories';
import TransactionCategories from './TransactionCategories';
import {
  StyledBox,
  StyledRadioGroup,
  StyledTableContainer,
  SummaryFormControlLabel,
  Wrapper,
} from './style';
import useCardControlsDisplaySettings from './useCardControlsDisplaySettings';

interface Props {
  cardConfigSetting: CardConfigSetting;
  control: CardCategoryControl | null;
  onChange: (control: CardCategoryControl | null) => void;
  disabled?: boolean;
}

const CardCategoryControlFormElement = ({
  cardConfigSetting,
  control,
  onChange,
  disabled = false,
}: Props) => {
  const { t } = useTranslation();
  const {
    categories: { isVisible, availableTypes },
  } = useCardControlsDisplaySettings(cardConfigSetting);
  const [isExpanded, setIsExpanded] = useState<boolean>(
    !!control?.values.length
  );
  const defaultControl = {
    restriction: CardControlRestriction.allowed,
    type: availableTypes[0],
    values: [],
    displayValues: [],
  };
  const { restriction, type, values, displayValues } =
    control || defaultControl;

  if (!isVisible) return null;

  return (
    <Wrapper>
      <SummaryFormControlLabel
        checked={isExpanded}
        onChange={(e, checked) => {
          onChange(checked ? defaultControl : null);
          setIsExpanded(checked);
        }}
        disabled={disabled}
        control={<Switch size="medium" />}
        label={
          <Typography variant="subtitle1">
            {t('cardControlFormElement.category.title')}
          </Typography>
        }
        labelPlacement="start"
      />
      <Typography variant="body2" color="textSecondary">
        {t('cardControlFormElement.category.description')}
      </Typography>
      <Collapse in={isExpanded}>
        <StyledRadioGroup
          row
          value={restriction}
          onChange={(e) =>
            onChange({
              type,
              values,
              displayValues,
              restriction: e.target.value as CardControlRestriction,
            })
          }
        >
          {cardControlRestrictions.map((item) => (
            <FormControlLabel
              key={item}
              value={item}
              disabled={disabled}
              control={<Radio />}
              label={
                <Typography variant="body1">
                  {t(`cardControlFormElement.restrictions.${item}`)}
                </Typography>
              }
            />
          ))}
        </StyledRadioGroup>
        <StyledBox>
          <Select
            value={type}
            onChange={(e) =>
              onChange({
                restriction,
                type: e.target.value as CardCategoryControlType,
                values: [],
                displayValues:
                  e.target.value === CardCategoryControlType.category
                    ? null
                    : [],
              })
            }
            renderValue={(value) =>
              t(`cardControlFormElement.category.types.${value}`)
            }
            disabled={availableTypes.length === 1 || disabled}
          >
            {availableTypes.map((item) => (
              <MenuItem key={item} value={item}>
                {t(`cardControlFormElement.category.types.${item}`)}
              </MenuItem>
            ))}
          </Select>
        </StyledBox>
        <StyledBox>
          {type === CardCategoryControlType.category ? (
            <TransactionCategories
              disabled={disabled}
              categories={values as MerchantCategory[]}
              onChange={onChange}
              restriction={restriction!}
            />
          ) : (
            <MccCategories
              disabled={disabled}
              mccGroup={cardConfigSetting.mccGroup}
              control={{ restriction, type, values, displayValues }}
              onChange={onChange}
            />
          )}
        </StyledBox>

        {!!values.length && (
          <StyledTableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: 'transparent' }}>
                    {t('cardControlFormElement.category.paymentCategory')}
                  </TableCell>
                  <TableCell sx={{ backgroundColor: 'transparent' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {type === CardCategoryControlType.category ? (
                  <>
                    {values.map((category) => {
                      const merchantCategory = category as MerchantCategory;
                      return (
                        <TableRow
                          key={merchantCategory}
                          sx={{ '.MuiTableCell-root': { border: 0 } }}
                        >
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <MerchantCategoryIcon
                                category={merchantCategory}
                                fontSize="small"
                              />
                              <Box ml={1}>
                                {t(`merchantCategories.${merchantCategory}`)}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() =>
                                onChange({
                                  restriction,
                                  type,
                                  displayValues,
                                  values: (values as MerchantCategory[]).filter(
                                    (item: MerchantCategory) =>
                                      item !== category
                                  ),
                                })
                              }
                              disabled={disabled}
                              size="small"
                            >
                              <XIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {displayValues?.map((mcc) => (
                      <TableRow
                        key={mcc.code}
                        sx={{ '.MuiTableCell-root': { border: 0 } }}
                      >
                        <TableCell>
                          {mcc.code} {mcc.name}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() =>
                              onChange({
                                restriction,
                                type,
                                values: values.filter(
                                  (item) => item !== mcc.code
                                ),
                                displayValues: displayValues!.filter(
                                  (item) => item.code !== mcc.code
                                ),
                              })
                            }
                            disabled={disabled}
                            size="small"
                          >
                            <XIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
      </Collapse>
    </Wrapper>
  );
};

export default CardCategoryControlFormElement;
