import {
  Children,
  cloneElement,
  isValidElement,
  MouseEvent,
  ReactNode,
  useState,
} from 'react';
import { Menu, MenuProps } from '@mui/material';
import clsx from 'clsx';

export interface MenuContainerProps {
  button: JSX.Element | Function;
  buttonSelectedClass?: string;
  MenuProps?: Omit<MenuProps, 'anchorEl' | 'open' | 'onClose' | 'keepMounted'>;
  children: ReactNode;
}

/**
 * Handy wrapper component that takes care of menu state in most cases.
 */
const MenuContainer = ({
  button,
  buttonSelectedClass = 'selected',
  MenuProps,
  children,
}: MenuContainerProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const btn = typeof button === 'function' ? button(openMenu) : button;

  return (
    <>
      {cloneElement(btn, {
        onClick: openMenu,
        className: clsx(!!anchorEl && buttonSelectedClass, btn.props.className),
      })}
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
        keepMounted
        {...MenuProps}
      >
        {Children.map(children, (child) => {
          if (!isValidElement<{ onClick: () => void }>(child)) return null;
          return cloneElement(child, {
            onClick: () => {
              if (typeof child.props.onClick === 'function') {
                child.props.onClick();
              }
              closeMenu();
            },
          });
        })}
      </Menu>
    </>
  );
};

export default MenuContainer;
