import React from 'react';
import MuiPopover, {
  PopoverProps as MuiPopoverProps,
} from '@material-ui/core/Popover/Popover';
import styled from 'styled-components';
import { ReactComponent as ArrowsButtonDownIcon } from 'assets/icons/interface/arrowsButtonDown.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/interface/calendar.svg';
import { IconButton } from 'components/Button';
import { StyledInputLabel } from 'components/Page/StyledInputLabel';

export const StyledDateRangePicker = styled.div`
  position: relative;
  display: inline-flex;
  margin: 0 0 0 16px;
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  position: absolute;
  top: 15px;
  left: 16px;
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.colors.grey10};
`;

export const ArrowDownUpIcon = styled(ArrowsButtonDownIcon)`
  position: absolute;
  top: 15px;
  right: 16px;
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.colors.grey10};
`;

export const DateRangeInputLabel = styled(StyledInputLabel)`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(48px, 14px);
`;

export const ToggleButton = styled.button`
  position: relative;
  z-index: 1;
  height: 46px;
  min-width: 250px;
  padding: 13px 34px 13px 48px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.grey10};
  background: ${({ theme }) => theme.colors.neutralVariant100};
  transition: border-color 0.2s;
  outline: none;
  text-align: left;
  cursor: pointer;

  &[aria-expanded='true'] {
    border-color: ${({ theme }) => theme.colors.grey10};

    & ${ArrowDownUpIcon} {
      transform: rotate(180deg);
    }
  }
`;

export const StyledMuiPopover = styled((props: MuiPopoverProps) => (
  <MuiPopover
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))`
  & .MuiPopover-paper {
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
  }
`;

export const NextButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 22px;
  padding: 10px;
`;

export const PrevButton = styled(NextButton)`
  right: auto;
  left: 22px;
`;
