import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import { InternalBlock } from 'domains/settings/components';
import {
  Box,
  FormControl,
  FormControlLabel,
  LoaderWithOverlay,
  QuestionIcon,
  Switch,
  Tooltip,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer } from 'layout';
import {
  CirculaConnectionStatus,
  CirculaConnectionType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser, withPermission } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  isUpdating: boolean;
  isFullSyncEnabled: boolean;
  isConfirmDialogOpen: boolean;
}

const IntegrationsSubPage = () => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { organization, organizationIntegrations },
  } = useGlobalState();
  const circulaSettings = organizationIntegrations?.syncSettings;
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    isLoading: false,
    isUpdating: false,
    isFullSyncEnabled:
      organizationIntegrations?.syncSettings?.orgConnectionType ===
      CirculaConnectionType.full,
    isConfirmDialogOpen: false,
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const organizationIntegrations = await api.getOrganizationIntegrations(
        organization!.id
      );

      if (!mounted.current) return;
      dispatch({
        type: 'SET_USER_DATA',
        payload: { organizationIntegrations },
      });
      setState((prevState) => ({ ...prevState, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const toggleCirculaFullSync = async () => {
    if (state.isUpdating) return;
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      const circulaSettings = await api.updateCirculaSettings(
        organization!.id,
        {
          status: organizationIntegrations!.syncSettings!.orgConnectionStatus,
          type: state.isFullSyncEnabled
            ? CirculaConnectionType.full
            : CirculaConnectionType.partial,
        }
      );
      dispatch({
        type: 'SET_USER_DATA',
        payload: {
          organizationIntegrations: {
            ...organizationIntegrations!,
            syncSettings: circulaSettings,
          },
        },
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isUpdating: false,
        isConfirmDialogOpen: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isUpdating: false,
        isFullSyncEnabled:
          circulaSettings?.orgConnectionType === CirculaConnectionType.full,
        isConfirmDialogOpen: false,
      }));
      logError(error);
    }
  };

  if (state.isLoading) return <LoaderWithOverlay loading />;

  return (
    <ContentContainer>
      <InternalBlock sx={{ mb: 5 }}>
        <Typography variant="h6" mb={2}>
          {t('integrationsSubPage.title')}
        </Typography>

        {circulaSettings?.orgConnectionStatus !==
        CirculaConnectionStatus.connected ? (
          <Typography>{t('integrationsSubPage.statusNotConnected')}</Typography>
        ) : (
          <FormControl fullWidth sx={{ label: { maxWidth: '100%' } }}>
            <FormControlLabel
              label={
                <Box display="flex" alignItems="center">
                  <Typography mr={1}>
                    {t('integrationsSubPage.toggleSyncSwitchLabel')}
                  </Typography>

                  <Tooltip title={t('integrationsSubPage.description')}>
                    <QuestionIcon fontSize="small" />
                  </Tooltip>
                </Box>
              }
              labelPlacement="start"
              checked={state.isFullSyncEnabled}
              disabled={!canUser('circula-settings:change')}
              onChange={(e, checked) => {
                if (state.isUpdating) return;
                setState((prevState) => ({
                  ...prevState,
                  isFullSyncEnabled: checked,
                  isConfirmDialogOpen: true,
                }));
              }}
              control={<Switch />}
            />
          </FormControl>
        )}

        <ConfirmDialog
          open={state.isConfirmDialogOpen}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              isConfirmDialogOpen: false,
              isFullSyncEnabled: prevState.isUpdating
                ? prevState.isFullSyncEnabled
                : organizationIntegrations?.syncSettings?.orgConnectionType ===
                  CirculaConnectionType.full,
            }))
          }
          onSuccess={toggleCirculaFullSync}
          loading={state.isUpdating}
          title={t('integrationsSubPage.confirmTitle')}
          description={t('integrationsSubPage.confirmDescription')}
        />
      </InternalBlock>
    </ContentContainer>
  );
};

export default withPermission(
  IntegrationsSubPage,
  'integrations-subpage:visit'
);
