import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  CaretDownIcon,
  CaretUpIcon,
  CheckCircleIcon,
  Collapse,
  Fade,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  IconButton,
  LoaderWithOverlay,
  Paper,
  Switch,
  Tooltip,
  Typography,
  XCircleIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePrevious from 'hooks/usePrevious';
import useSnackbar from 'hooks/useSnackbar';
import { ActivatedAndAvailableTpcConfigs } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import UpdateActivatedTpcConfigsDialog from './UpdateActivatedTpcConfigsDialog';

interface Props
  extends Pick<
    FormControlLabelProps,
    'label' | 'checked' | 'onChange' | 'disabled'
  > {
  title: React.ReactNode;
}

interface State {
  tpcConfigs: ActivatedAndAvailableTpcConfigs | null;
  isLoading: boolean;
  isOpen: boolean;
  isDialogOpen: boolean;
}

const TpcInternalModuleToggle = ({ title, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, featureModules },
  } = useGlobalState();
  const previousFmTpc = usePrevious<boolean>(
    featureModules.TRAVEL_PURCHASING_CARDS
  );
  const [state, setState] = useState<State>({
    tpcConfigs: null,
    isLoading: false,
    isOpen: false,
    isDialogOpen: false,
  });

  const onOpen = async (isFmActivation: boolean = false) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const tpcConfigs = await api.getActivatedAndAvailableTpcConfigs(
        organization!.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        tpcConfigs,
        isLoading: false,
        isOpen: true,
        isDialogOpen: isFmActivation,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (previousFmTpc !== undefined) {
      if (!previousFmTpc && featureModules.TRAVEL_PURCHASING_CARDS) {
        onOpen(true);
      }
      if (previousFmTpc && !featureModules.TRAVEL_PURCHASING_CARDS) {
        setState((prevState) => ({ ...prevState, isOpen: false }));
      }
    }
  }, [previousFmTpc, featureModules.TRAVEL_PURCHASING_CARDS]);

  return (
    <>
      <Tooltip title={title} placement="bottom-end">
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
            <Box flexGrow={1}>
              <FormControl fullWidth>
                <FormControlLabel
                  {...props}
                  control={<Switch size="small" />}
                  labelPlacement="start"
                  sx={{ py: '10px', mr: 0 }}
                />
              </FormControl>
            </Box>
            <Box position="relative" ml={2}>
              <Fade in={!state.isLoading}>
                <IconButton
                  onClick={() => {
                    if (!state.isOpen) onOpen();
                    else
                      setState((prevState) => ({
                        ...prevState,
                        isOpen: false,
                      }));
                  }}
                  disabled={
                    props.disabled ||
                    !featureModules.TRAVEL_PURCHASING_CARDS ||
                    state.isLoading
                  }
                >
                  {state.isOpen ? <CaretUpIcon /> : <CaretDownIcon />}
                </IconButton>
              </Fade>
              <LoaderWithOverlay loading={state.isLoading} size="small" />
            </Box>
          </Box>

          <Collapse in={state.isOpen}>
            <Box
              sx={(theme) => ({
                position: 'relative',
                p: 3,
                borderTop: `1px solid ${theme.palette.divider}`,
              })}
            >
              {state.tpcConfigs && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <div>
                    {Object.keys(state.tpcConfigs.availableConfigs).map(
                      (config) => (
                        <Box key={config} display="flex" sx={{ mb: 1 }}>
                          {state.tpcConfigs?.activatedConfigs.includes(
                            config
                          ) ? (
                            <CheckCircleIcon color="success" />
                          ) : (
                            <XCircleIcon color="error" />
                          )}
                          <Typography color="text.secondary" ml={1}>
                            {t(`cardNamesForSubtypes.${config}`)}
                          </Typography>
                        </Box>
                      )
                    )}
                    {state.tpcConfigs.lastUpdatedBy &&
                      state.tpcConfigs.lastUpdatedAt && (
                        <Typography variant="caption" color="text.secondary">
                          {t('int.common.lastEditedBy', {
                            name: state.tpcConfigs.lastUpdatedBy,
                            time: moment(state.tpcConfigs.lastUpdatedAt).format(
                              'DD MMM YYYY HH:mm'
                            ),
                          })}
                        </Typography>
                      )}
                  </div>

                  <Button
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isDialogOpen: true,
                      }))
                    }
                    variant="outlined"
                  >
                    {t('common.button.edit')}
                  </Button>
                </Box>
              )}
            </Box>
          </Collapse>
        </Paper>
      </Tooltip>

      <UpdateActivatedTpcConfigsDialog
        open={state.isDialogOpen}
        tpcConfigs={state.tpcConfigs!}
        onSuccess={(tpcConfigs) =>
          setState((prevState) => ({
            ...prevState,
            tpcConfigs,
            isDialogOpen: false,
          }))
        }
        onClose={() =>
          setState((prevState) => ({ ...prevState, isDialogOpen: false }))
        }
      />
    </>
  );
};

export default TpcInternalModuleToggle;
