import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dropzone,
  FileRejection,
  Typography,
} from 'elements';
import useSnackbar from 'hooks/useSnackbar';

const MAX_FILE_SIZE = 1000000;

const dropzoneDefaultProps = {
  multiple: false,
  // file mimes should be checked on different platforms
  accept: ['text/csv', 'application/vnd.ms-excel', 'application/csv'],
  maxSize: MAX_FILE_SIZE,
};

interface Props {
  title: string;
  description: string | JSX.Element;
  file: File | null;
  isLoading: boolean;
  onDrop: (file: File) => void;
  onClose: () => void;
}

export const BulkUploadDropzone = ({
  title,
  description,
  file,
  isLoading,
  onDrop,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    const [file] = acceptedFiles;
    onDrop(file);
  }, []);

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      if (
        fileRejections.length === 1 &&
        fileRejections[0].errors.length === 1 &&
        fileRejections[0].errors[0].code === 'file-too-large'
      ) {
        enqueueSnackbar(t('bulkUploadDropzone.fileIsTooBigErrorMessage'), {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={4}>
          {description}
        </Typography>
        <Dropzone
          file={file}
          dataTestId="bulk-upload-input"
          isLoading={isLoading}
          disabled={!!file || isLoading}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          {...dropzoneDefaultProps}
          dropzoneIdleProps={{
            description: t('bulkUploadDropzone.uploadFile'),
            secondaryDescription: t('bulkUploadDropzone.dropzoneLabel', {
              format: 'CSV',
            }),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.button.cancel')}</Button>
      </DialogActions>
    </>
  );
};
