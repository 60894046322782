import styled from 'styled-components';
import { ButtonLink } from 'components/Button';

export const CheckboxWrapper = styled.div`
  margin-top: 6px;
  margin-left: -9px;
  display: flex;
  align-items: flex-start;
`;

export const CheckboxLabel = styled.label`
  margin: 12px 0 0;
  font-size: 16px;
  line-height: 20px;
`;

export const StyledButtonLink = styled(ButtonLink)`
  font-size: 16px;
  margin: 0 51px 0 auto;
`;
