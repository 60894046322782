import { FormikErrors, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { CardAccountSettings } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  accountToEdit: CardAccountSettings | null;
  onClose: () => void;
  onSuccess: () => void;
}

interface FormValues {
  cardAccountId: string;
  pliantCreditCardAccount: string;
  defaultAccount: boolean;
}

const AssignEditAccountNumberDialog = ({
  accountToEdit,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, accountingSettings, cardAccounts },
  } = useGlobalState();
  const getCardAccountName = useCardAccountNameGetter();

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      cardAccountId: cardAccounts[0].id,
      pliantCreditCardAccount: accountToEdit?.pliantCreditCardAccount || '',
      defaultAccount: accountToEdit?.defaultAccount || false,
    },
    validate(values) {
      const errors: FormikErrors<FormValues> = {};

      const generalLedgerAccLen = accountingSettings!
        .generalLedgerAccountLength;
      if (!generalLedgerAccLen) return;

      const pliantCreditCardAccLen = values.pliantCreditCardAccount.length;
      if (
        pliantCreditCardAccLen &&
        (pliantCreditCardAccLen < generalLedgerAccLen ||
          pliantCreditCardAccLen > generalLedgerAccLen + 1)
      ) {
        errors.pliantCreditCardAccount = t(
          'assignEditAccountNumberDialog.strLenIsNotBetweenError',
          {
            min: generalLedgerAccLen,
            max: generalLedgerAccLen + 1,
          }
        );
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await api.updateCardAccountSettings(
          accountToEdit
            ? accountToEdit.cardAccountId
            : formik.values.cardAccountId,
          {
            organizationId: organization!.id,
            pliantCreditCardAccount: values.pliantCreditCardAccount.trim(),
          }
        );

        const accountingSettings = await api.getAccountingSettings(
          organization!.id
        );

        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { accountingSettings },
        });

        if (!mounted.current) return;
        onSuccess();
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const hasEmptyField = !formik.values.pliantCreditCardAccount.trim();

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {accountToEdit
          ? t('assignEditAccountNumberDialog.titleEdit')
          : t('assignEditAccountNumberDialog.titleAssign')}
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          id="update-card-acc-form"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {accountToEdit ? (
                <TextField
                  disabled
                  label={t(
                    'assignEditAccountNumberDialog.cardAccountNameLabel'
                  )}
                  name="cardAccountName"
                  value={getCardAccountName(accountToEdit.cardAccountId)}
                />
              ) : (
                <FormControl fullWidth>
                  <InputLabel>
                    {t('assignEditAccountNumberDialog.cardAccountNameLabel')}
                  </InputLabel>
                  <Select<string>
                    renderValue={(value) => {
                      const selectedAccount = cardAccounts.find(
                        (account) => account.id === value
                      );
                      if (!selectedAccount) return '';
                      return getCardAccountName(selectedAccount);
                    }}
                    {...formik.getFieldProps('cardAccountId')}
                  >
                    {cardAccounts.map((account) => (
                      <MenuItem key={account.id} value={account.id}>
                        {getCardAccountName(account)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12}>
              <NumberFormat
                label={t(
                  'assignEditAccountNumberDialog.pliantCreditCardAccountLabel'
                )}
                customInput={TextField}
                disabled={formik.isSubmitting}
                allowLeadingZeros={true}
                allowNegative={false}
                decimalScale={0}
                error={!!formik.errors.pliantCreditCardAccount}
                helperText={formik.errors.pliantCreditCardAccount}
                {...formik.getFieldProps('pliantCreditCardAccount')}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={hasEmptyField || formik.isSubmitting || !formik.dirty}
          type="submit"
          form="update-card-acc-form"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(AssignEditAccountNumberDialog);
