import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { PartnerWhitelabelLevel } from 'services/constants';

const usePartnerName = () => {
  const {
    state: { partnerConfig },
  } = useGlobalState();

  return useMemo(() => {
    if (partnerConfig) {
      return [
        PartnerWhitelabelLevel.FULLY_WHITELABEL,
        PartnerWhitelabelLevel.EMBEDDED_WHITELABEL,
      ].includes(partnerConfig.whitelabelLevel)
        ? partnerConfig.name
        : 'Pliant';
    }
    return 'Pliant';
  }, [partnerConfig]);
};

export default usePartnerName;
