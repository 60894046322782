import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { DialogProps } from 'elements';

interface Props extends DialogProps {
  onSuccess: () => void;
  onClose: () => void;
}

const ConfirmationDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      {...props}
      title={t(
        'accountingGeneralSubpage.basicAccountingSettings.confirmationDialog.title'
      )}
      description={
        <Trans
          i18nKey="accountingGeneralSubpage.basicAccountingSettings.confirmationDialog.description"
          components={{
            div: <div />,
          }}
        />
      }
      onSuccess={onSuccess}
      confirmButtonProps={{
        color: 'error',
        children: t(
          'accountingGeneralSubpage.basicAccountingSettings.confirmationDialog.deactivateButton'
        ),
      }}
    />
  );
};

export default ConfirmationDialog;
