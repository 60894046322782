import React from 'react';
import {
  AutocompleteRenderInputParams,
  InputAdornment,
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material';
import { MagnifyingGlassIcon } from 'elements/TemporaryIcon';
import TextField, { TextFieldProps } from 'elements/TextField';

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>
  extends Omit<
      MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
      'renderInput'
    >,
    Pick<TextFieldProps, 'error' | 'helperText' | 'label' | 'placeholder'> {
  searchIcon?: boolean;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined = false
>({
  searchIcon = true,
  error,
  helperText,
  label,
  placeholder,
  renderInput,
  ...rest
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => (
  <MuiAutocomplete
    // https://github.com/mui/material-ui/issues/19479#issuecomment-587387539
    renderInput={
      renderInput ||
      ((params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {searchIcon && (
                  <InputAdornment position="end">
                    <MagnifyingGlassIcon />
                  </InputAdornment>
                )}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          error={error}
          helperText={helperText}
          label={label}
          placeholder={placeholder}
        />
      ))
    }
    {...rest}
  />
);

export default Autocomplete;
