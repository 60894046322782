import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import {
  DownloadMt940Button,
  PartialPaymentStatusBadge,
} from 'domains/billing/components';
import {
  Alert,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from 'elements';
import { BatchPayment, PartialPaymentStatus } from 'services/constants';

interface Props {
  batchPayments: BatchPayment[] | null;
  onRetry: () => void;
}

const BatchPaymentsList = ({ batchPayments, onRetry }: Props) => {
  const { t } = useTranslation();

  if (!batchPayments) {
    return (
      <Alert
        sx={{ m: 4 }}
        severity="error"
        action={
          <Button variant="text" color="error" size="small" onClick={onRetry}>
            {t('common.retry')}
          </Button>
        }
      >
        {t('errors.loadData')}
      </Alert>
    );
  }
  if (!batchPayments.length) {
    return (
      <Typography color="text.secondary" m={4}>
        {t('int.batchPaymentsList.noData')}
      </Typography>
    );
  }

  return (
    <Box m={3}>
      <TableContainer>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 145 }}>
                {t('int.batchPaymentsList.organization')}
              </TableCell>
              <TableCell sx={{ width: 110, whiteSpace: 'nowrap' }}>
                {t('int.batchPaymentsList.paymentCode')}
              </TableCell>
              <TableCell sx={{ width: 115 }} align="right">
                {t('int.batchPaymentsList.amount')}
              </TableCell>
              <TableCell sx={{ width: 110 }}>
                {t('int.batchPaymentsList.state')}
              </TableCell>
              <TableCell sx={{ width: 72 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {batchPayments.map((item) => (
              <TableRow key={item.publicId}>
                <TableCell>
                  <Tooltip title={item.organizationName}>
                    <Typography variant="body2" noWrap>
                      {item.organizationName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap>
                    {item.publicId.toUpperCase()}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2" noWrap>
                    <FormatMoney value={item.amount} fractionalPart />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      '> div': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <PartialPaymentStatusBadge status={item.status} />
                  </Box>
                </TableCell>
                <TableCell>
                  {[
                    PartialPaymentStatus.failed,
                    PartialPaymentStatus.paid,
                  ].includes(item.status) && (
                    <DownloadMt940Button paymentId={item.paymentExportId} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BatchPaymentsList;
