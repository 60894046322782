import { TransactionReceiptStatus } from 'services/constants';

export const getReceiptFilterApiParams = (value: string) => {
  switch (value) {
    case TransactionReceiptStatus.missing:
      return {
        receiptMissing: true,
      };
    case TransactionReceiptStatus.notNeeded:
      return {
        receiptNeeded: false,
      };
    case TransactionReceiptStatus.uploaded:
      return {
        receiptMissing: false,
      };
    default:
      return {};
  }
};
