import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  DataGridCell,
  GridColDef,
  IconButton,
  PencilSimpleIcon,
  StatusBadge,
} from 'elements';
import { Team, TeamStatus } from 'services/constants';
import { useCanUser } from 'services/rbac';

const useColumns = (onEdit: (item: Team) => void) => {
  const { t, i18n } = useTranslation();
  const canUser = useCanUser();

  return useMemo(() => {
    const columns: GridColDef<Team>[] = [
      {
        field: 'name',
        headerName: t('constCenterSubPage.nameLabel'),
        flex: 1,
        renderCell: ({ row }) => (
          <Box display="flex" alignItems="center" overflow="hidden">
            <DataGridCell>{row.name}</DataGridCell>
            {row.teamStatus === TeamStatus.deactivated && (
              <StatusBadge
                color="error"
                sx={{ ml: 1, flexShrink: 0 }}
                label={t('constCenterSubPage.inactiveBadgeLabel')}
              />
            )}
          </Box>
        ),
      },
      {
        field: 'costCenter',
        headerName: t('constCenterSubPage.costCenterLabel'),
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 72,
        getActions: ({ row }) =>
          canUser('team-cost-center:change', row)
            ? [
                <IconButton onClick={() => onEdit(row)}>
                  <PencilSimpleIcon />
                </IconButton>,
              ]
            : [<></>],
      },
    ];

    return columns;
  }, [i18n.language]);
};

export default useColumns;
