import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Badge = styled.span`
  display: inline-block;
  padding: 3px 10px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  border-radius: 11px;
  color: ${({ theme }) => theme.colors.grey10};
  background: ${({ theme }) => theme.colors.yellow50};
`;

export const NewBadge = ({ className }: { className?: string }) => {
  const { t } = useTranslation();

  return <Badge className={className || ''}>{t('common.badge.new')}</Badge>;
};
