import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { IntegrationItem } from 'domains/settings/pages/IntegrationsPage';
import { Button, ButtonProps } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  IntegrationName,
  PartnerAuthStatus,
  PartnerIdType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends ButtonProps {
  integration: IntegrationItem | Partial<IntegrationItem>;
  onPartnerUnauthorized: (partnerId: PartnerIdType) => void;
  disabled?: boolean;
}

// this is a shared component for Integration and Partner pages
const AuthorizationRevokeComponent = ({
  integration,
  onPartnerUnauthorized,
  disabled,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isInModalLoading, setIsInModalLoading] = useState(false);

  const disconnectHandler = async () => {
    try {
      setIsInModalLoading(true);
      await api.unAuthorizePartner(
        integration.partnerDetails!.partnerId,
        integration.partnerDetails!.organizationId
      );
      if (!mounted.current) return;
      onPartnerUnauthorized(integration.partnerDetails!.partnerId);
    } catch (error) {
      if (!mounted.current) return;
      setIsInModalLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const getRemoveDescription = () => {
    if (integration.name === IntegrationName.circula)
      return t('integrationDialog.removeCirculaIntegrationDescription');
    if (integration.name === IntegrationName.candis)
      return t('integrationDialog.removeCandisIntegrationDescription');

    return t('integrationDialog.removePartnerIntegrationDescription', {
      name: integration.name,
    });
  };

  return (
    <>
      <Button
        color="error"
        onClick={() => setIsConfirmationModalOpen(true)}
        disabled={
          !canUser('partners:change') || !integration.partnerDetails || disabled
        }
        {...props}
      >
        {integration.status === PartnerAuthStatus.active
          ? t('integrationDialog.disconnectButton')
          : t('integrationDialog.pendingButton')}
      </Button>

      <ConfirmDialog
        title={t('integrationDialog.confirmTitle')}
        description={
          integration.status === PartnerAuthStatus.active ? (
            getRemoveDescription()
          ) : (
            <Trans
              i18nKey="integrationDialog.cancelPendingIntegrationDescription"
              components={{
                div: <div />,
              }}
            />
          )
        }
        open={isConfirmationModalOpen}
        loading={isInModalLoading}
        onSuccess={disconnectHandler}
        onClose={() => setIsConfirmationModalOpen(false)}
        confirmButtonProps={{
          children:
            integration.status === PartnerAuthStatus.active
              ? t('integrationDialog.yesRemove')
              : t('integrationDialog.yesCancel'),
        }}
      />
    </>
  );
};

export default AuthorizationRevokeComponent;
