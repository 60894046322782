import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { CardPicker, DialogStepper } from 'domains/card/components';
import {
  FormValues,
  getInitialValues,
  Step,
} from 'domains/card/dialogs/IssueCardDialog';
import useStepManager from 'domains/card/dialogs/IssueCardDialog/useStepManager';
import {
  useAvailableCardConfigSettings,
  useAvailableVirtualTravelCardSubtypes,
} from 'domains/card/hooks';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'elements';
import useSnackbar from 'hooks/useSnackbar';
import { CustomFieldType, Team } from 'services/constants';
import MemberAutocomplete from './MemberAutocomplete';

interface Props {
  managerTeam?: Team;
  isMemberAutocompleteDisabled: boolean;
  onClose: () => void;
}

const MemberAndTypeStep = ({
  managerTeam,
  isMemberAutocompleteDisabled,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const availableCardConfigSettings = useAvailableCardConfigSettings();
  const availableTravelCardSubtypes = useAvailableVirtualTravelCardSubtypes();
  const formik = useFormikContext<FormValues>();
  const stepManager = useStepManager();

  useEffect(() => {
    if (!availableCardConfigSettings.length)
      enqueueSnackbar(t('errors.general'), { variant: 'error' });
  }, [availableCardConfigSettings.length]);

  return (
    <>
      <DialogStepper<Step>
        currentStep={stepManager.currentStep}
        steps={stepManager.steps}
        goTo={stepManager.goTo}
        getStepLabel={stepManager.getStepLabel}
      />
      <DialogTitle>
        {t('issueCardDialog_v2.memberAndTypeStep.title')}
      </DialogTitle>
      <DialogContent>
        <MemberAutocomplete
          value={formik.values.member}
          onChange={(member) =>
            formik.resetForm({
              values: getInitialValues({
                member,
                managerTeam,
                customFields: formik.values.customFieldsResponse.map(
                  (customField) => {
                    if (customField.type === CustomFieldType.select)
                      return customField.defaultOption;
                    return customField.defaultValue || '';
                  }
                ),
                customFieldsResponse: formik.values.customFieldsResponse,
              }),
            })
          }
          managerTeam={managerTeam}
          disabled={isMemberAutocompleteDisabled}
        />
        <Typography component="div" variant="caption" my={3}>
          {t('issueCardDialog_v2.memberAndTypeStep.chooseCardType')}
        </Typography>
        <CardPicker
          items={availableCardConfigSettings}
          travelCardSubtypes={availableTravelCardSubtypes}
          value={formik.values.cardConfigSetting}
          onChange={(cardConfigSetting) =>
            formik.resetForm({
              values: getInitialValues({
                member: formik.values.member,
                cardConfigSetting,
                managerTeam,
                customFields: formik.values.customFieldsResponse.map(
                  (customField) => {
                    if (customField.type === CustomFieldType.select)
                      return customField.defaultOption;
                    return customField.defaultValue || '';
                  }
                ),
                customFieldsResponse: formik.values.customFieldsResponse,
              }),
            })
          }
          memberId={formik.values.member?.id || null}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={!formik.values.member || !formik.values.cardConfigSetting}
          onClick={stepManager.goNext}
          data-test-id="issue-card-continue-btn"
        >
          {t('common.button.continue')}
        </Button>
      </DialogActions>
    </>
  );
};

export default MemberAndTypeStep;
