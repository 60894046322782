import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Checkbox, ErrorMessage } from 'components/Form';
import { getDocumentUrl } from 'domains/document/utils';
import useMounted from 'hooks/useMounted';
import {
  DocumentGenericType,
  DocumentsByGenericTypeMap,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

export const CheckboxLabel = styled.label`
  padding: 0 0 0 8px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
`;

interface Props {
  value: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

const AcceptTermsSection = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { orgId } = useParams<{ orgId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState<DocumentsByGenericTypeMap | null>(
    null
  );
  const platformTac =
    documents?.[DocumentGenericType.platformTermsAndConditions][0];

  const getData = async () => {
    try {
      const data = await api.getPublicDocuments(orgId);
      if (!mounted.current) return;
      setDocuments(data.documents);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box position="relative" my="30px">
      {!isLoading && platformTac && (
        <Box display="flex" alignItems="center" mx="-9px">
          <Checkbox checked={value} onChange={onChange} id="tac-checkbox" />
          <CheckboxLabel htmlFor="tac-checkbox">
            <Trans
              i18nKey="membershipActivationPage.acceptTac_v2"
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={getDocumentUrl(platformTac.documentName)}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
              values={platformTac}
            />
          </CheckboxLabel>
        </Box>
      )}
      {!isLoading && !platformTac && (
        <ErrorMessage>{t('errors.general')}</ErrorMessage>
      )}
      {isLoading && <CircularProgress size={22} thickness={3} />}
    </Box>
  );
};

export default AcceptTermsSection;
