import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, PlusIcon, Typography } from 'elements';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingTransaction,
  Transaction,
  UpdateAccountingTransactionsPayload,
} from 'services/constants';
import { getCurrencyFractionalDigits } from 'services/utils';
import SplitAccountingTransactionsItem from './SplitAccountingTransactionsItem';

const MAX_ACCOUNTING_TRANSACTIONS_LENGTH = 10;

interface EditableTransaction {
  key: string;
  transaction: AccountingTransaction | null;
  stringValue: string | null;
  floatValue: number | null;
}

const getNewEditableTransaction = (): EditableTransaction => ({
  key: Date.now().toString(),
  transaction: null,
  stringValue: null,
  floatValue: null,
});

const fromMinorUnits = (value: number, currency: string): number => {
  const fractionDigits = getCurrencyFractionalDigits(currency);
  const fractionCoefficient = 10 ** fractionDigits;
  return value / fractionCoefficient;
};
const toMinorUnits = (value: number, currency: string): number => {
  const fractionDigits = getCurrencyFractionalDigits(currency);
  const fractionCoefficient = 10 ** fractionDigits;
  return value * fractionCoefficient;
};

const toEditableTransactions = (
  accountingTransactions: AccountingTransaction[],
  currency: string
) => {
  const editableTransactions = accountingTransactions.map(
    (transaction) =>
      ({
        key: `${transaction.index}`,
        transaction,
        stringValue: fromMinorUnits(
          transaction.fxAmount.value,
          currency
        ).toString(),
        floatValue: fromMinorUnits(transaction.fxAmount.value, currency),
      } as EditableTransaction)
  );
  return accountingTransactions.length > 1
    ? editableTransactions
    : [...editableTransactions, getNewEditableTransaction()];
};

const calcFirstTransactionValues = (
  editableTransactions: EditableTransaction[],
  transaction: Transaction
): EditableTransaction[] => {
  const [
    firstEditableTransaction,
    ...updatedEditableTransactions
  ] = editableTransactions;
  const sumOfUpdatedEditableTransactions = updatedEditableTransactions
    .map((item) => item.floatValue || 0)
    .map((v) => toMinorUnits(v, transaction.fxTransactionAmount.currency))
    .reduce((s, v) => s + v, 0);
  const firstTransactionCalculatedFxAmountValue =
    transaction.fxTransactionAmount.value - sumOfUpdatedEditableTransactions;
  const updatedFirstTransaction = {
    ...firstEditableTransaction,
    stringValue: fromMinorUnits(
      firstTransactionCalculatedFxAmountValue,
      transaction.fxTransactionAmount.currency
    ).toString(),
    floatValue: fromMinorUnits(
      firstTransactionCalculatedFxAmountValue,
      transaction.fxTransactionAmount.currency
    ),
  };
  return [updatedFirstTransaction, ...updatedEditableTransactions];
};

interface Props {
  transaction: Transaction;
  accountingTransactions: AccountingTransaction[];
  onUpdate: (transactions: UpdateAccountingTransactionsPayload) => void;
  onCancel: () => void;
}

const SplitAccountingTransactions = ({
  transaction,
  accountingTransactions: initialAccountingTransactions,
  onUpdate,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [editableTransactions, setEditableTransactions] = useState(
    toEditableTransactions(
      initialAccountingTransactions,
      transaction.fxTransactionAmount.currency
    )
  );

  const updateEditableItemValue = (
    key: string,
    stringValue: string,
    floatValue: number | null
  ) => {
    const updatedEditableTransactions = editableTransactions.map((item) =>
      item.key === key ? { ...item, stringValue, floatValue } : item
    );
    const editableTransactionsWithCalculatedFirstItemValues = calcFirstTransactionValues(
      updatedEditableTransactions,
      transaction
    );
    setEditableTransactions(editableTransactionsWithCalculatedFirstItemValues);
  };

  const removeEditableItem = (key: string) => {
    const editableTransactionsWithoutRemovedItem = editableTransactions.filter(
      (item) => item.key !== key
    );
    const editableTransactionsWithCalculatedFirstItemValues = calcFirstTransactionValues(
      editableTransactionsWithoutRemovedItem,
      transaction
    );
    setEditableTransactions(editableTransactionsWithCalculatedFirstItemValues);
  };

  const onSubmit = () => {
    if (editableTransactions.some((item) => item.floatValue === null)) {
      enqueueSnackbar(t('transactionDetails.splitErrorEmptyField'), {
        variant: 'error',
      });
      return;
    }
    if (editableTransactions.some((item) => item.floatValue === 0)) {
      enqueueSnackbar(t('transactionDetails.splitErrorZeroValue'), {
        variant: 'error',
      });
      return;
    }

    const updateAccountingTransactionPayload = editableTransactions.map(
      (item, index) => ({
        index,
        id: item.transaction?.id,
        category: item.transaction?.category || transaction.category,
        subcategoryId: item.transaction?.subcategoryId || null,
        vatRateId: item.transaction?.vatRateId || null,
        projectId: item.transaction?.projectId || null,
        projectName: item.transaction?.projectName || null,
        costUnit: item.transaction?.costUnit || null,
        teamId: item.transaction?.teamId || transaction.teamId || null,
        fxAmount: {
          ...transaction.fxTransactionAmount,
          value: Math.round(
            toMinorUnits(
              item.floatValue!,
              transaction.fxTransactionAmount.currency
            )
          ),
        },
        txnCustomFieldIdToField:
          item.transaction?.txnCustomFieldIdToField || {},
      })
    );

    onUpdate(updateAccountingTransactionPayload);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1.5}
      >
        <Typography variant="overline" color="textSecondary">
          {t('transactionDetails.splitTransactions')}
        </Typography>

        <Button
          size="small"
          variant="text"
          onClick={() => {
            setEditableTransactions([
              ...editableTransactions,
              getNewEditableTransaction(),
            ]);
          }}
          disabled={
            editableTransactions.length === MAX_ACCOUNTING_TRANSACTIONS_LENGTH
          }
          startIcon={<PlusIcon />}
        >
          {t('transactionDetails.addTransactionToSplit')}
        </Button>
      </Box>

      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {editableTransactions.map((item, index) => (
          <SplitAccountingTransactionsItem
            key={item.key}
            transaction={transaction}
            value={item.stringValue}
            onChange={(stringValue, floatValue) =>
              updateEditableItemValue(item.key, stringValue, floatValue)
            }
            readOnly={!index}
            onRemove={() => {
              removeEditableItem(item.key);
            }}
          />
        ))}

        <Box textAlign="right" mt={3}>
          <Button variant="outlined" onClick={onCancel}>
            {t('common.button.cancel')}
          </Button>
          <Button type="submit" sx={{ ml: 1 }}>
            {t('common.button.save')}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default SplitAccountingTransactions;
