import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MerchantCategoryIcon } from 'domains/merchant/components';
import { Box, LinkIcon, Paper, PlusIcon, Typography } from 'elements';
import { OrganizationMerchant } from 'services/constants';
import { useCanUser } from 'services/rbac';
import ChangeSubcategoryDialog from './ChangeSubcategoryDialog';

interface Props {
  value: OrganizationMerchant['subcategoryResponse'];
  onChange: (value: OrganizationMerchant['subcategoryResponse']) => void;
  merchantId: string;
}

const SubcategorySelect = ({ value, onChange, merchantId }: Props) => {
  const { t } = useTranslation();
  const {
    state: { subcategories },
  } = useGlobalState();
  const canUser = useCanUser();
  const [isOpen, setIsOpen] = useState(false);

  const selectedCategory = useMemo(
    () => subcategories?.find((item) => item.id === value?.id),
    [subcategories, value]
  );

  return (
    <>
      <Paper
        variant="outlined"
        onClick={() => {
          if (!canUser('subcategory:assign')) return;
          setIsOpen(true);
        }}
        sx={{
          height: 58,
          display: 'flex',
          alignItems: 'center',
          mb: 1,
          px: 1.5,
          cursor: canUser('subcategory:assign') ? 'pointer' : undefined,
        }}
      >
        {value ? (
          <>
            <Box mr="auto" pr={1}>
              <Typography variant="body2">
                {t('subcategorySelect.subcategory')}
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
              >
                {t('subcategorySelect.glAccount')}
              </Typography>
            </Box>
            {selectedCategory && (
              <MerchantCategoryIcon
                sx={{ mr: 1 }}
                category={selectedCategory.category}
              />
            )}
            <Box textAlign="right" overflow="hidden">
              <Typography variant="body2" noWrap>
                {value.name}
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
              >
                {value.number}
              </Typography>
            </Box>
            {canUser('subcategory:assign') && (
              <LinkIcon sx={{ ml: 2, mr: 0.5, color: 'action.active' }} />
            )}
          </>
        ) : (
          <>
            <Typography variant="body2">
              {t('subcategorySelect.subcategory')}
            </Typography>
            <Typography variant="caption" color="textSecondary" ml="auto">
              —
            </Typography>
            {canUser('subcategory:assign') && (
              <PlusIcon sx={{ ml: 2, mr: 0.5, color: 'action.active' }} />
            )}
          </>
        )}
      </Paper>
      <ChangeSubcategoryDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onSuccess={(value) => {
          onChange(value);
          setIsOpen(false);
        }}
        subcategoryResponse={value}
        merchantId={merchantId}
      />
    </>
  );
};

export default SubcategorySelect;
