import React from 'react';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AmlRiskScoring,
  ComplianceRatingPayload,
  ComplianceRatingValue,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getBooleanFromYesNo,
  getGenericErrorMsg,
  getYesNoFromBoolean,
} from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface FormValues
  extends Omit<
    ComplianceRatingPayload,
    | 'adverseMedia'
    | 'amlScreening'
    | 'complianceRatingValue'
    | 'highRiskCountry'
    | 'highRiskIndustry'
    | 'onboardingType'
    | 'pep'
    | 'sanctionScreening'
    | 'sanctioned'
    | 'tpmlRating'
    | 'unusuallyComplexStructure'
    | 'vgRating'
  > {
  adverseMedia: string;
  amlScreening: string;
  complianceRatingValue: string;
  highRiskCountry: string;
  highRiskIndustry: string;
  nextMonitoringDate: string;
  onboardingType: string;
  pep: string;
  sanctionScreening: string;
  sanctioned: string;
  tpmlRating: string;
  unusuallyComplexStructure: string;
  vgRating: string;
}

const EditComplianceRatingDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    actions: { setComplianceRating },
    state: { complianceCompany, complianceStaticData },
  } = useCreditAndComplianceContext();
  const complianceRating = complianceCompany!.complianceRating;

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      adverseMedia: getYesNoFromBoolean(
        complianceRating.adverseMedia.value,
        t,
        {
          returnSlug: true,
        }
      ),
      amlScreening: complianceRating.amlScreening.value ?? '',
      amlScreeningDate: complianceRating.amlScreeningDate.value ?? '',
      complianceRatingValue: complianceRating.complianceRatingValue.value ?? '',
      highRiskCountry: getYesNoFromBoolean(
        complianceRating.highRiskCountry.value,
        t,
        {
          returnSlug: true,
        }
      ),
      highRiskIndustry: getYesNoFromBoolean(
        complianceRating.highRiskIndustry.value,
        t,
        {
          returnSlug: true,
        }
      ),
      nextMonitoringDate: complianceRating.nextMonitoringDate.value ?? '',
      onboardingType: complianceRating.onboardingType.value ?? '',
      other: complianceRating.other.value ?? '',
      pep: getYesNoFromBoolean(complianceRating.pep.value, t, {
        returnSlug: true,
      }),
      sanctionScreening:
        typeof complianceRating.sanctionScreening.value === 'boolean'
          ? 'OK'
          : '',
      sanctionScreeningDate: complianceRating.sanctionScreeningDate.value ?? '',
      sanctioned: getYesNoFromBoolean(complianceRating.sanctioned.value, t, {
        returnSlug: true,
      }),
      unusuallyComplexStructure: getYesNoFromBoolean(
        complianceRating.unusuallyComplexStructure.value,
        t,
        {
          returnSlug: true,
        }
      ),
      vgRating: complianceRating.vgRating.value ?? '',
      tpmlRating: complianceRating.tpmlRating.value ?? '',
    },
    validate: (values) => {
      const errors: { [k in keyof FormValues]?: string } = {};

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const payload: ComplianceRatingPayload = {
          ...values,
          adverseMedia: getBooleanFromYesNo(values.adverseMedia),
          amlScreening: (values.amlScreening as AmlRiskScoring) || null,
          amlScreeningDate: values.amlScreeningDate || null,
          complianceRatingValue:
            (values.complianceRatingValue as ComplianceRatingValue) || null,
          highRiskCountry: getBooleanFromYesNo(values.highRiskCountry),
          highRiskIndustry: getBooleanFromYesNo(values.highRiskIndustry),
          nextMonitoringDate: values.nextMonitoringDate || null,
          onboardingType: values.onboardingType || null,
          pep: getBooleanFromYesNo(values.pep),
          sanctionScreening: values.sanctionScreening === 'OK' || null, // can only be true due to select options and validation
          sanctionScreeningDate: values.sanctionScreeningDate || null,
          sanctioned: getBooleanFromYesNo(values.sanctioned),
          tpmlRating: values.tpmlRating || null,
          unusuallyComplexStructure: getBooleanFromYesNo(
            values.unusuallyComplexStructure
          ),
          vgRating: values.vgRating || null,
        };

        const updatedComplianceRating = await api.updateComplianceRating(
          organization!.id,
          payload
        );
        setComplianceRating(updatedComplianceRating);

        if (!mounted) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const getCustomFieldProps = <T extends keyof FormValues>(name: T) => {
    return {
      ...(formik.errors[name] && {
        error: true,
        helperText: formik.errors[name],
      }),
      name,
      value: formik.values[name],
    };
  };
  const onChange = <T extends keyof FormValues>(
    name: T,
    value: FormValues[T]
  ) => {
    if (formik.errors[name]) {
      formik.setFieldError(name, undefined);
    }
    formik.setFieldValue(name, value);
  };

  const yesNoOptions = [
    { name: 'no', label: t('common.no') },
    { name: 'yes', label: t('common.yes') },
  ];

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('int.editComplianceRatingDialog.title')}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.sanctionScreening}>
              <InputLabel>
                {t('int.editComplianceRatingDialog.sanctionScreening')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('sanctionScreening'),
                  'helperText'
                )}
                onChange={({ target: { value } }) =>
                  onChange('sanctionScreening', value)
                }
              >
                {[{ name: 'OK', label: 'OK' }].map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.sanctionScreening}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('sanctionScreeningDate')}
              onChange={(event) =>
                onChange('sanctionScreeningDate', event.target.value)
              }
              label={t('int.editComplianceRatingDialog.sanctionScreeningDate')}
              type="date"
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.amlScreening}>
              <InputLabel>
                {t('int.editComplianceRatingDialog.amlScreening')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('amlScreening'), 'helperText')}
                onChange={({ target: { value } }) =>
                  onChange('amlScreening', value)
                }
              >
                {complianceStaticData!.amlScreeningValues.map(
                  ({ label, name }) => (
                    <MenuItem key={name} value={name}>
                      {label}
                    </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText>{formik.errors.amlScreening}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('amlScreeningDate')}
              onChange={(event) =>
                onChange('amlScreeningDate', event.target.value)
              }
              label={t('int.editComplianceRatingDialog.amlScreeningDate')}
              type="date"
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.sanctioned}>
              <InputLabel>
                {t('int.editComplianceRatingDialog.sanctioned')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('sanctioned'), 'helperText')}
                onChange={({ target: { value } }) =>
                  onChange('sanctioned', value)
                }
              >
                {yesNoOptions.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.sanctioned}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.pep}>
              <InputLabel>{t('int.editComplianceRatingDialog.pep')}</InputLabel>
              <Select
                {...omit(getCustomFieldProps('pep'), 'helperText')}
                onChange={({ target: { value } }) => onChange('pep', value)}
              >
                {yesNoOptions.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.pep}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.adverseMedia}>
              <InputLabel>
                {t('int.editComplianceRatingDialog.adverseMedia')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('adverseMedia'), 'helperText')}
                onChange={({ target: { value } }) =>
                  onChange('adverseMedia', value)
                }
              >
                {yesNoOptions.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.adverseMedia}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6} />

          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.highRiskIndustry}>
              <InputLabel>
                {t('int.editComplianceRatingDialog.highRiskIndustry')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('highRiskIndustry'), 'helperText')}
                onChange={({ target: { value } }) =>
                  onChange('highRiskIndustry', value)
                }
              >
                {yesNoOptions.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.highRiskIndustry}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.highRiskCountry}>
              <InputLabel>
                {t('int.editComplianceRatingDialog.highRiskCountry')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('highRiskCountry'), 'helperText')}
                onChange={({ target: { value } }) =>
                  onChange('highRiskCountry', value)
                }
              >
                {yesNoOptions.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.highRiskCountry}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={!!formik.errors.unusuallyComplexStructure}
            >
              <InputLabel>
                {t('int.editComplianceRatingDialog.unusuallyComplexStructure')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('unusuallyComplexStructure'),
                  'helperText'
                )}
                onChange={({ target: { value } }) =>
                  onChange('unusuallyComplexStructure', value)
                }
              >
                {yesNoOptions.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.unusuallyComplexStructure}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('other')}
              onChange={(event) => onChange('other', event.target.value)}
              label={t('int.editComplianceRatingDialog.other')}
              multiline
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={!!formik.errors.complianceRatingValue}
            >
              <InputLabel>
                {t('int.editComplianceRatingDialog.complianceRating')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('complianceRatingValue'),
                  'helperText'
                )}
                onChange={({ target: { value } }) =>
                  onChange('complianceRatingValue', value)
                }
              >
                {complianceStaticData!.complianceRatingValues.map(
                  ({ label, name }) => (
                    <MenuItem key={name} value={name}>
                      {label}
                    </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText>
                {formik.errors.complianceRatingValue}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.tpmlRating}>
              <InputLabel>
                {t('int.editComplianceRatingDialog.tpmlRating')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('tpmlRating'), 'helperText')}
                onChange={({ target: { value } }) =>
                  onChange('tpmlRating', value)
                }
              >
                {complianceStaticData!.tpmlRatings.map(({ label, name }) => (
                  <MenuItem key={name} value={name}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.tpmlRating}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.vgRating}>
              <InputLabel>
                {t('int.editComplianceRatingDialog.vgRating')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('vgRating'), 'helperText')}
                onChange={({ target: { value } }) =>
                  onChange('vgRating', value)
                }
              >
                {complianceStaticData!.vgRatings.map(({ label, name }) => (
                  <MenuItem key={name} value={name}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.vgRating}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.onboardingType}>
              <InputLabel>
                {t('int.editComplianceRatingDialog.onboardingType')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('onboardingType'), 'helperText')}
                onChange={({ target: { value } }) =>
                  onChange('onboardingType', value)
                }
              >
                {complianceStaticData!.onboardingTypes.map(
                  ({ label, name }) => (
                    <MenuItem key={name} value={name}>
                      {label}
                    </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText>{formik.errors.onboardingType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('nextMonitoringDate')}
              onChange={(event) =>
                onChange('nextMonitoringDate', event.target.value)
              }
              label={t('int.editComplianceRatingDialog.nextMonitoringDate')}
              type="date"
              inputProps={{
                min: moment().add(1, 'days').format('YYYY-MM-DD'),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={formik.isSubmitting} onClick={formik.submitForm}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditComplianceRatingDialog);
