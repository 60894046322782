import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  decodeMonthlyValue,
  doubleDigitFormat,
  encodeMonthlyValue,
  MONTHLY_DATE_FORMAT,
} from 'domains/card/utils';
import {
  Box,
  CalendarBlankIcon,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from 'elements';

interface Props {
  testId?: string;
  startDay: string | null;
  endDay: string | null;
  onDatesChange: (from: string, to: string | null) => void;
  disabled?: boolean;
}

interface State {
  from: string | null;
  to: string | null;
}

const DAYS = Array.from(Array(29).keys()).map((item) =>
  doubleDigitFormat((item + 1).toString())
);

export const DayRangePicker = ({
  testId,
  startDay,
  endDay,
  onDatesChange,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    from: decodeMonthlyValue(startDay) || null,
    to: decodeMonthlyValue(endDay) || null,
  });

  const isEndDayDisabled = (day: string) => {
    return (
      !!state.from && parseInt(day, 10) < parseInt(state.from as string, 10)
    );
  };

  const handleStartDayClick = (day: string) => {
    const intFromDay = parseInt(day || '', 10);
    const intToDay = parseInt(state.to || '', 10);
    const updatedTo = intFromDay < intToDay ? state.to : null;
    setState({ from: day, to: updatedTo });
    onDatesChange(day, updatedTo);
  };

  const handleEndDayClick = (day: string) => {
    setState((prevState) => ({ ...prevState, to: day }));
    onDatesChange(state.from as string, day);
  };

  const renderDay = (day: string | null) => {
    if (!day) {
      return MONTHLY_DATE_FORMAT;
    } else if (parseInt(day, 10) <= 28) {
      return day;
    }
    return t('common.ordinalNumbers.last');
  };

  return (
    <Box
      data-test-id={testId}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box flex={1} mr={1}>
        <FormControl fullWidth disabled={disabled}>
          <InputLabel>{t('common.from')}</InputLabel>
          <Select
            startAdornment={
              <InputAdornment position="start">
                <CalendarBlankIcon fontSize="small" />
              </InputAdornment>
            }
            value={state.from || ''}
            renderValue={(value) => renderDay(value)}
            onChange={(e) => handleStartDayClick(e.target.value)}
            displayEmpty
          >
            {DAYS.map((item) => {
              const encodedItem = encodeMonthlyValue(item.toString());
              const text =
                encodedItem === item.toString()
                  ? item
                  : t(`common.ordinalNumbers.${encodedItem}`);

              return (
                <MenuItem key={item} value={item}>
                  {text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box flex={1} ml={1}>
        <FormControl fullWidth disabled={disabled || !startDay}>
          <InputLabel>{t('common.to')}</InputLabel>
          <Select
            startAdornment={
              <InputAdornment position="start">
                <CalendarBlankIcon fontSize="small" />
              </InputAdornment>
            }
            value={state.to || ''}
            renderValue={(value) => renderDay(value)}
            onChange={(e) => handleEndDayClick(e.target.value)}
            displayEmpty
          >
            {DAYS.map((item) => {
              const encodedItem = encodeMonthlyValue(item.toString());
              const text =
                encodedItem === item.toString()
                  ? item
                  : t(`common.ordinalNumbers.${encodedItem}`);

              return (
                <MenuItem
                  key={item}
                  value={item}
                  disabled={isEndDayDisabled(item)}
                >
                  {text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
