import { useTranslation } from 'react-i18next';
import { MerchantCategoryIcon } from 'domains/merchant/components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  ListItemIcon,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { FilterDrawerClearButton } from 'layout';
import { merchantCategories, MerchantCategory } from 'services/constants';

interface Props {
  value: MerchantCategory[];
  onChange: (value: MerchantCategory[]) => void;
}

const CategoryFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-category"
        id="panel-category-header"
      >
        <Typography>{t('filters.categories')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<MerchantCategory[]>
            size="medium"
            multiple
            displayEmpty
            value={value}
            onChange={(e) => onChange(e.target.value as MerchantCategory[])}
            renderValue={(selected) => {
              if (!selected?.length)
                return (
                  <Typography color="text.disabled">
                    {t('filters.categories')}
                  </Typography>
                );
              return selected
                .map((category) => t(`merchantCategories.${category}`))
                .join(', ');
            }}
          >
            {merchantCategories.map((category) => (
              <MenuItem key={category} value={category}>
                <ListItemIcon>
                  <MerchantCategoryIcon category={category} />
                </ListItemIcon>
                {t(`merchantCategories.${category}`)}
              </MenuItem>
            ))}
          </Select>

          {!!value.length && <FilterDrawerClearButton onChange={onChange} />}
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default CategoryFilter;
