import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { ReceiptDropzone } from 'domains/transaction/components';
import { useUnmatchedReceiptsCount } from 'domains/transaction/hooks';
import {
  Box,
  Button,
  CircularProgress,
  EnvelopeSimpleIcon,
  Link,
  Paper,
  PlusIcon,
  Typography,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Receipt,
  ReceiptInboxMatchingFlow,
  Transaction,
  TransactionSimpleType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  isGlobalTxPage: boolean;
  transaction: Transaction;
  receipts: Receipt[];
  disabled: boolean;
  onReceiptMatch: () => void;
  onUploadSuccess: () => void;
  onUploadStart: () => void;
  onUploadFail: (error: unknown) => void;
}

/**
 * Combined component for a dropzone and match receipt functionality
 */
const ReceiptInputs = ({
  isGlobalTxPage,
  transaction,
  receipts,
  disabled,
  onReceiptMatch,
  onUploadSuccess,
  onUploadStart,
  onUploadFail,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { isCardholderApp } = useCurrentApp();
  const unmatchedReceiptsCountAppBased = useUnmatchedReceiptsCount();
  const {
    dispatch,
    state: { organization, unmatchedReceiptsCounts, receiptInbox },
  } = useGlobalState();
  const [isMatchingInProgress, setIsMatchingInProgress] = useState(false);

  const matchReceiptToTransaction = async () => {
    const receiptId = receiptInbox.receipt!.id;
    const flow = receiptInbox.flow;
    try {
      setIsMatchingInProgress(true);
      dispatch({
        type: 'SET_RECEIPT_INBOX_DATA',
        payload: {
          isDialogOpen: false,
          // do not reset flow if it's selectedReceiptMatching
          flow:
            flow === ReceiptInboxMatchingFlow.selectedReceiptMatching
              ? ReceiptInboxMatchingFlow.selectedReceiptMatching
              : null,
          selectedTransationHasReceipt: false,
          receipt: null,
          thumbnail: '',
        },
      });
      await api.matchReceiptToTransaction(receiptId, transaction.transactionId);

      const {
        unmatchedTaskCount,
        unmatchedTaskCountSelf,
      } = await api
        .getReceiptAutoMatchingCount(organization!.id)
        .catch((error) => {
          logError(error);
          return unmatchedReceiptsCounts;
        });
      dispatch({
        type: 'SET_UNMATCHED_RECEIPTS_COUNT',
        payload: { unmatchedTaskCount, unmatchedTaskCountSelf },
      });

      if (!mounted.current) return;
      const shownCount = isCardholderApp
        ? unmatchedTaskCountSelf
        : unmatchedTaskCount;
      setIsMatchingInProgress(false);
      if (flow === ReceiptInboxMatchingFlow.selectedTxMathing) {
        enqueueSnackbar(t('transactionReceipts.receiptAddSuccessMsg'));
      }

      if (
        flow === ReceiptInboxMatchingFlow.selectedReceiptMatching &&
        shownCount === 0
      ) {
        dispatch({ type: 'RESET_RECEIPT_INBOX_DATA' });
        dispatch({ type: 'TOGGLE_SIDEBAR', payload: true });
        enqueueSnackbar(t('transactionReceipts.allReceiptsMatchedSuccessMsg'));
      }

      if (
        flow === ReceiptInboxMatchingFlow.selectedReceiptMatching &&
        shownCount > 0
      ) {
        enqueueSnackbar(
          <>
            {t('transactionReceipts.receiptMatchedSuccessMsg')}{' '}
            <Link
              component="button"
              color="inherit"
              onClick={() => {
                dispatch({
                  type: 'SET_RECEIPT_INBOX_DATA',
                  payload: {
                    isDialogOpen: true,
                  },
                });
                closeSnackbar();
              }}
            >
              {t('transactionReceipts.continueMatchingMsg', {
                count: shownCount,
              })}
            </Link>
          </>,
          {
            persist: true,
            onClose: () => {
              dispatch({ type: 'RESET_RECEIPT_INBOX_DATA' });
              dispatch({ type: 'TOGGLE_SIDEBAR', payload: true });
            },
          }
        );
      }

      onReceiptMatch();
    } catch (error) {
      if (!mounted.current) return;
      setIsMatchingInProgress(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      if (flow === ReceiptInboxMatchingFlow.selectedReceiptMatching) {
        dispatch({ type: 'TOGGLE_SIDEBAR', payload: true });
      }
      dispatch({ type: 'RESET_RECEIPT_INBOX_DATA' });
      logError(error);
    }
  };

  // Match a receipt to the selected transaction when a Receipt Inbox
  // SELECTED_TX_MATCHING Matching flow is triggered
  useEffect(() => {
    if (
      receiptInbox.receipt &&
      receiptInbox.flow === ReceiptInboxMatchingFlow.selectedTxMathing
    )
      matchReceiptToTransaction();
  }, [receiptInbox.receipt]);

  if (isMatchingInProgress)
    return (
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderStyle: 'dashed',
        }}
      >
        <Box>
          <Typography>{t('transactionReceipts.matchingReceipt')}</Typography>
          <Typography variant="body2" color="textSecondary">
            {t('transactionReceipts.matchingLoading')}
          </Typography>
        </Box>
        <CircularProgress />
      </Paper>
    );

  // Matching selected receipt to a transaction flow: SELECTED_RECEIPT_MATCHING
  if (receiptInbox.flow === ReceiptInboxMatchingFlow.selectedReceiptMatching)
    return (
      <Button
        fullWidth
        sx={{ mb: 1 }}
        startIcon={<PlusIcon />}
        disabled={disabled || !receiptInbox.receipt}
        onClick={matchReceiptToTransaction}
      >
        {t('transactionReceipts.addReceiptHere')}
      </Button>
    );

  return (
    <>
      <ReceiptDropzone
        transaction={transaction}
        showMissingReceiptMessage={
          transaction.simpleType !== TransactionSimpleType.statusInquiry &&
          !!transaction.transactionAmount.value &&
          !receipts.length
        }
        onSuccess={onUploadSuccess}
        onUploadStart={onUploadStart}
        onFail={onUploadFail}
        disabled={!canUser('receipt:upload') || disabled}
      />

      {!isGlobalTxPage &&
        canUser('receipt-inbox:change') &&
        unmatchedReceiptsCountAppBased > 0 && (
          <Button
            fullWidth
            sx={{ mb: 1 }}
            startIcon={<EnvelopeSimpleIcon />}
            disabled={disabled}
            onClick={() =>
              dispatch({
                type: 'SET_RECEIPT_INBOX_DATA',
                payload: {
                  isDialogOpen: true,
                  flow: ReceiptInboxMatchingFlow.selectedTxMathing,
                  selectedTransationHasReceipt: !!receipts.length,
                  receipt: null,
                  thumbnail: '',
                },
              })
            }
          >
            {t('transactionReceipts.addFromInboxBtn', {
              count: unmatchedReceiptsCountAppBased,
            })}
          </Button>
        )}
    </>
  );
};

export default ReceiptInputs;
