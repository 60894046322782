import { MutableRefObject } from 'react';
import { DataGrid, GridApiPro } from 'elements';
import { ReceiptAutoMatching } from 'services/constants';
import useColumns from './useColumns';

interface Props {
  dataGridRef: MutableRefObject<GridApiPro>;
  isLoading: boolean;
  receipts: ReceiptAutoMatching[];
  thumbnails: { [key: string]: string | null };
  isThumbnailLoading: boolean;
  hasNextPage: boolean;
  totalCount: number;
  selectedReceiptId: string;
  setSelectedReceipt: (receiptId: string) => void;
  loadMoreItems: () => void;
}

const ReceiptInboxTable = ({
  dataGridRef,
  isLoading,
  receipts,
  thumbnails,
  isThumbnailLoading,
  hasNextPage,
  totalCount,
  selectedReceiptId,
  setSelectedReceipt,
  loadMoreItems,
}: Props) => {
  const columns = useColumns(thumbnails, isThumbnailLoading);

  return (
    <DataGrid<ReceiptAutoMatching>
      apiRef={dataGridRef}
      rowHeight={72}
      loading={isLoading}
      rows={receipts}
      columns={columns}
      rowCount={totalCount}
      rowSelectionModel={selectedReceiptId ? [selectedReceiptId] : []}
      onRowClick={({ row }) => setSelectedReceipt(row.id)}
      columnVisibilityModel={{
        sender: !selectedReceiptId,
      }}
      onRowsScrollEnd={() => {
        if (!isLoading && hasNextPage) loadMoreItems();
      }}
      slots={{
        noRowsOverlay: () => null,
        loadingOverlay: () => null,
      }}
    />
  );
};

export default ReceiptInboxTable;
