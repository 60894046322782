import { ReactNode, Suspense, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import PdfViewer from 'components/PdfViewer';
import {
  Box,
  CaretDownIcon,
  CaretUpIcon,
  CircularProgress,
  Dialog,
  DialogProps,
  Divider,
  DownloadSimpleIcon,
  Grid,
  IconButton,
  LoaderWithOverlay,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  Typography,
  withDialogWrapper,
  XIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  downloadResponseAsFile,
  getFileNameFromHeader,
  getGenericErrorMsg,
  transformArrayBufferToString,
} from 'services/utils';

const SCALE_STEP = 0.15;
export const DEFAULT_SCALE = 1;
const DEFAULT_PAGE = 1;
const DEFAULT_NUM_PAGES = 1;

interface State {
  isLoading: boolean;
  data: string | null;
  fileName: string;
  page: number;
  totalPages: number;
  scale: number;
  isDownloading: boolean;
}

interface Props extends DialogProps {
  onClose: () => void;
  reportId: string;
  children: ReactNode;
}

const ReportPreviewDialog = ({
  reportId,
  onClose,
  children,
  ...props
}: Props) => {
  const listRef = useRef<FixedSizeList>(null);
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const [state, setState] = useState<State>({
    isLoading: true,
    data: null,
    fileName: '',
    page: DEFAULT_PAGE,
    totalPages: DEFAULT_NUM_PAGES,
    scale: DEFAULT_SCALE,
    isDownloading: false,
  });

  const getPdf = async () => {
    try {
      const response = await api.getPublicDocumentContent(reportId);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        data: transformArrayBufferToString(response.data, response.headers),
        fileName: getFileNameFromHeader(response.headers),
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      onClose();
    }
  };

  useEffect(() => {
    getPdf();
  }, []);

  const downloadOriginal = async () => {
    setState((prevState) => ({ ...prevState, isDownloading: true }));
    try {
      const response = await api.getPublicDocumentContent(reportId);
      downloadResponseAsFile(response);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isDownloading: false }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isDownloading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const setPage = (page: number) => {
    listRef.current?.scrollToItem(page - 1, 'start');
    setState((prevState) => ({ ...prevState, page }));
  };

  const setScale = (scale: number) => {
    setState((prevState) => ({ ...prevState, scale: scale }));
  };

  const scalePercent = Math.round((state.scale - DEFAULT_SCALE + 1) * 100);

  return (
    <Dialog {...props} onClose={onClose} fullScreen>
      <IconButton
        sx={{ position: 'absolute', zIndex: 20, top: 11, left: 10 }}
        onClick={onClose}
      >
        <XIcon />
      </IconButton>
      <Grid container height="100vh" overflow="hidden">
        <Grid
          item
          xs={8}
          sx={{ display: 'flex', flexDirection: 'column', height: 1 }}
        >
          {state.data && (
            <>
              <Box display="flex" alignItems="center" height="60px">
                <Box width="40%" pr={1} pl={8}>
                  <Typography noWrap>{state.fileName}</Typography>
                </Box>
                <Box width="20%" whiteSpace="nowrap" textAlign="center">
                  <Box
                    position="relative"
                    display="inline-flex"
                    justifyContent="center"
                  >
                    {state.totalPages > 1 && (
                      <IconButton
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          left: '-45px',
                        }}
                        disabled={state.page <= 1}
                        onClick={() => setPage(state.page - 1)}
                      >
                        <CaretUpIcon fontSize="small" />
                      </IconButton>
                    )}
                    <Typography variant="body2" noWrap>
                      <Trans
                        i18nKey="accountingEntryDetailsPage.pagination"
                        values={{
                          page: state.page,
                          total: state.totalPages,
                        }}
                      />
                    </Typography>
                    {state.totalPages > 1 && (
                      <IconButton
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          right: '-45px',
                        }}
                        disabled={state.page >= state.totalPages}
                        onClick={() => setPage(state.page + 1)}
                      >
                        <CaretDownIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                </Box>
                <Box width="20%" textAlign="center">
                  <Box
                    position="relative"
                    display="inline-flex"
                    justifyContent="center"
                    width="48px"
                  >
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        left: '-40px',
                      }}
                      disabled={scalePercent <= 40}
                      onClick={() =>
                        setScale(
                          parseFloat((state.scale - SCALE_STEP).toFixed(2))
                        )
                      }
                    >
                      <MagnifyingGlassMinusIcon fontSize="small" />
                    </IconButton>
                    <Typography noWrap>{scalePercent}%</Typography>
                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '-40px',
                      }}
                      disabled={scalePercent >= 190}
                      onClick={() =>
                        setScale(
                          parseFloat((state.scale + SCALE_STEP).toFixed(2))
                        )
                      }
                    >
                      <MagnifyingGlassPlusIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                <Box width="20%" textAlign="right" pr="16px">
                  <IconButton
                    size="small"
                    sx={{ width: 40, height: 40 }}
                    onClick={downloadOriginal}
                    disabled={state.isDownloading}
                  >
                    {state.isDownloading ? (
                      <CircularProgress size="small" />
                    ) : (
                      <DownloadSimpleIcon fontSize="small" />
                    )}
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                flexGrow={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
                minHeight={0}
              >
                <Suspense fallback={<LoaderWithOverlay loading />}>
                  <PdfViewer
                    ref={listRef}
                    selectedPage={state.page}
                    numPages={state.totalPages}
                    scale={state.scale}
                    onChange={(receiptPageNumber, receiptNumPages) =>
                      setState((prevState) => ({
                        ...prevState,
                        page: receiptPageNumber,
                        totalPages: receiptNumPages,
                      }))
                    }
                    data={state.data}
                    onError={(error) => {
                      enqueueSnackbar(getGenericErrorMsg(error), {
                        variant: 'error',
                      });
                      logError(error);
                      onClose();
                    }}
                  />
                </Suspense>
              </Box>
            </>
          )}
          <LoaderWithOverlay loading={state.isLoading} />
        </Grid>
        <Grid
          item
          xs={4}
          pt={1}
          sx={{
            maxHeight: 'calc(100vh - 8px)',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            borderLeft: 1,
            borderColor: 'divider',
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default withDialogWrapper(ReportPreviewDialog);
