import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MemberDetails, memberTitles } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, validateCardholderFullName } from 'services/utils';

interface FormValues {
  title: string;
  firstName: string;
  lastName: string;
}

interface Props extends DialogProps {
  member: MemberDetails;
  onClose: () => void;
  onSuccess: (member: MemberDetails) => void;
}

const EditMemberDialog = ({ member, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    initialValues: {
      title: member.title || '',
      firstName: member.firstName,
      lastName: member.lastName,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await api.updateMember(member.id, {
          organizationId: member.organizationId,
          ...values,
          title: values.title || null,
        });
        if (!mounted.current) return;
        onSuccess({
          ...member,
          ...values,
        });
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const {
    firstNameNotPrintableError,
    lastNameNotPrintableError,
    fullNameTooLongError,
  } = validateCardholderFullName(
    formik.values.firstName,
    formik.values.lastName
  );

  const isSubmitDisabled =
    !formik.values.firstName.trim() ||
    !formik.values.lastName.trim() ||
    !!firstNameNotPrintableError ||
    !!lastNameNotPrintableError ||
    !!fullNameTooLongError ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('editMemberDialog.editMemberDetails')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="edit-member-form" noValidate>
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12}>
              <FormControl disabled={formik.isSubmitting} fullWidth>
                <InputLabel id="member-title-select-label">
                  {t('editMemberDialog.title')}
                </InputLabel>
                <Select<string>
                  labelId="member-title-select-label"
                  renderValue={(selected) => t(`memberTitles.${selected}`)}
                  {...formik.getFieldProps('title')}
                >
                  <MenuItem value="">-</MenuItem>
                  {memberTitles.map((title) => (
                    <MenuItem key={title} value={title}>
                      {t(`memberTitles.${title}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('firstName')}
                label={t('editMemberDialog.firstName')}
                inputProps={{ maxLength: 50 }}
                disabled={formik.isSubmitting}
                error={!!firstNameNotPrintableError || !!fullNameTooLongError}
                helperText={firstNameNotPrintableError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('lastName')}
                label={t('editMemberDialog.lastName')}
                inputProps={{ maxLength: 50 }}
                disabled={formik.isSubmitting}
                error={!!lastNameNotPrintableError || !!fullNameTooLongError}
                helperText={lastNameNotPrintableError}
              />
            </Grid>
          </Grid>
          {!!fullNameTooLongError && (
            <FormHelperText error>{fullNameTooLongError}</FormHelperText>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="edit-member-form"
          type="submit"
        >
          {t('editMemberDialog.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditMemberDialog);
