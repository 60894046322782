import { useState } from 'react';
import CostCentersStep from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/CostCentersStep';
import CostUnitsStep from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/CostUnitsStep';
import GlAccountsStep from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/GLAccountsStep';
import SuppliersStep from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/SuppliersStep';
import VatRatesStep from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/VatRatesStep';
import { Dialog, DialogProps, withDialogWrapper } from 'elements';

export enum SyncNameEnum {
  accounts = 'ACCOUNTS',
  vatRates = 'VAT_RATES',
  suppliers = 'SUPPLIERS',
  trackingCategories = 'TRACKING_CATEGORIES',
}

interface Props extends DialogProps {
  name: SyncNameEnum;
  onSuccess: () => void;
  onClose: () => void;
}

const CodatSyncSeparatedDialog = ({
  name,
  onSuccess,
  onClose,
  ...props
}: Props) => {
  const [trackingCategoriesStep, setTrackingCeategoriesStep] = useState(0);

  const renderInner = () => {
    if (name === SyncNameEnum.vatRates)
      return (
        <VatRatesStep
          isPartialFlow
          onPartialFlowSuccess={() => {
            onSuccess();
            onClose();
          }}
          onPartialFlowCancel={onClose}
        />
      );

    if (name === SyncNameEnum.suppliers)
      return (
        <SuppliersStep
          isPartialFlow
          onPartialFlowSuccess={() => {
            onSuccess();
            onClose();
          }}
          onPartialFlowCancel={onClose}
        />
      );

    if (name === SyncNameEnum.trackingCategories) {
      if (trackingCategoriesStep > 0)
        return (
          <CostUnitsStep
            isPartialFlow
            onPartialFlowSuccess={() => {
              onSuccess();
              onClose();
            }}
            onPartialFlowCancel={onClose}
          />
        );

      return (
        <CostCentersStep
          isPartialFlow
          onPartialFlowSuccess={() => {
            onSuccess();
            setTrackingCeategoriesStep(1);
          }}
          onPartialFlowSkip={() => setTrackingCeategoriesStep(1)}
          onPartialFlowCancel={onClose}
        />
      );
    }

    return (
      <GlAccountsStep
        isPartialFlow
        onPartialFlowSuccess={() => {
          onSuccess();
          onClose();
        }}
        onPartialFlowCancel={onClose}
      />
    );
  };

  return (
    <Dialog
      {...props}
      maxWidth="md"
      PaperProps={{
        sx: {
          height: 800,
        },
      }}
    >
      {renderInner()}
    </Dialog>
  );
};

export default withDialogWrapper(CodatSyncSeparatedDialog);
