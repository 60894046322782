import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { Router } from 'react-router-dom';
import AppErrorBoundary from 'components/App/AppErrorBoundary';
import AppErrorOverlay from 'components/AppErrorOverlay';
import InitialLoader from 'components/InitialLoader';
import { GlobalStateContext, GlobalStateProvider } from 'context/GlobalState';
import { SnackbarProvider } from 'elements/Snackbar';
import { history } from 'services/utils';
import { StylesWrapper } from './style';

/**
 * Wrapper component for both ExternalApp and InternalApp to keep common logic.
 */
export const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <GlobalStateProvider>
      <StylesWrapper>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          // localeText is required for correct placeholder format in DateRangePicker
          // https://mui.com/x/react-date-pickers/adapters-locale/#custom-field-placeholder
          localeText={{
            fieldMonthPlaceholder: (params) =>
              params.contentType === 'letter' ? 'MMM' : params.format,
          }}
        >
          <Router history={history}>
            <AppErrorBoundary>
              <SnackbarProvider>
                <GlobalStateContext.Consumer>
                  {(ctx) => (
                    <>
                      <InitialLoader loading={ctx!.state.isLoading} />
                      {ctx!.state.error && <AppErrorOverlay />}
                      {children}
                    </>
                  )}
                </GlobalStateContext.Consumer>
              </SnackbarProvider>
            </AppErrorBoundary>
          </Router>
        </LocalizationProvider>
      </StylesWrapper>
    </GlobalStateProvider>
  );
};
