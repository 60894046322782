import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Autocomplete, Box, Divider, UserIcon } from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
  Member,
  MemberDetails,
  MemberStatus,
  Team,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  inputValue: string;
  members: (Member | MemberDetails)[];
  isLoading: boolean;
}

interface Props {
  value: Member | MemberDetails | null;
  onChange: (member: Member | MemberDetails | null) => void;
  managerTeam?: Team;
  disabled?: boolean;
}

const MemberAutocomplete = ({
  value,
  onChange,
  managerTeam,
  disabled = false,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const canUser = useCanUser();
  const { isInternalApp } = useCurrentApp();
  const {
    state: { organization, member: currentMember },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    inputValue: '',
    members: [],
    isLoading: true,
  });

  const addCurrentMemberAsFirstItem = (members: Member[], query: string) => {
    const isCurrentMemberPresentInList = members.some(
      (item) => item.id === currentMember.id
    );

    if (query === '' || isCurrentMemberPresentInList) {
      return [
        currentMember,
        ...members.filter((item) => item.id !== currentMember.id),
      ];
    } else {
      return members;
    }
  };

  const getMembers = async () => {
    try {
      const query = state.inputValue.trim();
      const { members } = await api.getMembers({
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
        sort: '+firstName',
        q: query,
        status: [MemberStatus.invited, MemberStatus.active].join(),
        organizationId: organization!.id,
        teamIds: managerTeam?.id,
        teamEntitiesOnly: !!managerTeam,
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        members: isInternalApp
          ? members
          : addCurrentMemberAsFirstItem(members, query).filter((member) =>
              canUser('card:create-for-member', member, managerTeam)
            ),
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (disabled) return;

    let active = true;

    setState((prevState) => ({ ...prevState, isLoading: true }));

    setTimeout(() => {
      if (active) getMembers();
    }, DEFAULT_AUTOCOMPLETE_DELAY);

    return () => {
      active = false;
    };
  }, [state.inputValue.trim()]);

  return (
    <Autocomplete<Member | MemberDetails, false, false, false>
      inputValue={state.inputValue}
      onInputChange={(e, inputValue) =>
        setState((prevState) => ({ ...prevState, inputValue }))
      }
      value={value}
      onChange={(e, value) => onChange(value)}
      options={state.members}
      loading={state.isLoading}
      disabled={disabled}
      renderOption={(props, option) => (
        <Fragment key={option.id}>
          <li {...props}>
            <UserIcon fontSize="small" />
            <Box ml={2}>
              {option.id === currentMember.id
                ? t(
                    'issueCardDialog_v2.memberAndTypeStep.currentMemberIndicator',
                    { fullName: `${option.firstName} ${option.lastName}` }
                  )
                : `${option.firstName} ${option.lastName}`}
            </Box>
          </li>
          {option.id === currentMember.id && state.members.length > 1 && (
            <Divider component="li" sx={{ my: 1 }} />
          )}
        </Fragment>
      )}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      label={t('issueCardDialog_v2.memberAndTypeStep.chooseCardholder')}
      placeholder={t('issueCardDialog_v2.memberAndTypeStep.selectMember')}
      noOptionsText={t('common.nothingFound')}
      loadingText={t('common.loading')}
    />
  );
};

export default MemberAutocomplete;
