import React, { ReactNode, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Grid,
  LabeledValue,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  OrganizationAccountType,
  OrganizationStatus,
  PaymentFrequency,
  PaymentFrequencyType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import ChangeCurrPaymentFrequencyDialog from './ChangeCurrPaymentFrequencyDialog';
import ChangeMinPaymentFrequencyDialog from './ChangeMinPaymentFrequencyDialog';

const CutoffDay = ({ children }: { children: ReactNode }) => (
  <Typography variant="caption" color="text.secondary">
    {children}
  </Typography>
);

interface State {
  isLoading: boolean;
  paymentFrequency: PaymentFrequency | null;
  isChangeMinPmtFrequencyDialogOpen: boolean;
  isChangeCurPmtFrequencyDialogOpen: boolean;
}

const PaymentFrequencySection = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, defaultCardAccount },
  } = useGlobalState();

  const [state, setState] = React.useState<State>({
    isLoading: true,
    paymentFrequency: null,
    isChangeMinPmtFrequencyDialogOpen: false,
    isChangeCurPmtFrequencyDialogOpen: false,
  });

  const getData = React.useCallback(async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const paymentFrequency = await api.getPaymentFrequency(organization!.id);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        paymentFrequency,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const renderCutoffDay = () => {
    if (!state.paymentFrequency) return null;
    const { value, cutoffDay } = state.paymentFrequency.current;
    if (value === PaymentFrequencyType.DAILY || cutoffDay === null) return null;
    if (value === PaymentFrequencyType.MONTHLY) {
      if (!cutoffDay)
        return (
          <CutoffDay>({t('int.paymentFrequencySection.endOfMonth')})</CutoffDay>
        );
      return (
        <CutoffDay>
          ({t('int.paymentFrequencySection.onDay', { cutoffDay })})
        </CutoffDay>
      );
    }
    if (value === PaymentFrequencyType.SEMI_MONTHLY) {
      if (cutoffDay < 15)
        return (
          <CutoffDay>
            (
            {t('int.paymentFrequencySection.onDays', {
              first: cutoffDay,
              second: cutoffDay + 15,
            })}
            )
          </CutoffDay>
        );
      return (
        <CutoffDay>
          (
          {t('int.paymentFrequencySection.onDays', {
            first: cutoffDay,
            second: t('int.paymentFrequencySection.endOfMonth'),
          })}
          )
        </CutoffDay>
      );
    }
    return (
      <CutoffDay>({moment().isoWeekday(cutoffDay).format('ddd')})</CutoffDay>
    );
  };

  return (
    <>
      <Box position="relative" minHeight={80}>
        {state.isLoading ? (
          <LoaderWithOverlay loading size="small" />
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <LabeledValue
                label={t('int.paymentFrequencySection.minPaymentFrequency')}
                value={
                  state.paymentFrequency?.min
                    ? t(
                        `paymentFrequencyTypes.${state.paymentFrequency.min.value}`
                      )
                    : '-'
                }
                onEdit={
                  organization!.status !== OrganizationStatus.nonCustomer &&
                  canUser('min-payment-frequency:change') &&
                  defaultCardAccount!.accountType.value !==
                    OrganizationAccountType.prefunded
                    ? () =>
                        setState((prevState) => ({
                          ...prevState,
                          isChangeMinPmtFrequencyDialogOpen: true,
                        }))
                    : undefined
                }
              />
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                mt={1}
              >
                {state.paymentFrequency?.min
                  ? t('int.common.lastEditedBy', {
                      name: state.paymentFrequency.min.userName,
                      time: moment(
                        state.paymentFrequency.min.assignedAt
                      ).format('DD MMM YYYY, HH:mm'),
                    })
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <LabeledValue
                label={t('int.paymentFrequencySection.currPaymentFrequency')}
                value={
                  <>
                    {state.paymentFrequency?.current
                      ? t(
                          `paymentFrequencyTypes.${state.paymentFrequency.current.value}`
                        )
                      : '-'}{' '}
                    {renderCutoffDay()}
                  </>
                }
                onEdit={
                  organization!.status !== OrganizationStatus.nonCustomer &&
                  canUser('current-payment-frequency:change') &&
                  defaultCardAccount!.accountType.value !==
                    OrganizationAccountType.prefunded
                    ? () =>
                        setState((prevState) => ({
                          ...prevState,
                          isChangeCurPmtFrequencyDialogOpen: true,
                        }))
                    : undefined
                }
              />
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                mt={1}
              >
                {state.paymentFrequency?.current
                  ? t('int.common.lastEditedBy', {
                      name: state.paymentFrequency.current.userName,
                      time: moment(
                        state.paymentFrequency.current.assignedAt
                      ).format('DD MMM YYYY, HH:mm'),
                    })
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Box>

      <ChangeMinPaymentFrequencyDialog
        open={state.isChangeMinPmtFrequencyDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isChangeMinPmtFrequencyDialogOpen: false,
          }))
        }
        onSuccess={(paymentFrequency) =>
          setState((prevState) => ({
            ...prevState,
            paymentFrequency,
            isChangeMinPmtFrequencyDialogOpen: false,
          }))
        }
        paymentFrequency={state.paymentFrequency!}
      />
      <ChangeCurrPaymentFrequencyDialog
        open={state.isChangeCurPmtFrequencyDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isChangeCurPmtFrequencyDialogOpen: false,
          }))
        }
        onSuccess={(paymentFrequency) =>
          setState((prevState) => ({
            ...prevState,
            paymentFrequency,
            isChangeCurPmtFrequencyDialogOpen: false,
          }))
        }
        paymentFrequency={state.paymentFrequency!}
      />
    </>
  );
};

export default PaymentFrequencySection;
