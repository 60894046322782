import { styled } from '@mui/material';
import logoAdobe from 'assets/images/logo-adobe.png';
import logoApple from 'assets/images/logo-apple.png';
import logoAtlassian from 'assets/images/logo-atlassian.png';
import logoAzure from 'assets/images/logo-azure.png';
import logoDell from 'assets/images/logo-dell.png';
import logoFacebook from 'assets/images/logo-facebook.png';
import logoGoogle from 'assets/images/logo-google.png';
import logoGooglecloud from 'assets/images/logo-googlecloud.png';
import logoHeroku from 'assets/images/logo-heroku.png';
import logoHubspot from 'assets/images/logo-hubspot.png';
import logoIbmcloud from 'assets/images/logo-ibmcloud.png';
import logoIkea from 'assets/images/logo-ikea.png';
import logoLinkedin from 'assets/images/logo-linkedin.png';
import logoStaples from 'assets/images/logo-staples.png';
import logoTwitter from 'assets/images/logo-twitter.png';
import logoZenkesk from 'assets/images/logo-zenkesk.png';

export const TileList = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

export const CompanyTile = styled('div')<{ logo: string }>`
  width: 166px;
  height: 128px;
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  background: url('${({ logo }) =>
    (logo === 'twitter' && logoTwitter) ||
    (logo === 'linkedin' && logoLinkedin) ||
    (logo === 'dell' && logoDell) ||
    (logo === 'staples' && logoStaples) ||
    (logo === 'ibmcloud' && logoIbmcloud) ||
    (logo === 'azure' && logoAzure) ||
    (logo === 'facebook' && logoFacebook) ||
    (logo === 'google' && logoGoogle) ||
    (logo === 'googlecloud' && logoGooglecloud) ||
    (logo === 'heroku' && logoHeroku) ||
    (logo === 'adobe' && logoAdobe) ||
    (logo === 'atlassian' && logoAtlassian) ||
    (logo === 'zenkesk' && logoZenkesk) ||
    (logo === 'hubspot' && logoHubspot) ||
    (logo === 'apple' && logoApple) ||
    (logo === 'ikea' && logoIkea)}') center no-repeat;
  background-size: ${({ logo }) =>
    (logo === 'twitter' && '48px') ||
    (logo === 'linkedin' && '93px') ||
    (logo === 'dell' && '116px') ||
    (logo === 'staples' && '134px') ||
    (logo === 'ibmcloud' && '116px') ||
    (logo === 'azure' && '120px') ||
    (logo === 'facebook' && '40px') ||
    (logo === 'google' && '116px') ||
    (logo === 'googlecloud' && '159px') ||
    (logo === 'heroku' && '128px') ||
    (logo === 'adobe' && '104px') ||
    (logo === 'atlassian' && '120px') ||
    (logo === 'zenkesk' && '71px') ||
    (logo === 'hubspot' && '139px') ||
    (logo === 'apple' && '39px') ||
    (logo === 'ikea' && '95px') ||
    'contain'};
`;

export const TextTile = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 166,
  height: 128,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  ...theme.typography.body2,
}));
