import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  LoaderWithOverlay,
  Switch,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { AccountingItemStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, trimObjValues } from 'services/utils';

interface FormikValues {
  name: string;
  value: string;
  isDefault: boolean;
}

interface Props extends DialogProps {
  transactionCustomFieldId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const AddDropdownOptionDialog = ({
  transactionCustomFieldId,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();

  const formik = useFormik<FormikValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: '',
      value: '',
      isDefault: false,
    },
    onSubmit: async (values) => {
      try {
        await api.addCustomFieldOption({
          organizationId: organization!.id,
          transactionCustomFieldId,
          status: AccountingItemStatus.active,
          ...trimObjValues(values),
        });
        onSuccess();
        if (!mounted.current) return;
        enqueueSnackbar(t('addCustomFieldDropdownOptionDialog.successMsg'));
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('addCustomFieldDropdownOptionDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="add-dropdown-option-form"
          noValidate
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label={t('addCustomFieldDropdownOptionDialog.nameLabel')}
                {...formik.getFieldProps('name')}
                disabled={formik.isSubmitting}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={t('addCustomFieldDropdownOptionDialog.valueLabel')}
                {...formik.getFieldProps('value')}
                disabled={formik.isSubmitting}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                name="isDefault"
                checked={formik.values.isDefault}
                disabled={formik.isSubmitting}
                onChange={(_, checked) =>
                  formik.setFieldValue('isDefault', checked)
                }
                control={<Switch />}
                label={t('addCustomFieldDropdownOptionDialog.setDefaultLabel')}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.close')}
        </Button>
        <Button
          disabled={formik.isSubmitting || !formik.values.name.trim().length}
          type="submit"
          form="add-dropdown-option-form"
        >
          {t('common.button.create')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(AddDropdownOptionDialog);
