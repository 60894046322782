import { useEffect, useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { IntegrationTypeIcon } from 'domains/partner/components';
import { Link, LockIcon, Tooltip } from 'elements';
import { env } from 'env';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  IntegrationType,
  Transaction,
  TransactionExportStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

const CIRCULA_SUBMISSIONS_LINK = 'https://app.circula.com/submissions/open';

const CirculaStatus = ({
  transactionId,
  exportStatus,
  exportDate,
}: Pick<Transaction, 'transactionId' | 'exportStatus' | 'exportDate'>) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const canUser = useCanUser();
  const [isSynced, setIsSynced] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const resyncAllowed =
    canUser('tx-integration-sync:change') &&
    env.REACT_APP_BUILD_TARGET === 'internal';

  useEffect(() => {
    if (resyncAllowed) {
      setIsSynced(false);
      setIsLoading(false);
    }
  }, [transactionId]);

  const resyncCircula = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const memorizedTxId = transactionId;

    try {
      await api.resyncCirculaIntegration({ transactionIds: [memorizedTxId] });
      if (!mounted.current || transactionId !== memorizedTxId) return;
      setIsSynced(true);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current || transactionId !== memorizedTxId) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsLoading(false);
      logError(error);
    }
  };

  if (exportStatus === TransactionExportStatus.exported) {
    const formattedExportDate = moment(exportDate).format('D MMM YYYY');
    return (
      <>
        <IntegrationTypeIcon
          integrationType={IntegrationType.circula}
          sx={{ mr: 1 }}
        />
        <Trans
          i18nKey="transactionIntegrationStatus.submittedInCirula"
          components={{
            lockIcon: (
              <LockIcon color="action" fontSize="small" sx={{ ml: 1 }} />
            ),
            span: <></>,
          }}
          values={{ exportDate: formattedExportDate }}
        />
      </>
    );
  }
  if (exportStatus === TransactionExportStatus.notExported) {
    return (
      <>
        <IntegrationTypeIcon
          integrationType={IntegrationType.circula}
          sx={{ opacity: 0.5, mr: 1 }}
        />
        <span>
          <Trans
            i18nKey="transactionIntegrationStatus.syncedWithCircula"
            components={{
              a: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href={CIRCULA_SUBMISSIONS_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />

          {resyncAllowed && (
            <>
              <span> </span>
              {isSynced ? (
                t('int.transactionIntegrationStatus.resyncWithCirculaSuccess')
              ) : (
                <Tooltip
                  title={t(
                    'int.transactionIntegrationStatus.resyncWithCirculaTooltip'
                  )}
                >
                  <Link component="button" onClick={resyncCircula}>
                    {t('int.transactionIntegrationStatus.resyncWithCircula')}
                  </Link>
                </Tooltip>
              )}
            </>
          )}
        </span>
      </>
    );
  }
  return null;
};

export default CirculaStatus;
