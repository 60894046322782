import { useMemo } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, validateEmail } from 'services/utils';

type BillingEmailAddressChangeDialogForm = {
  billingEmail: string;
};

interface Props extends DialogProps {
  onClose: () => void;
}

const BillingEmailAddressChangeDialog = (props: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const org = useMemo(() => organization!, []);

  const formik = useFormik<BillingEmailAddressChangeDialogForm>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { billingEmail: org.billingEmail || '' },
    onSubmit: async ({ billingEmail }, { setSubmitting }) => {
      try {
        const data = await api.updateBillingEmailAddress(org.id, {
          billingEmail: billingEmail.trim(),
        });
        if (!mounted.current) return;
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { organization: data },
        });
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const { invalidEmailError, emailTooLongError } = validateEmail(
    formik.values.billingEmail
  );

  const trimmedBillingEmail = formik.values.billingEmail.trim();

  const isSubmitDisabled =
    trimmedBillingEmail === formik.initialValues.billingEmail ||
    !!invalidEmailError ||
    !!emailTooLongError ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('settingsOrganizationPage.billingEmailAddress')}
      </DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="email-change-form" noValidate>
          <TextField
            label={t('settingsOrganizationPage.billingEmailAddress')}
            {...formik.getFieldProps('billingEmail')}
            disabled={formik.isSubmitting}
            autoFocus
            error={!!invalidEmailError || !!emailTooLongError}
            helperText={invalidEmailError || emailTooLongError}
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          disabled={formik.isSubmitting}
          onClick={props.onClose}
        >
          {t('common.button.cancel')}
        </Button>
        <Button
          form="email-change-form"
          type="submit"
          disabled={isSubmitDisabled}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(BillingEmailAddressChangeDialog);
