import { useTranslation } from 'react-i18next';
import { StatusBadge, StatusBadgeProps } from 'elements';
import { CompensationBatchStatus } from 'services/constants';

const colorsByStatuses: {
  [key in CompensationBatchStatus]: StatusBadgeProps['color'];
} = {
  [CompensationBatchStatus.ongoing]: 'info',
  [CompensationBatchStatus.waiting]: 'default',
  [CompensationBatchStatus.paid]: 'success',
};

interface Props {
  status: CompensationBatchStatus;
}

const CompensationBatchStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();

  return (
    <StatusBadge
      variant="tinted"
      color={colorsByStatuses[status]}
      label={t(`compensationBatchStatuses.${status}`)}
    />
  );
};

export default CompensationBatchStatusBadge;
