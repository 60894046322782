import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { SupportedCountry, SupportedWebAppLanguage } from 'services/constants';

const ENGLISH = {
  code: SupportedWebAppLanguage.en,
  label: 'English',
};
const FINNISH = {
  code: SupportedWebAppLanguage.fi,
  label: 'Suomi',
};
const FRENCH = {
  code: SupportedWebAppLanguage.fr,
  label: 'Français',
};
const GERMAN = {
  code: SupportedWebAppLanguage.de,
  label: 'Deutsch',
};
const ITALIAN = {
  code: SupportedWebAppLanguage.it,
  label: 'Italiano',
};
const PORTUGUESE = {
  code: SupportedWebAppLanguage.pt,
  label: 'Português',
};
const SPANISH = {
  code: SupportedWebAppLanguage.es,
  label: 'Español',
};
const DUTCH = {
  code: SupportedWebAppLanguage.nl,
  label: 'Nederlands',
};

export const ALL_LANGUAGES = [
  GERMAN,
  ENGLISH,
  SPANISH,
  FRENCH,
  ITALIAN,
  DUTCH,
  PORTUGUESE,
  FINNISH,
];

/**
 * Returns the default language based on the organization billing country.
 */
export const useOrgDefaultLanguage = () => {
  const {
    state: { organization },
  } = useGlobalState();
  return useMemo(() => {
    switch (organization?.billingAddress.country) {
      case SupportedCountry.at:
      case SupportedCountry.de:
        return GERMAN;
      case SupportedCountry.es:
        return SPANISH;
      case SupportedCountry.fi:
        return FINNISH;
      case SupportedCountry.fr:
        return FRENCH;
      case SupportedCountry.it:
        return ITALIAN;
      case SupportedCountry.lu:
        return FRENCH;
      case SupportedCountry.nl:
        return DUTCH;
      case SupportedCountry.pt:
        return PORTUGUESE;
      default:
        return ENGLISH;
    }
  }, [organization?.billingAddress.country]);
};
