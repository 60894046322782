import React from 'react';
import { Trans } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import styled from 'styled-components';
import cozeroImage from 'assets/images/cozero.jpg';
import { useGlobalState } from 'context/GlobalState';
import { getDocumentUrl } from 'domains/document/utils';
import { getPath } from 'services/utils';

const Container = styled.div`
  align-items: center;
  display: flex;
  margin-top: 120px;
  max-width: 878px;
  width: 100%;
`;

const Image = styled.img`
  width: 270px;
  border-radius: 7px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
  margin-left: 34px;
  width: 100%;
`;

const CozeroBanner = () => {
  const {
    state: { organization },
  } = useGlobalState();

  return (
    <Container>
      <Image src={cozeroImage} />
      <Text>
        <Trans
          i18nKey="pliantEarthPage.cozerobanner.text"
          components={{
            'our-methodology-link': (
              // eslint-disable-next-line
              <a
                href={getDocumentUrl('ESG_COZERO_WHITE_PAPER.pdf')}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
            'cozero-link': (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href="https://cozero.io/"
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
            'cozero-deal-link': (
              <Link
                to={`${generatePath(getPath('rewardsPartnerDeals'), {
                  orgId: organization!.id,
                })}?deal=cozero`}
              />
            ),
          }}
        />
      </Text>
    </Container>
  );
};

export default CozeroBanner;
