import React, { Fragment, useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, NavLink, useHistory } from 'react-router-dom';
import AppLogo from 'components/AppLogo';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  CaretDownIcon,
  CaretLeftIcon,
  CaretRightIcon,
  CaretUpIcon,
  Collapse,
  GearSixIcon,
  ListItemIcon,
  ListItemText,
  RocketLaunchIcon,
  Typography,
} from 'elements';
import useHeaderBorderOnScroll from 'hooks/useBorderOnScroll';
import { useCanUser } from 'services/rbac';
import OrganizationSection from './OrganizationSection';
import {
  Container,
  ContainerWrapper,
  Content,
  Header,
  StyledDrawer,
  StyledIconButton,
  StyledListItemButton,
  StyledSubListItemButton,
} from './style';
import useInternalTabs from './useInternalTabs';
import useSettingsTabs from './useSettingsTabs';
import useTabs, { Tab } from './useTabs';

interface SidebarProps {
  paths: {
    [pathId: string]: string | undefined;
  };
  showOnboarding: boolean;
}

const Sidebar = ({ paths, showOnboarding }: SidebarProps) => {
  const { state, dispatch } = useGlobalState();
  const { t } = useTranslation();
  const history = useHistory();
  const canUser = useCanUser();
  const { getHeaderStyle, onContentScroll } = useHeaderBorderOnScroll();
  const theme = useTheme();
  const tabs = useTabs();
  const settingsTabs = useSettingsTabs();
  const internalTabs = useInternalTabs();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!state.isSidebarOpen) setIsHovered(false);
  }, [state.isSidebarOpen]);

  const renderTabWithChildren = (tab: Tab) => {
    const isOpen =
      tab.items?.some(
        (key) =>
          !!matchPath(history.location.pathname, {
            path: paths[key.pathId],
          })
      ) || false;

    return (
      <Fragment key={tab.pathId}>
        <StyledListItemButton
          component={NavLink}
          to={paths[tab.pathId] || '/'}
          disabled={!paths[tab.pathId]}
          data-test-id={tab.testId}
          data-intercom-target={tab.intercomTarget}
          activeClassName=""
        >
          <ListItemIcon>
            <tab.Icon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={tab.label}
            primaryTypographyProps={{ noWrap: true }}
          />
          {isOpen ? (
            <CaretUpIcon fontSize="small" color="inherit" />
          ) : (
            <CaretDownIcon fontSize="small" color="inherit" />
          )}
        </StyledListItemButton>
        <Collapse in={isOpen} unmountOnExit>
          {tab.items!.map((tab) => (
            <StyledSubListItemButton
              key={tab.pathId}
              component={NavLink}
              exact={!paths[tab.pathId]}
              disabled={!paths[tab.pathId]}
              to={paths[tab.pathId] || '/'}
              data-test-id={tab.testId}
              data-intercom-target={tab.intercomTarget}
              isActive={tab.isActive}
            >
              <ListItemText
                primary={tab.label}
                primaryTypographyProps={{ noWrap: true }}
              />
              {tab.children}
            </StyledSubListItemButton>
          ))}
        </Collapse>
      </Fragment>
    );
  };

  const renderTab = (tab: Tab) => (
    <StyledListItemButton
      key={tab.pathId}
      component={NavLink}
      exact={!paths[tab.pathId]}
      to={paths[tab.pathId] || '/'}
      isActive={tab.isActive}
      disabled={!paths[tab.pathId]}
      data-test-id={tab.testId}
      data-intercom-target={tab.intercomTarget}
    >
      <ListItemIcon>
        <tab.Icon fontSize="small" />
      </ListItemIcon>
      <ListItemText
        primary={tab.label}
        primaryTypographyProps={{ noWrap: true }}
      />
      {tab.children}
    </StyledListItemButton>
  );

  const renderSettingsTab = () => {
    const isOpen = settingsTabs.some(
      (tab) =>
        !!matchPath(history.location.pathname, {
          path: paths[tab.pathId],
        })
    );

    return (
      canUser('settings-page:visit') && (
        <>
          <StyledListItemButton
            component={Link}
            to={
              paths['settingsOrganization'] ||
              paths['settingsIntegrations'] ||
              '/'
            }
            disabled={!paths['settings']}
            data-test-id="settings-tab"
            data-intercom-target="sidebar-settings-button"
          >
            <ListItemIcon>
              <GearSixIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('navigation.settings.title')} />
            {isOpen ? (
              <CaretUpIcon fontSize="small" color="inherit" />
            ) : (
              <CaretDownIcon fontSize="small" color="inherit" />
            )}
          </StyledListItemButton>
          <Collapse in={isOpen} unmountOnExit>
            {settingsTabs.map((tab) => (
              <StyledSubListItemButton
                key={tab.pathId}
                component={NavLink}
                exact={!paths[tab.pathId]}
                disabled={!paths['settings']}
                to={paths[tab.pathId] || '/'}
                data-test-id={tab.testId}
                data-intercom-target={tab.intercomTarget}
              >
                <ListItemText primary={tab.label} />
              </StyledSubListItemButton>
            ))}
          </Collapse>
        </>
      )
    );
  };

  return (
    <StyledDrawer
      variant="permanent"
      onMouseEnter={(e) => {
        if (
          e.target instanceof Element &&
          !e.target.getAttribute('data-sidebar-toggle')
        ) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => setIsHovered(false)}
      className={clsx({
        'drawer-open': state.isSidebarOpen,
        'drawer-hovered': isHovered,
      })}
    >
      <StyledIconButton
        size="small"
        onClick={() => {
          dispatch({ type: 'TOGGLE_SIDEBAR', payload: !state.isSidebarOpen });
        }}
        data-sidebar-toggle="true"
      >
        {state.isSidebarOpen ? (
          <CaretLeftIcon fontSize="small" />
        ) : (
          <CaretRightIcon fontSize="small" />
        )}
      </StyledIconButton>

      <ContainerWrapper>
        <Container>
          <Header style={getHeaderStyle()}>
            <Box my={4} mx={2} lineHeight={1}>
              <Box component={Link} to="/" sx={{ textDecoration: 'none' }}>
                <AppLogo mode={theme.navigationMode} />
              </Box>
            </Box>
            <OrganizationSection />
          </Header>
          <Content onScroll={onContentScroll}>
            {showOnboarding &&
              renderTab({
                label: t('navigation.onboarding'),
                pathId: 'onboarding',
                testId: 'onboarding-tab',
                Icon: RocketLaunchIcon,
              })}
            {tabs.map((tab) => {
              if (tab.items?.length) return renderTabWithChildren(tab);
              return renderTab(tab);
            })}

            {renderSettingsTab()}

            {internalTabs.length > 0 && (
              <>
                <Typography
                  variant="overline"
                  component="div"
                  color="inherit"
                  mt={3}
                  mb={1}
                  ml={2}
                >
                  {t('int.sidebar.internal')}
                </Typography>

                {internalTabs.map((tab) => {
                  if (tab.items?.length) return renderTabWithChildren(tab);
                  return renderTab(tab);
                })}
              </>
            )}
          </Content>
        </Container>
      </ContainerWrapper>
    </StyledDrawer>
  );
};

export default Sidebar;
