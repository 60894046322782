import { i18n, TFunction } from 'i18next';
import { Trans } from 'react-i18next';
/** Place Avatar svg/png file into "images/integrationAvatars" folder, when it should be
 *  different from what we have in integration dialog.
 *  Otherwise, use the logo from "svg/integrations" folder in svg format (preferably) for Avatars.
 */
import AgicapAvatar from 'assets/images/integrationAvatars/agicap.png';
import BasAvatar from 'assets/images/integrationAvatars/bas.png';
import BBAvatar from 'assets/images/integrationAvatars/bb.png';
import BezalaAvatar from 'assets/images/integrationAvatars/bezala.png';
import CandisAvatar from 'assets/images/integrationAvatars/candis.png';
import CirculaAvatar from 'assets/images/integrationAvatars/circula.png';
import CommitlyAvatar from 'assets/images/integrationAvatars/commitly.png';
import ConfermaAvatar from 'assets/images/integrationAvatars/conferma.png';
import DatevAvatar from 'assets/images/integrationAvatars/datev.png';
import DomondaAvatar from 'assets/images/integrationAvatars/domonda.png';
import FlowwerAvatar from 'assets/images/integrationAvatars/flowwer.png';
import FreeFinanceAvatar from 'assets/images/integrationAvatars/freeFinance.png';
import GetMyInvoicesAvatar from 'assets/images/integrationAvatars/gmi.png';
import HelloHQAvatar from 'assets/images/integrationAvatars/helloHQ.png';
import KanzleilandAvatar from 'assets/images/integrationAvatars/kanzleiland.png';
import KlippaAvatar from 'assets/images/integrationAvatars/klippa.png';
import LexOfficeAvatar from 'assets/images/integrationAvatars/lexoffice.png';
import MobilexpenseAvatar from 'assets/images/integrationAvatars/mobilexpense.png';
import NetvisorAvatar from 'assets/images/integrationAvatars/netvisor.png';
import OracleNetsuiteAvatar from 'assets/images/integrationAvatars/oracleNetsuite.png';
import PliantAvatar from 'assets/images/integrationAvatars/pliant.png';
import QuickbooksAvatar from 'assets/images/integrationAvatars/quickbooks.png';
import RydooAvatar from 'assets/images/integrationAvatars/rydoo.png';
import TidelyAvatar from 'assets/images/integrationAvatars/tidely.png';
import { ReactComponent as AgicapLogo } from 'assets/svg/integrations/agicapLogo.svg';
import { ReactComponent as BezalaLogo } from 'assets/svg/integrations/bezalaLogo.svg';
import BookmanAvatar, {
  ReactComponent as BookmanLogo,
} from 'assets/svg/integrations/bookmanLogo.svg';
import { ReactComponent as BuchhaltungsButlerLogo } from 'assets/svg/integrations/buchhaltungsbutlerLogo.svg';
import { ReactComponent as CandisLogo } from 'assets/svg/integrations/candisLogo.svg';
import { ReactComponent as CirculaLogo } from 'assets/svg/integrations/circulaLogo.svg';
import { ReactComponent as CommitlyLogo } from 'assets/svg/integrations/commitlyLogo.svg';
import { ReactComponent as ConfermaLogo } from 'assets/svg/integrations/confermaLogo.svg';
import { ReactComponent as DatevLogo } from 'assets/svg/integrations/datevServiceLogo.svg';
import { ReactComponent as DomondaLogo } from 'assets/svg/integrations/domondaLogo.svg';
import { ReactComponent as FlowwerLogo } from 'assets/svg/integrations/flowwerLogo.svg';
import { ReactComponent as FreeFinanceLogo } from 'assets/svg/integrations/freeFinanceLogo.svg';
import { ReactComponent as GetMyInvoicesLogo } from 'assets/svg/integrations/getMyInvoicesLogo.svg';
import { ReactComponent as HelloHQLogo } from 'assets/svg/integrations/helloHQLogo.svg';
import { ReactComponent as KanzleilandLogo } from 'assets/svg/integrations/kanzleilandLogo.svg';
import { ReactComponent as KlippaLogo } from 'assets/svg/integrations/klippaLogo.svg';
import { ReactComponent as LexOfficeLogo } from 'assets/svg/integrations/lexOfficeLogo.svg';
import MicrosoftDynamics365Avatar, {
  ReactComponent as MicrosoftDynamics365Logo,
} from 'assets/svg/integrations/microsoftDynamics365Logo.svg';
import { ReactComponent as MobilexpenseLogo } from 'assets/svg/integrations/mobilexpenseLogo.svg';
import MocoappAvatar, {
  ReactComponent as MocoappLogo,
} from 'assets/svg/integrations/mocoappLogo.svg';
import { ReactComponent as NetvisorLogo } from 'assets/svg/integrations/netvisorLogo.svg';
import { ReactComponent as OracleNetsuiteLogo } from 'assets/svg/integrations/oracleNetsuiteLogo.svg';
import ProcountorAvatar, {
  ReactComponent as ProcountorLogo,
} from 'assets/svg/integrations/procountorLogo.svg';
import { ReactComponent as QuickbooksLogo } from 'assets/svg/integrations/quickbooksLogo.svg';
import { ReactComponent as RydooLogo } from 'assets/svg/integrations/rydooLogo.svg';
import ScopevisioAvatar, {
  ReactComponent as ScopevisioLogo,
} from 'assets/svg/integrations/scopevisioLogo.svg';
import { ReactComponent as TidelyLogo } from 'assets/svg/integrations/tidelyLogo.svg';
import VoxelAvatar, {
  ReactComponent as VoxelLogo,
} from 'assets/svg/integrations/voxelLogo.svg';
import XeroAvatar, {
  ReactComponent as XeroLogo,
} from 'assets/svg/integrations/xeroLogo.svg';
import { ReactComponent as PliantLogo } from 'assets/svg/pliantLogo.svg';
import { HelpCenterUrlsType } from 'hooks/useUrls';
import {
  IntegrationName,
  IntegrationSection,
  PartnerIds,
  SUPPORT_EMAIL,
} from 'services/constants';

const circulaHelpcenterLink = {
  en: 'https://help.circula.com/en/articles/6339895-pliant-enable-integration',
  de:
    'https://help.circula.com/de/articles/6339895-pliant-integration-aktivieren',
};

export const getStaticIntegrationsData = (
  t: TFunction,
  i18n: i18n,
  articles: Partial<HelpCenterUrlsType>,
  useCustomText?: boolean
) => ({
  [PartnerIds.circula]: {
    title: t('integrationsPage.circula.title'),
    subtitle: t('integrationsPage.circula.subtitle'),
    description: [
      <Trans i18nKey="integrationsPage.circula.descriptionA" />,
      <Trans i18nKey="integrationsPage.circula.descriptionB" />,
      <Trans
        i18nKey="integrationsPage.circula.descriptionC"
        components={{
          supportLink: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a href={'mailto:' + SUPPORT_EMAIL} rel="noreferrer" />
          ),
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href={
                i18n.language === 'de'
                  ? circulaHelpcenterLink.de
                  : circulaHelpcenterLink.en
              }
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
    ],
    subDescription: (
      <Trans
        i18nKey="integrationsPage.circula.subDescription"
        components={{ b: <b />, div: <div /> }}
      />
    ),
    type: [t('integrationsPage.api')],
    logo: <CirculaLogo />,
    avatar: CirculaAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: true,
  },
  [IntegrationName.datev]: {
    title: t('integrationsPage.datev.title'),
    subtitle: t('integrationsPage.datev.subtitle'),
    description: [
      t('integrationsPage.datev.descriptionA'),
      t('integrationsPage.datev.descriptionB'),
    ],
    type: [t('integrationsPage.exportFileXml'), t('integrationsPage.api')],
    logo: <DatevLogo />,
    avatar: DatevAvatar,
    section: IntegrationSection.accountingIntegrations,
    isPremium: false,
  },
  [IntegrationName.tidely]: {
    title: t('integrationsPage.tidely.title'),
    subtitle: t('integrationsPage.tidely.title'),
    description: [
      <Trans
        i18nKey="integrationsPage.tidely.descriptionA"
        components={{
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://www.tidely.com/"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
      t('integrationsPage.tidely.descriptionB'),
    ],
    type: [t('integrationsPage.apiPsd2')],
    logo: <TidelyLogo />,
    avatar: TidelyAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [IntegrationName.buchhaltungsButler]: {
    title: t('integrationsPage.buchhaltungsbutler.title'),
    subtitle: t('integrationsPage.buchhaltungsbutler.subtitle'),
    description: [
      t('integrationsPage.buchhaltungsbutler.descriptionA'),
      t('integrationsPage.buchhaltungsbutler.descriptionB'),
      <Trans
        i18nKey="integrationsPage.buchhaltungsbutler.descriptionC"
        components={{
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://www.buchhaltungsbutler.de/wissensdatenbank/csv-import-bankbewegungen-buchungssaetze-und-debitoren-kreditorenkonten/"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
    ],
    type: [t('integrationsPage.exportFile'), t('integrationsPage.email')],
    logo: <BuchhaltungsButlerLogo />,
    avatar: BBAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [PartnerIds.candis]: {
    title: t('integrationsPage.candis.title'),
    subtitle: useCustomText
      ? t('partnerPage.candis.subtitle')
      : t('integrationsPage.candis.subtitle'),
    description: useCustomText
      ? [
          <Trans
            i18nKey="partnerPage.candis.descriptionA"
            components={{
              linkToCandis: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="https://my.candis.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />,
          <Trans
            i18nKey="partnerPage.candis.descriptionB"
            components={{
              ul: <ul />,
              li: <li />,
            }}
          />,
          <Trans
            i18nKey="partnerPage.candis.descriptionC"
            components={{
              candisHelp: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="https://hilfe.candis.io/de/articles/6122088-deine-kreditkarte-von-pliant-uber-candis-beantragen-und-managen"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              candisPhone: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a href="tel:+49 30 311 930 40" rel="noreferrer" />
              ),
              candisChat: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="https://my.candis.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />,
        ]
      : [
          t('integrationsPage.candis.descriptionA'),
          t('integrationsPage.candis.descriptionB'),
          <Trans
            i18nKey="integrationsPage.candis.descriptionC"
            components={{
              supportLink: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a href={'mailto:' + SUPPORT_EMAIL} rel="noreferrer" />
              ),
            }}
          />,
        ],
    type: [t('integrationsPage.api')],
    logo: <CandisLogo />,
    avatar: CandisAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: true,
  },
  [IntegrationName.getMyInvoices]: {
    title: t('integrationsPage.getMyInvoices.title'),
    subtitle: t('integrationsPage.getMyInvoices.subtitle'),
    description: [
      t('integrationsPage.getMyInvoices.descriptionA'),
      <Trans
        i18nKey="integrationsPage.getMyInvoices.descriptionB"
        components={{
          videoLink: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://screencast-o-matic.com/watch/c31roJV38Fv"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              // adding lang check just in case of other languages addition
              href={`https://help.getmyinvoices.com/${
                i18n.language === 'de' ? 'de' : 'en'
              }`}
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
    ],
    type: [t('integrationsPage.email')],
    logo: <GetMyInvoicesLogo />,
    avatar: GetMyInvoicesAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [IntegrationName.flowwer]: {
    title: t('integrationsPage.flowwer.title'),
    subtitle: t('integrationsPage.flowwer.subtitle'),
    description: [
      t('integrationsPage.flowwer.descriptionA'),
      t('integrationsPage.flowwer.descriptionB'),
      t('integrationsPage.flowwer.descriptionC'),
    ],
    type: [t('integrationsPage.email')],
    logo: <FlowwerLogo />,
    avatar: FlowwerAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [IntegrationName.kanzleiland]: {
    title: t('integrationsPage.kanzleiland.title'),
    subtitle: t('integrationsPage.kanzleiland.subtitle'),
    description: [
      <Trans
        i18nKey="integrationsPage.kanzleiland.descriptionA"
        components={{
          b: <b />,
        }}
      />,
      t('integrationsPage.kanzleiland.descriptionB'),
      t('integrationsPage.kanzleiland.descriptionC'),
    ],
    type: [t('integrationsPage.apiPsd2')],
    logo: <KanzleilandLogo />,
    avatar: KanzleilandAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [IntegrationName.bookman]: {
    title: t('integrationsPage.bookman.title'),
    subtitle: t('integrationsPage.bookman.subtitle'),
    description: [
      t('integrationsPage.bookman.descriptionA'),
      t('integrationsPage.bookman.descriptionB'),
      <Trans
        i18nKey="integrationsPage.bookman.descriptionC"
        components={{
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://hilfecenter.bookman.de/helpdoc/integrationen/pliant-und-bookman/"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
    ],
    type: [t('integrationsPage.email')],
    logo: <BookmanLogo />,
    avatar: BookmanAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [IntegrationName.hellohq]: {
    title: t('integrationsPage.hellohq.title'),
    subtitle: t('integrationsPage.hellohq.subtitle'),
    description: [
      t('integrationsPage.hellohq.descriptionA'),
      t('integrationsPage.hellohq.descriptionB'),
      t('integrationsPage.hellohq.descriptionC'),
    ],
    type: [t('integrationsPage.exportFile')],
    logo: <HelloHQLogo />,
    avatar: HelloHQAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [PartnerIds.mobilexpenseDeclaree]: {
    title: t('integrationsPage.mobilexpense.title'),
    subtitle: t('integrationsPage.mobilexpense.subtitle'),
    description: [
      t('integrationsPage.mobilexpense.descriptionA'),
      t('integrationsPage.mobilexpense.descriptionB'),
      t('integrationsPage.mobilexpense.descriptionC'),
    ],
    type: [t('integrationsPage.api')],
    logo: <MobilexpenseLogo />,
    avatar: MobilexpenseAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: true,
  },
  [PartnerIds.mobilexpenseMXP]: {
    title: t('integrationsPage.mobilexpenseMXP.title'),
    subtitle: t('integrationsPage.mobilexpenseMXP.subtitle'),
    description: [
      <Trans
        i18nKey="integrationsPage.mobilexpenseMXP.description"
        components={{
          br: <br />,
        }}
      />,
    ],
    type: [t('integrationsPage.api')],
    logo: <MobilexpenseLogo />,
    avatar: MobilexpenseAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: true,
  },
  [IntegrationName.domonda]: {
    title: t('integrationsPage.domonda.title'),
    subtitle: t('integrationsPage.domonda.subtitle'),
    description: [
      <Trans
        i18nKey="integrationsPage.domonda.descriptionA"
        components={{
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://domonda.com/"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
      t('integrationsPage.domonda.descriptionB'),
      <Trans
        i18nKey="integrationsPage.domonda.descriptionC"
        components={{
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://academy.domonda.com/academy/upload-email-import"
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
    ],
    type: [t('integrationsPage.email')],
    logo: <DomondaLogo />,
    avatar: DomondaAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [IntegrationName.rydoo]: {
    title: t('integrationsPage.rydoo.title'),
    subtitle: t('integrationsPage.rydoo.subtitle'),
    description: [
      t('integrationsPage.rydoo.descriptionA'),
      t('integrationsPage.rydoo.descriptionB'),
      t('integrationsPage.rydoo.descriptionC'),
      t('integrationsPage.rydoo.descriptionD'),
    ],
    type: [t('integrationsPage.email')],
    logo: <RydooLogo />,
    avatar: RydooAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [IntegrationName.commitly]: {
    title: t('integrationsPage.commitly.title'),
    subtitle: t('integrationsPage.commitly.subtitle'),
    description: [
      t('integrationsPage.commitly.descriptionA'),
      t('integrationsPage.commitly.descriptionB'),
    ],
    type: [t('integrationsPage.apiPsd2')],
    logo: <CommitlyLogo />,
    avatar: CommitlyAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [IntegrationName.freeFinance]: {
    title: t('integrationsPage.freeFinance.title'),
    subtitle: t('integrationsPage.freeFinance.subtitle'),
    description: [
      t('integrationsPage.freeFinance.descriptionA'),
      t('integrationsPage.freeFinance.descriptionB'),
    ],
    type: [t('integrationsPage.apiPsd2')],
    logo: <FreeFinanceLogo />,
    avatar: FreeFinanceAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [IntegrationName.agicap]: {
    title: t('integrationsPage.agicap.title'),
    subtitle: t('integrationsPage.agicap.subtitle'),
    description: [
      t('integrationsPage.agicap.descriptionA'),
      t('integrationsPage.agicap.descriptionB'),
      t('integrationsPage.agicap.descriptionC'),
    ],
    type: [t('integrationsPage.apiPsd2')],
    logo: <AgicapLogo />,
    avatar: AgicapAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [PartnerIds.mocoapp]: {
    title: t('integrationsPage.mocoapp.title'),
    subtitle: (
      <Trans
        i18nKey="integrationsPage.mocoapp.subtitle"
        components={{
          div: <div />,
        }}
      />
    ),
    description: [
      t('integrationsPage.mocoapp.descriptionA'),
      t('integrationsPage.mocoapp.descriptionB'),
    ],
    type: [t('integrationsPage.apiPsd2')],
    logo: <MocoappLogo />,
    avatar: MocoappAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [IntegrationName.lexOffice]: {
    title: t('integrationsPage.lexOffice.title'),
    subtitle: t('integrationsPage.lexOffice.subtitle'),
    description: [
      t('integrationsPage.lexOffice.descriptionA'),
      t('integrationsPage.lexOffice.descriptionB'),
      <Trans
        i18nKey="integrationsPage.lexOffice.descriptionC"
        components={{
          linkTo: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href={articles.HELP_CENTER_EXPORTING_TRANSACTIONS_LEXOFFICE_URL}
              rel="noopener noreferrer"
              target="_blank"
            />
          ),
        }}
      />,
    ],
    type: [t('integrationsPage.api')],
    logo: <LexOfficeLogo />,
    avatar: LexOfficeAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [PartnerIds.bezala]: {
    title: t('integrationsPage.bezala.title'),
    subtitle: t('integrationsPage.bezala.subtitle'),
    description: [
      t('integrationsPage.bezala.descriptionA'),
      t('integrationsPage.bezala.descriptionB'),
    ],
    type: [t('integrationsPage.api')],
    logo: <BezalaLogo />,
    avatar: BezalaAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [IntegrationName.customCSV]: {
    title: t('integrationsPage.customCSV.title'),
    subtitle: t('integrationsPage.customCSV.subtitle'),
    description: [
      t('integrationsPage.customCSV.descriptionA'),
      t('integrationsPage.customCSV.descriptionB'),
      t('integrationsPage.customCSV.descriptionC'),
    ],
    type: [t('integrationsPage.exportFile')],
    logo: <PliantLogo />,
    avatar: PliantAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: true,
  },
  [IntegrationName.netvisor]: {
    title: t('integrationsPage.netvisor.title'),
    subtitle: t('integrationsPage.netvisor.subtitle'),
    description: [t('integrationsPage.netvisor.descriptionA')],
    type: [t('integrationsPage.exportFile')],
    logo: <NetvisorLogo />,
    avatar: NetvisorAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [IntegrationName.procountor]: {
    title: t('integrationsPage.procountor.title'),
    subtitle: t('integrationsPage.procountor.subtitle'),
    description: [
      t('integrationsPage.procountor.descriptionA'),
      <Trans
        i18nKey="integrationsPage.procountor.descriptionB"
        components={{
          li: <li />,
          ul: <ul />,
        }}
      />,
    ],
    type: [t('integrationsPage.exportFile')],
    logo: <ProcountorLogo />,
    avatar: ProcountorAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [IntegrationName.xero]: {
    title: t('integrationsPage.xero.title'),
    subtitle: t('integrationsPage.xero.subtitle'),
    description: [
      t('integrationsPage.xero.descriptionA'),
      t('integrationsPage.xero.descriptionB'),
      t('integrationsPage.xero.descriptionC'),
    ],
    type: [t('integrationsPage.api')],
    logo: <XeroLogo />,
    avatar: XeroAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: true,
  },
  [IntegrationName.quickbooks]: {
    title: t('integrationsPage.quickbooks.title'),
    subtitle: t('integrationsPage.quickbooks.subtitle'),
    description: [
      t('integrationsPage.quickbooks.descriptionA'),
      t('integrationsPage.quickbooks.descriptionB'),
      t('integrationsPage.quickbooks.descriptionC'),
    ],
    type: [t('integrationsPage.api')],
    logo: <QuickbooksLogo />,
    avatar: QuickbooksAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: true,
  },
  [IntegrationName.oracleNetSuite]: {
    title: t('integrationsPage.oracleNetSuite.title'),
    subtitle: t('integrationsPage.oracleNetSuite.subtitle'),
    description: [
      t('integrationsPage.oracleNetSuite.descriptionA'),
      t('integrationsPage.oracleNetSuite.descriptionB'),
      t('integrationsPage.oracleNetSuite.descriptionC'),
    ],
    type: [t('integrationsPage.api')],
    logo: <OracleNetsuiteLogo />,
    avatar: OracleNetsuiteAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: true,
  },
  [IntegrationName.microsoftDynamics365BusinessCentral]: {
    title: t('integrationsPage.microsoftDynamics365BusinessCentral.title'),
    subtitle: t(
      'integrationsPage.microsoftDynamics365BusinessCentral.subtitle'
    ),
    description: [
      t('integrationsPage.microsoftDynamics365BusinessCentral.descriptionA'),
      t('integrationsPage.microsoftDynamics365BusinessCentral.descriptionB'),
    ],
    type: [t('integrationsPage.api')],
    logo: <MicrosoftDynamics365Logo />,
    avatar: MicrosoftDynamics365Avatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: true,
  },
  [PartnerIds.klippa]: {
    title: t('integrationsPage.klippa.title'),
    subtitle: t('integrationsPage.klippa.subtitle'),
    description: [t('integrationsPage.klippa.descriptionA')],
    type: [t('integrationsPage.api')],
    logo: <KlippaLogo />,
    avatar: KlippaAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: true,
  },
  [PartnerIds.voxel]: {
    title: t('integrationsPage.voxel.title'),
    subtitle: t('integrationsPage.voxel.subtitle'),
    description: [t('integrationsPage.voxel.description')],
    type: [t('integrationsPage.api')],
    logo: <VoxelLogo />,
    avatar: VoxelAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [PartnerIds.conferma]: {
    title: t('integrationsPage.conferma.title'),
    subtitle: t('integrationsPage.conferma.subtitle'),
    description: [t('integrationsPage.conferma.description')],
    type: [t('integrationsPage.api')],
    logo: <ConfermaLogo />,
    avatar: ConfermaAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [PartnerIds.bas]: {
    title: t('integrationsPage.bas.title'),
    subtitle: t('integrationsPage.bas.subtitle'),
    description: [t('integrationsPage.bas.description')],
    type: [t('integrationsPage.api')],
    // logo should be replaced once we have svg
    logo: <img alt="BAS" src={BasAvatar} height={45} />,
    avatar: BasAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
  [PartnerIds.scopevisio]: {
    title: t('integrationsPage.scopevisio.title'),
    subtitle: t('integrationsPage.scopevisio.subtitle'),
    description: [t('integrationsPage.scopevisio.description')],
    type: [t('integrationsPage.api')],
    logo: <ScopevisioLogo />,
    avatar: ScopevisioAvatar,
    section: IntegrationSection.financeIntegrations,
    isPremium: false,
  },
});
