import React, { ReactNode, useMemo, useState } from 'react';
import { uniq } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import CurrencyFlag from 'components/CurrencyFlag';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import {
  HistoryCashbackRedemptionsDialog,
  RedeemCashbackDialog,
} from 'domains/reward/dialogs';
import {
  ArrowLineDownIcon,
  ArrowRightIcon,
  Box,
  CalendarBlankIcon,
  CheckCircleIcon,
  FormControl,
  Grid,
  HourglassMediumIcon,
  IconButton,
  InfoIcon,
  ListItemIcon,
  MenuItem,
  Paper,
  PiggyBankIcon,
  Select,
  Tooltip,
  Typography,
} from 'elements';
import {
  CardAccountCurrency,
  Cashback,
  CURRENCY_NAME_BY_CODE,
  CurrencyCashbackByCurrencyMap,
  MonthlyCashbackProgressList,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import Footnote from './Footnote';
import HeaderWithProgressBar from './HeaderWithProgressBar';

const Tile = ({ children }: { children: ReactNode }) => (
  <Grid item xs={6}>
    <Paper
      sx={{
        display: 'flex',
        minHeight: 112,
        alignItems: 'center',
        py: 2,
        px: 3,
      }}
      variant="outlined"
    >
      {children}
    </Paper>
  </Grid>
);

interface Props {
  cashback: Cashback;
  cashbackByCurrency: CurrencyCashbackByCurrencyMap;
  monthlyCashbackProgress: MonthlyCashbackProgressList;
  onUpdate: () => void;
}

const CashbackDashboard = ({
  cashback,
  cashbackByCurrency,
  monthlyCashbackProgress,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { cardAccounts },
  } = useGlobalState();
  const currencies = useMemo(
    () =>
      uniq(cardAccounts.map((item) => item.currency.value)).filter(
        (item) => !!cashbackByCurrency.buckets[item]
      ),
    [cardAccounts, cashbackByCurrency]
  );
  const [selectedCurrency, setSelectedCurrency] = useState<CardAccountCurrency>(
    currencies[0]
  );
  const selectedCurrencyCashback = useMemo(
    () => cashbackByCurrency.buckets[selectedCurrency],
    [cashbackByCurrency, selectedCurrency]
  );
  const [
    isHistoryRedemptionsDialogOpen,
    setIsHistoryRedemptionsDialogOpen,
  ] = useState(false);
  const [isRedeemDialogOpen, setIsRedeemDialogOpen] = useState(false);

  return (
    <>
      <HeaderWithProgressBar
        cashback={cashback}
        monthlyCashbackProgress={monthlyCashbackProgress}
      />

      {currencies.length > 1 && (
        <Box width={220} mb={3}>
          <FormControl fullWidth>
            <Select<CardAccountCurrency>
              value={selectedCurrency}
              onChange={(e, selected) =>
                setSelectedCurrency(e.target.value as CardAccountCurrency)
              }
              sx={{
                '.MuiListItemIcon-root': { minWidth: 36, verticalAlign: 'top' },
              }}
            >
              {currencies.map((item) => (
                <MenuItem key={item} value={item}>
                  <ListItemIcon>
                    <CurrencyFlag currencyCode={item} />
                  </ListItemIcon>
                  {CURRENCY_NAME_BY_CODE[item]} ({item})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {selectedCurrencyCashback ? (
        <Grid container spacing={4}>
          <Tile>
            <PiggyBankIcon fontSize="large" sx={{ color: 'text.secondary' }} />
            <Box flexGrow={1} mx={2}>
              <Typography variant="h6">
                <FormatMoney value={selectedCurrencyCashback.totalEarned} />
              </Typography>
              <Typography variant="body2" mt={0.5}>
                {t('cashbackSubpage.totalCashbackEarned')}
              </Typography>
            </Box>
          </Tile>

          <Tile>
            <ArrowLineDownIcon
              fontSize="large"
              sx={{ color: 'text.secondary' }}
            />
            <Box flexGrow={1} mx={2}>
              <Typography variant="h6">
                <FormatMoney value={selectedCurrencyCashback.totalRedeemed} />
              </Typography>
              <Typography variant="body2" mt={0.5}>
                {t('cashbackSubpage.totalRedeemed')}
              </Typography>
            </Box>
            <Tooltip title={t('cashbackSubpage.tooltips.viewHistory')}>
              <Box>
                <IconButton
                  onClick={() => setIsHistoryRedemptionsDialogOpen(true)}
                  disabled={selectedCurrencyCashback.totalRedeemed.value === 0}
                  size="large"
                >
                  <CalendarBlankIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Tile>

          <Tile>
            <HourglassMediumIcon
              fontSize="large"
              sx={{ color: 'text.secondary' }}
            />
            <Box flexGrow={1} mx={2}>
              <Box display="flex" alignItems="center">
                <Typography variant="h6">
                  <FormatMoney value={selectedCurrencyCashback.totalPending} />
                </Typography>
                <Tooltip title={t('cashbackSubpage.totalPendingTooltip')!}>
                  <InfoIcon
                    fontSize="small"
                    sx={{ color: 'text.secondary', ml: 1 }}
                  />
                </Tooltip>
              </Box>
              <Typography variant="body2" mt={0.5}>
                {t('cashbackSubpage.pending')}
              </Typography>
            </Box>
          </Tile>

          <Tile>
            <CheckCircleIcon
              fontSize="large"
              sx={{ color: 'text.secondary' }}
            />
            <Box flexGrow={1} mx={2}>
              <Box display="flex" alignItems="center">
                <Typography variant="h6">
                  <FormatMoney
                    value={selectedCurrencyCashback.totalRedeemable}
                    fractionalPart
                  />
                </Typography>
                <Tooltip
                  title={
                    <Trans
                      i18nKey="cashbackSubpage.minPayableLabel"
                      components={{
                        'min-payable': (
                          <FormatMoney
                            value={
                              selectedCurrencyCashback.cashbackDefaults
                                .minRedeemableCashback
                            }
                          />
                        ),
                      }}
                    />
                  }
                >
                  <InfoIcon
                    fontSize="small"
                    sx={{ color: 'text.secondary', ml: 1 }}
                  />
                </Tooltip>
              </Box>
              <Typography variant="body2" mt={0.5}>
                {t('cashbackSubpage.redeemableCashback')}
              </Typography>
            </Box>

            <Tooltip title={t('cashbackSubpage.tooltips.redeemCashback')}>
              <Box>
                <IconButton
                  onClick={() => setIsRedeemDialogOpen(true)}
                  disabled={
                    !canUser('cashback:redeem', selectedCurrencyCashback)
                  }
                  size="large"
                  color="primary-contained"
                >
                  <ArrowRightIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Tile>
        </Grid>
      ) : null}

      <Footnote cashback={cashback} />

      <RedeemCashbackDialog
        open={isRedeemDialogOpen}
        currencyCashback={selectedCurrencyCashback!}
        onSuccess={onUpdate}
        onClose={() => setIsRedeemDialogOpen(false)}
      />
      <HistoryCashbackRedemptionsDialog
        open={isHistoryRedemptionsDialogOpen}
        currencyCashback={selectedCurrencyCashback!}
        onClose={() => setIsHistoryRedemptionsDialogOpen(false)}
      />
    </>
  );
};

export default CashbackDashboard;
