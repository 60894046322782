import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MissingReceiptButton } from 'domains/transaction/components';
import { Divider, Typography } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { PageFiltersComponent } from 'layout';
import { Project, Team } from 'services/constants';
import FiltersDrawer from './FiltersDrawer';
import { QueryParams } from '.././index';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  disabled: boolean;
  teams: Team[];
  projects: Project[];
  missingReceiptsCount: number;
  transactionsCount: number;
}

const Filters = ({
  params,
  selectedFiltersCount,
  setParam,
  disabled,
  teams,
  projects,
  missingReceiptsCount,
  transactionsCount,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { organization, featureModules },
  } = useGlobalState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <>
      <PageFiltersComponent
        count={selectedFiltersCount}
        q={params.q}
        setQParam={(value) => setParam('q', value)}
        onOpen={() => setIsFilterOpen(true)}
        disabled={disabled}
      >
        <Typography variant="body2" color="textSecondary" ml={2}>
          {t('common.resultsTable', { count: transactionsCount })}
        </Typography>
        {featureModules.RECEIPT_MANAGEMENT &&
          organization?.missingReceiptNotificationEnabled &&
          missingReceiptsCount > 0 && (
            <>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ mx: 2 }}
              />
              <MissingReceiptButton
                receiptFilter={params.receipt}
                setParam={setParam}
              />
            </>
          )}
      </PageFiltersComponent>

      <FiltersDrawer
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        teams={teams}
        projects={projects}
        params={params}
        setParam={setParam}
      />
    </>
  );
};

export default Filters;
