import { useTranslation } from 'react-i18next';
import { StatusBadge } from 'elements';
import { PartnerAuthStatus } from 'services/constants';

interface Props {
  status: PartnerAuthStatus;
}

const getColor = (status: PartnerAuthStatus) => {
  if (status === PartnerAuthStatus.active) return 'success';
  if (status === PartnerAuthStatus.pending) return 'primary';
  return 'error';
};

export const PartnerStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();
  const label = t(`partnerStatuses.${status}`);
  const type = getColor(status);

  return (
    <StatusBadge
      color={type}
      variant={status === PartnerAuthStatus.pending ? 'tinted' : 'filled'}
      label={label}
    />
  );
};
