import React from 'react';
import { useGlobalState } from 'context/GlobalState';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { useCanUser } from 'services/rbac';
import AppSwitchButton from './AppSwitchButton';
import NotificationsMenu from './NotificationsMenu';
import ReceiptInboxButton from './ReceiptInboxButton';
import SupportMenu from './SupportMenu';
import UserMenu from './UserMenu';
import { MenuSection, StyledMainHeader } from './style';
import withLightNavigationModeTheme from './withLightNavigationModeTheme';

const AdminMainHeader = () => {
  const {
    state: { featureModules },
  } = useGlobalState();
  const canUser = useCanUser();
  const isOrgInOnboarding = useIsOrgInOnboarding();

  return (
    <StyledMainHeader>
      <MenuSection>
        {canUser('receipt-inbox:view') &&
          !isOrgInOnboarding &&
          featureModules.RECEIPT_MANAGEMENT && <ReceiptInboxButton />}
        <SupportMenu />
        <NotificationsMenu />
        <UserMenu />
        <AppSwitchButton />
      </MenuSection>
    </StyledMainHeader>
  );
};

export default withLightNavigationModeTheme(AdminMainHeader);
