import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import {
  AccountBillStatementBlockMenu,
  BillPaymentItem,
} from 'domains/billing/components';
import {
  ArrowRightIcon,
  ArrowsLeftRightIcon,
  Box,
  Button,
  CaretDownIcon,
  CaretUpIcon,
  Collapse,
  Divider,
  FileTextIcon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Money,
  OrganizationAccountStatement,
  OrganizationAccountStatementPayment,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getPath } from 'services/utils';

const toAbsoluteMoney = (money: Money): Money => ({
  ...money,
  value: Math.abs(money.value),
});

interface State {
  isOpen: boolean;
  isLoading: boolean;
  payments: OrganizationAccountStatementPayment[];
}

interface Props {
  statement: OrganizationAccountStatement;
}

const AccountStatementItem = ({ statement }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { featureModules },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isOpen: false,
    isLoading: false,
    payments: [],
  });
  const isOngoing = moment().isBetween(
    moment(statement.startDate),
    moment(statement.endDate)
  );
  const isStubPeriod = moment(statement.startDate).date() !== 1;

  const toggleOpen = async () => {
    if (!state.payments.length) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { payments } = await api.getOrganizationAccountStatementsPayments(
          statement.organizationAccountStatementId
        );
        if (!mounted.current) return;
        setState((prevState) => ({
          ...prevState,
          payments,
          isLoading: false,
          isOpen: true,
        }));
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          isOpen: false,
        }));
        logError(error);
      }
    } else {
      setState((prevState) => ({ ...prevState, isOpen: !prevState.isOpen }));
    }
  };

  return (
    <Paper variant="outlined" sx={{ mb: 2 }}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ border: 0, width: `6%`, p: 0 }} align="center">
              <FileTextIcon />
            </TableCell>
            <TableCell
              sx={{
                border: 0,
                width: `14%`,
                maxWidth: 0,
                px: 0,
              }}
            >
              <Typography variant="body2" color="text.primary" noWrap>
                {moment(statement.startDate).format('MMMM YYYY')}
              </Typography>
              {isOngoing ? (
                <Typography variant="overline2" color="info.main" noWrap>
                  {t('accountStatementItem.ongoing')}
                </Typography>
              ) : (
                <Typography
                  component="div"
                  variant="caption"
                  color="text.secondary"
                  noWrap
                >
                  <span>{moment(statement.startDate).format('DD MMM')}</span> -{' '}
                  <span>{moment(statement.endDate).format('DD MMM')}</span>
                </Typography>
              )}
              {isStubPeriod && (
                <Box position="relative">
                  <Typography
                    position="absolute"
                    top={-3}
                    variant="caption"
                    color="text.secondary"
                  >
                    {t('accountStatementItem.stubPeriod')}
                  </Typography>
                </Box>
              )}
            </TableCell>
            <TableCell
              sx={{
                border: 0,
                width: `13%`,
                maxWidth: 0,
                pl: 1,
                pr: 0,
              }}
              align="right"
            >
              <Typography variant="body2" color="text.primary" noWrap>
                {statement.openingBalance.value < 0 && <span>-</span>}
                <FormatMoney
                  value={toAbsoluteMoney(statement.openingBalance)}
                  fractionalPart
                />
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="text.secondary"
                noWrap
              >
                {t('accountStatementItem.startingBalance')}
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                border: 0,
                width: `13%`,
                pl: 1,
                pr: 0,
              }}
              align="right"
            >
              <Typography variant="body2" color="text.primary" noWrap>
                {statement.totalTransactionAmount.value < 0 ? (
                  <span>-</span>
                ) : (
                  <span>+</span>
                )}
                <FormatMoney
                  value={toAbsoluteMoney(statement.totalTransactionAmount)}
                  fractionalPart
                />
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="text.secondary"
                noWrap
              >
                {t('accountStatementItem.totalTransactions')}
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                border: 0,
                width: `13%`,
                pl: 1,
                pr: 0,
              }}
              align="right"
            >
              <Typography variant="body2" color="text.primary" noWrap>
                {statement.totalPaymentAmount.value < 0 ? (
                  <span>-</span>
                ) : (
                  <span>+</span>
                )}
                <FormatMoney
                  value={toAbsoluteMoney(statement.totalPaymentAmount)}
                  fractionalPart
                />
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="text.secondary"
                noWrap
              >
                {t('accountStatementItem.totalPayments')}
              </Typography>
            </TableCell>
            <TableCell sx={{ border: 0, width: `5%`, px: 0 }} align="right">
              <ArrowRightIcon />
            </TableCell>
            <TableCell
              sx={{
                border: 0,
                width: `13%`,
                pl: 1,
                pr: 0,
              }}
              align="right"
            >
              <Typography variant="body2" color="text.primary" noWrap>
                <FormatMoney value={statement.closingBalance} fractionalPart />
              </Typography>
              <Typography variant="caption" color="text.secondary" noWrap>
                {isOngoing
                  ? t('accountStatementItem.currentBalance')
                  : t('accountStatementItem.closingBalance')}
              </Typography>
            </TableCell>
            <TableCell
              sx={{ border: 0, width: `17%` }}
              padding="none"
              align="right"
            >
              <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="center"
                flexWrap="wrap"
                sx={{ pt: 0.5, '& > a, & > button': { ml: 1, mb: 0.5 } }}
              >
                {!featureModules.MULTI_CARD_ACCOUNT &&
                  !featureModules.MULTI_CURRENCY_BILLING && (
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<ArrowsLeftRightIcon />}
                      component={Link}
                      to={
                        generatePath(getPath('accounting'), {
                          orgId: statement.organizationId,
                        }) +
                        `?fromDate=${statement.startDate}&toDate=${statement.endDate}`
                      }
                    >
                      {t('accountStatementItem.transactionsBtn')}
                    </Button>
                  )}
                <AccountBillStatementBlockMenu statement={statement} />
              </Box>
            </TableCell>
            <TableCell
              sx={{ border: 0, width: `6%` }}
              padding="none"
              align="center"
            >
              <IconButton
                disabled={!statement.totalPaymentCount || state.isLoading}
                onClick={toggleOpen}
              >
                {state.isOpen ? <CaretUpIcon /> : <CaretDownIcon />}
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={9} sx={{ p: 0, border: 0 }}>
              <Collapse in={state.isOpen}>
                <Divider />
                <Box p={3}>
                  <Table>
                    <TableBody>
                      {state.payments.map((payment) => (
                        <BillPaymentItem data={payment} key={payment.id} />
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default AccountStatementItem;
