import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { PageContent, PageHeader, PageTitle } from 'layout';
import { useCanUser } from 'services/rbac';
import BankAccountsSubpage from './BankAccountsSubpage';
import GeneralSubPage from './GeneralSubPage';

const OrganizationWhiteLabeledPage = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const canUser = useCanUser();
  const { path, isExact } = useRouteMatch();

  useEffect(() => {
    contentRef.current?.scrollTo({ top: 0 });
  }, [pathname]);

  if (
    pathname.includes('bank-accounts') &&
    !canUser('bank-accounts-subpage:visit')
  )
    return <Redirect to={path} />;

  return (
    <>
      <PageHeader>
        <PageTitle
          title={
            isExact
              ? t('settingsOrganizationPage.titleCompanyDetails')
              : t('settingsOrganizationPage.titleBankAccounts')
          }
        />
      </PageHeader>

      <PageContent ref={contentRef}>
        <Switch>
          <Route exact path={path} component={GeneralSubPage} />
          <Route
            path={`${path}/bank-accounts`}
            component={BankAccountsSubpage}
          />
        </Switch>
      </PageContent>
    </>
  );
};

export default OrganizationWhiteLabeledPage;
