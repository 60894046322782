import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { TabGroup } from 'domains/transaction/dialogs/ReceiptInboxDialog';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import { ReceiptsAutoMatchingStatus } from 'services/constants';
import StatusFilter from './StatusFilter';
import { ReceiptInboxFilters } from './index';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  filters: ReceiptInboxFilters;
  tabGroup: TabGroup;
  onUpdate: (filters: ReceiptInboxFilters) => void;
  onClose: () => void;
}

interface State {
  status: ReceiptsAutoMatchingStatus[];
  fromDate: Moment | null;
  toDate: Moment | null;
}

const initialState: State = {
  status: [],
  fromDate: null,
  toDate: null,
};

const FiltersDrawer = ({ filters, tabGroup, onUpdate, ...props }: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>(initialState);

  const areFiltersSelected = !!(
    state.status.length ||
    state.fromDate ||
    state.toDate
  );

  useEffect(() => {
    if (props.open)
      setState({
        status: filters.status,
        fromDate: filters.fromDate,
        toDate: filters.toDate,
      });
  }, [filters, props.open]);

  const onFiltersApply = () => {
    onUpdate({
      ...state,
      fromDate: state.fromDate || null,
      toDate: state.toDate || null,
      status: state.status,
    });
    props.onClose();
  };

  const onFilterReset = () => {
    onUpdate(initialState);
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <StatusFilter
        tabGroup={tabGroup}
        value={state.status}
        onChange={(value) =>
          setState((prevState) => ({
            ...prevState,
            status: xor(prevState.status, [value]),
          }))
        }
      />

      <DateFilter
        fromDate={filters.fromDate}
        toDate={filters.toDate}
        label={t('filters.receiptSentToInbox')}
        placeholder={t('filters.receiptReceived')}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate: fromDate?.startOf('day') || null,
            toDate: toDate?.endOf('day') || null,
          }))
        }
      />
    </FilterDrawer>
  );
};

export default FiltersDrawer;
