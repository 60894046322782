import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MemberStatusBadge, TeamBadge } from 'domains/member/components';
import { QueryParams as MembersPageQueryParams } from 'domains/member/pages/MembersPage';
import { QueryParams as UsersPageQueryParams } from 'domains/member/pages/UsersPage';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import {
  FilterDrawer,
  FilterDrawerClearButton,
  FilterDrawerProps,
} from 'layout';
import { MemberStatus, memberStatuses, Team } from 'services/constants';
import { EXTERNAL_ADMIN_ROLES } from 'services/rbac';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: UsersPageQueryParams | MembersPageQueryParams;
  setParam: SetQueryParam;
  teams: Team[];
  onClose: () => void;
}

interface State {
  role: string[];
  status: string[];
  teamIds: string[];
}

const initialState: State = {
  role: [],
  status: [],
  teamIds: [],
};

type SetFilterArgs =
  | {
      key: 'role';
      value: string; // the same type as for MemberDetails, probably should be improved
      type: 'add' | 'remove';
    }
  | { key: 'status'; value: MemberStatus; type: 'add' | 'remove' };

const FiltersDrawer = ({ params, setParam, teams, ...props }: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules, isComplianceRiskWhiteLabelApp },
  } = useGlobalState();
  const [state, setState] = useState<State>(initialState);
  const areFiltersSelected = !!(
    state.role.length ||
    state.status.length ||
    state.teamIds.length
  );

  useEffect(() => {
    if (props.open)
      setState({
        role: params.role,
        status: params.status,
        teamIds: 'teamIds' in params ? params.teamIds : [],
      });
  }, [params, props.open]);

  const setFilters = ({ key, value, type }: SetFilterArgs) => {
    if (type === 'add')
      setState((prevState) => ({
        ...prevState,
        [key]: [...prevState[key], value],
      }));
    else
      setState((prevState) => ({
        ...prevState,
        [key]: prevState[key].filter((item) => item !== value),
      }));
  };

  const onFiltersApply = () => {
    setParam(Object.entries(state));
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.entries(initialState));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-status"
          id="panel-status-header"
        >
          <Typography>{t('filters.status')} </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <FormGroup>
              {memberStatuses.map((status) => (
                <FormControlLabel
                  sx={{ lineHeight: 0 }}
                  key={status}
                  checked={state.status.includes(status)}
                  control={<Checkbox />}
                  onChange={(_, checked) =>
                    setFilters({
                      key: 'status',
                      value: status,
                      type: checked ? 'add' : 'remove',
                    })
                  }
                  label={<MemberStatusBadge status={status} />}
                />
              ))}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-role"
          id="panel-role-header"
        >
          <Typography>{t('filters.role')} </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <FormGroup>
              {EXTERNAL_ADMIN_ROLES.map((role) => (
                <FormControlLabel
                  key={role}
                  checked={state.role.includes(role)}
                  control={<Checkbox />}
                  onChange={(_, checked) =>
                    setFilters({
                      key: 'role',
                      value: role,
                      type: checked ? 'add' : 'remove',
                    })
                  }
                  label={t(`roles.${role}`)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      {!isComplianceRiskWhiteLabelApp &&
        featureModules.TEAMS &&
        !!teams.length && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<CaretDownIcon />}
              aria-controls="panel-team"
              id="panel-team-header"
            >
              <Typography>{t('filters.team')} </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Select<string[]>
                size="medium"
                multiple
                displayEmpty
                value={state.teamIds}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    teamIds: e.target.value as string[],
                  }))
                }
                renderValue={(selected) => {
                  if (!selected.length)
                    return (
                      <Typography variant="inherit" color="text.disabled">
                        {t('filters.team')}
                      </Typography>
                    );

                  return selected.map((teamId) => {
                    const team = teams.find((item) => item.id === teamId);
                    return <TeamBadge key={team!.id} name={team!.name} />;
                  });
                }}
              >
                {teams.map((team) => (
                  <MenuItem key={team.id} value={team.id}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>

              {!!state.teamIds.length && (
                <FilterDrawerClearButton
                  onChange={() =>
                    setState((prevState) => ({
                      ...prevState,
                      teamIds: [],
                    }))
                  }
                />
              )}
            </AccordionDetails>
          </Accordion>
        )}
    </FilterDrawer>
  );
};

export default FiltersDrawer;
