import { createSvgIcon } from '@mui/material';
import { ReactComponent as BankPaymentFee } from 'assets/icons/phosphorCustom/BankPaymentFee.svg';
import { ReactComponent as BankReversal } from 'assets/icons/phosphorCustom/BankReversal.svg';
import { ReactComponent as BankTopUp } from 'assets/icons/phosphorCustom/BankTopUp.svg';
import { ReactComponent as BankWithdrawal } from 'assets/icons/phosphorCustom/BankWithdrawal.svg';
import { ReactComponent as CalendarBank } from 'assets/icons/phosphorCustom/CalendarBank.svg';
import { ReactComponent as CalendarReversal } from 'assets/icons/phosphorCustom/CalendarReversal.svg';
import { ReactComponent as CardBasedAutomation } from 'assets/icons/phosphorCustom/CardBasedAutomation.svg';
import { ReactComponent as Computing } from 'assets/icons/phosphorCustom/Computing.svg';
import { ReactComponent as ManagerRoundFill } from 'assets/icons/phosphorCustom/ManagerRoundFill.svg';
import { ReactComponent as MerchantBasedAutomation } from 'assets/icons/phosphorCustom/MerchantBasedAutomation.svg';
import { ReactComponent as ReceiptAdd } from 'assets/icons/phosphorCustom/ReceiptAdd.svg';
import { ReactComponent as ReceiptAttention } from 'assets/icons/phosphorCustom/ReceiptAttention.svg';
import { ReactComponent as ReceiptExternalLink } from 'assets/icons/phosphorCustom/ReceiptExternalLink.svg';
import { ReactComponent as Travel } from 'assets/icons/phosphorCustom/Travel.svg';
import { ReactComponent as VerticalCardCheck } from 'assets/icons/phosphorCustom/VerticalCardCheck.svg';
import { ReactComponent as VerticalCardExpired } from 'assets/icons/phosphorCustom/VerticalCardExpired.svg';
import { ReactComponent as VerticalCardReplaced } from 'assets/icons/phosphorCustom/VerticalCardReplaced.svg';
import { ReactComponent as VerticalCardRequest } from 'assets/icons/phosphorCustom/VerticalCardRequest.svg';
import { ReactComponent as VerticalCardShipped } from 'assets/icons/phosphorCustom/VerticalCardShipped.svg';
import { ReactComponent as VerticalCardTerminated } from 'assets/icons/phosphorCustom/VerticalCardTerminated.svg';
import { ReactComponent as VerticalCards } from 'assets/icons/phosphorCustom/VerticalCards.svg';
import { ReactComponent as VerticalCardsRequest } from 'assets/icons/phosphorCustom/VerticalCardsRequest.svg';
import { ReactComponent as VssMissing } from 'assets/icons/phosphorCustom/VssMissing.svg';
import { ReactComponent as Withdraw } from 'assets/icons/phosphorCustom/Withdraw.svg';

// Custom icons which look like phosphor icons.
// This is a temporary file structure
// which will be updated when we replace all old icons.

export const BankPaymentFeeIcon = createSvgIcon(
  <BankPaymentFee />,
  'BankPaymentFee'
);

export const BankReversalIcon = createSvgIcon(<BankReversal />, 'BankReversal');

export const BankTopUpIcon = createSvgIcon(<BankTopUp />, 'BankTopUp');

export const BankWithdrawalIcon = createSvgIcon(
  <BankWithdrawal />,
  'BankWithdrawal'
);

export const CalendarBankIcon = createSvgIcon(<CalendarBank />, 'CalendarBank');

export const CalendarReversalIcon = createSvgIcon(
  <CalendarReversal />,
  'CalendarReversal'
);

export const ComputingIcon = createSvgIcon(<Computing />, 'Computing');

export const VerticalCardShippedIcon = createSvgIcon(
  <VerticalCardShipped />,
  'VerticalCardShipped'
);

export const VerticalCardTerminatedIcon = createSvgIcon(
  <VerticalCardTerminated />,
  'VerticalCardTerminated'
);

export const VerticalCardCheckIcon = createSvgIcon(
  <VerticalCardCheck />,
  'VerticalCardCheck'
);

export const VerticalCardExpiredIcon = createSvgIcon(
  <VerticalCardExpired />,
  'VerticalCardExpired'
);

export const VerticalCardReplacedIcon = createSvgIcon(
  <VerticalCardReplaced />,
  'VerticalCardReplaced'
);

export const VerticalCardRequestIcon = createSvgIcon(
  <VerticalCardRequest />,
  'VerticalCardRequest'
);

export const VerticalCardsIcon = createSvgIcon(
  <VerticalCards />,
  'VerticalCards'
);

export const CardBasedAutomationIcon = createSvgIcon(
  <CardBasedAutomation />,
  'CardBasedAutomation'
);

export const MerchantBasedAutomationIcon = createSvgIcon(
  <MerchantBasedAutomation />,
  'MerchantBasedAutomation'
);

export const ReceiptAttentionIcon = createSvgIcon(
  <ReceiptAttention />,
  'ReceiptAttention'
);

export const ManagerRoundFillIcon = createSvgIcon(
  <ManagerRoundFill />,
  'ManagerRoundFill'
);

export const ReceiptAddIcon = createSvgIcon(<ReceiptAdd />, 'ReceiptAdd');

export const ReceiptExternalLinkIcon = createSvgIcon(
  <ReceiptExternalLink />,
  'ReceiptExternalLink'
);

export const TravelIcon = createSvgIcon(<Travel />, 'Travel');

export const VerticalCardsRequestIcon = createSvgIcon(
  <VerticalCardsRequest />,
  'VerticalCardsRequest'
);

export const VssMissingIcon = createSvgIcon(<VssMissing />, 'VssMissing');

export const WithdrawIcon = createSvgIcon(<Withdraw />, 'Withdraw');
