import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { TradeNameChangeDialog } from 'domains/organization/dialogs';
import { Box, Grid, LabeledValue, QuestionIcon, Tooltip } from 'elements';
import { useCanUser } from 'services/rbac';

const TradeNameGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Grid item xs={6}>
        <LabeledValue
          label={
            <Box display="flex" alignItems="center">
              {t('common.tradeName')}
              <Tooltip title={t('tooltips.tradeName')}>
                <QuestionIcon fontSize="small" sx={{ ml: 1 }} />
              </Tooltip>
            </Box>
          }
          value={organization!.tradeName || '-'}
          onEdit={
            canUser('org-trade-name:change')
              ? () => setIsDialogOpen(true)
              : undefined
          }
        />
      </Grid>

      <TradeNameChangeDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default TradeNameGroup;
