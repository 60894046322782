import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import FormatPercent from 'components/FormatPercent';
import { LinearProgress, Typography } from 'elements';
import { Money } from 'services/constants';
import {
  getBudgetSpentPercentage,
  getLimitPercentage,
  getMoneyWithOppositeSign,
} from 'services/utils';

interface Props {
  balance: Money;
  budget: Money;
}

const TeamBuget = ({ balance, budget }: Props) => {
  const { t } = useTranslation();

  const amountSpent = getMoneyWithOppositeSign(balance);

  const { limitPercentage, isPercentageLow } = getLimitPercentage({
    availableLimit: amountSpent,
    limit: budget,
    basePercentage: 80,
  });

  const budgetSpentPercentage = getBudgetSpentPercentage(
    budget.value,
    amountSpent.value
  );

  return (
    <>
      <Typography variant="subtitle2" mt={2}>
        {t('teamsPage.budget')}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <FormatMoney value={amountSpent} /> / <FormatMoney value={budget} />{' '}
        <Typography
          variant="inherit"
          component="span"
          color={isPercentageLow ? 'inherit' : 'warning.main'}
        >
          (
          <FormatPercent fractionalPart={false} value={budgetSpentPercentage} />
          )
        </Typography>
      </Typography>

      <LinearProgress
        value={limitPercentage}
        color={isPercentageLow ? 'primary' : 'warning'}
        sx={{ mt: 1 }}
      />
    </>
  );
};

export default TeamBuget;
