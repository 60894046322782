import { Components, Theme } from '@mui/material';

const MuiAutocompleteOverrides: Components<Theme> = {
  MuiAutocomplete: {
    styleOverrides: {
      paper: ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        boxShadow: theme.shadows[8],
      }),
    },
  },
};

export default MuiAutocompleteOverrides;
