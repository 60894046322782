import moment from 'moment';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { cardholderPaths } from 'components/App';
import FormatMoney from 'components/FormatMoney';
import {
  MerchantCell,
  TransactionAmount,
} from 'domains/transaction/components';
import { Table, TableBody, TableCell, TableRow, Typography } from 'elements';
import { Transaction } from 'services/constants';
import { getPath } from 'services/utils';

const getTransactionPath = (transaction: Transaction, teamId?: string) => {
  return teamId
    ? generatePath(cardholderPaths.teamTransactionDetails, {
        transactionId: transaction.transactionId,
      })
    : generatePath(getPath('transactionDetails'), {
        orgId: transaction.organizationId,
        transactionId: transaction.transactionId,
      });
};

interface Props {
  transactions: Transaction[];
  teamId?: string;
}

const LastTransactionsList = ({ transactions, teamId }: Props) => {
  return (
    <Table component="div">
      <TableBody component="div">
        {transactions.map((transaction) => {
          const transactionPath = getTransactionPath(transaction, teamId);

          return (
            <TableRow
              key={transaction.transactionId}
              component={RouterLink}
              to={transactionPath}
              sx={(theme) => ({
                textDecoration: 'none',
                '&:hover': {
                  background: theme.palette.action.hover,
                },
              })}
            >
              <TableCell
                component="div"
                sx={{ overflow: 'hidden', maxWidth: 300 }}
              >
                <MerchantCell transaction={transaction} />
              </TableCell>
              <TableCell component="div" align="right">
                <TransactionAmount
                  status={transaction.status}
                  simpleType={transaction.simpleType}
                >
                  <FormatMoney
                    value={transaction.transactionAmount}
                    fractionalPart
                    positiveSign
                  />
                </TransactionAmount>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component="div"
                >
                  {moment.utc(transaction.createdAt).format('D MMM YYYY')}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default LastTransactionsList;
