import React, { MouseEvent, useState } from 'react';
import { CardIcon, CardSummary } from 'domains/card/components';
import { Box, LoaderWithOverlay, Tooltip } from 'elements';
import useHover from 'hooks/useHover';
import useSnackbar from 'hooks/useSnackbar';
import { Card } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  cardId: string;
  cardDesignId: string;
  onClick?: (e: MouseEvent<HTMLSpanElement>, cardId: string) => void;
}

const CardIconWithTooltip = ({ cardId, cardDesignId, onClick }: Props) => {
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [hoverRef, isHovered] = useHover();
  const [card, setCard] = useState<Card | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasFailed, setHasFailed] = useState(false);

  React.useEffect(() => {
    if (isHovered && !card) {
      (async () => {
        try {
          const data = await api.getCard(cardId);

          if (hoverRef.current) {
            setCard(data);
            setIsLoading(false);
          }
        } catch (error) {
          if (!hoverRef.current) return;
          setHasFailed(true);
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      })();
    }
  }, [isHovered]);

  const renderTooltip = () => {
    return (
      <Box minHeight="88px" minWidth="152px" p={0.5}>
        {card && <CardSummary card={card} />}
        <LoaderWithOverlay loading={isLoading} size="small" />
      </Box>
    );
  };

  return (
    <Tooltip
      open={isHovered && !hasFailed}
      title={renderTooltip()}
      PopperProps={{
        sx: (theme) => ({
          '.MuiTooltip-tooltip': {
            background: theme.palette.common.white,
            boxShadow: theme.shadows[4],
            color: theme.palette.text.primary,
          },
          '.MuiTooltip-arrow': {
            color: theme.palette.common.white,
          },
        }),
      }}
    >
      <span ref={hoverRef} onClick={(e) => onClick && onClick(e, cardId)}>
        <CardIcon cardDesignId={cardDesignId} sx={{ mr: '10px' }} />
      </span>
    </Tooltip>
  );
};

export default CardIconWithTooltip;
