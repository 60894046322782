import NothingFound from 'components/NothingFound';
import { Box, LoaderWithOverlay } from 'elements';
import MapAccountsStep from './MapAccountsStep';
import SelectAccountsStep from './SelectAccountsStep';
import StartStep from './StartStep';
import {
  GlAccountsStepsEnum,
  useGLAccountsSyncContext,
  withGLAccountsSyncContext,
} from './useGLAccountsSyncContext';

const GLAccountsStep = () => {
  const {
    state: { isLoading, innerStep, accounts },
  } = useGLAccountsSyncContext();

  const renderStep = () => {
    // this is initial step for the whole sync process
    if (innerStep === GlAccountsStepsEnum.initialStep) return <StartStep />;

    if (!accounts?.length)
      return isLoading ? null : (
        <Box height="100%" position="relative">
          <NothingFound />
        </Box>
      );

    // inner steps for syncing and mapping G/L accounts
    if (innerStep === GlAccountsStepsEnum.selectAccountsStep)
      return <SelectAccountsStep />;
    return <MapAccountsStep />;
  };

  return (
    <>
      {renderStep()}
      <LoaderWithOverlay loading={isLoading} />
    </>
  );
};

export default withGLAccountsSyncContext(GLAccountsStep);
