import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { AccountingItemStatus, ProjectStatus } from 'services/constants';

type Value = ProjectStatus.missing | Omit<ProjectStatus.missing, string>;

interface Props {
  value: Value;
  onChange: (value: Value) => void;
}

const ProjectFilterSelect = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const {
    state: { projects },
  } = useGlobalState();

  const visibleProjects =
    sortBy(
      projects?.filter((item) => item.status === AccountingItemStatus.active),
      (v) => v.name.toLowerCase()
    ) || [];

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-project"
        id="panel-project-header"
      >
        <Typography>{t('filters.project')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<Value>
            size="medium"
            displayEmpty
            value={value}
            onChange={(e) => onChange(e.target.value as Value)}
            renderValue={(selected) => {
              if (!selected)
                return (
                  <Typography color="text.disabled">
                    {t('filters.project')}
                  </Typography>
                );

              if (selected === ProjectStatus.missing)
                return <Typography>—</Typography>;

              const project = visibleProjects.find(
                (item) => item.id === selected
              );
              return project?.name || '';
            }}
          >
            <MenuItem value="">
              <Typography color="text.disabled">
                {t('filters.vatRate')}
              </Typography>
            </MenuItem>

            <MenuItem value={ProjectStatus.missing}>—</MenuItem>

            {visibleProjects.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                {project.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProjectFilterSelect;
