import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { CashbackRedemptionStatusBadge } from 'domains/billing/components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import {
  AccountGroup,
  accountGroups,
  CashbackRedemptionStatus,
  cashbackRedemptionStatuses,
} from 'services/constants';
import { QueryParams } from '.././index';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  createdDateFrom: Moment | null;
  createdDateTo: Moment | null;
  paymentDateFrom: Moment | null;
  paymentDateTo: Moment | null;
  accountGroup: AccountGroup | '';
  states: CashbackRedemptionStatus[];
}

const initialState: State = {
  createdDateFrom: null,
  createdDateTo: null,
  paymentDateFrom: null,
  paymentDateTo: null,
  accountGroup: '',
  states: [],
};

const FiltersDrawer = ({ params, setParam, ...props }: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>(initialState);

  const areFiltersSelected = !!(
    state.createdDateFrom ||
    state.createdDateTo ||
    state.paymentDateFrom ||
    state.paymentDateTo ||
    state.accountGroup ||
    state.states.length
  );

  useEffect(() => {
    if (props.open)
      setState({
        createdDateFrom: params.createdDateFrom,
        createdDateTo: params.createdDateTo,
        paymentDateFrom: params.paymentDateFrom,
        paymentDateTo: params.paymentDateTo,
        accountGroup: params.accountGroup,
        states: params.states,
      });
  }, [props.open]);

  const onFiltersApply = () => {
    setParam(
      Object.entries({
        ...state,
        createdDateFrom: state.createdDateFrom?.format('YYYY-MM-DD'),
        createdDateTo: state.createdDateTo?.format('YYYY-MM-DD'),
        paymentDateFrom: state.paymentDateFrom?.format('YYYY-MM-DD'),
        paymentDateTo: state.paymentDateTo?.format('YYYY-MM-DD'),
      })
    );
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.entries(initialState));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <DateFilter
        label={t('filters.createdAt')}
        fromDate={params.createdDateFrom}
        toDate={params.createdDateTo}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            createdDateFrom: fromDate,
            createdDateTo: toDate,
          }))
        }
      />
      <DateFilter
        label={t('filters.paymentDate')}
        fromDate={params.paymentDateFrom}
        toDate={params.paymentDateTo}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            paymentDateFrom: fromDate,
            paymentDateTo: toDate,
          }))
        }
      />

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-account-group"
          id="panel-account-group-header"
        >
          <Typography>{t('int.filters.accountGroup')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth>
            <Select
              size="medium"
              displayEmpty
              value={state.accountGroup}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  accountGroup: e.target.value as State['accountGroup'],
                }))
              }
            >
              <MenuItem value="">
                <Typography color="text.disabled">
                  {t('int.filters.accountGroup')}
                </Typography>
              </MenuItem>
              {accountGroups.map((item) => (
                <MenuItem key={item} value={item}>
                  {t(`int.accountGroupSelect.items.${item}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-states"
          id="panel-states-header"
        >
          <Typography>{t('filters.state')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <FormGroup>
              {cashbackRedemptionStatuses.map((status) => (
                <FormControlLabel
                  key={status}
                  checked={state.states.includes(status)}
                  control={<Checkbox />}
                  onChange={() =>
                    setState((prevState) => ({
                      ...prevState,
                      states: xor(prevState.states, [status]),
                    }))
                  }
                  label={
                    <CashbackRedemptionStatusBadge
                      key={status}
                      status={status}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </FilterDrawer>
  );
};

export default FiltersDrawer;
