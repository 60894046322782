import { useCallback } from 'react';
import { TransactionExportStatus } from 'services/constants';
import useImperativeApi from 'services/network/useImperativeApi';

const MAX_TIMES = 30;
export const LIMIT_REACHED_ERROR = 'requestsLimitReached';

interface Params {
  organizationId: string;
  accountingExportId: string;
  exportStatus: TransactionExportStatus;
  totalCount: number;
}

// wait until the number of transactions in the export service equals the number
// of changed transactions based on accountingExportId
const useWaitForExportFlowTransactionsProcessing = () => {
  const api = useImperativeApi();

  return useCallback(
    ({ totalCount, ...params }: Params) =>
      new Promise<void>((resolve, reject) => {
        let count = 0;
        const fn = async () => {
          try {
            count += 1;
            const data = await api.getExportFlowTransactions({
              ...params,
              limit: 1,
            });
            if (data.totalCount === totalCount) {
              resolve();
            } else if (count >= MAX_TIMES) {
              reject(LIMIT_REACHED_ERROR);
            } else {
              setTimeout(fn, 1000);
            }
          } catch (e) {
            reject();
          }
        };
        fn();
      }),
    [api]
  );
};

export default useWaitForExportFlowTransactionsProcessing;
