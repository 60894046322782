import React, { useEffect, useState } from 'react';
import { findIndex, kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid, LoaderWithOverlay, Typography } from 'elements';
import withPageErrorWrapper from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import useQueryParams from 'hooks/useQueryParams';
import useSnackbar from 'hooks/useSnackbar';
import { PartnerDeal, SupportedWebAppLanguage } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import DealDescriptionDialog from './DealDescriptionDialog';
import { Logo, Tile } from './style';

interface State {
  isDealDescriptionDialogOpen: boolean;
  isLoading: boolean;
  partnerDeals: PartnerDeal[];
  selectedDealIdx: number | null;
}

const PartnerDealsSubpage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { i18n } = useTranslation();
  const queryParams = useQueryParams();
  const [state, setState] = useState<State>({
    isDealDescriptionDialogOpen: false,
    isLoading: true,
    partnerDeals: [],
    selectedDealIdx: null,
  });

  const getDataAndSetModal = async () => {
    try {
      const data = await api.getPartnerDeals(
        // show EN partner deals for all but DE language
        [SupportedWebAppLanguage.de, SupportedWebAppLanguage.en].includes(
          i18n.language as SupportedWebAppLanguage
        )
          ? i18n.language
          : SupportedWebAppLanguage.en
      );
      if (!mounted.current) return;

      let dealIdx: null | number = null;
      if (queryParams.deal) {
        const tempIdx = findIndex(
          data,
          (deal) => kebabCase(deal.merchantName) === queryParams.deal
        );
        dealIdx = tempIdx > -1 ? tempIdx : null;
      }

      setState((prevState) => ({
        ...prevState,
        selectedDealIdx: dealIdx,
        isDealDescriptionDialogOpen: typeof dealIdx === 'number',
        partnerDeals: data,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    getDataAndSetModal();
  }, []);

  return (
    <>
      {state.isLoading ? (
        <LoaderWithOverlay loading />
      ) : (
        <Grid container spacing={4}>
          {state.partnerDeals.map((deal, idx) => (
            <Grid item key={deal.partnerDealId}>
              <Tile
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isDealDescriptionDialogOpen: true,
                    selectedDealIdx: idx,
                  }))
                }
              >
                <Logo $src={deal.merchantLogo} />
                <div>
                  <Typography variant="h6" mb={0.5}>
                    {deal.merchantName}
                  </Typography>
                  <Typography variant="body2">
                    {deal.description.shortDescription.web}
                  </Typography>
                </div>
              </Tile>
            </Grid>
          ))}
        </Grid>
      )}
      <DealDescriptionDialog
        deal={state.partnerDeals[state.selectedDealIdx!]}
        open={state.isDealDescriptionDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isDealDescriptionDialogOpen: false,
          }))
        }
      />
    </>
  );
};

export default withPageErrorWrapper(PartnerDealsSubpage);
