import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { CompensationBatchStatusBadge } from 'domains/billing/components';
import { drawerPlaceholderColumn, GridColDef, gridUtils } from 'elements';
import { CompensationBatch } from 'services/constants';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<CompensationBatch>[] = [
      {
        headerName: t('int.compensationBatchesPage.createdAt'),
        field: 'createdAt',
        flex: 100,
        minWidth: 123,
        valueGetter: ({ row }) => moment(row.createdAt).format('YYYY-MM-DD'),
      },
      {
        headerName: t('int.compensationBatchesPage.paymentDate'),
        field: 'paymentDate',
        flex: 100,
        minWidth: 123,
        valueGetter: ({ row }) =>
          row.paymentDate ? moment(row.paymentDate).format('YYYY-MM-DD') : '-',
        sortable: false,
      },
      {
        headerName: t('int.compensationBatchesPage.amount'),
        field: 'amount',
        flex: 100,
        minWidth: 123,
        renderCell: ({ row }) => (
          <FormatMoney value={row.amount} fractionalPart />
        ),
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.compensationBatchesPage.count'),
        field: 'count',
        flex: 80,
        minWidth: 99,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.compensationBatchesPage.state'),
        field: 'state',
        flex: 120,
        minWidth: 148,
        renderCell: ({ row }) => (
          <CompensationBatchStatusBadge status={row.state} />
        ),
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },

      drawerPlaceholderColumn,
    ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language]);
};

export default useColumns;
