import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { getSubscriptionPlanName } from 'domains/organization/utils';
import { Box } from 'elements';
import { PageContent, PageHeader, PageTitle, Tab, Tabs } from 'layout';
import { useCanUser } from 'services/rbac';
import BankAccountsSubpage from './BankAccountsSubpage';
import GeneralSubPage from './GeneralSubPage';
import IntegrationsSubPage from './IntegrationsSubPage';
import ReceiptManagementSubPage from './ReceiptManagementSubPage';
import SubscriptionPlanSubPage from './SubscriptionPlanSubPage';

const OrganizationPage = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();
  const canUser = useCanUser();
  const {
    state: { featureModules, subscriptionPlan },
  } = useGlobalState();

  useEffect(() => {
    contentRef.current?.scrollTo({ top: 0 });
  }, [pathname]);

  return (
    <>
      <PageHeader>
        <PageTitle
          title={t('settingsOrganizationPage.title')}
          suptitle={t('navigation.settings.title')}
        />
      </PageHeader>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={5}>
        <Tabs
          aria-label="Organization page tabs"
          value={pathname.replace(/\/$/, '')}
        >
          <Tab
            component={Link}
            value={url}
            to={url}
            data-test-id="org-details-sub-tab"
            label={t('settingsOrganizationPage.nav.general')}
          />

          {canUser('integrations-subpage:visit') && (
            <Tab
              component={Link}
              value={`${url}/integrations`}
              to={`${url}/integrations`}
              data-test-id="integrations-sub-tab"
              label={t('settingsOrganizationPage.nav.integrations')}
            />
          )}

          {canUser('bank-accounts-subpage:visit') && (
            <Tab
              component={Link}
              value={`${url}/bank-accounts`}
              to={`${url}/bank-accounts`}
              data-test-id="bank-accounts-sub-tab"
              label={t('settingsOrganizationPage.nav.bankAccounts')}
            />
          )}

          {featureModules.RECEIPT_MANAGEMENT && (
            <Tab
              component={Link}
              value={`${url}/receipt-management`}
              to={`${url}/receipt-management`}
              data-test-id="receipt-management-sub-tab"
              data-intercom-target="receipt-management-sub-tab"
              label={t('settingsOrganizationPage.nav.receiptManagement')}
            />
          )}

          {canUser('subscription-plan-settings:view') && (
            <Tab
              component={Link}
              value={`${url}/subscription-plan`}
              to={`${url}/subscription-plan`}
              data-test-id="subscription-plan-sub-tab"
              label={
                subscriptionPlan
                  ? t('settingsOrganizationPage.nav.subscriptionPlan', {
                      plan: getSubscriptionPlanName(subscriptionPlan.type)
                        .replace('(', '')
                        .replace(')', ''),
                    })
                  : t('errors.loadData')
              }
            />
          )}
        </Tabs>
      </Box>

      <PageContent ref={contentRef} pt={5}>
        <Switch>
          <Route exact path={path} component={GeneralSubPage} />
          <Route
            path={`${path}/integrations`}
            component={IntegrationsSubPage}
          />
          <Route
            path={`${path}/bank-accounts`}
            component={BankAccountsSubpage}
          />
          <Route
            path={`${path}/receipt-management`}
            component={ReceiptManagementSubPage}
          />
          <Route
            path={`${path}/subscription-plan`}
            component={SubscriptionPlanSubPage}
          />
        </Switch>
      </PageContent>
    </>
  );
};

export default OrganizationPage;
