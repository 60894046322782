import { useState } from 'react';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { MerchantCategoryIcon } from 'domains/merchant/components';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  merchantCategories,
  NetworkErrorCode,
  SUBCATEGORY,
  Subcategory,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import useCanUser from 'services/rbac/useCanUser';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  trimObjValues,
} from 'services/utils';

interface State {
  confirmation: 'deactivate' | 'delete' | null;
  isUpdating: boolean;
}

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (subcategory: Subcategory) => void;
  onDelete: (id: string) => void;
  subcategory: Subcategory | null;
}

const EditSubCategoryDialog = ({
  subcategory,
  onSuccess,
  onDelete,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    confirmation: null,
    isUpdating: false,
  });
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      ...subcategory!,
    },
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        const data = await api.updateSubcategory(trimObjValues(values));
        if (!mounted.current) return;
        onSuccess(data);
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.nonUniqueSubcategoryName
        ) {
          setFieldError('name', t('editSubCategoryDialog.nonUniqNameError'));
          return;
        }
        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.invalidSubcategoryNumber
        ) {
          setFieldError(
            'number',
            t('editSubCategoryDialog.invalidNumberError')
          );
          return;
        }
        if (
          getNetworkErrorCode(error) === NetworkErrorCode.subcategoryHasLink
        ) {
          props.onClose();
          enqueueSnackbar(
            t('editSubCategoryDialog.updateSubcategoryHasLinkError'),
            { variant: 'error' }
          );
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const changeStatus = async (status: AccountingItemStatus) => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      const data = await api.updateSubcategory({ ...subcategory!, status });
      if (!mounted.current) return;
      onSuccess(data);
      enqueueSnackbar(
        status === AccountingItemStatus.inactive
          ? t('editSubCategoryDialog.deactivatedSuccessMessage')
          : t('editSubCategoryDialog.activatedSuccessMessage'),
        { autoHideDuration: 1500 }
      );
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        confirmation: null,
        isUpdating: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const handleDelete = async () => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      await api.deleteSubcategory(subcategory!.id);
      if (!mounted.current) return;
      onDelete(subcategory!.id);
      enqueueSnackbar(t('editSubCategoryDialog.deletedSuccessMessage'), {
        autoHideDuration: 1500,
      });
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        confirmation: null,
        isUpdating: false,
      }));
      if (getNetworkErrorCode(error) === NetworkErrorCode.subcategoryHasLink) {
        props.onClose();
        enqueueSnackbar(
          t('editSubCategoryDialog.deleteSubcategoryHasLinkError'),
          { variant: 'error' }
        );
        return;
      }
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const hasEmptyField = !formik.values.name.trim();
  const hasChangedValues =
    formik.values.name !== subcategory?.name ||
    formik.values.number !== subcategory?.number ||
    formik.values.category !== subcategory?.category;

  if (state.confirmation === 'deactivate') {
    return (
      <ConfirmDialog
        {...props}
        title={t('editSubCategoryDialog.deactivateStep.title')}
        description={
          <Trans
            i18nKey="editSubCategoryDialog.deactivateStep.description"
            components={{ br: <br /> }}
          />
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          color: 'error',
          children: t('editSubCategoryDialog.deactivateStep.deactivate'),
        }}
        onSuccess={() => changeStatus(AccountingItemStatus.inactive)}
        onClose={() =>
          setState((prevState) => ({ ...prevState, confirmation: null }))
        }
      />
    );
  }

  if (state.confirmation === 'delete') {
    return (
      <ConfirmDialog
        {...props}
        title={t('editSubCategoryDialog.deleteStep.title')}
        description={
          <Trans
            i18nKey="editSubCategoryDialog.deleteStep.description"
            components={{ br: <br /> }}
          />
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          color: 'error',
          children: t('editSubCategoryDialog.deleteStep.delete'),
        }}
        onSuccess={handleDelete}
        onClose={() =>
          setState((prevState) => ({ ...prevState, confirmation: null }))
        }
      />
    );
  }

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle display="flex" alignItems="center">
        {t('editSubCategoryDialog.title')}
        {subcategory?.status === AccountingItemStatus.inactive && (
          <Box ml={1} fontSize={0}>
            <Chip
              size="small"
              label={t('editSubCategoryDialog.inactiveBadge')}
              variant="outlined"
            />
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="edit-subcategory-form"
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('name')}
                label={t('editSubCategoryDialog.nameTitle')}
                inputProps={{
                  maxLength: SUBCATEGORY.nameMaxLength,
                }}
                disabled={state.isUpdating}
                onChange={(e) => {
                  formik.setFieldValue('name', e.target.value, true);
                }}
                error={!!formik.errors.name}
                helperText={formik.errors.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('number')}
                label={t('editSubCategoryDialog.numberTitle')}
                inputProps={{
                  maxLength: SUBCATEGORY.numberMaxLength,
                }}
                disabled={state.isUpdating}
                error={!!formik.errors.number}
                helperText={formik.errors.number}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="merchant-select-label">
                  {t('editSubCategoryDialog.categoryTitle')}
                </InputLabel>
                <Select
                  labelId="merchant-select-label"
                  disabled={state.isUpdating}
                  renderValue={(selected) => {
                    if (!selected) return '';
                    return t(`merchantCategories.${selected}`);
                  }}
                  {...formik.getFieldProps('category')}
                >
                  {merchantCategories.map((category) => (
                    <MenuItem key={category} value={category}>
                      <ListItemIcon>
                        <MerchantCategoryIcon category={category} />
                      </ListItemIcon>
                      {t(`merchantCategories.${category}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {subcategory?.status === AccountingItemStatus.active ? (
          <>
            <Box mr="auto">
              <Button
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    confirmation: 'deactivate',
                  }));
                }}
                disabled={state.isUpdating}
                variant="text"
                color="error"
              >
                {t('editSubCategoryDialog.deactivate')}
              </Button>
            </Box>
            <Button onClick={props.onClose} variant="text">
              {t('common.button.close')}
            </Button>
            <Button
              disabled={hasEmptyField || state.isUpdating}
              type="submit"
              form="edit-subcategory-form"
            >
              {t('editSubCategoryDialog.save')}
            </Button>
          </>
        ) : (
          <>
            {canUser('subcategory:delete') && (
              <Box mr="auto">
                <Button
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      confirmation: 'delete',
                    }))
                  }
                  disabled={state.isUpdating}
                  variant="text"
                  color="error"
                >
                  {t('editSubCategoryDialog.delete')}
                </Button>
              </Box>
            )}
            <Button onClick={props.onClose} variant="text">
              {t('common.button.close')}
            </Button>
            {hasChangedValues ? (
              <Button
                disabled={hasEmptyField || state.isUpdating}
                type="submit"
                form="edit-subcategory-form"
              >
                {t('editSubCategoryDialog.save')}
              </Button>
            ) : (
              <Button
                onClick={() => changeStatus(AccountingItemStatus.active)}
                disabled={state.isUpdating}
              >
                {t('editSubCategoryDialog.activate')}
              </Button>
            )}
          </>
        )}
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting || state.isUpdating} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditSubCategoryDialog);
