import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import NothingFound from 'components/NothingFound';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import {
  ArrowsClockwiseIcon,
  Box,
  Button,
  DataGrid,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import { PageSearchInput } from 'layout';
import { CodatSupplierItem } from 'services/constants';
import useColumns from './useColumns';
import useSuppliersSync from './useSuppliersSync';

interface Props {
  isPartialFlow?: boolean;
  onPartialFlowSuccess?: () => void;
  onPartialFlowCancel?: () => void;
}

const SuppliersStep = ({
  isPartialFlow,
  onPartialFlowSuccess,
  onPartialFlowCancel,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const {
    suppliers,
    selectedIds,
    onItemsSelect,
    isError,
    isLoading,
    isInnerLoading,
    getData,
    onSuppliersSave,
    fetchCodatData,
    onClose,
  } = useSuppliersSync();
  const [searchInput, setSearchInput] = useState('');
  const noItemsFound = !isLoading && suppliers?.length === 0;

  const filteredSuppliers = useMemo(
    () =>
      suppliers?.filter((supplier) => {
        const val = searchInput.toLowerCase();
        return (
          supplier.name.toLowerCase().includes(val) ||
          supplier.currency?.toLowerCase().includes(val)
        );
      }) || [],
    [suppliers, searchInput]
  );

  const gridColumns = useColumns(isPartialFlow);

  if (isLoading) return <LoaderWithOverlay loading />;

  if (isError)
    return (
      <Box margin="auto">
        <WidgetError onReload={getData} />
      </Box>
    );

  return (
    <>
      <DialogTitle>{t('codatSyncSetupDialog.suppliersStep.title')}</DialogTitle>

      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      >
        {!!suppliers?.length && (
          <Typography color="text.secondary">
            <Trans
              i18nKey="codatSyncSetupDialog.suppliersStep.description"
              values={{
                name: accountingSettings!.accountingSystemName,
              }}
              count={suppliers.length}
              components={{
                bold: <Typography component="span" color="text.primary" />,
              }}
            />
          </Typography>
        )}

        {suppliers?.length ? (
          <>
            <Box my={3}>
              <Typography color="text.secondary">
                {t('codatSyncSetupDialog.controls.selectedLabel', {
                  value: `${selectedIds.length} / ${suppliers.length}`,
                })}
              </Typography>
            </Box>

            <Box
              pt={1}
              pb={2}
              borderBottom="1px solid"
              borderColor={(theme) => theme.palette.divider}
            >
              <PageSearchInput initialValue="" onChange={setSearchInput} />
            </Box>

            <Box flex="1" overflow="hidden">
              <DataGrid<CodatSupplierItem>
                checkboxSelection
                disableRowSelectionOnClick={false}
                onRowSelectionModelChange={(newRowSelectionModel) =>
                  onItemsSelect(newRowSelectionModel as string[])
                }
                rowSelectionModel={selectedIds}
                columns={gridColumns}
                rows={filteredSuppliers}
              />
            </Box>
          </>
        ) : (
          <NothingFound />
        )}
      </DialogContent>

      <DialogActions>
        {isPartialFlow ? (
          <Box mr="auto">
            <Button
              variant="text"
              onClick={onPartialFlowCancel}
              disabled={isInnerLoading}
            >
              {t('common.button.close')}
            </Button>

            <Button
              variant="text"
              onClick={fetchCodatData}
              disabled={isInnerLoading}
              startIcon={<ArrowsClockwiseIcon />}
            >
              {t('codatSyncSetupDialog.syncNowButton')}
            </Button>
          </Box>
        ) : (
          <Button variant="text" onClick={onClose} disabled={isInnerLoading}>
            {t('common.button.close')}
          </Button>
        )}

        <Button
          disabled={(!selectedIds.length || isInnerLoading) && !noItemsFound}
          onClick={() => onSuppliersSave(onPartialFlowSuccess)}
        >
          {selectedIds.length
            ? t('codatSyncSetupDialog.suppliersStep.syncSuppliersButton', {
                count: selectedIds.length,
              })
            : t('common.button.next')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={isInnerLoading} />
    </>
  );
};

export default SuppliersStep;
