import { useTranslation } from 'react-i18next';
import { MerchantCategoryIcon } from 'domains/merchant/components';
import {
  FormControl,
  ListItemIcon,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { merchantCategories, MerchantCategory } from 'services/constants';

interface Props {
  category: MerchantCategory | null;
  onChange: (value: MerchantCategory) => void;
  disabled?: boolean;
  noneValue?: 'NONE' | '';
}

const CategorySelect = ({
  noneValue = '',
  category,
  disabled = false,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth disabled={disabled}>
      <Select<MerchantCategory | typeof noneValue>
        value={category || noneValue}
        renderValue={(selected) => {
          if (selected === noneValue)
            return (
              <Typography color="text.secondary">
                {t('codatSyncSetupDialog.controls.assignSelectedLabel')}
              </Typography>
            );
          return t(`merchantCategories.${selected}`);
        }}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => onChange(e.target.value as MerchantCategory)}
      >
        {noneValue && (
          <MenuItem disabled value={noneValue}>
            {t('codatSyncSetupDialog.controls.assignSelectedLabel')}
          </MenuItem>
        )}
        {merchantCategories.map((category) => (
          <MenuItem key={category} value={category}>
            <ListItemIcon>
              <MerchantCategoryIcon category={category} />
            </ListItemIcon>
            {t(`merchantCategories.${category}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CategorySelect;
