import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { InputLabelProps } from '@material-ui/core/InputLabel/InputLabel';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
} from '@material-ui/lab/Autocomplete';
import styled from 'styled-components';
import { ReactComponent as ArrowsButtonDownIcon } from 'assets/icons/interface/arrowsButtonDown.svg';
import { ReactComponent as Delete1Icon } from 'assets/icons/interface/delete1.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/interface/search.svg';
import { IconButton } from 'components/Button';
import { StyledInputBase } from './StyledInputBase';
import { StyledInputLabel } from './StyledInputLabel';

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: calc(50% - 8px);
  left: 16px;
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.colors.grey10};
  pointer-events: none;
`;

const StyledArrowsButtonDownIcon = styled(ArrowsButtonDownIcon)`
  position: absolute;
  top: calc(50% - 8px);
  right: 16px;
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.colors.grey10};
  pointer-events: none;

  [aria-expanded='true'] & {
    transform: rotate(180deg);
  }
`;

export const ClearButton = styled(IconButton)`
  position: absolute;
  top: calc(50% - 18px);
  right: 6px;
  padding: 10px;
`;

interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>
  extends Omit<
    MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  placeholder: string;
  withDropdownIcon?: boolean;
  error?: boolean;
  searchIcon?: boolean;
  onClear?: () => void;
  label?: string;
  inputLabelProps?: InputLabelProps;
}

const AutocompleteInternals = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  placeholder,
  withDropdownIcon,
  error,
  searchIcon,
  onClear,
  className,
  label,
  inputLabelProps,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const renderClearButton = () => {
    if (!props.disabled && (props.inputValue || props.value))
      return (
        <ClearButton onClick={onClear}>
          <Delete1Icon />
        </ClearButton>
      );

    return !props.disabled && withDropdownIcon ? (
      <StyledArrowsButtonDownIcon className="dropdown-icon" />
    ) : null;
  };

  return (
    <MuiAutocomplete
      fullWidth
      renderInput={(params) => {
        return (
          <FormControl fullWidth={params.fullWidth} error={error}>
            {label && (
              <StyledInputLabel shrink={false} {...inputLabelProps}>
                {label}
              </StyledInputLabel>
            )}

            <StyledInputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              placeholder={placeholder}
              startAdornment={searchIcon && <StyledSearchIcon />}
              endAdornment={
                onClear ? (
                  renderClearButton()
                ) : (
                  <StyledArrowsButtonDownIcon className="dropdown-icon" />
                )
              }
            />
          </FormControl>
        );
      }}
      classes={{ paper: className }}
      {...props}
    />
  );
};

export const Autocomplete = styled(AutocompleteInternals)`
  margin: 0;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);

  .MuiAutocomplete-listbox {
    padding: 18px 0;
  }

  .MuiAutocomplete-option {
    padding: 7px;
    margin: 0 15px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 6px;
    color: ${({ theme }) => theme.colors.grey10};

    &[data-focus='true'],
    &[aria-selected='true'] {
      background: ${({ theme }) => theme.colors.neutralVariant80};
    }
  }

  .MuiAutocomplete-loading,
  .MuiAutocomplete-noOptions {
    padding: 16px 24px;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.grey100};
  }
` as typeof AutocompleteInternals;
