import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { internalOrgPaths, internalRootPaths } from 'components/App';
import { Box, Link, StatusBadge, Typography } from 'elements';
import { SettlementTransactionPaymentType } from 'services/constants';

const IdElement = ({
  children,
  orgId,
  settlementId,
  type,
}: {
  children: string;
  orgId: string | null;
  settlementId: string | null;
  type: SettlementTransactionPaymentType;
}) => {
  if (!settlementId) {
    return (
      <Typography variant="body2" component="span" ml={1}>
        {children}
      </Typography>
    );
  }
  if (
    type === SettlementTransactionPaymentType.billPmt ||
    type === SettlementTransactionPaymentType.payout
  ) {
    return (
      <Typography variant="body2" component="span" ml={1}>
        {children}
      </Typography>
    );
  }
  if (type === SettlementTransactionPaymentType.orgSetlPmt) {
    if (!orgId)
      return (
        <Typography variant="body2" component="span" ml={1}>
          {children}
        </Typography>
      );
    return (
      <Link
        variant="body2"
        component={RouterLink}
        to={{
          pathname: generatePath(internalOrgPaths.settlementsDetails, {
            orgId,
            settlementId,
          }),
        }}
        ml={1}
      >
        {children}
      </Link>
    );
  }
  if (
    type === SettlementTransactionPaymentType.programSetlPmt ||
    type === SettlementTransactionPaymentType.programSetlFeePmt
  ) {
    return (
      <Link
        variant="body2"
        component={RouterLink}
        to={{
          pathname: generatePath(internalRootPaths.programSettlementDetails, {
            settlementId,
          }),
        }}
        ml={1}
      >
        {children}
      </Link>
    );
  }
  if (type === SettlementTransactionPaymentType.rcnPmt) {
    return (
      <Typography variant="body2" component="span" ml={1}>
        {children}
      </Typography>
    );
  }
  return null;
};

interface Props {
  organizationId: string | null;
  publicId: string;
  settlementId: string | null;
  type: SettlementTransactionPaymentType;
}

const SettlementGroupPaymentCell = ({
  organizationId,
  publicId,
  settlementId,
  type,
}: Props) => {
  const { t } = useTranslation();
  const paymentIdentifier = publicId || '-';
  const isRcnPmt = type === SettlementTransactionPaymentType.rcnPmt;

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <StatusBadge
        variant={isRcnPmt ? 'filled' : 'tinted'}
        color={isRcnPmt ? 'error' : 'primary'}
        label={t(`settlementTransactionPaymentTypes.${type}`)}
      />
      <IdElement orgId={organizationId} settlementId={settlementId} type={type}>
        {paymentIdentifier}
      </IdElement>
    </Box>
  );
};

export default SettlementGroupPaymentCell;
