import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import cardAccountsImageEN from 'assets/images/settingsCardAccountsModalBgImage.jpg';
import cardAccountsImageDE from 'assets/images/settingsCardAccountsModalBgImageDE.jpg';
import commentsImageEN from 'assets/images/settingsCommentsModalBgImage.jpg';
import commentsImageDE from 'assets/images/settingsCommentsModalBgImageDE.jpg';
import costCentersImageEN from 'assets/images/settingsCostCentersModalBgImage.jpg';
import costCentersImageDE from 'assets/images/settingsCostCentersModalBgImageDE.jpg';
import costUnitsImageEN from 'assets/images/settingsCostUnitsModalBgImage.jpg';
import costUnitsImageDE from 'assets/images/settingsCostUnitsModalBgImageDE.jpg';
import glAccountsImageEN from 'assets/images/settingsGLAccountsModalBgImage.jpg';
import glAccountsImageDE from 'assets/images/settingsGLAccountsModalBgImageDE.jpg';
import suppliersImageEN from 'assets/images/settingsSuppliersModalBgImage.jpg';
import suppliersImageDE from 'assets/images/settingsSuppliersModalBgImageDE.jpg';
import vatRatesImageEN from 'assets/images/settingsVatRatesModalBgImage.jpg';
import vatRatesImageDE from 'assets/images/settingsVatRatesModalBgImageDE.jpg';
import Loader from 'components/Loader';
import { useGlobalState } from 'context/GlobalState';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormControlLabelTooltipIcon,
  LoaderWithOverlay,
  Switch,
  Tooltip,
  Typography,
  withDialogWrapper,
} from 'elements';
import { useCanUser } from 'services/rbac';
import { imageLoad } from 'services/utils';
import ConfirmationDialog from './ConfirmationDialog';
import {
  CardItem,
  ChangeableUpdateKey,
  MandatoryExportUpdateKey,
  UpdateKey,
} from './useBasicAccountingSettingsTiles';

const ImageComponent = styled('div')<{ $src: string }>`
  height: 224px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ $src }) => ($src ? `url(${$src})` : 'none')};
`;

const images = {
  costCentersImageEN,
  costUnitsImageEN,
  suppliersImageEN,
  glAccountsImageEN,
  vatRatesImageEN,
  costCentersImageDE,
  costUnitsImageDE,
  suppliersImageDE,
  glAccountsImageDE,
  vatRatesImageDE,
  commentsImageEN,
  commentsImageDE,
  cardAccountsImageEN,
  cardAccountsImageDE,
};

type ImageKey = keyof typeof images;

interface Props extends DialogProps {
  isLoading: boolean;
  isMandatorySettingUpdating: boolean;
  selectedSetting: CardItem | null;
  updateSetting: (fieldName: ChangeableUpdateKey, checked: boolean) => void;
  updateMandatoryExportSetting: (
    fieldName: MandatoryExportUpdateKey,
    checked: boolean
  ) => void;
  onClose: () => void;
}

const SettingsInformationDialog = ({
  isLoading,
  isMandatorySettingUpdating,
  selectedSetting,
  updateSetting,
  updateMandatoryExportSetting,
  ...props
}: Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const canUser = useCanUser();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const [image, setImage] = useState('');
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isImgLoading, setIsImgLoading] = useState(true);

  const isMandatoryCheckboxDisabledForAccSystem =
    selectedSetting!.mandatoryFieldName &&
    [
      MandatoryExportUpdateKey.subcategoryExportMandatory,
      MandatoryExportUpdateKey.vatRateExportMandatory,
    ].includes(selectedSetting!.mandatoryFieldName) &&
    isCodatAccSystemConnected(accountingSettings);

  const getBgImage = (key: string) => {
    const imageKey = `${key}${i18n.language.toUpperCase()}` as ImageKey;
    if (imageKey in images) return images[imageKey];
    return images[`${key}EN` as ImageKey];
  };

  useEffect(() => {
    if (selectedSetting) {
      let activeImage: string;
      switch (selectedSetting.fieldName) {
        case UpdateKey.subcategoryEnabled:
          activeImage = getBgImage('glAccountsImage');
          break;
        case UpdateKey.costCenterEnabled:
          activeImage = getBgImage('costCentersImage');
          break;
        case UpdateKey.projectEnabled:
          activeImage = getBgImage('costUnitsImage');
          break;
        case UpdateKey.supplierEnabled:
          activeImage = getBgImage('suppliersImage');
          break;
        case UpdateKey.vatRateEnabled:
          activeImage = getBgImage('vatRatesImage');
          break;
        case UpdateKey.commentEnabled:
          activeImage = getBgImage('commentsImage');
          break;
        case UpdateKey.cardAccountsEnabled:
          activeImage = getBgImage('cardAccountsImage');
          break;
        default:
          activeImage = '';
      }

      if (!activeImage) {
        setIsImgLoading(false);
        return;
      }

      (async () => {
        try {
          await imageLoad(activeImage);
        } finally {
          setImage(activeImage);
          setIsImgLoading(false);
        }
      })();
    }
  }, [selectedSetting]);

  const renderTooltipText = () => {
    if (isMandatoryCheckboxDisabledForAccSystem)
      return t('settingInformationDialog.mandatoryForExportDisabledReason', {
        name: accountingSettings!.accountingSystemName,
      });
    if (!selectedSetting?.checked)
      return t('mandatoryForExportCheckbox.forbidTooltip');
    return '';
  };

  if (!selectedSetting) return null;

  if (isConfirmDialogOpen)
    return (
      <ConfirmationDialog
        {...props}
        onClose={() => setIsConfirmDialogOpen(false)}
        onSuccess={() => {
          updateSetting(
            selectedSetting.fieldName as ChangeableUpdateKey,
            false
          );
          props.onClose();
        }}
      />
    );

  const manageButtonShown =
    selectedSetting.redirectionLink &&
    selectedSetting.canVisitPage &&
    selectedSetting.checked;

  const activateButtonShown =
    !selectedSetting.readonly &&
    !!canUser('accounting-settings:update') &&
    !selectedSetting.disabled &&
    !selectedSetting.checked;

  return (
    <Dialog {...props}>
      <ImageComponent $src={image}>
        {isImgLoading && <Loader size={20} thickness={3} />}
      </ImageComponent>

      <DialogTitle>
        {t(`settingInformationDialog.${selectedSetting.fieldName}Item.title`)}
      </DialogTitle>

      <DialogContent>
        <Typography component="div">
          <Trans
            i18nKey={t(
              `settingInformationDialog.${selectedSetting.fieldName}Item.description`
            )}
            components={{ div: <div /> }}
          />
        </Typography>

        {'mandatoryFieldName' in selectedSetting &&
          'mandatoryForExport' in selectedSetting && (
            <Tooltip title={renderTooltipText()}>
              <FormControl sx={{ mt: 3 }}>
                <FormControlLabel
                  checked={!!selectedSetting.mandatoryForExport}
                  disabled={
                    isMandatorySettingUpdating ||
                    !!isMandatoryCheckboxDisabledForAccSystem ||
                    !selectedSetting.checked
                  }
                  onChange={(_, checked: boolean) => {
                    if (!selectedSetting.mandatoryFieldName) return;
                    updateMandatoryExportSetting(
                      selectedSetting.mandatoryFieldName,
                      checked
                    );
                  }}
                  control={<Switch />}
                  label={
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      component="span"
                    >
                      {t('settingInformationDialog.mandatoryForExportLabel')}
                      {selectedSetting.mandatoryForExportTooltip && (
                        <FormControlLabelTooltipIcon
                          title={selectedSetting.mandatoryForExportTooltip}
                        />
                      )}
                    </Box>
                  }
                  data-test-id={`checkbox-${selectedSetting.mandatoryFieldName}`}
                  data-intercom-target={`${selectedSetting.intercomTarget}-mandatory-toggle`}
                />
              </FormControl>
            </Tooltip>
          )}
      </DialogContent>

      <DialogActions>
        {!selectedSetting.readonly &&
          !!canUser('accounting-settings:update') &&
          selectedSetting.checked && (
            <Tooltip
              title={selectedSetting.disabledInfoText || ''}
              placement="top"
              disableHoverListener={
                !selectedSetting.disabled || !selectedSetting.disabledInfoText
              }
              disableTouchListener={
                !selectedSetting.disabled || !selectedSetting.disabledInfoText
              }
            >
              <Box mr="auto">
                <Button
                  data-intercom-target={`${selectedSetting.intercomTarget}-deactivate-button`}
                  disabled={
                    selectedSetting.disabled || isMandatorySettingUpdating
                  }
                  onClick={() => setIsConfirmDialogOpen(true)}
                  color="error"
                  variant="text"
                >
                  {t('settingInformationDialog.deactivateButton')}
                </Button>
              </Box>
            </Tooltip>
          )}

        <Button
          variant={
            manageButtonShown || activateButtonShown ? 'text' : 'contained'
          }
          onClick={props.onClose}
        >
          {t('common.button.close')}
        </Button>

        {manageButtonShown && (
          <Button
            data-intercom-target={`${selectedSetting.intercomTarget}-manage-button`}
            onClick={() => history.push(selectedSetting.redirectionLink!)}
          >
            {selectedSetting.showSetupButton
              ? t(
                  `settingInformationDialog.${selectedSetting.fieldName}Item.setupButton`
                )
              : t('settingInformationDialog.manageButton')}
          </Button>
        )}

        {activateButtonShown && (
          <Button
            data-intercom-target={`${selectedSetting.intercomTarget}-activate-button`}
            disabled={isMandatorySettingUpdating}
            onClick={() =>
              updateSetting(
                selectedSetting.fieldName as ChangeableUpdateKey,
                true
              )
            }
          >
            {t('settingInformationDialog.turnOnButton')}
          </Button>
        )}
      </DialogActions>

      <LoaderWithOverlay loading={isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(SettingsInformationDialog);
