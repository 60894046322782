export const getFileNameFromHeader = (headers: any) => {
  const contentDisposition = headers['content-disposition'];
  const match: {
    groups?: { encodedFilename?: string };
  } = contentDisposition.match(
    /.*filename\*=UTF-8''(?<encodedFilename>[^$|;]+)/
  );
  const encodedFilename = match?.groups?.encodedFilename;

  if (encodedFilename) {
    return decodeURIComponent(encodedFilename);
  }

  return headers['content-disposition']
    .replace('attachment; filename="', '')
    .replace(/"$/, '');
};
