import React, { useState } from 'react';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  LoaderWithOverlay,
  MenuItem,
  MoneyField,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CreditAssessmentUnderwritingCasePayload,
  OrganizationAccountType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getMoneyObject } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface FormValues
  extends Omit<
    CreditAssessmentUnderwritingCasePayload,
    'expectedMonthlySpend' | 'requestedLimit'
  > {
  expectedMonthlySpend: number | null;
  requestedLimit: number | null;
}

const EditUnderwritingCaseDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { defaultCardAccount, organization, organizationsStaticData },
  } = useGlobalState();
  const {
    actions: { setUnderwritingCase },
    state: { creditAssessment, isNonCustomer },
  } = useCreditAndComplianceContext();
  const underwritingCase = creditAssessment!.underwritingCase;
  const [showDiscrepancyReasons, setShowDiscrepancyReasons] = useState(
    !underwritingCase.availableUnderwritingCases.find(
      (x) => x.name === underwritingCase.selectedUnderwritingCase.value
    )?.available
  );

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      discrepancyReason: underwritingCase.discrepancyReason.value ?? '',
      discrepancyReasonDescription:
        underwritingCase.discrepancyReasonDescription.value ?? '',
      expectedMonthlySpend:
        typeof underwritingCase.expectedMonthlySpend.value?.value === 'number'
          ? underwritingCase.expectedMonthlySpend.value.value / 100
          : null,
      requestedLimit:
        typeof underwritingCase.requestedLimit.value?.value === 'number'
          ? underwritingCase.requestedLimit.value.value / 100
          : null,
      requestedPaymentFrequency:
        underwritingCase.requestedPaymentFrequency.value ?? '',
      requestedPaymentTerms: underwritingCase.requestedPaymentTerms.value ?? '',
      selectedUnderwritingCase:
        underwritingCase.selectedUnderwritingCase.value ?? '',
    },
    validate: (values) => {
      const errors: { [k in keyof FormValues]?: string } = {};

      if (
        values.selectedUnderwritingCase &&
        !underwritingCase.availableUnderwritingCases.find(
          (x) => x.name === values.selectedUnderwritingCase
        )?.available &&
        !values.discrepancyReason
      ) {
        errors['discrepancyReason'] = t('errors.fieldRequired');
      }

      if (
        values.discrepancyReason === 'OTHER' &&
        !values.discrepancyReasonDescription
      ) {
        errors['discrepancyReasonDescription'] = t('errors.fieldRequired');
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const payload = {
          ...(Object.entries(values).reduce(
            (cum, [key, value]) => ({
              ...cum,
              [key]: value !== '' ? value : null,
            }),
            {}
          ) as FormValues),
          expectedMonthlySpend:
            typeof values.expectedMonthlySpend === 'number'
              ? getMoneyObject(values.expectedMonthlySpend * 100)
              : null,
          requestedLimit:
            typeof values.requestedLimit === 'number'
              ? getMoneyObject(values.requestedLimit * 100)
              : null,
        };

        const updatedUnderwritingCase = await api.updateCreditAssessmentUnderwritingCase(
          organization!.id,
          payload
        );
        setUnderwritingCase(updatedUnderwritingCase);
        if (!mounted) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const getCustomFieldProps = <T extends keyof FormValues>(name: T) => {
    return {
      ...(formik.errors[name] && {
        error: true,
        helperText: formik.errors[name],
      }),
      name,
      value: formik.values[name],
    };
  };
  const onChange = <T extends keyof FormValues>(
    name: T,
    value: FormValues[T]
  ) => {
    if (formik.errors[name]) {
      formik.setFieldError(name, undefined);
    }
    formik.setFieldValue(name, value);
  };

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('int.editUnderwritingCaseDialog.title')}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MoneyField
              {...getCustomFieldProps('expectedMonthlySpend')}
              onValueChange={({ floatValue = null }) => {
                formik.setFieldValue('expectedMonthlySpend', floatValue);
              }}
              label={t('int.editUnderwritingCaseDialog.expectedMonthlySpend')}
            />
          </Grid>
          <Grid item xs={6}>
            <MoneyField
              {...getCustomFieldProps('requestedLimit')}
              onValueChange={({ floatValue = null }) => {
                formik.setFieldValue('requestedLimit', floatValue);
              }}
              label={t('int.editUnderwritingCaseDialog.requestedLimit')}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={!!formik.errors.requestedPaymentFrequency}
            >
              <InputLabel>
                {t('int.editUnderwritingCaseDialog.requestedFrequency')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('requestedPaymentFrequency'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('requestedPaymentFrequency', event.target.value)
                }
              >
                {organizationsStaticData!.paymentFrequencies.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.requestedPaymentFrequency}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={!!formik.errors.requestedPaymentTerms}
            >
              <InputLabel>
                {t('int.editUnderwritingCaseDialog.requestedPaymentTerms')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('requestedPaymentTerms'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('requestedPaymentTerms', event.target.value)
                }
              >
                {organizationsStaticData!.paymentTerms.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.requestedPaymentTerms}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item width="100%">
            <Box
              borderTop={(theme) => `1px solid ${theme.palette.divider}`}
              marginTop={1}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <RadioGroup
                {...getCustomFieldProps('selectedUnderwritingCase')}
                onChange={(_, value) => {
                  onChange('selectedUnderwritingCase', value);

                  const caseAvailable = !!underwritingCase.availableUnderwritingCases.find(
                    (x) => x.name === value
                  )?.available;
                  if (caseAvailable) {
                    setShowDiscrepancyReasons(false);
                    formik.setFieldValue('discrepancyReason', '');
                    formik.setFieldValue('discrepancyReasonDescription', '');
                  } else {
                    setShowDiscrepancyReasons(true);
                  }
                }}
                sx={{ position: 'relative' }}
              >
                <>
                  <FormHelperText sx={{ position: 'absolute', right: 0 }}>
                    <Link
                      component="button"
                      disabled={!formik.values.selectedUnderwritingCase}
                      onClick={() => {
                        formik.setFieldValue('selectedUnderwritingCase', '');
                        if (formik.values.discrepancyReason) {
                          formik.setFieldValue('discrepancyReason', '');
                        }
                        if (formik.values.discrepancyReasonDescription) {
                          formik.setFieldValue(
                            'discrepancyReasonDescription',
                            ''
                          );
                        }
                      }}
                    >
                      {t('common.button.clear')}
                    </Link>
                  </FormHelperText>
                  {underwritingCase.availableUnderwritingCases.map((item) => (
                    <FormControlLabel
                      key={item.name}
                      value={item.name}
                      control={<Radio />}
                      disabled={
                        !isNonCustomer &&
                        defaultCardAccount!.accountType.value ===
                          OrganizationAccountType.prefunded &&
                        item.name !== 'EXCESS_CASH_CASE'
                      }
                      label={
                        <>
                          <Typography
                            component="span"
                            color={
                              item.available ? 'textPrimary' : 'textSecondary'
                            }
                            sx={{
                              ...(!item.available && {
                                textDecoration: 'line-through',
                              }),
                            }}
                          >
                            {organizationsStaticData!.underwritingCaseTypes.find(
                              (x) => x.name === item.name
                            )?.label ?? ''}
                          </Typography>
                          {item.unavailableReasons.length > 0 && (
                            <Typography
                              color="textSecondary"
                              component="span"
                              variant="body2"
                            >
                              {` (${item.unavailableReasons.join(', ')})`}
                            </Typography>
                          )}
                        </>
                      }
                      sx={{
                        color: item.available
                          ? 'text.primary'
                          : 'text.secondary',
                      }}
                    />
                  ))}
                </>
              </RadioGroup>
              <FormHelperText>
                {formik.errors.selectedUnderwritingCase}
              </FormHelperText>
            </FormControl>
          </Grid>

          {showDiscrepancyReasons && (
            <>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  error={!!formik.errors.discrepancyReason}
                >
                  <InputLabel>
                    {t('int.editUnderwritingCaseDialog.discrepancyReason')}
                  </InputLabel>
                  <Select
                    {...omit(
                      getCustomFieldProps('discrepancyReason'),
                      'helperText'
                    )}
                    onChange={(event) =>
                      onChange('discrepancyReason', event.target.value)
                    }
                  >
                    {organizationsStaticData!.discrepancyReasons.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.errors.discrepancyReason}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} />

              {formik.values.discrepancyReason === 'OTHER' && (
                <Grid item xs={12}>
                  <TextField
                    minRows={3}
                    multiline
                    {...getCustomFieldProps('discrepancyReasonDescription')}
                    onChange={(event) =>
                      onChange(
                        'discrepancyReasonDescription',
                        event.target.value
                      )
                    }
                    label={t(
                      'int.editUnderwritingCaseDialog.discrepancyReasonDescription'
                    )}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={formik.isSubmitting} onClick={formik.submitForm}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditUnderwritingCaseDialog);
