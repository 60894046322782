import { FormEvent, ReactNode, useMemo, useState } from 'react';
import { isAxiosError } from 'axios';
import { uniq } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { OrganizationToHubspotLinkField } from 'domains/organization/components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { NetworkErrorCode, OrganizationStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';

interface Props extends DialogProps {
  existingHubspotId: string | null;
  label: ReactNode;
  onSuccess: (hubspotId: string) => void;
  onClose: () => void;
}

const OrganizationToHubspotLinkDialog = ({
  existingHubspotId,
  label,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const org = useMemo(() => organization!, []);

  const [state, setState] = useState({
    hubspotId: '',
    isLoading: false,
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const response = await api.updateHubspotId(
        org.id,
        state.hubspotId || null
      );
      if (!mounted.current) return;
      onSuccess(response.hubspotId);
    } catch (error) {
      if (!mounted.current) return;
      if (
        getNetworkErrorCode(error) ===
        NetworkErrorCode.organizationIdAlreadyExists
      ) {
        enqueueSnackbar(
          (isAxiosError(error) && error.response?.data?.message) || '',
          {
            variant: 'error',
          }
        );
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{label}</DialogTitle>

      <DialogContent>
        <form onSubmit={onSubmit} id="hubspot-link-form" noValidate>
          <OrganizationToHubspotLinkField
            initialHsIdOfExistingOrg={existingHubspotId ?? ''}
            onSuccess={(hsId: string) => {
              setState((prevState) => ({
                ...prevState,
                hubspotId: hsId,
              }));
            }}
            orgId={org.id}
            validHsOrgNames={uniq([
              org.name,
              org.name.replace(new RegExp(`${org.legalForm}$`, 'i'), '').trim(),
            ])}
            resetOnChange={() => {
              if (state.hubspotId) {
                setState((prevState) => ({
                  ...prevState,
                  hubspotId: '',
                }));
              }
            }}
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          disabled={state.isLoading}
          onClick={props.onClose}
        >
          {t('common.button.cancel')}
        </Button>

        <Button
          type="submit"
          form="hubspot-link-form"
          disabled={
            state.isLoading ||
            (![
              OrganizationStatus.cancelled,
              OrganizationStatus.deactivated,
            ].includes(organization!.status) &&
              !state.hubspotId)
          }
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(OrganizationToHubspotLinkDialog);
