import FormatMoney from 'components/FormatMoney';
import { LinearProgress, Typography } from 'elements';
import { Money } from 'services/constants';
import { getLimitPercentage } from 'services/utils';

interface Props {
  availableLimit: Money;
  limit: Money;
}

const BalanceLimit = ({ availableLimit, limit }: Props) => {
  const { limitPercentage, isPercentageLow } = getLimitPercentage({
    availableLimit,
    limit,
  });

  return (
    <>
      <Typography variant="body2" my={1}>
        <Typography
          variant="inherit"
          component="span"
          color={isPercentageLow ? 'warning.main' : 'inherit'}
        >
          <FormatMoney value={availableLimit} />
        </Typography>{' '}
        / <FormatMoney value={limit} />
      </Typography>
      <LinearProgress
        value={limitPercentage}
        color={isPercentageLow ? 'warning' : 'primary'}
        size="small"
      />
    </>
  );
};

export default BalanceLimit;
