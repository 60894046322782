import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { CustomFieldOptionsAutocomplete } from 'domains/card/components';
import { isTxCustomFieldVisible } from 'domains/transaction/utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AutomationType,
  Card,
  CardCustomFieldItem,
  CUSTOM_FIELD,
  CustomField,
  CustomFieldOption,
  CustomFieldStatus,
  CustomFieldType,
  DEFAULT_PAGE_LIMIT,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface HashMapCustomFields {
  [id: string]: Omit<CardCustomFieldItem, 'transactionCustomFieldId'>;
}

interface FormValues {
  // "customFields" array will have values of mixed types.
  // When type of custom field is text, it will be string,
  // but when type is select, it might be CustomFieldOption | null.
  customFields: (CustomFieldOption | string | null)[];
}

interface Props extends DialogProps {
  card: Card;
  onClose: () => void;
  onSuccess: (card: Card) => void;
}

interface State {
  cardsCardTxCustomFields: (CustomField & {
    cardTransactionCustomFieldId?: string;
  })[];
  isLoading: boolean;
}

const EditCardCustomFieldsDialog = ({ card, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { isAdminApp } = useCurrentApp();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    cardsCardTxCustomFields: [],
    isLoading: true,
  });

  const getData = async () => {
    try {
      const { transactionCustomFields } = await api.getCustomFields({
        organizationId: organization!.id,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
      });

      dispatch({
        type: 'SET_CUSTOM_FIELDS',
        payload: {
          transactionCustomFields,
        },
      });

      if (!mounted.current) return;

      const shownCustomFields = transactionCustomFields.filter(
        (field) =>
          field.status === CustomFieldStatus.active &&
          field.automationType === AutomationType.card &&
          isTxCustomFieldVisible(field, isAdminApp)
      );
      // add card custom fields hash map for easy mapping
      const hashMapCardCustomFields: HashMapCustomFields = card.cardTxnCustomFields.reduce(
        (result, field) => {
          const { transactionCustomFieldId, ...props } = field;
          result[transactionCustomFieldId] = props;
          return result;
        },
        {} as HashMapCustomFields
      );
      // override default values of custom fields if card custom fields exist
      const cardsCardTxCustomFields = shownCustomFields.map((field) => {
        if (hashMapCardCustomFields[field.id]) {
          return {
            ...field,
            defaultValue:
              hashMapCardCustomFields[field.id].defaultValueForCard || '',
            // fill object to match CustomFieldOption type. The filled fields don't have any impact on functionality
            defaultOption: hashMapCardCustomFields[field.id].customFieldOption
              ? {
                  source: 'PLIANT',
                  externalReference: null,
                  ...hashMapCardCustomFields[field.id].customFieldOption,
                }
              : null,
            cardTransactionCustomFieldId:
              hashMapCardCustomFields[field.id].cardTransactionCustomFieldId,
          };
        }
        return { ...field, defaultValue: '', defaultOption: null };
      }) as State['cardsCardTxCustomFields'];

      setState((prevState) => ({
        ...prevState,
        cardsCardTxCustomFields,
        isLoading: true,
      }));
      setState((prevState) => ({ ...prevState, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      customFields: state.cardsCardTxCustomFields.map((field) => {
        if (field.type === CustomFieldType.select) return field.defaultOption;
        return field.defaultValue || '';
      }),
    },
    validate: (values) => {
      const customFieldsErrors: string[] = [];
      if (values.customFields.length) {
        values.customFields.forEach((customFieldEntity, index) => {
          const customFieldObject = state.cardsCardTxCustomFields[index];
          if (
            customFieldObject.mandatoryOnAutomation &&
            ((customFieldObject.type === CustomFieldType.text &&
              !(customFieldEntity as string).trim()) ||
              (customFieldObject.type === CustomFieldType.select &&
                !(customFieldEntity as CustomFieldOption | null)))
          ) {
            customFieldsErrors[index] = t(
              'editCardCustomFieldsDialog.customFieldError'
            );
          }
        });
      }

      return customFieldsErrors.length
        ? { customFields: customFieldsErrors }
        : {};
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const {
          cardTransactionCustomFieldResponses,
        } = await api.updateCardsCardTxCustomFields({
          cardTransactionCustomFieldRequests: values.customFields.map(
            (customField, index) => ({
              cardId: card.cardId,
              cardTransactionCustomFieldId:
                state.cardsCardTxCustomFields[index]
                  .cardTransactionCustomFieldId,
              organizationId:
                state.cardsCardTxCustomFields[index].organizationId,
              transactionCustomFieldId: state.cardsCardTxCustomFields[index].id,
              defaultValueForCard:
                typeof customField === 'string' ? customField : '',
              customFieldOptionId:
                typeof customField !== 'string' && customField
                  ? customField.id
                  : null,
            })
          ),
        });

        if (!mounted.current) return;

        onSuccess({
          ...card,
          cardTxnCustomFields: cardTransactionCustomFieldResponses.map(
            (cardField) => ({
              defaultValueForCard: cardField.defaultValueForCard,
              label: cardField.transactionCustomFieldLabel,
              transactionCustomFieldId: cardField.transactionCustomFieldId,
              cardTransactionCustomFieldId:
                cardField.cardTransactionCustomFieldId,
              customFieldType: cardField.customFieldType,
              customFieldOption: cardField.customFieldOption,
            })
          ) as Card['cardTxnCustomFields'],
        });
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('editCardCustomFieldsDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="edit-card-custom-fields-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            {formik.values.customFields.map((_, index) => {
              const customFieldObject = state.cardsCardTxCustomFields[index];

              if (
                !customFieldObject.type ||
                ![CustomFieldType.text, CustomFieldType.select].includes(
                  customFieldObject.type
                )
              )
                return null;

              return (
                <Grid item xs={12} key={customFieldObject.id}>
                  {customFieldObject.type === CustomFieldType.text && (
                    <TextField
                      {...formik.getFieldProps(`customFields[${index}]`)}
                      label={`${customFieldObject.label}${
                        customFieldObject.mandatoryOnAutomation
                          ? ` (${t('editCardCustomFieldsDialog.mandatory')})`
                          : ''
                      }`}
                      multiline
                      maxRows={2}
                      inputProps={{ maxLength: CUSTOM_FIELD.valueMaxLength }}
                      error={!!formik.errors.customFields?.[index]}
                      helperText={formik.errors.customFields?.[index]}
                    />
                  )}

                  {customFieldObject.type === CustomFieldType.select && (
                    <CustomFieldOptionsAutocomplete
                      customFieldObject={customFieldObject}
                      value={
                        formik.values.customFields[
                          index
                        ] as CustomFieldOption | null
                      }
                      componentKey={'editCardCustomFieldsDialog'}
                      error={formik.errors.customFields?.[index]}
                      onChange={(option) => {
                        formik.setFieldValue(`customFields.${[index]}`, option);
                        formik.setFieldError(`customFields.${[index]}`, '');
                      }}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          type="submit"
          disabled={formik.isSubmitting || state.isLoading || !formik.dirty}
          form="edit-card-custom-fields-form"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting || state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditCardCustomFieldsDialog);
