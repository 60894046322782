import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { internalRootPaths } from 'components/App';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { BillingAccountsApprovalDetailsPage } from 'domains/billing/pages';
import {
  CheckCircleIcon,
  DataGrid,
  LoaderWithOverlay,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import {
  PageFilters,
  PageHeader,
  PageSearchInput,
  PageTableContent,
  PageTitle,
  Tab,
  Tabs,
} from 'layout';
import {
  BankAccountTransfersAllowedStatus,
  BillPaymentsBankAccount,
  DEFAULT_PAGE_LIMIT,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { OnBillPaymentsBankAccountUpdatedRef } from './StatusCell';
import useColumns from './useColumns';

const getQueryParams = (qs: string) => {
  const { q } = Object.fromEntries(new URLSearchParams(qs).entries());

  return {
    q: q?.trim() || '',
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

interface State {
  isLoading: boolean;
  bankAccounts: BillPaymentsBankAccount[];
  hasNextPage: boolean;
}

const BillingAccountsApprovalPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const setQueryParam = useSetQueryParam();
  const onItemUpdatedRef: OnBillPaymentsBankAccountUpdatedRef = useRef(() =>
    Promise.resolve()
  );
  const columns = useColumns(onItemUpdatedRef);
  const api = useImperativeApi();
  const mounted = useMounted();
  const showPageError = useShowPageError();
  const pageRef = useRef(0);
  const paramsRef = useRef(getQueryParams(location.search));
  const dataGridRef = useGridApiRef();
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:bankAccountId',
    true
  );
  const [state, setState] = useState<State>({
    isLoading: true,
    bankAccounts: [],
    hasNextPage: false,
  });

  const areFiltersApplied = !!paramsRef.current.q.length;
  const isEmptyState =
    !state.isLoading && !state.bankAccounts.length && !areFiltersApplied;

  onItemUpdatedRef.current = async (id: string) => {
    const bankAccount = await api.getBillPaymentsBankAccount(id);
    if (!mounted.current) return;
    setState((prevState) => ({
      ...prevState,
      bankAccounts: prevState.bankAccounts.map((item) =>
        item.id === id ? bankAccount : item
      ),
    }));
  };

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      if (!isLoadMore) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
      }
      const {
        bankAccounts,
        hasNextPage,
      } = await api.getBillPaymentsBankAccounts({
        page,
        limit,
        detectedAsTransferSender: true,
        q: paramsRef.current.q.trim() || undefined,
        transfersAllowedStatuses:
          path === internalRootPaths.billingAccountsApprovalsReview
            ? BankAccountTransfersAllowedStatus.review
            : undefined,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        bankAccounts: isLoadMore
          ? [...prevState.bankAccounts, ...bankAccounts]
          : bankAccounts,
        hasNextPage,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });
    paramsRef.current = getQueryParams(location.search);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const onBillingAccountUpdate = (bankAccount: BillPaymentsBankAccount) => {
    setState((prevState) => ({
      ...prevState,
      bankAccounts: prevState.bankAccounts.map((item) =>
        item.id === bankAccount.id ? bankAccount : item
      ),
    }));
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('int.billingAccountsApprovalPage.title')} />
        <Tabs value={path}>
          <Tab
            component={Link}
            value={internalRootPaths.billingAccountsApprovalsAll}
            to={internalRootPaths.billingAccountsApprovalsAll}
            label={t('int.billingAccountsApprovalPage.allBillingAccounts')}
          />
          <Tab
            component={Link}
            value={internalRootPaths.billingAccountsApprovalsReview}
            to={internalRootPaths.billingAccountsApprovalsReview}
            label={t('int.billingAccountsApprovalPage.needsReview')}
          />
        </Tabs>

        <PageFilters>
          <PageSearchInput
            initialValue={paramsRef.current.q}
            onChange={(value) => setQueryParam('q', value)}
            disabled={isEmptyState}
          />
        </PageFilters>
      </PageHeader>
      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />
        <DataGrid<BillPaymentsBankAccount>
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          apiRef={dataGridRef}
          rowSelectionModel={
            detailsParams?.bankAccountId ? [detailsParams.bankAccountId] : []
          }
          loading={state.isLoading}
          rows={state.bankAccounts}
          columns={columns}
          columnVisibilityModel={{
            transfersAllowedStatus: !isDetailsPageOpen,
            drawerPlaceholder: isDetailsPageOpen,
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ id }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${id}${location.search}`);
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.bankAccounts.length && areFiltersApplied) {
                return <NothingFound />;
              }
              return (
                <NoData
                  isNewDesign
                  Icon={CheckCircleIcon}
                  label={t('int.billingAccountsApprovalPage.noData')}
                />
              );
            },
            loadingOverlay: () => null,
          }}
          rowHeight={72}
        />
        <Route
          path={`${path}/:bankAccountId`}
          children={({ match }) => (
            <BillingAccountsApprovalDetailsPage
              open={!!match}
              onUpdate={onBillingAccountUpdate}
            />
          )}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(BillingAccountsApprovalPage, {
  permission: 'billing-accounts-approval-page:visit',
});
