import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import ReceiptInboxThumbnail from 'domains/transaction/components/ReceiptInboxThumbnail';
import { Box, IconButton, Typography, XIcon } from 'elements';
import { PageToast } from 'layout';
import { ReceiptInboxMatchingFlow } from 'services/constants';

interface Props {
  isDetailsPageOpen: boolean;
}

// this component is visible only when a 'SELECTED_RECEIPT_MATCHING' flow
// is triggered from Receipt Inbox Dialog (user clicks Match button)
const ReceiptInboxMatchToast = ({ isDetailsPageOpen }: Props) => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: {
      receiptInbox: { flow, receipt, thumbnail },
    },
  } = useGlobalState();

  if (flow !== ReceiptInboxMatchingFlow.selectedReceiptMatching || !receipt)
    return null;

  return (
    <>
      <PageToast width="md" isDetailsPageOpen={isDetailsPageOpen}>
        <Box position="relative" pr={3.5} width="100%">
          <IconButton
            onClick={() => {
              dispatch({ type: 'RESET_RECEIPT_INBOX_DATA' });
              dispatch({ type: 'TOGGLE_SIDEBAR', payload: true });
            }}
            size="small"
            sx={{ position: 'absolute', right: 0, top: 0.5 }}
          >
            <XIcon fontSize="small" />
          </IconButton>

          <Typography variant="caption">
            {t('receiptMatchingToast.title')}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" mt={1} pr={4.5} width="100%">
          <ReceiptInboxThumbnail
            hoverEnabled={false}
            width={(theme) => theme.spacing(10)}
            height={(theme) => theme.spacing(6)}
            status={receipt.status}
            thumbnail={thumbnail}
          />

          <Box ml={2} flex={1} overflow="hidden">
            <Typography variant="caption" component="div" noWrap>
              {t('receiptMatchingToast.merchantLabel')}
            </Typography>
            <Typography variant="body2" noWrap>
              {receipt.ocr?.merchantName || '-'}
            </Typography>
          </Box>
          <Box ml={2} flex={1} overflow="hidden">
            <Typography variant="caption" component="div" noWrap>
              {t('receiptMatchingToast.receiptReceivedDateLabel')}
            </Typography>
            <Typography variant="body2" noWrap>
              {receipt.createdAt
                ? moment(receipt.createdAt).format('DD.MM.YYYY, HH:mm')
                : '-'}
            </Typography>
          </Box>
          <Box ml={2} flex={1} overflow="hidden">
            <Typography variant="caption" component="div" noWrap>
              {t('receiptMatchingToast.receiptDateLabel')}
            </Typography>
            <Typography variant="body2" noWrap>
              {receipt.ocr?.receiptDate
                ? moment(receipt.ocr.receiptDate).format('DD.MM.YYYY, HH:mm')
                : '-'}
            </Typography>
          </Box>
          <Box ml={2} textAlign="right" flex={1} overflow="hidden">
            <Typography variant="caption" component="div" noWrap>
              {t('receiptMatchingToast.amountLabel')}
            </Typography>
            <Typography variant="subtitle2" component="div" noWrap>
              {receipt.ocr?.amount ? (
                <FormatMoney value={receipt.ocr.amount} fractionalPart />
              ) : (
                '-'
              )}
            </Typography>
          </Box>
        </Box>
      </PageToast>
    </>
  );
};

export default ReceiptInboxMatchToast;
