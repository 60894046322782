import { useEffect, useState } from 'react';
import {
  Box,
  CaretDownIcon,
  FlaskIcon,
  IconButton,
  Popover,
  Typography,
} from 'elements';
import LanguageSelect from './LanguageSelect';
import ThemeSelect from './ThemeSelect';

const QaTools = () => {
  const [isVisible, setIsVisible] = useState();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    //@ts-ignore
    window.qa = () => setIsVisible(true);
  }, []);

  return isVisible ? (
    <>
      <IconButton
        size="large"
        color="primary-contained"
        onClick={(e) =>
          setAnchorEl((prevState) => (prevState ? null : e.currentTarget))
        }
        sx={{ position: 'fixed', zIndex: 10000, right: 20, bottom: 83 }}
      >
        {!!anchorEl ? <CaretDownIcon /> : <FlaskIcon />}
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 380, p: 3, mt: -1.5 },
        }}
      >
        <Typography variant="subtitle1" mt={-0.5} mb={2}>
          Pliant QA tools
        </Typography>
        <Box mb={2}>
          <ThemeSelect />
        </Box>
        <Box mb={2}>
          <LanguageSelect />
        </Box>
      </Popover>
    </>
  ) : null;
};

export default QaTools;
