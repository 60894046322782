import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import WidgetError from 'components/WidgetError';
import {
  CashbackRedemptionStatusBadge,
  DownloadMt940Button,
  DownloadPainFileButton,
  ResubmitPaymentFileButton,
} from 'domains/billing/components';
import {
  ActionBox,
  ActionBoxActions,
  ActionBoxTitle,
  Box,
  Button,
  Chip,
  LoaderWithOverlay,
  Paper,
  Stack,
  Tooltip,
  Typography,
  WarningCircleIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  CashbackRedemption,
  CashbackRedemptionBatch,
  CashbackRedemptionBatchState,
  CashbackRedemptionStatus,
  cashbackRedemptionStatuses,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import CashbackRedemptions from './CashbackRedemptions';

interface State {
  isLoading: boolean;
  cashbackRedemptionBatch: CashbackRedemptionBatch | null;
  redemptions: CashbackRedemption[] | null;
  error: unknown;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (batch: CashbackRedemptionBatch) => void;
}

const CashbackPaymentBatchDetailsPage = ({ onUpdate, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const { batchId } = useParams<{ batchId: string }>();
  const mounted = useMounted();
  const idRef = useRef(batchId);
  const [state, setState] = useState<State>({
    isLoading: true,
    cashbackRedemptionBatch: null,
    redemptions: null,
    error: null,
  });
  const paymentExportId = useMemo(() => {
    if (!state.redemptions?.length) return null;
    return (
      state.redemptions.find(
        (item) =>
          !!item.paymentExportId &&
          [
            CashbackRedemptionStatus.redeemed,
            CashbackRedemptionStatus.failed,
          ].includes(item.state)
      )?.paymentExportId || null
    );
  }, [state.redemptions]);

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [cashbackRedemptionBatch, redemptions] = await Promise.all([
        api.getCashbackRedemptionBatch(batchId),
        api.getCashbackRedemptions(batchId).then(
          (data) => data.redemptions,
          () => null
        ),
      ]);
      if (!mounted.current || batchId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        cashbackRedemptionBatch,
        redemptions,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || batchId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        error,
        cashbackRedemptionBatch: null,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!batchId) return;
    idRef.current = batchId;
    getData();
  }, [batchId]);

  const canSubmit =
    state.cashbackRedemptionBatch?.batchState ===
    CashbackRedemptionBatchState.submit;

  const onSubmit = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const cashbackRedemptionBatch = await api.submitCashbackRedemptionBatch(
        batchId
      );
      if (!mounted.current || batchId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        cashbackRedemptionBatch,
        isLoading: false,
      }));
      onUpdate(cashbackRedemptionBatch);
    } catch (error) {
      if (!mounted.current || batchId !== idRef.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <DetailsDrawer {...props}>
        {state.cashbackRedemptionBatch && (
          <>
            <DetailsDrawerHeader>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="h5" mr="auto">
                  {moment(state.cashbackRedemptionBatch.paymentDate).format(
                    'YYYY-MM-DD'
                  )}
                </Typography>
                {paymentExportId && (
                  <DownloadMt940Button paymentId={paymentExportId} />
                )}
                {state.cashbackRedemptionBatch.paymentFileName && (
                  <DownloadPainFileButton
                    painFileName={state.cashbackRedemptionBatch.paymentFileName}
                  />
                )}
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                flexWrap="wrap"
                spacing={1}
                useFlexGap
              >
                <Chip
                  label={t(
                    `int.accountGroupSelect.items.${state.cashbackRedemptionBatch.accountGroup}`
                  )}
                  size="small"
                />
                <Chip
                  label={state.cashbackRedemptionBatch.currency}
                  size="small"
                />
              </Stack>
              {state.cashbackRedemptionBatch.paymentFileName &&
                !!state.cashbackRedemptionBatch.states.PENDING &&
                moment().diff(
                  state.cashbackRedemptionBatch.createdAt,
                  'days'
                ) <= 5 && (
                  <Box pt={2}>
                    <ResubmitPaymentFileButton
                      fileName={state.cashbackRedemptionBatch.paymentFileName}
                      onSuccess={getData}
                    />
                  </Box>
                )}
            </DetailsDrawerHeader>
            <DetailsDrawerContent>
              {canSubmit && (
                <ActionBox
                  icon={<WarningCircleIcon fontSize="inherit" />}
                  sx={{
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <ActionBoxTitle>
                    {t('int.cashbackPaymentBatchDetailsPage.submitTitle')}
                  </ActionBoxTitle>
                  <ActionBoxActions>
                    <Button disabled={state.isLoading} onClick={onSubmit}>
                      {t('int.cashbackPaymentBatchDetailsPage.submit')}
                    </Button>
                  </ActionBoxActions>
                </ActionBox>
              )}

              <Box
                sx={{ pt: 4, px: 3, pb: 3 }}
                borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
              >
                <Paper variant="outlined" sx={{ py: 1.5, px: 2.5 }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1">
                      {t('int.cashbackPaymentBatchDetailsPage.count')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {state.cashbackRedemptionBatch.count}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1.5}
                  >
                    <Typography variant="body1">
                      {t('int.cashbackPaymentBatchDetailsPage.batchInitiated')}
                    </Typography>
                    <Tooltip
                      title={t(
                        'int.cashbackPaymentBatchDetailsPage.berlinTime'
                      )}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {moment(state.cashbackRedemptionBatch.createdAt).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )}
                      </Typography>
                    </Tooltip>
                  </Box>

                  {state.cashbackRedemptionBatch.paymentFileCreatedAt && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={1.5}
                    >
                      <Typography variant="body1">
                        {t(
                          'int.cashbackPaymentBatchDetailsPage.paymentFileCreated'
                        )}
                      </Typography>
                      <Tooltip
                        title={t(
                          'int.cashbackPaymentBatchDetailsPage.berlinTime'
                        )}
                      >
                        <Typography variant="body2" color="text.secondary">
                          {moment(
                            state.cashbackRedemptionBatch.paymentFileCreatedAt
                          ).format('YYYY-MM-DD HH:mm:ss')}
                        </Typography>
                      </Tooltip>
                    </Box>
                  )}

                  {state.cashbackRedemptionBatch.submittedAt && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={1.5}
                    >
                      <Typography variant="body1">
                        {t('int.cashbackPaymentBatchDetailsPage.submitted')}
                      </Typography>
                      <Tooltip
                        title={t(
                          'int.cashbackPaymentBatchDetailsPage.berlinTime'
                        )}
                      >
                        <Typography variant="body2" color="text.secondary">
                          {moment(
                            state.cashbackRedemptionBatch.submittedAt
                          ).format('YYYY-MM-DD HH:mm:ss')}
                        </Typography>
                      </Tooltip>
                    </Box>
                  )}
                </Paper>
              </Box>

              <Box
                p={3}
                borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
              >
                <Typography variant="overline" component="div">
                  {t('int.cashbackPaymentBatchDetailsPage.state')}
                </Typography>
                <Paper variant="outlined" sx={{ py: 1.5, px: 2.5 }}>
                  <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                    {cashbackRedemptionStatuses
                      .filter(
                        (status) =>
                          !!state.cashbackRedemptionBatch?.states[status]
                      )
                      .map((status) => (
                        <CashbackRedemptionStatusBadge
                          key={status}
                          status={status}
                          count={state.cashbackRedemptionBatch!.states[status]}
                        />
                      ))}
                  </Stack>
                </Paper>
              </Box>

              <CashbackRedemptions
                onRetry={getData}
                redemptions={state.redemptions}
              />
            </DetailsDrawerContent>
          </>
        )}
        {state.error && <WidgetError onReload={getData} />}
        <LoaderWithOverlay loading={state.isLoading} />
      </DetailsDrawer>
    </>
  );
};

export default withDetailsDrawerWrapper(CashbackPaymentBatchDetailsPage);
