import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WidgetError from 'components/WidgetError';
import { ReceiptInboxStatusBadge } from 'domains/organization/components';
import {
  Box,
  IconButton,
  Link,
  LoaderWithOverlay,
  Typography,
  XIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import {
  DEFAULT_PAGE_LIMIT,
  ReceiptAutoMatchingDetails,
  ReceiptsAutoMatchingSameEmail,
  Transaction,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import DateTitle from './DateTitle';
import OtherFilesDialog from './OtherFilesDialog';
import ReceiptActionBlock from './ReceiptActionBlock';
import ReceiptInformationBlock from './ReceiptInformationBlock';
import ReceiptPreview from './ReceiptPreview';
import ReceiptThumbnailBlock from './ReceiptThumbnailBlock';
import SenderInfoBlock from './SenderInfoBlock';

export const DETAILS_WIDTH = 500;

interface Props {
  receiptId: string;
  isReceiptPreviewOpen: boolean;
  onClose: () => void;
  onReceiptPreviewToggle: (open: boolean) => void;
  onRefetch: (receiptId?: string) => void;
  onOtherReceiptSelect: (receipt: ReceiptsAutoMatchingSameEmail) => void;
}

interface State {
  receipt: ReceiptAutoMatchingDetails | null;
  thumbnail: string;
  transactions: Transaction[];
  isLoading: boolean;
  error: unknown;
}

const ReceiptInboxDetails = ({
  receiptId,
  isReceiptPreviewOpen,
  onClose,
  onReceiptPreviewToggle,
  onRefetch,
  onOtherReceiptSelect,
}: Props) => {
  const receiptIdRef = useRef(receiptId);
  const { t } = useTranslation();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const mounted = useMounted();
  const [state, setState] = useState<State>({
    receipt: null,
    thumbnail: '',
    transactions: [],
    isLoading: true,
    error: null,
  });
  const [isOtherFilesDialogOpen, setIsOtherFilesDialogOpen] = useState(false);

  const getData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        error: null,
      }));

      const [receipt, { thumbnails }] = await Promise.all([
        api.getReceiptAutoMatchingDetails(receiptId),
        api.getReceiptsAutoMatchingThumbnails(receiptId),
      ]);

      const { transactions } =
        canUser('receipt-inbox-matched-tx:view') &&
        receipt.matchedTransactions.length
          ? await api.getTransactionsBatch({
              transactionIds: receipt.matchedTransactions.join(','),
              page: 0,
              limit: DEFAULT_PAGE_LIMIT,
            })
          : { transactions: [] };

      if (!mounted.current || receiptId !== receiptIdRef.current) return;
      setState((prevState) => ({
        ...prevState,
        receipt,
        thumbnail: thumbnails?.[0].thumbnail || '',
        transactions,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || receiptId !== receiptIdRef.current) return;
      setState((prevState) => ({
        ...prevState,
        receipt: null,
        thumbnail: '',
        transactions: [],
        isLoading: false,
        error: error,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    receiptIdRef.current = receiptId;
    getData();
  }, [receiptId]);

  return (
    <>
      {isReceiptPreviewOpen && (
        <ReceiptPreview
          key={receiptId}
          id={receiptId}
          onClose={() => onReceiptPreviewToggle(false)}
        />
      )}

      <Box
        width={DETAILS_WIDTH}
        sx={{ borderLeft: 1, borderColor: 'divider' }}
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Box
          pt={3}
          px={3}
          display="flex"
          justifyContent="flex-end"
          sx={{
            ...(isReceiptPreviewOpen
              ? { visibility: 'hidden', pointerEvents: 'none' }
              : {}),
          }}
        >
          <IconButton onClick={onClose}>
            <XIcon />
          </IconButton>
        </Box>

        {state.receipt && (
          <>
            <Box
              px={3}
              pb={2}
              borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
            >
              <DateTitle createdAt={state.receipt.createdAt} />
              <ReceiptInboxStatusBadge status={state.receipt.status} />
            </Box>

            <Box flex={1} overflow="auto">
              <SenderInfoBlock receipt={state.receipt} />

              <ReceiptActionBlock
                receipt={state.receipt}
                thumbnail={state.thumbnail}
                transactions={state.transactions}
                onRefetch={onRefetch}
                onDetailsRefetch={getData}
              />

              <Box
                py={2}
                px={3}
                borderTop={(theme) => `1px solid ${theme.palette.divider}`}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Typography variant="overline" component="div" flexShrink={0}>
                    {t('receiptInboxDetails.receiptBlockTitle')}
                  </Typography>

                  {state.receipt.attachmentFileName && (
                    <Typography
                      variant="subtitle2"
                      ml={2}
                      component="div"
                      noWrap
                    >
                      {state.receipt.attachmentFileName}
                    </Typography>
                  )}
                </Box>

                <ReceiptThumbnailBlock
                  receipt={state.receipt}
                  thumbnail={state.thumbnail}
                  onPreviewOpen={() => onReceiptPreviewToggle(true)}
                />

                {state.receipt.otherFilesCount > 0 && (
                  <Box textAlign="right" mt={1}>
                    <Link
                      component="button"
                      onClick={() => setIsOtherFilesDialogOpen(true)}
                    >
                      <Typography variant="caption">
                        {t('receiptInboxDetails.otherFilesBtn', {
                          count: state.receipt.otherFilesCount,
                        })}
                      </Typography>
                    </Link>
                  </Box>
                )}

                <ReceiptInformationBlock receipt={state.receipt} />
              </Box>
            </Box>

            <OtherFilesDialog
              id={state.receipt.id}
              open={isOtherFilesDialogOpen}
              onOtherReceiptSelect={onOtherReceiptSelect}
              onClose={() => setIsOtherFilesDialogOpen(false)}
            />
          </>
        )}

        <LoaderWithOverlay loading={state.isLoading} />
        {state.error && <WidgetError onReload={getData} />}
      </Box>
    </>
  );
};

export default ReceiptInboxDetails;
