import React, { useState } from 'react';
import { isAxiosError } from 'axios';
import { toDecimal } from 'dinero.js';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { NumberFormatValues } from 'react-number-format';
import { useGlobalState } from 'context/GlobalState';
import { DataItemRow } from 'domains/creditAndCompliance/components';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  LoaderWithOverlay,
  MoneyField,
  Radio,
  RadioGroup,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { NetworkErrorCode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  getBooleanFromYesNo,
  getGenericErrorMsg,
  getNetworkErrorCode,
  getYesNoFromBoolean,
} from 'services/utils';

enum COLUMNS {
  limit = 'limit',
  maxLimit = 'maxLimit',
  peakLimit = 'peakLimit',
}

enum ROWS {
  extended = 'extended',
  full = 'full',
  limit = 'limit',
  standard = 'standard',
}

enum ERRORS {
  FIELD_REQUIRED = 'FIELD_REQUIRED',
  SUM_MISMATCH = 'SUM_MISMATCH',
}

const TABLE_LAYOUT = {
  columns: [COLUMNS.limit, COLUMNS.maxLimit, COLUMNS.peakLimit],
  rows: [ROWS.limit, ROWS.standard, ROWS.extended, ROWS.full],
};

type Group = { -readonly [k in keyof typeof ROWS]: number | null };

type FormValues = { -readonly [k in keyof typeof COLUMNS]: Group };

interface Props extends DialogProps {
  onClose: () => void;
  showAdditionalLimitFields: boolean;
}

const EditLimitSettingsDialog = ({
  showAdditionalLimitFields,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    actions: { setCreditAssessmentLimits },
    state: { creditAssessment },
  } = useCreditAndComplianceContext();
  const limits = creditAssessment!.limits;
  const [limitIncreaseAllowed, setLimitIncreaseAllowed] = useState(
    getYesNoFromBoolean(limits.limitIncreaseAllowed.value, t, {
      returnSlug: true,
    })
  );
  const limitCurrency = limits.limit.value.currency;
  const standardCollateralLimit = parseFloat(
    toDecimal(dineroFromMoney(limits.standardCollateralLimit))
  );

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      [COLUMNS.limit]: {
        [ROWS.limit]: parseFloat(
          toDecimal(dineroFromMoney(limits.limit.value))
        ),
        [ROWS.extended]: parseFloat(
          toDecimal(dineroFromMoney(limits.limitExtendedCollateral.value))
        ),
        [ROWS.full]: parseFloat(
          toDecimal(dineroFromMoney(limits.limitFullCollateral.value))
        ),
        [ROWS.standard]: parseFloat(
          toDecimal(dineroFromMoney(limits.limitStandardCollateral.value))
        ),
      },
      [COLUMNS.maxLimit]: {
        [ROWS.limit]: parseFloat(
          toDecimal(dineroFromMoney(limits.maxLimit.value))
        ),
        [ROWS.extended]: parseFloat(
          toDecimal(dineroFromMoney(limits.maxLimitExtendedCollateral.value))
        ),
        [ROWS.full]: parseFloat(
          toDecimal(dineroFromMoney(limits.maxLimitFullCollateral.value))
        ),
        [ROWS.standard]: parseFloat(
          toDecimal(dineroFromMoney(limits.maxLimitStandardCollateral.value))
        ),
      },
      [COLUMNS.peakLimit]: {
        [ROWS.limit]: parseFloat(
          toDecimal(dineroFromMoney(limits.peakLimit.value))
        ),
        [ROWS.extended]: parseFloat(
          toDecimal(dineroFromMoney(limits.peakLimitExtendedCollateral.value))
        ),
        [ROWS.full]: parseFloat(
          toDecimal(dineroFromMoney(limits.peakLimitFullCollateral.value))
        ),
        [ROWS.standard]: parseFloat(
          toDecimal(dineroFromMoney(limits.peakLimitStandardCollateral.value))
        ),
      },
    },
    validate: (values) => {
      const errors: {
        -readonly [k in keyof typeof COLUMNS]?: {
          -readonly [k in keyof typeof ROWS]?: string;
        };
      } = {};

      [
        ...(showAdditionalLimitFields
          ? [ROWS.limit, ROWS.standard, ROWS.extended, ROWS.full]
          : [ROWS.limit]),
      ].forEach((row) => {
        TABLE_LAYOUT.columns.forEach((column) => {
          if (typeof values[column][row] !== 'number') {
            if (!errors[column]) errors[column] = {};
            errors[column]![row] = ERRORS.FIELD_REQUIRED;
          }
        });
      });

      if (showAdditionalLimitFields) {
        TABLE_LAYOUT.columns.forEach((column) => {
          if (!errors[column]) {
            if (
              values[column].limit !==
              values[column].standard! +
                values[column].extended! +
                values[column].full!
            ) {
              if (!errors[column]) errors[column] = {};
              errors[column]!.limit = ERRORS.SUM_MISMATCH;
            }
          }
        });
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const payload = {
          limitIncreaseAllowed: getBooleanFromYesNo(limitIncreaseAllowed)!,

          limit: convertDineroToMoney(
            dineroFromFloat(values.limit.limit!, limitCurrency)
          ),
          maxLimit: convertDineroToMoney(
            dineroFromFloat(values.maxLimit.limit!, limitCurrency)
          ),
          peakLimit: convertDineroToMoney(
            dineroFromFloat(values.peakLimit.limit!, limitCurrency)
          ),
          ...(showAdditionalLimitFields && {
            limitExtendedCollateral: convertDineroToMoney(
              dineroFromFloat(values.limit.extended!, limitCurrency)
            ),
            limitFullCollateral: convertDineroToMoney(
              dineroFromFloat(values.limit.full!, limitCurrency)
            ),
            limitStandardCollateral: convertDineroToMoney(
              dineroFromFloat(values.limit.standard!, limitCurrency)
            ),
            maxLimitExtendedCollateral: convertDineroToMoney(
              dineroFromFloat(values.maxLimit.extended!, limitCurrency)
            ),
            maxLimitFullCollateral: convertDineroToMoney(
              dineroFromFloat(values.maxLimit.full!, limitCurrency)
            ),
            maxLimitStandardCollateral: convertDineroToMoney(
              dineroFromFloat(values.maxLimit.standard!, limitCurrency)
            ),
            peakLimitExtendedCollateral: convertDineroToMoney(
              dineroFromFloat(values.peakLimit.extended!, limitCurrency)
            ),
            peakLimitFullCollateral: convertDineroToMoney(
              dineroFromFloat(values.peakLimit.full!, limitCurrency)
            ),
            peakLimitStandardCollateral: convertDineroToMoney(
              dineroFromFloat(values.peakLimit.standard!, limitCurrency)
            ),
          }),
        };

        const updatedCreditAssessmentLimits = await api.updateCreditAssessmentLimits(
          organization!.id,
          payload
        );

        setCreditAssessmentLimits(updatedCreditAssessmentLimits);

        if (!mounted) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        if (
          getNetworkErrorCode(error) === NetworkErrorCode.incorrectLimitError &&
          isAxiosError(error) &&
          error.response?.data?.message
        ) {
          enqueueSnackbar(error.response.data.message, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    },
  });

  const onValueChange = (
    { floatValue }: NumberFormatValues,
    column: COLUMNS,
    row: ROWS
  ) => {
    if (formik.errors[column]?.[row]) {
      formik.setFieldError(`${column}.${row}`, undefined);
    }

    if (formik.errors[column]?.limit === ERRORS.SUM_MISMATCH) {
      formik.setFieldError(`${column}.limit`, undefined);
    }

    formik.setFieldValue(`${column}.${row}`, floatValue ?? null);
  };

  const isSubmitDisabled =
    formik.isSubmitting ||
    [
      ...Object.values(formik.errors?.limit ?? {}),
      ...Object.values(formik.errors?.maxLimit ?? {}),
      ...Object.values(formik.errors?.peakLimit ?? {}),
    ].some((x: string) => !!x);

  return (
    <Dialog {...props} maxWidth="md">
      <DialogTitle>
        {t('int.limitSettingsSection.editDialog.title')}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item container spacing={2}>
            <Grid item xs={3} />
            <Grid item xs={3}>
              <Typography color="textSecondary" variant="body2">
                {t('int.limitSettingsSection.current')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography color="textSecondary" variant="body2">
                {t('int.limitSettingsSection.max')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography color="textSecondary" variant="body2">
                {t('int.limitSettingsSection.peak')}
              </Typography>
            </Grid>
          </Grid>

          {TABLE_LAYOUT.rows
            .filter((row) => showAdditionalLimitFields || row === ROWS.limit)
            .map((row, rowIndex) => (
              <React.Fragment key={row}>
                <Grid item container spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="body2">
                      {t(`int.limitSettingsSection.${row}`)}
                    </Typography>
                  </Grid>

                  {TABLE_LAYOUT.columns.map((column) => (
                    <Grid item key={`${row}-${column}`} xs={3}>
                      <MoneyField
                        disabled={formik.isSubmitting}
                        {...omit(
                          formik.getFieldProps(`${column}.${row}`),
                          'onChange'
                        )}
                        allowNegative={row === 'full'}
                        {...(row === ROWS.limit && {
                          onBlur: () => {
                            if (
                              typeof formik.values[column].limit === 'number'
                            ) {
                              formik.setFieldValue(
                                `${column}.standard`,
                                formik.values[column].limit! <=
                                  standardCollateralLimit
                                  ? formik.values[column].limit
                                  : standardCollateralLimit
                              );
                            }
                          },
                        })}
                        onValueChange={(values) =>
                          onValueChange(values, column, row)
                        }
                        currency={limitCurrency}
                        {...(!!formik.errors[column]?.[row] && {
                          error: true,
                          helperText: t(
                            `int.limitSettingsSection.errors.${
                              formik.errors[column]![row]
                            }`
                          ),
                        })}
                        inputProps={{
                          'data-test-id': `${column}.${row}-input`,
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
                {rowIndex === 0 && showAdditionalLimitFields && (
                  <Grid item width="100%">
                    <Box
                      borderTop={(theme) =>
                        `1px solid ${theme.palette.divider}`
                      }
                    />
                  </Grid>
                )}
              </React.Fragment>
            ))}
        </Grid>

        <Grid item container mt={2} spacing={1}>
          <DataItemRow
            label={t('int.limitSettingsSection.gracePeriod')}
            updatedAt={null}
            updatedBy={null}
            value={
              typeof limits.gracePeriodInMonths === 'number'
                ? t('int.limitSettingsSection.month', {
                    count: limits.gracePeriodInMonths,
                  })
                : null
            }
          />

          <Grid item xs={12}>
            <FormControl
              variant="standard"
              sx={{ flexDirection: 'row', alignItems: 'center' }}
            >
              <FormLabel sx={{ minWidth: 200 }}>
                {t('int.limitSettingsSection.limitIncreaseAllowed')}
              </FormLabel>
              <RadioGroup
                row
                value={limitIncreaseAllowed}
                onChange={(_, value) => {
                  setLimitIncreaseAllowed(value);
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={t('common.yes')}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label={t('common.no')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button onClick={formik.submitForm} disabled={isSubmitDisabled}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditLimitSettingsDialog);
