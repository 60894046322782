import { useMemo } from 'react';
import { Box, Tooltip, Typography } from 'elements';

interface Props {
  legalName: string;
  street: string;
  postalCode: string;
  city: string;
  state: string;
}

const MerchantLegalNameAddressCell = ({
  legalName,
  street,
  postalCode,
  city,
  state,
}: Props) => {
  const formattedAddress = useMemo(() => {
    return [street, postalCode, city, state].filter((v) => !!v).join(', ');
  }, [street, postalCode, city, state]);

  return (
    <Box overflow="hidden">
      <Tooltip title={legalName}>
        <Typography variant="body2" color="text.primary" noWrap>
          {legalName || '-'}
        </Typography>
      </Tooltip>
      <Tooltip title={formattedAddress}>
        <Typography
          component="div"
          variant="caption"
          color="text.secondary"
          noWrap
        >
          {formattedAddress || '-'}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default MerchantLegalNameAddressCell;
