import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, SuitcaseSimpleIcon, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  BankForConnection,
  DEFAULT_AUTOCOMPLETE_DELAY,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const MIN_ALLOWED_CHARS = 3;

interface State {
  inputValue: string;
  isOpen: boolean;
  isLoading: boolean;
  options: BankForConnection[];
}

interface Props {
  value: BankForConnection | null;
  onChange: (value: BankForConnection | null) => void;
}

const BankSearchSelect = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    inputValue: '',
    isOpen: false,
    isLoading: false,
    options: [],
  });

  const getBanks = async (q: string) => {
    try {
      const { banks } = await api.getBanksForConnection({ q });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        options: banks,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isOpen: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    let active = true;

    if (state.inputValue.length < MIN_ALLOWED_CHARS) return;

    setState((prevState) => ({ ...prevState, isLoading: true }));

    setTimeout(() => {
      if (active) getBanks(state.inputValue);
    }, DEFAULT_AUTOCOMPLETE_DELAY);
  }, [state.inputValue]);

  return (
    <Autocomplete<BankForConnection, false, false, false>
      placeholder={t('bankSearchSelect.searchBank')}
      loadingText={t('bankSearchSelect.loading')}
      noOptionsText={t('common.nothingFound')}
      value={value}
      options={state.options}
      loading={state.isLoading}
      inputValue={state.inputValue}
      open={state.isOpen}
      onOpen={() =>
        setState((prevState) => ({
          ...prevState,
          isOpen: prevState.inputValue.length >= MIN_ALLOWED_CHARS,
        }))
      }
      onClose={() =>
        setState((prevState) => ({
          ...prevState,
          options: value ? prevState.options : [],
          isOpen: false,
        }))
      }
      onInputChange={(e, value) =>
        setState((prevState) => ({
          ...prevState,
          inputValue: value,
          isOpen: value.length >= MIN_ALLOWED_CHARS,
        }))
      }
      onChange={(e, value) => onChange(value)}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <SuitcaseSimpleIcon />
          <Box ml={2}>
            <div>{option.name}</div>
            {option.bic && (
              <Typography variant="caption" color="textSecondary">
                {option.bic}
              </Typography>
            )}
          </Box>
        </li>
      )}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option: BankForConnection) => option.name}
      forcePopupIcon={false}
    />
  );
};

export default BankSearchSelect;
