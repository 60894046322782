import { ReactNode } from 'react';
import { Badge, CheckCircleIcon, FlagPennantIcon } from 'elements';
import { TransactionReviewStatus } from 'services/constants';

interface Props {
  reviewStatus: TransactionReviewStatus | null;
  children: ReactNode;
}

const TransactionReviewStatusBadge = ({ reviewStatus, children }: Props) => {
  if (
    reviewStatus === TransactionReviewStatus.resolved ||
    reviewStatus === TransactionReviewStatus.flagged
  ) {
    return (
      <Badge
        badgeContent={
          reviewStatus === TransactionReviewStatus.resolved ? (
            <CheckCircleIcon sx={{ width: 8, height: 8 }} />
          ) : (
            <FlagPennantIcon sx={{ width: 8, height: 8 }} />
          )
        }
        color={
          reviewStatus === TransactionReviewStatus.resolved
            ? 'success'
            : 'error'
        }
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        sx={{
          '.MuiBadge-badge': {
            px: '2px',
            height: 12,
            minWidth: 12,
          },
        }}
      >
        {children}
      </Badge>
    );
  }

  return <>{children}</>;
};

export default TransactionReviewStatusBadge;
