import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  LoaderWithOverlay,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
  NetworkErrorCode,
  Team,
  TeamStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import { getPath } from 'services/utils/getPath';

interface Props extends DialogProps {
  isEditMode: boolean;
  team: Team | null;
  onClose: () => void;
  onSuccess: () => void;
}

interface State {
  teams: Team[];
  isLoading: boolean;
}

const AssignCostCenterDialog = ({
  isEditMode,
  team,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    teams: [],
    isLoading: !isEditMode,
  });
  const [inputValue, setInputValue] = useState('');

  const getData = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const { teams } = await api.getTeams({
        organizationId: organization!.id,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
        q: inputValue.trim(),
        sortBy: '+name',
        status: TeamStatus.active,
      });

      if (!mounted.current) return;

      setState((prevState) => ({
        ...prevState,
        teams,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!isEditMode) {
      let active = true;
      setState((prevState) => ({ ...prevState, isLoading: true }));
      setTimeout(() => {
        if (active) getData();
      }, DEFAULT_AUTOCOMPLETE_DELAY);
      return () => {
        active = false;
      };
    }
  }, [inputValue.trim(), isEditMode]);

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      team: team || null,
      costCenter: team?.costCenter || '',
    },
    onSubmit: async (
      { team, costCenter },
      { setSubmitting, setFieldError }
    ) => {
      try {
        const data = await api.updateTeam(team!.id, {
          name: team!.name,
          costCenter: costCenter.trim() || undefined,
        });
        dispatch({ type: 'UPDATE_TEAM', payload: data });
        if (!mounted.current) return;
        onSuccess();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (getNetworkErrorCode(error) === NetworkErrorCode.alreadyExists) {
          setFieldError(
            'name',
            t('createEditTeamDialog.nameAlreadyExistsError')
          );
          return;
        }
        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.illegalArgumentsProvided
        ) {
          setFieldError('costCenter', t('errors.invalidCharacters'));
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const disableSaveButton =
    !formik.values.team ||
    formik.values.team?.costCenter === formik.values.costCenter ||
    (!formik.values.team?.costCenter && !formik.values.costCenter);

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('assignCostCenterDialog.title')}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={2}>
          <Trans
            i18nKey="assignCostCenterDialog.description"
            components={{
              div: <div />,
              linkTo: (
                <Link
                  to={generatePath(getPath('teams'), {
                    orgId: organization!.id,
                  })}
                />
              ),
            }}
          />
        </Typography>

        <form
          onSubmit={formik.handleSubmit}
          noValidate
          id="assign-cost-center-form"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {isEditMode ? (
                <TextField
                  name="name"
                  value={formik.values.team?.name || ''}
                  label={t('assignCostCenterDialog.teamLabel')}
                  disabled
                />
              ) : (
                <FormControl fullWidth disabled={formik.isSubmitting}>
                  <Autocomplete<Team, false, false, false>
                    inputValue={inputValue}
                    onInputChange={(e, inputValue) => setInputValue(inputValue)}
                    value={formik.values.team}
                    onChange={(e, value) =>
                      formik.setValues({
                        team: value,
                        costCenter: value?.costCenter || '',
                      })
                    }
                    options={state.teams}
                    loading={state.isLoading}
                    filterOptions={(x) => x}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) => option.name}
                    label={t('assignCostCenterDialog.teamLabel')}
                    placeholder={t('filters.supplier')}
                    loadingText={t('common.loading')}
                    noOptionsText={t('common.nothingFound')}
                  />
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('costCenter')}
                label={t('assignCostCenterDialog.costCenterLabel')}
                name="costCenter"
                value={formik.values.costCenter}
                disabled={formik.isSubmitting}
                inputProps={{
                  maxLength: 36,
                }}
                error={!!formik.errors.costCenter}
                helperText={formik.errors.costCenter}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={!formik.dirty || disableSaveButton || formik.isSubmitting}
          type="submit"
          form="assign-cost-center-form"
        >
          {t('assignCostCenterDialog.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(AssignCostCenterDialog);
