import { MutableRefObject, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { Button, Chip, Stack } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { BankAccountTransfersAllowedStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

export type OnBillPaymentsBankAccountUpdatedRef = MutableRefObject<
  (id: string) => Promise<void>
>;

interface State {
  isUpdating: boolean;
  isAuthorizeAccountDialogOpen: boolean;
  isBlockAccountDialogOpen: boolean;
}

interface Props {
  bankAccountId: string;
  status: BankAccountTransfersAllowedStatus;
  onItemUpdatedRef: OnBillPaymentsBankAccountUpdatedRef;
}

const StatusCell = ({ status, bankAccountId, onItemUpdatedRef }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const canUser = useCanUser();
  const [state, setState] = useState<State>({
    isUpdating: false,
    isAuthorizeAccountDialogOpen: false,
    isBlockAccountDialogOpen: false,
  });

  if (
    status === BankAccountTransfersAllowedStatus.authorized ||
    status === BankAccountTransfersAllowedStatus.blocked
  ) {
    const label = t(`int.bankAccountTransfersAllowedStatus.${status}`);
    const color =
      status === BankAccountTransfersAllowedStatus.authorized
        ? 'success'
        : 'error';
    return <Chip variant="tinted" size="small" label={label} color={color} />;
  }

  const changeBankAccountStatus = async (
    status: BankAccountTransfersAllowedStatus
  ) => {
    if (state.isUpdating) return;
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      await api.updateBankAccountStatus({ bankAccountId, status });
      if (!mounted.current) return;
      await onItemUpdatedRef.current(bankAccountId);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isBlockAccountDialogOpen: false,
        isAuthorizeAccountDialogOpen: false,
        isUpdating: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isUpdating: false }));
      logError(error);
    }
  };

  return (
    <Stack direction="row" spacing={2} onClick={(e) => e.stopPropagation()}>
      <Button
        size="small"
        color="error"
        onClick={() =>
          setState((prevState) => ({
            ...prevState,
            isBlockAccountDialogOpen: true,
          }))
        }
        disabled={!canUser('top-up-approval-status:change')}
      >
        {t('int.billingAccountsApprovalPage.modal.reject.targetBtn')}
      </Button>
      <Button
        size="small"
        color="success"
        onClick={() =>
          setState((prevState) => ({
            ...prevState,
            isAuthorizeAccountDialogOpen: true,
          }))
        }
        disabled={!canUser('top-up-approval-status:change')}
      >
        {t('int.billingAccountsApprovalPage.modal.approve.targetBtn')}
      </Button>
      <ConfirmDialog
        open={state.isAuthorizeAccountDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isAuthorizeAccountDialogOpen: false,
            isUpdating: false,
          }))
        }
        onSuccess={() =>
          changeBankAccountStatus(BankAccountTransfersAllowedStatus.authorized)
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          children: t(
            'int.billingAccountsApprovalPage.modal.approve.confirmBtn'
          ),
        }}
        title={t('int.billingAccountsApprovalPage.modal.approve.title')}
        description={
          <Trans i18nKey="int.billingAccountsApprovalPage.modal.approve.content" />
        }
      />
      <ConfirmDialog
        open={state.isBlockAccountDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isBlockAccountDialogOpen: false,
            isUpdating: false,
          }))
        }
        onSuccess={() =>
          changeBankAccountStatus(BankAccountTransfersAllowedStatus.blocked)
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          children: t(
            'int.billingAccountsApprovalPage.modal.reject.confirmBtn'
          ),
        }}
        title={t('int.billingAccountsApprovalPage.modal.reject.title')}
        description={
          <Trans i18nKey="int.billingAccountsApprovalPage.modal.reject.content" />
        }
      />
    </Stack>
  );
};

export default StatusCell;
