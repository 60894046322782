import { FormEvent, useEffect, useRef, useState } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingSettings,
  AccountingSystem,
  AccountingSystemList,
  ApiIntegrationStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const getDefaultAccountingSystemName = (
  accountingSettings: AccountingSettings
) => {
  if (accountingSettings.accountingSystem === AccountingSystem.other)
    return accountingSettings.accountingSystemName || '';
  return '';
};

const getDefaultAccountingSystems = (
  accountingSettings: AccountingSettings
) => {
  const {
    accountingSystem,
    accountingSystemName,
    exportFormats,
  } = accountingSettings;
  if (!accountingSystem || !accountingSystemName) return [];
  return [
    {
      accountingSystemId: accountingSystem,
      accountingSystemName,
      availableExportFormats: exportFormats || [],
    },
  ];
};

interface State {
  accountingSystemId: AccountingSystem | null;
  accountingSystemName: string;
  isLoading: boolean;
  isErrorMode: boolean;
  accountingSystems: AccountingSystemList['accountingSystems'];
}

interface Props extends DialogProps {
  onClose: () => void;
}

const ChangeAccountingSystemNameDialog = (props: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    dispatch,
    state: { organization, accountingSettings },
  } = useGlobalState();
  const inputRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<State>({
    accountingSystemId: accountingSettings!.accountingSystem,
    accountingSystemName: getDefaultAccountingSystemName(accountingSettings!),
    isLoading: true,
    isErrorMode:
      accountingSettings!.apiIntegrationStatus ===
      ApiIntegrationStatus.connected,
    accountingSystems: getDefaultAccountingSystems(accountingSettings!),
  });

  const getData = async () => {
    try {
      const { accountingSystems } = await api.getAccountingSystems(
        organization!.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        accountingSystems: sortBy(accountingSystems, (accSystem) =>
          accSystem.accountingSystemName.toLowerCase()
        ),
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      props.onClose();
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (!state.isErrorMode) getData();
  }, [state.isErrorMode]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (state.isLoading) return;
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const accountingSettings = await api.setAccountingSystem(
        organization!.id,
        state.accountingSystemId!,
        state.accountingSystemName
      );
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { accountingSettings },
      });
      if (!mounted.current) return;
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const isSubmitDisabled =
    state.isLoading ||
    !state.accountingSystemId ||
    (state.accountingSystemId === AccountingSystem.other &&
      !state.accountingSystemName.trim());

  if (state.isErrorMode)
    return (
      <ConfirmDialog
        {...props}
        title={t('int.changeAccountingSystemNameDialog.confirmation.title')}
        description={t(
          'int.changeAccountingSystemNameDialog.confirmation.description',
          { name: accountingSettings!.accountingSystemName }
        )}
        cancelButtonProps={{ sx: { display: 'none' } }}
        confirmButtonProps={{ children: t('common.button.close') }}
        onSuccess={props.onClose}
      />
    );

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('int.changeAccountingSystemNameDialog.title')}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit} id="change-acc-system-form" noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="acc-system-select-label">
                  {t('int.changeAccountingSystemNameDialog.selectLabel')}
                </InputLabel>
                <Select
                  labelId="acc-system-select-label"
                  name="accountingSystem"
                  value={state.accountingSystemId || ''}
                  disabled={state.isLoading}
                  renderValue={(selected) => {
                    if (!selected) return '';
                    if (selected === AccountingSystem.other)
                      return t(`accountingSystems.${selected}`);

                    const selectedItem = state.accountingSystems.find(
                      (item) => item.accountingSystemId === selected
                    );
                    return selectedItem?.accountingSystemName
                      ? `${
                          selectedItem.accountingSystemName
                        } (${selectedItem.availableExportFormats.join(', ')})`
                      : (selected as string);
                  }}
                  onChange={(e) => {
                    const accountingSystemId = e.target
                      .value as AccountingSystem;
                    if (accountingSystemId === AccountingSystem.other) {
                      setState((prevState) => ({
                        ...prevState,
                        accountingSystemId,
                        accountingSystemName: getDefaultAccountingSystemName(
                          accountingSettings!
                        ),
                      }));
                      return;
                    }
                    setState((prevState) => ({
                      ...prevState,
                      accountingSystemId,
                      accountingSystemName: '',
                    }));
                  }}
                  displayEmpty
                >
                  {state.accountingSystems.map(
                    ({
                      accountingSystemId,
                      accountingSystemName,
                      availableExportFormats,
                    }) => (
                      <MenuItem
                        key={accountingSystemId}
                        value={accountingSystemId}
                      >
                        {accountingSystemName}
                        {!!availableExportFormats.length &&
                          ` (${availableExportFormats.join(', ')})`}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>

            {state.accountingSystemId === AccountingSystem.other && (
              <Grid item xs={12}>
                <TextField
                  inputRef={inputRef}
                  name="accountingSystemName"
                  label={t(
                    'int.changeAccountingSystemNameDialog.accountingSystemNameLabel'
                  )}
                  disabled={state.isLoading}
                  value={state.accountingSystemName}
                  onChange={(e) => {
                    const { value: accountingSystemName } = e.target;
                    setState((prevState) => ({
                      ...prevState,
                      accountingSystemName,
                    }));
                  }}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          type="submit"
          form="change-acc-system-form"
          disabled={isSubmitDisabled}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(ChangeAccountingSystemNameDialog);
