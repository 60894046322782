import { useMemo } from 'react';
import { adminPaths, cardholderPaths } from 'components/App/ExternalApp/paths';
import { useGlobalState } from 'context/GlobalState';
import useIsAdminApp from 'hooks/useIsAdminApp';

const useCardholderAppLink = () => {
  const isAdminApp = useIsAdminApp();
  const {
    state: { partnerOrgAuthDetails },
  } = useGlobalState();

  const innerLink = isAdminApp ? cardholderPaths.wallet : adminPaths.dashboard;

  const isCardholderAppDeactivated = useMemo(() => {
    return !!partnerOrgAuthDetails?.applicationWalletLink;
  }, [partnerOrgAuthDetails]);

  return {
    isCardholderAppDeactivated,
    cardholderAppLink: isCardholderAppDeactivated
      ? partnerOrgAuthDetails!.applicationWalletLink!
      : innerLink,
  };
};

export default useCardholderAppLink;
