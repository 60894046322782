import React from 'react';
import { isAxiosError } from 'axios';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { parseAndReformatDateStr } from 'domains/creditAndCompliance/utils/parseAndReformatDateStr';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  MoneyField,
  PercentField,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { AgencyProfilePayload, NetworkErrorCode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getMoneyObject,
  getNetworkErrorCode,
} from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface FormValues
  extends Omit<
    AgencyProfilePayload,
    'agencyLimit' | 'insolvency' | 'publishedAccounts'
  > {
  agencyLimit?: number | null;
  insolvency?: string;
  publishedAccounts?: string;
}

const EditAgencyProfileDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, organizationsStaticData },
  } = useGlobalState();
  const {
    actions: { refetchCompleteContext },
    state: { creditProfile },
  } = useCreditAndComplianceContext();
  const agencyProfile = creditProfile!.agencyProfile;

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      agencyLimit:
        typeof agencyProfile.agencyLimit.value?.value === 'number'
          ? agencyProfile.agencyLimit.value.value / 100
          : null,
      agencyLimitSource: agencyProfile.agencyLimitSource.value ?? '',
      companyStatus: agencyProfile.companyStatus.value ?? '',
      companyStatusSource: agencyProfile.companyStatusSource.value ?? '',
      crefoId: agencyProfile.crefoId.value,
      duns: agencyProfile.duns.value ?? '',
      insolvency:
        typeof agencyProfile.insolvency.value === 'boolean'
          ? agencyProfile.insolvency.value
            ? 'yes'
            : 'no'
          : '',
      insolvencySource: agencyProfile.insolvencySource.value,
      probabilityOfDefault: agencyProfile.probabilityOfDefault.value,
      probabilityOfDefaultSource:
        agencyProfile.probabilityOfDefaultSource.value,
      publishedAccounts:
        typeof agencyProfile.publishedAccounts.value === 'boolean'
          ? agencyProfile.publishedAccounts.value
            ? 'yes'
            : 'no'
          : '',
      publishedAccountsDate: parseAndReformatDateStr(
        agencyProfile.publishedAccountsDate.value
      ),
      publishedAccountsSource: agencyProfile.publishedAccountsSource.value,
    },
    validate: (values) => {
      const errors: { [k in keyof FormValues]?: string } = {};

      if (!!values.duns && !/^\d{9}$/.test(values.duns)) {
        errors.duns = t('int.editAgencyProfileDialog.dnbIdError');
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const payload = {
          ...(Object.entries(values).reduce(
            (cum, [key, value]) => ({
              ...cum,
              [key]: value !== '' ? value : null,
            }),
            {}
          ) as FormValues),
          agencyLimit:
            typeof values.agencyLimit === 'number'
              ? getMoneyObject(values.agencyLimit * 100)
              : null,
          insolvency: !!values.insolvency ? values.insolvency === 'yes' : null,
          publishedAccounts: !!values.publishedAccounts
            ? values.publishedAccounts === 'yes'
            : null,
        };

        await api.updateAgencyProfile(organization!.id, payload);
        await refetchCompleteContext();

        if (!mounted) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        if (
          getNetworkErrorCode(error) === NetworkErrorCode.crefoIdUpdateError &&
          isAxiosError(error) &&
          error.response?.data?.message
        ) {
          enqueueSnackbar(error.response.data.message, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    },
  });

  const getCustomFieldProps = <T extends keyof FormValues>(name: T) => {
    return {
      ...(formik.errors[name] && {
        error: true,
        helperText: formik.errors[name],
      }),
      name,
      value: formik.values[name],
    };
  };
  const onChange = <T extends keyof FormValues>(
    name: T,
    value: FormValues[T]
  ) => {
    if (formik.errors[name]) {
      formik.setFieldError(name, undefined);
    }
    formik.setFieldValue(name, value);
  };

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('int.editAgencyProfileDialog.title')}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('crefoId')}
              onChange={({ target: { value } }) => {
                if (/^[0-9]*$/.test(value)) {
                  onChange('crefoId', value.length ? +value : null);
                }
              }}
              label={t('int.editAgencyProfileDialog.crefoId')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('duns')}
              onChange={({ target: { value } }) =>
                onChange('duns', value.replace(/-/g, ''))
              }
              label={t('int.editAgencyProfileDialog.dnbId')}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.companyStatus}>
              <InputLabel>
                {t('int.editAgencyProfileDialog.companyStatus')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('companyStatus'), 'helperText')}
                onChange={(event) =>
                  onChange('companyStatus', event.target.value)
                }
              >
                {organizationsStaticData!.companyStatuses.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.companyStatus}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.companyStatusSource}>
              <InputLabel>{t('int.editAgencyProfileDialog.source')}</InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('companyStatusSource'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('companyStatusSource', event.target.value)
                }
              >
                {organizationsStaticData!.agencyProfileSources.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.companyStatusSource}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.insolvency}>
              <InputLabel>
                {t('int.editAgencyProfileDialog.companyIsInsolvent')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('insolvency'), 'helperText')}
                onChange={(event) => onChange('insolvency', event.target.value)}
              >
                {[
                  { name: 'yes', label: t('common.yes') },
                  { name: 'no', label: t('common.no') },
                ].map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.insolvency}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.insolvencySource}>
              <InputLabel>{t('int.editAgencyProfileDialog.source')}</InputLabel>
              <Select
                {...omit(getCustomFieldProps('insolvencySource'), 'helperText')}
                onChange={(event) =>
                  onChange('insolvencySource', event.target.value)
                }
              >
                {organizationsStaticData!.agencyProfileSources.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.insolvencySource}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <PercentField
              {...getCustomFieldProps('probabilityOfDefault')}
              onValueChange={({ floatValue = null }) =>
                onChange('probabilityOfDefault', floatValue)
              }
              label={t('int.editAgencyProfileDialog.pd')}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={!!formik.errors.probabilityOfDefaultSource}
            >
              <InputLabel>{t('int.editAgencyProfileDialog.source')}</InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('probabilityOfDefaultSource'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('probabilityOfDefaultSource', event.target.value)
                }
              >
                {organizationsStaticData!.probabilityOfDefaultSources.map(
                  (item) => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.label}
                    </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText>
                {formik.errors.probabilityOfDefaultSource}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <MoneyField
              {...getCustomFieldProps('agencyLimit')}
              onValueChange={({ floatValue }) => {
                onChange('agencyLimit', floatValue);
              }}
              label={t('int.editAgencyProfileDialog.agencyLimit')}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.agencyLimitSource}>
              <InputLabel>{t('int.editAgencyProfileDialog.source')}</InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('agencyLimitSource'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('agencyLimitSource', event.target.value)
                }
              >
                {organizationsStaticData!.agencyProfileSources.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.agencyLimitSource}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth error={!!formik.errors.publishedAccounts}>
              <InputLabel>
                {t('int.editAgencyProfileDialog.publishedAccounts')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('publishedAccounts'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('publishedAccounts', event.target.value)
                }
              >
                {[
                  { name: 'yes', label: t('common.yes') },
                  { name: 'no', label: t('common.no') },
                ].map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.publishedAccounts}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...getCustomFieldProps('publishedAccountsDate')}
              onChange={(event) =>
                onChange('publishedAccountsDate', event.target.value)
              }
              label={t('int.editAgencyProfileDialog.publishedAccountsDate')}
              type="date"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              error={!!formik.errors.publishedAccountsSource}
            >
              <InputLabel>{t('int.editAgencyProfileDialog.source')}</InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('publishedAccountsSource'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('publishedAccountsSource', event.target.value)
                }
              >
                {organizationsStaticData!.agencyProfileSources.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.publishedAccountsSource}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={formik.isSubmitting} onClick={formik.submitForm}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditAgencyProfileDialog);
