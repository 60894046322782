import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import { Typography } from 'elements';
import { AccountingTransaction } from 'services/constants';

const formatPercentOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

const calcVatAmount = (
  accountingTransactions: AccountingTransaction[],
  rate: number
) => {
  const sum = accountingTransactions
    .filter((item) => item.vatRateId)
    .map((item) => item.fxAmount.value)
    .reduce((sum, value) => sum + value, 0);

  return {
    value: sum - sum / (1 + rate / 100),
    currency: accountingTransactions[0].fxAmount.currency,
  };
};

interface Props {
  accountingTransactions: AccountingTransaction[];
}

const VatRateAmount = ({ accountingTransactions }: Props) => {
  const { t } = useTranslation();
  const {
    state: { vatRates, accountingSettings },
  } = useGlobalState();

  if (!accountingSettings?.vatRateEnabled || !vatRates) return null;

  const hasTransWithoutVat = accountingTransactions.find(
    (item) => !item.vatRateId
  );
  if (hasTransWithoutVat) return null;

  const selectedVatRateIds = accountingTransactions
    .map((item) => item.vatRateId)
    .filter((value) => value);

  if (!selectedVatRateIds.length) return null;

  const selectedVatRates = vatRates
    .filter((vatRate) => !!selectedVatRateIds.find((id) => id === vatRate.id))
    .map((item) => item.rate);

  const selectedUniqVatRates = Array.from(new Set(selectedVatRates));

  if (selectedUniqVatRates.length !== 1) return null;

  const vatAmount = calcVatAmount(
    accountingTransactions,
    selectedUniqVatRates[0]
  );

  return (
    <Typography variant="caption" color="textSecondary" display="block" noWrap>
      {t('transactionDetails.vat')} (
      <FormatPercent
        value={selectedUniqVatRates[0]}
        options={formatPercentOptions}
      />
      ) <FormatMoney value={vatAmount} fractionalPart />
    </Typography>
  );
};

export default VatRateAmount;
