import React, { ComponentType, MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  matchPath,
  NavLink,
  NavLinkProps,
  useRouteMatch,
} from 'react-router-dom';
import { cardholderPaths } from 'components/App';
import { StyledListItemButton } from 'components/MainHeader/style';
import { useGlobalState } from 'context/GlobalState';
import { useGetTransactionsNeedsReviewCounter } from 'domains/transaction/hooks';
import {
  ArrowsLeftRightIcon,
  Badge,
  Box,
  ListItemIcon,
  ListItemText,
  StarIcon,
  SvgIconProps,
  UsersIcon,
  WalletIcon,
} from 'elements';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';

interface NavItemProps {
  path: string;
  label: string;
  Icon: ComponentType<SvgIconProps>;
  testId: string;
  intercomTarget?: string;
  isActive?: NavLinkProps['isActive'];
  children?: ReactNode;
}

const NavItem = ({
  path,
  label,
  Icon,
  testId,
  intercomTarget,
  isActive,
  children,
}: NavItemProps) => {
  const { path: currentRootPath } = useRouteMatch();

  return (
    <StyledListItemButton
      component={NavLink}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        if (path === currentRootPath) e.preventDefault();
      }}
      to={path}
      data-test-id={testId}
      isActive={isActive}
      {...(intercomTarget && { 'data-intercom-target': intercomTarget })}
    >
      <ListItemIcon>
        <Icon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={label} />
      {children}
    </StyledListItemButton>
  );
};

const CardholderNavigation = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { partnerDealsEnabled } = useFlags();
  const {
    state: { featureModules },
  } = useGlobalState();
  const transactionsNeedsReviewCount = useGetTransactionsNeedsReviewCounter(
    true
  );
  const isTeamsTabVisible =
    featureModules.TEAMS && canUser('ch-manager-teams-page:visit');
  const isRewardsTabVisible =
    partnerDealsEnabled || canUser('ch-card-benefits-page:visit');

  return (
    <Box display="flex" alignItems="center" flexGrow={1}>
      <NavItem
        path={cardholderPaths.wallet}
        label={t('cardholderNavigation.wallet')}
        Icon={WalletIcon}
        testId="ch-wallet-tab"
      />

      <NavItem
        path={cardholderPaths.transactions}
        label={t('cardholderNavigation.transactions')}
        Icon={ArrowsLeftRightIcon}
        testId="ch-transactions-tab"
        intercomTarget="ch-transactions-tab"
        isActive={(match, location) => {
          return (
            !!match ||
            !!matchPath(location.pathname, {
              path: cardholderPaths.teamTransactions,
            }) ||
            !!matchPath(location.pathname, {
              path: cardholderPaths.transactionsReview,
            })
          );
        }}
        children={
          transactionsNeedsReviewCount > 0 && (
            <Box
              display="flex"
              justifyContent="flex-end"
              ml={transactionsNeedsReviewCount > 9 ? 1 : 2}
            >
              <Box component="span" visibility="hidden" height={0}>
                {transactionsNeedsReviewCount}
              </Box>
              <Badge
                color="secondary"
                badgeContent={transactionsNeedsReviewCount}
                max={99}
                sx={{
                  '& .MuiBadge-badge': {
                    right: 8,
                  },
                }}
              />
            </Box>
          )
        }
      />

      {isTeamsTabVisible && (
        <NavItem
          path={cardholderPaths.teams}
          label={t('cardholderNavigation.teams')}
          Icon={UsersIcon}
          testId="ch-manager-teams-tab"
        />
      )}

      {isRewardsTabVisible && (
        <NavItem
          path={cardholderPaths.rewards}
          label={t('cardholderNavigation.rewards')}
          Icon={StarIcon}
          testId="ch-benefits-tab"
        />
      )}
    </Box>
  );
};

export default CardholderNavigation;
