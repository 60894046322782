import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CardIcon, CardStatusBadge } from 'domains/card/components';
import { useAvailableCardConfigSettings } from 'domains/card/hooks';
import { QueryParams } from 'domains/card/pages/CardsPage/index';
import { getCardName } from 'domains/card/utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { FilterDrawer, FilterDrawerProps } from 'layout';
import { cardSimpleStatuses } from 'services/constants';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  status: string[];
  cardConfigGroups: string[];
}

const initialState: State = {
  status: [],
  cardConfigGroups: [],
};

const FiltersDrawer = ({ params, setParam, ...props }: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>(initialState);
  const availableCardConfigSettings = useAvailableCardConfigSettings();

  const areFiltersSelected =
    !!state.status.length || !!state.cardConfigGroups.length;

  useEffect(() => {
    if (props.open)
      setState({
        status: params.status,
        cardConfigGroups: params.cardConfigGroups,
      });
  }, [params, props.open]);

  const onFiltersApply = () => {
    setParam(Object.entries(state));
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.entries(initialState));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-status"
          id="panel-status-header"
        >
          <Typography>{t('filters.status')} </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <FormGroup>
              {cardSimpleStatuses.map((status) => (
                <FormControlLabel
                  key={status}
                  checked={state.status.includes(status)}
                  control={<Checkbox />}
                  onChange={() =>
                    setState((prevState) => ({
                      ...prevState,
                      status: xor(prevState.status, [status]),
                    }))
                  }
                  label={<CardStatusBadge status={status} />}
                  disableTypography
                />
              ))}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      {availableCardConfigSettings.length > 0 && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<CaretDownIcon />}
            aria-controls="panel-card-config-groups"
            id="panel-card-config-groups-header"
          >
            <Typography>{t('filters.cardType')} </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <FormGroup>
                {availableCardConfigSettings.map((item) => (
                  <FormControlLabel
                    key={item.cardConfigGroup}
                    checked={state.cardConfigGroups.includes(
                      item.cardConfigGroup
                    )}
                    control={<Checkbox />}
                    onChange={(_, checked) =>
                      setState((prevState) => ({
                        ...prevState,
                        cardConfigGroups: xor(prevState.cardConfigGroups, [
                          item.cardConfigGroup,
                        ]),
                      }))
                    }
                    label={
                      <>
                        <CardIcon
                          cardDesignId={item.cardDesignIds[0]}
                          sx={(theme) => ({
                            margin: theme.spacing(1, 2, 1, 1),
                          })}
                        />
                        <Typography>{getCardName(item.cardConfig)}</Typography>
                      </>
                    }
                    disableTypography
                  />
                ))}
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}
    </FilterDrawer>
  );
};

export default FiltersDrawer;
