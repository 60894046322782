import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { CaretLeftIcon, CaretRightIcon, Fade } from 'elements';
import { Card } from 'services/constants';
import WalletCardTile from './WalletCardTile';
import { Dot, NextButton, PrevButton, Slide, Wrapper } from './style';

interface CardCarouselProps {
  cards: Card[];
  cardIndex: number;
  onCardIndexChange: (index: number) => void;
}

const CardCarousel = ({
  cards,
  cardIndex,
  onCardIndexChange,
}: CardCarouselProps) => {
  const sliderRef = useRef<any>();
  const slickSliderSettings = {
    customPaging: () => <Dot />,
    beforeChange: (currentIndex: number, nextIndex: number) => {
      onCardIndexChange(nextIndex);
    },
    initialSlide: cardIndex,
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    sliderRef.current.slickGoTo(cardIndex, true);
  }, [cardIndex]);

  return (
    <Wrapper>
      <Slider ref={sliderRef} {...slickSliderSettings}>
        {cards.map((card, index) => (
          <Slide key={card.cardId}>
            <WalletCardTile card={card} isActive={index === cardIndex} />
          </Slide>
        ))}
      </Slider>
      {cards.length > 1 && (
        <>
          <Fade in={cardIndex !== 0}>
            <PrevButton
              onClick={() => sliderRef.current.slickPrev()}
              size="large"
            >
              <CaretLeftIcon />
            </PrevButton>
          </Fade>
          <Fade in={cardIndex !== cards.length - 1}>
            <NextButton
              onClick={() => sliderRef.current.slickNext()}
              size="large"
            >
              <CaretRightIcon />
            </NextButton>
          </Fade>
        </>
      )}
    </Wrapper>
  );
};

export default CardCarousel;
