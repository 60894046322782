import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { useGlobalState } from 'context/GlobalState';
import { Button } from 'elements';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';

const UpgradeButton = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();

  return (
    <Button
      disabled={!canUser('subscription-plan-settings:view')}
      onClick={() =>
        history.push(
          generatePath(getPath('settingsSubscriptionPlan'), {
            orgId: organization!.id,
          })
        )
      }
    >
      {t('common.button.upgrade')}
    </Button>
  );
};

export default UpgradeButton;
