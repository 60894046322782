import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Tooltip, Typography } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import {
  getFilterChipText,
  PageFilterChips,
  PageFiltersComponent,
} from 'layout';
import { countries } from 'services/constants';
import FiltersDrawer from './FiltersDrawer';
import { QueryParams } from '.././index';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  disabled: boolean;
  totalCount: number;
}

const Filters = ({
  params,
  selectedFiltersCount,
  setParam,
  disabled,
  totalCount,
}: Props) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const statusTooltip = useMemo(() => {
    if (params.status.length < 2) return '';
    return params.status
      .map((status) => t(`merchantStatuses.${status}`))
      .join(', ')
      .toUpperCase();
  }, [params.status]);

  const countryTooltip = useMemo(() => {
    if (params.country.length < 2) return '';
    return params.country
      .map((code) => countries.find(({ countryCode }) => countryCode === code))
      .map((country) => country?.countryName)
      .join(', ');
  }, [params.country]);

  const countryChipText = useMemo(() => {
    if (!params.country.length) return '';
    return (
      countries.find((country) => country.countryCode === params.country[0])
        ?.countryName || ''
    );
  }, [params.country]);

  return (
    <>
      <PageFiltersComponent
        count={selectedFiltersCount}
        q={params.q}
        setQParam={(value) => setParam('q', value)}
        onOpen={() => setIsFilterOpen(true)}
        disabled={disabled}
      >
        <Typography variant="body2" color="textSecondary" ml={2}>
          {t('common.resultsTable', {
            count: totalCount,
          })}
        </Typography>
      </PageFiltersComponent>

      {!!selectedFiltersCount && (
        <PageFilterChips>
          {!!params.status.length && (
            <Tooltip title={statusTooltip}>
              <Chip
                label={getFilterChipText(
                  t(`merchantStatuses.${params.status[0]}`).toUpperCase(),
                  params.status.length
                )}
                onDelete={() => setParam('status', [])}
              />
            </Tooltip>
          )}
          {!!params.country.length && (
            <Tooltip title={countryTooltip}>
              <Chip
                label={getFilterChipText(
                  countryChipText,
                  params.country.length
                )}
                onDelete={() => setParam('country', [])}
              />
            </Tooltip>
          )}
        </PageFilterChips>
      )}

      <FiltersDrawer
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        params={params}
        setParam={setParam}
      />
    </>
  );
};

export default Filters;
