import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, Select } from 'elements';
import { CardAccountStatus } from 'services/constants';

interface Props {
  filterValue: CardAccountStatus | '';
  teamsPage?: boolean;
  onFilterChange: (value: CardAccountStatus | '') => void;
}

export const StatusFilterSelect = ({ filterValue, onFilterChange }: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <Select<CardAccountStatus>
        displayEmpty
        value={filterValue}
        renderValue={(selected) => {
          if (selected) return t(`cardAccountStatus.${selected}`);
          return t('settingsSubpageHeader.all');
        }}
        onChange={(e) => {
          const activeFilter = e.target.value as CardAccountStatus | '';
          onFilterChange(activeFilter);
        }}
      >
        {[
          CardAccountStatus.active,
          CardAccountStatus.pending,
          CardAccountStatus.deactivated,
          CardAccountStatus.cancelled,
        ].map((status) => (
          <MenuItem key={status} value={status}>
            {t(`cardAccountStatus.${status}`)}
          </MenuItem>
        ))}

        <MenuItem>{t('settingsSubpageHeader.all')}</MenuItem>
      </Select>
    </FormControl>
  );
};
