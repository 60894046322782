import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flags } from './flags';

const useTypedFlags = () => {
  const flags = useFlags();

  return flags as Flags;
};

export default useTypedFlags;
