import { createTheme } from '@material-ui/core/styles';
import { DefaultTheme } from 'styled-components';
import mixins from './mixins';

export const colors = {
  white: '#fff',
  blackCard: '#25282a',
  plasticCard: '#031d3e',
  // these colors should be moved to theme.
  // for now use `constants -> IntegrationColor`
  // for getting rid of this theme
  /** @deprecated */
  circula: '#002d40',
  /** @deprecated */
  bezala: '#167576',
  /** @deprecated */
  candis: '#2c192e',
  /** @deprecated */
  klippa: '#01c464',

  circulaBg: '#eef3f7',
  candisBg: '#f3efee',
  mobilexpenseBg: '#eef2f5',

  // earth
  earthLightBlue: '#ddf5f6',
  earthGrey: '#b0c0c9',
  earthRoyalBlue: '#1929ff',

  // yellow
  yellow100: '#fbffe5',
  yellow90: '#f8ffcc',
  yellow80: '#f4ffb2',
  yellow70: '#f0ff99',
  yellow60: '#edff80',
  yellow50: '#e6ff52',
  yellow40: '#cfe54a',
  yellow30: '#b8cc42',
  yellow20: '#a1b239',
  yellow10: '#8a9931',

  // neutral
  neutral100: '#ffffff',
  neutral90: '#e6e6e6',
  neutral80: '#cccccc',
  neutral70: '#b3b3b3',
  neutral60: '#999999',
  neutral50: '#808080',
  neutral40: '#666666',
  neutral30: '#4d4d4d',
  neutral20: '#333333',
  neutral10: '#201c1c',

  // orange
  orange100: '#fff8f5',
  orange90: '#ffeee5',
  orange80: '#ffddcc',
  orange70: '#ffccb3',
  orange60: '#ffb48f',
  orange50: '#ffa070',
  orange40: '#f49260',
  orange30: '#ef8956',
  orange20: '#eb834c',
  orange10: '#e07d49',

  // red
  red100: '#ffe5e8',
  red90: '#ffccd0',
  red80: '#ffb3b9',
  red70: '#ff99a2',
  red60: '#ff808a',
  red50: '#ff727e',
  red40: '#f4717c',
  red30: '#ec6671',
  red20: '#d75762',
  red10: '#c74052',

  // blue
  blue100: '#d8ffff',
  blue90: '#cdf2f2',
  blue80: '#c2e5e5',
  blue70: '#b7d9d9',
  blue60: '#adcccc',
  blue50: '#a5c3c3',
  blue40: '#97b2b2',
  blue30: '#8ca6a6',
  blue20: '#819999',
  blue10: '#7c9293',

  // fawn
  fawn100: '#fbfaf8',
  fawn90: '#f8f4f2',
  fawn80: '#f2ece8',
  fawn70: '#eee7e2',
  fawn60: '#e9ded8',
  fawn50: '#e4d7cf',
  fawn40: '#d9ccc5',
  fawn30: '#ccc0b9',
  fawn20: '#bfb4ae',
  fawn10: '#b2a69e',

  // grey
  grey100: '#778e99',
  grey90: '#647680',
  grey80: '#5a6a73',
  grey70: '#505f66',
  grey60: '#465359',
  grey50: '#404c52',
  grey40: '#323b40',
  grey30: '#282f33',
  grey20: '#19242a',
  grey10: '#0f161a',

  // neutralVariant
  neutralVariant100: '#f8f8f8',
  neutralVariant90: '#f5f5f0',
  neutralVariant80: '#edede7',
  neutralVariant70: '#e4e4de',
  neutralVariant60: '#ddddd5',
  neutralVariant50: '#ccccc4',
  neutralVariant40: '#bfbfb8',
  neutralVariant30: '#b2b2ac',
  neutralVariant20: '#a6a6a0',
  neutralVariant10: '#999993',

  // financeRed
  financeRed100: '#ff99a2',
  financeRed90: '#ff8c96',
  financeRed80: '#ff808a',
  financeRed70: '#ff737f',
  financeRed60: '#ff6673',
  financeRed50: '#ff4d5c',
  financeRed40: '#e53948',
  financeRed30: '#cc2937',
  financeRed20: '#c50033',
  financeRed10: '#b2002e',

  // financeBlue
  financeBlue100: '#cde2f1',
  financeBlue90: '#b5d7f1',
  financeBlue80: '#9dcdf1',
  financeBlue70: '#91c8f1',
  financeBlue60: '#85c3f1',
  financeBlue50: '#79bef1',
  financeBlue40: '#5cabe5',
  financeBlue30: '#4c9dd9',
  financeBlue20: '#4590c9',
  financeBlue10: '#4289bf',

  // financeGreen
  financeGreen100: '#f2fffa',
  financeGreen90: '#e5fff5',
  financeGreen80: '#d9fff0',
  financeGreen70: '#ccffec',
  financeGreen60: '#b9f0db',
  financeGreen50: '#a1e5cb',
  financeGreen40: '#8dd9bc',
  financeGreen30: '#69bf9e',
  financeGreen20: '#54a184',
  financeGreen10: '#498c73',
};

export type Colors = typeof colors;

export const muiTheme = createTheme({
  typography: {
    fontFamily: '"MaisonNeue", sans-serif',
    // Legacy MUI letter spacing values that have been used because the custom font family was not set in MUI theme.
    h1: { fontWeight: 400, letterSpacing: '-0.01562em' },
    h2: { fontWeight: 400, letterSpacing: '-0.00833em' },
    h3: { fontWeight: 400, letterSpacing: '0em' },
    h4: { fontWeight: 400, letterSpacing: '0.00735em' },
    h5: { fontWeight: 400, letterSpacing: '0em' },
    h6: { fontWeight: 400, letterSpacing: '0.0075em' },
    subtitle1: { fontWeight: 400, letterSpacing: '0.00938em' },
    subtitle2: { fontWeight: 400, letterSpacing: '0.00714em' },
    body1: { fontWeight: 400, letterSpacing: '0.00938em' },
    body2: { fontWeight: 400, letterSpacing: '0.01071em' },
    button: { fontWeight: 400, letterSpacing: '0.02857em' },
    caption: { fontWeight: 400, letterSpacing: '0.03333em' },
    overline: { fontWeight: 400, letterSpacing: '0.08333em' },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        color: 'inherit',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: '#201c1c',
      },
    },
  },
});

const size = {
  dataTableHeaderHeight: 41,
};

export type Size = typeof size;

const padding = {
  dataTableRow: 12,
};

export type Padding = typeof padding;

export const styledComponentsTheme: DefaultTheme = {
  colors,
  mixins,
  size,
  padding,
};
