import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

export interface SetQueryParam {
  (key: string, value: any): void;
  (entries: [string, any][]): void;
}

const useSetQueryParam = (): SetQueryParam => {
  const history = useHistory();
  const location = useLocation();

  return useCallback(
    (key: string | [string, any][], value?: any) => {
      let entries;
      if (Array.isArray(key)) entries = key;
      else entries = [[key, value]];

      const urlParams = new URLSearchParams(location.search);
      entries.forEach(([key, value]) => {
        if (!value || (Array.isArray(value) && !value.length))
          urlParams.delete(key);
        else urlParams.set(key, value);
      });

      const newQueryString = urlParams.toString();
      if (location.search !== newQueryString) {
        history.push({
          pathname: location.pathname,
          search: urlParams.toString(),
        });
      }
    },
    [history, location]
  );
};

export default useSetQueryParam;
