import { useCallback } from 'react';
import { useGlobalState } from 'context/GlobalState';
import {
  CardAccount,
  CardAccountStatus,
  OrganizationAccountType,
  OrganizationProductType,
} from 'services/constants';
import { useCanUser } from 'services/rbac';

const useIsPayoutFromCardAccountAllowed = () => {
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();

  return useCallback(
    (cardAccount: CardAccount) => {
      if (
        cardAccount.status.value !== CardAccountStatus.active &&
        (cardAccount.status.value !== CardAccountStatus.deactivated ||
          !canUser('payout-payment-for-deactivated-card-account:create'))
      ) {
        return false;
      }

      if (
        cardAccount.accountType.value === OrganizationAccountType.prefunded &&
        canUser('payout-payment:create')
      ) {
        return true;
      }
      if (
        cardAccount.accountType.value === OrganizationAccountType.credit &&
        [OrganizationProductType.MDESD, OrganizationProductType.CCROY].includes(
          organization!.productType
        ) &&
        canUser('payout-payment:create')
      ) {
        return true;
      }
      if (
        cardAccount.accountType.value === OrganizationAccountType.credit &&
        organization!.productType === OrganizationProductType.CCREU &&
        canUser('payout-payment-for-ccreu-card-account:create')
      ) {
        return true;
      }

      return false;
    },
    [organization?.productType, canUser]
  );
};

export default useIsPayoutFromCardAccountAllowed;
