import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, Select } from 'elements';
import { AccountingItemStatus, TeamStatus } from 'services/constants';

// Removed 'all' from the type, as it's better to use empty string.
// Almost all pages with this filter will use empty value once we add pagination.
export type ActiveFilterValue = AccountingItemStatus | TeamStatus | '';

interface Props {
  filterValue: ActiveFilterValue;
  teamsPage?: boolean;
  disabled?: boolean;
  onFilterChange: (value: ActiveFilterValue) => void;
}

export const StatusFilterSelect = ({
  filterValue,
  disabled,
  teamsPage = false,
  onFilterChange,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <Select<ActiveFilterValue>
        displayEmpty
        disabled={disabled}
        value={filterValue}
        renderValue={(selected) => {
          if (selected === AccountingItemStatus.active)
            return t('settingsSubpageHeader.active');
          if (
            selected === AccountingItemStatus.inactive ||
            selected === TeamStatus.deactivated
          )
            return t('settingsSubpageHeader.inactive');
          return t('settingsSubpageHeader.all');
        }}
        onChange={(e) => {
          const activeFilter = e.target.value as ActiveFilterValue;
          onFilterChange(activeFilter);
        }}
      >
        <MenuItem
          value={teamsPage ? TeamStatus.active : AccountingItemStatus.active}
        >
          {t('settingsSubpageHeader.active')}
        </MenuItem>
        <MenuItem
          value={
            teamsPage ? TeamStatus.deactivated : AccountingItemStatus.inactive
          }
        >
          {t('settingsSubpageHeader.inactive')}
        </MenuItem>
        <MenuItem value="">{t('settingsSubpageHeader.all')}</MenuItem>
      </Select>
    </FormControl>
  );
};
