import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormControlLabelTooltipIcon,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  ComplianceDocumentUploadOption,
  DocumentType,
  documentTypes,
  supportedCountries,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import formatBytes from 'services/utils/formatBytes';
import PreviewDialog from './PreviewDialog';

const documentUploadOptions = Object.values(ComplianceDocumentUploadOption);

const DEFAULT = 'default';
const countries = [DEFAULT, ...supportedCountries];

export interface FormValues {
  type: DocumentType;
  option: ComplianceDocumentUploadOption;
  files: File[];
  country: string;
  version: string;
  partnerId: string;
}

export interface Props extends DialogProps {
  onClose: () => void;
}

const UpdateTermsOrPoliciesDialog = (props: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    state: { partners },
  } = useGlobalState();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState({
    step: 1,
    isLoading: false,
    existsActiveOptIn: false,
  });
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      type: DocumentType.platformTermsAndConditions,
      option: ComplianceDocumentUploadOption.NOT_APPLICABLE,
      files: [],
      country: DEFAULT,
      version: '',
      partnerId: DEFAULT,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await api.updateComplianceDocuments({
          type: values.type,
          option: values.option,
          version: values.version,
          file: values.files[0],
          country: values.country === DEFAULT ? null : values.country,
          partnerId: values.partnerId === DEFAULT ? null : values.partnerId,
        });
        if (!mounted.current) return;
        setSubmitting(false);
        props.onClose();
        enqueueSnackbar(
          t('int.updateTermsOrPoliciesDialog.success', {
            doc: t(`int.updateTermsOrPoliciesDialog.docs.${values.type}`),
          }),
          { variant: 'success' }
        );
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        setState((prevState) => ({ ...prevState, step: 1 }));
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const getExistingActiveOptIns = async (
    type: DocumentType,
    country: string,
    partnerId: string
  ) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const { existsActiveOptIn } = await api.getExistingActiveOptIn(
        type,
        country !== DEFAULT ? country : undefined,
        partnerId !== DEFAULT ? partnerId : undefined
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        existsActiveOptIn,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        existsActiveOptIn: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    getExistingActiveOptIns(
      formik.values.type,
      formik.values.country,
      formik.values.partnerId
    );
    formik.setFieldValue(
      'option',
      ComplianceDocumentUploadOption.NOT_APPLICABLE
    );
  }, [formik.values.type, formik.values.country, formik.values.partnerId]);

  const isRadioDisabled = (option: ComplianceDocumentUploadOption) => {
    if (option === ComplianceDocumentUploadOption.CHANGE_WITH_OBJECTION) {
      return formik.values.type.includes('DATA_PRIVACY_POLICY');
    }
    if (option === ComplianceDocumentUploadOption.ACTIVE_OPT_IN) {
      return (
        formik.values.type.includes('DATA_PRIVACY_POLICY') ||
        formik.values.type === DocumentType.travelInsuranceTerms
      );
    }
    return false;
  };

  const addFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const newFile = event.currentTarget.files.item(0);
      formik.setFieldValue('files', [newFile]);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const goToPreview = () => {
    setState((prevState) => ({ ...prevState, step: 2 }));
  };

  const isSubmitDisabled =
    state.isLoading ||
    state.existsActiveOptIn ||
    !formik.values.files.length ||
    !formik.values.version ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth={state.step === 1 ? 'xs' : 'sm'}>
      {state.step === 1 ? (
        <>
          <DialogTitle>
            {t('int.updateTermsOrPoliciesDialog.title')}
          </DialogTitle>

          <DialogContent>
            <form
              onSubmit={formik.handleSubmit}
              id="update-terms-form"
              noValidate
            >
              <Grid container columnSpacing={3} rowSpacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    disabled={formik.isSubmitting}
                    error={state.existsActiveOptIn}
                    fullWidth
                  >
                    <InputLabel id="type-label">
                      {t('int.updateTermsOrPoliciesDialog.termOrPolicyType')}
                    </InputLabel>
                    <Select<string>
                      labelId="type-label"
                      renderValue={(selected) =>
                        t(`int.updateTermsOrPoliciesDialog.docs.${selected}`)
                      }
                      {...formik.getFieldProps('type')}
                    >
                      {documentTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {t(`int.updateTermsOrPoliciesDialog.docs.${type}`)}
                        </MenuItem>
                      ))}
                    </Select>
                    {state.existsActiveOptIn && (
                      <FormHelperText>
                        {t(
                          'int.updateTermsOrPoliciesDialog.termOrPolicyTypeError'
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="date"
                    label={t(
                      'int.updateTermsOrPoliciesDialog.dateOfApplicability'
                    )}
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('version')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    disabled={formik.isSubmitting}
                    error={state.existsActiveOptIn}
                  >
                    <InputLabel id="terms-and-policies-country">
                      {t('int.updateTermsOrPoliciesDialog.country')}
                    </InputLabel>
                    <Select
                      labelId="terms-and-policies-country"
                      {...formik.getFieldProps('country')}
                      renderValue={(selected) =>
                        selected === DEFAULT
                          ? t('int.updateTermsOrPoliciesDialog.default')
                          : t(`countries.${selected}`)
                      }
                    >
                      {countries.map((country) => (
                        <MenuItem key={country} value={country}>
                          {country === DEFAULT
                            ? t('int.updateTermsOrPoliciesDialog.default')
                            : t(`countries.${country}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    disabled={formik.isSubmitting}
                    error={state.existsActiveOptIn}
                  >
                    <InputLabel id="terms-and-policies-partner-id">
                      {t('int.updateTermsOrPoliciesDialog.partnerSource')}
                    </InputLabel>
                    <Select
                      labelId="terms-and-policies-partner-id"
                      {...formik.getFieldProps('partnerId')}
                      renderValue={(selected) => {
                        if (selected === DEFAULT) {
                          return t('int.updateTermsOrPoliciesDialog.default');
                        }

                        return partners!.find(
                          (partner) => partner.partnerId === selected
                        )!.name;
                      }}
                    >
                      <MenuItem value={DEFAULT}>
                        {t('int.updateTermsOrPoliciesDialog.default')}
                      </MenuItem>
                      {partners!.map((partner) => (
                        <MenuItem
                          key={(partner.partnerId as unknown) as string}
                          value={(partner.partnerId as unknown) as string}
                        >
                          {partner.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    disabled={formik.isSubmitting}
                    variant="standard"
                    sx={{ my: 2 }}
                  >
                    <FormLabel>
                      {t('int.updateTermsOrPoliciesDialog.confirmationType')}
                    </FormLabel>
                    <RadioGroup
                      value={formik.values.option}
                      onChange={(_, value) =>
                        formik.setFieldValue('option', value)
                      }
                    >
                      {documentUploadOptions.map((option) => {
                        return (
                          <FormControlLabel
                            key={option}
                            value={option}
                            disabled={isRadioDisabled(option)}
                            control={<Radio />}
                            label={
                              <Box
                                display="inline-flex"
                                alignItems="center"
                                component="span"
                              >
                                {t(
                                  `int.updateTermsOrPoliciesDialog.types.${option}`
                                )}
                                <FormControlLabelTooltipIcon
                                  title={t(
                                    `int.updateTermsOrPoliciesDialog.tooltips.${option}`
                                  )}
                                />
                              </Box>
                            }
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      fileInputRef.current?.click();
                    }}
                    disabled={formik.isSubmitting}
                  >
                    {t('int.updateTermsOrPoliciesDialog.upload')}
                  </Button>
                  {formik.values.files[0] && (
                    <>
                      <Typography variant="body2" mt={1}>
                        {formik.values.files[0].name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatBytes(formik.values.files[0].size)}
                      </Typography>
                    </>
                  )}
                  <input
                    data-test-id="file-uploader"
                    style={{ display: 'none' }}
                    accept=".pdf"
                    type="file"
                    ref={fileInputRef}
                    onChange={addFile}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>

          <DialogActions>
            <Button onClick={props.onClose} variant="text">
              {t('int.common.button.cancel')}
            </Button>
            <Button
              onClick={goToPreview}
              disabled={isSubmitDisabled}
              form="update-terms-form"
              type="submit"
            >
              {t('int.common.button.preview')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <PreviewDialog
          values={formik.values}
          onCancel={() => setState((prevState) => ({ ...prevState, step: 1 }))}
          onSubmit={formik.submitForm}
          isSubmitting={formik.isSubmitting}
        />
      )}
    </Dialog>
  );
};

export default withDialogWrapper(UpdateTermsOrPoliciesDialog);
