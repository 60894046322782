import { styled } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from 'elements';

export const StyledAccordion = styled(Accordion)`
  z-index: 20;
  position: absolute;
  width: 100%;
  bottom: 0;
  border: none;
  background: ${({ theme }) => theme.variables.background.contrast};

  &.card-history-cardholder-app {
    position: static;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: ${({ theme }) => theme.shape.borderRadius + 'px'};
    background: none;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: auto;
  padding: ${({ theme }) => theme.spacing(0, 4.5, 0, 3)};

  & .MuiAccordionSummary-content {
    margin: 0;
    align-items: center;
  }

  .card-history-cardholder-app & {
    padding: ${({ theme }) => theme.spacing(0, 2)};
  }

  .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(180deg);

    &.Mui-expanded {
      transform: none;
    }

    .card-history-cardholder-app & {
      transform: none;
    }

    .card-history-cardholder-app &.Mui-expanded {
      transform: rotate(180deg);
    }
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: block;
  padding: 0;
`;

export const Nowrap = styled('span')`
  white-space: nowrap;
`;
