import { Avatar, AvatarProps, Box } from 'elements';

const generateLogoByName = (name: string) => name.toUpperCase().slice(0, 1);

interface Props extends AvatarProps {
  url: string | null;
  name?: string;
  size?: 'medium' | 'large';
}

const MerchantLogo = ({
  url,
  name,
  size = 'medium',
  sx = [],
  ...props
}: Props) => {
  return (
    <>
      <Avatar
        variant="rounded"
        sx={[
          {
            background: 'none',
            ...(size === 'large'
              ? {
                  height: 80,
                  width: 80,
                  borderRadius: 2,
                  color: 'text.primary',
                  fontSize: '40px',
                  border: (theme) =>
                    url
                      ? 'none'
                      : `1px solid ${theme.variables.input.outlined.enabledBorder}`,
                }
              : {
                  height: 24,
                  width: 24,
                  color: 'action.active',
                }),
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...props}
      >
        {url ? (
          <Box
            sx={{
              backgroundImage: `url(${url}})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              height: '100%',
              width: '100%',
            }}
          />
        ) : (
          <>{name ? generateLogoByName(name) : ''}</>
        )}
      </Avatar>
    </>
  );
};

export default MerchantLogo;
