import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CountryFlag from 'components/CountryFlag';
import FormatMoney from 'components/FormatMoney';
import {
  MerchantLogo,
  MerchantSplitTypeBadge,
  MerchantStatusBadge,
} from 'domains/merchant/components';
import {
  Box,
  DataGridCell,
  drawerPlaceholderColumn,
  GridColDef,
  Typography,
} from 'elements';
import { Merchant } from 'services/constants';
import MerchantLegalNameAddressCell from './MerchantLegalNameAddressCell';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo<GridColDef<Merchant>[]>(
    () => [
      {
        headerName: t('int.merchantsPage.logoName'),
        field: 'displayName',
        sortable: false,
        flex: 242,
        minWidth: 242,
        renderCell: ({ row }) => (
          <Box display="flex" alignItems="center" overflow="hidden">
            <MerchantLogo url={row.logoPath} name={row.displayName} />
            <Typography variant="body2" noWrap sx={{ ml: 2 }}>
              {row.displayName}
            </Typography>
            <MerchantSplitTypeBadge
              value={row.splitType}
              sx={{ position: 'relative', top: -5, ml: 1 }}
            />
          </Box>
        ),
      },
      {
        headerName: t('int.merchantsPage.status'),
        field: 'status',
        sortable: false,
        flex: 134,
        minWidth: 134,
        renderCell: ({ row }) => <MerchantStatusBadge status={row.status} />,
      },
      {
        headerName: t('int.merchantsPage.legalNameAddress'),
        field: 'legalName',
        sortable: false,
        flex: 420,
        minWidth: 200,
        renderCell: ({ row }) => (
          <MerchantLegalNameAddressCell
            legalName={row.legalName}
            street={row.street}
            postalCode={row.postalCode}
            city={row.city}
            state={row.state}
          />
        ),
      },
      {
        headerName: t('int.merchantsPage.country'),
        field: 'country',
        sortable: false,
        flex: 86,
        minWidth: 86,
        renderCell: ({ row }) => (
          <>
            <CountryFlag code={row.country} mr={1} />
            <Typography variant="body2" color="text.primary" noWrap>
              {row.country}
            </Typography>
          </>
        ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('int.merchantsPage.transactions')}
            <Typography
              noWrap
              variant="caption2"
              color="text.secondary"
              component="div"
              textAlign="right"
            >
              {t('int.merchantsPage.transactionsWithReceipts')}
            </Typography>
          </DataGridCell>
        ),
        field: 'transactionsCount',
        sortable: false,
        flex: 120,
        minWidth: 120,
        renderCell: ({ row }) => (
          <Box overflow="hidden" textAlign="right">
            <Typography variant="body2" color="text.primary">
              {row.transactionsCount}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {row.transactionsWithReceiptsCount}
            </Typography>
          </Box>
        ),
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.merchantsPage.totalVolume'),
        field: 'transactionsVolume',
        sortable: false,
        flex: 174,
        minWidth: 174,
        renderCell: ({ row }) => (
          <FormatMoney value={row.transactionsVolume} fractionalPart />
        ),
        headerAlign: 'right',
        align: 'right',
      },
      drawerPlaceholderColumn,
    ],
    [i18n.language]
  );
};

export default useColumns;
