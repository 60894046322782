import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { CardAccount, NetworkErrorCode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { getNetworkErrorCode } from 'services/utils/network';

const getAccountNumberFromIban = (str: string) => str.substring(12);

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (cardProcessingAccountIban: string) => void;
  cardAccount: CardAccount;
}

interface State {
  isLoading: boolean;
  errorMessage: string;
}

const AssignAccountDialog = ({ onSuccess, cardAccount, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [accountNumber, setAccountNumber] = useState(() => {
    return cardAccount.iban.value
      ? getAccountNumberFromIban(cardAccount.iban.value)
      : '';
  });
  const [state, setState] = useState<State>({
    isLoading: false,
    errorMessage: '',
  });

  const isFormValid = useMemo(() => {
    if (accountNumber.length === 10) return true;
  }, [accountNumber]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isFormValid) return;
    setState((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const { iban } = await api.assignCardProcessingAccountBalance(
        organization!.id,
        cardAccount.id,
        accountNumber
      );
      const updatedCardAccounts = await api.getCardAccounts(organization!.id);
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          cardAccounts: updatedCardAccounts.cardAccounts,
          defaultCardAccount: updatedCardAccounts.cardAccounts.find(
            (item) => item.defaultAccount.value
          )!,
        },
      });

      if (!mounted.current) return;
      onSuccess(iban);
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      const errorCode = getNetworkErrorCode(error);
      let errorMessage = '';
      if (errorCode === NetworkErrorCode.incorrectAccountNumber) {
        errorMessage = t('assignAccountDialog.incorrectAccountNumberError');
      } else if (errorCode === NetworkErrorCode.incorrectOrganizationState) {
        errorMessage = t('assignAccountDialog.incorrectOrganizationError');
      } else if (errorCode === NetworkErrorCode.ibanIsAlreadyInUse) {
        errorMessage = t('assignAccountDialog.ibanInUseError');
      } else if (errorCode === NetworkErrorCode.ibanGenerationError) {
        errorMessage = t('assignAccountDialog.ibanGenerationError');
      }

      if (!errorMessage) {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }

      setState((prevState) => ({
        ...prevState,
        errorMessage,
        isLoading: false,
      }));
    }
  };

  const onAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '' || /^[0-9]+$/.test(value)) {
      setAccountNumber(e.target.value);
      if (state.errorMessage)
        setState((prevState) => ({ ...prevState, errorMessage: '' }));
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('assignAccountDialog.title')}</DialogTitle>
      <DialogContent>
        <Box mb={4}>
          <Typography variant="body2">
            {t('assignAccountDialog.description')}
          </Typography>
        </Box>
        <form onSubmit={onSubmit} noValidate id="assign-account-form">
          <TextField
            name="accountNumber"
            label={t('assignAccountDialog.inputTitle')}
            value={accountNumber}
            onChange={onAccountNumberChange}
            inputProps={{ maxLength: 10 }}
            error={!!state.errorMessage}
            autoFocus
            disabled={state.isLoading}
            helperText={!!state.errorMessage && state.errorMessage}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          type="submit"
          disabled={!isFormValid}
          data-test-id="assign-account-submit"
          form="assign-account-form"
        >
          {t('assignAccountDialog.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(AssignAccountDialog);
