import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  CalendarBankIcon,
  CalendarCheckIcon,
  CalendarReversalIcon,
  Grid,
  Tooltip,
  Typography,
} from 'elements';
import { Transaction } from 'services/constants';

interface Props {
  transaction: Transaction;
}

const TransactionDatesInfo = ({ transaction }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('transactionDetailsHeader.createdAt')}>
        <Grid item display="flex" alignItems="center">
          <CalendarCheckIcon color="action" />
          <Typography variant="caption" data-test-id="tx-created-at" ml={1}>
            {moment(transaction.createdAt).format('D MMM YYYY, HH:mm')}
          </Typography>
        </Grid>
      </Tooltip>

      {transaction.bookingDate && (
        <Tooltip title={t('transactionDetailsHeader.bookingDate')}>
          <Grid item display="flex" alignItems="center">
            <CalendarBankIcon color="action" />
            <Typography variant="caption" data-test-id="tx-booking-date" ml={1}>
              {moment(transaction.bookingDate).format('D MMM YYYY')}
            </Typography>
          </Grid>
        </Tooltip>
      )}

      {transaction.reversedAt && (
        <Tooltip title={t('transactionDetailsHeader.reversalDate')}>
          <Grid item display="flex" alignItems="center">
            <CalendarReversalIcon color="action" />
            <Typography variant="caption" ml={1}>
              {moment(transaction.reversedAt).format('D MMM YYYY, HH:mm')}
            </Typography>
          </Grid>
        </Tooltip>
      )}
    </>
  );
};

export default TransactionDatesInfo;
