import React from 'react';
import { Box } from '@material-ui/core';
import { omit } from 'lodash';
import moment from 'moment';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { OnboardingDocument } from 'services/constants';
import { useCanUser } from 'services/rbac';

const UnorderedList = styled.ul`
  margin: 0;
  padding-inline-start: 24px;
`;

const SecondaryLabel = styled.div`
  color: ${({ theme }) => theme.colors.neutralVariant10};
  font-size: 12px;
  line-height: 16px;
`;

interface Props {
  altName?: string;
  attributes: OnboardingDocument['attributes'];
  customLabel: OnboardingDocument['customLabel'];
  customLabelLocal?: OnboardingDocument['customLabelLocal'];
  name: OnboardingDocument['type']['name'] | string;
}

const OnboardingDocumentTitle = ({
  altName,
  attributes,
  customLabel,
  customLabelLocal,
  name,
}: Props) => {
  const canUser = useCanUser();

  if (customLabel || customLabelLocal) {
    return (
      <Box>
        {customLabelLocal && <div>{customLabelLocal}</div>}
        {canUser('org-onboarding-questions:internal-view') && customLabel && (
          <SecondaryLabel>{customLabel}</SecondaryLabel>
        )}
      </Box>
    );
  }

  if (!name && altName) return <span>{altName}</span>;

  return (
    <span>
      {
        <Trans
          i18nKey={`onboardingDocumentType.${name}`}
          values={{
            ...omit(attributes, ['fromMonthYear', 'toMonthYear']),
            ...(attributes['fromMonthYear'] && {
              fromMonthYear: moment(attributes.fromMonthYear).format(
                'MMM yyyy'
              ),
            }),
            ...(attributes['toMonthYear'] && {
              toMonthYear: moment(attributes.toMonthYear).format('MMM yyyy'),
            }),
          }}
          components={{ b: <b />, li: <li />, ul: <UnorderedList /> }}
        />
      }
    </span>
  );
};

export default OnboardingDocumentTitle;
