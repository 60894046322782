import React from 'react';
import { Container, ContainerInner, MainContent } from 'components/App/style';
import { CardholderMainHeader } from 'components/MainHeader';

const CardholderLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container>
      <ContainerInner>
        <CardholderMainHeader />
        <MainContent>{children}</MainContent>
      </ContainerInner>
    </Container>
  );
};

export default CardholderLayout;
