import { useMemo } from 'react';
import { TeamStatus } from 'services/constants';
import { useGlobalState } from './index';

const useActiveTeams = () => {
  const {
    state: { teams },
  } = useGlobalState();

  return useMemo(
    () => teams.filter((item) => item.teamStatus === TeamStatus.active),
    [teams]
  );
};

export default useActiveTeams;
