import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { PAYMENT_TERMS } from 'domains/creditAndCompliance/constants';
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer } from 'layout';
import { PaymentDeferment } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import PaymentFrequencySection from './PaymentFrequencySection';

interface Props {
  paymentDeferment: PaymentDeferment;
  onUpdate: (paymentDeferment: PaymentDeferment) => void;
}

const PaymentTermsSection = ({ paymentDeferment, onUpdate }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const {
    state: { organization, organizationsStaticData },
    dispatch,
  } = useGlobalState();
  const [pmt, setPmt] = useState(organization!.paymentDefermentInDays);
  const [isLoading, setIsLoading] = useState(false);

  const handlePaymentDefermentChange = async (e: SelectChangeEvent<number>) => {
    const value = e.target.value as number;
    try {
      setPmt(value);
      setIsLoading(true);
      const updatedOrg = await api.updatePaymentDeferment(
        organization!.id,
        value
      );
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { organization: updatedOrg },
      });
      const data = await api.getPaymentDeferment(organization!.id);
      if (!mounted.current) return;
      onUpdate(data);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setPmt(organization!.paymentDefermentInDays);
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <ContentContainer py={5}>
        <Typography variant="h6" mb={1}>
          {t('int.generalTermsPage.paymentTermsSection.title')}
        </Typography>
        <Typography mb={3}>
          {t('int.generalTermsPage.paymentTermsSection.description')}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormControl
              disabled={isLoading || !canUser('org-payment-deferment:change')}
              fullWidth
            >
              <Select<number>
                name="paymentTermsFrequency"
                value={pmt}
                onChange={handlePaymentDefermentChange}
              >
                {organizationsStaticData!.paymentTerms.map((item) => (
                  <MenuItem
                    key={item.name}
                    value={
                      item.name in PAYMENT_TERMS
                        ? PAYMENT_TERMS[item.name as keyof typeof PAYMENT_TERMS]
                        : item.name
                    }
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ whiteSpace: 'nowrap' }}>
                {t('int.common.lastEditedBy', {
                  name: paymentDeferment.changedByEmail,
                  time: moment(paymentDeferment.changedAt).format(
                    'DD MMM YYYY, HH:mm'
                  ),
                })}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Box mt={2}>
          <PaymentFrequencySection />
        </Box>
      </ContentContainer>

      <Divider />
    </>
  );
};

export default PaymentTermsSection;
