import * as currencies from '@dinero.js/currencies';
import { Currency } from '@dinero.js/currencies';
import { dinero, Dinero, toSnapshot, transformScale } from 'dinero.js';
import { Money } from 'services/constants';
import { logError } from 'services/monitoring';

export const isMoney = (obj: any): obj is Money =>
  typeof obj?.currency === 'string';

export const getMoneyObject = (value: number): Money => {
  return {
    value,
    currency: 'EUR',
  };
};

export const getMoneyWithOppositeSign = ({ value, currency }: Money) => ({
  value: value === 0 ? 0 : -value,
  currency,
});

// Note that it's not 100% accurate and can return a wrong
// number of fractional digits if there are too many of them (~10-15+).
// https://stackoverflow.com/a/27865285
const getFractionalDigits = (value: number) => {
  if (!isFinite(value)) return 0;

  let e = 1;
  let fd = 0;
  while (Math.round(value * e) / e !== value) {
    e *= 10;
    fd++;
  }

  return fd;
};

export const getCurrencyFractionalDigits = (currency: string) =>
  Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency,
  }).resolvedOptions().minimumFractionDigits;

interface FormatMoneyConfig {
  /**
   * Use the currency symbol or the currency code.
   * This option is required on purpose to avoid using the default value accidentally.
   */
  currencyDisplay: 'narrowSymbol' | 'code';
  /**
   * Display the fractional (decimal) part or round and display the amount as an integer.
   * @default false
   */
  fractionalPart?: boolean;
  /**
   * Display the "+" sign for positive amounts. Mostly used for balance changes.
   * @default false
   */
  positiveSign?: boolean;
}

/**
 * Using the FormatMoney component is preferred over this function
 * because it automatically sets the locale and currencyDisplay options.
 */
export const formatMoney = (
  { value, currency }: Money,
  locale: string,
  {
    currencyDisplay,
    fractionalPart = false,
    positiveSign = false,
  }: FormatMoneyConfig
) => {
  const fractionDigits = getCurrencyFractionalDigits(currency);
  const fractionCoefficient = 10 ** fractionDigits;
  const positiveSignPart = positiveSign && value >= 0 ? '+' : '';

  if (currencyDisplay === 'narrowSymbol') {
    return (
      positiveSignPart +
      (value / fractionCoefficient).toLocaleString(locale, {
        style: 'currency',
        currency,
        currencyDisplay,
        minimumFractionDigits: fractionalPart ? fractionDigits : 0,
        maximumFractionDigits: fractionalPart ? fractionDigits : 0,
      })
    );
  }

  return (
    positiveSignPart +
    (value / fractionCoefficient).toLocaleString(locale, {
      minimumFractionDigits: fractionalPart ? fractionDigits : 0,
      maximumFractionDigits: fractionalPart ? fractionDigits : 0,
    }) +
    '\u00A0' + // non-breaking space
    currency
  );
};

export const formatFXAmount = (
  amount: Money,
  fxAmount: Money,
  locale: string
) => {
  if (amount.currency === fxAmount.currency) return '';

  return formatMoney(fxAmount, locale, {
    currencyDisplay: 'code',
    fractionalPart: true,
    positiveSign: true,
  });
};

// From Dinero.js documentation:
// "If you need to use fractional rates, you shouldn't use floats, but scaled amounts instead.
// For example, instead of passing 0.89, you should pass { amount: 89, scale: 2 }."
// https://v2.dinerojs.com/docs/api/conversions/convert
// Same applies for multiplication.
// This function is used to convert a float amount to a Dinero.js compatible scaled amount.
// Note that this function can round amounts with many digits after the floating point
// (see the comment above the getFractionalDigits function).
export const getDineroScaledAmount = (amount: number) => {
  const fractionalDigits = getFractionalDigits(amount);

  return {
    amount: amount * 10 ** fractionalDigits,
    scale: fractionalDigits,
  };
};

export const getCurrencyByCode = (code: string): Currency<number> => {
  let currency = currencies[code as keyof typeof currencies];

  if (!currency) {
    currency = {
      code,
      // There are only two non-decimal currencies currently circulating in the world,
      // and they are both provided by Dinero.js
      // (source: https://en.wikipedia.org/wiki/Malagasy_ariary)
      base: 10,
      exponent: getCurrencyFractionalDigits(code),
    };
    logError(`Currency not found: ${code}`, {
      generatedCurrency: currency,
    });
  }

  return currency;
};

/**
 * Create a Dinero object from a floating-point number. The number can be represented as a string -
 * it will be parsed to a float, and if it's not a valid number, the amount will be set to 0.
 * Note that amount is rounded to the currency's minor units.
 *
 * @example
 * dineroFromFloat(0.12, 'EUR').toJSON()
 * // {amount: 12, currency: {…}, scale: 2}
 *
 * @example
 * dineroFromFloat('123.4567', 'EUR').toJSON()
 * // {amount: 12346, currency: {…}, scale: 2}
 *
 * @example
 * dineroFromFloat('not a valid number', 'EUR').toJSON()
 * // {amount: 0, currency: {…}, scale: 2}
 *
 */
export const dineroFromFloat = (
  _amount: number | string,
  _currency: Currency<number> | string
) => {
  const amount = typeof _amount === 'string' ? parseFloat(_amount) : _amount;
  const currency =
    typeof _currency === 'string' ? getCurrencyByCode(_currency) : _currency;

  if (!isFinite(amount)) return dinero({ amount: 0, currency });

  const factor = (currency.base as number) ** currency.exponent;
  const amountInMinorUnits = Math.round(amount * factor);

  return dinero({ amount: amountInMinorUnits, currency });
};

export const dineroFromMoney = ({ value, currency: currencyCode }: Money) => {
  const currency = getCurrencyByCode(currencyCode);

  return dinero({ amount: value, currency });
};

export const convertDineroToMoney = (dineroObject: Dinero<number>) => {
  const { currency } = toSnapshot(dineroObject);
  const { amount } = toSnapshot(
    transformScale(dineroObject, currency.exponent)
  );

  return { value: amount, currency: currency.code };
};
