import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  FormControl,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  paymentSyncEnabled: boolean;
}

const LocoiaExportSettingsGroup = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
    paymentSyncEnabled: accountingSettings!.paymentSyncEnabled,
  });

  const updatePaymentSync = async (value: boolean) => {
    try {
      setState((prevState) => ({
        ...prevState,
        paymentSyncEnabled: value,
        isLoading: true,
      }));

      const data = await api.updateAccountingSettings({
        organizationId: organization!.id,
        vatRateEnabled: accountingSettings!.vatRateEnabled,
        subcategoryEnabled: accountingSettings!.subcategoryEnabled,
        commentEnabled: accountingSettings!.commentEnabled,
        useAccountingExport: accountingSettings!.useAccountingExport,
        paymentSyncEnabled: value,
      });

      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { accountingSettings: data },
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        paymentSyncEnabled: !value,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), {
        variant: 'error',
      });
      logError(error);
    }
  };

  return (
    // use table to be consistent between all settings groups for acc systems
    <Table sx={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow>
          <TableCell>
            {t('locoiaIntegrationSubPage.exportSettings.title')}
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow>
          <TableCell>
            <FormControl fullWidth>
              <FormControlLabel
                disabled={
                  !canUser('accounting-settings:update') || state.isLoading
                }
                checked={state.paymentSyncEnabled}
                onChange={(e, checked) => updatePaymentSync(checked)}
                control={<Switch />}
                label={t(
                  'locoiaIntegrationSubPage.exportSettings.switchPaymentsAutoSync'
                )}
                labelPlacement="start"
              />
            </FormControl>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default LocoiaExportSettingsGroup;
