import React from 'react';
import { Grid, GridProps, Typography } from 'elements';

interface Props {
  label: React.ReactNode;
  value: React.ReactNode;
  labelXsGridSize?: GridProps['xs'];
}

const LabelValueItem = ({ label, value, labelXsGridSize = 4 }: Props) => (
  <Grid container item>
    <Grid
      item
      xs={typeof labelXsGridSize === 'number' ? labelXsGridSize : undefined}
    >
      <Typography color="text.secondary" component="div" variant="body2">
        {label}
      </Typography>
    </Grid>
    <Grid
      item
      xs={
        typeof labelXsGridSize === 'number' ? 12 - labelXsGridSize : undefined
      }
    >
      <Typography component="div" variant="body2" flexGrow={1} maxWidth="50%">
        {value}
      </Typography>
    </Grid>
  </Grid>
);
export default LabelValueItem;
