import React from 'react';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from 'elements';
import { DirectDebitInfo } from 'services/constants';

interface Props {
  directDebitInfo: DirectDebitInfo;
}

const SepaConfirmedInfo = ({ directDebitInfo }: Props) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {directDebitInfo.activationConfirmations.map((confirmation) => (
            <TableRow key={confirmation.memberId}>
              <TableCell sx={{ width: '50%' }}>
                {confirmation.name}
                <br />
                {confirmation.email}
              </TableCell>
              <TableCell>
                {moment(confirmation.confirmedAt).format('D MMM YYYY')}
                <br />
                {moment(confirmation.confirmedAt).format('HH:mm')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SepaConfirmedInfo;
