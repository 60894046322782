import React, { CSSProperties } from 'react';
import Overlay, { OverlayProps } from 'components/Overlay';
import Loader, { LoaderProps } from './Loader';

interface LoaderWithOverlayProps extends OverlayProps, LoaderProps {
  style?: CSSProperties;
}

/**@deprecated Use LoaderWithOverlay imported from 'elements' in new design */
export const LoaderWithOverlay = ({
  style,
  $top,
  $bottom,
  ...loaderProps
}: LoaderWithOverlayProps) => {
  return (
    <Overlay style={style} $top={$top} $bottom={$bottom}>
      <Loader {...loaderProps} />
    </Overlay>
  );
};
