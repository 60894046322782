import { ChangeEvent } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  LoaderWithOverlay,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Organization } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { validateVatIdByCountry } from 'services/utils/validators';

interface Props extends DialogProps {
  isVatIdRequired: boolean;
  onSuccess: (organization: Organization, vatIdRequired: boolean) => void;
  onClose?: () => void;
}

const YES = 'yes';
const NO = 'no';

const VatIdChangeDialog = ({
  isVatIdRequired: initialIsVatIdRequired,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      isVatIdRequired: organization!.vatId || initialIsVatIdRequired ? YES : NO,
      vatId: organization!.vatId ?? '',
    },
    validate: ({ vatId, isVatIdRequired }) => {
      if (isVatIdRequired === YES) {
        const vatIdError = validateVatIdByCountry(
          organization!.country,
          vatId,
          t
        );
        if (vatIdError) {
          return {
            vatId: vatIdError,
          };
        }
      }

      return {};
    },
    onSubmit: async (values) => {
      try {
        const updatedOrganization = await api.updateVatId(
          organization!.id,
          values.isVatIdRequired === YES
            ? { vatIdRequired: true, vatId: values.vatId }
            : { vatIdRequired: false, vatId: '' }
        );
        onSuccess(updatedOrganization, values.isVatIdRequired === YES);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const onFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(e.target.name, e.target.value, false);
    formik.setFieldError(e.target.name, undefined);
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('settingsOrganizationPage.vat')}</DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="vat-id-change-dialog"
          noValidate
        >
          <Typography mb={1}>
            {t('settingsOrganizationPage.isVatIdRequired')}
          </Typography>

          <FormControl disabled={formik.isSubmitting} sx={{ mb: 1 }}>
            <RadioGroup
              name="isVatIdRequired"
              value={formik.values.isVatIdRequired}
              onChange={onFieldChange}
              row
            >
              {[YES, NO].map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={t(`common.${option}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>

          {formik.values.isVatIdRequired === YES && (
            <TextField
              label={t('vatIdForm.vatIdField')}
              name="vatId"
              value={formik.values.vatId}
              onChange={onFieldChange}
              disabled={formik.isSubmitting}
              autoFocus
              error={!!formik.errors.vatId}
              helperText={!!formik.errors.vatId && formik.errors.vatId}
            />
          )}
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          disabled={formik.isSubmitting}
          onClick={props.onClose}
        >
          {t('common.button.cancel')}
        </Button>

        <Button
          form="vat-id-change-dialog"
          type="submit"
          disabled={formik.isSubmitting}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(VatIdChangeDialog);
