import { useEffect } from 'react';
import { useCardholderAppLink } from 'context/GlobalState';

interface Props {
  children: React.ReactNode;
}

const CardholderRedirect = ({ children }: Props) => {
  const {
    isCardholderAppDeactivated,
    cardholderAppLink,
  } = useCardholderAppLink();

  useEffect(() => {
    if (isCardholderAppDeactivated) window.location.href = cardholderAppLink;
  }, [isCardholderAppDeactivated]);

  // avoid showing wallet app before redirection
  if (isCardholderAppDeactivated) return null;

  return <>{children}</>;
};

export default CardholderRedirect;
