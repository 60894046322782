import React, { ReactNode } from 'react';
import { uniqBy } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import accountingRulesHero from 'assets/images/featureModuleHeroImages/accountingRules.jpg';
import cardControlsHero from 'assets/images/featureModuleHeroImages/cardControls.png';
import cardRequestsHero from 'assets/images/featureModuleHeroImages/cardRequests.jpg';
import managerTxReviewsHero from 'assets/images/featureModuleHeroImages/managerTxReviews.jpg';
import featureModuleMonthlyTransactionReportHero from 'assets/images/featureModuleHeroImages/monthlyTransactionReport.png';
import multiCardAccountHero from 'assets/images/featureModuleHeroImages/multiCardAccount.jpg';
import multiCurrencyBillingHero from 'assets/images/featureModuleHeroImages/multiCurrencyBilling.png';
import pliantEarthHero from 'assets/images/featureModuleHeroImages/pliantEarth.png';
import privateExpensesHero from 'assets/images/featureModuleHeroImages/privateExpenses.jpg';
import receiptManagementHero from 'assets/images/featureModuleHeroImages/receiptManagement.jpg';
import securityKeyAuthenticationFieldsHero from 'assets/images/featureModuleHeroImages/securityKeyAuthentication.jpg';
import singleUseCardsHero from 'assets/images/featureModuleHeroImages/singleUseCards.jpg';
import teamBudgetsHero from 'assets/images/featureModuleHeroImages/teamBudgets.jpg';
import teamsHero from 'assets/images/featureModuleHeroImages/teams.jpg';
import transactionCustomFieldsHero from 'assets/images/featureModuleHeroImages/transactionCustomFields.jpg';
import accountingRulesImage from 'assets/images/featureModuleTiles/accountingRules.png';
import cardControlsImage from 'assets/images/featureModuleTiles/cardControls.png';
import cardRequestsImage from 'assets/images/featureModuleTiles/cardRequests.png';
import managerTxReviewsImage from 'assets/images/featureModuleTiles/managerTxReviews.png';
import featureModuleMonthlyTransactionReportImage from 'assets/images/featureModuleTiles/monthlyTransactionReport.png';
import multiCardAccountImage from 'assets/images/featureModuleTiles/multiCardAccount.png';
import multiCurrencyBillingImage from 'assets/images/featureModuleTiles/multiCurrencyBilling.png';
import pliantEarthImage from 'assets/images/featureModuleTiles/pliantEarth.png';
import privateExpensesImage from 'assets/images/featureModuleTiles/privateExpenses.png';
import receiptManagementImage from 'assets/images/featureModuleTiles/receiptManagement.png';
import securityKeyAuthenticationImage from 'assets/images/featureModuleTiles/securityKeyAuthentication.png';
import singleUseCardsImage from 'assets/images/featureModuleTiles/singleUseCards.png';
import teamBudgetsImage from 'assets/images/featureModuleTiles/teamBudgets.png';
import teamsImage from 'assets/images/featureModuleTiles/teams.png';
import transactionCustomFieldsImage from 'assets/images/featureModuleTiles/transactionCustomFields.png';
import { useActiveTeams, useGlobalState } from 'context/GlobalState';
import { useCardAccountCurrency } from 'domains/card/hooks';
import useUrls from 'hooks/useUrls';
import { AccountGroup, FeatureModuleKey } from 'services/constants';
import { useFlags } from 'services/featureflags';

export interface ModuleTile {
  isActive: boolean;
  isHidden?: boolean;
  isPremium?: boolean;
  key: FeatureModuleKey;
  tileImage: string;
  dialogHeroImage: string;
  helpCenterUrl?: string;
  disabledReason?: ReactNode;
  // Remove deactivateConfirmationDescription from the ModuleTile interface
  // once all keys are translated.
  // The cleaner way is to use i18n.exists to check if the key exists and then
  // use it in the dialog.
  deactivateConfirmationDescription?: string;
  beta?: boolean;
}

const useModuleTiles = (): ModuleTile[] => {
  const { t } = useTranslation();
  const {
    state: {
      cardAccounts,
      featureModules,
      featureModulesWithData,
      defaultCardAccount,
    },
  } = useGlobalState();
  const {
    managerTransactionReviewEnabled,
    privateExpense,
    multiCurrencyBillingEnabled,
    securityKeyAuthenticationEnabled,
  } = useFlags();
  const currency = useCardAccountCurrency();
  const activeTeams = useActiveTeams();
  const {
    HELP_CENTER_CARD_CONTROLS_URL,
    HELP_CENTER_CARD_REQUESTS_URL,
    HELP_CENTER_CARD_TYPES_URL,
    HELP_CENTER_TEAMS_URL,
    HELP_CENTER_MANAGER_TX_REVIEWS_URL,
    HELP_CENTER_TEAM_BUDGETS_URL,
    HELP_CENTER_PLIANT_EARTH_URL,
    HELP_CENTER_ACCOUNTING_MERCHANT_RULES_URL,
    HELP_CENTER_MONTHLY_TRANSACTION_REPORT_URL,
    HELP_CENTER_RECEIPT_MANAGEMENT_URL,
    HELP_CENTER_CUSTOM_FIELDS_URL,
    HELP_CENTER_PAIRING_SECURITY_KEY_URL,
  } = useUrls();

  const getTeamBudgetsDisabledReason = () => {
    if (!featureModules.TEAM_BUDGETS && !featureModules.TEAMS)
      return t(
        'manageFeatureModuleDialog.disabledReasons.teamBudgetsActivateTeams'
      );

    const doActiveBudgetsExist = activeTeams.some(
      (item) => item.budget && item.budget.value > 0
    );
    if (featureModules.TEAM_BUDGETS && doActiveBudgetsExist) {
      return t(
        'manageFeatureModuleDialog.disabledReasons.teamBudgetsDeactivateBudgets'
      );
    }

    return null;
  };

  const getTeamsDisabledReason = () =>
    featureModules.TEAMS && !!activeTeams.length ? (
      <Trans
        i18nKey="manageFeatureModuleDialog.disabledReasons.teamsDeactivateTeams"
        components={{
          a: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              style={{ color: 'inherit' }}
              href={HELP_CENTER_TEAMS_URL}
              target="_blank"
              rel="noopener noreferrer"
            />
          ),
        }}
      />
    ) : null;

  const getMultiCurrencyBillingDisabledReason = () => {
    if (
      featureModules.MULTI_CURRENCY_BILLING &&
      cardAccounts.length > 1 &&
      uniqBy(cardAccounts, (item) => item.currency.value).length > 1
    )
      return t(
        'manageFeatureModuleDialog.disabledReasons.multiCurrencyBillingContactSupport'
      );

    return null;
  };

  const getMultiCardAccountDisabledReason = () => {
    if (featureModules.MULTI_CARD_ACCOUNT && cardAccounts.length > 1)
      return t(
        'manageFeatureModuleDialog.disabledReasons.multiCardAccountContactSupport'
      );

    return null;
  };

  // hidden when FM is inactive and disabled by partner
  const isHiddenFromPartner = (key: FeatureModuleKey) =>
    !featureModules[key] && featureModulesWithData[key].disabledByPartner;

  return [
    {
      isActive: featureModules.CARD_REQUESTS,
      isHidden: isHiddenFromPartner(FeatureModuleKey.cardRequests),
      key: FeatureModuleKey.cardRequests,
      tileImage: cardRequestsImage,
      dialogHeroImage: cardRequestsHero,
      helpCenterUrl: HELP_CENTER_CARD_REQUESTS_URL,
      deactivateConfirmationDescription: t(
        'manageFeatureModuleDialog.deactivateCardRequestsConfirmationDescription'
      ),
    },
    {
      isActive: featureModules.SINGLE_USE_CARDS,
      isHidden: isHiddenFromPartner(FeatureModuleKey.singleUseCards),
      key: FeatureModuleKey.singleUseCards,
      tileImage: singleUseCardsImage,
      dialogHeroImage: singleUseCardsHero,
      helpCenterUrl: HELP_CENTER_CARD_TYPES_URL,
    },
    {
      isActive: featureModules.CARD_CONTROLS,
      isHidden: isHiddenFromPartner(FeatureModuleKey.cardControls),
      isPremium: true,
      key: FeatureModuleKey.cardControls,
      tileImage: cardControlsImage,
      dialogHeroImage: cardControlsHero,
      helpCenterUrl: HELP_CENTER_CARD_CONTROLS_URL,
      deactivateConfirmationDescription: t(
        'manageFeatureModuleDialog.deactivateCardControlsConfirmationDescription'
      ),
    },
    {
      isActive: featureModules.ACCOUNTING_MERCHANT_RULES,
      isHidden: isHiddenFromPartner(FeatureModuleKey.accountingMerchantRules),
      isPremium: true,
      key: FeatureModuleKey.accountingMerchantRules,
      tileImage: accountingRulesImage,
      dialogHeroImage: accountingRulesHero,
      helpCenterUrl: HELP_CENTER_ACCOUNTING_MERCHANT_RULES_URL,
    },
    {
      isActive: featureModules.TEAMS,
      isHidden: isHiddenFromPartner(FeatureModuleKey.teams),
      isPremium: true,
      key: FeatureModuleKey.teams,
      tileImage: teamsImage,
      dialogHeroImage: teamsHero,
      helpCenterUrl: HELP_CENTER_TEAMS_URL,
      disabledReason: getTeamsDisabledReason(),
    },
    {
      isActive: featureModules.MANAGER_TX_REVIEWS,
      isHidden:
        !managerTransactionReviewEnabled ||
        isHiddenFromPartner(FeatureModuleKey.managerTxReviews),
      isPremium: true,
      key: FeatureModuleKey.managerTxReviews,
      tileImage: managerTxReviewsImage,
      dialogHeroImage: managerTxReviewsHero,
      helpCenterUrl: HELP_CENTER_MANAGER_TX_REVIEWS_URL,
    },
    {
      isActive: featureModules.TEAM_BUDGETS,
      isHidden:
        !featureModules.TEAMS ||
        isHiddenFromPartner(FeatureModuleKey.teamBudgets),
      isPremium: true,
      key: FeatureModuleKey.teamBudgets,
      tileImage: teamBudgetsImage,
      dialogHeroImage: teamBudgetsHero,
      helpCenterUrl: HELP_CENTER_TEAM_BUDGETS_URL,
      disabledReason: getTeamBudgetsDisabledReason(),
    },
    {
      isActive: featureModules.MONTHLY_TRANSACTION_REPORT,
      isHidden: isHiddenFromPartner(FeatureModuleKey.monthlyTransactionReport),
      isPremium: true,
      key: FeatureModuleKey.monthlyTransactionReport,
      tileImage: featureModuleMonthlyTransactionReportImage,
      dialogHeroImage: featureModuleMonthlyTransactionReportHero,
      helpCenterUrl: HELP_CENTER_MONTHLY_TRANSACTION_REPORT_URL,
      deactivateConfirmationDescription: t(
        'manageFeatureModuleDialog.deactivateMonthlyTransactionReportConfirmationDescription'
      ),
    },
    {
      isActive: featureModules.MULTI_CURRENCY_BILLING,
      isHidden:
        !multiCurrencyBillingEnabled ||
        defaultCardAccount!.accountGroup.value !== AccountGroup.bcPliantOy ||
        isHiddenFromPartner(FeatureModuleKey.multiCurrencyBilling),
      isPremium: true,
      key: FeatureModuleKey.multiCurrencyBilling,
      tileImage: multiCurrencyBillingImage,
      dialogHeroImage: multiCurrencyBillingHero,
      disabledReason: getMultiCurrencyBillingDisabledReason(),
      beta: true,
    },
    {
      isActive: featureModules.MULTI_CARD_ACCOUNT,
      isHidden: isHiddenFromPartner(FeatureModuleKey.multiCardAccount),
      isPremium: true,
      key: FeatureModuleKey.multiCardAccount,
      tileImage: multiCardAccountImage,
      dialogHeroImage: multiCardAccountHero,
      disabledReason: getMultiCardAccountDisabledReason(),
      beta: true,
    },
    {
      isActive: featureModules.PLIANT_EARTH,
      isHidden:
        currency.code !== 'EUR' ||
        !featureModules.CASHBACK ||
        isHiddenFromPartner(FeatureModuleKey.pliantEarth),
      key: FeatureModuleKey.pliantEarth,
      tileImage: pliantEarthImage,
      dialogHeroImage: pliantEarthHero,
      helpCenterUrl: HELP_CENTER_PLIANT_EARTH_URL,
    },
    {
      isActive: featureModules.RECEIPT_MANAGEMENT,
      isHidden: isHiddenFromPartner(FeatureModuleKey.receiptManagement),
      key: FeatureModuleKey.receiptManagement,
      tileImage: receiptManagementImage,
      dialogHeroImage: receiptManagementHero,
      deactivateConfirmationDescription: t(
        'manageFeatureModuleDialog.deactivateReceiptManagementConfirmationDescription'
      ),
      helpCenterUrl: HELP_CENTER_RECEIPT_MANAGEMENT_URL,
    },
    {
      isActive: featureModules.TRANSACTION_CUSTOM_FIELDS,
      isHidden: isHiddenFromPartner(FeatureModuleKey.transactionCustomFields),
      key: FeatureModuleKey.transactionCustomFields,
      tileImage: transactionCustomFieldsImage,
      dialogHeroImage: transactionCustomFieldsHero,
      isPremium: true,
      helpCenterUrl: HELP_CENTER_CUSTOM_FIELDS_URL,
    },
    {
      isActive: featureModules.PRIVATE_EXPENSE,
      isHidden:
        !privateExpense || isHiddenFromPartner(FeatureModuleKey.privateExpense),
      key: FeatureModuleKey.privateExpense,
      tileImage: privateExpensesImage,
      dialogHeroImage: privateExpensesHero,
      beta: true,
    },
    {
      isActive: featureModules.SECURITY_KEY_AUTHENTICATION,
      isHidden:
        !securityKeyAuthenticationEnabled ||
        isHiddenFromPartner(FeatureModuleKey.securityKeyAuthentication),
      key: FeatureModuleKey.securityKeyAuthentication,
      tileImage: securityKeyAuthenticationImage,
      dialogHeroImage: securityKeyAuthenticationFieldsHero,
      isPremium: true,
      helpCenterUrl: HELP_CENTER_PAIRING_SECURITY_KEY_URL,
    },
  ];
};

export default useModuleTiles;
