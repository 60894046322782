import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ReceiptList } from 'services/constants';
import useImperativeApi from 'services/network/useImperativeApi';

// max allowed time (10 sec)
const MAX_TIME = 10000;
export const LIMIT_REACHED_ERROR = 'requestsLimitReached';

interface Params {
  transactionId: string;
}

// wait for max 10 sec or until we get receipt number
const useWaitForReceiptDataExtraction = () => {
  const { transactionId } = useParams<{ transactionId: string }>();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const api = useImperativeApi();

  useEffect(() => {
    if (transactionId && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, [transactionId]);

  return useCallback(
    ({ transactionId: txId }: Params) =>
      new Promise<ReceiptList>((resolve, reject) => {
        const startTime = new Date().getTime();

        const fn = async () => {
          try {
            const data = await api.getReceipts(txId);

            if (
              new Date().getTime() - startTime > MAX_TIME ||
              data.receiptNumber ||
              data.receiptDate
            ) {
              resolve(data);
            } else {
              timeoutRef.current = setTimeout(fn, 2000);
            }
          } catch (e) {
            reject();
          }
        };
        fn();
      }),
    [api]
  );
};

export default useWaitForReceiptDataExtraction;
