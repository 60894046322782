import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ButtonLink } from 'components/Button';
import { useCanUser } from 'services/rbac';

const Container = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const ExpandableText = styled.div<{ isExpanded: boolean }>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: ${({ isExpanded }) => (isExpanded ? 'block' : '-webkit-box')};
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  width: 100%;
`;

const SecondaryDescription = styled.div`
  color: ${({ theme }) => theme.colors.neutralVariant10};
`;

interface Props {
  description: React.ReactNode;
  descriptionLocal?: React.ReactNode | null;
}

const OnboardingDocumentDescription = ({
  description,
  descriptionLocal,
}: Props) => {
  const canUser = useCanUser();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (textRef.current) {
      setIsExpandable(
        textRef.current.clientHeight < textRef.current.scrollHeight
      );
    }
  }, [description]);

  return (
    <Container>
      <ExpandableText ref={textRef} isExpanded={isExpanded}>
        {descriptionLocal ? (
          <div>{descriptionLocal}</div>
        ) : (
          <div>{description}</div>
        )}
        {canUser('org-onboarding-questions:internal-view') &&
          descriptionLocal && (
            <SecondaryDescription>{description}</SecondaryDescription>
          )}
      </ExpandableText>

      {isExpandable && (
        <ButtonLink onClick={() => setIsExpanded((prev) => !prev)}>
          {isExpanded
            ? t('common.button.showLess')
            : t('common.button.showMore')}
        </ButtonLink>
      )}
    </Container>
  );
};

export default OnboardingDocumentDescription;
