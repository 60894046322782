import { useTranslation } from 'react-i18next';
import { Step, StepConnector, StepIcon, StepLabel, Stepper } from 'elements';
import { useCodatStepperContext } from './useCodatStepperContext';

export const DIALOG_STEPPER_WIDTH = 230;

const CodatStepper = () => {
  const { t } = useTranslation();
  const {
    state: { step: activeStep },
  } = useCodatStepperContext();

  return (
    <Stepper
      activeStep={activeStep}
      connector={<StepConnector sx={{ visibility: 'hidden' }} />}
      orientation="vertical"
    >
      {[
        t('codatSyncSetupDialog.stepper.glAccountsStep'),
        t('codatSyncSetupDialog.stepper.vatRatesStep'),
        t('codatSyncSetupDialog.stepper.suppliersStep'),
        t('codatSyncSetupDialog.stepper.teamsStep'),
        t('codatSyncSetupDialog.stepper.projectsStep'),
      ].map((label) => (
        <Step key={label}>
          <StepLabel sx={{ padding: 0 }} StepIconComponent={StepIcon}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CodatStepper;
