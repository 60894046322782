import { Components, Theme } from '@mui/material';

const MuiAccordionOverrides: Components<Theme> = {
  MuiAccordion: {
    defaultProps: {
      elevation: 0,
      disableGutters: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-of-type': {
          borderRadius: 0,
        },

        '&:before': { display: 'none' },

        '& .MuiAccordionDetails-root': {
          paddingBottom: theme.spacing(4),
        },
      }),
    },
  },
};

export default MuiAccordionOverrides;
