import { useTranslation } from 'react-i18next';
import { generatePath, Link, useLocation } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import {
  FormControl,
  ListItem,
  ListItemIcon,
  MenuItem,
  ProjectorScreenChartIcon,
  Select,
  UsersIcon,
} from 'elements';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';

const getItems = (orgId: string) => [
  {
    title: 'costCentersSelectItem',
    icon: <UsersIcon />,
    key: 'settingsTeams',
    link: generatePath(getPath('settingsTeams'), {
      orgId,
    }),
  },
  {
    title: 'costUnitsSelectItem',
    icon: <ProjectorScreenChartIcon />,
    key: 'settingsProjects',
    link: generatePath(getPath('settingsProjects'), {
      orgId,
    }),
  },
];

const CostCenterUnitsSelect = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const canUser = useCanUser();
  const {
    state: { organization, accountingSettings, featureModules },
  } = useGlobalState();

  const isProjectsPageAllowed =
    accountingSettings!.projectEnabled && canUser('projects-sub-page:visit');

  const isTeamsPageAllowed =
    featureModules.TEAMS &&
    accountingSettings!.costCenterEnabled &&
    canUser('teams-page:visit');

  if (!isProjectsPageAllowed || !isTeamsPageAllowed) return null;

  const items = getItems(organization!.id);
  const selectedValue = items.find((item) => pathname.includes(item.link));

  return (
    <FormControl fullWidth sx={{ maxWidth: 220 }}>
      <Select
        value={selectedValue?.key || ''}
        renderValue={() => {
          if (!selectedValue) return '';
          return t(
            `accountingGeneralSubpage.basicAccountingSettings.${selectedValue.title}`
          );
        }}
      >
        {items.map((item) => (
          <MenuItem key={item.title} value={item.key}>
            <ListItem component={Link} to={item.link} sx={{ p: 0 }}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              {t(
                `accountingGeneralSubpage.basicAccountingSettings.${item.title}`
              )}
            </ListItem>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CostCenterUnitsSelect;
