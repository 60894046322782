import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  DownloadIcon,
  ListItemText,
  LoadingButton,
  MenuContainer,
  MenuItem,
  Tooltip,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  GetAccountEntriesParams,
  MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_ZIP_RECEIPTS,
  NetworkErrorCode,
  TransactionsDownloadFileType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  downloadResponseAsFile,
  getGenericErrorMsg,
  getNetworkErrorCode,
} from 'services/utils';

interface Props {
  disabled: boolean;
  transactionsCount: number;
  isCamtDisabled: boolean;
  getRequestParams: () => GetAccountEntriesParams;
}

const AccountEntriesPageMenu = ({
  disabled,
  transactionsCount,
  isCamtDisabled: isCamtDownloadDisabled,
  getRequestParams,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const mounted = useMounted();
  const [isCsvDownloading, setIsCsvDownloading] = useState(false);
  const [isCamtDownloading, setIsCamtDownloading] = useState(false);
  const [isZipDownloading, setIsZipDownloading] = useState(false);
  const [
    isBillingStatementDownloading,
    setIsBillingStatementDownloading,
  ] = useState(false);

  const downloadCsv = async () => {
    try {
      setIsCsvDownloading(true);
      const { page, limit, ...params } = getRequestParams();
      const response = await api.getAccountEntriesFile({
        ...params,
        format: TransactionsDownloadFileType.defaultCsv,
      });
      if (!mounted.current) return;
      downloadResponseAsFile(response);
      setIsCsvDownloading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsCsvDownloading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const downloadCamt = async () => {
    try {
      setIsCamtDownloading(true);
      const { page, limit, ...params } = getRequestParams();
      const response = await api.getAccountEntriesFile({
        ...params,
        format: TransactionsDownloadFileType.camt053Xml,
      });
      if (!mounted.current) return;
      downloadResponseAsFile(response);
      setIsCamtDownloading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsCamtDownloading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const downloadTransactionsReceipts = async () => {
    try {
      setIsZipDownloading(true);
      const params = getRequestParams();
      const { transactionIds } = await api.getAccountEntriesIds(params);
      if (!mounted.current) return;

      // this is uncommon case, which can happen sometimes
      if (transactionIds.length < 1) {
        enqueueSnackbar(t('errors.general'), { variant: 'error' });
        return;
      }

      if (
        transactionIds.length > MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_ZIP_RECEIPTS
      ) {
        setIsZipDownloading(false);
        closeSnackbar();
        enqueueSnackbar(
          <Box display="flex" alignItems="center">
            {t('accountEntriesPageMenu.tooManySelectedTransactionsError', {
              count: MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_ZIP_RECEIPTS,
            })}
          </Box>,
          {
            persist: true,
            variant: 'warning',
          }
        );
        return;
      }
      const response = await api.getTransactionReceipts(
        params.organizationId,
        transactionIds
      );
      if (!mounted.current) return;
      setIsZipDownloading(false);
      downloadResponseAsFile(response);
    } catch (error) {
      if (!mounted.current) return;
      closeSnackbar();
      if (getNetworkErrorCode(error) === NetworkErrorCode.notFound) {
        enqueueSnackbar(t('accountEntriesPageMenu.noUploadedReceiptsError'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
      setIsZipDownloading(false);
    }
  };

  const downloadBillingStatement = async () => {
    try {
      const {
        organizationId,
        cardAccountId,
        fromBookingDate,
        toBookingDate,
      } = getRequestParams();
      if (!fromBookingDate || !toBookingDate) {
        enqueueSnackbar(t('accountEntriesPageMenu.emptyBookingDateError'), {
          variant: 'error',
        });
        return;
      }
      if (
        moment(toBookingDate, 'YYYY-MM-DD').diff(fromBookingDate, 'days') + 1 >
        31
      ) {
        enqueueSnackbar(t('accountEntriesPageMenu.invalidBookingDateError'), {
          variant: 'error',
        });
        return;
      }
      setIsBillingStatementDownloading(true);
      const response = await api.getBillingStmt({
        organizationId,
        cardAccountId,
        fromBookingDate,
        toBookingDate,
      });
      if (!mounted.current) return;
      downloadResponseAsFile(response);
      setIsBillingStatementDownloading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsBillingStatementDownloading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const isLoading =
    isCsvDownloading ||
    isCamtDownloading ||
    isZipDownloading ||
    isBillingStatementDownloading;

  return (
    <>
      <MenuContainer
        button={
          <LoadingButton
            sx={{ ml: 1 }}
            variant="text"
            startIcon={<DownloadIcon />}
            loadingPosition="start"
            disabled={disabled}
            loading={isLoading}
          >
            {t('accountEntriesPageMenu.menuBtn')}
            {!!transactionsCount && ` (${transactionsCount})`}
          </LoadingButton>
        }
      >
        <MenuItem disabled={isCsvDownloading} onClick={downloadCsv}>
          <ListItemText primary={t('accountEntriesPageMenu.csv')} />
          {isCsvDownloading && (
            <Box ml="auto" pl={1} display="inline-flex">
              <CircularProgress size="small" />
            </Box>
          )}
        </MenuItem>

        <Tooltip
          title={
            isCamtDownloadDisabled &&
            t('accountEntriesPageMenu.camtDisabledTooltip')
          }
        >
          <span>
            <MenuItem
              disabled={isCamtDownloadDisabled || isCamtDownloading}
              onClick={downloadCamt}
            >
              <ListItemText
                primary={
                  <>
                    {t('accountEntriesPageMenu.camt053Btn')}
                    {isCamtDownloading && (
                      <Box ml="auto" pl={1} display="inline-flex">
                        <CircularProgress size="small" />
                      </Box>
                    )}
                  </>
                }
              />
            </MenuItem>
          </span>
        </Tooltip>

        {canUser('account-entries-zip:download') && (
          <MenuItem
            disabled={isZipDownloading}
            onClick={downloadTransactionsReceipts}
          >
            <ListItemText
              primary={t('accountEntriesPageMenu.receiptsZipBtn')}
            />
            {isZipDownloading && (
              <Box ml="auto" pl={1} display="inline-flex">
                <CircularProgress size="small" />
              </Box>
            )}
          </MenuItem>
        )}
        {canUser('billing-stmt-on-acc-entries-page:download') && (
          <MenuItem
            disabled={isBillingStatementDownloading}
            onClick={downloadBillingStatement}
          >
            <ListItemText
              primary={t('accountEntriesPageMenu.billingStatement')}
            />
            {isBillingStatementDownloading && (
              <Box ml="auto" pl={1} display="inline-flex">
                <CircularProgress size="small" />
              </Box>
            )}
          </MenuItem>
        )}
      </MenuContainer>
    </>
  );
};

export default AccountEntriesPageMenu;
