import { useState } from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { ButtonLink } from 'components/Button';
import { LoaderWithOverlay } from 'components/Loader';
import { useGlobalState } from 'context/GlobalState';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OnboardingDocumentFile } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { downloadResponseAsFile, getGenericErrorMsg } from 'services/utils';

const FileName = styled.div`
  color: ${({ theme }) => theme.colors.grey10};
  font-size: 14px;
  line-height: 20px;
`;

const StyledButtonLink = styled(ButtonLink)`
  color: ${({ theme }) => theme.colors.grey10};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  &:disabled {
    opacity: 0.5;
  }
`;

interface Props {
  documentId: string;
  file: OnboardingDocumentFile;
  internalFilename?: string;
}

const FileRow = ({ documentId, file, internalFilename }: Props) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadFile = async () => {
    try {
      setIsLoading(true);
      const response = await api.downloadOnboardingDocumentFile(
        organization!.id,
        documentId,
        file.id
      );

      if (!mounted) return;
      downloadResponseAsFile(response, internalFilename);
      setIsLoading(false);
    } catch (error) {
      if (!mounted) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Box display="flex">
      {canUser('org-onboarding-document-files:manage') ? (
        <StyledButtonLink disabled={isLoading} onClick={handleDownloadFile}>
          {file.name}
        </StyledButtonLink>
      ) : (
        <FileName>{file.name}</FileName>
      )}

      {isLoading && (
        <Box ml="8px" position="relative" width="20px">
          <LoaderWithOverlay size={20} thickness={3} />
        </Box>
      )}
    </Box>
  );
};

export default FileRow;
