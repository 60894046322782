import styled from 'styled-components';

export const ErrorDescription = styled.div`
  background-color: ${({ theme }) => theme.colors.red100};
  font-size: 14px;
  margin-top: 16px;
  padding: 8px;
  border-radius: 4px;

  b {
    font-weight: 500;
  }
`;

export const Label = styled.div`
  min-width: 65px;
  text-align: right;
`;

export const NameDiffWrapper = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  display: flex;
  line-height: 20px;
  margin: 8px 0;
  padding: 4px 4px 4px 8px;
`;

export const NameValue = styled.span`
  font-weight: 500;
  margin-left: 4px;
  word-break: break-all;
`;

export const StepsList = styled.ol`
  margin: 0;
`;
