import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { generatePath, Redirect } from 'react-router-dom';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import { InternalBlock } from 'domains/settings/components';
import { HeaderContainer, HeaderTitle } from 'domains/settings/layout';
import { Box, LoaderWithOverlay, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import useQueryParams from 'hooks/useQueryParams';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer } from 'layout';
import {
  ApiIntegrationStatus,
  DatevClient,
  DatevConnection,
  ExportFormat,
  NetworkErrorCode,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  getPath,
} from 'services/utils';
import DatevChooseClientDialog from './DatevChooseClientDialog';
import DatevConnectionSidebar from './DatevConnectionSidebar';
import DatevExportFormatsGroup from './DatevExportFormatsGroup';
import DatevExportSettings from './DatevExportSettings';
import DatevGeneralSettings from './DatevGeneralSettings/index';

export const SESSION_STORAGE_KEY = 'DATEV_CONNECTION_STEP';
export const DATEV_CONNECTION_STEPS = {
  LOAD_INITIAL_REDIRECT_URL: 'LOAD_INITIAL_REDIRECT_URL',
  GET_DATEV_AUTH_REQUEST_OFFLINE: 'GET_DATEV_AUTH_REQUEST_OFFLINE',
};

interface State {
  isConfirmConnectDialogOpen: boolean;
  isConfirmDisconnectDialogOpen: boolean;
  isLoading: boolean; // general loading
  isInitialRedirectUrlLoading: boolean;
  isDisconnectLoading: boolean;
  isCleanupLoading: boolean;
  isGetDatevErrorsAndAuditLoading: boolean;
  validationErrors: string[] | null;
  connectionAudit: Omit<DatevConnection, 'validationErrors'> | null;
  clients: DatevClient[] | null;
  isConnectionError: boolean;
  isClientsFetchError: boolean;
  isDisconnectionError: boolean;
  isDatevValidationError: boolean;
}

const DatevSubPage = () => {
  const { datevExportEnabled } = useFlags();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const partnerName = usePartnerName();
  const history = useHistory();
  const {
    error,
    code: datevCode,
    id_token: datevIdToken,
    state: datevState,
    session_state: datevSessionState,
  } = useQueryParams();
  const {
    state: { organization, accountingSettings },
    dispatch,
  } = useGlobalState();
  const [state, setState] = useState<State>({
    clients: null,
    isConfirmConnectDialogOpen: false,
    isConfirmDisconnectDialogOpen: false,
    isLoading: false,
    isInitialRedirectUrlLoading: false,
    isDisconnectLoading: false,
    isCleanupLoading: false,
    isGetDatevErrorsAndAuditLoading: false,

    validationErrors: null,
    connectionAudit: null,
    isConnectionError: false,
    isClientsFetchError: false,
    isDisconnectionError: false,
    isDatevValidationError: false, // validation error thrown to our API directly from datev
  });

  const canViewDatevConnection =
    datevExportEnabled && canUser('datev-sub-page:visit', accountingSettings);
  const canChangeDatevConnection =
    canViewDatevConnection && canUser('datev-api-connection:change');

  const getDatevErrorsAndAudit = async () => {
    setState((prevState) => ({
      ...prevState,
      isGetDatevErrorsAndAuditLoading: true,
    }));
    try {
      const { validationErrors, ...rest } = await api.getDatevErrorsAndAudit(
        organization!.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        validationErrors,
        connectionAudit: rest,
        isGetDatevErrorsAndAuditLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isGetDatevErrorsAndAuditLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const loadInitialRedirectUrl = async () => {
    if (state.isInitialRedirectUrlLoading) return;
    try {
      setState((prevState) => ({
        ...prevState,
        isConnectionError: false,
        isInitialRedirectUrlLoading: true,
      }));
      const { url } = await api.getDatevAuthRequest(organization!.id);
      if (!mounted.current) return;
      sessionStorage.setItem(
        SESSION_STORAGE_KEY,
        DATEV_CONNECTION_STEPS.LOAD_INITIAL_REDIRECT_URL
      );
      window.location.href = url;
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isInitialRedirectUrlLoading: false,
        isConnectionError: true,
      }));
      logError(error);
    }
  };

  const getDatevClients = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const { clients } = await api.getDatevConnect({
        organizationId: organization!.id,
        code: datevCode,
        id_token: datevIdToken,
        state: datevState,
        session_state: datevSessionState,
      });
      if (!mounted.current) return;
      history.replace({ search: '' });
      if (!clients?.length) {
        // empty client error
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          isClientsFetchError: true,
        }));
        return;
      }
      setState((prevState) => ({ ...prevState, clients, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      history.replace({ search: '' });
      sessionStorage.removeItem(SESSION_STORAGE_KEY);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isClientsFetchError: true,
      }));
      logError(error);
    }
  };

  const validateConnection = async () => {
    try {
      if (!state.isLoading)
        setState((prevState) => ({
          ...prevState,
          isDatevValidationError: false,
          isLoading: true,
        }));
      const { validationErrors, ...rest } = await api.validateDatevConnections(
        organization!.id
      );
      if (!mounted.current) return;

      const updatedAccountingSettings = await api.getAccountingSettings(
        organization!.id
      );
      if (!mounted.current) return;

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        validationErrors,
        connectionAudit: rest,
      }));
      dispatch({
        type: 'SET_USER_DATA',
        payload: {
          accountingSettings: {
            ...accountingSettings!,
            ...updatedAccountingSettings,
            apiIntegrationStatus: rest.apiIntegrationStatus,
          },
        },
      });
    } catch (error) {
      if (!mounted.current) return;
      const isDatevError =
        getNetworkErrorCode(error) ===
        NetworkErrorCode.datevPermissionRequirementsNotMet;

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isConnectionError: !isDatevError,
        isDatevValidationError: isDatevError,
      }));

      if (!isDatevError) logError(error);
    }
  };

  const connectDatev = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      await api.getDatevConnect({
        organizationId: organization!.id,
        code: datevCode,
        id_token: datevIdToken,
        state: datevState,
        session_state: datevSessionState,
      });
      if (!mounted.current) return;
      history.replace({ search: '' });
      sessionStorage.removeItem(SESSION_STORAGE_KEY);

      validateConnection();
    } catch (error) {
      if (!mounted.current) return;
      history.replace({ search: '' });
      sessionStorage.removeItem(SESSION_STORAGE_KEY);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isConnectionError: true,
      }));
      logError(error);
    }
  };

  const disconnectDatev = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isDisconnectLoading: true,
        isDisconnectionError: false,
      }));
      await api.deleteDatevConnection(organization!.id);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isDisconnectLoading: false,
        isConfirmDisconnectDialogOpen: false,
        validationErrors: [],
        connectionAudit: null,
      }));
      dispatch({
        type: 'SET_USER_DATA',
        payload: {
          accountingSettings: {
            ...accountingSettings!,
            apiIntegrationStatus: ApiIntegrationStatus.notConnected,
          },
        },
      });
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isConfirmDisconnectDialogOpen: false,
        isDisconnectLoading: false,
        isDisconnectionError: true,
      }));
      logError(error);
    }
  };

  const cleanupDatevConnection = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isCleanupLoading: true,
        isConnectionError: true, // we will display connection error
      }));
      await api.cleanupDatevConnection(organization!.id);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isCleanupLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isCleanupLoading: false,
      }));
      logError(error);
    }
  };

  // fetch errors from datev side
  useEffect(() => {
    if (error) {
      history.replace({ search: '' });
      if (!canChangeDatevConnection) return;
      // params in url: error=access_denied&error_description=Resource+owner+did+not+approve&state=...
      // when user declines registration or the second step on datev side
      cleanupDatevConnection();
    }
  }, [error]);

  useEffect(() => {
    if (!canViewDatevConnection) return;
    // fetch datev user info and validation errors
    if (
      accountingSettings?.exportFormats.includes(ExportFormat.api) &&
      (accountingSettings!.apiIntegrationStatus ===
        ApiIntegrationStatus.needsWork ||
        accountingSettings!.apiIntegrationStatus ===
          ApiIntegrationStatus.connected)
    ) {
      getDatevErrorsAndAudit();
    }
  }, []);

  useEffect(() => {
    if (datevCode && datevIdToken && datevState && datevSessionState) {
      if (
        !canChangeDatevConnection ||
        accountingSettings!.apiIntegrationStatus ===
          ApiIntegrationStatus.connected
      ) {
        history.replace({ search: '' });
        return;
      }

      if (
        sessionStorage.getItem(SESSION_STORAGE_KEY) ===
        DATEV_CONNECTION_STEPS.LOAD_INITIAL_REDIRECT_URL
      ) {
        getDatevClients();
      } else if (
        sessionStorage.getItem(SESSION_STORAGE_KEY) ===
        DATEV_CONNECTION_STEPS.GET_DATEV_AUTH_REQUEST_OFFLINE
      ) {
        connectDatev();
      } else {
        history.replace({ search: '' });
      }
    }
  }, [datevCode, datevIdToken, datevState, datevSessionState]);

  if (!canViewDatevConnection)
    return (
      <Redirect
        to={generatePath(getPath('settingsAccounting'), {
          orgId: organization!.id,
        })}
      />
    );

  return (
    <>
      <Box display="flex">
        <ContentContainer width="100%">
          <HeaderContainer mb={4}>
            <HeaderTitle>{t('datevSubPage.mainTitle')}</HeaderTitle>

            {canUser('datev-export-formats:change') ? (
              <InternalBlock>
                <DatevExportFormatsGroup />
              </InternalBlock>
            ) : (
              <Typography>
                {accountingSettings?.apiIntegrationStatus ===
                  ApiIntegrationStatus.connected &&
                accountingSettings?.exportFormats.includes(ExportFormat.api) ? (
                  <Trans
                    i18nKey="datevSubPage.titleDatev"
                    components={{
                      linkTo: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          href="https://www.datev.de/web/de/datev-shop/betriebliches-rechnungswesen/rechnungsdatenservice-1-0/"
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                    }}
                  />
                ) : (
                  t('datevSubPage.title')
                )}
              </Typography>
            )}
          </HeaderContainer>

          <DatevGeneralSettings />

          <DatevExportSettings />
        </ContentContainer>

        {accountingSettings?.exportFormats.includes(ExportFormat.api) && (
          <DatevConnectionSidebar
            validationErrors={state.validationErrors}
            connectionAudit={state.connectionAudit}
            isClientsFetchError={state.isClientsFetchError}
            isConnectionError={state.isConnectionError}
            isDisconnectionError={state.isDisconnectionError}
            isDatevValidationError={state.isDatevValidationError}
            onConnect={() =>
              setState((prevState) => ({
                ...prevState,
                isConfirmConnectDialogOpen: true,
              }))
            }
            onDisconnect={() =>
              setState((prevState) => ({
                ...prevState,
                isConfirmDisconnectDialogOpen: true,
              }))
            }
            onActivate={validateConnection}
          />
        )}
      </Box>

      <ConfirmDialog
        open={state.isConfirmConnectDialogOpen}
        loading={state.isInitialRedirectUrlLoading}
        title={t('datevSubPage.connectionDialog.title')}
        confirmButtonProps={{ children: t('common.button.continue') }}
        description={
          <Trans
            i18nKey="datevSubPage.connectionDialog.description"
            components={{
              linkTo: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="https://apps.datev.de/help-center/documents/1007329"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              b: <b />,
            }}
            values={{
              partnerName,
            }}
          />
        }
        onSuccess={loadInitialRedirectUrl}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isConfirmConnectDialogOpen: false,
          }))
        }
      />

      <ConfirmDialog
        open={state.isConfirmDisconnectDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isConfirmDisconnectDialogOpen: false,
          }))
        }
        onSuccess={disconnectDatev}
        title={t('datevSubPage.disconnectionDialog.title')}
        loading={state.isDisconnectLoading}
        confirmButtonProps={{
          children: t('datevSubPage.disconnectionDialog.disconnect'),
        }}
        description={t('datevSubPage.disconnectionDialog.description')}
      />

      <DatevChooseClientDialog
        open={!!state.clients}
        clients={state.clients || []}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            clients: null,
          }));
          cleanupDatevConnection();
        }}
        onError={() =>
          setState((prevState) => ({
            ...prevState,
            isConnectionError: true,
          }))
        }
      />

      <LoaderWithOverlay
        loading={
          state.isLoading ||
          state.isCleanupLoading ||
          state.isGetDatevErrorsAndAuditLoading
        }
      />
    </>
  );
};

export default DatevSubPage;
