import { useMemo } from 'react';
import { CardConfig } from 'services/constants';
import { useGlobalState } from './index';

const useCardConfigSetting = (cardConfig: CardConfig) => {
  const {
    state: { cardConfigSettings },
  } = useGlobalState();

  return useMemo(
    () => cardConfigSettings.find((item) => item.cardConfig === cardConfig),
    [cardConfigSettings, cardConfig]
  );
};

export default useCardConfigSetting;
