import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { InputBaseProps } from '@material-ui/core/InputBase';
import { StyledInputBase } from './StyledInputBase';
import { StyledInputLabel } from './StyledInputLabel';

export interface TextFieldProps extends InputBaseProps {
  label?: string;
  error?: boolean;
  shrink?: boolean;
}

export const TextField = ({
  label,
  error,
  shrink,
  ...props
}: TextFieldProps) => (
  <FormControl fullWidth error={error} style={props.style}>
    {label && (
      <StyledInputLabel htmlFor={props.id ?? props.name} shrink={shrink}>
        {label}
      </StyledInputLabel>
    )}
    <StyledInputBase id={props.id ?? props.name} {...props} />
  </FormControl>
);
