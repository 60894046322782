import React from 'react';
import { lessThanOrEqual, toDecimal } from 'dinero.js';
import { useFormik } from 'formik';
import omit from 'lodash/omit';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountCurrency } from 'domains/card/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  MoneyField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Cashback } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  getGenericErrorMsg,
} from 'services/utils';

interface FormValues {
  threshold: string;
}

interface Props extends DialogProps {
  cashback: Cashback;
  onSuccess: (cashback: Cashback) => void;
  onClose: () => void;
}

const SetCashbackThresholdDialog = ({
  cashback,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const currency = useCardAccountCurrency();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      threshold: toDecimal(
        dineroFromMoney(
          cashback.upcomingCashbackMonthlyThreshold ||
            cashback.cashbackMonthlyThreshold
        )
      ),
    },
    onSubmit: async ({ threshold }, { setSubmitting }) => {
      try {
        const data = await api.updateCashbackThreshold(
          organization!.id,
          convertDineroToMoney(dineroFromFloat(threshold, currency))
        );
        if (!mounted.current) return;
        onSuccess(data);
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const isSubmitDisabled = !formik.values.threshold || formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('int.setCashbackThresholdDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="set-cashback-threshold-form"
          noValidate
        >
          <MoneyField
            autoFocus
            label={t('int.setCashbackThresholdDialog.threshold')}
            placeholder={t('int.setCashbackThresholdDialog.threshold')}
            helperText={
              <Trans
                i18nKey="int.setCashbackThresholdDialog.helperText"
                components={{
                  max: (
                    <FormatMoney
                      value={cashback.cashbackDefaults.maxMonthlyThreshold}
                    />
                  ),
                  b: (
                    <Typography
                      variant="inherit"
                      component="b"
                      color="textPrimary"
                    />
                  ),
                }}
              />
            }
            disabled={formik.isSubmitting}
            isAllowed={({ value }) =>
              !value ||
              lessThanOrEqual(
                dineroFromFloat(value, currency),
                dineroFromMoney(cashback.cashbackDefaults.maxMonthlyThreshold)
              )
            }
            isNumericString
            currency={currency.code}
            {...omit(formik.getFieldProps('threshold'), 'onChange')}
            onValueChange={({ value }) =>
              formik.setFieldValue('threshold', value)
            }
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="set-cashback-threshold-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(SetCashbackThresholdDialog);
