import { useEffect, useState } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { isTxCustomFieldVisible } from 'domains/transaction/utils';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AutomationType,
  CustomField,
  CustomFieldOption,
  CustomFieldStatus,
  CustomFieldType,
  DEFAULT_PAGE_LIMIT,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  // "customFields" array will have values of mixed types.
  // When type of custom field is text, it will be string,
  // but when type is select, it might be CustomFieldOption | null.
  customFields: (CustomFieldOption | string | null)[];
  customFieldsResponse: CustomField[];
}

const useCardCreationCustomFields = (onClose: () => void) => {
  const api = useImperativeApi();
  const canUser = useCanUser();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { isAdminApp } = useCurrentApp();
  const {
    dispatch,
    state: { organization, featureModules },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    customFields: [],
    customFieldsResponse: [],
  });

  useEffect(() => {
    (async () => {
      if (
        !featureModules.TRANSACTION_CUSTOM_FIELDS ||
        !canUser('custom-fields:view') ||
        !canUser('custom-fields-card:assign')
      ) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        return;
      }

      try {
        const { transactionCustomFields } = await api.getCustomFields({
          organizationId: organization!.id,
          page: 0,
          limit: DEFAULT_PAGE_LIMIT,
        });

        dispatch({
          type: 'SET_CUSTOM_FIELDS',
          payload: {
            transactionCustomFields,
          },
        });

        if (!mounted.current) return;

        const activeTransactionCustomFields = transactionCustomFields.filter(
          (customField) =>
            customField.status === CustomFieldStatus.active &&
            customField.automationType === AutomationType.card &&
            isTxCustomFieldVisible(customField, isAdminApp)
        );

        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          customFieldsResponse: activeTransactionCustomFields,
          customFields: activeTransactionCustomFields.map((customField) => {
            if (customField.type === CustomFieldType.select)
              return customField.defaultOption;
            return customField.defaultValue || '';
          }),
        }));
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        onClose();
        logError(error);
      }
    })();
  }, []);

  return { ...state };
};

export default useCardCreationCustomFields;
