import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Role } from 'services/rbac';

const useInviteDialogThresholdExceededMessage = () => {
  const {
    state: { member },
  } = useGlobalState();
  const { t } = useTranslation();

  if (
    member &&
    member.roles &&
    (member.roles.includes(Role.accountOwner) ||
      member.roles.includes(Role.orgAdmin))
  ) {
    return t('tooltips.thresholdExceededForOrgAdminsInStarterPlan');
  } else {
    return t('tooltips.thresholdExceededForCardholdersInStarterPlan');
  }
};

export default useInviteDialogThresholdExceededMessage;
