import {
  MerchantData,
  MerchantLogo,
  MerchantStatusBadge,
  MerchantTransactionsCountAndVolume,
} from 'domains/merchant/components';
import { Box, Typography } from 'elements';
import { Merchant, MerchantStatus, Money } from 'services/constants';

interface Props {
  logoPath: string | null;
  displayName: string;
  status: MerchantStatus;
  transactionsCount: number;
  transactionsVolume: Money;
  merchant?: Merchant;
}

const MerchantPreview = ({
  logoPath,
  displayName,
  status,
  transactionsCount,
  transactionsVolume,
  merchant,
}: Props) => {
  return (
    <>
      <>
        <Box display="flex" alignItems="center" mb={4}>
          <MerchantLogo size="large" url={logoPath} name={displayName} />
          <Box ml={2} overflow="hidden">
            <Typography gutterBottom>{displayName}</Typography>
            <MerchantStatusBadge status={status} />
          </Box>
        </Box>

        <MerchantTransactionsCountAndVolume
          transactionsCount={transactionsCount}
          transactionsVolume={transactionsVolume}
        />

        {merchant && (
          <>
            <Typography sx={{ mt: 3, mb: 1 }}>
              {merchant.legalName || merchant.displayName}
            </Typography>
            <MerchantData merchant={merchant} />
          </>
        )}
      </>
    </>
  );
};

export default MerchantPreview;
