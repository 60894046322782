import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, SuitcaseSimpleIcon, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CompanyRegistryProvider,
  DEFAULT_AUTOCOMPLETE_DELAY,
  OrganizationSearchItem,
  SupportedCountry,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const MIN_ALLOWED_CHARS = 3;

interface Props {
  country: SupportedCountry;
  onChange: (org: OrganizationSearchItem | null) => void;
  value: OrganizationSearchItem | null;
  helperText?: ReactNode;
}

interface State {
  inputValueOrg: string;
  isLoading: boolean;
  isOpen: boolean;
  searchResults: OrganizationSearchItem[];
}

const OrganizationAutocompleteSearch = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState<State>({
    inputValueOrg: '',
    isLoading: false,
    isOpen: false,
    searchResults: [],
  });

  const getCompanyRegistryProvider = (country: SupportedCountry) => {
    if (country === SupportedCountry.de) {
      return CompanyRegistryProvider.crefo;
    }

    return CompanyRegistryProvider.dnb;
  };

  const searchOrganization = async (name: string) => {
    try {
      const { data: searchResults } = await api.getOrganizationSearch(
        name,
        props.country,
        getCompanyRegistryProvider(props.country)
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        searchResults,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isOpen: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    let active = true;

    if (state.inputValueOrg.length < MIN_ALLOWED_CHARS) return;

    setState((prevState) => ({ ...prevState, isLoading: true }));

    setTimeout(() => {
      if (active) searchOrganization(state.inputValueOrg);
    }, DEFAULT_AUTOCOMPLETE_DELAY);

    return () => {
      active = false;
    };
  }, [state.inputValueOrg]);

  return (
    <Autocomplete<OrganizationSearchItem, false, false, false>
      inputValue={state.inputValueOrg}
      onInputChange={(e, value) =>
        setState((prevState) => ({
          ...prevState,
          inputValueOrg: value,
          isOpen: value.length >= MIN_ALLOWED_CHARS,
        }))
      }
      value={props.value}
      onChange={(e, selectedOrg) => props.onChange(selectedOrg)}
      options={state.searchResults}
      open={state.isOpen}
      onOpen={() =>
        setState((prevState) => ({
          ...prevState,
          isOpen: prevState.inputValueOrg.length >= MIN_ALLOWED_CHARS,
        }))
      }
      onClose={() =>
        setState((prevState) => ({
          ...prevState,
          searchResults: props.value ? prevState.searchResults : [],
          isOpen: false,
        }))
      }
      loading={state.isLoading}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) =>
        option.companyId === value.companyId
      }
      getOptionLabel={(option) => option.companyName}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.companyId}>
            <SuitcaseSimpleIcon />
            <Box ml={2}>
              <div>{option.companyName}</div>
              <Typography variant="caption" color="textSecondary">
                {`${option.street}, ${option.postalCode} ${option.city}`}
              </Typography>
            </Box>
          </li>
        );
      }}
      helperText={props.helperText}
      forcePopupIcon={false}
      label={t('int.organizationAutocompleteSearch.orgName')}
      placeholder={t('int.organizationAutocompleteSearch.orgName')}
      noOptionsText={t('common.nothingFound')}
    />
  );
};

export default OrganizationAutocompleteSearch;
