import { useTranslation } from 'react-i18next';
import { ReceiptAttentionIcon, ReceiptXIcon, Tooltip } from 'elements';
import {
  AccountEntryTransactionInfo,
  ExportFlowTransaction,
  Transaction,
  TransactionExportStatus,
  TransactionSimpleType,
} from 'services/constants';

type SameKeys =
  | 'receiptUploadEnabled'
  | 'receiptNeeded'
  | 'receiptsCount'
  | 'simpleType'
  | 'transactionAmount';

type MergedTransactionData =
  | Pick<Transaction, SameKeys>
  | Pick<AccountEntryTransactionInfo, SameKeys>
  | Pick<ExportFlowTransaction, SameKeys>;

interface Props {
  transaction: MergedTransactionData & {
    hasRejectedReceipts?: boolean;
    exportStatus?: TransactionExportStatus;
  };
}

const TransactionMissingReceiptCell = ({ transaction }: Props) => {
  const { t } = useTranslation();
  const {
    hasRejectedReceipts,
    receiptUploadEnabled,
    receiptNeeded,
    receiptsCount,
    simpleType,
    transactionAmount,
    exportStatus,
  } = transaction;

  if (exportStatus === TransactionExportStatus.exported) return null;

  if (hasRejectedReceipts) {
    return (
      <Tooltip title={t('tooltips.incorrectReceipt')}>
        <ReceiptAttentionIcon fontSize="small" color="error" />
      </Tooltip>
    );
  }

  return receiptUploadEnabled &&
    simpleType !== TransactionSimpleType.statusInquiry &&
    !!transactionAmount.value &&
    receiptNeeded &&
    !receiptsCount ? (
    <Tooltip title={t('tooltips.missingReceipt')}>
      <ReceiptXIcon fontSize="small" color="error" />
    </Tooltip>
  ) : null;
};

export default TransactionMissingReceiptCell;
