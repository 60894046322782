import React from 'react';
import { StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import GlobalStyle from './GlobalStyle';
import { muiTheme, styledComponentsTheme } from './theme';
import theme from './themeMui5';

interface StylesWrapperProps {
  children: React.ReactElement;
}

export const StylesWrapper = ({ children }: StylesWrapperProps) => {
  return (
    <StyledComponentsThemeProvider theme={styledComponentsTheme}>
      <StylesProvider injectFirst>
        {/* MUI 4 */}
        <MuiThemeProvider theme={muiTheme}>
          {/* MUI 5 */}
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyle />
            {children}
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </StyledComponentsThemeProvider>
  );
};
