import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  FormControlLabel,
  FormControlLabelTooltipIcon,
  FormGroup,
  Switch,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { FeatureModuleByKeyMap, FeatureModuleKey } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const ManagerTxReviewsSettings = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, featureModulesWithData, featureModules },
  } = useGlobalState();
  const module = featureModulesWithData.MANAGER_TX_REVIEWS;
  const [state, setState] = useState<{
    isLoading: boolean;
    settings: FeatureModuleByKeyMap[FeatureModuleKey.managerTxReviews]['settings'];
  }>({
    isLoading: false,
    settings: module.settings,
  });

  const updateSettings = async (
    settings: FeatureModuleByKeyMap[FeatureModuleKey.managerTxReviews]['settings']
  ) => {
    if (state.isLoading) return;
    try {
      setState({ isLoading: true, settings });
      const updatedModule = await api.updateFeatureModuleSettings<
        FeatureModuleKey.managerTxReviews
      >(organization!.id, {
        featureName: FeatureModuleKey.managerTxReviews,
        settings,
      });
      dispatch({
        type: 'SET_FEATURE_MODULES',
        payload: {
          featureModules: {},
          featureModulesWithData: {
            [updatedModule.name]: updatedModule,
          },
        },
      });
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState({ isLoading: false, settings: module.settings });
      logError(error);
    }
  };

  return (
    <FormGroup sx={{ mt: 3 }}>
      <FormControlLabel
        control={<Switch />}
        label={
          <Box display="inline-flex" alignItems="center" component="span">
            {t('manageFeatureModuleDialog.mandatoryForExportLabel')}
            <FormControlLabelTooltipIcon
              title={t(
                'manageFeatureModuleDialog.mandatoryDescription.MANAGER_TX_REVIEWS'
              )}
            />
          </Box>
        }
        disabled={!module.enabled}
        checked={state.settings.REVIEW_FOR_EXPORT_REQUIRED}
        onChange={(_, checked) =>
          updateSettings({
            ...state.settings,
            REVIEW_FOR_EXPORT_REQUIRED: checked,
          })
        }
      />
      <FormControlLabel
        control={<Switch />}
        label={
          <Box display="inline-flex" alignItems="center" component="span">
            {t('manageFeatureModuleDialog.limitTxReviewToTeam')}
            <FormControlLabelTooltipIcon
              title={
                featureModules.TEAMS
                  ? t('manageFeatureModuleDialog.limitTxReviewToTeamTooltip')
                  : t(
                      'manageFeatureModuleDialog.limitTxReviewToTeamDisabledTooltip'
                    )
              }
            />
          </Box>
        }
        disabled={!featureModules.TEAMS || !module.enabled}
        checked={state.settings.LIMIT_TX_REVIEW_TO_TEAM}
        onChange={(_, checked) =>
          updateSettings({
            ...state.settings,
            LIMIT_TX_REVIEW_TO_TEAM: checked,
          })
        }
      />
    </FormGroup>
  );
};

export default ManagerTxReviewsSettings;
