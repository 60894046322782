import React from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, NavLink } from 'react-router-dom';
import { internalOrgPaths } from 'components/App';
import { PageHeader, PageHeadingLine } from 'components/Page';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Box } from 'elements';
import useHeaderBorderOnScroll from 'hooks/useBorderOnScroll';
import AccountTypeSection from './AccountTypeSection';
import AutomaticActivationToggle from './AutomaticActivationToggle';

const ButtonGroup = styled('div')`
  background-color: ${({ theme }) => theme.palette.grey[900]};
  border-radius: 8px;
  display: flex;
  padding: 1px;
  width: fit-content;
`;

const StyledNavLink = styled(NavLink)`
  border-radius: 7px;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 9px 24px;
  text-decoration: none;

  &.active {
    background-color: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.grey[900]};
    cursor: default;
    pointer-events: none;
  }
`;

interface Props {
  children: React.ReactNode;
}

const CreditAndCompliancePageHeader = ({ children }: Props) => {
  const { t } = useTranslation();
  const { getHeaderStyle } = useHeaderBorderOnScroll();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    state: { isNonCustomer },
  } = useCreditAndComplianceContext();

  return (
    <PageHeader style={getHeaderStyle()}>
      <PageHeadingLine heading={t('int.creditAndCompliancePageHeader.title')}>
        {!isNonCustomer && <AutomaticActivationToggle />}
      </PageHeadingLine>

      <Box
        alignItems="flex-start"
        display="flex"
        justifyContent="space-between"
        marginBottom="20px"
      >
        <ButtonGroup>
          <StyledNavLink
            to={generatePath(internalOrgPaths.credit, {
              orgId: organization!.id,
            })}
          >
            {t('int.creditAndCompliancePageHeader.credit')}
          </StyledNavLink>
          {!isNonCustomer && (
            <StyledNavLink
              to={generatePath(internalOrgPaths.compliance, {
                orgId: organization!.id,
              })}
            >
              {t('int.creditAndCompliancePageHeader.compliance')}
            </StyledNavLink>
          )}
        </ButtonGroup>

        {!isNonCustomer && <AccountTypeSection />}
      </Box>

      <Box display="flex" marginLeft="-20px">
        {children}
      </Box>
    </PageHeader>
  );
};

export default CreditAndCompliancePageHeader;
