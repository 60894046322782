import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertTitle,
  CheckCircleIcon,
  FlagPennantIcon,
  Tooltip,
  Typography,
} from 'elements';
import { ReviewFlagReason, TransactionReviewStatus } from 'services/constants';

interface Props {
  reviewStatus: TransactionReviewStatus | null;
  reviewFlagReason: ReviewFlagReason | null;
  reviewComment: string | null;
  reviewedAt: string | null;
  reviewerFullName: string | null;
  reviewerEmail: string | null;
}

const TransactionReviewComment = ({
  reviewStatus,
  reviewFlagReason,
  reviewComment,
  reviewedAt,
  reviewerFullName,
  reviewerEmail,
}: Props) => {
  const { t } = useTranslation();

  if (reviewStatus === TransactionReviewStatus.flagged)
    return (
      <Alert
        severity="error"
        icon={<FlagPennantIcon fontSize="inherit" />}
        sx={{ my: 2, mx: 3 }}
      >
        <AlertTitle>
          {reviewFlagReason
            ? t(`transactionDetails.reviewComment.flaggedAs`, {
                reviewFlagReason: t(`reviewFlagReasons.${reviewFlagReason}`),
              })
            : t(`transactionReviewStatus.${reviewStatus}`)}
        </AlertTitle>
        <Typography variant="body2">{reviewComment}</Typography>
        {reviewedAt && (
          <Typography
            mt={0.5}
            variant="overline2"
            component="div"
            textAlign="right"
          >
            <Tooltip title={reviewerEmail || ''}>
              <span>
                {t(`transactionDetails.reviewComment.info`, {
                  name: reviewerFullName,
                  date: moment(reviewedAt).format('DD.MM.YYYY'),
                })}
              </span>
            </Tooltip>
          </Typography>
        )}
      </Alert>
    );

  if (reviewStatus === TransactionReviewStatus.resolved)
    return (
      <Alert
        severity="success"
        icon={<CheckCircleIcon fontSize="inherit" />}
        sx={{ my: 2, mx: 3 }}
      >
        <AlertTitle>
          {t(`transactionDetails.reviewComment.markedAsResolved`, {
            reviewFlagReason: t(`reviewFlagReasons.${reviewFlagReason}`),
          })}
        </AlertTitle>
        <Typography variant="body2">{reviewComment}</Typography>
        {reviewedAt && (
          <Typography
            mt={0.5}
            variant="overline2"
            component="div"
            textAlign="right"
          >
            <Tooltip title={reviewerEmail || ''}>
              <span>
                {t(`transactionDetails.reviewComment.info`, {
                  name: reviewerFullName,
                  date: moment(reviewedAt).format('DD.MM.YYYY'),
                })}
              </span>
            </Tooltip>
          </Typography>
        )}
      </Alert>
    );

  return null;
};

export default TransactionReviewComment;
