import { useEffect, useState } from 'react';
import { alpha, styled } from '@mui/material';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  FilePdfIcon,
  Paper,
  ReceiptXIcon,
  Typography,
  XSquareIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import { Receipt, ReceiptMimeType, ReceiptStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

const CenteredContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface State {
  isLoading: boolean;
  image: string | null;
}

interface Props {
  $disabled?: boolean;
  receipt: Receipt;
  onClick: () => void;
}

const ThumbnailPreview = ({ $disabled = false, receipt, onClick }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const [state, setState] = useState<State>({ isLoading: true, image: null });

  const getPreview = async () => {
    try {
      setState((state) => ({ ...state, image: null, isLoading: true }));
      const str = await api.getReceiptThumbnail(receipt.receiptId);
      if (!mounted.current) return;
      setState((state) => ({
        ...state,
        isLoading: false,
        image: `url(${str})`,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((state) => ({ ...state, isLoading: false }));
      if (isAxiosError(error) && error?.response?.status === 404) return;
      logError(error);
    }
  };

  useEffect(() => {
    getPreview();
  }, [receipt.receiptId]);

  return (
    <Paper
      variant="outlined"
      data-test-id="receipt-thumbnail"
      onClick={onClick}
      sx={{
        height: 150,
        backgroundRepeat: 'no-repeat',
        backgroundImage: state.image || 'none',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        overflow: 'hidden',
        position: 'relative',
        ...(receipt.status === ReceiptStatus.rejected
          ? { borderColor: ({ palette }) => palette.error.main }
          : {}),

        '&:hover': {
          cursor: $disabled ? 'default' : 'pointer',
        },

        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          ...(receipt.status === ReceiptStatus.rejected
            ? {
                backgroundColor: (theme) =>
                  alpha(theme.palette.error.main, 0.16),
              }
            : {}),
        },
      }}
    >
      {state.isLoading && (
        <CenteredContainer>
          <CircularProgress size="small" />
        </CenteredContainer>
      )}

      {!state.isLoading &&
        !state.image &&
        receipt.mimeType === ReceiptMimeType.pdf && (
          <CenteredContainer>
            <FilePdfIcon />
            <Typography mt={2}>
              {t('transactionReceipts.thumbnailNoPreviewPossible')}
            </Typography>
          </CenteredContainer>
        )}

      {!state.isLoading &&
        !state.image &&
        receipt.mimeType !== ReceiptMimeType.pdf && (
          <CenteredContainer>
            <XSquareIcon />
            <Typography mt={2}>
              {t('transactionReceipts.thumbnailErrorMessage')}
            </Typography>
          </CenteredContainer>
        )}

      {receipt.status === ReceiptStatus.rejected && (
        <CenteredContainer>
          <ReceiptXIcon color="error" />
          <Typography mt={2} color="error">
            {t('transactionReceipts.thumbnailReceiptRejected')}
          </Typography>
        </CenteredContainer>
      )}
    </Paper>
  );
};

export default ThumbnailPreview;
