import { useTranslation } from 'react-i18next';
import { Badge, Button } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { TransactionReceiptStatus } from 'services/constants';

interface Props {
  count?: number;
  receiptFilter: string;
  setParam: SetQueryParam;
}

export default function MissingReceiptButton({
  count,
  receiptFilter,
  setParam,
}: Props) {
  const { t } = useTranslation();

  const isMissingFilterActive =
    receiptFilter === TransactionReceiptStatus.missing;

  const toggleMissingReceiptFilter = () => {
    setParam(
      'receipt',
      isMissingFilterActive ? null : TransactionReceiptStatus.missing
    );
  };

  return count === 0 ? null : (
    <Button
      onClick={toggleMissingReceiptFilter}
      data-intercom-target="missing-receipt-filter-button"
      variant={isMissingFilterActive ? 'contained' : 'text'}
      sx={{ borderRightWidth: '0 !important' }}
    >
      {t('filters.missingReceiptButton')}
      <Badge
        color="secondary"
        badgeContent={count}
        showZero={false}
        max={1000000}
        sx={{
          '.MuiBadge-badge': {
            m: 'auto',
            ml: 1,
            transform: 'none',
            position: 'static',

            '&.MuiBadge-invisible': { display: 'none' },
          },
        }}
      />
    </Button>
  );
}
