import lodashSortBy from 'lodash/sortBy';
import {
  Card,
  CardCount,
  CardSimpleStatus,
  CardType,
  Member,
} from 'services/constants';

export const isCardVoid = ({ simpleStatus }: Pick<Card, 'simpleStatus'>) =>
  simpleStatus === CardSimpleStatus.terminated ||
  simpleStatus === CardSimpleStatus.expired;

const cardTypeComparator = ({ type }: { type: CardType }) => {
  if (type === CardType.singleUse) return 1;
  if (type === CardType.black) return 2;
  if (type === CardType.plastic) return 3;
  if (type === CardType.virtual) return 4;
};

const cardStatusComparator = ({ status }: { status: CardSimpleStatus }) => {
  if (status !== CardSimpleStatus.requested) return 1;
  if (status === CardSimpleStatus.requested) return 2;
};

const cardIssuingDateComparator = ({ issuingDate }: { issuingDate: string }) =>
  +new Date(issuingDate);

export const filterAndSortMemberCards = (cards: Card[]) => {
  const filteredCards = cards.filter((card) => !isCardVoid(card));
  return lodashSortBy(filteredCards, [
    cardStatusComparator,
    cardTypeComparator,
    cardIssuingDateComparator,
  ]) as Card[];
};

export const filterAndSortMemberCardsInMember = (cards: Member['cards']) => {
  return lodashSortBy(cards, [
    cardTypeComparator,
    cardIssuingDateComparator,
  ]) as Member['cards'];
};

export const getCardCount = (
  cardCount: CardCount | null,
  cardType: CardType,
  cardSimpleStatus?: (
    | CardSimpleStatus.active
    | CardSimpleStatus.pending
    | CardSimpleStatus.requested
  )[]
) => {
  if (!cardCount) return 0;
  return (
    (!cardSimpleStatus || cardSimpleStatus.includes(CardSimpleStatus.active)
      ? cardCount.activeCardsByType[cardType] ?? 0
      : 0) +
    (!cardSimpleStatus || cardSimpleStatus?.includes(CardSimpleStatus.pending)
      ? cardCount.pendingCardsByType[cardType] ?? 0
      : 0) +
    (!cardSimpleStatus || cardSimpleStatus?.includes(CardSimpleStatus.requested)
      ? cardCount.requestedCardsByType[cardType] ?? 0
      : 0)
  );
};
