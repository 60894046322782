import { useTranslation } from 'react-i18next';
import {
  Box,
  CardBasedAutomationIcon,
  EyeSlashIcon,
  MerchantBasedAutomationIcon,
  Tooltip,
  WarningCircleIcon,
} from 'elements';
import { AutomationType, CustomFieldVisibility } from 'services/constants';

interface Props {
  mandatoryForExport?: boolean | null;
  automationType?: AutomationType | null;
  visibility?: CustomFieldVisibility;
}

export const GeneralTileFooter = ({
  mandatoryForExport,
  automationType,
  visibility,
}: Props) => {
  const { t } = useTranslation();

  if (!mandatoryForExport && !automationType) return null;

  return (
    <Box
      mt="auto"
      px={3}
      py={2}
      display="flex"
      alignItems="center"
      borderTop={(theme) => `1px solid ${theme.palette.divider}`}
    >
      <Box display="flex" alignItems="center" sx={{ 'svg + svg': { ml: 2 } }}>
        {automationType === AutomationType.card && (
          <Tooltip
            title={t(`accountingGeneralSubpage.tooltips.cardBasedAutomation`)}
          >
            <CardBasedAutomationIcon color="action" />
          </Tooltip>
        )}

        {automationType === AutomationType.merchant && (
          <Tooltip
            title={t(
              `accountingGeneralSubpage.tooltips.merchantBasedAutomation`
            )}
          >
            <MerchantBasedAutomationIcon color="action" />
          </Tooltip>
        )}

        {visibility && visibility !== CustomFieldVisibility.adminAndCardholder && (
          <Tooltip
            title={`${t('accountingGeneralSubpage.tooltips.visibility')}: ${t(
              `customFieldsVisibility.${visibility}`
            )}`}
          >
            <EyeSlashIcon color="action" />
          </Tooltip>
        )}
      </Box>

      {mandatoryForExport && (
        <Tooltip
          title={t(`accountingGeneralSubpage.tooltips.mandatoryForExport`)}
        >
          <WarningCircleIcon color="action" sx={{ ml: 'auto' }} />
        </Tooltip>
      )}
    </Box>
  );
};
