import { styled } from '@mui/material';

export const Logo = styled('div')<{ $src: string }>`
  width: 50px;
  height: 50px;
  margin: 6px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-image: ${({ $src }) => `url(${$src})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const PromoImage = styled('div')<{ $src: string }>`
  position: absolute;
  width: 350px;
  height: 100%;
  top: 0;
  right: 0;
  background-image: ${({ $src }) => `url(${$src})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;
