import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Box, Button, EditButton, Typography } from 'elements';
import useCanUser from 'services/rbac/useCanUser';
import ChangeAccountingSystemNameDialog from './ChangeAccountingSystemNameDialog';

const AccountingSystemGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { state } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const accountingSettings = state.accountingSettings!;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Typography variant="body2">
          {t('accountingGeneralSubpage.accountingSystemTitle')}
        </Typography>
        {accountingSettings.accountingSystem ? (
          <Box display="flex" alignItems="center">
            <Typography variant="body2">
              {accountingSettings.accountingSystemName}
            </Typography>
            {canUser('accounting-system:change') && (
              <EditButton onClick={() => setIsDialogOpen(true)} />
            )}
          </Box>
        ) : (
          <Button
            disabled={!canUser('accounting-system:change')}
            onClick={() => setIsDialogOpen(true)}
          >
            {t('accountingGeneralSubpage.setAccountingSystemButton')}
          </Button>
        )}
      </Box>

      <ChangeAccountingSystemNameDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default AccountingSystemGroup;
