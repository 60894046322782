import React from 'react';
import { CardDesign } from 'services/constants';
import { StyledCardTile } from './style';

interface Props {
  cardDesign: CardDesign;
  children?: React.ReactNode;
}

const CardTileContainer = ({ cardDesign, children }: Props) => {
  return (
    <StyledCardTile data-test-id="card" style={{ color: cardDesign.textColor }}>
      <div
        style={{
          width: 200,
          height: 316,
          backgroundImage: `url("${cardDesign.largeImage}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      />
      {children}
    </StyledCardTile>
  );
};

export default CardTileContainer;
