import { useTranslation } from 'react-i18next';
import { Alert, AlertProps, AlertTitle, EyeIcon } from 'elements';

interface Props extends AlertProps {}

export const InternalBlock = ({ children, sx = [], ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <Alert
      icon={<EyeIcon fontSize="inherit" />}
      severity="info"
      {...props}
      sx={[
        { '.MuiAlert-message': { flex: 1 } },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <AlertTitle>{t('common.internalOnly')}</AlertTitle>
      {children}
    </Alert>
  );
};
