import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Link,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
  OrganizationMerchant,
  Supplier,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getPath } from 'services/utils';

type SupplierType = OrganizationMerchant['supplierResponse'] | Supplier;

const getSupplierName = (supplier: SupplierType) => {
  if (!supplier) return '';
  const { name, accountNumber } = supplier;
  return name + (accountNumber ? ` (${accountNumber})` : '');
};

interface State {
  isLoading: boolean;
  isAutocompleteLoading: boolean;
  value: SupplierType;
  suppliers: Supplier[];
}

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (value: OrganizationMerchant['supplierResponse']) => void;
  supplierResponse: OrganizationMerchant['supplierResponse'];
  merchantId: string;
}

const ChangeSupplierDialog = ({
  supplierResponse,
  onSuccess,
  merchantId,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
    isAutocompleteLoading: true,
    value: supplierResponse,
    suppliers: [],
  });
  const [inputValue, setInputValue] = useState('');

  const getData = async () => {
    try {
      const { suppliers } = await api.getSuppliers({
        organizationId: organization!.id,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
        q: inputValue.trim(),
        sortBy: '+name',
        status: AccountingItemStatus.active,
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        suppliers,
        isAutocompleteLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    let active = true;
    setState((prevState) => ({ ...prevState, isAutocompleteLoading: true }));
    setTimeout(() => {
      if (active) getData();
    }, DEFAULT_AUTOCOMPLETE_DELAY);
    return () => {
      active = false;
    };
  }, [inputValue.trim()]);

  const updateSupplier = async (): Promise<
    OrganizationMerchant['supplierResponse']
  > => {
    if (state.value) {
      await api.assignSupplierToMerchant(
        organization!.id,
        state.value.id,
        merchantId
      );

      return {
        id: state.value.id,
        name: state.value.name,
        accountNumber: state.value.accountNumber,
      };
    }
    await api.unassignSupplierFromMerchant(
      organization!.id,
      supplierResponse!.id,
      merchantId
    );
    return null;
  };

  const handleSubmit = async () => {
    if (state.isLoading) return;
    if (
      state.value?.id === supplierResponse?.id ||
      (!state.value && !supplierResponse)
    )
      return props.onClose();
    setState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const updatedSupplierResponse = await updateSupplier();
      if (!mounted.current) return;
      onSuccess(updatedSupplierResponse);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('supplierSelect.dialog.header')}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={4}>
          <Trans
            i18nKey="supplierSelect.dialog.description"
            components={{
              b: <b />,
              suppliersLink: (
                <Link
                  component={RouterLink}
                  to={generatePath(getPath('settingsSuppliers'), {
                    orgId: organization!.id,
                  })}
                />
              ),
            }}
          />
        </Typography>

        <FormControl fullWidth>
          <Autocomplete<SupplierType, false, false, true>
            freeSolo
            inputValue={inputValue}
            loading={state.isAutocompleteLoading}
            disabled={state.isLoading}
            label={t('supplierSelect.dialog.label')}
            size="medium"
            onInputChange={(e, inputValue) => setInputValue(inputValue)}
            value={state.value}
            onChange={(e, value) => {
              if (typeof value !== 'string')
                setState((prevState) => ({
                  ...prevState,
                  value,
                }));
            }}
            options={state.suppliers}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => {
              if (!option) return '—';
              if (typeof option === 'string') return '';
              return getSupplierName(option as SupplierType);
            }}
            renderOption={(props, option) => {
              if (!option) return '';
              return (
                <li {...props} key={option.id}>
                  <Box
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {getSupplierName(option as SupplierType)}
                  </Box>
                </li>
              );
            }}
            placeholder={t('filters.supplier')}
            loadingText={t('common.loading')}
            noOptionsText={t('common.nothingFound')}
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={state.isLoading} onClick={handleSubmit}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(ChangeSupplierDialog);
