import { styled } from '@mui/material';
import { Box } from 'elements';

export const AutocompleteWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',

  '.MuiInputBase-root': {
    // as actions buttons are changed,
    // previous padding is not correct any more
    paddingRight: `${theme.spacing(7.5)} !important`,
  },

  '.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
    paddingRight: 0,
  },

  '.MuiAutocomplete-root': {
    '.MuiInputBase-input': {
      opacity: 0,
    },
    '&.Mui-expanded': {
      '.MuiInputBase-input': {
        opacity: 1,
      },

      '& + .autocomplete-value': {
        opacity: 0,
      },
    },
  },
}));

export const AutocompleteInfo = styled(Box)`
  position: absolute;
  top: 50%;
  left: 30%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
  text-align: right;
`;
