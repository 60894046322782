import { TFunction } from 'i18next';
import { SupportedCountry } from 'services/constants';

export function validateZipCodeByCountry(
  country: SupportedCountry,
  zipCode: string,
  t: TFunction
): string | null {
  if ([SupportedCountry.ro].includes(country) && !zipCode.match(/^\d{6}$/)) {
    return t('errors.fields.zipCodeFormatSixDigits');
  }

  if (
    [
      SupportedCountry.cz,
      SupportedCountry.de,
      SupportedCountry.ee,
      SupportedCountry.es,
      SupportedCountry.fi,
      SupportedCountry.fr,
      SupportedCountry.gr,
      SupportedCountry.hr,
      SupportedCountry.it,
      SupportedCountry.lt,
      SupportedCountry.mc,
      SupportedCountry.pl,
      SupportedCountry.se,
      SupportedCountry.sk,
      SupportedCountry.tr,
      SupportedCountry.us,
    ].includes(country) &&
    !zipCode.match(/^\d{5}$/)
  ) {
    return t('errors.fields.zipCodeFormatFiveDigits');
  }

  if (
    [
      SupportedCountry.at,
      SupportedCountry.be,
      SupportedCountry.bg,
      SupportedCountry.ch,
      SupportedCountry.cy,
      SupportedCountry.dk,
      SupportedCountry.hu,
      SupportedCountry.lu,
      SupportedCountry.lv,
      SupportedCountry.no,
      SupportedCountry.si,
    ].includes(country) &&
    !zipCode.match(/^\d{4}$/)
  ) {
    return t('errors.fields.zipCodeFormatFourDigits');
  }

  if (
    [SupportedCountry.mt].includes(country) &&
    !zipCode.match(/^[a-zA-Z]{3} \d{4}$/)
  ) {
    return t('errors.fields.zipCodeFormatThreeLettersSpaceFourDigits');
  }

  if (
    [SupportedCountry.nl].includes(country) &&
    !zipCode.match(/^\d{4} [A-Za-z]{2}$/)
  ) {
    return t('errors.fields.zipCodeFormatFourDigitsSpaceTwoLetters');
  }

  if (
    [SupportedCountry.pt].includes(country) &&
    !zipCode.match(/^\d{4}-\d{3}$/)
  ) {
    return t('errors.fields.zipCodeFormatFourDigitsDashThreeDigits');
  }

  if (
    [SupportedCountry.ie].includes(country) &&
    !zipCode.match(/^[A-Za-z0-9]{3} [A-Za-z0-9]{4}$/)
  ) {
    return t('errors.fields.zipCodeFormatThreeCharsSpaceFourChars');
  }

  if (
    [SupportedCountry.uk].includes(country) &&
    !zipCode.match(/^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/)
  ) {
    return t('errors.fields.zipCodeFormatUk');
  }

  // valid zipCode
  return null;
}
