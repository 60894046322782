import { styled } from '@mui/material';

export const StyledWalletCardTile = styled('div')`
  display: inline-flex;
  position: relative;
  vertical-align: top;
`;

export const CardTileOverlay = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: opacity 0.25s;

  &:hover {
    opacity: 1;
  }
`;

export const PlaceholderValue = styled('span')`
  & + iframe {
    display: none !important;
  }
`;
