import { FC, ReactNode, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, EditButton } from 'components/Button';
import { TradeNameChangeDialog } from 'domains/organization/dialogs';
import { StatusBadge } from 'elements';
import { Organization } from 'services/constants';
import { Group, GroupDescription, GroupLabel } from './style';

interface Props {
  organization: Organization;
  label: ReactNode;
}

const TradeNameFormGroup: FC<Props> = ({
  organization,

  label,
}) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Group $alignItems="flex-start">
        <GroupLabel $lineHeight={32}>{label}</GroupLabel>
        <GroupDescription>
          <Box display="flex" alignItems="center">
            {!organization!.tradeNameRequired || !!organization!.tradeName ? (
              <>
                <GroupDescription>
                  {organization!.tradeName || '-'}
                </GroupDescription>
                <EditButton
                  $m="0 0 0 10px"
                  onClick={() => setIsDialogOpen(true)}
                />
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  onClick={() => setIsDialogOpen(true)}
                >
                  {t('onboardingPage.addInfoBtn')}
                </Button>
                <StatusBadge
                  sx={{ ml: 2 }}
                  label={t('common.badge.informationRequired')}
                  color="warning"
                />
              </>
            )}
          </Box>
        </GroupDescription>
      </Group>

      <TradeNameChangeDialog
        open={isDialogOpen}
        noPreselect={!organization!.tradeName}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default TradeNameFormGroup;
