import {
  OutlinedTextFieldProps as MuiOutlinedTextFieldProps,
  TextField as MuiTextField,
} from '@mui/material';

export interface TextFieldProps
  extends Omit<MuiOutlinedTextFieldProps, 'variant' | 'color' | 'fullWidth'> {}

const TextField = ({
  InputProps,
  InputLabelProps,
  ...rest
}: TextFieldProps) => (
  <MuiTextField
    InputProps={{ ...InputProps, notched: false }}
    InputLabelProps={{ ...InputLabelProps, shrink: true }}
    {...rest}
  />
);

export default TextField;
