import { TFunction } from 'i18next';
import {
  LegalRepresentativeType,
  RelatedPerson,
  UboType,
} from 'services/constants';

const createRepresentativeDescription = (
  representative: RelatedPerson,
  t: TFunction
) => {
  let description =
    representative.salutation.value === 1
      ? 'Mr'
      : representative.salutation.value === 2
      ? 'Mrs'
      : '';

  description += ` ${representative.givenName.value} ${representative.surName.value}`;

  const legal = [
    (representative.legalRepType.value === LegalRepresentativeType.joint ||
      representative.legalRepType.value === LegalRepresentativeType.sole) &&
      t('int.organizationRepresentativeSection.legalRep'),
    representative.uboType.value === UboType.shareholding &&
      t('int.organizationRepresentativeSection.shareholdingUbo'),
    representative.uboType.value === UboType.fictive &&
      t('int.organizationRepresentativeSection.fictiveUbo'),
    representative.uboType.value === UboType.other &&
      t('int.organizationRepresentativeSection.otherUbo'),
  ]
    .filter(Boolean)
    .join(' & ');

  return (
    <span>
      <span>{description}</span>
      <br />
      {legal && <span>{`(${legal})`}</span>}
    </span>
  );
};

export { createRepresentativeDescription };
