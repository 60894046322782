import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography } from 'elements';
import { Transaction } from 'services/constants';
import { getPath } from 'services/utils';

interface Props {
  transaction: Transaction;
}
const OrganizationSection = ({ transaction }: Props) => {
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        px: 1.5,
        py: 2,
        mt: 1,
        minHeight: 56,
      }}
      variant="outlined"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">
          {t('transactionDetails.organization')}
        </Typography>

        <Typography
          noWrap
          variant="body2"
          ml={2}
          sx={{
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          component={Link}
          to={generatePath(getPath('transactionDetails'), {
            orgId: transaction.organizationId,
            transactionId: transaction.transactionId,
          })}
        >
          {transaction.organizationName}
        </Typography>
      </Box>
    </Paper>
  );
};

export default OrganizationSection;
