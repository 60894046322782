import { useTranslation } from 'react-i18next';
import { StatusBadge, StatusBadgeProps } from 'elements';
import { ReceiptsAutoMatchingStatus } from 'services/constants';

interface Props extends Omit<StatusBadgeProps, 'label'> {
  status: ReceiptsAutoMatchingStatus;
}

const ReceiptInboxStatusBadge = ({ status, ...props }: Props) => {
  const { t } = useTranslation();

  const getStatusProps = () => {
    switch (status) {
      case ReceiptsAutoMatchingStatus.processing: // checked
        return 'default';

      case ReceiptsAutoMatchingStatus.matchedAuto:
      case ReceiptsAutoMatchingStatus.matchedManually:
        return 'success';

      case ReceiptsAutoMatchingStatus.duplicate:
      case ReceiptsAutoMatchingStatus.notPaidWithPliant:
      case ReceiptsAutoMatchingStatus.alreadyExported:
      case ReceiptsAutoMatchingStatus.deletedByCustomer:
      case ReceiptsAutoMatchingStatus.invalidAttachment:
      case ReceiptsAutoMatchingStatus.notAReceipt:
      case ReceiptsAutoMatchingStatus.notReadable: // checked
        return 'error';

      default:
        return 'info';
    }
  };

  return (
    <StatusBadge
      color={getStatusProps()}
      label={t(`receiptsAutoMatchingStatus.${status}`)}
      {...props}
    />
  );
};

export default ReceiptInboxStatusBadge;
