import { useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ConfirmDialog from 'components/ConfirmDialogV2';
import FormatMoney from 'components/FormatMoney';
import WidgetError from 'components/WidgetError';
import {
  BillPaymentTypeBadge,
  PartialPaymentStatusBadge,
} from 'domains/billing/components';
import {
  ActionBox,
  ActionBoxActions,
  ActionBoxTitle,
  ArrowCounterClockwiseIcon,
  Box,
  Button,
  CaretDownIcon,
  CheckCircleIcon,
  Chip,
  Link,
  ListItemIcon,
  ListItemText,
  LoaderWithOverlay,
  MenuContainer,
  MenuItem,
  Paper,
  ProhibitIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  WarningCircleIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  BankAccountTransfersAllowedStatus,
  BillPaymentBatchBillPaymentType,
  BillPaymentsBankAccount,
  BillPaymentType,
  NetworkErrorCode,
  PartialPaymentStatus,
  Payment,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  getPath,
} from 'services/utils';

interface State {
  isLoading: boolean;
  bankAccount: BillPaymentsBankAccount | null;
  payments: Payment[];
  error: unknown;
  isBlockAccountDialogOpen: boolean;
  isAuthorizeAccountDialogOpen: boolean;
  isBlockApprovedDialogOpen: boolean;
  isAuthorizeBlockedDialogOpen: boolean;
  isUpdating: boolean;
  isPaymentReverseDialogOpen: boolean;
  isPaymentApproveDialogOpen: boolean;
  selectedPaymentId: string | null;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (bankAccount: BillPaymentsBankAccount) => void;
}

const BillingAccountsApprovalDetailsPage = ({ onUpdate, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { bankAccountId } = useParams<{ bankAccountId: string }>();
  const idRef = useRef(bankAccountId);
  const [state, setState] = useState<State>({
    isLoading: true,
    bankAccount: null,
    payments: [],
    error: null,
    isBlockAccountDialogOpen: false,
    isAuthorizeAccountDialogOpen: false,
    isBlockApprovedDialogOpen: false,
    isAuthorizeBlockedDialogOpen: false,
    isUpdating: false,
    isPaymentReverseDialogOpen: false,
    isPaymentApproveDialogOpen: false,
    selectedPaymentId: null,
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [bankAccount, { payments }] = await Promise.all([
        api.getBillPaymentsBankAccount(bankAccountId),
        api.getBillPaymentsByBankAccountId(bankAccountId, {
          billPaymentTypes: BillPaymentBatchBillPaymentType.prefundPayment,
        }),
      ]);
      if (!mounted.current || bankAccountId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        bankAccount,
        payments,
        isLoading: false,
        error: null,
      }));
      onUpdate(bankAccount);
    } catch (error) {
      if (!mounted.current || bankAccountId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        error,
        isLoading: false,
        bankAccount: null,
        payments: [],
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!bankAccountId) return;
    idRef.current = bankAccountId;
    getData();
  }, [bankAccountId]);

  const changeBankAccountStatus = async (
    status: BankAccountTransfersAllowedStatus
  ) => {
    if (state.isUpdating) return;
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      await api.updateBankAccountStatus({
        bankAccountId: state.bankAccount!.id,
        status,
      });
      if (!mounted.current || bankAccountId !== idRef.current) return;
      await getData();
      if (!mounted.current || bankAccountId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        isBlockAccountDialogOpen: false,
        isAuthorizeAccountDialogOpen: false,
        isBlockApprovedDialogOpen: false,
        isAuthorizeBlockedDialogOpen: false,
        isUpdating: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isUpdating: false }));
      logError(error);
    }
  };

  const changePaymentStatus = async (type: 'approve' | 'reject') => {
    if (state.isUpdating) return;
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      if (type === 'approve') {
        await api.approveBillPayment(state.selectedPaymentId!);
      } else {
        await api.rejectBillPayment(state.selectedPaymentId!);
      }
      if (!mounted.current || bankAccountId !== idRef.current) return;
      await getData();
      if (!mounted.current || bankAccountId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        isPaymentApproveDialogOpen: false,
        isPaymentReverseDialogOpen: false,
        isUpdating: false,
        selectedPaymentId: null,
      }));
    } catch (error) {
      if (!mounted.current) return;
      if (
        getNetworkErrorCode(error) ===
        NetworkErrorCode.paymentStatusChangeImpossible
      ) {
        enqueueSnackbar(
          t(
            'int.billingAccountsApprovalDetailsPage.paymentStatusChangeImpossibleError'
          ),
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
      setState((prevState) => ({ ...prevState, isUpdating: false }));
    }
  };

  const renderPaymentStatus = (payment: Payment) => {
    if (payment.status === PartialPaymentStatus.paid) {
      return (
        <MenuContainer
          button={
            <Chip
              size="medium"
              variant="outlined"
              color="success"
              icon={<CaretDownIcon />}
              disabled={!canUser('top-up-payment-status:change')}
              label={t('int.billingAccountsApprovalDetailsPage.approved')}
              sx={{
                textTransform: 'uppercase',
                flexDirection: 'row-reverse',
                '.MuiSvgIcon-root': {
                  mr: 0.5,
                  ml: -0.5,
                },
              }}
            />
          }
        >
          <MenuItem
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                isPaymentReverseDialogOpen: true,
                selectedPaymentId: payment.id,
              }))
            }
          >
            <ListItemIcon>
              <ArrowCounterClockwiseIcon />
            </ListItemIcon>
            <ListItemText>
              {t('int.billingAccountsApprovalDetailsPage.reverse')}
            </ListItemText>
          </MenuItem>
        </MenuContainer>
      );
    }

    if (payment.status === PartialPaymentStatus.reversalPending) {
      return (
        <MenuContainer
          button={
            <Chip
              size="medium"
              variant="outlined"
              color="warning"
              icon={<CaretDownIcon />}
              disabled={!canUser('top-up-payment-status:change')}
              label={t(
                'int.billingAccountsApprovalDetailsPage.pendingReversal'
              )}
              sx={{
                textTransform: 'uppercase',
                flexDirection: 'row-reverse',
                '.MuiSvgIcon-root': {
                  mr: 0.5,
                  ml: -0.5,
                },
              }}
            />
          }
        >
          <MenuItem
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                isPaymentApproveDialogOpen: true,
                selectedPaymentId: payment.id,
              }))
            }
          >
            <ListItemIcon>
              <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText>
              {t('int.billingAccountsApprovalDetailsPage.approve')}
            </ListItemText>
          </MenuItem>
        </MenuContainer>
      );
    }

    return <PartialPaymentStatusBadge status={payment.status} />;
  };

  return (
    <DetailsDrawer {...props}>
      {state.bankAccount && (
        <>
          <DetailsDrawerHeader>
            <Box display="flex" alignItems="center">
              <Typography variant="h4" component="span" pr={1} mr="auto">
                {state.bankAccount.accountHolderName}
              </Typography>
              <BillPaymentTypeBadge
                type={
                  state.bankAccount.directDebit
                    ? BillPaymentType.directDebit
                    : BillPaymentType.wireTransfer
                }
                sx={{ flexShrink: 0 }}
              />
            </Box>

            {state.bankAccount.transfersAllowedStatus !==
              BankAccountTransfersAllowedStatus.review && (
              <Box pt={2} textAlign="right">
                <MenuContainer
                  button={
                    <Chip
                      size="small"
                      color={
                        state.bankAccount?.transfersAllowedStatus ===
                        BankAccountTransfersAllowedStatus.authorized
                          ? 'success'
                          : 'error'
                      }
                      label={t(
                        `int.bankAccountTransfersAllowedStatus.${state.bankAccount.transfersAllowedStatus}`
                      )}
                      disabled={!canUser('top-up-approval-status:change')}
                      icon={<CaretDownIcon />}
                      sx={{
                        flexDirection: 'row-reverse',
                        '.MuiSvgIcon-root': {
                          mr: 0.5,
                          ml: -0.5,
                        },
                      }}
                    />
                  }
                >
                  {state.bankAccount.transfersAllowedStatus ===
                    BankAccountTransfersAllowedStatus.authorized && (
                    <MenuItem
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isBlockApprovedDialogOpen: true,
                        }))
                      }
                    >
                      <ListItemIcon>
                        <ProhibitIcon />
                      </ListItemIcon>
                      <ListItemText>
                        {t('int.billingAccountsApprovalDetailsPage.block')}
                      </ListItemText>
                    </MenuItem>
                  )}
                  {state.bankAccount.transfersAllowedStatus ===
                    BankAccountTransfersAllowedStatus.blocked && (
                    <MenuItem
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isAuthorizeBlockedDialogOpen: true,
                        }))
                      }
                    >
                      <ListItemIcon>
                        <CheckCircleIcon />
                      </ListItemIcon>
                      <ListItemText>
                        {t('int.billingAccountsApprovalDetailsPage.authorize')}
                      </ListItemText>
                    </MenuItem>
                  )}
                </MenuContainer>
              </Box>
            )}
          </DetailsDrawerHeader>
          <DetailsDrawerContent>
            {state.bankAccount.transfersAllowedStatus ===
              BankAccountTransfersAllowedStatus.review && (
              <ActionBox
                icon={
                  <WarningCircleIcon
                    sx={{
                      width: 22,
                      height: 22,
                    }}
                  />
                }
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
              >
                <ActionBoxTitle>
                  {t(
                    'int.billingAccountsApprovalDetailsPage.authorizeBankAccountTitle'
                  )}
                </ActionBoxTitle>
                <ActionBoxActions>
                  <Button
                    color="error"
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isBlockAccountDialogOpen: true,
                      }))
                    }
                    disabled={!canUser('top-up-approval-status:change')}
                  >
                    {t(
                      'int.billingAccountsApprovalPage.modal.reject.targetBtn'
                    )}
                  </Button>
                  <Button
                    color="success"
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isAuthorizeAccountDialogOpen: true,
                      }))
                    }
                    disabled={!canUser('top-up-approval-status:change')}
                  >
                    {t(
                      'int.billingAccountsApprovalPage.modal.approve.targetBtn'
                    )}
                  </Button>
                </ActionBoxActions>
              </ActionBox>
            )}

            <Box
              p={3}
              borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
            >
              <Typography variant="overline" component="div" mb={1.5}>
                {t('int.billingAccountsApprovalPage.billingAccountDetails')}
              </Typography>

              <Paper
                variant="outlined"
                sx={{
                  mb: 1,
                  p: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="subtitle2" flexShrink={0} mr={2}>
                  {t('int.billingAccountsApprovalDetailsPage.orgName')}
                </Typography>
                <Tooltip title={state.bankAccount.organizationName}>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    to={generatePath(getPath('billingStatements'), {
                      orgId: state.bankAccount.organizationId,
                    })}
                    sx={{ textDecoration: 'none' }}
                    noWrap
                  >
                    {state.bankAccount.organizationName}
                  </Link>
                </Tooltip>
              </Paper>

              <Paper
                variant="outlined"
                sx={{
                  mb: 1,
                  p: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="subtitle2" flexShrink={0} mr={2}>
                  {t(
                    'int.billingAccountsApprovalDetailsPage.accountHolderName'
                  )}
                </Typography>
                <Tooltip title={state.bankAccount.accountHolderName}>
                  <Typography variant="body2" noWrap>
                    {state.bankAccount.accountHolderName || '-'}
                  </Typography>
                </Tooltip>
              </Paper>

              <Paper
                variant="outlined"
                sx={{
                  px: 2,
                  py: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="subtitle2" flexShrink={0} mr={2}>
                  {t('int.billingAccountsApprovalDetailsPage.bankName')}
                </Typography>
                <Box textAlign="right" display="flex" flexDirection="column">
                  <Tooltip title={state.bankAccount.bankName}>
                    <Typography variant="body2" noWrap>
                      {state.bankAccount.bankName}
                    </Typography>
                  </Tooltip>
                  <Link
                    variant="caption"
                    component={RouterLink}
                    to={generatePath(getPath('settingsBankAccounts'), {
                      orgId: state.bankAccount.organizationId,
                    })}
                    sx={{ textDecoration: 'none' }}
                    noWrap
                  >
                    {state.bankAccount.iban}
                  </Link>
                </Box>
              </Paper>
            </Box>

            {!!state.payments.length && (
              <Box p={3}>
                <Typography variant="overline" component="div">
                  {t('int.billingAccountsApprovalDetailsPage.recentTopUps')}
                </Typography>

                <TableContainer>
                  <Table sx={{ tableLayout: 'fixed' }}>
                    <TableBody>
                      {state.payments.map((payment) => (
                        <TableRow key={payment.id}>
                          <TableCell>
                            <Typography variant="inherit" noWrap>
                              <FormatMoney
                                value={payment.amount}
                                fractionalPart
                              />
                            </Typography>
                            <Typography
                              variant="inherit"
                              color="text.secondary"
                            >
                              {payment.paymentDate
                                ? moment(payment.paymentDate).format(
                                    'D MMM YYYY'
                                  )
                                : '-'}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ width: 150 }}>
                            <BillPaymentTypeBadge type={payment.type} />
                          </TableCell>
                          <TableCell sx={{ width: 200, textAlign: 'right' }}>
                            {renderPaymentStatus(payment)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </DetailsDrawerContent>

          <ConfirmDialog
            open={state.isAuthorizeAccountDialogOpen}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                isAuthorizeAccountDialogOpen: false,
                isUpdating: false,
              }))
            }
            onSuccess={() =>
              changeBankAccountStatus(
                BankAccountTransfersAllowedStatus.authorized
              )
            }
            loading={state.isUpdating}
            confirmButtonProps={{
              children: t(
                'int.billingAccountsApprovalPage.modal.approve.confirmBtn'
              ),
            }}
            title={t('int.billingAccountsApprovalPage.modal.approve.title')}
            description={
              <Trans i18nKey="int.billingAccountsApprovalPage.modal.approve.content" />
            }
          />

          <ConfirmDialog
            open={state.isBlockAccountDialogOpen}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                isBlockAccountDialogOpen: false,
                isUpdating: false,
              }))
            }
            onSuccess={() =>
              changeBankAccountStatus(BankAccountTransfersAllowedStatus.blocked)
            }
            loading={state.isUpdating}
            confirmButtonProps={{
              children: t(
                'int.billingAccountsApprovalPage.modal.reject.confirmBtn'
              ),
            }}
            title={t('int.billingAccountsApprovalPage.modal.reject.title')}
            description={
              <Trans i18nKey="int.billingAccountsApprovalPage.modal.reject.content" />
            }
          />

          <ConfirmDialog
            open={state.isAuthorizeBlockedDialogOpen}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                isAuthorizeBlockedDialogOpen: false,
                isUpdating: false,
              }))
            }
            onSuccess={() =>
              changeBankAccountStatus(
                BankAccountTransfersAllowedStatus.authorized
              )
            }
            loading={state.isUpdating}
            confirmButtonProps={{
              children: t(
                'int.billingAccountsApprovalDetailsPage.modal.rejectedToApprove.confirmBtn'
              ),
            }}
            title={t(
              'int.billingAccountsApprovalDetailsPage.modal.rejectedToApprove.title'
            )}
            description={
              <Trans
                i18nKey="int.billingAccountsApprovalDetailsPage.modal.rejectedToApprove.content"
                components={{ b: <b /> }}
              />
            }
          />
          <ConfirmDialog
            open={state.isBlockApprovedDialogOpen}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                isBlockApprovedDialogOpen: false,
                isUpdating: false,
              }))
            }
            onSuccess={() =>
              changeBankAccountStatus(BankAccountTransfersAllowedStatus.blocked)
            }
            loading={state.isUpdating}
            confirmButtonProps={{
              children: t(
                'int.billingAccountsApprovalDetailsPage.modal.approvedToReject.confirmBtn'
              ),
            }}
            title={t(
              'int.billingAccountsApprovalDetailsPage.modal.approvedToReject.title'
            )}
            description={
              <Trans
                i18nKey="int.billingAccountsApprovalDetailsPage.modal.approvedToReject.content"
                components={{ b: <b /> }}
              />
            }
          />

          <ConfirmDialog
            open={state.isPaymentReverseDialogOpen}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                isPaymentReverseDialogOpen: false,
                selectedPaymentId: null,
              }))
            }
            onSuccess={() => changePaymentStatus('reject')}
            loading={state.isUpdating}
            confirmButtonProps={{
              children: t(
                'int.billingAccountsApprovalDetailsPage.modal.reverse.confirmBtn'
              ),
            }}
            title={t(
              'int.billingAccountsApprovalDetailsPage.modal.reverse.title'
            )}
            description={
              <Trans
                i18nKey="int.billingAccountsApprovalDetailsPage.modal.reverse.content"
                components={{ b: <b /> }}
              />
            }
          />
          <ConfirmDialog
            open={state.isPaymentApproveDialogOpen}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                isPaymentApproveDialogOpen: false,
                selectedPaymentId: null,
              }))
            }
            onSuccess={() => changePaymentStatus('approve')}
            loading={state.isUpdating}
            confirmButtonProps={{
              children: t(
                'int.billingAccountsApprovalDetailsPage.modal.approve.confirmBtn'
              ),
            }}
            title={t(
              'int.billingAccountsApprovalDetailsPage.modal.approve.title'
            )}
            description={t(
              'int.billingAccountsApprovalDetailsPage.modal.approve.content'
            )}
          />
        </>
      )}
      {state.error && <WidgetError onReload={getData} />}
      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(BillingAccountsApprovalDetailsPage);
