import React from 'react';
import { generatePath } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { getPath } from 'services/utils';

/** URL /billing is used in email templates and will be removed after backend update */
const BillingPageRedirect = () => {
  const location = useLocation();
  const {
    state: { organization },
  } = useGlobalState();

  return (
    <Redirect
      to={
        generatePath(getPath('billingStatements'), {
          orgId: organization!.id,
        }) + location.search
      }
    />
  );
};

export default BillingPageRedirect;
