import { useGlobalState } from 'context/GlobalState';
import useCurrentApp from 'hooks/useCurrentApp';
import {
  LegalRepresentative,
  LegalRepresentativeType,
  Member,
  MemberDetails,
} from 'services/constants';
import { Role, useCanUser } from 'services/rbac';

export const useAvailableRoles = (
  members?: Member[] | null,
  isInvitingMember?: boolean
) => {
  const { isExternalApp } = useCurrentApp();
  const {
    state: { featureModules },
  } = useGlobalState();
  const canUser = useCanUser();

  const availableRoles = [
    // remove cardholder as pre-selected for some partners
    {
      role: Role.cardholder,
      isAllowed:
        featureModules.CARDHOLDER_MANAGEMENT &&
        canUser('member-cardholder:create'),
    },
    // hide accountant from some partners
    ...(featureModules.ACCOUNTING_FEATURES
      ? [
          {
            role: Role.accountant,
            isAllowed: canUser('member-accountant:create'),
          },
        ]
      : []),
    {
      role: Role.orgAdmin,
      isAllowed: canUser('member-org-admin:create', members),
    },
    {
      role: Role.accountOwner,
      isAllowed: canUser('member-account-owner:create'),
    },
    {
      role: Role.auditor,
      isAllowed: !!isInvitingMember && canUser('member-auditor:create'),
    },
  ];
  const allowedRoles = availableRoles.filter((role) => role.isAllowed);
  // IN-12078 Forbid external users from inviting members if MEMBER_MANAGEMENT feature is disabled
  const hideInviteButton = isExternalApp && !featureModules.MEMBER_MANAGEMENT;

  return {
    availableRoles,
    allowedRoles,
    isInviteMemberAllowed: !!allowedRoles.length && !hideInviteButton,
  };
};

export const hasEnoughLegalReps = (
  legalReps: LegalRepresentative[],
  member: MemberDetails
) => {
  if (!member.roles.includes(Role.legalRep)) return true;

  const otherLegalReps = legalReps.filter((rep) => rep.memberId !== member.id);

  const soleLegalRepsCount = otherLegalReps.reduce(
    (acc, curr) => (curr.type === LegalRepresentativeType.sole ? ++acc : acc),
    0
  );

  const jointLegalRepsCount = otherLegalReps.reduce(
    (acc, curr) => (curr.type === LegalRepresentativeType.joint ? ++acc : acc),
    0
  );

  return soleLegalRepsCount >= 1 || jointLegalRepsCount >= 2;
};
