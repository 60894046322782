import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Typography } from 'elements';

interface Props {
  updatedAt?: string | null;
  updatedBy?: string | null;
  value: React.ReactNode;
}

const DataItemCell = ({ updatedAt, updatedBy, value }: Props) => {
  const { t } = useTranslation();
  const [showMetaInfo, setShowMetaInfo] = useState(false);

  return (
    <td>
      <Typography
        component="span"
        variant="body2"
        onMouseEnter={() => setShowMetaInfo(true)}
        onMouseLeave={() => setShowMetaInfo(false)}
      >
        {value}
      </Typography>

      {showMetaInfo && (
        <Typography
          position="absolute"
          right="64px"
          top={0}
          color="textSecondary"
          lineHeight="1.2"
          marginLeft="auto"
          variant="caption"
          whiteSpace="pre"
        >
          {updatedAt && updatedBy
            ? t('int.creditAndCompliancePage.dataItemRow.lastChanged', {
                updatedAt: moment(updatedAt).format('DD.MM.YYYY HH:mm'),
                updatedBy,
              })
            : '-'}
        </Typography>
      )}
    </td>
  );
};

export default DataItemCell;
