import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from 'elements';
import { SettlementPayment } from 'services/constants';
import SettlementPaymentItem from './SettlementPaymentItem';

interface Props {
  payments: SettlementPayment[] | null;
  label: ReactNode;
  noItemsLabel: ReactNode;
  onRetry: () => void;
  isShowResubmitPainFileButton?: boolean;
  dateTooltip?: string;
}

const SettlementPaymentsList = ({
  payments,
  label,
  noItemsLabel,
  onRetry,
  isShowResubmitPainFileButton = false,
  dateTooltip,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box p={3} borderTop={(theme) => `1px solid ${theme.palette.divider}`}>
      <Typography variant="overline">{label}</Typography>
      {!!payments ? (
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableBody>
            {payments.map((item) => (
              <SettlementPaymentItem
                key={item.id}
                payment={item}
                onResubmitSuccess={onRetry}
                isShowResubmitPainFileButton={isShowResubmitPainFileButton}
                dateTooltip={dateTooltip}
              />
            ))}
            {!payments.length && (
              <TableRow>
                <TableCell sx={{ border: 'none' }}>{noItemsLabel}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      ) : (
        <Alert
          sx={{ mt: (theme) => theme.spacing(1) }}
          severity="error"
          variant="outlined"
          action={
            <Button variant="text" color="error" size="small" onClick={onRetry}>
              {t('common.retry')}
            </Button>
          }
        >
          {t('errors.loadData')}
        </Alert>
      )}
    </Box>
  );
};

export default SettlementPaymentsList;
