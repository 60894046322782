import React, { useState } from 'react';
import { sortBy } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  Box,
  Button,
  CloudCheckIcon,
  CloudSlashIcon,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ExternalReportProvider, SupportedCountry } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import LatestReportWithHistory from './LatestReportWithHistory';

interface State {
  cancelExternalReportProvidorMonitoring: ExternalReportProvider | null;
  isCancellingMonitoring: boolean;
  isConfirmCrefoDialogOpen: boolean;
  isConfirmExternalDialogOpen: boolean;
  isRequestingReport: boolean;
}

const CreditReports = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    actions: {
      fetchCrefoReportsMetaData,
      fetchExternalReportsMetaData,
      refetchMonitoringStatuses,
    },
    state: {
      creditProfile,
      crefoReportsMetaData,
      externalReportsMetaData,
      monitoringStatuses,
    },
  } = useCreditAndComplianceContext();
  const [state, setState] = useState<State>({
    cancelExternalReportProvidorMonitoring: null,
    isCancellingMonitoring: false,
    isConfirmCrefoDialogOpen: false,
    isConfirmExternalDialogOpen: false,
    isRequestingReport: false,
  });
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const allReports = sortBy(
    [...crefoReportsMetaData, ...externalReportsMetaData],
    (report) => report.reportCreatedAt
  ).reverse();

  const showRequestCrefoReportButton = [
    SupportedCountry.at,
    SupportedCountry.de,
  ].includes(organization!.billingAddress.country);

  const requestReportFromCrefo = async () => {
    try {
      setState((prevState) => ({ ...prevState, isRequestingReport: true }));
      // Download a credit report from Crefo to our system
      await api.requestReportFromCrefo(organization!.id);
      await Promise.all([
        refetchMonitoringStatuses(),
        fetchCrefoReportsMetaData(),
      ]);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isConfirmCrefoDialogOpen: false,
        isRequestingReport: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isRequestingReport: false }));
      logError(error);
    }
  };

  const requestReportFromExternal = async () => {
    try {
      setState((prevState) => ({ ...prevState, isRequestingReport: true }));
      await api.requestExternalReport(creditProfile!.agencyProfile.duns.value);
      await Promise.all([
        refetchMonitoringStatuses(),
        fetchExternalReportsMetaData(),
      ]);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isConfirmExternalDialogOpen: false,
        isRequestingReport: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isRequestingReport: false }));
      logError(error);
    }
  };

  const cancelMonitoring = async () => {
    try {
      setState((prevState) => ({ ...prevState, isCancellingMonitoring: true }));

      if (
        state.cancelExternalReportProvidorMonitoring ===
        ExternalReportProvider.crefo
      ) {
        await api.cancelCrefoMonitoring(organization!.id);
      } else {
        await api.cancelDnbMonitoring(creditProfile!.agencyProfile.duns.value);
      }

      await refetchMonitoringStatuses();

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        cancelExternalReportProvidorMonitoring: null,
        isCancellingMonitoring: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), {
        variant: 'error',
      });
      setState((prevState) => ({
        ...prevState,
        isCancellingMonitoring: false,
      }));
      logError(error);
    }
  };

  return (
    <Box minHeight="46px">
      <Box display="flex" mb={1}>
        {(crefoReportsMetaData.length > 0 ||
          externalReportsMetaData.length > 0) && (
          <>
            <LatestReportWithHistory
              allReports={allReports}
              duns={creditProfile!.agencyProfile.duns.value}
            />
            <Box ml={1}>
              {monitoringStatuses!.crefoMonitoringActive ||
              monitoringStatuses!.dnbMonitoringActive ? (
                <>
                  <Tooltip
                    title={
                      <>
                        {monitoringStatuses!.crefoMonitoringActive && (
                          <div>
                            {t(
                              `int.agencyProfileSection.creditReport.crefoMonitoringActive`
                            )}
                          </div>
                        )}
                        {monitoringStatuses!.dnbMonitoringActive && (
                          <div>
                            {t(
                              `int.agencyProfileSection.creditReport.dnbMonitoringActive`
                            )}
                          </div>
                        )}
                      </>
                    }
                    disableHoverListener={!!menuAnchorEl}
                  >
                    <IconButton
                      onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                    >
                      <CloudCheckIcon color="success" />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={menuAnchorEl}
                    open={!!menuAnchorEl}
                    onClose={() => setMenuAnchorEl(null)}
                  >
                    {[
                      ...(monitoringStatuses!.crefoMonitoringActive
                        ? [ExternalReportProvider.crefo]
                        : []),
                      ...(monitoringStatuses!.dnbMonitoringActive
                        ? [ExternalReportProvider.dnb]
                        : []),
                    ].map((item) => (
                      <MenuItem
                        key={item!}
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            cancelExternalReportProvidorMonitoring: item,
                          }))
                        }
                      >
                        <ListItemIcon>
                          <CloudSlashIcon />
                        </ListItemIcon>
                        <Box component="span">
                          {t(
                            `int.agencyProfileSection.creditReport.cancelMonitoringTitle_${item}`
                          )}
                        </Box>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                <Box p={1}>
                  <CloudSlashIcon />
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>

      <Grid container columnSpacing={2}>
        {showRequestCrefoReportButton && crefoReportsMetaData.length === 0 && (
          <Tooltip
            title={
              creditProfile!.agencyProfile.crefoId.value
                ? ''
                : t('int.agencyProfileSection.creditReport.missingCrefoId')!
            }
          >
            <Grid item>
              <Button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isConfirmCrefoDialogOpen: true,
                  }))
                }
                disabled={!creditProfile!.agencyProfile.crefoId.value}
                variant="outlined"
              >
                {t('int.agencyProfileSection.creditReport.requestCrefoReport')}
              </Button>
            </Grid>
          </Tooltip>
        )}

        {externalReportsMetaData.length === 0 && (
          <Tooltip
            title={
              creditProfile!.agencyProfile.duns.value
                ? ''
                : t('int.agencyProfileSection.creditReport.missingDuns')!
            }
          >
            <Grid item>
              <Button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isConfirmExternalDialogOpen: true,
                  }))
                }
                disabled={!creditProfile!.agencyProfile.duns.value}
                variant="outlined"
              >
                {t(
                  'int.agencyProfileSection.creditReport.requestExternalReport'
                )}
              </Button>
            </Grid>
          </Tooltip>
        )}
      </Grid>

      <ConfirmDialog
        open={state.isConfirmCrefoDialogOpen}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            isConfirmCrefoDialogOpen: false,
          }));
        }}
        onSuccess={requestReportFromCrefo}
        loading={state.isRequestingReport}
        title={t(
          'int.agencyProfileSection.creditReport.requestCrefoReportConfirmationTitle'
        )}
        description={t(
          'int.agencyProfileSection.creditReport.requestReportConfirmationDescription'
        )}
      />

      <ConfirmDialog
        open={state.isConfirmExternalDialogOpen}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            isConfirmExternalDialogOpen: false,
          }));
        }}
        onSuccess={requestReportFromExternal}
        loading={state.isRequestingReport}
        title={t(
          'int.agencyProfileSection.creditReport.requestExternalReportConfirmationTitle'
        )}
        description={t(
          'int.agencyProfileSection.creditReport.requestReportConfirmationDescription'
        )}
      />

      <ConfirmDialog
        open={!!state.cancelExternalReportProvidorMonitoring}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            cancelExternalReportProvidorMonitoring: null,
          }));
        }}
        onSuccess={cancelMonitoring}
        loading={state.isCancellingMonitoring}
        title={t(
          `int.agencyProfileSection.creditReport.cancelMonitoringTitle_${state.cancelExternalReportProvidorMonitoring}`
        )}
        description={
          <Trans
            components={{ b: <b /> }}
            i18nKey="int.agencyProfileSection.creditReport.cancelMonitoringDescription"
            values={{ name: organization!.name }}
          />
        }
      />
    </Box>
  );
};

export default CreditReports;
