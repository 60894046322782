import { styled, Typography } from '@mui/material';

// add to mixins?
// When we override cell with renderCell
// MuiDataGrid-cellContent doesn't exist
export const DataGridCell = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DataGridSecondaryHeader = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Typography noWrap variant="caption2" color="textSecondary" component="div">
    {children}
  </Typography>
);
