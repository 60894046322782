import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { getSortedPartnersForPartnerSelect } from 'domains/partner/utils';
import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  InputLabelTooltipIcon,
} from 'elements';
import { Partner, PartnerIdType } from 'services/constants';

interface Props
  extends Pick<
    AutocompleteProps<Partner, false, true, false>,
    'onBlur' | 'disabled'
  > {
  name: string;
  value: PartnerIdType;
  label?: string;
  tooltipLabel?: string;
  isPartnerConfig?: boolean;
  onChange: (value: PartnerIdType) => void;
}

const PartnerSelect = ({
  label,
  value,
  tooltipLabel,
  isPartnerConfig = false,
  onChange,
  disabled,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { partners },
  } = useGlobalState();
  const sortedPartners = useMemo(
    () => getSortedPartnersForPartnerSelect(partners, isPartnerConfig),
    [partners]
  );

  const selectValue: Partner | undefined = useMemo(
    () =>
      sortedPartners.find((partner) => partner.partnerId === value) ??
      undefined,
    [sortedPartners, value]
  );

  return (
    <FormControl fullWidth disabled={disabled}>
      <Autocomplete<Partner, false, true, false>
        disableClearable
        value={selectValue}
        onChange={(_, option) => {
          if (!option) return;
          onChange(option.partnerId);
        }}
        options={sortedPartners}
        isOptionEqualToValue={(option, value) =>
          option.partnerId === value.partnerId
        }
        getOptionLabel={(option) => option.name.toUpperCase()}
        label={
          <>
            {label || t('partnerSelect.label')}
            {tooltipLabel && <InputLabelTooltipIcon title={tooltipLabel} />}
          </>
        }
        noOptionsText={t('common.nothingFound')}
        loadingText={t('common.loading')}
        {...props}
      />
    </FormControl>
  );
};

export default PartnerSelect;
