import { Box, Typography } from 'elements';

interface Props {
  pin: string;
}

const CardPin = ({ pin }: Props) => (
  <Box display="flex" justifyContent="center">
    {pin ? (
      pin.split('').map((digit, index) => (
        <Typography key={index} variant="h2" width={80} textAlign="center">
          {digit}
        </Typography>
      ))
    ) : (
      <Typography variant="h2">&nbsp;</Typography>
    )}
  </Box>
);

export default CardPin;
