import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from 'elements';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const CardAccountFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const {
    state: { cardAccounts },
  } = useGlobalState();
  const getCardAccountName = useCardAccountNameGetter();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-card-account"
        id="panel-card-account-header"
      >
        <Typography>{t('filters.account')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<string>
            size="medium"
            displayEmpty
            value={value}
            onChange={(e) => onChange(e.target.value as string)}
            renderValue={(selected) => {
              if (!selected)
                return (
                  <Typography color="text.disabled">
                    {t('filters.account')}
                  </Typography>
                );

              const selectedAccount = cardAccounts.find(
                (account) => account.id === selected
              );

              return selectedAccount ? getCardAccountName(selectedAccount) : '';
            }}
          >
            <MenuItem value="">
              <Typography color="text.disabled">
                {t('filters.account')}
              </Typography>
            </MenuItem>
            {cardAccounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {getCardAccountName(account)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};
