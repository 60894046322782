import { useEffect } from 'react';
import { useGlobalState } from 'context/GlobalState';
import useMounted from 'hooks/useMounted';
import { TransactionReviewStatus } from 'services/constants';
import useFlags from 'services/featureflags/useFlags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';

const useGetTransactionsFlaggedCounter = () => {
  const { managerTransactionReviewEnabled } = useFlags();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    dispatch,
    state: { featureModules, transactionsFlaggedCount: count, organization },
  } = useGlobalState();
  const isFlaggedTransactionsPageVisible =
    managerTransactionReviewEnabled &&
    canUser('transaction-review:view') &&
    (featureModules.MANAGER_TX_REVIEWS || featureModules.PRIVATE_EXPENSE);

  useEffect(() => {
    if (!isFlaggedTransactionsPageVisible) return;
    if (!organization?.id) return;
    (async () => {
      try {
        const { totalCount } = await api.getTransactions({
          organizationId: organization.id,
          page: 0,
          limit: 1,
          reviewStatus: TransactionReviewStatus.flagged,
        });
        if (!mounted.current) return;
        dispatch({
          type: 'SET_TRANSACTIONS_COUNT',
          payload: { transactionsFlaggedCount: totalCount },
        });
      } catch (error) {
        logError(error);
      }
    })();
  }, [isFlaggedTransactionsPageVisible, organization?.id]);

  if (!isFlaggedTransactionsPageVisible) return 0;

  return count;
};

export default useGetTransactionsFlaggedCounter;
