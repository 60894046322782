import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Divider,
  DownloadSimpleIcon,
  LoadingButton,
  MenuContainer,
  MenuItem,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  GetBillPaymentsCsvParams,
  NetworkErrorCode,
  OrganizationAccountStatement,
  TransactionsDownloadFileType,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  downloadResponseAsFile,
  getGenericErrorMsg,
  getNetworkErrorCode,
} from 'services/utils';
import useGetAccountingExportDownloadConfig, {
  MenuButtonDownloadFormat,
} from './useGetAccountingExportDownloadConfig';

interface Props {
  statement: OrganizationAccountStatement;
}

const AccountBillStatementBlockMenu = ({ statement }: Props) => {
  const { receiptsZipOnBillingPageEnabled } = useFlags();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization, featureModules, accountingSettings },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const buttonsList = useGetAccountingExportDownloadConfig(organization!.id);

  const isCardStmtZipEnabled =
    featureModules.MONTHLY_TRANSACTION_REPORT &&
    statement.cardStatementZipDocumentId;

  const downloadCsv = async () => {
    try {
      setIsLoading(true);
      const response = await api.getAccountEntriesFile({
        organizationId: organization!.id,
        cardAccountId: statement.cardAccountId,
        format: TransactionsDownloadFileType.defaultCsv,
        fromBookingDate: statement.startDate,
        toBookingDate: statement.endDate,
      });
      downloadResponseAsFile(response);
      if (!mounted.current) return;
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const downloadPdf = async () => {
    try {
      setIsLoading(true);
      const response = await api.downloadFile(
        `/documents/${statement.documentId}/content`,
        {},
        { responseType: 'arraybuffer' }
      );
      downloadResponseAsFile(response);
      if (!mounted.current) return;
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const downloadZip = async () => {
    try {
      setIsLoading(true);
      const params = {
        organizationId: organization!.id,
        fromBookingDate: statement.startDate,
        toBookingDate: statement.endDate,
      };
      const { transactionIds } = await api.getAccountEntriesIds(params);
      if (!mounted.current) return;
      if (transactionIds.length < 1) {
        setIsLoading(false);
        enqueueSnackbar(t('accountBillStatementBlockMenu.zipError'), {
          variant: 'error',
        });
        return;
      }
      const response = await api.getTransactionReceipts(
        organization!.id,
        transactionIds
      );
      if (!mounted.current) return;
      downloadResponseAsFile(response);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const downloadCardStatementZip = async () => {
    try {
      setIsLoading(true);
      const response = await api.downloadFile(
        `/documents/${statement.cardStatementZipDocumentId}/content`,
        {},
        { responseType: 'arraybuffer' }
      );
      downloadResponseAsFile(response);
      if (!mounted.current) return;
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const getDocument = (format: MenuButtonDownloadFormat) => {
    const params = {
      organizationId: statement.organizationId,
      organizationAccountStatementId: statement.organizationAccountStatementId,
    };
    if (format === MenuButtonDownloadFormat.csv)
      return api.getBillPaymentsCsv({
        ...params,
        accountingSystem: accountingSettings!
          .accountingSystem as GetBillPaymentsCsvParams['accountingSystem'],
      });
    return api.getBillPaymentsXml(params);
  };

  const downloadBillPaymentViaAccSystem = async (
    format: MenuButtonDownloadFormat
  ) => {
    try {
      setIsLoading(true);
      const response = await getDocument(format);
      if (!mounted.current) return;
      if (!response) {
        setIsLoading(false);
        return;
      }
      downloadResponseAsFile(response);
      if (!mounted.current) return;
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      if (getNetworkErrorCode(error) === NetworkErrorCode.noTransactionsFound) {
        enqueueSnackbar(t('statementItemMenu.noBillPaymentsFound'), {
          variant: 'error',
        });
        return;
      }
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <MenuContainer
      button={
        <LoadingButton
          startIcon={<DownloadSimpleIcon />}
          size="small"
          loading={isLoading}
        >
          {t('accountBillStatementBlockMenu.download')}
        </LoadingButton>
      }
    >
      <MenuItem onClick={downloadCsv}>
        {t('accountBillStatementBlockMenu.csv')}
      </MenuItem>
      <MenuItem onClick={downloadPdf} disabled={!statement.documentId}>
        {t('accountBillStatementBlockMenu.pdf')}
      </MenuItem>
      {receiptsZipOnBillingPageEnabled && (
        <MenuItem onClick={downloadZip}>
          {t('accountBillStatementBlockMenu.zip')}
        </MenuItem>
      )}
      {isCardStmtZipEnabled && <Divider />}
      {isCardStmtZipEnabled && (
        <MenuItem onClick={downloadCardStatementZip}>
          {t('accountBillStatementBlockMenu.cardStatementZip')}
        </MenuItem>
      )}
      {statement.totalTransactionCount > 0 && !!buttonsList.length && (
        <Divider />
      )}
      {statement.totalTransactionCount > 0 &&
        !!buttonsList.length &&
        buttonsList.map((item) => (
          <MenuItem
            key={item.key}
            disabled={item.disabled}
            onClick={() => downloadBillPaymentViaAccSystem(item.format)}
          >
            {item.label}
          </MenuItem>
        ))}
    </MenuContainer>
  );
};

export default AccountBillStatementBlockMenu;
