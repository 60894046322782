import FormatDecimal from 'components/FormatDecimal';
import { GlobeHemisphereWestIcon, Typography } from 'elements';

interface Props {
  unit?: 'kg' | 't';
  value: number;
}

export const Co2EmissionBadge = ({ unit = 't', value }: Props) => {
  return (
    <>
      <GlobeHemisphereWestIcon color="action" />
      <Typography variant="body2" ml={0.5} noWrap>
        {unit === 'kg' ? (
          <FormatDecimal value={Math.round(value / 1000)} unit="kg" />
        ) : (
          <FormatDecimal value={value / 1000000} unit="t" />
        )}
      </Typography>
    </>
  );
};
