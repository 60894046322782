import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { Button } from 'elements';
import usePartnerName from 'hooks/usePartnerName';

interface Props {
  isLoading: boolean;
  onSuccess: () => void;
  disabled?: boolean;
}

const AuthConfirmationComponent = ({
  isLoading,
  onSuccess,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const partnerName = usePartnerName();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setIsConfirmDialogOpen(true)}
        disabled={disabled}
        size="large"
      >
        {t('partnersPage.generalPartner.authorizeButton')}
      </Button>

      <ConfirmDialog
        open={isConfirmDialogOpen}
        loading={isLoading}
        onClose={() => setIsConfirmDialogOpen(false)}
        onSuccess={onSuccess}
        title={t('partnersPage.confirmDialog.title', { partnerName })}
        confirmButtonProps={{ children: t('common.button.continue') }}
        description={t('partnersPage.confirmDialog.description', {
          partnerName,
        })}
      />
    </>
  );
};

export default AuthConfirmationComponent;
