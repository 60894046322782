import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { CashbackRedemptionStatusBadge } from 'domains/billing/components';
import {
  Alert,
  Box,
  Button,
  CurrencyCircleDollarIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from 'elements';
import { CashbackRedemption, CashbackRedemptionType } from 'services/constants';

interface Props {
  redemptions: CashbackRedemption[] | null;
  onRetry: () => void;
}

const CashbackRedemptions = ({ redemptions, onRetry }: Props) => {
  const { t } = useTranslation();

  if (!redemptions) {
    return (
      <Alert
        sx={{ m: 4 }}
        severity="error"
        action={
          <Button variant="text" color="error" size="small" onClick={onRetry}>
            {t('common.retry')}
          </Button>
        }
      >
        {t('errors.loadData')}
      </Alert>
    );
  }

  if (!redemptions.length) {
    return (
      <Typography color="text.secondary" m={4}>
        {t('int.cashbackRedemptions.noData')}
      </Typography>
    );
  }

  return (
    <Box m={3}>
      <TableContainer>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 100 }}>
                {t('int.cashbackRedemptions.requested')}
              </TableCell>
              <TableCell sx={{ width: 96 }}>
                {t('int.cashbackRedemptions.organization')}
              </TableCell>
              <TableCell sx={{ width: 56 }}>
                {t('int.cashbackRedemptions.type')}
              </TableCell>
              <TableCell sx={{ width: 95 }}>
                {t('int.cashbackRedemptions.code')}
              </TableCell>
              <TableCell align="right" sx={{ width: 105 }}>
                {t('int.cashbackRedemptions.amount')}
              </TableCell>
              <TableCell sx={{ width: 100 }}>
                {t('int.cashbackRedemptions.state')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {redemptions.map((item) => (
              <TableRow key={item.id}>
                <TableCell sx={{ pr: 0 }}>
                  <Typography variant="inherit" noWrap>
                    {moment(item.createdAt).format('YYYY-MM-DD')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Tooltip title={item.organizationName}>
                    <Typography variant="inherit" noWrap>
                      {item.organizationName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {item.type === CashbackRedemptionType.payout && (
                    <CurrencyCircleDollarIcon />
                  )}
                </TableCell>
                <TableCell sx={{ pr: 0 }}>
                  <Typography variant="inherit" noWrap>
                    {item.publicId?.toUpperCase()}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="inherit" noWrap>
                    <FormatMoney value={item.amount} fractionalPart />
                  </Typography>
                </TableCell>
                <TableCell>
                  <CashbackRedemptionStatusBadge status={item.state} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CashbackRedemptions;
