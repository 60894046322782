import printJS from 'print-js';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { CrefoReport, ExternalReport } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { downloadFileUsingAnchorTag, getGenericErrorMsg } from 'services/utils';

function b64DecodeUnicode(str: string) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}

const useDownloadReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { creditProfile },
  } = useCreditAndComplianceContext();

  return async (report: CrefoReport | ExternalReport, fileName: string) => {
    try {
      if ('crefoReportRawResponseId' in report) {
        const { data } = await api.getCrefoReportContents(
          report.crefoReportRawResponseId
        );
        if (!mounted.current) return;
        downloadFileUsingAnchorTag(
          fileName,
          'data:application/pdf;base64,' + data
        );
      } else {
        const { data } = await api.getExternalReportContents(
          creditProfile!.agencyProfile.duns.value,
          report.reportId
        );
        if (!mounted.current) return;
        printJS({ printable: b64DecodeUnicode(data), type: 'raw-html' });
      }
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };
};

export default useDownloadReport;
