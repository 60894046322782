import { ReactNode } from 'react';
import { FormHelperText, styled } from '@mui/material';
import ReactVerificationCodeInput from 'react-verification-code-input';

export interface VerificationCodeFieldProps {
  // Copy-pasted from the react-verification-code-input interface because it's not exported
  onChange?: (val: string) => void;
  onComplete?: (val: string) => void;
  fields?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
  // Custom props
  error?: boolean;
  helperText?: ReactNode;
}

const VerificationCodeField = styled(
  ({ error, helperText, ...props }: VerificationCodeFieldProps) => (
    <>
      <ReactVerificationCodeInput fieldWidth={34} fieldHeight={40} {...props} />
      {helperText && (
        <FormHelperText
          error={error}
          sx={{ textAlign: 'center', marginTop: '4px' }}
        >
          {helperText}
        </FormHelperText>
      )}
    </>
  )
)(({ theme, error }) => ({
  width: '100% !important',

  '& > div': {
    display: 'flex',
    justifyContent: 'center',

    '& > input': {
      padding: '13px 0',
      margin: '0 6px',
      borderRadius: `${theme.shape.borderRadius}px !important`,
      border: `1px solid ${theme.variables.input.outlined.enabledBorder} !important`,
      color: theme.variables.text.primary,
      caretColor: 'currentColor',
      background: 'none',
      ...theme.variables.components['input-text'],

      '&:hover': {
        borderColor: theme.variables.text.primary + ' !important',
      },
      '&:focus': {
        borderWidth: '2px !important',
        borderColor: theme.variables.primary.main + ' !important',
      },
      ...(error && {
        '&[type="tel"]': {
          borderColor: theme.variables.error.main + ' !important',
        },
      }),
      '&[disabled]': {
        borderColor:
          theme.variables.input.outlined.disabledBorder + ' !important',
      },
    },
  },
}));

export default VerificationCodeField;
