import React, { useLayoutEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from 'elements';

const ExpandableText = styled('div')<{ isExpanded: boolean }>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: ${({ isExpanded }) => (isExpanded ? 'block' : '-webkit-box')};
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  width: 100%;
`;

interface Props {
  description: React.ReactNode;
  descriptionLocal?: React.ReactNode | null;
}

const OnboardingDocumentDescription = ({
  description,
  descriptionLocal,
}: Props) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (textRef.current) {
      setIsExpandable(
        textRef.current.clientHeight < textRef.current.scrollHeight
      );
    }
  }, [description]);

  const hasLocalDescription = !!descriptionLocal;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      marginTop={3}
    >
      <ExpandableText ref={textRef} isExpanded={isExpanded}>
        {hasLocalDescription ? (
          <>
            <div>{descriptionLocal}</div>
            <Typography color="grey.500" variant="body2">
              {description}
            </Typography>
          </>
        ) : (
          description
        )}
      </ExpandableText>

      {isExpandable && (
        <Button
          variant="text"
          size="small"
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          {isExpanded
            ? t('common.button.showLess')
            : t('common.button.showMore')}
        </Button>
      )}
    </Box>
  );
};

export default OnboardingDocumentDescription;
