import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: auto;
    font-size: 18px;
    line-height: 26px;
    color: ${({ theme }) => theme.colors.grey10};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 16px; /* rem units are used by MUI and it expects 1 rem to be 16px */
  }

  input,
  select,
  textarea,
  button {
    font-family: inherit;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
  }

  /* React Slick */
  .slick-slider {
    position: relative;
    user-select: none;

    & .slick-track,
    & .slick-list {
      transform: translate3d(0, 0, 0);
    }
  }

  .slick-list {
    position: relative;
    overflow: hidden;

    &:focus {
      outline: none;
    }
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
      display: table;
      content: '';
    }

    &:after {
      clear: both;
    }
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;


    .slick-initialized & {
      display: block;
    }
  }

  .slick-dots {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;
  }
`;

export default GlobalStyle;
