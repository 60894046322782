import { GridColumnHeaderTitle } from '@mui/x-data-grid-pro';
import { InfoIcon, Tooltip } from 'elements';

interface Props {
  width: number;
  label: string;
  description: string;
}

const GridColumnHeaderTitleWithTooltipDescription = ({
  width,
  label,
  description,
}: Props) => {
  return (
    <>
      <GridColumnHeaderTitle columnWidth={width} label={label} />
      <Tooltip title={description}>
        <InfoIcon
          sx={(theme) => ({
            width: 18,
            height: 18,
            ml: 0.5,
            color: theme.palette.action.active,
            verticalAlign: 'text-top',
          })}
        />
      </Tooltip>
    </>
  );
};

export default GridColumnHeaderTitleWithTooltipDescription;
