import { useMemo } from 'react';
import { lessThanOrEqual } from 'dinero.js';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import {
  useCardAccountCurrency,
  useCardAccountNameGetter,
} from 'domains/card/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  LoaderWithOverlay,
  MenuItem,
  MoneyField,
  Select,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CardAccountCurrency,
  CardAccountStatus,
  CurrencyCashbackByCurrencyMap,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  getGenericErrorMsg,
} from 'services/utils';

interface FormValues {
  cardAccountId: string;
  customCashbackValue: string;
  comment: string;
}

interface Props extends DialogProps {
  cashbackByCurrency: CurrencyCashbackByCurrencyMap;
  onClose: () => void;
}

export const AddCustomCashbackAmountDialog = ({
  cashbackByCurrency,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, cardAccounts, defaultCardAccount },
  } = useGlobalState();
  const activeCardAccounts = useMemo(
    () =>
      cardAccounts.filter(
        (item) => item.status.value === CardAccountStatus.active
      ),
    [cardAccounts]
  );
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      cardAccountId: defaultCardAccount!.id,
      customCashbackValue: '',
      comment: '',
    },
    onSubmit: async (values) => {
      try {
        await api.addCustomCashback(organization!.id, {
          cardAccountId: values.cardAccountId,
          customCashbackAmount: convertDineroToMoney(
            dineroFromFloat(values.customCashbackValue, currency)
          ),
          comment: values.comment.trim(),
        });
        if (!mounted.current) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });
  const getCardAccountName = useCardAccountNameGetter();
  const currency = useCardAccountCurrency(formik.values.cardAccountId);
  const maxCustomCashback = useMemo(() => {
    const currencyCashback =
      cashbackByCurrency.buckets[currency.code as CardAccountCurrency];
    return currencyCashback
      ? currencyCashback.cashbackDefaults.maxCustomCashback
      : { value: 100000, currency: currency.code };
  }, [cashbackByCurrency, currency]);

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('int.addCustomCashbackAmountDialog.title', {
          name: organization!.name,
        })}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="add-custom-cashback-amount-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            {activeCardAccounts.length > 1 && (
              <Grid item xs={12}>
                <FormControl fullWidth disabled={formik.isSubmitting}>
                  <InputLabel id="card-account-id-select-label">
                    {t('int.addCustomCashbackAmountDialog.cardAccount')}
                  </InputLabel>
                  <Select<string>
                    {...formik.getFieldProps('cardAccountId')}
                    renderValue={(selected) => {
                      const account = activeCardAccounts.find(
                        (item) => item.id === selected
                      );

                      return account ? getCardAccountName(account) : null;
                    }}
                    labelId="card-account-id-select-label"
                    MenuProps={{ PaperProps: { sx: { maxWidth: 500 } } }}
                  >
                    {activeCardAccounts.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <ListItemText
                          primary={getCardAccountName(item)}
                          primaryTypographyProps={{ noWrap: true }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <MoneyField
                autoFocus
                allowNegative
                label={t('int.addCustomCashbackAmountDialog.inputLabel')}
                placeholder={t('int.addCustomCashbackAmountDialog.inputLabel')}
                helperText={
                  <Trans
                    i18nKey="int.addCustomCashbackAmountDialog.helperText"
                    components={{
                      max: <FormatMoney value={maxCustomCashback} />,
                      b: (
                        <Typography
                          variant="inherit"
                          component="b"
                          color="textPrimary"
                        />
                      ),
                    }}
                  />
                }
                disabled={formik.isSubmitting}
                isAllowed={({ value }) =>
                  lessThanOrEqual(
                    dineroFromFloat(value, currency),
                    dineroFromMoney(maxCustomCashback)
                  )
                }
                isNumericString
                currency={currency.code}
                {...omit(
                  formik.getFieldProps('customCashbackValue'),
                  'onChange'
                )}
                onValueChange={({ value }) => {
                  formik.setFieldValue('customCashbackValue', value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={t('int.addCustomCashbackAmountDialog.commentLabel')}
                placeholder={t(
                  'int.addCustomCashbackAmountDialog.commentLabel'
                )}
                disabled={formik.isSubmitting}
                multiline
                rows={3}
                {...formik.getFieldProps('comment')}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          form="add-custom-cashback-amount-form"
          type="submit"
          disabled={
            formik.isSubmitting ||
            !formik.values.customCashbackValue ||
            !formik.values.comment.trim()
          }
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(AddCustomCashbackAmountDialog);
