import React from 'react';
import { camelCase } from 'lodash';
import { FileError, FileWithPath } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormHelperText,
  IconButton,
  TrashIcon,
  Typography,
  XIcon,
} from 'elements';
import { OnboardingDocumentFile } from 'services/constants';
import formatBytes from 'services/utils/formatBytes';

type SelectedFileProps = {
  version: 'selected';
  file: FileWithPath;
  isLoading: boolean;
  onDelete: () => void;
  uploadingProgress?: number;
};

type UploadedFileProps = {
  version: 'uploaded';
  file: OnboardingDocumentFile;
  isLoading: boolean;
  onDelete: () => Promise<void>;
};

type RejectedFileProps = {
  version: 'error';
  errors: FileError[];
  file: FileWithPath;
  isLoading: boolean;
  maxFileSizeInBytes: number;
};

export function Row(props: SelectedFileProps): React.ReactElement;
export function Row(props: UploadedFileProps): React.ReactElement;
export function Row(props: RejectedFileProps): React.ReactElement;

export function Row(
  props: SelectedFileProps | UploadedFileProps | RejectedFileProps
) {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <div>
        <Typography component="span" variant="body2" sx={{ color: 'grey.800' }}>
          {props.file.name}
        </Typography>
        <Typography
          component="span"
          variant="body2"
          sx={{ color: 'grey.500' }}
        >{` ${formatBytes(
          props.version === 'uploaded'
            ? props.file.sizeInBytes
            : props.file.size
        )}`}</Typography>

        {props.isLoading &&
          props.version === 'selected' &&
          typeof props.uploadingProgress === 'number' && (
            <Typography
              component="span"
              variant="body2"
              sx={{ color: 'grey.500' }}
            >{` (${props.uploadingProgress}%)`}</Typography>
          )}
      </div>

      {props.version !== 'error' && (
        <IconButton onClick={props.onDelete} disabled={props.isLoading}>
          {props.version === 'uploaded' ? <TrashIcon /> : <XIcon />}
        </IconButton>
      )}

      {props.version === 'error' && (
        <FormHelperText error>
          {props.errors.map((e) => (
            <span key={e.code}>
              {t(`errors.${camelCase(e.code)}`, {
                maxFileSize: formatBytes(props.maxFileSizeInBytes),
              })}
            </span>
          ))}
        </FormHelperText>
      )}
    </Box>
  );
}
