import React from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment/moment';
import { CardTileProps } from 'domains/card/components/CardTile/index';
import { isCardVoid } from 'services/utils';
import { CardNumber, PropName, PropValue } from './style';

const CardSensitiveData = ({ card, pan, cvv }: CardTileProps) => {
  return (
    <>
      <CardNumber data-test-id="card-number">{pan}</CardNumber>
      <Box display="flex">
        {card.expiryDate && (
          <Box width={80}>
            <PropName>EXP</PropName>
            <PropValue>{moment.utc(card.expiryDate).format('MM/YY')}</PropValue>
          </Box>
        )}
        {cvv && !isCardVoid(card) && (
          <Box>
            <PropName>CVV</PropName>
            <PropValue>{cvv}</PropValue>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CardSensitiveData;
