import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import FormatDecimal from 'components/FormatDecimal';
import { OrganizationEmission } from 'services/constants';

const Container = styled.div`
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  margin: 0 0 20px 50px;
  min-width: 175px;
  padding: 30px 0 0 50px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px 20px;
  min-width: fit-content;

  & > div:first-of-type {
    margin-bottom: 16px;
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 10px;
  line-height: 14px;
`;

const Value = styled.div<{ $color: string }>`
  color: ${({ $color }) => $color};
  font-size: 24px;
  line-height: 24px;
`;

interface Props {
  orgEmission: OrganizationEmission | null;
}

const SummaryStats = ({ orgEmission }: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const lifetimeOffsetKg = (orgEmission?.lifetimeCarbonOffset ?? 0) / 1000;
  const lifetimeEmissionKg = (orgEmission?.lifetimeCarbonEmission ?? 0) / 1000;

  return (
    <Container>
      <Column>
        <Cell>
          <Label>
            {t('pliantEarthPage.dashboard.summaryStats.compensated')}
          </Label>
          <Value $color={colors.earthRoyalBlue}>
            <FormatDecimal value={lifetimeOffsetKg} unit="kg" precision={0} />
          </Value>
        </Cell>

        <Cell>
          <Label>{t('pliantEarthPage.dashboard.summaryStats.produced')}</Label>
          <Value $color={colors.earthGrey}>
            <FormatDecimal value={lifetimeEmissionKg} unit="kg" precision={0} />
          </Value>
        </Cell>
      </Column>
    </Container>
  );
};

export default SummaryStats;
