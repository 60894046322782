import { useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import { AccountingAutocomplete } from 'domains/transaction/components';
import AutoMatchedSelectIndicator from 'domains/transaction/pages/TransactionDetailsPage/AutoMatchedSelectIndicator';
import { Box, Typography } from 'elements';
import {
  AccountingItemStatus,
  AccountingTransaction,
  VatRate,
} from 'services/constants';

interface Props {
  isAutoMatched: boolean;
  organizationId: string;
  merchantId?: string;
  isExportPage: boolean;
  onChange: (vatRate: VatRate | null) => void;
  accountingTransaction: AccountingTransaction;
  disabled: boolean;
}

const VatRatesSelect = ({
  isAutoMatched,
  organizationId,
  merchantId,
  isExportPage,
  accountingTransaction,
  onChange,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { vatRates, accountingSettings },
  } = useGlobalState();
  const [inputValue, setInputValue] = useState('');

  const selectedVatRate = vatRates?.find(
    (item) => item.id === accountingTransaction?.vatRateId
  );

  useEffect(() => {
    if (selectedVatRate?.name !== inputValue)
      setInputValue(selectedVatRate?.name || '');
  }, [selectedVatRate?.name]);

  const renderSelectedValue = () => {
    if (selectedVatRate) {
      return (
        <Box overflow="hidden" pl={2}>
          <Typography variant="body2" component="div" noWrap>
            {selectedVatRate.name} (
            <FormatPercent
              value={selectedVatRate.rate}
              options={{
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }}
            />
            )
          </Typography>
          {isExportPage && selectedVatRate.code && (
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              noWrap
            >
              {selectedVatRate.code}
            </Typography>
          )}
        </Box>
      );
    }
    return '—';
  };

  if (!accountingSettings?.vatRateEnabled || !vatRates) return null;

  const value = selectedVatRate || null;
  const visibleVatRates = sortBy(
    vatRates.filter(
      (item) =>
        item.status === AccountingItemStatus.active ||
        item.id === accountingTransaction?.vatRateId
    ),
    (v) => v.name.toLowerCase()
  );

  return (
    <Box mb={1}>
      <AccountingAutocomplete<VatRate>
        label={
          <Box display="flex" alignItems="center">
            <Box overflow="hidden">
              <Typography variant="body2" noWrap>
                {t('transactionDetails.vatRateSelectLabel')}
              </Typography>
              {isExportPage && (
                <Typography
                  noWrap
                  component="div"
                  variant="caption"
                  color="textSecondary"
                >
                  {t('transactionDetails.vatCode')}
                </Typography>
              )}
            </Box>

            <AutoMatchedSelectIndicator
              isAutoMatched={isAutoMatched}
              organizationId={organizationId}
              merchantId={merchantId}
            />
          </Box>
        }
        data-test-id="vat-rates-autocomplete"
        selectedValueComponent={renderSelectedValue()}
        disabled={disabled}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        value={value}
        options={visibleVatRates}
        onChange={(_, option) => {
          if (!option) setInputValue('');
          onChange(option as VatRate);
        }}
        onClear={() => {
          setInputValue('');
          if (value) onChange(null);
        }}
        getOptionLabel={(option: VatRate) => option.name || ''}
        filterOptions={(options, { inputValue }) =>
          options.filter((option) => {
            const name = option.name.toLowerCase();
            const code = isExportPage ? option.code?.toLowerCase() : '';
            const val = inputValue?.toLowerCase();
            return name.includes(val) || code?.includes(val);
          })
        }
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              <Box overflow="hidden">
                <Typography noWrap>
                  {option.name} (
                  <FormatPercent
                    value={option.rate}
                    options={{
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    }}
                  />
                  )
                </Typography>
                {isExportPage && option.code && (
                  <Typography
                    noWrap
                    component="div"
                    variant="caption"
                    color="textSecondary"
                  >
                    {option.code}
                  </Typography>
                )}
              </Box>
            </li>
          );
        }}
      />
    </Box>
  );
};

export default VatRatesSelect;
