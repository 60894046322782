import { Trans, useTranslation } from 'react-i18next';
import NothingFound from 'components/NothingFound';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import {
  ArrowsClockwiseIcon,
  Box,
  Button,
  DataGrid,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import { CodatTaxRateItem } from 'services/constants';
import useColumns from './useColumns';
import useVatRatesSync from './useVatRatesSync';

interface Props {
  isPartialFlow?: boolean;
  onPartialFlowSuccess?: () => void;
  onPartialFlowCancel?: () => void;
}

const VatRatesStep = ({
  isPartialFlow,
  onPartialFlowSuccess,
  onPartialFlowCancel,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const {
    taxRates,
    selectedIds,
    onItemsSelect,
    isError,
    isLoading,
    isInnerLoading,
    getData,
    onTaxRatesSave,
    fetchCodatData,
    onClose,
  } = useVatRatesSync();

  const gridColumns = useColumns(isPartialFlow);

  if (isLoading) return <LoaderWithOverlay loading />;

  if (isError)
    return (
      <Box margin="auto">
        <WidgetError onReload={getData} />
      </Box>
    );

  return (
    <>
      <DialogTitle>{t('codatSyncSetupDialog.vatRatesStep.title')}</DialogTitle>

      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      >
        {!!taxRates?.length && (
          <Typography color="text.secondary">
            <Trans
              i18nKey="codatSyncSetupDialog.vatRatesStep.description"
              values={{
                name: accountingSettings!.accountingSystemName,
              }}
              count={taxRates.length}
              components={{
                bold: <Typography component="span" color="text.primary" />,
              }}
            />
          </Typography>
        )}

        {taxRates?.length ? (
          <>
            <Box my={3}>
              <Typography color="text.secondary">
                {t('codatSyncSetupDialog.controls.selectedLabel', {
                  value: `${selectedIds.length} / ${taxRates.length}`,
                })}
              </Typography>
            </Box>

            <Box flex="1" overflow="hidden">
              <DataGrid<CodatTaxRateItem>
                checkboxSelection
                disableRowSelectionOnClick={false}
                onRowSelectionModelChange={(newRowSelectionModel) =>
                  onItemsSelect(newRowSelectionModel as string[])
                }
                rowSelectionModel={selectedIds}
                columns={gridColumns}
                rows={taxRates}
              />
            </Box>
          </>
        ) : (
          <NothingFound />
        )}
      </DialogContent>

      <DialogActions>
        {isPartialFlow ? (
          <Box mr="auto">
            <Button
              variant="text"
              onClick={onPartialFlowCancel}
              disabled={isInnerLoading}
            >
              {t('common.button.close')}
            </Button>

            <Button
              variant="text"
              onClick={fetchCodatData}
              disabled={isInnerLoading}
              startIcon={<ArrowsClockwiseIcon />}
            >
              {t('codatSyncSetupDialog.syncNowButton')}
            </Button>
          </Box>
        ) : (
          <Button variant="text" onClick={onClose} disabled={isInnerLoading}>
            {t('common.button.close')}
          </Button>
        )}
        <Button
          disabled={!selectedIds.length || isInnerLoading}
          onClick={() => onTaxRatesSave(onPartialFlowSuccess)}
        >
          {selectedIds.length
            ? t('codatSyncSetupDialog.vatRatesStep.syncVatRatesButton', {
                count: selectedIds.length,
              })
            : t('common.button.next')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={isInnerLoading} />
    </>
  );
};

export default VatRatesStep;
