import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import useUrls from 'hooks/useUrls';
import { ReviewFlagReason, TransactionReviewStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const MAX_COMMENT_LENGTH = 200;

interface FormValues {
  flagReason: ReviewFlagReason;
  reviewComment: string;
}

interface Props extends DialogProps {
  transactionIds: string[];
  isTxFrozenByExport: boolean;
  onClose: () => void;
  onSuccess: () => void;
  hasMultipleAccountingTransactions: boolean;
  reviewFlagReason?: ReviewFlagReason;
  reviewComment?: string | null;
}

const FlagTransactionDialog = ({
  transactionIds,
  isTxFrozenByExport,
  onSuccess,
  hasMultipleAccountingTransactions,
  reviewFlagReason: initialFlagReason,
  reviewComment: initialReviewComment,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { HELP_CENTER_MANAGER_TX_REVIEWS_URL } = useUrls();
  const {
    state: { featureModules },
  } = useGlobalState();
  const getInitialFlagReason = () => {
    if (initialFlagReason) return initialFlagReason;
    if (!featureModules.PRIVATE_EXPENSE) return ReviewFlagReason.outOfPolicy;
    if (hasMultipleAccountingTransactions) return ReviewFlagReason.outOfPolicy;
    if (isTxFrozenByExport) return ReviewFlagReason.outOfPolicy;
    return '' as ReviewFlagReason;
  };
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      flagReason: getInitialFlagReason(),
      reviewComment: initialReviewComment || '',
    },
    onSubmit: async ({ flagReason, reviewComment }) => {
      try {
        await api.updateTransactionsReview({
          transactionIds,
          reviewStatus: TransactionReviewStatus.flagged,
          flagReason,
          reviewComment,
        });
        if (!mounted.current) return;
        onSuccess();
        if (initialFlagReason) {
          enqueueSnackbar(t('flagTransactionDialog.reasonChangedMsg'));
        } else {
          enqueueSnackbar(
            t(
              transactionIds.length > 1
                ? 'flagTransactionDialog.flaggedSuccessMsgMultiple'
                : 'flagTransactionDialog.flaggedSuccessMsg'
            )
          );
        }
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const renderFlagReasonHelperText = () => {
    if (!featureModules.PRIVATE_EXPENSE)
      return t(
        'flagTransactionDialog.flagReasonHelperText.privateExpenseDisabled'
      );

    if (!formik.values.flagReason) {
      return (
        <Trans
          i18nKey="flagTransactionDialog.flagReasonHelperText.fieldEmpty"
          components={{
            a: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                style={{ color: 'inherit' }}
                href={HELP_CENTER_MANAGER_TX_REVIEWS_URL}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      );
    }

    return t(
      `flagTransactionDialog.flagReasonHelperText.${formik.values.flagReason}`
    );
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {transactionIds.length > 1
          ? t('flagTransactionDialog.titleMultiple', {
              count: transactionIds.length,
            })
          : t('flagTransactionDialog.title')}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          id="flag-transaction-form"
        >
          <Typography variant="body2" mb={2}>
            {t('flagTransactionDialog.description')}
          </Typography>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>
              {t('flagTransactionDialog.flagReasonLabel')}
            </InputLabel>
            <Select<string>
              {...formik.getFieldProps('flagReason')}
              displayEmpty
              renderValue={(selected) => {
                if (!selected)
                  return (
                    <Typography color="text.disabled">
                      {t('flagTransactionDialog.flagReasonPlaceholder')}
                    </Typography>
                  );

                return t(`reviewFlagReasons.${selected}`);
              }}
              disabled={!featureModules.PRIVATE_EXPENSE}
            >
              {hasMultipleAccountingTransactions || isTxFrozenByExport ? (
                <Tooltip
                  title={t(
                    hasMultipleAccountingTransactions
                      ? 'flagTransactionDialog.privateExpenseDisabledSplitTooltip'
                      : 'flagTransactionDialog.privateExpenseDisabledExportedTooltip'
                  )}
                >
                  <MenuItem
                    value={ReviewFlagReason.privateExpense}
                    onClick={(e) => e.preventDefault()}
                    disableRipple
                    sx={{ cursor: 'default' }}
                  >
                    <Typography color="text.disabled">
                      {t(
                        `reviewFlagReasons.${ReviewFlagReason.privateExpense}`
                      )}
                    </Typography>
                  </MenuItem>
                </Tooltip>
              ) : (
                <MenuItem value={ReviewFlagReason.privateExpense}>
                  {t(`reviewFlagReasons.${ReviewFlagReason.privateExpense}`)}
                </MenuItem>
              )}
              <MenuItem value={ReviewFlagReason.outOfPolicy}>
                {t(`reviewFlagReasons.${ReviewFlagReason.outOfPolicy}`)}
              </MenuItem>
            </Select>
            <FormHelperText>{renderFlagReasonHelperText()}</FormHelperText>
          </FormControl>
          <TextField
            {...formik.getFieldProps('reviewComment')}
            label={t('flagTransactionDialog.reviewCommentLabel')}
            helperText={t('errors.maxChars', { count: MAX_COMMENT_LENGTH })}
            inputProps={{ maxLength: MAX_COMMENT_LENGTH }}
            multiline
            minRows={1}
            maxRows={5}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={
            !formik.values.flagReason ||
            !formik.values.reviewComment.trim() ||
            formik.isSubmitting
          }
          type="submit"
          form="flag-transaction-form"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(FlagTransactionDialog);
