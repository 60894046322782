import { useState } from 'react';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card, CardStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLocking: boolean;
  isUnlocking: boolean;
}

const useCardLock = (
  onCardUpdated: (card: Card) => void,
  isPlatformFeeCard?: boolean
) => {
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const [state, setState] = useState<State>({
    isLocking: false,
    isUnlocking: false,
  });

  const lockCard = async (cardId: string) => {
    try {
      setState((prevState) => ({ ...prevState, isLocking: true }));
      const data = isPlatformFeeCard
        ? await api.lockPlatformFeeCard(cardId)
        : await api.lockCard(cardId);
      if (!mounted.current) return;
      onCardUpdated(data);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    } finally {
      setState((prevState) => ({ ...prevState, isLocking: false }));
    }
  };

  const unlockCard = async (cardId: string) => {
    try {
      setState((prevState) => ({ ...prevState, isUnlocking: true }));
      const data = isPlatformFeeCard
        ? await api.unlockPlatformFeeCard(cardId)
        : await api.unlockCard(cardId);
      if (!mounted.current) return;
      onCardUpdated(data);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    } finally {
      setState((prevState) => ({ ...prevState, isUnlocking: false }));
    }
  };

  const isCardLocked = (card: Card) => card.status === CardStatus.locked;

  return {
    lockCard,
    unlockCard,
    isCardLocked,
    isLockingOrUnlocking: state.isLocking || state.isUnlocking,
  };
};

export default useCardLock;
