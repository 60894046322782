import { useMemo } from 'react';
import {
  cardCategoryControlTypes,
  CardConfigSetting,
  cardDateControlTypes,
  cardLocationControlTypes,
  cardMerchantControlTypes,
  cardTimeControlTypes,
} from 'services/constants';

const useCardControlsDisplaySettings = (
  cardConfigSetting: CardConfigSetting
) => {
  return useMemo(() => {
    const categoryTypes = cardCategoryControlTypes.filter(
      (item) => cardConfigSetting.cardControls.categories[item].enabled
    );
    const merchantTypes = cardMerchantControlTypes.filter(
      (item) => cardConfigSetting.cardControls.merchants[item].enabled
    );
    const dateTypes = cardDateControlTypes.filter(
      (item) => cardConfigSetting.cardControls.dates[item].enabled
    );
    const timeTypes = cardTimeControlTypes.filter(
      (item) => cardConfigSetting.cardControls.times[item].enabled
    );

    return {
      categories: {
        isVisible: categoryTypes.length > 0,
        availableTypes: categoryTypes,
      },
      merchants: {
        isVisible: merchantTypes.length > 0,
        availableTypes: merchantTypes,
      },
      dates: {
        isVisible: dateTypes.length > 0,
        availableTypes: dateTypes,
      },
      times: {
        isVisible: timeTypes.length > 0,
        availableTypes: timeTypes,
      },
      // TODO: update logic when BE is ready
      locations: {
        isVisible: true,
        availableTypes: cardLocationControlTypes,
      },
    };
  }, [cardConfigSetting]);
};

export default useCardControlsDisplaySettings;
