import { useGlobalState } from 'context/GlobalState';
import withPageErrorWrapper from 'hoc/withPageErrorWrapper';
import OrganizationDefaultPage from './OrganizationPage';
import OrganizationWhiteLabeledPage from './OrganizationWhiteLabeledPage';

const OrganizationPage = () => {
  const {
    state: { isComplianceRiskWhiteLabelApp },
  } = useGlobalState();

  return isComplianceRiskWhiteLabelApp ? (
    <OrganizationWhiteLabeledPage />
  ) : (
    <OrganizationDefaultPage />
  );
};

export default withPageErrorWrapper(OrganizationPage);
