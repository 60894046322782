import { AxiosError } from 'axios';
import moment from 'moment';
import { Trans } from 'react-i18next';

// helper for showing generic error message with details (date/exceptionId)
export const getGenericErrorMsg = (error: unknown) => {
  if (error instanceof AxiosError && error.response) {
    return (
      <Trans
        i18nKey={
          error.response.status === 408
            ? 'errors.requestTimeout'
            : 'errors.generalWithDetails'
        }
        components={{ div: <div />, b: <b /> }}
        values={{
          exceptionId: error.response.data?.exceptionId || '',
          date: moment.tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss'),
        }}
      />
    );
  }

  return <Trans i18nKey="errors.general" />;
};
