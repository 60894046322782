import React, { useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import OnboardingDocumentRow from 'components/OnboardingDocumentRow';
import OnboardingBlockWrapper from 'components/OnboardingPage/OnboardingBlockWrapper';
import { useOnboardingContext } from 'components/OnboardingPage/useOnboardingContext';
import { useGlobalState } from 'context/GlobalState';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  OnboardingDocumentCategory,
  OnboardingDocumentStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import { Description, HR, StyledButton } from './style';

const OnboardingDocuments = () => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const canUser = useCanUser();
  const {
    actions: { fetchOnboardingDocuments, setOnboardingDocuments },
    state: { onboardingDocuments },
  } = useOnboardingContext();
  const [isLoading, setIsLoading] = useState(false);
  const onboardingDocumentsExternallyVisible = useMemo(
    () =>
      onboardingDocuments.filter(
        (doc) =>
          ![
            OnboardingDocumentStatus.ARCHIVED,
            OnboardingDocumentStatus.DRAFT,
          ].includes(doc.status)
      ),
    [onboardingDocuments]
  );

  const riskAndComplianceOnboardingDocuments = onboardingDocumentsExternallyVisible.filter(
    (doc) =>
      [
        OnboardingDocumentCategory.COMPLIANCE,
        OnboardingDocumentCategory.RISK,
      ].includes(doc.type.category)
  );

  const hideOnboardingDocumentsBlock =
    riskAndComplianceOnboardingDocuments.length === 0;

  const confirmDocuments = async (
    documentCategoryToConfirm: OnboardingDocumentCategory
  ) => {
    try {
      setIsLoading(true);
      const {
        onboardingDocuments: updatedOnboardingDocuments,
      } = await api.submitOnboardingDocuments(
        organization!.id,
        onboardingDocuments
          .filter(
            (doc) =>
              doc.type.category === documentCategoryToConfirm &&
              doc.status === OnboardingDocumentStatus.REQUESTED
          )
          .map((doc) => doc.id)
      );
      if (!mounted.current) return;
      setOnboardingDocuments(updatedOnboardingDocuments);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const getCanConfirmRequestedOnboardingDocumentsByCategory = (
    documentCategoryToConfirm: OnboardingDocumentCategory
  ) =>
    onboardingDocumentsExternallyVisible.some(
      (doc) =>
        doc.type.category === documentCategoryToConfirm &&
        doc.status === OnboardingDocumentStatus.REQUESTED
    ) &&
    onboardingDocumentsExternallyVisible
      .filter(
        (doc) =>
          doc.type.category === documentCategoryToConfirm &&
          doc.status === OnboardingDocumentStatus.REQUESTED
      )
      .every((doc) => {
        if (doc.type.question) {
          return !!doc.answerLocal || doc.files.length > 0;
        }

        return doc.files.length >= doc.type.minNumberOfFiles;
      });

  const canConfirmRequestedRiskDocuments = useMemo(
    () =>
      getCanConfirmRequestedOnboardingDocumentsByCategory(
        OnboardingDocumentCategory.RISK
      ),
    [onboardingDocumentsExternallyVisible]
  );
  const canConfirmRequestedComplianceDocuments = useMemo(
    () =>
      getCanConfirmRequestedOnboardingDocumentsByCategory(
        OnboardingDocumentCategory.COMPLIANCE
      ),
    [onboardingDocumentsExternallyVisible]
  );

  const showRiskDocumentsSection = onboardingDocumentsExternallyVisible.some(
    (doc) => doc.type.category === OnboardingDocumentCategory.RISK
  );
  const showComplianceDocumentsSection = onboardingDocumentsExternallyVisible.some(
    (doc) => doc.type.category === OnboardingDocumentCategory.COMPLIANCE
  );

  const onboardingDocumentsBlockState = useMemo(() => {
    if (
      riskAndComplianceOnboardingDocuments.some(
        (doc) => doc.status === OnboardingDocumentStatus.REQUESTED
      )
    ) {
      return 'active';
    }

    if (
      riskAndComplianceOnboardingDocuments.every(
        (doc) => doc.status === OnboardingDocumentStatus.SUBMITTED
      )
    ) {
      return 'completed';
    }

    return 'disabled';
  }, [riskAndComplianceOnboardingDocuments]);

  if (hideOnboardingDocumentsBlock) return null;

  return (
    <OnboardingBlockWrapper
      title={t('applicationReviewBlock.title')}
      state={onboardingDocumentsBlockState}
      testId="onboarding-documents-block"
    >
      {showRiskDocumentsSection && (
        <>
          <Box marginBottom="24px">
            <Description>
              {t('applicationReviewBlock.riskDocumentsDescription')}
            </Description>
          </Box>
          {onboardingDocumentsExternallyVisible
            .filter(
              (doc) => doc.type.category === OnboardingDocumentCategory.RISK
            )
            .map((doc) => (
              <OnboardingDocumentRow
                key={doc.id}
                isDisabled={isLoading}
                document={doc}
                onUpdate={fetchOnboardingDocuments}
              />
            ))}

          {canUser('org-onboarding-questions:submit') && (
            <Box display="flex" justifyContent="flex-end">
              <StyledButton
                disabled={!canConfirmRequestedRiskDocuments || isLoading}
                onClick={() =>
                  confirmDocuments(OnboardingDocumentCategory.RISK)
                }
              >
                {t('applicationReviewBlock.confirmRiskDocumentsButton')}
              </StyledButton>
            </Box>
          )}

          {showComplianceDocumentsSection && <HR />}
        </>
      )}

      {showComplianceDocumentsSection && (
        <>
          <Box marginBottom="24px">
            <Description>
              {t('applicationReviewBlock.complianceDocumentsDescription')}
            </Description>
          </Box>
          {onboardingDocumentsExternallyVisible
            .filter(
              (doc) =>
                doc.type.category === OnboardingDocumentCategory.COMPLIANCE
            )
            .map((doc) => (
              <OnboardingDocumentRow
                key={doc.id}
                isDisabled={isLoading}
                document={doc}
                onUpdate={fetchOnboardingDocuments}
              />
            ))}

          {canUser('org-onboarding-questions:submit') && (
            <Box display="flex" justifyContent="flex-end">
              <StyledButton
                disabled={!canConfirmRequestedComplianceDocuments || isLoading}
                onClick={() =>
                  confirmDocuments(OnboardingDocumentCategory.COMPLIANCE)
                }
              >
                {t('applicationReviewBlock.confirmComplianceDocumentsButton')}
              </StyledButton>
            </Box>
          )}
        </>
      )}
    </OnboardingBlockWrapper>
  );
};

export default OnboardingDocuments;
