import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Box, Typography } from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { OrganizationStatus } from 'services/constants';
import { didOrgOnboardingStepPassed } from 'services/utils/onboarding';

interface Props {
  isReconnectFlow: boolean;
}

const ConnectionSteps = ({ isReconnectFlow }: Props) => {
  const { t } = useTranslation();
  const partnerName = usePartnerName();
  const {
    state: { organization },
  } = useGlobalState();
  const didOrgPassOnboardingRisk = didOrgOnboardingStepPassed(
    organization!.status,
    OrganizationStatus.onboardingRiskReview
  );
  const stepLabels = isReconnectFlow
    ? [
        t('connectBankAccountsDialog.reconnectStepA'),
        t('connectBankAccountsDialog.reconnectStepB'),
        t('connectBankAccountsDialog.reconnectStepC', { partnerName }),
      ]
    : [
        t('connectBankAccountsDialog.connectStepA'),
        t('connectBankAccountsDialog.connectStepB'),
        `${t('connectBankAccountsDialog.connectStepC', { partnerName })} ${
          !didOrgPassOnboardingRisk
            ? t('connectBankAccountsDialog.connectStepCa')
            : ''
        }`,
      ];

  return (
    <>
      {stepLabels.map((label, index) => (
        <Box display="flex" alignItems="flex-start" mb={3} key={index}>
          <Typography variant="h5" component="div" width={32} flexShrink={0}>
            {index + 1}
          </Typography>
          <Typography variant="body1" ml={2}>
            {label}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default ConnectionSteps;
