import { useMemo } from 'react';
import { sortBy } from 'lodash';
import { useGlobalState } from 'context/GlobalState';
import { env } from 'env';
import { AccountingItemStatus } from 'services/constants';

export const useProjectsPerTeam = (
  selectedProjectId: string | null | undefined,
  teamId: string | null
) => {
  const {
    state: { featureModules, projects },
  } = useGlobalState();

  const projectPerTeamEnabled = featureModules.TEAMS;

  // used for filtering the projects based on team assignment
  const visibleProjects = useMemo(() => {
    if (!projects) return [];

    const activeProjects = sortBy(
      projects.filter(
        (item) =>
          item.status === AccountingItemStatus.active ||
          item.id === selectedProjectId
      ),
      (v) => v.name.toLowerCase()
    );

    // If external app and teams feature module enabled -> filter projects by team.
    // If no team selected -> only public projects will be shown
    if (projectPerTeamEnabled && env.REACT_APP_BUILD_TARGET === 'external')
      return activeProjects.filter(
        (item) =>
          !item.teams.length ||
          (teamId && item.teams.some((item) => item.id === teamId)) ||
          item.id === selectedProjectId
      );

    // Otherwise the logic is the same as before
    return activeProjects;
  }, [selectedProjectId, teamId, projectPerTeamEnabled]);

  return { projectPerTeamEnabled, visibleProjects };
};

export default useProjectsPerTeam;
