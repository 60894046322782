import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'elements';
import useDelayedDisabled from 'hooks/useDelayedDisabled';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MemberDetails } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  member: MemberDetails;
  children: ReactNode;
}

const ResendInviteButton = ({ member, children }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [delayedDisabled, onDelayedDisable] = useDelayedDisabled(
    'sendInvite:' + member.id
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) setLoading(false);
  }, [member.id]);

  const onResendInvite = async () => {
    try {
      setLoading(true);
      await api.resendMemberInvite(member.organizationId, member.id);
      if (!mounted.current) return;
      enqueueSnackbar(t('common.invitationSent'));
      onDelayedDisable();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    } finally {
      if (mounted.current) setLoading(false);
    }
  };

  return (
    <Button
      variant="text"
      color="info"
      size="small"
      onClick={onResendInvite}
      disabled={loading || delayedDisabled}
    >
      {children}
    </Button>
  );
};

export default ResendInviteButton;
