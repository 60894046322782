import { useEffect, useState } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { useCodatStepperContext } from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/useCodatStepperContext';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { CodatDataItemStatus, CodatTaxRateItem } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  isInnerLoading: boolean;
  isError: boolean;
  taxRates: CodatTaxRateItem[] | null;
  selectedIds: string[];
}

const useVatRatesSync = () => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    actions: { onNext, onClose },
  } = useCodatStepperContext();
  const [state, setState] = useState<State>({
    isLoading: true,
    isInnerLoading: false,
    isError: false,
    taxRates: null,
    selectedIds: [],
  });

  const getData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isError: false,
        isLoading: true,
      }));

      const taxRates = await api.getCodatTaxRates(organization!.id);

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        taxRates,
        selectedIds: taxRates
          .filter((item) => item.status === CodatDataItemStatus.selected)
          .map((item) => item.id),
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isError: true,
        isLoading: false,
      }));
      logError(error);
    }
  };

  const onItemsSelect = (selectedIds: string[]) => {
    setState((prevState) => ({ ...prevState, selectedIds }));
  };

  const onTaxRatesSave = async (onSaveCallback?: () => void) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isInnerLoading: true,
      }));

      await api.updateCodatTaxRates({
        organizationId: organization!.id,
        selectedTaxRates: state.taxRates!.map((rate) => ({
          codatTaxRateId: rate.id,
          status: state.selectedIds.includes(rate.id)
            ? CodatDataItemStatus.selected
            : CodatDataItemStatus.unselected,
        })),
      });

      await api.mapCodatTaxRates({
        organizationId: organization!.id,
        codatTaxRates: state
          .taxRates!.filter((rate) => state.selectedIds.includes(rate.id))
          .map((rate) => ({
            id: rate.id,
            name: rate.name,
            code: rate.code,
            effectiveTaxRate: rate.effectiveTaxRate,
            totalTaxRate: rate.totalTaxRate,
          })),
      });

      if (!mounted.current) return;
      onSaveCallback ? onSaveCallback() : onNext();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isInnerLoading: false,
      }));
      logError(error);
    }
  };

  // get the latest Codat data
  const fetchCodatData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isInnerLoading: true,
        // reset selected items once user clicks Sync Now button
        selectedIds: [],
      }));

      await api.getSyncedCodatMappingOptionsSummary(organization!.id);
      const taxRates = await api.getCodatTaxRates(organization!.id);

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        taxRates,
        selectedIds: taxRates
          .filter((item) => item.status === CodatDataItemStatus.selected)
          .map((item) => item.id),
        isInnerLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isInnerLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    ...state,
    getData,
    onItemsSelect,
    onTaxRatesSave,
    fetchCodatData,
    onClose,
  };
};

export default useVatRatesSync;
