import React from 'react';
import LegalSection from './LegalSection';
import MasterDataSection from './MasterDataSection';

const CompanySubPage = () => {
  return (
    <>
      <MasterDataSection />
      <LegalSection />
    </>
  );
};

export default CompanySubPage;
