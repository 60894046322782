import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Grid, LabeledValue } from 'elements';
import { useCanUser } from 'services/rbac';
import RegistrationChangeDialog from './RegistrationChangeDialog';

const RegistrationGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <LabeledValue
          label={t('settingsOrganizationPage.registration')}
          value={
            <>
              <span>{organization!.nationalRegisterCourt || '-'}</span>
              <br />
              <span>{organization!.tradeRegisterNumber}</span>
            </>
          }
          onEdit={
            canUser('org-registration:change')
              ? () => setIsDialogOpen(true)
              : undefined
          }
        />
      </Grid>

      <RegistrationChangeDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default RegistrationGroup;
