import { useTranslation } from 'react-i18next';
import { TransactionAvatar } from 'components/Avatar';
import { TransactionBadge } from 'components/Badge';
import FormatMoney from 'components/FormatMoney';
import {
  TransactionAmount,
  TransactionReviewDropdown,
  VatRateAmount,
} from 'domains/transaction/components';
import { Box, Grid, Typography } from 'elements';
import {
  AccountingTransaction,
  Transaction,
  TransactionReviewStatus,
  TransactionStatus,
} from 'services/constants';
import { formatFXAmount } from 'services/utils';
import TransactionDatesInfo from './TransactionDatesInfo';
import TransactionIntegrationStatus from './TransactionIntegrationStatus';

interface Props {
  transaction: Transaction;
  accountingTransactions?: AccountingTransaction[];
  isReceiptPreviewDialogOpen?: boolean;
  onResetReview?: () => void;
  onUpdate?: () => void;
  onReviewUpdated?: (reviewStatus: TransactionReviewStatus) => void;
}

const TransactionDetailsHeader = ({
  transaction,
  accountingTransactions,
  isReceiptPreviewDialogOpen,
  onResetReview,
  onUpdate,
  onReviewUpdated,
}: Props) => {
  const { i18n } = useTranslation();

  return (
    <>
      <TransactionIntegrationStatus
        transaction={transaction}
        isReceiptPreviewDialogOpen={isReceiptPreviewDialogOpen}
      />

      <Box display="flex" justifyContent="space-between" mb={2}>
        <TransactionAvatar size="large" transaction={transaction} />

        <Box textAlign="right" minWidth={0} pl={2}>
          <TransactionBadge
            status={transaction.status}
            simpleType={transaction.simpleType}
            bookingDate={transaction.bookingDate}
          />

          <TransactionAmount
            variant="h4"
            status={transaction.status}
            simpleType={transaction.simpleType}
            data-test-id="tx-amount"
          >
            <FormatMoney
              value={transaction.transactionAmount}
              fractionalPart
              positiveSign
            />
          </TransactionAmount>

          <TransactionAmount
            variant="caption"
            color="textSecondary"
            status={transaction.status}
            simpleType={transaction.simpleType}
            data-test-id="tx-fx-amount"
          >
            {formatFXAmount(
              transaction.transactionAmount,
              transaction.fxTransactionAmount,
              i18n.language
            )}
          </TransactionAmount>

          {transaction.status !== TransactionStatus.declined &&
            accountingTransactions && (
              <VatRateAmount accountingTransactions={accountingTransactions} />
            )}
        </Box>
      </Box>

      <Typography variant="subtitle1" data-test-id="tx-merchant-name">
        {transaction.merchantName}
      </Typography>

      {transaction.merchantNameConfirmation &&
        transaction.merchantNameConfirmation !== transaction.merchantName && (
          <Typography
            variant="subtitle2"
            color="textSecondary"
            data-test-id="tx-merchant-name-confirmation"
          >
            {transaction.merchantNameConfirmation}
          </Typography>
        )}

      <Grid container columnSpacing={3} rowSpacing={1} mt={1}>
        <TransactionDatesInfo transaction={transaction} />
        {onResetReview && (
          <Grid item display="flex" alignItems="center" ml="auto">
            <TransactionReviewDropdown
              transaction={transaction}
              onUpdate={(reviewStatus) => {
                onUpdate?.();
                onReviewUpdated?.(reviewStatus);
              }}
              onResetReview={onResetReview}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default TransactionDetailsHeader;
