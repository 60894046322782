import { useState } from 'react';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  LoaderWithOverlay,
  Switch,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { AccountingItemStatus, CustomFieldOption } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, trimObjValues } from 'services/utils';

interface State {
  confirmation: 'deactivate' | 'delete' | null;
  isUpdating: boolean;
}

interface FormikValues {
  name: string;
  value: string;
  isDefault: boolean;
}

interface Props extends DialogProps {
  customFieldOption: CustomFieldOption;
  transactionCustomFieldId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const EditDropdownOptionDialog = ({
  customFieldOption,
  transactionCustomFieldId,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    confirmation: null,
    isUpdating: false,
  });

  const formik = useFormik<FormikValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: customFieldOption.name,
      value: customFieldOption.value || '',
      isDefault: customFieldOption.isDefault,
    },
    onSubmit: async (values) => {
      const { id, ...prevCustomFieldFields } = customFieldOption;
      try {
        await api.editCustomFieldOption(customFieldOption.id, {
          organizationId: organization!.id,
          ...prevCustomFieldFields,
          ...trimObjValues(values),
        });
        onSuccess();
        if (!mounted.current) return;
        enqueueSnackbar(
          t('editCustomFieldDropdownOptionDialog.successMessage')
        );
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const changeStatus = async (status: AccountingItemStatus) => {
    const { id, ...prevCustomFieldFields } = customFieldOption;
    setState((prevState) => ({ ...prevState, isUpdating: true }));
    try {
      await api.editCustomFieldOption(customFieldOption.id, {
        organizationId: organization!.id,
        ...prevCustomFieldFields,
        status,
      });
      onSuccess();
      if (!mounted.current) return;

      enqueueSnackbar(
        status === AccountingItemStatus.inactive
          ? t('editCustomFieldDropdownOptionDialog.deactivatedSuccessMessage')
          : t('editCustomFieldDropdownOptionDialog.activatedSuccessMessage'),
        { autoHideDuration: 1500 }
      );
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        confirmation: null,
        isUpdating: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const handleDelete = async () => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      await api.deleteCustomFieldOption(customFieldOption.id);
      onSuccess();
      if (!mounted.current) return;
      enqueueSnackbar(
        t('editCustomFieldDropdownOptionDialog.deletedSuccessMessage'),
        {
          autoHideDuration: 1500,
        }
      );
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        confirmation: null,
        isUpdating: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  if (state.confirmation === 'deactivate') {
    return (
      <ConfirmDialog
        {...props}
        title={t('editCustomFieldDropdownOptionDialog.deactivateStep.title')}
        description={
          <Trans
            i18nKey="editCustomFieldDropdownOptionDialog.deactivateStep.description"
            components={{ br: <br /> }}
          />
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          color: 'error',
          children: t('common.button.deactivate'),
        }}
        onSuccess={() => changeStatus(AccountingItemStatus.inactive)}
        onClose={() =>
          setState((prevState) => ({ ...prevState, confirmation: null }))
        }
      />
    );
  }

  if (state.confirmation === 'delete') {
    return (
      <ConfirmDialog
        {...props}
        title={t('editCustomFieldDropdownOptionDialog.deleteStep.title')}
        description={
          <Trans
            i18nKey="editCustomFieldDropdownOptionDialog.deleteStep.description"
            components={{ br: <br /> }}
          />
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          color: 'error',
          children: t('common.button.delete'),
        }}
        onSuccess={handleDelete}
        onClose={() =>
          setState((prevState) => ({ ...prevState, confirmation: null }))
        }
      />
    );
  }

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle display="flex" alignItems="center">
        {t('editCustomFieldDropdownOptionDialog.title')}
        {customFieldOption.status === AccountingItemStatus.inactive && (
          <Box ml={1} fontSize={0}>
            <Chip
              size="small"
              label={t('editCustomFieldDropdownOptionDialog.inactiveBadge')}
              variant="outlined"
            />
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="edit-dropdown-option-form"
          noValidate
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label={t('editCustomFieldDropdownOptionDialog.nameLabel')}
                {...formik.getFieldProps('name')}
                disabled={formik.isSubmitting || state.isUpdating}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={t('editCustomFieldDropdownOptionDialog.valueLabel')}
                {...formik.getFieldProps('value')}
                disabled={formik.isSubmitting || state.isUpdating}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                name="isDefault"
                checked={formik.values.isDefault}
                disabled={formik.isSubmitting || state.isUpdating}
                onChange={(_, checked) =>
                  formik.setFieldValue('isDefault', checked)
                }
                control={<Switch />}
                label={t('editCustomFieldDropdownOptionDialog.setDefaultLabel')}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        {customFieldOption.status === AccountingItemStatus.active ? (
          <>
            <Box mr="auto">
              <Button
                disabled={formik.isSubmitting || state.isUpdating}
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    confirmation: 'deactivate',
                  }));
                }}
                variant="text"
                color="error"
              >
                {t('common.button.deactivate')}
              </Button>
            </Box>
            <Button onClick={props.onClose} variant="text">
              {t('common.button.cancel')}
            </Button>
            <Button
              disabled={
                !formik.values.name.trim().length ||
                formik.isSubmitting ||
                state.isUpdating ||
                !formik.dirty
              }
              form="edit-dropdown-option-form"
              type="submit"
            >
              {t('common.button.save')}
            </Button>
          </>
        ) : (
          <>
            <Box mr="auto">
              <Button
                disabled={formik.isSubmitting || state.isUpdating}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    confirmation: 'delete',
                  }))
                }
                variant="text"
                color="error"
              >
                {t('common.button.delete')}
              </Button>
            </Box>
            <Button onClick={props.onClose} variant="text">
              {t('common.button.cancel')}
            </Button>
            {formik.dirty ? (
              <Button
                disabled={
                  !formik.values.name.trim().length ||
                  formik.isSubmitting ||
                  state.isUpdating
                }
                form="edit-dropdown-option-form"
                type="submit"
              >
                {t('common.button.save')}
              </Button>
            ) : (
              <Button
                disabled={formik.isSubmitting || state.isUpdating}
                onClick={() => changeStatus(AccountingItemStatus.active)}
              >
                {t('common.button.activate')}
              </Button>
            )}
          </>
        )}
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(EditDropdownOptionDialog);
