import { Trans, useTranslation } from 'react-i18next';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Box, LightningIcon, Link, Tooltip } from 'elements';
import useIsAdminApp from 'hooks/useIsAdminApp';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';

interface PropsMerchant {
  isAutoMatched: boolean;
  organizationId: string;
  merchantId?: string;
}

interface PropsCard {
  isAutoMatched: boolean;
  organizationId: string;
  cardId?: string;
}

const AutoMatchedSelectIndicator = ({
  isAutoMatched,
  organizationId,
  ...props
}: PropsMerchant | PropsCard) => {
  const { organizationMerchantsPageEnabled } = useFlags();
  const { t } = useTranslation();
  const canUser = useCanUser();
  const isAdminApp = useIsAdminApp();

  if (!isAutoMatched) return null;

  if (
    organizationMerchantsPageEnabled &&
    canUser('organization-merchants-page:visit') &&
    isAdminApp &&
    'merchantId' in props
  )
    return (
      <Box
        position="relative"
        zIndex="1"
        lineHeight={0}
        sx={{ pointerEvents: 'initial' }}
        ml={1.5}
      >
        <Tooltip
          title={
            <Trans
              i18nKey="transactionDetails.fieldAutoMatchedTooltip"
              components={{
                br: <br />,
                linkTo: (
                  <Link
                    component={RouterLink}
                    to={generatePath(getPath('merchantDetails'), {
                      orgId: organizationId,
                      merchantId: props.merchantId,
                    })}
                    target="_blank"
                    color="inherit"
                  />
                ),
              }}
            />
          }
        >
          <LightningIcon fontSize="small" color="info" />
        </Tooltip>
      </Box>
    );

  if (canUser('cards-page:visit') && isAdminApp && 'cardId' in props)
    return (
      <Box
        position="relative"
        zIndex="1"
        lineHeight={0}
        sx={{ pointerEvents: 'initial' }}
        ml={1.5}
      >
        <Tooltip
          title={
            <Trans
              i18nKey="transactionDetails.customFieldAutoMatchedTooltip"
              components={{
                br: <br />,
                linkTo: (
                  <Link
                    component={RouterLink}
                    to={generatePath(getPath('cardDetails'), {
                      orgId: organizationId,
                      cardId: props.cardId,
                    })}
                    target="_blank"
                    color="inherit"
                  />
                ),
              }}
            />
          }
        >
          <LightningIcon fontSize="small" color="info" />
        </Tooltip>
      </Box>
    );

  return (
    <Box
      position="relative"
      zIndex="1"
      lineHeight={0}
      sx={{ pointerEvents: 'initial' }}
      ml={1.5}
    >
      <Tooltip
        title={t('transactionDetails.fieldAutoMatchedTooltipForCardholder')}
      >
        <LightningIcon fontSize="small" color="info" />
      </Tooltip>
    </Box>
  );
};

export default AutoMatchedSelectIndicator;
