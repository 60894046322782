import { Box, LinearProgress } from '@mui/material';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { CircularProgress, IconButton, Typography } from 'elements';
import { ArrowsClockwiseIcon } from 'elements/TemporaryIcon';
import { LatestXslxUploadResponse } from 'services/constants';

interface Props {
  isLoadingLatestStatus: boolean;
  latestUploadStatus: LatestXslxUploadResponse | null;
  updateStatus: () => void;
}

const UploadingStatus = ({
  isLoadingLatestStatus,
  latestUploadStatus,
  updateStatus,
}: Props) => {
  const { t } = useTranslation();

  const percentage = latestUploadStatus
    ? Math.round(
        (latestUploadStatus.processedRecords /
          latestUploadStatus.totalRecords) *
          100
      )
    : 0;

  if (isLoadingLatestStatus) {
    return (
      <Box display="flex" justifyContent="center" marginTop={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {latestUploadStatus && (
        <Box marginTop={4}>
          {percentage === 100 ? (
            <>
              <Typography variant="body2">
                <Trans
                  i18nKey="int.bulkUploadItemsDialog.lastUpdate"
                  components={{
                    b: <b />,
                    i: <i />,
                  }}
                  values={{
                    name: latestUploadStatus!.createdBy,
                    date: moment(latestUploadStatus!.completedAt).format(
                      'DD.MM.YYYY HH:mm'
                    ),
                  }}
                />
              </Typography>
              <Typography variant="body2">
                {t('int.bulkUploadItemsDialog.completedMessage', {
                  created: latestUploadStatus!.stats.created,
                  skipped: latestUploadStatus!.stats.skipped,
                  errors: latestUploadStatus!.stats.errors,
                })}
              </Typography>
            </>
          ) : (
            <>
              <Box display="flex" justifyContent="space-between">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  flexGrow={1}
                >
                  <LinearProgress sx={{ width: '100%' }} />
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body2">
                      {t('int.bulkUploadItemsDialog.uploadInProgress')}
                    </Typography>
                    {latestUploadStatus && (
                      <Typography variant="body2">
                        {latestUploadStatus.processedRecords}/
                        {latestUploadStatus.totalRecords}
                        {` (${percentage}%)`}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <IconButton onClick={updateStatus} aria-label="refresh">
                  <ArrowsClockwiseIcon />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default UploadingStatus;
