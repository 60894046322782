import { useTheme } from 'styled-components';
import { ReactComponent as ConnectionSignalLoadingIcon } from 'assets/icons/computerDevices/connectionSignalLoading.svg';
import { ReactComponent as CandisIcon } from 'assets/icons/partnerLogos/candis.svg';
import { ReactComponent as CirculaIcon } from 'assets/icons/partnerLogos/circula.svg';
import { ReactComponent as MobilexpenseIcon } from 'assets/icons/partnerLogos/mobilexpense.svg';
import { ReactComponent as PliantRoundLogo } from 'assets/svg/pliantRoundLogo.svg';
import { PartnerIds, PartnerIdType } from 'services/constants';

interface Props {
  partnerId: PartnerIdType;
}

const PartnerMinorLogo = ({ partnerId }: Props) => {
  const { colors } = useTheme();

  switch (partnerId) {
    case PartnerIds.candis:
      return <CandisIcon color={colors.candis} />;
    case PartnerIds.circula:
    case PartnerIds.circulaDemo:
    case PartnerIds.circulaEmbedded:
    case PartnerIds.circulaFullyEmbedded:
      return <CirculaIcon />;
    case PartnerIds.mobilexpenseDeclaree:
      return <MobilexpenseIcon />;
    case PartnerIds.pliant:
      return <PliantRoundLogo />;
  }

  return <ConnectionSignalLoadingIcon />;
};

export default PartnerMinorLogo;
