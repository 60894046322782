import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledIconButton } from 'components/MainHeader/style';
import { BellIcon, Popover, Typography } from 'elements';

const NotificationsMenu = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <StyledIconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className={!!anchorEl ? 'selected' : ''}
      >
        <BellIcon />
      </StyledIconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{ sx: { width: 380, py: 6, textAlign: 'center' } }}
      >
        <BellIcon sx={{ color: 'text.secondary', verticalAlign: 'top' }} />
        <Typography mt={1}>{t('header.noNotifications')}</Typography>
      </Popover>
    </>
  );
};

export default NotificationsMenu;
