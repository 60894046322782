import { differenceWith, sortBy } from 'lodash';
import { MemberTeamDetails, Team } from 'services/constants';

const getDiffBetweenTeams = (
  selectedTeamIds: string[],
  memberTeams: MemberTeamDetails[],
  teams: Team[]
) => {
  const teamsToAssign = differenceWith<string, MemberTeamDetails>(
    selectedTeamIds,
    memberTeams,
    (first, second) => first === second.teamId
  );
  const teamsToUnassign = differenceWith<MemberTeamDetails, string>(
    memberTeams,
    selectedTeamIds,
    (first, second) => first.teamId === second
  ).map((item) => item.teamId);

  const newMemberTeams = sortBy<MemberTeamDetails>(
    [
      ...memberTeams.filter((item) => !teamsToUnassign.includes(item.teamId)),
      ...teamsToAssign.map((teamId) => {
        const team = teams.find((item) => item.id === teamId);
        return {
          teamId,
          name: team!.name,
          hasActiveCards: false,
          teamManager: false,
        };
      }),
    ],
    (item) => item.name.toLowerCase()
  );

  return { teamsToAssign, teamsToUnassign, newMemberTeams };
};

export default getDiffBetweenTeams;
