import { useMemo } from 'react';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { filterNonPrintableChars } from 'services/utils/printableString';

interface Props extends DialogProps {
  onClose: () => void;
}

const LegalNameChangeDialog = (props: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const org = useMemo(() => organization!, []);
  const mounted = useMounted();
  const api = useImperativeApi();

  const formik = useFormik({
    validateOnMount: true,
    validateOnBlur: false,
    initialValues: { name: org!.name || '' },
    onSubmit: async ({ name }, { setSubmitting }) => {
      try {
        const updatedOrg = await api.updateLegalName(org!.id, name.trim());
        if (!mounted.current) return;
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { organization: updatedOrg },
        });
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const nonPrintableChars = filterNonPrintableChars(formik.values.name);
  const isStringPrintable = nonPrintableChars.length === 0;
  const isSubmitDisabled =
    !formik.values.name.trim() || !isStringPrintable || formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('settingsOrganizationPage.legalName')}</DialogTitle>

      <DialogContent>
        <form
          id="legal-name-change-form"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <TextField
            label={t('settingsOrganizationPage.legalName')}
            {...formik.getFieldProps('name')}
            disabled={formik.isSubmitting}
            autoFocus
            error={!isStringPrintable}
            helperText={
              !isStringPrintable && (
                <Trans
                  i18nKey="errors.charsNotPrintable"
                  values={{
                    nonPrintableChars: nonPrintableChars.join(', '),
                  }}
                />
              )
            }
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          disabled={formik.isSubmitting}
          onClick={props.onClose}
        >
          {t('common.button.cancel')}
        </Button>

        <Button
          form="legal-name-change-form"
          type="submit"
          disabled={isSubmitDisabled}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(LegalNameChangeDialog);
