import { useFormik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  DEFAULT_CUTOFF_DAY_BY_FREQUENCY,
  PAYMENT_FREQUENCY_CUTOFF_VISIBLE_DAYS,
  PAYMENT_TERMS,
  PAYMENT_TERMS_INVERTED,
} from 'domains/creditAndCompliance/constants';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { CardAccount, PaymentFrequencyType } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  cardAccountOpenInDialog: CardAccount;
  onClose: () => void;
}

const PaymentFrequencyOrder = {
  [PaymentFrequencyType.DAILY]: 0,
  [PaymentFrequencyType.WEEKLY]: 1,
  [PaymentFrequencyType.BI_WEEKLY]: 2,
  [PaymentFrequencyType.SEMI_MONTHLY]: 3,
  [PaymentFrequencyType.MONTHLY]: 4,
};

interface FormValues {
  minimumPaymentFrequency: PaymentFrequencyType;
  paymentFrequency: PaymentFrequencyType;
  paymentFrequencyCutoffDay: number | null;
  paymentDefermentInDays: keyof typeof PAYMENT_TERMS;
}

const EditPaymentSettingsDialog = ({
  cardAccountOpenInDialog: cardAccount,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: {
      cardAccounts,
      defaultCardAccount,
      organization,
      organizationsStaticData,
    },
  } = useGlobalState();

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      minimumPaymentFrequency: cardAccount.minimumPaymentFrequency.value,
      paymentFrequency: cardAccount.paymentFrequency.value,
      paymentFrequencyCutoffDay: cardAccount.paymentFrequencyCutoffDay.value,
      paymentDefermentInDays:
        PAYMENT_TERMS_INVERTED[cardAccount.paymentDefermentInDays.value],
    },
    onSubmit: async (values) => {
      try {
        const updatedCardAccount = await api.updateCardAccountPaymentSettings(
          organization!.id,
          cardAccount.id,
          {
            ...values,
            paymentDefermentInDays:
              PAYMENT_TERMS[values.paymentDefermentInDays],
          }
        );

        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: {
            cardAccounts: cardAccounts.map((item) =>
              item.id === updatedCardAccount.id ? updatedCardAccount : item
            ),
            ...(defaultCardAccount!.id === updatedCardAccount.id && {
              defaultCardAccount: updatedCardAccount,
            }),
          },
        });

        if (!mounted) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle noWrap>
        {t('int.editPaymentSettingsDialog.title', {
          name: cardAccount.name.value,
        })}
      </DialogTitle>

      <DialogContent>
        <FormControl fullWidth disabled={formik.isSubmitting}>
          <InputLabel>
            {t('int.editPaymentSettingsDialog.minPaymentFrequency')}
          </InputLabel>
          <Select
            value={formik.values.minimumPaymentFrequency}
            onChange={(e) =>
              formik.setFieldValue('minimumPaymentFrequency', e.target.value)
            }
          >
            {organizationsStaticData!.paymentFrequencies.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth disabled={formik.isSubmitting} sx={{ mt: 3 }}>
          <InputLabel>
            {t('int.editPaymentSettingsDialog.currentPaymentFrequency')}
          </InputLabel>
          <Select
            value={formik.values.paymentFrequency}
            onChange={(e) => {
              const val = e.target.value as PaymentFrequencyType;
              formik.setFieldValue('paymentFrequency', val);
              formik.setFieldValue(
                'paymentFrequencyCutoffDay',
                DEFAULT_CUTOFF_DAY_BY_FREQUENCY[val]
              );
            }}
          >
            {organizationsStaticData!.paymentFrequencies.map((item) => (
              <MenuItem
                key={item.name}
                value={item.name}
                disabled={
                  PaymentFrequencyOrder[item.name as PaymentFrequencyType] >
                  PaymentFrequencyOrder[
                    formik.values
                      .minimumPaymentFrequency as PaymentFrequencyType
                  ]
                }
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {[
          PaymentFrequencyType.MONTHLY,
          PaymentFrequencyType.SEMI_MONTHLY,
        ].includes(formik.values.paymentFrequency) && (
          <FormControl fullWidth disabled={formik.isSubmitting} sx={{ mt: 3 }}>
            <InputLabel>
              {t('int.editPaymentSettingsDialog.paymentFrequencyCutoffDay')}
            </InputLabel>
            <Select<number>
              value={formik.values.paymentFrequencyCutoffDay ?? 0}
              onChange={(e) =>
                formik.setFieldValue(
                  'paymentFrequencyCutoffDay',
                  e.target.value
                )
              }
            >
              {formik.values.paymentFrequency ===
                PaymentFrequencyType.MONTHLY && [
                ...Array.from({ length: 28 }, (_, i) => i + 1).map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                )),
                <MenuItem key={0} value={0}>
                  {t('int.editPaymentSettingsDialog.endOfMonth')}
                </MenuItem>,
              ]}
              {formik.values.paymentFrequency ===
                PaymentFrequencyType.SEMI_MONTHLY && [
                Array.from({ length: 14 }, (_, i) => i + 1).map((value) => (
                  <MenuItem key={value} value={value}>
                    {value} - {value + 15}
                  </MenuItem>
                )),
                <MenuItem key={15} value={15}>
                  {`15 - ${t('int.editPaymentSettingsDialog.endOfMonth')}`}
                </MenuItem>,
              ]}
            </Select>
          </FormControl>
        )}
        {(formik.values.paymentFrequency === PaymentFrequencyType.BI_WEEKLY ||
          formik.values.paymentFrequency === PaymentFrequencyType.WEEKLY) && (
          <FormControl fullWidth disabled={formik.isSubmitting} sx={{ mt: 3 }}>
            <InputLabel>
              {t('int.editPaymentSettingsDialog.paymentFrequencyCutoffDay')}
            </InputLabel>
            <ButtonGroup
              color="primary"
              variant="outlined"
              fullWidth
              sx={{ mt: 3 }}
            >
              {PAYMENT_FREQUENCY_CUTOFF_VISIBLE_DAYS.map((day) => (
                <Button
                  key={day}
                  variant={
                    formik.values.paymentFrequencyCutoffDay === day
                      ? 'contained'
                      : undefined
                  }
                  onClick={() =>
                    formik.setFieldValue('paymentFrequencyCutoffDay', day)
                  }
                >
                  {moment().isoWeekday(day).format('ddd')}
                </Button>
              ))}
            </ButtonGroup>
          </FormControl>
        )}

        <FormControl fullWidth disabled={formik.isSubmitting} sx={{ mt: 3 }}>
          <InputLabel>
            {t('int.editPaymentSettingsDialog.paymentTerms')}
          </InputLabel>

          <Select
            value={formik.values.paymentDefermentInDays}
            onChange={(e) =>
              formik.setFieldValue('paymentDefermentInDays', e.target.value)
            }
          >
            {organizationsStaticData!.paymentTerms.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={formik.isSubmitting} onClick={formik.submitForm}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditPaymentSettingsDialog);
