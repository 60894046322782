import { forwardRef, ReactNode } from 'react';
import { styled } from '@mui/material';
import { Box, BoxProps, Typography } from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';

interface PageTitleProps {
  title: string;
  suptitle?: ReactNode;
  children?: ReactNode;
}

interface PageContentProps extends BoxProps {}

export const PageHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 5, 2),
}));

export const PageTitle = ({ title, suptitle, children }: PageTitleProps) => {
  const { isCardholderApp } = useCurrentApp();

  return (
    <Box pt={isCardholderApp ? 5 : 2}>
      {suptitle && (
        <Typography component="div" variant="caption" color="textSecondary">
          {suptitle}
        </Typography>
      )}
      <Box
        pb={isCardholderApp ? 3 : 2}
        sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
      >
        <Typography variant="h4">{title}</Typography>
        {children}
      </Box>
    </Box>
  );
};

interface PageTitleActionsProps extends BoxProps {}

export const PageTitleActions = ({
  children,
  sx = [],
}: PageTitleActionsProps) => {
  return (
    <Box
      sx={[
        { ml: 'auto', button: { '& + button': { ml: 1 } } },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};

export const PageFilters = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
}));

export const PageFilterChips = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}));

export const PageContent = forwardRef((props: PageContentProps, ref) => {
  return (
    <Box
      ref={ref}
      py={3}
      px={5}
      position="relative"
      overflow="auto"
      flex={1}
      {...props}
    >
      {props.children}
    </Box>
  );
});

export const PageTableContent = ({ children }: { children?: ReactNode }) => {
  return (
    <Box
      mx={5}
      minHeight={10} // check if this will fix the next issue - MUI: useResizeContainer - The parent DOM element of the data grid has an empty height.
      sx={(theme) => ({
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
        borderTop: `1px solid ${theme.palette.divider}`,
      })}
    >
      {children}
    </Box>
  );
};

/**
 * Use this component to wrap content when it should not exceed 960px
 */
export const ContentContainer = styled(Box)`
  max-width: 960px;
`;

/**
 * Use this component for Settings groups
 */
export const SettingsGroupBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(4),

  '&+&': {
    paddingTop: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

export { PageSearchInput } from './PageSearchInput';
export type { PageSearchInputProps } from './PageSearchInput';

export { PageFiltersComponent } from './PageFiltersComponent';
export type { PageFiltersComponentType } from './PageFiltersComponent';

export { PageToast, SelectionPageToast } from './PageToast';
export type { PageToastProps, SelectionPageToastProps } from './PageToast';
