import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { ALL_LANGUAGES } from 'domains/organization/hooks';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from 'elements';

const LanguageSelect = () => {
  const { i18n } = useTranslation();

  const onChange = async (e: SelectChangeEvent) => {
    const languageCode = e.target.value;
    moment.locale(languageCode);
    await i18n.changeLanguage(languageCode);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="qa-language-select-label">Language</InputLabel>
      <Select
        value={i18n.language}
        onChange={onChange}
        renderValue={(selected) =>
          ALL_LANGUAGES.find((language) => language.code === selected)?.label
        }
        labelId="qa-language-select-label"
      >
        {ALL_LANGUAGES.map((item) => (
          <MenuItem key={item.code} value={item.code}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelect;
