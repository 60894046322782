import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';
import useCanUser from 'services/rbac/useCanUser';

/**
 * Hook used for checking if teams information should be shown
 * on the projects subpages/dialogs
 */
export const useTeamsShown = () => {
  const canUser = useCanUser();
  const {
    state: { accountingSettings, featureModules },
  } = useGlobalState();

  // we use the same logic as for Teams subpage visit
  return useMemo(
    () =>
      accountingSettings!.costCenterEnabled &&
      canUser('teams-page:visit') &&
      featureModules.TEAMS,
    [accountingSettings!.costCenterEnabled, featureModules.TEAMS]
  );
};
