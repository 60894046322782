import { SyntheticEvent, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Address,
  Organization,
  supportedCountries,
  SupportedCountry,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  trimObjValues,
  validateAddress,
} from 'services/utils';

export interface DeliveryAddressChangeDialogProps extends DialogProps {
  label: React.ReactNode;
  organizationId: string;
  deliveryAddress: Address | null;
  billingAddress: Address;
  onSuccess: (organization: Organization) => void;
  onClose: () => void;
}

interface FormValues {
  city: string;
  country: SupportedCountry;
  postalCode: string;
  streetAdditional: string;
  streetName: string;
  streetNumber: string;
}

const DeliveryAddressChangeDialog = ({
  label,
  organizationId,
  deliveryAddress: initialDeliveryAddress,
  billingAddress,
  onSuccess,
  ...props
}: DeliveryAddressChangeDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { t } = useTranslation();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      city: initialDeliveryAddress?.city ?? '',
      country: initialDeliveryAddress?.country ?? billingAddress.country,
      postalCode: initialDeliveryAddress?.postalCode ?? '',
      streetAdditional: initialDeliveryAddress?.streetAdditional ?? '',
      streetName: initialDeliveryAddress?.streetName ?? '',
      streetNumber: initialDeliveryAddress?.streetNumber ?? '',
    },
    validate: (values) => {
      const deliveryAddressErrors = validateAddress(values, t, true);
      return { ...deliveryAddressErrors };
    },
    onSubmit: async (values) => {
      try {
        const organizationData = await api.updateDeliveryAddress(
          organizationId,
          trimObjValues(values)
        );

        if (!mounted.current) return;
        onSuccess(organizationData);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });
  const [countryInputValue, setCountryInputValue] = useState('');

  const isSubmitDisabled =
    !formik.values.city.trim() ||
    !formik.values.country?.trim() ||
    !formik.values.postalCode.trim() ||
    !formik.values.streetName.trim() ||
    !(
      formik.values.country === SupportedCountry.ie ||
      formik.values.streetNumber.trim()
    );

  const onCheckboxChange = (
    e: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (checked) {
      formik.setFieldValue('city', billingAddress.city ?? '');
      formik.setFieldValue('country', billingAddress.country ?? '');
      formik.setFieldValue('postalCode', billingAddress.postalCode ?? '');
      formik.setFieldValue(
        'streetAdditional',
        billingAddress.streetAdditional ?? ''
      );
      formik.setFieldValue('streetName', billingAddress.streetName ?? '');
      formik.setFieldValue('streetNumber', billingAddress.streetNumber ?? '');
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{label}</DialogTitle>

      <DialogContent>
        <FormControlLabel
          disabled={formik.isSubmitting}
          onChange={onCheckboxChange}
          control={<Checkbox />}
          label={t('deliveryAddressForm.sameAsBillingLabel')}
        />

        <form
          onSubmit={formik.handleSubmit}
          id="delivery-address-change-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2} mt={1}>
            <Grid item xs={6}>
              <TextField
                label={t('deliveryAddressForm.streetNameField')}
                {...formik.getFieldProps('streetName')}
                disabled={formik.isSubmitting}
                error={!!formik.errors.streetName}
                helperText={formik.errors.streetName}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t('deliveryAddressForm.streetNumberField')}
                {...formik.getFieldProps('streetNumber')}
                disabled={formik.isSubmitting}
                error={!!formik.errors.streetNumber}
                helperText={formik.errors.streetNumber}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t('deliveryAddressForm.streetAdditionalField')}
                {...formik.getFieldProps('streetAdditional')}
                disabled={formik.isSubmitting}
                error={!!formik.errors.streetAdditional}
                helperText={formik.errors.streetAdditional}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t('deliveryAddressForm.postalCodeField')}
                {...formik.getFieldProps('postalCode')}
                disabled={formik.isSubmitting}
                error={!!formik.errors.postalCode}
                helperText={formik.errors.postalCode}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t('deliveryAddressForm.cityField')}
                {...formik.getFieldProps('city')}
                disabled={formik.isSubmitting}
                error={!!formik.errors.city}
                helperText={formik.errors.city}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete<SupportedCountry, false, false, false>
                searchIcon
                disabled={formik.isSubmitting}
                getOptionLabel={(option: SupportedCountry) =>
                  t(`countries.${option}`)
                }
                inputValue={countryInputValue}
                label={t('billingAddressForm.country')}
                noOptionsText={t('common.nothingFound')}
                onChange={(_, option) =>
                  formik.setFieldValue('country', option)
                }
                onInputChange={(_, inputValue) =>
                  setCountryInputValue(inputValue)
                }
                options={supportedCountries}
                placeholder={t('billingAddressForm.country')}
                value={formik.values.country}
                error={!!formik.errors.country}
                helperText={formik.errors.country}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          disabled={formik.isSubmitting}
          onClick={props.onClose}
        >
          {t('common.button.cancel')}
        </Button>

        <Button
          type="submit"
          form="delivery-address-change-form"
          disabled={isSubmitDisabled || formik.isSubmitting}
          data-test-id="delivery-address-submit"
        >
          {t('deliveryAddressForm.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(DeliveryAddressChangeDialog);
