import React from 'react';
import { camelCase } from 'lodash';
import { FileError, FileWithPath } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { IconButton, TableCell, TableRow, TrashIcon, XIcon } from 'elements';
import { OnboardingDocumentFile } from 'services/constants';
import formatBytes from 'services/utils/formatBytes';

type SelectedFileProps = {
  version: 'selected';
  file: FileWithPath;
  isLoading: boolean;
  onDelete: () => void;
  uploadingProgress?: number;
};

type UploadedFileProps = {
  version: 'uploaded';
  file: OnboardingDocumentFile;
  isLoading: boolean;
  onDelete: () => Promise<void>;
};

type RejectedFileProps = {
  version: 'error';
  errors: FileError[];
  file: FileWithPath;
  isLoading: boolean;
  maxFileSizeInBytes: number;
};

export function Row(props: SelectedFileProps): React.ReactElement;
export function Row(props: UploadedFileProps): React.ReactElement;
export function Row(props: RejectedFileProps): React.ReactElement;

export function Row(
  props: SelectedFileProps | UploadedFileProps | RejectedFileProps
) {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell sx={{ wordBreak: 'break-word' }}>{props.file.name}</TableCell>
      <TableCell sx={{ color: 'text.secondary' }}>
        {formatBytes(
          props.version === 'uploaded'
            ? props.file.sizeInBytes
            : props.file.size
        )}
      </TableCell>

      {props.version !== 'error' && (
        <TableCell align="right">
          <IconButton
            onClick={props.onDelete}
            disabled={props.isLoading}
            size="small"
          >
            {props.version === 'uploaded' ? (
              <TrashIcon fontSize="small" />
            ) : (
              <XIcon fontSize="small" />
            )}
          </IconButton>
        </TableCell>
      )}

      {props.version === 'error' && (
        <TableCell align="right" sx={{ color: 'error.main' }}>
          {props.errors.map((e) => (
            <span key={e.code}>
              {t(`errors.${camelCase(e.code)}`, {
                maxFileSize: formatBytes(props.maxFileSizeInBytes),
              })}
            </span>
          ))}
        </TableCell>
      )}
    </TableRow>
  );
}
