import React from 'react';
import {
  Alert,
  AlertProps,
  AlertTitle,
  AlertTitleProps,
  Box,
  BoxProps,
} from '@mui/material';

export interface ActionBoxProps
  extends Omit<AlertProps, 'severity' | 'variant'> {}

export const ActionBox = ({ children, sx = [], ...rest }: ActionBoxProps) => {
  return (
    <Alert
      severity="warning"
      sx={[
        (theme) => ({
          background: theme.variables.background.contrast,
          color: theme.palette.text.primary,
          borderRadius: 0,
          '.MuiAlert-message': { flex: 1 },
          '.MuiAlert-icon': { color: theme.palette.text.primary },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </Alert>
  );
};

export const ActionBoxTitle = ({ children, ...rest }: AlertTitleProps) => {
  return <AlertTitle {...rest}>{children}</AlertTitle>;
};

export const ActionBoxActions = ({ children, sx = [], ...rest }: BoxProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      mt={2}
      sx={[{ 'button+button': { ml: 1 } }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...rest}
    >
      {children}
    </Box>
  );
};
