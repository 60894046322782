import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderWithOverlay } from 'components/Loader';
import OnboardingBlockWrapper from 'components/OnboardingPage/OnboardingBlockWrapper';
import { useOnboardingContext } from 'components/OnboardingPage/useOnboardingContext';
import { useGlobalState } from 'context/GlobalState';
import { Box, Button } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  OnboardingDocumentStatus,
  OrganizationRepresentativeExternalStatus,
  OrganizationStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import RepresentativeAdditionalInfoSection from './RepresentativeAdditionalInfoSection';

interface State {
  isLoading: boolean;
}

const ComplianceBlock = () => {
  const { t } = useTranslation();
  const {
    state: { onboarding },
  } = useOnboardingContext();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const canUser = useCanUser();
  const {
    actions: {
      fetchOnboardingDocuments,
      setOrgRepsAdditionalInfo,
      updateOrgRepAdditionalInfo,
    },
    state: { onboardingDocuments, orgRepsAdditionalInfo },
  } = useOnboardingContext();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
  });

  const getComplianceBlockState = () => {
    if (!onboarding) return 'disabled';

    if (
      orgRepsAdditionalInfo.length > 0 &&
      orgRepsAdditionalInfo.every(
        (rep) =>
          rep.externalStatus ===
          OrganizationRepresentativeExternalStatus.infoSubmitted
      )
    ) {
      return 'completed';
    }

    if ([OrganizationStatus.onboardingCompliance].includes(onboarding.status)) {
    }

    if (
      [
        OrganizationStatus.onboardingRiskData,
        OrganizationStatus.onboardingRiskReview,
        OrganizationStatus.onboardingCompliance,
      ].includes(onboarding.status)
    ) {
      return 'active';
    }

    return 'disabled';
  };

  const confirmAdditionalInfo = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const onboardingDocIdsToSubmit = onboardingDocuments
        .filter(
          (doc) =>
            orgRepsAdditionalInfo
              .map((rep) => rep.representativeId)
              .includes(doc.relatedEntityId) &&
            doc.status === OnboardingDocumentStatus.REQUESTED
        )
        .map((doc) => doc.id);

      const [updatedOrgRepsAdditionalInfo] = await Promise.all([
        api.confirmOrganizationRepresentativesAdditionalInfo(organization!.id),
        onboardingDocIdsToSubmit.length
          ? api.submitOnboardingDocuments(
              organization!.id,
              onboardingDocIdsToSubmit
            )
          : undefined,
      ]);

      const onboardingDocumentsResponse = onboardingDocIdsToSubmit.length
        ? await api.getOnboardingDocuments(organization!.id)
        : undefined;

      if (!mounted.current) return;
      setOrgRepsAdditionalInfo(updatedOrgRepsAdditionalInfo);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        ...(onboardingDocumentsResponse && {
          onboardingDocuments: onboardingDocumentsResponse.onboardingDocuments,
        }),
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const isConfirmButtonDisabled = useMemo(
    () =>
      orgRepsAdditionalInfo.some((rep) => {
        if (
          ![
            OrganizationRepresentativeExternalStatus.infoFilled,
            OrganizationRepresentativeExternalStatus.infoSubmitted,
          ].includes(rep.externalStatus)
        ) {
          return true;
        }

        return !!onboardingDocuments.some(
          (doc) =>
            doc.relatedEntityId === rep.representativeId &&
            doc.status === OnboardingDocumentStatus.REQUESTED &&
            doc.files.length < doc.type.minNumberOfFiles
        );
      }),
    [orgRepsAdditionalInfo, onboardingDocuments]
  );

  const showConfirmButton =
    canUser('legal-rep-additional-info:update') &&
    orgRepsAdditionalInfo.some((rep) => {
      if (
        rep.externalStatus !==
        OrganizationRepresentativeExternalStatus.infoSubmitted
      ) {
        return true;
      }

      return !!onboardingDocuments.some(
        (doc) =>
          doc.relatedEntityId === rep.representativeId &&
          doc.status !== OnboardingDocumentStatus.SUBMITTED
      );
    });

  return (
    <OnboardingBlockWrapper
      title={t('representativeSection.title')}
      state={getComplianceBlockState()}
      testId="onboarding-representative-block"
    >
      {orgRepsAdditionalInfo.length === 0 ? null : (
        <>
          {orgRepsAdditionalInfo.map((rep) => (
            <RepresentativeAdditionalInfoSection
              data={rep}
              key={rep.representativeId}
              onUpdate={(updatedRep) => updateOrgRepAdditionalInfo(updatedRep)}
              onboardingDocs={onboardingDocuments.filter(
                (doc) => doc.relatedEntityId === rep.representativeId
              )}
              onOnboardingDocsUpdate={fetchOnboardingDocuments}
            />
          ))}

          <Box mt="38px" display="flex" justifyContent="flex-end">
            {showConfirmButton && (
              <Button
                disabled={isConfirmButtonDisabled}
                onClick={confirmAdditionalInfo}
              >
                {t('common.button.confirm')}
              </Button>
            )}
          </Box>
        </>
      )}
      {state.isLoading && <LoaderWithOverlay size={32} thickness={3} />}
    </OnboardingBlockWrapper>
  );
};

export default ComplianceBlock;
