import { Money } from 'services/constants';

export interface Attributes {
  canCreateCardForSelf?: boolean;
  maxSpendLimitPerCard?: Money;
}

const getAttributes = (
  attr: { [key in keyof Attributes]?: any }
): Attributes => {
  // auth0 issue: objects inside token.attr are stringified twice
  // e.g. { ..., maxSpendLimitPerCard: "{\"value\":350000,\"currency\":\"EUR\"}" }

  return {
    ...attr,
    ...(attr?.maxSpendLimitPerCard && {
      maxSpendLimitPerCard: JSON.parse(attr.maxSpendLimitPerCard),
    }),
  };
};

export default getAttributes;
