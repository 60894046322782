import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Box } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link, matchPath, useHistory } from 'react-router-dom';
import { ReactComponent as SettingMenuVerticalIcon } from 'assets/icons/interface/settingMenuVertical.svg';
import { internalRootPaths } from 'components/App';
import { RoundedBadge } from 'components/Badge';
import { Button, IconButton } from 'components/Button';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { LoaderWithOverlay } from 'components/Loader';
import { ListItemText, MenuContainer, MenuItem } from 'components/Menu';
import Tooltip from 'components/Tooltip';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import {
  ConfirmSepaDirectDebitDialog,
  ConnectBankAccountsThroughFinApiDialog,
} from 'domains/billing/dialogs';
import { Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  BankAccount,
  BankAccountTransfersAllowedStatus,
  BankAccountType,
  BankConnection,
  BankConnectionImportStatus,
  BankForConnection,
  BankingServicePartner,
  DirectDebitInfo,
  DirectDebitType,
  NetworkErrorCode,
  OrganizationAccountType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  getPath,
  sleep,
} from 'services/utils';
import AuthorizeBankConnectionDialog from './AuthorizeBankConnectionDialog';
import BankAccountsTableNew from './BankAccountsTableNew';
import BankAvatar from './BankAvatar';
import ConnectionBox from './ConnectionBox';
import SepaDirectDebitDialog from './SepaDirectDebitDialog';
import SwitchToB2bMandateDialog from './SwitchToB2bMandateDialog';
import {
  BankAccountItem,
  BankItem,
  BankLabel,
  ConnectionStatusLabel,
  IbanLabel,
  LabelWrapper,
  NothingFound,
  StyledBlockIcon,
  TableBody,
  TableComponent,
  TableFooter,
  TableHeader,
  TableRow,
} from './style';

interface GroupedBankAccountList {
  [bankConnectionId: string]: {
    [bankId: string]: BankAccount[];
  };
}

const groupBankAccounts = (bankAccounts: BankAccount[]) =>
  bankAccounts.reduce((acc, current) => {
    const bankConnectionId = current.bankConnectionId || 'NONE';
    if (!acc[bankConnectionId]) acc[bankConnectionId] = {};
    const { bankId } = current;
    if (!acc[bankConnectionId][bankId]) acc[bankConnectionId][bankId] = [];
    acc[bankConnectionId][bankId].push(current);
    return acc;
  }, {} as GroupedBankAccountList);

const getBankAccountsWithoutBlockedStatus = (bankAccounts: BankAccount[]) => {
  return bankAccounts.filter(
    (item) =>
      item.transfersAllowedStatus !== BankAccountTransfersAllowedStatus.blocked
  );
};

const isConnectFinApiBankAccountDialogOpen = (
  bankAccountToReconnect: BankAccount | null,
  bankToConnect: BankForConnection | null
) => {
  if (bankAccountToReconnect?.bankAccountType === BankAccountType.finapi)
    return true;
  return !!bankToConnect;
};

export interface BankAccountsTableRef {
  refreshList(): void;
}

export interface BankAccountsTableState {
  bankAccounts: BankAccount[];
  groupedBankAccounts: GroupedBankAccountList;
  bankConnections: BankConnection[];
  error: any;
  isLoading: boolean;
  bankConnectionImportStatus: BankConnectionImportStatus | null;
  bankConnectionToRemove: BankConnection | null;
  canSetBillingAccountAsPrefund: boolean;
  isBankConnectionRemoving: boolean;
  bankAccountToReconnect: BankAccount | null;
  bankAccountToRemove: BankAccount | null;
  bankAccountToSetAsBilling: BankAccount | null;
  isBankAccountRemoving: boolean;
  bankToConnect: BankForConnection | null;
  isBankToConnectLoading: boolean;
  isSDDDialogOpen: boolean;
  isSwitchToB2bMandateDialogOpen: boolean;
  directDebitInfo: DirectDebitInfo | null;
}

export interface BankAccountsTableProps {
  // this is a temporary solution for keeping all logic and new/old designs in one place
  isNewDesign?: boolean;
  onConfirm?: () => void;
}

const BankAccountsTable = (
  { onConfirm, isNewDesign }: BankAccountsTableProps,
  ref: ForwardedRef<BankAccountsTableRef>
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const canUser = useCanUser();
  const history = useHistory();
  const {
    state: { member, organization, defaultCardAccount },
  } = useGlobalState();
  const [state, setState] = useState<BankAccountsTableState>({
    bankAccounts: [],
    groupedBankAccounts: {},
    bankConnections: [],
    error: null,
    isLoading: true,
    bankConnectionImportStatus: null,
    bankConnectionToRemove: null,
    isBankConnectionRemoving: false,
    bankAccountToReconnect: null,
    bankAccountToRemove: null,
    bankAccountToSetAsBilling: null,
    isBankAccountRemoving: false,
    bankToConnect: null,
    isBankToConnectLoading: false,
    isSDDDialogOpen: false,
    isSwitchToB2bMandateDialogOpen: false,
    directDebitInfo: null,
    canSetBillingAccountAsPrefund: false,
  });
  const canShowLinkToBillingApprovalDetailsPage =
    canUser('billing-accounts-approval-page:visit') &&
    !!matchPath(history.location.pathname, {
      path: getPath('settingsBankAccounts'),
    });

  const isPrefundedAccount = useMemo(() => {
    return (
      defaultCardAccount!.accountType.value ===
      OrganizationAccountType.prefunded
    );
  }, [defaultCardAccount!.accountType.value]);

  const checkBankConnectionImportStatus = async (): Promise<
    undefined | BankConnectionImportStatus
  > => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const importStatus = await api.getBankConnectionImportStatus(
        organization!.id
      );

      if (importStatus.startedBy !== member.id || !mounted.current) return;

      if (
        importStatus.status === 'PENDING' &&
        window.location.search.includes('from=finapi')
      ) {
        await sleep(3000);
        if (!mounted.current) return;
        return checkBankConnectionImportStatus();
      }

      if (importStatus.status === 'IMPORTED') {
        setState((prevState) => ({
          ...prevState,
          bankConnectionImportStatus: importStatus,
        }));
        return importStatus;
      }

      if (importStatus.status === 'FAILED') {
        enqueueSnackbar(t('bankAccountsTable.bankConnectionError'), {
          variant: 'error',
          persist: true,
          onExit: () => {
            api
              .deleteBankConnectionImportStatus(organization!.id)
              .catch(() => {});
          },
        });
        return importStatus;
      }

      return importStatus;
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const getOrgDirectDebitInfo = async () => {
    try {
      const directDebitInfo = await api.getSddMandate(organization!.id);
      return directDebitInfo;
    } catch (error) {
      if (getNetworkErrorCode(error) === NetworkErrorCode.notFound) {
        return null;
      } else {
        throw error;
      }
    }
  };

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [
        bankAccountsResponse,
        { bankConnections },
        directDebitInfo,
        additionalInfo,
      ] = await Promise.all([
        api.getBankAccounts(organization!.id),
        api.getBankConnections(organization!.id),
        getOrgDirectDebitInfo(),
        api.getAdditionalInformations(organization!.id),
      ]);

      if (!mounted.current) return;
      const bankAccounts = canUser('bank-account-blocked:view')
        ? bankAccountsResponse.bankAccounts
        : getBankAccountsWithoutBlockedStatus(
            bankAccountsResponse.bankAccounts
          );

      setState((prevState) => ({
        ...prevState,
        bankAccounts,
        groupedBankAccounts: groupBankAccounts(bankAccounts),
        bankConnections,
        directDebitInfo,
        canSetBillingAccountAsPrefund:
          additionalInfo.canSetBillingAccountAsPrefund,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, error, isLoading: false }));
      logError(error);
    }
  };

  useImperativeHandle(ref, () => ({
    refreshList() {
      getData();
    },
  }));

  useEffect(() => {
    (async () => {
      if (canUser('bank-account:connect')) {
        await checkBankConnectionImportStatus();
      }
      await getData();
    })();
  }, []);

  const removeBankAccount = async () => {
    try {
      setState((state) => ({ ...state, isBankAccountRemoving: true }));
      await api.deleteBankAccount(
        organization!.id,
        state.bankAccountToRemove!.id
      );
      if (!mounted.current) return;
      setState((state) => ({
        ...state,
        bankAccountToRemove: null,
        isBankAccountRemoving: false,
      }));
      getData();
    } catch (error) {
      if (!mounted.current) return;
      setState((state) => ({
        ...state,
        bankAccountToRemove: null,
        isBankAccountRemoving: false,
      }));
      if (getNetworkErrorCode(error) === NetworkErrorCode.dataIsNotYetReady) {
        enqueueSnackbar(t('bankAccountsTable.removeBankAccountError'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    }
  };

  const connectBankAccount = async (bankAccount: BankAccount) => {
    try {
      if (state.isBankToConnectLoading) return;
      setState((prevState) => ({ ...prevState, isBankToConnectLoading: true }));
      const { banks } = await api.getBanksForConnection({
        q: bankAccount.iban,
      });
      if (!mounted.current) return;
      if (!banks.length) {
        enqueueSnackbar(t('bankAccountsTable.bankNotFoundError'), {
          variant: 'error',
        });
        return;
      }
      setState((prevState) => ({ ...prevState, bankToConnect: banks[0] }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    } finally {
      if (mounted.current) {
        setState((prevState) => ({
          ...prevState,
          isBankToConnectLoading: false,
        }));
      }
    }
  };

  const reconnectBankAccount = (bankAccountToReconnect: BankAccount) => {
    setState((prevState) => ({ ...prevState, bankAccountToReconnect }));
  };

  const askForBankConnectionRemoval = (
    bankConnectionToRemove: BankConnection
  ) => {
    setState((prevState) => ({ ...prevState, bankConnectionToRemove }));
  };

  const removeBankConnection = async () => {
    try {
      setState((state) => ({ ...state, isBankConnectionRemoving: true }));
      const { id, bankAccountType } = state.bankConnectionToRemove!;
      await api.deleteBankConnection(id, bankAccountType);
      if (!mounted.current) return;
      setState((state) => ({
        ...state,
        bankConnectionToRemove: null,
        isBankConnectionRemoving: false,
      }));
      getData();
    } catch (error) {
      if (!mounted.current) return;
      setState((state) => ({
        ...state,
        bankConnectionToRemove: null,
        isBankConnectionRemoving: false,
      }));
      if (getNetworkErrorCode(error) === NetworkErrorCode.dataIsNotYetReady) {
        enqueueSnackbar(t('bankAccountsTable.removeBankAccountError'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    }
  };

  const renderMenu = (bankAccount: BankAccount) => {
    const isMenuVisible =
      (canUser('bank-account:set-as-billing', bankAccount) &&
        (defaultCardAccount!.accountType.value ===
          OrganizationAccountType.credit ||
          (defaultCardAccount!.accountType.value ===
            OrganizationAccountType.prefunded &&
            state.canSetBillingAccountAsPrefund))) ||
      canUser('bank-account:delete', bankAccount) ||
      state.directDebitInfo?.iban === bankAccount.iban;

    if (!isMenuVisible) return <Box width="30px" />;

    return (
      <MenuContainer
        button={
          <IconButton>
            <SettingMenuVerticalIcon />
          </IconButton>
        }
      >
        {canUser('bank-account:set-as-billing', bankAccount) &&
          (defaultCardAccount!.accountType.value ===
            OrganizationAccountType.credit ||
            (defaultCardAccount!.accountType.value ===
              OrganizationAccountType.prefunded &&
              state.canSetBillingAccountAsPrefund)) && (
            <MenuItem
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  bankAccountToSetAsBilling: bankAccount,
                }))
              }
            >
              <ListItemText
                primary={t('bankAccountsTable.setBillingAccount')}
              />
            </MenuItem>
          )}
        {canUser('bank-account:delete', bankAccount) && (
          <MenuItem
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                bankAccountToRemove: bankAccount,
              }))
            }
          >
            <ListItemText primary={t('bankAccountsTable.removeBankAccount')} />
          </MenuItem>
        )}
        {state.directDebitInfo?.iban === bankAccount.iban && (
          <MenuItem
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                isSDDDialogOpen: true,
              }))
            }
          >
            <ListItemText primary={t('bankAccountsTable.viewSsdMandate')} />
          </MenuItem>
        )}
        {canUser('bank-account:upload-sdd-mandate-file') &&
          state.directDebitInfo?.bankingServicePartner ===
            BankingServicePartner.varengold &&
          state.directDebitInfo?.iban === bankAccount.iban &&
          state.directDebitInfo?.directDebitType === DirectDebitType.core && (
            <MenuItem
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  isSwitchToB2bMandateDialogOpen: true,
                }))
              }
            >
              <ListItemText
                primary={t('bankAccountsTable.switchToB2bMandate')}
              />
            </MenuItem>
          )}
      </MenuContainer>
    );
  };

  const renderIban = (bankAccount: BankAccount) => {
    if (!bankAccount.iban) return null;

    const iban = isNewDesign ? (
      <Typography
        variant="body2"
        component="span"
        color={
          bankAccount.transfersAllowedStatus ===
          BankAccountTransfersAllowedStatus.blocked
            ? 'textSecondary'
            : 'textPrimary'
        }
      >
        {bankAccount.iban}
      </Typography>
    ) : (
      bankAccount.iban
    );

    if (
      canShowLinkToBillingApprovalDetailsPage &&
      bankAccount.detectedAsTransferSender
    ) {
      const path = generatePath(
        internalRootPaths.billingAccountsApprovalsAllDetails,
        { bankAccountId: bankAccount.id }
      );

      return (
        <Link style={{ textDecoration: 'none' }} to={path}>
          {iban}
        </Link>
      );
    }

    return iban;
  };

  return (
    <>
      {isNewDesign ? (
        <BankAccountsTableNew
          isPrefundedAccount={isPrefundedAccount}
          state={state}
          getData={getData}
          onConfirm={onConfirm}
          connectBankAccount={connectBankAccount}
          reconnectBankAccount={reconnectBankAccount}
          askForBankConnectionRemoval={askForBankConnectionRemoval}
          renderIban={renderIban}
          renderMenu={renderMenu}
        />
      ) : (
        <TableComponent>
          <TableHeader>
            <span>{t('bankAccountsTable.bank')}</span>
            {!isPrefundedAccount && (
              <ConnectionStatusLabel>
                {t('bankAccountsTable.connectionStatus')}
              </ConnectionStatusLabel>
            )}
          </TableHeader>
          <TableBody>
            {Object.keys(state.groupedBankAccounts).map((bankConnectionId) =>
              Object.keys(state.groupedBankAccounts[bankConnectionId]).map(
                (bankId) => {
                  const key = `${bankConnectionId}.${bankId}`;
                  const bankAccounts =
                    state.groupedBankAccounts[bankConnectionId][bankId];

                  return bankAccounts.length === 1 ? (
                    <TableRow key={key}>
                      <BankItem>
                        <BankAvatar bankName={bankAccounts[0].bankName} />
                        <LabelWrapper>
                          <Box
                            display="flex"
                            alignItems="center"
                            maxWidth="240px"
                            overflow="hidden"
                          >
                            <BankLabel
                              $lighter={
                                bankAccounts[0].transfersAllowedStatus ===
                                BankAccountTransfersAllowedStatus.blocked
                              }
                            >
                              {bankAccounts[0].bankName}
                            </BankLabel>
                            {bankAccounts[0].transfersAllowedStatus ===
                              BankAccountTransfersAllowedStatus.blocked && (
                              <Tooltip
                                title={t(
                                  'bankAccountsTable.blockedBankAccount'
                                )}
                              >
                                <StyledBlockIcon />
                              </Tooltip>
                            )}
                          </Box>
                          <Box display="flex" alignItems="center">
                            <IbanLabel
                              $lighter={
                                bankAccounts[0].transfersAllowedStatus ===
                                BankAccountTransfersAllowedStatus.blocked
                              }
                              $pr={24}
                            >
                              {renderIban(bankAccounts[0])}
                            </IbanLabel>
                            {bankAccounts[0].directDebit && (
                              <RoundedBadge>
                                {t('bankAccountsTable.billingAccount')}
                              </RoundedBadge>
                            )}
                          </Box>
                        </LabelWrapper>
                        <ConnectionBox
                          bankAccountsOfConnection={bankAccounts}
                          bankConnections={state.bankConnections}
                          onConnect={connectBankAccount}
                          onReconnect={reconnectBankAccount}
                          onRemove={askForBankConnectionRemoval}
                        />
                        {renderMenu(bankAccounts[0])}
                      </BankItem>
                    </TableRow>
                  ) : (
                    <TableRow key={key}>
                      <BankItem>
                        <BankAvatar bankName={bankAccounts[0].bankName} />
                        <LabelWrapper>
                          <BankLabel>{bankAccounts[0].bankName}</BankLabel>
                        </LabelWrapper>
                        <ConnectionBox
                          bankAccountsOfConnection={bankAccounts}
                          bankConnections={state.bankConnections}
                          onConnect={connectBankAccount}
                          onReconnect={reconnectBankAccount}
                          onRemove={askForBankConnectionRemoval}
                        />
                        <Box width="30px" />
                      </BankItem>
                      <Box>
                        {bankAccounts.map((item) => (
                          <BankAccountItem key={item.id}>
                            <Box
                              display="flex"
                              alignItems="center"
                              maxWidth="240px"
                              overflow="hidden"
                            >
                              <IbanLabel
                                $lighter={
                                  item.transfersAllowedStatus ===
                                  BankAccountTransfersAllowedStatus.blocked
                                }
                              >
                                {renderIban(item)}
                              </IbanLabel>
                              {item.transfersAllowedStatus ===
                                BankAccountTransfersAllowedStatus.blocked && (
                                <Tooltip
                                  title={t(
                                    'bankAccountsTable.blockedBankAccount'
                                  )}
                                >
                                  <StyledBlockIcon />
                                </Tooltip>
                              )}
                            </Box>
                            <Box display="flex" flexGrow="1" pl="24px">
                              {item.directDebit && (
                                <RoundedBadge>
                                  {t('bankAccountsTable.billingAccount')}
                                </RoundedBadge>
                              )}
                            </Box>
                            {renderMenu(item)}
                          </BankAccountItem>
                        ))}
                      </Box>
                    </TableRow>
                  );
                }
              )
            )}

            {!state.isLoading && !state.error && !state.bankAccounts.length && (
              <NothingFound>{t('bankAccountsTable.nothingFound')}</NothingFound>
            )}
            {state.error && <WidgetError onReload={getData} />}
            {state.isLoading && <LoaderWithOverlay size={32} thickness={3} />}
          </TableBody>
          {onConfirm && (
            <TableFooter>
              <Button
                onClick={onConfirm}
                disabled={
                  !canUser('bank-connection:confirm', state.bankAccounts)
                }
                $px={60}
              >
                {t('common.button.confirm')}
              </Button>
            </TableFooter>
          )}
        </TableComponent>
      )}

      <AuthorizeBankConnectionDialog
        open={!!state.bankConnectionImportStatus}
        bankConnectionImportStatus={state.bankConnectionImportStatus!}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            bankConnectionImportStatus: null,
          }))
        }
        onSuccess={() => {
          setState((prevState) => ({
            ...prevState,
            bankConnectionImportStatus: null,
          }));
          getData();
        }}
      />
      <ConfirmDialog
        open={!!state.bankConnectionToRemove}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            bankConnectionToRemove: null,
          }));
        }}
        onSuccess={removeBankConnection}
        loading={state.isBankConnectionRemoving}
        title={t('bankAccountsTable.removeConnectionTitle')}
        description={
          <Trans
            i18nKey="bankAccountsTable.removeConnectionDescription"
            values={organization!}
          />
        }
      />
      <SepaDirectDebitDialog
        open={state.isSDDDialogOpen}
        onClose={() =>
          setState((prevState) => ({ ...prevState, isSDDDialogOpen: false }))
        }
        organization={organization!}
        directDebitInfo={state.directDebitInfo!}
      />
      <ConfirmDialog
        open={!!state.bankAccountToRemove}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            bankAccountToRemove: null,
          }));
        }}
        onSuccess={removeBankAccount}
        loading={state.isBankAccountRemoving}
        title={t('bankAccountsTable.removeBankAccountTitle')}
        description={
          <Trans
            i18nKey="bankAccountsTable.removeBankAccountDescription"
            values={state.bankAccountToRemove || undefined}
          />
        }
      />
      <ConnectBankAccountsThroughFinApiDialog
        open={isConnectFinApiBankAccountDialogOpen(
          state.bankAccountToReconnect,
          state.bankToConnect
        )}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            bankAccountToReconnect: null,
            bankToConnect: null,
          }))
        }
        bankAccountToReconnect={state.bankAccountToReconnect}
        bankToConnect={state.bankToConnect}
      />
      <ConfirmSepaDirectDebitDialog
        open={!!state.bankAccountToSetAsBilling}
        isInOnboarding={false}
        bankAccount={state.bankAccountToSetAsBilling!}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            bankAccountToSetAsBilling: null,
          }))
        }
        onSuccess={() => {
          setState((prevState) => ({
            ...prevState,
            bankAccountToSetAsBilling: null,
          }));
          getData();
        }}
      />
      <SwitchToB2bMandateDialog
        open={!!state.isSwitchToB2bMandateDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isSwitchToB2bMandateDialogOpen: false,
          }))
        }
        onSuccess={async () => {
          await getData();
        }}
      />
    </>
  );
};

export default forwardRef(BankAccountsTable);
