import styled from 'styled-components';

export const PaperHolder = styled.div`
  display: flex;
  height: 100vh;
  min-height: 700px;
`;

export const Paper = styled.div<{ $width?: string }>`
  position: relative;
  height: 610px;
  width: ${({ $width }) => $width || '918px'};
  padding: 48px;
  margin: auto;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
`;

export const PaperHeading = styled.div`
  font-size: 24px;
  line-height: 30px;
  margin: 45px 0 42px;
`;

export const PaperDescription = styled.div`
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 9px;
  color: ${({ theme }) => theme.colors.grey100};
`;
