import { useMemo } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const DATEV_GENERAL_SETTINGS = {
  consultantNumberMinVal: 1001,
  consultantNumberMaxVal: 9999999,
  clientNumberMinVal: 1,
  clientNumberMaxVal: 99999,
  generalLedgerAccountLengthMinVal: 4,
  generalLedgerAccountLengthMaxVal: 8,
};

interface MonthSelectProps {
  value: number | null; // 1-12
  onChange: (value: number) => void;
}

function MonthSelect({ value, onChange }: MonthSelectProps) {
  const months = useMemo(() => moment.months(), []);

  return (
    <Select<string>
      labelId="month-select-label"
      value={value?.toString() || ''}
      onChange={(e) => {
        onChange(+e.target.value);
      }}
      renderValue={(selected) => {
        if (!selected) return '';
        const v = +selected - 1;
        return months[v];
      }}
    >
      <MenuItem sx={{ display: 'none' }} />
      {months.map((v, index) => (
        <MenuItem key={v} value={index + 1}>
          {v}
        </MenuItem>
      ))}
    </Select>
  );
}

interface Props extends DialogProps {
  onClose: () => void;
}

const DatevGeneralSettingsDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, accountingSettings },
  } = useGlobalState();
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      consultantNumber: accountingSettings!.consultantNumber || '',
      clientNumber: accountingSettings!.clientNumber || '',
      beginOfFiscalYear: accountingSettings!.beginOfFiscalYear,
      generalLedgerAccountLength:
        accountingSettings!.generalLedgerAccountLength || '',
    },
    validate(values) {
      const errors: { [key in keyof typeof values]?: string } = {};
      const validateField = (
        key: 'consultantNumber' | 'clientNumber' | 'generalLedgerAccountLength',
        min: number,
        max: number
      ) => {
        if (!values[key]) return;
        const val = +values[key];
        if (val < min) {
          errors[key] = t('datevExportSettingsGroup.valueTooLowError', {
            min: min,
          });
        }
        if (val > max) {
          errors[key] = t('datevExportSettingsGroup.valueTooBigError', {
            max: max,
          });
        }
      };
      validateField(
        'consultantNumber',
        DATEV_GENERAL_SETTINGS.consultantNumberMinVal,
        DATEV_GENERAL_SETTINGS.consultantNumberMaxVal
      );
      validateField(
        'clientNumber',
        DATEV_GENERAL_SETTINGS.clientNumberMinVal,
        DATEV_GENERAL_SETTINGS.clientNumberMaxVal
      );
      if (
        !accountingSettings!.pliantCreditCardAccount &&
        (!accountingSettings!.useSupplierAccount ||
          !accountingSettings!.defaultSupplierAccount) &&
        !accountingSettings!.cashInTransitAccount
      ) {
        validateField(
          'generalLedgerAccountLength',
          DATEV_GENERAL_SETTINGS.generalLedgerAccountLengthMinVal,
          DATEV_GENERAL_SETTINGS.generalLedgerAccountLengthMaxVal
        );
      } else if (values.generalLedgerAccountLength) {
        const val = +values.generalLedgerAccountLength;
        if (
          (accountingSettings!.pliantCreditCardAccount &&
            !(
              accountingSettings!.pliantCreditCardAccount.length === val ||
              accountingSettings!.pliantCreditCardAccount.length === val + 1
            )) ||
          (accountingSettings!.useSupplierAccount &&
            accountingSettings!.defaultSupplierAccount &&
            accountingSettings!.defaultSupplierAccount.length !== val + 1) ||
          (accountingSettings!.cashInTransitAccount &&
            accountingSettings!.cashInTransitAccount.length !== val)
        ) {
          errors.generalLedgerAccountLength = t(
            'datevExportSettingsGroup.generalLedgerAccountLengthMatchingError'
          );
        }
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const data = await api.updateAccountingGeneralSettings({
          organizationId: organization!.id,
          consultantNumber: values.consultantNumber
            ? +values.consultantNumber
            : undefined,
          clientNumber: values.clientNumber ? +values.clientNumber : undefined,
          beginOfFiscalYear: values.beginOfFiscalYear
            ? +values.beginOfFiscalYear
            : undefined,
          generalLedgerAccountLength: values.generalLedgerAccountLength
            ? +values.generalLedgerAccountLength
            : undefined,
        });
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { accountingSettings: data },
        });
        if (!mounted.current) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), {
          variant: 'error',
        });
        logError(error);
      }
    },
  });

  const hasNonEmptyField =
    !!formik.values.consultantNumber ||
    !!formik.values.clientNumber ||
    !!formik.values.beginOfFiscalYear ||
    !!formik.values.generalLedgerAccountLength;
  const isSubmitDisabled = !hasNonEmptyField || formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('datevExportSettingsGroup.datevSettings')}</DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="datev-settings-form-change"
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NumberFormat
                label={t('datevExportSettingsGroup.consultantNumber')}
                isNumericString
                customInput={TextField}
                allowNegative={false}
                decimalScale={0}
                name="consultantNumber"
                value={formik.values.consultantNumber}
                onBlur={formik.handleBlur}
                onValueChange={(values) => {
                  formik.setFieldValue('consultantNumber', values.value, false);
                  formik.setFieldError('consultantNumber', undefined);
                }}
                disabled={formik.isSubmitting}
                error={!!formik.errors.consultantNumber}
                helperText={
                  !!formik.errors.consultantNumber &&
                  formik.errors.consultantNumber
                }
              />
            </Grid>

            <Grid item xs={12}>
              <NumberFormat
                label={t('datevExportSettingsGroup.clientNumber')}
                isNumericString
                customInput={TextField}
                allowNegative={false}
                decimalScale={0}
                name="clientNumber"
                value={formik.values.clientNumber}
                onBlur={formik.handleBlur}
                onValueChange={(values) => {
                  formik.setFieldValue('clientNumber', values.value, false);
                  formik.setFieldError('clientNumber', undefined);
                }}
                disabled={formik.isSubmitting}
                error={!!formik.errors.clientNumber}
                helperText={
                  !!formik.errors.clientNumber && formik.errors.clientNumber
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth disabled={formik.isSubmitting}>
                <InputLabel id="month-select-label">
                  {t('datevExportSettingsGroup.beginOfFiscalYear')}
                </InputLabel>
                <MonthSelect
                  value={formik.values.beginOfFiscalYear}
                  onChange={(value) =>
                    formik.setFieldValue('beginOfFiscalYear', value)
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <NumberFormat
                label={t('datevExportSettingsGroup.generalLedgerAccountLength')}
                isNumericString
                customInput={TextField}
                allowNegative={false}
                decimalScale={0}
                name="generalLedgerAccountLength"
                value={formik.values.generalLedgerAccountLength}
                onBlur={formik.handleBlur}
                onValueChange={(values) => {
                  formik.setFieldValue(
                    'generalLedgerAccountLength',
                    values.value,
                    false
                  );
                  formik.setFieldError('generalLedgerAccountLength', undefined);
                }}
                disabled={formik.isSubmitting}
                error={!!formik.errors.generalLedgerAccountLength}
                helperText={
                  !!formik.errors.generalLedgerAccountLength &&
                  formik.errors.generalLedgerAccountLength
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          type="submit"
          form="datev-settings-form-change"
        >
          {t('datevExportSettingsGroup.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(DatevGeneralSettingsDialog);
