import React from 'react';
import { useTranslation } from 'react-i18next';
import { SepaDirectDebit } from 'domains/billing/components';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  withDialogWrapper,
} from 'elements';
import useDirectDebitDocument from 'hooks/useDirectDebitDocument';
import { DirectDebitInfo, Organization } from 'services/constants';
import SepaConfirmedInfo from './SepaConfirmedInfo';

interface Props extends DialogProps {
  onClose: () => void;
  organization: Organization;
  directDebitInfo: DirectDebitInfo;
}

const SepaDirectDebitDialog = ({
  organization,
  directDebitInfo,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const downloadDocument = useDirectDebitDocument(directDebitInfo);

  return (
    <Dialog {...props}>
      <DialogTitle>
        {t('sepaDirectDebitDialog.title', {
          type: directDebitInfo.directDebitType,
        })}
      </DialogTitle>
      <DialogContent>
        <SepaDirectDebit directDebitInfo={directDebitInfo} />
        <Box mb={3} />
        <SepaConfirmedInfo directDebitInfo={directDebitInfo} />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.close')}
        </Button>
        <Button
          disabled={!directDebitInfo.documentId}
          onClick={downloadDocument}
        >
          {t('sepaDirectDebitDialog.download')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withDialogWrapper(SepaDirectDebitDialog);
