import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import styled from 'styled-components';

const Wrapper = styled.div<{ $size: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  transform: translate(-50%, -50%);

  .MuiCircularProgress-root {
    color: #201c1c;
  }
`;

export interface LoaderProps {
  size: number;
  thickness: number;
  className?: string;
}

const Loader = ({ size, thickness, className = '' }: LoaderProps) => {
  return (
    <Wrapper $size={size} className={className}>
      <CircularProgress size={size} thickness={thickness} />
    </Wrapper>
  );
};

export default Loader;
