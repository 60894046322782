import React, { useCallback, useLayoutEffect, useState } from 'react';
import { dinero } from 'dinero.js';
import { FormikHelpers, useFormikContext } from 'formik';
import pick from 'lodash/pick';
import { Trans, useTranslation } from 'react-i18next';
import { useActiveTeams, useGlobalState } from 'context/GlobalState';
import { useCardAccountCurrency } from 'domains/card/hooks';
import { RolePicker } from 'domains/member/components';
import {
  useCurrencyDisplayMode,
  useOrgDefaultLanguage,
} from 'domains/organization/hooks';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  ListItemIcon,
  LoaderWithOverlay,
  MenuItem,
  PlusIcon,
  Radio,
  RadioGroup,
  Select,
  TextField,
  XIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  MemberDetails,
  memberTitles,
  NetworkErrorCode,
  SubscriptionPlan,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { Role, useCanUser } from 'services/rbac';
import {
  convertDineroToMoney,
  formatMoney,
  getGenericErrorMsg,
  getNetworkErrorCode,
  trimObjValues,
  validateCardholderFullName,
  validateEmail,
} from 'services/utils';
import { AllFormikValues, FormikHandlers } from './index';

interface Props {
  allowedRoles: { role: Role; isAllowed: boolean }[];
  isFirstMember: boolean;
  isLoading: boolean;
  nextStep: (role: Role) => void;
  subscriptionPlan: SubscriptionPlan;
  onClose: () => void;
  onInviteSuccess: (member: MemberDetails, shouldIssueCard: boolean) => void;
  setFormikHandlers: (value: React.SetStateAction<FormikHandlers>) => void;
}

const VALUE_NAMES_ON_SUBMIT = [
  'title',
  'firstName',
  'lastName',
  'email',
  'teamIds',
  'role',
  'shouldIssueCard',
] as const;

const InviteMemberStep = ({
  allowedRoles,
  isFirstMember,
  isLoading,
  nextStep,
  subscriptionPlan,
  onClose,
  onInviteSuccess,
  setFormikHandlers,
}: Props) => {
  const formik = useFormikContext<AllFormikValues>();
  const { t, i18n } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const {
    state: { isComplianceRiskWhiteLabelApp, organization, featureModules },
  } = useGlobalState();
  const currency = useCardAccountCurrency();
  const currencyDisplay = useCurrencyDisplayMode();
  const [isTeamSelectOpen, setIsTeamSelectOpen] = useState(false);
  const teams = useActiveTeams();
  const orgDefaultLanguage = useOrgDefaultLanguage();
  const isTeamsSelectVisible =
    featureModules.TEAMS && canUser('member-teams:change', teams);

  const onSubmit = useCallback(
    async (
      allValues: AllFormikValues,
      { setSubmitting, setErrors }: FormikHelpers<AllFormikValues>
    ) => {
      const { role, shouldIssueCard, ...rest } = {
        ...pick(allValues, VALUE_NAMES_ON_SUBMIT),
      };

      if (
        !isComplianceRiskWhiteLabelApp &&
        (role === Role.orgAdmin || role === Role.accountant) &&
        (canUser('member-permissions-role:update') ||
          canUser('member-permissions:change'))
      ) {
        return nextStep(role);
      }

      try {
        const memberData = trimObjValues(rest);
        const roles = role === Role.auditor ? [] : [Role.cardholder]; // default role for all members, except for auditors
        if (role !== Role.cardholder) roles.push(role);

        const member = await api.inviteMember({
          ...memberData,
          ...(role === Role.orgAdmin && {
            maxSpendLimitPerCard: convertDineroToMoney(
              dinero({ amount: 0, currency })
            ),
            canCreateCardForSelf: false,
          }),
          roles,
          title: memberData.title || null,
          languageCode: orgDefaultLanguage.code,
          organizationId: organization!.id,
        });
        if (!mounted.current) return;
        enqueueSnackbar(t('common.invitationSent'));
        onInviteSuccess(member, shouldIssueCard);
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (
          getNetworkErrorCode(error) === NetworkErrorCode.memberLimitExceeded
        ) {
          enqueueSnackbar(t('inviteMemberDialog.memberLimitExceeded'), {
            variant: 'error',
          });
        } else if (
          getNetworkErrorCode(error) === NetworkErrorCode.userAlreadyExists
        ) {
          setErrors({ email: t('inviteMemberDialog.userExistsError') });
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    },
    []
  );

  useLayoutEffect(() => {
    setFormikHandlers({ onSubmit, validate: () => {} });
  }, [onSubmit]);

  const {
    firstNameNotPrintableError,
    lastNameNotPrintableError,
    fullNameTooLongError,
  } = validateCardholderFullName(
    formik.values.firstName,
    formik.values.lastName
  );

  const { invalidEmailError, emailTooLongError } = validateEmail(
    formik.values.email
  );

  const isSubmitDisabled =
    !formik.values.firstName.trim() ||
    !formik.values.lastName.trim() ||
    !formik.values.email.trim() ||
    !!firstNameNotPrintableError ||
    !!lastNameNotPrintableError ||
    !!fullNameTooLongError ||
    !!invalidEmailError ||
    !!emailTooLongError ||
    formik.isSubmitting;

  const showNextButton =
    !isComplianceRiskWhiteLabelApp &&
    (formik.values.role === Role.orgAdmin ||
      formik.values.role === Role.accountant) &&
    (canUser('member-permissions-role:update') ||
      canUser('member-permissions:change'));

  const shouldShowInvitationTip =
    subscriptionPlan.freeMembersCount !== null &&
    subscriptionPlan.activeAndInvitedMembersCount >=
      subscriptionPlan.freeMembersCount &&
    subscriptionPlan.additionalUserFee.value > 0;

  return (
    <>
      <DialogTitle>{t('inviteMemberDialog.inviteMember')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="invite-member-form" noValidate>
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={6}>
              <FormControl disabled={formik.isSubmitting} fullWidth>
                <InputLabel id="member-title-select-label">
                  {t('inviteMemberDialog.title')}
                </InputLabel>
                <Select<string>
                  labelId="member-title-select-label"
                  renderValue={(selected) => t(`memberTitles.${selected}`)}
                  {...formik.getFieldProps('title')}
                >
                  <MenuItem value="">-</MenuItem>
                  {memberTitles.map((title) => (
                    <MenuItem key={title} value={title}>
                      {t(`memberTitles.${title}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} />

            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('firstName')}
                label={t('inviteMemberDialog.firstName')}
                inputProps={{ maxLength: 50 }}
                disabled={formik.isSubmitting}
                error={!!firstNameNotPrintableError || !!fullNameTooLongError}
                helperText={firstNameNotPrintableError}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('lastName')}
                label={t('inviteMemberDialog.lastName')}
                inputProps={{ maxLength: 50 }}
                disabled={formik.isSubmitting}
                error={!!lastNameNotPrintableError || !!fullNameTooLongError}
                helperText={lastNameNotPrintableError}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('email')}
                label={t('inviteMemberDialog.email')}
                disabled={formik.isSubmitting}
                error={!!invalidEmailError || !!emailTooLongError}
                helperText={invalidEmailError || emailTooLongError}
              />
            </Grid>

            {isTeamsSelectVisible && (
              <Grid item xs={6}>
                <FormControl disabled={formik.isSubmitting} fullWidth>
                  <InputLabel id="team-select-label">
                    {t('inviteMemberDialog.team')}
                  </InputLabel>
                  <Select<string[]>
                    multiple
                    open={isTeamSelectOpen}
                    onClose={() => setIsTeamSelectOpen(false)}
                    onOpen={() => setIsTeamSelectOpen(true)}
                    renderValue={(selected) =>
                      selected
                        .map((teamId) => {
                          const team = teams.find((item) => item.id === teamId);
                          return team?.name;
                        })
                        .join(', ')
                    }
                    {...formik.getFieldProps('teamIds')}
                  >
                    {teams.map((item) => {
                      const isTeamSelected = formik.values.teamIds.find(
                        (teamId) => teamId === item.id
                      );
                      return (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          onClick={() => setIsTeamSelectOpen(false)}
                        >
                          <ListItemIcon>
                            {isTeamSelected ? (
                              <XIcon fontSize="small" />
                            ) : (
                              <PlusIcon fontSize="small" />
                            )}
                          </ListItemIcon>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={6}>
              <RolePicker
                value={formik.values.role}
                onChange={(role) => formik.setFieldValue('role', role)}
                allowedRoles={allowedRoles}
                isDisabled={formik.isSubmitting}
              />
            </Grid>
          </Grid>

          {!!fullNameTooLongError && (
            <FormHelperText error>{fullNameTooLongError}</FormHelperText>
          )}

          {!isComplianceRiskWhiteLabelApp &&
            featureModules.CARDHOLDER_MANAGEMENT &&
            canUser('card:create') &&
            formik.values.role !== Role.auditor && (
              <Box mt={2}>
                <FormControl disabled={formik.isSubmitting} variant="standard">
                  <FormLabel>
                    {t('inviteMemberDialog.issueCardForMember')}
                  </FormLabel>
                  <RadioGroup
                    name="shouldIssueCard"
                    value={formik.values.shouldIssueCard ? 'yes' : 'no'}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'shouldIssueCard',
                        e.target.value === 'yes'
                      );
                    }}
                    row
                  >
                    {['yes', 'no'].map((option) => (
                      <FormControlLabel
                        key={option}
                        value={option}
                        control={<Radio />}
                        label={t(`inviteMemberDialog.${option}`)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            )}

          {canUser('member-first:create') && isFirstMember && (
            <Box mt={2}>
              <Alert severity="warning">
                {t('inviteMemberDialog.firstUserWarning')}
              </Alert>
            </Box>
          )}

          {shouldShowInvitationTip && (
            <Box mt={2}>
              <Alert severity="warning">
                <Trans
                  i18nKey="tooltips.thresholdExceededInPremiumOrEnterprisePlans"
                  components={{
                    RedAndBoldText: <b />,
                  }}
                  values={{
                    fee: formatMoney(
                      subscriptionPlan.additionalUserFee,
                      i18n.language,
                      { currencyDisplay, fractionalPart: true }
                    ),
                  }}
                />
              </Alert>
            </Box>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="invite-member-form"
          type="submit"
        >
          {showNextButton
            ? t('common.button.continue')
            : t('inviteMemberDialog.sendInvite')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={isLoading || formik.isSubmitting} />
    </>
  );
};

export default InviteMemberStep;
