import styled from 'styled-components';

export interface OverlayProps {
  $top?: number;
  $bottom?: number;
}

const Overlay = styled.div<OverlayProps>`
  z-index: 50;
  position: absolute;
  top: ${({ $top }) => ($top ? $top + 'px' : 0)};
  right: 0;
  bottom: ${({ $bottom }) => ($bottom ? $bottom + 'px' : 0)};
  left: 0;
  background: rgba(255, 255, 255, 0.5);
`;

export default Overlay;
