import React, { useMemo } from 'react';
import MuiStep from '@material-ui/core/Step';
import MuiStepConnector from '@material-ui/core/StepConnector';
import MuiStepLabel from '@material-ui/core/StepLabel';
import MuiStepper from '@material-ui/core/Stepper';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { useOnboardingContext } from 'components/OnboardingPage/useOnboardingContext';
import { useGlobalState } from 'context/GlobalState';
import {
  BillPaymentType,
  OnboardingDocumentCategory,
  OnboardingDocumentStatus,
  OrganizationAccountType,
  OrganizationRepresentativeExternalStatus,
  OrganizationStatus,
} from 'services/constants';
import StepIcon from './StepIcon';

const StyledMuiStepper = styled(MuiStepper)`
  width: 645px;
  padding: 3px 0 0;
  margin: 0 10px;
`;

const StepConnector = styled(MuiStepConnector)`
  top: 7px;
  left: calc(-50% + 18px);
  right: calc(50% + 18px);

  .MuiStepConnector-line {
    border-top-width: 1px;
    border-color: ${({ theme }) => theme.colors.neutralVariant50};
  }

  &.MuiStepConnector-completed .MuiStepConnector-line,
  &.MuiStepConnector-active .MuiStepConnector-line {
    border-color: ${({ theme }) => theme.colors.financeGreen10};
  }
`;

const StepLabel = styled(MuiStepLabel)`
  .MuiStepLabel-label {
    margin: 8px 0 0;
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutralVariant50};

    &.MuiStepLabel-active {
      color: ${({ theme }) => theme.colors.grey10};
    }

    &.MuiStepLabel-completed {
      color: ${({ theme }) => theme.colors.financeGreen10};
    }
  }
`;

const Stepper = () => {
  const {
    state: { onboarding, onboardingDocuments, orgRepsAdditionalInfo },
  } = useOnboardingContext();
  const {
    state: { member, defaultCardAccount },
  } = useGlobalState();

  const didUserConfirmSDDMandate = !!onboarding?.directDebitActivationConfirmations?.some(
    (confirmation) => confirmation.memberId === member.id
  );
  const didUserConfirmTacs = !!onboarding?.bankTermsConfirmations?.some(
    (confirmation) => confirmation.memberId === member.id
  );
  const onboardingDocumentsExternallyVisible = useMemo(
    () =>
      onboardingDocuments.filter(
        (doc) =>
          ![
            OnboardingDocumentStatus.ARCHIVED,
            OnboardingDocumentStatus.DRAFT,
          ].includes(doc.status)
      ),
    [onboardingDocuments]
  );

  const riskAndComplianceOnboardingDocuments = onboardingDocumentsExternallyVisible.filter(
    (doc) =>
      [
        OnboardingDocumentCategory.COMPLIANCE,
        OnboardingDocumentCategory.RISK,
      ].includes(doc.type.category)
  );
  const hasOnboardingDocumentsStep =
    riskAndComplianceOnboardingDocuments.length > 0;
  const isOnboardingDocumentsStepCompleted = riskAndComplianceOnboardingDocuments.every(
    (doc) => doc.status === OnboardingDocumentStatus.SUBMITTED
  );
  const isRepresentativesAdditionalInfoStepCompleted = orgRepsAdditionalInfo.every(
    (rep) =>
      rep.externalStatus ===
      OrganizationRepresentativeExternalStatus.infoSubmitted
  );

  const getActiveStep = () => {
    if (!onboarding) return;

    if (onboarding.status === OrganizationStatus.onboardingDetails) return 0;

    if (
      defaultCardAccount!.accountType.value ===
      OrganizationAccountType.prefunded
    ) {
      if (hasOnboardingDocumentsStep && !isOnboardingDocumentsStepCompleted) {
        return 1;
      }

      if (!(onboarding.termsAndConditionsCompleted || didUserConfirmTacs)) {
        return 1 + Number(hasOnboardingDocumentsStep);
      }

      if (!onboarding.complianceCheckCompleted) {
        return 2 + Number(hasOnboardingDocumentsStep);
      }
    }

    if (
      defaultCardAccount!.accountType.value === OrganizationAccountType.credit
    ) {
      if (hasOnboardingDocumentsStep && !isOnboardingDocumentsStepCompleted) {
        return 1;
      }

      if (!onboarding.riskAssessmentCompleted) {
        return 1 + Number(hasOnboardingDocumentsStep);
      }

      if (
        defaultCardAccount!.billPaymentTransferType.value ===
        BillPaymentType.directDebit
      ) {
        if (!(onboarding.directDebitCompleted || didUserConfirmSDDMandate)) {
          return 2 + Number(hasOnboardingDocumentsStep);
        }

        if (!(onboarding.termsAndConditionsCompleted || didUserConfirmTacs)) {
          return 3 + Number(hasOnboardingDocumentsStep);
        }

        if (!onboarding.complianceCheckCompleted) {
          return 4 + Number(hasOnboardingDocumentsStep);
        }
      } else {
        if (!(onboarding.termsAndConditionsCompleted || didUserConfirmTacs)) {
          return 2 + Number(hasOnboardingDocumentsStep);
        }

        if (!onboarding.complianceCheckCompleted) {
          return 3 + Number(hasOnboardingDocumentsStep);
        }
      }

      return -1;
    }
  };

  if (!onboarding) return null;

  return (
    <StyledMuiStepper
      alternativeLabel
      nonLinear
      activeStep={getActiveStep()}
      connector={<StepConnector />}
    >
      <MuiStep
        completed={onboarding.status !== OrganizationStatus.onboardingDetails}
      >
        <StepLabel StepIconComponent={StepIcon}>
          <Trans
            i18nKey="onboardingStepper.orgDetails"
            components={{ br: <br /> }}
          />
        </StepLabel>
      </MuiStep>

      {hasOnboardingDocumentsStep && (
        <MuiStep completed={isOnboardingDocumentsStepCompleted}>
          <StepLabel StepIconComponent={StepIcon}>
            <Trans
              i18nKey="onboardingStepper.onboardingDocuments"
              components={{ br: <br /> }}
            />
          </StepLabel>
        </MuiStep>
      )}

      {orgRepsAdditionalInfo.length > 0 && (
        <MuiStep completed={isRepresentativesAdditionalInfoStepCompleted}>
          <StepLabel StepIconComponent={StepIcon}>
            <Trans
              i18nKey="onboardingStepper.representativesAdditonalInfo"
              components={{ br: <br /> }}
            />
          </StepLabel>
        </MuiStep>
      )}

      {defaultCardAccount!.accountType.value !==
        OrganizationAccountType.prefunded && (
        <MuiStep completed={onboarding.riskAssessmentCompleted}>
          <StepLabel StepIconComponent={StepIcon}>
            <Trans
              i18nKey="onboardingStepper.riskAssessment"
              components={{ br: <br /> }}
            />
          </StepLabel>
        </MuiStep>
      )}

      {defaultCardAccount!.accountType.value !==
        OrganizationAccountType.prefunded &&
        defaultCardAccount!.billPaymentTransferType.value ===
          BillPaymentType.directDebit && (
          <MuiStep
            completed={
              didUserConfirmSDDMandate || onboarding.directDebitCompleted
            }
          >
            <StepLabel StepIconComponent={StepIcon}>
              <Trans
                i18nKey="onboardingStepper.sddMandate"
                components={{ br: <br /> }}
              />
            </StepLabel>
          </MuiStep>
        )}

      <MuiStep
        completed={didUserConfirmTacs || onboarding.termsAndConditionsCompleted}
      >
        <StepLabel StepIconComponent={StepIcon}>
          <Trans
            i18nKey="onboardingStepper.terms"
            components={{ br: <br /> }}
          />
        </StepLabel>
      </MuiStep>

      <MuiStep completed={onboarding.complianceCheckCompleted}>
        <StepLabel StepIconComponent={StepIcon}>
          <Trans
            i18nKey="onboardingStepper.complianceCheck"
            components={{ br: <br /> }}
          />
        </StepLabel>
      </MuiStep>
    </StyledMuiStepper>
  );
};

export default Stepper;
