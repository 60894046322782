import React, { useEffect, useState } from 'react';
import { useGlobalState } from 'context/GlobalState';

const MIN_LOADER_DISPLAY_TIME = 1200;

/**
 * This simple HOC is specifically designed to customize
 * the withAuthenticationRequired HOC from auth0. It just starts the loader
 * and delays the withAuthenticationRequired component rendering to enforce
 * min loader display time. This is done because the auth0 HOC doesn't provide
 * options to control when users are redirected to the login page.
 */
const withLoader = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => (props: P): JSX.Element | null => {
  const { dispatch } = useGlobalState();
  const [didTimePassed, setDidTimePassed] = useState(false);

  useEffect(() => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    setTimeout(() => setDidTimePassed(true), MIN_LOADER_DISPLAY_TIME);
  }, []);

  return didTimePassed ? <Component {...props} /> : null;
};

export default withLoader;
