import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Autocomplete, FormControl, Tooltip } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  CustomField,
  CustomFieldOption,
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  customFieldObject: CustomField;
  value: CustomFieldOption | null;
  componentKey: string;
  error: string | undefined;
  onChange: (option: CustomFieldOption | null) => void;
}

interface State {
  isLoading: boolean;
  isOpen: boolean;
  customFieldOptions: CustomFieldOption[];
}

const CustomFieldOptionsAutocomplete = ({
  customFieldObject,
  value,
  componentKey,
  error,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
    isOpen: false,
    customFieldOptions: [],
  });
  const [inputValue, setInputValue] = useState('');

  const getData = async () => {
    try {
      const { customFieldOptions } = await api.getCustomFieldOptions({
        transactionCustomFieldId: customFieldObject.id,
        organizationId: organization!.id,
        q: inputValue.trim(),
        status: AccountingItemStatus.active,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        customFieldOptions,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isOpen: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (!state.isOpen || !customFieldObject.dropdownOptionsCount) return;
    let active = true;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    setTimeout(() => {
      if (active) getData();
    }, DEFAULT_AUTOCOMPLETE_DELAY);
    return () => {
      active = false;
    };
  }, [inputValue.trim()]);

  return (
    <Tooltip
      title={
        !customFieldObject.dropdownOptionsCount &&
        t(`${componentKey}.minimumOneOptionTooltip`)
      }
    >
      <FormControl fullWidth>
        <Autocomplete<CustomFieldOption, false, false, false>
          label={`${customFieldObject.label}${
            customFieldObject.mandatoryOnAutomation
              ? ` (${t(`${componentKey}.mandatory`)})`
              : ''
          }`}
          open={state.isOpen}
          onOpen={() =>
            setState((prevState) => ({
              ...prevState,
              isOpen: true,
            }))
          }
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              isOpen: false,
            }))
          }
          value={value}
          loading={state.isLoading}
          disabled={!customFieldObject.dropdownOptionsCount}
          inputValue={inputValue}
          onInputChange={(e, inputValue) => setInputValue(inputValue)}
          options={state.customFieldOptions}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name}
          noOptionsText={t('common.nothingFound')}
          loadingText={t('common.loading')}
          onChange={(_, option) => {
            if (!option)
              setState((prevState) => ({
                ...prevState,
                isOpen: true,
              }));
            onChange(option);
          }}
          error={!!error}
          helperText={error}
        />
      </FormControl>
    </Tooltip>
  );
};

export default CustomFieldOptionsAutocomplete;
