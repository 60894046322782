import { styled } from '@mui/material';

export const Overlay = styled('div')`
  position: fixed;
  z-index: 2500;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 54px;
  background: ${({ theme }) => theme.palette.background.paper};
`;

export const Content = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 418px;
  transform: translate(-50%, -50%);
  text-align: center;
`;
