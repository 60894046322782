import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
  withDialogWrapper,
} from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { OpenActiveOptIn } from 'services/constants';
import { useCanUser } from 'services/rbac';
import LegalRepInternal from './LegalRepInternal';

interface Props extends DialogProps {
  isLoading?: boolean;
  openActiveOptIn: OpenActiveOptIn;
  legalReps: string[];
  onSuccess: () => void;
}

const UpdatedTermsAndConditionsDialog = ({
  isLoading = false,
  openActiveOptIn,
  legalReps,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const partnerName = usePartnerName();

  return (
    <Dialog {...props}>
      <DialogTitle>{t('updatedTermsAndConditionsDialog.title')}</DialogTitle>
      {canUser('terms-and-conditions:accept', openActiveOptIn) ? (
        <LegalRepInternal
          isLoading={isLoading}
          openActiveOptIn={openActiveOptIn}
          onSuccess={onSuccess}
        />
      ) : (
        <DialogContent>
          <Typography mb={4}>
            {t('updatedTermsAndConditionsDialog.otherRolesDescription', {
              partnerName,
            })}
          </Typography>
          <Typography mb={4}>
            {legalReps.map((legalRep) => (
              <React.Fragment key={legalRep}>
                <b key={legalRep}>{legalRep}</b>
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default withDialogWrapper<Props>(UpdatedTermsAndConditionsDialog);
