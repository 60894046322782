import { useTranslation } from 'react-i18next';
import { TeamBadge } from 'domains/member/components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { FilterDrawerClearButton } from 'layout';
import { Team } from 'services/constants';

interface Props {
  teams: Team[];
  value: string[];
  onChange: (value: string[]) => void;
}

const TeamFilter = ({ teams, value, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-team"
        id="panel-team-header"
      >
        <Typography>{t('filters.team')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<string[]>
            size="medium"
            multiple
            displayEmpty
            value={value}
            onChange={(e) => onChange(e.target.value as string[])}
            renderValue={(selected) => {
              if (!selected?.length)
                return (
                  <Typography color="text.disabled">
                    {t('filters.team')}
                  </Typography>
                );

              return selected
                .map((teamId) => teams.find((item) => item.id === teamId))
                .filter((item) => !!item)
                .map((team) => <TeamBadge key={team!.id} name={team!.name} />);
            }}
          >
            {teams.map((team) => (
              <MenuItem key={team.id} value={team.id}>
                {team.name}
              </MenuItem>
            ))}
          </Select>

          {!!value.length && <FilterDrawerClearButton onChange={onChange} />}
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default TeamFilter;
