import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';
import AppErrorOverlay from 'components/AppErrorOverlay';
import { logError } from 'services/monitoring';

const isEmailNotVerifiedError = (error: Error) =>
  error.message.includes('Please verify your email');

// See https://github.com/auth0/auth0-react/issues/122 for more details
const Auth0Callback = () => {
  const { isLoading, error } = useAuth0();

  useEffect(() => {
    if (error && !isEmailNotVerifiedError(error))
      logError(error, { type: 'auth0_redirect' });
  }, [error]);

  if (isLoading) return null;

  if (error) {
    return isEmailNotVerifiedError(error) ? (
      <Redirect to="/email-not-verified" />
    ) : (
      <AppErrorOverlay />
    );
  }

  return <Redirect to="/" />;
};

export default Auth0Callback;
