import styled from 'styled-components';

export const Item = styled.div<{ $alignItems?: string }>`
  display: flex;
  align-items: ${({ $alignItems = 'initial' }) => $alignItems};
  margin: 0 0 36px;

  &:first-child {
    margin-top: 30px;
  }
`;

export const ItemHeading = styled.h4<{ $width?: string; $lineHeight?: number }>`
  width: ${({ $width }) => $width};
  margin: 0;
  font-size: 14px;
  line-height: ${({ $lineHeight = 17 }) => $lineHeight}px;
`;

export const ItemParagraph = styled.p<{ $pt?: number }>`
  margin: 0;
  padding: ${({ $pt = 0 }) => `${$pt}px 0 0`};
  font-size: 14px;
  line-height: 17px;
`;
