import { useTranslation } from 'react-i18next';
import { StyledIconButton } from 'components/MainHeader/style';
import { useGlobalState } from 'context/GlobalState';
import { ReceiptInboxDialog } from 'domains/transaction/dialogs';
import { useUnmatchedReceiptsCount } from 'domains/transaction/hooks';
import { Badge, Tooltip, TrayIcon } from 'elements';

const ReceiptInboxButton = () => {
  const { t } = useTranslation();
  const unmatchedReceiptsCountAppBased = useUnmatchedReceiptsCount();
  const {
    dispatch,
    state: {
      receiptInbox: { isDialogOpen },
    },
  } = useGlobalState();

  const toggleDialog = (open: boolean) => {
    dispatch({
      type: 'SET_RECEIPT_INBOX_DATA',
      payload: {
        isDialogOpen: open,
        flow: null,
        selectedTransationHasReceipt: false,
        receipt: null,
        thumbnail: '',
      },
    });
  };

  return (
    <>
      <Tooltip title={t('header.receiptInbox')}>
        <StyledIconButton
          onClick={() => toggleDialog(true)}
          data-intercom-target="receipt-inbox-button"
        >
          <Badge
            badgeContent={unmatchedReceiptsCountAppBased}
            color="secondary"
          >
            <TrayIcon />
          </Badge>
        </StyledIconButton>
      </Tooltip>

      <ReceiptInboxDialog
        open={isDialogOpen}
        onClose={() => toggleDialog(false)}
      />
    </>
  );
};

export default ReceiptInboxButton;
