import { MerchantAvatar, MerchantLogo } from 'domains/merchant/components';
import { AvatarProps } from 'elements';
import {
  AccountEntryTransactionInfo,
  ExportFlowTransaction,
  Transaction,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { SplitTransactionsAvatar } from './SplitTransactionsAvatar';

type TransactionAvatarSize = 'medium' | 'large';

interface Props extends AvatarProps {
  size?: TransactionAvatarSize;
  transaction:
    | Transaction
    | AccountEntryTransactionInfo
    | ExportFlowTransaction;
}

export const TransactionAvatar = ({
  size = 'medium',
  transaction,
  ...props
}: Props) => {
  const { merchantDisplayNameEnabled } = useFlags();

  if (transaction.hasMultipleAccountingTransactions)
    return <SplitTransactionsAvatar size={size} {...props} />;

  return merchantDisplayNameEnabled && transaction.merchant?.logoPath ? (
    <MerchantLogo size={size} url={transaction.merchant.logoPath} />
  ) : (
    <MerchantAvatar category={transaction.category} size={size} {...props} />
  );
};
