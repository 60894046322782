import { useMemo } from 'react';
import { add } from 'dinero.js';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  LoaderWithOverlay,
  QuestionIcon,
  Tooltip,
  Typography,
  withDialogWrapper,
} from 'elements';
import {
  CardAccountBalance,
  OrganizationAccountType,
} from 'services/constants';
import { convertDineroToMoney, dineroFromMoney } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
  cardAccountBalance: CardAccountBalance;
}

const BalanceCalculationDialog = ({
  cardAccountBalance: balance,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { cardAccounts },
  } = useGlobalState();
  const cardAccount = cardAccounts.find(
    (ca) => ca.id === balance.cardAccountId
  );
  const isCredit =
    cardAccount?.accountType.value === OrganizationAccountType.credit;
  const effectiveCardAccountBalance = useMemo(() => {
    const sum = [
      balance.cardAccountBalance,
      balance.pendingTransactionsTotalAmount,
      balance.pendingPayoutsTotalAmount,
      balance.pendingTopupsTotalAmount,
    ]
      .map(dineroFromMoney)
      .reduce(add);
    return convertDineroToMoney(sum);
  }, [balance]);

  return (
    <Dialog {...props} PaperProps={{ sx: { position: 'relative' } }}>
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        sx={{ pointerEvents: 'none' }}
        data-intercom-target="balance-calculation-dialog"
      />
      <DialogTitle>{t('balanceCalculationDialog.title')}</DialogTitle>
      <DialogContent sx={{ position: 'relative' }}>
        <Typography variant="body2">
          {t('balanceCalculationDialog.descriptionA')}
          <br />
          <br />
          {t('balanceCalculationDialog.descriptionB')}
          {isCredit && (
            <>
              <br />
              <br />
              {t('balanceCalculationDialog.descriptionC')}
            </>
          )}
        </Typography>
        <Box display="flex" justifyContent="space-between" mt={1} py={2}>
          <Typography variant="body2">
            {t('balanceCalculationDialog.cardAccountBalanceLabel')}
          </Typography>
          <Typography variant="body2">
            <FormatMoney value={balance.cardAccountBalance} />
          </Typography>
        </Box>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          py={2}
          sx={{ color: 'text.secondary' }}
        >
          <Typography variant="body2">
            {t('balanceCalculationDialog.pendingTransactionsTotalAmountLabel')}
            <Tooltip
              title={t(
                'balanceCalculationDialog.pendingTransactionsTotalAmountTooltip'
              )}
            >
              <QuestionIcon
                fontSize="small"
                sx={{ ml: 1, verticalAlign: 'top' }}
              />
            </Tooltip>
          </Typography>
          <Typography variant="body2">
            <FormatMoney value={balance.pendingTransactionsTotalAmount} />
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          py={2}
          sx={{ color: 'text.secondary' }}
        >
          <Typography variant="body2">
            {t('balanceCalculationDialog.pendingPayoutsTotalAmountLabel')}
          </Typography>
          <Typography variant="body2">
            <FormatMoney value={balance.pendingPayoutsTotalAmount} />
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          py={2}
          sx={{ color: 'text.secondary' }}
        >
          <Typography variant="body2">
            {t('balanceCalculationDialog.pendingTopupsTotalAmountLabel')}
          </Typography>
          <Typography variant="body2">
            <FormatMoney value={balance.pendingTopupsTotalAmount} />
          </Typography>
        </Box>
        <Divider />
        {isCredit && (
          <>
            <Box display="flex" justifyContent="space-between" py={2}>
              <Typography variant="body2">
                {t('balanceCalculationDialog.effectiveCardAccountBalanceLabel')}
                <Tooltip
                  title={
                    <Trans
                      i18nKey="balanceCalculationDialog.effectiveCardAccountBalanceTooltip"
                      components={{ br: <br /> }}
                    />
                  }
                >
                  <QuestionIcon
                    fontSize="small"
                    sx={{
                      ml: 1,
                      verticalAlign: 'top',
                      color: 'text.secondary',
                    }}
                  />
                </Tooltip>
              </Typography>
              <Typography variant="body2">
                <FormatMoney value={effectiveCardAccountBalance} />
              </Typography>
            </Box>
            <Divider />
            <Box
              display="flex"
              justifyContent="space-between"
              py={2}
              sx={{ color: 'text.secondary' }}
            >
              <Typography variant="body2">
                {t('balanceCalculationDialog.creditLimitLabel')}
              </Typography>
              <Typography variant="body2">
                <FormatMoney value={balance.creditLimit} />
              </Typography>
            </Box>
            <Divider />
          </>
        )}
        <Box display="flex" justifyContent="space-between" pt={2}>
          <Typography variant="body2">
            {t('balanceCalculationDialog.spendableAmountLabel')}
          </Typography>
          <Typography variant="body2">
            <FormatMoney value={balance.spendableAmount} />
          </Typography>
        </Box>
        <LoaderWithOverlay loading={!balance} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          {t('balanceCalculationDialog.closeBtn')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withDialogWrapper(BalanceCalculationDialog);
