import { useState } from 'react';
import { LoaderWithOverlay } from 'components/Loader';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  FileMagnifyingGlassIcon,
  IconButton,
  Link,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MimeType, OnboardingDocumentFile } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { downloadResponseAsFile, getGenericErrorMsg } from 'services/utils';
import FilePreviewDialog from './FilePreviewDialog';

interface Props {
  documentId: string;
  file: OnboardingDocumentFile;
  internalFilename?: string;
}

const FileRow = ({ documentId, file, internalFilename }: Props) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const handleDownloadFile = async () => {
    try {
      setIsLoading(true);
      const response = await api.downloadOnboardingDocumentFile(
        organization!.id,
        documentId,
        file.id
      );

      if (!mounted) return;
      downloadResponseAsFile(response, internalFilename);
      setIsLoading(false);
    } catch (error) {
      if (!mounted) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const hasPreview = [
    MimeType.jpeg,
    MimeType.pdf,
    MimeType.png,
    MimeType.tiff,
  ].includes(file.mediaType as MimeType);

  return (
    <>
      <Box display="flex">
        {canUser('org-onboarding-document-files:manage') ? (
          <>
            <Link
              component="button"
              disabled={isLoading}
              onClick={handleDownloadFile}
              sx={{
                ...(isLoading && {
                  color: 'grey.500',
                  cursor: 'default',
                }),
              }}
              variant="body2"
            >
              {file.name}
            </Link>

            {!!internalFilename && hasPreview && (
              <IconButton onClick={() => setIsPreviewOpen(true)}>
                <FileMagnifyingGlassIcon />
              </IconButton>
            )}
          </>
        ) : (
          <Typography variant="body2" sx={{ color: 'grey.800' }}>
            {file.name}
          </Typography>
        )}

        {isLoading && (
          <Box ml="8px" position="relative" width="20px">
            <LoaderWithOverlay size={20} thickness={3} />
          </Box>
        )}
      </Box>

      <FilePreviewDialog
        documentId={documentId}
        file={file}
        onClose={() => setIsPreviewOpen(false)}
        open={isPreviewOpen}
      />
    </>
  );
};

export default FileRow;
