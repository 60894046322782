import i18next from 'i18next';
import { EMAIL_MAX_LENGTH } from 'services/constants';
const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email: string) => {
  const trimmedEmail = email.trim();

  const invalidEmailError =
    trimmedEmail.length && !EMAIL_REGEXP.test(trimmedEmail)
      ? i18next.t('errors.invalidEmail')
      : null;
  const emailTooLongError =
    trimmedEmail.length > EMAIL_MAX_LENGTH
      ? i18next.t('errors.stringTooLong', {
          maxLength: EMAIL_MAX_LENGTH,
        })
      : null;

  return {
    invalidEmailError,
    emailTooLongError,
  };
};
