import React, { FC, ReactNode } from 'react';
import { ReactComponent as CardRequestedIcon } from 'assets/icons/appSpecific/cardRequested.svg';
import { ReactComponent as BlockIcon } from 'assets/icons/interface/block.svg';
import { ReactComponent as LockIcon } from 'assets/icons/interface/lock.svg';
import { useCardDesign } from 'context/GlobalState';
import { Card, CardDesignLayout, CardSimpleStatus } from 'services/constants';
import { isCardVoid } from 'services/utils';
import CardTileContainer from './CardTileContainer';
import PartnerVirtualLayout from './layouts/PartnerVirtualLayout';
import PhysicalLayout from './layouts/PhysicalLayout';
import VirtualLayout from './layouts/VirtualLayout';
import { CardTileOverlay, IconHolder, StyledVoidLabel } from './style';

const LAYOUT_COMPONENT_BY_KEY_MAP: {
  [key in CardDesignLayout]: FC<CardTileProps>;
} = {
  [CardDesignLayout.physical]: PhysicalLayout,
  [CardDesignLayout.virtual]: VirtualLayout,
  [CardDesignLayout.partnerVirtual]: PartnerVirtualLayout,
};

export interface CardTileProps {
  card: Partial<Card> &
    Pick<
      Card,
      | 'cardDesignId'
      | 'simpleStatus'
      | 'chFirstName'
      | 'chLastName'
      | 'customFirstName'
      | 'customLastName'
      | 'cardName'
      | 'expiryDate'
    >;
  pan: ReactNode;
  cvv?: ReactNode;
  areDetailsVisible?: boolean;
}

export const CardTile = (props: CardTileProps) => {
  const { card } = props;
  const cardDesign = useCardDesign(card.cardDesignId);
  // Fallback to the physical layout just in case
  const LayoutComponent =
    LAYOUT_COMPONENT_BY_KEY_MAP[cardDesign.layout] || PhysicalLayout;

  return (
    <CardTileContainer cardDesign={cardDesign}>
      <LayoutComponent {...props} />

      {!props.areDetailsVisible && (
        <>
          {card.simpleStatus === CardSimpleStatus.requested && (
            <CardTileOverlay>
              <IconHolder>
                <CardRequestedIcon />
              </IconHolder>
            </CardTileOverlay>
          )}
          {card.simpleStatus === CardSimpleStatus.pending && (
            <CardTileOverlay>
              <IconHolder>
                <BlockIcon />
              </IconHolder>
            </CardTileOverlay>
          )}
          {card.simpleStatus === CardSimpleStatus.locked && (
            <CardTileOverlay>
              <IconHolder>
                <LockIcon />
              </IconHolder>
            </CardTileOverlay>
          )}
          {isCardVoid(card) && (
            <CardTileOverlay>
              <StyledVoidLabel width={88} />
            </CardTileOverlay>
          )}
        </>
      )}
    </CardTileContainer>
  );
};

export { default as CardTileContainer } from './CardTileContainer';
