import { useState } from 'react';
import { FormikErrors, useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  AccountingSystem,
  NetworkErrorCode,
  Supplier,
  UpdateSupplierPayload,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  trimObjValues,
} from 'services/utils';

interface State {
  confirmation: 'deactivate' | 'delete' | null;
  isUpdating: boolean;
}

interface Props extends DialogProps {
  supplier: Supplier | null;
  onClose: () => void;
  onSuccess: () => void;
}

const EditSupplierDialog = ({ supplier, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    confirmation: null,
    isUpdating: false,
  });

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: supplier?.name ?? '',
      accountNumber: supplier?.accountNumber ?? '',
      organizationId: supplier?.organizationId ?? '',
    },
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        const trimmedValues: Omit<UpdateSupplierPayload, 'status'> = {
          ...trimObjValues(values),
        };
        await api.updateSupplier(supplier!.id, {
          ...trimmedValues,
          status: supplier!.status,
        });
        if (!mounted.current) return;
        onSuccess();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (
          getNetworkErrorCode(error) === NetworkErrorCode.supplierNameIsTaken
        ) {
          setFieldError(
            'name',
            t('editSupplierDialog.supplierNameIsTakenError')
          );
          return;
        }
        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.supplierAccountNumberInUse
        ) {
          setFieldError(
            'accountNumber',
            t('editSupplierDialog.accountNumberIsUseError')
          );
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};

      if (
        accountingSettings!.accountingSystem === AccountingSystem.datev &&
        values.name.length > 50
      ) {
        errors.name = t('editSupplierDialog.supplierNameLengthError', {
          limit: 50,
        });
      }

      return errors;
    },
  });

  const changeStatus = async (status: AccountingItemStatus) => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      await api.updateSupplier(supplier!.id, {
        status,
        organizationId: supplier!.organizationId,
        name: supplier!.name,
        accountNumber: supplier!.accountNumber!,
      });
      if (!mounted.current) return;
      onSuccess();
      enqueueSnackbar(
        status === AccountingItemStatus.inactive
          ? t('editSupplierDialog.deactivatedSuccessMessage')
          : t('editSupplierDialog.activatedSuccessMessage'),
        { autoHideDuration: 1500 }
      );
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        confirmation: null,
        isUpdating: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const handleDelete = async () => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      await api.deleteSupplier(supplier!.id);
      if (!mounted.current) return;
      onSuccess();
      enqueueSnackbar(t('editSupplierDialog.deletedSuccessMessage'), {
        autoHideDuration: 1500,
      });
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        confirmation: null,
        isUpdating: false,
      }));
      if (getNetworkErrorCode(error) === NetworkErrorCode.supplierInUse) {
        props.onClose();
        enqueueSnackbar(t('editSupplierDialog.supplierIsInUseError'), {
          variant: 'error',
        });
        return;
      }
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const hasEmptyField = !formik.values.name.trim();

  if (state.confirmation === 'deactivate') {
    return (
      <ConfirmDialog
        {...props}
        title={t('editSupplierDialog.deactivateStep.title')}
        description={
          <Trans
            i18nKey="editSupplierDialog.deactivateStep.description"
            components={{ br: <br /> }}
          />
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          color: 'error',
          children: t('editSupplierDialog.deactivateStep.deactivate'),
        }}
        onSuccess={() => changeStatus(AccountingItemStatus.inactive)}
        onClose={() =>
          setState((prevState) => ({ ...prevState, confirmation: null }))
        }
      />
    );
  }

  if (state.confirmation === 'delete') {
    return (
      <ConfirmDialog
        {...props}
        title={t('editSupplierDialog.deleteStep.title')}
        description={
          <Trans
            i18nKey="editSupplierDialog.deleteStep.description"
            components={{ br: <br /> }}
          />
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          color: 'error',
          children: t('editSupplierDialog.deleteStep.delete'),
        }}
        onSuccess={handleDelete}
        onClose={() =>
          setState((prevState) => ({ ...prevState, confirmation: null }))
        }
      />
    );
  }

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle display="flex" alignItems="center">
        {t('editSupplierDialog.title')}
        {supplier?.status === AccountingItemStatus.inactive && (
          <Box ml={1} fontSize={0}>
            <Chip
              size="small"
              label={t('editSupplierDialog.inactiveBadge')}
              variant="outlined"
            />
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} noValidate id="edit-supplier-form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t('editSupplierDialog.nameTitle')}
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue(e.target.name, e.target.value, false);
                  formik.setFieldError(e.target.name, undefined);
                }}
                disabled={formik.isSubmitting}
                error={!!formik.errors.name}
                helperText={formik.errors.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('editSupplierDialog.accountNumberTitle')}
                name="accountNumber"
                value={formik.values.accountNumber}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue(e.target.name, e.target.value, false);
                  formik.setFieldError(e.target.name, undefined);
                }}
                disabled={formik.isSubmitting}
                error={!!formik.errors.accountNumber}
                helperText={
                  formik.errors.accountNumber && formik.errors.accountNumber
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {supplier?.status === AccountingItemStatus.active ? (
          <>
            <Box mr="auto">
              <Button
                disabled={formik.isSubmitting || state.isUpdating}
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    confirmation: 'deactivate',
                  }));
                }}
                variant="text"
                color="error"
              >
                {t('editSupplierDialog.deactivate')}
              </Button>
            </Box>
            <Button onClick={props.onClose} variant="text">
              {t('common.button.cancel')}
            </Button>
            <Button
              disabled={
                hasEmptyField ||
                formik.isSubmitting ||
                state.isUpdating ||
                !formik.dirty
              }
              form="edit-supplier-form"
              type="submit"
            >
              {t('editSupplierDialog.save')}
            </Button>
          </>
        ) : (
          <>
            {canUser('supplier:delete') && (
              <Box mr="auto">
                <Button
                  disabled={formik.isSubmitting || state.isUpdating}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      confirmation: 'delete',
                    }))
                  }
                  variant="text"
                  color="error"
                >
                  {t('editSupplierDialog.delete')}
                </Button>
              </Box>
            )}
            <Button onClick={props.onClose} variant="text">
              {t('common.button.cancel')}
            </Button>
            {formik.dirty ? (
              <Button
                disabled={
                  hasEmptyField || formik.isSubmitting || state.isUpdating
                }
                form="edit-supplier-form"
                type="submit"
              >
                {t('editSupplierDialog.save')}
              </Button>
            ) : (
              <Button
                disabled={formik.isSubmitting || state.isUpdating}
                onClick={() => changeStatus(AccountingItemStatus.active)}
              >
                {t('editSupplierDialog.activate')}
              </Button>
            )}
          </>
        )}
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting || state.isUpdating} />
    </Dialog>
  );
};

export default withDialogWrapper(EditSupplierDialog);
