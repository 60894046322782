import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Dropzone,
  FileRejection,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  LatestXslxUploadResponse,
  XlsxUploadSuccessStatus,
  XlsxValidationErrors,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import UploadingStatus from './UploadingStatus';
import ValidatingStatus from './ValidatingStatus';

interface Props extends DialogProps {
  onClose: () => void;
  context: 'members' | 'cards' | 'teams';
}

interface State {
  isLoadingLatestStatus: boolean;
  latestUploadStatus: LatestXslxUploadResponse | null;
  isValidating: boolean;
  validationErrors: XlsxValidationErrors | null;
  file: File | null;
  isUploading: boolean;
}

const BulkUploadItemsDialog = (props: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, defaultCardAccount },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoadingLatestStatus: false,
    latestUploadStatus: null,
    isValidating: false,
    validationErrors: {},
    file: null,
    isUploading: false,
  });

  useEffect(() => {
    getLatestBulkUploadStatus();
  }, []);

  const getLatestBulkUploadStatus = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoadingLatestStatus: true }));
      const latestUploadStatus = await api.getLatestBulkUploadStatus(
        props.context,
        organization!.id,
        defaultCardAccount!.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoadingLatestStatus: false,
        latestUploadStatus,
        file: null,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoadingLatestStatus: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    const [file] = acceptedFiles;
    validateXlsxFile(file);
  }, []);

  const validateXlsxFile = async (file: File) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isValidating: true,
        validationErrors: null,
      }));
      const response = await api.validateXlsxFile(
        props.context,
        organization!.id,
        defaultCardAccount!.id,
        file
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isValidating: false,
        file: isEmpty(response.validationErrors) ? file : null,
        validationErrors: response.validationErrors,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isValidating: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const uploadItems = async () => {
    setState((prevState) => ({ ...prevState, isUploading: true }));
    try {
      await api.uploadItems(
        props.context,
        organization!.id,
        defaultCardAccount!.id,
        state.file as File
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isUploading: false,
      }));
      getLatestBulkUploadStatus();
      enqueueSnackbar(t('int.bulkUploadItemsDialog.uploadSuccess'), {
        variant: 'success',
      });
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isUploading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      if (
        fileRejections.length === 1 &&
        fileRejections[0].errors.length === 1 &&
        fileRejections[0].errors[0].code === 'file-too-large'
      ) {
        enqueueSnackbar(t('bulkUploadDropzone.fileIsTooBigErrorMessage'), {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>
        {t('int.bulkUploadItemsDialog.title', { items: props.context })}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={2}>
          <Trans
            i18nKey="int.bulkUploadItemsDialog.description"
            components={{
              fileLink: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="https://docs.google.com/spreadsheets/d/1uvAAJOgl1DtZBY9Gd7PgQDhBqRmugUpxkCA9VHK-nXc"
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
            values={{
              items: props.context,
            }}
          />
        </Typography>

        <ValidatingStatus
          isValidating={state.isValidating}
          validationErrors={state.validationErrors}
        />

        {!state.isUploading &&
          !state.isValidating &&
          state.latestUploadStatus?.status !==
            XlsxUploadSuccessStatus.inProgress && (
            <Dropzone
              file={state.file}
              dataTestId="bulk-upload-input"
              disabled={!!state.file}
              onDropAccepted={onDropAccepted}
              onDropRejected={onDropRejected}
              multiple={false}
              accept={[
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel',
              ]}
              maxSize={1000000}
              dropzoneIdleProps={{
                description: t('bulkUploadDropzone.uploadFile'),
                secondaryDescription: t('bulkUploadDropzone.dropzoneLabel', {
                  format: 'XSLX',
                }),
              }}
            />
          )}

        {state.isUploading && (
          <Box display="flex" justifyContent="center" marginTop={2}>
            <CircularProgress />
          </Box>
        )}

        {!state.isUploading &&
          !state.isValidating &&
          !!state.latestUploadStatus?.id && (
            <UploadingStatus
              isLoadingLatestStatus={state.isLoadingLatestStatus}
              latestUploadStatus={state.latestUploadStatus}
              updateStatus={getLatestBulkUploadStatus}
            />
          )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button onClick={uploadItems} disabled={!state.file}>
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withDialogWrapper(BulkUploadItemsDialog);
