import React, { MouseEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LegitimationStatusBadge } from 'components/Badge';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  IconButton,
  Link,
  LoaderWithOverlay,
  Menu,
  MenuItem,
  XCircleIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountGroup,
  IdentificationType,
  LegitimationStatus,
  OrganizationRepresentative,
  RelatedPerson,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { downloadFileUsingAnchorTag, getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  isDownloadingFile: boolean;
  isCancelLegitimationRequestDialogOpen: boolean;
  menuAnchorEl: HTMLElement | null;
  requestedLegitimationType: IdentificationType | null;
}

type PropsOrganizationRepresentative = {
  isDisabled?: boolean;
  version: 'orgRep';
  orgRep: OrganizationRepresentative;
  onUpdate: (representative: OrganizationRepresentative) => void;
};

type PropsRelatedPerson = {
  isDisabled?: boolean;
  version: 'relatedPerson';
  orgRep: RelatedPerson;
  onUpdate: (representative: OrganizationRepresentative) => void;
};

export function LegitimationRequestButton(
  props: PropsOrganizationRepresentative
): React.ReactElement;
export function LegitimationRequestButton(
  props: PropsRelatedPerson
): React.ReactElement;

export function LegitimationRequestButton(
  props: PropsOrganizationRepresentative | PropsRelatedPerson
) {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const canUser = useCanUser();
  const {
    state: { organization },
  } = useGlobalState();

  // Todo: refactor once orgRep OrganizationRepresentative type is removed
  const availableIdentificationTypes =
    props.version === 'orgRep'
      ? [AccountGroup.vgTpml, AccountGroup.vgPliantOy].includes(
          organization!.defaultAccountGroup
        )
        ? [
            {
              name: IdentificationType.IDNOW_VG_VIDEOIDENT,
              default: true,
            },
          ]
        : [
            {
              name: IdentificationType.IDNOW_PLIANT_OY_AUTOIDENT,
              default: true,
            },
            {
              name: IdentificationType.IDNOW_PLIANT_OY_VIDEOIDENT,
              default: false,
            },
          ]
      : props.orgRep.availableIdentificationTypes;
  const identificationArchiveDownloaded =
    props.version === 'orgRep'
      ? props.orgRep.identificationArchiveDownloaded
      : props.orgRep.identificationArchiveDownloaded.value;
  const legitimationStatus =
    props.version === 'orgRep'
      ? props.orgRep.legitimationStatus
      : props.orgRep.legitimationStatus.value;
  const canStartIdentification = props.orgRep.canStartIdentification;
  const email =
    props.version === 'orgRep' ? props.orgRep.email : props.orgRep.email.value;
  const givenName =
    props.version === 'orgRep'
      ? props.orgRep.givenName
      : props.orgRep.givenName.value;
  const surName =
    props.version === 'orgRep'
      ? props.orgRep.surName
      : props.orgRep.surName.value;
  const orgRepId =
    props.version === 'orgRep' ? props.orgRep.id : props.orgRep.id.value;

  const [state, setState] = useState<State>({
    isLoading: false,
    isDownloadingFile: false,
    isCancelLegitimationRequestDialogOpen: false,
    menuAnchorEl: null,
    requestedLegitimationType: null,
  });

  const requestLegitimation = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      await api.createRepresentativLegitimationRequest(
        orgRepId,
        state.requestedLegitimationType!
      );
      const updatedOrgReps = await api.getOrganizationRepresentatives(
        organization!.id
      );

      if (!mounted.current) return;
      props.onUpdate(updatedOrgReps.find((item) => item.id === orgRepId)!);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        requestedLegitimationType: null,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const cancelLegitimationRequest = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      await api.deleteRepresentativLegitimationRequest(orgRepId);
      const updatedOrgReps = await api.getOrganizationRepresentatives(
        organization!.id
      );

      if (!mounted.current) return;
      props.onUpdate(updatedOrgReps.find((item) => item.id === orgRepId)!);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isCancelLegitimationRequestDialogOpen: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const downloadLegitimationResults = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isDownloadingFile: true,
      }));
      const { data } = await api.downloadLegalRepIdentificationArchive(
        organization!.id,
        orgRepId
      );
      if (!mounted) return;
      downloadFileUsingAnchorTag(
        `${surName}_${givenName}.zip`,
        'data:application/pdf;base64,' + data
      );
      setState((prevState) => ({
        ...prevState,
        isDownloadingFile: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isDownloadingFile: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        height="52px"
        mr="16px"
        position="relative"
      >
        {canUser('legal-rep-identification-results:download') &&
        LegitimationStatus.COMPLETED === legitimationStatus &&
        identificationArchiveDownloaded ? (
          <>
            <Link
              component="button"
              disabled={state.isDownloadingFile}
              onClick={(e) => {
                e.stopPropagation();
                downloadLegitimationResults();
              }}
              sx={{
                ...(state.isDownloadingFile && {
                  color: 'grey.500',
                  cursor: 'default',
                }),
              }}
              variant="body2"
            >
              {t(
                'int.organizationRepresentativeSection.legitimationRequestButton.downloadVideoIdentification'
              )}
            </Link>

            <LoaderWithOverlay loading={state.isDownloadingFile} />
          </>
        ) : (
          [
            LegitimationStatus.COMPLETED,
            LegitimationStatus.FRAUD,
            LegitimationStatus.ONGOING,
          ].includes(legitimationStatus) && (
            <>
              <LegitimationStatusBadge status={legitimationStatus} />

              {LegitimationStatus.ONGOING === legitimationStatus && (
                <IconButton
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setState((prevState) => ({
                      ...prevState,
                      isCancelLegitimationRequestDialogOpen: true,
                    }));
                  }}
                >
                  <XCircleIcon fontSize="small" />
                </IconButton>
              )}
            </>
          )
        )}

        {canStartIdentification &&
          legitimationStatus === LegitimationStatus.NOT_REQUESTED && (
            <>
              <Button
                disabled={props.isDisabled}
                variant="outlined"
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  if (availableIdentificationTypes.length > 1) {
                    setState((prevState) => ({
                      ...prevState,
                      menuAnchorEl: e.currentTarget,
                    }));
                  } else {
                    setState((prevState) => ({
                      ...prevState,
                      requestedLegitimationType:
                        availableIdentificationTypes[0].name,
                    }));
                  }
                }}
              >
                {t(
                  'int.organizationRepresentativeSection.legitimationRequestButton.requestLegitimationButton'
                )}
              </Button>

              <Menu
                anchorEl={state.menuAnchorEl}
                open={!!state.menuAnchorEl}
                onClose={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  setState((prevState) => ({
                    ...prevState,
                    menuAnchorEl: null,
                  }));
                }}
              >
                {availableIdentificationTypes.map((item) => (
                  <MenuItem
                    key={item.name}
                    onClick={(e) => {
                      e.stopPropagation();
                      setState((prevState) => ({
                        ...prevState,
                        menuAnchorEl: null,
                        requestedLegitimationType: item.name,
                      }));
                    }}
                  >
                    {t(
                      `int.organizationRepresentativeSection.legitimationRequestButton.identType.${item.name}`
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
      </Box>

      <ConfirmDialog
        open={!!state.requestedLegitimationType}
        loading={state.isLoading}
        title={t(
          'int.organizationRepresentativeSection.legitimationRequestButton.requestConfirmDialogTitle'
        )}
        description={
          <Trans
            i18nKey="int.organizationRepresentativeSection.legitimationRequestButton.requestConfirmDialogDescription"
            values={{
              email: email,
              identType: t(
                `int.organizationRepresentativeSection.legitimationRequestButton.identType.${state.requestedLegitimationType}`
              ),
              name: `${givenName} ${surName}`,
            }}
            components={{ b: <b /> }}
          />
        }
        onSuccess={(e) => {
          e.stopPropagation();
          requestLegitimation();
        }}
        onClose={(e) => {
          e.stopPropagation();
          setState((prevState) => ({
            ...prevState,
            requestedLegitimationType: null,
          }));
        }}
      />

      <ConfirmDialog
        open={state.isCancelLegitimationRequestDialogOpen}
        loading={state.isLoading}
        title={t(
          'int.organizationRepresentativeSection.legitimationRequestButton.cancelOpenRequestConfirmDialogTitle'
        )}
        description={
          <Trans
            i18nKey="int.organizationRepresentativeSection.legitimationRequestButton.cancelOpenRequestConfirmDialogDescription"
            values={{
              email: email,
              name: `${givenName} ${surName}`,
            }}
            components={{ b: <b /> }}
          />
        }
        onSuccess={(e) => {
          e.stopPropagation();
          cancelLegitimationRequest();
        }}
        onClose={(e) => {
          e.stopPropagation();
          setState((prevState) => ({
            ...prevState,
            isCancelLegitimationRequestDialogOpen: false,
          }));
        }}
      />
    </>
  );
}
