import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  Box,
  Button,
  LoaderWithOverlay,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { BankSubmission, BankSubmissionStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import BankSubmissionStatusBadge from './BankSubmissionStatusBadge';
import ReviewDialog from './ReviewDialog';
import SubmitChecklistTooltip from './SubmitChecklistTooltip';

type State = {
  isLoading: boolean;
  isReviewDialogOpen: boolean;
  submissionInReviewDialog: BankSubmission | null;
};

const BankSubmissionSubPage = () => {
  const mounted = useMounted();
  const api = useImperativeApi();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const { user } = useAuth0();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    actions: { fetchBankSubmissions },
    state: { bankSubmissions },
  } = useCreditAndComplianceContext();

  const [state, setState] = useState<State>({
    isLoading: false,
    isReviewDialogOpen: false,
    submissionInReviewDialog: null,
  });

  useEffect(() => {
    (async () => {
      try {
        await fetchBankSubmissions();
        if (!mounted.current) return;
        setState((prevState) => ({ ...prevState, isLoading: false }));
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        setState((prevState) => ({ ...prevState, isLoading: false }));
        logError(error);
      }
    })();
  }, [mounted.current]);

  const hasOngoingSubmission = !!bankSubmissions.find((item) =>
    [BankSubmissionStatus.draft, BankSubmissionStatus.pendingApproval].includes(
      item.status
    )
  );

  const onCreateNewSubmission = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      await api.createBankSubmission(organization!.id);
      await fetchBankSubmissions();

      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {t('int.bankSubmissionSubPage.createdAtColumn')}
            </TableCell>
            <TableCell>
              {t('int.bankSubmissionSubPage.firstApprovalColumn')}
            </TableCell>
            <TableCell>
              {t('int.bankSubmissionSubPage.secondApprovalColumn')}
            </TableCell>
            <TableCell />
            <TableCell>{t('int.bankSubmissionSubPage.statusColumn')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bankSubmissions.map((item) => (
            <TableRow
              key={item.id}
              sx={{ '& > td': { borderBottom: 'unset' } }}
            >
              <TableCell>
                <Typography variant="body2">
                  {moment(item.createdAt).format('D MMM YYYY')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {item.approvalRequestedAt &&
                    moment(item.approvalRequestedAt).format(
                      'DD MMM YYYY HH:mm'
                    )}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {item.approvalRequestedBy}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {item.secondReviewAt &&
                    moment(item.secondReviewAt).format('DD MMM YYYY HH:mm')}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {item.secondReviewBy}
                </Typography>
              </TableCell>
              <TableCell width="168px">
                {item.status === BankSubmissionStatus.draft ||
                (item.status === BankSubmissionStatus.pendingApproval &&
                  item.approvalRequestedBy !== user!.email) ? (
                  <Box display="flex" alignItems="center">
                    <Button
                      disabled={
                        !item.isReadyForReview ||
                        !canUser('varengold-submission:create-or-submit')
                      }
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          isReviewDialogOpen: true,
                          submissionInReviewDialog: item,
                        }));
                      }}
                      size="small"
                    >
                      {t('int.bankSubmissionSubPage.approveButton')}
                    </Button>
                    {!item.isReadyForReview && (
                      <SubmitChecklistTooltip
                        isReadyForReview={item.isReadyForReview}
                      />
                    )}
                  </Box>
                ) : (
                  <Button
                    disabled={!canUser('varengold-submission:create-or-submit')}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isReviewDialogOpen: true,
                        submissionInReviewDialog: item,
                      }));
                    }}
                    size="small"
                    variant="outlined"
                  >
                    {t('int.bankSubmissionSubPage.viewButton')}
                  </Button>
                )}
              </TableCell>
              <TableCell>
                <div>
                  <BankSubmissionStatusBadge status={item.status} />
                </div>
                {item.organizationActivationDate && (
                  <Typography color="textSecondary" variant="caption">
                    {t('int.bankSubmissionSubPage.activatedLabel', {
                      date: moment(item.organizationActivationDate).format(
                        'DD.MM.YYYY'
                      ),
                    })}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {bankSubmissions.length > -1 && (
        <Box
          alignItems="center"
          display="flex"
          height="320px"
          justifyContent="center"
        >
          <Button
            disabled={
              state.isLoading ||
              !canUser('varengold-submission:create-or-submit') ||
              hasOngoingSubmission
            }
            onClick={onCreateNewSubmission}
          >
            {t('int.bankSubmissionSubPage.createSubmissionButton')}
          </Button>
        </Box>
      )}

      <ReviewDialog
        open={state.isReviewDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isReviewDialogOpen: false,
          }))
        }
        submission={state.submissionInReviewDialog!}
      />

      <LoaderWithOverlay loading={state.isLoading} />
    </>
  );
};

export default BankSubmissionSubPage;
