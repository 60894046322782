import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { TeamBadge } from 'domains/member/components';
import { AccountingAutocomplete } from 'domains/transaction/components';
import { isTransactionReadonly } from 'domains/transaction/utils';
import { Box, Typography } from 'elements';
import useIsAdminApp from 'hooks/useIsAdminApp';
import { MinimalTeam, TeamStatus, Transaction } from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props {
  teams: MinimalTeam[];
  isExportPage: boolean;
  teamId: string | null;
  teamName: string | null;
  onChange: (team: MinimalTeam | null) => void;
  transaction: Transaction;
  disabled: boolean;
}

const TeamsSelect = ({
  teams,
  isExportPage,
  teamId,
  teamName,
  onChange,
  transaction,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const isAdminApp = useIsAdminApp();
  const {
    state: { teamCount, accountingSettings, featureModules },
  } = useGlobalState();
  const [inputValue, setInputValue] = useState('');

  const selectedTeam = useMemo(() => teams.find((item) => item.id === teamId), [
    teams,
    teamId,
  ]);

  useEffect(() => {
    if (selectedTeam?.name !== inputValue)
      setInputValue(selectedTeam?.name || '');
  }, [selectedTeam?.name]);

  const renderSelectedValue = () => {
    if (!value) return '—';
    if (isExportPage) {
      return (
        <Box overflow="hidden" pl={2}>
          <Typography variant="body2" component="div" noWrap>
            {selectedTeam?.name || '—'}
          </Typography>
          {accountingSettings?.costCenterEnabled && selectedTeam?.costCenter && (
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              noWrap
            >
              {selectedTeam.costCenter}
            </Typography>
          )}
        </Box>
      );
    }
    if (teamId && teamName)
      return <TeamBadge key={teamId} name={teamName} sx={{ mr: 0 }} />;
    return '—';
  };

  if (
    !accountingSettings?.costCenterEnabled ||
    !featureModules.TEAMS ||
    teamCount === 0
  )
    return null;

  const isDisabled =
    disabled ||
    !canUser('transaction-team:change') ||
    isTransactionReadonly(transaction, isAdminApp);

  const value = selectedTeam || null;

  return (
    <AccountingAutocomplete<MinimalTeam>
      label={
        <>
          <Typography variant="body2" noWrap>
            {t('transactionDetails.team')}
          </Typography>
          {isExportPage && accountingSettings?.costCenterEnabled && (
            <Typography component="div" variant="caption" color="textSecondary">
              {t('transactionDetails.costCenter')}
            </Typography>
          )}
        </>
      }
      data-test-id="teams-autocomplete"
      selectedValueComponent={renderSelectedValue()}
      disabled={isDisabled}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      value={value}
      defaultShownValue={teamName ? <TeamBadge name={teamName} /> : '—'}
      options={teams}
      onChange={(_, option) => {
        if (!option) setInputValue('');
        onChange(option as MinimalTeam);
      }}
      onClear={() => {
        setInputValue('');
        if (value) onChange(null);
      }}
      getOptionLabel={(option: MinimalTeam) => option.name || ''}
      filterOptions={(options, { inputValue }) =>
        options.filter((option) => {
          const name = option.name.toLowerCase();
          const costCenter = isExportPage
            ? option.costCenter?.toLowerCase()
            : '';
          const val = inputValue?.toLowerCase();
          return name.includes(val) || costCenter?.includes(val);
        })
      }
      getOptionDisabled={(option: MinimalTeam) =>
        option.teamStatus === TeamStatus.deactivated
      }
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Typography noWrap>
            {isExportPage &&
            option.costCenter &&
            accountingSettings?.costCenterEnabled
              ? `${option.name} (${option.costCenter})`
              : option.name}
          </Typography>
        </li>
      )}
    />
  );
};

export default TeamsSelect;
