import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { getGenericErrorMsg } from 'services/utils';

// codat pagination starts from 1
const FIRST_PAGINATION_INDEX = 1;

interface State<T> {
  inputValue: string;
  items: T[];
  isOpen: boolean;
  isLoading: boolean;
}

interface Props<T> {
  value: T | null;
  label: React.ReactNode;
  onChange: (value: T | null) => void;
  getPageableList(params: {
    q: string;
    page: number;
    limit: number;
  }): Promise<{ items: T[]; hasNextPage: boolean }>;
  placeholder: string;
}

const CodatEntitySearchSelect = <T extends { id: string; name: string }>({
  value,
  label,
  onChange,
  getPageableList,
  placeholder,
}: Props<T>) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State<T>>({
    inputValue: '',
    items: [],
    isOpen: false,
    isLoading: true,
  });

  const getEntities = async () => {
    try {
      const { items } = await getPageableList({
        q: state.inputValue.trim(),
        page: FIRST_PAGINATION_INDEX,
        limit: DEFAULT_PAGE_LIMIT,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        items,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    let active = true;

    setState((prevState) => ({ ...prevState, isLoading: true }));

    setTimeout(() => {
      if (active) getEntities();
    }, DEFAULT_AUTOCOMPLETE_DELAY);

    return () => {
      active = false;
    };
  }, [state.inputValue.trim()]);

  return (
    <Autocomplete<T, false, false, false>
      searchIcon
      label={label}
      placeholder={placeholder}
      loadingText={t('datevExportSettingsGroup.codatEntrySelectLoadingText')}
      noOptionsText={t(
        'datevExportSettingsGroup.codatEntrySelectNoOptionsText'
      )}
      options={state.items}
      loading={state.isLoading}
      value={value}
      inputValue={state.inputValue}
      onInputChange={(e, inputValue) =>
        setState((prevState) => ({
          ...prevState,
          inputValue,
        }))
      }
      onChange={(e, value) => onChange(value)}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option) => option.id === value?.id}
      getOptionLabel={(option) => option.name}
    />
  );
};

export default CodatEntitySearchSelect;
