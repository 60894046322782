import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCardConfigSetting, useGlobalState } from 'context/GlobalState';
import {
  ChangeCardNameDialog,
  ChangeCardPurposeDialog,
  EditCardControlsDialog,
  EditCardCustomFieldsDialog,
  EditCardProjectDialog,
  EditCardTeamDialog,
  EditCardValidityPeriodDialog,
  LimitChangeRequestDialog,
  ReplaceCardDialog,
  TerminateCardDialog,
  ToggleCardSyncInCirculaDialog,
  UpdateCardVelocityGroupDialog,
} from 'domains/card/dialogs';
import { IntegrationTypeIcon } from 'domains/partner/components';
import { useProjectsPerTeam } from 'domains/settings/utils';
import { isTxCustomFieldVisible } from 'domains/transaction/utils';
import {
  ArrowLineUpIcon,
  ArrowsClockwiseIcon,
  CalendarBlankIcon,
  FadersHorizontalIcon,
  GearIcon,
  GearSixIcon,
  IconButton,
  ListItemIcon,
  LockIcon,
  LockOpenIcon,
  MenuContainer,
  MenuItem,
  PencilSimpleIcon,
  ProjectorScreenIcon,
  SwapIcon,
  Tooltip,
  UsersIcon,
  VerticalCardsIcon,
  XCircleIcon,
} from 'elements';
import useCardLock from 'hooks/useCardLock';
import useCurrentApp from 'hooks/useCurrentApp';
import {
  AutomationType,
  Card,
  CardSimpleStatus,
  CardType,
  CirculaConnectionType,
  CustomFieldStatus,
  IntegrationType,
  Team,
} from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props {
  card: Card;
  onUpdate: (card: Card) => void;
  onCardReplace?: (newCard: Card) => void;
  managerTeam?: Team;
  isCardholderApp?: boolean;
}

const CardDetailsMenu = ({
  card,
  onUpdate,
  onCardReplace,
  isCardholderApp,
  managerTeam,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: {
      organizationIntegrations,
      accountingSettings,
      featureModules,
      transactionCustomFields,
    },
  } = useGlobalState();
  const cardConfigSetting = useCardConfigSetting(card.cardConfig);
  const [isChangeCardNameDialogOpen, setIsChangeCardNameDialogOpen] = useState(
    false
  );
  const [
    isChangeCardPurposeDialogOpen,
    setIsChangeCardPurposeDialogOpen,
  ] = useState(false);
  const [
    isEditCardControlsDialogOpen,
    setIsEditCardControlsDialogOpen,
  ] = useState(false);
  const [
    isLimitChangeRequestDialogOpen,
    setIsLimitChangeRequestDialogOpen,
  ] = useState(false);
  const [
    isEditCardValidityPeriodDialogOpen,
    setIsEditCardValidityPeriodDialogOpen,
  ] = useState(false);
  const [isEditCardTeamDialogOpen, setIsEditCardTeamDialogOpen] = useState(
    false
  );
  const [
    isEditCardProjectDialogOpen,
    setIsEditCardProjectDialogOpen,
  ] = useState(false);
  const [isReplaceCardDialogOpen, setIsReplaceCardDialogOpen] = useState(false);
  const [isTerminateCardDialogOpen, setIsTerminateCardDialogOpen] = useState(
    false
  );
  const [
    toggleCirculaIntegrationDialogOpen,
    setToggleCirculaIntegrationDialogOpen,
  ] = useState(false);
  const [isManagingLimitDialogOpen, setIsManagingLimitDialogOpen] = useState(
    false
  );
  const [
    isEditCustomFieldsDialogOpen,
    setIsEditCustomFieldsDialogOpen,
  ] = useState(false);
  const { lockCard, unlockCard, isCardLocked } = useCardLock(onUpdate);
  const { isAdminApp } = useCurrentApp();
  const { visibleProjects } = useProjectsPerTeam(card.projectId, card.teamId);
  const shownTxCustomFields = useMemo(
    () =>
      transactionCustomFields.filter(
        (field) =>
          field.status === CustomFieldStatus.active &&
          field.automationType === AutomationType.card &&
          isTxCustomFieldVisible(field, isAdminApp)
      ),
    [transactionCustomFields]
  );

  const isRequestLimitChangeVisible =
    isCardholderApp &&
    featureModules.CARD_REQUESTS &&
    canUser('card-limit:create-change-request', card);
  const isEditCardValidityPeriodVisible =
    !isCardholderApp &&
    canUser('card-validity-period:change', card, cardConfigSetting);
  const isChangeTeamVisible =
    !isCardholderApp &&
    featureModules.TEAMS &&
    canUser('card-team:change', card);
  const isChangeProjectVisible =
    !isCardholderApp &&
    canUser('card-project:change', card, accountingSettings, visibleProjects) &&
    card.simpleStatus !== CardSimpleStatus.terminated;
  const isToggleCirculaSyncBtnVisible =
    !isCardholderApp &&
    organizationIntegrations?.syncSettings?.orgConnectionType ===
      CirculaConnectionType.partial &&
    canUser('card:connect-to-circula', card);
  const isEditControlsVisible =
    !isCardholderApp &&
    featureModules.CARD_CONTROLS &&
    !!cardConfigSetting &&
    canUser('card-controls:change', card, managerTeam);
  const isCustomFieldBtnVisible =
    canUser('custom-fields:view') &&
    canUser('custom-fields-card:assign') &&
    !!shownTxCustomFields.length &&
    featureModules.TRANSACTION_CUSTOM_FIELDS &&
    card.simpleStatus !== CardSimpleStatus.terminated;

  const isMenuVisible =
    (!isCardholderApp && canUser('card:lock-unlock', card, managerTeam)) ||
    isRequestLimitChangeVisible ||
    isEditCardValidityPeriodVisible ||
    isChangeTeamVisible ||
    isChangeProjectVisible ||
    canUser('card-name:change', card, managerTeam) ||
    canUser('card-purpose:change', card) ||
    isEditControlsVisible ||
    isToggleCirculaSyncBtnVisible ||
    canUser('card:replace', card, managerTeam) ||
    canUser('card:terminate', card, managerTeam) ||
    canUser('card-velocity:change', card) ||
    isCustomFieldBtnVisible;

  if (!isMenuVisible) return null;

  return (
    <>
      <MenuContainer
        button={
          <IconButton
            data-test-id="open-card-menu-btn"
            data-intercom-target="card-details-menu-button"
            sx={{ ml: 1 }}
          >
            {isCardholderApp ? <GearSixIcon /> : <FadersHorizontalIcon />}
          </IconButton>
        }
      >
        {!isCardholderApp && canUser('card:lock-unlock', card, managerTeam) && (
          <Tooltip title={t('cardDetails.lockCardHint')}>
            <MenuItem
              onClick={() =>
                isCardLocked(card)
                  ? unlockCard(card.cardId)
                  : lockCard(card.cardId)
              }
            >
              <ListItemIcon>
                {isCardLocked(card) ? <LockOpenIcon /> : <LockIcon />}
              </ListItemIcon>
              {isCardLocked(card)
                ? t('cardDetails.unlockCard')
                : t('cardDetails.lockCard')}
            </MenuItem>
          </Tooltip>
        )}
        {isRequestLimitChangeVisible && (
          <MenuItem
            onClick={() => setIsLimitChangeRequestDialogOpen(true)}
            data-intercom-target="limit-change-request-menu-item"
          >
            <ListItemIcon>
              <ArrowsClockwiseIcon />
            </ListItemIcon>
            {t('cardDetails.limitChangeRequest')}
          </MenuItem>
        )}
        {isEditCardValidityPeriodVisible && (
          <MenuItem onClick={() => setIsEditCardValidityPeriodDialogOpen(true)}>
            <ListItemIcon>
              <CalendarBlankIcon />
            </ListItemIcon>
            {t('cardDetails.editValidityPeriod')}
          </MenuItem>
        )}
        {isChangeTeamVisible && (
          <MenuItem onClick={() => setIsEditCardTeamDialogOpen(true)}>
            <ListItemIcon>
              <UsersIcon />
            </ListItemIcon>
            {t('cardDetails.editTeamAssignment')}
          </MenuItem>
        )}
        {isChangeProjectVisible && (
          <MenuItem onClick={() => setIsEditCardProjectDialogOpen(true)}>
            <ListItemIcon>
              <ProjectorScreenIcon />
            </ListItemIcon>
            {t('cardDetails.editProjectAssignment')}
          </MenuItem>
        )}
        {isCustomFieldBtnVisible && (
          <MenuItem onClick={() => setIsEditCustomFieldsDialogOpen(true)}>
            <ListItemIcon>
              <PencilSimpleIcon />
            </ListItemIcon>
            {t('cardDetails.editCustomFields')}
          </MenuItem>
        )}
        {canUser('card-name:change', card, managerTeam) && (
          <MenuItem onClick={() => setIsChangeCardNameDialogOpen(true)}>
            <ListItemIcon>
              <VerticalCardsIcon />
            </ListItemIcon>
            {!!cardConfigSetting && cardConfigSetting.cardDesignIds.length > 1
              ? t('cardDetails.changeCardLabelAndColor')
              : t('cardDetails.changeCardName')}
          </MenuItem>
        )}
        {canUser('card-purpose:change', card) &&
          card.type === CardType.singleUse && (
            <MenuItem onClick={() => setIsChangeCardPurposeDialogOpen(true)}>
              <ListItemIcon>
                <VerticalCardsIcon />
              </ListItemIcon>
              {!!cardConfigSetting && cardConfigSetting.cardDesignIds.length > 1
                ? t('cardDetails.changeCardPurposeAndColor')
                : t('cardDetails.changeCardPurpose')}
            </MenuItem>
          )}
        {isEditControlsVisible && (
          <MenuItem onClick={() => setIsEditCardControlsDialogOpen(true)}>
            <ListItemIcon>
              <GearIcon />
            </ListItemIcon>
            {t('cardDetails.editCardControls')}
          </MenuItem>
        )}
        {isToggleCirculaSyncBtnVisible && (
          <MenuItem onClick={() => setToggleCirculaIntegrationDialogOpen(true)}>
            <ListItemIcon>
              <IntegrationTypeIcon
                integrationType={IntegrationType.circula}
                sx={{ width: 16, height: 16, color: 'inherit' }}
              />
            </ListItemIcon>
            {card.integrationType === IntegrationType.circula
              ? t('cardDetails.disableCirculaSync')
              : t('cardDetails.enableCirculaSync')}
          </MenuItem>
        )}
        {canUser('card:replace', card, managerTeam) && (
          <MenuItem
            onClick={() => setIsReplaceCardDialogOpen(true)}
            data-test-id="replace-card-btn"
          >
            <ListItemIcon>
              <SwapIcon />
            </ListItemIcon>
            {t('cardDetails.replaceCard')}
          </MenuItem>
        )}
        {canUser('card:terminate', card, managerTeam) && (
          <Tooltip title={t('cardDetails.terminateCardHint')}>
            <MenuItem
              onClick={() => setIsTerminateCardDialogOpen(true)}
              data-test-id="terminate-card-btn"
            >
              <ListItemIcon>
                <XCircleIcon />
              </ListItemIcon>
              {t('cardDetails.terminateCard')}
            </MenuItem>
          </Tooltip>
        )}
        {canUser('card-velocity:change', card) && (
          <MenuItem
            onClick={() => setIsManagingLimitDialogOpen(true)}
            data-test-id="manage-limit-btn"
          >
            <ListItemIcon>
              <ArrowLineUpIcon />
            </ListItemIcon>
            {t('cardDetails.manageLimit')}
          </MenuItem>
        )}
      </MenuContainer>
      <ChangeCardNameDialog
        open={isChangeCardNameDialogOpen}
        onClose={() => setIsChangeCardNameDialogOpen(false)}
        card={card}
        onSuccess={(card) => {
          setIsChangeCardNameDialogOpen(false);
          onUpdate(card);
        }}
      />
      <ChangeCardPurposeDialog
        open={isChangeCardPurposeDialogOpen}
        onClose={() => setIsChangeCardPurposeDialogOpen(false)}
        card={card}
        onSuccess={(card) => {
          setIsChangeCardPurposeDialogOpen(false);
          onUpdate(card);
        }}
      />
      {cardConfigSetting && (
        <EditCardControlsDialog
          open={isEditCardControlsDialogOpen}
          card={card}
          cardConfigSetting={cardConfigSetting}
          onClose={() => setIsEditCardControlsDialogOpen(false)}
          onSuccess={(card) => {
            setIsEditCardControlsDialogOpen(false);
            onUpdate(card);
          }}
        />
      )}
      <EditCardTeamDialog
        open={isEditCardTeamDialogOpen}
        card={card}
        onClose={() => setIsEditCardTeamDialogOpen(false)}
        onSuccess={(card) => {
          setIsEditCardTeamDialogOpen(false);
          onUpdate(card);
        }}
      />
      <EditCardProjectDialog
        open={isEditCardProjectDialogOpen}
        onClose={() => setIsEditCardProjectDialogOpen(false)}
        onSuccess={(card) => {
          setIsEditCardProjectDialogOpen(false);
          onUpdate(card);
        }}
        card={card}
        projects={visibleProjects}
      />
      <LimitChangeRequestDialog
        open={isLimitChangeRequestDialogOpen}
        onClose={() => setIsLimitChangeRequestDialogOpen(false)}
        card={card}
        onSuccess={onUpdate}
      />
      <EditCardValidityPeriodDialog
        open={isEditCardValidityPeriodDialogOpen}
        card={card}
        onSuccess={(card) => {
          setIsEditCardValidityPeriodDialogOpen(false);
          onUpdate(card);
        }}
        onClose={() => setIsEditCardValidityPeriodDialogOpen(false)}
      />
      <ReplaceCardDialog
        open={isReplaceCardDialogOpen}
        onClose={() => setIsReplaceCardDialogOpen(false)}
        card={card}
        onSuccess={(card) => {
          setIsReplaceCardDialogOpen(false);
          onUpdate(card);
          onCardReplace && onCardReplace(card);
        }}
      />
      <TerminateCardDialog
        open={isTerminateCardDialogOpen}
        onClose={() => setIsTerminateCardDialogOpen(false)}
        card={card}
        onSuccess={(card) => {
          setIsTerminateCardDialogOpen(false);
          onUpdate(card);
        }}
      />
      <ToggleCardSyncInCirculaDialog
        open={toggleCirculaIntegrationDialogOpen}
        onClose={() => setToggleCirculaIntegrationDialogOpen(false)}
        onSuccess={(card) => {
          setToggleCirculaIntegrationDialogOpen(false);
          onUpdate(card);
        }}
        card={card}
      />
      <UpdateCardVelocityGroupDialog
        open={isManagingLimitDialogOpen}
        onClose={() => setIsManagingLimitDialogOpen(false)}
        onSuccess={(card) => {
          setIsManagingLimitDialogOpen(false);
          onUpdate(card);
        }}
        card={card}
      />

      <EditCardCustomFieldsDialog
        card={card}
        open={isEditCustomFieldsDialogOpen}
        onSuccess={(card) => {
          setIsEditCustomFieldsDialogOpen(false);
          onUpdate(card);
        }}
        onClose={() => setIsEditCustomFieldsDialogOpen(false)}
      />
    </>
  );
};

export default CardDetailsMenu;
