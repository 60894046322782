import { Area } from 'react-easy-crop/types';
import { imageLoad } from 'services/utils';

export const IMAGE_MAX_WIDTH = 90;
export const IMAGE_MAX_HEIGHT = 90;

export const getImgBlob = async (
  imageSrc: string,
  crop: Area,
  fileType: string
): Promise<Blob | null> => {
  const image: HTMLImageElement = await imageLoad(imageSrc);
  const canvas = document.createElement('canvas');
  canvas.width = IMAGE_MAX_WIDTH;
  canvas.height = IMAGE_MAX_HEIGHT;
  const ctx = canvas.getContext('2d');
  if (!ctx) return null;
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, IMAGE_MAX_WIDTH, IMAGE_MAX_HEIGHT);
  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    IMAGE_MAX_WIDTH,
    IMAGE_MAX_HEIGHT
  );

  return await new Promise((resolve) => canvas.toBlob(resolve, fileType));
};

export const getImageByFile = (file: File) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const _URL = window.URL || window.webkitURL;
    const image = new Image();
    image.src = _URL.createObjectURL(file);
    image.onload = () => resolve(image);
    image.onerror = reject;
  });
