import { ReactComponent as MicrosoftDynamics365Logo } from 'assets/svg/integrations/microsoftDynamics365Logo.svg';
import { ReactComponent as OracleNetsuiteLogo } from 'assets/svg/integrations/oracleNetsuiteLogo.svg';
import { ReactComponent as QuickbooksLogo } from 'assets/svg/integrations/quickbooksLogo.svg';
import { ReactComponent as XeroLogo } from 'assets/svg/integrations/xeroLogo.svg';
import { useGlobalState } from 'context/GlobalState';
import { AccountingSystem } from 'services/constants';

export const AccountingSystemLogo = () => {
  const {
    state: { accountingSettings },
  } = useGlobalState();

  // logos should come from BE in future
  if (accountingSettings!.accountingSystem === AccountingSystem.quickbooks)
    return <QuickbooksLogo />;

  if (
    accountingSettings!.accountingSystem ===
    AccountingSystem.microsoftDynamics365BusinessCentral
  )
    return <MicrosoftDynamics365Logo />;

  if (accountingSettings!.accountingSystem === AccountingSystem.xero)
    return <XeroLogo />;

  if (accountingSettings!.accountingSystem === AccountingSystem.oracleNetSuite)
    return <OracleNetsuiteLogo />;

  return null;
};
