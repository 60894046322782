import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  LoaderWithOverlay,
  Radio,
  RadioGroup,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { YesNo } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getYesNoFromBoolean } from 'services/utils';

interface State {
  isLoading: boolean;
  value: boolean;
}

interface Props extends DialogProps {
  intialValue: boolean;
  onClose: () => void;
  onSuccess: (newValue: boolean) => void;
}

const CanSetBillingAccountAsPrefundGroupChangeDialog = ({
  intialValue,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const api = useImperativeApi();

  const [state, setState] = useState<State>({
    isLoading: false,
    value: intialValue,
  });

  const onClick = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const orgAddtionalInfo = await api.updateCanSetBillingAccountAsPrefund(
        organization!.id,
        state.value
      );
      onSuccess(orgAddtionalInfo.canSetBillingAccountAsPrefund);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      props.onClose();
    }
  };

  const isSubmitDisabled = state.value === intialValue;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t(
          'int.generalTermsPage.onboardingSettingsSection.canSetBillingAccountAsPrefund'
        )}
      </DialogTitle>

      <DialogContent>
        <FormControl variant="standard">
          <FormLabel>
            {t(
              'int.generalTermsPage.onboardingSettingsSection.canSetBillingAccountAsPrefund'
            )}
          </FormLabel>
          <RadioGroup
            row
            value={getYesNoFromBoolean(state.value, t, { returnSlug: true })}
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                value: e.target.value === YesNo.YES,
              }));
            }}
          >
            <FormControlLabel
              control={<Radio />}
              disabled={state.isLoading}
              label={t('common.yes')}
              value={YesNo.YES}
            />
            <FormControlLabel
              control={<Radio />}
              disabled={state.isLoading}
              label={t('common.no')}
              value={YesNo.NO}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          disabled={state.isLoading}
          onClick={props.onClose}
        >
          {t('common.button.cancel')}
        </Button>

        <Button disabled={isSubmitDisabled} type="submit" onClick={onClick}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(
  CanSetBillingAccountAsPrefundGroupChangeDialog
);
