import React, { useState } from 'react';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowsRightIcon } from 'assets/icons/interface/arrowsRight.svg';
import { LoaderWithOverlay } from 'components/Loader';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface FormValues {
  customEnterpriseSize: string;
  selfDeclaredAnnualRevenue: string;
  selfDeclaredBalanceSheet: string;
  selfDeclaredNumberOfEmployees: string;
}

const EditFirmographicsSegmentationDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, organizationsStaticData },
  } = useGlobalState();
  const {
    actions: { refetchCompleteContext },
    state: { creditProfile },
  } = useCreditAndComplianceContext();
  const firmographics = creditProfile!.firmographics;

  const [selfDeclaredSegmentSize, setSelfDeclaredSegmentSize] = useState<
    string
  >(firmographics.selfDeclaredSegment.value);
  const [
    isFetchingSelfDeclaredSegmentSize,
    setIsFetchingSelfDeclaredSegmentSize,
  ] = useState(false);

  const updateSelfDeclaredSegmentSize = async (
    selfDeclaredAnnualRevenue: string,
    selfDeclaredBalanceSheet: string,
    selfDeclaredNumberOfEmployees: string
  ) => {
    try {
      setIsFetchingSelfDeclaredSegmentSize(true);
      const updatedSelfDeclaredSegmentSize = await api.getCalculatedSelfDeclaredEnterpriseSize(
        {
          selfDeclaredAnnualRevenue: selfDeclaredAnnualRevenue || null,
          selfDeclaredBalanceSheet: selfDeclaredBalanceSheet || null,
          selfDeclaredNumberOfEmployees: selfDeclaredNumberOfEmployees || null,
        }
      );
      setSelfDeclaredSegmentSize(updatedSelfDeclaredSegmentSize.enterpriseSize);
      setIsFetchingSelfDeclaredSegmentSize(false);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsFetchingSelfDeclaredSegmentSize(false);
      logError(error);
    }
  };

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    initialValues: {
      customEnterpriseSize: firmographics.isCustomEnterpriseSize.value
        ? firmographics.enterpriseSize.value
        : '-',
      selfDeclaredAnnualRevenue:
        firmographics.selfDeclaredAnnualRevenue.value ?? '',
      selfDeclaredBalanceSheet:
        firmographics.selfDeclaredBalanceSheet.value ?? '',
      selfDeclaredNumberOfEmployees:
        firmographics.selfDeclaredNumberOfEmployees.value ?? '',
    },
    validate: (values) => {
      // workaround: used as onChange form handler here https://github.com/jaredpalmer/formik/issues/271
      updateSelfDeclaredSegmentSize(
        values.selfDeclaredAnnualRevenue,
        values.selfDeclaredBalanceSheet,
        values.selfDeclaredNumberOfEmployees
      );
    },
    onSubmit: async (values) => {
      try {
        await api.updateFirmographicsSegmentation(organization!.id, {
          selfDeclaredAnnualRevenue: values.selfDeclaredAnnualRevenue || null,
          selfDeclaredBalanceSheet: values.selfDeclaredBalanceSheet || null,
          selfDeclaredNumberOfEmployees:
            values.selfDeclaredNumberOfEmployees || null,
          customEnterpriseSize:
            values.customEnterpriseSize !== '-'
              ? values.customEnterpriseSize
              : null,
        });
        await refetchCompleteContext();

        if (!mounted) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const getCustomFieldProps = <T extends keyof FormValues>(name: T) => {
    return {
      ...(formik.errors[name] && {
        error: true,
        helperText: formik.errors[name],
      }),
      name,
      value: formik.values[name],
    };
  };
  const onChange = <T extends keyof FormValues>(
    name: T,
    value: FormValues[T]
  ) => {
    if (formik.errors[name]) {
      formik.setFieldError(name, undefined);
    }
    formik.setFieldValue(name, value);
  };

  const internalToVgEnterpriseSizeMap: {
    [key: string]: string;
  } = organizationsStaticData!.enterpriseSizes.reduce(
    (prev, cum) => ({ ...prev, [cum.name]: cum.label }),
    {
      '-': '',
    }
  );

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>
        {t('int.editFirmographicsSegmentationDialog.title')}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>
                {t(
                  'int.editFirmographicsSegmentationDialog.selfDeclaredAnnualRevenue'
                )}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('selfDeclaredAnnualRevenue'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('selfDeclaredAnnualRevenue', event.target.value)
                }
              >
                {organizationsStaticData!.selfDeclaredAnnualRevenue.map(
                  (item) => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>
                {t(
                  'int.editFirmographicsSegmentationDialog.selfDeclaredNumberOfEmployees'
                )}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('selfDeclaredNumberOfEmployees'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('selfDeclaredNumberOfEmployees', event.target.value)
                }
              >
                {organizationsStaticData!.selfDeclaredNumberOfEmployees.map(
                  (item) => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>
                {t(
                  'int.editFirmographicsSegmentationDialog.selfDeclaredBalanceSheet'
                )}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('selfDeclaredBalanceSheet'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('selfDeclaredBalanceSheet', event.target.value)
                }
              >
                {organizationsStaticData!.selfDeclaredBalanceSheet.map(
                  (item) => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} position="relative">
            <TextField
              disabled
              value={selfDeclaredSegmentSize}
              label={t(
                'int.editFirmographicsSegmentationDialog.selfDeclaredSegment'
              )}
            />
            {isFetchingSelfDeclaredSegmentSize && (
              <LoaderWithOverlay size={24} thickness={2} $top={40} />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            borderBottom="1px solid"
            borderColor={(theme) => theme.palette.divider}
            marginLeft={2}
          />

          <Grid item xs={5.5}>
            <FormControl fullWidth error={!!formik.errors.customEnterpriseSize}>
              <InputLabel>
                {t('int.editFirmographicsSegmentationDialog.enterpriseSize')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('customEnterpriseSize'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('customEnterpriseSize', event.target.value)
                }
              >
                {Object.keys(internalToVgEnterpriseSizeMap).map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.customEnterpriseSize}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={1} display="flex" alignItems="flex-end">
            <ArrowsRightIcon />
          </Grid>

          <Grid item xs={5.5}>
            <TextField
              disabled
              value={
                formik.values.customEnterpriseSize
                  ? internalToVgEnterpriseSizeMap[
                      formik.values.customEnterpriseSize
                    ]
                  : ''
              }
              label={t(
                'int.editFirmographicsSegmentationDialog.correspondingVgEnterpriseSize'
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={formik.isSubmitting} onClick={formik.submitForm}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      {formik.isSubmitting && <LoaderWithOverlay size={32} thickness={3} />}
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditFirmographicsSegmentationDialog);
