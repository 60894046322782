import {
  AccountingSettings,
  AccountingSystem,
  AccountingSystemIntegrationMethod,
  ApiIntegrationStatus,
} from 'services/constants';

export const isCodatAccSystemConnected = (
  accountingSettings: AccountingSettings | null
) =>
  !!accountingSettings?.accountingSystem &&
  accountingSettings.accountingSystemIntegrationMethod ===
    AccountingSystemIntegrationMethod.codat &&
  accountingSettings.useAccountingExport &&
  accountingSettings.apiIntegrationStatus === ApiIntegrationStatus.connected;

// custom Locoia systems - Custom CSV
export const isCustomLocoiaAccountingSystem = (
  accountingSettings: AccountingSettings | null
) =>
  accountingSettings?.accountingSystem !== AccountingSystem.lexOffice &&
  accountingSettings?.accountingSystemIntegrationMethod ===
    AccountingSystemIntegrationMethod.locoia;
