import { useTranslation } from 'react-i18next';
import { Box, Paper, Tooltip, Typography } from 'elements';
import { ReceiptAutoMatchingDetails } from 'services/constants';

interface Props {
  receipt: ReceiptAutoMatchingDetails;
}

const SenderInfoBlock = ({ receipt }: Props) => {
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        px: 1.5,
        py: 1,
        mt: 2,
        mx: 3,
        mb: 2,
      }}
      variant="outlined"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="body2" noWrap>
            {t('receiptInboxDetails.sender')}
          </Typography>
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            noWrap
          >
            {t('receiptInboxDetails.subject')}
          </Typography>
        </Box>

        <Box pl={2} overflow="hidden">
          <Tooltip title={receipt.emailFrom}>
            <Typography variant="body2" noWrap>
              {receipt.emailFrom}
            </Typography>
          </Tooltip>

          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            align="right"
            noWrap
          >
            <Tooltip title={receipt.emailSubject || ''}>
              <span>{receipt.emailSubject || '-'}</span>
            </Tooltip>
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default SenderInfoBlock;
