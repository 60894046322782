import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  CircularProgress,
  Divider,
  DownloadIcon,
  ListItemText,
  LoadingButton,
  MenuContainer,
  MenuItem,
} from 'elements';
import useIsAdminApp from 'hooks/useIsAdminApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingSystem,
  GetTransactionsParams,
  MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_CUSTOM_CSV_RECEIPTS,
  MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_ZIP_RECEIPTS,
  NetworkErrorCode,
  ReceiptInboxMatchingFlow,
  TransactionsDownloadFileType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  downloadResponseAsFile,
  getGenericErrorMsg,
  getNetworkErrorCode,
} from 'services/utils';

interface State {
  isCsvDownloading: boolean;
  isZipDownloading: boolean;
  isCustomCsvDownloading: boolean;
}

interface Props {
  disabled: boolean;
  transactionsCount: number;
  transactionsShownCount: number;
  getRequestParams: () => GetTransactionsParams;
}

const TransactionsPageMenu = ({
  disabled,
  transactionsCount,
  transactionsShownCount,
  getRequestParams,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isAdminApp = useIsAdminApp();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization, member, receiptInbox, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isCsvDownloading: false,
    isZipDownloading: false,
    isCustomCsvDownloading: false,
  });

  const downloadTransactionsReceipts = async () => {
    try {
      setState((prevState) => ({ ...prevState, isZipDownloading: true }));
      const params = getRequestParams();
      const { transactionIds } = await api.getTransactionsIds(params);
      if (!mounted.current) return;
      // this is uncommon case, which can happen sometimes
      if (transactionIds.length < 1) {
        enqueueSnackbar(t('errors.general'), { variant: 'error' });
        return;
      }

      if (
        transactionIds.length > MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_ZIP_RECEIPTS
      ) {
        setState((state) => ({
          ...state,
          isZipDownloading: false,
        }));
        closeSnackbar();
        enqueueSnackbar(
          t('transactionsPageMenu.tooManySelectedTransactionsError', {
            count: MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_ZIP_RECEIPTS,
          }),
          {
            persist: true,
            variant: 'warning',
          }
        );
        return;
      }
      const response = await api.getTransactionReceipts(
        organization!.id,
        transactionIds,
        isAdminApp ? undefined : member!.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isZipDownloading: false }));
      downloadResponseAsFile(response);
    } catch (error) {
      if (!mounted.current) return;
      closeSnackbar();
      if (getNetworkErrorCode(error) === NetworkErrorCode.notFound) {
        enqueueSnackbar(t('transactionsPageMenu.noUploadedReceiptsError'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
      setState((state) => ({ ...state, isZipDownloading: false }));
    }
  };

  const downloadTransactionsCsv = async (
    format: TransactionsDownloadFileType | AccountingSystem
  ) => {
    if (
      format !== TransactionsDownloadFileType.defaultCsv &&
      transactionsCount >
        MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_CUSTOM_CSV_RECEIPTS
    ) {
      enqueueSnackbar(
        t('transactionsPageMenu.tooManySelectedTransactionsError', {
          count: MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_CUSTOM_CSV_RECEIPTS,
        }),
        {
          persist: true,
          variant: 'warning',
        }
      );
      return;
    }

    const loadingKey =
      format === TransactionsDownloadFileType.defaultCsv
        ? 'isCsvDownloading'
        : 'isCustomCsvDownloading';
    try {
      setState((state) => ({ ...state, [loadingKey]: true }));
      const params = getRequestParams();
      const response = await api.getTransactionsDownloadFile({
        ...params,
        format,
      });
      if (!mounted.current) return;
      downloadResponseAsFile(response);
      setState((state) => ({
        ...state,
        [loadingKey]: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((state) => ({
        ...state,
        [loadingKey]: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const isCsvBtnVisible = canUser('transactions-csv:download');
  const isZipBtnVisible = canUser('transactions-zip:download');
  const isCustomCsvVisible =
    canUser('transactions-csv:download') &&
    !!accountingSettings?.accountingSystem &&
    [
      AccountingSystem.customEdreams,
      AccountingSystem.customEasyMarket,
    ].includes(accountingSettings.accountingSystem);

  if (!isCsvBtnVisible && !isZipBtnVisible && !isCustomCsvVisible) {
    return null;
  }

  const isLoading =
    state.isCsvDownloading ||
    state.isZipDownloading ||
    state.isCustomCsvDownloading;

  return (
    <MenuContainer
      button={
        <LoadingButton
          sx={{ ml: 'auto' }}
          variant="text"
          startIcon={<DownloadIcon />}
          disabled={
            disabled ||
            receiptInbox.flow ===
              ReceiptInboxMatchingFlow.selectedReceiptMatching
          }
          loadingPosition="start"
          loading={isLoading}
        >
          {t('transactionsPageMenu.exportMenuBtn')}
          {!!transactionsShownCount && ` (${transactionsShownCount})`}
        </LoadingButton>
      }
    >
      {isCsvBtnVisible && (
        <MenuItem
          disabled={state.isCsvDownloading}
          onClick={() =>
            downloadTransactionsCsv(TransactionsDownloadFileType.defaultCsv)
          }
        >
          <ListItemText>{t('transactionsPageMenu.csvBtn')}</ListItemText>
          {state.isCsvDownloading && (
            <Box ml="auto" pl={1} display="inline-flex">
              <CircularProgress size="small" />
            </Box>
          )}
        </MenuItem>
      )}
      {isZipBtnVisible && (
        <MenuItem
          disabled={state.isZipDownloading}
          onClick={downloadTransactionsReceipts}
        >
          <ListItemText primary={t('transactionsPageMenu.receiptsZipBtn')} />
          {state.isZipDownloading && (
            <Box ml="auto" pl={1} display="inline-flex">
              <CircularProgress size="small" />
            </Box>
          )}
        </MenuItem>
      )}

      {(isCsvBtnVisible || isZipBtnVisible) && isCustomCsvVisible && (
        <Divider />
      )}

      {isCustomCsvVisible && (
        <MenuItem
          disabled={state.isCustomCsvDownloading}
          onClick={() =>
            downloadTransactionsCsv(accountingSettings!.accountingSystem!)
          }
        >
          <ListItemText primary={t('transactionsPageMenu.customCsvBtn')} />
          {state.isCustomCsvDownloading && (
            <Box ml="auto" pl={1} display="inline-flex">
              <CircularProgress size="small" />
            </Box>
          )}
        </MenuItem>
      )}
    </MenuContainer>
  );
};

export default TransactionsPageMenu;
