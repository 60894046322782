import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { internalOrgPaths } from 'components/App';
import { PageContent, PageNavItem } from 'components/Page';
import { useGlobalState } from 'context/GlobalState';
import { CreditAndCompliancePageHeader } from 'domains/creditAndCompliance/components';
import useHeaderBorderOnScroll from 'hooks/useBorderOnScroll';
import AssessmentSubPage from './AssessmentSubPage';
import CreditQaSubPage from './CreditQaSubPage';
import ProfileSubPage from './ProfileSubPage';

const CreditPage = () => {
  const { t } = useTranslation();
  const { onContentScroll } = useHeaderBorderOnScroll();
  const { path } = useRouteMatch();
  const {
    state: { organization },
  } = useGlobalState();

  return (
    <>
      <CreditAndCompliancePageHeader>
        <PageNavItem
          to={generatePath(internalOrgPaths.creditProfile, {
            orgId: organization!.id,
          })}
        >
          {t('int.creditPage.profile')}
        </PageNavItem>
        <PageNavItem
          to={generatePath(internalOrgPaths.creditQa, {
            orgId: organization!.id,
          })}
        >
          {t('int.creditPage.qa')}
        </PageNavItem>
        <PageNavItem
          to={generatePath(internalOrgPaths.creditAssessment, {
            orgId: organization!.id,
          })}
        >
          {t('int.creditPage.assessment')}
        </PageNavItem>
      </CreditAndCompliancePageHeader>

      <PageContent onScroll={onContentScroll}>
        <Switch>
          <Route path={`${path}/profile`} component={ProfileSubPage} />
          <Route path={`${path}/qa`} component={CreditQaSubPage} />
          <Route path={`${path}/assessment`} component={AssessmentSubPage} />

          <Redirect to={`${path}/profile`} />
        </Switch>
      </PageContent>
    </>
  );
};

export default CreditPage;
