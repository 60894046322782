import { styled } from '@mui/material';
import { IconButton, ListItemButton } from 'elements';

export const StyledMainHeader = styled('div')<{ navigation?: boolean }>`
  padding: ${({ theme }) => theme.spacing(2, 5)};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: ${({ navigation, theme }) =>
    navigation
      ? theme.variables.navigation[theme.navigationMode].background
      : 'none'};
`;

export const MenuSection = styled('div')`
  display: flex;
  align-items: center;

  .MuiIconButton-root {
    margin-left: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  flexGrow: 0,
  padding: theme.spacing(0.5, 2),
  marginRight: theme.spacing(1),
  borderRadius: theme.shape.borderRadius + 'px',
  '&:hover': {
    background: theme.variables.navigation[theme.navigationMode].hover,
  },
  '&.active': {
    background: theme.variables.navigation[theme.navigationMode].selected,
    '.MuiListItemIcon-root, .MuiTypography-root': {
      color:
        theme.variables.navigation[theme.navigationMode].text.selectedPrimary,
    },
  },
  '.MuiListItemIcon-root': {
    minWidth: 36,
    color: theme.variables.navigation[theme.navigationMode].text.secondary,
  },
  '.MuiTypography-root': {
    ...theme.variables.components['menu-item-dense'],
    color: theme.variables.navigation[theme.navigationMode].text.secondary,
  },
})) as typeof ListItemButton;

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.variables.navigation[theme.navigationMode].text.secondary,
  '&:hover': {
    background: theme.variables.navigation[theme.navigationMode].hover,
  },
  '&.selected': {
    background: theme.variables.navigation[theme.navigationMode].hover,
    color: theme.variables.navigation[theme.navigationMode].text.primary,
  },
  '&.Mui-disabled': {
    color: theme.variables.navigation[theme.navigationMode].text.disabled,
  },
}));
