import { Box, Typography } from 'elements';
import { ProgramSettlementStatus } from 'services/constants';

interface Props {
  status: ProgramSettlementStatus;
  transactionsCount: number;
  matchedTransactionCount: number | null;
}

const CountCell = ({
  status,
  transactionsCount,
  matchedTransactionCount,
}: Props) => {
  if (status === ProgramSettlementStatus.reconcile) {
    return (
      <Box display="flex" justifyContent="flex-end" flexWrap="wrap">
        <Box display="flex" flexWrap="nowrap" overflow="hidden">
          <Typography variant="inherit" color="error.main" noWrap>
            {matchedTransactionCount}
          </Typography>
          /
        </Box>
        {transactionsCount}
      </Box>
    );
  }
  return <>{transactionsCount}</>;
};

export default CountCell;
