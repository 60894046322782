import { useMemo } from 'react';
import { useGlobalState } from './index';

const useManagedTeams = () => {
  const {
    state: { teams, member },
  } = useGlobalState();

  return useMemo(
    () =>
      teams.filter((item) =>
        item.managers.some((manager) => manager.memberId === member.id)
      ),
    [teams, member.id]
  );
};

export default useManagedTeams;
