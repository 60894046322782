import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CountryFlag from 'components/CountryFlag';
import { CardIconWithDetails } from 'domains/card/components';
import {
  MerchantCell,
  TransactionAmountCell,
  TransactionMissingReceiptCell,
} from 'domains/transaction/components';
import { DataGridCell, drawerPlaceholderColumn, GridColDef } from 'elements';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import { Transaction } from 'services/constants';
import { getFullName } from 'services/utils';

const useColumns = () => {
  const { t, i18n } = useTranslation();
  const isDetailsPageOpen = useIsDetailsPageOpen('/:transactionId');

  return useMemo(() => {
    const columns: GridColDef<Transaction>[] = [
      {
        headerName: t('globalTransactionsPage.merchant'),
        field: 'merchantName',
        sortable: false,
        flex: 1.5,
        minWidth: 60,
        renderCell: ({ row }) => (
          <MerchantCell
            isGlobalPage
            transaction={row}
            showReceiptIcon
            showCommentIcon
          />
        ),
      },
      {
        headerName: '',
        field: 'receiptNeeded',
        sortable: false,
        flex: 0.2,
        renderCell: ({ row }) => (
          <TransactionMissingReceiptCell transaction={row} />
        ),
      },
      {
        headerName: t('globalTransactionsPage.country'),
        field: 'country',
        sortable: false,
        flex: 0.5,
        renderCell: ({ row }) => (
          <DataGridCell>
            <CountryFlag code={row.country!} mr={1} />
            {row.country}
          </DataGridCell>
        ),
      },
      {
        headerName: t('globalTransactionsPage.date'),
        field: 'createdAt',
        sortable: false,
        flex: 0.7,
        valueGetter: ({ row }) => moment(row.createdAt).format('D MMM YYYY'),
      },
      {
        headerName: t('globalTransactionsPage.organization'),
        field: 'organizationName',
        sortable: false,
        flex: 1,
        valueGetter: ({ row }) => row.organizationName,
      },
      {
        headerName: t('globalTransactionsPage.card'),
        field: 'cardType',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <CardIconWithDetails
            cardConfig={row.cardConfig}
            cardDesignId={row.cardDesignId}
            cardRefNum={row.cardRefNum}
            cardName={row.cardName}
            showCardNameTooltip
          />
        ),
      },
      {
        headerName: t('globalTransactionsPage.member'),
        field: 'memberFirstName',
        sortable: false,
        flex: 1,
        valueGetter: ({ row }) =>
          getFullName(row.memberFirstName, row.memberLastName) || '-',
      },
      {
        headerName: t('globalTransactionsPage.amount'),
        field: 'transactionAmount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        width: isDetailsPageOpen ? 80 : 98,
        renderCell: ({ row }) => (
          <TransactionAmountCell
            transaction={row}
            bookingDate={row.bookingDate}
          />
        ),
      },
      drawerPlaceholderColumn,
    ];

    return columns;
  }, [i18n.language, isDetailsPageOpen]);
};

export default useColumns;
