import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import useCreditAndComplianceContext, {
  withCreditAndComplianceContext,
} from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { CompliancePage, CreditPage } from 'domains/creditAndCompliance/pages';
import { LoaderWithOverlay } from 'elements';

const CreditAndComplianceRoutes = () => {
  const { path } = useRouteMatch();
  const {
    state: { isInitialLoading, isNonCustomer },
  } = useCreditAndComplianceContext();

  if (isInitialLoading) return <LoaderWithOverlay loading />;

  return (
    <Switch>
      <Route path={`${path}/credit`} component={CreditPage} />
      {!isNonCustomer && (
        <Route path={`${path}/compliance`} component={CompliancePage} />
      )}

      <Redirect to={`${path}/credit`} />
    </Switch>
  );
};

export default withCreditAndComplianceContext(CreditAndComplianceRoutes);
