import React from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import {
  ComplianceDocumentUploadOption,
  SUPPORT_NUMBER,
} from 'services/constants';
import { FormValues } from './index';

interface Props {
  values: FormValues;
  onCancel: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
}

const getPreviewTitleByOption = (docType: ComplianceDocumentUploadOption) => {
  if (docType === ComplianceDocumentUploadOption.ACTIVE_OPT_IN) {
    return 'int.updateTermsOrPoliciesDialog.preview.activeOptInTitle';
  }
  return 'int.updateTermsOrPoliciesDialog.preview.commonTitle';
};

const getPreviewBodyByOption = (docType: ComplianceDocumentUploadOption) => {
  switch (docType) {
    case ComplianceDocumentUploadOption.ACTIVE_OPT_IN:
      return 'int.updateTermsOrPoliciesDialog.preview.activeOptInBody';
    case ComplianceDocumentUploadOption.CHANGE_WITH_OBJECTION:
      return 'int.updateTermsOrPoliciesDialog.preview.changeWithObjectionBody';
    case ComplianceDocumentUploadOption.NOT_APPLICABLE:
      return 'int.updateTermsOrPoliciesDialog.preview.notApplicableBody';
    default:
      return '';
  }
};

const PreviewDialog = ({ values, onCancel, onSubmit, isSubmitting }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle>
        {t(getPreviewTitleByOption(values.option), {
          type: t(`int.updateTermsOrPoliciesDialog.docs.${values.type}`),
        })}
      </DialogTitle>
      <DialogContent>
        <Typography>
          <Trans
            i18nKey={getPreviewBodyByOption(values.option)}
            components={{
              b: <b />,
              br: <br />,
            }}
            values={{
              date: moment(values.version).format('DD MMMM YYYY'),
              phone: SUPPORT_NUMBER,
            }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box flexGrow={1}>
          <Button onClick={onCancel} variant="text">
            {t('common.button.back')}
          </Button>
        </Box>
        <Button onClick={onCancel} variant="text">
          {t('common.button.cancel')}
        </Button>
        <Button onClick={onSubmit} disabled={isSubmitting}>
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={isSubmitting} />
    </>
  );
};

export default PreviewDialog;
