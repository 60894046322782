import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import circulaImage from 'assets/images/integrationsModaLCirculaBg.jpg';
import agicapImage from 'assets/images/integrationsModalAgicapBg.jpg';
import basImage from 'assets/images/integrationsModalBasBg.jpg';
import bezalaImage from 'assets/images/integrationsModalBezalaBg.jpg';
import bookmanImage from 'assets/images/integrationsModalBookmanBg.jpg';
import buchhaltungsButlerImage from 'assets/images/integrationsModalBuchhaltungsButlerBg.jpg';
import candisImage from 'assets/images/integrationsModalCandisBg.jpg';
import commitlyImage from 'assets/images/integrationsModalCommitlyBg.jpg';
import confermaImage from 'assets/images/integrationsModalConfermaBg.jpg';
import customCSVImage from 'assets/images/integrationsModalCustomCSVBg.jpg';
import datevImage from 'assets/images/integrationsModalDatevBg.jpg';
import domondaImage from 'assets/images/integrationsModalDomondaBg.jpg';
import flowwerImage from 'assets/images/integrationsModalFlowwerBg.jpg';
import freeFinanceImage from 'assets/images/integrationsModalFreeFinanceBg.jpg';
import getMyInvoicesImage from 'assets/images/integrationsModalGetMyInvoicesBg.jpg';
import helloHQImage from 'assets/images/integrationsModalHelloHQBg.jpg';
import kanzleilandImage from 'assets/images/integrationsModalKanzleilandBg.jpg';
import klippaImage from 'assets/images/integrationsModalKlippaBg.jpg';
import lexOfficeImage from 'assets/images/integrationsModalLexOfficeBg.jpg';
import lexOfficeImageEn from 'assets/images/integrationsModalLexOfficeBgEn.jpg';
import microsoftDynamicsImage from 'assets/images/integrationsModalMicrosoftDynamicsBg.jpg';
import mobilexpenseImage from 'assets/images/integrationsModalMobilexpenseBg.jpg';
import mobilexpenseMxpImage from 'assets/images/integrationsModalMobilexpenseMxpBg.jpg';
import mocoappImage from 'assets/images/integrationsModalMocoappBg.jpg';
import netvisorImage from 'assets/images/integrationsModalNetvisorBg.jpg';
import oracleNetSuiteImage from 'assets/images/integrationsModalOracleNetSuiteBg.jpg';
import procountorImage from 'assets/images/integrationsModalProcountorBg.jpg';
import quickbooksImage from 'assets/images/integrationsModalQuickbooksBg.jpg';
import rydooImage from 'assets/images/integrationsModalRydooBg.jpg';
import scopevisioImage from 'assets/images/integrationsModalScopevisioBg.jpg';
import tidelyImage from 'assets/images/integrationsModalTidelyBg.jpg';
import voxelImage from 'assets/images/integrationsModalVoxelBg.jpg';
import xeroImage from 'assets/images/integrationsModalXeroBg.jpg';
import Loader from 'components/Loader';
import {
  IntegrationName,
  IntegrationNameType,
  PartnerIds,
  PartnerIdType,
} from 'services/constants';
import { imageLoad } from 'services/utils';

const HeroImage = styled('div')<{ $image: string; $lg: boolean }>`
  height: 100%;
  background-image: ${({ $image }) => ($image ? `url(${$image})` : 'none')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ $lg }) =>
    $lg
      ? `
          position: relative;
          max-height: 1440px;
          max-width: 840px;
          min-width: 400px;
          aspect-ratio: 24/41;
          margin-left: auto;
       `
      : `
          position: absolute;
          right: 0;
          top: 0;
          width: 350px;
        `};
`;

interface Props {
  integrationName: IntegrationNameType;
  partnerId?: PartnerIdType;
  $lg?: boolean;
}

export const IntegrationImage = ({
  integrationName,
  partnerId,
  $lg = false,
}: Props) => {
  const { i18n } = useTranslation();
  const [image, setImage] = useState('');
  const [isImgLoading, setIsImgLoading] = useState(true);

  // adding spinner, when image is still loading
  useEffect(() => {
    let image: string;
    const keyToMatch = partnerId || integrationName;
    switch (keyToMatch) {
      case PartnerIds.circula:
      case PartnerIds.circulaDemo:
      case PartnerIds.circulaEmbedded:
      case PartnerIds.circulaFullyEmbedded:
        image = circulaImage;
        break;
      case IntegrationName.datev:
        image = datevImage;
        break;
      case IntegrationName.tidely:
        image = tidelyImage;
        break;
      case IntegrationName.buchhaltungsButler:
        image = buchhaltungsButlerImage;
        break;
      case PartnerIds.candis:
        image = candisImage;
        break;
      case IntegrationName.getMyInvoices:
        image = getMyInvoicesImage;
        break;
      case IntegrationName.flowwer:
        image = flowwerImage;
        break;
      case IntegrationName.kanzleiland:
        image = kanzleilandImage;
        break;
      case IntegrationName.rydoo:
        image = rydooImage;
        break;
      case IntegrationName.bookman:
        image = bookmanImage;
        break;
      case IntegrationName.hellohq:
        image = helloHQImage;
        break;
      case IntegrationName.domonda:
        image = domondaImage;
        break;
      case PartnerIds.mobilexpenseDeclaree:
        image = mobilexpenseImage;
        break;
      case PartnerIds.mobilexpenseMXP:
        image = mobilexpenseMxpImage;
        break;
      case IntegrationName.commitly:
        image = commitlyImage;
        break;
      case IntegrationName.freeFinance:
        image = freeFinanceImage;
        break;
      case IntegrationName.agicap:
        image = agicapImage;
        break;
      case PartnerIds.mocoapp:
        image = mocoappImage;
        break;
      case PartnerIds.bezala:
        image = bezalaImage;
        break;
      case IntegrationName.lexOffice:
        image = i18n.language === 'de' ? lexOfficeImage : lexOfficeImageEn;
        break;
      case IntegrationName.customCSV:
        image = customCSVImage;
        break;
      case IntegrationName.netvisor:
        image = netvisorImage;
        break;
      case IntegrationName.procountor:
        image = procountorImage;
        break;
      case IntegrationName.xero:
        image = xeroImage;
        break;
      case IntegrationName.quickbooks:
        image = quickbooksImage;
        break;
      case IntegrationName.oracleNetSuite:
        image = oracleNetSuiteImage;
        break;
      case IntegrationName.microsoftDynamics365BusinessCentral:
        image = microsoftDynamicsImage;
        break;
      case PartnerIds.klippa:
        image = klippaImage;
        break;
      case PartnerIds.voxel:
        image = voxelImage;
        break;
      case PartnerIds.conferma:
        image = confermaImage;
        break;
      case PartnerIds.bas:
        image = basImage;
        break;
      case PartnerIds.scopevisio:
        image = scopevisioImage;
        break;

      default:
        image = '';
    }

    if (!image) {
      setIsImgLoading(false);
      return;
    }

    (async () => {
      try {
        await imageLoad(image);
      } finally {
        //  if load was failed, let's still
        //  set image as a bg
        setImage(image);
        setIsImgLoading(false);
      }
    })();
  }, [integrationName, partnerId]);

  return (
    <HeroImage $lg={$lg} $image={image}>
      {isImgLoading && <Loader size={20} thickness={3} />}
    </HeroImage>
  );
};
