import { useEffect, useMemo, useRef, useState } from 'react';
import { sortBy } from 'lodash';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { useGlobalState, useManagedTeams } from 'context/GlobalState';
import {
  ChTransactionPagesTabs,
  TransactionsPageFilterChips,
  TransactionsPageFilters,
  TransactionsPageMenu,
} from 'domains/transaction/components';
import { TransactionDetailsPage } from 'domains/transaction/pages';
import {
  Box,
  DataGrid,
  FileXIcon,
  LoaderWithOverlay,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  DEFAULT_PAGE_LIMIT,
  FeatureModuleKey,
  FeatureModuleValueByKeyMap,
  GetTransactionsParams,
  merchantCategories,
  NONE_VALUE,
  reviewFlagReasons,
  Transaction,
  transactionReceiptStatuses,
  TransactionReviewStatus,
  transactionReviewStatuses,
  TransactionSimpleType,
  TransactionStatus,
  transactionStatuses,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getReceiptFilterApiParams,
  getValidQueryParamValue,
  getValidQueryParamValues,
} from 'services/utils';
import useColumns from './useColumns';

export const visibleTransactionTypes = [
  TransactionSimpleType.purchase,
  TransactionSimpleType.refund,
  TransactionSimpleType.chargeback,
  TransactionSimpleType.recharge,
  TransactionSimpleType.statusInquiry,
];

const getQueryParams = (
  qs: string,
  managedTeamIds: string[],
  sortedProjectIds: string[],
  cardAccountIds: string[],
  featureModules: FeatureModuleValueByKeyMap
) => {
  const {
    q,
    teamId,
    status,
    type,
    category,
    projectIds,
    fromDate,
    toDate,
    receipt,
    reviewStatus,
    flagReason,
    cardAccountId,
  } = Object.fromEntries(new URLSearchParams(qs).entries());

  const fromDateMoment = moment(fromDate, moment.ISO_8601);
  const toDateMoment = moment(toDate, moment.ISO_8601);

  const visibleReviewStatuses: (
    | TransactionReviewStatus
    | typeof NONE_VALUE
  )[] = featureModules.MANAGER_TX_REVIEWS
    ? [...transactionReviewStatuses, NONE_VALUE]
    : [
        TransactionReviewStatus.flagged,
        TransactionReviewStatus.resolved,
        NONE_VALUE,
      ];

  return {
    q: q ? q.trim() : '',
    teamId: getValidQueryParamValues(teamId, managedTeamIds),
    status: getValidQueryParamValues(status, transactionStatuses),
    type: getValidQueryParamValues(type, visibleTransactionTypes),
    category: getValidQueryParamValues(category, merchantCategories),
    projectIds: getValidQueryParamValues(projectIds, sortedProjectIds),
    fromDate: fromDateMoment.isValid() ? fromDateMoment : null,
    toDate:
      fromDateMoment.isValid() && toDateMoment.isValid() ? toDateMoment : null,
    receipt: getValidQueryParamValue(receipt, transactionReceiptStatuses),
    reviewStatus: getValidQueryParamValue(reviewStatus, visibleReviewStatuses),
    flagReason: featureModules.PRIVATE_EXPENSE
      ? getValidQueryParamValue(flagReason, reviewFlagReasons)
      : '',
    cardAccountId: getValidQueryParamValue(cardAccountId, cardAccountIds),
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({
  teamId,
  status,
  type,
  category,
  projectIds,
  fromDate,
  receipt,
  reviewStatus,
  flagReason,
  cardAccountId,
}: QueryParams) =>
  +!!teamId.length +
  +!!status.length +
  +!!type.length +
  +!!category.length +
  +!!projectIds.length +
  +!!fromDate +
  +!!receipt.length +
  +!!reviewStatus +
  +!!flagReason +
  +!!cardAccountId;

interface State {
  isLoading: boolean;
  transactions: Transaction[];
  hasNextPage: boolean;
  totalCount: number;
  missingReceiptsCount: number;
}

const CardholderTeamTransactionsPage = () => {
  const { managerTransactionReviewEnabled } = useFlags();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const api = useImperativeApi();
  const mounted = useMounted();
  const showPageError = useShowPageError();
  const setQueryParam = useSetQueryParam();
  const {
    state: {
      organization,
      accountingSettings,
      featureModules,
      projects,
      cardAccounts,
    },
  } = useGlobalState();
  const managedTeams = useManagedTeams();
  const managedTeamsIds = useMemo(() => managedTeams.map((item) => item.id), [
    managedTeams,
  ]);
  const sortedProjects = useMemo(
    () =>
      projects?.length &&
      // projects hidden from partner
      featureModules.ACCOUNTING_FEATURES &&
      accountingSettings!.projectEnabled
        ? sortBy(projects, (v) => v.name.toLowerCase())
        : [],
    [projects]
  );
  const sortedProjectIds = useMemo(
    () => sortedProjects.map((project) => project.id),
    [sortedProjects]
  );
  const cardAccountIds = useMemo(
    () => cardAccounts.map((account) => account.id),
    [cardAccounts]
  );
  const paramsRef = useRef(
    getQueryParams(
      location.search,
      managedTeamsIds,
      sortedProjectIds,
      cardAccountIds,
      featureModules
    )
  );
  const dataGridRef = useGridApiRef();
  const columns = useColumns();
  const pageRef = useRef(0);
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:transactionId',
    true
  );
  const [state, setState] = useState<State>({
    isLoading: true,
    transactions: [],
    hasNextPage: false,
    totalCount: 0,
    missingReceiptsCount: 0,
  });
  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;
  const isEmptyState = !state.transactions.length && !areFiltersApplied;

  const getTeamIdRequestParam = () => {
    const { teamId } = paramsRef.current;
    if (teamId.length) {
      return teamId.join();
    }
    return managedTeamsIds.join();
  };

  const getRequestParams = (): GetTransactionsParams => {
    const {
      q,
      status,
      type,
      category,
      projectIds,
      fromDate,
      toDate,
      receipt,
      reviewStatus,
      flagReason,
      cardAccountId,
    } = paramsRef.current;

    return {
      q: q.length ? q : undefined,
      status: status.length ? status.join() : undefined,
      type: type.length ? type.join() : undefined,
      category: category.length ? category.join() : undefined,
      projectIds: projectIds.length ? projectIds.join() : undefined,
      fromDate: fromDate?.format(),
      toDate: toDate?.format(),
      organizationId: organization!.id,
      teamId: getTeamIdRequestParam(),
      ...getReceiptFilterApiParams(receipt),
      reviewStatus: reviewStatus || undefined,
      flagReason: flagReason || undefined,
      cardAccountId: cardAccountId || undefined,
    };
  };

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const params = getRequestParams();
      const {
        transactions,
        hasNextPage,
        totalCount,
      } = await api.getTransactions({ ...params, page, limit });

      const transactionIdsToCheckForEmissions = transactions
        .filter((t) =>
          [TransactionStatus.confirmed, TransactionStatus.pending].includes(
            t.status
          )
        )
        .map((t) => t.transactionId);

      const emissions =
        featureModules.PLIANT_EARTH && transactionIdsToCheckForEmissions.length
          ? await api
              .getTransactionEmissions({
                organizationId: organization!.id,
                transactionIds: transactionIdsToCheckForEmissions,
              })
              .then((data) => data.transactions)
          : [];

      const transactionsEmissionObject: {
        [key: string]: number;
      } = emissions.reduce(
        (acc, curr) => ({ ...acc, [curr.transactionId]: curr.emissionAmount }),
        {}
      );

      const transactionsInclEmissions = transactions.map((transaction) => ({
        ...transaction,
        emission:
          transactionsEmissionObject[transaction.transactionId] > 0
            ? transactionsEmissionObject[transaction.transactionId]
            : null,
      }));

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        transactions: isLoadMore
          ? [...prevState.transactions, ...transactionsInclEmissions]
          : transactionsInclEmissions,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const getMissingReceiptsFiltersCounts = async () => {
    try {
      const {
        count: missingReceiptsCount,
      } = await api.getMissingTransactionReceiptCount({
        organizationId: organization!.id,
        teamId: managedTeamsIds.join(),
      });

      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, missingReceiptsCount }));
    } catch (error) {
      logError(error);
    }
  };

  const onTransactionDetailsUpdated = (updatedTransaction: Transaction) => {
    setState((prevState) => ({
      ...prevState,
      transactions: prevState.transactions.map((transaction) =>
        transaction.transactionId === updatedTransaction.transactionId
          ? updatedTransaction
          : transaction
      ),
    }));
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });

    paramsRef.current = getQueryParams(
      location.search,
      managedTeamsIds,
      sortedProjectIds,
      cardAccountIds,
      featureModules
    );
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  useEffect(() => {
    if (
      featureModules.RECEIPT_MANAGEMENT &&
      organization?.missingReceiptNotificationEnabled
    ) {
      getMissingReceiptsFiltersCounts();
    }
  }, []);

  return (
    <>
      <PageHeader>
        <PageTitle title={t('chTeamTransactionsPage.title')} />

        <ChTransactionPagesTabs />

        <Box mt={2} display="flex" alignItems="center">
          <TransactionsPageFilters
            params={paramsRef.current}
            selectedFiltersCount={selectedFiltersCount}
            setParam={setQueryParam}
            disabled={isEmptyState}
            teams={managedTeams}
            projects={sortedProjects}
            missingReceiptsCount={state.missingReceiptsCount}
            transactionsCount={state.totalCount}
          />

          <TransactionsPageMenu
            disabled={!state.transactions.length}
            transactionsCount={state.totalCount}
            transactionsShownCount={areFiltersApplied ? state.totalCount : 0}
            getRequestParams={getRequestParams}
          />
        </Box>

        <TransactionsPageFilterChips
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          teams={managedTeams}
          projects={sortedProjects}
        />
      </PageHeader>
      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />
        <DataGrid<Transaction>
          apiRef={dataGridRef}
          rowHeight={72}
          rowCount={state.totalCount}
          getRowId={(row) => row.transactionId}
          loading={state.isLoading}
          rows={state.transactions}
          columns={columns}
          columnVisibilityModel={{
            merchantName: true,
            receiptNeeded:
              featureModules.RECEIPT_MANAGEMENT &&
              !!organization?.missingReceiptNotificationEnabled,
            createdAt: true,
            cardType: !isDetailsPageOpen,
            memberFirstName: !isDetailsPageOpen,
            teamName: !isDetailsPageOpen && managedTeams.length > 1,
            cardAccountName: !isDetailsPageOpen && cardAccounts.length > 1,
            emission: !isDetailsPageOpen && featureModules.PLIANT_EARTH,
            transactionAmount: true,
            reviewStatus:
              !isDetailsPageOpen &&
              managerTransactionReviewEnabled &&
              featureModules.MANAGER_TX_REVIEWS,
            drawerPlaceholder: isDetailsPageOpen,
          }}
          rowSelectionModel={
            detailsParams?.transactionId ? [detailsParams.transactionId] : []
          }
          onRowClick={({ id }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${id}${location.search}`);
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.transactions.length && areFiltersApplied)
                return <NothingFound />;

              return (
                <NoData
                  isNewDesign
                  Icon={FileXIcon}
                  label={t('chTeamTransactionsPage.noTransactions')}
                  $top={90}
                />
              );
            },
            loadingOverlay: () => null,
          }}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
        />
        <Route
          path={`${path}/:transactionId`}
          children={({ match }) => (
            <TransactionDetailsPage
              open={!!match}
              isAdminApp={false}
              onUpdate={onTransactionDetailsUpdated}
              refetchMissingReceiptCount={getMissingReceiptsFiltersCounts}
            />
          )}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(CardholderTeamTransactionsPage, {
  permission: 'ch-team-transactions-page:visit',
  featureModule: FeatureModuleKey.teams,
});
