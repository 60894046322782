import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { isPartnerBasedSource } from 'domains/partner/utils';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  LabeledValue,
  StatusBadge,
  Switch,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer } from 'layout';
import { PartnerAudit, PartnerName, PartnerStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import ChangePartnerDialog from './ChangePartnerDialog';
import ChangePartnerIdDialog from './ChangePartnerIdDialog';

interface Props {
  partnerAudit: PartnerAudit | null;
  onSuccess: (partnerAudit: PartnerAudit) => void;
}

const PartnerSettingsSection = ({ partnerAudit, onSuccess }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const {
    state: { organization, partnerOrgAuthDetails, partners },
    dispatch,
  } = useGlobalState();
  const sourcePartnerConfig = useMemo(
    () =>
      (partnerAudit?.partnerId &&
        partners?.find((item) => item.partnerId === partnerAudit.partnerId)) ||
      null,
    [partnerAudit?.partnerId]
  );
  const revenueSharePartnerConfig = useMemo(
    () =>
      (partnerAudit?.partnerRevenueShareId &&
        partners?.find(
          (item) => item.partnerId === partnerAudit.partnerRevenueShareId
        )) ||
      null,
    [partnerAudit?.partnerRevenueShareId]
  );

  const [isChangePartnerDialogOpen, setIsChangePartnerDialogOpen] = useState(
    false
  );
  const [
    isChangePartnerIdDialogOpen,
    setIsChangePartnerIdDialogOpen,
  ] = useState(false);
  const [shareOnboardingStatus, setShareOnboardingStatus] = useState(
    partnerOrgAuthDetails?.shareOnboardingStatus || false
  );
  const [
    isShareOnboardingStatusLoading,
    setIsShareOnboardingStatusLoading,
  ] = useState(false);

  const changeShareOnboardingStatus = async (value: boolean) => {
    if (isShareOnboardingStatusLoading) return;
    try {
      setIsShareOnboardingStatusLoading(true);
      setShareOnboardingStatus(value);

      const updatedPartner = await api.changeShareOnboardingStatus(
        partnerOrgAuthDetails!.partnerId,
        organization!.id,
        value
      );

      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { partnerOrgAuthDetails: updatedPartner },
      });

      if (!mounted.current) return;
      setIsShareOnboardingStatusLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsShareOnboardingStatusLoading(false);
      setShareOnboardingStatus(
        partnerOrgAuthDetails?.shareOnboardingStatus || false
      );
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <ContentContainer py={5}>
        <Typography variant="h6" mb={1}>
          {t('int.generalTermsPage.partnerSettingsSection.title')}
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <LabeledValue
              label={t(
                'int.generalTermsPage.partnerSettingsSection.sourceLabel'
              )}
              value={
                <Box display="flex" alignItems="center">
                  {(
                    organization!.partnerRevenueShareName || PartnerName.pliant
                  ).toUpperCase()}
                  {revenueSharePartnerConfig?.status ===
                    PartnerStatus.inactive && (
                    <StatusBadge
                      label={t('common.badge.archived')}
                      color="default"
                      sx={{ ml: 1 }}
                    />
                  )}

                  <Box component="span" mx={1}>
                    -
                  </Box>

                  {(
                    organization!.partnerName || PartnerName.pliant
                  ).toUpperCase()}
                  {sourcePartnerConfig?.status === PartnerStatus.inactive && (
                    <StatusBadge
                      label={t('common.badge.archived')}
                      color="default"
                      sx={{ ml: 1 }}
                    />
                  )}
                </Box>
              }
              onEdit={
                canUser('partners:view') &&
                canUser('organization-partner:change')
                  ? () => setIsChangePartnerDialogOpen(true)
                  : undefined
              }
            />

            {partnerAudit?.assignedAt && (
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                mt={1}
              >
                {t('int.common.lastEditedBy', {
                  name: partnerAudit.userName,
                  time: moment(partnerAudit.assignedAt).format(
                    'DD MMM YYYY, HH:mm'
                  ),
                })}
              </Typography>
            )}
          </Grid>

          {partnerAudit && (
            <Grid item xs={6}>
              <LabeledValue
                label={t(
                  'int.generalTermsPage.partnerSettingsSection.partnerIdLabel'
                )}
                value={
                  partnerAudit.partnersOrganizationsAudit
                    ?.partnersOrganizationId || '—'
                }
                onEdit={
                  canUser('organization-partner:change')
                    ? () => setIsChangePartnerIdDialogOpen(true)
                    : undefined
                }
              />

              {partnerAudit.partnersOrganizationsAudit?.assignedAt && (
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                  mt={1}
                >
                  {t('int.common.lastEditedBy', {
                    name: partnerAudit.partnersOrganizationsAudit.userName,
                    time: moment(
                      partnerAudit.partnersOrganizationsAudit.assignedAt
                    ).format('DD MMM YYYY, HH:mm'),
                  })}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>

        {isPartnerBasedSource(organization!.partnerId) && (
          <FormControl
            variant="standard"
            disabled={
              typeof partnerOrgAuthDetails?.shareOnboardingStatus !==
                'boolean' || !canUser('share-onboarding-status:change')
            }
            sx={{ mt: 4 }}
          >
            <FormControlLabel
              checked={shareOnboardingStatus}
              onChange={(e, checked) => changeShareOnboardingStatus(checked)}
              control={<Switch size="small" />}
              label={t(
                'int.generalTermsPage.partnerSettingsSection.shareOnboardingStatusLabel'
              )}
              sx={{ ml: 0 }}
            />

            {!!partnerOrgAuthDetails?.latestSharingStatusAudit && (
              <FormHelperText>
                {t('int.common.lastEditedBy', {
                  name: partnerOrgAuthDetails.latestSharingStatusAudit.userName,
                  time: moment(
                    partnerOrgAuthDetails.latestSharingStatusAudit.assignedAt
                  ).format('DD MMM YYYY, HH:mm'),
                })}
              </FormHelperText>
            )}
          </FormControl>
        )}
      </ContentContainer>

      <Divider />

      <ChangePartnerDialog
        open={isChangePartnerDialogOpen}
        onClose={() => setIsChangePartnerDialogOpen(false)}
        onSuccess={onSuccess}
      />
      <ChangePartnerIdDialog
        partnersOrganizationId={
          partnerAudit?.partnersOrganizationsAudit?.partnersOrganizationId || ''
        }
        open={isChangePartnerIdDialogOpen}
        onClose={() => setIsChangePartnerIdDialogOpen(false)}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default PartnerSettingsSection;
