import { useTranslation } from 'react-i18next';
import { TransactionBadge } from 'components/Badge';
import FormatMoney from 'components/FormatMoney';
import { TransactionAmount } from 'domains/transaction/components';
import { Box } from 'elements';
import {
  AccountEntryTransactionInfo,
  ExportFlowTransaction,
  Transaction,
} from 'services/constants';
import { formatFXAmount } from 'services/utils';

type SameKeys =
  | 'status'
  | 'simpleType'
  | 'transactionAmount'
  | 'fxTransactionAmount';

type MergedTransactionData =
  | Pick<Transaction, SameKeys>
  | Pick<ExportFlowTransaction, SameKeys>
  | Pick<AccountEntryTransactionInfo, SameKeys>;

interface AmountCellProps {
  transaction: MergedTransactionData;
  bookingDate: string | null;
}

const TransactionAmountCell = ({
  transaction,
  bookingDate,
}: AmountCellProps) => {
  const { i18n } = useTranslation();

  return (
    <Box textAlign="right" overflow="hidden">
      <TransactionBadge
        status={transaction.status}
        simpleType={transaction.simpleType}
        bookingDate={bookingDate}
      />

      <TransactionAmount
        status={transaction.status}
        simpleType={transaction.simpleType}
      >
        <FormatMoney
          value={transaction.transactionAmount}
          fractionalPart
          positiveSign
        />
      </TransactionAmount>

      <TransactionAmount
        status={transaction.status}
        simpleType={transaction.simpleType}
        color="textSecondary"
        variant="caption"
      >
        {formatFXAmount(
          transaction.transactionAmount,
          transaction.fxTransactionAmount,
          i18n.language
        )}
      </TransactionAmount>
    </Box>
  );
};

export default TransactionAmountCell;
