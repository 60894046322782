import { Components, Theme } from '@mui/material';

const MuiStepperOverrides: Components<Theme> = {
  MuiStepLabel: {
    styleOverrides: {
      label: ({ theme }) => ({
        '&.Mui-active, &.Mui-completed': {
          ...theme.variables.typography.subtitle2,
        },
      }),
    },
  },
};

export default MuiStepperOverrides;
