import { useTranslation } from 'react-i18next';
import { StatusBadge, StatusBadgeProps } from 'elements';
import { env } from 'env';
import { PartialPaymentStatus } from 'services/constants';

const getColor = (status: PartialPaymentStatus): StatusBadgeProps['color'] => {
  if (status === PartialPaymentStatus.readyForPmt) return 'info';
  if (
    status === PartialPaymentStatus.pending ||
    status === PartialPaymentStatus.deferred
  )
    return 'secondary';
  if (
    status === PartialPaymentStatus.paid ||
    status === PartialPaymentStatus.booked
  )
    return 'success';
  if (
    status === PartialPaymentStatus.failed ||
    status === PartialPaymentStatus.reversed
  )
    return 'error';
  if (status === PartialPaymentStatus.delayed) return 'warning';

  return 'default';
};

const getVisibleStatus = (status: PartialPaymentStatus) => {
  if (
    env.REACT_APP_BUILD_TARGET === 'external' &&
    status === PartialPaymentStatus.delayed
  )
    return PartialPaymentStatus.pending;
  if (
    env.REACT_APP_BUILD_TARGET === 'external' &&
    status === PartialPaymentStatus.readyForPmt
  )
    return PartialPaymentStatus.upcoming;
  return status;
};

interface Props {
  status: PartialPaymentStatus;
}

const PartialPaymentStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();
  const visibleStatus = getVisibleStatus(status);
  const label = t(`batchPaymentStatuses.${visibleStatus}`);

  return <StatusBadge label={label} color={getColor(visibleStatus)} />;
};

export default PartialPaymentStatusBadge;
