import { useTranslation } from 'react-i18next';
import {
  MemberRoleOverline,
  MemberStatusBadge,
  MemberTypeOverline,
} from 'domains/member/components';
import { Box, StatusBadge, Typography } from 'elements';
import { LegalRepresentativeType, MemberDetails } from 'services/constants';

interface Props {
  member: MemberDetails;
}

const MemberDetailsHeader = ({ member }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Typography
          variant="h5"
          component="span"
        >{`${member.firstName} ${member.lastName}`}</Typography>

        <MemberRoleOverline
          roles={member.roles}
          ml={1}
          color="textSecondary"
          sx={{
            verticalAlign: 'top',
          }}
        />
        {member.embeddedPartnerMembership && (
          <MemberTypeOverline
            isNewDesign
            roles={member.roles}
            color="textSecondary"
            sx={{
              verticalAlign: 'top',
            }}
          />
        )}
      </Box>

      <Box mt={1}>
        <MemberStatusBadge status={member.status} />

        {member.legalRepresentative &&
          member.legalRepresentative !== LegalRepresentativeType.none && (
            <StatusBadge
              sx={{ marginLeft: 1 }}
              label={t('memberDetailsHeader.legalRep', {
                type: member.legalRepresentative,
              })}
            />
          )}
      </Box>
    </>
  );
};

export default MemberDetailsHeader;
