import React from 'react';
import FormatMoney from 'components/FormatMoney';
import { Typography, TypographyProps } from 'elements';
import { AccountEntry } from 'services/constants';

interface Props extends TypographyProps {
  accountEntry: AccountEntry;
}

const AmountCell = ({ accountEntry, ...props }: Props) => {
  return (
    <Typography
      variant="body2"
      noWrap
      {...props}
      color={accountEntry.amount.value > 0 ? 'textPrimary' : 'error.main'}
    >
      <FormatMoney value={accountEntry.amount} fractionalPart positiveSign />
    </Typography>
  );
};

export default AmountCell;
