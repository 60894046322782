import { Components, Theme } from '@mui/material';
import { omit } from 'lodash';

const MuiTextFieldOverrides: Components<Theme> = {
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
      size: 'small',
    },
  },
  MuiInputLabel: {
    defaultProps: {
      shrink: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.variables.components['input-label'],
        overflow: 'visible',
        transform: 'none !important', // TODO: overwrite MUI4 injected styles (TEMPORARY)
        top: theme.spacing(0.5),
        left: theme.spacing(0.5),
        '& + .MuiInputBase-root': {
          marginTop: theme.spacing(3),
        },
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        // The 24px line-height from a token increases the height of the select by 1px
        ...omit(theme.variables.components['input-text'], 'lineHeight'),

        // line-height for regular inputs should be set from a token
        'input.MuiInputBase-input': {
          lineHeight: theme.variables.components['input-text'].lineHeight,
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.variables.input.outlined.disabledBorder,
        },
      }),
      notchedOutline: ({ theme }) => ({
        borderColor: theme.variables.input.outlined.enabledBorder,
      }),
    },
  },
};

export default MuiTextFieldOverrides;
