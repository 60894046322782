import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, DataGridCell, GridColDef, StatusBadge } from 'elements';
import { CodatDataItemStatus, CodatSupplierItem } from 'services/constants';

const useColumns = (isPartialFlow?: boolean) => {
  const { t } = useTranslation();

  return useMemo(
    (): GridColDef<CodatSupplierItem>[] => [
      {
        field: 'name',
        headerName: t('codatSyncSetupDialog.suppliersStep.supplierNameLabel'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box display="flex" alignItems="center" overflow="hidden">
            <DataGridCell>{row.name}</DataGridCell>
            {isPartialFlow && row.status === CodatDataItemStatus.new && (
              <StatusBadge
                color="secondary"
                variant="filled"
                label={t('common.badge.new')}
                sx={{ ml: 1 }}
              />
            )}
          </Box>
        ),
      },
      {
        field: 'currency',
        headerName: t(
          'codatSyncSetupDialog.suppliersStep.supplierCurrencyLabel'
        ),
        sortable: false,
        flex: 0.6,
      },
    ],
    [isPartialFlow]
  );
};

export default useColumns;
