import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { isPartnerBasedSource } from 'domains/partner/utils';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';

const useSettingsTabs = () => {
  const { t } = useTranslation();
  const {
    state: { organization, accountingSettings, featureModules },
  } = useGlobalState();
  const { suppliersEnabled, datevExportEnabled } = useFlags();
  const canUser = useCanUser();

  if (!canUser('settings-page:visit')) return [];

  const isEmbeddedPartnerPageUsed = !featureModules.INTEGRATIONS_PAGE;

  const orgHasSourcePartner =
    !!organization && isPartnerBasedSource(organization.partnerId);

  const showPartnerPage =
    canUser('partners:view') &&
    orgHasSourcePartner &&
    isEmbeddedPartnerPageUsed;

  const accountingSubPagesAllowed =
    featureModules.ACCOUNTING_FEATURES &&
    (canUser('accounting-sub-page:visit') ||
      canUser('categories-sub-page:visit') ||
      canUser('vat-rates-sub-page:visit') ||
      canUser('projects-sub-page:visit') ||
      (suppliersEnabled && canUser('suppliers-sub-page:visit')) ||
      (datevExportEnabled &&
        canUser('datev-sub-page:visit', accountingSettings)));

  return [
    {
      label: t('navigation.settings.organization'),
      pathId: 'settingsOrganization',
      testId: 'org-details-sub-tab',
      intercomTarget: 'sidebar-settings-org-button',
    },
    ...(accountingSubPagesAllowed
      ? [
          {
            label: t('navigation.settings.accounting'),
            pathId: 'settingsAccounting',
            testId: 'accouting-sub-tab',
            intercomTarget: 'sidebar-settings-accounting-button',
          },
        ]
      : []),
    ...(canUser('feature-modules-subtab:visit')
      ? [
          {
            label: t('navigation.settings.featureModules'),
            pathId: 'settingsFeatureModules',
            testId: 'feature-modules-sub-tab',
            intercomTarget: 'sidebar-settings-feature-modules-button',
          },
        ]
      : []),
    ...(!showPartnerPage && canUser('integrations-settings-page:visit')
      ? [
          {
            label: t('navigation.settings.integrations'),
            pathId: 'settingsIntegrations',
            testId: 'integrations-sub-tab',
            intercomTarget: 'sidebar-settings-integrations-button',
          },
        ]
      : []),
  ];
};

export default useSettingsTabs;
