import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  LoaderWithOverlay,
  Paper,
  Switch,
  Tooltip,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, PageContent, PageHeader, PageTitle } from 'layout';
import {
  Cashback,
  CurrencyCashbackByCurrencyMap,
  FeatureModuleKey,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, mapFeatureModules } from 'services/utils';
import CashbackRatesSection from './CashbackRatesSection';
import CashbackThresholdSection from './CashbackThresholdSection';
import CustomCashbackAmountSection from './CustomCashbackAmountSection';

interface State {
  cashback: Cashback | null;
  cashbackByCurrency: CurrencyCashbackByCurrencyMap | null;
  isLoading: boolean;
  isUpdating: boolean;
  isConfirmDialogOpen: boolean;
}

const CashbackSettingsPage = () => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const showPageError = useShowPageError();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const {
    state: { organization, featureModules, featureModulesWithData },
    dispatch,
  } = useGlobalState();
  const [state, setState] = useState<State>({
    cashback: null,
    cashbackByCurrency: null,
    isLoading: true,
    isUpdating: false,
    isConfirmDialogOpen: false,
  });
  const confirmDialogRef = useRef<{ title: string; description: ReactNode }>({
    title: '',
    description: null,
  });

  const getData = async () => {
    try {
      const [cashback, cashbackByCurrency] = await Promise.all([
        api.getCashback(organization!.id),
        api.getCashbackByCurrency(organization!.id),
      ]);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        cashback,
        cashbackByCurrency,
        isLoading: false,
      }));
    } catch (error) {
      showPageError();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const confirmCashbackToggle = () => {
    if (featureModules.PLIANT_EARTH && featureModules.CASHBACK) {
      enqueueSnackbar(t('int.cashbackSettingsPage.pliantEarthIsActiveError'), {
        variant: 'error',
      });
    } else {
      if (featureModules.CASHBACK) {
        confirmDialogRef.current = {
          title: t('int.cashbackSettingsPage.deactivateConfirmDialog.title', {
            orgName: organization!.name,
          }),
          description: (
            <Trans
              i18nKey="int.cashbackSettingsPage.deactivateConfirmDialog.description"
              components={{
                br: <br />,
                ul: <ul />,
                li: <li />,
              }}
            />
          ),
        };
      } else {
        confirmDialogRef.current = {
          title: t('int.cashbackSettingsPage.activateConfirmDialog.title', {
            orgName: organization!.name,
          }),
          description: t(
            'int.cashbackSettingsPage.activateConfirmDialog.description'
          ),
        };
      }
      setState((prevState) => ({
        ...prevState,
        isConfirmDialogOpen: true,
      }));
    }
  };

  const toggleCashback = async () => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      const data = await api.toggleInternalFeatureModule(organization!.id, {
        feature: FeatureModuleKey.cashback,
        enabled: !featureModules.CASHBACK,
      });
      dispatch({
        type: 'SET_FEATURE_MODULES',
        payload: mapFeatureModules(data),
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isConfirmDialogOpen: false,
        isUpdating: false,
      }));
      enqueueSnackbar(
        t(
          featureModules.CASHBACK
            ? 'int.cashbackSettingsPage.successToast.disabled'
            : 'int.cashbackSettingsPage.successToast.enabled',
          { orgName: organization!.name }
        )
      );
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isUpdating: false }));
      logError(error);
    }
  };

  return (
    <>
      <PageHeader>
        <PageTitle
          title={t('int.cashbackSettingsPage.title')}
          suptitle={t('int.sidebar.terms.title')}
        />
      </PageHeader>
      <PageContent>
        <ContentContainer>
          {state.cashback && state.cashbackByCurrency && (
            <Paper variant="tinted">
              <Tooltip
                title={
                  featureModulesWithData.CASHBACK.disabledByPartner &&
                  t('int.cashbackSettingsPage.disabledOnPartnerLevelTooltip')
                }
              >
                <FormControl fullWidth sx={{ px: 3, py: 2 }}>
                  <FormControlLabel
                    checked={featureModules.CASHBACK}
                    onChange={confirmCashbackToggle}
                    disabled={
                      !canUser('internal-feature-module:toggle') ||
                      featureModulesWithData.CASHBACK.disabledByPartner
                    }
                    control={<Switch />}
                    label={t('int.cashbackSettingsPage.cashback')}
                    labelPlacement="start"
                  />
                </FormControl>
              </Tooltip>

              <Collapse in={featureModules.CASHBACK}>
                <Box
                  p={3}
                  borderTop={(theme) => `1px solid ${theme.palette.divider}`}
                >
                  <CashbackThresholdSection
                    cashback={state.cashback}
                    onUpdate={(cashback) =>
                      setState((prevState) => ({ ...prevState, cashback }))
                    }
                  />

                  <CashbackRatesSection
                    cashback={state.cashback}
                    onUpdate={(cashback) =>
                      setState((prevState) => ({ ...prevState, cashback }))
                    }
                  />

                  <CustomCashbackAmountSection
                    cashbackByCurrency={state.cashbackByCurrency}
                  />
                </Box>
              </Collapse>
            </Paper>
          )}
        </ContentContainer>
        <LoaderWithOverlay loading={state.isLoading} />
      </PageContent>

      <ConfirmDialog
        open={state.isConfirmDialogOpen}
        title={confirmDialogRef.current.title}
        description={confirmDialogRef.current.description}
        loading={state.isUpdating}
        onSuccess={toggleCashback}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isConfirmDialogOpen: false,
          }))
        }
      />
    </>
  );
};

export default withPageConfig(CashbackSettingsPage, {
  permission: 'cashback-settings-page:visit',
});
