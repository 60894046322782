import React from 'react';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { CardIconWithDetails } from 'domains/card/components';
import { IntegrationTypeIcon } from 'domains/partner/components';
import { Box, Tooltip, Typography } from 'elements';
import {
  Card,
  CardType,
  CirculaConnectionType,
  IntegrationType,
} from 'services/constants';
import BalanceLimit from './BalanceLimit';

interface Props {
  card: Card;
}

const CardSummary = ({ card }: Props) => {
  const { t } = useTranslation();
  const {
    state: { organizationIntegrations },
  } = useGlobalState();

  const renderHeader = () => {
    if (card.integrationType === IntegrationType.circula) {
      return (
        <Box pr={3} position="relative">
          <CardIconWithDetails
            cardConfig={card.cardConfig}
            cardDesignId={card.cardDesignId}
            cardRefNum={card.cardRefNum}
            cardName={card.cardName}
          />
          {organizationIntegrations?.syncSettings?.orgConnectionType ===
            CirculaConnectionType.partial && (
            <Tooltip title={t('tooltips.circulaSynced')}>
              <IntegrationTypeIcon
                integrationType={IntegrationType.circula}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: 16,
                  height: 16,
                }}
              />
            </Tooltip>
          )}
        </Box>
      );
    }
    return (
      <CardIconWithDetails
        cardConfig={card.cardConfig}
        cardDesignId={card.cardDesignId}
        cardRefNum={card.cardRefNum}
        cardName={card.cardName}
      />
    );
  };

  return (
    <>
      {renderHeader()}
      {card.type === CardType.singleUse ? (
        <Typography variant="body2" mt={2} mb={0.5}>
          <FormatMoney value={card.transactionLimit} />
        </Typography>
      ) : (
        <BalanceLimit availableLimit={card.availableLimit} limit={card.limit} />
      )}
    </>
  );
};

export default CardSummary;
