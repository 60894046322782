import { useMemo } from 'react';
import { Chip, Tooltip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { getFilterChipText, PageFilterChips } from 'layout';
import { QueryParams } from '.././utils';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
}

const FilterChips = ({ params, selectedFiltersCount, setParam }: Props) => {
  const periodChipText = useMemo(
    () =>
      [params.fromDate, params.toDate]
        .map((val) => val?.format('D MMM YYYY'))
        .filter((val) => !!val)
        .join(' - '),
    [params.fromDate, params.toDate]
  );

  const paymentTypeChipTooltip = useMemo(() => {
    if (params.paymentType.length < 2) return '';
    return params.paymentType
      .map((item) => item.replace(/_/g, ' '))
      .join(', ')
      .toUpperCase();
  }, [params.paymentType]);

  const transactionTypeChipTooltip = useMemo(() => {
    if (params.transactionType.length < 2) return '';
    return params.transactionType
      .map((item) => item.replace(/_/g, ' '))
      .join(', ')
      .toUpperCase();
  }, [params.transactionType]);

  if (!selectedFiltersCount) return null;

  return (
    <PageFilterChips>
      {(params.fromDate || params.toDate) && (
        <Chip
          label={periodChipText}
          onDelete={() =>
            setParam([
              ['toDate', ''],
              ['fromDate', ''],
            ])
          }
        />
      )}

      {!!params.paymentType.length && (
        <Tooltip title={paymentTypeChipTooltip}>
          <Chip
            label={getFilterChipText(
              params.paymentType[0].replace(/_/g, ' '),
              params.paymentType.length
            )}
            onDelete={() => setParam('paymentType', [])}
          />
        </Tooltip>
      )}

      {!!params.transactionType.length && (
        <Tooltip title={transactionTypeChipTooltip}>
          <Chip
            label={getFilterChipText(
              params.transactionType[0].replace(/_/g, ' '),
              params.transactionType.length
            )}
            onDelete={() => setParam('transactionType', [])}
          />
        </Tooltip>
      )}
    </PageFilterChips>
  );
};

export default FilterChips;
