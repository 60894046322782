import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from 'elements';
import {
  Card,
  CardManufacturer,
  CardReplacementReason,
  CardSimpleStatus,
  CardType,
} from 'services/constants';

interface Props {
  card: Card | null;
  isCardholderApp?: boolean;
}

const CardInReplacementBox = ({ card, isCardholderApp = false }: Props) => {
  const { t } = useTranslation();

  if (
    card &&
    [CardType.black, CardType.plastic].includes(card.type) &&
    card.replacedByCardId &&
    card.replacementReason &&
    [
      CardReplacementReason.expiringSoon,
      CardReplacementReason.other,
      CardReplacementReason.damaged,
    ].includes(card.replacementReason) &&
    card.simpleStatus !== CardSimpleStatus.terminated
  ) {
    return (
      <Alert
        severity="warning"
        sx={{ mt: isCardholderApp ? 2 : -1, mb: isCardholderApp ? 0 : 3 }}
      >
        <AlertTitle>{t('cardDetails.cardInReplacementBox.title')}</AlertTitle>
        {card.manufacturer === CardManufacturer.exceet
          ? t('cardDetails.cardInReplacementBox.description')
          : t(
              'cardDetails.cardInReplacementBox.descriptionLastDigitsActivation'
            )}
      </Alert>
    );
  }

  return null;
};

export default CardInReplacementBox;
