import { useTranslation } from 'react-i18next';
import { MerchantLogo } from 'domains/merchant/components';
import {
  Box,
  ChartPieIcon,
  CubeIcon,
  PercentIcon,
  Tooltip,
  Typography,
} from 'elements';
import { MerchantStatus, OrganizationMerchant } from 'services/constants';

interface Props {
  merchant: OrganizationMerchant;
}

const OrganizationMerchantCell = ({ merchant }: Props) => {
  const { t } = useTranslation();
  const [firstCategory, ...otherCategories] = merchant.transactionCategories;

  return (
    <Box display="flex" alignItems="center" overflow="hidden">
      <MerchantLogo
        url={
          merchant.status === MerchantStatus.published
            ? merchant.logoPath
            : null
        }
        name={merchant.displayName}
      />
      <Box overflow="hidden" ml={2}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" noWrap>
            {merchant.displayName}
          </Typography>
          {!!merchant.supplierResponse && (
            <Tooltip
              title={t('accountingEntryDetailsPage.accRuleSupplierTooltip')}
            >
              <ChartPieIcon fontSize="small" sx={{ ml: 0.5 }} />
            </Tooltip>
          )}
          {!!merchant.subcategoryResponse && (
            <Tooltip
              title={t('accountingEntryDetailsPage.accRuleSubcategoryTooltip')}
            >
              <CubeIcon fontSize="small" sx={{ ml: 0.5 }} />
            </Tooltip>
          )}
          {!!merchant.vatRateResponse && (
            <Tooltip
              title={t('accountingEntryDetailsPage.accRuleVatRateTooltip')}
            >
              <PercentIcon fontSize="small" sx={{ ml: 0.5 }} />
            </Tooltip>
          )}
        </Box>
        {!!merchant.transactionCategories.length && (
          <Box display="flex">
            <Typography variant="caption" color="textSecondary" noWrap>
              {t(`merchantCategories.${firstCategory}`)}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {otherCategories.length ? `, +${otherCategories.length}` : ''}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OrganizationMerchantCell;
