import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { adminPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import { CardIcon } from 'domains/card/components';
import { getCardName } from 'domains/card/utils';
import {
  Box,
  Button,
  Collapse,
  FormControl,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import useSnackbar from 'hooks/useSnackbar';
import {
  CardConfig,
  CardConfigGroup,
  CardConfigSetting,
  CardCountAndLimit,
  CardCountAndLimitByConfigMap,
  CardIssuanceUnavailableReason,
  CardNewType,
  CardPremiumProductType,
  PartnerIds,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { CardNameWrapper, Label, SubtypeBox } from './style';

const getTooltipKey = (
  cardConfigSetting: CardConfigSetting,
  cardCountAndLimit: CardCountAndLimit | null
) => {
  if (!cardCountAndLimit?.unavailableReason) return '';

  const base = 'cardPicker.disabledTooltips.';

  if (
    cardCountAndLimit.unavailableReason ===
    CardIssuanceUnavailableReason.organizationLimitReached
  ) {
    const customKey = `${base}customByConfig.${cardConfigSetting.cardConfigGroup}`;

    if (i18next.exists(customKey)) return customKey;

    if (cardConfigSetting.cardType === CardNewType.virtual) {
      return (
        base +
        (cardConfigSetting.maxUsage === 1 ? 'virtualSingleUse' : 'virtual')
      );
    }
  }

  if (
    cardCountAndLimit.unavailableReason ===
      CardIssuanceUnavailableReason.memberLimitReached &&
    cardConfigSetting.cardType === CardNewType.physical
  )
    return base + 'physical';

  return '';
};

const getDescriptionPartialKey = (cardConfigSetting: CardConfigSetting) => {
  if (cardConfigSetting.cardType === CardNewType.virtual) {
    if (
      cardConfigSetting.cardConfigGroup === CardConfigGroup.pliantVirtualTravel
    )
      return 'virtualTravel';

    return cardConfigSetting.maxUsage === 1 ? 'virtualSingleUse' : 'virtual';
  }

  return cardConfigSetting.premiumProductType ===
    CardPremiumProductType.visaInfinite
    ? 'premium'
    : 'standard';
};

interface State {
  isLoading: boolean;
  cardCountsAndLimits: CardCountAndLimitByConfigMap | null;
  expandedByConfig: { [K in CardConfig]?: boolean };
}

interface Props {
  items: CardConfigSetting[];
  travelCardSubtypes: CardConfigSetting[];
  value: CardConfigSetting | null;
  onChange: (value: CardConfigSetting) => void;
  memberId: string | null;
  isCardRequestFlow?: boolean;
}

const CardPicker = ({
  items,
  travelCardSubtypes,
  value,
  onChange,
  memberId,
  isCardRequestFlow,
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const partnerName = usePartnerName();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, partnerConfig },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
    cardCountsAndLimits: null,
    expandedByConfig: {},
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const data = await api.getCardCountsAndLimits({
        organizationId: organization!.id,
        memberId: memberId!,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        cardCountsAndLimits: data.countsAndLimits,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (memberId) {
      getData();
    } else {
      setState((prevState) => ({ ...prevState, cardCountsAndLimits: null }));
    }
  }, [memberId]);

  const renderCountLabel = (
    cardConfigSetting: CardConfigSetting,
    cardCountAndLimit: CardCountAndLimit | null
  ) => {
    if (
      isCardRequestFlow ||
      cardConfigSetting.cardType !== CardNewType.virtual ||
      !cardCountAndLimit ||
      cardCountAndLimit.max === null
    )
      return null;

    const isSelected =
      cardConfigSetting.cardConfigGroup === value?.cardConfigGroup;

    return (
      <Box component="span" sx={{ ml: 1, color: 'text.secondary' }}>
        ({cardCountAndLimit.issued + +isSelected}/{cardCountAndLimit.max})
      </Box>
    );
  };

  return (
    <>
      <RadioGroup
        value={value?.cardConfigGroup || ''}
        onChange={(e) =>
          onChange(
            items.find(
              (cardConfigSetting) =>
                cardConfigSetting.cardConfigGroup === e.target.value
            )!
          )
        }
      >
        {items.map((cardConfigSetting) => {
          const id = `issue-card-${cardConfigSetting.cardConfigGroup}-radio-btn`;
          const { cardConfigGroup } = cardConfigSetting;
          const descriptionKey = getDescriptionPartialKey(cardConfigSetting);
          const cardCountAndLimit = state.cardCountsAndLimits
            ? state.cardCountsAndLimits[cardConfigGroup]!
            : null;
          const isDisabled =
            !cardCountAndLimit || !!cardCountAndLimit.unavailableReason;

          return (
            <Tooltip
              key={cardConfigGroup}
              title={t(getTooltipKey(cardConfigSetting, cardCountAndLimit))}
              placement="top"
            >
              <Label htmlFor={id} $disabled={isDisabled}>
                <Box display="flex" alignItems="center" padding={2}>
                  <CardIcon cardDesignId={cardConfigSetting.cardDesignIds[0]} />
                  <CardNameWrapper>
                    <Typography component="span">
                      {getCardName(cardConfigSetting.cardConfig, true)}
                      {renderCountLabel(cardConfigSetting, cardCountAndLimit)}
                    </Typography>
                  </CardNameWrapper>
                  <Button
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        expandedByConfig: {
                          ...prevState.expandedByConfig,
                          [cardConfigGroup]: !prevState.expandedByConfig[
                            cardConfigGroup
                          ],
                        },
                      }))
                    }
                    variant="text"
                    sx={{ flexShrink: 0 }}
                  >
                    {state.expandedByConfig[cardConfigGroup]
                      ? t('cardPicker.hide')
                      : t('cardPicker.learnMore')}
                  </Button>
                  <Radio
                    id={id}
                    value={cardConfigGroup}
                    disabled={isDisabled}
                  />
                </Box>
                <Collapse in={state.expandedByConfig[cardConfigGroup]}>
                  <Box px={2} pb={2}>
                    <Typography variant="body2" mb={1}>
                      {t(`cardPicker.descriptions_v2.${descriptionKey}.p1`)}
                    </Typography>
                    <Typography variant="body2" mb={1}>
                      {t(`cardPicker.descriptions_v2.${descriptionKey}.p2`, {
                        partnerName,
                      })}
                    </Typography>
                    {i18next.exists(
                      `cardPicker.descriptions_v2.${descriptionKey}.p3`
                    ) &&
                      partnerConfig?.partnerId === PartnerIds.pliant &&
                      !isCardRequestFlow && (
                        <Typography variant="body2" mb={1}>
                          <Trans
                            i18nKey={`cardPicker.descriptions_v2.${descriptionKey}.p3`}
                            components={{
                              a: (
                                <NavLink to={adminPaths.rewardsCardBenefits} />
                              ),
                            }}
                          />
                        </Typography>
                      )}
                  </Box>
                </Collapse>
                {value?.cardConfigGroup ===
                  CardConfigGroup.pliantVirtualTravel &&
                  cardConfigSetting.cardConfigGroup ===
                    CardConfigGroup.pliantVirtualTravel &&
                  travelCardSubtypes.length > 1 && (
                    <SubtypeBox>
                      <FormControl fullWidth>
                        <InputLabel>
                          {t('cardPicker.subtypeDescription')}
                        </InputLabel>
                        <Select
                          value={value.cardConfig}
                          onChange={(e) => {
                            onChange(
                              travelCardSubtypes.find(
                                (cardConfigSetting) =>
                                  cardConfigSetting.cardConfig ===
                                  e.target.value
                              )!
                            );
                          }}
                        >
                          {travelCardSubtypes.map((item) => (
                            <MenuItem
                              key={item.cardConfig}
                              value={item.cardConfig}
                            >
                              {t(`cardNamesForSubtypes.${item.cardConfig}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </SubtypeBox>
                  )}
              </Label>
            </Tooltip>
          );
        })}
      </RadioGroup>
      <LoaderWithOverlay loading={state.isLoading} />
    </>
  );
};

export default CardPicker;
