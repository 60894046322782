import { Components, Theme } from '@mui/material';

const MuiFormHelperTextOverrides: Components<Theme> = {
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.variables.components['helper-text'],
      }),
      contained: {
        marginLeft: 4,
      },
    },
  },
};

export default MuiFormHelperTextOverrides;
