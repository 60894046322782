import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import RestrictionBadge from 'domains/card/components/CardControlIndicator/RestrictionBadge';
import { MerchantCategoryIcon } from 'domains/merchant/components';
import { Tooltip, Typography } from 'elements';
import {
  CardCategoryControl,
  CardCategoryControlType,
  MerchantCategory,
} from 'services/constants';
import { Header, StyledChip, Wrapper } from './style';

interface Props {
  control: CardCategoryControl | null;
}

const CardCategoryControlIndicator = ({ control }: Props) => {
  const { t } = useTranslation();

  if (!control) return null;
  const { restriction, type, values, displayValues } = control;

  return (
    <Wrapper>
      <Header>
        <Typography variant="subtitle2">
          {t(`cardControlIndicator.category.titles.${type}`)}
        </Typography>
        <RestrictionBadge restriction={restriction} />
      </Header>

      {type === CardCategoryControlType.category &&
        values.map((item) => {
          const category = item as MerchantCategory;
          return (
            <Tooltip key={category} title={t(`merchantCategories.${category}`)}>
              <StyledChip
                label={t(`merchantCategories.${category}`)}
                icon={
                  <MerchantCategoryIcon category={category} fontSize="small" />
                }
              />
            </Tooltip>
          );
        })}
      {type === CardCategoryControlType.mcc &&
        displayValues!.map((mcc) => (
          <Tooltip key={mcc.code} title={`${mcc.code} ${mcc.name}`}>
            <StyledChip label={`${mcc.code} ${mcc.name}`} />
          </Tooltip>
        ))}
    </Wrapper>
  );
};

export default memo(CardCategoryControlIndicator);
