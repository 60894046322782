import React from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { FIXED_DATE_FORMAT } from 'domains/card/utils';
import { Box, InputLabel, TextField } from 'elements';

interface Props {
  testId?: string;
  disabled?: boolean;
  startDate: Moment | null;
  endDate: Moment | null;
  onDatesChange: (dates: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
}

export const SplittedDateRangePicker = ({
  testId,
  startDate,
  endDate,
  onDatesChange,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const formattedStartDate = startDate
    ? moment(startDate, FIXED_DATE_FORMAT).format('YYYY-MM-DD')
    : '';
  const formattedEndDate = endDate
    ? moment(endDate, FIXED_DATE_FORMAT).format('YYYY-MM-DD')
    : '';

  const handleStartDateChange = (e: any) => {
    onDatesChange({
      startDate: moment(e.target.value, 'YYYY-MM-DD'),
      endDate: null,
    });
  };

  const handleEndDateChange = (e: any) => {
    onDatesChange({
      startDate: moment(formattedStartDate, 'YYYY-MM-DD'),
      endDate: moment(e.target.value, 'YYYY-MM-DD'),
    });
  };

  return (
    <Box data-test-id={testId}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box flex={1} mr={1}>
          <InputLabel>{t('common.from')}</InputLabel>
          <TextField
            sx={{ marginTop: 1.5 }}
            type="date"
            value={formattedStartDate || ''}
            onChange={handleStartDateChange}
            disabled={disabled}
          />
        </Box>
        <Box flex={1} ml={1}>
          <InputLabel>{t('common.to')}</InputLabel>
          <TextField
            sx={{ marginTop: 1.5 }}
            type="date"
            value={formattedEndDate || ''}
            onChange={handleEndDateChange}
            disabled={disabled || !formattedStartDate}
            inputProps={{
              min: formattedStartDate,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
