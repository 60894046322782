import { useGlobalState } from 'context/GlobalState';
import { organizationOnboardingStatuses } from 'services/constants';

const useIsOrgInOnboarding = () => {
  const {
    state: { organization },
  } = useGlobalState();

  return (
    !!organization &&
    organizationOnboardingStatuses.includes(organization.status)
  );
};

export default useIsOrgInOnboarding;
