import { useTranslation } from 'react-i18next';
import { Chip, ChipProps, ManagerRoundFillIcon, Tooltip } from 'elements';

const TeamManagerIcon = () => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('tooltips.managerForTeam')}>
      <ManagerRoundFillIcon
        sx={{
          width: 16,
          height: 16,
          color: 'text.secondary',
          ml: '-4px',
          mr: '4px',
        }}
      />
    </Tooltip>
  );
};

interface Props extends ChipProps {
  name: string;
  isManager?: boolean;
}

const TeamBadge = ({ name, isManager = false, sx = [], ...props }: Props) => {
  return (
    <Chip
      label={name}
      icon={isManager ? <TeamManagerIcon /> : undefined}
      size="small"
      sx={[
        { flexDirection: 'row-reverse', mr: 1 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  );
};

export default TeamBadge;
