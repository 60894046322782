import moment from 'moment';
import { CardDateControlType } from 'services/constants';

export const FIXED_DATE_FORMAT = 'DD.MM.YYYY';
export const YEARLY_DATE_FORMAT = 'DD.MM';
export const MONTHLY_DATE_FORMAT = 'DD';

export const decodeValuesToDates = (
  type: CardDateControlType | null,
  values: string[]
) => {
  if (!type || !values.length)
    return {
      startDate: null,
      endDate: null,
    };

  switch (type) {
    case CardDateControlType.fixed:
      return {
        startDate:
          values[0].split('-')[0] !== 'undefined'
            ? moment(values[0].split('-')[0], FIXED_DATE_FORMAT)
            : null,
        endDate:
          values[0].split('-')[1] !== 'undefined'
            ? moment(values[0].split('-')[1], FIXED_DATE_FORMAT)
            : null,
      };

    case CardDateControlType.yearly:
      return {
        startDate:
          values[0].split('-')[0] !== 'undefined'
            ? values[0].split('-')[0]
            : null,
        endDate:
          values[0].split('-')[1] !== 'undefined'
            ? values[0].split('-')[1]
            : null,
      };

    case CardDateControlType.monthly: {
      const [fromDay, toDay] = values[0].split('-');

      return {
        startDate: fromDay !== 'undefined' ? fromDay : null,
        endDate: toDay !== 'undefined' ? toDay : null,
      };
    }

    case CardDateControlType.weekly:
      // Capitalise all values to match the format of the values in the date picker
      return values.map((item) => item.charAt(0).toUpperCase() + item.slice(1));

    default:
      return values;
  }
};

export const decodeMonthlyValue = (value: string | null) => {
  if (!value) return value;

  if (value === 'last') {
    return '29';
  }

  return value;
};

export const encodeMonthlyValue = (value: string | null) => {
  if (!value) return value;

  if (value === '29') {
    return 'last';
  }

  return value;
};

export const doubleDigitFormat = (day: string | null) => {
  const dayInt = parseInt(day || '', 10);
  return dayInt < 10 ? `0${dayInt}` : day || '';
};

export * from './getCardName';
