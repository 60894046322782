import { ChangeEvent, FormEvent, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  LoaderWithOverlay,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import {
  filterNonPrintableChars,
  getPrintableLength,
} from 'services/utils/printableString';

interface Props extends DialogProps {
  onClose: () => void;
  noPreselect?: boolean;
}

interface State {
  hasTradeName: boolean | undefined;
  tradeName: string;
  isLoading: boolean;
}

const TradeNameChangeDialog = ({ noPreselect = false, ...props }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const { id: organizationId, tradeName: initialTradeName } = useMemo(
    () => organization!,
    []
  );

  const [state, setState] = useState<State>({
    hasTradeName:
      noPreselect && !initialTradeName ? undefined : !!initialTradeName,
    tradeName: initialTradeName || '',
    isLoading: false,
  });

  const nonPrintableChars = filterNonPrintableChars(state.tradeName);
  const isStringPrintable = nonPrintableChars.length === 0;
  const printableLength = getPrintableLength(state.tradeName);

  const changeTradeName = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const organizationData = await api.updateTradeName(
        organizationId,
        state.tradeName.trim() || null
      );
      if (!mounted.current) return;

      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { organization: organizationData },
      });

      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const onRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (value === 'no') {
      setState((prevState) => ({
        ...prevState,
        tradeName: '',
        hasTradeName: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        tradeName: initialTradeName || prevState.tradeName,
        hasTradeName: true,
      }));
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (state.isLoading) return;
    changeTradeName();
  };

  const onTradeNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, tradeName: e.target.value }));
  };

  const isTooLong = printableLength > 20;
  const isAtLeastOneChar = !!printableLength;

  const renderError = () => {
    if (!isStringPrintable) {
      return (
        <Trans
          i18nKey="errors.charsNotPrintable"
          values={{
            nonPrintableChars: nonPrintableChars.join(', '),
          }}
        />
      );
    }

    if (state.tradeName && isTooLong) {
      return (
        <Trans
          i18nKey="errors.stringTooLong"
          values={{
            maxLength: 20,
          }}
        />
      );
    }

    return null;
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('common.tradeName')}</DialogTitle>

      <DialogContent>
        {!organization!.tradeNameRequired && (
          <>
            <Typography mb={1}>{t('common.optionalTradeNameLabel')}</Typography>

            <FormControl disabled={state.isLoading} sx={{ mb: 1 }}>
              <RadioGroup
                onChange={onRadioChange}
                defaultValue={
                  state.hasTradeName === undefined
                    ? undefined
                    : state.hasTradeName
                    ? 'yes'
                    : 'no'
                }
                row
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={t('common.yes')}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label={t('common.no')}
                />
              </RadioGroup>
            </FormControl>
          </>
        )}

        {(organization!.tradeNameRequired || state.hasTradeName) && (
          <form onSubmit={onSubmit} id="trade-name-change-form" noValidate>
            <TextField
              label={t('common.tradeName')}
              name="tradeName"
              value={state.tradeName}
              onChange={onTradeNameChange}
              inputProps={{ readOnly: state.isLoading }}
              autoFocus
              error={!isStringPrintable || !!(state.tradeName && isTooLong)}
              helperText={renderError()}
            />
            {organization!.tradeNameRequired && (
              <FormHelperText>{t('common.tradeNameRequired')}</FormHelperText>
            )}
          </form>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          disabled={state.isLoading}
          onClick={props.onClose}
        >
          {t('common.button.cancel')}
        </Button>

        {organization!.tradeNameRequired || state.hasTradeName ? (
          <>
            <Button
              form="trade-name-change-form"
              type="submit"
              disabled={
                !isAtLeastOneChar ||
                isTooLong ||
                state.isLoading ||
                !isStringPrintable
              }
              data-test-id="trade-name-submit"
            >
              {t('common.button.save')}
            </Button>
          </>
        ) : (
          <Button
            onClick={changeTradeName}
            disabled={state.isLoading || state.hasTradeName === undefined}
          >
            {t('common.button.save')}
          </Button>
        )}
      </DialogActions>

      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(TradeNameChangeDialog);
