import FormatMoney from 'components/FormatMoney';
import { DataGridCell, Typography } from 'elements';
import { Card, CardSimpleStatus } from 'services/constants';

interface Props {
  card: Card;
}

const TransactionLimitCell = ({ card }: Props) => {
  if (card.simpleStatus === CardSimpleStatus.requested) {
    return (
      <Typography variant="inherit" color="info.main" noWrap>
        <FormatMoney value={card.transactionLimit} />
      </Typography>
    );
  }

  return (
    <DataGridCell sx={{ textAlign: 'right' }}>
      {card.requestedTransactionLimit && (
        <Typography variant="caption2" component="div" color="info.main" noWrap>
          <FormatMoney value={card.requestedTransactionLimit} />
        </Typography>
      )}
      <Typography variant="inherit" noWrap>
        <FormatMoney value={card.transactionLimit} />
      </Typography>
    </DataGridCell>
  );
};

export default TransactionLimitCell;
