import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { ReceiptInboxStatusBadge } from 'domains/organization/components';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  ReceiptIcon,
  Typography,
} from 'elements';
import {
  ReceiptAutoMatching,
  ReceiptsAutoMatchingTagGroup,
} from 'services/constants';
import ReceiptInboxGridThumbnail from './ReceiptInboxGridThumbnail';

interface Props {
  isLoading: boolean;
  receipts: ReceiptAutoMatching[];
  thumbnails: { [key: string]: string | null };
  isThumbnailLoading: boolean;
  hasNextPage: boolean;
  selectedReceiptId: string;
  loadMoreItems: () => void;
  setSelectedReceipt: (receiptId: string, isPreviewOpen?: boolean) => void;
}

const ReceiptInboxGrid = ({
  isLoading,
  receipts,
  thumbnails,
  isThumbnailLoading,
  hasNextPage,
  selectedReceiptId,
  loadMoreItems,
  setSelectedReceipt,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box overflow="auto" height="100%">
      <Grid container spacing={1} sx={{ py: 2 }}>
        {receipts.map((receipt) => (
          <Grid item key={receipt.id}>
            <Card
              sx={{
                width: 320,
                cursor: 'pointer',
                position: 'relative',
                height: '100%',
                minHeight: 326,
                borderColor: (theme) =>
                  selectedReceiptId === receipt.id
                    ? theme.variables.primary.main
                    : theme.palette.divider,
              }}
              variant="outlined"
              onClick={() => setSelectedReceipt(receipt.id)}
            >
              <CardContent>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2">
                    {moment(receipt.createdAt).format('D MMM YYYY, HH:mm')}
                  </Typography>
                  <ReceiptInboxStatusBadge
                    status={receipt.status}
                    sx={{ ml: 1 }}
                  />
                </Box>
                <Typography
                  mt={0.5}
                  variant="caption"
                  color="textSecondary"
                  component="div"
                  noWrap
                >
                  {receipt.emailFrom}
                </Typography>

                <ReceiptInboxGridThumbnail
                  status={receipt.status}
                  thumbnail={thumbnails[receipt.id]}
                  isLoading={
                    isThumbnailLoading && thumbnails[receipt.id] === undefined
                  }
                  onPreviewOpen={() => setSelectedReceipt(receipt.id, true)}
                />

                <Box display="flex" alignItems="center">
                  <Box flex={1} overflow="hidden">
                    <Box display="flex" alignItems="center">
                      <ReceiptIcon color="action" fontSize="small" />
                      <Typography
                        variant="body2"
                        ml={1}
                        color={
                          receipt.ocr.merchantName
                            ? 'textPrimary'
                            : 'textSecondary'
                        }
                        noWrap
                      >
                        {receipt.ocr.merchantName || '-'}
                      </Typography>
                    </Box>

                    <Typography variant="caption" color="textSecondary">
                      {receipt.ocr.receiptDate
                        ? moment(receipt.ocr.receiptDate).format('D MMM YYYY')
                        : '-'}
                    </Typography>
                  </Box>

                  <Typography
                    variant="subtitle1"
                    noWrap
                    ml={1}
                    color={
                      receipt.tabGroup === ReceiptsAutoMatchingTagGroup.trash
                        ? 'error.main'
                        : 'textPrimary'
                    }
                  >
                    {receipt.ocr.amount ? (
                      <FormatMoney value={receipt.ocr.amount} fractionalPart />
                    ) : (
                      t('receiptInbox.table.NA')
                    )}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {hasNextPage && (
        <Box my={3} textAlign="center">
          <Button
            sx={{ minWidth: 240 }}
            onClick={loadMoreItems}
            disabled={isLoading}
          >
            {t('common.button.loadMore')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ReceiptInboxGrid;
