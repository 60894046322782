import { Trans } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { ApiIntegrationStatusBadge } from 'components/Badge';
import { useGlobalState } from 'context/GlobalState';
import { AccountingSystemLogo } from 'domains/settings/components';
import {
  IntegrationConnectionBlock,
  IntegrationConnectionLogoWrapper,
} from 'domains/settings/layout';
import { Box, Button, Typography } from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { ApiIntegrationStatus } from 'services/constants';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';

interface Props {
  onConnectionChange: () => Promise<void>;
}

const ConnectionBlock = ({ onConnectionChange }: Props) => {
  const canUser = useCanUser();
  const partnerName = usePartnerName();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();

  return (
    <IntegrationConnectionBlock>
      <IntegrationConnectionLogoWrapper>
        <AccountingSystemLogo />
      </IntegrationConnectionLogoWrapper>

      <Box display="flex" justifyContent="center" mb={3}>
        <ApiIntegrationStatusBadge
          status={accountingSettings!.apiIntegrationStatus}
        />
      </Box>

      <Typography mb={3}>
        <Trans
          i18nKey={
            accountingSettings!.apiIntegrationStatus ===
            ApiIntegrationStatus.connected
              ? 'accountingSystemSubPage.activeDescription'
              : 'accountingSystemSubPage.inactiveDescription'
          }
          values={{
            name: accountingSettings!.accountingSystemName,
            partnerName,
          }}
          components={{
            linkTo: (
              <Link
                to={generatePath(getPath('export'), {
                  orgId: organization!.id,
                })}
              />
            ),
          }}
        />
      </Typography>

      <Button
        variant={
          accountingSettings!.apiIntegrationStatus !==
          ApiIntegrationStatus.connected
            ? 'contained'
            : 'outlined'
        }
        onClick={onConnectionChange}
        // should handle other permissions once we combine other
        // accounting system pages
        disabled={!canUser('codat-settings:change')}
      >
        <Trans
          i18nKey={`accountingSystemSubPage.${
            accountingSettings!.apiIntegrationStatus ===
            ApiIntegrationStatus.notConnected
              ? 'connectButton'
              : 'disconnectButton'
          }`}
          values={{
            name: accountingSettings!.accountingSystemName,
          }}
        />
      </Button>
    </IntegrationConnectionBlock>
  );
};

export default ConnectionBlock;
