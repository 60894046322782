import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from 'elements';
import { TransactionSimpleType } from 'services/constants';

const getColor = (type: TransactionSimpleType) => {
  if (
    [TransactionSimpleType.refund, TransactionSimpleType.chargeback].includes(
      type
    )
  )
    return 'success.main';
  if (type === TransactionSimpleType.recharge) return 'error.main';
  if (type === TransactionSimpleType.statusInquiry) return 'textSecondary';
  return 'textPrimary';
};

interface Props {
  type: TransactionSimpleType;
}

const TransactionSimpleTypeLabel = ({ type }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        [
          TransactionSimpleType.chargeback,
          TransactionSimpleType.recharge,
        ].includes(type)
          ? t(`transactionTypeTooltip.${type}`)
          : ''
      }
    >
      <Typography variant="overline" color={getColor(type)}>
        {t(`transactionSimpleTypes.${type}`)}
      </Typography>
    </Tooltip>
  );
};

export default TransactionSimpleTypeLabel;
