import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { MerchantLogo } from 'domains/merchant/components';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from 'elements';
import { RelatedMerchant } from 'services/constants';

interface Props {
  title: ReactNode;
  merchants: RelatedMerchant[];
  generateMerchantLink: (merchantId: string) => string;
}

const RelatedMerchants = ({
  generateMerchantLink,
  title,
  merchants,
}: Props) => {
  if (!merchants.length) return null;

  return (
    <Box p={3} borderBottom={(theme) => `1px solid ${theme.palette.divider}`}>
      <Typography variant="overline" component="div">
        {title}
      </Typography>

      <Table component="div">
        <TableBody component="div">
          {merchants.map((item) => (
            <TableRow
              key={item.id}
              component={RouterLink}
              to={generateMerchantLink(item.id)}
              sx={(theme) => ({
                textDecoration: 'none',
                '&:hover': {
                  background: theme.palette.action.hover,
                },
              })}
            >
              <TableCell component="div" sx={{ width: 40, px: 0 }}>
                <MerchantLogo
                  sx={{ height: 40, width: 40 }}
                  url={item.logoPath}
                  name={item.displayName}
                />
              </TableCell>
              <TableCell component="div">
                <Typography variant="body2">{item.displayName}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default RelatedMerchants;
