import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import {
  BillPaymentBatchStatesCell,
  DirectDebitBadge,
} from 'domains/billing/components';
import { Chip, drawerPlaceholderColumn, GridColDef, gridUtils } from 'elements';
import { BillPaymentBatch } from 'services/constants';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<BillPaymentBatch>[] = [
      {
        headerName: t('int.billPaymentBatchesPage.createdAt'),
        field: 'submissionDate',
        flex: 90,
        minWidth: 150,
      },
      {
        headerName: t('int.billPaymentBatchesPage.paymentDate'),
        field: 'plannedPaymentDate',
        flex: 90,
        minWidth: 150,
      },
      {
        headerName: t('int.billPaymentBatchesPage.accountGroup'),
        field: 'accountGroup',
        flex: 90,
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <Chip
            label={t(`int.accountGroupSelect.items.${row.accountGroup}`)}
            size="small"
          />
        ),
      },
      {
        headerName: t('int.billPaymentBatchesPage.currency'),
        field: 'currency',
        flex: 60,
        minWidth: 100,
        sortable: false,
      },
      {
        headerName: t('int.billPaymentBatchesPage.type'),
        field: 'directDebitType',
        flex: 60,
        minWidth: 100,
        renderCell: ({ row }) =>
          row.directDebitType ? (
            <DirectDebitBadge value={row.directDebitType} />
          ) : null,
      },
      {
        headerName: t('int.billPaymentBatchesPage.amount'),
        field: 'amount',
        flex: 100,
        minWidth: 164,
        headerAlign: 'right',
        align: 'right',
        sortable: false,
        renderCell: ({ row }) => (
          <FormatMoney value={row.amount} fractionalPart />
        ),
      },
      {
        headerName: t('int.billPaymentBatchesPage.count'),
        field: 'count',
        flex: 60,
        minWidth: 98,
        headerAlign: 'right',
        align: 'right',
        sortable: false,
      },
      {
        headerName: t('int.billPaymentBatchesPage.state'),
        field: 'states',
        flex: 160,
        minWidth: 263,
        headerAlign: 'right',
        align: 'right',
        sortable: false,
        renderCell: ({ row }) => (
          <BillPaymentBatchStatesCell states={row.states} />
        ),
      },
      drawerPlaceholderColumn,
    ];

    return {
      columns,
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
    };
  }, [i18n.language]);
};

export default useColumns;
