import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { ReviewFlagReason, reviewFlagReasons } from 'services/constants';

type Value = ReviewFlagReason | '';

interface Props {
  value: Value;
  onChange: (value: Value) => void;
}

const FlagReasonFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();

  if (!featureModules.PRIVATE_EXPENSE) return null;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-review-flag-reason"
        id="panel-review-flag-reason-header"
      >
        <Typography>{t('filters.flagReason')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<Value>
            size="medium"
            displayEmpty
            value={value}
            onChange={(e) => onChange(e.target.value as Value)}
            renderValue={(selected) => {
              if (!selected)
                return (
                  <Typography color="text.disabled">
                    {t('filters.flagReason')}
                  </Typography>
                );
              return t(`reviewFlagReasons.${selected}`);
            }}
          >
            <MenuItem value="">
              <Typography color="text.disabled">
                {t('filters.flagReason')}
              </Typography>
            </MenuItem>
            {reviewFlagReasons.map((reason) => (
              <MenuItem key={reason} value={reason}>
                {t(`reviewFlagReasons.${reason}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default FlagReasonFilter;
