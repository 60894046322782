import { CircularProgress, Paper } from 'elements';
import {
  ReceiptAutoMatchingDetails,
  ReceiptsAutoMatchingStatus,
} from 'services/constants';

interface Props {
  receipt: ReceiptAutoMatchingDetails;
  thumbnail: string;
  onPreviewOpen: () => void;
}

const ReceiptThumbnailBlock = ({
  receipt,
  thumbnail,
  onPreviewOpen,
}: Props) => {
  const loadingState = receipt.status === ReceiptsAutoMatchingStatus.processing;

  if (!loadingState && !thumbnail) return null;

  return (
    <Paper
      variant="outlined"
      sx={{
        height: 150,
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          !loadingState && thumbnail
            ? `url('data:image/jpeg;base64,${thumbnail}')`
            : 'none',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: loadingState ? 'dashed' : 'solid',
      }}
      onClick={() => onPreviewOpen()}
    >
      {loadingState && <CircularProgress />}
    </Paper>
  );
};

export default ReceiptThumbnailBlock;
