import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { AccountEntryAvatar } from 'domains/transaction/components';
import { Box, Typography } from 'elements';
import {
  AccountEntry,
  AccountEntryBillPaymentType,
  AccountEntryType,
} from 'services/constants';

const getTypeName = (
  t: TFunction,
  type: AccountEntryType,
  billPaymentType?: AccountEntryBillPaymentType
) => {
  if (type === AccountEntryType.other) {
    return t('accountingPage.otherPayment');
  }
  if (billPaymentType) {
    return t(`accountEntryBillPaymentType.${billPaymentType}`);
  }
  return '';
};

interface Props {
  accountEntry: AccountEntry;
}

const AccountEntryMerchantCell = ({ accountEntry }: Props) => {
  const { t } = useTranslation();

  const renderLabel = () => {
    const purpose = accountEntry.billPaymentInfo?.purpose;
    const label =
      getTypeName(
        t,
        accountEntry.type,
        accountEntry.billPaymentInfo?.billPaymentType
      ) + (purpose ? `: ${purpose}` : '');
    return (
      <Typography variant="body2" noWrap>
        {label}
      </Typography>
    );
  };

  const renderDescription = () => {
    const billPaymentType = accountEntry.billPaymentInfo?.billPaymentType;
    const paymentType = accountEntry.billPaymentInfo?.paymentType;
    if (billPaymentType === AccountEntryBillPaymentType.bill)
      return (
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          noWrap
        >
          {t(`billPaymentTypes.${paymentType}`)}
        </Typography>
      );
    if (billPaymentType === AccountEntryBillPaymentType.prefunding)
      return (
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          noWrap
        >
          {t(`billPaymentTypes.${paymentType}`)}
        </Typography>
      );
    if (billPaymentType === AccountEntryBillPaymentType.billPaymentReversal)
      return (
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          noWrap
        >
          {t('accountingEntryDetailsPage.ddReturn')}
        </Typography>
      );
    if (billPaymentType === AccountEntryBillPaymentType.directDebitReversalFee)
      return (
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          noWrap
        >
          {t('accountingEntryDetailsPage.ddReturn')}
        </Typography>
      );
    if (billPaymentType === AccountEntryBillPaymentType.payout)
      return (
        <Typography
          variant="caption"
          color="textSecondary"
          component="div"
          noWrap
        >
          {t(`billPaymentTypes.${paymentType}`)}
        </Typography>
      );
    return null;
  };

  return (
    <Box display="flex" alignItems="center" overflow="hidden">
      <AccountEntryAvatar
        type={accountEntry.type}
        billPaymentType={accountEntry.billPaymentInfo?.billPaymentType}
      />
      <Box pl={2} minWidth={0}>
        {renderLabel()}
        {renderDescription()}
      </Box>
    </Box>
  );
};

export default AccountEntryMerchantCell;
