import { UIEvent, useCallback, useState } from 'react';
import { useTheme } from '@mui/material';
import lodashThrottle from 'lodash/throttle';

const useHeaderBorderOnScroll = () => {
  const [isHeaderBordered, setIsHeaderBordered] = useState(false);
  const theme = useTheme();

  const throttledOnScrollTopChange = useCallback(
    lodashThrottle((scrollTop: number) => {
      setIsHeaderBordered(scrollTop > 0);
    }, 200),
    []
  );

  const onContentScroll = (e: UIEvent<HTMLDivElement>) => {
    e.persist();
    throttledOnScrollTopChange(e.currentTarget.scrollTop);
  };

  const getHeaderStyle = () =>
    isHeaderBordered ? { boxShadow: `0 1px 0 ${theme.palette.divider}` } : {};

  return {
    onContentScroll,
    getHeaderStyle,
  };
};

export default useHeaderBorderOnScroll;
