import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryParams } from 'domains/organization/pages/OrganizationsPage/GroupsSubPage';
import { Chip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { PageFilterChips, PageFiltersComponent } from 'layout';
import FiltersDrawer from './FiltersDrawer';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
}

const Filters = ({ params, selectedFiltersCount, setParam }: Props) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <>
      <PageFiltersComponent
        count={selectedFiltersCount}
        q={params.q}
        setQParam={(value) => setParam('q', value)}
        onOpen={() => setIsFilterOpen(true)}
      />

      {!!selectedFiltersCount && (
        <PageFilterChips>
          {!!params.country && (
            <Chip
              label={t(`countries.${params.country}`)}
              onDelete={() => setParam('country', '')}
            />
          )}
          {!!params.type && (
            <Chip
              label={t(`int.organizationGroupType.${params.type}`)}
              onDelete={() => setParam('type', '')}
            />
          )}
        </PageFilterChips>
      )}

      <FiltersDrawer
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        params={params}
        setParam={setParam}
      />
    </>
  );
};

export default Filters;
