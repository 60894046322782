import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FaqItem from './FaqItem';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 120px 0 80px;
`;

const Header = styled.h3`
  font-size: 34px;
  line-height: 34px;
  margin: 0 0 40px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div:not(:last-child) {
    margin-bottom: 26px;
  }
`;

const Bold = styled.span`
  font-weight: 700;
`;

const List = styled.ul`
  margin-top: 10px;
  margin-bottom: 0;
`;

const Item = styled.li`
  margin-bottom: 10px;
`;

const Faq = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>{t('pliantEarthPage.faq.title')}</Header>

      <ItemContainer>
        <FaqItem id={0} summary={t('pliantEarthPage.faq.items.0.title')}>
          {t('pliantEarthPage.faq.items.0.description')}
        </FaqItem>

        <FaqItem id={1} summary={t('pliantEarthPage.faq.items.1.title')}>
          <p>{t('pliantEarthPage.faq.items.1.description.text')}</p>

          <Bold>{t('pliantEarthPage.faq.items.1.description.list.title')}</Bold>
          <List>
            <Item>
              <Trans
                i18nKey={'pliantEarthPage.faq.items.1.description.list.items.0'}
                components={{
                  'ghc-link': (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href="https://ghgprotocol.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                  'biohotels-link': (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href="https://biohotels.de/CO2-Fussabdruck"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                }}
              />
            </Item>

            <Item>
              {t('pliantEarthPage.faq.items.1.description.list.items.1')}
            </Item>

            <Item>
              {t('pliantEarthPage.faq.items.1.description.list.items.2')}
            </Item>
          </List>
        </FaqItem>

        <FaqItem id={2} summary={t('pliantEarthPage.faq.items.2.title')}>
          {t('pliantEarthPage.faq.items.2.description')}
        </FaqItem>

        <FaqItem id={3} summary={t('pliantEarthPage.faq.items.3.title')}>
          <Trans
            i18nKey={'pliantEarthPage.faq.items.3.description'}
            components={{
              'cozero-link': (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href="https://cozero.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </FaqItem>

        <FaqItem id={4} summary={t('pliantEarthPage.faq.items.4.title')}>
          {t('pliantEarthPage.faq.items.4.description')}
        </FaqItem>

        <FaqItem id={5} summary={t('pliantEarthPage.faq.items.5.title')}>
          {t('pliantEarthPage.faq.items.5.description')}
        </FaqItem>
      </ItemContainer>
    </Container>
  );
};

export default Faq;
