import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import { ReceiptInboxStatusBadge } from 'domains/organization/components';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ReceiptsAutoMatchingSameEmail } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  receipts: ReceiptsAutoMatchingSameEmail[];
}

interface Props extends DialogProps {
  id: string;
  onOtherReceiptSelect: (receipt: ReceiptsAutoMatchingSameEmail) => void;
  onClose: () => void;
}

const OtherFilesDialog = ({ id, onOtherReceiptSelect, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    receipts: [],
  });

  const getData = async () => {
    try {
      const receipts = await api.getReceiptsAutoMatchingSameEmail(
        id,
        organization!.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        receipts,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>
        {t('receiptInboxDetails.otherFilesDialog.header')}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t('receiptInboxDetails.otherFilesDialog.description')}
        </Typography>

        {!!state.receipts.length && (
          <Table sx={{ mt: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('receiptInboxDetails.otherFilesDialog.fileNameLabel')}
                </TableCell>
                <TableCell align="right">
                  {t('receiptInboxDetails.otherFilesDialog.statusLabel')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {state.receipts.map((receipt) => (
                <TableRow
                  hover
                  key={receipt.taskId}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    onOtherReceiptSelect(receipt);
                    props.onClose();
                  }}
                >
                  <TableCell>{receipt.attachmentFileName}</TableCell>
                  <TableCell align="right">
                    <ReceiptInboxStatusBadge status={receipt.taskStatus} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {!state.receipts.length && !state.isLoading && (
          <Box position="relative" minHeight={150}>
            <NothingFound />
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose}>{t('common.button.close')}</Button>
      </DialogActions>

      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(OtherFilesDialog);
