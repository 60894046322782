import { useEffect, useState } from 'react';
import { ReceiptFilter } from 'domains/transaction/components';
import { QueryParams } from 'domains/transaction/pages/GlobalTransactionsPage';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { FilterDrawer, FilterDrawerProps } from 'layout';
import { TransactionReceiptStatus } from 'services/constants';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  receipt: TransactionReceiptStatus | '';
}

const FiltersDrawer = ({ params, setParam, ...props }: Props) => {
  const [state, setState] = useState<State>({
    receipt: '',
  });
  const areFiltersSelected = !!state.receipt;

  useEffect(() => {
    if (props.open)
      setState({
        receipt: params.receipt,
      });
  }, [params, props.open]);

  const onFiltersApply = () => {
    setParam(Object.keys(state).map((key) => [key, state[key as keyof State]]));
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.keys(state).map((key) => [key, '']));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <ReceiptFilter
        value={state.receipt}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, receipt: value }))
        }
      />
    </FilterDrawer>
  );
};

export default FiltersDrawer;
