import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Alert,
  AlertTitle,
  EnvelopeSimpleIcon,
  List,
  ListItem,
  ListItemIcon,
  ListItemTextReversed,
  PhoneIcon,
  Typography,
} from 'elements';
import { env } from 'env';
import { MemberDetails, MemberStatus } from 'services/constants';
import { useCanUser } from 'services/rbac';
import ResendInviteButton from './ResendInviteButton';

interface Props {
  member: MemberDetails;
}

const MemberDetailsContactBlock = ({ member }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { member: currentMember },
  } = useGlobalState();

  return (
    <Box p={3}>
      {member.status === MemberStatus.invited && (
        <Alert
          severity="info"
          action={
            canUser('member:resend-invitation') && (
              <ResendInviteButton member={member}>
                {t('memberDetailsPage.resendInvite')}
              </ResendInviteButton>
            )
          }
          sx={{ mt: -1, mb: 3 }}
        >
          <AlertTitle>{t('memberDetailsPage.inviteSentTitle')}</AlertTitle>
          {t('memberDetailsPage.inviteSent')}
        </Alert>
      )}

      <Typography variant="overline" component="div" py={0.5}>
        {t('memberDetailsPage.contactDetails.title')}
      </Typography>

      <List sx={{ p: 0 }}>
        <ListItem>
          <ListItemIcon>
            <EnvelopeSimpleIcon />
          </ListItemIcon>
          <ListItemTextReversed
            primary={member.email}
            secondary={t('memberDetailsPage.contactDetails.email')}
            sx={{ m: 0 }}
          />
        </ListItem>

        {(member.id === currentMember.id ||
          env.REACT_APP_BUILD_TARGET === 'internal') && (
          <ListItem>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemTextReversed
              primary={member.phoneNumber || '-'}
              secondary={t('memberDetailsPage.contactDetails.phone')}
              sx={{ m: 0 }}
            />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default MemberDetailsContactBlock;
