import { Trans, useTranslation } from 'react-i18next';
import { useCodatStepperContext } from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/useCodatStepperContext';
import {
  Box,
  Button,
  CheckCircleIcon,
  DialogActions,
  DialogContent,
  Typography,
} from 'elements';

const FinishStep = () => {
  const { t } = useTranslation();
  const {
    actions: { onClose },
  } = useCodatStepperContext();

  return (
    <>
      <DialogContent>
        <Box sx={{ fontSize: (theme) => theme.typography.fontSize * 4, mt: 3 }}>
          <CheckCircleIcon fontSize="inherit" />
        </Box>

        <Typography variant="h4" mb={2} mt={8}>
          {t('codatSyncSetupDialog.finishStep.title')}
        </Typography>
        <Typography color="text.secondary">
          <Trans
            i18nKey="codatSyncSetupDialog.finishStep.description"
            components={{ br: <br /> }}
          />
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          {t('codatSyncSetupDialog.finishStep.finishSyncButton')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FinishStep;
