import {
  organizationOnboardingStatuses,
  OrganizationStatus,
} from 'services/constants';

export const didOrgOnboardingStepPassed = (
  currentStatus: OrganizationStatus,
  requestedStatus: OrganizationStatus
) =>
  !organizationOnboardingStatuses.includes(currentStatus) ||
  organizationOnboardingStatuses.indexOf(currentStatus) >
    organizationOnboardingStatuses.indexOf(requestedStatus);
