import React, { useLayoutEffect, useState } from 'react';
import { FormControl, InputBaseProps } from '@material-ui/core';
import styled from 'styled-components';
import { StyledInputBase } from './StyledInputBase';
import { StyledInputLabel } from './StyledInputLabel';

const StyledFormControl = styled(FormControl)`
  input[type='date']::-webkit-calendar-picker-indicator {
    opacity: 0.4;
  }

  input[type='date']:disabled::-webkit-calendar-picker-indicator {
    visibility: visible;
  }
`;

interface DateFieldProps extends InputBaseProps {
  label?: string;
  error?: boolean;
  type?: 'date' | 'month';
}

// todo: should be updated with other components later
export const DateField = ({
  label,
  error,
  type = 'date',
  ...props
}: DateFieldProps) => {
  const [isDateInputSupported, setIsDateInputSupported] = useState(true);

  useLayoutEffect(() => {
    // test whether a new date input falls back to a text input or not
    const test = document.createElement('input');

    try {
      test.type = 'date';
    } catch (e) {
      setIsDateInputSupported(false);
    }
  }, []);

  return (
    <StyledFormControl fullWidth error={error}>
      {label && (
        <StyledInputLabel
          htmlFor={props.id ?? props.name}
          // scale width back to 100% of parent
          style={{ ...(isDateInputSupported && { width: '133%' }) }}
          shrink
        >
          {`${label} ${isDateInputSupported ? '' : '(yyyy-mm-dd)'}`}
        </StyledInputLabel>
      )}
      <StyledInputBase id={props.id ?? props.name} type={type} {...props} />
    </StyledFormControl>
  );
};
