import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import {
  Route,
  Link as RouterLink,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { internalRootPaths } from 'components/App';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import MerchantDetailsPage from 'domains/merchant/pages/MerchantDetailsPage';
import {
  ArrowRightIcon,
  Box,
  DataGrid,
  Link,
  LoaderWithOverlay,
  StorefrontIcon,
  Typography,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import useSnackbar from 'hooks/useSnackbar';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  countryCodesArray,
  DEFAULT_PAGE_LIMIT,
  Merchant,
  MerchantDetails,
  merchantStatuses,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getValidQueryParamValues } from 'services/utils';
import Filters from './Filters';
import useColumns from './useColumns';

const getQueryParams = (qs: string) => {
  const { q, status, country } = Object.fromEntries(
    new URLSearchParams(qs).entries()
  );
  return {
    q: q ? q.trim() : '',
    status: getValidQueryParamValues(status, merchantStatuses),
    country: getValidQueryParamValues(country, countryCodesArray),
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

interface State {
  isLoading: boolean;
  merchants: Merchant[];
  hasNextPage: boolean;
  totalCount: number;
}

const MerchantsPage = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const showPageError = useShowPageError();
  const api = useImperativeApi();
  const mounted = useMounted();
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(getQueryParams(location.search));
  const pageRef = useRef(0);
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:merchantId',
    true
  );
  const columns = useColumns();
  const dataGridRef = useGridApiRef();
  const [state, setState] = useState<State>({
    isLoading: true,
    merchants: [],
    hasNextPage: false,
    totalCount: 0,
  });
  const selectedFiltersCount =
    +!!paramsRef.current.status.length + +!!paramsRef.current.country.length;

  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;
  const isEmptyState =
    !state.isLoading && !state.merchants.length && !areFiltersApplied;

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      if (!isLoadMore) {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
      }
      const { q, status, country } = paramsRef.current;
      const { merchants, hasNextPage, totalCount } = await api.getMerchants({
        page,
        limit,
        q: q.length ? q : undefined,
        statuses: status.length ? status.join() : undefined,
        countries: country.length ? country.join() : undefined,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        merchants: isLoadMore
          ? [...prevState.merchants, ...merchants]
          : merchants,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });
    paramsRef.current = getQueryParams(location.search);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const onMerchantUpdated = (updatedMerchant: Merchant) => {
    setState((state) => ({
      ...state,
      merchants: state.merchants.map((merchant) =>
        merchant.id === updatedMerchant.id
          ? { ...merchant, ...updatedMerchant }
          : merchant
      ),
    }));
  };

  const onMerchantMerge = (mergedMerchant: MerchantDetails) => {
    pageRef.current = 0;
    getData(pageRef.current);
    history.push(`${url}/${mergedMerchant.id}${location.search}`);
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('int.merchantsPage.header')} />
        <Filters
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          disabled={isEmptyState}
          totalCount={state.totalCount}
        />
      </PageHeader>
      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />
        <DataGrid<Merchant>
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          apiRef={dataGridRef}
          rowSelectionModel={
            detailsParams?.merchantId ? [detailsParams.merchantId] : []
          }
          loading={state.isLoading}
          rows={state.merchants}
          rowCount={state.totalCount}
          columns={columns}
          columnVisibilityModel={{ drawerPlaceholder: isDetailsPageOpen }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ id }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${id}${location.search}`);
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.merchants.length && areFiltersApplied) {
                return <NothingFound />;
              }
              return (
                <NoData
                  isNewDesign
                  Icon={StorefrontIcon}
                  label={t('int.merchantsPage.noData')}
                />
              );
            },
            loadingOverlay: () => null,
          }}
          rowHeight={72}
        />
        <Route
          path={`${path}/:merchantId`}
          children={({ match }) => (
            <MerchantDetailsPage
              open={!!match}
              onUpdate={onMerchantUpdated}
              onMerchantMerge={onMerchantMerge}
              onMerchantUnmerge={(unmergedMerchant) => {
                pageRef.current = 0;
                getData(pageRef.current);
                enqueueSnackbar(
                  <>
                    <Typography component="div" variant="body2">
                      {t('int.merchantsPage.unMergeSuccessMsg')}
                    </Typography>
                    <Box mb={0.375} mt={1.5}>
                      <Box display="flex" alignItems="center">
                        <ArrowRightIcon fontSize="small" />
                        <Link
                          sx={{ ml: 0.5 }}
                          variant="body2"
                          component={RouterLink}
                          to={`${url}/${unmergedMerchant.id}${location.search}`}
                        >
                          {unmergedMerchant.displayName}
                        </Link>
                      </Box>
                      <Box display="flex" ml={3}>
                        <div>{t('int.merchantsPage.merchantId')}</div>
                        <Box
                          sx={{
                            ml: 1.5,
                            flex: 1,
                            opacity: 0.7,
                          }}
                        >
                          {unmergedMerchant.merchantsDe42
                            .map((item) => item.merchantIdDe42)
                            .join(', ')}
                        </Box>
                      </Box>
                    </Box>
                  </>,
                  { variant: 'success', persist: true }
                );
              }}
              onMerchantSplit={(merchant) => {
                pageRef.current = 0;
                getData(pageRef.current);
                enqueueSnackbar(
                  <>
                    <Typography component="div" variant="body2">
                      {t('int.merchantsPage.splitMerchantSuccessMsg')}
                    </Typography>
                    <Box mb={0.375} mt={1.5}>
                      <Box display="flex" alignItems="center">
                        <ArrowRightIcon fontSize="small" />
                        <Link
                          sx={{ ml: 0.5 }}
                          variant="body2"
                          component={RouterLink}
                          to={`${url}/${merchant.id}${location.search}`}
                        >
                          {merchant.displayName}
                        </Link>
                      </Box>
                      <Box display="flex" ml={3}>
                        <div>{t('int.merchantsPage.merchantId')}</div>
                        <Box
                          sx={{
                            ml: 1.5,
                            flex: 1,
                            opacity: 0.7,
                          }}
                        >
                          {merchant.merchantsDe42
                            .map((item) => item.merchantIdDe42)
                            .join(', ')}
                        </Box>
                      </Box>
                    </Box>
                  </>,
                  { variant: 'success', persist: true }
                );
              }}
              onMerchantUnsplit={(merchantId) => {
                pageRef.current = 0;
                getData(pageRef.current);
                enqueueSnackbar(
                  t('int.merchantsPage.unsplitMerchantSuccessMsg'),
                  { variant: 'success' }
                );
                if (merchantId) {
                  // open main merchant details
                  history.replace(
                    generatePath(internalRootPaths.merchantsDetails, {
                      merchantId,
                    })
                  );
                } else {
                  // close merchant details view
                  history.replace({
                    pathname: internalRootPaths.merchants,
                    search: location.search,
                  });
                }
              }}
            />
          )}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(MerchantsPage, {
  permission: 'merchants-page:visit',
});
