import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { BillPaymentBatchDetailsPage } from 'domains/billing/pages';
import { visibleBatchPaymentStatuses } from 'domains/billing/utils';
import {
  DataGrid,
  FileTextIcon,
  GridSortModel,
  gridUtils,
  LoaderWithOverlay,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  BillPaymentBatch,
  DEFAULT_PAGE_LIMIT,
  directDebitTypes,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getValidQueryParamValue,
  getValidQueryParamValues,
  isSortValid,
} from 'services/utils';
import Filters from './Filters';
import useColumns from './useColumns';

const getQueryParams = (qs: string, allowedSortKeys: string[]) => {
  const { sort, type, status, fromDate, toDate } = Object.fromEntries(
    new URLSearchParams(qs).entries()
  );

  const fromDateMoment = moment(fromDate, moment.ISO_8601);
  const toDateMoment = moment(toDate, moment.ISO_8601);

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '-submissionDate',
    type: getValidQueryParamValue(type, directDebitTypes),
    status: getValidQueryParamValues(status, visibleBatchPaymentStatuses),
    fromDate: fromDateMoment.isValid() ? fromDateMoment : null,
    toDate:
      fromDateMoment.isValid() && toDateMoment.isValid() ? toDateMoment : null,
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({ type, status, fromDate }: QueryParams) =>
  0 + (type ? 1 : 0) + (status.length ? 1 : 0) + (fromDate ? 1 : 0);

interface State {
  isLoading: boolean;
  billPayments: BillPaymentBatch[];
  hasNextPage: boolean;
  totalCount: number;
}

const BillPaymentBatchesPage = () => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const { allowedSortKeys, columns } = useColumns();
  const location = useLocation();
  const history = useHistory();
  const api = useImperativeApi();
  const mounted = useMounted();
  const dataGridRef = useGridApiRef();
  const showPageError = useShowPageError();
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:batchId',
    true
  );
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(getQueryParams(location.search, allowedSortKeys));
  const pageRef = useRef(0);
  const [state, setState] = useState<State>({
    isLoading: true,
    billPayments: [],
    hasNextPage: false,
    totalCount: 0,
  });
  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied = !!selectedFiltersCount;
  const isEmptyState =
    !state.isLoading && !state.billPayments.length && !areFiltersApplied;

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      if (!isLoadMore) {
        setState((state) => ({
          ...state,
          isLoading: true,
        }));
      }
      const { sort, type, status, fromDate, toDate } = paramsRef.current;
      const {
        billPayments,
        hasNextPage,
        totalCount,
      } = await api.getBillPaymentBatches({
        page,
        limit,
        sort,
        directDebitTypes: type ? type : undefined,
        paymentStatuses: status.length ? status.join() : undefined,
        paymentDateFrom: fromDate?.format('YYYY-MM-DD'),
        paymentDateTo: toDate?.format('YYYY-MM-DD'),
      });
      if (!mounted.current) return;
      setState((state) => ({
        isLoading: false,
        billPayments: isLoadMore
          ? [...state.billPayments, ...billPayments]
          : billPayments,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });
    paramsRef.current = getQueryParams(location.search, allowedSortKeys);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const handleSortModelChange = (sort: GridSortModel) => {
    if (state.isLoading || !state.billPayments.length) return;
    setQueryParam('sort', gridUtils.getNewSortParam(sort));
  };

  const onBatchUpdated = (billPaymentBatch: BillPaymentBatch) => {
    setState((state) => ({
      ...state,
      billPayments: state.billPayments.map((item) =>
        item.id === billPaymentBatch.id
          ? { ...item, ...billPaymentBatch }
          : item
      ),
    }));
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('int.billPaymentBatchesPage.title')} />
        <Filters
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          disabled={isEmptyState}
          totalCount={state.totalCount}
        />
      </PageHeader>
      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />
        <DataGrid<BillPaymentBatch>
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          apiRef={dataGridRef}
          rowSelectionModel={
            detailsParams?.batchId ? [detailsParams.batchId] : []
          }
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(paramsRef.current.sort),
            },
          }}
          loading={state.isLoading}
          rows={state.billPayments}
          rowCount={state.totalCount}
          columns={columns}
          columnVisibilityModel={{ drawerPlaceholder: isDetailsPageOpen }}
          onSortModelChange={handleSortModelChange}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ id }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${id}${location.search}`);
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.billPayments.length && areFiltersApplied) {
                return <NothingFound />;
              }
              return (
                <NoData
                  isNewDesign
                  Icon={FileTextIcon}
                  label={t('int.billPaymentBatchesPage.noData')}
                />
              );
            },
            loadingOverlay: () => null,
          }}
          rowHeight={72}
        />
        <Route
          path={`${path}/:batchId`}
          children={({ match }) => (
            <BillPaymentBatchDetailsPage
              open={!!match}
              onUpdate={onBatchUpdated}
            />
          )}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(BillPaymentBatchesPage, {
  permission: 'bill-payment-batches-page:visit',
});
