import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  EditButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'elements';
import { ApiIntegrationStatus, ExportFormat } from 'services/constants';
import { useCanUser } from 'services/rbac';
import DatevGeneralSettingsDialog from './DatevGeneralSettingsDialog';

const DatevGeneralSettings = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="60%">
              {t('datevExportSettingsGroup.datevSettings')}
            </TableCell>
            <TableCell width="40%" align="right">
              {canUser('accounting-settings:update') &&
                !(
                  accountingSettings!.exportFormats.includes(
                    ExportFormat.api
                  ) &&
                  (accountingSettings!.apiIntegrationStatus ===
                    ApiIntegrationStatus.connected ||
                    accountingSettings!.apiIntegrationStatus ===
                      ApiIntegrationStatus.needsWork)
                ) && <EditButton onClick={() => setIsDialogOpen(true)} />}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell width="60%">
              {t('datevExportSettingsGroup.consultantNumber')}
            </TableCell>
            <TableCell width="40%">
              {accountingSettings!.consultantNumber || '-'}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="60%">
              {t('datevExportSettingsGroup.clientNumber')}
            </TableCell>
            <TableCell width="40%">
              {accountingSettings!.clientNumber || '-'}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="60%">
              {t('datevExportSettingsGroup.beginOfFiscalYear')}
            </TableCell>
            <TableCell width="40%">
              {typeof accountingSettings!.beginOfFiscalYear === 'number'
                ? moment()
                    .month(accountingSettings!.beginOfFiscalYear - 1)
                    .format('MMMM')
                : '-'}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell width="60%">
              {t('datevExportSettingsGroup.generalLedgerAccountLength')}
            </TableCell>
            <TableCell width="40%">
              {accountingSettings!.generalLedgerAccountLength || '-'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <DatevGeneralSettingsDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default DatevGeneralSettings;
