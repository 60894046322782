import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { DataItemRow } from 'domains/creditAndCompliance/components';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { parseAndReformatDateStr } from 'domains/creditAndCompliance/utils';
import { Box, EditButton, Grid, Typography } from 'elements';
import { Firmographics, OrganizationsStaticData } from 'services/constants';
import EditFirmographicsFundingDialog from './EditFirmographicsFundingDialog';
import EditFirmographicsSegmentationDialog from './EditFirmographicsSegmentationDialog';
import EditFirmographicsSizeDialog from './EditFirmographicsSizeDialog';

const FirmographicsSection = () => {
  const { t } = useTranslation();
  const {
    state: { organizationsStaticData },
  } = useGlobalState();
  const {
    state: { creditProfile },
  } = useCreditAndComplianceContext();
  const firmographics = creditProfile!.firmographics;

  const [
    isEditFirmographicsSizeDialogOpen,
    setIsEditFirmographicsSizeDialogOpen,
  ] = useState(false);
  const [
    isEditFirmographicsSegmentationDialogOpen,
    setIsEditFirmographicsSegmentationDialogOpen,
  ] = useState(false);
  const [
    isEditFirmographicsFundingDiologOpen,
    setIsEditFirmographicsFundingDialogOpen,
  ] = useState(false);

  const getSourceField = (
    field: keyof Firmographics,
    optionsSource: keyof OrganizationsStaticData = 'firmographicsSources'
  ) => {
    if (firmographics[field].value === null) return '-';

    return (
      organizationsStaticData![optionsSource].find(
        (item) => item.name === firmographics[field].value
      )?.label ?? '-'
    );
  };

  return (
    <>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        justifyContent="space-between"
        py={1}
      >
        <Typography variant="h6">
          {t('int.firmographicsSection.title')}
        </Typography>
      </Box>

      <Grid container direction="column" spacing={2} py={3}>
        <Grid item alignItems="center" display="flex">
          <Typography color="textSecondary" mr={1} variant="body1">
            {t('int.firmographicsSection.size')}
          </Typography>
          <EditButton
            onClick={() => setIsEditFirmographicsSizeDialogOpen(true)}
          />
        </Grid>
        <DataItemRow
          label={t('int.firmographicsSection.firmographicsDate')}
          updatedAt={firmographics.firmographicsDate.updatedAt}
          updatedBy={firmographics.firmographicsDate.updatedBy}
          value={
            firmographics.firmographicsDate.value
              ? parseAndReformatDateStr(
                  firmographics.firmographicsDate.value,
                  'DD.MM.YYYY'
                )
              : null
          }
        />
        <DataItemRow
          label={t('int.firmographicsSection.annualRevenue')}
          updatedAt={firmographics.annualRevenue.updatedAt}
          updatedBy={firmographics.annualRevenue.updatedBy}
          value={
            firmographics.annualRevenue.value ? (
              <>
                <FormatMoney value={firmographics.annualRevenue.value} />
                <span>{` (${getSourceField('annualRevenueSource')})`}</span>
              </>
            ) : null
          }
        />
        <DataItemRow
          label={t('int.firmographicsSection.balanceSheet')}
          updatedAt={firmographics.balanceSheet.updatedAt}
          updatedBy={firmographics.balanceSheet.updatedBy}
          value={
            firmographics.balanceSheet.value ? (
              <>
                <FormatMoney value={firmographics.balanceSheet.value} />
                <span>{` (${getSourceField('balanceSheetSource')})`}</span>
              </>
            ) : null
          }
        />
        <DataItemRow
          label={t('int.firmographicsSection.numberOfEmployees')}
          updatedAt={firmographics.numberOfEmployees.updatedAt}
          updatedBy={firmographics.numberOfEmployees.updatedBy}
          value={
            typeof firmographics.numberOfEmployees.value === 'number'
              ? `${firmographics.numberOfEmployees.value} (${getSourceField(
                  'numberOfEmployeesSource'
                )})`
              : null
          }
        />

        <Grid item alignItems="center" display="flex" marginTop={2}>
          <Typography color="textSecondary" mr={1} variant="body1">
            {t('int.firmographicsSection.segmentation')}
          </Typography>
          <EditButton
            onClick={() => setIsEditFirmographicsSegmentationDialogOpen(true)}
          />
        </Grid>
        <DataItemRow
          label={t('int.firmographicsSection.segmentSelfDeclared')}
          updatedAt={firmographics.selfDeclaredSegment.updatedAt}
          updatedBy={firmographics.selfDeclaredSegment.updatedBy}
          value={firmographics.selfDeclaredSegment.value}
        />
        <DataItemRow
          label={t('int.firmographicsSection.vgEnterpriseSize')}
          updatedAt={firmographics.enterpriseSize.updatedAt}
          updatedBy={firmographics.enterpriseSize.updatedBy}
          value={
            organizationsStaticData!.enterpriseSizes.find(
              (item) => item.name === firmographics.enterpriseSize.value
            )?.label ?? null
          }
        />
        <DataItemRow
          label={t('int.firmographicsSection.internalEnterpriseSize')}
          updatedAt={firmographics.enterpriseSize.updatedAt}
          updatedBy={firmographics.enterpriseSize.updatedBy}
          value={[
            firmographics.enterpriseSize.value,
            firmographics.isCustomEnterpriseSize.value &&
              `(${t('int.firmographicsSection.custom')})`,
          ]
            .filter(Boolean)
            .join(' ')}
        />

        <Grid item alignItems="center" display="flex" marginTop={2}>
          <Typography color="textSecondary" mr={1} variant="body1">
            {t('int.firmographicsSection.funding')}
          </Typography>
          <EditButton
            onClick={() => setIsEditFirmographicsFundingDialogOpen(true)}
          />
        </Grid>
        <DataItemRow
          label={t('int.firmographicsSection.capitalRaised')}
          updatedAt={firmographics.capitalRaised.updatedAt}
          updatedBy={firmographics.capitalRaised.updatedBy}
          value={
            typeof firmographics.capitalRaised.value === 'boolean'
              ? t(`common.${firmographics.capitalRaised.value ? 'yes' : 'no'}`)
              : null
          }
        />
        <DataItemRow
          label={t('int.firmographicsSection.amountCapitalRaised')}
          updatedAt={firmographics.capitalRaisedAmount.updatedAt}
          updatedBy={firmographics.capitalRaisedAmount.updatedBy}
          value={
            firmographics.capitalRaisedAmount.value ? (
              <>
                <FormatMoney value={firmographics.capitalRaisedAmount.value} />
                <span>{` (${getSourceField(
                  'capitalRaisedSource',
                  'capitalRaisedSources'
                )})`}</span>
              </>
            ) : null
          }
        />
      </Grid>

      <EditFirmographicsSizeDialog
        open={isEditFirmographicsSizeDialogOpen}
        onClose={() => setIsEditFirmographicsSizeDialogOpen(false)}
      />
      <EditFirmographicsSegmentationDialog
        open={isEditFirmographicsSegmentationDialogOpen}
        onClose={() => setIsEditFirmographicsSegmentationDialogOpen(false)}
      />
      <EditFirmographicsFundingDialog
        open={isEditFirmographicsFundingDiologOpen}
        onClose={() => setIsEditFirmographicsFundingDialogOpen(false)}
      />
    </>
  );
};

export default FirmographicsSection;
