import { Money } from 'services/constants';

interface Props {
  availableLimit: Money;
  limit: Money | null;
  basePercentage?: number;
}

export const getLimitPercentage = ({
  availableLimit,
  limit,
  basePercentage = 20,
}: Props) => {
  let limitPercentage = 0;
  let isPercentageLow = false;

  if (limit && limit.value !== 0) {
    limitPercentage = (availableLimit.value / limit.value) * 100;
    limitPercentage = limitPercentage > 100 ? 100 : limitPercentage;
    isPercentageLow = limitPercentage < basePercentage;
  }

  return {
    limitPercentage,
    isPercentageLow,
  };
};
