import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';
import useCurrentApp from 'hooks/useCurrentApp';

/**
 * unmatchedTaskCountSelf - is a number based on memberId,
 * which means we show the number of unmatched tasks based on app type
 */
const useUnmatchedReceiptsCount = () => {
  const {
    state: { unmatchedReceiptsCounts },
  } = useGlobalState();
  const { isCardholderApp } = useCurrentApp();

  return useMemo(
    () =>
      isCardholderApp
        ? unmatchedReceiptsCounts.unmatchedTaskCountSelf
        : unmatchedReceiptsCounts.unmatchedTaskCount,
    [isCardholderApp, unmatchedReceiptsCounts]
  );
};

export default useUnmatchedReceiptsCount;
