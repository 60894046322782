import React from 'react';
import MuiSwitch, {
  SwitchProps as MuiSwitchProps,
} from '@material-ui/core/Switch';
import styled from 'styled-components';

export const Switch = styled((props: MuiSwitchProps) => (
  <MuiSwitch
    color="primary"
    focusVisibleClassName=""
    disableRipple
    {...props}
  />
))`
  overflow: visible;
  width: 51px;
  height: 32px;
  margin: 0 8px;
  padding: 0;

  .MuiSwitch-switchBase {
    padding: 1px;

    &.Mui-checked {
      transform: translateX(19px);
    }
  }

  .MuiSwitch-thumb {
    width: 30px;
    height: 30px;
  }

  .MuiSwitch-track {
    border-radius: 16px;
  }

  .MuiSwitch-switchBase.MuiSwitch-colorPrimary {
    .MuiSwitch-thumb {
      background: ${({ theme }) => theme.colors.white};
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 3px 1px rgba(0, 0, 0, 0.06);
    }

    & + .MuiSwitch-track {
      background: ${({ theme }) => theme.colors.neutral80};
      opacity: 1;
    }

    &.Mui-checked + .MuiSwitch-track {
      background: ${({ theme }) => theme.colors.grey10};
      opacity: 1;
    }
  }

  ${({ disabled, checked }) => {
    // Opacity for the disabled state is set based on props instead of classes
    // because we need to set it on the root element (.MuiSwitch-root)
    // but MUI adds the Mui-disabled class to the children element (.MuiSwitch-switchBase)
    // and it's not possible to select parent elements in CSS in a cross-browser way.
    if (!disabled) return '';
    return checked ? 'opacity: 0.1;' : 'opacity: 0.3;';
  }}

  &.MuiSwitch-sizeSmall {
    width: 46px;
    height: 28px;

    .MuiSwitch-switchBase {
      &.Mui-checked {
        transform: translateX(18px);
      }
    }

    .MuiSwitch-thumb {
      width: 26px;
      height: 26px;
    }
  }
`;
