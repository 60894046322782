import moment from 'moment';
import { Trans } from 'react-i18next';
import { Tooltip, WarningIcon } from 'elements';
import { TransactionExportError } from 'services/constants';

interface Props {
  error: TransactionExportError | null;
  isDatevApiConnected: boolean;
  isCodatConnected: boolean;
}

const ExportErrorCell = ({
  error,
  isDatevApiConnected,
  isCodatConnected,
}: Props) => {
  if (isDatevApiConnected) {
    if (!error?.updatedAt || !error?.createdAt) return null;

    return (
      <Tooltip
        title={
          <Trans
            i18nKey="exportPage.table.exportError"
            values={{
              date: moment(error.updatedAt || error.createdAt).format(
                'DD.MM.YYYY, HH:mm'
              ),
            }}
          />
        }
      >
        <WarningIcon color="warning" />
      </Tooltip>
    );
  }

  if (isCodatConnected) {
    if (error?.error?.error)
      return (
        <Tooltip title={error.error.error}>
          <WarningIcon color="warning" />
        </Tooltip>
      );

    if (error?.receiptError?.error)
      return (
        <Tooltip title={error.receiptError.error}>
          {/* todo: maybe the icon should be updated later (re-upload) */}
          <WarningIcon color="warning" />
        </Tooltip>
      );

    return null;
  }

  return null;
};

export default ExportErrorCell;
