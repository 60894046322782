import { ComponentType } from 'react';
import { SxProps, Theme } from '@mui/material';
import styled from 'styled-components';
import Overlay, { OverlayProps } from 'components/Overlay';
import { Box, SvgIconProps, Typography } from 'elements';

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grey100};
  transform: translate(-50%, -50%);
  text-align: center;
`;

const IconHolder = styled.div`
  margin: 0 0 8px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

interface NoDataProps extends OverlayProps {
  icon: React.ReactElement;
  label: string;
}

interface NoDataPropsNewDesign {
  isNewDesign: boolean;
  Icon: ComponentType<SvgIconProps>;
  label: string;
  sx?: SxProps<Theme>;
}

type Props = NoDataProps | NoDataPropsNewDesign;

const NoData = (props: Props) => {
  if ('isNewDesign' in props) {
    const { Icon, label, sx } = props;
    return (
      <Box
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        color="text.disabled"
        sx={sx}
      >
        <Icon color="inherit" />
        <Typography color="text.disabled" variant="subtitle1" mt={1}>
          {label}
        </Typography>
      </Box>
    );
  }

  const { icon, label, ...overlayProps } = props;

  return (
    <Overlay {...overlayProps}>
      <Container>
        <IconHolder>{icon}</IconHolder>
        <div>{label}</div>
      </Container>
    </Overlay>
  );
};

export default NoData;
