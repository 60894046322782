import { useMemo } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { supportedCountries, SupportedCountry } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  trimObjValues,
  validateAddress,
} from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface FormValues {
  city: string;
  country: SupportedCountry;
  postalCode: string;
  streetAdditional: string;
  streetName: string;
  streetNumber: string;
}

const BillingAddressChangeDialog = (props: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const org = useMemo(() => organization!, []);

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    validate: (values) => {
      const billingAddressErrors = validateAddress(values, t);
      return { ...billingAddressErrors };
    },
    initialValues: {
      city: org.billingAddress.city || '',
      postalCode: org.billingAddress.postalCode || '',
      streetName: org.billingAddress.streetName || '',
      streetNumber: org.billingAddress.streetNumber || '',
      streetAdditional: org.billingAddress.streetAdditional || '',
      country: org.billingAddress.country || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const data = await api.updateBillingAddress(
          org.id,
          trimObjValues(values)
        );
        if (!mounted.current) return;
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { organization: data },
        });
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const hasEmptyField =
    !formik.values.country.trim() ||
    !formik.values.city.trim() ||
    !formik.values.postalCode.trim() ||
    !formik.values.streetName.trim() ||
    !(
      formik.values.country === SupportedCountry.ie ||
      formik.values.streetNumber.trim()
    );

  return (
    <Dialog {...props}>
      <DialogTitle>{t('settingsOrganizationPage.billingAddress')}</DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="billing-address-change-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={6}>
              <TextField
                label={t('billingAddressForm.streetNameField')}
                {...formik.getFieldProps('streetName')}
                disabled={formik.isSubmitting}
                error={!!formik.errors.streetName}
                helperText={formik.errors.streetName}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t('billingAddressForm.streetNumberField')}
                {...formik.getFieldProps('streetNumber')}
                disabled={formik.isSubmitting}
                error={!!formik.errors.streetNumber}
                helperText={formik.errors.streetNumber}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t('billingAddressForm.streetAdditionalField')}
                {...formik.getFieldProps('streetAdditional')}
                disabled={formik.isSubmitting}
                error={!!formik.errors.streetAdditional}
                helperText={formik.errors.streetAdditional}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t('billingAddressForm.postalCodeField')}
                {...formik.getFieldProps('postalCode')}
                disabled={formik.isSubmitting}
                error={!!formik.errors.postalCode}
                helperText={formik.errors.postalCode}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={t('billingAddressForm.cityField')}
                {...formik.getFieldProps('city')}
                disabled={formik.isSubmitting}
                error={!!formik.errors.city}
                helperText={formik.errors.city}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="billing-address-country">
                  {t('billingAddressForm.country')}
                </InputLabel>

                <Select<string>
                  disabled
                  labelId="billing-address-country"
                  renderValue={(selected) => {
                    return typeof selected === 'string' && selected
                      ? t(`countries.${selected}`)
                      : '';
                  }}
                  {...formik.getFieldProps('country')}
                >
                  {supportedCountries.map((slug) => {
                    return (
                      <MenuItem key={slug} value={slug}>
                        {t(`countries.${slug}`)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          disabled={formik.isSubmitting}
          onClick={props.onClose}
        >
          {t('common.button.cancel')}
        </Button>
        <Button
          form="billing-address-change-form"
          type="submit"
          disabled={hasEmptyField || formik.isSubmitting}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(BillingAddressChangeDialog);
