import React, { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  ComposedChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getNiceTickValues } from 'recharts-scale';
import styled, { useTheme } from 'styled-components';
import { MonthlyEmissionData } from 'services/constants';
import CustomTooltip from './Tooltip';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const ChartWrapper = styled.div`
  .xAxis text {
    fill: ${({ theme }) => theme.colors.grey100};
    font-size: 10px;
    line-height: 14px;
  }

  .yAxis text {
    fill: ${({ theme }) => theme.colors.grey100};
    font-size: 10px;
    line-height: 14px;
  }
`;

const Label = styled.span`
  font-size: 10px;
  line-height: 14px;
`;

interface Props {
  data: MonthlyEmissionData[];
  year: number;
}

const BarChart = ({ data, year }: Props) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { domain, plotData } = useMemo(() => {
    const newPlotData = moment
      .monthsShort()
      .map((label) => ({ name: label, offset: 0, emission: 0 }));

    let absMaxValue = 0;
    data.forEach((item) => {
      const idx = item.month - 1;
      const offset = Math.round(item.offsetAmount / 1000);
      const emission = Math.round(item.emissionAmount / 1000);

      if (Math.max(offset, emission) > absMaxValue) {
        absMaxValue = Math.max(offset, emission);
      }

      newPlotData[idx] = {
        ...newPlotData[idx],
        offset,
        emission: -emission,
      };
    });

    let maxTickValue = 100;
    if (data.length) {
      // center XAxis in middle of BarChart
      // and scale YAxis equally to both sides with "0" label displayed
      maxTickValue = getNiceTickValues([0, absMaxValue], 3)[2];
    }

    return {
      plotData: newPlotData,
      domain: [-maxTickValue, maxTickValue],
    };
  }, [data]);

  return (
    <Container>
      <Label>{t('pliantEarthPage.dashboard.barChart.compensationLabel')}</Label>

      <ChartWrapper>
        <ComposedChart
          data={plotData}
          height={154}
          margin={{ top: 5, left: 0, right: 0, bottom: 5 }}
          stackOffset="sign"
          width={519}
        >
          <XAxis
            axisLine={false}
            tickLine={false}
            interval={0}
            dataKey="name"
            dy={5}
          />

          <YAxis
            axisLine={false}
            tickLine={false}
            type="number"
            interval={0}
            width={45}
            textAnchor="start"
            dx={-35}
            domain={domain}
            tickFormatter={(value: number) => `${Math.abs(value)}`}
          />

          <Bar
            dataKey="offset"
            fill={colors.earthRoyalBlue}
            stackId="a"
            radius={[4, 4, 0, 0]}
            barSize={21}
          />

          <Bar
            dataKey="emission"
            fill={colors.earthGrey}
            stackId="a"
            radius={[4, 4, 0, 0]}
            barSize={21}
          />

          <ReferenceLine
            y={0}
            stroke={colors.white}
            strokeWidth="2"
            isFront={true}
          />

          <Tooltip
            allowEscapeViewBox={{ x: true, y: false }}
            content={<CustomTooltip year={year} />}
            cursor={false}
          />
        </ComposedChart>
      </ChartWrapper>

      <Label>{t('pliantEarthPage.dashboard.barChart.emissionLabel')}</Label>
    </Container>
  );
};

export default BarChart;
