import { Components, Theme } from '@mui/material';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    caption2: boolean;
    overline2: boolean;
  }
}

const MuiTypographyOverrides: Components<Theme> = {
  MuiTypography: {
    variants: [
      {
        props: { variant: 'caption2' },
        style: ({ theme }) => ({
          ...theme.variables.typography.caption2,
        }),
      },
      {
        props: { variant: 'overline2' },
        style: ({ theme }) => ({
          ...theme.variables.typography.overline2,
          textTransform: 'uppercase',
        }),
      },
    ],
  },
};

export default MuiTypographyOverrides;
