import { useTranslation } from 'react-i18next';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { Box, Button, Typography, VerticalCardsIcon } from 'elements';
import { useCanUser } from 'services/rbac';

interface Props {
  areFiltersApplied: boolean;
  onIssueCardClick: () => void;
}

const NoCards = ({ areFiltersApplied, onIssueCardClick }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();

  if (areFiltersApplied) return <NothingFound />;

  return canUser('card:create') ? (
    <Box
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography variant="h6" mb={1}>
        {t('cardsPage.noCardsIssued')}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={3}>
        {t('cardsPage.issueFirstCard')}
      </Typography>
      <Button onClick={onIssueCardClick}>{t('cardsPage.issueCard')}</Button>
    </Box>
  ) : (
    <NoData
      isNewDesign
      Icon={VerticalCardsIcon}
      label={t('cardsPage.noCards')}
    />
  );
};

export default NoCards;
