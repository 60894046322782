import React from 'react';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  PercentField,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { CreditAssessmentSummaryPayload } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface FormValues extends CreditAssessmentSummaryPayload {}

const EditCreditAssessmentSummaryDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, organizationsStaticData },
  } = useGlobalState();
  const {
    actions: { refetchCompleteContext },
    state: { creditAssessment, creditProfile },
  } = useCreditAndComplianceContext();
  const agencyProfile = creditProfile!.agencyProfile;

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      nextMonitoringDate: creditAssessment!.summary.nextMonitoringDate.value,
      probabilityOfDefault: agencyProfile.probabilityOfDefault.value,
      probabilityOfDefaultSource:
        agencyProfile.probabilityOfDefaultSource.value,
    },
    validate: (values) => {
      const errors: { [k in keyof FormValues]?: string } = {};
      if (!values.probabilityOfDefault) {
        errors['probabilityOfDefault'] = t('errors.fieldRequired');
      }
      if (!values.probabilityOfDefaultSource) {
        errors['probabilityOfDefaultSource'] = t('errors.fieldRequired');
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await api.updateCreditAssessmentSummary(organization!.id, values);
        await refetchCompleteContext();

        if (!mounted) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const getCustomFieldProps = <T extends keyof FormValues>(name: T) => {
    return {
      ...(formik.errors[name] && {
        error: true,
        helperText: formik.errors[name],
      }),
      name,
      value: formik.values[name],
    };
  };
  const onChange = <T extends keyof FormValues>(
    name: T,
    value: FormValues[T]
  ) => {
    if (formik.errors[name]) {
      formik.setFieldError(name, undefined);
    }
    formik.setFieldValue(name, value);
  };

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>
        {t('int.editCreditAssessmentSummaryDialog.title')}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <PercentField
              {...getCustomFieldProps('probabilityOfDefault')}
              onValueChange={({ floatValue = null }) =>
                onChange('probabilityOfDefault', floatValue)
              }
              label={t('int.editCreditAssessmentSummaryDialog.pd')}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={!!formik.errors.probabilityOfDefaultSource}
            >
              <InputLabel>
                {t('int.editCreditAssessmentSummaryDialog.source')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('probabilityOfDefaultSource'),
                  'helperText'
                )}
                onChange={(event) =>
                  onChange('probabilityOfDefaultSource', event.target.value)
                }
              >
                {organizationsStaticData!.probabilityOfDefaultSources.map(
                  (item) => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.label}
                    </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText>
                {formik.errors.probabilityOfDefaultSource}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('nextMonitoringDate')}
              onChange={(event) =>
                onChange('nextMonitoringDate', event.target.value)
              }
              label={t(
                'int.editCreditAssessmentSummaryDialog.nextMonitoringDate'
              )}
              type="date"
              inputProps={{
                min: moment().add(1, 'days').format('YYYY-MM-DD'),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={formik.isSubmitting} onClick={formik.submitForm}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditCreditAssessmentSummaryDialog);
