import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  CategoryFilter,
  FlagReasonFilter,
  ProjectFilter,
  ReceiptFilter,
  ReviewFilter,
  TeamFilter,
} from 'domains/transaction/components';
import { QueryParams } from 'domains/transaction/pages/AccountEntriesPage';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import {
  MerchantCategory,
  NONE_VALUE,
  Project,
  ReviewFlagReason,
  Team,
  TransactionReceiptStatus,
  TransactionReviewStatus,
} from 'services/constants';
import AccountEntriesSubTypeFilter, {
  AccountEntriesSubTypeFilterOption,
} from './AccountEntriesSubTypeFilter';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  teams: Team[];
  projects: Project[];
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  subType: AccountEntriesSubTypeFilterOption[];
  category: MerchantCategory[];
  teamId: string[];
  projectIds: string[];
  receipt: TransactionReceiptStatus | '';
  fromDate: Moment | null;
  toDate: Moment | null;
  reviewStatus: TransactionReviewStatus | typeof NONE_VALUE | '';
  flagReason: ReviewFlagReason | '';
}

const initialState: State = {
  subType: [],
  category: [],
  teamId: [],
  projectIds: [],
  receipt: '',
  fromDate: null,
  toDate: null,
  reviewStatus: '',
  flagReason: '',
};

const FiltersDrawer = ({
  teams,
  projects,
  params,
  setParam,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();
  const [state, setState] = useState<State>(initialState);

  const areFiltersSelected = !!(
    state.subType.length ||
    state.category.length ||
    state.teamId.length ||
    state.projectIds.length ||
    state.receipt ||
    state.fromDate ||
    state.toDate ||
    state.reviewStatus ||
    state.flagReason
  );

  useEffect(() => {
    if (props.open)
      setState({
        subType: params.subType,
        category: params.category,
        teamId: params.teamId,
        projectIds: params.projectIds,
        receipt: params.receipt,
        fromDate: params.fromDate,
        toDate: params.toDate,
        reviewStatus: params.reviewStatus,
        flagReason: params.flagReason,
      });
  }, [props.open]);

  const onFiltersApply = () => {
    const filtersToApply = {
      ...state,
      fromDate: state.fromDate?.format('YYYY-MM-DD'),
      toDate: state.toDate?.format('YYYY-MM-DD'),
    };

    setParam(
      Object.keys(filtersToApply).map((key) => [
        key,
        filtersToApply[key as keyof State],
      ])
    );
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(
      featureModules.MULTI_CARD_ACCOUNT || featureModules.MULTI_CURRENCY_BILLING
        ? [
            ...Object.entries(initialState),
            ['cardAccountId', params.cardAccountId],
          ]
        : Object.entries(initialState)
    );
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <AccountEntriesSubTypeFilter
        value={state.subType}
        onChange={(value) =>
          setState((prevState) => ({
            ...prevState,
            subType: xor(prevState.subType, [value]),
          }))
        }
      />

      <DateFilter
        label={t('filters.bookingDate')}
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate,
            toDate,
          }))
        }
      />

      <ReceiptFilter
        value={state.receipt}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, receipt: value }))
        }
      />

      <CategoryFilter
        value={state.category}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, category: value }))
        }
      />

      {!!teams.length && (
        <TeamFilter
          teams={teams}
          value={state.teamId}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, teamId: value }))
          }
        />
      )}

      {!!projects.length && (
        <ProjectFilter
          projects={projects}
          value={state.projectIds}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, projectIds: value }))
          }
        />
      )}

      <ReviewFilter
        value={state.reviewStatus}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, reviewStatus: value }))
        }
      />

      <FlagReasonFilter
        value={state.flagReason}
        onChange={(flagReason) =>
          setState((prevState) => ({
            ...prevState,
            flagReason,
            reviewStatus:
              prevState.reviewStatus || TransactionReviewStatus.flagged,
          }))
        }
      />
    </FilterDrawer>
  );
};

export default FiltersDrawer;
