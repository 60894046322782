import { useEffect, useRef, useState } from 'react';
import lodashIntersection from 'lodash/intersection';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import { OrganizationSettlementDetailsPage } from 'domains/billing/pages';
import {
  BookIcon,
  Box,
  DataGrid,
  GridSortModel,
  gridUtils,
  LoaderWithOverlay,
  Typography,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  accountGroups,
  DEFAULT_PAGE_LIMIT,
  Money,
  organizationSettlementStatuses,
  Settlement,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { isSortValid } from 'services/utils';
import Filters from './Filters';
import useColumns from './useColumns';

const getQueryParams = (qs: string, allowedSortKeys: string[]) => {
  const {
    sort,
    q,
    status,
    fromDate,
    toDate,
    accountGroup,
  } = Object.fromEntries(new URLSearchParams(qs).entries());

  const fromDateMoment = moment(fromDate, moment.ISO_8601);
  const toDateMoment = moment(toDate, moment.ISO_8601);

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '-createdAt',
    q: q ? q.trim() : '',
    status: status
      ? lodashIntersection(status.split(','), organizationSettlementStatuses)
      : [],
    fromDate: fromDateMoment.isValid() ? fromDateMoment : null,
    toDate:
      fromDateMoment.isValid() && toDateMoment.isValid() ? toDateMoment : null,
    accountGroup: (accountGroups as string[]).includes(accountGroup)
      ? accountGroup
      : '',
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({
  status,
  fromDate,
  accountGroup,
}: QueryParams) =>
  0 + (status.length ? 1 : 0) + (fromDate ? 1 : 0) + +!!accountGroup;

interface State {
  isLoading: boolean;
  settlements: Settlement[];
  hasNextPage: boolean;
  totalSettlementsAmount: Money | null;
}

const OrganizationSettlementsPage = () => {
  const dataGridRef = useGridApiRef();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:settlementId',
    true
  );
  const { allowedSortKeys, columns } = useColumns();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const showPageError = useShowPageError();
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(getQueryParams(location.search, allowedSortKeys));
  const pageRef = useRef(0);

  const [state, setState] = useState<State>({
    isLoading: true,
    settlements: [],
    hasNextPage: false,
    totalSettlementsAmount: null,
  });

  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);

  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;
  const isEmptyState =
    !state.isLoading && !state.settlements.length && !areFiltersApplied;

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      if (!isLoadMore) {
        setState((state) => ({
          ...state,
          isLoading: true,
        }));
      }
      const {
        sort,
        q,
        status,
        fromDate,
        toDate,
        accountGroup,
      } = paramsRef.current;
      const {
        settlements,
        hasNextPage,
        totalSettlementsAmount,
      } = await api.getOrgSettlements({
        page,
        limit,
        sort,
        q: q.length ? q : undefined,
        statuses: status.join() || undefined,
        fromDate: fromDate?.format('YYYY-MM-DD'),
        toDate: toDate?.format('YYYY-MM-DD'),
        accountGroup: accountGroup || undefined,
        organizationId: organization!.id,
      });
      if (!mounted.current) return;
      setState((state) => ({
        isLoading: false,
        settlements: isLoadMore
          ? [...state.settlements, ...settlements]
          : settlements,
        hasNextPage,
        totalSettlementsAmount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });

    paramsRef.current = getQueryParams(location.search, allowedSortKeys);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const handleSortModelChange = (sort: GridSortModel) => {
    if (state.isLoading || !state.settlements.length) return;
    setQueryParam('sort', gridUtils.getNewSortParam(sort));
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('orgSettlementsPage.header')}>
          {/* should be done using absolute position, as is dynamic and affects the title height */}
          {state.totalSettlementsAmount && (
            <Box position="absolute" right="0" top="0" textAlign="right">
              <Typography variant="caption" color="textSecondary">
                {t('orgSettlementsPage.cumulativeAmount')}
              </Typography>
              <Typography variant="h4" sx={{ whiteSpace: 'nowrap' }}>
                <FormatMoney
                  value={state.totalSettlementsAmount}
                  fractionalPart
                />
              </Typography>
            </Box>
          )}
        </PageTitle>

        <Filters
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          disabled={isEmptyState}
        />
      </PageHeader>

      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid<Settlement>
          apiRef={dataGridRef}
          getRowId={(row) => row.settlementId}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          rowSelectionModel={
            detailsParams?.settlementId ? [detailsParams.settlementId] : []
          }
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(paramsRef.current.sort),
            },
          }}
          onSortModelChange={handleSortModelChange}
          loading={state.isLoading}
          rows={state.settlements}
          columns={columns}
          columnVisibilityModel={{
            issuer: !isDetailsPageOpen,
            bank: !isDetailsPageOpen,
            transactionsCount: !isDetailsPageOpen,
            feesAmount: !isDetailsPageOpen,
            drawerPlaceholder: isDetailsPageOpen,
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ id, row }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${row.settlementId}${location.search}`);
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.settlements.length && areFiltersApplied)
                return <NothingFound $top={0} $bottom={0} />;

              return (
                <NoData
                  isNewDesign
                  Icon={BookIcon}
                  label={t('orgSettlementsPage.noData')}
                  $top={90}
                />
              );
            },
            loadingOverlay: () => null,
          }}
        />

        <Route
          path={`${path}/:settlementId`}
          children={({ match }) => (
            <OrganizationSettlementDetailsPage open={!!match} />
          )}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(OrganizationSettlementsPage, {
  permission: 'settlements-page:visit',
});
