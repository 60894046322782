import styled from 'styled-components';
import { Button } from 'components/Button';

export const HR = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutralVariant60};
  margin: 40px 0;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
`;

export const StyledButton = styled(Button)`
  font-size: 14px;
  height: 40px;
  line-height: 20px;
  padding: 10px 24px;
`;
