import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { CompensationBatchDetailsPage } from 'domains/billing/pages';
import {
  DataGrid,
  GlobeHemisphereWestIcon,
  GridSortModel,
  gridUtils,
  LoaderWithOverlay,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import { CompensationBatch, DEFAULT_PAGE_LIMIT } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { isSortValid } from 'services/utils';
import useColumns from './useColumns';

const getQueryParams = (qs: string, allowedSortKeys: string[]) => {
  const { sort } = Object.fromEntries(new URLSearchParams(qs).entries());

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '-createdAt',
  };
};

interface State {
  isLoading: boolean;
  batches: CompensationBatch[];
  hasNextPage: boolean;
}

const CompensationBatchesPage = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { path, url } = useRouteMatch();
  const { allowedSortKeys, columns } = useColumns();
  const location = useLocation();
  const history = useHistory();
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:batchId',
    true
  );
  const showPageError = useShowPageError();
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(getQueryParams(location.search, allowedSortKeys));
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const [state, setState] = useState<State>({
    isLoading: true,
    batches: [],
    hasNextPage: false,
  });

  const getData = async (page: number, isLoadMore = false) => {
    try {
      if (!isLoadMore) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
      }
      const { sort } = paramsRef.current;
      const { batches, hasNextPage } = await api.getCompensationBatches({
        page,
        limit: DEFAULT_PAGE_LIMIT,
        sort,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        isLoading: false,
        batches: isLoadMore ? [...prevState.batches, ...batches] : batches,
        hasNextPage,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });
    paramsRef.current = getQueryParams(location.search, allowedSortKeys);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, true);
  };

  const handleSortModelChange = (sort: GridSortModel) => {
    if (state.isLoading || !state.batches.length) return;
    setQueryParam('sort', gridUtils.getNewSortParam(sort));
  };

  const onBatchUpdate = (batch: CompensationBatch) => {
    setState((prevState) => ({
      ...prevState,
      batches: prevState.batches.map((item) =>
        item.id === batch.id ? batch : item
      ),
    }));
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('int.compensationBatchesPage.title')} />
      </PageHeader>
      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />
        <DataGrid<CompensationBatch>
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          apiRef={dataGridRef}
          rowSelectionModel={
            detailsParams?.batchId ? [detailsParams.batchId] : []
          }
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(paramsRef.current.sort),
            },
          }}
          loading={state.isLoading}
          rows={state.batches}
          columns={columns}
          columnVisibilityModel={{ drawerPlaceholder: isDetailsPageOpen }}
          onSortModelChange={handleSortModelChange}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ id }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${id}${location.search}`);
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.batches.length) {
                return <NothingFound />;
              }
              return (
                <NoData
                  isNewDesign
                  Icon={GlobeHemisphereWestIcon}
                  label={t('int.compensationBatchesPage.noData')}
                />
              );
            },
            loadingOverlay: () => null,
          }}
          rowHeight={72}
        />
        <Route
          path={`${path}/:batchId`}
          children={({ match }) => (
            <CompensationBatchDetailsPage
              open={!!match}
              onUpdate={onBatchUpdate}
            />
          )}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(CompensationBatchesPage, {
  permission: 'compensation-batches-page:visit',
});
