import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from 'elements';
import useUrls from 'hooks/useUrls';
import { Role } from 'services/rbac';

interface AvailableRole {
  role: Role;
  isAllowed: boolean;
}

interface Props {
  showLabel?: boolean;
  value: Role;
  onChange: (role: Role) => void;
  allowedRoles: AvailableRole[];
  isDisabled?: boolean;
  tipKey?: string;
  tipLink?: string;
}

const RolePicker = ({
  showLabel = true,
  value,
  onChange,
  allowedRoles,
  isDisabled = false,
  tipKey = 'rolePicker.tip',
  tipLink,
}: Props) => {
  const { t } = useTranslation();
  const { HELP_CENTER_MEMBER_ROLES_URL } = useUrls();

  if (!allowedRoles.length) return null;

  return (
    <FormControl disabled={isDisabled} fullWidth>
      {showLabel && <InputLabel>{t('rolePicker.roles')}</InputLabel>}
      <Select<Role>
        value={value}
        onChange={(e) => {
          onChange(e.target.value as Role);
        }}
        renderValue={(selected) => t(`roles.${selected}`)}
      >
        {allowedRoles.map((option) => (
          <MenuItem key={option.role} value={option.role}>
            {t(`roles.${option.role}`)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        <Trans
          i18nKey={tipKey}
          components={{
            a: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                style={{ color: 'inherit' }}
                href={tipLink || HELP_CENTER_MEMBER_ROLES_URL}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      </FormHelperText>
    </FormControl>
  );
};

export default RolePicker;
