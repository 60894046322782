import { Components } from '@mui/material';

const MuiSelectOverrides: Components = {
  MuiSelect: {
    defaultProps: {
      fullWidth: true,
      notched: false,
      size: 'small',
    },
  },
};

export default MuiSelectOverrides;
