import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  margin: 12px 0 0 12px;
  min-width: 140px;
  padding: 8px 14px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);

  /* triangle on side of tooltip */
  &:after {
    z-index: -10;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 0;
    bottom: 0;
    top: calc(50% - 4px);
    left: 0;
    box-sizing: border-box;

    border: 4px solid ${({ theme }) => theme.colors.neutralVariant50};
    border-top-color: transparent;
    border-right-color: transparent;

    transform-origin: 0 0;
    transform: rotate(45deg);
  }

  &:before {
    z-index: 10;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 1px;
    bottom: 0;
    top: calc(50% - 4px);
    left: 0;
    box-sizing: border-box;

    border: 4px solid ${({ theme }) => theme.colors.white};
    border-top-color: transparent;
    border-right-color: transparent;

    transform-origin: 0 0;
    transform: rotate(45deg);
  }
`;

const Row = styled.div<{ $isOffset?: boolean }>`
  color: ${({ $isOffset = false, theme }) =>
    theme.colors[$isOffset ? 'earthRoyalBlue' : 'earthGrey']};
  display: flex;
  font-size: 14px;
  line-height: 20px;
  justify-content: space-between;

  > span:first-of-type {
    margin-right: 16px;
  }
`;

const Label = styled.div`
  font-size: 10px;
  line-height: 22px;
  margin-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.colors.grey100};
`;

interface Props extends TooltipProps<number, string> {
  year: number;
}

const CustomTooltip = ({ active, payload, year, ...rest }: Props) => {
  const { t } = useTranslation();

  if (active && payload?.length) {
    const { name, offset, emission } = payload[0].payload;

    return (
      <Container>
        <Row $isOffset>
          <span>{t('pliantEarthPage.dashboard.barChart.compensation')}</span>
          <span>{offset}</span>
        </Row>

        <Row>
          <span>{t('pliantEarthPage.dashboard.barChart.emission')}</span>
          <span>{Math.abs(emission)}</span>
        </Row>

        <Label>{`${name} ${year}`}</Label>
      </Container>
    );
  }

  return null;
};

export default CustomTooltip;
