import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import Select, { SelectProps } from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as ArrowsButtonDownIcon } from 'assets/icons/interface/arrowsButtonDown.svg';
import { ClearFilterButton, StyledInputLabel } from './StyledInputLabel';

const StyledFormControl = styled(FormControl)`
  margin: 0 0 0 16px;
`;

const StyledInput = styled(InputBase)`
  & .MuiInputBase-input {
    position: relative;
    width: 210px;
    height: 46px;
    padding: 13px 40px 0 14px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 20px;
    border-radius: 4px;
    border: 1px solid transparent;
    color: ${({ theme }) => theme.colors.grey10};
    background: ${({ theme }) => theme.colors.neutralVariant100};
    transition: border-color 0.2s;

    &[aria-expanded='true'] {
      border-color: ${({ theme }) => theme.colors.grey10};
    }
  }
`;

const StyledArrowsButtonDownIcon = styled(ArrowsButtonDownIcon)`
  top: calc(50% - 8px);
  right: 16px;
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.colors.grey10};
`;

const StyledSelect = styled(({ className, ...props }: SelectProps) => (
  <Select
    input={<StyledInput />}
    IconComponent={StyledArrowsButtonDownIcon}
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      getContentAnchorEl: null,
      classes: { paper: className },
    }}
    {...props}
  />
))`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);

  & .MuiMenu-list {
    padding: 12px 0;
  }

  .MuiListSubheader-root {
    font-size: 12px;
    line-height: 34px;
    padding: 0 24px;
    color: ${({ theme }) => theme.colors.grey100};
    pointer-events: none;
  }

  & .MuiListItem-root {
    min-height: 44px;
    padding: 1px 20px;

    &:hover,
    &.Mui-selected {
      background: ${({ theme }) => theme.colors.neutralVariant80};
    }
  }

  .MuiTypography-root {
    font-size: 16px;
    line-height: 20px;
  }
`;

export interface PageStatusSelectProps extends SelectProps {
  label: string;
  onClear?: () => void;
}

export const PageFilterSelect = ({
  label,
  onClear,
  ...selectProps
}: PageStatusSelectProps) => {
  const { t } = useTranslation();

  return (
    <StyledFormControl>
      <StyledInputLabel htmlFor={`page-filter-select-${label}`}>
        {label}
      </StyledInputLabel>
      {onClear && (
        <ClearFilterButton onClick={onClear}>
          {t('filters.clearFilter')}
        </ClearFilterButton>
      )}
      <StyledSelect labelId={`page-filter-select-${label}`} {...selectProps} />
    </StyledFormControl>
  );
};
