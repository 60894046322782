import { matchPath, useHistory } from 'react-router-dom';
// imported directly from the paths.ts file because of a circular dependency issue
import { cardholderPaths } from 'components/App/ExternalApp/paths';

const cardholderPathsArray = Object.values(cardholderPaths);

// @deprecated - use useCurrentApp instead
const useIsAdminApp = () => {
  const history = useHistory();
  return !cardholderPathsArray.some((path) =>
    matchPath(history.location.pathname, {
      path,
    })
  );
};

export default useIsAdminApp;
