import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from 'elements';
import useLocoiaMappedName from 'hooks/useLocoiaMappedName';
import {
  ApiIntegrationStatus,
  ExportFormat,
  InternalExportFormat,
  SUPPORT_EMAIL,
} from 'services/constants';
import { getPath } from 'services/utils';

interface Props {
  format: ExportFormat | InternalExportFormat;
  onSave: (format: ExportFormat) => void;
}

const CustomCSVExportFormats = ({ format, onSave }: Props) => {
  const { t } = useTranslation();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();

  const isAccSystemConnected =
    accountingSettings!.apiIntegrationStatus === ApiIntegrationStatus.connected;
  const locoiaMappedName = useLocoiaMappedName();

  if (!isAccSystemConnected) {
    return (
      <FormControl fullWidth error={format === InternalExportFormat.customCsv}>
        <InputLabel>{t('exportTransactionsDialog.exportFormat')}</InputLabel>
        <Select
          name="format"
          value={format}
          onChange={(e) => onSave(e.target.value as ExportFormat)}
        >
          <MenuItem value={InternalExportFormat.customCsv} disabled>
            {locoiaMappedName}
          </MenuItem>
          <MenuItem value={ExportFormat.csv}>CSV</MenuItem>
        </Select>
        {format === InternalExportFormat.customCsv && (
          <FormHelperText>
            <Trans
              i18nKey="customCsvWarningTooltip.title"
              components={{
                linkTo: (
                  <Link
                    style={{ color: 'inherit' }}
                    to={generatePath(getPath('settingsLocoia'), {
                      orgId: organization!.id,
                    })}
                  />
                ),
                sup: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    style={{ color: 'inherit' }}
                    href={'mailto:' + SUPPORT_EMAIL}
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  return (
    <Table sx={{ marginTop: -2 }}>
      <TableBody>
        <TableRow>
          <TableCell sx={{ width: '48%' }}>
            <Typography variant="subtitle2">
              {t('exportTransactionsDialog.exportFormat')}
            </Typography>
          </TableCell>
          <TableCell>{locoiaMappedName}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default CustomCSVExportFormats;
