import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { CardBenefitsContent } from 'domains/reward/components';
import { InsuranceDetailsDialog } from 'domains/reward/dialogs';
import useChCardBenefits from 'domains/reward/pages/RewardsPage/ChCardBenefitsSubpage/useChCardBenefits';
import { LoaderWithOverlay, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer } from 'layout';
import {
  Card,
  CardNewType,
  CardPremiumProductType,
  memberCardStatusesToRequest,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { withPermission } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  cards: Card[];
  isLoading: boolean;
}

const CardBenefitsPage = () => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, member, cardConfigSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({ cards: [], isLoading: true });
  const { commonBenefits, premiumBenefits } = useChCardBenefits({
    openInsuranceDetailsDialog: () => setIsInsuranceDetailsDialogOpen(true),
  });
  const [
    isInsuranceDetailsDialogOpen,
    setIsInsuranceDetailsDialogOpen,
  ] = useState(false);
  const {
    virtualCardConfigSetting,
    physicalCardConfigSetting,
    physicalPremiumCardConfigSetting,
    hasVirtual,
    hasPhysical,
    hasPhysicalPremium,
  } = useMemo(() => {
    const issuable = cardConfigSettings.filter((item) => item.canBeIssued);
    const virtualCardConfigSetting = issuable.find(
      (item) => item.cardType === CardNewType.virtual && item.maxUsage === null
    );
    const physicalCardConfigSetting = issuable.find(
      (item) =>
        item.cardType === CardNewType.physical &&
        item.premiumProductType === null
    );
    const physicalPremiumCardConfigSetting = issuable.find(
      (item) =>
        item.cardType === CardNewType.physical &&
        item.premiumProductType === CardPremiumProductType.visaInfinite
    );
    const hasVirtual =
      !!virtualCardConfigSetting &&
      state.cards.some((item) => item.cardType === CardNewType.virtual);
    const hasPhysical =
      !!physicalCardConfigSetting &&
      state.cards.some(
        (item) =>
          item.cardType === CardNewType.physical &&
          item.premiumProductType === null
      );
    const hasPhysicalPremium =
      !!physicalPremiumCardConfigSetting &&
      state.cards.some(
        (item) =>
          item.cardType === CardNewType.physical &&
          item.premiumProductType === CardPremiumProductType.visaInfinite
      );

    return {
      virtualCardConfigSetting,
      physicalCardConfigSetting,
      physicalPremiumCardConfigSetting,
      hasVirtual,
      hasPhysical,
      hasPhysicalPremium,
    };
  }, [cardConfigSettings, state.cards]);

  const getData = async () => {
    try {
      const { cards } = await api.getCards({
        organizationId: organization!.id,
        memberId: member.id,
        status: memberCardStatusesToRequest.join(),
      });
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false, cards }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (state.isLoading) return <LoaderWithOverlay loading />;

  return (
    <>
      <ContentContainer>
        <Typography variant="h5" mb={1}>
          {t('cardBenefitsContent.title')}
        </Typography>
        <Typography variant="body2" mb={5}>
          {t('cardBenefitsContent.subtitle')}
        </Typography>
      </ContentContainer>
      {state.cards.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          {t('cardBenefitsPage.noItemsLabel')}
        </Typography>
      ) : (
        <>
          <CardBenefitsContent
            commonBenefits={commonBenefits}
            premiumBenefits={premiumBenefits}
            virtualCardConfigSetting={virtualCardConfigSetting}
            physicalCardConfigSetting={physicalCardConfigSetting}
            physicalPremiumCardConfigSetting={physicalPremiumCardConfigSetting}
            hasVirtual={hasVirtual}
            hasPhysical={hasPhysical}
            hasPhysicalPremium={hasPhysicalPremium}
          />
          <InsuranceDetailsDialog
            open={isInsuranceDetailsDialogOpen}
            onClose={() => setIsInsuranceDetailsDialogOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default withPermission(CardBenefitsPage, 'ch-card-benefits-page:visit');
