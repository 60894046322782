import { PaymentStatusBadge } from 'domains/billing/components';
import { visibleBatchPaymentStatuses } from 'domains/billing/utils';
import { Stack } from 'elements';
import { BillPaymentBatch, PaymentStatus } from 'services/constants';

interface Props {
  states: BillPaymentBatch['states'];
}

const BillPaymentBatchStatesCell = ({ states }: Props) => {
  const readyForPmt = states[PaymentStatus.readyForPmt];

  if (!!readyForPmt && readyForPmt > 0) {
    return <PaymentStatusBadge status={PaymentStatus.readyForPmt} />;
  }

  return (
    <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
      {visibleBatchPaymentStatuses
        .filter((paymentStatus) => !!states[paymentStatus])
        .map((paymentStatus) => (
          <PaymentStatusBadge
            key={paymentStatus}
            status={paymentStatus}
            count={states[paymentStatus]}
          />
        ))}
    </Stack>
  );
};

export default BillPaymentBatchStatesCell;
