import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { Box, Paper, Typography } from 'elements';
import { Money } from 'services/constants';

interface Props {
  transactionsCount: number;
  transactionsVolume: Money;
}

const MerchantTransactionsCountAndVolume = ({
  transactionsCount,
  transactionsVolume,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box display="flex">
      <Paper
        variant="outlined"
        sx={{ px: 1.5, py: 1, flex: '1 1 0', width: 0, mr: 2 }}
      >
        <Typography variant="body2" noWrap>
          {t('merchantTransactionsCountAndVolume.transactions')}
        </Typography>
        <Typography variant="caption" color="textSecondary" noWrap>
          {transactionsCount}
        </Typography>
      </Paper>
      <Paper
        variant="outlined"
        sx={{ px: 1.5, py: 1, flex: '1 1 0', width: 0 }}
      >
        <Typography variant="body2" noWrap>
          {t('merchantTransactionsCountAndVolume.totalVolume')}
        </Typography>
        <Typography variant="caption" color="textSecondary" noWrap>
          <FormatMoney value={transactionsVolume} fractionalPart />
        </Typography>
      </Paper>
    </Box>
  );
};

export default MerchantTransactionsCountAndVolume;
