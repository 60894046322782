import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Receipt,
  ReceiptRejectionReason,
  receiptRejectionReasons,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
  receiptId: string;
  onRejected: (receipt: Receipt) => void;
}

const RejectReceiptDialog = ({ receiptId, onRejected, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      reason: '' as ReceiptRejectionReason,
      comment: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const receipt = await api.rejectReceipt(
          receiptId,
          values.reason as ReceiptRejectionReason,
          values.comment
        );
        if (!mounted.current) return;
        props.onClose();
        onRejected(receipt);
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const submitDisabled =
    !formik.values.reason ||
    (formik.values.reason === ReceiptRejectionReason.other &&
      !formik.values.comment) ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('previewDialog.rejectReceipt')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          id="reject-receipt-form"
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth disabled={formik.isSubmitting}>
                <InputLabel id="reject-reason-select-label">
                  {t('previewDialog.selectReason')}
                </InputLabel>
                <Select<ReceiptRejectionReason>
                  labelId="reject-reason-select-label"
                  name="reason"
                  displayEmpty
                  value={formik.values.reason}
                  onChange={(e) =>
                    formik.setFieldValue('reason', e.target.value)
                  }
                  renderValue={(value) => {
                    if (!value)
                      return (
                        <Typography component="span" color="textSecondary">
                          {t('previewDialog.selectReasonPlaceholder')}
                        </Typography>
                      );
                    return t(`transactionReceipts.rejectReasons.${value}`);
                  }}
                >
                  {receiptRejectionReasons.map((value) => (
                    <MenuItem key={value} value={value}>
                      {t(`transactionReceipts.rejectReasons.${value}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('previewDialog.addComment', { count: 200 })}
                placeholder={t('previewDialog.leaveComment')}
                multiline
                rows={3}
                {...formik.getFieldProps('comment')}
                disabled={formik.isSubmitting}
                inputProps={{ maxLength: 200 }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          form="reject-receipt-form"
          disabled={submitDisabled}
          type="submit"
        >
          {t('previewDialog.rejectReceipt')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(RejectReceiptDialog);
