import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { DataItemRow } from 'domains/creditAndCompliance/components';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Box, EditButton, Grid, Typography } from 'elements';
import EditUnderwritingCaseDialog from './EditUnderwritingCaseDialog';

const UnderwritingCaseSection = () => {
  const { t } = useTranslation();
  const {
    state: { organizationsStaticData },
  } = useGlobalState();
  const {
    state: { creditAssessment },
  } = useCreditAndComplianceContext();
  const underwritingCase = creditAssessment!.underwritingCase;
  const [
    isEditUnderwritingCaseDialogOpen,
    setIsEditUnderwritingCaseDialogOpen,
  ] = useState(false);

  return (
    <>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        py={1}
      >
        <Typography mr={1} variant="h6">
          {t('int.underwritingCaseSection.title')}
        </Typography>

        <EditButton onClick={() => setIsEditUnderwritingCaseDialogOpen(true)} />
      </Box>

      <Grid container direction="column" spacing={2} py={3}>
        <DataItemRow
          label={t('int.underwritingCaseSection.expectedMonthlySpend')}
          updatedAt={underwritingCase.expectedMonthlySpend.updatedAt}
          updatedBy={underwritingCase.expectedMonthlySpend.updatedBy}
          value={
            underwritingCase.expectedMonthlySpend.value ? (
              <FormatMoney
                value={underwritingCase.expectedMonthlySpend.value}
              />
            ) : null
          }
        />
        <DataItemRow
          label={t('int.underwritingCaseSection.requestedLimit')}
          updatedAt={underwritingCase.requestedLimit.updatedAt}
          updatedBy={underwritingCase.requestedLimit.updatedBy}
          value={
            underwritingCase.requestedLimit.value ? (
              <FormatMoney value={underwritingCase.requestedLimit.value} />
            ) : null
          }
        />
        <DataItemRow
          label={t('int.underwritingCaseSection.requestedFrequency')}
          updatedAt={underwritingCase.requestedPaymentFrequency.updatedAt}
          updatedBy={underwritingCase.requestedPaymentFrequency.updatedBy}
          value={
            organizationsStaticData!.paymentFrequencies.find(
              (item) =>
                item.name === underwritingCase.requestedPaymentFrequency.value
            )?.label ?? null
          }
        />
        <DataItemRow
          label={t('int.underwritingCaseSection.requestedPaymentTerms')}
          updatedAt={underwritingCase.requestedPaymentTerms.updatedAt}
          updatedBy={underwritingCase.requestedPaymentTerms.updatedBy}
          value={
            organizationsStaticData!.paymentTerms.find(
              (item) =>
                item.name === underwritingCase.requestedPaymentTerms.value
            )?.label ?? null
          }
        />
        <DataItemRow
          label={t('int.underwritingCaseSection.availableUwCases')}
          updatedAt={null}
          updatedBy={null}
          value={
            <Grid container>
              {underwritingCase.availableUnderwritingCases.map((item, idx) => (
                <Grid item xs={12} key={item.name} display="flex">
                  <Typography
                    color={item.available ? 'textPrimary' : 'textSecondary'}
                    variant="body2"
                    sx={{
                      ...(!item.available && {
                        textDecoration: 'line-through',
                      }),
                    }}
                  >
                    {organizationsStaticData!.underwritingCaseTypes.find(
                      (x) => x.name === item.name
                    )?.label ?? item.name}
                  </Typography>
                  {item.unavailableReasons.length > 0 && (
                    <Typography variant="body2" color="textSecondary">
                      {` (${item.unavailableReasons.join(', ')})`}
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>
          }
        />
        <DataItemRow
          label={t('int.underwritingCaseSection.selectedUwCase')}
          updatedAt={underwritingCase.selectedUnderwritingCase.updatedAt}
          updatedBy={underwritingCase.selectedUnderwritingCase.updatedBy}
          value={
            <Box maxWidth="320px" whiteSpace="normal">
              {(underwritingCase.selectedUnderwritingCase.value &&
                organizationsStaticData!.underwritingCaseTypes.find(
                  (x) =>
                    x.name === underwritingCase.selectedUnderwritingCase.value
                )?.label) ??
                underwritingCase.selectedUnderwritingCase.value}
              {underwritingCase.discrepancyReason.value && (
                <Typography variant="body2" color="textSecondary">
                  {organizationsStaticData!.discrepancyReasons.find(
                    (item) =>
                      item.name === underwritingCase.discrepancyReason.value
                  )?.label ?? ''}
                  {underwritingCase.discrepancyReasonDescription.value &&
                    ` (${underwritingCase.discrepancyReasonDescription.value})`}
                </Typography>
              )}
            </Box>
          }
        />
      </Grid>

      <EditUnderwritingCaseDialog
        open={isEditUnderwritingCaseDialogOpen}
        onClose={() => setIsEditUnderwritingCaseDialogOpen(false)}
      />
    </>
  );
};

export default UnderwritingCaseSection;
