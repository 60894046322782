import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Chip, FunnelSimpleIcon, Tooltip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { getFilterChipText, PageFilterChips, PageFilters } from 'layout';
import FiltersDrawer from './FiltersDrawer';
import { QueryParams } from '.././index';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  disabled: boolean;
}

const Filters = ({
  params,
  selectedFiltersCount,
  setParam,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const createdAtChipText = useMemo(
    () =>
      [params.createdDateFrom, params.createdDateTo]
        .map((val) => val?.format('D MMM YYYY'))
        .filter((val) => !!val)
        .join(' - '),
    [params.createdDateFrom, params.createdDateTo]
  );

  const paymentDateChipText = useMemo(
    () =>
      [params.paymentDateFrom, params.paymentDateTo]
        .map((val) => val?.format('D MMM YYYY'))
        .filter((val) => !!val)
        .join(' - '),
    [params.paymentDateFrom, params.paymentDateTo]
  );

  const statesChipTooltip = useMemo(() => {
    if (params.states.length < 2) return '';
    return params.states
      .map((item) => t(`cashbackRedemptionStatuses.${item}`))
      .join(', ')
      .toUpperCase();
  }, [params.states]);

  return (
    <>
      <PageFilters>
        <Button
          variant={selectedFiltersCount ? 'contained' : 'text'}
          startIcon={<FunnelSimpleIcon />}
          onClick={() => setIsFilterOpen(true)}
          disabled={disabled}
        >
          {t('filterDrawer.title', { defaultValue: 'Filter' })}
          {!!selectedFiltersCount && ` (${selectedFiltersCount})`}
        </Button>

        <FiltersDrawer
          open={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
          params={params}
          setParam={setParam}
        />
      </PageFilters>

      {!!selectedFiltersCount && (
        <PageFilterChips>
          {(params.createdDateFrom || params.createdDateTo) && (
            <Chip
              label={t('filters.createdAt') + ': ' + createdAtChipText}
              onDelete={() =>
                setParam([
                  ['createdDateFrom', ''],
                  ['createdDateTo', ''],
                ])
              }
            />
          )}
          {(params.paymentDateFrom || params.paymentDateTo) && (
            <Chip
              label={t('filters.paymentDate') + ': ' + paymentDateChipText}
              onDelete={() =>
                setParam([
                  ['paymentDateFrom', ''],
                  ['paymentDateTo', ''],
                ])
              }
            />
          )}
          {params.accountGroup && (
            <Chip
              label={t(`int.accountGroupSelect.items.${params.accountGroup}`)}
              onDelete={() => setParam('accountGroup', '')}
            />
          )}
          {!!params.states.length && (
            <Tooltip title={statesChipTooltip}>
              <Chip
                label={getFilterChipText(
                  t(
                    `cashbackRedemptionStatuses.${params.states[0]}`
                  ).toUpperCase(),
                  params.states.length
                )}
                onDelete={() => setParam('states', [])}
              />
            </Tooltip>
          )}
        </PageFilterChips>
      )}
    </>
  );
};

export default Filters;
