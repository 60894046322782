import { styled } from '@mui/material';
import { ReactComponent as HollowCardTile } from 'assets/svg/hollowCardTile.svg';

export const StyledRequestCardTile = styled('div')`
  margin: 0 0 61px;
  text-align: center;
`;

const TileHolder = styled('div')`
  display: inline-flex;
  position: relative;
  vertical-align: top;
`;

const StyledHollowCardTile = styled(HollowCardTile)`
  vertical-align: top;
  stroke: ${({ theme }) => theme.palette.text.secondary};
`;

export const RequestCardTile = () => {
  return (
    <StyledRequestCardTile>
      <TileHolder>
        <StyledHollowCardTile />
      </TileHolder>
    </StyledRequestCardTile>
  );
};
