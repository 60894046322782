import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import { CardHistoryCommonEntry } from 'services/constants';
import { formatMoney } from 'services/utils';
import { Nowrap } from './style';

interface Props {
  entry: CardHistoryCommonEntry;
}

const LimitChangedEventTitle = ({ entry }: Props) => {
  const { t, i18n } = useTranslation();
  const currencyDisplay = useCurrencyDisplayMode();

  return (
    <Trans
      i18nKey={`cardHistory.titleByType.${entry.type}`}
      values={{
        prevLimitWithFrequency: t(
          `cardLimitFrequency.limitWithFrequencies.${entry.prevLimitRenewFrequency}`,
          {
            limit: formatMoney(entry.prevLimit!, i18n.language, {
              currencyDisplay,
            }),
          }
        ),
        limitWithFrequency: t(
          `cardLimitFrequency.limitWithFrequencies.${entry.limitRenewFrequency}`,
          {
            limit: formatMoney(entry.limit, i18n.language, { currencyDisplay }),
          }
        ),
      }}
      components={{ nowrap: <Nowrap />, b: <b /> }}
    />
  );
};

export default LimitChangedEventTitle;
