import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyProps } from 'elements';
import { MerchantSplitType } from 'services/constants';

interface Props extends TypographyProps {
  value: MerchantSplitType;
}

export const MerchantSplitTypeBadge = ({ value, ...rest }: Props) => {
  const { t } = useTranslation();

  if (value === MerchantSplitType.none) return null;

  return (
    <Typography variant="caption2" {...rest}>
      {t(`merchantSplitType.${value}`)}
    </Typography>
  );
};
