import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import { WarningContainer } from 'domains/transaction/components/TransactionDetails/style';
import { ActionBox, ActionBoxActions, ActionBoxTitle, Button } from 'elements';
import {
  Receipt,
  ReceiptStatus,
  Transaction,
  TransactionExportStatus,
} from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props {
  transaction: Transaction;
  onReupload: (receiptId?: string) => void;
  receipts: Receipt[];
}

const ReuploadReceiptsError = ({
  transaction,
  receipts,
  onReupload,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { accountingSettings },
  } = useGlobalState();

  const isCodatConnected = isCodatAccSystemConnected(accountingSettings);

  if (!isCodatConnected) return null;
  if (transaction.exportStatus !== TransactionExportStatus.exported)
    return null;
  if (!transaction.exportError?.receiptError?.error) return null;

  return (
    <WarningContainer>
      <ActionBox>
        <ActionBoxTitle>{t('reuploadReceiptsError.title')}</ActionBoxTitle>
        <Trans
          i18nKey="reuploadReceiptsError.description"
          values={{ name: accountingSettings!.accountingSystemName }}
        />
        <ActionBoxActions>
          <Button
            disabled={!canUser('receipt-rejected-by-acc-system:update')}
            onClick={() => {
              const rejectedReceipt = receipts.find(
                (receipt) =>
                  receipt.status === ReceiptStatus.rejectedByAccountingSystem
              );
              onReupload(rejectedReceipt?.receiptId);
            }}
          >
            {t('reuploadReceiptsError.btn')}
          </Button>
        </ActionBoxActions>
      </ActionBox>
    </WarningContainer>
  );
};

export default ReuploadReceiptsError;
