import React, { PropsWithChildren, useState } from 'react';
import { styled } from '@mui/material';
import { uniq } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  XIcon,
} from 'elements';
import {
  CardConfigSetting,
  CardControlRestriction,
  cardControlRestrictions,
  CardMerchantControl,
  CardMerchantControlType,
} from 'services/constants';
import {
  StyledBox,
  StyledRadioGroup,
  StyledTableContainer,
  SummaryFormControlLabel,
  Wrapper,
} from './style';
import useCardControlsDisplaySettings from './useCardControlsDisplaySettings';

interface Props {
  cardConfigSetting: CardConfigSetting;
  control: CardMerchantControl | null;
  onChange: (control: CardMerchantControl | null) => void;
}

const Text = styled('span')`
  white-space: nowrap;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const MAX_ITEM_LENGTH = 50;

const TextWithTooltip = ({
  type,
  children,
}: PropsWithChildren<{ type: CardMerchantControlType }>) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(`cardControlFormElement.merchant.tooltips.${type}`)}>
      <Text>{children}</Text>
    </Tooltip>
  );
};

const CardMerchantControlFormElement = ({
  cardConfigSetting,
  control,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const {
    merchants: { isVisible, availableTypes },
  } = useCardControlsDisplaySettings(cardConfigSetting);
  const [isExpanded, setIsExpanded] = useState<boolean>(
    !!control?.values.length
  );
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState<
    'CONTAINS_SPACES' | 'MAX_LENGTH_REACHED' | null
  >(null);
  const parsedValues = uniq(
    inputValue
      .split(',')
      .map((item) => item.trim())
      .filter((item) => item.length >= 3)
  );
  const defaultControl = {
    restriction: CardControlRestriction.allowed,
    type: availableTypes[0],
    values: [],
  };

  const { restriction, type, values } = control || defaultControl;

  const addItemsToList = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      type === CardMerchantControlType.mid &&
      parsedValues.some((item) => /\s/.test(item))
    ) {
      setError('CONTAINS_SPACES');
      return;
    }

    if (parsedValues.some((item) => item.length > MAX_ITEM_LENGTH)) {
      setError('MAX_LENGTH_REACHED');
      return;
    }

    onChange({
      restriction,
      type,
      values: uniq([...values, ...parsedValues]),
    });
    setError(null);
    setInputValue('');
  };

  if (!isVisible) return null;

  return (
    <Wrapper>
      <SummaryFormControlLabel
        checked={isExpanded}
        onChange={(e, checked) => {
          onChange(checked ? defaultControl : null);
          setIsExpanded(checked);
        }}
        control={<Switch size="medium" />}
        label={
          <Typography variant="subtitle1">
            {t('cardControlFormElement.merchant.title')}
          </Typography>
        }
        labelPlacement="start"
      />
      <Typography variant="body2" color="textSecondary">
        <Trans
          i18nKey="cardControlFormElement.merchant.description"
          components={{
            matchTerm: (
              <TextWithTooltip type={CardMerchantControlType.matchTerm} />
            ),
            mid: <TextWithTooltip type={CardMerchantControlType.mid} />,
          }}
        />
      </Typography>
      <Collapse in={isExpanded}>
        <StyledRadioGroup
          row
          value={restriction}
          onChange={(e) =>
            onChange({
              type,
              values,
              restriction: e.target.value as CardControlRestriction,
            })
          }
        >
          {cardControlRestrictions.map((item) => (
            <FormControlLabel
              key={item}
              value={item}
              control={<Radio />}
              label={
                <Typography variant="body1">
                  {t(`cardControlFormElement.restrictions.${item}`)}
                </Typography>
              }
            />
          ))}
        </StyledRadioGroup>
        <StyledBox>
          <Select
            value={type}
            onChange={(e) =>
              onChange({
                restriction,
                type: e.target.value as CardMerchantControlType,
                values: [],
              })
            }
            renderValue={(value) =>
              t(`cardControlFormElement.merchant.types.${value}`)
            }
            disabled={availableTypes.length === 1}
          >
            {availableTypes.map((item) => (
              <MenuItem key={item} value={item}>
                {t(`cardControlFormElement.merchant.types.${item}`)}
              </MenuItem>
            ))}
          </Select>
        </StyledBox>
        <StyledBox>
          <form onSubmit={addItemsToList} noValidate>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} mr={1}>
                <TextField
                  value={inputValue}
                  onChange={(e) =>
                    setInputValue(e.target.value.replace(/[^A-Za-z\d\s,]/g, ''))
                  }
                  error={!!error}
                  helperText={
                    error &&
                    t(`cardControlFormElement.merchant.errors.${error}`, {
                      maxLength: MAX_ITEM_LENGTH,
                    })
                  }
                  placeholder={t(
                    `cardControlFormElement.merchant.placeholders.${type}`
                  )}
                />
              </Box>
              <Box flexShrink={0}>
                <Button
                  type="submit"
                  variant="text"
                  disabled={!parsedValues.length}
                >
                  {t('cardControlFormElement.addToList')}
                </Button>
              </Box>
            </Box>
          </form>
        </StyledBox>

        {!!values.length && (
          <StyledTableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ backgroundColor: 'transparent' }}
                    colSpan={2}
                  >
                    {t(`cardControlFormElement.merchant.types.${type}`)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((item) => {
                  return (
                    <TableRow
                      key={item}
                      sx={{ '.MuiTableCell-root': { border: 0 } }}
                    >
                      <TableCell>{item}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          size="small"
                          onClick={() =>
                            onChange({
                              restriction,
                              type,
                              values: values.filter(
                                (filteredItem) => filteredItem !== item
                              ),
                            })
                          }
                        >
                          <XIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
      </Collapse>
    </Wrapper>
  );
};

export default CardMerchantControlFormElement;
