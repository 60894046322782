import { StatusBadge, StatusBadgeProps } from 'elements';
import { VssType } from 'services/constants';

const COLORS_BY_TYPES: { [k in VssType]: StatusBadgeProps['color'] } = {
  [VssType.EANSS]: 'secondary',
  [VssType.ISS]: 'info',
  [VssType.NNSS]: 'success',
};

interface Props {
  value: VssType;
  sx?: StatusBadgeProps['sx'];
}

const VssTypeBadge = ({ value, sx }: Props) => {
  return (
    <StatusBadge
      variant="tinted"
      color={COLORS_BY_TYPES[value]}
      label={value}
      sx={sx}
    />
  );
};

export default VssTypeBadge;
