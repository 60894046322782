import React from 'react';
import { PaletteMode } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useCardholderAppLink } from 'context/GlobalState';
import { Box, Button, CardholderIcon, SquaresFourIcon } from 'elements';
import useIsAdminApp from 'hooks/useIsAdminApp';
import { useCanUser } from 'services/rbac';

interface Props {
  mode?: PaletteMode;
}

const AppSwitchButton = ({ mode = 'light' }: Props) => {
  const { t } = useTranslation();
  const isAdminApp = useIsAdminApp();
  const canUser = useCanUser();
  const {
    isCardholderAppDeactivated,
    cardholderAppLink,
  } = useCardholderAppLink();

  if (!canUser('app:switch')) return null;

  const linkProps = isCardholderAppDeactivated
    ? { component: 'a', href: cardholderAppLink }
    : { component: Link, to: cardholderAppLink };

  return (
    <Box
      sx={(theme) => ({
        ml: 3,
        color: theme.variables.navigation[theme.navigationMode].text.primary,
      })}
    >
      <Button
        variant="outlined"
        color={mode === 'light' ? 'primary' : 'inherit'}
        startIcon={isAdminApp ? <CardholderIcon /> : <SquaresFourIcon />}
        data-intercom-target="app-switch-button"
        {...linkProps}
      >
        {isAdminApp
          ? t('header.cardholderAppButton')
          : t('header.adminAppButton')}
      </Button>
    </Box>
  );
};

export default AppSwitchButton;
