import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  BankAccountsTable,
  BankAccountsTableRef,
} from 'domains/billing/components';
import { FIN_API_SUPPORTED_COUNTRIES } from 'domains/billing/constants';
import {
  AddBankAccountDialog,
  ConnectBankAccountsThroughFinApiDialog,
} from 'domains/billing/dialogs';
import { Box, Button, Typography } from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { withPermission } from 'services/rbac';
import useCanUser from 'services/rbac/useCanUser';

const BankAccountsSubpage = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const partnerName = usePartnerName();
  const {
    state: { organization },
  } = useGlobalState();
  const bankAccountsTableRef = useRef<BankAccountsTableRef>(null);
  const [isAddBankAccountDialogOpen, setIsAddBankAccountDialogOpen] = useState(
    false
  );
  const [
    isConnectBankAccountDialogOpen,
    setIsConnectBankAccountDialogOpen,
  ] = useState(false);

  return (
    <>
      {canUser('bank-account:add') && (
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            size="large"
            variant="outlined"
            onClick={() => setIsAddBankAccountDialogOpen(true)}
          >
            {t('bankAccountsSubpage.addBankAccount')}
          </Button>
        </Box>
      )}

      {canUser('bank-account:connect') && (
        <Box display="flex" alignItems="flex-end" mb={2}>
          <Typography>
            <Trans
              i18nKey="bankAccountsSubpage.bankConnectionDescription"
              components={{ bold: <b /> }}
              values={{ partnerName }}
            />
          </Typography>

          <Box flexShrink={0} ml={5}>
            {FIN_API_SUPPORTED_COUNTRIES.includes(organization!.country) && (
              <Button
                size="large"
                variant="outlined"
                onClick={() => setIsConnectBankAccountDialogOpen(true)}
              >
                {t('bankAccountsSubpage.connectBusinessAccount')}
              </Button>
            )}
          </Box>
        </Box>
      )}

      <BankAccountsTable isNewDesign ref={bankAccountsTableRef} />

      <AddBankAccountDialog
        open={isAddBankAccountDialogOpen}
        onClose={() => setIsAddBankAccountDialogOpen(false)}
      />
      <ConnectBankAccountsThroughFinApiDialog
        open={isConnectBankAccountDialogOpen}
        onClose={() => setIsConnectBankAccountDialogOpen(false)}
      />
    </>
  );
};

export default withPermission(
  BankAccountsSubpage,
  'bank-accounts-subpage:visit'
);
