import { sortBy } from 'lodash';
import {
  OrganizationRepresentativeInternalStatus,
  RelatedPerson,
} from 'services/constants';

const sortOrgRepresentatives = (reps: RelatedPerson[]) =>
  sortBy(reps, [
    (item) =>
      ({
        [OrganizationRepresentativeInternalStatus.draft]: 1,
        [OrganizationRepresentativeInternalStatus.ready]: 2,
        [OrganizationRepresentativeInternalStatus.archived]: 3,
      }[item.internalStatus.value]),
    'surName',
    'givenName',
  ]);

export { sortOrgRepresentatives };
