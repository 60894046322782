import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  CardConfigGroup,
  CardNewType,
  CirculaConnectionStatus,
  CirculaConnectionType,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import { FormValues, Step } from './index';

const useAttributesStepSettings = ({
  member,
  cardConfigSetting,
  customFields,
}: FormValues) => {
  const canUser = useCanUser();
  const {
    state: { featureModules, accountingSettings, organizationIntegrations },
  } = useGlobalState();

  return useMemo(() => {
    if (!member || !cardConfigSetting) {
      return {
        isLabelInputVisible: true,
        isPurposeTextareaVisible: true,
        isDesignPickerVisible: true,
        isTeamsSelectVisible: true,
        isProjectsSelectVisible: true,
        isCirculaCheckboxVisible: true,
        areCustomNameInputsVisible: true,
        areCustomFieldsVisible: false,
      };
    }

    const isLabelInputVisible =
      cardConfigSetting.cardType === CardNewType.virtual &&
      cardConfigSetting.maxUsage !== 1;

    const isPurposeTextareaVisible = cardConfigSetting.maxUsage === 1;

    const isDesignPickerVisible = cardConfigSetting.cardDesignIds.length > 1;

    const isTeamsSelectVisible = featureModules.TEAMS && !!member.teams.length;

    const isProjectsSelectVisible =
      cardConfigSetting.maxUsage !== 1 && accountingSettings?.projectEnabled;

    const isCirculaCheckboxVisible =
      organizationIntegrations?.syncSettings?.orgConnectionStatus ===
        CirculaConnectionStatus.connected &&
      organizationIntegrations?.syncSettings?.orgConnectionType ===
        CirculaConnectionType.partial &&
      canUser('new-card:connect-to-circula');

    const areCustomNameInputsVisible =
      cardConfigSetting?.cardConfigGroup ===
        CardConfigGroup.pliantVirtualTravel &&
      featureModules.CUSTOM_CARDHOLDER_NAME;

    const areCustomFieldsVisible = customFields.length > 0;

    return {
      isLabelInputVisible,
      isPurposeTextareaVisible,
      isDesignPickerVisible,
      isTeamsSelectVisible,
      isProjectsSelectVisible,
      isCirculaCheckboxVisible,
      areCustomNameInputsVisible,
      areCustomFieldsVisible,
    };
  }, [
    member,
    cardConfigSetting,
    customFields,
    featureModules,
    accountingSettings,
    organizationIntegrations,
  ]);
};

const useStepManager = () => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();
  const formik = useFormikContext<FormValues>();
  const attributesStepSettings = useAttributesStepSettings(formik.values);
  const steps = useMemo<Step[]>(() => {
    const isAttributesStepAvailable = (Object.keys(
      attributesStepSettings
    ) as Array<keyof typeof attributesStepSettings>).some(
      (key) =>
        typeof attributesStepSettings[key] === 'boolean' &&
        attributesStepSettings[key]
    );

    return [
      'memberAndType',
      'limitsAndValidity',
      ...(featureModules.CARD_CONTROLS ? (['controls'] as Step[]) : []),
      ...(isAttributesStepAvailable ? (['attributes'] as Step[]) : []),
      'summary',
    ];
  }, [featureModules.CARD_CONTROLS, attributesStepSettings]);

  const goPrev = () => {
    const currentStepIndex = steps.indexOf(formik.values.step);
    const prevStep = steps[currentStepIndex - 1] || steps[currentStepIndex];
    formik.setFieldValue('step', prevStep);
  };

  const goNext = () => {
    const currentStepIndex = steps.indexOf(formik.values.step);
    const nextStep = steps[currentStepIndex + 1] || steps[currentStepIndex];
    formik.setFieldValue('step', nextStep);
  };

  const goTo = (step: Step) => {
    formik.setFieldValue('step', step);
  };

  const getStepLabel = (step: Step) =>
    t(`issueCardDialog_v2.stepper.titlesByStep.${step}`);

  return {
    currentStep: formik.values.step,
    steps,
    attributesStepSettings,
    goPrev,
    goNext,
    goTo,
    getStepLabel,
  };
};

export default useStepManager;
