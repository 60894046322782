import {
  MemberDetails,
  MinimalTeam,
  Money,
  Transaction,
} from 'services/constants';
import { Role } from './constants';

export const getMaxAllowedCardLimit = (
  roles: string[],
  orgAdminLimit: Money | undefined,
  managedTeamLimit: Money | undefined
): Money | undefined => {
  if (roles.includes(Role.accountOwner)) return undefined;
  if (roles.includes(Role.orgAdmin)) return orgAdminLimit;
  if (roles.includes(Role.teamManager)) return managedTeamLimit;
};

export const getMaxAllowedCardLimitErrorMessageKey = (roles: string[]) =>
  roles.includes(Role.orgAdmin)
    ? 'errors.cardIssuanceLimitAdmin'
    : 'errors.cardIssuanceLimitManager';

export const filterMinimalTeams = (
  minimalTeams: MinimalTeam[],
  transaction: Transaction | null,
  member: MemberDetails
) => {
  const isMyTransaction = transaction?.memberId === member.id;
  const isManagerOfTransactionTeam =
    !!transaction?.teamId &&
    minimalTeams?.some(
      (team) => team.id === transaction?.teamId && team.isManager
    );

  if (
    member.roles?.some((role) =>
      [Role.accountant, Role.accountOwner, Role.orgAdmin].includes(role as Role)
    )
  ) {
    return minimalTeams;
  }

  if (member.roles?.includes(Role.teamManager)) {
    if (isMyTransaction) {
      return minimalTeams;
    } else if (isManagerOfTransactionTeam) {
      return minimalTeams.filter((team: MinimalTeam) => team.isManager);
    } else {
      return [];
    }
  }

  return minimalTeams;
};
