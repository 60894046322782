import { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  HandsClappingIcon,
  IconButton,
  ListIcon,
  LoaderWithOverlay,
  SmileyIcon,
  SquaresFourIcon,
  useGridApiRef,
  withDialogWrapper,
  XIcon,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  ReceiptAutoMatching,
  ReceiptInboxLayout,
  ReceiptsAutoMatchingTagGroup,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import EmptyTrashButton from './EmptyTrashButton';
import Filters, { ReceiptInboxFilters } from './Filters';
import FilterChips from './Filters/FilterChips';
import ReceiptInboxDetails from './ReceiptInboxDetails';
import ReceiptInboxGrid from './ReceiptInboxGrid';
import ReceiptInboxHeader from './ReceiptInboxHeader';
import ReceiptInboxTable from './ReceiptInboxTable';
import { getTabGroupFromStatus } from './utils';

// this is the limit on BE for fetching thumbnails
export const PAGE_LIMIT = 25;

const initialTabState = {
  isLoading: true,
  isThumbnailLoading: true,
  receipts: [],
  thumbnails: {},
  hasNextPage: false,
  totalCount: 0,
  search: '',
  filters: {
    status: [],
    fromDate: null,
    toDate: null,
  },
  selectedReceiptId: '',
  isReceiptPreviewOpen: false,
};

interface Props extends DialogProps {
  onClose: () => void;
}

export type TabGroup = Omit<
  ReceiptsAutoMatchingTagGroup,
  ReceiptsAutoMatchingTagGroup.none
>;

interface State {
  isLoading: boolean;
  isThumbnailLoading: boolean;
  receipts: ReceiptAutoMatching[];
  thumbnails: { [key: string]: string | null };
  hasNextPage: boolean;
  totalCount: number;
  search: string;
  filters: ReceiptInboxFilters;
  selectedReceiptId: string;
  isReceiptPreviewOpen: boolean;
}

const getRequestParams = (search: string, filters: ReceiptInboxFilters) => {
  const { status, fromDate, toDate } = filters;
  return {
    q: search.length ? search : undefined,
    status: status.length ? status.join() : undefined,
    fromDate: fromDate?.startOf('day')?.format(),
    toDate: toDate?.endOf('day')?.format(),
  };
};

const ReceiptInboxDialog = (props: Props) => {
  const pageRef = useRef(0);
  const tabGroupRef = useRef<TabGroup>(ReceiptsAutoMatchingTagGroup.unmatched);
  const dataGridRef = useGridApiRef();
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const { isCardholderApp } = useCurrentApp();
  const {
    dispatch,
    state: { organization, member, unmatchedReceiptsCounts, receiptInbox },
  } = useGlobalState();
  const [state, setState] = useState<State>(initialTabState);

  const selectedFiltersCount =
    0 +
    (state.filters.status.length ? 1 : 0) +
    (state.filters.fromDate ? 1 : 0);
  const areFiltersApplied = !!state.search.length || !!selectedFiltersCount;
  const isEmptyState =
    !state.isLoading && !state.receipts.length && !areFiltersApplied;

  const getThumbnails = async (ids: string[], loadMore: boolean) => {
    const memoTabGroup = tabGroupRef.current;
    try {
      const { thumbnails } = await api.getReceiptsAutoMatchingThumbnails(
        ids.join()
      );

      const mappedThumbnails = thumbnails.reduce(
        (result, item) => ({ ...result, [item.taskId]: item.thumbnail }),
        {}
      );

      if (!mounted.current || memoTabGroup !== tabGroupRef.current) return;
      setState((prevState) => ({
        ...prevState,
        thumbnails: loadMore
          ? { ...prevState.thumbnails, ...mappedThumbnails }
          : mappedThumbnails,
        isThumbnailLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || memoTabGroup !== tabGroupRef.current) return;
      logError(error);
      setState((prevState) => ({
        ...prevState,
        isThumbnailLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
    }
  };

  const getData = async (page: number) => {
    const memoTabGroup = tabGroupRef.current;
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        isThumbnailLoading: true,
      }));
      const params = getRequestParams(state.search, state.filters);
      const {
        data: receipts,
        hasNextPage,
        totalCount,
      } = await api.getReceiptsAutoMatching({
        tabGroup: params.status
          ? undefined
          : (tabGroupRef.current as ReceiptsAutoMatchingTagGroup),
        ...params,
        memberId: isCardholderApp ? member.id : undefined,
        organizationId: organization!.id,
        page,
        limit: PAGE_LIMIT,
      });

      // update global count to be in sync with dialog header
      if (
        memoTabGroup === ReceiptsAutoMatchingTagGroup.unmatched &&
        !areFiltersApplied
      ) {
        dispatch({
          type: 'SET_UNMATCHED_RECEIPTS_COUNT',
          payload: isCardholderApp
            ? { ...unmatchedReceiptsCounts, unmatchedTaskCountSelf: totalCount }
            : { ...unmatchedReceiptsCounts, unmatchedTaskCount: totalCount },
        });
      }

      if (!mounted.current || memoTabGroup !== tabGroupRef.current) return;
      setState((prevState) => ({
        ...prevState,
        receipts: page > 0 ? [...prevState.receipts, ...receipts] : receipts,
        hasNextPage,
        totalCount,
        isLoading: false,
        isThumbnailLoading: !!receipts.length,
      }));

      if (receipts.length)
        getThumbnails(
          receipts.map((receipt) => receipt.id),
          !!page
        );
    } catch (error) {
      if (!mounted.current || memoTabGroup !== tabGroupRef.current) return;
      logError(error);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isThumbnailLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (!isEmpty(dataGridRef.current) && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });
    pageRef.current = 0;

    getData(pageRef.current);
  }, [state.filters, state.search, tabGroupRef.current]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current);
  };

  return (
    <Dialog
      maxWidth="xl"
      PaperProps={{
        sx: (theme) => ({
          height: `calc(100% - ${theme.spacing(8)})`,
        }),
      }}
      {...props}
    >
      {!state.selectedReceiptId && (
        <IconButton
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: (theme) => theme.spacing(3),
            right: (theme) => theme.spacing(3),
          }}
        >
          <XIcon />
        </IconButton>
      )}

      <Box
        display="flex"
        height="100%"
        minWidth={1200}
        position="relative"
        sx={{ overflow: 'hidden', overflowX: 'auto' }}
      >
        <Box flex={1} display="flex" flexDirection="column" minWidth={0}>
          <DialogTitle>{t('receiptInbox.title')}</DialogTitle>

          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pb: 0,
              pr: state.selectedReceiptId ? 1 : 3,
            }}
          >
            <ReceiptInboxHeader
              tabGroup={tabGroupRef.current}
              onTabGroupChange={(tabGroup) => {
                tabGroupRef.current = tabGroup;
                setState((prevState) => ({
                  ...prevState,
                  ...initialTabState,
                }));
              }}
            />

            <Box py={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Box display="flex" alignItems="center">
                <Filters
                  tabGroup={tabGroupRef.current}
                  search={state.search}
                  filters={state.filters}
                  selectedFiltersCount={selectedFiltersCount}
                  resultsCount={state.totalCount}
                  disabled={isEmptyState}
                  onSearchChange={(search) =>
                    setState((prevState) => ({ ...prevState, search }))
                  }
                  onUpdate={(filters) =>
                    setState((prevState) => ({ ...prevState, filters }))
                  }
                />

                <Box display="flex" ml="auto">
                  <IconButton
                    size="small"
                    data-test-id="grid-view-button"
                    disabled={isEmptyState}
                    onClick={() =>
                      dispatch({
                        type: 'SET_RECEIPT_INBOX_DATA',
                        payload: {
                          ...receiptInbox,
                          layout: ReceiptInboxLayout.GRID,
                        },
                      })
                    }
                  >
                    <SquaresFourIcon
                      color={
                        receiptInbox.layout === ReceiptInboxLayout.GRID
                          ? 'primary'
                          : 'inherit'
                      }
                    />
                  </IconButton>

                  <IconButton
                    size="small"
                    data-test-id="list-view-button"
                    sx={{ ml: 1 }}
                    disabled={isEmptyState}
                    onClick={() =>
                      dispatch({
                        type: 'SET_RECEIPT_INBOX_DATA',
                        payload: {
                          ...receiptInbox,
                          layout: ReceiptInboxLayout.LIST,
                        },
                      })
                    }
                  >
                    <ListIcon
                      color={
                        receiptInbox.layout === ReceiptInboxLayout.LIST
                          ? 'primary'
                          : 'inherit'
                      }
                    />
                  </IconButton>

                  {tabGroupRef.current ===
                    ReceiptsAutoMatchingTagGroup.trash && (
                    <EmptyTrashButton
                      disabled={isEmptyState || state.isLoading}
                      onSuccess={() => {
                        if (!isEmpty(dataGridRef.current) && !state.isLoading)
                          dataGridRef.current.scroll({ left: 0, top: 0 });
                        pageRef.current = 0;
                        getData(pageRef.current);
                      }}
                    />
                  )}
                </Box>
              </Box>

              <FilterChips
                filters={state.filters}
                selectedFiltersCount={selectedFiltersCount}
                onChange={(filters) => {
                  setState((prevState) => ({
                    ...prevState,
                    filters: { ...prevState.filters, ...filters },
                  }));
                }}
              />
            </Box>

            <Box overflow="hidden" flex={1} position="relative">
              {receiptInbox.layout === ReceiptInboxLayout.GRID ? (
                <ReceiptInboxGrid
                  receipts={state.receipts}
                  isLoading={state.isLoading}
                  thumbnails={state.thumbnails}
                  isThumbnailLoading={state.isThumbnailLoading}
                  hasNextPage={state.hasNextPage}
                  loadMoreItems={loadMoreItems}
                  selectedReceiptId={state.selectedReceiptId}
                  setSelectedReceipt={(receiptId, isPreviewOpen) =>
                    setState((prevState) => ({
                      ...prevState,
                      selectedReceiptId: receiptId,
                      isReceiptPreviewOpen: !!isPreviewOpen,
                    }))
                  }
                />
              ) : (
                <ReceiptInboxTable
                  dataGridRef={dataGridRef}
                  isLoading={state.isLoading}
                  receipts={state.receipts}
                  thumbnails={state.thumbnails}
                  isThumbnailLoading={state.isThumbnailLoading}
                  hasNextPage={state.hasNextPage}
                  totalCount={state.totalCount}
                  selectedReceiptId={state.selectedReceiptId}
                  loadMoreItems={loadMoreItems}
                  setSelectedReceipt={(receiptId) =>
                    setState((prevState) => ({
                      ...prevState,
                      selectedReceiptId:
                        prevState.selectedReceiptId === receiptId
                          ? ''
                          : receiptId,
                    }))
                  }
                />
              )}
              {!state.isLoading &&
                !state.receipts.length &&
                areFiltersApplied && <NothingFound />}
              {isEmptyState && (
                <NoData
                  isNewDesign
                  Icon={
                    tabGroupRef.current ===
                    ReceiptsAutoMatchingTagGroup.unmatched
                      ? HandsClappingIcon
                      : SmileyIcon
                  }
                  label={t(`receiptInbox.notFound.${tabGroupRef.current}`)}
                  sx={{ position: 'absolute', top: 0, left: 0 }}
                />
              )}
            </Box>

            <LoaderWithOverlay loading={state.isLoading} />
          </DialogContent>
        </Box>

        {state.selectedReceiptId && (
          <ReceiptInboxDetails
            receiptId={state.selectedReceiptId}
            isReceiptPreviewOpen={state.isReceiptPreviewOpen}
            onRefetch={(unselectReceiptId) => {
              if (unselectReceiptId) {
                setState((prevState) => ({
                  ...prevState,
                  selectedReceiptId:
                    unselectReceiptId === prevState.selectedReceiptId
                      ? ''
                      : prevState.selectedReceiptId,
                }));
              }
              if (!isEmpty(dataGridRef.current) && !state.isLoading)
                dataGridRef.current.scroll({ left: 0, top: 0 });
              pageRef.current = 0;
              getData(pageRef.current);
            }}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                selectedReceiptId: '',
                isReceiptPreviewOpen: false,
              }))
            }
            onReceiptPreviewToggle={(open) =>
              setState((prevState) => ({
                ...prevState,
                isReceiptPreviewOpen: open,
              }))
            }
            onOtherReceiptSelect={(receipt) => {
              const selectedReceiptTab = getTabGroupFromStatus(
                receipt.taskStatus
              );
              // if the receipt is from other tab, switch to that tab
              if (
                selectedReceiptTab &&
                selectedReceiptTab !== tabGroupRef.current
              ) {
                tabGroupRef.current = selectedReceiptTab;
                setState((prevState) => ({
                  ...prevState,
                  ...initialTabState,
                  selectedReceiptId: receipt.taskId,
                }));
                return;
              }

              setState((prevState) => ({
                ...prevState,
                selectedReceiptId: receipt.taskId,
              }));
            }}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default withDialogWrapper(ReceiptInboxDialog);
