import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import WidgetError from 'components/WidgetError';
import { AccountEntryAvatar } from 'domains/transaction/components';
import { Box, LoaderWithOverlay, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import { AccountEntry } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import AccountEntryDetailsPageContent from './AccountEntryDetailsPageContent';
import AccountEntryPaymentTypeBadge from './AccountEntryPaymentTypeBadge';
import BillPaymentInfo from './BillPaymentInfo';
import ReportPreviewDialog from './ReportPreviewDialog';

interface State {
  isLoading: boolean;
  accountEntry: AccountEntry | null;
  error: unknown;
  isReportPreviewDialogOpen: boolean;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (accountEntry: AccountEntry) => void;
}

const AccountEntryDetailsPage = ({ onUpdate, ...props }: Props) => {
  const api = useImperativeApi();
  const { entryId } = useParams<{ entryId: string }>();
  const mounted = useMounted();
  const idRef = useRef(entryId);
  const [state, setState] = useState<State>({
    isLoading: false,
    accountEntry: null,
    error: null,
    isReportPreviewDialogOpen: false,
  });

  useEffect(() => {
    if (state.accountEntry) onUpdate(state.accountEntry);
  }, [state.accountEntry]);

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const accountEntry = await api.getAccountEntry(entryId);
      if (!mounted.current || entryId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        accountEntry,
        error: null,
      }));
    } catch (error) {
      if (!mounted.current || entryId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        accountEntry: null,
        error,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!entryId) return;
    idRef.current = entryId;
    getData();
  }, [entryId]);

  const renderAccountEntryContent = (
    accountEntry: AccountEntry,
    isPreviewDialogOpen: boolean
  ) => {
    return (
      <>
        <DetailsDrawerHeader pb={2.5}>
          <Box display="flex" alignItems="center">
            <AccountEntryAvatar
              size="large"
              type={accountEntry.type}
              billPaymentType={accountEntry.billPaymentInfo?.billPaymentType}
            />

            <Box pl={2} ml="auto" textAlign="right" overflow="hidden">
              <Typography
                variant="h4"
                color={
                  accountEntry.amount.value > 0 ? 'success.main' : 'error.main'
                }
              >
                <FormatMoney
                  value={accountEntry.amount}
                  fractionalPart
                  positiveSign
                />
              </Typography>

              {accountEntry.billPaymentInfo?.billPaymentType && (
                <AccountEntryPaymentTypeBadge
                  billPaymentType={accountEntry.billPaymentInfo.billPaymentType}
                  paymentType={accountEntry.billPaymentInfo.paymentType}
                />
              )}
            </Box>
          </Box>

          <BillPaymentInfo accountEntry={accountEntry} />
        </DetailsDrawerHeader>

        <DetailsDrawerContent pb={1}>
          <Box p={3}>
            <AccountEntryDetailsPageContent
              accountEntry={accountEntry}
              onPreviewDialogOpen={
                isPreviewDialogOpen
                  ? undefined
                  : () =>
                      setState((prevState) => ({
                        ...prevState,
                        isReportPreviewDialogOpen: true,
                      }))
              }
            />
          </Box>
        </DetailsDrawerContent>
      </>
    );
  };

  const renderContent = (accountEntry: AccountEntry) => {
    if (!accountEntry.billPaymentInfo?.reportId)
      return renderAccountEntryContent(accountEntry, false);

    return (
      <>
        {renderAccountEntryContent(accountEntry, false)}
        <ReportPreviewDialog
          open={state.isReportPreviewDialogOpen}
          reportId={accountEntry.billPaymentInfo.reportId}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              isReportPreviewDialogOpen: false,
            }))
          }
        >
          {renderAccountEntryContent(accountEntry, true)}
        </ReportPreviewDialog>
      </>
    );
  };

  return (
    <DetailsDrawer {...props}>
      {state.error && <WidgetError onReload={getData} />}
      {state.accountEntry && renderContent(state.accountEntry)}
      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(AccountEntryDetailsPage);
