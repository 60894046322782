import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoMatchedSelectIndicator from 'domains/transaction/pages/TransactionDetailsPage/AutoMatchedSelectIndicator';
import {
  Box,
  Button,
  IconButton,
  Paper,
  PencilSimpleIcon,
  TextField,
  Typography,
} from 'elements';
import {
  CUSTOM_FIELD,
  UpdateAccTxnCustomFieldPayload,
} from 'services/constants';
import { CustomFieldExtended } from './index';

interface Props {
  field: CustomFieldExtended;
  disabled: boolean;
  organizationId: string;
  cardId: string;
  onUpdate: (
    data: UpdateAccTxnCustomFieldPayload,
    createNewField: boolean
  ) => void;
}

const CustomFieldText = ({
  field,
  disabled,
  organizationId,
  cardId,
  onUpdate,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(field.defaultValue);

  useEffect(() => {
    setInputValue(field.defaultValue);
  }, [field.defaultValue]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
    }
  }, [isEditMode]);

  if (isEditMode)
    return (
      <Box mt={1} role="custom-field-box">
        <TextField
          size="medium"
          multiline
          label={field.label}
          inputRef={inputRef}
          inputProps={{
            maxLength: CUSTOM_FIELD.valueMaxLength,
          }}
          maxRows={2}
          value={inputValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setInputValue(e.target.value)
          }
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mt={1}
        >
          <Button
            onClick={() => {
              setInputValue(field.defaultValue);
              setIsEditMode(false);
            }}
            variant="text"
          >
            {t('common.button.cancel')}
          </Button>
          <Button
            onClick={() => {
              onUpdate(
                {
                  transactionCustomFieldId: field.id,
                  value: inputValue,
                  customFieldOptionId: null,
                },
                field.createNewField
              );
              setIsEditMode(false);
            }}
            sx={{ ml: 1 }}
          >
            {t('common.button.save')}
          </Button>
        </Box>
      </Box>
    );

  return (
    <Paper
      role="custom-field-box"
      sx={{
        px: 1.5,
        py: 2,
      }}
      variant="outlined"
    >
      <Box display="flex" alignItems="center">
        <Box>
          <Typography variant="body2">{field.label}</Typography>
          <Typography
            variant="caption"
            sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
            color={!field.defaultValue ? 'textSecondary' : undefined}
          >
            {field.defaultValue || '—'}
          </Typography>
        </Box>

        <AutoMatchedSelectIndicator
          isAutoMatched={field.automatched}
          organizationId={organizationId}
          cardId={cardId}
        />

        {!disabled && (
          <Box ml="auto" pl={1}>
            <IconButton
              data-test-id="edit-custom-field"
              onClick={() => setIsEditMode(true)}
              size="small"
            >
              <PencilSimpleIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default CustomFieldText;
