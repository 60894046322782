import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { GenericError } from '@auth0/auth0-spa-js';
import throttle from 'lodash/throttle';
import xor from 'lodash/xor';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { logError } from 'services/monitoring';
import { getJwtPayload, parseJwt, Role } from 'services/rbac';

const AUTH0_SESSION_TTL = 1000 * 60 * 30; // 30 minutes
const UPDATE_SESSION_EVERY = 1000 * 60 * 2; // 2 minutes
const CHECK_FOR_SESSION_EXPIRATION_EVERY = 1000 * 10; // 10 seconds

const updateSessionExpirationTime = () => {
  localStorage.setItem(
    'sessionExpirationTime',
    (Date.now() + AUTH0_SESSION_TTL).toString()
  );
};

const shouldLogOutUser = (currentRoles: string[], newRoles: string[]) => {
  const diff = xor(currentRoles, newRoles);
  const didRolesChange = diff.length > 0;
  const wasOnlyLegalRepRoleAdded =
    diff.length === 1 &&
    diff.includes(Role.legalRep) &&
    !currentRoles.includes(Role.legalRep);
  const wasOnlyTeamManagerRoleChanged =
    diff.length === 1 && diff.includes(Role.teamManager);

  return (
    didRolesChange &&
    !wasOnlyLegalRepRoleAdded &&
    !wasOnlyTeamManagerRoleChanged
  );
};

const useSessionManager = () => {
  const { i18n } = useTranslation();
  const {
    dispatch,
    state: {
      organization,
      jwtPayload: { roles: currentRoles },
    },
  } = useGlobalState();
  const { getAccessTokenSilently, logout } = useAuth0();

  const logoutAndShowExplanation = () =>
    logout({
      returnTo: `${window.location.origin}?inactivityLogout=true&language=${i18n.language}`,
    });

  useEffect(() => {
    // overwrite old value
    updateSessionExpirationTime();
    setInterval(() => {
      const sessionExpirationTime = localStorage.getItem(
        'sessionExpirationTime'
      );
      if (
        sessionExpirationTime &&
        parseInt(sessionExpirationTime, 10) < Date.now()
      ) {
        logoutAndShowExplanation();
      }
    }, CHECK_FOR_SESSION_EXPIRATION_EVERY);
  }, []);

  useEffect(() => {
    const updateSession = throttle(
      async () => {
        try {
          // update session on auth0 authorization server
          const accessToken = await getAccessTokenSilently({
            ignoreCache: true,
          });
          const jwtPayload = getJwtPayload(accessToken);
          const orgId = parseJwt(accessToken)[
            'https://infinnity.com/organizationId'
          ];

          if (organization?.id && organization.id !== orgId) {
            return window.location.reload();
          }
          if (shouldLogOutUser(currentRoles, jwtPayload.roles)) {
            return logout({ returnTo: window.location.origin });
          }
          dispatch({ type: 'SET_JWT_PAYLOAD', payload: { jwtPayload } });
          updateSessionExpirationTime();
        } catch (error) {
          if (
            error instanceof GenericError &&
            error.error === 'login_required'
          ) {
            // handle edge cases when the interval didn't call the logout function for some reason
            logoutAndShowExplanation();
            return;
          }
          logError(error);
        }
      },
      UPDATE_SESSION_EVERY,
      { trailing: false }
    );

    window.addEventListener('mousemove', updateSession);
    window.addEventListener('mousedown', updateSession);
    window.addEventListener('keydown', updateSession);

    return () => {
      window.removeEventListener('mousemove', updateSession);
      window.removeEventListener('mousedown', updateSession);
      window.removeEventListener('keydown', updateSession);
    };
  }, [getAccessTokenSilently]);
};

export default useSessionManager;
