import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, MenuItem, Select } from 'elements';
import { AccountingSystem, ExportFormat } from 'services/constants';

interface Props {
  format: ExportFormat;
  onSave: (format: ExportFormat) => void;
}

const ProcounterExportFormats = ({ format, onSave }: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth error={format === ExportFormat.api}>
      <InputLabel>{t('exportTransactionsDialog.exportFormat')}</InputLabel>
      <Select
        name="format"
        value={format}
        onChange={(e) => onSave(e.target.value as ExportFormat)}
      >
        <MenuItem value={ExportFormat.csvMerged}>
          {t('exportTransactionsDialog.accSystemMergedCsvLabel', {
            name: AccountingSystem.procountor,
          })}
        </MenuItem>
        <MenuItem value={ExportFormat.csv}>
          {AccountingSystem.procountor} - CSV
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default ProcounterExportFormats;
