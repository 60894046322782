import { useCallback, useState } from 'react';
import {
  FileError,
  FileRejection,
  FileWithPath,
  useDropzone,
} from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { LoaderWithOverlay } from 'components/Loader';
import { useGlobalState } from 'context/GlobalState';
import OnboardingDocumentTitle from 'domains/creditAndCompliance/components/OnboardingDocumentRow/OnboardingDocumentTitle';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormHelperText,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  NetworkErrorCode,
  OnboardingDocument,
  OnboardingDocumentFile,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import { Row } from './Row';

interface Props extends DialogProps {
  document: OnboardingDocument;
  onClose: () => void;
  onUpdate: () => Promise<void>;
}

interface State {
  acceptedFiles: FileWithPath[];
  fileRejections: FileRejection[];
  isLoading: boolean;
  uploadedFiles: OnboardingDocumentFile[];
  filePathUploadingProgressMap: { [key: string]: number };
}

const UploadDocumentFilesDialog = ({ document, onUpdate, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const { enqueueSnackbar } = useSnackbar();

  const [
    {
      acceptedFiles,
      fileRejections,
      isLoading,
      uploadedFiles,
      filePathUploadingProgressMap,
    },
    setState,
  ] = useState<State>({
    acceptedFiles: [],
    fileRejections: [],
    isLoading: false,
    uploadedFiles: document.files,
    filePathUploadingProgressMap: {},
  });

  const onFileSelect = useCallback(
    (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) =>
      setState((prevState) => {
        const alreadyAddedFilePaths = prevState.acceptedFiles.map(
          (file) => file.path
        );
        const newAcceptedFiles = acceptedFiles.filter(
          (file) => !alreadyAddedFilePaths.includes(file.path)
        );
        return {
          ...prevState,
          acceptedFiles: [...prevState.acceptedFiles, ...newAcceptedFiles],
          fileRejections,
        };
      }),
    []
  );

  const { getInputProps, open } = useDropzone({
    accept: document.type.allowedMediaTypes,
    maxSize: document.type.maxFileSizeInBytes,
    onDrop: onFileSelect,
  });

  const onSelectedFileRemove = (fileIndex: number) =>
    setState((prevState) => ({
      ...prevState,
      acceptedFiles: [
        ...prevState.acceptedFiles.slice(0, fileIndex),
        ...prevState.acceptedFiles.slice(fileIndex + 1),
      ],
    }));

  const onUpload = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let hasFileRejectionsDuringUpload = false;
    for (const currentFile of acceptedFiles) {
      try {
        const response = await api.uploadOnboardingDocumentFile(
          organization!.id,
          document.id,
          currentFile as File,
          (progress) => {
            if (!mounted.current) return;
            setState((prevState) => ({
              ...prevState,
              filePathUploadingProgressMap: {
                ...prevState.filePathUploadingProgressMap,
                [currentFile.path!]: progress,
              },
            }));
          }
        );
        if (!mounted.current) return;
        setState((prevState) => ({
          ...prevState,
          acceptedFiles: prevState.acceptedFiles.filter(
            (file) => file.path !== currentFile.path
          ),
          uploadedFiles: [...prevState.uploadedFiles, response],
        }));
      } catch (error) {
        const fileRejection: FileRejection = {
          file: currentFile as File,
          errors: [],
        };

        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.onboardingDocumentFileTooBigError
        ) {
          fileRejection.errors.push({
            message: '',
            code: 'file-too-large',
          });
        }
        if (
          getNetworkErrorCode(error) ===
          NetworkErrorCode.onboardingDocumentFileTypeNotAllowedError
        ) {
          fileRejection.errors.push({
            message: '',
            code: 'file-invalid-type',
          });
        }
        if (!mounted.current) return;
        if (fileRejection.errors.length > 0) {
          setState((prevState) => ({
            ...prevState,
            acceptedFiles: prevState.acceptedFiles.filter(
              (file) => file.path !== currentFile.path
            ),
            fileRejections: [...prevState.fileRejections, fileRejection],
          }));
          hasFileRejectionsDuringUpload = true;
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    }

    await onUpdate();

    if (!mounted.current) return;
    if (hasFileRejectionsDuringUpload) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    } else {
      props.onClose();
    }
  };

  const onUploadedFileDelete = async (fileId: string) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      await api.deleteOnboardingDocumentFile(
        organization!.id,
        document.id,
        fileId
      );

      await onUpdate();

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        uploadedFiles: prevState.uploadedFiles.filter(
          (file) => file.id !== fileId
        ),
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const isUploadDisabled =
    document.type.maxNumberOfFiles <
      acceptedFiles.length + uploadedFiles.length ||
    document.type.minNumberOfFiles >
      acceptedFiles.length + uploadedFiles.length ||
    acceptedFiles.length === 0;

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>
        {t('onboardingDocumentRow.uploadFilesDialog.title')}
      </DialogTitle>
      <DialogContent>
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          minHeight="120px"
        >
          <Typography variant="body2" marginBottom={1}>
            {t('onboardingDocumentRow.uploadFilesDialog.description')}
          </Typography>
          <OnboardingDocumentTitle
            attributes={document.attributes}
            customLabel={document.customLabel}
            customLabelLocal={document.customLabelLocal}
            name={document.type.name}
          />

          <Box display="flex" flexDirection="column" my={2} width="100%">
            {uploadedFiles.map((file: OnboardingDocumentFile) => (
              <Row
                key={file.id}
                file={file}
                isLoading={isLoading}
                onDelete={() => onUploadedFileDelete(file.id)}
                version="uploaded"
              />
            ))}

            {acceptedFiles.map((file: FileWithPath, index) => (
              <Row
                key={file.path}
                file={file}
                isLoading={isLoading}
                onDelete={() => onSelectedFileRemove(index)}
                version="selected"
                uploadingProgress={filePathUploadingProgressMap[file.path!]}
              />
            ))}

            {fileRejections.map(
              ({
                file,
                errors,
              }: {
                file: FileWithPath;
                errors: FileError[];
              }) => (
                <Row
                  key={file.path}
                  errors={errors}
                  file={file}
                  isLoading={isLoading}
                  maxFileSizeInBytes={document.type.maxFileSizeInBytes}
                  version="error"
                />
              )
            )}
          </Box>

          <input {...getInputProps()} />
          <Button onClick={open}>
            {t('onboardingDocumentRow.uploadFilesDialog.selectFiles')}
          </Button>

          {document.type.maxNumberOfFiles <
            acceptedFiles.length + uploadedFiles.length && (
            <Box display="flex" justifyContent="center" width="100%" mt="14px">
              <FormHelperText error>
                {t(
                  'onboardingDocumentRow.uploadFilesDialog.maxAllowedFilesError',
                  {
                    value: document.type.maxNumberOfFiles,
                  }
                )}
              </FormHelperText>
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.close')}
        </Button>
        <Button disabled={isUploadDisabled} onClick={onUpload}>
          {t('onboardingDocumentRow.uploadFilesDialog.uploadButton')}
        </Button>
      </DialogActions>

      {isLoading && <LoaderWithOverlay size={32} thickness={3} />}
    </Dialog>
  );
};

export default withDialogWrapper<Props>(UploadDocumentFilesDialog);
