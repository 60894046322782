import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { FormControlLabel, Switch } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { FeatureModuleByKeyMap, FeatureModuleKey } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const ReceiptManagementSettings = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, featureModulesWithData },
  } = useGlobalState();
  const module = featureModulesWithData.RECEIPT_MANAGEMENT;
  const [state, setState] = useState<{
    isLoading: boolean;
    settings: FeatureModuleByKeyMap[FeatureModuleKey.receiptManagement]['settings'];
  }>({
    isLoading: false,
    settings: module.settings,
  });

  const updateSettings = async (
    settings: FeatureModuleByKeyMap[FeatureModuleKey.receiptManagement]['settings']
  ) => {
    if (state.isLoading) return;
    try {
      setState({ isLoading: true, settings });
      const updatedModule = await api.updateFeatureModuleSettings<
        FeatureModuleKey.receiptManagement
      >(organization!.id, {
        featureName: FeatureModuleKey.receiptManagement,
        settings,
      });
      dispatch({
        type: 'SET_FEATURE_MODULES',
        payload: {
          featureModules: {},
          featureModulesWithData: {
            [updatedModule.name]: updatedModule,
          },
        },
      });
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState({ isLoading: false, settings: module.settings });
      logError(error);
    }
  };

  return (
    <FormControlLabel
      sx={{ mt: 3 }}
      control={<Switch />}
      label={t('manageFeatureModuleDialog.mandatoryForExportLabel')}
      disabled={!module.enabled}
      checked={state.settings.RECEIPT_FOR_EXPORT_REQUIRED}
      onChange={(_, checked) =>
        updateSettings({ RECEIPT_FOR_EXPORT_REQUIRED: checked })
      }
    />
  );
};

export default ReceiptManagementSettings;
