import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as DownloadButton2Icon } from 'assets/icons/interface/downloadButton2.svg';
import bulgariaImage from 'assets/images/bulgaria.jpg';
import ghanaImage from 'assets/images/ghana.jpg';
import indiaImage from 'assets/images/india.jpg';
import { getDocumentUrl } from 'domains/document/utils';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
`;

const Header = styled.h3`
  font-size: 34px;
  line-height: 34px;
  margin: 0 0 40px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
  margin-bottom: 34px;
  max-width: 758px;
  text-align: center;
`;

const TileContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 34px;
  }
`;

const CardTile = styled.div<{ logo: string }>`
  background: url('${({ logo }) => logo}') no-repeat;
  background-size: 270px 173px;
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
  border-radius: 7px;
  display: flex;
  width: 270px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 173px;
  padding: 22px;
`;

const Country = styled.div`
  color: ${({ theme }) => theme.colors.grey100};
  font-size: 12px;
  line-height: 27px;
  letter-spacing: -0.18px;
  text-transform: uppercase;
`;

const CardTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.27px;
  margin-bottom: 13px;
  min-height: 44px;
`;

const CardDescription = styled.div`
  flex-grow: 1;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.18px;
`;

const ReadMoreLink = styled.a`
  align-self: flex-end;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.grey10};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.18px;
  margin-top: 15px;
  outline: none;

  svg {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
`;

const CompensationPortfolio = () => {
  const { t } = useTranslation();

  const items = [
    {
      imageUrl: ghanaImage,
      readMoreUrl: getDocumentUrl('ESG_SAMPLE_PROJECT_GHANA_2021-08-12.pdf'),
    },
    {
      imageUrl: indiaImage,
      readMoreUrl: getDocumentUrl('ESG_SAMPLE_PROJECT_INDIA.pdf'),
    },
    {
      imageUrl: bulgariaImage,
      readMoreUrl: getDocumentUrl('ESG_SAMPLE_PROJECT_BULGARIA.pdf'),
    },
  ];

  return (
    <Container>
      <Header>{t('pliantEarthPage.compensationPortfolio.title')}</Header>

      <Description>
        {t('pliantEarthPage.compensationPortfolio.description')}
      </Description>

      <TileContainer>
        {items.map(({ imageUrl, readMoreUrl }, index) => (
          <CardTile key={index} logo={imageUrl}>
            <Content>
              <Country>
                {t(
                  `pliantEarthPage.compensationPortfolio.items.${index}.country`
                )}
              </Country>

              <CardTitle>
                {t(
                  `pliantEarthPage.compensationPortfolio.items.${index}.title`
                )}
              </CardTitle>

              <CardDescription>
                {t(
                  `pliantEarthPage.compensationPortfolio.items.${index}.description`
                )}
              </CardDescription>

              <ReadMoreLink
                href={readMoreUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadButton2Icon />
                <span>
                  {t('pliantEarthPage.compensationPortfolio.readMore')}
                </span>
              </ReadMoreLink>
            </Content>
          </CardTile>
        ))}
      </TileContainer>
    </Container>
  );
};

export default CompensationPortfolio;
