import {
  FeatureModuleByKeyMap,
  FeatureModuleValueByKeyMap,
  MappedFeatureModules,
} from 'services/constants';

/**
 * This function returns mapped and also untouched feature
 * modules response for extra information.
 */
export const mapFeatureModules = (
  featureModuleByKeyMap: FeatureModuleByKeyMap
): MappedFeatureModules => {
  return {
    featureModules: Object.values(featureModuleByKeyMap).reduce(
      (result, value) => ({
        ...result,
        [value.name]: value.enabled,
      }),
      {} as FeatureModuleValueByKeyMap
    ),
    featureModulesWithData: featureModuleByKeyMap,
  };
};
