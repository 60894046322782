import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import {
  TransactionReceiptStatus,
  transactionReceiptStatuses,
} from 'services/constants';

interface Props {
  value: TransactionReceiptStatus | '';
  onChange: (value: TransactionReceiptStatus | '') => void;
}

const ReceiptFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-receipt"
        id="panel-receipt-header"
      >
        <Typography>{t('filters.receipts')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<TransactionReceiptStatus>
            size="medium"
            displayEmpty
            value={value}
            onChange={(e) =>
              onChange(e.target.value as TransactionReceiptStatus | '')
            }
            renderValue={(selected) => {
              if (!selected)
                return (
                  <Typography color="text.disabled">
                    {t('filters.receipts')}
                  </Typography>
                );

              return t(`transactionReceiptStatuses.${selected}`);
            }}
          >
            <MenuItem value="">
              <Typography color="text.disabled">
                {t('filters.receipts')}
              </Typography>
            </MenuItem>
            {transactionReceiptStatuses.map((status) => (
              <MenuItem key={status} value={status}>
                {t(`transactionReceiptStatuses.${status}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default ReceiptFilter;
