import { ComponentType, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { adminPaths } from 'components/App';
import {
  useGlobalState,
  useSourcePartnerScopeCheck,
} from 'context/GlobalState';
import { isPartnerBasedSource } from 'domains/partner/utils';
import { getStaticIntegrationsData } from 'domains/settings/utils';
import { LoaderWithOverlay } from 'elements';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import useUrls from 'hooks/useUrls';
import {
  PartnerAuthStatus,
  PartnerConfig,
  PartnerIds,
  PartnerIdType,
  PartnerOrgAuthDetails,
  PartnerScope,
  SubscriptionPlanType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

interface State {
  partnerOrgDetails: PartnerOrgAuthDetails | null;
  partnerDetails: PartnerConfig | null;
  isLoading: boolean;
}

const withPartnerCheck = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P) => {
  const showPageError = useShowPageError();
  const { partnerId } = useParams<{ partnerId: string }>();
  const { t, i18n } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { HELP_CENTER_EXPORTING_TRANSACTIONS_LEXOFFICE_URL } = useUrls();
  const {
    state: { organization, subscriptionPlan, featureModules },
  } = useGlobalState();

  const [partnerAllowed, setPartnerAllowed] = useState<boolean | null>(null);
  const [state, setState] = useState<State>({
    isLoading: true,
    partnerOrgDetails: null,
    partnerDetails: null,
  });

  const orgHasNoSourcePartner = !isPartnerBasedSource(organization!.partnerId);
  const sourcePartnerIsCustomer = useSourcePartnerScopeCheck(
    PartnerScope.customer
  );
  const partnerStaticData = getStaticIntegrationsData(t, i18n, {
    HELP_CENTER_EXPORTING_TRANSACTIONS_LEXOFFICE_URL,
  });
  const isEmbeddedPartnerPageUsed = !featureModules.INTEGRATIONS_PAGE;
  const appRedirectionUrl =
    orgHasNoSourcePartner || !isEmbeddedPartnerPageUsed
      ? adminPaths.settingsIntegrations
      : adminPaths.partner;

  useEffect(() => {
    if (!partnerId) return;

    // all circula partner instances should use the same translations
    let circulaId;
    if (
      organization!.partnerId &&
      ([
        PartnerIds.circula,
        PartnerIds.circulaDemo,
        PartnerIds.circulaEmbedded,
      ] as PartnerIdType[]).includes(organization!.partnerId)
    ) {
      circulaId = PartnerIds.circula;
    }

    // forbid the page view for pliant source orgs with
    // starter subscription plan for premium integrations
    if (
      orgHasNoSourcePartner &&
      (!subscriptionPlan ||
        subscriptionPlan.type === SubscriptionPlanType.starter) &&
      partnerStaticData[
        (circulaId || partnerId) as keyof typeof partnerStaticData
      ]?.isPremium
    ) {
      setPartnerAllowed(false);
      return;
    }

    switch (partnerId) {
      case PartnerIds.candis:
        setPartnerAllowed(
          orgHasNoSourcePartner ||
            sourcePartnerIsCustomer ||
            organization!.partnerId === PartnerIds.candis
        );
        break;

      case PartnerIds.bezala:
        setPartnerAllowed(orgHasNoSourcePartner || sourcePartnerIsCustomer);
        break;

      case PartnerIds.circula:
      case PartnerIds.circulaDemo:
      case PartnerIds.circulaEmbedded:
      case PartnerIds.circulaFullyEmbedded:
        setPartnerAllowed(
          orgHasNoSourcePartner ||
            sourcePartnerIsCustomer ||
            organization!.partnerId === PartnerIds.circula ||
            organization!.partnerId === PartnerIds.circulaDemo ||
            organization!.partnerId === PartnerIds.circulaEmbedded ||
            organization!.partnerId === PartnerIds.circulaFullyEmbedded
        );
        break;

      case PartnerIds.mobilexpenseDeclaree:
        setPartnerAllowed(
          orgHasNoSourcePartner ||
            sourcePartnerIsCustomer ||
            organization!.partnerId === PartnerIds.mobilexpenseDeclaree
        );
        break;

      case PartnerIds.mobilexpenseMXP:
        setPartnerAllowed(
          orgHasNoSourcePartner ||
            sourcePartnerIsCustomer ||
            organization!.partnerId === PartnerIds.mobilexpenseMXP
        );
        break;

      case PartnerIds.klippa:
        setPartnerAllowed(
          orgHasNoSourcePartner ||
            sourcePartnerIsCustomer ||
            organization!.partnerId === PartnerIds.klippa
        );
        break;

      case PartnerIds.mocoapp:
        setPartnerAllowed(
          orgHasNoSourcePartner ||
            sourcePartnerIsCustomer ||
            organization!.partnerId === PartnerIds.mocoapp
        );
        break;

      case PartnerIds.voxel:
        setPartnerAllowed(
          orgHasNoSourcePartner ||
            sourcePartnerIsCustomer ||
            organization!.partnerId === PartnerIds.voxel
        );
        break;

      case PartnerIds.conferma:
        setPartnerAllowed(
          orgHasNoSourcePartner ||
            sourcePartnerIsCustomer ||
            organization!.partnerId === PartnerIds.conferma
        );
        break;

      case PartnerIds.bas:
        setPartnerAllowed(
          orgHasNoSourcePartner ||
            sourcePartnerIsCustomer ||
            organization!.partnerId === PartnerIds.bas
        );
        break;
      case PartnerIds.scopevisio:
        setPartnerAllowed(
          orgHasNoSourcePartner ||
            sourcePartnerIsCustomer ||
            organization!.partnerId === PartnerIds.scopevisio
        );
        break;
      default:
        setPartnerAllowed(false);
    }
  }, [partnerId]);

  const getData = async () => {
    try {
      const [partnerOrgDetails, partnerDetails] = await Promise.all([
        api.getPartnerOrgAuthDetails(partnerId, organization!.id),
        api.getPartnerConfig(partnerId),
      ]);
      if (!mounted.current) return;

      setState((prevState) => ({
        ...prevState,
        partnerOrgDetails,
        partnerDetails,
        isLoading: false,
      }));
    } catch (error) {
      showPageError();
      logError(error);
    }
  };

  useEffect(() => {
    if (partnerAllowed) getData();
    else if (typeof partnerAllowed === 'boolean')
      setState((prevState) => ({ ...prevState, isLoading: false }));
  }, [partnerAllowed]);

  if (state.isLoading) return <LoaderWithOverlay loading />;

  if (
    !state.partnerOrgDetails ||
    !state.partnerDetails ||
    state.partnerOrgDetails.status === PartnerAuthStatus.active ||
    state.partnerOrgDetails.status === PartnerAuthStatus.pending
  )
    return <Redirect to={appRedirectionUrl} />;

  return (
    <Component
      {...props}
      partnerDetails={state.partnerDetails!}
      partnerOrgDetails={state.partnerOrgDetails!}
      appRedirectionUrl={appRedirectionUrl}
    />
  );
};

export default withPartnerCheck;
