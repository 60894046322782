import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FileMultipleIcon } from 'assets/icons/interface/fileMultiple.svg';
import FormatMoney from 'components/FormatMoney';
import { LoaderWithOverlay } from 'components/Loader';
import NoData from 'components/NoData';
import { PageContent, PageHeader, PageHeadingLine } from 'components/Page';
import { useGlobalState } from 'context/GlobalState';
import { env } from 'env';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useHeaderBorderOnScroll from 'hooks/useBorderOnScroll';
import useMounted from 'hooks/useMounted';
import {
  Bill,
  OrganizationAccountType,
  OrganizationStatus,
  PartialPaymentStatus,
  Payment,
  PaymentFrequencyType,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { didOrgOnboardingStepPassed } from 'services/utils/onboarding';
import StatementItem from './StatementItem';
import { Header, OrgAvailableLimit, OrgAvailableLimitLabel } from './style';

const filterPayments = (
  payments: Payment[],
  currentPaymentFrequency: PaymentFrequencyType
) => {
  if (currentPaymentFrequency !== PaymentFrequencyType.DAILY) return payments;
  return payments.filter(
    (item) => item.status !== PartialPaymentStatus.planned
  );
};

const transformPayments = (
  payments: Payment[],
  currentPaymentFrequency: PaymentFrequencyType
) => filterPayments(payments, currentPaymentFrequency);

interface State {
  isLoading: boolean;
  bills: Bill[] | null;
  billPayments: {
    [billId: string]: Payment[] | null;
  };
  currentPaymentFrequency: PaymentFrequencyType | null;
}

const StatementsPage = () => {
  const { datevExportEnabled } = useFlags();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const { getHeaderStyle, onContentScroll } = useHeaderBorderOnScroll();
  const showPageError = useShowPageError();
  const mounted = useMounted();
  const {
    dispatch,
    state: { organization, defaultCardAccount },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    bills: null,
    billPayments: {},
    currentPaymentFrequency: null,
  });
  const didOrgPassOnboardingRisk = didOrgOnboardingStepPassed(
    organization!.status,
    OrganizationStatus.onboardingRiskReview
  );

  const getBillPayments = async (
    billId: string,
    currentPaymentFrequency: PaymentFrequencyType
  ) => {
    const payments = await api.getBillPayments(billId);
    if (!mounted.current) return;
    setState((state) => ({
      ...state,
      billPayments: {
        ...state.billPayments,
        [billId]: transformPayments(payments, currentPaymentFrequency),
      },
    }));
  };

  const getData = async () => {
    try {
      setState((state) => ({ ...state, isLoading: true }));
      const [
        { bills },
        paymentFrequency,
        accountingSettings,
      ] = await Promise.all([
        api.getBills(
          organization!.id,
          env.REACT_APP_BUILD_TARGET === 'internal'
        ),
        api.getPaymentFrequency(organization!.id),
        datevExportEnabled
          ? api.getAccountingSettings(organization!.id)
          : Promise.resolve(null),
      ]);
      if (accountingSettings) {
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { accountingSettings },
        });
      }
      const { value: currentPaymentFrequency } = paymentFrequency.current;
      if (
        bills.length &&
        (bills[0].pendingAmount.value ||
          bills[0].paidAmount.value ||
          bills[0].unpaidAmount.value ||
          bills[0].deferredAmount.value)
      ) {
        await getBillPayments(bills[0].billId, currentPaymentFrequency);
      }
      if (!mounted.current) return;
      setState((state) => ({
        ...state,
        isLoading: false,
        bills,
        currentPaymentFrequency,
      }));
    } catch (error) {
      showPageError();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <PageHeader style={getHeaderStyle()}>
        <PageHeadingLine heading={t('statementsPage.statements')} $mb={14}>
          {defaultCardAccount!.accountType.value ===
            OrganizationAccountType.prefunded &&
            didOrgPassOnboardingRisk &&
            defaultCardAccount!.balance && (
              <Box display="flex" alignItems="center">
                <Box textAlign="right" mr="56px">
                  <OrgAvailableLimit>
                    <FormatMoney value={defaultCardAccount!.balance.value} />
                  </OrgAvailableLimit>
                  <OrgAvailableLimitLabel>
                    {t('statementsPage.availableBalance')}
                  </OrgAvailableLimitLabel>
                </Box>
              </Box>
            )}
        </PageHeadingLine>
      </PageHeader>
      <PageContent onScroll={onContentScroll}>
        <Header>
          <span>{t('statementsPage.statementPeriod')}</span>
          <span>{t('statementsPage.transactions')}</span>
          <span>{t('statementsPage.amount')}</span>
        </Header>
        {state.bills?.map((bill, index) => (
          <StatementItem
            key={bill.billId}
            payments={state.billPayments[bill.billId]}
            fetchPayments={() =>
              getBillPayments(bill.billId, state.currentPaymentFrequency!)
            }
            bill={bill}
            defaultOpen={
              index === 0 &&
              !!(
                bill.pendingAmount.value ||
                bill.paidAmount.value ||
                bill.unpaidAmount.value ||
                bill.deferredAmount.value
              )
            }
          />
        ))}
        {state.isLoading && <LoaderWithOverlay size={32} thickness={3} />}
        {!state.isLoading && state.bills?.length === 0 && (
          <NoData
            icon={<FileMultipleIcon />}
            label={t('statementsPage.noStatements')}
          />
        )}
      </PageContent>
    </>
  );
};

export default withPageConfig(StatementsPage, {
  permission: 'statements-page:visit',
});
