import { styled } from '@mui/material';

export const CustomTable = styled('table')(
  ({ theme }) => `
  padding: ${theme.spacing(3)} 0;
  table-layout: fixed;
  width: fixed;

  tr {
    position: relative;
  }

  th:nth-of-type(1) {
    min-width: 232px;
  }

  th:nth-of-type(2),
  th:nth-of-type(3),
  th:nth-of-type(4) {
    min-width: 164px;
  }

  th:nth-of-type(5) {
    width: 100%;
  }

  td:nth-of-type(5) {
    text-align: right;
  }

  td,
  th {
    padding-bottom: ${theme.spacing(3)};
    text-align: left;
    vertical-align: top;
  }

  td:not(:last-child),
  th:not(:last-child) {
    padding-right: ${theme.spacing(4)};
  }
`
);
