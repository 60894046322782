import React, { useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Box, Button, CheckCircleIcon, Grid, Typography } from 'elements';
import {
  OrganizationAccountType,
  OrganizationStatus,
} from 'services/constants';

interface State {
  isCompleteCreditAssessmentLoading: boolean;
  isCompleteCreditAssessmentDialogOpen: boolean;
  isRevertToRiskAssessmentLoading: boolean;
  isRevertToRiskAssessmentDialogOpen: boolean;
  isUpdateConnectBankAccountsDialogOpen: boolean;
  isUpdateConnectBankAccountsLoading: boolean;
}

const CompleteCreditAssessmentSection = () => {
  const { t } = useTranslation();

  const [state, setState] = useState<State>({
    isCompleteCreditAssessmentDialogOpen: false,
    isCompleteCreditAssessmentLoading: false,
    isRevertToRiskAssessmentDialogOpen: false,
    isRevertToRiskAssessmentLoading: false,
    isUpdateConnectBankAccountsDialogOpen: false,
    isUpdateConnectBankAccountsLoading: false,
  });

  const {
    actions: { completeCreditAssessment, revertOrganizationStatus },
    state: { bankAccounts, creditAssessment, riskAndCompliance },
  } = useCreditAndComplianceContext();

  const {
    state: { organization, defaultCardAccount },
  } = useGlobalState();

  const canBeCompleted =
    organization!.status === OrganizationStatus.onboardingRiskReview &&
    !!defaultCardAccount!.accountType.value &&
    creditAssessment!.underwritingCase.selectedUnderwritingCase.value &&
    (defaultCardAccount!.accountType.value ===
      OrganizationAccountType.prefunded ||
      creditAssessment!.limits.limit.value.value > 0);

  return (
    <>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        justifyContent="space-between"
        marginBottom={3}
        py={1}
      >
        <Typography variant="h6">
          {t('int.completeCreditAssessmentSection.title')}
        </Typography>
      </Box>

      {riskAndCompliance!.riskAssessment ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          paddingY={3}
          spacing={1}
          sx={{
            backgroundColor: '#e5fff5', // Todo color palette
            borderRadius: 1,
          }}
        >
          <Grid
            item
            display="flex"
            alignItems="center"
            sx={{ color: 'success.main' }}
          >
            <CheckCircleIcon color="inherit" />
            <Typography variant="body2" color="inherit" marginLeft={1}>
              {t(
                'int.completeCreditAssessmentSection.creditAssessmentCompleted'
              )}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {t('common.lastChangedBy', {
                dateTime: moment(
                  riskAndCompliance!.riskAssessment.completedAt
                ).format('DD.MM.YYYY HH:mm'),
                email: riskAndCompliance!.riskAssessment.completedBy,
              })}
            </Typography>
          </Grid>

          <Grid item>
            {[
              OrganizationStatus.onboardingCompliance,
              OrganizationStatus.onboardingDirectDebit,
              OrganizationStatus.onboardingWait,
            ].includes(organization!.status) && (
              <Button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isRevertToRiskAssessmentDialogOpen: true,
                  }))
                }
                variant="text"
              >
                {t(
                  'int.resetRiskAssessmentSection.revertToRiskAssessmentButton'
                )}
              </Button>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          paddingY={3}
          spacing={1}
          sx={{
            backgroundColor: 'grey.100',
            borderRadius: 1,
          }}
        >
          <Grid item>
            <Button
              disabled={!canBeCompleted}
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  isCompleteCreditAssessmentDialogOpen: true,
                }))
              }
              variant="outlined"
            >
              {t(
                'int.completeCreditAssessmentSection.completeCreditAssessmentLabel'
              )}
            </Button>
          </Grid>

          <Grid item>
            {OrganizationStatus.onboardingRiskReview === organization!.status &&
              organization!.bankConnectionRequired &&
              !!bankAccounts.length && (
                <Button
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isUpdateConnectBankAccountsDialogOpen: true,
                    }))
                  }
                  variant="text"
                >
                  {t(
                    'int.resetRiskAssessmentSection.updateConnectBankAccountsButton'
                  )}
                </Button>
              )}
          </Grid>
        </Grid>
      )}

      <ConfirmDialog
        open={state.isCompleteCreditAssessmentDialogOpen}
        loading={state.isCompleteCreditAssessmentLoading}
        title={t(
          'int.completeCreditAssessmentSection.completeCreditAssessmentDialogTitle'
        )}
        description={
          <Trans
            i18nKey="int.completeCreditAssessmentSection.completeCreditAssessmentDialogDescription"
            components={{
              b: <Typography component="span" sx={{ fontWeight: 'bold' }} />,
            }}
            values={{
              name: organization!.name,
            }}
          />
        }
        onSuccess={async () => {
          setState((prevState) => ({
            ...prevState,
            isCompleteCreditAssessmentLoading: true,
          }));
          await completeCreditAssessment(() => {
            setState((prevState) => ({
              ...prevState,
              isCompleteCreditAssessmentDialogOpen: false,
              isCompleteCreditAssessmentLoading: false,
            }));
          });
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isCompleteCreditAssessmentDialogOpen: false,
          }))
        }
      />

      <ConfirmDialog
        open={state.isUpdateConnectBankAccountsDialogOpen}
        loading={state.isUpdateConnectBankAccountsLoading}
        title={t(
          'int.completeCreditAssessmentSection.updateConnectBankAccountsDialogTitle'
        )}
        description={t(
          'int.completeCreditAssessmentSection.updateConnectBankAccountsDialogDescription'
        )}
        onSuccess={async () => {
          setState((prevState) => ({
            ...prevState,
            isUpdateConnectBankAccountsLoading: true,
          }));
          await revertOrganizationStatus(
            OrganizationStatus.onboardingRiskData,
            () => {
              setState((prevState) => ({
                ...prevState,
                isUpdateConnectBankAccountsDialogOpen: false,
                isUpdateConnectBankAccountsLoading: false,
              }));
            }
          );
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isUpdateConnectBankAccountsDialogOpen: false,
          }))
        }
      />

      <ConfirmDialog
        open={state.isRevertToRiskAssessmentDialogOpen}
        loading={state.isRevertToRiskAssessmentLoading}
        title={t(
          'int.completeCreditAssessmentSection.revertToRiskAssessmentDialogTitle'
        )}
        description={
          <Trans
            i18nKey="int.completeCreditAssessmentSection.revertToRiskAssessmentDialogDescription"
            components={{
              li: <li />,
              p: <p />,
              ul: <ul />,
            }}
          />
        }
        onSuccess={async () => {
          setState((prevState) => ({
            ...prevState,
            isRevertToRiskAssessmentLoading: true,
          }));
          await revertOrganizationStatus(
            OrganizationStatus.onboardingRiskReview,
            () => {
              setState((prevState) => ({
                ...prevState,
                isRevertToRiskAssessmentDialogOpen: false,
                isRevertToRiskAssessmentLoading: false,
              }));
            }
          );
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isRevertToRiskAssessmentDialogOpen: false,
          }))
        }
      />
    </>
  );
};

export default CompleteCreditAssessmentSection;
