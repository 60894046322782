import React from 'react';
import styled from 'styled-components';
import { ReactComponent as MapEarth2Icon } from 'assets/icons/mapsTravel/mapEarth2.svg';
import FormatDecimal from 'components/FormatDecimal';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  line-height: 20px;
  justify-content: flex-end;

  svg {
    width: 16px;
    height: 16px;
    margin-bottom: 2px;
    margin-right: 4px;
  }
`;

interface Props {
  value: number;
}

export const Co2CompensatedBadge = ({ value }: Props) => {
  return (
    <Wrapper>
      <MapEarth2Icon />
      <FormatDecimal value={value / 1000000} unit="t" />
    </Wrapper>
  );
};
