import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CountryFlag from 'components/CountryFlag';
import NothingFound from 'components/NothingFound';
import {
  MerchantLogo,
  MerchantPreview,
  MerchantSplitTypeBadge,
  MerchantStatusBadge,
} from 'domains/merchant/components';
import {
  ArrowRightIcon,
  Box,
  Button,
  DataGrid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  GridColDef,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Typography,
  useGridApiRef,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageSearchInput } from 'layout';
import {
  countries,
  DEFAULT_PAGE_LIMIT,
  Merchant,
  MerchantDetails,
  MerchantSplitType,
  MerchantStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const columns: GridColDef<Merchant>[] = [
  {
    field: 'displayName',
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <Box display="flex" alignItems="center" overflow="hidden">
        <MerchantLogo url={row.logoPath} name={row.displayName} />
        <Typography variant="body2" noWrap sx={{ ml: 2 }}>
          {row.displayName}
        </Typography>
        <MerchantSplitTypeBadge
          value={row.splitType}
          sx={{ position: 'relative', top: -5, ml: 1 }}
        />
      </Box>
    ),
  },
  {
    field: 'legalName',
    sortable: false,
    flex: 1.5,
    renderCell: ({ row }) => (
      <Box overflow="hidden">
        <Typography variant="body2" color="text.primary" noWrap>
          {row.legalName || '-'}
        </Typography>
        <Typography
          component="div"
          variant="caption"
          color="text.secondary"
          noWrap
        >
          {[row.street, row.postalCode, row.city, row.state]
            .filter((v) => !!v)
            .join(', ') || '-'}
        </Typography>
      </Box>
    ),
  },
  {
    field: 'status',
    sortable: false,
    width: 130,
    minWidth: 130,
    maxWidth: 130,
    renderCell: ({ row }) => <MerchantStatusBadge status={row.status} />,
  },
  {
    field: 'country',
    sortable: false,
    width: 48,
    minWidth: 48,
    maxWidth: 48,
    renderCell: ({ row }) => <CountryFlag code={row.country} />,
  },
];

const visibleMerchantStatuses = [
  MerchantStatus.draft,
  MerchantStatus.published,
];

const isRowSelectable = (srcMerchant: Merchant, row: Merchant) => {
  if (
    srcMerchant.id === row.id ||
    row.status === MerchantStatus.new ||
    row.splitType === MerchantSplitType.splitMerchant
  ) {
    return false;
  }
  return true;
};

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (merchant: MerchantDetails) => void;
  srcMerchant: Merchant;
}

interface State {
  step: 1 | 2;
  isMerging: boolean;
  isLoading: boolean;
  q: string;
  country: string;
  status: string;
  merchants: Merchant[];
  hasNextPage: boolean;
  totalCount: number;
  targetMerchant: Merchant | null;
}

const MergeMerchantsDialog = ({ onSuccess, srcMerchant, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const [state, setState] = useState<State>({
    step: 1,
    isMerging: false,
    isLoading: true,
    q: '',
    country: '',
    status: '',
    merchants: [],
    hasNextPage: false,
    totalCount: 0,
    targetMerchant: null,
  });

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      if (!isLoadMore) {
        setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
      }
      const { q, country, status } = state;
      const { merchants, hasNextPage, totalCount } = await api.getMerchants({
        page,
        limit,
        q: q || undefined,
        countries: country || undefined,
        statuses: status || visibleMerchantStatuses.join(','),
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        merchants: isLoadMore
          ? [...prevState.merchants, ...merchants]
          : merchants,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });
    pageRef.current = 0;
    getData(pageRef.current);
  }, [state.q, state.country, state.status]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const mergeMerchants = async () => {
    try {
      setState((prevState) => ({ ...prevState, isMerging: true }));
      const mergedMerchant = await api.mergeMerchants(
        srcMerchant.id,
        state.targetMerchant!.id
      );
      if (!mounted.current) return;
      onSuccess(mergedMerchant);
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isMerging: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth="md">
      <DialogTitle>{t('int.mergeMerchantsDialog.title')}</DialogTitle>
      <DialogContent
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {state.step === 1 ? (
          <>
            <Typography variant="body2">
              {t('int.mergeMerchantsDialog.subTitle1')}
            </Typography>

            <Box sx={{ my: 3, display: 'flex' }}>
              <PageSearchInput
                initialValue={state.q}
                onChange={(str) =>
                  setState((prevState) => ({ ...prevState, q: str }))
                }
              />
              <Box mx={2}>
                <Select<string>
                  displayEmpty
                  value={state.country}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      country: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="">
                    <Typography lineHeight="inherit" color="text.disabled">
                      {t('filters.country')}
                    </Typography>
                  </MenuItem>
                  {countries.map(({ countryCode, countryName }) => (
                    <MenuItem key={countryCode} value={countryCode}>
                      {countryName}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <Select<string>
                  displayEmpty
                  value={state.status}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      status: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="">
                    <Typography lineHeight="inherit" color="text.disabled">
                      {t('filters.status')}
                    </Typography>
                  </MenuItem>
                  {visibleMerchantStatuses.map((status) => (
                    <MenuItem key={status} value={status}>
                      {t(`merchantStatuses.${status}`)?.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="body2" sx={{ mb: 3 }}>
              {t('int.mergeMerchantsDialog.subTitle2')}
            </Typography>
            <Box display="flex" flexDirection="row" bgcolor="white">
              <Box flex={1} overflow="hidden">
                <Box my={2}>
                  <Typography variant="subtitle2">
                    {t('int.mergeMerchantsDialog.toBeAcquired')}
                  </Typography>
                </Box>
                <MerchantPreview
                  logoPath={srcMerchant.logoPath}
                  displayName={srcMerchant.displayName}
                  status={srcMerchant.status}
                  transactionsCount={srcMerchant.transactionsCount}
                  transactionsVolume={srcMerchant.transactionsVolume}
                  merchant={srcMerchant}
                />
              </Box>
              <Box
                px={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <ArrowRightIcon />
              </Box>
              <Box flex={1} overflow="hidden">
                <Box my={2}>
                  <Typography variant="subtitle2">
                    {t('int.mergeMerchantsDialog.acquirer')}
                  </Typography>
                </Box>
                <MerchantPreview
                  logoPath={state.targetMerchant!.logoPath}
                  displayName={state.targetMerchant!.displayName}
                  status={state.targetMerchant!.status}
                  transactionsCount={state.targetMerchant!.transactionsCount}
                  transactionsVolume={state.targetMerchant!.transactionsVolume}
                  merchant={state.targetMerchant!}
                />
              </Box>
            </Box>
          </>
        )}

        <Box
          sx={{
            // to avoid unexpected errors, it is necessary to keep table
            // component mounted with defined height
            // see https://github.com/mui/mui-x/issues/6879
            position: state.step === 2 ? 'absolute' : 'relative',
            top: state.step === 2 ? '100%' : undefined,
            height: state.step === 2 ? '1px' : undefined,
            minHeight: state.step === 2 ? undefined : '300px',
            visibility: state.step === 2 ? 'hidden' : undefined,
            flex: '1 1 512px',
          }}
        >
          <LoaderWithOverlay loading={state.isLoading} />
          <DataGrid<Merchant>
            sx={{
              '& .MuiDataGrid-row.MuiDataGrid-row--disabled': {
                cursor: 'default',
                background: 'transparent',
                opacity: 0.5,
              },
            }}
            keepNonExistentRowsSelected
            apiRef={dataGridRef}
            rowSelectionModel={
              state.targetMerchant?.id ? [state.targetMerchant?.id] : []
            }
            loading={state.isLoading}
            rows={state.merchants}
            rowCount={state.totalCount}
            columns={columns}
            getRowClassName={(params) =>
              isRowSelectable(srcMerchant, params.row)
                ? ''
                : 'MuiDataGrid-row--disabled'
            }
            onRowsScrollEnd={() => {
              if (!state.isLoading && state.hasNextPage) loadMoreItems();
            }}
            onRowClick={({ id, row }) => {
              if (!isRowSelectable(srcMerchant, row)) return;
              setState((prevState) => ({
                ...prevState,
                targetMerchant:
                  prevState.targetMerchant?.id === id ? null : row,
              }));
            }}
            slots={{
              columnHeaders: forwardRef(() => null),
              noRowsOverlay: () => {
                return <NothingFound />;
              },
              loadingOverlay: () => null,
            }}
            rowHeight={64}
          />
        </Box>
        <LoaderWithOverlay loading={state.isMerging} />
      </DialogContent>
      <DialogActions>
        {state.step === 2 && (
          <Box flexGrow="1">
            <Button
              disabled={state.isMerging}
              variant="text"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  step: 1,
                  splitMerchantDetails: null,
                }))
              }
            >
              {t('common.button.back')}
            </Button>
          </Box>
        )}
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        {state.step === 1 ? (
          <Button
            onClick={() => setState((prevState) => ({ ...prevState, step: 2 }))}
            disabled={!state.targetMerchant}
          >
            {t('common.button.next')}
          </Button>
        ) : (
          <Button disabled={state.isMerging} onClick={mergeMerchants}>
            {t('int.mergeMerchantsDialog.merge')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withDialogWrapper(MergeMerchantsDialog);
