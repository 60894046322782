import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon, IconButton, Paper, Typography } from 'elements';

interface Props {
  displayValue?: JSX.Element;
  value: string;
  onCopy?: (text: string, result: boolean) => void;
}

const CopyTextToClipboard = ({ displayValue, value, onCopy }: Props) => {
  return (
    <Paper
      variant="tinted"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        maxWidth: '100%',
        py: 1,
        pl: 2,
      }}
    >
      <Typography noWrap>{displayValue || value}</Typography>
      <CopyToClipboard text={value} onCopy={onCopy}>
        <IconButton size="small" sx={{ mx: 1 }}>
          <CopyIcon fontSize="small" />
        </IconButton>
      </CopyToClipboard>
    </Paper>
  );
};

export default CopyTextToClipboard;
