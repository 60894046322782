import React from 'react';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import InformationRequestSection from './InformationRequestSection';

const CreditQaSubPage = () => {
  const {
    state: { isNonCustomer },
  } = useCreditAndComplianceContext();

  return <>{!isNonCustomer && <InformationRequestSection />}</>;
};

export default CreditQaSubPage;
