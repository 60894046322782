import { memo } from 'react';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import { DataGridCell, Typography } from 'elements';
import { ExportFlowTransaction } from 'services/constants';

const CardAccountCell = ({
  cardAccountId,
  cardAccountNumber,
}: Pick<ExportFlowTransaction, 'cardAccountId' | 'cardAccountNumber'>) => {
  const getCardAccountName = useCardAccountNameGetter();

  return (
    <DataGridCell>
      {getCardAccountName(cardAccountId)}

      {!!cardAccountNumber && (
        <Typography
          component="div"
          variant="caption"
          color="textSecondary"
          noWrap
        >
          {cardAccountNumber}
        </Typography>
      )}
    </DataGridCell>
  );
};

export default memo(CardAccountCell);
