import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import NoData from 'components/NoData';
import { ReceivableRepaymentDetailsPage } from 'domains/billing/pages';
import {
  DataGrid,
  FileTextIcon,
  LoaderWithOverlay,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import { DEFAULT_PAGE_LIMIT, ReceivableRepayment } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import useColumns from './useColumns';

interface State {
  isLoading: boolean;
  repayments: ReceivableRepayment[];
  hasNextPage: boolean;
  totalCount: number;
}

const ReceivableRepaymentsPage = () => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const { columns } = useColumns();
  const history = useHistory();
  const location = useLocation();
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:repaymentId',
    true
  );
  const api = useImperativeApi();
  const mounted = useMounted();
  const showPageError = useShowPageError();
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const [state, setState] = useState<State>({
    isLoading: true,
    repayments: [],
    hasNextPage: false,
    totalCount: 0,
  });

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      if (!isLoadMore) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
      }
      const {
        repayments,
        totalCount,
        hasNextPage,
      } = await api.getReceivableRepayments({ page, limit });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        repayments: isLoadMore
          ? [...prevState.repayments, ...repayments]
          : repayments,
        totalCount,
        hasNextPage,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  useEffect(() => {
    getData(pageRef.current);
  }, []);

  const onRepaymentUpdated = (repayment: ReceivableRepayment) => {
    setState((prevState) => ({
      ...prevState,
      repayments: prevState.repayments.map((item) =>
        item.id === repayment.id ? repayment : item
      ),
    }));
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('int.receivableRepaymentsPage.title')} />
      </PageHeader>
      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />
        <DataGrid<ReceivableRepayment>
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          apiRef={dataGridRef}
          rowSelectionModel={
            detailsParams?.repaymentId ? [detailsParams.repaymentId] : []
          }
          loading={state.isLoading}
          rows={state.repayments}
          columns={columns}
          columnVisibilityModel={{ drawerPlaceholder: isDetailsPageOpen }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ id }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${id}${location.search}`);
          }}
          slots={{
            noRowsOverlay: () => {
              return (
                <NoData
                  isNewDesign
                  Icon={FileTextIcon}
                  label={t('int.receivableRepaymentsPage.noData')}
                />
              );
            },
            loadingOverlay: () => null,
          }}
          rowHeight={72}
        />
        <Route
          path={`${path}/:repaymentId`}
          children={({ match }) => (
            <ReceivableRepaymentDetailsPage
              open={!!match}
              onUpdate={onRepaymentUpdated}
            />
          )}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(ReceivableRepaymentsPage, {
  permission: 'receivable-repayments-page:visit',
});
