import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  XSquareIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

interface State {
  isLoading: boolean;
  image: string | null;
}

interface Props {
  reportId: string;
  onClick?: () => void;
}

const ThumbnailPreview = ({ reportId, onClick }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const [state, setState] = useState<State>({ isLoading: true, image: null });

  const getData = async () => {
    try {
      const image = await api.getPublicDocumentThumbnail(reportId);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, image, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Paper
      variant="outlined"
      onClick={onClick}
      sx={{
        height: 150,
        backgroundRepeat: 'no-repeat',
        backgroundImage: state.image ? `url(${state.image})` : 'none',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        overflow: 'hidden',
        '&:hover': {
          cursor: onClick ? 'pointer' : undefined,
        },
      }}
    >
      {state.isLoading && (
        <Box
          height={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size="small" />
        </Box>
      )}
      {!state.isLoading && !state.image && (
        <Box
          height={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <XSquareIcon />
          <Typography mt={2}>
            {t('accountingEntryDetailsPage.thumbnailErrorMessage')}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default ThumbnailPreview;
