import styled from 'styled-components';

export const Heading = styled.h2`
  flex-shrink: 0;
  margin: 0 0 30px;
  font-size: 24px;
  line-height: 46px;
`;

export const MailDescription = styled.div<{ $mb?: number; $maxWidth?: string }>`
  max-width: ${({ $maxWidth = 'none' }) => $maxWidth};
  margin: 0 0 ${({ $mb = 0 }) => $mb}px;
  font-size: 14px;
  line-height: 26px;
`;

export const Disclaimer = styled.div<{ $mt?: number }>`
  margin-top: ${({ $mt = 0 }) => $mt}px;
  font-size: 14px;
  line-height: 26px;
`;
