import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  ListItemText,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  OrganizationMerchant,
  VatRate,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getPath } from 'services/utils';

const getVatRateName = (value: VatRate) => {
  const code = value.code ? `, ${value.code}` : '';
  return `${value.name} (${value.rate}%${code})`;
};

interface State {
  isLoading: boolean;
  value: string;
  vatRates: VatRate[];
}

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (value: OrganizationMerchant['vatRateResponse']) => void;
  vatRateResponse: OrganizationMerchant['vatRateResponse'];
  merchantId: string;
}

const ChangeVatRateDialog = ({
  vatRateResponse,
  onSuccess,
  merchantId,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    value: '',
    vatRates: [],
  });

  const getVatRates = async () => {
    try {
      const { vatRates } = await api.getVatRates(organization!.id);
      dispatch({ type: 'SET_ORGANIZATION_DATA', payload: { vatRates } });
      if (!mounted.current) return;
      const visibleVatRates = vatRates.filter(
        (item) =>
          item.status === AccountingItemStatus.active ||
          item.id === vatRateResponse?.id
      );
      setState((prevState) => ({
        ...prevState,
        vatRates: visibleVatRates,
        isLoading: false,
        value: vatRateResponse?.id || '',
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getVatRates();
  }, []);

  const updateVatRate = async (): Promise<
    OrganizationMerchant['vatRateResponse']
  > => {
    if (state.value) {
      await api.assignVatRateToMerchant(
        organization!.id,
        state.value,
        merchantId
      );
      const selectedVatRate = state.vatRates.find(
        (item) => item.id === state.value
      )!;
      return {
        id: selectedVatRate.id,
        name: selectedVatRate.name,
        code: selectedVatRate.code || null,
        rate: String(selectedVatRate.rate),
      };
    }
    await api.unassignVatRateFromMerchant(
      organization!.id,
      vatRateResponse!.id,
      merchantId
    );
    return null;
  };

  const handleSubmit = async () => {
    if (state.isLoading) return;
    if (state.value === (vatRateResponse?.id || '')) return props.onClose();
    setState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const updatedVatRateResponse = await updateVatRate();
      if (!mounted.current) return;
      onSuccess(updatedVatRateResponse);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('vatRateSelectDialog.header')}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={4}>
          <Trans
            i18nKey="vatRateSelectDialog.description"
            components={{
              b: <b />,
              vatRatesLink: (
                <Link
                  component={RouterLink}
                  to={generatePath(getPath('settingsVatRates'), {
                    orgId: organization!.id,
                  })}
                />
              ),
            }}
          />
        </Typography>
        <FormControl fullWidth disabled={state.isLoading}>
          <InputLabel id="vat-rate-select-label">
            {t('vatRateSelectDialog.label')}
          </InputLabel>
          <Select<string>
            labelId="vat-rate-select-label"
            disabled={state.isLoading}
            value={state.value}
            displayEmpty
            renderValue={(value) => {
              if (value) {
                const selectedVatRate = state.vatRates.find(
                  (item) => item.id === value
                );
                if (selectedVatRate) return getVatRateName(selectedVatRate);
              }
              return '—';
            }}
            onChange={(event) => {
              setState((prevState) => ({
                ...prevState,
                value: event.target.value,
              }));
            }}
          >
            <MenuItem value="">
              <ListItemText primary="—" />
            </MenuItem>
            {state.vatRates.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                <ListItemText primary={getVatRateName(item)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={state.isLoading} onClick={handleSubmit}>
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(ChangeVatRateDialog);
