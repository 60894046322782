import axios from 'axios';
import { env } from 'env';

axios.defaults.baseURL =
  env.NODE_ENV === 'development' ? '/api' : env.REACT_APP_API_URL;

axios.interceptors.response.use(
  (v) => v,
  (e) => {
    if (
      e.request?.responseType === 'arraybuffer' &&
      e.response?.data?.toString() === '[object ArrayBuffer]'
    ) {
      // when we want to get an array buffer from the server,
      // the error message is also received as an array buffer,
      // read error code we need to transform the error response to json
      try {
        let jsonKey: string = '';
        new Uint8Array(e.response.data).forEach(function (byte: number) {
          jsonKey += String.fromCharCode(byte);
        });
        e.response.data = JSON.parse(jsonKey);
      } catch (e) {}
    }
    return Promise.reject(e);
  }
);
