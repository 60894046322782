import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Box, DataGridCell, GridColDef, StatusBadge } from 'elements';
import { CodatAccountItem, CodatDataItemStatus } from 'services/constants';

const useColumns = (isPartialFlow?: boolean) => {
  const { t } = useTranslation();
  const {
    state: { accountingSettings },
  } = useGlobalState();

  return useMemo(
    (): GridColDef<CodatAccountItem>[] => [
      {
        field: 'accountingSystemName',
        headerName: t('codatSyncSetupDialog.glAccountsStep.accountsLabel', {
          name: accountingSettings!.accountingSystemName,
        }),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box display="flex" alignItems="center" overflow="hidden">
            <DataGridCell>{row.name}</DataGridCell>
            {isPartialFlow && row.status === CodatDataItemStatus.new && (
              <StatusBadge
                color="secondary"
                variant="filled"
                label={t('common.badge.new')}
                sx={{ ml: 1 }}
              />
            )}
          </Box>
        ),
      },
      {
        field: 'accountType',
        headerName: t('codatSyncSetupDialog.glAccountsStep.accountTypeLabel'),
        sortable: false,
        flex: 1,
      },
    ],
    [isPartialFlow]
  );
};

export default useColumns;
