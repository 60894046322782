import React from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Box, Typography } from 'elements';
import {
  countries,
  OrganizationRepresentativeInternalStatus,
} from 'services/constants';
import { getYesNoFromBoolean } from 'services/utils';

const BulletListItem = ({ label, value }: { label: string; value: string }) => (
  <li>
    <Box display="flex" alignItems="center" mb={0.5}>
      <Typography width="120px">{label}</Typography>
      <Typography
        sx={{
          bgcolor: 'grey.200',
          flexGrow: 1,
          minHeight: (theme) => theme.spacing(4),
          p: 0.5,
        }}
      >
        {value}
      </Typography>
    </Box>
  </li>
);

const KycInformationSection = () => {
  const { t } = useTranslation();

  const {
    state: { representatives },
  } = useCreditAndComplianceContext();

  return (
    <>
      <Typography variant="h6" mb={1}>
        {t('int.customerDeclarations.kycInformationSection')}
      </Typography>

      {representatives
        .filter(
          (rep) =>
            rep.internalStatus.value !==
            OrganizationRepresentativeInternalStatus.archived
        )
        .map((rep) => {
          const primaryTaxResidence = rep.taxResidences.find(
            (taxResidence) => taxResidence.primary.value
          );

          return (
            <Box key={rep.id.value} marginBottom={2}>
              <span>
                {rep.givenName.value} {rep.surName.value}
              </span>

              <Box component="ul" sx={{ marginBottom: 0.5, marginTop: 1 }}>
                <BulletListItem
                  label={t('int.customerDeclarations.pep')}
                  value={getYesNoFromBoolean(rep.isPep.value, t)}
                />
                <BulletListItem
                  label={t('int.customerDeclarations.fatca')}
                  value={getYesNoFromBoolean(rep.isFatca.value, t)}
                />
                {primaryTaxResidence && (
                  <BulletListItem
                    label={t('int.customerDeclarations.tin')}
                    value={primaryTaxResidence.taxId.value}
                  />
                )}
                {primaryTaxResidence && (
                  <BulletListItem
                    label={t('int.customerDeclarations.taxResidence')}
                    value={
                      countries.find(
                        (c) =>
                          primaryTaxResidence.taxResidence.value ===
                          c.countryCode
                      )?.countryName ?? primaryTaxResidence.taxResidence.value
                    }
                  />
                )}
              </Box>

              <Typography variant="body2" color="textSecondary">
                <Trans
                  i18nKey="int.customerDeclarations.providedByAndAt"
                  components={{ b: <b /> }}
                  values={{
                    providedBy: rep.externalStatus.updatedBy,
                    providedAt: moment(rep.externalStatus.updatedAt).format(
                      'DD MMM YYYY HH:mm'
                    ),
                  }}
                />
              </Typography>
            </Box>
          );
        })}
    </>
  );
};

export default KycInformationSection;
