import styled from 'styled-components';
import { ReactComponent as BlockIcon } from 'assets/icons/interface/block.svg';

export const TableComponent = styled.div`
  position: relative;
  min-height: 100px;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${({ theme }) => theme.size.dataTableHeaderHeight}px;
  margin: 0 0 9px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  line-height: ${({ theme }) => theme.size.dataTableHeaderHeight - 1}px;
  color: ${({ theme }) => theme.colors.grey100};
`;

export const TableBody = styled.div`
  position: relative;
  min-height: 84px;
`;

export const TableFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 60px 0 0;
`;

export const TableRow = styled.div`
  margin: 30px 0;
`;

export const BankItem = styled.div`
  display: flex;
  align-items: center;
`;

export const BankAccountItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  margin: 20px 0 0 61px;

  &:before {
    content: '';
    position: absolute;
    left: -38px;
    bottom: 14px;
    width: 22px;
    height: 50px;
    border-left: 1px solid ${({ theme }) => theme.colors.neutralVariant80};
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralVariant80};
  }

  &:first-child {
    margin-top: 29px;
    &:before {
      height: 35px;
    }
  }
`;

export const ConnectionStatusLabel = styled.span`
  min-width: 150px;
  margin: 0 30px 0 0;
`;

export const NothingFound = styled.div`
  padding: 28px 0;
  font-size: 16px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.grey100};
  text-align: center;
`;

export const LabelWrapper = styled.div`
  padding: 0 13px;
`;

export const IbanLabel = styled.div<{ $lighter: boolean; $pr?: number }>`
  padding: ${({ $pr = 0 }) => `0 ${$pr}px 0 0`};
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: ${({ theme, $lighter }) =>
    $lighter ? theme.colors.grey100 : theme.colors.grey10};

  a {
    text-decoration: none;
    color: ${({ theme, $lighter }) =>
      $lighter ? theme.colors.grey100 : theme.colors.grey10};
  }
`;

export const BankLabel = styled.div<{ $lighter?: boolean }>`
  max-width: 240px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme, $lighter }) =>
    $lighter ? theme.colors.grey100 : theme.colors.grey10};
  ${({ theme }) => theme.mixins.overflowEllipsis}
`;

export const StyledBlockIcon = styled(BlockIcon)`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  fill: ${({ theme }) => theme.colors.red10};
`;
