import React from 'react';
import { Redirect } from 'react-router-dom';
import { PermissionKey } from './permissions';
import useCanUser from './useCanUser';

const withPermission = <P extends object>(
  Component: React.ComponentType<P>,
  permission: PermissionKey
): React.FC<P> => (props: P): JSX.Element => {
  const canUser = useCanUser();

  return canUser(permission) ? <Component {...props} /> : <Redirect to="/" />;
};

export default withPermission;
