import { useTranslation } from 'react-i18next';
import { QueryParams } from 'domains/transaction/pages/GlobalTransactionsPage';
import { Chip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { getFilterChipText, PageFilterChips } from 'layout';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
}

const FilterChips = ({ params, selectedFiltersCount, setParam }: Props) => {
  const { t } = useTranslation();

  if (!selectedFiltersCount) return null;

  return (
    <>
      <PageFilterChips>
        {!!params.receipt && (
          <Chip
            label={getFilterChipText(
              t(`transactionReceiptStatuses.${params.receipt}`),
              1,
              t('filters.receipts')
            )}
            onDelete={() => setParam('receipt', '')}
          />
        )}
      </PageFilterChips>
    </>
  );
};

export default FilterChips;
