export const downloadFileUsingAnchorTag = (
  filename: string,
  objectURL: string
) => {
  const el = document.createElement('a');
  el.href = objectURL;
  el.setAttribute('download', filename);
  el.style.display = 'none';
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};

/** @deprecated use downloadResponseAsFile instead */
export const downloadTxtFile = (filename: string, text: string) => {
  const base64 = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text);
  downloadFileUsingAnchorTag(filename, base64);
};

/** @deprecated use downloadResponseAsFile instead */
export const downloadPdfFile = (filename: string, bytes: string) => {
  const objectURL = URL.createObjectURL(
    new Blob([bytes], { type: 'application/pdf' })
  );
  downloadFileUsingAnchorTag(filename, objectURL);
};

/** @deprecated use downloadResponseAsFile instead */
export const downloadZipFile = (filename: string, bytes: string) => {
  const objectURL = URL.createObjectURL(
    new Blob([bytes], { type: 'application/zip' })
  );
  downloadFileUsingAnchorTag(filename, objectURL);
};
