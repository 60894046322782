import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import { Money } from 'services/constants';
import { formatMoney } from 'services/utils';

interface FormatMoneyProps {
  value: Money;
  fractionalPart?: boolean;
  positiveSign?: boolean;
}

const FormatMoney = ({
  value,
  fractionalPart = false,
  positiveSign = false,
}: FormatMoneyProps) => {
  const { i18n } = useTranslation();
  const currencyDisplay = useCurrencyDisplayMode();

  return (
    <>
      {formatMoney(value, i18n.language, {
        currencyDisplay,
        fractionalPart,
        positiveSign,
      })}
    </>
  );
};

export default FormatMoney;
