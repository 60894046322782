import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { DataItemRow } from 'domains/creditAndCompliance/components';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Box, EditButton, Grid, Typography } from 'elements';
import { AccountGroup } from 'services/constants';
import { getYesNoFromBoolean } from 'services/utils';
import DataItemCell from './DataItemCell';
import EditLimitSettingsDialog from './EditLimitSettingsDialog';

const LimitSettingsSection = () => {
  const { t } = useTranslation();
  const {
    state: { defaultCardAccount },
  } = useGlobalState();
  const {
    state: { creditAssessment },
  } = useCreditAndComplianceContext();
  const limits = creditAssessment!.limits;

  const [
    isEditLimitSettingsDialogOpen,
    setIsEditLimitSettingsDialogOpen,
  ] = useState(false);

  const showAdditionalLimitFields = [
    AccountGroup.vgPliantOy,
    AccountGroup.vgTpml,
  ].includes(defaultCardAccount!.accountGroup.value);

  return (
    <>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        py={1}
      >
        <Typography mr={1} variant="h6">
          {t('int.limitSettingsSection.title')}
        </Typography>

        <EditButton onClick={() => setIsEditLimitSettingsDialogOpen(true)} />
      </Box>

      <Grid container py={3} rowSpacing={1}>
        <Grid item xs={12} columnSpacing={4} display="flex" marginBottom={2}>
          <Grid item minWidth={232} />
          <Grid item>
            <Typography
              color="textSecondary"
              component="p"
              marginRight={4}
              minWidth={128}
              variant="caption"
            >
              {t('int.limitSettingsSection.current')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              component="p"
              marginRight={4}
              minWidth={128}
              variant="caption"
            >
              {t('int.limitSettingsSection.max')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              component="p"
              marginRight={4}
              minWidth={128}
              variant="caption"
            >
              {t('int.limitSettingsSection.peak')}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} display="flex" marginBottom={2} position="relative">
          <Grid item>
            <Typography variant="body1" minWidth={232}>
              {t('int.limitSettingsSection.orgLimit')}
            </Typography>
          </Grid>
          <DataItemCell
            variant="body1"
            updatedAt={limits.limit.updatedAt}
            updatedBy={limits.limit.updatedBy}
            value={<FormatMoney value={limits.limit.value} />}
          />
          <DataItemCell
            variant="body1"
            updatedAt={limits.maxLimit.updatedAt}
            updatedBy={limits.maxLimit.updatedBy}
            value={<FormatMoney value={limits.maxLimit.value} />}
          />
          <DataItemCell
            variant="body1"
            updatedAt={limits.peakLimit.updatedAt}
            updatedBy={limits.peakLimit.updatedBy}
            value={<FormatMoney value={limits.peakLimit.value} />}
          />
        </Grid>

        {showAdditionalLimitFields && (
          <>
            <Grid item xs={12} display="flex" position="relative">
              <Grid item>
                <Typography minWidth={232} variant="body2">
                  {t('int.limitSettingsSection.standard')}
                </Typography>
              </Grid>
              <DataItemCell
                updatedAt={limits.limitStandardCollateral.updatedAt}
                updatedBy={limits.limitStandardCollateral.updatedBy}
                value={
                  <FormatMoney value={limits.limitStandardCollateral.value} />
                }
              />
              <DataItemCell
                updatedAt={limits.maxLimitStandardCollateral.updatedAt}
                updatedBy={limits.maxLimitStandardCollateral.updatedBy}
                value={
                  <FormatMoney
                    value={limits.maxLimitStandardCollateral.value}
                  />
                }
              />
              <DataItemCell
                updatedAt={limits.peakLimitStandardCollateral.updatedAt}
                updatedBy={limits.peakLimitStandardCollateral.updatedBy}
                value={
                  <FormatMoney
                    value={limits.peakLimitStandardCollateral.value}
                  />
                }
              />
            </Grid>

            <Grid item xs={12} display="flex" position="relative">
              <Grid item>
                <Typography minWidth={232} variant="body2">
                  {t('int.limitSettingsSection.extended')}
                </Typography>
              </Grid>
              <DataItemCell
                updatedAt={limits.limitExtendedCollateral.updatedAt}
                updatedBy={limits.limitExtendedCollateral.updatedBy}
                value={
                  <FormatMoney value={limits.limitExtendedCollateral.value} />
                }
              />
              <DataItemCell
                updatedAt={limits.maxLimitExtendedCollateral.updatedAt}
                updatedBy={limits.maxLimitExtendedCollateral.updatedBy}
                value={
                  <FormatMoney
                    value={limits.maxLimitExtendedCollateral.value}
                  />
                }
              />
              <DataItemCell
                updatedAt={limits.peakLimitExtendedCollateral.updatedAt}
                updatedBy={limits.peakLimitExtendedCollateral.updatedBy}
                value={
                  <FormatMoney
                    value={limits.peakLimitExtendedCollateral.value}
                  />
                }
              />
            </Grid>

            <Grid item xs={12} display="flex" position="relative">
              <Grid item>
                <Typography minWidth={232} variant="body2">
                  {t('int.limitSettingsSection.full')}
                </Typography>
              </Grid>
              <DataItemCell
                updatedAt={limits.limitFullCollateral.updatedAt}
                updatedBy={limits.limitFullCollateral.updatedBy}
                value={<FormatMoney value={limits.limitFullCollateral.value} />}
              />
              <DataItemCell
                updatedAt={limits.maxLimitFullCollateral.updatedAt}
                updatedBy={limits.maxLimitFullCollateral.updatedBy}
                value={
                  <FormatMoney value={limits.maxLimitFullCollateral.value} />
                }
              />
              <DataItemCell
                updatedAt={limits.peakLimitFullCollateral.updatedAt}
                updatedBy={limits.peakLimitFullCollateral.updatedBy}
                value={
                  <FormatMoney value={limits.peakLimitFullCollateral.value} />
                }
              />
            </Grid>
          </>
        )}

        <Grid item container direction="column" spacing={2} py={3}>
          <DataItemRow
            label={t('int.limitSettingsSection.gracePeriod')}
            updatedAt={null}
            updatedBy={null}
            value={
              typeof limits.gracePeriodInMonths === 'number'
                ? t('int.limitSettingsSection.month', {
                    count: limits.gracePeriodInMonths,
                  })
                : null
            }
          />
          <DataItemRow
            label={t('int.limitSettingsSection.limitIncreaseAllowed')}
            updatedAt={limits.limitIncreaseAllowed.updatedAt}
            updatedBy={limits.limitIncreaseAllowed.updatedBy}
            value={getYesNoFromBoolean(limits.limitIncreaseAllowed.value, t)}
          />
        </Grid>
      </Grid>

      <EditLimitSettingsDialog
        open={isEditLimitSettingsDialogOpen}
        onClose={() => setIsEditLimitSettingsDialogOpen(false)}
        showAdditionalLimitFields={showAdditionalLimitFields}
      />
    </>
  );
};

export default LimitSettingsSection;
