import {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SyncSetupDialogProps } from './index';

interface CodatStepperContextType {
  actions: {
    onClose: () => void;
    onNext: () => void;
    onPrev: () => void;
  };
  state: {
    step: number;
  };
}

const CodatStepperContext = createContext<CodatStepperContextType>({
  actions: {} as CodatStepperContextType['actions'],
  state: {
    step: -1,
  },
});

const CodatStepperContextProvider = ({
  children,
  onClose,
}: {
  children: ReactNode;
  onClose: () => void;
}) => {
  const [step, setStep] = useState(-1);

  const onNext: CodatStepperContextType['actions']['onNext'] = useCallback(() => {
    setStep((prevStep) => prevStep + 1);
  }, [step]);

  const onPrev: CodatStepperContextType['actions']['onPrev'] = useCallback(() => {
    setStep((prevStep) => prevStep - 1);
  }, [step]);

  const value = useMemo(
    () => ({
      actions: {
        onClose,
        onNext,
        onPrev,
      },
      state: { step },
    }),
    [onNext, onPrev, step]
  );

  return (
    <CodatStepperContext.Provider value={value}>
      {children}
    </CodatStepperContext.Provider>
  );
};

const useCodatStepperContext = () => {
  const context = useContext(CodatStepperContext);
  return context;
};

const withCodatStepperContext = (
  Component: ComponentType<SyncSetupDialogProps>
) => (props: SyncSetupDialogProps) => {
  return (
    <CodatStepperContextProvider onClose={props.onClose}>
      <Component {...props} />
    </CodatStepperContextProvider>
  );
};

export { useCodatStepperContext, withCodatStepperContext };
