import {
  adminPaths,
  AdminPathsKeys,
  internalOrgPaths,
  InternalOrgPathsKeys,
} from 'components/App';
import { env } from 'env';

export type PathsKeys = AdminPathsKeys | InternalOrgPathsKeys;

export const getPath = (pathId: PathsKeys) =>
  env.REACT_APP_BUILD_TARGET === 'external'
    ? adminPaths[pathId as AdminPathsKeys]
    : internalOrgPaths[pathId as InternalOrgPathsKeys];
