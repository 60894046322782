import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBadge } from 'elements';
import {
  organizationOnboardingStatuses,
  OrganizationStatus,
} from 'services/constants';

interface OrganizationStatusBadgeProps {
  status: OrganizationStatus;
}

const getColorByStatus = (status: OrganizationStatus) => {
  if (organizationOnboardingStatuses.includes(status)) return 'warning';
  return status === OrganizationStatus.active ? 'success' : 'primary';
};

const OrganizationStatusBadge = ({ status }: OrganizationStatusBadgeProps) => {
  const { t } = useTranslation();

  return (
    <StatusBadge
      label={t(`orgStatuses.${status}`)}
      variant="filled"
      color={getColorByStatus(status)}
    />
  );
};

export default OrganizationStatusBadge;
