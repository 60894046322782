import { useTranslation } from 'react-i18next';
import {
  CardSection,
  DeclinedTransactionInfoSection,
  OrganizationSection,
  TransactionDetailsHeader,
  TransactionReceipts,
} from 'domains/transaction/components';
import { Box, Typography } from 'elements';
import { DetailsDrawerContent, DetailsDrawerHeader } from 'layout';
import { Receipt, Transaction, TransactionStatus } from 'services/constants';

interface Props {
  transaction: Transaction;
  receipts: Receipt[];
  onUpdate: () => void;
  onRefresh: () => void;
  isReceiptPreviewDialogOpen: boolean;
  onReceiptPreviewDialogOpen: () => void;
}

const GlobalTransactionDetailsContent = ({
  transaction,
  receipts,
  onUpdate,
  onRefresh,
  isReceiptPreviewDialogOpen,
  onReceiptPreviewDialogOpen,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailsDrawerHeader pb={2}>
        <TransactionDetailsHeader transaction={transaction} />
      </DetailsDrawerHeader>

      <DetailsDrawerContent pb={4}>
        {transaction.status === TransactionStatus.declined && (
          <DeclinedTransactionInfoSection transaction={transaction} />
        )}

        <Box p={3}>
          <Typography variant="overline" component="div" mb={1.5}>
            {t('transactionDetails.txDetailsBlockTitle')}
          </Typography>

          <OrganizationSection transaction={transaction} />

          <CardSection transaction={transaction} isAdminApp />
        </Box>

        {(transaction.receiptUploadEnabled || !!receipts.length) && (
          <TransactionReceipts
            isGlobalTxPage
            key={transaction.transactionId}
            transaction={transaction}
            receipts={receipts}
            onUpdate={onUpdate}
            onRefresh={onRefresh}
            isReceiptPreviewDialogOpen={isReceiptPreviewDialogOpen}
            onReceiptPreviewDialogOpen={onReceiptPreviewDialogOpen}
          />
        )}
      </DetailsDrawerContent>
    </>
  );
};

export default GlobalTransactionDetailsContent;
