import React from 'react';
import { CompletedBlock, StyledValidationCheckCircleIcon } from './style';

interface CompletedMessageBlockProps {
  children: React.ReactNode;
  $margin?: string;
}

const CompletedMessageBlock = ({
  children,
  $margin,
}: CompletedMessageBlockProps) => {
  return (
    <CompletedBlock $margin={$margin}>
      <StyledValidationCheckCircleIcon />
      <div>{children}</div>
    </CompletedBlock>
  );
};

export default CompletedMessageBlock;
