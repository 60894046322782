import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { DataGridCell, Typography } from 'elements';
import {
  ProjectStatus,
  ReviewFlagReason,
  TransactionReviewStatus,
} from 'services/constants';

interface Props {
  projectStatus: ProjectStatus;
  projectId: string | null;
  reviewStatus: TransactionReviewStatus | null;
  reviewFlagReason: ReviewFlagReason | null;
}

const ProjectCell = ({
  projectId,
  projectStatus,
  reviewStatus,
  reviewFlagReason,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { projects },
  } = useGlobalState();

  if (
    reviewStatus === TransactionReviewStatus.flagged &&
    reviewFlagReason === ReviewFlagReason.privateExpense
  ) {
    return <DataGridCell>—</DataGridCell>;
  }

  if (projectStatus === ProjectStatus.multiple)
    return (
      <DataGridCell sx={{ textTransform: 'uppercase' }}>
        {t('exportPage.multiple')}
      </DataGridCell>
    );
  if (projectStatus === ProjectStatus.missing)
    return <DataGridCell>—</DataGridCell>;

  const selectedProject = projects?.find((item) => item.id === projectId);
  if (!selectedProject) return null;

  return (
    <DataGridCell>
      {selectedProject.name}
      <Typography
        component="div"
        variant="caption"
        color="textSecondary"
        noWrap
      >
        {selectedProject.costUnit}
      </Typography>
    </DataGridCell>
  );
};

export default ProjectCell;
