import React, { useCallback, useLayoutEffect, useState } from 'react';
import { FormikHelpers, useFormikContext } from 'formik';
import { isMatch, pick } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useActiveTeams, useGlobalState } from 'context/GlobalState';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemIcon,
  LoaderWithOverlay,
  MenuItem,
  PlusIcon,
  QuestionIcon,
  Select,
  TextField,
  Tooltip,
  XIcon,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import useUrls from 'hooks/useUrls';
import { MemberDetails, memberTitles, Team } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  getGenericErrorMsg,
  trimObjValues,
  validateCardholderFullName,
} from 'services/utils';
import getDiffBetweenTeams from './getDiffBetweenTeams';
import { AllFormikValues, FormikHandlers } from './index';

interface Props {
  member: MemberDetails;
  managerTeam: Team | undefined;
  onClose: () => void;
  onSuccess: (member: MemberDetails) => void;
  setFormikHandlers: (value: React.SetStateAction<FormikHandlers>) => void;
}

const VALUE_NAMES_ON_SUBMIT = [
  'title',
  'firstName',
  'lastName',
  'teamIds',
] as const;

const EditMemberStep = ({
  member,
  managerTeam,
  onSuccess,
  onClose,
  setFormikHandlers,
}: Props) => {
  const formik = useFormikContext<AllFormikValues>();
  const { t } = useTranslation();
  const { SUPPORT_REQUEST_URL } = useUrls();
  const { isExternalApp } = useCurrentApp();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const {
    state: { featureModules },
  } = useGlobalState();
  const teams = useActiveTeams();
  const [isTeamSelectOpen, setIsTeamSelectOpen] = useState<boolean>(false);

  const isTeamsSelectVisible =
    featureModules.TEAMS &&
    canUser('member-teams:change', teams, managerTeam, member);

  const onSubmit = useCallback(
    async (
      allValues: AllFormikValues,
      { setSubmitting }: FormikHelpers<AllFormikValues>
    ) => {
      const { teamIds, ...rest } = {
        ...pick(allValues, VALUE_NAMES_ON_SUBMIT),
      };

      try {
        const memberData = trimObjValues(rest);
        const doUpdateMemberCall = !isMatch(member, memberData);

        const {
          teamsToAssign,
          teamsToUnassign,
          newMemberTeams,
        } = getDiffBetweenTeams(teamIds, member.teams, teams);
        const doUpdateMemberTeamsCall =
          !!teamsToAssign.length || !!teamsToUnassign.length;

        await Promise.all([
          doUpdateMemberCall
            ? api.updateMember(member.id, {
                organizationId: member.organizationId,
                ...memberData,
                title: memberData.title || null,
              })
            : undefined,
          doUpdateMemberTeamsCall
            ? api.updateMemberTeams(member.id, {
                organizationId: member.organizationId,
                teamsToAssign,
                teamsToUnassign,
              })
            : undefined,
        ]);

        if (!mounted.current) return;
        onSuccess({
          ...member,
          ...memberData,
          teams: newMemberTeams,
        });
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
    [member]
  );

  useLayoutEffect(() => {
    setFormikHandlers({ onSubmit });
  }, [onSubmit]);

  const {
    firstNameNotPrintableError,
    lastNameNotPrintableError,
    fullNameTooLongError,
  } = validateCardholderFullName(
    formik.values.firstName,
    formik.values.lastName
  );

  const isSubmitDisabled =
    !formik.values.firstName.trim() ||
    !formik.values.lastName.trim() ||
    !!firstNameNotPrintableError ||
    !!lastNameNotPrintableError ||
    !!fullNameTooLongError ||
    formik.isSubmitting;

  const canUserEditMemberDetails = canUser(
    'member-details:change',
    member,
    managerTeam
  );

  return (
    <>
      <DialogTitle>{t('editMemberDialog.editMemberDetails')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="edit-member-form" noValidate>
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                disabled={
                  !canUserEditMemberDetails ||
                  isExternalApp ||
                  formik.isSubmitting
                }
              >
                <InputLabel id="member-title-select-label">
                  {t('editMemberDialog.title')}
                </InputLabel>
                <Select<string>
                  labelId="member-title-select-label"
                  renderValue={(selected) => t(`memberTitles.${selected}`)}
                  {...formik.getFieldProps('title')}
                >
                  <MenuItem value="">-</MenuItem>
                  {memberTitles.map((title) => (
                    <MenuItem key={title} value={title}>
                      {t(`memberTitles.${title}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('firstName')}
                label={t('editMemberDialog.firstName')}
                inputProps={{ maxLength: 50 }}
                disabled={
                  !canUserEditMemberDetails ||
                  isExternalApp ||
                  formik.isSubmitting
                }
                error={!!firstNameNotPrintableError || !!fullNameTooLongError}
                helperText={firstNameNotPrintableError}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('lastName')}
                label={t('editMemberDialog.lastName')}
                inputProps={{ maxLength: 50 }}
                disabled={
                  !canUserEditMemberDetails ||
                  isExternalApp ||
                  formik.isSubmitting
                }
                error={!!lastNameNotPrintableError || !!fullNameTooLongError}
                helperText={lastNameNotPrintableError}
              />
            </Grid>
            {isExternalApp && (
              <Grid item>
                <FormHelperText>
                  <Trans
                    i18nKey="editMemberDialog.nameChangeDisabledTip"
                    components={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          style={{ color: 'inherit' }}
                          href={SUPPORT_REQUEST_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                    }}
                  />
                </FormHelperText>
              </Grid>
            )}
            {isTeamsSelectVisible && (
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  disabled={!canUserEditMemberDetails || formik.isSubmitting}
                >
                  <InputLabel id="team-select-label">
                    {t('editMemberDialog.team')}
                  </InputLabel>
                  <Select<string[]>
                    multiple
                    open={isTeamSelectOpen}
                    onClose={() => setIsTeamSelectOpen(false)}
                    onOpen={() => setIsTeamSelectOpen(true)}
                    labelId="team-select-label"
                    renderValue={(selected) =>
                      selected
                        .map((teamId) => {
                          const team = teams.find((item) => item.id === teamId);
                          return team?.name;
                        })
                        .join(', ')
                    }
                    {...formik.getFieldProps('teamIds')}
                  >
                    {teams.map((team) => {
                      const memberTeam = member.teams.find(
                        (item) => item.teamId === team.id
                      );
                      const isRemovalDisabled =
                        !!memberTeam && memberTeam.hasActiveCards;

                      const isTeamSelected = formik.values.teamIds.find(
                        (teamId) => teamId === team.id
                      );
                      return (
                        <MenuItem
                          key={team.id}
                          value={team.id}
                          disabled={isRemovalDisabled}
                          onClick={() => setIsTeamSelectOpen(false)}
                        >
                          <ListItemIcon>
                            {isRemovalDisabled && (
                              <Tooltip
                                title={t('tooltips.removalFromTeamDisabled')!}
                              >
                                <QuestionIcon
                                  onClick={(e) => e.stopPropagation()}
                                  style={{
                                    pointerEvents: 'auto',
                                    color: 'text.secondary',
                                  }}
                                  fontSize="small"
                                />
                              </Tooltip>
                            )}
                            {isTeamSelected && !isRemovalDisabled && (
                              <XIcon fontSize="small" />
                            )}
                            {!isTeamSelected && <PlusIcon fontSize="small" />}
                          </ListItemIcon>
                          {team.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>

          {!!fullNameTooLongError && (
            <FormHelperText error>{fullNameTooLongError}</FormHelperText>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        {isExternalApp && !isTeamsSelectVisible ? (
          <Button onClick={onClose}>{t('common.button.close')}</Button>
        ) : (
          <>
            <Button variant="text" onClick={onClose}>
              {t('common.button.cancel')}
            </Button>
            <Button
              disabled={isSubmitDisabled}
              form="edit-member-form"
              type="submit"
            >
              {t('editMemberDialog.save')}
            </Button>
          </>
        )}
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default EditMemberStep;
