import { Components, Theme } from '@mui/material';

const MuiTableOverrides: Components<Theme> = {
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.variables.typography.body2,
        borderColor: theme.palette.divider,
      }),
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiTableCell-head': {
          ...theme.variables.components['table-header'],
          textTransform: 'uppercase',
        },
        '& .MuiTableSortLabel-root': {
          ...theme.variables.components['table-header'],
        },
      }),
    },
  },
};

export default MuiTableOverrides;
