import { useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import moment from 'moment/moment';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer } from 'layout';
import {
  OrganizationPartner,
  Partner,
  PartnerAuthStatus,
  PartnerScope,
  PartnerStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import ConnectPartnerDialog from './ConnectPartnerDialog';

export const getSortedPartnersBySource = (partners: Partner[] | null) => {
  if (!partners?.length) return [];

  const shownPartners = partners.filter(
    (item) =>
      [
        PartnerScope.sync,
        PartnerScope.embeddedWallet,
        PartnerScope.fullyEmbedded,
        PartnerScope.customer,
        PartnerScope.whitelabel,
        PartnerScope.logoCard,
      ].includes(item.partnerScope) && item.status !== PartnerStatus.inactive
  );

  return sortBy(shownPartners!, (v) => v.name.toLowerCase());
};

const getVisiblePartners = (
  partners: Partner[] | null,
  activeOrgPartners: OrganizationPartner[]
) => {
  const sortedPartners = getSortedPartnersBySource(partners);
  const activePartnerIds = activeOrgPartners.map(
    (partner) => partner.partnerId
  );

  // remove already active parnters and PLIANT_INTERNAL
  return sortedPartners.filter(
    (partner) =>
      partner.partnerScope !== PartnerScope.pliantInternal &&
      !activePartnerIds.includes(partner.partnerId)
  );
};

interface Props {
  organizationPartners: OrganizationPartner[] | null;
  onOrgPartnerUpdate: (partners: OrganizationPartner[]) => void;
}

const PartnerAccessSection = ({
  organizationPartners,
  onOrgPartnerUpdate,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, partners },
  } = useGlobalState();
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState(false);
  const [
    partnerToRevoke,
    setPartnerToRevoke,
  ] = useState<OrganizationPartner | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const activeOrgPartners = useMemo(
    () =>
      organizationPartners?.filter(
        (partner) => partner.authStatus === PartnerAuthStatus.active
      ) || [],
    [organizationPartners]
  );

  const availablePartnersToConnect = getVisiblePartners(
    partners,
    activeOrgPartners
  );

  const refetchOrgPartners = async () => {
    try {
      setIsLoading(true);
      const { data: organizationPartners } = await api.getOrganizationPartners(
        organization!.id
      );
      if (!mounted.current) return;
      setIsLoading(false);
      onOrgPartnerUpdate(organizationPartners);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const revokePartnerConnection = async () => {
    try {
      setIsLoading(true);
      await api.unAuthorizePartner(
        partnerToRevoke!.partnerId,
        organization!.id
      );
      if (!mounted.current) return;
      setPartnerToRevoke(null);
      refetchOrgPartners();
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <ContentContainer pt={5} position="relative">
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h6" mb={1}>
              {t('int.generalTermsPage.partnerAccessSection.title')}
            </Typography>
            <Typography>
              {t('int.generalTermsPage.partnerAccessSection.description')}
            </Typography>
          </Box>

          {!!availablePartnersToConnect.length &&
            canUser('organization-partner-connection:change') && (
              <Button
                onClick={() => setIsConnectDialogOpen(true)}
                disabled={isLoading}
                sx={{ flexShrink: 0 }}
              >
                {t('int.generalTermsPage.partnerAccessSection.connectBtn')}
              </Button>
            )}
        </Box>

        {activeOrgPartners?.length ? (
          <List sx={{ p: 0 }}>
            {activeOrgPartners.map((partner, index) => (
              <ListItem
                key={String(partner.partnerId)}
                divider={index !== activeOrgPartners.length - 1}
                secondaryAction={
                  <Button
                    variant="outlined"
                    onClick={() => setPartnerToRevoke(partner)}
                    disabled={
                      isLoading ||
                      !canUser('organization-partner-connection:change')
                    }
                  >
                    {t(
                      'int.generalTermsPage.partnerAccessSection.revokeAccessBtn'
                    )}
                  </Button>
                }
                sx={{ mt: 2 }}
              >
                <ListItemText
                  primary={partner.partnerName}
                  secondary={
                    partner.changedBy &&
                    partner.createdAt &&
                    t('int.common.lastEditedBy', {
                      name: partner.changedBy,
                      time: moment(partner.createdAt).format(
                        'DD MMM YYYY, HH:mm'
                      ),
                    })
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography mt={2} color="text.secondary">
            {t('int.generalTermsPage.partnerAccessSection.nothingFound')}
          </Typography>
        )}

        <LoaderWithOverlay loading={isLoading} />
      </ContentContainer>

      <ConnectPartnerDialog
        availablePartners={availablePartnersToConnect}
        open={isConnectDialogOpen}
        onSuccess={() => {
          setIsConnectDialogOpen(false);
          refetchOrgPartners();
        }}
        onClose={() => setIsConnectDialogOpen(false)}
      />

      <ConfirmDialog
        title={t('int.revokePartnerConnectDialog.title')}
        description={
          <Trans
            i18nKey="int.revokePartnerConnectDialog.description"
            values={{
              partnerName: partnerToRevoke?.partnerName,
              orgName: organization!.name,
            }}
            components={{
              p: <p />,
            }}
          />
        }
        open={!!partnerToRevoke}
        onClose={() => setPartnerToRevoke(null)}
        onSuccess={revokePartnerConnection}
        loading={isLoading}
        confirmButtonProps={{
          children: t('int.revokePartnerConnectDialog.revokeAccessButton'),
          color: 'error',
        }}
      />
    </>
  );
};

export default PartnerAccessSection;
