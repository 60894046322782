import { CSSProperties } from 'react';
import { PaletteMode, useTheme } from '@mui/material';

interface Props {
  mode?: PaletteMode;
  style?: CSSProperties;
}

const AppLogo = ({ mode = 'light', ...props }: Props = {}) => {
  const theme = useTheme();
  const { Component, style } =
    mode === 'light' ? theme.logoLightMode : theme.logoDarkMode;

  return (
    <Component style={{ verticalAlign: 'top', ...style, ...props.style }} />
  );
};

export default AppLogo;
