import { Components, Theme } from '@mui/material';

const MuiTabsOverrides: Components<Theme> = {
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: theme.spacing(5.25),
        // changes for all horizontal tabs
        '.MuiTabs-flexContainer:not(.MuiTabs-flexContainerVertical)': {
          '.MuiTab-root': {
            minHeight: theme.spacing(5.25),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          },
        },
      }),
    },
    variants: [
      {
        props: { variant: 'standard' },
        style: ({ theme }) => ({
          // change width and y-padding for only standart horizontal tabs
          '.MuiTabs-flexContainer:not(.MuiTabs-flexContainerVertical)': {
            '.MuiTab-root': {
              minWidth: 'auto',
              padding: theme.spacing(1, 0),

              '&+.MuiTab-root': {
                marginLeft: theme.spacing(4),
              },
            },
          },
        }),
      },
    ],
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        textTransform: 'none',
        ...theme.variables.components['button-medium'],
      }),
    },
  },
};

export default MuiTabsOverrides;
