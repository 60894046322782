import { useTranslation } from 'react-i18next';
import { MerchantAvatar } from 'domains/merchant/components';
import {
  Box,
  IconButton,
  PencilSimpleIcon,
  StatusBadge,
  TableCell,
  TableRow,
  Typography,
} from 'elements';
import {
  AccountingItemStatus,
  MerchantCategory,
  Subcategory,
} from 'services/constants';
import useCanUser from 'services/rbac/useCanUser';

interface Props {
  category: MerchantCategory;
  subcategoriesByCategory: Subcategory[];
  areItemsReadOnly: boolean;
  onUpdate: (subcategory: Subcategory) => void;
}

const SubcategoriesTable = ({
  category,
  subcategoriesByCategory,
  areItemsReadOnly,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();

  return (
    <>
      <TableRow key={category}>
        <TableCell sx={{ border: 0 }}>
          <Box display="flex" alignItems="center">
            <MerchantAvatar category={category} />
            <Typography variant="subtitle1" ml={1}>
              {t(`merchantCategories.${category}`)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell sx={{ border: 0 }} />
        <TableCell sx={{ border: 0 }} width={72} />
      </TableRow>

      {subcategoriesByCategory?.map((item) => (
        <TableRow key={item.id}>
          <TableCell sx={{ border: 0, pl: (theme) => theme.spacing(9) }}>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                wordBreak: 'break-word',
              }}
            >
              {item.name}
              {item.status === AccountingItemStatus.inactive && (
                <StatusBadge
                  color="error"
                  sx={{ ml: 1, flexShrink: 0 }}
                  label={t('categoriesSubPage.inactiveBadgeLabel')}
                />
              )}
            </Box>
          </TableCell>
          <TableCell sx={{ border: 0 }}>{item.number}</TableCell>

          {canUser('subcategory:update') && !areItemsReadOnly && (
            <TableCell width={72} sx={{ border: 0 }}>
              <IconButton onClick={() => onUpdate(item)}>
                <PencilSimpleIcon />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default SubcategoriesTable;
