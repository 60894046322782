import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import { Box, Link } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const DeactivateOrganizationSection = () => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const updatedOrganization = await api.deactivateOrganization(
        organization!.id
      );
      if (!mounted.current) return;
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          organization: updatedOrganization,
        },
      });
      enqueueSnackbar(t('int.deactivateOrganizationButton.successToast'), {
        variant: 'success',
      });
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    } finally {
      if (mounted.current) setIsSubmitting(false);
    }
  };

  return (
    <>
      <Box
        alignItems="center"
        borderTop="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        justifyContent="center"
        mt={4}
        py={3}
      >
        <Link
          color="textSecondary"
          component="button"
          disabled={isSubmitting}
          onClick={() => setIsConfirmDialogOpen(true)}
          variant="body2"
        >
          {t('int.deactivateOrganizationSection.button')}
        </Link>
      </Box>

      <ConfirmDialog
        title={t('int.deactivateOrganizationSection.confirmationDialogTitle')}
        description={
          <Trans
            i18nKey="int.deactivateOrganizationSection.confirmationDialogDescription"
            components={{ b: <b />, br: <br /> }}
            values={{ orgName: organization!.name, orgId: organization!.id }}
          />
        }
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onSuccess={onSubmit}
        loading={isSubmitting}
      />
    </>
  );
};

export default DeactivateOrganizationSection;
