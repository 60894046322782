import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useQueryParams from 'hooks/useQueryParams';
import useSetQueryParam from 'hooks/useSetQueryParam';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { downloadResponseAsFile } from 'services/utils';

enum SupportedDocumentTypes {
  creditCardTransactionReport = 'credit-card-transaction-report',
}

const useDownloadBillingFileFromQueryParams = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const queryParams = useQueryParams();
  const setQueryParam = useSetQueryParam();

  const downloadCreditCardTransactionReport = async (statementId: string) => {
    try {
      enqueueSnackbar(t('billingPage.fileDownloadingStartedMessage'), {
        variant: 'info',
        persist: true,
      });
      const statement = await api.getOrganizationAccountStatement(statementId);
      if (!statement.cardStatementZipDocumentId) {
        closeSnackbar();
        enqueueSnackbar(t('billingPage.fileDownloadingErrorMessage'), {
          variant: 'error',
        });
        setQueryParam([
          ['statementId', null],
          ['documentType', null],
        ]);
        return;
      }
      const response = await api.downloadFile(
        `/documents/${statement.cardStatementZipDocumentId}/content`,
        {},
        { responseType: 'arraybuffer' }
      );
      downloadResponseAsFile(response);
      setQueryParam([
        ['statementId', null],
        ['documentType', null],
      ]);
      closeSnackbar();
    } catch (error) {
      enqueueSnackbar(t('billingPage.fileDownloadingErrorMessage'), {
        variant: 'error',
      });
      setQueryParam([
        ['statementId', null],
        ['documentType', null],
      ]);
      logError(error);
    }
  };

  useEffect(() => {
    if (
      queryParams.documentType ===
        SupportedDocumentTypes.creditCardTransactionReport &&
      queryParams.statementId
    ) {
      downloadCreditCardTransactionReport(queryParams.statementId);
    }
  }, [queryParams.statementId, queryParams.documentType]);
};

export default useDownloadBillingFileFromQueryParams;
