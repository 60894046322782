import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardDetailsDialog,
  ReplaceCardDialog,
  UpdateCardVelocityGroupDialog,
} from 'domains/card/dialogs';
import {
  ArrowLineUpIcon,
  Button,
  InfoIcon,
  ListItemIcon,
  ListItemText,
  LockIcon,
  LockOpenIcon,
  Menu,
  MenuItem,
  SlidersHorizontalIcon,
  SwapIcon,
} from 'elements';
import useCardLock from 'hooks/useCardLock';
import { Card } from 'services/constants';

interface Props {
  card: Card;
  onUpdate: (card: Card) => void;
}

interface State {
  isDetailsDialogOpen: boolean;
  isManagingLimitDialogOpen: boolean;
  isReplaceCardDialogOpen: boolean;
}

const ManagePlatformFeeCardButton = ({ card, onUpdate }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [state, setState] = useState<State>({
    isDetailsDialogOpen: false,
    isManagingLimitDialogOpen: false,
    isReplaceCardDialogOpen: false,
  });
  const {
    lockCard,
    unlockCard,
    isCardLocked,
    isLockingOrUnlocking,
  } = useCardLock((card: Card) => {
    onUpdate(card);
  }, true);

  const open = Boolean(anchorEl);

  const lockOrUnlockCard = () => {
    if (isLockingOrUnlocking) return;
    isCardLocked(card!) ? unlockCard(card!.cardId) : lockCard(card!.cardId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        sx={{ ml: 2 }}
        variant="outlined"
        startIcon={<SlidersHorizontalIcon />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {t(
          'int.subscriptionPlanSettingsSubPage.paymentDetailsSection.manageCard'
        )}
      </Button>

      {/* todo: replace with menu component when we have a local one */}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            setState((prevState) => ({
              ...prevState,
              isDetailsDialogOpen: true,
            }));
          }}
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>
            {t(
              'int.subscriptionPlanSettingsSubPage.paymentDetailsSection.showDetails'
            )}
          </ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            lockOrUnlockCard();
          }}
        >
          <ListItemIcon>
            {isCardLocked(card) ? <LockOpenIcon /> : <LockIcon />}
          </ListItemIcon>
          <ListItemText>
            {isCardLocked(card)
              ? t(
                  'int.subscriptionPlanSettingsSubPage.paymentDetailsSection.unlockCard'
                )
              : t(
                  'int.subscriptionPlanSettingsSubPage.paymentDetailsSection.lockCard'
                )}
          </ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            setState((prevState) => ({
              ...prevState,
              isManagingLimitDialogOpen: true,
            }));
          }}
        >
          <ListItemIcon>
            <ArrowLineUpIcon />
          </ListItemIcon>
          <ListItemText>
            {t(
              'int.subscriptionPlanSettingsSubPage.paymentDetailsSection.manageLimit'
            )}
          </ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            setState((prevState) => ({
              ...prevState,
              isReplaceCardDialogOpen: true,
            }));
          }}
        >
          <ListItemIcon>
            <SwapIcon />
          </ListItemIcon>
          <ListItemText>
            {t(
              'int.subscriptionPlanSettingsSubPage.paymentDetailsSection.replaceCard'
            )}
          </ListItemText>
        </MenuItem>
      </Menu>

      <CardDetailsDialog
        card={card}
        open={state.isDetailsDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isDetailsDialogOpen: false,
          }))
        }
      />

      <UpdateCardVelocityGroupDialog
        open={state.isManagingLimitDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isManagingLimitDialogOpen: false,
          }))
        }
        onSuccess={(card: Card) => {
          onUpdate(card);
          setState((prevState) => ({
            ...prevState,
            isManagingLimitDialogOpen: false,
          }));
        }}
        card={card}
      />

      <ReplaceCardDialog
        open={state.isReplaceCardDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isReplaceCardDialogOpen: false,
          }))
        }
        card={card}
        onSuccess={(card: Card) => {
          onUpdate(card);
          setState((prevState) => ({
            ...prevState,
            isReplaceCardDialogOpen: false,
          }));
        }}
      />
    </>
  );
};

export default ManagePlatformFeeCardButton;
