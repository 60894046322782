import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  value: number;
  unit?: string;
  precision?: number;
}

const FormatDecimal = ({ value, unit = '', precision = 2 }: Props) => {
  const { i18n } = useTranslation();

  return (
    <>
      {value.toLocaleString(i18n.language, {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: precision,
      })}
      {unit && <> {unit}</>}
    </>
  );
};

export default FormatDecimal;
