import { Components, Theme } from '@mui/material';

const MuiMenuOverrides: Components<Theme> = {
  MuiMenu: {
    defaultProps: {
      MenuListProps: { dense: true },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    },
    styleOverrides: {
      list: {
        maxHeight: '40vh', // same as the default height in MUI Autocomplete dropdown menus
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.variables.components['menu-item'],
      }),
      dense: ({ theme }) => ({
        ...theme.variables.components['menu-item-dense'],
      }),
    },
  },
};

export default MuiMenuOverrides;
