import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import { SettlementStatusBadge } from 'domains/billing/components';
import {
  Alert,
  Box,
  Button,
  LoaderWithOverlay,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from 'elements';
import { Settlement } from 'services/constants';
import { getPath } from 'services/utils';

interface Props {
  settlements: Settlement[];
  hasNextPage: boolean;
  onLoadMore: () => void;
  error: unknown;
  isDrawdownAmountVisible?: boolean;
}

const OrgSettlementsList = ({
  settlements,
  hasNextPage,
  onLoadMore,
  error,
  isDrawdownAmountVisible,
}: Props) => {
  const { t } = useTranslation();

  const generateRowLink = (rowData: Settlement) => {
    return rowData.organizationId
      ? generatePath(getPath('settlementsDetails'), {
          orgId: rowData.organizationId,
          settlementId: rowData.settlementId,
        })
      : '';
  };

  if (!settlements.length) {
    return null;
  }

  return (
    <Box m={3}>
      <TableContainer>
        <Table sx={{ tableLayout: 'fixed' }} component="div">
          <TableHead component="div">
            <TableRow component="div">
              <TableCell
                sx={{ width: isDrawdownAmountVisible ? 112 : 237 }}
                component="div"
              >
                {t('int.orgSettlementsList.organization')}
              </TableCell>
              <TableCell sx={{ width: 75 }} align="right" component="div">
                {t('int.orgSettlementsList.count')}
              </TableCell>
              <TableCell sx={{ width: 130 }} align="right" component="div">
                {t('int.orgSettlementsList.amount')}
              </TableCell>
              {isDrawdownAmountVisible && (
                <TableCell sx={{ width: 130 }} align="right" component="div">
                  {t('int.orgSettlementsList.drawdown')}
                </TableCell>
              )}
              <TableCell sx={{ width: 110 }} component="div">
                {t('int.orgSettlementsList.state')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody component="div">
            {settlements.map((item) => (
              <TableRow
                key={item.settlementId}
                component={RouterLink}
                to={generateRowLink(item)}
                sx={(theme) => ({
                  textDecoration: 'none',
                  '&:hover': {
                    background: theme.palette.action.hover,
                  },
                })}
              >
                <TableCell component="div">
                  <Tooltip title={item.organizationName || ''}>
                    <Typography variant="inherit" noWrap>
                      {item.organizationName}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell component="div" align="right">
                  <Typography variant="inherit" noWrap>
                    {item.transactionsCount}
                  </Typography>
                </TableCell>
                <TableCell component="div" align="right">
                  <Typography variant="inherit" noWrap>
                    <FormatMoney value={item.settlementAmount} fractionalPart />
                  </Typography>
                </TableCell>
                {isDrawdownAmountVisible && (
                  <TableCell component="div" align="right">
                    <Typography variant="inherit" noWrap>
                      <FormatMoney value={item.drawdownAmount} fractionalPart />
                    </Typography>
                  </TableCell>
                )}
                <TableCell component="div">
                  <SettlementStatusBadge status={item.status} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {hasNextPage && !error && (
        <Box position="relative" height={52}>
          <LoaderWithOverlay loading />
        </Box>
      )}
      {hasNextPage && !!error && (
        <Alert
          sx={{ mt: 2 }}
          severity="error"
          action={
            <Button
              variant="text"
              color="error"
              size="small"
              onClick={onLoadMore}
            >
              {t('common.retry')}
            </Button>
          }
        >
          {t('errors.loadData')}
        </Alert>
      )}
    </Box>
  );
};

export default OrgSettlementsList;
