import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LabeledValue,
  MenuItem,
  Select,
  Switch,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, SettingsGroupBox } from 'layout';
import {
  ForwardingIntegrationFrequency,
  ForwardingIntegrations,
  ForwardingIntegrationStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import ChangeReceiptAutoForwardingDialog from './ChangeReceiptAutoForwardingDialog';

interface Props {
  forwardingIntegrations: ForwardingIntegrations;
  onUpdate: (integrations: ForwardingIntegrations) => void;
}

interface State {
  isAutomatedForwardingDialogOpen: boolean;
  isForwardingUpdating: boolean;
  forwardingFrequency: ForwardingIntegrationFrequency | null;
  includeCreditCardStatement: boolean | null;
  includeTransactionDetails: boolean | null;
  detailedEmailSubject: boolean | null;
}

const ReceiptForwardingGroup = ({
  forwardingIntegrations,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const partnerName = usePartnerName();
  const {
    state: { organization },
  } = useGlobalState();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    forwardingFrequency: forwardingIntegrations.forwardingFrequency || null,
    includeCreditCardStatement:
      forwardingIntegrations.includeCreditCardStatement || null,
    includeTransactionDetails:
      forwardingIntegrations.includeTransactionDetails || null,
    detailedEmailSubject: forwardingIntegrations.detailedEmailSubject || null,
    isAutomatedForwardingDialogOpen: false,
    isForwardingUpdating: false,
  });

  const deactivateAutoForwarding = async () => {
    setState((prevState) => ({ ...prevState, isForwardingUpdating: true }));
    try {
      const data = await api.updateForwardingIntegration(organization!.id, {
        forwardingEmail: forwardingIntegrations!.forwardingEmail,
        status: ForwardingIntegrationStatus.inactive,
        forwardingFrequency: forwardingIntegrations!.forwardingFrequency,
        includeCreditCardStatement: forwardingIntegrations!
          .includeCreditCardStatement,
        includeTransactionDetails: forwardingIntegrations!
          .includeTransactionDetails,
        detailedEmailSubject: forwardingIntegrations!.detailedEmailSubject,
      });
      if (!mounted.current) return;

      onUpdate(data);
      setState((prevState) => ({
        ...prevState,
        forwardingFrequency: data.forwardingFrequency,
        includeCreditCardStatement: data.includeCreditCardStatement,
        includeTransactionDetails: data.includeTransactionDetails,
        detailedEmailSubject: data.detailedEmailSubject,
        isForwardingUpdating: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isForwardingUpdating: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const handleToggleChange = () => {
    if (forwardingIntegrations!.status === ForwardingIntegrationStatus.active) {
      deactivateAutoForwarding();
    } else {
      setState((prevState) => ({
        ...prevState,
        isAutomatedForwardingDialogOpen: true,
      }));
    }
  };

  const onForwardingConfigChange = async (
    key:
      | 'forwardingFrequency'
      | 'includeCreditCardStatement'
      | 'includeTransactionDetails'
      | 'detailedEmailSubject',
    value: ForwardingIntegrationFrequency | boolean
  ) => {
    try {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
        isForwardingUpdating: true,
      }));

      const data = await api.updateForwardingIntegration(organization!.id, {
        forwardingEmail: forwardingIntegrations!.forwardingEmail,
        status: forwardingIntegrations!.status,
        forwardingFrequency: forwardingIntegrations!.forwardingFrequency,
        includeCreditCardStatement: forwardingIntegrations!
          .includeCreditCardStatement,
        includeTransactionDetails: forwardingIntegrations!
          .includeTransactionDetails,
        detailedEmailSubject: forwardingIntegrations!.detailedEmailSubject,
        [key]: value,
      });

      if (!mounted.current) return;

      onUpdate(data);
      setState((prevState) => ({
        ...prevState,
        forwardingEmail: data.forwardingEmail,
        status: data.status,
        forwardingFrequency: data.forwardingFrequency,
        includeCreditCardStatement: data.includeCreditCardStatement,
        includeTransactionDetails: data.includeTransactionDetails,
        detailedEmailSubject: data.detailedEmailSubject,
        isForwardingUpdating: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        [key]: forwardingIntegrations[key],
        isForwardingUpdating: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <SettingsGroupBox>
        <ContentContainer>
          <Typography variant="h6" mb={1}>
            {t('receiptManagementSubPage.emailForwarding.title')}
          </Typography>

          <Typography variant="body1">
            {t('receiptManagementSubPage.emailForwarding.description')}
          </Typography>

          <Box mt={2}>
            <FormControl
              fullWidth
              disabled={
                state.isForwardingUpdating ||
                !canUser('automatic-receipt-forwarding:change')
              }
            >
              <FormControlLabel
                labelPlacement="start"
                label={t('receiptManagementSubPage.switchReceiptForwarding')}
                checked={
                  forwardingIntegrations.status ===
                  ForwardingIntegrationStatus.active
                }
                onChange={handleToggleChange}
                control={<Switch />}
              />
            </FormControl>
          </Box>

          {forwardingIntegrations.status ===
            ForwardingIntegrationStatus.active && (
            <>
              <Box mt={2}>
                <FormControl
                  fullWidth
                  disabled={
                    state.isForwardingUpdating ||
                    !canUser('automatic-receipt-forwarding:change')
                  }
                >
                  <FormControlLabel
                    labelPlacement="start"
                    label={t(
                      'receiptManagementSubPage.switchCreditCardStatement',
                      { partnerName }
                    )}
                    checked={!!state.includeCreditCardStatement}
                    onChange={(_, checked: boolean) =>
                      onForwardingConfigChange(
                        'includeCreditCardStatement',
                        checked
                      )
                    }
                    control={<Switch />}
                  />
                </FormControl>
              </Box>

              <Box mt={2}>
                <FormControl
                  fullWidth
                  disabled={
                    state.isForwardingUpdating ||
                    !canUser('automatic-receipt-forwarding:change')
                  }
                >
                  <FormControlLabel
                    labelPlacement="start"
                    label={t(
                      'receiptManagementSubPage.switchIncludeTransactionDetailsSubject'
                    )}
                    checked={!!state.detailedEmailSubject}
                    onChange={(_, checked: boolean) =>
                      onForwardingConfigChange('detailedEmailSubject', checked)
                    }
                    control={<Switch />}
                  />
                </FormControl>
              </Box>

              <Box mt={2}>
                <FormControl
                  fullWidth
                  disabled={
                    state.isForwardingUpdating ||
                    !canUser('automatic-receipt-forwarding:change')
                  }
                >
                  <FormControlLabel
                    labelPlacement="start"
                    label={t(
                      'receiptManagementSubPage.switchIncludeTransactionDetails'
                    )}
                    checked={!!state.includeTransactionDetails}
                    onChange={(_, checked: boolean) =>
                      onForwardingConfigChange(
                        'includeTransactionDetails',
                        checked
                      )
                    }
                    control={<Switch />}
                  />
                </FormControl>
              </Box>

              <Grid container columnSpacing={2} mt={4}>
                <Grid item xs={6}>
                  <LabeledValue
                    label={t('receiptManagementSubPage.emailLabel')}
                    value={forwardingIntegrations.forwardingEmail}
                    onEdit={
                      canUser('automatic-receipt-forwarding:change')
                        ? () =>
                            setState((prevState) => ({
                              ...prevState,
                              isAutomatedForwardingDialogOpen: true,
                            }))
                        : undefined
                    }
                  />
                </Grid>

                <Grid item xs={6} display="flex" alignItems="flex-end">
                  <FormControl
                    disabled={
                      state.isForwardingUpdating ||
                      !canUser('automatic-receipt-forwarding:change')
                    }
                    sx={{ width: '200px' }}
                  >
                    <InputLabel>
                      {t('receiptManagementSubPage.forwardingFrequencyLabel')}
                    </InputLabel>
                    <Select
                      value={state.forwardingFrequency}
                      renderValue={(selected) => {
                        return selected ===
                          ForwardingIntegrationFrequency.instant
                          ? t(
                              'receiptManagementSubPage.immediateFrequencyOption'
                            )
                          : t('receiptManagementSubPage.dailyFrequencyOption');
                      }}
                      onChange={(e) => {
                        onForwardingConfigChange(
                          'forwardingFrequency',
                          e.target.value as ForwardingIntegrationFrequency
                        );
                      }}
                    >
                      <MenuItem value={ForwardingIntegrationFrequency.instant}>
                        {t('receiptManagementSubPage.immediateFrequencyOption')}
                      </MenuItem>
                      <MenuItem value={ForwardingIntegrationFrequency.daily}>
                        {t('receiptManagementSubPage.dailyFrequencyOption')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
        </ContentContainer>
      </SettingsGroupBox>

      <ChangeReceiptAutoForwardingDialog
        forwardingIntegrations={forwardingIntegrations}
        open={state.isAutomatedForwardingDialogOpen}
        onSuccess={(updatedIntegrations) => {
          onUpdate(updatedIntegrations);
          setState((prevState) => ({
            ...prevState,
            forwardingFrequency: updatedIntegrations.forwardingFrequency,
            includeCreditCardStatement:
              updatedIntegrations.includeCreditCardStatement,
            includeTransactionDetails: updatedIntegrations!
              .includeTransactionDetails,
            detailedEmailSubject: updatedIntegrations!.detailedEmailSubject,
          }));
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isAutomatedForwardingDialogOpen: false,
          }))
        }
      />
    </>
  );
};

export default ReceiptForwardingGroup;
