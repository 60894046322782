import React, { forwardRef, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { ListItemIcon, MenuItem } from 'elements';

interface Props {
  to: string;
  text: string;
  icon: ReactNode;
}

const MenuLink = forwardRef<HTMLAnchorElement, Props>(
  ({ to, text, icon, ...rest }, ref) => (
    <MenuItem
      component={NavLink}
      to={to}
      activeClassName="Mui-selected"
      ref={ref}
      {...rest}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      {text}
    </MenuItem>
  )
);

export default MenuLink;
