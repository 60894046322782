import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  NetworkErrorCode,
  PaymentFrequency,
  PaymentFrequencyType,
  paymentFrequencyTypes,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';

interface State {
  value: PaymentFrequencyType;
  isLoading: boolean;
}

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (paymentFrequency: PaymentFrequency) => void;
  paymentFrequency: PaymentFrequency;
}

const ChangeMinPaymentFrequencyDialog = ({
  paymentFrequency,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    value: paymentFrequency.min.value,
    isLoading: false,
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const updatedPaymentFrequency = await api.updateMinPaymentFrequency(
        organization!.id,
        state.value
      );
      if (!mounted.current) return;
      onSuccess(updatedPaymentFrequency);
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      if (
        getNetworkErrorCode(error) ===
        NetworkErrorCode.pmtFrequencyIsLowerThanMinValue
      ) {
        enqueueSnackbar(
          t('int.changeMinPaymentFrequencyDialog.lowerThanMinError'),
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t(`int.changeMinPaymentFrequencyDialog.title`, {
          orgName: organization!.name,
        })}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit} id="change-payment-frequency-form" noValidate>
          <FormControl fullWidth disabled={state.isLoading}>
            <InputLabel id="payment-frequency-select-label">
              {t(`int.changeMinPaymentFrequencyDialog.label`)}
            </InputLabel>
            <Select<PaymentFrequencyType>
              labelId="payment-frequency-select-label"
              value={state.value}
              onChange={(e) => {
                setState((prevState) => ({
                  ...prevState,
                  value: e.target.value as PaymentFrequencyType,
                }));
              }}
              renderValue={(selected) => t(`paymentFrequencyTypes.${selected}`)}
            >
              {paymentFrequencyTypes.map((value) => (
                <MenuItem key={value} value={value}>
                  {t(`paymentFrequencyTypes.${value}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={state.isLoading}
          form="change-payment-frequency-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(ChangeMinPaymentFrequencyDialog);
