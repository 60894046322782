import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { CardConfigGroup } from 'services/constants';

/**
 * Returns card configs grouped by card group.
 */
const useAvailableVirtualTravelCardSubtypes = () => {
  const {
    state: { cardConfigSettings },
  } = useGlobalState();

  return useMemo(
    () =>
      cardConfigSettings.filter(
        (cardConfig) =>
          cardConfig.canBeIssued &&
          cardConfig.cardConfigGroup === CardConfigGroup.pliantVirtualTravel
      ),
    [cardConfigSettings]
  );
};

export default useAvailableVirtualTravelCardSubtypes;
