import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import TextField, { TextFieldProps } from 'elements/TextField';
import { DEFAULT_CURRENCY } from 'services/constants';

const addCurrencySignDelimiter = (
  possibleLiteralPart: Intl.NumberFormatPart | undefined
) => (possibleLiteralPart?.type === 'literal' ? possibleLiteralPart.value : '');

const addCurrencySymbolPrefixOrSuffix = (parts: Intl.NumberFormatPart[]) => {
  const currencyPartIndex = parts.findIndex((item) => item.type === 'currency');

  if (currencyPartIndex === -1) return {};

  if (currencyPartIndex === 0) {
    return {
      prefix:
        parts[currencyPartIndex].value +
        addCurrencySignDelimiter(parts[currencyPartIndex + 1]),
    };
  }

  return {
    suffix:
      addCurrencySignDelimiter(parts[currencyPartIndex - 1]) +
      parts[currencyPartIndex].value,
  };
};

export const useLanguageSensitiveProps = (currency: string) => {
  const { i18n } = useTranslation();
  const currencyDisplay = useCurrencyDisplayMode();
  const parts = Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currencyDisplay,
    currency,
  }).formatToParts(1000000);
  const decimalPart = parts.find((item) => item.type === 'decimal');
  const groupPart = parts.find((item) => item.type === 'group');
  /** (JPY) Decimal separator can't be same as a thousand separator. */
  const thousandSeparator = groupPart?.value ?? '.';
  let decimalSeparator = decimalPart?.value ?? ',';
  if (thousandSeparator === decimalSeparator) {
    decimalSeparator = thousandSeparator === ',' ? '.' : ',';
  }

  return {
    decimalSeparator,
    thousandSeparator,
    ...(currencyDisplay === 'narrowSymbol'
      ? addCurrencySymbolPrefixOrSuffix(parts)
      : { suffix: ` ${currency}` }),
  };
};

export type MoneyFieldProps = Omit<
  NumberFormatProps<TextFieldProps>,
  'onChange'
> & {
  currency?: string;
};

const MoneyField = ({
  currency = DEFAULT_CURRENCY.code,
  ...props
}: MoneyFieldProps) => {
  const languageSensitiveProps = useLanguageSensitiveProps(currency);

  return (
    <NumberFormat
      customInput={TextField}
      allowNegative={false}
      decimalScale={0}
      {...languageSensitiveProps}
      {...props}
    />
  );
};

export default MoneyField;
