import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MerchantDe42, MerchantDetails } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  merchantId: string;
  merchantsDe42: MerchantDe42[];
  merchantId42Groups: MerchantDe42[][];
  onMerchantUnmerge: (merchant: MerchantDetails) => void;
  onClose: () => void;
}

interface State {
  selectedGroupIds: string[];
  isLoading: boolean;
}

const MerchantUnmergeDialog = ({
  merchantId,
  merchantsDe42,
  merchantId42Groups,
  onMerchantUnmerge,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    selectedGroupIds: [],
    isLoading: false,
  });

  const maxSelection = merchantId42Groups.length - 1;

  const handleMerchantUnmerge = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const selectedMerchantIds = merchantsDe42
        .filter((item) => state.selectedGroupIds.includes(item.autoGroupId))
        .map((item) => item.merchantIdDe42);
      const updatedMerchant = await api.unmergeMerchants(merchantId, {
        merchantIdsDe42: selectedMerchantIds,
      });
      if (!mounted.current) return;
      onMerchantUnmerge(updatedMerchant);
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        {t('int.merchantDetailsPage.unmergeDialog.title')}
      </DialogTitle>
      <DialogContent>
        <Box mb={4}>
          <Typography component="div">
            <Trans
              i18nKey="int.merchantDetailsPage.unmergeDialog.text"
              components={{ div: <div /> }}
            />
          </Typography>
        </Box>

        <FormControl variant="standard" sx={{ width: 1 }}>
          <FormGroup>
            {merchantId42Groups.map((group) => {
              const autoGroupId = group[0].autoGroupId;
              const checked = state.selectedGroupIds.includes(autoGroupId);

              return (
                <FormControlLabel
                  key={autoGroupId}
                  checked={checked}
                  onChange={(e, checked) => {
                    if (!checked) {
                      setState((prevState) => ({
                        ...prevState,
                        selectedGroupIds: prevState.selectedGroupIds.filter(
                          (item) => item !== autoGroupId
                        ),
                      }));
                    } else {
                      setState((prevState) => ({
                        ...prevState,
                        selectedGroupIds: [
                          ...prevState.selectedGroupIds,
                          autoGroupId,
                        ],
                      }));
                    }
                  }}
                  disabled={
                    state.selectedGroupIds.length === maxSelection && !checked
                  }
                  disableTypography
                  control={<Checkbox />}
                  label={
                    <Box
                      sx={{
                        flex: 1,
                        ml: 1,
                        px: 3,
                        py: 2,
                        border: 1,
                        borderRadius: 2,
                        borderColor: checked ? 'primary.main' : 'divider',
                      }}
                    >
                      {group.map((merchantDe42) => (
                        <Box py={1} key={merchantDe42.merchantIdDe42}>
                          <Typography variant="body2">
                            {merchantDe42.merchantName}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            <span>
                              {t(
                                'int.merchantDetailsPage.unmergeDialog.merchantId'
                              )}
                            </span>{' '}
                            <span>{merchantDe42.merchantIdDe42}</span>
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  }
                  sx={{ mb: 2 }}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={state.selectedGroupIds.length < 1}
          onClick={handleMerchantUnmerge}
        >
          {t('int.merchantDetailsPage.unmergeDialog.unmerge')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(MerchantUnmergeDialog);
