import moment from 'moment';
import { useGlobalState } from 'context/GlobalState';
import { DataGridCell, Typography } from 'elements';
import {
  ExportFlowTransaction,
  ReceiptDateExportMode,
} from 'services/constants';

interface Props {
  transaction: ExportFlowTransaction;
}

const ReceiptDateCell = ({ transaction }: Props) => {
  const {
    state: { accountingSettings },
  } = useGlobalState();

  if (
    [ReceiptDateExportMode.receiptDate, ReceiptDateExportMode.dynamic].includes(
      accountingSettings!.receiptDateExportMode
    )
  ) {
    const shownDate = transaction.receiptDate || transaction.createdAt;
    return (
      <DataGridCell>
        {moment(shownDate).format('D MMM YYYY')}
        <Typography
          component="div"
          variant="caption"
          color="textSecondary"
          noWrap
        >
          {moment(transaction.bookingDate).format('D MMM YYYY')}
        </Typography>
      </DataGridCell>
    );
  }

  return (
    <DataGridCell>
      {moment(transaction.bookingDate).format('D MMM YYYY')}
      <Typography
        component="div"
        variant="caption"
        color="textSecondary"
        noWrap
      >
        {moment(transaction.createdAt).format('D MMM YYYY')}
      </Typography>
    </DataGridCell>
  );
};

export default ReceiptDateCell;
