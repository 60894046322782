import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from 'elements/CircularProgress';
import LinearProgress from 'elements/LinearProgress';
import { FileArrowUpIcon } from 'elements/TemporaryIcon';
import formatBytes from 'services/utils/formatBytes';
import { Variant } from './index';

const flexCenterCss = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

interface DropzoneStatusComponentProps {
  variant?: Variant;
  progress?: number;
  file: File;
  isLoading?: boolean;
  error?: string;
}

// can be extended to handle linear progress if needed
const DropzoneStatusComponent = ({
  variant,
  progress,
  file,
  isLoading,
  error,
}: DropzoneStatusComponentProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ ...flexCenterCss }}>
      <Box
        sx={{
          width: '56px',
          ...flexCenterCss,
        }}
      >
        <FileArrowUpIcon
          fontSize="large"
          color={variant === 'error' ? 'inherit' : error ? 'error' : 'primary'}
        />
      </Box>
      <Box px={2} flex={1} minWidth={0}>
        {error ? (
          <>
            <Typography
              variant="body1"
              noWrap
              color={variant === 'error' ? 'inherit' : 'error.main'}
            >
              {t('dropzone.uploadFailed')}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                color: (theme) =>
                  variant === 'error' ? 'inherit' : theme.palette.error.main,
              }}
            >
              <Typography variant="body2">{error}</Typography>
              <Box px={1}>•</Box>
              <Typography variant="body2">{t('dropzone.failed')}</Typography>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="body1" noWrap>
              {file.name}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                color: (theme) =>
                  variant === 'error'
                    ? 'inherit'
                    : theme.palette.text.secondary,
              }}
            >
              <Typography variant="body2" noWrap>
                {formatBytes(file.size)}
              </Typography>

              {isLoading && (
                <>
                  <Box px={1}>•</Box>
                  <Typography variant="body2" noWrap>
                    {t('dropzone.loading')}
                  </Typography>
                </>
              )}
            </Box>
            {isLoading && typeof progress === 'number' && (
              <Box display="flex" alignItems="center" width="80%" mt={0.5}>
                <LinearProgress value={progress} sx={{ flex: 1 }} />
                <Typography
                  variant="body2"
                  ml={1}
                  color={variant === 'error' ? 'inherit' : 'textSecondary'}
                >
                  {progress}%
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
      {isLoading && typeof progress !== 'number' && (
        <Box
          sx={{
            width: '56px',
            ...flexCenterCss,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default DropzoneStatusComponent;
