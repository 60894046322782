import { useState } from 'react';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { TabGroup } from 'domains/transaction/dialogs/ReceiptInboxDialog';
import { Box, Button, FunnelSimpleIcon, Typography } from 'elements';
import { PageSearchInput } from 'layout';
import { ReceiptsAutoMatchingStatus } from 'services/constants';
import FiltersDrawer from './FiltersDrawer';

export interface ReceiptInboxFilters {
  status: ReceiptsAutoMatchingStatus[];
  fromDate: Moment | null;
  toDate: Moment | null;
}

interface Props {
  tabGroup: TabGroup;
  search: string;
  filters: ReceiptInboxFilters;
  selectedFiltersCount: number;
  disabled: boolean;
  resultsCount: number;
  onSearchChange: (search: string) => void;
  onUpdate: (filters: ReceiptInboxFilters) => void;
}

const Filters = ({
  tabGroup,
  search,
  filters,
  resultsCount,
  selectedFiltersCount,
  disabled,
  onSearchChange,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <>
      <Box display="flex" alignItems="center">
        <PageSearchInput
          initialValue={search}
          onChange={onSearchChange}
          disabled={disabled}
        />
        <Button
          sx={{ ml: 2 }}
          variant={selectedFiltersCount ? 'contained' : 'text'}
          startIcon={<FunnelSimpleIcon />}
          onClick={() => setIsFilterOpen(true)}
          disabled={disabled}
        >
          {t('filterDrawer.title')}
          {!!selectedFiltersCount && ` (${selectedFiltersCount})`}
        </Button>

        <Typography variant="body2" color="textSecondary" ml={2}>
          {t('common.resultsTable', { count: resultsCount })}
        </Typography>
      </Box>

      <FiltersDrawer
        open={isFilterOpen}
        tabGroup={tabGroup}
        filters={filters}
        onUpdate={onUpdate}
        onClose={() => setIsFilterOpen(false)}
      />
    </>
  );
};

export default Filters;
