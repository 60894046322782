import { useContext, useMemo } from 'react';
import { GlobalStateContext } from 'context/GlobalState';

const useCurrencyDisplayMode = () => {
  // Use "useContext" directly instead of "useGlobalState" to have the correct typings
  // when this hook is used in a component that is not wrapped with GlobalStateProvider.
  const globalState = useContext(GlobalStateContext);

  return useMemo(() => {
    // three letter currency codes on the internal pages
    if (!globalState?.state.organization) return 'code';

    return globalState?.state.featureModules.MULTI_CURRENCY_BILLING
      ? 'code'
      : 'narrowSymbol';
  }, [
    !!globalState?.state.organization,
    globalState?.state.featureModules.MULTI_CURRENCY_BILLING,
  ]);
};

export default useCurrencyDisplayMode;
