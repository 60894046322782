import styled from 'styled-components';
import { StyledInputBase as InputBase } from 'components/Form/StyledInputBase';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
  border-radius: 10px;
  min-height: 266px;
  overflow: hidden;
  padding: 16px 20px;
  position: relative;
  min-width: 988px;
`;

export const Header = styled.h3`
  font-size: 16px;
  line-height: 22px;
  margin: 0 6px 0 0;
`;

export const StyledInputBase = styled(InputBase)`
  & .MuiInputBase-input {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.grey100};
    font-size: 12px;
    line-height: 14px;
    height: 22px;
    min-width: 92px;
    padding: 3px 14px;
  }
`;
