import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  DatePicker,
  IconButton,
  LightningIcon,
  Paper,
  PencilSimpleIcon,
  Tooltip,
  Typography,
} from 'elements';
import { ReceiptAutomatchStatus } from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props {
  isLoading: boolean;
  disableEdit: boolean;
  isEditingMode: boolean;
  isError: boolean;
  receiptDate: string;
  receiptDateStatus: ReceiptAutomatchStatus;
  onChange: (receiptDate: string) => void;
  onSave: () => void;
  onEdit: () => void;
  onCancel: () => void;
}

const ReceiptDate = ({
  isLoading,
  disableEdit,
  isError,
  isEditingMode,
  receiptDate,
  receiptDateStatus,
  onChange,
  onSave,
  onEdit,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();

  if (isEditingMode)
    return (
      <Box mt={2}>
        <DatePicker
          label={t('transactionReceipts.receiptDateLabel')}
          disableFuture
          value={receiptDate ? moment(receiptDate) : null}
          onChange={(newDate) => onChange(moment(newDate).format('YYYY-MM-DD'))}
          slotProps={{
            field: {
              format: 'DD.MM.YYYY',
            },
            textField: {
              InputProps: { notched: false },
              size: 'medium',
              error: isError,
              helperText:
                isError && t('transactionDetails.receiptDateValidationError'),
            },
          }}
        />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mt={1}
        >
          <Button onClick={onCancel} variant="text">
            {t('common.button.cancel')}
          </Button>
          <Button onClick={onSave} sx={{ ml: 1 }}>
            {t('common.button.save')}
          </Button>
        </Box>
      </Box>
    );

  return (
    <Paper
      variant="outlined"
      sx={{
        mt: 2,
        px: 1.5,
        py: 1.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box display="flex" alignItems="center" minWidth={0}>
        <Typography variant="body2" noWrap>
          {t('transactionReceipts.receiptDateLabel')}
        </Typography>

        {receiptDateStatus === ReceiptAutomatchStatus.AUTOMATCHED_SUCCESS && (
          <Tooltip title={t('transactionDetails.receiptDateExtractedTooltip')}>
            <LightningIcon fontSize="small" color="info" sx={{ ml: 1.5 }} />
          </Tooltip>
        )}
      </Box>

      <Box pl={2} display="flex" alignItems="center" minWidth={0}>
        <Box
          lineHeight={0}
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {isLoading ? (
            <CircularProgress size="small" />
          ) : (
            <Typography variant="body2" display="inline">
              {receiptDate ? moment(receiptDate).format('DD.MM.YYYY') : '—'}
            </Typography>
          )}
        </Box>

        {!disableEdit && canUser('receipt:upload') && (
          <IconButton
            onClick={onEdit}
            disabled={isLoading}
            sx={{ ml: 1 }}
            size="small"
          >
            <PencilSimpleIcon />
          </IconButton>
        )}
      </Box>
    </Paper>
  );
};

export default ReceiptDate;
