import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { getSubscriptionPlanName } from 'domains/organization/utils';
import { Box, Button, Grid, InfoIcon, Tooltip, Typography } from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { ContentContainer, SettingsGroupBox } from 'layout';
import {
  CardCount,
  CardSimpleStatus,
  CardType,
  SubscriptionPlan,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import { getCardCount } from 'services/utils';
import UpgradePlanConfirmationDialog from './UpgradePlanConfirmationDialog';

interface Props {
  cardCount: CardCount;
  subscriptionPlan: SubscriptionPlan;
}

const PlanSection = ({ cardCount, subscriptionPlan }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const partnerName = usePartnerName();
  const [state, setState] = useState({
    isUpgradeDialogOpen: false,
  });

  const activeSingleUseCardCount = getCardCount(cardCount, CardType.singleUse, [
    CardSimpleStatus.active,
  ]);
  const pendingOrRequestedSingleUseCardCount = getCardCount(
    cardCount,
    CardType.singleUse,
    [CardSimpleStatus.pending, CardSimpleStatus.requested]
  );

  const activeVirtualCardCount = getCardCount(cardCount, CardType.virtual, [
    CardSimpleStatus.active,
  ]);
  const pendingOrRequestedVirtualCardCount = getCardCount(
    cardCount,
    CardType.virtual,
    [CardSimpleStatus.pending, CardSimpleStatus.requested]
  );

  const getPlanDescriptionKey = () => {
    const isBaseFeeCharged = subscriptionPlan.baseFee.value > 0;
    const isUserFeeCharged =
      subscriptionPlan.freeMembersCount !== null &&
      subscriptionPlan.additionalUserFee.value > 0;

    if (isBaseFeeCharged && isUserFeeCharged) return 'baseFeeAndMemberFee';

    if (!isBaseFeeCharged && !isUserFeeCharged) return 'noFees';

    if (isBaseFeeCharged) return 'baseFeeOnly';

    return 'memberFeeOnly';
  };

  return (
    <>
      <SettingsGroupBox>
        <ContentContainer>
          <Typography variant="h6" mb={2}>
            {t('subscriptionPlanSettingsSubPage.planSection.title')}
          </Typography>

          <Box display="flex" alignItems="center">
            <Typography variant="h4">
              {getSubscriptionPlanName(subscriptionPlan.type)}
            </Typography>
            {canUser('subscription-plan:upgrade') && (
              <Button
                sx={{ ml: 3 }}
                variant="outlined"
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isUpgradeDialogOpen: true,
                  }))
                }
              >
                {t('subscriptionPlanSettingsSubPage.upgradePlan')}
              </Button>
            )}
          </Box>

          <Typography mt={1}>
            <Trans
              i18nKey={`subscriptionPlanSettingsSubPage.planSection.${getPlanDescriptionKey()}`}
              values={{ partnerName }}
              components={{
                additionalUserFee: (
                  <FormatMoney
                    value={subscriptionPlan.additionalUserFee}
                    fractionalPart
                  />
                ),
                baseFee: (
                  <FormatMoney
                    value={subscriptionPlan.baseFee}
                    fractionalPart
                  />
                ),
              }}
            />{' '}
            {subscriptionPlan.freeMembersCount !== null &&
              subscriptionPlan.freeMembersCount > 0 &&
              t('subscriptionPlanSettingsSubPage.planSection.freeMembers', {
                count: subscriptionPlan.freeMembersCount,
              })}
          </Typography>

          <Grid container mt={3}>
            <Grid item xs={4} pr={2}>
              <Typography variant="overline">
                {t(
                  'subscriptionPlanSettingsSubPage.planSection.membersKeyPlan'
                )}
              </Typography>
              <Box display="flex" alignItems="flex-end">
                <Typography variant="h5">
                  {subscriptionPlan.activeMembersCount}
                </Typography>
                <Typography>
                  {subscriptionPlan.invitedMembersCount > 0 &&
                    ` (+${subscriptionPlan.invitedMembersCount})`}
                  &nbsp;/&nbsp;
                  {subscriptionPlan.maxActiveMembersCount !== null
                    ? subscriptionPlan.maxActiveMembersCount
                    : t(
                        'subscriptionPlanSettingsSubPage.planSection.unlimited'
                      )}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4} pr={2}>
              <Typography variant="overline">
                {t(
                  'subscriptionPlanSettingsSubPage.planSection.virtualCardsKeyPlan'
                )}
              </Typography>
              <Box display="flex" alignItems="flex-end">
                <Typography variant="h5">{activeVirtualCardCount}</Typography>
                <Typography>
                  {pendingOrRequestedVirtualCardCount > 0 &&
                    ` (+${pendingOrRequestedVirtualCardCount})`}
                  &nbsp;/&nbsp;
                  {subscriptionPlan.maxVirtualCardsCount !== null
                    ? subscriptionPlan.maxVirtualCardsCount
                    : t(
                        'subscriptionPlanSettingsSubPage.planSection.unlimited'
                      )}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={4} pr={2}>
              <Box display="flex" alignItems="center">
                <Typography variant="overline">
                  {t(
                    'subscriptionPlanSettingsSubPage.planSection.singleUseCardsKeyPlan'
                  )}
                </Typography>
                <Tooltip
                  title={t(
                    'subscriptionPlanSettingsSubPage.planSection.singleUseCardsKeyPlanTooltip'
                  )}
                >
                  <InfoIcon
                    sx={{
                      width: 16,
                      height: 16,
                      ml: 0.5,
                      color: 'text.secondary',
                    }}
                  />
                </Tooltip>
              </Box>
              <Box display="flex" alignItems="flex-end">
                <Typography variant="h5">{activeSingleUseCardCount}</Typography>
                <Typography>
                  {pendingOrRequestedSingleUseCardCount > 0 &&
                    ` (+${pendingOrRequestedSingleUseCardCount})`}
                  &nbsp;/&nbsp;
                  {subscriptionPlan.maxSingleUseCardsCount !== null
                    ? subscriptionPlan.maxSingleUseCardsCount
                    : t(
                        'subscriptionPlanSettingsSubPage.planSection.unlimited'
                      )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </ContentContainer>
      </SettingsGroupBox>

      <UpgradePlanConfirmationDialog
        open={state.isUpgradeDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isUpgradeDialogOpen: false,
          }))
        }
      />
    </>
  );
};

export default PlanSection;
