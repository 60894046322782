import React, { useEffect, useState } from 'react';
import flatten from 'lodash/flatten';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormControlLabelTooltipIcon,
  FormGroup,
  FormLabel,
  LoaderWithOverlay,
  Switch,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { NotificationSetting } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  settings: { [key: string]: boolean } | null;
  settingsGrouped: {
    group: string;
    categories: NotificationSetting[];
  }[];
}

interface Props extends DialogProps {
  onClose: () => void;
}

const NotificationSettingsDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { member, organization, featureModules },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    settings: null,
    settingsGrouped: [],
  });

  const receiptManagementEnabled = featureModules.RECEIPT_MANAGEMENT;

  const onSubmit = async () => {
    try {
      setState((prev) => ({ ...prev, isLoading: true }));
      const data = Object.keys(state.settings!).map((key) => ({
        type: key,
        enabled: state.settings![key],
      }));
      await api.updateNotificationSettings(organization!.id, member.id, data);

      if (!mounted.current) return;
      enqueueSnackbar(
        t('profilePage.notificationSettingsDialog.successfulUpdatedToast')
      );
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    } finally {
      props.onClose();
    }
  };

  const getData = async () => {
    try {
      const settingsCategorized = await api.getNotificationSettingsCategorized(
        organization!.id,
        member.id
      );
      const settings = flatten(
        settingsCategorized.groups.map((group) => group.categories)
      ).reduce((prev, { type, enabled }) => ({ ...prev, [type]: enabled }), {});

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        settings,
        settingsGrouped: settingsCategorized.groups,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Dialog {...props}>
      <DialogTitle>
        {t('profilePage.notificationSettingsDialog.title')}
      </DialogTitle>
      <DialogContent sx={{ minHeight: 300 }}>
        <Box mb={4}>
          <Typography variant="body2">
            {t('profilePage.notificationSettingsDialog.description')}
          </Typography>
        </Box>
        {state.settingsGrouped.map((group) => (
          <Box key={group.group} mb={3}>
            <FormControl
              disabled={state.isLoading}
              variant="standard"
              fullWidth
            >
              <FormLabel>
                {t(`profilePage.notificationSettingsDialog.${group.group}`)}
              </FormLabel>
              <FormGroup>
                {group.categories.map((category) => {
                  if (
                    !receiptManagementEnabled &&
                    (category.type === 'ORG_MISSING_RECEIPTS' ||
                      category.type === 'MEMBER_MISSING_RECEIPTS')
                  )
                    return null;

                  return (
                    <FormControlLabel
                      key={category.type}
                      control={
                        <Switch
                          checked={state.settings![category.type]}
                          onChange={() =>
                            setState((prevState) => ({
                              ...prevState,
                              settings: {
                                ...prevState.settings,
                                [category.type]: !prevState.settings![
                                  category.type
                                ],
                              },
                            }))
                          }
                        />
                      }
                      label={
                        <Box
                          display="inline-flex"
                          alignItems="center"
                          component="span"
                        >
                          {t(
                            `profilePage.notificationSettingsDialog.${category.type}`
                          )}
                          {category.type === 'MARKETING_EMAILS' && (
                            <FormControlLabelTooltipIcon
                              title={t(
                                `profilePage.notificationSettingsDialog.tooltip.${category.type}`
                              )}
                            />
                          )}
                        </Box>
                      }
                      labelPlacement="start"
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={state.isLoading} onClick={onSubmit}>
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(NotificationSettingsDialog);
