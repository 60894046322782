import React from 'react';
import {
  Paper,
  Step,
  StepButton,
  StepConnector,
  StepIcon,
  StepIconProps,
  StepLabel,
  Stepper,
} from 'elements';

export const DIALOG_STEPPER_WIDTH = 230;

const SummaryStepIcon = (props: StepIconProps) => (
  <StepIcon
    {...props}
    completed
    sx={
      !props.active
        ? (theme) => ({
            '&.Mui-completed': {
              color: theme.palette.text.disabled,
            },
          })
        : null
    }
  />
);

interface Props<T> {
  currentStep: T;
  steps: T[];
  goTo: (step: T) => void;
  getStepLabel: (step: T) => string;
}

export const DialogStepper = <T extends string>({
  currentStep,
  steps,
  goTo,
  getStepLabel,
}: Props<T>) => {
  return (
    <Paper
      square
      variant="tinted"
      sx={(theme) => ({
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: DIALOG_STEPPER_WIDTH,
        padding: theme.spacing(4, 3),
      })}
    >
      <Stepper
        activeStep={steps.indexOf(currentStep)}
        connector={<StepConnector sx={{ visibility: 'hidden' }} />}
        orientation="vertical"
      >
        {steps.map((item) => (
          <Step key={item}>
            <StepButton
              color="inherit"
              sx={{ display: 'flex' }}
              onClick={() => goTo(item)}
            >
              <StepLabel
                sx={{ padding: 0 }}
                StepIconComponent={(props) =>
                  item === 'summary' ? (
                    <SummaryStepIcon {...props} />
                  ) : (
                    <StepIcon {...props} />
                  )
                }
              >
                {getStepLabel(item)}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Paper>
  );
};
