import React from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveTeams, useGlobalState } from 'context/GlobalState';
import { MISSING_TEAM_OPTION } from 'domains/transaction/pages/ExportPage/utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from 'elements';

type Value =
  | typeof MISSING_TEAM_OPTION
  | Omit<typeof MISSING_TEAM_OPTION, string>;

interface Props {
  value: Value;
  onChange: (value: Value) => void;
}

const TeamsFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const activeTeams = useActiveTeams();

  if (activeTeams.length === 0) return null;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-team"
        id="panel-team-header"
      >
        <Typography>{t('filters.team')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<Value>
            size="medium"
            displayEmpty
            value={value}
            onChange={(e) => onChange(e.target.value as Value)}
            renderValue={(selected) => {
              if (!selected)
                return (
                  <Typography color="text.disabled">
                    {t('filters.team')}
                  </Typography>
                );

              if (selected === MISSING_TEAM_OPTION)
                return <Typography>—</Typography>;

              const team = activeTeams.find((item) => item.id === selected);
              return team?.name || '';
            }}
          >
            <MenuItem value="">
              <Typography color="text.disabled">{t('filters.team')}</Typography>
            </MenuItem>

            <MenuItem value={MISSING_TEAM_OPTION}>—</MenuItem>

            {activeTeams.map((team) => (
              <MenuItem key={team.id} value={team.id}>
                {accountingSettings?.costCenterEnabled && team.costCenter ? (
                  <>
                    {team.name} ({team.costCenter})
                  </>
                ) : (
                  team.name
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default TeamsFilter;
