import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  LoaderWithOverlay,
  MenuItem,
  Select,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DatevExportFormatOption,
  DatevExportFormatOptions,
  ExportFormat,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  datevExportFormatsToOption,
  getGenericErrorMsg,
  optionToDatevExportFormats,
} from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
  datevExportFormats: ExportFormat[];
}

const ChangeDatevExportFormatDialog = ({
  datevExportFormats,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    dispatch,
    state: { organization, accountingSettings },
  } = useGlobalState();
  const [value, setValue] = useState<DatevExportFormatOption>(
    datevExportFormatsToOption(datevExportFormats)
  );
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const data = await api.updateAccountingSettings({
        organizationId: organization!.id,
        vatRateEnabled: accountingSettings!.vatRateEnabled,
        subcategoryEnabled: accountingSettings!.subcategoryEnabled,
        projectEnabled: accountingSettings!.projectEnabled,
        supplierEnabled: accountingSettings!.supplierEnabled,
        costCenterEnabled: accountingSettings!.costCenterEnabled,
        commentEnabled: accountingSettings!.commentEnabled,
        useAccountingExport: accountingSettings!.useAccountingExport,
        exportFormats: optionToDatevExportFormats(value),
      });
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { accountingSettings: data },
      });
      if (!mounted.current) return;
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('datevExportSettingsGroup.exportTypesLabel')}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth disabled={isLoading}>
          <Select
            value={value}
            onChange={(e) =>
              setValue(e.target.value as DatevExportFormatOption)
            }
            renderValue={(value) => t(`datevExportTypes.${value}`)}
          >
            {DatevExportFormatOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {t(`datevExportTypes.${option}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button onClick={onSubmit}>{t('datevExportSettingsGroup.save')}</Button>
      </DialogActions>
      <LoaderWithOverlay loading={isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(ChangeDatevExportFormatDialog);
