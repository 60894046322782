import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { DataItemRow } from 'domains/creditAndCompliance/components';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { parseAndReformatDateStr } from 'domains/creditAndCompliance/utils';
import { Box, EditButton, Grid, Typography } from 'elements';
import EditCreditAssessmentSummaryDialog from './EditCreditAssessmentSummaryDialog';

const CreditAssessmentSummarySection = () => {
  const { t } = useTranslation();
  const {
    state: { organizationsStaticData },
  } = useGlobalState();
  const {
    state: { creditAssessment, creditProfile },
  } = useCreditAndComplianceContext();
  /* using pd values from creditProfile.agencyProfile instead of creditAssessment.summary to keep in synch without extra call */
  const agencyProfile = creditProfile!.agencyProfile;
  /* using orgLimit value from creditAssessment.limits instead of creditAssessment.summary to keep in synch without extra call */
  const limits = creditAssessment!.limits;
  const summary = creditAssessment!.summary;

  const [
    isEditCreditAssessmentSummaryDialog,
    setIsEditCreditAssessmentSummaryDialog,
  ] = useState(false);

  return (
    <>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor={(theme) => theme.palette.divider}
        display="flex"
        py={1}
      >
        <Typography mr={1} variant="h6">
          {t('int.creditAssessmentSummarySection.title')}
        </Typography>

        <EditButton
          onClick={() => setIsEditCreditAssessmentSummaryDialog(true)}
        />
      </Box>

      <Grid container direction="column" spacing={2} py={3}>
        <DataItemRow
          label={t('int.creditAssessmentSummarySection.probabilityOfDefault')}
          updatedAt={agencyProfile.probabilityOfDefault.updatedAt}
          updatedBy={agencyProfile.probabilityOfDefault.updatedBy}
          value={
            typeof agencyProfile.probabilityOfDefault.value === 'number'
              ? `${agencyProfile.probabilityOfDefault.value}% (${
                  organizationsStaticData!.probabilityOfDefaultSources.find(
                    (item) =>
                      item.name ===
                      agencyProfile.probabilityOfDefaultSource.value
                  )?.label ?? '-'
                })`
              : null
          }
        />
        <DataItemRow
          label={t('int.creditAssessmentSummarySection.organizationLimit')}
          readOnlyValue
          updatedAt={limits.limit.updatedAt}
          updatedBy={limits.limit.updatedBy}
          value={
            limits.limit.value ? (
              <FormatMoney value={limits.limit.value} />
            ) : null
          }
        />
        <DataItemRow
          label={t('int.creditAssessmentSummarySection.nextMonitoringDate')}
          updatedAt={summary.nextMonitoringDate.updatedAt}
          updatedBy={summary.nextMonitoringDate.updatedBy}
          value={
            summary.nextMonitoringDate.value &&
            parseAndReformatDateStr(
              summary.nextMonitoringDate.value,
              'DD.MM.YYYY'
            )
          }
        />
      </Grid>

      <EditCreditAssessmentSummaryDialog
        open={isEditCreditAssessmentSummaryDialog}
        onClose={() => setIsEditCreditAssessmentSummaryDialog(false)}
      />
    </>
  );
};

export default CreditAssessmentSummarySection;
