import { ChangeEvent, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  CardDesignPicker,
  CustomFieldOptionsAutocomplete,
  DialogStepper,
} from 'domains/card/components';
import { FormValues, Step } from 'domains/card/dialogs/IssueCardDialog';
import useStepManager from 'domains/card/dialogs/IssueCardDialog/useStepManager';
import { TeamSelect } from 'domains/member/components';
import { useProjectsPerTeam } from 'domains/settings/utils';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormControlLabelTooltipIcon,
  FormHelperText,
  Grid,
  InputLabel,
  InputLabelTooltipIcon,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from 'elements';
import {
  CARD_NAME_MAX_LENGTH,
  CARD_PURPOSE_MAX_LENGTH,
  CUSTOM_FIELD,
  CustomFieldOption,
  CustomFieldType,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import { validateCardholderFullName } from 'services/utils';

const YES = 'yes';
const NO = 'no';

interface Props {
  onClose: () => void;
}

const AttributesStep = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const stepManager = useStepManager();
  const [addCustomCardholderName, setAddCustomCardholderName] = useState<
    boolean
  >(false);
  const formik = useFormikContext<FormValues>();
  const { projectPerTeamEnabled, visibleProjects } = useProjectsPerTeam(
    null,
    formik.values.teamId
  );

  const {
    firstNameNotPrintableError,
    lastNameNotPrintableError,
    fullNameTooLongError,
  } = addCustomCardholderName
    ? validateCardholderFullName(
        formik.values.customFirstName,
        formik.values.customLastName
      )
    : {
        firstNameNotPrintableError: null,
        lastNameNotPrintableError: null,
        fullNameTooLongError: null,
      };

  const isSubmitDisabled =
    (stepManager.attributesStepSettings.isPurposeTextareaVisible &&
      !formik.values.purpose.trim()) ||
    (stepManager.attributesStepSettings.areCustomNameInputsVisible &&
      addCustomCardholderName &&
      (!formik.values.customFirstName.trim() ||
        !formik.values.customLastName.trim() ||
        !!firstNameNotPrintableError ||
        !!lastNameNotPrintableError ||
        !!fullNameTooLongError));

  return (
    <>
      <DialogStepper<Step>
        currentStep={stepManager.currentStep}
        steps={stepManager.steps}
        goTo={stepManager.goTo}
        getStepLabel={stepManager.getStepLabel}
      />
      <DialogTitle>{t('issueCardDialog_v2.attributesStep.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // if there are empty mandatory custom fields, do not continue
            if (formik.values.customFields.length) {
              const emptyMandatoryCustomFields = formik.values.customFields.filter(
                (customFieldEntity, index) => {
                  const customFieldObject =
                    formik.values.customFieldsResponse[index];

                  if (
                    customFieldObject.mandatoryOnAutomation &&
                    ((customFieldObject.type === CustomFieldType.text &&
                      !(customFieldEntity as string).trim()) ||
                      (customFieldObject.type === CustomFieldType.select &&
                        !(customFieldEntity as CustomFieldOption | null)))
                  ) {
                    formik.setFieldError(
                      `customFields.${[index]}`,
                      t('issueCardDialog_v2.attributesStep.customFieldError')
                    );
                    return true;
                  }
                  return false;
                }
              );
              if (emptyMandatoryCustomFields.length) return;
            }

            stepManager.goNext();
          }}
          noValidate
          id="issue-card-attributes-form"
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            {stepManager.attributesStepSettings.isLabelInputVisible && (
              <Grid item xs={12}>
                <TextField
                  {...formik.getFieldProps('cardName')}
                  label={
                    <>
                      {t('issueCardDialog_v2.attributesStep.cardLabel')}
                      <InputLabelTooltipIcon
                        title={t(
                          'issueCardDialog_v2.attributesStep.cardLabelHint'
                        )}
                      />
                    </>
                  }
                  inputProps={{ maxLength: CARD_NAME_MAX_LENGTH }}
                />
              </Grid>
            )}
            {stepManager.attributesStepSettings.isPurposeTextareaVisible && (
              <Grid item xs={12}>
                <TextField
                  {...formik.getFieldProps('purpose')}
                  label={t('issueCardDialog_v2.attributesStep.purpose')}
                  multiline
                  maxRows={5}
                  inputProps={{ maxLength: CARD_PURPOSE_MAX_LENGTH }}
                />
              </Grid>
            )}
            {stepManager.attributesStepSettings.isDesignPickerVisible && (
              <Grid item xs={12}>
                <CardDesignPicker
                  cardConfigSetting={formik.values.cardConfigSetting!}
                  label={t('issueCardDialog_v2.attributesStep.cardColor')}
                  value={formik.values.cardDesignId}
                  onChange={(value) =>
                    formik.setFieldValue('cardDesignId', value)
                  }
                />
              </Grid>
            )}
            {stepManager.attributesStepSettings.isTeamsSelectVisible && (
              <Grid item xs={12}>
                <TeamSelect
                  {...formik.getFieldProps('teamId')}
                  onChange={(event) => {
                    formik.setFieldValue('teamId', event.target.value);
                    // reset project to be sure that selected project is visible for user
                    if (projectPerTeamEnabled)
                      formik.setFieldValue('projectId', '');
                  }}
                  selectedMember={formik.values.member!}
                  disabled={!canUser('card-team:assign')}
                  label={t('issueCardDialog_v2.attributesStep.team')}
                />
              </Grid>
            )}
            {stepManager.attributesStepSettings.isProjectsSelectVisible &&
              !!visibleProjects.length && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="card-project-select-label">
                      {t('issueCardDialog_v2.attributesStep.project')}
                    </InputLabel>
                    <Select<string>
                      {...formik.getFieldProps('projectId')}
                      renderValue={(selected) => {
                        const project = visibleProjects.find(
                          (item) => item.id === selected
                        );
                        return project?.name;
                      }}
                      labelId="card-project-select-label"
                    >
                      <MenuItem value="">
                        {t('issueCardDialog_v2.attributesStep.noneProject')}
                      </MenuItem>
                      {visibleProjects.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            {stepManager.attributesStepSettings.areCustomNameInputsVisible && (
              <>
                <Grid item xs={12}>
                  <Box mt={2}>
                    <FormControl
                      disabled={formik.isSubmitting}
                      variant="standard"
                    >
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        component="span"
                      >
                        <Typography variant="body2">
                          {t(
                            'issueCardDialog_v2.attributesStep.addCustomCardholderName'
                          )}
                        </Typography>
                        <FormControlLabelTooltipIcon
                          title={t(
                            'issueCardDialog_v2.attributesStep.addCustomCardholderNameHint'
                          )}
                        />
                      </Box>
                      <RadioGroup
                        row
                        value={addCustomCardholderName ? YES : NO}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setAddCustomCardholderName(e.target.value === YES);
                          formik.setFieldValue('customFirstName', '');
                          formik.setFieldValue('customLastName', '');
                        }}
                      >
                        <FormControlLabel
                          value={NO}
                          control={<Radio />}
                          label={t('common.no')}
                        />
                        <FormControlLabel
                          value={YES}
                          control={<Radio />}
                          label={t('common.yes')}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>
                {addCustomCardholderName && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        {...formik.getFieldProps('customFirstName')}
                        label={t(
                          'issueCardDialog_v2.attributesStep.customFirstName'
                        )}
                        inputProps={{ maxLength: 50 }}
                        error={
                          !!firstNameNotPrintableError || !!fullNameTooLongError
                        }
                        helperText={firstNameNotPrintableError}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        {...formik.getFieldProps('customLastName')}
                        label={t(
                          'issueCardDialog_v2.attributesStep.customLastName'
                        )}
                        inputProps={{ maxLength: 50 }}
                        error={
                          !!lastNameNotPrintableError || !!fullNameTooLongError
                        }
                        helperText={lastNameNotPrintableError}
                      />
                    </Grid>
                    {!!fullNameTooLongError && (
                      <Grid item xs={12}>
                        <FormHelperText error>
                          {fullNameTooLongError}
                        </FormHelperText>
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
            {stepManager.attributesStepSettings.isCirculaCheckboxVisible && (
              <Grid item xs={12}>
                <FormControlLabel
                  label={t(
                    'issueCardDialog_v2.attributesStep.syncCirculaLabel'
                  )}
                  control={<Checkbox />}
                  checked={formik.values.circulaSynced}
                  onChange={(e, checked: boolean) =>
                    formik.setFieldValue('circulaSynced', checked)
                  }
                />
              </Grid>
            )}

            {formik.values.customFields.map((_, index) => {
              // add other types when we have them (not supported on BE yet)
              const customFieldObject =
                formik.values.customFieldsResponse[index];
              if (
                !customFieldObject.type ||
                ![CustomFieldType.text, CustomFieldType.select].includes(
                  customFieldObject.type
                )
              )
                return null;

              return (
                <Grid
                  item
                  xs={12}
                  key={formik.values.customFieldsResponse[index].id}
                >
                  {customFieldObject.type === CustomFieldType.text && (
                    <TextField
                      {...formik.getFieldProps(`customFields[${index}]`)}
                      label={`${customFieldObject.label}${
                        customFieldObject.mandatoryOnAutomation
                          ? ` (${t(
                              'issueCardDialog_v2.attributesStep.mandatory'
                            )})`
                          : ''
                      }`}
                      onChange={(e) => {
                        formik.setFieldValue(
                          `customFields.${[index]}`,
                          e.target.value
                        );
                        formik.setFieldError(`customFields.${[index]}`, '');
                      }}
                      multiline
                      maxRows={2}
                      inputProps={{ maxLength: CUSTOM_FIELD.valueMaxLength }}
                      error={!!formik.errors.customFields?.[index]}
                      helperText={formik.errors.customFields?.[index]}
                    />
                  )}

                  {customFieldObject.type === CustomFieldType.select && (
                    <CustomFieldOptionsAutocomplete
                      customFieldObject={customFieldObject}
                      value={
                        formik.values.customFields[
                          index
                        ] as CustomFieldOption | null
                      }
                      componentKey={'issueCardDialog_v2.attributesStep'}
                      error={formik.errors.customFields?.[index]}
                      onChange={(option) => {
                        formik.setFieldValue(`customFields.${[index]}`, option);
                        formik.setFieldError(`customFields.${[index]}`, '');
                      }}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Box flexGrow="1">
          <Button variant="text" onClick={stepManager.goPrev}>
            {t('common.button.back')}
          </Button>
        </Box>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          form="issue-card-attributes-form"
          type="submit"
          disabled={isSubmitDisabled}
        >
          {t('common.button.continue')}
        </Button>
      </DialogActions>
    </>
  );
};

export default AttributesStep;
