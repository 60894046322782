import React, { useEffect, useMemo, useRef, useState } from 'react';
import lodashIntersection from 'lodash/intersection';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import BulkUploadItemsDialog from 'components/BulkUploadItemsDialog';
import { useGlobalState } from 'context/GlobalState';
import { IssueCardDialog } from 'domains/card/dialogs';
import { CardDetailsPage } from 'domains/card/pages';
import { AddNewItemMenu } from 'domains/settings/components/AddNewItemMenu';
import {
  Button,
  DataGrid,
  gridUtils,
  LoaderWithOverlay,
  useGridApiRef,
  VerticalCardsIcon,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import {
  PageHeader,
  PageTableContent,
  PageTitle,
  PageTitleActions,
} from 'layout';
import {
  Card,
  CardConfigGroup,
  cardSimpleStatuses,
  DEFAULT_PAGE_LIMIT,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getPath, isSortValid } from 'services/utils';
import Filters from './Filters';
import NoCards from './NoCards';
import useColumns from './useColumns';

const getQueryParams = (
  qs: string,
  allowedSortKeys: string[],
  allowedCardConfigs: CardConfigGroup[]
) => {
  const { sort, q, cardConfigGroups, status } = Object.fromEntries(
    new URLSearchParams(qs).entries()
  );

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '+status',
    q: q ? q.trim() : '',
    cardConfigGroups: cardConfigGroups
      ? lodashIntersection(cardConfigGroups.split(','), allowedCardConfigs)
      : [],
    status: status
      ? lodashIntersection(status.split(','), cardSimpleStatuses)
      : [],
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({ cardConfigGroups, status }: QueryParams) =>
  0 + (cardConfigGroups.length ? 1 : 0) + (status.length ? 1 : 0);

interface State {
  isLoading: boolean;
  cards: Card[];
  hasNextPage: boolean;
  totalCount: number;
}

const CardsPage = () => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { columns, allowedSortKeys } = useColumns();
  const canUser = useCanUser();
  const showPageError = useShowPageError();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:cardId',
    true
  );
  const {
    state: { organization, cardConfigSettings },
  } = useGlobalState();
  const allowedCardConfigGroups = useMemo(
    () => cardConfigSettings.map((item) => item.cardConfigGroup),
    [cardConfigSettings]
  );
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(
    getQueryParams(location.search, allowedSortKeys, allowedCardConfigGroups)
  );
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const [state, setState] = useState<State>({
    isLoading: true,
    cards: [],
    hasNextPage: false,
    totalCount: 0,
  });
  const [isIssueCardDialogOpen, setIsIssueCardDialogOpen] = useState(false);
  const [isUploadCardsDialogOpen, setIsUploadCardsDialogOpen] = useState(false);
  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;
  const isEmptyState = !state.cards.length && !areFiltersApplied;

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      setState((state) => ({ ...state, isLoading: true }));
      const { sort, q, cardConfigGroups, status } = paramsRef.current;
      const { cards, hasNextPage, totalCount } = await api.getCards({
        page,
        limit,
        sort: sort.includes('issuingDate') ? sort : `${sort},-issuingDate`,
        q: q.length ? q : undefined,
        cardConfigGroups: cardConfigGroups.length
          ? cardConfigGroups.join()
          : undefined,
        status: status.length ? status.join() : undefined,
        organizationId: organization!.id,
      });
      if (!mounted.current) return;
      setState((state) => ({
        isLoading: false,
        cards: isLoadMore ? [...state.cards, ...cards] : cards,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });

    paramsRef.current = getQueryParams(
      location.search,
      allowedSortKeys,
      allowedCardConfigGroups
    );
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const onCardUpdate = (card: Card) => {
    setState((prevState) => ({
      ...prevState,
      cards: prevState.cards.map((item) =>
        item.cardId === card.cardId ? card : item
      ),
    }));
  };

  const onCardIssued = () => {
    setIsIssueCardDialogOpen(false);
    getData(0, (pageRef.current + 1) * DEFAULT_PAGE_LIMIT);
  };

  const onCardReplace = (newCard: Card) => {
    getData(0, (pageRef.current + 1) * DEFAULT_PAGE_LIMIT);
    history.push(
      generatePath(getPath('cardDetails'), {
        orgId: organization!.id,
        cardId: newCard.cardId,
      })
    );
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('cardsPage.cards')}>
          {canUser('card:bulk-upload') ? (
            <AddNewItemMenu
              format="XLSX"
              title={t('int.cardsPage.addCard')}
              onAdd={() => setIsIssueCardDialogOpen(true)}
              onUpload={() => setIsUploadCardsDialogOpen(true)}
            />
          ) : canUser('card:create') ? (
            <PageTitleActions>
              <Button
                onClick={() => setIsIssueCardDialogOpen(true)}
                startIcon={<VerticalCardsIcon />}
                data-test-id="issue-card-btn"
              >
                {t('cardsPage.issueNewCard')}
              </Button>
            </PageTitleActions>
          ) : null}
        </PageTitle>
        <Filters
          params={paramsRef.current}
          setParam={setQueryParam}
          selectedFiltersCount={selectedFiltersCount}
          disabled={isEmptyState}
          cardsCount={state.totalCount}
        />
      </PageHeader>

      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid<Card>
          apiRef={dataGridRef}
          rowHeight={72}
          getRowId={(row) => row.cardId}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          rowCount={state.totalCount}
          rowSelectionModel={
            detailsParams?.cardId ? [detailsParams.cardId] : []
          }
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(paramsRef.current.sort),
            },
          }}
          loading={state.isLoading}
          rows={state.cards}
          columns={columns}
          columnVisibilityModel={{
            issuingDate: !isDetailsPageOpen,
            expiryDate: !isDetailsPageOpen,
            transactionLimit: !isDetailsPageOpen,
            limit: !isDetailsPageOpen,
            limitRenewFrequency: !isDetailsPageOpen,
            drawerPlaceholder: isDetailsPageOpen,
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ id, row }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${row.cardId}${location.search}`);
          }}
          onSortModelChange={(sort) => {
            if (state.isLoading || !state.cards.length) return;
            setQueryParam('sort', gridUtils.getNewSortParam(sort));
          }}
          slots={{
            noRowsOverlay: () => (
              <NoCards
                areFiltersApplied={areFiltersApplied}
                onIssueCardClick={() => setIsIssueCardDialogOpen(true)}
              />
            ),
            loadingOverlay: () => null,
          }}
        />

        <Route
          path={`${path}/:cardId`}
          children={({ match }) => (
            <CardDetailsPage
              open={!!match}
              onUpdate={onCardUpdate}
              onCardReplace={onCardReplace}
            />
          )}
        />
      </PageTableContent>

      <IssueCardDialog
        open={isIssueCardDialogOpen}
        onClose={() => setIsIssueCardDialogOpen(false)}
        onSuccess={onCardIssued}
      />
      <BulkUploadItemsDialog
        open={isUploadCardsDialogOpen}
        onClose={() => setIsUploadCardsDialogOpen(false)}
        context="cards"
      />
    </>
  );
};

export default withPageConfig(CardsPage, { permission: 'cards-page:visit' });
