import { ReactNode } from 'react';
import { ListItem, ListItemIcon } from '@mui/material';
import { EditButton } from 'elements/Button';
import { ListItemTextReversed } from 'elements/ListItem';
import Tooltip from 'elements/Tooltip';

export interface LabeledValueProps {
  label: ReactNode;
  value: ReactNode;
  icon?: ReactNode;
  onEdit?: () => void;
  disabled?: boolean;
  disabledTooltip?: string | ReactNode;
}

/**
 * Abstraction on top of ListItem component to display editable values with labels.
 */
const LabeledValue = ({
  label,
  value,
  icon,
  onEdit,
  disabled,
  disabledTooltip,
}: LabeledValueProps) => {
  return (
    <ListItem
      component="div"
      disableGutters
      divider
      secondaryAction={
        onEdit && (
          <Tooltip title={disabled ? disabledTooltip : null}>
            <span>
              <EditButton
                disabled={disabled}
                onClick={onEdit}
                size="large"
                data-test-id="edit-button"
              />
            </span>
          </Tooltip>
        )
      }
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemTextReversed
        primary={value}
        secondary={label}
        primaryTypographyProps={{ component: 'div', noWrap: true }}
        secondaryTypographyProps={{ component: 'div', noWrap: true }}
      />
    </ListItem>
  );
};

export default LabeledValue;
