import React, { useMemo } from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';

/**
 * This HOC overrides theme.navigationMode to "light" to show
 * the MainHeader component in light mode to admins.
 */
const withLightNavigationModeTheme = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => (props: P): JSX.Element => {
  const theme = useTheme();
  const lightNavigationModeTheme = useMemo(
    () =>
      createTheme(theme, {
        navigationMode: 'light',
      }),
    [theme]
  );

  return (
    <ThemeProvider theme={lightNavigationModeTheme}>
      <Component {...props} />
    </ThemeProvider>
  );
};

export default withLightNavigationModeTheme;
