import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from 'elements';
import useQueryParams from 'hooks/useQueryParams';

/**
 * This page is temporary
 * It should be replaced by Auth0 Forms (https://forms.auth0.com/tenants/eu/infinnitydevinternal/forms) when it will be possible to manage them with Terraform
 * https://github.com/auth0/terraform-provider-auth0/issues/957
 */

const AccountLinking = () => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();

  return (
    <Box display="flex" height="100vh" minWidth={1000} minHeight={740}>
      <Helmet>
        <title>{t('accountLinking.title')}</title>
      </Helmet>
      <Box
        position="relative"
        overflow="hidden"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flexShrink={0}
        minHeight={640}
        width={400}
        padding="40px 40px 24px 40px"
        margin="auto"
        borderRadius="16px"
        bgcolor="white"
        boxShadow="0 12px 40px rgba(0, 0, 0, 0.12)"
      >
        <Typography
          mt={3}
          mb={2}
          fontSize={24}
          fontWeight={700}
          textAlign="center"
        >
          {t('accountLinking.title')}
        </Typography>
        <Typography variant="body1" textAlign="center">
          {t('accountLinking.description')}
        </Typography>
        <Box
          mt="24px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            size="large"
            fullWidth
            href={`https://${queryParams.domain}/continue?state=${queryParams.state}`}
          >
            {t('common.button.continue')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountLinking;
