import { CardIcon, CardStatusBadge } from 'domains/card/components';
import { getCardName } from 'domains/card/utils';
import { Box, Tooltip, Typography } from 'elements';
import { CardConfig, CardSimpleStatus } from 'services/constants';

interface CardIconWithDetailsProps {
  cardConfig: CardConfig;
  cardDesignId: string;
  cardRefNum: string | null;
  cardName: string | null;
  cardSimpleStatus?: CardSimpleStatus;
  showCardNameTooltip?: boolean;
  iconAlign?: 'left' | 'right';
}

const CardIconWithDetails = ({
  iconAlign = 'left',
  cardConfig,
  cardDesignId,
  cardRefNum,
  cardName,
  cardSimpleStatus,
  showCardNameTooltip = false,
}: CardIconWithDetailsProps) => {
  return (
    <Tooltip title={cardName && showCardNameTooltip ? cardName : ''}>
      <Box display="flex" alignItems="center" minWidth={0}>
        {iconAlign === 'left' && <CardIcon cardDesignId={cardDesignId} />}

        <Box
          overflow="hidden"
          ml={iconAlign === 'right' ? 0 : 2}
          textAlign={iconAlign}
        >
          <Typography component="div" variant="body2" noWrap>
            {cardName || getCardName(cardConfig)}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={iconAlign === 'right' ? 'flex-end' : undefined}
          >
            <Typography
              variant="caption"
              color="textSecondary"
              noWrap
              sx={{
                display: 'inline-block',
              }}
            >
              {cardRefNum || '****'}
            </Typography>

            {cardSimpleStatus && (
              <CardStatusBadge status={cardSimpleStatus} sx={{ ml: 1 }} />
            )}
          </Box>
        </Box>

        {iconAlign === 'right' && (
          <Box ml={1.5}>
            <CardIcon cardDesignId={cardDesignId} />
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};

export default CardIconWithDetails;
