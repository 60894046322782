import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'elements';
import { ContentContainer } from 'layout';
import { useCanUser } from 'services/rbac';
import BillingAddressGroup from './BillingAddressGroup';
import BillingEmailAddressFormGroup from './BillingEmailAddressGroup';
import DeliveryAddressFormGroup from './DeliveryAddressFormGroup';
import FoundationDateGroup from './FoundationDateGroup';
import LegalNameGroup from './LegalNameGroup';
import OrganizationDocuments from './OrganizationDocuments';
import RegistrationGroup from './RegistrationGroup';
import TradeNameGroup from './TradeNameGroup';
import VatIdFormGroup from './VatIdFormGroup';

const GeneralSubPage = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();

  return (
    <ContentContainer pb={4}>
      <Typography variant="h6" mb={4}>
        {t('settingsOrganizationPage.detailsTitle')}
      </Typography>

      <Grid container columnSpacing={4} rowSpacing={3} flexWrap="wrap">
        <LegalNameGroup />

        <TradeNameGroup />

        <BillingAddressGroup />

        <BillingEmailAddressFormGroup />

        <RegistrationGroup />

        {canUser('org-foundation-date:change') && <FoundationDateGroup />}

        <VatIdFormGroup />

        <DeliveryAddressFormGroup />
      </Grid>

      <OrganizationDocuments />
    </ContentContainer>
  );
};

export default GeneralSubPage;
