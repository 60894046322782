import {
  Box,
  BoxProps,
  CircularProgress,
  ReceiptExternalLinkIcon,
  ReceiptXIcon,
  Tooltip,
} from 'elements';
import { ReceiptsAutoMatchingStatus } from 'services/constants';

interface Props extends BoxProps {
  status: ReceiptsAutoMatchingStatus;
  thumbnail: string;
  isLoading?: boolean;
  hoverEnabled?: boolean;
}

const ReceiptInboxThumbnail = ({
  status,
  thumbnail,
  isLoading,
  hoverEnabled = true,
  ...props
}: Props) => {
  const getThumbnail = () => {
    if (isLoading || status === ReceiptsAutoMatchingStatus.processing)
      return <CircularProgress size="small" />;

    if (status === ReceiptsAutoMatchingStatus.notReadable)
      return <ReceiptXIcon color="error" />;

    if (status === ReceiptsAutoMatchingStatus.externalLink)
      return <ReceiptExternalLinkIcon color="info" />;

    if (thumbnail) {
      const ImageComponent = (
        <Box
          sx={{
            cursor: hoverEnabled ? 'pointer' : 'default',
            backgroundImage: `url('data:image/jpeg;base64,${thumbnail}')`,
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% auto',
            height: '100%',
            width: '100%',
          }}
        />
      );

      return hoverEnabled ? (
        <Tooltip
          placement="right"
          title={
            <Box height="192px" overflow="hidden">
              <img
                style={{ maxWidth: '100%' }}
                src={`data:image/jpeg;base64,${thumbnail}`}
                alt="Receipt thumbnail"
              />
            </Box>
          }
          PopperProps={{
            sx: (theme) => ({
              '.MuiTooltip-tooltip': {
                background: theme.palette.common.white,
                boxShadow: theme.shadows[4],
                color: theme.palette.text.primary,
                maxWidth: '328px',
              },
              '.MuiTooltip-arrow': {
                color: theme.palette.common.white,
              },
            }),
          }}
        >
          {ImageComponent}
        </Tooltip>
      ) : (
        ImageComponent
      );
    }

    return null;
  };

  return (
    <Box
      height={(theme) => theme.spacing(5)}
      width={(theme) => theme.spacing(5)}
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      {getThumbnail()}
    </Box>
  );
};

export default ReceiptInboxThumbnail;
