import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryParams } from 'domains/card/pages/CardsPage/index';
import { Chip, Tooltip, Typography } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import {
  getFilterChipText,
  PageFilterChips,
  PageFiltersComponent,
} from 'layout';
import FiltersDrawer from './FiltersDrawer';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  disabled: boolean;
  cardsCount: number;
}

const Filters = ({
  params,
  setParam,
  selectedFiltersCount,
  disabled,
  cardsCount,
}: Props) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const cardConfigGroupsChipTooltip = useMemo(() => {
    if (params.cardConfigGroups.length < 2) return '';
    return params.cardConfigGroups
      .map((item) => t(`cardNames.${item}`))
      .join(', ');
  }, [params.cardConfigGroups]);

  const statusChipTooltip = useMemo(() => {
    if (params.status.length < 2) return '';
    return params.status
      .map(
        (item) =>
          t(`cardStatuses.${item}`)[0].toUpperCase() +
          t(`cardStatuses.${item}`).slice(1)
      )
      .join(', ');
  }, [params.status]);

  return (
    <>
      <PageFiltersComponent
        count={selectedFiltersCount}
        q={params.q}
        setQParam={(value) => setParam('q', value)}
        onOpen={() => setIsFilterOpen(true)}
        disabled={disabled}
      >
        <Typography variant="body2" color="textSecondary" ml={2}>
          {t('common.resultsTable', { count: cardsCount })}
        </Typography>
      </PageFiltersComponent>

      {!!selectedFiltersCount && (
        <PageFilterChips>
          {!!params.status.length && (
            <Tooltip title={statusChipTooltip}>
              <Chip
                label={getFilterChipText(
                  t(`cardStatuses.${params.status[0]}`)[0].toUpperCase() +
                    t(`cardStatuses.${params.status[0]}`).slice(1),
                  params.status.length
                )}
                onDelete={() => setParam('status', [])}
              />
            </Tooltip>
          )}
          {!!params.cardConfigGroups.length && (
            <Tooltip title={cardConfigGroupsChipTooltip}>
              <Chip
                label={getFilterChipText(
                  t(`cardNames.${params.cardConfigGroups[0]}`),
                  params.cardConfigGroups.length
                )}
                onDelete={() => setParam('cardConfigGroups', [])}
              />
            </Tooltip>
          )}
        </PageFilterChips>
      )}

      <FiltersDrawer
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        params={params}
        setParam={setParam}
      />
    </>
  );
};

export default Filters;
