import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { AccountingItemStatus, VatRateStatus } from 'services/constants';

type Value = VatRateStatus.missing | Omit<VatRateStatus.missing, string>;

interface Props {
  value: Value;
  onChange: (value: Value) => void;
}

const VatRateFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const {
    state: { vatRates },
  } = useGlobalState();

  const visibleVatRates =
    sortBy(
      vatRates?.filter((item) => item.status === AccountingItemStatus.active),
      (v) => v.name.toLowerCase()
    ) || [];

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-vatRate"
        id="panel-vatRate-header"
      >
        <Typography>{t('filters.vatRate')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<Value>
            size="medium"
            displayEmpty
            value={value}
            onChange={(e) => onChange(e.target.value as Value)}
            renderValue={(selected) => {
              if (!selected)
                return (
                  <Typography color="text.disabled">
                    {t('filters.vatRate')}
                  </Typography>
                );

              if (selected === VatRateStatus.missing)
                return <Typography>—</Typography>;

              const vatRate = visibleVatRates.find(
                (item) => item.id === selected
              );
              return vatRate?.name || '';
            }}
          >
            <MenuItem value="">
              <Typography color="text.disabled">
                {t('filters.vatRate')}
              </Typography>
            </MenuItem>

            <MenuItem value={VatRateStatus.missing}>—</MenuItem>

            {visibleVatRates.map((vatRate) => (
              <MenuItem key={vatRate.id} value={vatRate.id}>
                {vatRate.name} (
                <FormatPercent
                  value={vatRate.rate}
                  options={{
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }}
                />
                )
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default VatRateFilter;
