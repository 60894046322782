import { difference } from 'lodash';
import { OnboardingDocumentType } from 'services/constants';

export enum ValidationErrorCode {
  ATTRIBUTE_DATE_FORMAT_INVALID = 'ATTRIBUTE_DATE_FORMAT_INVALID',
  ATTRIBUTE_MISSING = 'ATTRIBUTE_MISSING',
  ATTRIBUTE_NOT_SUPPORTED = 'ATTRIBUTE_NOT_SUPPORTED',
  NAME_INVALID = 'NAME_INVALID',
  NAME_MISSING = 'NAME_MISSING',
  INVALID_INPUT_STRING = 'INVALID_INPUT_STRING',
}

const validateParsedInfoRequest = (
  request: { [key: string]: string },
  informationRequestTypes: OnboardingDocumentType[]
) => {
  const { name, ...attributes } = request;
  if (!name) return ValidationErrorCode.NAME_MISSING;

  const informationRequestType = informationRequestTypes.find(
    (item) => item.name === name
  );
  if (!informationRequestType) return ValidationErrorCode.NAME_INVALID;

  if (
    difference(
      informationRequestType.allowedAttributes,
      Object.keys(attributes)
    ).length > 0
  ) {
    return ValidationErrorCode.ATTRIBUTE_MISSING;
  }

  if (
    difference(
      Object.keys(attributes),
      informationRequestType.allowedAttributes
    ).length > 0
  ) {
    return ValidationErrorCode.ATTRIBUTE_NOT_SUPPORTED;
  }

  if (attributes.date && !/^\d{4}-\d{2}-\d{2}$/.test(attributes.date)) {
    return ValidationErrorCode.ATTRIBUTE_DATE_FORMAT_INVALID;
  }

  return null;
};

export const parseInformationRequestString = (
  rawString: string,
  informationRequestTypes: OnboardingDocumentType[]
) => {
  const parsedInfoRequests: { [key: string]: string }[] = [];
  let isValid = true;

  const infoRequestStrings = rawString.trim().split(';').filter(Boolean);
  infoRequestStrings.forEach((infoRequestString) => {
    const infoRequestAttributeStrings = infoRequestString.split(',');
    const infoRequestAttributes = infoRequestAttributeStrings.reduce(
      (acc, curr) => {
        const [key, value] = curr.split(':');
        if (!key || !value) {
          isValid = false;
          return acc;
        }
        return {
          ...acc,
          [key]: decodeURIComponent(value),
        };
      },
      {}
    );
    parsedInfoRequests.push(infoRequestAttributes);
  });
  if (infoRequestStrings.length === 0) {
    return {
      isValid: false,
      result: [],
      validationErrorCodes: [ValidationErrorCode.INVALID_INPUT_STRING],
    };
  }

  const validationErrorCodes = parsedInfoRequests
    .map((item) => validateParsedInfoRequest(item, informationRequestTypes))
    .filter(Boolean) as ValidationErrorCode[];

  return {
    result: parsedInfoRequests,
    isValid: isValid && validationErrorCodes.length === 0,
    validationErrorCodes,
  };
};
