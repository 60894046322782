import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';

/**
 * Returns cards that can be issued / requested by users.
 */
const useAvailableCardConfigSettings = (isCardRequestFlow: boolean = false) => {
  const {
    state: { cardConfigSettings, featureModules },
  } = useGlobalState();

  return useMemo(
    () =>
      cardConfigSettings
        .filter(
          (item) =>
            item.canBeIssued &&
            (!item.premiumProductType || !isCardRequestFlow) &&
            (featureModules.SINGLE_USE_CARDS || item.maxUsage !== 1)
        )
        // Group by cardConfigGroup
        .filter(
          (cardConfig, i, filteredCardConfigSettings) =>
            filteredCardConfigSettings.findIndex(
              (config) => config.cardConfigGroup === cardConfig.cardConfigGroup
            ) === i
        ),
    [cardConfigSettings, featureModules, isCardRequestFlow]
  );
};

export default useAvailableCardConfigSettings;
