import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { useWaitForReceiptDataExtraction } from 'domains/transaction/hooks';
import { isTransactionReadonly } from 'domains/transaction/utils';
import {
  Badge,
  Box,
  Button,
  FormControlLabel,
  MinusIcon,
  PlusIcon,
  Switch,
  Typography,
} from 'elements';
import useIsAdminApp from 'hooks/useIsAdminApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  NetworkErrorCode,
  Receipt,
  ReceiptAutomatchStatus,
  ReceiptInboxMatchingFlow,
  ReceiptList,
  ReceiptStatus,
  Transaction,
  TransactionStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import ReceiptDate from './ReceiptDate';
import ReceiptInputs from './ReceiptInputs';
import ReceiptNumber from './ReceiptNumber';
import ThumbnailPreview from './ThumbnailPreview';

interface State {
  isUploadInProgress: boolean;
  // a new name for isDropzoneVisible + inbox receipt matching component
  isReceiptInputVisible: boolean;
  isReceiptNotNeededChanging: boolean;
  receiptNeeded: boolean;
  receiptNumber: string;
  isReceiptNumberError: boolean;
  receiptDate: string;
  isReceiptDateError: boolean;
  isExtractReceiptDataLoading: boolean;
}

interface Props {
  isGlobalTxPage?: boolean;
  transaction: Transaction;
  receipts: Receipt[];
  receiptDateStatus?: ReceiptAutomatchStatus;
  isReceiptDateEditing?: boolean;
  receiptNumberStatus?: ReceiptAutomatchStatus;
  onReceiptDateEditChange?: (isEditing: boolean) => void;
  isReceiptNumberEditing?: boolean;
  onReceiptNumberEditChange?: (isEditing: boolean) => void;
  onUpdate: (receiptsListFromPolling?: ReceiptList) => void;
  onRefresh: () => void;
  isReceiptPreviewDialogOpen: boolean;
  onReceiptPreviewDialogOpen: (receiptId?: string) => void;
  onReceiptDataUpdate?: (
    key: 'receiptNumber' | 'receiptDate',
    value: string
  ) => void;
}

const TransactionReceipts = ({
  isGlobalTxPage = false,
  transaction,
  receipts,
  receiptDateStatus = ReceiptAutomatchStatus.NONE,
  isReceiptDateEditing,
  onReceiptDateEditChange,
  receiptNumberStatus = ReceiptAutomatchStatus.NONE,
  isReceiptNumberEditing,
  onReceiptNumberEditChange,
  onUpdate,
  onRefresh,
  isReceiptPreviewDialogOpen,
  onReceiptPreviewDialogOpen,
  onReceiptDataUpdate,
}: Props) => {
  const {
    state: { featureModules, receiptsSettings, receiptInbox },
  } = useGlobalState();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const canUser = useCanUser();

  const updateNotNeededRequestTime = useRef<number>(0);
  const api = useImperativeApi();
  const isAdminApp = useIsAdminApp();
  const waitForReceiptDataExtraction = useWaitForReceiptDataExtraction();

  const receiptsNotNeededHidden = !featureModules.RECEIPT_MANAGEMENT;
  const receiptsAreReadOnly =
    isTransactionReadonly(transaction, isAdminApp) ||
    !featureModules.RECEIPT_MANAGEMENT;
  const receiptInboxMatchingFlowInProgress =
    receiptInbox.flow === ReceiptInboxMatchingFlow.selectedReceiptMatching;

  const [state, setState] = useState<State>({
    isUploadInProgress: false,
    isReceiptInputVisible:
      (receipts.length === 0 ||
        (receiptInboxMatchingFlowInProgress && !!receiptInbox.receipt)) &&
      transaction.receiptNeeded &&
      !receiptsAreReadOnly,
    isReceiptNotNeededChanging: false,
    receiptNeeded: transaction.receiptNeeded,
    receiptNumber: transaction.receiptNumber || '',
    isReceiptNumberError: false,
    receiptDate: transaction.receiptDate || '',
    isReceiptDateError: false,
    isExtractReceiptDataLoading: false,
  });

  // For cardholders 'receipt not needed' should be inactive based on receipt configs
  const receiptNotNeededInactive =
    receiptsSettings?.receiptNotNeeded &&
    !canUser('receipt-not-needed-restriction:bypass');

  const isReversedReceiptViewOnly =
    !isGlobalTxPage && transaction.status === TransactionStatus.reversed;

  useEffect(() => {
    if (
      transaction.receiptNumber !== state.receiptNumber ||
      transaction.receiptDate !== state.receiptDate
    ) {
      setState((prevState) => ({
        ...prevState,
        receiptNumber: transaction.receiptNumber,
        receiptDate: transaction.receiptDate || '',
      }));
    }
  }, [transaction]);

  useEffect(() => {
    // show dropzone/"receipt inbox match button" after
    // user deleted all receipts in transaction
    if (
      receipts.length === 0 &&
      !state.isReceiptNotNeededChanging &&
      state.receiptNeeded &&
      !state.isReceiptInputVisible &&
      isReceiptPreviewDialogOpen
    ) {
      setState((prevState) => ({ ...prevState, isReceiptInputVisible: true }));
    }
  }, [
    receipts.length,
    state.receiptNeeded,
    state.isReceiptInputVisible,
    state.isReceiptNotNeededChanging,
    isReceiptPreviewDialogOpen,
  ]);

  if (
    (receiptsAreReadOnly &&
      !receipts.length &&
      (transaction.receiptNeeded || receiptsNotNeededHidden)) ||
    (!receipts.length && isReversedReceiptViewOnly)
  ) {
    return null;
  }

  const updateReceiptNotNeeded = async (newValue: boolean) => {
    const requestTime = Date.now();
    updateNotNeededRequestTime.current = requestTime;
    try {
      setState((state) => ({
        ...state,
        isReceiptNotNeededChanging: true,
        receiptNeeded: newValue,
        isReceiptInputVisible: newValue,
      }));
      await api.updateReceiptNeeded(transaction.transactionId, newValue);
      if (!mounted.current) return;
      if (updateNotNeededRequestTime.current !== requestTime) return;
      setState((state) => ({ ...state, isReceiptNotNeededChanging: false }));
      onUpdate();
    } catch (error) {
      if (!mounted.current) return;
      if (updateNotNeededRequestTime.current !== requestTime) return;

      let errorMessage;
      let errorSpecificState = {};
      const errorCode = getNetworkErrorCode(error);
      if (errorCode === NetworkErrorCode.receiptUploadNotEnabled) {
        errorMessage = getGenericErrorMsg(error);
        onRefresh();
      } else if (errorCode === NetworkErrorCode.hasReceipts) {
        errorMessage = t('transactionReceipts.hasReceiptsErrorMessage');
        errorSpecificState = {
          receiptNeeded: transaction.receiptNeeded,
          isReceiptInputVisible: false,
        };
        onRefresh();
      } else {
        errorMessage = getGenericErrorMsg(error);
        errorSpecificState = { receiptNeeded: transaction.receiptNeeded };
        logError(error);
      }

      setState((state) => ({
        ...state,
        isReceiptNotNeededChanging: false,
        ...errorSpecificState,
      }));
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    }
  };

  const onReceiptUpload = async () => {
    let receiptPollingEnabled =
      !isGlobalTxPage && transaction?.receiptsCount === 0;

    // update data just after upload
    onUpdate();

    setState((prevState) => ({
      ...prevState,
      isExtractReceiptDataLoading: receiptPollingEnabled,
      isReceiptInputVisible: false,
      isUploadInProgress: false,
    }));

    if (!receiptPollingEnabled) return;

    try {
      const data = await waitForReceiptDataExtraction({
        transactionId: transaction.transactionId,
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isExtractReceiptDataLoading: false,
      }));
      onUpdate(data);
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isExtractReceiptDataLoading: false,
      }));
      logError(error);
    }
  };

  return (
    <Box p={3} borderTop={(theme) => `1px solid ${theme.palette.divider}`}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="overline">
          {t('transactionReceipts.label')}
        </Typography>

        {!!receipts.length &&
          !isReversedReceiptViewOnly &&
          !receiptsAreReadOnly &&
          !receiptInboxMatchingFlowInProgress && (
            <Button
              disabled={
                !canUser('receipt:upload') ||
                state.isReceiptNotNeededChanging ||
                state.isUploadInProgress ||
                !transaction.receiptUploadEnabled
              }
              size="small"
              variant="text"
              onClick={() => {
                setState((state) => ({
                  ...state,
                  isReceiptInputVisible: !state.isReceiptInputVisible,
                }));
              }}
              data-test-id="receipt-toggle-upload"
              startIcon={
                state.isReceiptInputVisible ? <MinusIcon /> : <PlusIcon />
              }
            >
              {state.isReceiptInputVisible
                ? t('transactionReceipts.hideLabel')
                : t('transactionReceipts.addReceiptLabel')}
            </Button>
          )}

        {!receiptsNotNeededHidden &&
          !receipts.length &&
          !receiptsAreReadOnly &&
          !receiptNotNeededInactive &&
          !receiptInboxMatchingFlowInProgress && (
            <FormControlLabel
              sx={{ mr: 0 }}
              disabled={
                !canUser('receipt-not-needed:change') ||
                state.isUploadInProgress
              }
              checked={!state.receiptNeeded}
              onChange={(e, checked) => {
                updateReceiptNotNeeded(!checked);
              }}
              control={<Switch />}
              label={t('transactionReceipts.notNeededLabel')}
            />
          )}
        {!transaction.receiptNeeded &&
          !receipts.length &&
          !receiptsNotNeededHidden &&
          (receiptsAreReadOnly || receiptNotNeededInactive) && (
            <div>{t('transactionReceipts.notNeededLabel')}</div>
          )}
      </Box>

      {state.isReceiptInputVisible && (
        <ReceiptInputs
          isGlobalTxPage={isGlobalTxPage}
          transaction={transaction}
          receipts={receipts}
          disabled={state.isReceiptNotNeededChanging}
          onReceiptMatch={() => {
            setState((prevState) => ({
              ...prevState,
              isReceiptInputVisible: false,
            }));
            onRefresh();
          }}
          onUploadSuccess={onReceiptUpload}
          onUploadStart={() =>
            setState((prevState) => ({
              ...prevState,
              isUploadInProgress: true,
            }))
          }
          onUploadFail={(error) => {
            const errorCode = getNetworkErrorCode(error);
            if (
              errorCode === NetworkErrorCode.receiptsNotNeeded ||
              errorCode === NetworkErrorCode.receiptUploadNotEnabled
            ) {
              enqueueSnackbar(getGenericErrorMsg(error), {
                variant: 'error',
              });
              setState((state) => ({
                ...state,
                isUploadInProgress: false,
                receiptNeeded:
                  errorCode === NetworkErrorCode.receiptsNotNeeded
                    ? false
                    : state.receiptNeeded,
                isDropzoneVisible: false,
              }));
              onRefresh();
            } else {
              setState((state) => ({
                ...state,
                isUploadInProgress: false,
              }));
            }
          }}
        />
      )}

      {receipts.length > 0 && (
        <>
          <Box position="relative" mt={1}>
            <ThumbnailPreview
              $disabled={isReceiptPreviewDialogOpen}
              receipt={receipts[0]}
              onClick={() => onReceiptPreviewDialogOpen()}
            />

            {receipts.length > 1 && (
              <Badge
                sx={(theme) => ({
                  position: 'absolute',
                  bottom: theme.spacing(2.5),
                  right: theme.spacing(2.5),
                })}
                badgeContent={receipts.length}
                color={
                  receipts.some(
                    (receipt) => receipt.status === ReceiptStatus.rejected
                  )
                    ? 'error'
                    : 'primary'
                }
              />
            )}
          </Box>

          {!isGlobalTxPage && (
            <>
              <ReceiptDate
                isLoading={state.isExtractReceiptDataLoading}
                disableEdit={isReversedReceiptViewOnly || receiptsAreReadOnly}
                isEditingMode={isReceiptDateEditing!}
                isError={state.isReceiptDateError}
                receiptDate={state.receiptDate}
                receiptDateStatus={receiptDateStatus}
                onChange={(date) =>
                  setState((prevState) => ({
                    ...prevState,
                    receiptDate: date,
                    isReceiptDateError: false,
                  }))
                }
                onEdit={() => onReceiptDateEditChange!(true)}
                onSave={() => {
                  if (transaction.receiptNumber === state.receiptDate) {
                    onReceiptDateEditChange!(false);
                    setState((prevState) => ({
                      ...prevState,
                      receiptDate: transaction.receiptDate || '',
                    }));
                    return;
                  }
                  const momentDate = moment(
                    state.receiptDate,
                    'YYYY-MM-DD',
                    true
                  );

                  if (momentDate.isValid() && momentDate.isBefore(moment())) {
                    onReceiptDataUpdate!('receiptDate', state.receiptDate);
                  } else {
                    setState((prevState) => ({
                      ...prevState,
                      isReceiptDateError: true,
                    }));
                  }
                }}
                onCancel={() => {
                  onReceiptDateEditChange!(false);
                  setState((prevState) => ({
                    ...prevState,
                    receiptDate: transaction.receiptDate || '',
                    isReceiptDateError: false,
                  }));
                }}
              />

              <ReceiptNumber
                isLoading={state.isExtractReceiptDataLoading}
                disableEdit={isReversedReceiptViewOnly || receiptsAreReadOnly}
                isReceiptNumberEditing={isReceiptNumberEditing!}
                isReceiptNumberError={state.isReceiptNumberError}
                receiptNumber={state.receiptNumber}
                receiptNumberStatus={receiptNumberStatus}
                onChange={(val) =>
                  setState((prevState) => ({
                    ...prevState,
                    receiptNumber: val,
                    isReceiptNumberError: false,
                  }))
                }
                onEdit={() => onReceiptNumberEditChange!(true)}
                onSave={() => {
                  const trimmedReceiptNumber = state.receiptNumber.trim();
                  if (transaction.receiptNumber === trimmedReceiptNumber) {
                    onReceiptNumberEditChange!(false);
                    setState((prevState) => ({
                      ...prevState,
                      receiptNumber: transaction.receiptNumber,
                    }));
                    return;
                  }

                  if (
                    /^([a-zA-Z\d$%&*+-/]){0,36}$/.test(trimmedReceiptNumber)
                  ) {
                    onReceiptDataUpdate!('receiptNumber', trimmedReceiptNumber);
                  } else {
                    setState((prevState) => ({
                      ...prevState,
                      isReceiptNumberError: true,
                    }));
                  }
                }}
                onCancel={() => {
                  onReceiptNumberEditChange!(false);
                  setState((prevState) => ({
                    ...prevState,
                    receiptNumber: transaction.receiptNumber,
                    isReceiptNumberError: false,
                  }));
                }}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default TransactionReceipts;
