import { useMemo } from 'react';
import { matchPath, useHistory, useRouteMatch } from 'react-router-dom';

export function useIsDetailsPageOpen(detailsPath: string): boolean;
export function useIsDetailsPageOpen(
  detailsPath: string,
  extended?: boolean
): { isDetailsPageOpen: boolean; detailsParams: any };

export function useIsDetailsPageOpen(detailsPath: string, extended?: boolean) {
  const history = useHistory();
  const { path } = useRouteMatch();

  return useMemo(() => {
    const match = matchPath(history.location.pathname, {
      path: path + detailsPath,
    });

    if (extended) {
      return {
        isDetailsPageOpen: !!match,
        detailsParams: match?.params || {},
      };
    }

    return !!match;
  }, [path, history.location.pathname, extended]);
}

export default useIsDetailsPageOpen;
