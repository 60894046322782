import { styled } from '@mui/material';

export const Tile = styled('div')`
  display: flex;
  align-items: center;
  width: 320px;
  min-height: 128px;
  padding: ${({ theme }) => theme.spacing(2, 3)};
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  cursor: pointer;
`;

export const Logo = styled('div')<{ $src: string }>`
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  margin-right: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background: ${({ $src }) => `url(${$src}) center no-repeat`};
  background-size: contain;
`;
