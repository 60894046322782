import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { cardholderPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import { CardIconWithDetails } from 'domains/card/components';
import { Box, Paper, Typography } from 'elements';
import { Transaction } from 'services/constants';
import { getPath } from 'services/utils';

interface Props {
  transaction: Transaction;
  isAdminApp: boolean;
}

const CardSection = ({ transaction, isAdminApp }: Props) => {
  const { t } = useTranslation();
  const {
    state: { member },
  } = useGlobalState();

  const renderCardIcon = () => {
    const cardIcon = (
      <CardIconWithDetails
        iconAlign="right"
        cardConfig={transaction.cardConfig}
        cardDesignId={transaction.cardDesignId}
        cardRefNum={transaction.cardRefNum}
        cardName={transaction.cardName}
        showCardNameTooltip
      />
    );

    if (isAdminApp) {
      return transaction.platformFeeCard ? (
        cardIcon
      ) : (
        <Link
          to={generatePath(getPath('cardDetails'), {
            orgId: transaction.organizationId,
            cardId: transaction.cardId,
          })}
          style={{ textDecoration: 'none' }}
        >
          {cardIcon}
        </Link>
      );
    } else {
      return transaction.memberId === member.id ? (
        <Link
          to={`${cardholderPaths.wallet}?cardId=${transaction.cardId}`}
          style={{ textDecoration: 'none' }}
        >
          {cardIcon}
        </Link>
      ) : (
        cardIcon
      );
    }
  };

  return (
    <>
      {(isAdminApp || transaction.memberId !== member.id) && (
        <Paper
          sx={{
            px: 1.5,
            py: 2,
            mt: 1,
            minHeight: 56,
          }}
          variant="outlined"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">
              {t('transactionDetails.cardholder')}
            </Typography>

            {isAdminApp && (
              <Typography
                noWrap
                variant="body2"
                ml={2}
                sx={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                component={Link}
                to={generatePath(getPath('memberDetails'), {
                  orgId: transaction.organizationId,
                  memberId: transaction.memberId,
                })}
              >
                {transaction.memberFirstName} {transaction.memberLastName}
              </Typography>
            )}
            {!isAdminApp && transaction.memberId !== member.id && (
              <Typography variant="body2" noWrap>
                {transaction.memberFirstName} {transaction.memberLastName}
              </Typography>
            )}
          </Box>
        </Paper>
      )}

      <Paper
        sx={{
          px: 1.5,
          py: 1,
          mt: 1,
        }}
        variant="outlined"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2">
            {t('transactionDetails.card')}
          </Typography>

          <Box ml={2} minWidth={0}>
            {renderCardIcon()}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default CardSection;
