import { ChangeEvent, useLayoutEffect, useMemo, useRef, useState } from 'react';

// This hook was introduced to solve the problem of losing cursor position in a text field, this happens
// when the user tries to change the string in the middle and there is a custom change handler that modifies
// value entered by the user, eg removes illegal characters
// See https://thegreenerman.medium.com/react-handling-an-input-caret-position-54a7df14837a for more details
const useInputSelection = () => {
  const [selection, setSelection] = useState<
    [number | null, number | null] | null
  >(null);
  const ref = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (selection && ref.current) {
      [ref.current.selectionStart, ref.current.selectionEnd] = selection;
    }
  }, [selection]);

  return useMemo(
    () => ({
      ref,
      onChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setSelection([e.target.selectionStart, e.target.selectionEnd]);
      },
    }),
    [ref]
  );
};

export default useInputSelection;
