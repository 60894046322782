import { useTranslation } from 'react-i18next';
import { WarningContainer } from 'domains/transaction/components/TransactionDetails/style';
import { Alert, AlertTitle } from 'elements';

const TransactionDisputeSection = () => {
  const { t } = useTranslation();

  return (
    <WarningContainer>
      <Alert severity="error">
        <AlertTitle>{t('transactionDisputeSection.title')}</AlertTitle>
        {t('transactionDisputeSection.description')}
      </Alert>
    </WarningContainer>
  );
};

export default TransactionDisputeSection;
