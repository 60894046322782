import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { omit, uniqueId } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FormatDecimal from 'components/FormatDecimal';
import FormatMoney from 'components/FormatMoney';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  LoaderWithOverlay,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CompensationBatchDetails,
  CompensationCertificationProject,
  CompensationStatement,
  NetworkErrorCode,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  trimObjValues,
} from 'services/utils';

interface FormProject
  extends Omit<CompensationCertificationProject, 'applyOrder'> {
  id: string;
}

const generateInitialProjectData = (): FormProject => ({
  id: uniqueId(),
  name: '',
  url: '',
  provider: '',
  register: '',
  purchasedOn: '',
  serialNumberFrom: '',
  serialNumberTo: '',
});

interface FormValues {
  projects: FormProject[];
}

interface State {
  isLoading: boolean;
  expandedIndex: number;
  compensationStatements: CompensationStatement[] | null;
}

interface Props extends DialogProps {
  compensationBatch: CompensationBatchDetails;
  onClose: () => void;
  onSuccess: () => void;
}

const GenerateCompensationStatementsDialog = ({
  compensationBatch,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const [state, setState] = useState<State>({
    isLoading: false,
    expandedIndex: 0,
    compensationStatements: null,
  });
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      projects: [generateInitialProjectData()],
    },
    validate: ({ projects }) => {
      const hasInvalidDate = projects.some(
        (item) =>
          !moment(item.purchasedOn, 'YYYY-MM-DD', true).isValid() ||
          !moment(item.purchasedOn).isBetween(
            moment().subtract(1, 'month'),
            moment(),
            'day',
            '[]'
          )
      );

      if (hasInvalidDate) {
        enqueueSnackbar('INVALID_DATE', { variant: 'error' });
        return { projects: true };
      }
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const projects: CompensationCertificationProject[] = values.projects.map(
          (item, index) => ({
            ...trimObjValues(omit(item, 'id')),
            applyOrder: index + 1,
          })
        );
        const data = await api.addCompensationCertificationProjects(
          compensationBatch.id,
          { projects }
        );
        if (!mounted.current) return;
        setState((prevState) => ({
          ...prevState,
          compensationStatements: data.result,
        }));
        setSubmitting(false);
      } catch (error) {
        if (!mounted.current) return;
        const errorCode = getNetworkErrorCode(error);
        if (
          errorCode &&
          [
            NetworkErrorCode.notEnoughCertificates,
            NetworkErrorCode.unsupportedCertificateNumberFormat,
            NetworkErrorCode.invalidCertificatesRange,
            NetworkErrorCode.invalidProjectUrl,
          ].includes(errorCode)
        ) {
          enqueueSnackbar(errorCode, { variant: 'error' });
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
        setSubmitting(false);
      }
    },
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const data = await api.getCompensationCertificationProjects(
        compensationBatch.id
      );
      if (!mounted.current) return;
      if (data.projects.length > 0) {
        const projects: FormProject[] = data.projects.map((item) => ({
          ...omit(item, 'applyOrder'),
          id: uniqueId(),
        }));
        formik.setFieldValue('projects', projects);
      }
      setState((prevState) => ({ ...prevState, isLoading: false }));
    } catch (error) {
      if (mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const generateCompensationStatements = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      await api.generateCompensationStatements(compensationBatch.id);
      if (!mounted.current) return;
      onSuccess();
    } catch (error) {
      if (mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const isLoading = state.isLoading || formik.isSubmitting;
  const isSubmitDisabled =
    isLoading ||
    formik.values.projects.some(
      (project) =>
        !project.name ||
        !project.url ||
        !project.provider ||
        !project.register ||
        !project.purchasedOn ||
        !project.serialNumberFrom ||
        !project.serialNumberTo
    );

  const renderContent = () => {
    if (!state.compensationStatements) {
      return (
        <>
          <DialogTitle>
            {t('generateCompensationStatementsDialog.title')}
          </DialogTitle>
          <DialogContent>
            <form
              onSubmit={formik.handleSubmit}
              noValidate
              id="generate-compensation-statements-form"
            >
              {formik.values.projects.map((project, index) => (
                <Box
                  key={project.id}
                  mb={formik.values.projects.length === index + 1 ? 4 : 2}
                >
                  <Box mb={2}>
                    <Typography variant="overline">
                      {t('generateCompensationStatementsDialog.projectNumber', {
                        number: index + 1,
                      })}
                    </Typography>
                    {index !== state.expandedIndex && (
                      <Button
                        variant="text"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            expandedIndex: index,
                          }))
                        }
                      >
                        {t('generateCompensationStatementsDialog.edit')}
                      </Button>
                    )}
                    {index === state.expandedIndex &&
                      formik.values.projects.length > 1 && (
                        <Button
                          variant="text"
                          onClick={() => {
                            formik.setFieldValue(
                              'projects',
                              formik.values.projects.filter(
                                (item) => item.id !== project.id
                              )
                            );
                            setState((prevState) => ({
                              ...prevState,
                              expandedIndex:
                                prevState.expandedIndex === 0
                                  ? prevState.expandedIndex
                                  : prevState.expandedIndex - 1,
                            }));
                          }}
                        >
                          {t('generateCompensationStatementsDialog.remove')}
                        </Button>
                      )}
                  </Box>
                  {index === state.expandedIndex && (
                    <Grid container columnSpacing={3} rowSpacing={2} mb={4}>
                      <Grid item xs={6}>
                        <TextField
                          label={t('generateCompensationStatementsDialog.name')}
                          {...formik.getFieldProps(`projects[${index}].name`)}
                          disabled={isLoading}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label={t('generateCompensationStatementsDialog.url')}
                          {...formik.getFieldProps(`projects[${index}].url`)}
                          disabled={isLoading}
                          inputProps={{ maxLength: 255 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label={t(
                            'generateCompensationStatementsDialog.provider'
                          )}
                          {...formik.getFieldProps(
                            `projects[${index}].provider`
                          )}
                          disabled={isLoading}
                          inputProps={{ maxLength: 64 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label={t(
                            'generateCompensationStatementsDialog.register'
                          )}
                          {...formik.getFieldProps(
                            `projects[${index}].register`
                          )}
                          disabled={isLoading}
                          inputProps={{ maxLength: 64 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type="date"
                          label={t(
                            'generateCompensationStatementsDialog.purchasedOn'
                          )}
                          {...formik.getFieldProps(
                            `projects[${index}].purchasedOn`
                          )}
                          disabled={isLoading}
                        />
                      </Grid>
                      <Grid item xs={6} />
                      <Grid item xs={6}>
                        <TextField
                          label={t(
                            'generateCompensationStatementsDialog.serialNumberFrom'
                          )}
                          {...formik.getFieldProps(
                            `projects[${index}].serialNumberFrom`
                          )}
                          disabled={isLoading}
                          inputProps={{ maxLength: 64 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label={t(
                            'generateCompensationStatementsDialog.serialNumberTo'
                          )}
                          {...formik.getFieldProps(
                            `projects[${index}].serialNumberTo`
                          )}
                          disabled={isLoading}
                          inputProps={{ maxLength: 64 }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Divider />
                </Box>
              ))}

              <Button
                fullWidth
                variant="outlined"
                size="large"
                onClick={() => {
                  formik.setFieldValue('projects', [
                    ...formik.values.projects,
                    generateInitialProjectData(),
                  ]);
                  setState((prevState) => ({
                    ...prevState,
                    expandedIndex: formik.values.projects.length,
                  }));
                }}
              >
                {t('generateCompensationStatementsDialog.addProject')}
              </Button>
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={props.onClose}>
              {t('generateCompensationStatementsDialog.cancel')}
            </Button>
            <Button
              disabled={isSubmitDisabled}
              type="submit"
              form="generate-compensation-statements-form"
            >
              {t('generateCompensationStatementsDialog.preview')}
            </Button>
          </DialogActions>
        </>
      );
    }

    return (
      <>
        <DialogTitle>
          {t('generateCompensationStatementsDialog.reviewAndSubmitStep.title')}
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t(
                      'generateCompensationStatementsDialog.reviewAndSubmitStep.organization'
                    )}
                  </TableCell>
                  <TableCell>
                    {t(
                      'generateCompensationStatementsDialog.reviewAndSubmitStep.compensated'
                    )}
                  </TableCell>
                  <TableCell>
                    {t(
                      'generateCompensationStatementsDialog.reviewAndSubmitStep.cashbackRedeemed'
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {t(
                      'generateCompensationStatementsDialog.reviewAndSubmitStep.certificates'
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.compensationStatements.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.organizationName}</TableCell>
                    <TableCell>
                      <FormatDecimal
                        value={item.compensatedEmissionAmount / 1000000}
                        unit="t"
                      />
                    </TableCell>
                    <TableCell>
                      <FormatMoney
                        value={item.compensatedAmount}
                        fractionalPart
                      />
                    </TableCell>
                    <TableCell align="right">
                      {item.certificates.map(({ certificateNumber }) => (
                        <div key={certificateNumber}>{certificateNumber}</div>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Box flexGrow="1">
            <Button
              variant="outlined"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  compensationStatements: null,
                }))
              }
            >
              {t('int.common.button.back')}
            </Button>
          </Box>
          <Button variant="text" onClick={props.onClose}>
            {t(
              'generateCompensationStatementsDialog.reviewAndSubmitStep.cancel'
            )}
          </Button>
          <Button onClick={generateCompensationStatements} disabled={isLoading}>
            {t(
              'generateCompensationStatementsDialog.reviewAndSubmitStep.submit'
            )}
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Dialog {...props} maxWidth={state.compensationStatements ? 'md' : 'sm'}>
      {renderContent()}
      <LoaderWithOverlay loading={isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(GenerateCompensationStatementsDialog);
