import React, { ReactNode } from 'react';
import { TeamBadge } from 'domains/member/components';
import { Box, Stack, Typography } from 'elements';
import { OrganizationMerchantTeamDetails } from 'services/constants';

interface Props {
  title: ReactNode;
  teams: OrganizationMerchantTeamDetails[];
}

const TeamsList = ({ title, teams }: Props) => {
  if (!teams.length) return null;

  return (
    <Box
      p={[3, 3, 2, 3]}
      borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
    >
      <Typography variant="overline" component="div" py={0.5}>
        {title}
      </Typography>
      <Stack direction="row" flexWrap="wrap">
        {teams.map((item) => (
          <TeamBadge key={item.teamId} name={item.name} sx={{ mb: 1 }} />
        ))}
      </Stack>
    </Box>
  );
};

export default TeamsList;
