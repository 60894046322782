import moment from 'moment';
import {
  SettlementTransactionPaymentType,
  SettlementTransactionType,
} from 'services/constants';
import { getValidQueryParamValues, isSortValid } from 'services/utils';

export const paymentTypes = [
  SettlementTransactionPaymentType.orgSetlPmt,
  SettlementTransactionPaymentType.programSetlPmt,
  SettlementTransactionPaymentType.programSetlFeePmt,
  SettlementTransactionPaymentType.rcnPmt,
];

export const transactionTypes = [
  SettlementTransactionType.remitCredit,
  SettlementTransactionType.remitDebit,
  SettlementTransactionType.sctCredit,
  SettlementTransactionType.sctDebit,
];

export const getQueryParams = (qs: string, allowedSortKeys: string[]) => {
  const {
    sort,
    q,
    paymentType,
    transactionType,
    fromDate,
    toDate,
    settlementGroupId,
  } = Object.fromEntries(new URLSearchParams(qs).entries());

  const fromDateMoment = moment(fromDate, moment.ISO_8601);
  const toDateMoment = moment(toDate, moment.ISO_8601);

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '-postingDate',
    q: q?.trim() || '',
    paymentType: getValidQueryParamValues(paymentType, paymentTypes),
    transactionType: getValidQueryParamValues(
      transactionType,
      transactionTypes
    ),
    fromDate: fromDateMoment.isValid() ? fromDateMoment : null,
    toDate:
      fromDateMoment.isValid() && toDateMoment.isValid() ? toDateMoment : null,
    // this param is validated in withInitialData hoc
    settlementGroupId,
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

export const getSelectedFiltersCount = ({
  paymentType,
  transactionType,
  fromDate,
}: QueryParams) =>
  0 +
  (paymentType.length ? 1 : 0) +
  (transactionType.length ? 1 : 0) +
  (fromDate ? 1 : 0);
