import React, { useEffect } from 'react';
import { omit } from 'lodash';
import { Box, TextField } from 'elements';
import { OnboardingDocumentAllowedAttribute } from 'services/constants';

interface Props {
  setValue: (value: string | number | null) => void;
  type: OnboardingDocumentAllowedAttribute;
  value: string | number | null;
  label: string;
}

const AttributeField = ({ type, setValue, ...props }: Props) => {
  useEffect(() => {
    if (type === OnboardingDocumentAllowedAttribute.year && !props.value) {
      const previousYearString = (new Date().getFullYear() - 1).toString();
      setValue(previousYearString);
    }
  }, []);

  switch (type) {
    case OnboardingDocumentAllowedAttribute.orgName:
      return (
        <Box width="293px">
          <TextField {...props} onChange={(e) => setValue(e.target.value)} />
        </Box>
      );
    case OnboardingDocumentAllowedAttribute.ibanRef:
      return (
        <Box width="154px">
          <TextField
            {...props}
            inputProps={{ maxLength: 4 }}
            onChange={(e) => setValue(e.target.value)}
          />
        </Box>
      );
    case OnboardingDocumentAllowedAttribute.fromMonthYear:
    case OnboardingDocumentAllowedAttribute.toMonthYear:
      return (
        <Box width="154px">
          <TextField
            {...props}
            type="month"
            onChange={(e) => setValue(e.target.value)}
          />
        </Box>
      );
    case OnboardingDocumentAllowedAttribute.year:
      return (
        <Box width="154px">
          <TextField
            {...omit(props, 'value')}
            value={props.value}
            inputProps={{
              min: 1900,
              max: new Date().getFullYear(),
            }}
            onChange={(e) => setValue(e.target.value)}
            type="number"
          />
        </Box>
      );
    case OnboardingDocumentAllowedAttribute.date:
      return (
        <Box width="154px">
          <TextField
            {...props}
            type="date"
            onChange={(e) => setValue(e.target.value)}
          />
        </Box>
      );
    case OnboardingDocumentAllowedAttribute.amount:
      return (
        <Box width="154px">
          <TextField {...props} onChange={(e) => setValue(e.target.value)} />
        </Box>
      );
    default:
      return null;
  }
};

export default AttributeField;
