import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardPin } from 'domains/card/components';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card } from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { ChangePinStep } from './ChangePinStep';

interface State {
  step: 'viewPin' | 'changePin';
  pin: string;
  isLoading: boolean;
}

interface Props extends DialogProps {
  onClose: () => void;
  card: Card;
}

const PinDialog = ({ card, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { changePinEnabled } = useFlags();
  const [state, setState] = useState<State>({
    step: 'viewPin',
    pin: '',
    isLoading: true,
  });

  const getData = async () => {
    try {
      const { pin } = await api.getCardPin(card.cardId);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, pin, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Dialog {...props} maxWidth={state.step === 'viewPin' ? 'xs' : 'sm'}>
      {state.step === 'viewPin' ? (
        <>
          <DialogTitle>{t('pinDialog.pin')}</DialogTitle>
          <DialogContent>
            <Alert severity="warning">{t('pinDialog.keepPinSecureHint')}</Alert>
            <Box mt={2}>
              <CardPin pin={state.pin} />
            </Box>
          </DialogContent>
          <DialogActions>
            {changePinEnabled && (
              <Button
                variant="text"
                onClick={() =>
                  setState((prevState) => ({ ...prevState, step: 'changePin' }))
                }
              >
                {t('pinDialog.changePin')}
              </Button>
            )}
            <Button onClick={props.onClose}>{t('common.button.close')}</Button>
          </DialogActions>
          <LoaderWithOverlay loading={state.isLoading} />
        </>
      ) : (
        <ChangePinStep onClose={props.onClose} card={card} />
      )}
    </Dialog>
  );
};

export default withDialogWrapper<Props>(PinDialog);

export { default as UnlockPinDialog } from './UnlockPinDialog';
