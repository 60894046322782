import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { internalOrgPaths } from 'components/App';
import CountryFlag from 'components/CountryFlag';
import FormatMoney from 'components/FormatMoney';
import { OrganizationStatusBadge } from 'domains/organization/components';
import { Box, Chip, DataGridCell, GridColDef, gridUtils, Link } from 'elements';
import { OrganizationGroupMember } from 'services/constants';

const useColumns = (isRiskUnit: boolean) => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<OrganizationGroupMember>[] = [
      {
        headerName: t('int.organizationGroupDetailsPage.organization'),
        field: 'name',
        sortable: false,
        flex: 200,
        renderCell: ({ row }) => (
          <DataGridCell>
            <Link
              component={RouterLink}
              to={generatePath(internalOrgPaths.dashboard, {
                orgId: row.id,
              })}
              onClick={(e) => e.stopPropagation()}
              underline="none"
              color="inherit"
            >
              {row.name}
            </Link>
          </DataGridCell>
        ),
      },
      ...(isRiskUnit
        ? [
            {
              headerName: t('int.organizationGroupDetailsPage.groupMemberType'),
              field: 'groupMemberType',
              sortable: false,
              flex: 150,
              renderCell: ({ row }: { row: OrganizationGroupMember }) => (
                <DataGridCell>
                  <Box display="flex">
                    {row.types.map((type) => (
                      <Chip
                        key={type}
                        label={t(`int.organizationGroupMember.${type}`)}
                        size="small"
                        sx={{ mr: 0.5 }}
                      />
                    ))}
                  </Box>
                </DataGridCell>
              ),
            },
          ]
        : []),
      {
        headerName: t('int.organizationGroupDetailsPage.country'),
        field: 'country',
        sortable: false,
        flex: 70,
        renderCell: ({ row }) => (
          <DataGridCell>
            <CountryFlag code={row.country} mr={1} />
            {row.country}
          </DataGridCell>
        ),
      },
      {
        headerName: t('int.organizationGroupDetailsPage.status'),
        field: 'status',
        sortable: false,
        flex: 150,
        renderCell: ({ row }) => (
          <OrganizationStatusBadge status={row.status} />
        ),
      },
      {
        headerName: t('int.organizationGroupDetailsPage.activationDate'),
        field: 'activationDate',
        flex: 90,
        sortable: false,
        renderCell: ({ row }) => row.activationDate || '-',
      },
      ...(isRiskUnit
        ? [
            {
              headerName: t(
                'int.organizationGroupDetailsPage.nextMonitoringDate'
              ),
              field: 'nextMonitoringDate',
              sortable: false,
              flex: 90,
              renderCell: ({ row }: { row: OrganizationGroupMember }) =>
                row.nextMonitoringDate || '-',
            },
            {
              headerName: t(
                'int.organizationGroupDetailsPage.underwritingCase'
              ),
              field: 'underwritingCase',
              sortable: false,
              flex: 90,
              renderCell: ({ row }: { row: OrganizationGroupMember }) =>
                row.underwritingCase || '-',
            },
          ]
        : []),
      {
        headerName: t('int.organizationGroupDetailsPage.limit'),
        field: 'limit',
        flex: 110,
        sortable: false,
        renderCell: ({ row }) =>
          row.limit ? <FormatMoney value={row.limit} /> : '-',
      },
    ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language, isRiskUnit]);
};

export default useColumns;
