import React, { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import { FormControl, FormControlLabel, Paper, Switch } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { SubscriptionPlan, SubscriptionPlanType } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  isConfirmDialogOpen: boolean;
}

interface Props {
  subscriptionPlan: SubscriptionPlan;
}

const FlagsSection = ({ subscriptionPlan }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const canUser = useCanUser();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: false,
    isConfirmDialogOpen: false,
  });
  const confirmDialogRef = useRef<{ title: string; description: ReactNode }>({
    title: '',
    description: null,
  });

  const confirmFlagToggle = () => {
    const action = subscriptionPlan.premiumFeaturesEnabled
      ? 'deactivate'
      : 'activate';

    confirmDialogRef.current = {
      title: t(
        `int.subscriptionSettingsPage.flagsSection.premiumFeaturesEnabled.${action}ConfirmationTitle`
      ),
      description: t(
        `int.subscriptionSettingsPage.flagsSection.premiumFeaturesEnabled.${action}ConfirmationDescription`
      ),
    };

    setState((prevState) => ({ ...prevState, isConfirmDialogOpen: true }));
  };

  const toggleFlag = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const data = await api.updatePremiumFeaturesFlag(
        organization!.id,
        !subscriptionPlan.premiumFeaturesEnabled
      );
      dispatch({
        type: 'SET_SUBSCRIPTION_PLAN_DATA',
        payload: { subscriptionPlan: data },
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isConfirmDialogOpen: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  return (
    <>
      <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
        <FormControl fullWidth>
          <FormControlLabel
            checked={subscriptionPlan.premiumFeaturesEnabled}
            onChange={confirmFlagToggle}
            disabled={
              ![
                SubscriptionPlanType.custom,
                SubscriptionPlanType.enterprise,
              ].includes(subscriptionPlan.type) ||
              !canUser('subscription-plan:update')
            }
            control={<Switch size="small" />}
            label={t(
              'int.subscriptionSettingsPage.flagsSection.premiumFeaturesEnabled.label'
            )}
            labelPlacement="start"
            sx={{ py: '10px', mr: 0 }}
          />
        </FormControl>
      </Paper>

      <ConfirmDialog
        open={state.isConfirmDialogOpen}
        title={confirmDialogRef.current.title}
        description={confirmDialogRef.current.description}
        loading={state.isLoading}
        onSuccess={toggleFlag}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isConfirmDialogOpen: false,
          }))
        }
      />
    </>
  );
};

export default FlagsSection;
