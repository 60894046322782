import moment from 'moment';
import { Trans } from 'react-i18next';
import {
  Box,
  DownloadSimpleIcon,
  FileTextIcon,
  IconButton,
  Link,
  ListItem,
  ListItemIcon,
  Tooltip,
  Typography,
} from 'elements';
import { TacMetadata } from 'services/constants';

export interface Props {
  name: string;
  url: string;
  metadata?: TacMetadata | null;
}

const DocumentLink = ({ name, url, metadata }: Props) => {
  return (
    <ListItem
      sx={(theme) => ({
        minHeight: theme.spacing(8.5),
        '&+&': { borderTop: 1, borderColor: theme.palette.divider },
      })}
      secondaryAction={
        <IconButton
          component={Link}
          sx={{ textDecoration: 'none' }}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          edge="end"
          aria-label="download"
        >
          <DownloadSimpleIcon />
        </IconButton>
      }
    >
      <ListItemIcon>
        <FileTextIcon />
      </ListItemIcon>

      <Box>
        <Typography
          component={Link}
          sx={{ textDecoration: 'none' }}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </Typography>

        {metadata && (
          <Box data-test-id="confirmations">
            {metadata.confirmations.map((c, idx) => (
              <Tooltip key={c.memberId} title={c.email}>
                <Typography variant="body2" color="textSecondary">
                  <Trans
                    i18nKey="documents.approval"
                    values={{
                      name: c.name,
                      time: moment(c.confirmedAt).format('DD MMM YYYY HH:mm'),
                    }}
                    components={{
                      // todo: should be removed later from all translations
                      b: <span />,
                    }}
                  />
                  {metadata.confirmations.length - 1 > idx && (
                    <span>,&nbsp;</span>
                  )}
                </Typography>
              </Tooltip>
            ))}
          </Box>
        )}
      </Box>
    </ListItem>
  );
};

export default DocumentLink;
