import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyProps } from 'elements';
import { Role } from 'services/rbac';

interface Props extends TypographyProps {
  roles: string[];
}

const MemberRoleOverline = ({ roles, ...props }: Props) => {
  const { t } = useTranslation();

  if (roles.includes(Role.auditor))
    return (
      <Typography variant="overline2" {...props}>
        {t(`roles.${Role.auditor}`)}
      </Typography>
    );

  if (roles.includes(Role.accountant))
    return (
      <Typography variant="overline2" {...props}>
        {t(`roles.${Role.accountant}`)}
      </Typography>
    );

  if (roles.includes(Role.orgAdmin))
    return (
      <Typography variant="overline2" {...props}>
        {t(`roles.${Role.orgAdmin}`)}
      </Typography>
    );

  if (roles.includes(Role.accountOwner))
    return (
      <Typography variant="overline2" {...props}>
        {t(`roles.${Role.accountOwner}`)}
      </Typography>
    );

  return null;
};

export default MemberRoleOverline;
