import { Components } from '@mui/material';

const MuiDialogOverrides: Components = {
  MuiDialog: {
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: { padding: 24 },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: { padding: 24 },
    },
  },
};

export default MuiDialogOverrides;
