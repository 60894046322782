import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Grid, LabeledValue } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getYesNoFromBoolean } from 'services/utils';
import CanSetBillingAccountAsPrefundGroupChangeDialog from './CanSetBillingAccountAsPrefundGroupChangeDialog';

interface State {
  canSetBillingAccountAsPrefund: boolean;
  isChangeDialogOpen: boolean;
  isLoading: boolean;
}

const CanSetBillingAccountAsPrefundGroup = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    canSetBillingAccountAsPrefund: false,
    isChangeDialogOpen: false,
    isLoading: true,
  });

  const getData = async () => {
    try {
      const orgAdditionalInfo = await api.getAdditionalInformations(
        organization!.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        canSetBillingAccountAsPrefund:
          orgAdditionalInfo.canSetBillingAccountAsPrefund,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <LabeledValue
          disabled={state.isLoading}
          label={t(
            'int.generalTermsPage.onboardingSettingsSection.canSetBillingAccountAsPrefund'
          )}
          value={getYesNoFromBoolean(state.canSetBillingAccountAsPrefund, t)}
          onEdit={
            canUser('org-can-set-billing-account-as-prefund:update')
              ? () =>
                  setState((prevState) => ({
                    ...prevState,
                    isChangeDialogOpen: true,
                  }))
              : undefined
          }
        />
      </Grid>

      <CanSetBillingAccountAsPrefundGroupChangeDialog
        intialValue={state.canSetBillingAccountAsPrefund}
        open={state.isChangeDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isChangeDialogOpen: false,
          }))
        }
        onSuccess={(newValue: boolean) =>
          setState((prevState) => ({
            ...prevState,
            canSetBillingAccountAsPrefund: newValue,
            isChangeDialogOpen: false,
          }))
        }
      />
    </>
  );
};

export default CanSetBillingAccountAsPrefundGroup;
