import { forwardRef } from 'react';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { Alert } from 'elements/Alert';

const CustomSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(
  (props, ref) => {
    const { closeSnackbar } = useSnackbar();
    const severity = props.variant === 'default' ? 'success' : props.variant;

    return (
      <SnackbarContent ref={ref}>
        <Alert
          icon={false}
          severity={severity}
          variant="filled"
          onClose={() => closeSnackbar(props.id)}
          elevation={6}
          sx={{
            width: '100%',
            '.MuiAlert-message': { padding: '5px 0', a: { color: 'inherit' } },
            '.MuiAlert-action': { paddingTop: 0 },
          }}
        >
          {props.message}
        </Alert>
      </SnackbarContent>
    );
  }
);

export default CustomSnackbar;
