import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { GenericError } from '@auth0/auth0-spa-js';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

interface RequestConfig extends AxiosRequestConfig {
  fullResponse: true;
}

const useRequest = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { i18n } = useTranslation();

  async function request<T>(
    url: string,
    requestConfig: RequestConfig
  ): Promise<AxiosResponse<T>>;

  async function request<T>(
    url: string,
    requestConfig?: AxiosRequestConfig
  ): Promise<T>;

  async function request<T>(url: string, requestConfig?: any): Promise<any> {
    try {
      const accessToken = await getAccessTokenSilently();
      const response: AxiosResponse<T> = await axios.request({
        url,
        ...requestConfig,
        headers: {
          'Accept-Language': i18n.language,
          ...requestConfig?.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (
        typeof requestConfig?.fullResponse === 'boolean' &&
        requestConfig.fullResponse
      ) {
        return response;
      } else {
        return response.data;
      }
    } catch (error) {
      // source: https://github.com/auth0/auth0-spa-js/blob/baa3c8f61db659249de6f199e0b0dd8658662e88/static/index.html#L753
      if (error instanceof GenericError && error.error === 'login_required') {
        return window.location.reload();
      }

      throw error;
    }
  }

  return useCallback(request, [getAccessTokenSilently]);
};

export default useRequest;
