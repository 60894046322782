import styled from 'styled-components';
import { ReactComponent as ValidationCheckCircleIcon } from 'assets/icons/interface/validationCheckCircle.svg';

export const CompletedBlock = styled.div<{ $margin?: string }>`
  display: inline-flex;
  align-items: center;
  margin: ${({ $margin }) => $margin || 0};
  padding: 21px 32px 21px 18px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.financeGreen90};
  color: ${({ theme }) => theme.colors.financeGreen10};
  font-size: 16px;
  line-height: 22px;
`;

export const StyledValidationCheckCircleIcon = styled(
  ValidationCheckCircleIcon
)`
  width: 16px;
  height: 16px;
  margin-right: 14px;
`;
