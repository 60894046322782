import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderWithOverlay } from 'components/Loader';
import WidgetError from 'components/WidgetError';
import {
  Avatar,
  Box,
  Button,
  Chip,
  ProhibitIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from 'elements';
import {
  BankAccount,
  BankAccountTransfersAllowedStatus,
  BankConnection,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import ConnectionBox from './ConnectionBox';
import { BankAccountsTableProps, BankAccountsTableState } from './index';
import { NothingFound } from './style';

const getBankAvatarLabel = (name: string) =>
  name
    .split(' ')
    .map((str) => str[0])
    .concat('B')
    .slice(0, 2)
    .join('');

interface Props extends BankAccountsTableProps {
  // it's easier to pass full state for now as we are going to move this component to main file
  state: BankAccountsTableState;
  isPrefundedAccount: boolean;
  getData: () => void;
  connectBankAccount: (bankAccountToReconnect: BankAccount) => void;
  reconnectBankAccount: (bankAccountToReconnect: BankAccount) => void;
  askForBankConnectionRemoval: (bankConnectionToRemove: BankConnection) => void;
  renderIban: (bankAccount: BankAccount) => string | JSX.Element | null;
  renderMenu: (bankAccount: BankAccount) => string | JSX.Element;
}

const BankAccountsTableNew = ({
  state,
  isPrefundedAccount,
  getData,
  onConfirm,
  connectBankAccount,
  reconnectBankAccount,
  askForBankConnectionRemoval,
  renderIban,
  renderMenu,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('bankAccountsTable.bank')}</TableCell>
            <TableCell />
            {!isPrefundedAccount && (
              <TableCell>{t('bankAccountsTable.connectionStatus')}</TableCell>
            )}
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(state.groupedBankAccounts).map((bankConnectionId) =>
            Object.keys(state.groupedBankAccounts[bankConnectionId]).map(
              (bankId) => {
                const key = `${bankConnectionId}.${bankId}`;
                const bankAccounts =
                  state.groupedBankAccounts[bankConnectionId][bankId];

                return bankAccounts.length === 1 ? (
                  <TableRow key={key}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar variant="rounded">
                          {getBankAvatarLabel(bankAccounts[0].bankName)}
                        </Avatar>

                        <Box ml={2}>
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="body2"
                              noWrap
                              sx={{
                                maxWidth: '240px',
                              }}
                            >
                              {bankAccounts[0].bankName}
                            </Typography>

                            {bankAccounts[0].transfersAllowedStatus ===
                              BankAccountTransfersAllowedStatus.blocked && (
                              <Box ml={1} lineHeight={0}>
                                <Tooltip
                                  title={t(
                                    'bankAccountsTable.blockedBankAccount'
                                  )}
                                >
                                  <ProhibitIcon />
                                </Tooltip>
                              </Box>
                            )}
                          </Box>

                          <Box display="flex" alignItems="center">
                            {renderIban(bankAccounts[0])}

                            {bankAccounts[0].directDebit && (
                              <Chip
                                size="small"
                                color="primary"
                                sx={{ textTransform: 'uppercase', ml: 1 }}
                                label={t('bankAccountsTable.billingAccount')}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>

                    <ConnectionBox
                      isNewDesign
                      bankAccountsOfConnection={bankAccounts}
                      bankConnections={state.bankConnections}
                      onConnect={connectBankAccount}
                      onReconnect={reconnectBankAccount}
                      onRemove={askForBankConnectionRemoval}
                    />

                    {/* Old component is used. Should be updated later */}
                    <TableCell align="right">
                      {renderMenu(bankAccounts[0])}
                    </TableCell>
                  </TableRow>
                ) : (
                  <Fragment key={key}>
                    <TableRow>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Avatar variant="rounded">
                            {getBankAvatarLabel(bankAccounts[0].bankName)}
                          </Avatar>

                          <Box ml={2}>
                            <Typography
                              variant="body2"
                              noWrap
                              sx={{
                                maxWidth: '240px',
                              }}
                            >
                              {bankAccounts[0].bankName}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <ConnectionBox
                        isNewDesign
                        bankAccountsOfConnection={bankAccounts}
                        bankConnections={state.bankConnections}
                        onConnect={connectBankAccount}
                        onReconnect={reconnectBankAccount}
                        onRemove={askForBankConnectionRemoval}
                      />
                      <TableCell />
                    </TableRow>

                    {bankAccounts.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            {/* using Avatar for consistency */}
                            <Avatar
                              variant="rounded"
                              sx={{ background: 'none', position: 'relative' }}
                            >
                              <Box
                                width="50%"
                                height="50%"
                                position="absolute"
                                top={0}
                                right={0}
                                borderBottom="1px solid"
                                borderLeft="1px solid"
                                borderColor={(theme) => theme.palette.divider}
                              />
                            </Avatar>

                            <Box display="flex" alignItems="center" ml={2}>
                              {renderIban(item)}

                              {item.transfersAllowedStatus ===
                                BankAccountTransfersAllowedStatus.blocked && (
                                <Box ml={1} lineHeight={0}>
                                  <Tooltip
                                    title={t(
                                      'bankAccountsTable.blockedBankAccount'
                                    )}
                                  >
                                    <ProhibitIcon />
                                  </Tooltip>
                                </Box>
                              )}

                              <Box display="flex" flexGrow="1">
                                {item.directDebit && (
                                  <Chip
                                    size="small"
                                    color="secondary"
                                    sx={{ textTransform: 'uppercase', ml: 1 }}
                                    label={t(
                                      'bankAccountsTable.billingAccount'
                                    )}
                                  />
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell />
                        <TableCell />
                        {/* Old component is used. Should be updated later */}
                        <TableCell align="right">{renderMenu(item)}</TableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                );
              }
            )
          )}
        </TableBody>
      </Table>

      {state.isLoading && <LoaderWithOverlay size={32} thickness={3} />}
      {!state.isLoading && !state.error && !state.bankAccounts.length && (
        <NothingFound>{t('bankAccountsTable.nothingFound')}</NothingFound>
      )}
      {state.error && <WidgetError onReload={getData} />}

      {onConfirm && (
        <Box mt={5} display="flex" justifyContent="flex-end">
          <Button
            onClick={onConfirm}
            disabled={!canUser('bank-connection:confirm', state.bankAccounts)}
          >
            {t('common.button.confirm')}
          </Button>
        </Box>
      )}
    </>
  );
};

export default BankAccountsTableNew;
