import React, { Suspense, useEffect, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';
import PdfViewer from 'components/PdfViewer';
import { useGlobalState } from 'context/GlobalState';
import ImagePreview from 'domains/transaction/components/TransactionDetails/ReceiptPreviewDialog/ImagePreview';
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { MimeType, OnboardingDocumentFile } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  fileData: any;
  isLoading: boolean;
  receiptNumPages: number;
  receiptPageNumber: number;
}

interface Props extends DialogProps {
  documentId: string;
  file: OnboardingDocumentFile;
  onClose: () => void;
}

const FilePreviewDialog = ({ documentId, file, ...props }: Props) => {
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const listRef = useRef<FixedSizeList>(null);
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    fileData: null,
    isLoading: true,
    receiptNumPages: 1,
    receiptPageNumber: 1,
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await api.downloadOnboardingDocumentFile(
          organization!.id,
          documentId,
          file.id,
          true
        );
        if (!mounted) return;
        setState((prevState) => ({
          ...prevState,
          fileData: response,
          isLoading: false,
        }));
      } catch (error) {
        if (!mounted) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        props.onClose();
        logError(error);
      }
    })();
  }, []);

  return (
    <Dialog
      {...props}
      sx={{
        '& .MuiDialog-container': {
          flexDirection: 'column',
        },
        '& .MuiDialog-paper': {
          flexGrow: 1,
        },
      }}
      maxWidth="lg"
    >
      <DialogTitle
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span>{file.name}</span>
      </DialogTitle>

      <DialogContent sx={{ padding: 0, position: 'relative' }}>
        {!state.isLoading &&
          (file.mediaType === MimeType.pdf ? (
            <Suspense fallback={<LoaderWithOverlay loading />}>
              <PdfViewer
                ref={listRef}
                selectedPage={state.receiptPageNumber}
                numPages={state.receiptNumPages}
                scale={1}
                onChange={(receiptPageNumber, receiptNumPages) => {
                  setState((prevState) => ({
                    ...prevState,
                    receiptPageNumber,
                    receiptNumPages,
                  }));
                }}
                data={state.fileData.data}
                onError={() => props.onClose()}
              />
            </Suspense>
          ) : (
            <ImagePreview data={state.fileData.data} scale={1} />
          ))}
      </DialogContent>
      {state.receiptNumPages > 1 && (
        <Box
          sx={(theme) => ({
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            minWidth: theme.spacing(5),
            position: 'absolute',
            right: theme.spacing(3),
            top: '40%',
          })}
        >
          <Typography variant="body1">
            {state.receiptPageNumber}/{state.receiptNumPages}
          </Typography>
        </Box>
      )}
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(FilePreviewDialog);
