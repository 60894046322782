import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { useCodatStepperContext } from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/useCodatStepperContext';
import {
  ArrowsClockwiseIcon,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { useGLAccountsSyncContext } from './useGLAccountsSyncContext';

const StartStep = () => {
  const { t } = useTranslation();
  const partnerName = usePartnerName();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const {
    actions: { onClose },
  } = useCodatStepperContext();
  const {
    actions: { getSyncedMappingOptions },
  } = useGLAccountsSyncContext();

  return (
    <>
      <DialogContent>
        <Box sx={{ fontSize: (theme) => theme.typography.fontSize * 4, mt: 3 }}>
          <ArrowsClockwiseIcon fontSize="inherit" />
        </Box>

        <Typography variant="h4" mb={2} mt={8}>
          {t('codatSyncSetupDialog.startStep.title', {
            name: accountingSettings!.accountingSystemName,
            partnerName,
          })}
        </Typography>
        <Typography color="text.secondary">
          {t('codatSyncSetupDialog.startStep.description', {
            name: accountingSettings!.accountingSystemName,
          })}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.button.close')}
        </Button>
        <Button onClick={getSyncedMappingOptions}>
          {t('codatSyncSetupDialog.startStep.getStartedButton')}
        </Button>
      </DialogActions>
    </>
  );
};

export default StartStep;
