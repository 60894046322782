import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { BillPaymentTypeBadge } from 'domains/billing/components';
import {
  Box,
  Chip,
  drawerPlaceholderColumn,
  GridColDef,
  Typography,
} from 'elements';
import { BillPaymentsBankAccount, BillPaymentType } from 'services/constants';
import StatusCell, { OnBillPaymentsBankAccountUpdatedRef } from './StatusCell';

const useColumns = (onItemUpdatedRef: OnBillPaymentsBankAccountUpdatedRef) => {
  const { t, i18n } = useTranslation();

  return useMemo<GridColDef<BillPaymentsBankAccount>[]>(
    () => [
      {
        headerName: t('int.billingAccountsApprovalPage.orgName'),
        field: 'organizationName',
        flex: 200,
        minWidth: 200,
        sortable: false,
      },
      {
        headerName: t('int.billingAccountsApprovalPage.ld'),
        field: 'levenshteinDistance',
        flex: 64,
        minWidth: 64,
        renderCell: ({ row }) => {
          const value = row.levenshteinDistance;
          if (value === 0) return <Chip size="small" label={0} />;
          if (value > 5)
            return <Chip size="small" label={value} color="error" />;
          return (
            <Chip size="small" label={value} color="error" variant="outlined" />
          );
        },
        sortable: false,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('int.billingAccountsApprovalPage.accountHolderName'),
        field: 'accountHolderName',
        flex: 200,
        minWidth: 200,
        sortable: false,
      },
      {
        headerName: t('int.billingAccountsApprovalPage.bank'),
        field: 'iban',
        flex: 200,
        minWidth: 200,
        renderCell: ({ row }) => (
          <Box overflow="hidden">
            <Typography variant="body2" color="text.primary" noWrap>
              {row.bankName}
            </Typography>
            <Typography variant="caption" color="text.secondary" noWrap>
              {row.iban}
            </Typography>
          </Box>
        ),
        sortable: false,
      },
      {
        headerName: '',
        field: 'directDebit',
        flex: 150,
        minWidth: 150,
        renderCell: ({ row }) => (
          <BillPaymentTypeBadge
            type={
              row.directDebit
                ? BillPaymentType.directDebit
                : BillPaymentType.wireTransfer
            }
          />
        ),
        sortable: false,
      },
      {
        headerName: t('int.billingAccountsApprovalPage.firstPaymentDate'),
        field: 'firstPaymentDate',
        flex: 150,
        minWidth: 150,
        renderCell: ({ row }) =>
          row.firstPaymentDate
            ? moment(row.firstPaymentDate).format('YYYY-MM-DD')
            : null,
        sortable: false,
      },
      {
        headerName: t('int.billingAccountsApprovalPage.status'),
        field: 'transfersAllowedStatus',
        flex: 190,
        minWidth: 190,
        renderCell: ({ row }) => (
          <StatusCell
            bankAccountId={row.id}
            status={row.transfersAllowedStatus}
            onItemUpdatedRef={onItemUpdatedRef}
          />
        ),
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },
      drawerPlaceholderColumn,
    ],
    [i18n.language]
  );
};

export default useColumns;
