import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, LinkIcon, Paper, PlusIcon, Typography } from 'elements';
import { OrganizationMerchant } from 'services/constants';
import { useCanUser } from 'services/rbac';
import ChangeSupplierDialog from './ChangeSupplierDialog';

interface Props {
  value: OrganizationMerchant['supplierResponse'];
  onChange: (value: OrganizationMerchant['supplierResponse']) => void;
  merchantId: string;
}

const SupplierSelect = ({ value, onChange, merchantId }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Paper
        variant="outlined"
        onClick={() => {
          if (!canUser('supplier:assign')) return;
          setIsOpen(true);
        }}
        sx={{
          height: 58,
          display: 'flex',
          alignItems: 'center',
          mb: 1,
          px: 1.5,
          cursor: canUser('supplier:assign') ? 'pointer' : undefined,
        }}
      >
        {value ? (
          <>
            <Box pr={1}>
              <Typography variant="body2" noWrap>
                {t('supplierSelect.supplier')}
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
              >
                {t('supplierSelect.accountNumber')}
              </Typography>
            </Box>
            <Box ml="auto" textAlign="right" overflow="hidden">
              <Typography variant="body2" noWrap>
                {value.name}
              </Typography>
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
                noWrap
              >
                {value.accountNumber || '—'}
              </Typography>
            </Box>
            {canUser('supplier:assign') && (
              <LinkIcon sx={{ ml: 2, mr: 0.5, color: 'action.active' }} />
            )}
          </>
        ) : (
          <>
            <Typography variant="body2">
              {t('supplierSelect.supplier')}
            </Typography>
            <Typography variant="caption" color="textSecondary" ml="auto">
              —
            </Typography>
            {canUser('supplier:assign') && (
              <PlusIcon sx={{ ml: 2, mr: 0.5, color: 'action.active' }} />
            )}
          </>
        )}
      </Paper>
      <ChangeSupplierDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onSuccess={(value) => {
          onChange(value);
          setIsOpen(false);
        }}
        supplierResponse={value}
        merchantId={merchantId}
      />
    </>
  );
};

export default SupplierSelect;
