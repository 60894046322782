import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, SettingsGroupBox } from 'layout';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isConfirmationDialogOpen: boolean;
  isReceiptNotificationsUpdating: boolean;
}

const NotificationsGroup = () => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    isConfirmationDialogOpen: false,
    isReceiptNotificationsUpdating: false,
  });

  const updateReceiptNotifications = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isReceiptNotificationsUpdating: true,
      }));
      const orgData = await api.updateOrgMissingReceiptNotifications(
        organization!.id,
        !organization!.missingReceiptNotificationEnabled
      );

      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { organization: orgData },
      });
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    } finally {
      if (mounted.current) {
        setState((prevState) => ({
          ...prevState,
          isConfirmationDialogOpen: false,
          isReceiptNotificationsUpdating: false,
        }));
      }
    }
  };

  return (
    <>
      <SettingsGroupBox>
        <ContentContainer>
          <Typography variant="h6" mb={1}>
            {t('receiptManagementSubPage.notification.title')}
          </Typography>

          <Typography variant="body1">
            {t('receiptManagementSubPage.notification.description')}
          </Typography>

          <Box mt={2}>
            <FormControl
              fullWidth
              disabled={
                !canUser('missing-receipt-notification:toggle') ||
                state.isReceiptNotificationsUpdating
              }
            >
              <FormControlLabel
                label={t('receiptManagementSubPage.switchReceiptNotifications')}
                checked={organization!.missingReceiptNotificationEnabled}
                labelPlacement="start"
                onChange={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isConfirmationDialogOpen: true,
                  }))
                }
                control={<Switch />}
              />
            </FormControl>
          </Box>
        </ContentContainer>
      </SettingsGroupBox>

      <ConfirmDialog
        open={state.isConfirmationDialogOpen}
        loading={state.isReceiptNotificationsUpdating}
        title={t(
          'receiptManagementSubPage.receiptNotificationsConfirmationDialog.title'
        )}
        description={
          organization!.missingReceiptNotificationEnabled
            ? t(
                'receiptManagementSubPage.receiptNotificationsConfirmationDialog.turningOff'
              )
            : t(
                'receiptManagementSubPage.receiptNotificationsConfirmationDialog.turningOn'
              )
        }
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            isConfirmationDialogOpen: false,
          }));
        }}
        onSuccess={updateReceiptNotifications}
      />
    </>
  );
};

export default NotificationsGroup;
