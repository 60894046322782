import { Typography, TypographyProps } from 'elements';
import { TransactionSimpleType, TransactionStatus } from 'services/constants';

interface Props extends TypographyProps {
  status: TransactionStatus;
  simpleType: TransactionSimpleType;
}

const TransactionAmount = ({
  status,
  simpleType,
  children,
  sx = [],
  ...rest
}: Props) => {
  const isSecondary =
    [TransactionStatus.declined, TransactionStatus.reversed].includes(status) ||
    simpleType === TransactionSimpleType.statusInquiry;
  const isCrossed = [
    TransactionStatus.declined,
    TransactionStatus.reversed,
  ].includes(status);

  return (
    <Typography
      variant="body2"
      color={isSecondary ? 'textSecondary' : 'textPrimary'}
      noWrap
      sx={[
        {
          textDecoration: isCrossed ? 'line-through' : 'initial',
          display: 'block',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default TransactionAmount;
