import React from 'react';
import { Box } from '@material-ui/core';
import { CardTileProps } from 'domains/card/components/CardTile/index';
import CardSensitiveData from './CardSensitiveData';
import { CardholderName } from './style';

const PhysicalLayout = (props: CardTileProps) => {
  const { card } = props;

  return (
    <>
      <Box position="absolute" top={129} left={24}>
        <CardholderName>
          {card.chFirstName} {card.chLastName}
        </CardholderName>
      </Box>
      <Box position="absolute" top={161} left={24}>
        <CardSensitiveData {...props} />
      </Box>
    </>
  );
};

export default PhysicalLayout;
