import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { QueryParams as ChTeamsTransactionsPageQueryParams } from 'domains/transaction/pages/CardholderTeamTransactionsPage';
import { QueryParams as TransactionsPageQueryParams } from 'domains/transaction/pages/TransactionsPage';
import { Chip, Tooltip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { getFilterChipText, PageFilterChips } from 'layout';
import { NONE_VALUE, Project, Team } from 'services/constants';

interface Props {
  params: TransactionsPageQueryParams | ChTeamsTransactionsPageQueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  teams: Team[];
  projects: Project[];
}

const FilterChips = ({
  params,
  selectedFiltersCount,
  setParam,
  teams,
  projects,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { cardAccounts, featureModules },
  } = useGlobalState();

  const statusChipTooltip = useMemo(() => {
    if (params.status.length < 2) return '';
    return params.status
      .map((item) => t(`transactionStatuses.${item}`))
      .join(', ');
  }, [params.status]);

  const txSimpleTypeChipTooltip = useMemo(() => {
    if (params.type.length < 2) return '';
    return params.type
      .map((item) => t(`transactionSimpleTypes.${item}`))
      .join(', ');
  }, [params.type]);

  const categoryChipTooltip = useMemo(() => {
    if (params.category.length < 2) return '';
    return params.category
      .map((item) => t(`merchantCategories.${item}`))
      .join(', ');
  }, [params.category]);

  const teamChipTooltip = useMemo(() => {
    if (!teams.length || params.teamId.length < 2) return '';
    const teamNames = params.teamId
      .map((item) => teams.find((team) => team.id === item)?.name)
      .filter((item) => !!item);
    return teamNames.join(', ');
  }, [params.teamId, teams]);

  const projectChipTooltip = useMemo(() => {
    if (!projects.length || params.projectIds.length < 2) return '';
    const projectNames = params.projectIds
      .map((item) => projects.find((project) => project.id === item)?.name)
      .filter((item) => !!item);
    return projectNames.join(', ');
  }, [params.projectIds, projects]);

  const periodChipText = useMemo(() => {
    if (!params.fromDate && !params.toDate) return '';
    const array = [];
    if (params.fromDate)
      array.push(moment(params.fromDate).format('D MMM YYYY'));
    if (params.toDate) array.push(moment(params.toDate).format('D MMM YYYY'));
    return array.join(' - ');
  }, [params.fromDate, params.toDate]);

  const reviewStatusChipText = useMemo(() => {
    if (!params.reviewStatus) return '';
    if (params.reviewStatus === NONE_VALUE) return t(`common.none`);
    return t(`transactionReviewStatus.${params.reviewStatus}`);
  }, [params.reviewStatus]);

  const cardAccountsChipText = useMemo(
    () =>
      cardAccounts.find((account) => account.id === params.cardAccountId)?.name
        ?.value || '',
    [params.cardAccountId, cardAccounts]
  );

  if (!selectedFiltersCount) return null;

  return (
    <>
      <PageFilterChips>
        {!!params.status.length && (
          <Tooltip title={statusChipTooltip}>
            <Chip
              label={getFilterChipText(
                t(`transactionStatuses.${params.status[0]}`),
                params.status.length
              )}
              onDelete={() => setParam('status', [])}
            />
          </Tooltip>
        )}

        {!!params.type.length && (
          <Tooltip title={txSimpleTypeChipTooltip}>
            <Chip
              label={getFilterChipText(
                t(`transactionSimpleTypes.${params.type[0]}`),
                params.type.length
              )}
              onDelete={() => setParam('type', [])}
            />
          </Tooltip>
        )}

        {(params.fromDate || params.toDate) && (
          <Chip
            label={periodChipText}
            onDelete={() =>
              setParam([
                ['toDate', ''],
                ['fromDate', ''],
              ])
            }
          />
        )}

        {cardAccounts.length > 1 && params.cardAccountId && (
          <Chip
            label={getFilterChipText(
              cardAccountsChipText,
              1,
              t('filters.account')
            )}
            onDelete={() => setParam('cardAccountId', '')}
          />
        )}

        {featureModules.RECEIPT_MANAGEMENT && !!params.receipt && (
          <Chip
            label={getFilterChipText(
              t(`transactionReceiptStatuses.${params.receipt}`),
              1,
              t('filters.receipts')
            )}
            onDelete={() => setParam('receipt', '')}
          />
        )}

        {!!params.category.length && (
          <Tooltip title={categoryChipTooltip}>
            <Chip
              label={getFilterChipText(
                t(`merchantCategories.${params.category[0]}`),
                params.category.length
              )}
              onDelete={() => setParam('category', [])}
            />
          </Tooltip>
        )}

        {!!params.teamId.length && !!teams.length && (
          <Tooltip title={teamChipTooltip}>
            <Chip
              label={getFilterChipText(
                teams.find((team) => team.id === params.teamId[0])?.name || '',
                params.teamId.length,
                t('filters.team')
              )}
              onDelete={() => setParam('teamId', [])}
            />
          </Tooltip>
        )}

        {!!params.projectIds.length && !!projects.length && (
          <Tooltip title={projectChipTooltip}>
            <Chip
              label={getFilterChipText(
                projects.find((project) => project.id === params.projectIds[0])
                  ?.name || '',
                params.projectIds.length,
                t('filters.project')
              )}
              onDelete={() => setParam('projectIds', [])}
            />
          </Tooltip>
        )}

        {params.reviewStatus && (
          <Chip
            label={reviewStatusChipText}
            onDelete={() =>
              setParam([
                ['reviewStatus', ''],
                ['flagReason', ''],
              ])
            }
          />
        )}

        {params.flagReason && (
          <Chip
            label={t(`reviewFlagReasons.${params.flagReason}`)}
            onDelete={() => setParam('flagReason', '')}
          />
        )}
      </PageFilterChips>
    </>
  );
};

export default FilterChips;
