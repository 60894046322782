import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { getDocumentUrl } from 'domains/document/utils';
import {
  BulkUploadDropzone,
  BulkUploadErrors,
} from 'domains/settings/components';
import { Dialog, DialogProps, withDialogWrapper } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { BulkUploadError, SupportedCountry } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

export const getUploadParams = (lng: 'de' | 'en') => ({
  delimiter: lng === 'de' ? ';' : ',',
  nameHeader: 'Name',
  costUnitHeader: lng === 'de' ? 'Kostenträger' : 'Cost unit',
});

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (count: number) => void;
}

interface State {
  file: File | null;
  uploadErrors: BulkUploadError[];
  progress: number;
  isLoading: boolean;
}

const UploadProjectDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const usedLang =
    organization!.country === SupportedCountry.de ||
    organization!.country === SupportedCountry.at
      ? 'de'
      : 'en';
  const [state, setState] = useState<State>({
    file: null,
    uploadErrors: [],
    progress: 0,
    isLoading: false,
  });
  const [isValidationLoading, setIsValidationLoading] = useState(false);

  const uploadHandler = async (uploadedFile?: File) => {
    if (!uploadedFile && !state.file) return;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const { projects } = await api.uploadProjects(
        organization!.id,
        (uploadedFile || state.file) as File,
        getUploadParams(usedLang),
        (progress) => {
          if (!mounted.current) return;
          setState((prevState) => ({ ...prevState, progress }));
        }
      );
      dispatch({ type: 'ADD_PROJECTS', payload: projects });
      if (!mounted.current) return;
      onSuccess(projects.length);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  const dropHandler = async (file: File) => {
    try {
      setState((prevState) => ({ ...prevState, file }));
      setIsValidationLoading(true);
      const errors = await api.validateProjects(
        organization!.id,
        file,
        getUploadParams(usedLang)
      );
      if (!mounted.current) return;
      setIsValidationLoading(false);
      if (errors.length > 0) {
        setState((prevState) => ({
          ...prevState,
          uploadErrors: errors,
        }));
      } else uploadHandler(file);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth="sm">
      {state.uploadErrors.length ? (
        <BulkUploadErrors
          isLoading={state.isLoading}
          dialogKey="uploadProjectsDialog"
          errors={state.uploadErrors}
          onSuccess={() => uploadHandler()}
          onClose={props.onClose}
        />
      ) : (
        <BulkUploadDropzone
          title={t('uploadProjectsDialog.title')}
          description={
            <Trans
              i18nKey="uploadProjectsDialog.description"
              components={{
                div: <span />,
                csvLink: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={getDocumentUrl(`project_csv_sample_${usedLang}.csv`)}
                    rel="noreferrer"
                    download
                  />
                ),
              }}
            />
          }
          file={state.file}
          isLoading={isValidationLoading || state.isLoading}
          onDrop={dropHandler}
          onClose={props.onClose}
        />
      )}
    </Dialog>
  );
};

export default withDialogWrapper(UploadProjectDialog);
