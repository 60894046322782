import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import {
  Alert,
  Box,
  Button,
  CopyIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  LoaderWithOverlay,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  ProgramSettlement,
  ProgramSettlementPaymentDetails,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { formatMoney, getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  onSuccess: () => void;
  onClose: () => void;
  settlement: ProgramSettlement;
}

const TriggerPaymentManuallyDialog = ({
  settlement,
  onSuccess,
  ...props
}: Props) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const currencyDisplay = useCurrencyDisplayMode();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [
    summary,
    setSummary,
  ] = useState<ProgramSettlementPaymentDetails | null>(null);
  const allInfo = summary
    ? [
        summary.creditorAccount.accountHolderName,
        summary.creditorAccount.bankName,
        summary.creditorAccount.iban,
        summary.creditorAccount.bic,

        summary.purpose,
        formatMoney(summary.amount, i18n.language, {
          currencyDisplay,
          fractionalPart: true,
        }),
      ].join()
    : '';

  const getData = async () => {
    try {
      const data = await api.getProgramSettlementPaymentSummary(
        settlement.paymentId!
      );
      if (!mounted.current) return;
      setSummary(data);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onCopy = () =>
    enqueueSnackbar(t('int.triggerPaymentManuallyDialog.itemCopiedMessage'), {
      variant: 'success',
    });

  const onTriggerPayment = async () => {
    try {
      setIsUpdating(true);
      await api.submitProgramSettlementPaymentsFiles(
        [settlement.programPaymentFileId!],
        settlement.settlementId
      );
      if (!mounted.current) return;
      onSuccess();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsUpdating(false);
      logError(error);
    }
  };

  const onSubmittedManually = async () => {
    try {
      setIsUpdating(true);
      await api.moveProgramSettlementPaymentToPending(
        settlement.programPaymentFileId!
      );
      if (!mounted.current) return;
      onSuccess();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsUpdating(false);
      logError(error);
    }
  };

  const renderTableHeaderCell = () => {
    if (!summary?.amount) return null;
    return summary.amount.value < 0
      ? t('int.triggerPaymentManuallyDialog.programSettlementAccount')
      : t('int.triggerPaymentManuallyDialog.issueSettlementAccount');
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{t('int.triggerPaymentManuallyDialog.title')}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={1}>
          {t('int.triggerPaymentManuallyDialog.description')}
        </Typography>

        {(summary?.amount.value || 0) < 0 && (
          <Alert severity="warning" sx={{ mb: 1, mt: 2 }}>
            {t('int.triggerPaymentManuallyDialog.reverseSettlementMessage')}
          </Alert>
        )}

        <Box position="relative" sx={{ minHeight: 200 }}>
          {!!summary && (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>{renderTableHeaderCell()}</TableCell>
                    <TableCell colSpan={2} align="right">
                      <CopyToClipboard onCopy={onCopy} text={allInfo}>
                        <Button
                          startIcon={<CopyIcon />}
                          variant="text"
                          size="small"
                        >
                          {t('int.triggerPaymentManuallyDialog.copyAll')}
                        </Button>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ width: 1 / 4, color: 'text.secondary' }}>
                      {t('int.triggerPaymentManuallyDialog.beneficiary')}
                    </TableCell>
                    <TableCell colSpan={2}>
                      {summary.creditorAccount.accountHolderName}
                    </TableCell>
                    <TableCell align="right">
                      <CopyToClipboard
                        text={summary.creditorAccount.accountHolderName}
                        onCopy={onCopy}
                      >
                        <IconButton size="small">
                          <CopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: 1 / 4, color: 'text.secondary' }}>
                      {t('int.triggerPaymentManuallyDialog.bank')}
                    </TableCell>
                    <TableCell colSpan={2}>
                      {summary.creditorAccount.bankName}
                    </TableCell>
                    <TableCell align="right">
                      <CopyToClipboard
                        text={summary.creditorAccount.bankName}
                        onCopy={onCopy}
                      >
                        <IconButton size="small">
                          <CopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: 1 / 4, color: 'text.secondary' }}>
                      {t('int.triggerPaymentManuallyDialog.iban')}
                    </TableCell>
                    <TableCell colSpan={2}>
                      {summary.creditorAccount.iban}
                    </TableCell>
                    <TableCell align="right">
                      <CopyToClipboard
                        text={summary.creditorAccount.iban}
                        onCopy={onCopy}
                      >
                        <IconButton size="small">
                          <CopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: 1 / 4, color: 'text.secondary' }}>
                      {t('int.triggerPaymentManuallyDialog.bic')}
                    </TableCell>
                    <TableCell colSpan={2}>
                      {summary.creditorAccount.bic}
                    </TableCell>
                    <TableCell align="right">
                      <CopyToClipboard
                        text={summary.creditorAccount.bic}
                        onCopy={onCopy}
                      >
                        <IconButton size="small">
                          <CopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: 1 / 4, color: 'text.secondary' }}>
                      {t('int.triggerPaymentManuallyDialog.purpose')}
                    </TableCell>
                    <TableCell colSpan={2}>{summary.purpose}</TableCell>
                    <TableCell align="right">
                      <CopyToClipboard text={summary.purpose} onCopy={onCopy}>
                        <IconButton size="small">
                          <CopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: 1 / 4, color: 'text.secondary' }}>
                      {t('int.triggerPaymentManuallyDialog.amount')}
                    </TableCell>
                    <TableCell colSpan={2}>
                      <FormatMoney value={summary.amount} fractionalPart />
                    </TableCell>
                    <TableCell align="right">
                      <CopyToClipboard
                        text={formatMoney(summary.amount, i18n.language, {
                          currencyDisplay,
                          fractionalPart: true,
                        })}
                        onCopy={onCopy}
                      >
                        <IconButton size="small">
                          <CopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <LoaderWithOverlay loading={isLoading} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        {(!summary || summary.amount.value > 0) && (
          <Button
            variant="outlined"
            disabled={isLoading || isUpdating}
            onClick={onTriggerPayment}
          >
            {t('int.triggerPaymentManuallyDialog.submitAutomatically')}
          </Button>
        )}
        <Button
          disabled={isLoading || isUpdating}
          onClick={onSubmittedManually}
        >
          {t('int.triggerPaymentManuallyDialog.submittedManually')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={isUpdating} />
    </Dialog>
  );
};

export default withDialogWrapper(TriggerPaymentManuallyDialog);
