import { useGlobalState, useManagedTeams } from 'context/GlobalState';
import useCurrentApp from 'hooks/useCurrentApp';
import { TransactionReviewStatus } from 'services/constants';
import useFlags from 'services/featureflags/useFlags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';

const useUpdateTransactionsCounters = () => {
  const { managerTransactionReviewEnabled } = useFlags();
  const { isAdminApp } = useCurrentApp();
  const api = useImperativeApi();
  const canUser = useCanUser();

  const {
    dispatch,
    state: { organization, featureModules },
  } = useGlobalState();
  const managedTeams = useManagedTeams();
  const isNeedsReviewPageVisible =
    managerTransactionReviewEnabled &&
    ((isAdminApp && canUser('transaction-review:view')) ||
      canUser('team-transaction-review:view')) &&
    featureModules.MANAGER_TX_REVIEWS;
  const isFlaggedTransactionsPageVisible =
    managerTransactionReviewEnabled &&
    canUser('transaction-review:view') &&
    (featureModules.MANAGER_TX_REVIEWS || featureModules.PRIVATE_EXPENSE);

  const getTransactionsNeedsReviewCounter = () => {
    if (!isNeedsReviewPageVisible) return;
    if (!organization?.id) return;
    return api
      .getTransactions({
        organizationId: organization.id,
        page: 0,
        limit: 1,
        teamId: isAdminApp
          ? undefined
          : managedTeams?.map((team) => team.id).join(','),
        reviewStatus: TransactionReviewStatus.needsReview,
      })
      .then((data) => data.totalCount);
  };

  const getTransactionsFlaggedCounter = () => {
    if (!isFlaggedTransactionsPageVisible) return;
    if (!organization?.id) return;
    return api
      .getTransactions({
        organizationId: organization.id,
        page: 0,
        limit: 1,
        reviewStatus: TransactionReviewStatus.flagged,
      })
      .then((data) => data.totalCount);
  };

  return async () => {
    if (!isNeedsReviewPageVisible && !isFlaggedTransactionsPageVisible) return;
    try {
      const [
        transactionsNeedsReviewCount,
        transactionsFlaggedCount,
      ] = await Promise.all([
        getTransactionsNeedsReviewCounter(),
        getTransactionsFlaggedCounter(),
      ]);
      dispatch({
        type: 'SET_TRANSACTIONS_COUNT',
        payload: {
          transactionsNeedsReviewCount,
          transactionsFlaggedCount,
        },
      });
    } catch (error) {
      logError(error);
    }
  };
};

export default useUpdateTransactionsCounters;
