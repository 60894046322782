import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Badge = styled.div`
  padding: 3px 10px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  border-radius: 11px;
  color: ${({ theme }) => theme.colors.grey10};
  background: ${({ theme }) => theme.colors.yellow50};
`;

interface Props {
  className?: string;
  text?: string;
}

export const PremiumBadge = ({ className, text }: Props) => {
  const { t } = useTranslation();

  return (
    <Badge className={className}>{text || t('common.plan.premium')}</Badge>
  );
};
