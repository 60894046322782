import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationRepresentativeInternalStatusBadge } from 'components/Badge';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CaretDownIcon,
  CheckIcon,
  EditButton,
  IconButton,
  Tooltip,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  OrganizationRepresentativeExternalStatus,
  OrganizationRepresentativeInternalStatus,
  OrganizationStatus,
  RelatedPerson,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import EditRepresentativeDialog from './EditRepresentativeDialog';
import { LegitimationRequestButton } from './LegitimationRequestButton';
import RepresentativeMenu from './RepresentativeMenu';
import RequestAdditionalInfoButton from './RequestAdditionalInfoButton';
import View from './View';
import { createRepresentativeDescription } from './utils';

interface State {
  isEditDialogOpen: boolean;
  isLoading: boolean;
}

interface Props {
  representative: RelatedPerson;
}

const Representative = ({ representative }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();

  const {
    state: { organization },
  } = useGlobalState();
  const {
    actions: { fetchRepresentatives },
  } = useCreditAndComplianceContext();

  const [state, setState] = useState<State>({
    isEditDialogOpen: false,
    isLoading: false,
  });

  const onReadyForSubmission = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      await api.markOrganizationRepresentativeReadyForSubmission(
        organization!.id,
        representative!.id.value
      );
      await fetchRepresentatives();

      if (!mounted) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<CaretDownIcon />}>
          <Box display="flex" alignItems="center">
            {createRepresentativeDescription(representative, t)}
            <Box marginLeft={2}>
              <EditButton
                disabled={state.isEditDialogOpen || state.isLoading}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  setState((prevState) => ({
                    ...prevState,
                    isEditDialogOpen: true,
                  }));
                }}
              />
            </Box>
          </Box>

          <Box marginLeft="auto" display="flex" alignItems="center">
            {representative.internalStatus.value !==
              OrganizationRepresentativeInternalStatus.archived &&
              ![
                OrganizationStatus.cancelled,
                OrganizationStatus.deactivated,
              ].includes(organization!.status) &&
              representative.externalStatus && (
                <>
                  <Box display="flex" alignItems="center">
                    <LegitimationRequestButton
                      isDisabled={state.isLoading}
                      version="relatedPerson"
                      orgRep={representative!}
                      onUpdate={fetchRepresentatives}
                    />
                  </Box>
                  <RequestAdditionalInfoButton
                    isLoading={state.isLoading}
                    setIsLoading={(isLoading) =>
                      setState((prevState) => ({ ...prevState, isLoading }))
                    }
                    representative={representative!}
                  />
                </>
              )}

            <Box display="flex" justifyContent="center" minWidth="100px">
              {representative.internalStatus.value ===
              OrganizationRepresentativeInternalStatus.draft ? (
                <Tooltip
                  title={t(
                    'int.organizationRepresentativeSection.markAsReadyTooltip'
                  )}
                >
                  <Box component="span" sx={{ cursor: 'default' }}>
                    <IconButton
                      disabled={
                        state.isLoading ||
                        !representative ||
                        representative.internalStatus.value !==
                          OrganizationRepresentativeInternalStatus.draft ||
                        [
                          OrganizationRepresentativeExternalStatus.infoFilled,
                          OrganizationRepresentativeExternalStatus.infoRequested,
                        ].includes(representative.externalStatus.value) ||
                        !representative.isRepresentativeDataFull
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        onReadyForSubmission();
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              ) : (
                <OrganizationRepresentativeInternalStatusBadge
                  status={representative.internalStatus.value}
                />
              )}
            </Box>
          </Box>

          <Box display="flex" alignItems="center" mr={2}>
            <RepresentativeMenu rep={representative} />
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <View rep={representative} />
        </AccordionDetails>
      </Accordion>

      <EditRepresentativeDialog
        mode="edit"
        open={state.isEditDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isEditDialogOpen: false,
          }))
        }
        relatedIndividual={undefined}
        representative={representative}
      />
    </>
  );
};

export default Representative;
