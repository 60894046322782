import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'components/Button';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OrganizationStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

const CancelApplicationButton = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    state: { organization },
    dispatch,
  } = useGlobalState();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const cancelApplication = async () => {
    try {
      setIsLoading(true);
      await api.cancelOrganization(organization!.id);
      if (!mounted.current) return;
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          organization: {
            ...organization!,
            status: OrganizationStatus.cancelled,
          },
        },
      });
      setIsLoading(false);
      setIsConfirmDialogOpen(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return canUser('org:cancel') ? (
    <>
      <Button variant="outlined" onClick={() => setIsConfirmDialogOpen(true)}>
        {t('int.riskAndCompliancePage.cancelApplicationButton')}
      </Button>

      <ConfirmDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onSuccess={cancelApplication}
        title={t(
          'int.riskAndCompliancePage.cancelApplicationConfirmationDialogTitle'
        )}
        loading={isLoading}
        description={
          <Trans
            i18nKey="int.riskAndCompliancePage.cancelApplicationConfirmationDialogText"
            values={{
              name: organization!.name,
            }}
            components={{
              b: <b />,
            }}
          />
        }
      />
    </>
  ) : null;
};

export default CancelApplicationButton;
