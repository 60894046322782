import styled from 'styled-components';
import { ReactComponent as ArrowsButtonDownIcon } from 'assets/icons/interface/arrowsButtonDown.svg';
import { ReactComponent as FileTextIcon } from 'assets/icons/interface/fileText.svg';
import { ErrorMessage } from 'components/Form';

export const StatementItemWrapper = styled.div<{ $isActive: boolean }>`
  margin: 0 0 14px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ $isActive, theme }) =>
      theme.colors[$isActive ? 'grey10' : 'neutralVariant50']};
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 15px 10px 13px 0;
`;

export const StatementItemMenuWrapper = styled.div`
  width: 210px;
  padding: 1px 10px 1px 18px;
  display: flex;
  justify-content: center;
`;

export const StyledFileTextIcon = styled(FileTextIcon)`
  width: 16px;
  height: 16px;
  margin: 16px 24px 0 32px;
`;

export const StyledArrowsButtonDownIcon = styled(ArrowsButtonDownIcon)<{
  $rotated?: boolean;
}>`
  transition: transform 150ms;
  transform: ${({ $rotated }) => `rotate(${$rotated ? 180 : 0}deg)`};
`;

export const DatesLabel = styled.div`
  margin: 8px 0 4px 6px;
  font-size: 18px;
  line-height: 20px;
`;

export const TransactionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px auto 0 auto;
`;

export const TransactionsMainValue = styled.div`
  position: relative;
  font-size: 18px;
  line-height: 20px;
`;

export const AmountBlock = styled.div`
  width: 180px;
  text-align: right;
`;

export const AmountLabel = styled.div`
  margin: 15px 0 0;
  font-size: 18px;
  line-height: 20px;
`;

export const PaidLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grey100};
`;

export const SummaryHeader = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 10px 20px 0 73px;
  font-size: 12px;
  line-height: 14px;
`;

export const SummaryHeaderLine = styled.div`
  margin: 0 0 5px 7px;
  flex: 1 0 auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const SummarySubHeader = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.grey100};
  padding-left: 73px;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  min-height: 42px;
  margin: 0;
  padding: 14px 0 0;
`;
