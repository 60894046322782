import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'elements';
import { OrganizationRepresentative } from 'services/constants';
import IdNowXmlUploadButton from './IdNowXmlUploadButton';
import WebIdJsonUploadButton from './WebIdJsonUploadButton';

interface Props {
  isInDialog?: boolean;
  onError: () => void;
  onFileSelectOpen: () => void;
  onSuccess: (updatedRepresentative: OrganizationRepresentative) => void;
  representativeId: string;
}

const FileUploadButtons = ({ isInDialog = false, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection={isInDialog ? 'row' : 'column'}
      position={isInDialog ? 'relative' : 'absolute'}
      right={isInDialog ? 'unset' : '35px'}
      marginRight={isInDialog ? 'auto' : 'unset'}
    >
      {!isInDialog && (
        <Box mb={1}>
          <Typography color="textSecondary">
            {t('int.organizationRepresentativeSection.fileUpload')}
          </Typography>
        </Box>
      )}
      <IdNowXmlUploadButton {...props} />

      <Box {...(isInDialog ? { ml: 2 } : { mt: '12px' })}>
        <WebIdJsonUploadButton {...props} />
      </Box>
    </Box>
  );
};

export default FileUploadButtons;
