import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';
import { AppWrapper } from 'components/App/AppWrapper';
import { env } from 'env';
import { onAuth0RedirectCallback } from 'services/utils';
import Routes from './Routes';

export const InternalApp = () => {
  const assetsFolderPath = {
    development: `${env.REACT_APP_ASSETS_URL}/images/dev`,
    staging: `${env.REACT_APP_ASSETS_URL}/images/staging`,
    prod: `${env.REACT_APP_ASSETS_URL}/images/v2`,
  }[env.REACT_APP_TARGET_ENV];

  return (
    <AppWrapper>
      <Helmet>
        <link
          type="image/x-icon"
          rel="shortcut icon"
          href={`${assetsFolderPath}/favicon.ico`}
        />
        <link
          type="image/png"
          sizes="16x16"
          rel="icon"
          href={`${assetsFolderPath}/favicon-16x16.png`}
        />
        <link
          type="image/png"
          sizes="32x32"
          rel="icon"
          href={`${assetsFolderPath}/favicon-32x32.png`}
        />
        <link
          type="image/png"
          sizes="96x96"
          rel="icon"
          href={`${assetsFolderPath}/favicon-96x96.png`}
        />
        <title>Internal</title>
      </Helmet>
      <Auth0Provider
        domain={env.REACT_APP_AUTH0_DOMAIN}
        clientId={env.REACT_APP_AUTH0_CLIENT_ID}
        audience={env.REACT_APP_AUTH0_AUDIENCE}
        redirectUri={window.location.origin + '/callback'}
        onRedirectCallback={onAuth0RedirectCallback}
      >
        <Routes />
      </Auth0Provider>
    </AppWrapper>
  );
};
