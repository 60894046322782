import { MerchantData } from 'domains/merchant/components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  Typography,
} from 'elements';
import { Merchant, OrganizationMerchant } from 'services/constants';

interface Props {
  merchant: Merchant | OrganizationMerchant;
}

const MerchantInfoAccordion = ({ merchant }: Props) => {
  return (
    <Accordion sx={{ ml: -3, pl: 1, borderBottomWidth: 0 }}>
      <AccordionSummary expandIcon={<CaretDownIcon />}>
        <Typography>{merchant.legalName || merchant.displayName}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pr: 0 }}>
        <MerchantData merchant={merchant} />
      </AccordionDetails>
    </Accordion>
  );
};

export default MerchantInfoAccordion;
