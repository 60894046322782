import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import InternalIndustryTypeSelectGroup from 'domains/creditAndCompliance/components/InternalIndustryTypeSelectGroup';
import NaceIndustryCodeAutoComplete from 'domains/creditAndCompliance/components/NaceIndustryCodeAutoComplete';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { parseAndReformatDateStr } from 'domains/creditAndCompliance/utils/parseAndReformatDateStr';
import OrganizationGroupAutocompleteSearch from 'domains/organization/components/OrganizationGroupAutocompleteSearch';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CustomerSummaryPayload,
  OrganizationGroupType,
  PartialOrganizationGroup,
  YesNo,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getBooleanFromYesNo,
  getGenericErrorMsg,
  getYesNoFromBoolean,
  validateVatIdByCountry,
} from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

interface FormValues
  extends Omit<
    CustomerSummaryPayload,
    | 'riskUnitHoldingType'
    | 'vatIdRequired'
    | 'riskUnitId'
    | 'riskUnitName'
    | 'riskUnitGroupId'
    | 'riskUnitGroupName'
  > {
  industryCodeDescription: string;
  riskUnit: PartialOrganizationGroup | null;
  riskUnitHoldingType: string;
  riskUnitGroup: PartialOrganizationGroup | null;
  vatIdRequired: string;
}

const EditCustomerSummaryDialog = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, organizationsStaticData, platformLegalForms },
  } = useGlobalState();
  const {
    actions: { refetchCompleteContext },
    state: { creditProfile },
  } = useCreditAndComplianceContext();
  const customerSummary = creditProfile!.customerSummary;

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      legalName: customerSummary.legalName.value ?? '',
      legalForm: customerSummary.legalForm.value ?? '',
      foundationDate: parseAndReformatDateStr(
        customerSummary.foundationDate.value
      ),
      website: customerSummary.website.value ?? '',
      vatId: customerSummary.vatId.value ?? '',
      vatIdRequired:
        typeof customerSummary.vatIdRequired.value === 'boolean'
          ? getYesNoFromBoolean(customerSummary.vatIdRequired.value, t, {
              returnSlug: true,
            })
          : '',
      industryCodeType: customerSummary.industryCodeType.value ?? '',
      industryCodeSource: customerSummary.industryCodeSource.value ?? '',
      industryCode: customerSummary.industryCode.value ?? '',
      industryCodeDescription: customerSummary.industryCodeDescription ?? '',
      internalIndustryType: customerSummary.internalIndustryType.value ?? '',
      internalIndustrySubtype:
        customerSummary.internalIndustrySubtype.value ?? '',
      riskUnit: customerSummary.riskUnitId.value
        ? ({
            id: customerSummary.riskUnitId.value,
            name: customerSummary.riskUnitName.value,
          } as PartialOrganizationGroup)
        : null,
      riskUnitGroup: customerSummary.riskUnitGroupId.value
        ? ({
            id: customerSummary.riskUnitGroupId.value,
            name: customerSummary.riskUnitGroupName.value,
          } as PartialOrganizationGroup)
        : null,
      riskUnitHoldingType:
        typeof customerSummary.riskUnitHoldingType.value === 'boolean'
          ? getYesNoFromBoolean(customerSummary.riskUnitHoldingType.value, t, {
              returnSlug: true,
            })
          : '',
    },
    validate: (values) => {
      const errors: { [k in keyof FormValues]?: string } = {};
      if (!values.legalName) {
        errors['legalName'] = t('errors.fieldRequired');
      }
      if (!values.legalForm) {
        errors['legalForm'] = t('errors.fieldRequired');
      }
      if (!values.foundationDate) {
        errors['foundationDate'] = t('errors.fieldRequired');
      }
      if (!values.industryCode) {
        errors['industryCode'] = t('errors.fieldRequired');
      }
      if (values.vatIdRequired === YesNo.YES) {
        if (!values.vatId) {
          errors['vatId'] = t('errors.fieldRequired');
        }
        const vatIdFormatError = validateVatIdByCountry(
          customerSummary.country.value,
          values.vatId!,
          t
        );
        if (!!values.vatId && vatIdFormatError) {
          errors['vatId'] = vatIdFormatError;
        }
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const payload: CustomerSummaryPayload = {
          foundationDate: values.foundationDate ? values.foundationDate : null,
          industryCode: values.industryCode ? values.industryCode : null,
          industryCodeSource: values.industryCodeSource
            ? values.industryCodeSource
            : null,
          industryCodeType: values.industryCodeType
            ? values.industryCodeType
            : null,
          internalIndustryType: values.internalIndustryType
            ? values.internalIndustryType
            : null,
          internalIndustrySubtype: values.internalIndustrySubtype
            ? values.internalIndustrySubtype
            : null,
          legalForm: values.legalForm ? values.legalForm : null,
          legalName: values.legalName ? values.legalName : null,
          riskUnitId: values.riskUnit ? values.riskUnit.id : null,
          riskUnitName: values.riskUnit ? values.riskUnit.name : null,
          riskUnitGroupId: values.riskUnitGroup
            ? values.riskUnitGroup.id
            : null,
          riskUnitGroupName: values.riskUnitGroup
            ? values.riskUnitGroup.name
            : null,
          riskUnitHoldingType: getBooleanFromYesNo(values.riskUnitHoldingType)!,
          vatId: values.vatId ? values.vatId : null,
          vatIdRequired: getBooleanFromYesNo(values.vatIdRequired)!,
          website: values.website ? values.website : null,
        };

        await api.updateCustomerSummary(organization!.id, payload);
        await refetchCompleteContext();

        if (!mounted) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });
  const platformLegalFormsByCountry = useMemo(
    () =>
      platformLegalForms
        .filter((item) => item.country === customerSummary.country.value)
        .map((item) => item.abbreviation)
        .sort(),
    [customerSummary.country.value, platformLegalForms]
  );

  const getCustomFieldProps = <T extends keyof FormValues>(name: T) => {
    return {
      ...(formik.errors[name] && {
        error: true,
        helperText: formik.errors[name],
      }),
      name,
      value: formik.values[name],
    };
  };
  const onChange = <T extends keyof FormValues>(
    name: T,
    value: FormValues[T]
  ) => {
    if (formik.errors[name]) {
      formik.setFieldError(name, undefined);
    }
    formik.setFieldValue(name, value);
  };

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('int.editCustomerSummaryDialog.title')}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('legalName')}
              onChange={(event) => onChange('legalName', event.target.value)}
              label={t('int.editCustomerSummaryDialog.legalName')}
            />
          </Grid>
          <Grid item xs={6}>
            <OrganizationGroupAutocompleteSearch
              label={t('int.organizationGroupType.GCC')}
              onChange={(orgGroup) => onChange('riskUnitGroup', orgGroup)}
              organizationGroupType={[
                OrganizationGroupType.gccLegalEntity,
                OrganizationGroupType.gccNaturalPerson,
              ].join(',')}
              value={formik.values.riskUnitGroup}
            />
          </Grid>
          <Grid item xs={6}>
            <OrganizationGroupAutocompleteSearch
              label={t('int.organizationGroupType.RU')}
              onChange={(orgGroup) => onChange('riskUnit', orgGroup)}
              organizationGroupType={OrganizationGroupType.ru}
              value={formik.values.riskUnit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard">
              <FormLabel>
                {t('int.editCustomerSummaryDialog.riskUnitHolding')}
              </FormLabel>
              <RadioGroup
                row
                value={formik.values.riskUnitHoldingType}
                onChange={(_, value) => {
                  onChange('riskUnitHoldingType', value);
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={t('common.yes')}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label={t('common.no')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete<string, false, false, false>
              {...getCustomFieldProps('legalForm')}
              onChange={(_, option) => onChange('legalForm', option)}
              options={platformLegalFormsByCountry}
              label={t('int.editCustomerSummaryDialog.legalForm')}
              placeholder="Placeholder"
              noOptionsText="Nothing found"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('foundationDate')}
              onChange={(event) =>
                onChange('foundationDate', event.target.value)
              }
              label={t('int.editCustomerSummaryDialog.foundationDate')}
              type="date"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...getCustomFieldProps('website')}
              onChange={(event) => onChange('website', event.target.value)}
              label={t('int.editCustomerSummaryDialog.website')}
            />
          </Grid>

          <Grid
            item
            xs={12}
            borderBottom="1px solid"
            borderColor={(theme) => theme.palette.divider}
            marginLeft={2}
          />
          <Grid item xs={6}>
            <FormControl variant="standard">
              <FormLabel>
                {t('int.editCustomerSummaryDialog.subjectToVatId')}
              </FormLabel>
              <RadioGroup
                row
                value={formik.values.vatIdRequired}
                onChange={(_, value) => {
                  onChange('vatIdRequired', value);
                  if (value === YesNo.NO) {
                    formik.setFieldValue('vatId', '');
                  }
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={t('common.yes')}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label={t('common.no')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {formik.values.vatIdRequired === YesNo.YES && (
              <TextField
                {...getCustomFieldProps('vatId')}
                onChange={(event) => onChange('vatId', event.target.value)}
                label={t('int.editCustomerSummaryDialog.vatId')}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            borderBottom="1px solid"
            borderColor={(theme) => theme.palette.divider}
            marginLeft={2}
          />

          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.industryCodeSource}>
              <InputLabel>
                {t('int.editCustomerSummaryDialog.industryCodeType')}
              </InputLabel>
              <Select
                {...omit(getCustomFieldProps('industryCodeType'), 'helperText')}
                onChange={({ target: { value } }) =>
                  onChange('industryCodeType', value)
                }
              >
                {organizationsStaticData!.industryCodeTypes.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors.industryCodeType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!formik.errors.industryCodeSource}>
              <InputLabel>
                {t('int.editCustomerSummaryDialog.industryCodeSource')}
              </InputLabel>
              <Select
                {...omit(
                  getCustomFieldProps('industryCodeSource'),
                  'helperText'
                )}
                onChange={({ target: { value } }) =>
                  onChange('industryCodeSource', value)
                }
              >
                {organizationsStaticData!.industryCodeSources.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.industryCodeSource}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <NaceIndustryCodeAutoComplete
              error={formik.errors.industryCode}
              value={
                formik.values.industryCode
                  ? {
                      code: formik.values.industryCode,
                      description: formik.values.industryCodeDescription,
                    }
                  : null
              }
              onChange={(values) => {
                if (formik.errors.industryCode) {
                  formik.setFieldError('industryCode', undefined);
                }
                formik.setValues((prev) => ({
                  ...prev,
                  industryCode: values ? values.code : null,
                  industryCodeDescription: values ? values.description : '',
                }));
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            borderBottom="1px solid"
            borderColor={(theme) => theme.palette.divider}
            marginLeft={2}
          />

          <Grid item xs={12}>
            <InternalIndustryTypeSelectGroup
              internalIndustrySubtype={formik.values.internalIndustrySubtype}
              internalIndustryType={formik.values.internalIndustryType}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={formik.isSubmitting} onClick={formik.submitForm}>
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditCustomerSummaryDialog);
