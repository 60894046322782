import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'elements';
import { BankSubmissionRawData } from 'services/constants';
import { getYesNoFromBoolean } from 'services/utils';
import ComplianceScreeningItem from './ComplianceScreeningItem';
import LabelValueItem from './LabelValueItem';

interface Props {
  data:
    | BankSubmissionRawData['representatives'][number]
    | BankSubmissionRawData['beneficialOwners'][number];
}

const RepresentativeSection = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" fontWeight="bold">
          {[
            'salutation' in data && data.salutation
              ? {
                  1: 'Mr',
                  2: 'Mrs',
                }[data.salutation]
              : [],
            data.givenName,
            data.name,
          ].join(' ')}
        </Typography>
      </Grid>

      {'companyShare' in data && (
        <LabelValueItem
          label={t('int.organizationRepresentativeSection.companyShareLabel')}
          value={
            t(
              `int.organizationRepresentativeSection.companyShareValues.${data.companyShare}`
            ) + ` (${data.companyShare})`
          }
          labelXsGridSize={2}
        />
      )}

      <Grid item xs={12}>
        <Typography variant="body2">
          {t('int.organizationRepresentativeSection.personalBlockTitle')}
        </Typography>
      </Grid>
      <Grid item container>
        <Grid container item direction="column" xs={4} spacing={1}>
          <LabelValueItem
            label={t('int.organizationRepresentativeSection.salutationLabel')}
            value={
              'salutation' in data && data.salutation
                ? {
                    1: 'Mr',
                    2: 'Mrs',
                  }[data.salutation]
                : '-'
            }
          />
          <LabelValueItem
            label={t('int.organizationRepresentativeSection.givenNameLabel')}
            value={data.givenName}
          />
          <LabelValueItem
            label={t('int.organizationRepresentativeSection.surnameLabel')}
            value={data.name}
          />
          <LabelValueItem
            label={t('int.organizationRepresentativeSection.addressLabel')}
            value={
              <>
                <div>
                  {data.street}&nbsp;
                  {data.streetNumber}
                </div>
                <div>
                  {data.postalCode}&nbsp;
                  {data.city}
                </div>
                <div>{data.country}</div>
              </>
            }
          />
        </Grid>
        <Grid container item direction="column" xs={4} spacing={1}>
          <LabelValueItem
            label={t('int.organizationRepresentativeSection.citizenshipLabel')}
            value={data.citizenship.join(',')}
          />
          <LabelValueItem
            label={t('int.organizationRepresentativeSection.dateOfBirthLabel')}
            value={
              data.dateOfBirth && moment(data.dateOfBirth).format('DD.MM.YYYY')
            }
          />
          {'placeOfBirth' in data && (
            <LabelValueItem
              label={t(
                'int.organizationRepresentativeSection.placeOfBirthLabel'
              )}
              value={data.placeOfBirth}
            />
          )}
          {'countryOfBirth' in data && (
            <LabelValueItem
              label={t(
                'int.organizationRepresentativeSection.countryOfBirthLabel'
              )}
              value={data.countryOfBirth}
            />
          )}
        </Grid>
        <Grid container item direction="column" xs={4} spacing={1}>
          <LabelValueItem
            label={t('int.organizationRepresentativeSection.fatcaLabel')}
            value={getYesNoFromBoolean(data.isFATCA, t)}
          />
          <LabelValueItem
            label={t('int.organizationRepresentativeSection.pepLabel')}
            value={getYesNoFromBoolean(data.isPep, t)}
          />
          <LabelValueItem
            label={t('int.organizationRepresentativeSection.tinLabel')}
            value={data.tin}
          />
          {'email' in data && (
            <LabelValueItem
              label={t('int.organizationRepresentativeSection.emailLabel')}
              value={data.email}
            />
          )}
          {'mobileNumber' in data && (
            <LabelValueItem
              label={t('int.organizationRepresentativeSection.mobileLabel')}
              value={data.mobileNumber}
            />
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2">
          {t('int.organizationRepresentativeSection.legitimationBlockTitle')}
        </Typography>
      </Grid>
      <Grid item container>
        <Grid container item direction="column" xs={4} spacing={1}>
          <LabelValueItem
            label={t(
              'int.organizationRepresentativeSection.legitimationDateLabel'
            )}
            value={
              data.legitimation.date &&
              moment(data.legitimation.date).format('DD.MM.YYYY')
            }
          />
          <LabelValueItem
            label={t(
              'int.organizationRepresentativeSection.legitimationProviderLabel'
            )}
            value={
              data.legitimation.provider &&
              t(
                `int.organizationRepresentativeSection.legitimationProvider.${data.legitimation.provider}`
              )
            }
          />
        </Grid>
        <Grid container item direction="column" xs={4} spacing={1}>
          <LabelValueItem
            label={t(
              'int.organizationRepresentativeSection.legitimationDocumentLabel'
            )}
            value={
              data.legitimation.identificationType &&
              t(
                `int.organizationRepresentativeSection.legitimationDocument.${data.legitimation.identificationType}`
              )
            }
          />
          <LabelValueItem
            label={t('int.organizationRepresentativeSection.documentIdLabel')}
            value={data.legitimation.identificationDocument.number}
          />
          <LabelValueItem
            label={t(
              'int.organizationRepresentativeSection.issuingOfficeLabel'
            )}
            value={data.legitimation.identificationDocument.issuer}
          />
        </Grid>
        <Grid container item direction="column" xs={4} spacing={1}>
          <LabelValueItem
            label={t('int.organizationRepresentativeSection.issueDateLabel')}
            value={
              data.legitimation.identificationDocument.issuanceDate &&
              moment(
                data.legitimation.identificationDocument.issuanceDate
              ).format('DD.MM.YYYY')
            }
          />
          <LabelValueItem
            label={t('int.organizationRepresentativeSection.validityDateLabel')}
            value={
              data.legitimation.identificationDocument.validityDate &&
              moment(
                data.legitimation.identificationDocument.validityDate
              ).format('DD.MM.YYYY')
            }
          />
        </Grid>
      </Grid>

      <Grid container item direction="column" xs={12} spacing={1}>
        <Grid item>
          <Typography variant="body2">
            {t(
              'int.organizationRepresentativeSection.complianceScreeningBlockTitle'
            )}
          </Typography>
        </Grid>
        <ComplianceScreeningItem
          amlRiskScoringDate={data.kyc.amlRiskScoring.checkDate}
          amlRiskScoringResult={data.kyc.amlRiskScoring.result}
          sanctionListCheckDate={data.kyc.sanctionListCheck.checkDate}
          sanctionListCheckResult={data.kyc.sanctionListCheck.result}
        />
      </Grid>
    </Grid>
  );
};

export default RepresentativeSection;
