import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from 'elements';
import {
  LegalRepresentativeType,
  OrganizationStatus,
} from 'services/constants';

interface Props {
  initialLegalRepresentativeValue: LegalRepresentativeType | null;
  legalRepresentativeValue: LegalRepresentativeType | null;
  powerOfAttorneyValue: boolean;
  onChangeLegalRepresentative: (value: LegalRepresentativeType) => void;
  onChangePowerOfAttorney: (checked: boolean) => void;
}

const EditLegalRepSection = ({
  initialLegalRepresentativeValue,
  legalRepresentativeValue,
  powerOfAttorneyValue,
  onChangeLegalRepresentative,
  onChangePowerOfAttorney,
}: Props) => {
  const { t } = useTranslation();

  const {
    state: { organization },
  } = useGlobalState();

  return (
    <>
      <FormControl variant="standard">
        <FormLabel>
          {t('int.editLegalRepSection.authorizedToRepOrgLabel')}
        </FormLabel>
        <RadioGroup
          value={legalRepresentativeValue}
          onChange={(e) =>
            onChangeLegalRepresentative(
              e.target.value as LegalRepresentativeType
            )
          }
          row
        >
          <FormControlLabel
            value={LegalRepresentativeType.sole}
            control={<Radio />}
            label={t('int.editLegalRepSection.soleLegalRepOption')}
          />
          <FormControlLabel
            value={LegalRepresentativeType.joint}
            control={<Radio />}
            label={t('int.editLegalRepSection.jointLegalRepOption')}
          />
          <FormControlLabel
            value={LegalRepresentativeType.none}
            control={<Radio />}
            label={t('int.editLegalRepSection.noLegalRepOption')}
          />
        </RadioGroup>
      </FormControl>

      {legalRepresentativeValue &&
        [LegalRepresentativeType.sole, LegalRepresentativeType.joint].includes(
          legalRepresentativeValue
        ) && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  id="power-of-attorney"
                  checked={powerOfAttorneyValue}
                  onChange={(_, checked: boolean) =>
                    onChangePowerOfAttorney(checked)
                  }
                />
              }
              label={t('int.editLegalRepSection.powerOfAttorney')}
            />
          </Box>
        )}

      {[
        OrganizationStatus.onboardingCompliance,
        OrganizationStatus.onboardingDirectDebit,
        OrganizationStatus.onboardingWait,
      ].includes(organization!.status) &&
        initialLegalRepresentativeValue &&
        [LegalRepresentativeType.joint, LegalRepresentativeType.sole].includes(
          initialLegalRepresentativeValue
        ) &&
        initialLegalRepresentativeValue !== legalRepresentativeValue && (
          <Box mt={2}>
            <Alert severity="warning">
              <Trans
                i18nKey="int.editLegalRepSection.updateRoleWarningDuringOnboarding"
                components={{ b: <b /> }}
              />
            </Alert>
          </Box>
        )}
    </>
  );
};

export default EditLegalRepSection;
