import { useTranslation } from 'react-i18next';
import { DataGridCell, Typography } from 'elements';
import { Card, CardSimpleStatus } from 'services/constants';

interface Props {
  card: Card;
}

const LimitFrequencyCell = ({ card }: Props) => {
  const { t } = useTranslation();

  if (card.simpleStatus === CardSimpleStatus.requested) {
    return (
      <Typography variant="inherit" color="info.main" noWrap>
        {t(`cardLimitFrequency.frequencies.${card.limitRenewFrequency}`)}
      </Typography>
    );
  }

  return (
    <DataGridCell sx={{ textAlign: 'right' }}>
      {card.requestedLimitRenewFrequency && (
        <Typography variant="caption2" component="div" color="info.main" noWrap>
          {t(
            `cardLimitFrequency.frequencies.${card.requestedLimitRenewFrequency}`
          )}
        </Typography>
      )}
      <Typography variant="inherit" noWrap>
        {t(`cardLimitFrequency.frequencies.${card.limitRenewFrequency}`)}
      </Typography>
    </DataGridCell>
  );
};

export default LimitFrequencyCell;
