import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import {
  MemberDetailsCardsBlock,
  MemberDetailsContactBlock,
  MemberDetailsHeader,
  MemberDetailsMenu,
  MemberDetailsTransactionsBlock,
} from 'domains/member/components';
import { LoaderWithOverlay } from 'elements';
import useMounted from 'hooks/useMounted';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  Card,
  CardSimpleStatus,
  memberCardStatusesToRequest,
  MemberDetails,
  Transaction,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { filterAndSortMemberCards } from 'services/utils';

interface State {
  isLoading: boolean;
  member: MemberDetails | null;
  error: unknown;
  cards: Card[] | null;
  transactions: Transaction[] | null;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (member: MemberDetails, cards: Card[]) => void;
}

const UserDetailsPage = ({ onUpdate, ...props }: Props) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { orgId, memberId } = useParams<{ memberId: string; orgId: string }>();
  const organizationId = orgId;
  const {
    state: { isComplianceRiskWhiteLabelApp },
  } = useGlobalState();
  const idRef = useRef(memberId);
  const [state, setState] = useState<State>({
    isLoading: true,
    member: null,
    error: null,
    cards: null,
    transactions: null,
  });

  useEffect(() => {
    if (state.member && state.cards) {
      const cards = state.cards.filter(
        (item) => item.simpleStatus !== CardSimpleStatus.requested
      );
      onUpdate(state.member, cards);
    }
  }, [state.member, state.cards]);

  const getData = async () => {
    try {
      setState((state) => ({
        ...state,
        isLoading: true,
      }));

      const member = await api.getMember(organizationId, memberId);
      const [cards, transactions] = isComplianceRiskWhiteLabelApp
        ? [null, null]
        : await Promise.all([
            api
              .getCards({
                organizationId,
                memberId,
                status: memberCardStatusesToRequest.join(),
              })
              .then(
                (data) => filterAndSortMemberCards(data.cards),
                () => null
              ),
            api
              .getTransactions({
                organizationId,
                memberId,
                page: 0,
                limit: 3,
              })
              .then(
                (data) => data.transactions,
                () => null
              ),
          ]);

      if (!mounted.current || memberId !== idRef.current) return;
      setState((state) => ({
        ...state,
        member,
        cards,
        transactions,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || memberId !== idRef.current) return;
      setState((state) => ({
        ...state,
        error,
        member: null,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!memberId) return;
    idRef.current = memberId;
    getData();
    // pass organizationId as there can be
    // users duplicates with different orgs
  }, [memberId, organizationId]);

  return (
    <DetailsDrawer
      {...props}
      actionsComponent={
        state.member && (
          <MemberDetailsMenu
            member={state.member}
            cards={state.cards}
            onCardIssue={getData}
            onUpdate={(member, cards) =>
              setState((prevState) => ({
                ...prevState,
                member,
                cards: cards || prevState.cards,
              }))
            }
            isGlobalUsersPage
          />
        )
      }
    >
      {state.member && (
        <>
          <DetailsDrawerHeader>
            <MemberDetailsHeader member={state.member} />
          </DetailsDrawerHeader>

          <DetailsDrawerContent>
            <MemberDetailsContactBlock member={state.member} />

            <MemberDetailsCardsBlock
              member={state.member}
              cards={state.cards}
              onCardIssue={getData}
              onRetry={getData}
            />

            <MemberDetailsTransactionsBlock
              member={state.member}
              transactions={state.transactions}
              onRetry={getData}
            />
          </DetailsDrawerContent>
        </>
      )}

      {state.error && <WidgetError onReload={getData} />}
      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(UserDetailsPage);
