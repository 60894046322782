import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
}

const FoundationDateChangeDialog = (props: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const org = useMemo(() => organization!, []);
  const formik = useFormik({
    validateOnMount: true,
    validateOnBlur: false,
    initialValues: {
      foundationDate: org.foundationDate
        ? moment(org.foundationDate, 'D.MM.YY').format('YYYY-MM-DD')
        : '',
    },
    onSubmit: async ({ foundationDate }, { setSubmitting }) => {
      try {
        const data = await api.updateFoundationDate(org.id, {
          foundationDate: moment(foundationDate).format('DD.MM.YYYY'),
        });
        if (!mounted.current) return;
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { organization: data },
        });
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const hasEmptyField = !formik.values.foundationDate;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('settingsOrganizationPage.foundationDate')}</DialogTitle>

      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="foundation-date-change-form"
          noValidate
        >
          <TextField
            type="date"
            label={t('settingsOrganizationPage.foundationDate')}
            value={formik.values.foundationDate}
            onChange={(e) => {
              formik.setFieldValue('foundationDate', e.target.value, true);
            }}
            disabled={formik.isSubmitting}
            inputProps={{
              max: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            }}
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          disabled={formik.isSubmitting}
          onClick={props.onClose}
        >
          {t('common.button.cancel')}
        </Button>

        <Button
          form="foundation-date-change-form"
          type="submit"
          disabled={hasEmptyField || formik.isSubmitting}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(FoundationDateChangeDialog);
