import { useState } from 'react';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import TeamsSelect from 'domains/settings/components/TeamsSelect';
import { useTeamsShown } from 'domains/settings/utils';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  NetworkErrorCode,
  Project,
  Team,
  TeamStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import useCanUser from 'services/rbac/useCanUser';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';

interface FormikValues {
  name: string;
  costUnit: string;
  teams: Team[];
}

interface State {
  confirmation: 'deactivate' | 'delete' | null;
  isUpdating: boolean;
}

interface Props extends DialogProps {
  isAssignMode: boolean;
  onClose: () => void;
  onSuccess: () => void;
  project: Project | null;
}

const EditProjectDialog = ({
  isAssignMode,
  project,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useGlobalState();
  const [state, setState] = useState<State>({
    confirmation: null,
    isUpdating: false,
  });

  const teamsAreShown = useTeamsShown();

  const formik = useFormik<FormikValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: project!.name,
      costUnit: project!.costUnit,
      // map to a regular object for a proper view in Team select
      teams: project!.teams.map((item) => ({
        ...item,
        costCenter: '',
        teamStatus: TeamStatus.active,
        createdAt: '',
        members: [],
        managers: [],
        maxLimitPerCard: { value: 0, currency: '' },
        teamManagerCanIssueCardForSelf: false,
        budget: { value: 0, currency: '' },
        balance: null,
        availableBudget: null,
        managerTxReviewRequired: false,
      })),
    },
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        const data = await api.updateProject(project!.id, {
          status: project!.status,
          organizationId: project!.organizationId,
          name: values.name.trim(),
          costUnit: values.costUnit.trim(),
          teamIds: values.teams.map((item) => item.id),
        });
        dispatch({ type: 'UPDATE_PROJECT', payload: data });
        if (!mounted.current) return;
        onSuccess();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (
          getNetworkErrorCode(error) === NetworkErrorCode.projectNameIsTaken
        ) {
          setFieldError('name', t('editProjectDialog.projectNameIsTakenError'));
          return;
        }
        if (getNetworkErrorCode(error) === NetworkErrorCode.costUnitIsTaken) {
          setFieldError(
            'costUnit',
            t('editProjectDialog.costUnitIsTakenError')
          );
          return;
        }
        if (getNetworkErrorCode(error) === NetworkErrorCode.costUnitInUse) {
          setFieldError('costUnit', t('editProjectDialog.costUnitInUseError'));
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const changeStatus = async (status: AccountingItemStatus) => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      const data = await api.updateProject(project!.id, {
        status,
        organizationId: project!.organizationId,
        name: project!.name,
        costUnit: project!.costUnit,
        teamIds: project!.teams.map((item) => item.id),
      });
      dispatch({ type: 'UPDATE_PROJECT', payload: data });
      if (!mounted.current) return;
      onSuccess();
      enqueueSnackbar(
        status === AccountingItemStatus.inactive
          ? t('editProjectDialog.deactivatedSuccessMessage')
          : t('editProjectDialog.activatedSuccessMessage'),
        { autoHideDuration: 1500 }
      );
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        confirmation: null,
        isUpdating: false,
      }));
      if (
        getNetworkErrorCode(error) === NetworkErrorCode.projectInCardUseStatus
      ) {
        props.onClose();
        enqueueSnackbar(
          t('editProjectDialog.deactivateProjectInCardUseStatus'),
          { variant: 'error' }
        );
        return;
      }
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const handleDelete = async () => {
    try {
      setState((prevState) => ({ ...prevState, isUpdating: true }));
      await api.deleteProject(project!.id);
      dispatch({ type: 'DELETE_PROJECT', payload: project! });
      if (!mounted.current) return;
      onSuccess();
      enqueueSnackbar(t('editProjectDialog.deletedSuccessMessage'), {
        autoHideDuration: 1500,
      });
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        confirmation: null,
        isUpdating: false,
      }));
      if (getNetworkErrorCode(error) === NetworkErrorCode.projectInUse) {
        props.onClose();
        enqueueSnackbar(t('editProjectDialog.deleteProjectIsInUse'), {
          variant: 'error',
        });
        return;
      }
      if (getNetworkErrorCode(error) === NetworkErrorCode.projectIsActive) {
        props.onClose();
        enqueueSnackbar(t('editProjectDialog.deleteProjectIsActive'), {
          variant: 'error',
        });
        return;
      }
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  if (state.confirmation === 'deactivate') {
    return (
      <ConfirmDialog
        {...props}
        title={t('editProjectDialog.deactivateStep.title')}
        description={
          <Trans
            i18nKey="editProjectDialog.deactivateStep.description"
            components={{ br: <br /> }}
          />
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          color: 'error',
          children: t('editProjectDialog.deactivateStep.deactivate'),
        }}
        onSuccess={() => changeStatus(AccountingItemStatus.inactive)}
        onClose={() =>
          setState((prevState) => ({ ...prevState, confirmation: null }))
        }
      />
    );
  }

  if (state.confirmation === 'delete') {
    return (
      <ConfirmDialog
        {...props}
        title={t('editProjectDialog.deleteStep.title')}
        description={
          <Trans
            i18nKey="editProjectDialog.deleteStep.description"
            components={{ br: <br /> }}
          />
        }
        loading={state.isUpdating}
        confirmButtonProps={{
          color: 'error',
          children: t('editProjectDialog.deleteStep.delete'),
        }}
        onSuccess={handleDelete}
        onClose={() =>
          setState((prevState) => ({ ...prevState, confirmation: null }))
        }
      />
    );
  }

  const hasEmptyField = !formik.values.name.trim();

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle display="flex" alignItems="center">
        {t('editProjectDialog.title')}
        {project?.status === AccountingItemStatus.inactive && (
          <Box ml={1} fontSize={0}>
            <Chip
              size="small"
              label={t('editProjectDialog.inactiveBadge')}
              variant="outlined"
            />
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} noValidate id="edit-project-form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('name')}
                label={t('editProjectDialog.nameTitle')}
                onChange={(e) => {
                  formik.setFieldValue(e.target.name, e.target.value, false);
                  formik.setFieldError(e.target.name, undefined);
                }}
                disabled={
                  isAssignMode || formik.isSubmitting || state.isUpdating
                }
                error={!!formik.errors.name}
                helperText={formik.errors.name}
              />
            </Grid>

            {!(isAssignMode && !formik.values.costUnit) && (
              <Grid item xs={12}>
                <TextField
                  {...formik.getFieldProps('costUnit')}
                  label={t('editProjectDialog.costUnitTitle')}
                  onChange={(e) => {
                    formik.setFieldValue(e.target.name, e.target.value, false);
                    formik.setFieldError(e.target.name, undefined);
                  }}
                  disabled={
                    isAssignMode || formik.isSubmitting || state.isUpdating
                  }
                  error={!!formik.errors.costUnit}
                  helperText={formik.errors.costUnit}
                />
              </Grid>
            )}

            {teamsAreShown && (
              <Grid item xs={12}>
                <TeamsSelect
                  label={t('editProjectDialog.teamTitle')}
                  disabled={formik.isSubmitting}
                  {...omit(formik.getFieldProps('teams'), 'onChange')}
                  onChange={(teams) => formik.setFieldValue('teams', teams)}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        {project?.status === AccountingItemStatus.active ? (
          <>
            {!isAssignMode && (
              <Box mr="auto">
                <Button
                  disabled={formik.isSubmitting || state.isUpdating}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      confirmation: 'deactivate',
                    }));
                  }}
                  variant="text"
                  color="error"
                >
                  {t('editProjectDialog.deactivate')}
                </Button>
              </Box>
            )}
            <Button onClick={props.onClose} variant="text">
              {t('common.button.close')}
            </Button>
            <Button
              disabled={
                hasEmptyField || formik.isSubmitting || state.isUpdating
              }
              type="submit"
              form="edit-project-form"
            >
              {t('editProjectDialog.save')}
            </Button>
          </>
        ) : (
          <>
            {!isAssignMode && canUser('project:delete') && (
              <Box mr="auto">
                <Button
                  disabled={formik.isSubmitting || state.isUpdating}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      confirmation: 'delete',
                    }))
                  }
                  variant="text"
                  color="error"
                >
                  {t('editProjectDialog.delete')}
                </Button>
              </Box>
            )}
            <Button onClick={props.onClose} variant="text">
              {t('common.button.close')}
            </Button>
            {formik.dirty && (
              <Button
                disabled={
                  hasEmptyField || formik.isSubmitting || state.isUpdating
                }
                type="submit"
                form="edit-project-form"
              >
                {t('editProjectDialog.save')}
              </Button>
            )}
            {!isAssignMode && !formik.dirty && (
              <Button
                disabled={formik.isSubmitting || state.isUpdating}
                onClick={() => changeStatus(AccountingItemStatus.active)}
              >
                {t('editProjectDialog.activate')}
              </Button>
            )}
          </>
        )}
      </DialogActions>

      <LoaderWithOverlay loading={formik.isSubmitting || state.isUpdating} />
    </Dialog>
  );
};

export default withDialogWrapper(EditProjectDialog);
