import React, { useState } from 'react';
import { sortBy } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, PlusIcon, XIcon } from 'elements';
import {
  CardCategoryControl,
  CardCategoryControlType,
  CardControlRestriction,
  merchantCategories,
  MerchantCategory,
} from 'services/constants';
import { StyledAutocomplete } from './style';

interface Props {
  disabled?: boolean;
  restriction: CardControlRestriction;
  categories: MerchantCategory[];
  onChange: (control: CardCategoryControl) => void;
}

interface State {
  inputValue: string;
  preselectedCategories: MerchantCategory[];
}

const TransactionCategories = ({
  disabled,
  restriction,
  categories,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  const [state, setState] = useState<State>({
    inputValue: '',
    preselectedCategories: [] as MerchantCategory[],
  });

  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={1} mr={1}>
        <StyledAutocomplete<MerchantCategory, true, false, false>
          sx={{ backgroundColor: 'transparent' }}
          multiple
          disabled={disabled}
          placeholder={t(
            'cardControlFormElement.category.selectTransactionCategories'
          )}
          noOptionsText={t('common.nothingFound')}
          loadingText={t('common.loading')}
          searchIcon={false}
          disableCloseOnSelect
          options={merchantCategories}
          value={state.preselectedCategories}
          onChange={(e, value) => {
            setState((prevState) => ({
              ...prevState,
              preselectedCategories: value,
            }));
          }}
          inputValue={state.inputValue}
          onInputChange={(e, inputValue) =>
            setState((prevState) => ({ ...prevState, inputValue }))
          }
          renderTags={() => null}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option}>
              {selected ? (
                <XIcon fontSize="small" />
              ) : (
                <PlusIcon fontSize="small" />
              )}
              <Box ml={2}>{t(`merchantCategories.${option}`)}</Box>
            </li>
          )}
          filterOptions={(options) =>
            options.filter((item) => !categories.includes(item))
          }
        />
      </Box>
      <Box flexShrink={0}>
        <Button
          onClick={() => {
            onChange({
              restriction,
              type: CardCategoryControlType.category,
              displayValues: null,
              values: sortBy(
                [...categories, ...state.preselectedCategories],
                (item) => merchantCategories.indexOf(item)
              ),
            });
            setState((prevState) => ({
              ...prevState,
              mccOptions: [],
              preselectedCategories: [],
            }));
          }}
          disabled={!state.preselectedCategories.length || disabled}
          variant="text"
        >
          <Trans
            i18nKey="cardControlFormElement.addToList"
            values={{ count: state.preselectedCategories.length || '' }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default TransactionCategories;
