import { Components } from '@mui/material';

const MuiLinkOverrides: Components = {
  MuiLink: {
    styleOverrides: {
      button: {
        verticalAlign: 'inherit',
      },
    },
  },
};

export default MuiLinkOverrides;
