import {
  Box,
  Alert as MuiAlert,
  AlertProps as MuiAlertProps,
} from '@mui/material';

export interface AlertProps extends MuiAlertProps {}

export const Alert = ({ children, action, sx = [], ...props }: AlertProps) => (
  <MuiAlert
    {...props}
    sx={[
      { '.MuiAlert-message': { flexGrow: 1, pb: action ? 0 : undefined } },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {children}
    {action && (
      <Box display="flex" justifyContent="flex-end" mt={1}>
        {action}
      </Box>
    )}
  </MuiAlert>
);

export { AlertTitle } from '@mui/material';
