import { useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { QueryParams } from 'domains/billing/pages/OrganizationSettlementsPage';
import { Chip, Tooltip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import {
  getFilterChipText,
  PageFilterChips,
  PageFiltersComponent,
} from 'layout';
import FiltersDrawer from './FiltersDrawer';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  disabled: boolean;
}

const Filters = ({
  params,
  selectedFiltersCount,
  setParam,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const statusChipTooltip = useMemo(() => {
    if (params.status.length < 2) return '';
    return params.status
      .map((item) => t(`settlementStatuses.${item}`))
      .join(', ')
      .toUpperCase();
  }, [params.status]);

  const periodChipText = useMemo(() => {
    if (!params.fromDate && !params.toDate) return '';
    const array = [];
    if (params.fromDate)
      array.push(moment(params.fromDate).format('D MMM YYYY'));
    if (params.toDate) array.push(moment(params.toDate).format('D MMM YYYY'));
    return array.join(' - ');
  }, [params.fromDate, params.toDate]);

  return (
    <>
      <PageFiltersComponent
        count={selectedFiltersCount}
        q={params.q}
        setQParam={(value) => setParam('q', value)}
        onOpen={() => setIsFilterOpen(true)}
        disabled={disabled}
      />

      {!!selectedFiltersCount && (
        <PageFilterChips>
          {(params.fromDate || params.toDate) && (
            <Chip
              label={periodChipText}
              onDelete={() =>
                setParam([
                  ['toDate', ''],
                  ['fromDate', ''],
                ])
              }
            />
          )}

          {params.accountGroup && (
            <Chip
              label={t(`int.accountGroupSelect.items.${params.accountGroup}`)}
              onDelete={() => setParam('accountGroup', '')}
            />
          )}

          {!!params.status.length && (
            <Tooltip title={statusChipTooltip}>
              <Chip
                label={getFilterChipText(
                  t(`settlementStatuses.${params.status[0]}`).toUpperCase(),
                  params.status.length
                )}
                onDelete={() => setParam('status', [])}
              />
            </Tooltip>
          )}
        </PageFilterChips>
      )}

      <FiltersDrawer
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        params={params}
        setParam={setParam}
      />
    </>
  );
};

export default Filters;
