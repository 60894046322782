import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Chip, FunnelSimpleIcon, Tooltip, Typography } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { getFilterChipText, PageFilterChips, PageFilters } from 'layout';
import FiltersDrawer from './FiltersDrawer';
import { QueryParams } from '.././index';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  disabled: boolean;
  totalCount: number;
}

const Filters = ({
  params,
  selectedFiltersCount,
  setParam,
  disabled,
  totalCount,
}: Props) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const periodChipText = useMemo(
    () =>
      [params.fromDate, params.toDate]
        .map((val) => val?.format('D MMM YYYY'))
        .filter((val) => !!val)
        .join(' - '),
    [params.fromDate, params.toDate]
  );

  const statusChipTooltip = useMemo(() => {
    if (params.status.length < 2) return '';
    return params.status
      .map((item) => t(`paymentStatuses.${item}`))
      .join(', ')
      .toUpperCase();
  }, [params.status]);

  return (
    <>
      <PageFilters>
        <Button
          variant={selectedFiltersCount ? 'contained' : 'text'}
          startIcon={<FunnelSimpleIcon />}
          onClick={() => setIsFilterOpen(true)}
          disabled={disabled}
        >
          {t('filterDrawer.title', { defaultValue: 'Filter' })}
          {!!selectedFiltersCount && ` (${selectedFiltersCount})`}
        </Button>
        <Typography variant="body2" color="textSecondary" ml={2}>
          {t('int.common.resultsTable', { count: totalCount })}
        </Typography>

        <FiltersDrawer
          open={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
          params={params}
          setParam={setParam}
        />
      </PageFilters>

      {!!selectedFiltersCount && (
        <PageFilterChips>
          {(params.fromDate || params.toDate) && (
            <Chip
              label={periodChipText}
              onDelete={() =>
                setParam([
                  ['toDate', ''],
                  ['fromDate', ''],
                ])
              }
            />
          )}
          {!!params.status.length && (
            <Tooltip title={statusChipTooltip}>
              <Chip
                label={getFilterChipText(
                  t(`paymentStatuses.${params.status[0]}`).toUpperCase(),
                  params.status.length
                )}
                onDelete={() => setParam('status', [])}
              />
            </Tooltip>
          )}
        </PageFilterChips>
      )}
    </>
  );
};

export default Filters;
