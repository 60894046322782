import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  CheckCircleIcon,
  CircleDashedIcon,
  LockIcon,
  Tooltip,
} from 'elements';
import { env } from 'env';
import useCurrentApp from 'hooks/useCurrentApp';
import usePartnerName from 'hooks/usePartnerName';
import {
  AccountingSystem,
  ExportFormat,
  Transaction,
  TransactionExportStatus,
} from 'services/constants';

const NoneStatus = ({
  exportStatus,
  exportDate,
  exportFormat,
  exportExternalReference,
}: Pick<
  Transaction,
  'exportStatus' | 'exportDate' | 'exportFormat' | 'exportExternalReference'
>) => {
  const { t } = useTranslation();
  const { isAdminApp } = useCurrentApp();
  const partnerName = usePartnerName();
  const {
    state: { accountingSettings },
  } = useGlobalState();

  if (exportStatus === TransactionExportStatus.inExportQueue) {
    if (isAdminApp)
      return (
        <Box display="flex" alignItems="center">
          <CircleDashedIcon color="action" sx={{ mr: 1 }} />
          {t('transactionIntegrationStatus.inExportQueue')}
        </Box>
      );

    return (
      <Tooltip
        title={
          t('transactionIntegrationStatus.inExportQueueCardholderTooltip', {
            partnerName,
          })!
        }
      >
        <Box display="flex" alignItems="center">
          <CircleDashedIcon color="action" sx={{ mr: 1 }} />
          {t('transactionIntegrationStatus.inExportQueueCardholder')}
          <LockIcon color="action" fontSize="small" sx={{ ml: 1 }} />
        </Box>
      </Tooltip>
    );
  }

  if (exportStatus === TransactionExportStatus.exported) {
    const formattedExportDate = moment(exportDate).format('D MMM YYYY');
    const toolTipExportDate = moment(exportDate).format('DD.MM.YYYY');

    if (
      accountingSettings?.accountingSystem === AccountingSystem.datev &&
      exportFormat === ExportFormat.api
    ) {
      return (
        <>
          {isAdminApp ? (
            <Tooltip
              title={
                t('transactionIntegrationStatus.exportedTransactionTooltip', {
                  date: toolTipExportDate,
                })!
              }
            >
              <Box display="flex" alignItems="center">
                <CheckCircleIcon color="action" sx={{ mr: 1 }} />
                {t('transactionIntegrationStatus.exported', {
                  exportDate: formattedExportDate,
                })}
                <LockIcon color="action" fontSize="small" sx={{ ml: 1 }} />
              </Box>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                t('transactionIntegrationStatus.exportedCardholderTooltip')!
              }
            >
              <Box display="flex" alignItems="center">
                <CheckCircleIcon color="action" sx={{ mr: 1 }} />
                {t('transactionIntegrationStatus.exportedCardholder', {
                  exportDate: formattedExportDate,
                })}
                <LockIcon color="action" fontSize="small" sx={{ ml: 1 }} />
              </Box>
            </Tooltip>
          )}
        </>
      );
    }

    return (
      <>
        <CheckCircleIcon color="action" sx={{ mr: 1 }} />
        {isAdminApp
          ? t('transactionIntegrationStatus.exported', {
              exportDate: formattedExportDate,
            })
          : t('transactionIntegrationStatus.exportedCardholder', {
              exportDate: formattedExportDate,
            })}
        {/* Used only for LexOffice so far. Need some adjustments if we handle other cases in the future */}
        {exportExternalReference ? (
          <>
            &nbsp;
            <a
              href={`${env.REACT_APP_LEXOFFICE_APP_BASE_URL}/permalink/vouchers/view/${exportExternalReference}`}
              rel="noreferrer"
              target="_blank"
            >
              {t('transactionIntegrationStatus.seeInOtherApp', {
                name: accountingSettings?.accountingSystemName || '',
              })}
              &nbsp; ↗︎
            </a>
          </>
        ) : (
          <LockIcon color="action" fontSize="small" sx={{ ml: 1 }} />
        )}
      </>
    );
  }
  return null;
};

export default NoneStatus;
