import { Components, Theme } from '@mui/material';

const MuiCssBaselineOverrides: Components<Theme> = {
  MuiCssBaseline: {
    styleOverrides: (theme) => ({
      a: {
        color: theme.palette.primary.main,
        outline: 'none',
      },
      'button.MuiLink-root': {
        verticalAlign: 'baseline',
      },
    }),
  },
};

export default MuiCssBaselineOverrides;
