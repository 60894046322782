import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import styled from 'styled-components';
import { ReactComponent as Add1Icon } from 'assets/icons/interface/add1.svg';
import { ReactComponent as Remove1Icon } from 'assets/icons/interface/remove1.svg';

const StyledAccordion = styled(Accordion)`
  max-width: 1048px;
  border-radius: 12px !important;
  border: 1px solid ${({ theme }) => theme.colors.neutralVariant50};
  box-shadow: none;

  &:before {
    background-color: unset;
  }

  &:hover,
  &.Mui-expanded {
    border-color: ${({ theme }) => theme.colors.grey10};
  }

  .MuiAccordionSummary-root {
    font-size: 16px;
    line-height: 24px;
    min-height: 99px;
    margin: 0;
    padding: 0 45px 0 48px;
  }

  .MuiAccordionDetails-root {
    display: block;
    font-size: 14px;
    line-height: 17px;
    padding: 6px 150px 48px 48px;
  }

  svg {
    fill: ${({ theme }) => theme.colors.grey10};
  }
`;

interface Props {
  id: number;
  summary: string;
  children: React.ReactNode;
}

const FaqItem = ({ id, summary, children }: Props) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const toggleExpaned = () => setIsExpanded((prevState) => !prevState);

  return (
    <StyledAccordion expanded={isExpanded} onChange={toggleExpaned}>
      <AccordionSummary
        aria-controls={`panel${id}-content`}
        expandIcon={
          isExpanded ? (
            <Remove1Icon width={24} height={24} />
          ) : (
            <Add1Icon width={24} height={24} />
          )
        }
        id={`panel${id}-header`}
      >
        {summary}
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};

export default FaqItem;
