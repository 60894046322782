import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  CircularProgress,
  HandCoinsIcon,
  Link,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import { Cashback } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getPath } from 'services/utils';

interface Props {
  size: 'sm' | 'lg';
}

interface State {
  isLoading: boolean;
  cashback: Cashback | null;
  isTopUpAccountDialogOpen: boolean;
}

const CashbackSummary = ({ size }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    cashback: null,
    isTopUpAccountDialogOpen: false,
  });

  const getData = async () => {
    try {
      const cashback = await api.getCashback(organization!.id);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false, cashback }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (size === 'sm') {
    return (
      <Box
        flexShrink={0}
        p={2}
        sx={(theme) => ({
          border: `1px solid ${theme.palette.divider}`,
          borderWidth: '1px 1px 0 0',
        })}
      >
        <Typography
          variant="caption2"
          component="div"
          color="text.secondary"
          mb={1}
        >
          {t('dashboardPage.totalCashbackEarned')}
        </Typography>
        {!state.isLoading && state.cashback && (
          <Link
            variant="h6"
            underline="none"
            sx={{ display: 'flex', alignItems: 'center' }}
            component={RouterLink}
            to={generatePath(getPath('rewardsCashback'), {
              orgId: organization!.id,
            })}
          >
            <HandCoinsIcon sx={{ mr: 1 }} />
            <FormatMoney value={state.cashback.totalEarned} />
          </Link>
        )}
        {state.isLoading && (
          <Box minHeight={32} display="flex" alignItems="center">
            <CircularProgress size="small" />
          </Box>
        )}
        {!state.isLoading && !state.cashback && (
          <Typography variant="caption" color="error.main">
            {t('errors.loadData')}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      minHeight="65px"
      p={2}
      borderTop={(theme) => `1px solid ${theme.palette.divider}`}
    >
      <HandCoinsIcon />

      <Typography variant="subtitle1" flexGrow={1} noWrap mx={1}>
        {t('dashboardPage.totalCashbackEarned')}
      </Typography>

      {!state.isLoading && state.cashback && (
        <Link
          variant="h6"
          underline="none"
          component={RouterLink}
          to={generatePath(getPath('rewardsCashback'), {
            orgId: organization!.id,
          })}
        >
          <FormatMoney value={state.cashback.totalEarned} />
        </Link>
      )}
      {!state.isLoading && !state.cashback && (
        <Typography variant="caption" color="error.main">
          {t('errors.loadData')}
        </Typography>
      )}
      {state.isLoading && <CircularProgress size="small" />}
    </Box>
  );
};

export default CashbackSummary;
