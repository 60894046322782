import React, { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

const StyledPageHeadingLine = styled.div<{ $mb: number }>`
  display: flex;
  margin: 0 0 ${({ $mb }) => $mb}px;
`;

const Heading = styled.h2`
  margin: 0 30px 0 0;
  flex-shrink: 0;
  font-size: 24px;
  line-height: 46px;
  font-weight: 400;
`;

interface PageHeadingLineProps {
  heading: ReactNode;
  children?: ReactNode;
  filters?: ReactNode;
  $mb?: number;
}

export const PageHeadingLine = ({
  heading,
  children,
  filters,
  $mb = 34,
}: PageHeadingLineProps) => {
  return (
    <StyledPageHeadingLine $mb={$mb}>
      <Heading>{heading}</Heading>
      {filters}
      <Box ml="auto">{children}</Box>
    </StyledPageHeadingLine>
  );
};
