import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import TableControls from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/GLAccountsStep/TableControls';
import {
  GlAccountsStepsEnum,
  useGLAccountsSyncContext,
} from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/GLAccountsStep/useGLAccountsSyncContext';
import { useCodatStepperContext } from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/useCodatStepperContext';
import {
  ArrowsClockwiseIcon,
  Box,
  Button,
  CaretLeftIcon,
  DataGrid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'elements';
import { CodatAccountItem } from 'services/constants';
import useColumns from './useColumns';

const SelectAccountsStep = () => {
  const { t } = useTranslation();
  const {
    state: { accountingSettings },
  } = useGlobalState();
  const {
    actions: { onPrev },
  } = useCodatStepperContext();
  const {
    isPartialFlow,
    state: { accounts, accountsTypeFilterValue, selectedAccountIds },
    actions: {
      onInnerStepChange,
      onSelectedAccountsChange,
      getSyncedMappingOptions,
      onPartialFlowCancel,
      onClose,
    },
  } = useGLAccountsSyncContext();

  const gridColumns = useColumns(isPartialFlow);

  const visibleAccounts = useMemo(() => {
    if (accountsTypeFilterValue === 'all') return accounts || [];
    return (
      accounts!.filter(
        (account) => account.accountType === accountsTypeFilterValue
      ) || []
    );
  }, [accounts, accountsTypeFilterValue]);

  return (
    <>
      <DialogTitle>
        {t('codatSyncSetupDialog.glAccountsStep.title', { step: 1 })}
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography color="text.secondary">
          <Trans
            i18nKey="codatSyncSetupDialog.glAccountsStep.selectAccountsDescription"
            values={{
              name: accountingSettings!.accountingSystemName,
            }}
            count={accounts!.length}
            components={{
              bold: <Typography component="span" color="text.primary" />,
            }}
          />
        </Typography>

        <TableControls
          selectedCount={selectedAccountIds.length}
          visibleAccountsCount={visibleAccounts.length}
        />

        <Box flex="1" overflow="hidden">
          <DataGrid<CodatAccountItem>
            checkboxSelection
            disableRowSelectionOnClick={false}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              onSelectedAccountsChange(
                'selectedAccountIds',
                newRowSelectionModel as string[]
              );
            }}
            rowSelectionModel={selectedAccountIds}
            columns={gridColumns}
            rows={visibleAccounts}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Box mr="auto">
          {isPartialFlow ? (
            <>
              {onPartialFlowCancel && (
                <Button variant="text" onClick={onPartialFlowCancel}>
                  {t('common.button.close')}
                </Button>
              )}

              <Button
                variant="text"
                startIcon={<ArrowsClockwiseIcon />}
                onClick={getSyncedMappingOptions}
              >
                {t('codatSyncSetupDialog.syncNowButton')}
              </Button>
            </>
          ) : (
            <Button
              variant="text"
              startIcon={<CaretLeftIcon />}
              onClick={() => {
                onInnerStepChange(GlAccountsStepsEnum.initialStep);
                onPrev();
              }}
            >
              {t('common.button.back')}
            </Button>
          )}
        </Box>

        {!isPartialFlow && (
          <Button variant="text" onClick={onClose}>
            {t('common.button.close')}
          </Button>
        )}

        <Button
          disabled={!selectedAccountIds.length}
          onClick={() => onInnerStepChange(GlAccountsStepsEnum.mapAccountsStep)}
        >
          {selectedAccountIds.length
            ? t('codatSyncSetupDialog.syncAccountsButton', {
                count: selectedAccountIds.length,
              })
            : t('common.button.next')}
        </Button>
      </DialogActions>
    </>
  );
};

export default SelectAccountsStep;
