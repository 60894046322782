import React from 'react';
import { Redirect } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { AccountingSettings } from 'services/constants';

// this is wrapper for AccountingPage tabs
// in case when we toogle projects/vatRates/suppliers/categories on General page off
// tha pages should be hidden
const withTabPermission = <P extends object>(
  Component: React.ComponentType<P>,
  accountSettingsKey: keyof AccountingSettings
): React.FC<P> => (props: P): JSX.Element => {
  const {
    state: { accountingSettings },
  } = useGlobalState();

  return accountingSettings![accountSettingsKey] ? (
    <Component {...props} />
  ) : (
    <Redirect to="/" />
  );
};

export default withTabPermission;
