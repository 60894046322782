import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState } from 'context/GlobalState';
import { logError } from 'services/monitoring';
import { getJwtPayload } from './utils';

const withJwtPayload = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => (props: P): JSX.Element | null => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    state: { jwtPayload },
    dispatch,
  } = useGlobalState();

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const jwtPayload = getJwtPayload(accessToken);
        dispatch({ type: 'SET_JWT_PAYLOAD', payload: { jwtPayload } });
      } catch (error) {
        dispatch({ type: 'SET_ERROR', payload: error });
        logError(error);
      }
    })();
  }, []);

  return jwtPayload.permissions.length ? <Component {...props} /> : null;
};

export default withJwtPayload;
