import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Typography } from 'elements';

const DateTitle = ({ createdAt }: { createdAt: string }) => {
  const { t } = useTranslation();

  const renderDate = () => {
    const receiptDate = moment(createdAt);
    const now = moment();

    if (receiptDate.isSame(now, 'day'))
      return `${t('receiptInboxDetails.todayDate')}, ${receiptDate.format(
        'HH:mm'
      )}`;

    if (receiptDate.isSame(now.subtract(1, 'days'), 'day'))
      return `${t('receiptInboxDetails.yesterdayDate')}, ${receiptDate.format(
        'HH:mm'
      )}`;

    return receiptDate.format('D MMM YYYY, HH:mm');
  };

  return (
    <Typography variant="h5" mb={2}>
      {renderDate()}
    </Typography>
  );
};

export default DateTitle;
