import { useCallback, useMemo } from 'react';
import { isAxiosError } from 'axios';
import { Trans } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { Team } from 'services/constants';
import {
  getMaxAllowedCardLimit,
  getMaxAllowedCardLimitErrorMessageKey,
} from 'services/rbac';
import { isMoney } from 'services/utils';

const useMaxAllowedCardLimit = (managerTeam: Team | undefined) => {
  const {
    state: {
      jwtPayload: {
        roles,
        attr: { maxSpendLimitPerCard },
      },
    },
  } = useGlobalState();

  const maxAllowedCardLimit = useMemo(
    () =>
      getMaxAllowedCardLimit(
        roles,
        maxSpendLimitPerCard,
        managerTeam?.maxLimitPerCard
      ),
    [roles, maxSpendLimitPerCard, managerTeam?.maxLimitPerCard]
  );

  const getMaxAllowedCardLimitExceededErrorMessage = useCallback(
    (errorOrLimit?: unknown) => {
      let maxLimit = maxAllowedCardLimit;

      if (
        isAxiosError(errorOrLimit) &&
        isMoney(errorOrLimit.response?.data?.data?.convertedLimit)
      ) {
        maxLimit = errorOrLimit.response?.data?.data?.convertedLimit;
      } else if (isMoney(errorOrLimit)) {
        maxLimit = errorOrLimit;
      }

      return maxLimit
        ? (((
            <Trans
              i18nKey={getMaxAllowedCardLimitErrorMessageKey(roles)}
              components={{
                limit: <FormatMoney value={maxLimit} fractionalPart />,
              }}
            />
          ) as unknown) as string)
        : '';
    },
    [roles, maxAllowedCardLimit]
  );

  return { maxAllowedCardLimit, getMaxAllowedCardLimitExceededErrorMessage };
};

export default useMaxAllowedCardLimit;
