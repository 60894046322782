import { invert } from 'lodash';
import { PaymentFrequencyType } from 'services/constants';

export const PAYMENT_TERMS = {
  THIRTY_DAYS: 30,
  FOURTEEN_DAYS: 14,
  SEVEN_DAYS: 7,
  IMMEDIATE: 0,
};

export const PAYMENT_TERMS_INVERTED = invert(PAYMENT_TERMS) as {
  [key: number]: keyof typeof PAYMENT_TERMS;
};

export const PAYMENT_FREQUENCY_CUTOFF_VISIBLE_DAYS = [1, 2, 3, 4, 5, 6, 7];

export const DEFAULT_CUTOFF_DAY_BY_FREQUENCY = {
  [PaymentFrequencyType.MONTHLY]: 22,
  [PaymentFrequencyType.SEMI_MONTHLY]: 12,
  [PaymentFrequencyType.BI_WEEKLY]: 7,
  [PaymentFrequencyType.WEEKLY]: 7,
  [PaymentFrequencyType.DAILY]: null,
};
