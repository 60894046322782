import React from 'react';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { getCardNameWithRefNum } from 'domains/card/utils';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  InputLabelTooltipIcon,
  LoaderWithOverlay,
  MoneyField,
  Select,
  TextField,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card, CardReplacementReason, CardType } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import ExpiryPeriodMonthsSelect from './ExpiryPeriodMonthsSelect';

interface Props {
  goToPrevStep: () => void;
  onClose: () => void;
  reason: CardReplacementReason;
  card: Card;
  onSuccess: (newCard: Card) => void;
}

const SecondStep = ({
  goToPrevStep,
  onClose,
  reason,
  card,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      expiryPeriodMonths: 36,
      teamId: card.teamId || '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      let terminatedCardReason = reason;
      if (
        card.type === CardType.virtual &&
        reason === CardReplacementReason.compromised
      ) {
        // BE is using STOLEN key as COMPROMISED for Virtual cards
        terminatedCardReason = CardReplacementReason.stolen;
      }

      try {
        const newCard = await api.renewCard(
          card.cardId,
          terminatedCardReason,
          values.expiryPeriodMonths
        );
        if (!mounted.current) return;
        setSubmitting(false);
        onSuccess(newCard);
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <>
      <DialogTitle>{t('replaceCardDialog.replaceCardHeading')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="replace-card-form" noValidate>
          <Box mb={4}>
            <Typography variant="body2">
              <Trans
                i18nKey="replaceCardDialog.confirmMessage"
                components={{
                  b: <b />,
                }}
                values={{ cardName: getCardNameWithRefNum(card) }}
              />
            </Typography>
          </Box>

          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={6}>
              <TextField
                label={t('replaceCardDialog.cardholder')}
                value={`${card.chFirstName} ${card.chLastName}`}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('replaceCardDialog.teamLabel')}
                value={card.teamName || ''}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <ExpiryPeriodMonthsSelect
                {...formik.getFieldProps('expiryPeriodMonths')}
                selectedCardType={card.type}
                disabled={card.type !== CardType.virtual || formik.isSubmitting}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <FormControl fullWidth disabled>
                <InputLabel component="div">
                  {t('replaceCardDialog.limitFrequency')}
                </InputLabel>
                <Select
                  value=""
                  renderValue={() =>
                    t(
                      `cardLimitFrequency.frequencies.${card.limitRenewFrequency}`
                    )
                  }
                  displayEmpty
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <MoneyField
                label={t('replaceCardDialog.limit')}
                value={card.limit.value / 100}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <MoneyField
                label={
                  <>
                    {t('replaceCardDialog.transactionLimit')}
                    <InputLabelTooltipIcon
                      title={t('tooltips.transactionLimit')}
                    />
                  </>
                }
                value={card.transactionLimit.value / 100}
                disabled
              />
            </Grid>
            {card.type === CardType.virtual && (
              <Grid item xs={6}>
                <TextField
                  label={t('replaceCardDialog.cardLabel')}
                  value={card.cardName || ''}
                  disabled
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Box flexGrow="1">
          <Button variant="text" onClick={goToPrevStep}>
            {t('common.button.back')}
          </Button>
        </Box>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={formik.isSubmitting}
          form="replace-card-form"
          type="submit"
        >
          {t('replaceCardDialog.replaceCard')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default SecondStep;
