import { Container, ContainerInner, MainContent } from 'components/App/style';
import { WhiteLabeledMainHeader } from 'components/MainHeader';

const WhiteLabelLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container>
      <ContainerInner>
        <WhiteLabeledMainHeader />
        <MainContent>{children}</MainContent>
      </ContainerInner>
    </Container>
  );
};

export default WhiteLabelLayout;
