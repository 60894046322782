import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { internalRootPaths } from 'components/App';
import { StyledListItemButton } from 'components/MainHeader/style';
import { UpdateTermsOrPoliciesDialog } from 'domains/document/dialogs';
import {
  ArrowsCounterClockwiseIcon,
  BookIcon,
  CaretDownIcon,
  CheckCircleIcon,
  FileTextIcon,
  GlobeHemisphereWestIcon,
  ListItemIcon,
  ListItemText,
  MenuContainer,
  MenuItem,
  StorefrontIcon,
  SuitcaseSimpleIcon,
  UploadSimpleIcon,
} from 'elements';
import { useCanUser } from 'services/rbac';
import MenuLink from './MenuLink';

const PlatformAdminMenu = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const [
    updateTermsOrPoliciesDialogOpen,
    setUpdateTermsOrPoliciesDialogOpen,
  ] = useState(false);

  const isPlatformAdminMenuVisible =
    canUser('merchants-page:visit') ||
    canUser('program-settlements-page:visit') ||
    canUser('settlement-account-page:visit') ||
    canUser('bill-payment-batches-page:visit') ||
    canUser('cashback-payment-batches-page:visit') ||
    canUser('payout-batches-page:visit') ||
    canUser('billing-accounts-approval-page:visit') ||
    canUser('compensation-batches-page:visit') ||
    canUser('terms-or-policies:update') ||
    canUser('crm-integration-page:visit');

  if (!isPlatformAdminMenuVisible) return null;

  return (
    <>
      <MenuContainer
        button={
          <StyledListItemButton>
            <ListItemIcon>
              <CaretDownIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('int.platformAdminMenu.more')} />
          </StyledListItemButton>
        }
        buttonSelectedClass="active"
      >
        {canUser('merchants-page:visit') && (
          <MenuLink
            to={internalRootPaths.merchants}
            icon={<StorefrontIcon />}
            text={t('int.platformAdminMenu.merchants')}
          />
        )}
        {canUser('program-settlements-page:visit') && (
          <MenuLink
            to={internalRootPaths.programSettlements}
            icon={<BookIcon />}
            text={t('int.platformAdminMenu.programSettlements')}
          />
        )}
        {canUser('settlement-account-page:visit') && (
          <MenuLink
            to={internalRootPaths.settlementAccount}
            icon={<SuitcaseSimpleIcon />}
            text={t('int.platformAdminMenu.settlementAccount')}
          />
        )}
        {canUser('receivable-repayments-page:visit') && (
          <MenuLink
            to={internalRootPaths.receivableRepayments}
            icon={<FileTextIcon />}
            text={t('int.platformAdminMenu.receivablePayments')}
          />
        )}

        {canUser('bill-payment-batches-page:visit') && (
          <MenuLink
            to={internalRootPaths.billPaymentsBatches}
            icon={<FileTextIcon />}
            text={t('int.platformAdminMenu.billPaymentsBatches')}
          />
        )}
        {canUser('cashback-payment-batches-page:visit') && (
          <MenuLink
            to={internalRootPaths.cashbackPaymentBatches}
            icon={<FileTextIcon />}
            text={t('int.platformAdminMenu.cashbackPaymentBatches')}
          />
        )}
        {canUser('payout-batches-page:visit') && (
          <MenuLink
            to={internalRootPaths.payoutBatches}
            icon={<FileTextIcon />}
            text={t('int.platformAdminMenu.payoutBatches')}
          />
        )}
        {canUser('compensation-batches-page:visit') && (
          <MenuLink
            to={internalRootPaths.compensationBatches}
            icon={<GlobeHemisphereWestIcon />}
            text={t('int.platformAdminMenu.compensationBatches')}
          />
        )}
        {canUser('billing-accounts-approval-page:visit') && (
          <MenuLink
            to={internalRootPaths.billingAccountsApproval}
            icon={<CheckCircleIcon />}
            text={t('int.platformAdminMenu.billingAccountsApproval')}
          />
        )}
        {canUser('terms-or-policies:update') && (
          <MenuItem onClick={() => setUpdateTermsOrPoliciesDialogOpen(true)}>
            <ListItemIcon>
              <UploadSimpleIcon />
            </ListItemIcon>
            {t('int.platformAdminMenu.updateTermsOrPolicies')}
          </MenuItem>
        )}
        {canUser('crm-integration-page:visit') && (
          <MenuLink
            to={internalRootPaths.crmIntegration}
            icon={<ArrowsCounterClockwiseIcon />}
            text={t('int.platformAdminMenu.crmIntegration')}
          />
        )}
      </MenuContainer>

      {canUser('terms-or-policies:update') && (
        <UpdateTermsOrPoliciesDialog
          open={updateTermsOrPoliciesDialogOpen}
          onClose={() => setUpdateTermsOrPoliciesDialogOpen(false)}
        />
      )}
    </>
  );
};

export default PlatformAdminMenu;
