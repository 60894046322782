import React from 'react';
import { startCase } from 'lodash';
import { OrganizationRepresentativeExternalStatus } from 'services/constants';
import { StatusBadge } from './StatusBadge';

export const OrganizationRepresentativeExternalStatusBadge = ({
  status,
}: {
  status: OrganizationRepresentativeExternalStatus;
}) => {
  const label = status.toLowerCase();

  switch (status) {
    case OrganizationRepresentativeExternalStatus.infoRequested:
    case OrganizationRepresentativeExternalStatus.infoFilled:
      return <StatusBadge type="dark">{startCase(label)}</StatusBadge>;
    case OrganizationRepresentativeExternalStatus.infoSubmitted:
      return <StatusBadge type="success">{startCase(label)}</StatusBadge>;
    default:
      return null;
  }
};
