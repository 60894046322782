import { useEffect, useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import FormatPercent from 'components/FormatPercent';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  ActiveFilterValue,
  StatusFilterSelect,
} from 'domains/settings/components';
import {
  AddVatRateDialog,
  CodatSyncSeparatedDialog,
  EditVatRateDialog,
  SyncNameEnum,
} from 'domains/settings/dialogs';
import {
  FiltersContainer,
  HeaderContainer,
  HeaderTitle,
} from 'domains/settings/layout';
import withTabPermission from 'domains/settings/pages/AccountingPage/withTabPermission';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import {
  Badge,
  Box,
  Button,
  IconButton,
  LoaderWithOverlay,
  PencilSimpleIcon,
  StatusBadge,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, PageSearchInput } from 'layout';
import {
  AccountingItemStatus,
  CodatMappingOptionsSummary,
  VatRate,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import useCanUser from 'services/rbac/useCanUser';
import { getGenericErrorMsg, getPath } from 'services/utils';

interface State {
  isLoading: boolean;
  activeFilter: ActiveFilterValue | 'all';
  isAddVatRateDialogOpen: boolean;
  isEditVatRateDialogOpen: boolean;
  editableVatRate: VatRate | null;
  codatMappingOptionsSummary: CodatMappingOptionsSummary | null;
  search: string;
  codatSyncDialogOpen: boolean;
}

const VatRatesSubPage = () => {
  const history = useHistory();
  const location = useLocation<{ isCodatFlow: boolean }>();
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, vatRates, accountingSettings },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    activeFilter: AccountingItemStatus.active,
    isAddVatRateDialogOpen: false,
    isEditVatRateDialogOpen: false,
    editableVatRate: null,
    codatMappingOptionsSummary: null,
    search: '',
    codatSyncDialogOpen: false,
  });

  useEffect(() => {
    if (location.state?.isCodatFlow && canUser('codat-sync:change')) {
      setState((prevState) => ({
        ...prevState,
        codatSyncDialogOpen: true,
      }));
      history.replace({ state: null });
    }
  }, [location.state]);

  const filteredItems = useMemo(() => {
    if (!vatRates?.length) return [];
    const sortedVatRates = sortBy(vatRates, (v) => v.name.toLowerCase());
    return state.activeFilter !== 'all'
      ? sortedVatRates.filter((item) => item.status === state.activeFilter)
      : sortedVatRates;
  }, [vatRates, state.activeFilter]);

  const visibleItems = useMemo(
    () =>
      filteredItems.filter((item) =>
        item.name.toLowerCase().includes(state.search.trim().toLowerCase())
      ),
    [filteredItems, state.search]
  );

  const isCodatConnected = isCodatAccSystemConnected(accountingSettings);
  const areItemsReadOnly = isCodatConnected;

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [{ vatRates }, codatMappingOptionsSummary] = await Promise.all([
        api.getVatRates(organization!.id),
        isCodatConnected && canUser('codat-sync:view')
          ? api.getCodatMappingOptionsSummary(organization!.id)
          : null,
      ]);
      dispatch({ type: 'SET_ORGANIZATION_DATA', payload: { vatRates } });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        codatMappingOptionsSummary,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const noSearchResults = !visibleItems.length && state.search;

  const renderTitle = () => {
    if (isCodatConnected)
      return (
        <Trans
          i18nKey="vatRatesSubPage.codatTitle"
          values={{ name: accountingSettings!.accountingSystemName }}
          components={{
            a: (
              <Link
                to={generatePath(getPath('settingsAccountingSystem'), {
                  orgId: organization!.id,
                })}
              />
            ),
          }}
        />
      );
    return t('vatRatesSubPage.title');
  };

  const renderCustomButton = () => {
    if (isCodatConnected && state.codatMappingOptionsSummary) {
      if (!state.codatMappingOptionsSummary.lastSynced) {
        return (
          <Button
            sx={{ ml: 1 }}
            variant={
              canUser('vat-rate:create') && !areItemsReadOnly
                ? 'outlined'
                : 'contained'
            }
            onClick={() =>
              history.push(
                generatePath(getPath('settingsAccountingSystem'), {
                  orgId: organization!.id,
                })
              )
            }
          >
            {t('vatRatesSubPage.setupSyncBtn')}
          </Button>
        );
      }

      return (
        <Badge
          color="secondary"
          badgeContent={state.codatMappingOptionsSummary?.taxRates.newItems}
        >
          <Button
            sx={{ ml: 1 }}
            variant={
              canUser('vat-rate:create') && !areItemsReadOnly
                ? 'outlined'
                : 'contained'
            }
            disabled={!canUser('codat-sync:change')}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                codatSyncDialogOpen: true,
              }))
            }
          >
            {t('vatRatesSubPage.editAccSyncBtn')}
          </Button>
        </Badge>
      );
    }

    return null;
  };

  return (
    <>
      {!!vatRates && (
        <>
          <HeaderContainer>
            <ContentContainer>
              <HeaderTitle>
                {t('vatRatesSubPage.mainTitle')}
                {state.codatMappingOptionsSummary?.lastSynced && (
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    component="span"
                    ml={1}
                  >
                    (
                    {t('vatRatesSubPage.lastSync', {
                      date: moment(
                        state.codatMappingOptionsSummary.lastSynced
                      ).format('DD MMM YYYY, HH:mm'),
                    })}
                    )
                  </Typography>
                )}
              </HeaderTitle>
              <Typography>{renderTitle()}</Typography>
            </ContentContainer>
          </HeaderContainer>

          <FiltersContainer>
            {!!vatRates.length && (
              <>
                <PageSearchInput
                  sx={{ mr: 2 }}
                  initialValue={state.search}
                  onChange={(value) =>
                    setState((prevState) => ({ ...prevState, search: value }))
                  }
                  disabled={!filteredItems.length}
                />

                <StatusFilterSelect
                  filterValue={
                    state.activeFilter === 'all' ? '' : state.activeFilter
                  }
                  onFilterChange={(activeFilter) =>
                    setState((prevState) => ({
                      ...prevState,
                      activeFilter: activeFilter || 'all',
                    }))
                  }
                />
              </>
            )}

            <Box ml="auto">
              {canUser('vat-rate:create') && !areItemsReadOnly && (
                <Button
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isAddVatRateDialogOpen: true,
                    }))
                  }
                >
                  {t('vatRatesSubPage.addButtonTitle')}
                </Button>
              )}
              {renderCustomButton()}
            </Box>
          </FiltersContainer>

          <Box>
            <TableContainer>
              <Table sx={{ tableLayout: 'fixed' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('vatRatesSubPage.nameLabel')}</TableCell>
                    <TableCell>{t('vatRatesSubPage.rateLabel')}</TableCell>
                    <TableCell>{t('vatRatesSubPage.codeLabel')}</TableCell>
                    {canUser('vat-rate:update') && !areItemsReadOnly && (
                      <TableCell width={72} />
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!noSearchResults &&
                    visibleItems.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>
                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{
                              wordBreak: 'break-word',
                            }}
                          >
                            {item.name}
                            {item.status === AccountingItemStatus.inactive && (
                              <StatusBadge
                                color="error"
                                sx={{ ml: 1, flexShrink: 0 }}
                                label={t('vatRatesSubPage.inactiveBadgeLabel')}
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <FormatPercent
                            value={item.rate}
                            options={{
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                            }}
                          />
                        </TableCell>
                        <TableCell>{item.code}</TableCell>
                        {canUser('vat-rate:update') && !areItemsReadOnly && (
                          <TableCell width={72}>
                            <IconButton
                              onClick={() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  isEditVatRateDialogOpen: true,
                                  editableVatRate: item,
                                }));
                              }}
                            >
                              <PencilSimpleIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}

      {!state.isLoading && noSearchResults && (
        // todo: use the old component before we have UI for a new one
        <Box position="relative" minHeight={150}>
          <NothingFound />
        </Box>
      )}

      <LoaderWithOverlay loading={state.isLoading} />

      <AddVatRateDialog
        open={state.isAddVatRateDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isAddVatRateDialogOpen: false,
          }))
        }
        onSuccess={(vatRate) => {
          dispatch({
            type: 'SET_ORGANIZATION_DATA',
            payload: { vatRates: [...vatRates!, vatRate] },
          });
          setState((prevState) => ({
            ...prevState,
            isAddVatRateDialogOpen: false,
          }));
        }}
      />

      <EditVatRateDialog
        open={state.isEditVatRateDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isEditVatRateDialogOpen: false,
          }))
        }
        onUpdate={(vatRate) => {
          dispatch({
            type: 'SET_ORGANIZATION_DATA',
            payload: {
              vatRates: vatRates!.map((item) =>
                item.id === vatRate.id ? vatRate : item
              ),
            },
          });
          setState((prevState) => ({
            ...prevState,
            isEditVatRateDialogOpen: false,
          }));
        }}
        onDelete={(id) => {
          dispatch({
            type: 'SET_ORGANIZATION_DATA',
            payload: {
              vatRates: vatRates!.filter((item) => item.id !== id),
            },
          });
          setState((prevState) => ({
            ...prevState,
            isEditVatRateDialogOpen: false,
          }));
        }}
        vatRate={state.editableVatRate}
      />

      <CodatSyncSeparatedDialog
        name={SyncNameEnum.vatRates}
        open={state.codatSyncDialogOpen}
        onSuccess={getData}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            codatSyncDialogOpen: false,
          }));
        }}
      />
    </>
  );
};

export default withPageConfig(
  withTabPermission(VatRatesSubPage, 'vatRateEnabled'),
  { permission: 'vat-rates-sub-page:visit' }
);
