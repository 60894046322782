import { useEffect, useState } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import { LoaderWithOverlay } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import {
  AccountingSystem,
  ApiIntegrationStatus,
  CodatCompanyConfig,
  CustomField,
  DEFAULT_PAGE_LIMIT,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import BasicAccountingSettingsGroup from './BasicAccountingSettingsGroup';
import CustomFieldsGroup from './CustomFieldsGroup';
import CodatGeneralAccountsSettingsGroup from './GeneralAccountsSettingsGroup/CodatGeneralAccountsSettingsGroup';
import GeneralAccountsSettingsGroup from './GeneralAccountsSettingsGroup/GeneralAccountsSettingsGroup';
import InternalAccountingSettings from './InternalAccountingSettings';

interface State {
  codatCompanyConfig: CodatCompanyConfig | null;
  customFields: CustomField[];
  isLoading: boolean;
}

const GeneralSubPage = () => {
  const canUser = useCanUser();
  const api = useImperativeApi();
  const mounted = useMounted();
  const showPageError = useShowPageError();
  const {
    dispatch,
    state: { organization, accountingSettings: accSettings, featureModules },
  } = useGlobalState();
  const accountingSettings = accSettings!;
  const [state, setState] = useState<State>({
    codatCompanyConfig: null,
    customFields: [],
    isLoading: true,
  });

  const generalAccountsHidden =
    accountingSettings.accountingSystem === AccountingSystem.lexOffice &&
    accountingSettings.useAccountingExport &&
    accountingSettings.apiIntegrationStatus === ApiIntegrationStatus.connected;
  const isCodatConnected = isCodatAccSystemConnected(accountingSettings);

  const getData = async () => {
    try {
      const [
        accountingSettings,
        codatCompanyConfig,
        { transactionCustomFields },
      ] = await Promise.all([
        api.getAccountingSettings(organization!.id),
        isCodatConnected && canUser('codat-general-accounts:view')
          ? api.getCodatCompanyConfig(organization!.id)
          : null,
        canUser('custom-fields:view') &&
        featureModules.TRANSACTION_CUSTOM_FIELDS
          ? api.getCustomFields({
              organizationId: organization!.id,
              page: 0,
              limit: DEFAULT_PAGE_LIMIT,
            })
          : { transactionCustomFields: [], hasNextPage: false },
      ]);

      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { accountingSettings, transactionCustomFields },
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        codatCompanyConfig,
        customFields: transactionCustomFields,
        isLoading: false,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (state.isLoading) return <LoaderWithOverlay loading />;

  const renderGeneralAccountsSettingsGroup = () => {
    if (generalAccountsHidden) return null;
    if (isCodatConnected && state.codatCompanyConfig) {
      return (
        <CodatGeneralAccountsSettingsGroup
          codatCompanyConfig={state.codatCompanyConfig}
          onChange={(codatCompanyConfig) =>
            setState((prevState) => ({
              ...prevState,
              codatCompanyConfig,
            }))
          }
        />
      );
    }
    return <GeneralAccountsSettingsGroup />;
  };

  return (
    <>
      <InternalAccountingSettings />

      {renderGeneralAccountsSettingsGroup()}

      <BasicAccountingSettingsGroup />

      {canUser('custom-fields:view') &&
        featureModules.TRANSACTION_CUSTOM_FIELDS && (
          <CustomFieldsGroup
            customFields={state.customFields}
            onSuccess={(customFields) =>
              setState((prevState) => ({ ...prevState, customFields }))
            }
          />
        )}
    </>
  );
};

export default withPageConfig(GeneralSubPage, {
  permission: 'accounting-sub-page:visit',
});
