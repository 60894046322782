import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Chip, Tooltip } from 'elements';
import { getFilterChipText, PageFilterChips } from 'layout';
import { ReceiptInboxFilters } from './index';

interface Props {
  filters: ReceiptInboxFilters;
  selectedFiltersCount: number;
  onChange: (filters: Partial<ReceiptInboxFilters>) => void;
}

const FilterChips = ({ filters, selectedFiltersCount, onChange }: Props) => {
  const { t } = useTranslation();

  const statusChipTooltip = useMemo(() => {
    if (filters.status.length < 2) return '';
    return filters.status
      .map((item) => t(`receiptsAutoMatchingStatus.${item}`))
      .join(', ');
  }, [filters.status]);

  const periodChipText = useMemo(() => {
    if (!filters.fromDate && !filters.toDate) return '';
    const array = [];
    if (filters.fromDate)
      array.push(moment(filters.fromDate).format('D MMM YYYY'));
    if (filters.toDate) array.push(moment(filters.toDate).format('D MMM YYYY'));
    return array.join(' - ');
  }, [filters.fromDate, filters.toDate]);

  if (!selectedFiltersCount) return null;

  return (
    <>
      <PageFilterChips>
        {!!filters.status.length && (
          <Tooltip title={statusChipTooltip}>
            <Chip
              label={getFilterChipText(
                t(`receiptsAutoMatchingStatus.${filters.status[0]}`),
                filters.status.length
              )}
              onDelete={() => onChange({ status: [] })}
            />
          </Tooltip>
        )}

        {(filters.fromDate || filters.toDate) && (
          <Chip
            label={periodChipText}
            onDelete={() => onChange({ toDate: null, fromDate: null })}
          />
        )}
      </PageFilterChips>
    </>
  );
};

export default FilterChips;
