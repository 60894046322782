import { sortBy } from 'lodash';
import { ValueWithAuditLog } from 'services/constants';

export const getLatestUpdateMetaOfValues = (
  values: ValueWithAuditLog<any>[]
) => {
  const latestValueUpdated = sortBy(values, ['updatedAt']).reverse()[0];

  return {
    updatedAt: latestValueUpdated?.updatedAt,
    updatedBy: latestValueUpdated?.updatedBy,
  };
};
