import React from 'react';
import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';

export interface TooltipProps extends Omit<MuiTooltipProps, 'arrow'> {}

const Tooltip = ({ children, ...props }: TooltipProps) => (
  <MuiTooltip {...props}>{children}</MuiTooltip>
);

export default Tooltip;
