import { useMemo } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MerchantCategoryIcon } from 'domains/merchant/components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CaretDownIcon,
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import {
  AccountingItemStatus,
  merchantCategories,
  MerchantCategory,
  Subcategory,
  SubcategoryStatus,
} from 'services/constants';

type Value =
  | SubcategoryStatus.missing
  | Omit<string, SubcategoryStatus.missing>;

interface Props {
  value: Value;
  onChange: (value: Value) => void;
}

const SubcategoryFilter = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const {
    state: { subcategories },
  } = useGlobalState();

  const subcategoriesByCategories: {
    [key in MerchantCategory]?: Subcategory[];
  } = useMemo(() => {
    const visibleCategories = sortBy(
      subcategories?.filter(
        (item) => item.status === AccountingItemStatus.active
      ) || [],
      (v) => v.name.toLowerCase()
    );
    return merchantCategories.reduce(
      (prev, category) => ({
        ...prev,
        [category]: visibleCategories.filter(
          (item) => item.category === category
        ),
      }),
      {}
    );
  }, [subcategories]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-subcategory"
        id="panel-subcategory-header"
      >
        <Typography>{t('filters.subcategory')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Select<Value>
            size="medium"
            displayEmpty
            value={value}
            onChange={(e) => onChange(e.target.value as Value)}
            renderValue={(selected) => {
              if (!selected)
                return (
                  <Typography color="text.disabled">
                    {t('filters.subcategory')}
                  </Typography>
                );

              if (selected === SubcategoryStatus.missing)
                return <Typography>—</Typography>;

              const subcategory = subcategories?.find(
                (item) => item.id === selected
              );
              return subcategory?.name || '';
            }}
          >
            <MenuItem value="">
              <Typography color="text.disabled">
                {t('filters.subcategory')}
              </Typography>
            </MenuItem>

            <MenuItem value={SubcategoryStatus.missing}>—</MenuItem>

            {merchantCategories.map((category) => [
              <ListSubheader sx={{ display: 'flex', alignItems: 'center' }}>
                <MerchantCategoryIcon category={category} />
                <Box ml={2} whiteSpace="nowrap">
                  {t(`merchantCategories.${category}`)}
                </Box>
              </ListSubheader>,
              subcategoriesByCategories[category]?.map((subcategory) => (
                <MenuItem key={subcategory.id} value={subcategory.id}>
                  <Box pl={5} overflow="hidden">
                    {subcategory.name}
                  </Box>
                </MenuItem>
              )) || [],
            ])}
          </Select>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default SubcategoryFilter;
