import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import TeamsSelect from 'domains/settings/components/TeamsSelect';
import { useTeamsShown } from 'domains/settings/utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { NetworkErrorCode, Team } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  trimObjValues,
} from 'services/utils';

interface FormikValues {
  name: string;
  costUnit: string;
  organizationId: string;
  teams: Team[];
}

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
}

const AddProjectDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const teamsAreShown = useTeamsShown();

  const formik = useFormik<FormikValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: '',
      costUnit: '',
      organizationId: organization!.id,
      teams: [],
    },
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const { teams, ...valuesToPass } = values;

      try {
        const data = await api.addProject({
          ...trimObjValues(valuesToPass),
          teamIds: teams.map((item) => item.id),
        });
        dispatch({ type: 'ADD_PROJECTS', payload: [data] });
        if (!mounted.current) return;
        onSuccess();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (
          getNetworkErrorCode(error) === NetworkErrorCode.projectNameIsTaken
        ) {
          setFieldError('name', t('addProjectDialog.projectNameIsTakenError'));
          return;
        }
        if (getNetworkErrorCode(error) === NetworkErrorCode.costUnitIsTaken) {
          setFieldError('costUnit', t('addProjectDialog.costUnitIsTakenError'));
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const hasEmptyField = !formik.values.name.trim();

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('addProjectDialog.title')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} noValidate id="add-project-form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('name')}
                label={t('addProjectDialog.nameTitle')}
                onChange={(e) => {
                  formik.setFieldValue(e.target.name, e.target.value, false);
                  formik.setFieldError(e.target.name, undefined);
                }}
                disabled={formik.isSubmitting}
                error={!!formik.errors.name}
                helperText={formik.errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('costUnit')}
                label={t('addProjectDialog.costUnitTitle')}
                onChange={(e) => {
                  formik.setFieldValue(e.target.name, e.target.value, false);
                  formik.setFieldError(e.target.name, undefined);
                }}
                disabled={formik.isSubmitting}
                error={!!formik.errors.costUnit}
                helperText={formik.errors.costUnit}
              />
            </Grid>

            {teamsAreShown && (
              <Grid item xs={12}>
                <TeamsSelect
                  label={t('addProjectDialog.teamTitle')}
                  disabled={formik.isSubmitting}
                  {...omit(formik.getFieldProps('teams'), 'onChange')}
                  onChange={(teams) => formik.setFieldValue('teams', teams)}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={hasEmptyField || formik.isSubmitting}
          type="submit"
          form="add-project-form"
        >
          {t('addProjectDialog.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(AddProjectDialog);
