import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Partner, PartnerAuthStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props extends DialogProps {
  availablePartners: Partner[];
  onSuccess: () => void;
  onClose: () => void;
}

const ConnectPartnerDialog = ({
  availablePartners,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const [selectedValue, setSelectedValue] = useState<Partner | null>(null);
  const [customerConsent, setCustomerConsent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const connectPartner = async () => {
    try {
      setIsLoading(true);

      const updatedPartner = await api.authorizePartner(
        selectedValue!.partnerId,
        organization!.id
      );

      if (updatedPartner.status !== PartnerAuthStatus.active) {
        await api.changePartnerStatus(
          selectedValue!.partnerId,
          organization!.id,
          { status: PartnerAuthStatus.active }
        );
      }

      if (!mounted.current) return;
      onSuccess();
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('int.connectPartnerDialog.title')}</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete<Partner, false, false, false>
                label={t('int.connectPartnerDialog.partnerLabel')}
                disabled={isLoading}
                value={selectedValue}
                onChange={(_, option) => {
                  if (!option) return;
                  setSelectedValue(option);
                }}
                options={availablePartners}
                isOptionEqualToValue={(option, value) =>
                  option.partnerId === value.partnerId
                }
                getOptionLabel={(option) => option.name.toUpperCase()}
                noOptionsText={t('common.nothingFound')}
                loadingText={t('common.loading')}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography component="div">
              <Trans
                i18nKey="int.connectPartnerDialog.description"
                values={{ orgName: organization!.name }}
                components={{
                  p: <Box sx={{ '&+&': { mt: 3 } }} />,
                }}
              />
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox />}
              disabled={isLoading}
              onChange={(_, checked) => setCustomerConsent(checked)}
              label={t('int.connectPartnerDialog.customerConsent')}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isLoading || !selectedValue || !customerConsent}
          onClick={connectPartner}
        >
          {t('int.connectPartnerDialog.grandAccessBtn')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(ConnectPartnerDialog);
