import React from 'react';
import { Badge } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as TextFormattingFilter2Icon } from 'assets/icons/interface/textFormattingFilter2.svg';
import { Button } from 'components/Button';

const Wrapper = styled.div`
  display: flex;
  padding-left: 10px;
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    padding: 0 4px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    font-size: 8px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.grey10};
    text-align: center;
  }
`;

interface Props {
  isOpen: boolean;
  onToggle: () => void;
  selectedCount: number;
  onClear: () => void;
  disabled?: boolean;
}

export const PageFiltersSwitch = ({
  isOpen,
  onToggle,
  selectedCount,
  onClear,
  disabled = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StyledBadge badgeContent={selectedCount} overlap="rectangular">
        <Button
          $isIconButton
          variant={isOpen ? 'contained' : 'outlined'}
          onClick={onToggle}
          disabled={disabled}
        >
          <TextFormattingFilter2Icon />
        </Button>
      </StyledBadge>
      {selectedCount > 0 && (
        <Button variant="text" $px={14} $m="0 0 0 10px" onClick={onClear}>
          {t('filters.clearFilter')}
        </Button>
      )}
    </Wrapper>
  );
};
