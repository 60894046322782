import { ComponentType, FC, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { LoaderWithOverlay } from 'elements';
import useMounted from 'hooks/useMounted';
import {
  AccountingItemStatus,
  Supplier,
  TransactionExportStatus,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getPath } from 'services/utils';

interface State {
  isLoading: boolean;
  suppliers: Supplier[];
}

const withInitialData = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element => {
  const { suppliersEnabled } = useFlags();
  const { path } = useRouteMatch();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: suppliersEnabled,
    suppliers: [],
  });

  const exportStatus = useMemo(() => {
    if (path === getPath('exportQueue')) {
      return TransactionExportStatus.inExportQueue;
    }
    if (path === getPath('exported')) return TransactionExportStatus.exported;
    return TransactionExportStatus.notExported;
  }, [path]);

  const getData = async () => {
    if (!suppliersEnabled) return;
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const { suppliers } = await api.getSuppliers({
        organizationId: organization!.id,
        status: AccountingItemStatus.active,
        page: 0,
        limit: 2000,
      });
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false, suppliers }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, [suppliersEnabled]);

  if (state.isLoading) return <LoaderWithOverlay loading />;

  return (
    <Component
      {...props}
      // as we silently change route, we should be sure
      // all states are reset
      key={exportStatus}
      suppliers={state.suppliers}
      exportStatus={exportStatus}
    />
  );
};

export default withInitialData;
