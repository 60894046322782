import { ReactNode, useState } from 'react';
import { Box } from '@material-ui/core';
import { EditButton } from 'components/Button';
import { useOnboardingContext } from 'components/OnboardingPage/useOnboardingContext';
import { useGlobalState } from 'context/GlobalState';
import { OrganizationToHubspotLinkDialog } from 'domains/organization/dialogs';
import { useCanUser } from 'services/rbac';
import { Item, ItemHeading, ItemParagraph } from './style';

interface OrganizationToHubspotLinkFormGroupProps {
  existingHubspotId: string | null;
  label: ReactNode;
  width?: string;
}

const OrganizationToHubspotLinkFormGroup = ({
  existingHubspotId,
  label,
  width = '300px',
}: OrganizationToHubspotLinkFormGroupProps) => {
  const canUser = useCanUser();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const {
    actions: { updateOnboarding },
  } = useOnboardingContext();
  const [state, setState] = useState({
    hubspotId: '',
    isDialogOpen: false,
  });

  const onUpdated = (hubspotId: string) => {
    updateOnboarding({
      hubspotId,
    });

    dispatch({
      type: 'SET_ORGANIZATION_DATA',
      payload: {
        organization: {
          ...organization!,
          hubspotId,
        },
      },
    });
    setState((prevState) => ({
      ...prevState,
      isDialogOpen: false,
    }));
  };

  return (
    <>
      <Item $alignItems="center">
        <ItemHeading $lineHeight={undefined} $width={width}>
          {label}
        </ItemHeading>

        <Box display="flex" alignItems="center">
          <ItemParagraph>{existingHubspotId || '-'}</ItemParagraph>
          {canUser('org-hubspot-id:update') && (
            <EditButton
              $m="0 0 0 10px"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  isDialogOpen: true,
                }))
              }
            />
          )}
        </Box>
      </Item>

      <OrganizationToHubspotLinkDialog
        open={state.isDialogOpen}
        label={label}
        existingHubspotId={existingHubspotId}
        onSuccess={onUpdated}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isDialogOpen: false,
          }))
        }
      />
    </>
  );
};

export default OrganizationToHubspotLinkFormGroup;
