import { useState } from 'react';
import { AccordionDetails, AccordionSummary } from '@material-ui/core';
import { isAxiosError } from 'axios';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as ArrowsButtonDownIcon } from 'assets/icons/interface/arrowsButtonDown.svg';
import { LegitimationStatusBadge } from 'components/Badge';
import { LoaderWithOverlay } from 'components/Loader';
import OnboardingDocumentRow from 'components/OnboardingDocumentRow';
import { useGlobalState } from 'context/GlobalState';
import TaxIdNumberInputGroup from 'domains/organization/components/TaxIdNumberInputGroup/NewDesign';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  QuestionIcon,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  LegalRepresentativeType,
  LegitimationStatus,
  NetworkErrorCode,
  OnboardingDocument,
  OnboardingDocumentName,
  OnboardingDocumentStatus,
  OrganizationRepresentativeAdditionalInfo,
  OrganizationRepresentativeAdditionalInfoUpdatePayload,
  OrganizationRepresentativeExternalStatus,
  UboType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  validateEmail,
} from 'services/utils';
import {
  Form,
  Heading,
  ReasonInfoRequestWrapper,
  StyledAccordion,
  StyledValidationCheckIcon,
} from './style';

interface Props {
  data: OrganizationRepresentativeAdditionalInfo;
  onUpdate: (updatedData: OrganizationRepresentativeAdditionalInfo) => void;
  onboardingDocs: OnboardingDocument[];
  onOnboardingDocsUpdate: () => Promise<void>;
}

const RepresentativeAdditionalInfoSection = ({
  data,
  onUpdate,
  onboardingDocs,
  onOnboardingDocsUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const canUser = useCanUser();
  const isNotCompleted =
    OrganizationRepresentativeExternalStatus.infoRequested ===
      data.externalStatus ||
    (OrganizationRepresentativeExternalStatus.infoFilled ===
      data.externalStatus &&
      !!data.reasonForAdditionalInfoRequest);

  const [expanded, setExpanded] = useState(isNotCompleted);
  const {
    state: { organization },
  } = useGlobalState();
  const formik = useFormik<
    OrganizationRepresentativeAdditionalInfoUpdatePayload
  >({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: data.email ?? '',
      isFatca: data.isFatca,
      isPep: data.isPep,
      taxResidences: data.taxResidences.length
        ? data.taxResidences
        : [
            {
              taxId: '',
              taxResidence: '',
              primary: true,
            },
          ],
    },
    onSubmit: async (values) => {
      try {
        const updatedData = await api.updateOrganizationRepresentativesAdditionalInfo(
          organization!.id,
          data.representativeId,
          values
        );

        if (!mounted.current) return;
        setExpanded(false);
        onUpdate(updatedData);
      } catch (error) {
        if (!mounted.current) return;
        if (getNetworkErrorCode(error) === NetworkErrorCode.invalidTin) {
          const invalidTaxId: string =
            (isAxiosError(error) && error.response?.data?.message) || '';
          const invalidTaxIdIndex = values.taxResidences.findIndex(
            (item) => item.taxId === invalidTaxId
          );
          if (invalidTaxIdIndex > -1) {
            formik.setFieldError(
              `taxResidences.[${invalidTaxIdIndex}].taxId`,
              t('errors.invalidTin')
            );
            return;
          }
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const fullName = `${data.givenName} ${data.surName}`;

  const { invalidEmailError, emailTooLongError } = validateEmail(
    formik.values.email || ''
  );

  const isSubmitDisabled =
    formik.isSubmitting ||
    (data.isEmailRequestedFromUser && !formik.values.email) ||
    typeof formik.values.isFatca !== 'boolean' ||
    typeof formik.values.isPep !== 'boolean' ||
    formik.values.taxResidences.some(
      (item) => !item.taxResidence || !item.taxId
    ) ||
    onboardingDocs.some(
      (doc) =>
        doc.status === OnboardingDocumentStatus.REQUESTED &&
        doc.files.length < doc.type.minNumberOfFiles
    ) ||
    !!invalidEmailError ||
    !!emailTooLongError;

  const canBeEdited =
    canUser('legal-rep-additional-info:update') &&
    [
      OrganizationRepresentativeExternalStatus.infoFilled,
      OrganizationRepresentativeExternalStatus.infoRequested,
    ].includes(data.externalStatus);

  const idCopyDoc = onboardingDocs.find(
    (doc) => doc.type.name === OnboardingDocumentName.ID_COPY
  );
  const proofOfAddressDoc = onboardingDocs.find(
    (doc) => doc.type.name === OnboardingDocumentName.PROOF_OF_ADDRESS
  );

  return (
    <StyledAccordion
      square
      expanded={expanded}
      onChange={() => setExpanded((prevExpanded) => !prevExpanded)}
    >
      <AccordionSummary
        expandIcon={<ArrowsButtonDownIcon width={16} height={16} />}
      >
        <Heading>
          <Box display="flex" alignItems="center">
            {!isNotCompleted && <StyledValidationCheckIcon />}

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography mr={2}>{`${fullName} (${[
                data.legalRepType !== LegalRepresentativeType.none &&
                  t('representativeAdditionalInfoSection.legalRepresentative'),
                data.uboType !== UboType.notUbo &&
                  t('representativeAdditionalInfoSection.ubo'),
              ]
                .filter(Boolean)
                .join(' & ')})`}</Typography>
              {[
                LegitimationStatus.ONGOING,
                LegitimationStatus.COMPLETED,
              ].includes(data.legitimationStatus) && (
                <LegitimationStatusBadge status={data.legitimationStatus} />
              )}
            </Box>
          </Box>

          {canBeEdited && data.reasonForAdditionalInfoRequest && (
            <ReasonInfoRequestWrapper>
              {data.reasonForAdditionalInfoRequest}
            </ReasonInfoRequestWrapper>
          )}
        </Heading>
      </AccordionSummary>

      <AccordionDetails>
        <Form onSubmit={formik.handleSubmit} noValidate>
          {data.isEmailRequestedFromUser && (
            <>
              <Typography variant="body2" mb={1}>
                {t(
                  'representativeAdditionalInfoSection.identificationProcessNotice',
                  {
                    name: fullName,
                  }
                )}
              </Typography>
              <Box mb={3} maxWidth="432px">
                <TextField
                  disabled={formik.isSubmitting || !canBeEdited}
                  label={t('representativeAdditionalInfoSection.email')}
                  {...formik.getFieldProps('email')}
                  error={!!invalidEmailError || !!emailTooLongError}
                  helperText={invalidEmailError || emailTooLongError}
                />
              </Box>
            </>
          )}
          <Box mb={3}>
            <FormControl>
              <Box display="flex" alignItems="center">
                <FormLabel sx={{ marginRight: 0.5 }}>
                  {t('representativeAdditionalInfoSection.fatcaLabel', {
                    fullName,
                  })}
                </FormLabel>
                <Tooltip
                  title={
                    <Trans
                      i18nKey="representativeAdditionalInfoSection.fatcaTooltip"
                      components={{
                        li: <li />,
                        ul: <ul />,
                      }}
                    />
                  }
                >
                  <QuestionIcon />
                </Tooltip>
              </Box>
              <RadioGroup
                row
                {...formik.getFieldProps('isFatca')}
                onChange={(_, value) =>
                  formik.setFieldValue('isFatca', value === 'true')
                }
              >
                <FormControlLabel
                  control={<Radio />}
                  disabled={formik.isSubmitting || !canBeEdited}
                  label={t('common.no')}
                  value={false}
                />
                <FormControlLabel
                  control={<Radio />}
                  disabled={formik.isSubmitting || !canBeEdited}
                  label={t('common.yes')}
                  value={true}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box mb={3}>
            <FormControl>
              <Box display="flex" alignItems="center">
                <FormLabel sx={{ marginRight: 0.5 }}>
                  {t('representativeAdditionalInfoSection.pepLabel', {
                    fullName,
                  })}
                </FormLabel>
                <Tooltip
                  title={
                    <Trans
                      i18nKey="representativeAdditionalInfoSection.pepTooltip"
                      components={{
                        li: <li />,
                        ul: <ul />,
                      }}
                    />
                  }
                >
                  <QuestionIcon />
                </Tooltip>
              </Box>
              <RadioGroup
                row
                {...formik.getFieldProps('isPep')}
                onChange={(_, value) =>
                  formik.setFieldValue('isPep', value === 'true')
                }
              >
                <FormControlLabel
                  control={<Radio />}
                  disabled={formik.isSubmitting || !canBeEdited}
                  label={t('common.no')}
                  value={false}
                />
                <FormControlLabel
                  control={<Radio />}
                  disabled={formik.isSubmitting || !canBeEdited}
                  label={t('common.yes')}
                  value={true}
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box mb={3}>
            <TaxIdNumberInputGroup
              canBeEdited={canBeEdited}
              isLoading={formik.isSubmitting}
              errors={formik.errors?.taxResidences ?? []}
              taxResidences={formik.values.taxResidences}
              onChange={(taxResidences) =>
                formik.setFieldValue('taxResidences', taxResidences)
              }
              onErrorChange={(errors) =>
                formik.setErrors({ taxResidences: errors })
              }
            />
          </Box>

          {onboardingDocs.length > 0 && (
            <Box mb={3}>
              {idCopyDoc && (
                <OnboardingDocumentRow
                  document={idCopyDoc}
                  onUpdate={onOnboardingDocsUpdate}
                />
              )}
              {proofOfAddressDoc && (
                <OnboardingDocumentRow
                  document={proofOfAddressDoc}
                  onUpdate={onOnboardingDocsUpdate}
                />
              )}
            </Box>
          )}

          {canBeEdited && (
            <Box mb="40px" mt="32px" position="relative" width="fit-content">
              <Button disabled={isSubmitDisabled} type="submit">
                {t('common.button.save')}
              </Button>
              {formik.isSubmitting && (
                <LoaderWithOverlay size={32} thickness={3} />
              )}
            </Box>
          )}
        </Form>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default RepresentativeAdditionalInfoSection;
