import React from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import {
  CardHistoryEntryType,
  CardHistoryValidityChangedEntry,
} from 'services/constants';

interface Props {
  entry: CardHistoryValidityChangedEntry;
}

const ValidityChangedEventTitle = ({ entry }: Props) => {
  const { t } = useTranslation();
  let values;

  if (entry.type === CardHistoryEntryType.validityFromChanged)
    values = {
      prevDate: entry.prevValidityFrom
        ? moment(entry.prevValidityFrom).format('DD.MM.YYYY')
        : t('cardHistory.none'),
      date: entry.validityFrom
        ? moment(entry.validityFrom).format('DD.MM.YYYY')
        : t('cardHistory.none'),
    };

  if (entry.type === CardHistoryEntryType.validityToChanged)
    values = {
      prevDate: entry.prevValidityTo
        ? moment(entry.prevValidityTo).format('DD.MM.YYYY')
        : t('cardHistory.none'),
      date: entry.validityTo
        ? moment(entry.validityTo).format('DD.MM.YYYY')
        : t('cardHistory.none'),
    };

  if (entry.type === CardHistoryEntryType.validityTimezoneChanged)
    values = {
      prevTimezone: entry.prevValidityTimezone || t('cardHistory.none'),
      timezone: entry.validityTimezone || t('cardHistory.none'),
    };

  return (
    <Trans
      i18nKey={`cardHistory.titleByType.${entry.type}`}
      values={values}
      components={{ b: <b /> }}
    />
  );
};

export default ValidityChangedEventTitle;
