import { useTranslation } from 'react-i18next';
import { StatusBadge } from 'elements';
import { DirectDebitType } from 'services/constants';

interface Props {
  value: DirectDebitType;
}

const DirectDebitBadge = ({ value }: Props) => {
  const { t } = useTranslation();

  return (
    <StatusBadge
      variant="tinted"
      color={value === DirectDebitType.b2b ? 'secondary' : 'info'}
      label={t(`directDebitTypes.${value}`)}
    />
  );
};

export default DirectDebitBadge;
