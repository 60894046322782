import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/de';
import { initReactI18next } from 'react-i18next';
import { SupportedWebAppLanguage } from 'services/constants';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const updateHtmlLangAttribute = (
  languageCode: SupportedWebAppLanguage
) => document.querySelector('html')?.setAttribute('lang', languageCode);

const initializeI18n = (lang: string) => {
  moment.tz.setDefault('Europe/Berlin');
  moment.locale(lang);
  return i18next
    .use(
      resourcesToBackend((language: string) =>
        import(`./locales/${language}.json`).then(
          (module) => module.translation
        )
      )
    )
    .use(initReactI18next)
    .init({
      returnNull: false,
      lng: lang,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        skipOnVariables: false, // default definition for previous versions. Should be tested before removing.
      },
      react: {
        transWrapTextNodes: 'span',
      },
    });
};

export const loadInternalTranslations = () =>
  import('./locales/internal/en.json').then((module) => {
    i18next.addResourceBundle('en', 'translation', {
      int: module.internal,
    });
  });

export default initializeI18n;
