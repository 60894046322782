import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card, MemberDetails } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  teamId: string;
}

interface Props extends DialogProps {
  card: Card;
  onSuccess: (card: Card) => void;
  onClose: () => void;
}

const EditCardTeamDialog = ({ card, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const [member, setMember] = useState<MemberDetails | null>(null);
  const formik = useFormik<FormValues>({
    initialValues: {
      teamId: '',
    },
    onSubmit: async ({ teamId }, { setSubmitting }) => {
      try {
        const data = await api.updateCardTeam(card.cardId, teamId || null);
        if (!mounted.current) return;
        setSubmitting(false);
        onSuccess(data);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        setSubmitting(false);
        logError(error);
      }
    },
  });

  const getData = async () => {
    try {
      formik.setSubmitting(true);
      const data = await api.getMember(organization!.id, card.memberId);
      if (!mounted.current) return;
      setMember(data);
      // The initial value is set here instead of the formik initialValues to prevent the following warning:
      // "MUI: You have provided an out-of-range value ... for the select component ..."
      formik.setFieldValue('teamId', card.teamId || '');
      formik.setSubmitting(false);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const isSubmitDisabled =
    formik.values.teamId === (card.teamId || '') || formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('editCardTeamDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="edit-card-team-form"
          noValidate
        >
          <Box mb={4}>
            <Typography variant="body2">
              {t('editCardTeamDialog.description', card)}
            </Typography>
          </Box>
          <FormControl
            fullWidth
            disabled={!member?.teams.length || formik.isSubmitting}
            error={member?.teams.length === 0}
          >
            <InputLabel id="card-team-select-label">
              {t('editCardTeamDialog.team')}
            </InputLabel>
            <Select<string>
              {...formik.getFieldProps('teamId')}
              renderValue={(selected) => {
                const team = member?.teams.find(
                  (item) => item.teamId === selected
                );
                return team?.name;
              }}
              labelId="card-team-select-label"
            >
              <MenuItem value="">{t('editCardTeamDialog.none')}</MenuItem>
              {member?.teams.map((item) => (
                <MenuItem key={item.teamId} value={item.teamId}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {member?.teams.length === 0 && (
              <FormHelperText>
                {t('editCardTeamDialog.teamAssignmentDisabledError')}
              </FormHelperText>
            )}
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="edit-card-team-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditCardTeamDialog);
