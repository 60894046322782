import React, { useState } from 'react';
import { useGlobalState } from 'context/GlobalState';
import {
  CreateEditTeamBudgetDialog,
  CreateEditTeamDialog,
  EditTeamPermissionsDialog,
  UpdateTeamMembersDialog,
} from 'domains/member/dialogs';
import { DialogProps, withDialogWrapper } from 'elements';
import { Team } from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props extends DialogProps {
  onClose: () => void;
}

const CreateTeamMultistepDialog = (props: Props) => {
  const canUser = useCanUser();
  const {
    state: { featureModules },
  } = useGlobalState();
  const [step, setStep] = useState<
    'createTeam' | 'addMembers' | 'setPermissions' | 'setTeamBudget'
  >('createTeam');
  const [newTeam, setNewTeam] = useState<Team | null>(null);
  const canUserChangeBudget =
    featureModules.TEAM_BUDGETS && canUser('team-budget:change');

  if (step === 'createTeam')
    return (
      <CreateEditTeamDialog
        {...props}
        onCreate={(team) => {
          setNewTeam(team);
          setStep('addMembers');
        }}
      />
    );

  if (step === 'addMembers')
    return (
      <UpdateTeamMembersDialog
        {...props}
        team={newTeam}
        onSuccess={(team) => {
          if (canUser('team-permissions:change', team)) {
            setNewTeam(team);
            setStep('setPermissions');
          } else if (canUserChangeBudget) {
            setNewTeam(team);
            setStep('setTeamBudget');
          } else {
            props.onClose();
          }
        }}
      />
    );

  if (step === 'setPermissions')
    return (
      <EditTeamPermissionsDialog
        {...props}
        team={newTeam}
        onSuccess={(team) => {
          if (canUserChangeBudget) {
            setNewTeam(team);
            setStep('setTeamBudget');
          } else {
            props.onClose();
          }
        }}
      />
    );

  if (step === 'setTeamBudget')
    return <CreateEditTeamBudgetDialog {...props} team={newTeam} />;

  return null;
};

export default withDialogWrapper(CreateTeamMultistepDialog);
