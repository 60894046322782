import styled from 'styled-components';

export const PageContent = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  position: relative;
`;

export const HeroContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: fit-content;
  padding: 48px 24px;
  width: 100%;
`;

export const Header = styled.h2`
  align-self: flex-start;
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 84px;
  user-select: none;
  z-index: 1;
`;

export const BottomAnnotation = styled.div`
  color: ${({ theme }) => theme.colors.grey100};
  font-size: 10px;
  line-height: 12px;
  margin: 20px 0 24px;
  text-align: center;
`;
