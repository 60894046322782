import React, { useEffect, useRef, useState } from 'react';
import intersection from 'lodash/intersection';
import { useTranslation } from 'react-i18next';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import BulkUploadItemsDialog from 'components/BulkUploadItemsDialog';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { useActiveTeams, useGlobalState } from 'context/GlobalState';
import {
  InviteMemberButtonWithDialog,
  MembersPageFilters,
} from 'domains/member/components';
import { InviteMemberDialog } from 'domains/member/dialogs';
import { MemberDetailsPage } from 'domains/member/pages';
import { AddNewItemMenu } from 'domains/settings/components/AddNewItemMenu';
import {
  DataGrid,
  gridUtils,
  LoaderWithOverlay,
  useGridApiRef,
  UsersIcon,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import {
  PageHeader,
  PageTableContent,
  PageTitle,
  PageTitleActions,
} from 'layout';
import {
  Card,
  DEFAULT_PAGE_LIMIT,
  Member,
  MemberDetails,
  memberStatuses,
  Team,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { EXTERNAL_ADMIN_ROLES, useCanUser } from 'services/rbac';
import { isSortValid } from 'services/utils';
import useColumns from './useColumns';

const getQueryParams = (
  qs: string,
  allowedSortKeys: string[],
  teams: Team[] = []
) => {
  const { sort, q, role, status, teamIds } = Object.fromEntries(
    new URLSearchParams(qs).entries()
  );

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '+status',
    q: q ? q.trim() : '',
    role: role ? intersection(role.split(','), EXTERNAL_ADMIN_ROLES) : [],
    status: status ? intersection(status.split(','), memberStatuses) : [],
    teamIds: teamIds
      ? intersection(
          teamIds.split(','),
          teams.map((item) => item.id)
        )
      : [],
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({ role, status, teamIds }: QueryParams) =>
  +!!role.length + +!!status.length + +!!teamIds.length;

interface State {
  isLoading: boolean;
  members: Member[];
  hasNextPage: boolean;
  totalCount: number;
}

const MembersPage = () => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const canUser = useCanUser();
  const location = useLocation();
  const history = useHistory();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { detailsParams } = useIsDetailsPageOpen('/:memberId', true);
  const showPageError = useShowPageError();
  const teams = useActiveTeams();
  const { allowedSortKeys, columns } = useColumns();
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(
    getQueryParams(location.search, allowedSortKeys, teams)
  );
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const {
    state: { organization, subscriptionPlan },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    members: [],
    hasNextPage: false,
    totalCount: 0,
  });
  const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = useState(false);
  const [isUploadMembersDialogOpen, setIsUploadMembersDialogOpen] = useState(
    false
  );
  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const { sort, q, role, status, teamIds } = paramsRef.current;
      const { members, hasNextPage, totalCount } = await api.getMembers({
        page,
        limit,
        sort,
        q: q || undefined,
        role: role.length ? role.join() : undefined,
        status: status.length ? status.join() : undefined,
        teamIds: teamIds.length ? teamIds.join() : undefined,
        organizationId: organization!.id,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        isLoading: false,
        members: isLoadMore ? [...prevState.members, ...members] : members,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });

    paramsRef.current = getQueryParams(location.search, allowedSortKeys, teams);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const onMemberUpdate = (member: MemberDetails, cards: Card[]) => {
    setState((prevState) => ({
      ...prevState,
      members: prevState.members.map((item) =>
        item.id === member.id && item.organizationId === member.organizationId
          ? { ...item, ...member, cards }
          : item
      ),
    }));
  };

  const onMemberInvite = () => {
    getData(0, (pageRef.current + 1) * DEFAULT_PAGE_LIMIT);
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('membersPage.title')}>
          {canUser('member:bulk-upload') ? (
            <AddNewItemMenu
              format="XLSX"
              title={t('int.membersPage.addMember')}
              onAdd={
                subscriptionPlan
                  ? () => setIsAddMemberDialogOpen(true)
                  : undefined
              }
              onUpload={() => setIsUploadMembersDialogOpen(true)}
            />
          ) : (
            <PageTitleActions>
              <InviteMemberButtonWithDialog
                dataTestId="invite-member-btn"
                onSuccess={onMemberInvite}
                isNewDesign
              />
            </PageTitleActions>
          )}
        </PageTitle>
        <MembersPageFilters
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          teams={teams}
          membersCount={state.totalCount}
        />
      </PageHeader>

      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid<Member>
          apiRef={dataGridRef}
          rowHeight={72}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          rowCount={state.totalCount}
          rowSelectionModel={
            detailsParams?.memberId ? [detailsParams.memberId] : []
          }
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(paramsRef.current.sort),
            },
          }}
          loading={state.isLoading}
          rows={state.members}
          columns={columns}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ id, row }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${row.id}${location.search}`);
          }}
          onSortModelChange={(sort) => {
            if (state.isLoading || !state.members.length) return;
            setQueryParam('sort', gridUtils.getNewSortParam(sort));
          }}
          slots={{
            noRowsOverlay: () =>
              areFiltersApplied ? (
                <NothingFound />
              ) : (
                <NoData
                  isNewDesign
                  Icon={UsersIcon}
                  label={t('membersPage.noMembers')}
                />
              ),
            loadingOverlay: () => null,
          }}
        />

        <Route
          path={`${path}/:memberId`}
          children={({ match }) => (
            <MemberDetailsPage open={!!match} onUpdate={onMemberUpdate} />
          )}
        />
      </PageTableContent>

      {subscriptionPlan && (
        <InviteMemberDialog
          subscriptionPlan={subscriptionPlan}
          open={isAddMemberDialogOpen}
          onClose={() => setIsAddMemberDialogOpen(false)}
          onSuccess={() => {
            setIsAddMemberDialogOpen(false);
            onMemberInvite();
          }}
        />
      )}
      <BulkUploadItemsDialog
        open={isUploadMembersDialogOpen}
        onClose={() => setIsUploadMembersDialogOpen(false)}
        context="members"
      />
    </>
  );
};

export default withPageConfig(MembersPage, {
  permission: 'members-page:visit',
});
